import { ParthenonIcon } from 'assets/icons';
import { Loader } from 'components/Loader';
import { styled } from 'theme';

export const StyledCellRoot = styled('div')`
  display: contents;
  align-items: center;
  position: relative;
  max-width: 100%;

  &[data-colored='true'] {
    top: 4px;
  }

  &[data-parthenon='true'] {
    top: 4px;
  }
`;

export const StyledIcon = styled('span')`
  width: 15px;
  height: 15px;
  background: center center/15px 15px no-repeat;
  position: relative;
  display: inline-flex;
  margin-right: 8px;
  margin-bottom: -3px;

  &[data-border='true']::before {
    position: absolute;
    content: '';
    left: 2px;
    top: 2px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.color.icon.secondary};
  }
`;

export const StyledName = styled('span')`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 8px;
  position: relative;
`;

export const StyledParthenonIcon = styled(ParthenonIcon)`
  color: ${({ theme }) => theme.color.icon.promo};
  margin-right: 8px;
  margin-bottom: -2px;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  right: 0;
  top: 8px;
`;
