import { Select } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledFormWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  padding: 24px 16px 24px 24px;
  margin-bottom: 8px;
`;

export const StyledFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

// todo fix after https://jira.exante.eu/browse/RUI-643
// bug in mobile from here https://jira.exante.eu/browse/CL-3783
// this is hack for desktop
export const StyledFormSelect = styled(Select)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 202px;
  }
  .MuiSelect-filled {
    display: block;
  }
`;
