import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledExternalAccountsLabel,
  StyledExternalAccountsContainer,
  StyledExternalAccountsBlock,
} from './ExternalAccounts.styled';
import { AccountSkeleton } from './components/Account';

export const ExternalAccountsSkeleton = () => {
  return (
    <StyledExternalAccountsBlock className="ExternalAccountsBlock">
      <StyledExternalAccountsLabel className="ExternalAccountsLabel">
        <Skeleton variant="rounded" width={80} height={24} />
      </StyledExternalAccountsLabel>
      <StyledExternalAccountsContainer className="ExternalAccountsContainer">
        <AccountSkeleton />
      </StyledExternalAccountsContainer>
    </StyledExternalAccountsBlock>
  );
};
