import { IColumn } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { TCorporateAction } from 'services/corporateActions/corporateActions.types';

import { CORPORATE_ACTIONS_LIST_DISPLAYED_COLUMNS } from './CorporateActionsList.constants';
import { TGetCorporateActionsListTableColumnsProps } from './CorporateActionsList.types';

const prepareColumnData = (columnKey: string, data: any) => {
  const dataWithFallback = data || DEFAULT_EMPTY_PLACEHOLDER;

  switch (columnKey) {
    case 'record_date':
      if (typeof data === 'string') {
        return data.split(' ')[0];
      }

      return dataWithFallback;
    default:
      return dataWithFallback;
  }
};

export const getCorporateActionsListTableColumns = ({
  t,
  onRemove,
  onFilter,
}: TGetCorporateActionsListTableColumnsProps): IColumn<TCorporateAction>[] =>
  Object.entries(CORPORATE_ACTIONS_LIST_DISPLAYED_COLUMNS).map(
    ([accessor, colKey]) => {
      const typedAccessor = accessor as keyof TCorporateAction;
      const localizedName = t(colKey);
      const allowedSortBy: Array<keyof TCorporateAction> = [
        'announce_date',
        'effective_date',
        'isin',
        'symbol_type',
        'status',
      ];

      return {
        id: typedAccessor,
        Header: localizedName,
        accessor: (corpAction: TCorporateAction) => {
          const caExtend = corpAction.ca_extend;
          const name = typedAccessor as keyof TCorporateAction['ca_extend'];

          if (caExtend === null) {
            return corpAction[name] ?? DEFAULT_EMPTY_PLACEHOLDER;
          }

          const value = name in caExtend ? caExtend[name] : corpAction[name];

          return prepareColumnData(typedAccessor, value);
        },
        minWidth: (() => {
          if (typedAccessor === 'bo_symbol') {
            return 180;
          }

          const approximateLength = typedAccessor.length * 12;
          const localizedLength = localizedName.length;

          return localizedLength > approximateLength
            ? localizedLength
            : approximateLength;
        })(),
        type: typedAccessor.includes('date') ? 'dateRange' : 'textInput',
        required: typedAccessor === 'bo_symbol',
        disableSortBy: !allowedSortBy.includes(typedAccessor),
        onFilter,
        onRemove,
      };
    },
  );
