import { times } from 'lodash';
import { FC } from 'react';

import {
  StyledTabSkeleton,
  StyledTabsSkeletonWrapper,
} from './TabsSkeleton.styled';

export const TabsSkeleton: FC = () => {
  return (
    <StyledTabsSkeletonWrapper>
      {times(4, (i) => (
        <StyledTabSkeleton key={i} variant="rounded" animation="wave" />
      ))}
    </StyledTabsSkeletonWrapper>
  );
};
