import { styled } from 'theme';

export const StyledPartnerProceedPicture = styled('img')`
  width: 92px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute;
    top: 32px;
    right: -100px;
    width: 597px;
  }
  ${({ theme }) => theme.breakpoints.up('lr')} {
    right: 14px;
  }
`;
