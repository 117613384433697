import { TFunction } from 'i18next';
import { createElement } from 'react';
import { Link, matchPath } from 'react-router-dom';
import { IMenuItem } from 'react-ui-kit-exante';

import {
  TExtendedMenuItem,
  TMenuIcons,
} from 'components/Navigation/Navigation.types';
import { getQueryString } from 'helpers/getQueryString';
import { PATHS } from 'router';
import { TBrandingResponse } from 'services/branding';
import { challengeService } from 'services/challenge';
import { TMenuItem } from 'services/menu';
import { PAGE_TABS_MAPPING, TTab } from 'services/tabs';

import {
  NAVIGATION_ITEM_ICONS,
  NAVIGATION_NON_ROOT_PATHS,
} from './Navigation.constants';

export const prepareUrl = (url: string): string => {
  const isLocalDev = ['localhost', 'site.exante'].includes(
    window.location.hostname,
  );

  if (
    url.includes('http') &&
    (isLocalDev || url.startsWith(window.location.origin))
  ) {
    return new URL(url).pathname;
  }

  return url;
};

const getIsReactUrl = (url: string): boolean => {
  return Object.values(NAVIGATION_NON_ROOT_PATHS).some((path) => {
    return !!matchPath(url, PATHS.ROOT + path);
  });
};

export const sanitizeMenuItems = (
  menuItems: Array<TMenuItem | null> | undefined = [],
): TMenuItem[] => {
  return menuItems.filter<TMenuItem>(
    (item): item is TMenuItem => item !== null,
  );
};

export const getMenu = (
  menuIcons: TMenuIcons,
  menuItems: TExtendedMenuItem[],
  tabs: TTab[],
  t: TFunction,
): IMenuItem[] => {
  const consistentItems: TExtendedMenuItem[] = (menuItems || []).filter(
    (item): item is TMenuItem => typeof item === 'object' && item !== null,
  );

  const getIcon = (id: string): JSX.Element => {
    if (menuIcons?.[id]) {
      return createElement('img', {
        src: menuIcons[id],
        ariaLabel: id,
      });
    }

    const iconName = NAVIGATION_ITEM_ICONS[id] || 'div';
    return createElement(iconName);
  };

  return consistentItems.map<IMenuItem>(
    ({ item_id, title, url, items, target, visited }): IMenuItem => {
      let urlAliases: string[];
      const pageTabs =
        PAGE_TABS_MAPPING[item_id as keyof typeof PAGE_TABS_MAPPING];
      if (pageTabs) {
        urlAliases = tabs
          .filter((i) => Object.values(pageTabs).includes(i.id))
          .map((i) => i.url);
      } else {
        urlAliases = [];
      }

      return {
        id: `${item_id}-${title}-${url}`,
        forceExternal: !getIsReactUrl(url),
        linkComponent: Link,
        title: t(title),
        url: prepareUrl(url),
        urlAliases,
        items: items.length
          ? getMenu(menuIcons, sanitizeMenuItems(items), tabs, t)
          : [],
        icon: getIcon(item_id),
        target: target || '_self',
        showAttentionMark: !visited,
      };
    },
  );
};

export const getChallengeMenu = async (
  branding: TBrandingResponse | null,
  accountId?: string | null,
  userId?: string,
): Promise<TExtendedMenuItem[]> => {
  const menu: TExtendedMenuItem[] = [];
  if (!branding || !accountId || !userId) {
    return menu;
  }
  const challengeSettings = branding?.branding?.challenge_settings;
  if (
    challengeSettings &&
    accountId &&
    (challengeSettings?.pit_community?.is_showing ||
      challengeSettings?.squawkbox?.is_showing)
  ) {
    const challenge = await challengeService.getChallenge(accountId);
    if (challenge?.data) {
      const planOverride = challenge.data.plan_override;
      const searchParams = getQueryString({
        userId,
        accountId,
        challengeId: planOverride?.plan_id,
      });

      if (
        planOverride?.pit_community &&
        challengeSettings?.pit_community?.is_showing &&
        challengeSettings?.pit_community?.redirect_url
      ) {
        const url = `${challengeSettings?.pit_community?.redirect_url}?${searchParams}`;
        menu.push({
          item_id: 'pitCommunity',
          items: [],
          soft: false,
          css: '',
          title: 'menu_item__pit_community',
          alias: 'pit_community',
          target: '_blank',
          url,
          visited: true,
        });
      }

      if (
        planOverride?.squawkbox &&
        challengeSettings?.squawkbox?.is_showing &&
        challengeSettings?.squawkbox?.redirect_url
      ) {
        const url = `${challengeSettings?.squawkbox?.redirect_url}?${searchParams}`;
        menu.push({
          item_id: 'squawkbox',
          items: [],
          soft: false,
          css: '',
          title: 'menu_item__squawkbox',
          alias: 'squawkbox',
          target: '_blank',
          url,
          visited: true,
        });
      }
    }
  }
  return menu;
};
