import { FC, useContext } from 'react';

import { CurrencyContext, TranslationContext } from 'contexts';
import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useTheme } from 'theme';

import { getChartColors, NAME_MAP } from '../Chart.constants';

import {
  StyledPoint,
  StyledTooltipContainer,
  StyledTooltipLabel,
  StyledTooltipRow,
  StyledTooltipTitle,
  StyledTooltipValue,
} from './CustomTooltip.styled';
import { TCustomTooltipProps } from './CustomTooltip.types';

export const CustomTooltip: FC = (props) => {
  const theme = useTheme();
  const { currency } = useContext(CurrencyContext);
  const { t } = useContext(TranslationContext);
  const formatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter();

  const { payload, label } = props as TCustomTooltipProps;

  const chartColors = getChartColors(theme);

  const getOtherColor = (value?: number) => {
    return Number(value) > 0 ? chartColors.positive : chartColors.negative;
  };

  return (
    <StyledTooltipContainer className="TooltipContainer">
      <StyledTooltipTitle className="TooltipTitle">
        {dateFormatter(label)}
      </StyledTooltipTitle>
      {payload?.map((item) => {
        return (
          <StyledTooltipRow className="TooltipRow" key={item.name}>
            <StyledTooltipLabel className="TooltipLabel">
              <StyledPoint
                className="Point"
                color={item.stroke || item.color || getOtherColor(item.value)}
              />
              {NAME_MAP(t)[item.name]}
            </StyledTooltipLabel>
            <StyledTooltipValue className="TooltipValue">
              {formatCurrency(formatter, item.value)} {currency}
            </StyledTooltipValue>
          </StyledTooltipRow>
        );
      })}
    </StyledTooltipContainer>
  );
};
