import { useMediaQuery } from '@mui/material';
import { FC, useContext, useRef, useState } from 'react';

import {
  StyledCurrencySelectorCurrentButton,
  StyledCurrencySelectorList,
  StyledCurrencySelectorMoneyIcon,
  StyledCurrencySelectorSelect,
  StyledCurrencySelectorSelectButton,
  StyledCurrencySelectorWrapper,
} from 'components/Header/components/CurrencySelector/CurrencySelector.styled';
import { CurrencyContext } from 'contexts';
import { useOutsideClick } from 'hooks/index';
import { useTheme } from 'theme';

export const CurrencySelector: FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const ref = useRef(null);
  const toggle = () => setExpanded(!expanded);
  const {
    currency: selectedCurrency,
    currencies,
    changeCurrency,
  } = useContext(CurrencyContext);

  useOutsideClick(ref, () => setExpanded(false));

  const select = async (currency: string) => {
    setExpanded(false);
    setDisabled(true);
    await changeCurrency(currency);
    setDisabled(false);
  };

  const currenciesList = currencies?.list || [];

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return !currenciesList.length ? null : (
    <StyledCurrencySelectorWrapper className="currency-selector" ref={ref}>
      {isDesktop ? (
        <>
          <StyledCurrencySelectorCurrentButton
            className="currency-selector__current"
            aria-haspopup="listbox"
            aria-expanded={expanded}
            aria-describedby="currency_menu"
            aria-controls="currency_menu"
            data-test-id="currency-selector__current"
            disabled={disabled}
            onClick={toggle}
            disableRipple
          >
            {selectedCurrency}
          </StyledCurrencySelectorCurrentButton>
          {expanded && (
            <StyledCurrencySelectorList
              id="currency_menu"
              className="currency-selector__list"
            >
              {currenciesList.map((currency) => (
                <li key={currency}>
                  <StyledCurrencySelectorSelectButton
                    className="currency-selector__button"
                    data-test-id="currency-selector__button"
                    onClick={() => select(currency)}
                  >
                    {currency}
                  </StyledCurrencySelectorSelectButton>
                </li>
              ))}
            </StyledCurrencySelectorList>
          )}
        </>
      ) : (
        <>
          <StyledCurrencySelectorMoneyIcon
            iconName="MoneyIcon"
            iconSize={24}
            className="currency-selector__plain-icon xnt-icon"
          />
          <StyledCurrencySelectorSelect
            className="currency-selector__plain-select"
            onChange={(event) => select(event.target.value)}
            defaultValue={selectedCurrency ?? ''}
          >
            {currenciesList.map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </StyledCurrencySelectorSelect>
        </>
      )}
    </StyledCurrencySelectorWrapper>
  );
};
