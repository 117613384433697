import { createContext } from 'react';

import {
  initialTransfersState,
  initialAccountDetailsState,
  commissionsInitialState,
} from './hooks';
import { TTransfersContext } from './types';

export const transfersContext: TTransfersContext = {
  accountDetailsState: initialAccountDetailsState,
  accountDetailsDispatch: () => {},

  transfersState: initialTransfersState,
  transfersDispatch: () => {},

  commissionsState: commissionsInitialState,
  commissionsDispatch: () => {},

  cashTransferTab: 'BANK',
  setCashTransferTab: () => {},
  fetchServiceFees: () => {},
};

export const TransfersContext = createContext(transfersContext);
