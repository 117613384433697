import { TFunction } from 'i18next';
import {
  FilterValue,
  ICellValue,
  IColumn,
  TableCellProps,
} from 'react-ui-kit-exante';

import {
  formatCellToCurrencyUnrounded,
  formatCurrencyUnrounded,
  TCreateCurrencyFormatter,
} from 'helpers/formatters';
import { TDateFormatter } from 'hooks/useDateFormatter';
import { TTrade } from 'services/trades';
import { TTransactionData } from 'services/transactions';

export const columnKeys = [
  'gwTime',
  'side',
  'symbolId',
  'symbolType',
  'price',
  'quantity',
  'commission',
  'pnl',
  'tradeType',
] as const;

export type TTradesColumns = {
  onFilter: (col: string, value: FilterValue) => void;
  onRemove: (col: string) => void;
  t: TFunction;
  currencyFormatter: TCreateCurrencyFormatter;
  dateFormatter: TDateFormatter;
};

export const getColumns: ({
  onFilter,
  onRemove,
  t,
  currencyFormatter,
  dateFormatter,
}: TTradesColumns) => IColumn<TTrade>[] = ({
  onFilter,
  onRemove,
  t,
  currencyFormatter,
  dateFormatter,
}) => {
  const setFilterDate = (column: string, value: (Date | null)[]) => {
    let dateFilter: string[] | string = value
      .map((v) => v && new Date(v))
      .map((v) => (v?.setMilliseconds(0) && v.toISOString()) || '');
    if (dateFilter.every((date) => !date)) {
      dateFilter = '';
    }
    onFilter(column, dateFilter);
  };

  return [
    {
      id: 'gwTime',
      Header: t('generic__date'),
      accessor: 'gwTime',
      type: 'dateRange',
      onFilter: setFilterDate,
      onRemove,
      onToday: setFilterDate,
      onYesterday: setFilterDate,
      onLastWeek: setFilterDate,
      Cell: ({ value }: TableCellProps<TTransactionData>) => {
        if (typeof value === 'string') {
          const fixedUtcTime = value.replace('Z', ''); // preventing conversion to local timezone
          return dateFormatter(fixedUtcTime);
        }
        return null;
      },
    },
    {
      id: 'side',
      Header: t('layout__trades__trade_side'),
      accessor: 'side',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      id: 'symbolId',
      Header: t('symbol_id'),
      accessor: 'symbolId',
      disableFilters: true,
      disableSortBy: true,
      required: true,
    },
    {
      id: 'symbolType',
      Header: t('generic__type'),
      accessor: 'symbolType',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      id: 'price',
      Header: t('generic__price'),
      accessor: 'price',
      disableFilters: true,
      Cell: ({ row: { values, original } }: ICellValue<TTrade>) => {
        return `${formatCurrencyUnrounded(currencyFormatter, values.price)} ${
          original.currency
        }`;
      },
      disableSortBy: true,
    },
    {
      id: 'quantity',
      Header: t('layout__trades__trade_quantity'),
      accessor: 'quantity',
      disableFilters: true,
      disableSortBy: true,
      Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'quantity'),
    },
    {
      id: 'commission',
      Header: t('generic__commissions'),
      accessor: 'commission',
      disableFilters: true,
      formatting: 'number',
      Cell: ({ row: { values, original } }: ICellValue<TTrade>) => {
        return `${formatCurrencyUnrounded(
          currencyFormatter,
          values.commission,
        )} ${original.commissionCurrency || ''}`;
      },
      disableSortBy: true,
    },
    {
      id: 'pnl',
      Header: t('layout__summary__pl'),
      accessor: 'pnl',
      disableFilters: true,
      formatting: 'number',
      Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'pnl'),
      disableSortBy: true,
    },
    {
      id: 'tradeType',
      Header: t('layout__trades__trade_type'),
      accessor: 'tradeType',
      disableFilters: true,
      disableSortBy: true,
    },
  ];
};
