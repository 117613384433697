import { FC, FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Input, TToggleButtonGroupOption } from 'react-ui-kit-exante';

import { CurrencyContext, TranslationContext } from 'contexts';
import { clearNumberString } from 'helpers/numbers';
import { TCardSettingsOrderFormProps } from 'pages/CardSettings/components/CardSettingsOrder/components/CardSettingsOrderForm/CardSettingsOrderForm.types';
import { CardSettingsContext } from 'pages/CardSettings/contexts';
import { TCurrencies } from 'services/currencies';
import { CardFeesTypes } from 'services/serviceFees';

import {
  StyledCardSettingsOrderFormWrapper,
  StyledOrderCardFeeNote,
  StyledOrderCardTypeSelect,
  StyledOrderCurrencySelect,
  StyledOrderInfoHeader,
} from './CardSettingsOrderForm.styled';

export const CardSettingsOrderForm: FC<TCardSettingsOrderFormProps> = ({
  orderIsDisabled,
  onOrderSuccess,
}) => {
  const { t } = useContext(TranslationContext);
  const { currencies: allCurrencies } = useContext(CurrencyContext);
  const {
    onOrder,
    currencies: allowedCurrencies,
    order: {
      availableCardTypes,
      cardholderName: orderCardholderName,
      deliveryLocation,
    },
    isOrdering,
    cardsLoading,
    getCardFeeByType,
    cardFeesCurrency,
  } = useContext(CardSettingsContext);

  const currencies = useMemo<TCurrencies>(() => {
    const result: TCurrencies = {
      list: [],
      icons: {},
    };

    if (!allCurrencies || !allowedCurrencies) {
      return result;
    }

    return allowedCurrencies.reduce<TCurrencies>((res, currency) => {
      if (
        allCurrencies.list.includes(currency) &&
        currency in allCurrencies.icons
      ) {
        res.list.push(currency);
        res.icons[currency] = allCurrencies.icons[currency];
      }

      return res;
    }, result);
  }, [allCurrencies, allowedCurrencies]);

  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');

  const cardTypes: TToggleButtonGroupOption[] =
    availableCardTypes?.map(
      ({ value, labelKey }): TToggleButtonGroupOption => ({
        value,
        label: t(labelKey),
        className: 'OrderCardTypeSelectOption',
      }),
    ) || [];

  const orderCard = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!(orderCardholderName && deliveryLocation && selectedCurrency)) {
      return;
    }

    const ordered: boolean = await onOrder({
      currency: selectedCurrency,
      deliveryLocation,
      cardholder: orderCardholderName,
      limitsGroup: selectedType,
    });

    if (ordered) {
      onOrderSuccess();
    }
  };

  const cardFeeNote = useMemo<string>(() => {
    const fee = getCardFeeByType(CardFeesTypes.ADDITIONAL);
    const value = fee?.value?.fixed;
    if (!value) {
      return '';
    }

    return t('layout__cards__fee_notice', {
      VALUE: clearNumberString(value),
      CURRENCY: cardFeesCurrency || 'EUR',
    });
  }, [getCardFeeByType, cardFeesCurrency]);

  const firstCardType = availableCardTypes?.[0]?.value;

  useEffect(() => {
    if (firstCardType && !selectedType.length) {
      setSelectedType(firstCardType);
    }
  }, [firstCardType]);

  const firstAvailableCurrency = currencies.list[0];
  const orderButtonDisabled: boolean =
    isOrdering || !selectedCurrency || orderIsDisabled;

  useEffect(() => {
    if (firstAvailableCurrency && !selectedCurrency.length) {
      setSelectedCurrency(firstAvailableCurrency);
    }
  }, [firstAvailableCurrency]);

  return (
    <StyledCardSettingsOrderFormWrapper
      className="CardSettingsOrderFormWrapper"
      onSubmit={orderCard}
    >
      <StyledOrderInfoHeader className="OrderInfoHeader">
        {t('layout__cards__confirm_card_details')}
      </StyledOrderInfoHeader>
      <Input
        value={orderCardholderName}
        placeholder={t('layout__cards__cardholder_name')}
        title={orderCardholderName || ''}
        fullWidth
        disabled
        showSkeleton={cardsLoading}
      />
      <StyledOrderCardTypeSelect
        className="OrderCardTypeSelect"
        options={cardTypes}
        onChange={setSelectedType}
        value={selectedType}
        orientation="horizontal"
        exclusive
        disabled={orderIsDisabled}
        showSkeleton={cardsLoading}
      />

      <StyledOrderCurrencySelect
        className="OrderCurrencySelect"
        options={currencies?.list}
        icons={currencies?.icons}
        value={selectedCurrency}
        onChange={setSelectedCurrency}
        placeholder={t('generic__currency')}
        disabled={orderIsDisabled}
        showSkeleton={cardsLoading}
      />
      <Input
        value={deliveryLocation}
        placeholder={t('layout__cards__delivery_location')}
        fullWidth
        disabled
        title={deliveryLocation || ''}
        showSkeleton={cardsLoading}
      />
      {cardFeeNote && (
        <StyledOrderCardFeeNote className="OrderCardFeeNote">
          {cardFeeNote}
        </StyledOrderCardFeeNote>
      )}
      <Button
        disabled={orderButtonDisabled}
        type="submit"
        showSkeleton={cardsLoading}
      >
        {t('layout__cards__order')}
      </Button>
    </StyledCardSettingsOrderFormWrapper>
  );
};
