import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

type TStyledLoaderIconProps = {
  iconUrl: string;
};

export const StyledLoaderIcon = styled('div', {
  shouldForwardProp: blockNonNativeProps('iconUrl'),
})<TStyledLoaderIconProps>`
  width: 100%;
  height: 100%;
  background-image: url('${({ iconUrl }) => iconUrl}');
  background-repeat: no-repeat;
  background-size: contain;
`;
