import { FC, useContext, useState } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { FormCheckboxContainer } from 'components/Form/FormCheckboxContainer/FormCheckboxContainer';
import { TranslationContext } from 'contexts/TranslationContext';

import { TransfersContext } from '../../../../context';
import { SecuritiesContext } from '../../contexts/SecuritiesContext/SecuritiesContext';

import {
  StyledOptionalContainer,
  StyledSecuritiesForm,
} from './SecuritiesForm.styled';
import { TSecuritiesFormProps } from './SecuritiesForm.types';
import { SecuritiesFormGeneric } from './components';

export const SecuritiesForm: FC<TSecuritiesFormProps> = ({
  loading,
  formIsDisabled,
}) => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { transfersState } = useContext(TransfersContext);
  const { requiredFields, notRequiredFields, visibleFields } =
    useContext(SecuritiesContext);

  const [expanded, setExpanded] = useState(false);

  const handleOptionalButtonClick = () => {
    setExpanded((prevState) => !prevState);
  };

  const showSkeletons =
    loading ||
    transfersState?.fetching?.idle ||
    transfersState?.fetching?.pending ||
    isTranslationsLoading;

  return (
    <StyledSecuritiesForm className="SecuritiesForm">
      <SecuritiesFormGeneric
        loading={loading}
        fields={requiredFields}
        formIsDisabled={formIsDisabled}
      />
      <StyledOptionalContainer className="OptionalContainer">
        <IconButton
          iconColor="secondary"
          onClick={handleOptionalButtonClick}
          iconName={expanded ? 'MinusIcon' : 'AddIcon'}
          label={t('layout__subaccount_transfer__external_optional')}
          showSkeleton={showSkeletons}
        />
        {expanded && (
          <SecuritiesFormGeneric
            fields={notRequiredFields}
            formIsDisabled={formIsDisabled}
          />
        )}
      </StyledOptionalContainer>

      {visibleFields.includes('authorize_confirm') && (
        <FormCheckboxContainer
          label={t('layout__index__i_hereby_authorise_name_to').replace(
            'NAME',
            // we shouldn't display wl name
            '',
          )}
          name="authorize_confirm"
          showSkeleton={showSkeletons}
          disabled={formIsDisabled}
        />
      )}
    </StyledSecuritiesForm>
  );
};
