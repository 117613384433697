import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IconButton, Loader, Select, Tooltip } from 'react-ui-kit-exante';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { TranslationContext } from 'contexts';
import { reportsService } from 'services/reports';
import { TaxResidence } from 'services/reports/reports.types';

import { useFundsCheck } from '../../TaxReports.hooks';
import { TaxReportsContext } from '../../contexts/TaxReportsContext';
import { getFloatingMenuPositioningHack } from '../CreateTaxReport/components/RequestReport/RequestReport.helpers';
import { StyledSelectWrapper } from '../CreateTaxReport/components/RequestReport/RequestReport.styled';

import {
  StyledAcceptPriceFooter,
  StyledAcceptPriceHeading,
  StyledAcceptPriceText,
  StyledAcceptPriceWrapper,
  StyledActionButton,
  StyledOrderIconButton,
} from './AcceptPrice.styled';
import { TAcceptPriceProps } from './AcceptPrice.types';

export const AcceptPrice: FC<TAcceptPriceProps> = ({ report }) => {
  const { stateData, updateReports, setYearsRequested } =
    useContext(TaxReportsContext);
  const { t, currentLanguage } = useContext(TranslationContext);

  const acctOptions = useMemo(
    () =>
      stateData?.ownAccounts?.map((account) => ({
        label: account,
        value: account,
      })) || [],
    [stateData?.ownAccounts],
  );

  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [acctSelected, setAcctSelected] = useState(acctOptions[0].value);

  const refAccount = useRef<HTMLDivElement>(null);

  const { isFundsChecking, checkFunds, hasFunds } = useFundsCheck(
    report?.tax_year,
    acctSelected,
    false,
  );

  const isLoading = isAcceptLoading || isRemoveLoading;
  const isAcceptDisabled = isLoading || isFundsChecking || !hasFunds;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleAccountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAcctSelected(e.target.value);
  };

  const handleAcceptPrice = async () => {
    setIsAcceptLoading(true);

    let accept = false;

    if (stateData?.taxResidence === TaxResidence.Russia) {
      const acceptData = await reportsService.acceptRusPrice({
        lang: currentLanguage,
        account: acctSelected,
        reportId: String(report.id),
      });

      accept = acceptData?.rus_price_accepted || false;
    }

    if (accept) {
      setOpen(false);
      setTooltipOpen(false);
      updateReports();
    }

    setIsAcceptLoading(false);
  };

  const handleRemoveReport = async () => {
    setIsRemoveLoading(true);

    let removeData;

    if (stateData?.taxResidence === TaxResidence.Russia) {
      removeData = await reportsService.removeRusReport({
        lang: currentLanguage,
        year: report.tax_year,
        id: String(report.id),
      });

      updateReports();
    } else {
      removeData = true;
    }

    if (removeData) {
      setOpen(false);
      setTooltipOpen(false);
      setYearsRequested((yearsReq) =>
        yearsReq.filter((year) => year !== Number(report.tax_year)),
      );
    }

    setIsRemoveLoading(false);
  };

  const handleDismiss = async () => {
    setOpen(false);
    setTooltipOpen(false);
  };

  useEffect(() => {
    if (open) {
      checkFunds();
    }
  }, [acctSelected, open]);

  const acceptPriceText = t('layout__reports__tax__service_fee_charge', {
    FEE: report.tndfl_price,
  });

  return (
    <ActionWithConfirmation
      content={
        <StyledAcceptPriceWrapper className="AcceptPriceWrapper">
          <StyledAcceptPriceHeading className="AcceptPriceHeading">
            {t('layout__reports__tax__confirmation')}
            {isRemoveLoading ? (
              <Loader />
            ) : (
              <IconButton
                iconName="CloseIcon"
                color={isLoading ? 'ghost' : 'promo'}
                onClick={handleDismiss}
                disabled={isLoading}
              />
            )}
          </StyledAcceptPriceHeading>
          <StyledSelectWrapper className="SelectWrapper" ref={refAccount}>
            <Select
              fullWidth
              placeholder={t('reports__tax__table__account')}
              value={acctSelected}
              options={acctOptions}
              onChange={handleAccountChange}
              showSkeleton={isFundsChecking}
              menuProps={{
                disablePortal: true,
                anchorEl: getFloatingMenuPositioningHack(refAccount),
                disableScrollLock: true,
              }}
            />
          </StyledSelectWrapper>
          <StyledAcceptPriceText className="AcceptPriceText">
            {acceptPriceText}
          </StyledAcceptPriceText>
          <StyledAcceptPriceFooter className="AcceptPriceFooter">
            <StyledActionButton
              type="submit"
              color={isAcceptDisabled ? 'ghost' : 'action'}
              className="AcceptActionButton"
              onClick={handleAcceptPrice}
              disabled={isAcceptDisabled}
            >
              {isAcceptLoading && <Loader />}
              {t('generic__action__confirm')}
            </StyledActionButton>
            <StyledActionButton
              type="reset"
              color={isLoading ? 'ghost' : 'radical'}
              className="DeleteActionButton"
              onClick={handleRemoveReport}
              disabled={isLoading}
            >
              {isRemoveLoading && <Loader />}
              {t('layout__reports__tax__cancel_order')}
            </StyledActionButton>
          </StyledAcceptPriceFooter>
        </StyledAcceptPriceWrapper>
      }
      openFromExternal
      externalOpened={open}
      onConfirm={handleAcceptPrice}
      closeHandler={handleRemoveReport}
      dismissHandler={handleDismiss}
      noDismiss={isLoading}
      hideDefaultButtons
      placement="right"
    >
      <Tooltip
        title={acceptPriceText}
        placement="right"
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        open={tooltipOpen && !open}
      >
        <StyledOrderIconButton
          iconSize={16}
          iconName="OrderReportIcon"
          color="action"
          onClick={handleOpen}
          aria-label={t('layout__reports__tax__confirmation')}
        />
      </Tooltip>
    </ActionWithConfirmation>
  );
};
