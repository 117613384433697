import { IconButton } from 'react-ui-kit-exante';

import { SpinnerIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledAccountSelectionQRContentContainer = styled('div')`
  display: flex;
  width: 100%;
`;

export const StyledAccountSelectionQRCodeWrapper = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  height: 120px;
  padding: 1px;
  margin-right: 16px;
  //don't change baclground, it must always stay white for better qr code readness
  background: #fff;
`;

export const StyledAccountSelectionQRContentBackdrop = styled('div')`
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.color.bg.primary};
  opacity: 0.9;
  pointer-events: none;
`;

export const StyledAccountSelectionQRContentLoader = styled('div')`
  position: absolute;
`;

export const StyledAccountSelectionQRContentErrorContainer = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 4px;
  z-index: 1;
`;

export const StyledAccountSelectionQRContentText = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const StyledAccountSelectionQRContentTitle = styled('span')`
  font-family: ${({ theme }) => theme.font.header};
  color: ${({ theme }) => theme.color.typo.primary};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const StyledAccountSelectionQRContentDescription = styled('span')<{
  isError?: boolean;
}>`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme, isError }) =>
    isError ? theme.color.input.warning : theme.color.typo.secondary};
`;

export const StyledRetryIcon = styled(IconButton)`
  position: absolute;
`;

export const StyledSpinnerIcon = styled(SpinnerIcon)`
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.color.icon.action};
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
