import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Loader, Select } from 'react-ui-kit-exante';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { TranslationContext } from 'contexts';
import { notifyWith } from 'helpers/notifyWith';
import { TaxReportsContext } from 'pages/Reports/tabs/TaxReports/contexts/TaxReportsContext';
import { reportsService } from 'services/reports';
import {
  TAcceptPolTimingsResponse,
  TaxResidence,
  TCheckPolFundsResponse,
  TRequestReportResponse,
} from 'services/reports/reports.types';

import { TAX_REPORT_TYPE_BY_RESIDENCE } from '../../../../TaxReports.consts';
import { useFundsCheck } from '../../../../TaxReports.hooks';
import { StyledCreateButton } from '../../CreateTaxReport.styled';

import { getFloatingMenuPositioningHack } from './RequestReport.helpers';
import {
  StyledAccountSelect,
  StyledActionButton,
  StyledRequestReportFooter,
  StyledRequestReportHeading,
  StyledRequestReportText,
  StyledRequestReportWrapper,
  StyledSelectionPanel,
  StyledSelectWrapper,
} from './RequestReport.styled';

export const RequestReport: FC = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const {
    isLoadingAcceptTerms,
    isLoading,
    updateReports,
    stateData,
    setYearsRequested,
  } = useContext(TaxReportsContext);

  const refYear = useRef<HTMLDivElement>(null);
  const refAccount = useRef<HTMLDivElement>(null);

  const [openRequest, setOpenRequest] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const years = useMemo(
    () =>
      stateData?.taxationYears?.filter(
        (year) => !stateData?.requestedTaxYears.includes(year),
      ) || [],
    [stateData?.taxationYears, stateData?.requestedTaxYears],
  );

  const yearOptions = useMemo(
    () =>
      years.map((year) => ({
        label: year,
        value: String(year),
      })),
    [years],
  );

  const acctOptions = useMemo(
    () =>
      stateData?.ownAccounts?.map((account) => ({
        label: account,
        value: account,
      })) || [],
    [stateData?.ownAccounts],
  );

  const [yearSelected, setYearSelected] = useState(yearOptions[0]?.value);
  const [acctSelected, setAcctSelected] = useState(acctOptions[0]?.value);

  const { isFundsChecking, checkFunds, hasFunds } = useFundsCheck(
    yearSelected,
    acctSelected,
    true,
  );

  const isRequestDisabled = isFundsChecking || !hasFunds || isRequestLoading;

  const reportTypeTitle = stateData?.taxResidence
    ? TAX_REPORT_TYPE_BY_RESIDENCE[stateData?.taxResidence]
    : '';

  const isRus = stateData?.taxResidence === TaxResidence.Russia;

  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    setYearSelected(e.target.value);
  };

  const handleAccountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAcctSelected(e.target.value);
  };

  const isCreateDisabled =
    years.length === 0 ||
    isLoadingAcceptTerms ||
    isLoading ||
    !stateData?.termsAccepted;

  const handleRequest = async () => {
    let result:
      | TRequestReportResponse
      | TAcceptPolTimingsResponse
      | TCheckPolFundsResponse
      | null = { success: true, message: '' };

    setIsRequestLoading(true);

    if (isRus) {
      result = await reportsService.requestRusReport({
        lang: currentLanguage,
        year: yearSelected,
      });
    } else {
      // accept timings
      if (!stateData?.timingsAccepted) {
        result = await reportsService.acceptPolTimings(currentLanguage);
      }

      if (result?.success) {
        result = await reportsService.requestPolReport({
          account: acctSelected,
          year: yearSelected,
        });
      }
    }

    if (result?.success) {
      setOpenRequest(false);
      updateReports();
      setYearsRequested((yearsReq) => [...yearsReq, Number(yearSelected)]);
      notifyWith.success(result.message);
    }

    setIsRequestLoading(false);
  };

  const handleOpenClick = () => {
    setOpenRequest(false);
  };

  const handleCloseClick = () => {
    setOpenRequest(true);
  };

  useEffect(() => {
    if (openRequest) {
      checkFunds();
    }
  }, [yearSelected, acctSelected, openRequest]);

  useEffect(() => {
    setYearSelected(yearOptions[0]?.value);
  }, [yearOptions]);

  return (
    <ActionWithConfirmation
      content={
        <StyledRequestReportWrapper className="RequestReportWrapper">
          <StyledRequestReportHeading className="RequestReportHeading">
            {t(reportTypeTitle)}
          </StyledRequestReportHeading>
          <StyledSelectionPanel className="SelectionPanel">
            <StyledSelectWrapper className="SelectWrapper" ref={refYear}>
              <Select
                fullWidth
                placeholder={t('generic__year')}
                value={yearSelected}
                options={yearOptions}
                disabled={isRequestLoading || isFundsChecking}
                showSkeleton={isFundsChecking}
                onChange={handleYearChange}
                menuProps={{
                  disablePortal: true,
                  anchorEl: getFloatingMenuPositioningHack(refYear),
                  disableScrollLock: true,
                }}
              />
            </StyledSelectWrapper>
            {!isRus && (
              <StyledSelectWrapper className="SelectWrapper" ref={refAccount}>
                <StyledAccountSelect
                  fullWidth
                  placeholder={t('reports__tax__table__account')}
                  value={acctSelected}
                  options={acctOptions}
                  disabled={isRequestLoading || isFundsChecking}
                  showSkeleton={isFundsChecking}
                  onChange={handleAccountChange}
                  autoFocus
                  menuProps={{
                    disablePortal: true,
                    anchorEl: getFloatingMenuPositioningHack(refAccount),
                    disableScrollLock: true,
                  }}
                />
              </StyledSelectWrapper>
            )}
          </StyledSelectionPanel>
          <StyledRequestReportText className="RequestReportText">
            {stateData?.reportPrice == null
              ? t(
                  'Request the cost of the report preparation? This may take some time.',
                )
              : `${t('layout__reports__tax__service_fee_charge', {
                  FEE: stateData?.reportPrice,
                })}\n${t(
                  'The report will be requested, preparation may take 6-8 weeks.',
                )}`}
          </StyledRequestReportText>
          <StyledRequestReportFooter className="RequestReportFooter">
            <StyledActionButton
              type="submit"
              color={isRequestDisabled ? 'ghost' : 'action'}
              className="RequestActionButton"
              onClick={handleRequest}
              disabled={isRequestDisabled}
            >
              {isRequestLoading && <Loader />}
              {t('layout__reports__tax__request_report')}
            </StyledActionButton>
            <StyledActionButton
              type="reset"
              color={isRequestLoading ? 'ghost' : 'secondary'}
              className="CancelActionButton"
              onClick={handleOpenClick}
              disabled={isRequestLoading}
            >
              {t('generic__action__cancel')}
            </StyledActionButton>
          </StyledRequestReportFooter>
        </StyledRequestReportWrapper>
      }
      openFromExternal
      externalOpened={openRequest}
      closeHandler={handleOpenClick}
      placement="bottom-end"
      disabled={isCreateDisabled}
      hideDefaultButtons
      noDismiss={isRequestLoading}
    >
      <StyledCreateButton
        iconName="AddIcon"
        iconSize={24}
        iconColor="action"
        label={t('layout__reports__tax__create_report')}
        type="submit"
        disabled={isCreateDisabled}
        onClick={handleCloseClick}
        className="CreateButton"
      />
    </ActionWithConfirmation>
  );
};
