import { Input } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { MarginTradingButton } from '../AccountsTable/components';

export const StyledMobileAccountSetting = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledMobileAccountSettingHeader = styled('h3')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'liga' off;
  font-family: ${({ theme }) => theme.font.header};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  margin: 0;
`;

export const StyledMobileAccountSettingRow = styled('div')`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .TooltipTextWrapper:nth-child(1) {
    flex: 1;
  }
`;

export const StyledMarginTradingButtonDecorator = styled(Input)`
  .MuiInputBase-input {
    padding-left: 60px;
    padding-top: 10px;
    padding-bottom: 10px;

    &:disabled {
      color: ${({ theme }) => theme.color.typo.primary} !important;
      -webkit-text-fill-color: ${({ theme }) =>
        theme.color.typo.primary} !important;
    }
  }
`;

export const StyledMarginTradingButton = styled(MarginTradingButton)`
  position: absolute;
  left: 12px;
  top: 12px;
`;
