import { useEffect, useMemo } from 'react';
import { useData } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import {
  TUseExchangeCrossratesProps,
  TUseExchangeCrossratesValue,
} from 'pages/Portfolio/tabs/Exchange/contexts/ExchangeContext/hooks/useExchangeCrossrates/useExchangeCrossrates.types';
import { exchangeService, TExchangeCrossRates } from 'services/exchange';

export const useExchangeCrossrates = ({
  currency,
  targetCurrency,
}: TUseExchangeCrossratesProps): TUseExchangeCrossratesValue => {
  const {
    data: crossrates,
    isLoading: loading,
    fetchData: updateCrossrates,
  } = useData<TExchangeCrossRates | null>({
    onFetch: () => exchangeService.fetchCrossrates(),
  });

  const crossrate = useMemo<string | null>(() => {
    if (
      !crossrates ||
      !currency ||
      !targetCurrency ||
      !(targetCurrency in crossrates) ||
      !(currency in crossrates)
    ) {
      return DEFAULT_EMPTY_PLACEHOLDER;
    }

    return (
      Number(crossrates[targetCurrency]) / Number(crossrates[currency])
    ).toFixed(4);
  }, [crossrates, currency, targetCurrency]);

  useEffect(() => {
    const crossratesUpdater = setInterval(updateCrossrates, 15 * 1000);
    updateCrossrates();

    return () => clearInterval(crossratesUpdater);
  }, []);

  return useMemo<TUseExchangeCrossratesValue>(
    () => ({
      loadingCrossrates: loading,
      crossrate,
      crossrates,
    }),
    [loading, crossrate, crossrates],
  );
};
