import { TExtendedColumnWithAdditionalFields } from 'react-ui-kit-exante';

import { TClientsareaTheme } from 'theme';
import { TPosition } from 'types/accounts';

export function getFooterPropsWithTotal(
  column: TExtendedColumnWithAdditionalFields<TPosition>,
  theme: TClientsareaTheme,
) {
  // TODO Moved from run-ui as is. Fix types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (column.filteredRows?.length < 2) {
    return {};
  }

  return {
    style: {
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: 600,
      backgroundColor: theme.color.table.total.bg,
      color: theme.color.table.total.color,
    },
  };
}
