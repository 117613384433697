import cn from 'classnames';
import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { StyledToMyAccountInternalNoteWrapper } from './ToMyAccountInternalNote.styled';
import { TToMyAccountInternalNoteProps } from './ToMyAccountInternalNote.types';

export const ToMyAccountInternalNote: FC<TToMyAccountInternalNoteProps> = ({
  loading,
  className,
  children,
}) => (
  <StyledToMyAccountInternalNoteWrapper
    className={cn('ToMyAccountInternalNoteWrapper', className)}
  >
    {loading ? (
      <>
        <Skeleton width={24} height={24} />
        <Skeleton width="100%" height={100} />{' '}
      </>
    ) : (
      children
    )}
  </StyledToMyAccountInternalNoteWrapper>
);
