import { TPartnerLink } from 'services/partner';

export const PARTNER_EMPTY_EDIT_LINK: Readonly<TPartnerLink> = {
  id: 0,
  site_url: '',
  url: '',
  name: '',
  created: '',
  updated: '',
  is_default: false,
  visit_count: null,
};
