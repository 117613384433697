import { AxiosError } from 'axios';
import { useContext, useEffect, useReducer } from 'react';

import { TOOMANY_ERROR } from 'constants/api';
import { RESEND_CODE_TIME } from 'constants/index';
import { TranslationContext } from 'contexts';
import { isCodeValid } from 'helpers/string';
import { useTimer } from 'hooks';
import { mfaService } from 'services/mfa';

import { DisableByCodeActions } from './useDisableByCode.actions';
import { disableByCodeInitialState } from './useDisableByCode.constants';
import { useDisableByCodeReducer } from './useDisableByCode.reducer';
import {
  TDisableByCodeOptions,
  TDisableByCodeReducer,
} from './useDisableByCode.types';

export const useDisableByCode = (
  codeLength: number,
  key: string,
  onSuccess: () => void,
  options?: TDisableByCodeOptions,
) => {
  const timer = useTimer(RESEND_CODE_TIME, { startOnInit: false });
  const { t } = useContext(TranslationContext);
  const [state, dispatch] = useReducer<TDisableByCodeReducer>(
    useDisableByCodeReducer,
    disableByCodeInitialState,
  );

  const handleCodeChange = (payload: string) => {
    if (isCodeValid(payload, codeLength)) {
      dispatch({ type: DisableByCodeActions.OnCodeChange, payload });
    }
  };

  const handleResend = async () => {
    timer.start();
    dispatch({ type: DisableByCodeActions.RequestStart });

    try {
      await mfaService.resendByKey(key);
      dispatch({ type: DisableByCodeActions.RequestSuccess });
    } catch (error) {
      const { response } = error as AxiosError;

      let payload =
        response?.data.detail ||
        t('layout__actions__unknown_error_please_refresh_page');

      if (response?.status === TOOMANY_ERROR) {
        payload = t('layout__settings__mfa__resend_429');
      }

      dispatch({ type: DisableByCodeActions.RequestError, payload });
    }
  };

  const handleVerify = async () => {
    dispatch({ type: DisableByCodeActions.VerifyStart });

    try {
      await mfaService.disableByKey(state.code, key, 'site');

      dispatch({ type: DisableByCodeActions.VerifySuccess });
      onSuccess();
    } catch (error) {
      dispatch({
        type: DisableByCodeActions.VerifyError,
        payload: t('layout__code_expired'),
      });
    }
  };

  useEffect(() => {
    if (!state.isVerifying && state.code.length === codeLength) {
      handleVerify();
    }
  }, [state.code]);

  useEffect(() => {
    if (!options?.disableTimer) {
      timer.start();
    }
  }, []);

  return {
    ...state,
    seconds: timer.time,
    handleCodeChange,
    handleResend,
  };
};
