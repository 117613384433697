import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledChallengeAccountSummaryBalance = styled(Panel)`
  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding-right: 28%;
  }
`;

export const StyledChallengeAccountSummaryBalanceTitle = styled('h3')`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0 0 20px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;

  .ChallengeAccountSummaryBalanceTooltip {
    color: ${({ theme }) => theme.color.icon.action};
  }
`;

export const StyledChartWrapper = styled('div')`
  width: 100%;
  height: 400px;
  font-size: 12px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .recharts-cartesian-grid {
    line {
      stroke: ${({ theme }) => theme.color.line.primary};
    }
  }
  .recharts-cartesian-axis {
    line {
      stroke: ${({ theme }) => theme.color.bg.promo};
    }
  }
`;
