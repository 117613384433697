import { FC } from 'react';

import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { StyledPriceCell } from 'pages/Subscriptions/Subscriptions.styled';

import { TSubscriptionPriceCellProps } from './SubscriptionPriceCell.types';

export const SubscriptionPriceCell: FC<TSubscriptionPriceCellProps> = ({
  feedPermission,
}) => {
  const currencyFormatter = useCurrencyFormatter(feedPermission.currency);

  if (feedPermission.price === 'N/A') {
    return (
      <StyledPriceCell className="PriceCell">
        {feedPermission.price}
      </StyledPriceCell>
    );
  }

  const price = formatCurrency(
    currencyFormatter,
    feedPermission.feedType === 'retail'
      ? feedPermission.retailFeedPrice
      : feedPermission.professionalFeedPrice,
  );

  return <span title={price}>{price}</span>;
};
