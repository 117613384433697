import {
  TBrandingDownloadPagePlatform,
  TBrandingDownloadPagePlatformButton,
} from 'services/branding';

export const getConditionalCaption = (
  platform: TBrandingDownloadPagePlatform,
  os: string | undefined,
): string => {
  const defaultCaption = platform.caption;
  const possibleCaptions = platform.caption_conditional;

  if (!possibleCaptions) {
    return defaultCaption || '';
  }

  const overrideCaption = possibleCaptions.find(
    ({ show_condition: requiredOs }) => requiredOs === os,
  );

  return overrideCaption ? overrideCaption.caption : defaultCaption || '';
};

export const getConditionalButtons = (
  platform: TBrandingDownloadPagePlatform,
  os: string | undefined,
): TBrandingDownloadPagePlatformButton[] => {
  return (
    platform.buttons?.filter(
      ({ show_condition: requiredOs }) => !requiredOs || requiredOs === os,
    ) || []
  );
};

export const getPlatformsWithUrls = (
  platforms: TBrandingDownloadPagePlatform[],
): TBrandingDownloadPagePlatform[] => {
  const platformsWithUrls: TBrandingDownloadPagePlatform[] = [];
  platforms.forEach((platform) => {
    const filteredPlatform = {
      ...platform,
      buttons: platform.buttons?.filter((button) => button.anchor_url) || [],
    };

    if (filteredPlatform.buttons.length > 0) {
      platformsWithUrls.push(filteredPlatform);
    }
  });

  return platformsWithUrls;
};
