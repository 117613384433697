import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  calculateCountOfPages,
  IOnFetchArguments,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { REFETCH_STATUS } from 'constants/api';
import { DEFAULT_MAX_PAGINATION_LENGTH } from 'constants/common';
import { TranslationContext } from 'contexts';
import { prepareTableId } from 'helpers/prepareTableId';
import { prepareToQueryParams } from 'helpers/prepareToQueryParams';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import {
  setInterestsEmpty,
  setInterestsLoading,
  useTermsContext,
} from 'pages/Terms/context';
import { interestsService } from 'services/interests';

import { TInterestsTableProps } from './InterestsTable.types';
import { columnKeys, getColumns } from './columns';

const tableId = prepareTableId('clientsarea-interests');

export const InterestsTable: FC<TInterestsTableProps> = ({
  accountId,
  isLoading,
}) => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);
  const currencyFormatter = useCurrencyFormatter();

  const termsContext = useTermsContext();

  const fetchInterests = useCallback(
    async ({ params }: IOnFetchArguments) => {
      termsContext.dispatch(setInterestsLoading(true));
      if (!accountId) {
        return {
          data: [],
          total: 0,
        };
      }

      const { sorting, limit, skip } = params;

      const preparedParams = prepareToQueryParams({
        accountId: String(accountId),
        sorting,
        columnKeys: [...columnKeys],
        limit,
        skip,
      });

      const interests = await interestsService.getInterests(
        preparedParams,
        currentLanguage,
      );

      termsContext.dispatch(setInterestsLoading(false));
      termsContext.dispatch(setInterestsEmpty(!interests?.interests.length));

      if (!interests) {
        return null;
      }

      if (interests?.status === REFETCH_STATUS) {
        termsContext.dispatch(setInterestsLoading(true));
        return null;
      }

      const { interests: data, iTotalRecords: total } = interests;

      const fixedNumbersData = data.map((el) => {
        const newEl = { ...el };

        const cutMantissa = (str: string): string => {
          const parts = str.split('.');
          parts[1] = parts[1].substring(0, 5).replace(/0+$/, '');

          if (!parts[1].length) {
            return parts[0];
          }

          return parts.join('.');
        };

        if (newEl.negative.includes('.')) {
          newEl.negative = cutMantissa(newEl.negative);
        }

        if (newEl.positive.includes('.')) {
          newEl.positive = cutMantissa(newEl.positive);
        }

        return newEl;
      });

      return {
        data: fixedNumbersData,
        total,
      };
    },
    [accountId],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId,
      data: { onFetch: fetchInterests },
      pagination: {
        getDefaultPagination: () => ({
          limit: DEFAULT_MAX_PAGINATION_LENGTH,
          skip: 0,
        }),
      },
    }),
    [fetchInterests],
  );

  const {
    data: rawData,
    limit,
    setLimit,
    setPage,
    page,
    setSorting,
  } = useTableData(tableDataArgs);

  const columns = useMemo(
    () => getColumns({ t, currencyFormatter }),
    [isTranslationsLoading, t],
  );

  const { data: interests = [], total } = rawData || {};
  const tableIsLoading =
    !accountId || isLoading || termsContext.state.isInterestsLoading;

  useEffect(() => {
    return () => {
      termsContext.dispatch(setInterestsLoading(false));
    };
  }, []);

  const data = useMemo(() => interests || [], [interests]);

  return (
    <Table
      tableId={tableId}
      columns={columns}
      data={data}
      isLoading={tableIsLoading}
      translator={t}
      locale={currentLanguage}
      isFlexLayout
      onSort={setSorting}
      manualSortBy
      hasPagination
      defaultSortBy={[{ id: 'currency', desc: false }]}
      skeletonsCount={10}
      pageSize={limit}
      serverPaginationProps={{
        pageSize: limit,
        setPage,
        setPageSize: setLimit,
        pageIndex: page,
        total: total ?? 0,
        pageCount: calculateCountOfPages(total ?? 0, limit),
      }}
    />
  );
};
