import { Link } from 'react-router-dom';

import { styled } from 'theme';

export const StyledSourceItemContainer = styled(Link)`
  display: grid;
  grid-template-columns: 240px 1fr;
  text-decoration: none;
  min-height: 56px;
  padding: 8px 24px;

  &:nth-child(odd) {
    background-color: ${({ theme }) => {
      return theme.color.input.bg.hover;
    }};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;

    & > div:first-child {
      & span {
        font-size: 20px;
      }
    }

    &:nth-child(odd) {
      background-color: inherit;
    }

    &:not(:last-child) {
      border-bottom: 1px solid
        ${({ theme }) => {
          return theme.color.line.primary;
        }};
    }
  }
`;

export const StyledSourceItemValue = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  }
`;
