import { styled } from 'theme';

export const StyledCardSettingsListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px;
`;

export const StyledCardsCardActiveHint = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;
