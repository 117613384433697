import { useContext, useMemo } from 'react';

import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';

import { getScopesString } from './SourceItem.helpers';
import {
  StyledSourceItemContainer,
  StyledSourceItemValue,
} from './SourceItem.styled';
import { TSourceItemProps } from './SourceItem.types';

export const SourceItem = ({ value }: TSourceItemProps) => {
  const { t } = useContext(TranslationContext);

  const scopes = useMemo(() => {
    return getScopesString(value.scopes);
  }, [value.scopes]);

  return (
    <StyledSourceItemContainer to={value.id}>
      <Text weight="500">{value.description}</Text>
      <StyledSourceItemValue className="SourceItemValue">
        <Text weight="500">{scopes}</Text>
        <Text color="secondary" size="13px">
          {t('layout__settings__api__key_permissions')}
        </Text>
      </StyledSourceItemValue>
    </StyledSourceItemContainer>
  );
};
