import { createElement, FC, useContext, useMemo } from 'react';

import { CheckoutDepositConformIcon } from 'assets/icons';
import { TranslationContext } from 'contexts';
import { useQuery } from 'hooks/index';
import { PATHS } from 'router';

import {
  StyledCheckoutButton,
  StyledCheckoutStatus,
  StyledCheckoutTitle,
  StyledCheckoutWrapper,
} from '../Checkout.styled';

export const CheckoutSuccess: FC = () => {
  const { t } = useContext(TranslationContext);
  const query = useQuery();
  const accountId = query.get('account_id');

  const depositHref = useMemo(() => {
    if (accountId) {
      return `${PATHS.CHECKOUT_DEPOSIT}?account_id=${accountId}`;
    }
    return PATHS.CHECKOUT_DEPOSIT;
  }, [accountId]);

  return (
    <StyledCheckoutWrapper className="CheckoutWrapper">
      <StyledCheckoutStatus className="CheckoutStatus">
        {createElement(CheckoutDepositConformIcon)}

        <StyledCheckoutTitle className="CheckoutTitle">
          {t('checkout__success__title')}
        </StyledCheckoutTitle>
        <p>{t('checkout__success__message')}</p>
      </StyledCheckoutStatus>

      <StyledCheckoutButton href={depositHref} fullWidth>
        {t('checkout__success__ok_btn')}
      </StyledCheckoutButton>
    </StyledCheckoutWrapper>
  );
};
