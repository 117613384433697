import { FC, useContext } from 'react';

import { StyledHeaderCustomButton } from 'components/Header/components/HeaderCustomButton/HeaderCustomButton.styled';
import { BrandingContext, TranslationContext } from 'contexts';

export const HeaderCustomButton: FC = () => {
  const { branding } = useContext(BrandingContext);
  const { t } = useContext(TranslationContext);

  const { text, link } = branding?.header_custom_button || {};

  const postfix: string = text?.replaceAll(' ', '-').toLowerCase() || '';

  return !(text && link) ? null : (
    <StyledHeaderCustomButton
      data-test-id={`header__custom-button__${postfix}`}
      className={`HeaderCustomButton HeaderCustomButton-${postfix}`}
      target="_blank"
      href={link}
    >
      {t(text)}
    </StyledHeaderCustomButton>
  );
};
