import { ErrorIcon, SpinnerIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledAnimateSpinnerIcon = styled(SpinnerIcon)`
  color: ${({ theme }) => theme.color.icon.warning};
  animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const StyledErrorIcon = styled(ErrorIcon)`
  color: ${({ theme }) => theme.color.icon.radical};
`;
