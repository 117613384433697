import cn from 'classnames';
import { FC, useContext, useMemo, useState } from 'react';
import { Modal, useModal } from 'react-ui-kit-exante';

import {
  BrandingContext,
  LegalDocsContext,
  TranslationContext,
} from 'contexts';

import { DocumentViewer } from './DocumentViewer';
import { TModalDoc } from './DocumentViewer/DocumentViewer.types';
import { FollowLinks } from './FollowLinks';
import { CURRENT_YEAR } from './Footer.consts';
import {
  StyledFooter,
  StyledFooterCnt,
  StyledFooterColumn,
  StyledFooterCopyright,
  StyledFooterHelp,
  StyledFooterItem,
  StyledFooterLegal,
  StyledFooterLinks,
  StyledFooterLinksItem,
  StyledFooterRestricted,
  StyledFooterVersion,
  StyledLinkItem,
} from './Footer.styled';
import { Doc } from './Footer.types';
import { Links } from './Links';

const IS_BOT = () => {
  const botRegex = /bot|slurp|crawler|spider|robot|crawling/i;
  return navigator.userAgent && botRegex.test(navigator.userAgent);
};

export const Footer: FC = () => {
  const docModal = useModal();
  const legalDocs = useContext(LegalDocsContext);
  const { branding } = useContext(BrandingContext);
  const { t } = useContext(TranslationContext);

  const [openedDoc, setOpenedDoc] = useState<TModalDoc | null>(null);

  const isSocialLinksExistAndEnabled = useMemo(() => {
    return (
      typeof branding?.follow_us_links === 'object' &&
      branding?.follow_us_links !== null &&
      Object.values(branding?.follow_us_links || {}).some((e) => e?.length)
    );
  }, [branding]);

  const isDownloadLinksExistAndEnabled = useMemo(() => {
    const atp = branding?.atp || {};
    return (
      branding?.branding?.show_mobile_apps_footer ||
      (Object.keys(atp.links || {}).length && atp.is_desktop)
    );
  }, [branding]);

  const downloadAndFollowDataExists =
    isSocialLinksExistAndEnabled || isDownloadLinksExistAndEnabled;

  const safeLeAdapters = legalDocs?.data?.['legal-docs']?.le_adapters || {};

  const legalEntities = (): Record<string, any> => {
    const brandingTexts: Record<
      string,
      Record<string, any>
    > = branding?.le_legal_texts || {};

    const entities = [
      ...Object.keys(safeLeAdapters),
      ...Object.keys(brandingTexts),
    ];

    return entities.reduce<any>((res, entity) => {
      const data = safeLeAdapters[entity];

      const brandingEntity =
        typeof brandingTexts[entity] === 'object' ? brandingTexts[entity] : {};
      const footerTexts =
        typeof brandingEntity.footer === 'object' ? brandingEntity.footer : {};
      const getAndFixFooterStr = (val: string) => {
        return (
          (typeof footerTexts[val] === 'string' &&
            footerTexts[val].replace(/\r\n/g, '<br>')) ||
          ''
        );
      };

      const docs = (data && data.filtered_docs) || [];
      const text = getAndFixFooterStr('text');
      const restriction = getAndFixFooterStr('restriction');

      if (docs.length || text || restriction) {
        res[entity] = {
          docs: (data && data.filtered_docs) || [],
          text: getAndFixFooterStr('text'),
          restriction: getAndFixFooterStr('restriction'),
        };
      }

      return res;
    }, {});
  };

  const link = (doc: Doc) => {
    // eslint-disable-next-line no-script-url
    return doc.is_file ? `${doc.open_url}?raw=` : 'javascript:void(0);';
  };

  const hc = () => {
    const footerContactText =
      branding?.legal_texts?.footer?.footer_contact_text;

    if (footerContactText) {
      return footerContactText;
    }

    return (
      t('layout__footer__help_center').replace(
        /hc/gi,
        branding?.help_center || '',
      ) + (branding?.legal_texts?.contact || '')
    );
  };

  const onOpenModal = (doc: Doc) => {
    setOpenedDoc({
      dataUrl: doc.data_url,
      isSigned: doc.is_signed,
      title: doc.title,
    });
    docModal.onOpen();
  };

  const onCloseModal = () => {
    setOpenedDoc(null);
    docModal.onClose();
  };

  return (
    <StyledFooter className="footer">
      <StyledFooterCnt
        downloadAndFollowDataExists
        className={cn(
          'footer__cnt',
          !downloadAndFollowDataExists && 'footer__cnt--one-col',
        )}
      >
        {downloadAndFollowDataExists && (
          <StyledFooterColumn className="footer__column">
            {isDownloadLinksExistAndEnabled && branding?.atp && (
              <Links atp={branding.atp} />
            )}
            {isSocialLinksExistAndEnabled && (
              <FollowLinks followUsLinks={branding?.follow_us_links} />
            )}
          </StyledFooterColumn>
        )}

        <StyledFooterColumn className="footer__column">
          {branding?.help_center && (
            <StyledFooterHelp
              className="footer__help"
              dangerouslySetInnerHTML={{ __html: hc() }}
            />
          )}
          {Object.keys(legalEntities()).map((key: string, index: number) => {
            const { text, restriction, docs } = legalEntities()[key];

            return (
              <StyledFooterItem className="footer__item" key={index}>
                {text && (
                  <StyledFooterLegal
                    className="footer__legal"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                )}

                {restriction && (
                  <StyledFooterRestricted
                    className="footer__restricted"
                    dangerouslySetInnerHTML={{ __html: restriction }}
                  />
                )}

                {!legalDocs?.isLoading && (
                  <StyledFooterLinks className="footer__links">
                    {docs.map((doc: Doc) => (
                      <StyledFooterLinksItem
                        key={doc.id}
                        className="footer__links-item"
                      >
                        <StyledLinkItem
                          className="LinkItem"
                          href={link(doc)}
                          target="_blank"
                          onClick={(e) => {
                            e.preventDefault();
                            onOpenModal(doc);
                          }}
                          rel="noreferrer"
                        >
                          {doc.title}
                        </StyledLinkItem>
                      </StyledFooterLinksItem>
                    ))}
                  </StyledFooterLinks>
                )}
              </StyledFooterItem>
            );
          })}

          {branding?.wl_contact_us && (
            <StyledFooterLinks className="footer__links">
              <StyledFooterLinksItem className="FooterLinksItem">
                <StyledLinkItem
                  className="LinkItem"
                  href={`${branding?.wl_contact_us}`}
                >
                  {t('layout__footer__contact_us')}
                </StyledLinkItem>
              </StyledFooterLinksItem>
            </StyledFooterLinks>
          )}
          {branding?.legal_texts?.name && (
            <StyledFooterCopyright className="footer__copyright">
              Copyright {CURRENT_YEAR} {branding.legal_texts.name}{' '}
              {t('layout__footer__all_rights_reserved')}
            </StyledFooterCopyright>
          )}
          {!IS_BOT() && (
            <StyledFooterVersion className="footer__version">
              {t('generic__version')} {process?.env?.REACT_APP_VERSION}
            </StyledFooterVersion>
          )}
        </StyledFooterColumn>
      </StyledFooterCnt>
      <Modal
        title={openedDoc?.title || ''}
        isOpened={Boolean(docModal.isOpened && openedDoc)}
        onClose={onCloseModal}
        cancelButton={{
          cancelButtonName: t('generic__action__cancel'),
        }}
        maxWidth="lg"
        disableContentPaddings
        fullWidth
      >
        {openedDoc && <DocumentViewer doc={openedDoc} />}
      </Modal>
    </StyledFooter>
  );
};
