import { FC } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { PATHS } from 'router';

import { TReportEditProps } from './ReportEdit.types';
import { ReportEditForm } from './components';
import { ReportFormProvider } from './components/ReportEditForm/contexts/ReportFormContext';

export const ReportEdit: FC<TReportEditProps> = ({ isNew }) => {
  const navigate = useNavigate();

  const listUrl = `${PATHS.ROOT}${PATHS.CUSTOM_REPORTS}`;
  const createUrl = `${PATHS.ROOT}${PATHS.CUSTOM_REPORTS_CREATE}`;

  const params = new URLSearchParams(window.location.search);
  const idParam = params.get('id');
  const reportId = idParam != null ? Number(idParam) : undefined;

  // 'edit' action is impossible, fallback to 'create'
  if (!isNew && reportId === null) {
    // react-router does not update the url on navigate
    window.history.replaceState({}, '', createUrl);

    // redirect
    return <Navigate to={createUrl} />;
  }

  const handleSave = () => {
    navigate(listUrl);
  };

  return (
    <ReportFormProvider reportId={reportId} onSave={handleSave}>
      <ReportEditForm isNew={isNew} />
    </ReportFormProvider>
  );
};
