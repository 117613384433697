import { InstrumentsTree } from 'modules/instrumentsTree/InstrumentsTree';
import { TTreeModule } from 'services/instrumentsTree/instrumentsTree.types';

import { getColumns } from './columns';

export const ShortAllowanceStatus = () => {
  return (
    <InstrumentsTree module={TTreeModule.PERMISSIONS} getColumns={getColumns} />
  );
};
