import { styled } from 'theme';

export const StyledTermsFeeNoticeContainer = styled('div')`
  padding: 0 0 24px;
`;

export const StyledTermsFeeNoticeContent = styled('div')`
  padding: 0 24px;
  font-size: 15px;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.typo.action};
  }
`;

export const StyledNoticeSkeletonContainer = styled('div')`
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

export const StyledNoticeSkeletonRow = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
