export const CORPORATE_ACTIONS_LIST_DISPLAYED_COLUMNS = {
  bo_symbol: 'generic__symbol',
  isin: 'generic__isin',
  sedol: 'generic__sedol',
  country_of_incr: 'generic__country_of_incorporation',
  announce_date: 'generic__announce_date',
  record_date: 'generic__record_date',
  effective_date: 'generic__effective_date',
  pay_date: 'generic__pay_date',
  gross_amount: 'generic__gross_amount',
  currency: 'generic__currency',
};
