import { initialFetchState } from 'constants/state';

import { TCommissionsState } from './types';

export const commissionsInitialState: TCommissionsState = {
  fetching: initialFetchState,
  serviceFees: null,
  otherServiceFees: null,
  crossrates: null,
  fixedCurrency: false,
  currency: '',
};
