import { MfaTooltipState } from '../../../../../../../Security/pages/Mfa/Mfa.constants';

import { TUseConfirmationData } from './useConfirmation.types';

export const useConfirmationInitData: Record<
  MfaTooltipState,
  TUseConfirmationData
> = {
  [MfaTooltipState.Sms]: {
    code: '',
    error: '',
    isCodeSent: false,
  },
  [MfaTooltipState.Email]: {
    code: '',
    error: '',
    isCodeSent: false,
  },
  [MfaTooltipState.Totp]: {
    code: '',
    error: '',
    isCodeSent: false,
  },
};
