import { Notification } from 'react-ui-kit-exante';

import { NOTIFICATION } from 'constants/NOTIFICATION';
import { i18nLocal } from 'helpers/translate';

export const error = (messageKey: string) => {
  const translation = i18nLocal.translate([
    messageKey,
    NOTIFICATION.ERROR.GENERIC.MESSAGE,
  ]);

  Notification.error({ title: translation });
};
