import { TCashCovnersionState } from './CashConversionContext.types';

export const initialState: TCashCovnersionState = {
  isLoading: false,
  isCurrencyUpdating: false,
  isAccountAllowed: false,
  currency: null,
  currencies: [],
  setCurrency() {},
};
