import { ChangeEvent, FC, useContext, useMemo } from 'react';
import { ISelectOption, useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { accountSettingsService } from 'services/settings/accountSettings';

import { AccountsTableContext } from '../../../../contexts';
import {
  StyledOptionDescription,
  StyledSelect,
} from '../../AccountsTable.styled';

import { TSelectAccountForAutoDebitProps } from './SelectAccountForAutoDebit.types';

export const SelectAccountForAutoDebit: FC<TSelectAccountForAutoDebitProps> = ({
  accountId,
  autodebitAccountId,
  isAutodebitEditable,
}) => {
  const { autoDebitData, setPaymentData, accountsList, accountDescriptions } =
    useContext(AccountsTableContext);
  const { t, currentLanguage } = useContext(TranslationContext);

  const accountsForAutoDebit = useMemo<ISelectOption[]>(
    () => [
      {
        value: 'disabled',
        label: t('generic__disabled'),
      },
      ...autoDebitData.choices.map<ISelectOption>((accountName) => {
        const description = accountDescriptions[accountName]?.description || '';

        return {
          value: accountName,
          label: (
            <div className="OptionDescriptionWrapper">
              {accountName}
              <StyledOptionDescription className="OptionDescription">
                {description}
              </StyledOptionDescription>
            </div>
          ),
        };
      }),
    ],
    [accountsList, autoDebitData],
  );

  const { fetchData: selectAutoDebitAccount, isLoading: savingChanges } =
    useData({
      onFetch: async (e: ChangeEvent<HTMLInputElement>) => {
        const accountForAutoDebit = e.target.value;

        return accountSettingsService.changeAutoDebitAccount(
          accountId,
          accountForAutoDebit,
          currentLanguage,
        );
      },
      onSuccess: (newPaymentData) => {
        if (newPaymentData) {
          setPaymentData(accountId, newPaymentData);
        }
      },
      loading: false,
    });

  return (
    <StyledSelect
      className="AccountForAutodebitSelector"
      disabled={savingChanges || !isAutodebitEditable}
      options={accountsForAutoDebit}
      value={autodebitAccountId || 'disabled'}
      placeholder={t('generic__account')}
      onChange={selectAutoDebitAccount}
      fullWidth
    />
  );
};
