import cn from 'classnames';
import { FC } from 'react';

import { Loader } from 'components/Loader';

import { StyledXntAnchor, StyledXntButton } from '../Anchor/Anchor.styled';
import { AnchorProps } from '../Anchor/Anchor.types';

const LOADER_VIEWS_MAP = {
  default: 'primary',
  raised: 'raised',
  radical: 'radical',
};

export const AnchorNative: FC<AnchorProps> = ({
  isButtonLike,
  size = 'medium',
  icon,
  buttonView = 'default',
  loading,
  href,
  target,
  label,
  className,
  dataTestId = '',
}) => {
  const classes = isButtonLike
    ? {
        'xnt-button xnt-button--default': true,
        [`xnt-button--${size}`]: size,
        [`xnt-button--${buttonView}`]: buttonView,
        'xnt-button--loading': loading,
        'xnt-button--with-icon': icon,
        'xnt-button--icon-only': !label,
      }
    : {
        'xnt-anchor': true,
        [`xnt-anchor--${size}`]: size,
        'xnt-anchor--with-icon': icon,
        'xnt-anchor--icon-only': !label,
      };

  const shouldShowLoader = Object.keys(LOADER_VIEWS_MAP).includes(buttonView);

  const LinkComponent = isButtonLike ? StyledXntButton : StyledXntAnchor;
  return (
    <LinkComponent
      className={cn(className, classes)}
      href={href}
      target={target}
      data-test-id={dataTestId}
      color="secondary"
      size={size}
    >
      {loading && (
        <>
          {shouldShowLoader && <Loader isCentered isInner />}
          <span className="xnt-anchor__desc xnt-anchor__desc--visually-hidden">
            Loading
          </span>
        </>
      )}
      {!loading && icon}
      <span className="xnt-anchor__desc">{label}</span>
    </LinkComponent>
  );
};
