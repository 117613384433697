import { styled } from 'theme';

export const StyledRadioButtonsContainer = styled('div')`
  display: flex;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    flex: 1 1 auto;
  }
`;

export const StyledRadioButton = styled('button')<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  color: ${({ active, theme }) =>
    active ? theme.color.typo.primary : theme.color.typo.secondary};
  background-color: ${({ active, theme }) =>
    active ? theme.color.bg.secondary : 'transparent'};
  border: 1px solid ${({ theme }) => theme.color.input.border};
  outline: 0;
  cursor: pointer;
  padding: 0 16px;
  border-radius: 4px;
`;

export const StyledRadioButtonsTitle = styled('span')`
  display: flex;
  white-space: nowrap;
  font-size: 15px;
`;
