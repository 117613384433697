import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Input, Skeleton } from 'react-ui-kit-exante';

import { CurrencySelect } from 'components/CurrencySelect';
import { CurrencyContext, TranslationContext } from 'contexts';

import { EXCHANGE_MIN_TRANSFER_VALUE, ExchangeContext } from '../../contexts';

import {
  getAvailableText,
  validateAndUpdateAmount,
} from './ExchangeAmountBlock.helpers';
import {
  StyledExchangeAmountBlock,
  StyledExchangeAmountFields,
  StyledExchangeAmountSelectWrapper,
  StyledExchangeAmountSwapButton,
  StyledExchangeAmountTitle,
} from './ExchangeAmountBlock.styled';

export const ExchangeAmountBlock: FC = () => {
  const { t } = useContext(TranslationContext);
  const {
    loading,
    accountId,
    limit,
    amount,
    updateAmount,
    expectedAmount,
    updateExpectedAmount,
    currencies,
    currency,
    updateCurrency,
    targetCurrency,
    updateTargetCurrency,
    targetCurrencies,
    lastConversionId,
  } = useContext(ExchangeContext);

  const { currencies: currenciesInfo } = useContext(CurrencyContext);

  const currencyIcons = currenciesInfo?.icons || {};

  const [localAmount, setLocalAmount] = useState<string>(
    amount ? String(amount) : '',
  );
  const [localExpectedAmount, setLocalExpectedAmount] = useState<string>(
    expectedAmount ? String(expectedAmount) : '',
  );

  const onAmountUpdate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      validateAndUpdateAmount(e.target.value, setLocalAmount, updateAmount);
    },
    [setLocalAmount, updateAmount],
  );

  const onExpectedAmountUpdate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      validateAndUpdateAmount(
        e.target.value,
        setLocalExpectedAmount,
        updateExpectedAmount,
      );
    },
    [setLocalAmount, updateAmount],
  );

  const onCurrencyUpdate = (value: string) => {
    updateCurrency(value);
  };

  const onTargetCurrencyUpdate = (value: string) => {
    updateTargetCurrency(value);
  };

  const availableText = useMemo<string>(() => {
    return getAvailableText({
      limit,
      currency,
      amount,
      translateFunction: t,
    });
  }, [limit, currency, amount, t]);

  const isWrongAmount = useMemo<boolean>(() => {
    if (amount === null || limit === null) {
      return false;
    }

    return amount > limit || amount < EXCHANGE_MIN_TRANSFER_VALUE;
  }, [amount, limit]);

  const isSwapAvailable = useMemo<boolean>(() => {
    return Boolean(
      !loading &&
        targetCurrency &&
        currencies.includes(targetCurrency) &&
        currency &&
        targetCurrencies.includes(currency),
    );
  }, [loading, targetCurrency, currencies, currency, targetCurrencies]);

  const onSwap = useCallback(() => {
    if (isSwapAvailable && currency && targetCurrency) {
      updateCurrency(targetCurrency);
      updateTargetCurrency(currency);
    }
  }, [isSwapAvailable, currency, targetCurrency]);

  // reset amount
  useEffect(() => {
    updateAmount(null);
    updateExpectedAmount(null);
  }, [accountId, lastConversionId]);

  useEffect(() => {
    if (amount) {
      setLocalAmount(String(amount));
    } else {
      setLocalAmount('');
    }
  }, [amount, setLocalAmount]);

  useEffect(() => {
    if (expectedAmount) {
      setLocalExpectedAmount(String(expectedAmount));
    } else {
      setLocalExpectedAmount('');
    }
  }, [expectedAmount, setLocalExpectedAmount]);

  return (
    <StyledExchangeAmountBlock className="ExchangeAmountBlock">
      <StyledExchangeAmountTitle className="ExchangeAmountTitle">
        {t('layout__exchange__amount__title')}
      </StyledExchangeAmountTitle>
      {loading && !targetCurrency?.length ? (
        <Skeleton width="100%" height={60} />
      ) : (
        <StyledExchangeAmountFields className="ExchangeAmountFields">
          <Input
            className="ExchangeAmountInput"
            disabled={loading}
            value={localAmount ?? ''}
            error={isWrongAmount}
            placeholder={t('layout__exchange__amount__label')}
            onChange={onAmountUpdate}
            message={availableText}
            maxLength={16}
            fullWidth
          />
          <StyledExchangeAmountSelectWrapper className="ExchangeAmountSelectWrapper">
            <CurrencySelect
              className="ExchangeAmountTargetCurrencySelector"
              disabled={loading}
              options={currencies}
              icons={currencyIcons}
              value={currency}
              placeholder={t('layout__exchange__amount__currency')}
              onChange={onCurrencyUpdate}
              fullWidth
            />
          </StyledExchangeAmountSelectWrapper>
          <StyledExchangeAmountSwapButton
            iconName="CashTransfersIcon"
            className="ExchangeAmountSwapButton"
            disabled={!isSwapAvailable}
            onClick={onSwap}
            iconSize={32}
          />
          <Input
            className="ExchangeAmountExpectedInput"
            disabled={loading}
            value={localExpectedAmount ?? ''}
            placeholder={t('layout__exchange__amount_get__label')}
            onChange={onExpectedAmountUpdate}
            maxLength={16}
            fullWidth
          />
          <StyledExchangeAmountSelectWrapper className="ExchangeAmountSelectWrapper">
            <CurrencySelect
              className="ExchangeAmountTargetCurrencySelector"
              disabled={loading}
              options={targetCurrencies}
              icons={currencyIcons}
              value={targetCurrency}
              placeholder={t('layout__exchange__amount__currency')}
              onChange={onTargetCurrencyUpdate}
              fullWidth
            />
          </StyledExchangeAmountSelectWrapper>
        </StyledExchangeAmountFields>
      )}
    </StyledExchangeAmountBlock>
  );
};
