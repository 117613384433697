import { useContext, useMemo, useState } from 'react';

import {
  BrandingContext,
  LegalDocsContext,
  TranslationContext,
} from 'contexts';
import { notifyWith } from 'helpers/notifyWith';
import { TLegalEntityDocument } from 'services/legal-docs';

import { mergeLeAdapters } from './Agreements.helpers';
import {
  StyledAgreementsBlock,
  StyledAgreementsBlockHeading,
  StyledAgreementsNotice,
  StyledAgreementsNoticeDescription,
  StyledAgreementsNoticeTitle,
  StyledAgreementsWrapper,
  StyledToggleButtonGroup,
} from './Agreements.styled';
import { TDocsSigned, TLeAdaptersArray } from './Agreements.types';
import { AgreementsAssetDocs } from './components/AgreementsAssetDocs';
import { AgreementsLegalDocs } from './components/AgreementsLegalDocs';
import { AgreementsSkeleton } from './components/AgreementsSkeleton';
import { AgreementsW8Affidavit } from './components/AgreementsW8Affidavit';
import { AgreementsW8Form } from './components/AgreementsW8Form';

export const Agreements = () => {
  const legalDocs = useContext(LegalDocsContext);
  const { branding } = useContext(BrandingContext);
  const { t } = useContext(TranslationContext);

  const [leSelected, setLeSelected] = useState<string | null>(null);
  const [isAffidavitConfirmed, setIsAffidavitConfirmed] = useState(false);
  const [docsSigned, setDocsSigned] = useState<TDocsSigned>({});

  const leAdapters = legalDocs?.data?.['legal-docs']?.le_adapters || {};
  const mfaType = legalDocs?.data?.['legal-docs']?.mfa_type || 'sms';

  const leAdaptersArray: TLeAdaptersArray = Object.entries(leAdapters);

  const options = useMemo(
    () =>
      leAdaptersArray.map((leAdapterEntry) => ({
        value: leAdapterEntry[0],
        label: leAdapterEntry[0],
      })),
    [legalDocs],
  );

  const leValue = leSelected || leAdaptersArray?.[0]?.[0];

  const handleChange = (value: any) => {
    setLeSelected(value);
  };

  const handleDocSign = (legalEntity: string, doc: TLegalEntityDocument) => {
    setDocsSigned((docs) => ({
      ...docs,
      [legalEntity]: [...(docs?.[legalEntity] || []), doc],
    }));
  };

  const handleDocUpload = () => {
    legalDocs?.fetchData();
  };

  const docsWithSigned = useMemo(
    () => mergeLeAdapters({ leAdaptersArray, docsSigned }),
    [leAdaptersArray, docsSigned],
  );

  const leAdapter = leValue
    ? docsWithSigned.find((lea) => lea[0] === leValue)?.[1]
    : null;

  const handleAffidavitConfirm = () => {
    setIsAffidavitConfirmed(true);
    notifyWith.success(t('layout__w8_affidavit_form_w8_document_uploaded'));
  };

  const isAffidavitEnabled =
    leAdapter?.w8_affidavit_form_context?.enable || false;
  const showAffidavitForm = isAffidavitEnabled && !isAffidavitConfirmed;

  return (
    <StyledAgreementsWrapper className="AgreementsWrapper">
      <StyledToggleButtonGroup
        className="ToggleButtonGroup"
        options={options}
        onChange={handleChange}
        value={leValue}
        exclusive
        fullWidth
        skeletonsCount={2}
        showSkeleton={legalDocs?.isLoading}
      />
      {legalDocs?.isLoading && <AgreementsSkeleton />}
      {!!leAdapter?.to_sign?.length && (
        <StyledAgreementsBlock className="AgreementsBlock">
          <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
            {t('layout__legal_docs__docs_to_sign')}
          </StyledAgreementsBlockHeading>
          <AgreementsLegalDocs
            docs={leAdapter?.to_sign}
            onSign={handleDocSign}
            withStatus
          />
        </StyledAgreementsBlock>
      )}
      {!!leAdapter?.legal_signed_by_user?.length && (
        <StyledAgreementsBlock className="AgreementsBlock">
          <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
            {t('layout__legal_docs__docs_signed_by_you')}
          </StyledAgreementsBlockHeading>
          <AgreementsLegalDocs docs={leAdapter?.legal_signed_by_user} />
        </StyledAgreementsBlock>
      )}
      {!!leAdapter?.legal_signed_by_exante?.length && (
        <StyledAgreementsBlock className="AgreementsBlock">
          <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
            {t('layout__legal_docs__docs_signed_by_WL_NAME').replace(
              'WL_NAME',
              branding?.wl?.name || '',
            )}
          </StyledAgreementsBlockHeading>
          <AgreementsLegalDocs docs={leAdapter?.legal_signed_by_exante} />
        </StyledAgreementsBlock>
      )}
      {!!leAdapter?.other_docs.length && (
        <StyledAgreementsBlock className="AgreementsBlock">
          <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
            {t('layout__legal_docs__other_documents')}
          </StyledAgreementsBlockHeading>
          <AgreementsLegalDocs docs={leAdapter?.other_docs} />
        </StyledAgreementsBlock>
      )}
      {!!leAdapter?.asset_management_docs?.length && (
        <StyledAgreementsBlock className="AgreementsBlock">
          <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
            {t('layout__legal_docs__asset_management_docs')}
          </StyledAgreementsBlockHeading>
          <AgreementsAssetDocs
            docs={leAdapter?.asset_management_docs}
            onDocUploaded={handleDocUpload}
          />
        </StyledAgreementsBlock>
      )}
      {!!leAdapter?.w8ben_url && (
        <StyledAgreementsBlock className="AgreementsBlock">
          <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
            {t('layout__legal_docs__irs_w-8_forms')}
          </StyledAgreementsBlockHeading>
          <AgreementsW8Form url={leAdapter.w8ben_url} />
        </StyledAgreementsBlock>
      )}
      {!!(leAdapter && showAffidavitForm) && (
        <StyledAgreementsBlock className="AgreementsBlock">
          <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
            {t('layout__legal_docs__w8_series_form_affidavit')}
          </StyledAgreementsBlockHeading>
          <AgreementsW8Affidavit
            clientId={leAdapter?.w8_affidavit_form_context.client_id}
            clientName={leAdapter?.w8_affidavit_form_context.client_name}
            signUrl={leAdapter?.w8_affidavit_form_context.sign_url}
            legalEntity={leValue}
            mfaType={mfaType}
            onConfirm={handleAffidavitConfirm}
          />
        </StyledAgreementsBlock>
      )}
      {!legalDocs?.isLoading && (
        <StyledAgreementsBlock className="AgreementsBlock">
          <StyledAgreementsNotice className="AgreementsNotice">
            <StyledAgreementsNoticeTitle className="AgreementsNoticeTitle">
              {t('layout__legal_docs__english_is_the_official_language')}
            </StyledAgreementsNoticeTitle>
            <StyledAgreementsNoticeDescription className="AgreementsNoticeDescription">
              {t('layout__legal_docs__in_case_of_disputes')}
            </StyledAgreementsNoticeDescription>
          </StyledAgreementsNotice>
        </StyledAgreementsBlock>
      )}
    </StyledAgreementsWrapper>
  );
};
