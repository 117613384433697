import { styled } from 'theme';

export const StyledToMyAccountContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledAccountSelection = styled('div')`
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledInternalNoteContainer = styled('div')`
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const StyledInternalCardsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .InputWrapper {
      max-width: 320px;
    }
  }
`;

export const StyledInternalCardsWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 334px;
    overflow-x: scroll;
  }
`;

export const StyledInternalCards = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  & > span {
    flex-shrink: 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-wrap: nowrap;
  }
`;

export const StyledAutocompleteWrapper = styled('div')`
  width: 240px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;
