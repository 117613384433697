import cn from 'classnames';
import { FC } from 'react';

import { StyledPageWrapper } from 'components/PageWrapper/PageWrapper.styled';
import { PageWrapperProps } from 'components/PageWrapper/PageWrapper.types';

export const PageWrapper: FC<PageWrapperProps> = ({ children, className }) => (
  <StyledPageWrapper className={cn(['PageWrapper', className])}>
    {children}
  </StyledPageWrapper>
);
