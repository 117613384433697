import memoize from 'lodash/memoize';

import { DEFAULT_LOCALE } from 'constants/common';

import { TCreateCurrencyFormatter } from './currencyFormatter.types';

function createCurrencyFormatter(
  locale: string,
  currency?: string | null,
): Intl.NumberFormat | TCreateCurrencyFormatter {
  if (currency) {
    try {
      return new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,
      });
    } catch (e) {
      return {
        format: (value: number | bigint): string =>
          `${currency} ${new Intl.NumberFormat(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 20,
          }).format(value)}`,
      };
    }
  } else {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 20,
    });
  }
}

function memoizeResolver(
  locale: string = DEFAULT_LOCALE,
  currency?: string | null,
) {
  return `${locale}-${currency}`;
}

export const currencyFormatter = memoize(
  createCurrencyFormatter,
  memoizeResolver,
);
