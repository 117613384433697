import { ToggleButtonGroup } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledConfirmationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 24px;
  width: 334px;
`;

export const StyledConfirmationInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const StyledConfirmationInputText = styled('span')<{ visible: boolean }>`
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: ${({ theme }) => theme.color.typo.secondary};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  padding: 0 14px;
`;

export const StyledResendButton = styled('button')<{
  disabled?: boolean;
}>`
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  font-size: 13px;
  font-weight: 400;
  background-color: transparent;
  color: ${({ disabled, theme }) =>
    !disabled ? theme.color.typo.action : theme.color.typo.ghost};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
`;

export const StyledConfirmFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const StyledTitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledConfirmationSwitch = styled(ToggleButtonGroup)`
  flex-wrap: nowrap !important;
`;
