import { styled } from 'theme';

export const StyledButtonContainer = styled('div')`
  display: flex;

  button {
    font-weight: 500;
    font-family: ${({ theme }) => theme.font.main};
    font-size: 15px;
  }
`;

export const StyledTooltipContent = styled('div')`
  padding-right: 24px;
`;

export const StyledTooltipHeader = styled('div')`
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.header};
  font-size: 20px;
  margin-bottom: 24px;
`;

export const StyledTooltipText = styled('div')`
  margin-bottom: 24px;
`;
