import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledHelpSubheadingTextSkeleton = styled(Skeleton)`
  width: 300px;
  height: 16px;
  margin-bottom: 16px;
`;

export const StyledHelpTextSkeleton = styled(Skeleton)`
  width: 300px;
  height: 16px;
`;
