import { useMediaQuery } from '@mui/material';
import Cookies from 'js-cookie';
import { FC, useContext, useState } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { BurgerIcon, CloseIcon } from 'assets/icons';
import { OpenedMenu } from 'components/Header/Header.constants';
import { useShowUnsignedNotification } from 'components/Header/hooks';
import { isLocalhost } from 'constants/endpoints';
import { BrandingContext, TranslationContext } from 'contexts';
import { getBaseName, isBranchStand } from 'helpers/isBranchStand';
import { useMenuOpened } from 'hooks';
import { ChallengeAccountStatus } from 'modules/challenge/components/ChallengeInfo/ChallengeAccountStatus/ChallengeAccountStatus';
import { ChallengeName } from 'modules/challenge/components/ChallengeInfo/ChallengeName/ChallengeName';
import { ChallengeHeaderProvider } from 'modules/challenge/context/ChallengeContext/ChallengeContext';
import { useTheme } from 'theme';

import { LayoutContext } from '../Layout/context';

import {
  StyledHeaderButton,
  StyledHeaderContent,
  StyledHeaderLinkLogout,
  StyledHeaderPowerIcon,
  StyledHeaderSkeletonContainer,
  StyledHeaderSkeletonContainerMobile,
  StyledHeaderWrapper,
} from './Header.styled';
import {
  Account,
  Breadcrumbs,
  CurrencySelector,
  HeaderAccountSelector,
  HeaderCustomButton,
  HeaderDeposit,
  HeaderTitle,
  HeaderWebTrade,
  LangSelector,
  MobileMenu,
  NotificationModal,
  Notifications,
} from './components';

export const Header: FC = () => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const { onToggleMenu } = useMenuOpened();

  const theme = useTheme();
  const {
    menu: {
      opened: mobileSideMenuVisible,
      setOpened: setMobileSideMenuVisible,
    },
  } = useContext(LayoutContext);

  const [openedMenu, setOpenedMenu] = useState<OpenedMenu | null>(null);

  const webTradeVisible = !branding?.hide_trading;

  const SideMenuButtonIcon =
    openedMenu === OpenedMenu.Side ? CloseIcon : BurgerIcon;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const onMobileSideMenuClick = () => {
    if (openedMenu === OpenedMenu.Side) {
      setOpenedMenu(null);
      setMobileSideMenuVisible(false);
    } else {
      setOpenedMenu(OpenedMenu.Side);
      setMobileSideMenuVisible(true);
    }
  };

  const onMobileMenuClick = () => {
    if (openedMenu === OpenedMenu.Mobile) {
      setOpenedMenu(null);
    } else {
      setOpenedMenu(OpenedMenu.Mobile);
      setMobileSideMenuVisible(false);
    }
    onToggleMenu(openedMenu !== OpenedMenu.Mobile);
  };

  if (isDesktop && mobileSideMenuVisible) {
    setMobileSideMenuVisible(false);
  }

  const closeMenu = () => setOpenedMenu(null);

  useShowUnsignedNotification();

  const logout = () => {
    if (isLocalhost || isBranchStand()) {
      Cookies.remove('x_access');
      Cookies.remove('y_access');
      Cookies.remove('in_cache');

      window.location.href = `${getBaseName()}/clientsarea/accounts/login/`;
      return;
    }

    window.location.href = '/clientsarea/logout/';
  };

  return (
    <StyledHeaderWrapper className="header">
      <StyledHeaderContent className="header__cnt">
        {!isDesktop && (
          <>
            <StyledHeaderButton
              onClick={onMobileSideMenuClick}
              className="header__menu"
              disableRipple
              opened={openedMenu === OpenedMenu.Side}
            >
              <SideMenuButtonIcon className="xnt-icon" />
            </StyledHeaderButton>
            <HeaderTitle />
            <HeaderDeposit />
          </>
        )}
        <Breadcrumbs />
        {isDesktop && (
          <>
            <HeaderDeposit />
            {webTradeVisible && <HeaderWebTrade />}
            {isTranslationsLoading ? (
              <StyledHeaderSkeletonContainer>
                <Skeleton
                  width={100}
                  height={24}
                  animation="wave"
                  variant="rounded"
                />
                <Skeleton
                  width={24}
                  height={24}
                  animation="wave"
                  variant="rounded"
                />
                <Skeleton
                  width={40}
                  height={24}
                  animation="wave"
                  variant="rounded"
                />
                <Skeleton
                  width={24}
                  height={24}
                  animation="wave"
                  variant="rounded"
                />
              </StyledHeaderSkeletonContainer>
            ) : (
              <>
                <HeaderCustomButton />
                <HeaderAccountSelector />
                <ChallengeHeaderProvider>
                  <ChallengeName />
                  <ChallengeAccountStatus />
                </ChallengeHeaderProvider>
                <Account />
                <CurrencySelector />
                <LangSelector />
                <Notifications />
              </>
            )}
            <StyledHeaderLinkLogout
              onClick={logout}
              className="header__logout"
              title={t('layout__header__log_out')}
            >
              <StyledHeaderPowerIcon className="xnt-icon" />
            </StyledHeaderLinkLogout>
          </>
        )}
        <MobileMenu
          opened={openedMenu === OpenedMenu.Mobile}
          onClick={onMobileMenuClick}
        >
          {isTranslationsLoading ? (
            <StyledHeaderSkeletonContainerMobile>
              {[...Array(5)].map((_, i) => (
                <StyledHeaderSkeletonContainer key={`i${i}`}>
                  <Skeleton
                    width={24}
                    height={24}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    width={120}
                    height={24}
                    animation="wave"
                    variant="rounded"
                  />
                </StyledHeaderSkeletonContainer>
              ))}
            </StyledHeaderSkeletonContainerMobile>
          ) : (
            <>
              <HeaderCustomButton />
              <HeaderAccountSelector />
              <ChallengeHeaderProvider>
                <ChallengeName />
                <ChallengeAccountStatus />
              </ChallengeHeaderProvider>
              <Account onClick={closeMenu} />
              <LangSelector />
              <CurrencySelector />
              <Notifications />
              <StyledHeaderLinkLogout onClick={logout}>
                <StyledHeaderPowerIcon className="xnt-icon" />
                {t('layout__account__log_out')}
              </StyledHeaderLinkLogout>
            </>
          )}
        </MobileMenu>
        <NotificationModal />
      </StyledHeaderContent>
    </StyledHeaderWrapper>
  );
};
