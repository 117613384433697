import {
  blockNonNativeProps,
  FileUpload,
  TFileUploadProps,
} from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TTooltipListOptionalProps } from './UploadGroup.types';

export const StyledFileUploadHeader = styled('div')`
  display: flex;
  gap: 8px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 4px 0;
  }
`;

export const StyledFileUploadHeaderText = styled('p')`
  margin: 0;

  color: ${({ theme }) => theme.color.typo.primary};
  font-family: ${({ theme }) => theme.font.main};
  font-size: 15px;
  line-height: 32px;
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 20px;
    font-weight: 400;
  }
`;

export const StyledFileUploadHeaderIconWrapper = styled('div')`
  width: 24px;
  height: 24px;

  display: flex;

  color: ${({ theme }) => theme.color.icon.promo};

  svg {
    display: block;
    margin: auto;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 32px;
    height: 32px;
  }
`;

export const StyledFileUpload = styled(FileUpload, {
  shouldForwardProp: blockNonNativeProps([
    'isDragOver',
    'isMouseOver',
    'hasFile',
  ]),
})<TFileUploadProps>`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 16px;
  }

  // reverse order of "drag-n-drop text" and "upload button"
  .DragAndDropWrapper,
  .RowContainerLeft {
    flex-direction: row-reverse;
  }
`;

export const StyledDocumentUploadGroupNotice = styled('div')`
  padding: 16px;

  display: flex;
  gap: 8px;

  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledDocumentUploadGroupNoticeIconWrapper = styled('div')`
  width: 16px;
  color: ${({ theme }) => theme?.color?.icon?.secondary};
`;

export const StyledDocumentUploadGroupNoticeTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledDocumentUploadGroupNoticeText = styled('div')`
  a {
    color: ${({ theme }) => theme?.color?.typo?.action};
  }
`;

export const StyledDocumentUploadTooltipContainer = styled('div')`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const StyledDocumentUploadTooltipText = styled('p')`
  margin: 0;
`;

export const StyledDocumentUploadTooltipList = styled('ul')`
  margin: 10px;
  padding-inline-start: 20px;

  li {
    margin: 0;
  }
`;

export const StyledDocumentUploadTooltipListOptional = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isVisible']),
})<TTooltipListOptionalProps>`
  display: ${({ isVisible }) => (isVisible ? 'inline' : 'none')};
`;

export const StyledDocumentUploadTooltipListToggle = styled('span')`
  color: ${({ theme }) => theme?.color?.typo?.action};

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
