import { TRootState } from 'store/store';

export const selectAccounts = (state: TRootState) => state.accounts;

export const selectAccountLabel = (state: TRootState) => {
  return state.accounts.selectedAccount?.label || null;
};

export const selectSelectedDate = (state: TRootState) => {
  return new Date(state.accounts.selectedDateIso);
};

export const selectAccountDescription = (
  state: TRootState,
  accountId: string,
) => {
  return state.accounts.allAccountDescriptions[accountId] || null;
};
