import { AxiosError } from 'axios';
import { useContext, useEffect, useRef } from 'react';
import {
  FileUpload,
  IconButton,
  InfoIcon,
  Input,
  Tooltip,
} from 'react-ui-kit-exante';

import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext';
import { UserDetailsContext } from 'contexts/UserDetailsContext';
import { delay } from 'helpers/timers';
import { PATHS } from 'router/router.constants';
import { transferService, TransferType } from 'services/transfer';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';

import { TRANSFERS_REFRESH_TIMEOUT } from '../../../../Transfers.constants';
import { NoteText } from '../../../../components/NoteText/NoteText';
import { useTransfers } from '../../../../hooks';
import { isInternalTransferShowNotAllowed } from '../../../../hooks/useTransfers/useTransfers.helpers';
import { acceptsFiles } from '../../../Securities/Securities.consts';
import { StyledHint } from '../../../shared.styled';
import { FundsContext } from '../../context';
import { useToInternalAccountData } from '../../hooks/useToInternalAccountData';

import {
  StyledFileUploadContainer,
  StyledFileUploadHeader,
  StyledNoteContainer,
  StyledOptionalContainer,
  StyledOptionalContent,
  StyledReference,
  StyledReferenceContainer,
  StyledTargetAccount,
  StyledToInternalContainer,
  StyledToInternalSearchContainer,
  StyledToInternalUnavailable,
  StyledTooltipContainer,
  StyledTooltipDescription,
  StyledValidateFooter,
  StyledValidateLoader,
  StyledValidateTarget,
} from './styled';
import { useComponentState } from './useComponentState';

export const ToInternalAccount = () => {
  const currencyRef = useRef<string | null>(null);

  const {
    getFieldHint,
    expanded,
    handleFileAppend,
    handleFileDelete,
    handleOptionalButtonClick,
    handleReferenceInputChange,
    handleSearchInputChange,
    handleValidate,
    search,
    dirty,
    validating,
    requestingTransactionState,
  } = useComponentState();

  const { t } = useContext(TranslationContext);
  const { userDetails } = useContext(UserDetailsContext);
  const { transfersState } = useTransfers();
  const { currencies } = useToInternalAccountData();
  const {
    toInternalAccountState,
    toInternalAccountDispatch,
    selectedAccountId,
  } = useContext(FundsContext);

  const updateState = async () => {
    await delay(TRANSFERS_REFRESH_TIMEOUT);

    if (currencyRef.current) {
      toInternalAccountDispatch({ type: 'UPDATE_LIMITS_START' });
      try {
        const { limits } = await transferService.fetchTransferDirections(
          selectedAccountId,
          TransferType.ToInternalAccount,
        );

        toInternalAccountDispatch({
          type: 'UPDATE_LIMITS_SUCCESS',
          payload: String(limits[currencyRef.current] || 0),
        });
      } catch (error) {
        const { message } = error as AxiosError;
        toInternalAccountDispatch({
          type: 'UPDATE_LIMITS_ERROR',
          payload: message,
        });
      } finally {
        updateState();
      }
    }
  };

  useEffect(() => {
    if (toInternalAccountState.currency) {
      currencyRef.current = toInternalAccountState.currency;
    }

    return () => {
      currencyRef.current = null;
    };
  }, [toInternalAccountState.currency]);

  useEffect(() => {
    updateState();
  }, []);

  useEffect(() => {
    if (currencies.length > 0) {
      toInternalAccountDispatch({
        type: 'SET_CURRENCY',
        payload: currencies[0],
      });
    }
  }, [currencies.length]);

  const [id] = (selectedAccountId || '').split('.');
  const legalEntity = transfersState.client_id_le_map?.[id];

  if (isInternalTransferShowNotAllowed(transfersState)) {
    return (
      <StyledToInternalContainer className="ClientAccountContainer">
        <Text size="15px" weight="400" color="secondary">
          <StyledToInternalUnavailable
            className="ToInternalUnavailable"
            dangerouslySetInnerHTML={{
              __html: t('layout__transfers__internal__no_payment_account', {
                LINK: PATHS.ROOT + PATHS.SETTINGS_ACCOUNT_SETTINGS,
              }),
            }}
          />
        </Text>
      </StyledToInternalContainer>
    );
  }

  return (
    <StyledToInternalContainer className="ClientAccountContainer">
      <StyledTooltipContainer className="TooltipContainer">
        <Tooltip
          className="Tooltip"
          placement="bottom"
          title={
            <StyledTooltipDescription className="TooltipDescription">
              <Text weight="400" size="13px" color="secondary">
                {t('layout__transfers__cash__note-tooltip')}
              </Text>
              <Text weight="400" size="13px" color="secondary">
                {t('layout__transfers__cash__note-tooltip-description')}
              </Text>
            </StyledTooltipDescription>
          }
        >
          <StyledNoteContainer className="NoteContainer">
            <Text weight="500" size="13px" color="secondary" withInfoIcon>
              {t('label_key_important_note')}
            </Text>
          </StyledNoteContainer>
        </Tooltip>
      </StyledTooltipContainer>

      <StyledTargetAccount className="TargetAccount">
        <StyledToInternalSearchContainer className="ExternalSearchContainer">
          <Input
            fullWidth
            disabled={toInternalAccountState.mode === 'CONFIRM'}
            value={search}
            onChange={handleSearchInputChange}
            placeholder={t('layout__subaccount_transfer__external_search')}
            error={validating.error}
            onBlur={handleValidate}
            iconRight={getFieldHint('internal_account')}
          />
          {validating.pending && <StyledValidateLoader />}
        </StyledToInternalSearchContainer>
        <StyledValidateFooter className="ValidateFooter">
          {validating.error && !validating.pending && (
            <Text size="11px" color="radical">
              {validating.errorMessage}
            </Text>
          )}
          {validating.succeed &&
            !validating.error &&
            !validating.pending &&
            !dirty &&
            toInternalAccountState.name &&
            toInternalAccountState.targetAccountId && (
              <>
                <StyledValidateTarget className="ValidateTarget">
                  {toInternalAccountState.name}
                </StyledValidateTarget>
                <span>
                  {t('layout__subaccount_transfer__external_description')}
                </span>
                <StyledValidateTarget className="ValidateTarget">
                  {toInternalAccountState.targetAccountId}
                </StyledValidateTarget>
              </>
            )}
        </StyledValidateFooter>
      </StyledTargetAccount>
      <StyledOptionalContainer className="OptionalContainer">
        {!userDetails?.info?.isCorporate && (
          <IconButton
            iconColor="secondary"
            onClick={handleOptionalButtonClick}
            iconName={expanded ? 'MinusIcon' : 'AddIcon'}
            label={t('layout__subaccount_transfer__external_optional')}
          />
        )}

        <StyledOptionalContent
          className="OptionalContent"
          isHidden={!(expanded || userDetails?.info?.isCorporate)}
        >
          <StyledReferenceContainer className="ReferenceContainer">
            <StyledReference
              fullWidth
              disabled={toInternalAccountState.mode === 'CONFIRM'}
              label={t('layout__subaccount_transfer__external_reference_title')}
              className="Reference"
              value={toInternalAccountState.reference}
              onChange={handleReferenceInputChange}
              error={Boolean(toInternalAccountState.errors.reference)}
              message={toInternalAccountState.errors.reference}
              multiline
              iconRight={getFieldHint('reference_comment')}
              inputProps={{
                maxLength:
                  transfersState?.wr_types?.Internal?.max_description_length?.[
                    legalEntity
                  ],
              }}
            />
          </StyledReferenceContainer>
          <StyledFileUploadContainer className="FileUploadContainer">
            <StyledFileUploadHeader className="FileUploadHeader">
              {t('layout__fields__accompanying_documents')}
            </StyledFileUploadHeader>
            <div>
              {!requestingTransactionState.pending && (
                <>
                  <FileUpload
                    onDelete={handleFileDelete}
                    onUpload={handleFileAppend}
                    translator={t}
                    disabled={toInternalAccountState.mode === 'CONFIRM'}
                    accept={acceptsFiles}
                    dndLabel={t('layout__fields__drag_n_drop')}
                    buttonLabel={t('generic__action__upload')}
                    error={Boolean(toInternalAccountState.errors.files)}
                    message={toInternalAccountState.errors.files}
                  />
                  {getFieldHint('files', true) && (
                    <StyledHint className="Hint" withMarginTop>
                      <InfoIcon className="InfoIcon" />
                      <Text size="13px" color="secondary">
                        {getFieldHint('files', true)}
                      </Text>
                    </StyledHint>
                  )}
                </>
              )}
              {requestingTransactionState.pending && <FileUpload loading />}
            </div>
          </StyledFileUploadContainer>
        </StyledOptionalContent>
      </StyledOptionalContainer>
      <NoteText type={WITHDRAWAL_TYPE.INTERNAL} />
    </StyledToInternalContainer>
  );
};
