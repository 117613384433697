import { TWithdrawalHistoryEntryPosition } from 'services/withdrawal';

export const WITHDRAWAL_HISTORY_ENTRY_POSITIONS_MAP: Record<
  string,
  | keyof TWithdrawalHistoryEntryPosition
  | ((position: TWithdrawalHistoryEntryPosition) => any)
> = {
  layout__item__instrument_type: 'instrumentTypeName',
  Symbol: 'symbolId',
  ISIN: 'isin',
  Exchange: 'exchange',
  Quantity: (position) => Number(position.value),
  Price: 'price',
  Currency: 'currency',
} as const;
