import { TAccount, TAccountType } from 'services/accounts';
import { TAccountsState } from 'store/accounts';
import { TPageAlias } from 'types/TPageAlias';

export const filterRouteAssociatedAccountsBy = (
  accounts: TAccountsState,
  {
    pageAlias,
    excludedTypes: excludedTypesProp = [],
  }: {
    pageAlias?: TPageAlias;
    excludedTypes?: TAccountType[];
  },
): TAccount[] => {
  const filterTypes: Set<TAccountType> = new Set();
  const excludedTypes: Set<TAccountType> = new Set(excludedTypesProp);

  if (pageAlias) {
    accounts.accountsPages[pageAlias]?.forEach((accType) =>
      filterTypes.add(accType),
    );

    Object.keys(accounts.accountsExcludePages).forEach((accType) => {
      const accountType = accType as TAccountType;

      if (accounts.accountsExcludePages[accountType]?.includes(pageAlias)) {
        excludedTypes.add(accountType);
      }
    });
  }

  const shouldFilterType = filterTypes.size !== 0;

  if (!shouldFilterType && excludedTypes.size === 0) {
    return accounts.data;
  }

  return accounts.data.filter((account) => {
    let hasFilteredType = !shouldFilterType;

    for (const type of account.types) {
      if (excludedTypes.has(type)) {
        return false;
      }

      if (shouldFilterType && !hasFilteredType) {
        hasFilteredType = filterTypes.has(type);
      }
    }

    return hasFilteredType;
  });
};
