import { AxiosError } from 'axios';

import { isCancelledError } from 'helpers/abortController/abortController.helpers';
import { ApiRequest } from 'helpers/apiRequest';
import { Dates } from 'helpers/dates';
import { notifyWith } from 'helpers/notifyWith';
import {
  TAccountSummary,
  TAccountSummaryMetrics,
  TAddAccount,
  TFetchAddAccountLimit,
} from 'types/accounts';

import {
  TAccountInfoResponse,
  TAccountNavReportResponse,
  TAccounts,
  TAccountsBalances,
  TAccountsBalancesParams,
  TAccountUsedSymbolsParams,
  TAccountUsersResponse,
  TGetAllAccountDescriptionsResponse,
  TGetUserAccountsMetricsParams,
  TGetUserAccountsParams,
  TUserAccounts,
} from './accounts.types';

class AccountsService extends ApiRequest {
  async getUserAccounts(
    params?: TGetUserAccountsParams,
  ): Promise<TUserAccounts | null> {
    try {
      const { data } = await this.fetch<TUserAccounts>({
        url: `/api/v2/accounts/`,
        params,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getUserAccounts error: ', error);
      return null;
    }
  }

  async getUserAccountsMetrics({
    accounts,
    currency,
    date,
    excludeAccountPurposes,
    signal,
  }: TGetUserAccountsMetricsParams): Promise<TAccountSummaryMetrics[] | null> {
    try {
      const { data } = await this.fetch<TAccountSummaryMetrics[] | null>({
        method: 'POST',
        url: '/api/accounts-metrics/',
        data: {
          accounts,
          currency,
          type: 'summary',
          date: Dates.format(date, false, 'dd.MM.yyyy'),
          excludeAccountPurposes:
            excludeAccountPurposes?.join(',') || undefined,
        },
        signal,
      });

      return data;
    } catch (error) {
      if (!isCancelledError(error)) {
        notifyWith.genericNetworkError(error);
        console.error('getUserAccountsMetrics error: ', error);
      }

      return null;
    }
  }

  async getAccountInfo(accountId?: string) {
    const { data } = await this.fetch<TAccountInfoResponse>({
      method: 'GET',
      url: `/en/clientsarea/rest/account/info/${accountId}/`,
    });

    return data;
  }

  async getLayoutData<T>(name: string, lang: string): Promise<T | null> {
    try {
      const response = await this.fetch<Record<string, T>>({
        url: `/${lang}/clientsarea/spa-layout/`,
        params: { name },
      });

      return response?.data?.[name] || null;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error(`getLayoutData ${name} error: `, error);
      return null;
    }
  }

  async getAccounts(
    name: 'trades' | 'transactions',
    lang: string,
  ): Promise<TAccounts | null> {
    try {
      return await this.getLayoutData<TAccounts>(name, lang);
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getAccounts error: ', error);
      return null;
    }
  }

  async getAccountSummary(
    account: string,
    date: Date,
    currency: string,
    signal: AbortSignal,
    lang = 'en',
  ): Promise<TAccountSummary | null> {
    try {
      const { data } = await this.fetch<TAccountSummary>({
        url: `/${lang}/clientsarea/account/summary/details/`,
        params: {
          date: Dates.format(date, false, 'MM.dd.yyyy'),
          currency,
          account,
        },
        signal,
      });

      if (data.currency) {
        // hack for display blockedQtty & lockedValue in summary tables
        data.currency = data.currency.map((i) => {
          const mappedCurrency = { ...i, lockedValue: i.blockedQtty };
          delete mappedCurrency.blockedQtty;

          return mappedCurrency;
        });
      }

      data.account = account;

      return data;
    } catch (error: unknown) {
      if (
        !(
          ((error as AxiosError)?.isAxiosError &&
            (error as AxiosError).response?.status === 403) ||
          isCancelledError(error)
        )
      ) {
        notifyWith.genericNetworkError(error);
        console.error('getAccountInfo error: ', error);
      }

      return null;
    }
  }

  async addAccount(clientId: string): Promise<TAddAccount | null> {
    try {
      const { data } = await this.fetch<TAddAccount>({
        method: 'POST',
        url: `/api/create-account/`,
        data: {
          client_id: clientId,
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('addAccount error: ', error);
      return null;
    }
  }

  async getAddAccountLimits(
    lang: string,
  ): Promise<TFetchAddAccountLimit | null> {
    try {
      return await this.getLayoutData<TFetchAddAccountLimit>(
        'account-management',
        lang,
      );
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getAddAccountLimits error: ', error);
      return null;
    }
  }

  async fetchAccountsBalance({
    accountIds,
    currency,
    lang = 'en',
    excludeAsset = true,
  }: TAccountsBalancesParams): Promise<TAccountsBalances | null> {
    try {
      const ids: string = Array.isArray(accountIds)
        ? accountIds.join(',')
        : accountIds;

      const { data } = await this.fetch<TAccountsBalances>({
        url: `/${lang}/clientsarea/rest/account/balance/details/`,
        params: {
          accounts: ids,
          currency,
          exclude_asset: excludeAsset,
        },
      });

      return typeof data === 'object' ? data : {};
    } catch (error: any) {
      notifyWith.genericNetworkError(error);

      return null;
    }
  }

  async resolveAccountsBalance(
    accounts: string[],
    currency?: string | null,
    lang = 'en',
  ) {
    const { data } = await this.fetch<Record<string, number>>({
      url: `/${lang}/clientsarea/rest/account/balance/nav/`,
      method: 'POST',
      data: {
        accounts,
        currency,
      },
    });

    return typeof data === 'object' ? data : {};
  }

  async getAccountNavReport(
    account_id: string,
    currency: string,
    date_from: string,
    date_to: string,
    lang: string,
  ): Promise<TAccountNavReportResponse | null> {
    try {
      const { data } = await this.fetch<TAccountNavReportResponse>({
        url: `/${lang}/clientsarea/rest/bo/nav-report/`,
        params: {
          account_id,
          currency,
          date_from,
          date_to,
          timeout: 180,
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async getAccountUsedSymbols(
    params: TAccountUsedSymbolsParams,
  ): Promise<Array<string> | null> {
    try {
      const { data } = await this.fetch<Array<string>>({
        url: `/${params.lang}/clientsarea/rest/bo/history/symbols/`,
        params,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async getAccountUsers(
    account_id: string,
    offset: number,
    size: number,
    signal?: AbortSignal,
  ): Promise<TAccountUsersResponse> {
    try {
      const { data } = await this.fetch<TAccountUsersResponse>({
        signal,
        url: `/clientsarea/account/settings/account-users/`,
        params: {
          account_id,
          offset,
          size,
        },
      });

      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }

      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async getAllAccountDescriptions(
    limit = 10_000,
  ): Promise<TGetAllAccountDescriptionsResponse | null> {
    try {
      const { data } = await this.fetch<TGetAllAccountDescriptionsResponse>({
        url: '/clientsarea/account/settings/account-descriptions/',
        params: { page_size: limit },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }
}

export const accountsService = new AccountsService();
