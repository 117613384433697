import { format as dateFnsFormat, intlFormat, isBefore } from 'date-fns';
import { isDate } from 'lodash';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const today = new Date();
export const minDate = new Date('1900-01-01T00:00:00');

export class Dates {
  static isValid(d: unknown) {
    return (
      d instanceof Date && !Number.isNaN(d) && d.toString() !== 'Invalid Date'
    );
  }

  static format(date: Date | null, time?: boolean, customFormat?: string) {
    const defaultFormat = time ? DATE_TIME_FORMAT : DATE_FORMAT;
    const dateFormat = customFormat || defaultFormat;
    return date ? dateFnsFormat(date, dateFormat) : '';
  }

  static formatLocaleDateTime(date: Date | null, locale: string) {
    return date
      ? intlFormat(
          date,
          {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            // https://bugs.chromium.org/p/chromium/issues/detail?id=1045791
            // hourCycle is used instead of hour12 as it still causes the bug
            // (unsupported here but passed to Intl.DateTimeFormat function)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            hourCycle: 'h23',
          },
          { locale },
        )
      : '';
  }

  static formatLocaleDate(date: Date | null, locale: string) {
    return date
      ? intlFormat(
          date,
          {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },
          { locale },
        )
      : '';
  }

  static convertDateToISO(value: unknown): string | null {
    if (value && (typeof value === 'string' || isDate(value))) {
      let date = new Date(value);

      if (!Dates.isValid(date) && typeof value === 'string') {
        const [day, month, year] = value.split('.').map(Number);

        date = new Date(year, month - 1, day);
      }

      if (Dates.isValid(date)) {
        return date.toISOString();
      }
      return null;
    }

    return null;
  }

  static convertToDate(value: unknown): Date | null {
    if (!value) {
      return null;
    }
    if (this.isValid(value)) {
      return value as Date;
    }

    if (typeof value === 'string') {
      // If received ISO format
      const date = this.convertISOToDate(value);
      if (this.isValid(date)) {
        return date;
      }

      // If not, trying to cast into ISO format
      const dateIso: string | null = this.convertDateToISO(value);
      if (dateIso) {
        return this.convertISOToDate(dateIso);
      }
    }

    return null;
  }

  static convertISOToDate(value: string): Date {
    // Some browsers do not support ISO 8601 format
    // https://github.com/mdn/browser-compat-data/issues/15401
    return new Date(value.replace(' ', 'T'));
  }

  static convertDateToUTCString(date: Date | string) {
    return new Date(date).toISOString().slice(0, 16);
  }

  static isExpired(expirationTime?: string | Date | null) {
    if (!expirationTime) {
      return false;
    }

    return isBefore(new Date(expirationTime), today);
  }

  static convertDateToUtcTimezone(date: Date) {
    return new Date(date.toISOString().split('Z')[0]);
  }

  static convertDateToUtcTimezoneWithFormat(date: Date, time?: boolean) {
    if (!Dates.isValid(date)) {
      return null;
    }

    const newDate = this.convertDateToUtcTimezone(date);

    if (Dates.isValid(newDate)) {
      return Dates.format(newDate, time);
    }

    return null;
  }

  static getDateRangeForTable(dates: Date[]) {
    return [
      Dates.convertDateToUTCString(dates[0]),
      Dates.convertDateToUTCString(dates[1]),
    ];
  }

  static getDatesFromQuery(date?: string | null) {
    const dates = date?.split(',');

    if (
      !dates ||
      dates.length !== 2 ||
      !Dates.isValid(dates[0]) ||
      !Dates.isValid(dates[1])
    ) {
      return null;
    }

    return date?.split(',')?.map((dt) => new Date(dt)) || null;
  }

  static getUTCFromQuery(date?: string | null) {
    return (
      Dates.getDatesFromQuery(date)?.map((dt) =>
        Dates.convertDateToUTCString(dt),
      ) || null
    );
  }
}
