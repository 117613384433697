import { useMediaQuery } from '@mui/material';
import { FC, useContext } from 'react';
import { Button } from 'react-ui-kit-exante';

import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { TPartnerLandingImages } from 'services/branding';
import { theme } from 'theme';

import { FeatureColors } from './Features.constants';
import {
  StyledFeatures,
  StyledFeaturesDesc,
  StyledFeaturesHeading,
  StyledFeaturesItemDesc,
  StyledFeaturesItemTitle,
  StyledFeaturesList,
  StyledFeaturesListItem,
} from './Features.styled';
import { TFeaturesProps } from './Features.types';

export const Features: FC<TFeaturesProps> = ({ onChooseType }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const partnerImages: TPartnerLandingImages | undefined =
    branding?.branding?.ui_settings?.partner_landing_images;

  return (
    <StyledFeatures className="Features">
      <StyledFeaturesHeading className="FeaturesSubheading">
        {t('layout__partner_landing__feature_main_title')}
      </StyledFeaturesHeading>
      <StyledFeaturesDesc className="FeaturesDesc">
        {t('layout__partner_landing__feature_main_desc')}
      </StyledFeaturesDesc>
      <StyledFeaturesList className="FeaturesList" role="list">
        <StyledFeaturesListItem
          className="FeaturesListItem"
          color={FeatureColors.GRAY}
          imageUrl={partnerImages?.features_item_1}
        >
          <StyledFeaturesItemTitle
            className="FeaturesItemTitle"
            dangerouslySetInnerHTML={{
              __html: t('layout__partner_landing__feature_1_title'),
            }}
          />
          <StyledFeaturesItemDesc className="FeaturesItemDesc">
            {t('layout__partner_landing__feature_1_desc')}
          </StyledFeaturesItemDesc>
        </StyledFeaturesListItem>
        <StyledFeaturesListItem
          className="FeaturesListItem"
          color={FeatureColors.VIOLET}
          imageUrl={partnerImages?.features_item_2}
        >
          <StyledFeaturesItemTitle
            className="FeaturesItemTitle"
            dangerouslySetInnerHTML={{
              __html: t('layout__partner_landing__feature_2_title'),
            }}
          />
          <StyledFeaturesItemDesc className="FeaturesItemDesc">
            {t('layout__partner_landing__feature_2_desc')}
          </StyledFeaturesItemDesc>
        </StyledFeaturesListItem>
        <StyledFeaturesListItem
          className="FeaturesListItem"
          color={FeatureColors.GREEN}
          imageUrl={partnerImages?.features_item_3}
        >
          <StyledFeaturesItemTitle
            className="FeaturesItemTitle"
            dangerouslySetInnerHTML={{
              __html: t('layout__partner_landing__feature_3_title'),
            }}
          />
          <StyledFeaturesItemDesc className="FeaturesItemDesc">
            {t('layout__partner_landing__feature_3_desc')}
          </StyledFeaturesItemDesc>
        </StyledFeaturesListItem>
        <StyledFeaturesListItem
          className="FeaturesListItem"
          color={FeatureColors.BLUE}
          imageUrl={partnerImages?.features_item_4}
        >
          <StyledFeaturesItemTitle
            className="FeaturesItemTitle"
            dangerouslySetInnerHTML={{
              __html: t('layout__partner_landing__feature_4_title'),
            }}
          />
          <StyledFeaturesItemDesc className="FeaturesItemDesc">
            {t('layout__partner_landing__feature_4_desc')}
          </StyledFeaturesItemDesc>
        </StyledFeaturesListItem>
      </StyledFeaturesList>
      <Button
        fullWidth={!isDesktop}
        onClick={onChooseType}
        data-test-id="partner__features__choose"
      >
        {t('layout__partner_landing__choose_btn')}
      </Button>
    </StyledFeatures>
  );
};
