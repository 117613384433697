import { Abort } from 'helpers/abortController';
import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';
import {
  TAssetManagementFeeSetup,
  TAssetManagementFeeUsers,
  TFeeGenericList,
  TFetchAssetManagementFeeSetupsParams,
} from 'services/assetManagement/assetManagement.types';

class AssetManagementService extends ApiRequest {
  abortClient;

  constructor() {
    super();
    this.abortClient = new Abort();
  }

  async getListFeeSetups(
    params: TFetchAssetManagementFeeSetupsParams,
    lang = 'en',
  ): Promise<TFeeGenericList<TAssetManagementFeeSetup> | null> {
    try {
      const { data } = await this.fetch<
        TFeeGenericList<TAssetManagementFeeSetup>
      >({
        method: 'GET',
        url: `/${lang}/clientsarea/rest/fee/setup/`,
        params,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async createFeeSetup(
    setup?: TAssetManagementFeeSetup,
  ): Promise<TAssetManagementFeeSetup | null> {
    try {
      const { data } = await this.fetch<TAssetManagementFeeSetup>({
        method: 'POST',
        url: '/clientsarea/rest/fee/setup/',
        data: setup,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async deleteFeeSetup(
    setupId: TAssetManagementFeeSetup['id'],
  ): Promise<boolean | null> {
    try {
      await this.fetch<TAssetManagementFeeSetup>({
        method: 'DELETE',
        url: `/clientsarea/rest/fee/setup/${setupId}/`,
      });

      return true;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async updateSetup(
    setup: TAssetManagementFeeSetup,
  ): Promise<TAssetManagementFeeSetup | null> {
    try {
      const { data } = await this.fetch<TAssetManagementFeeSetup>({
        method: 'PATCH',
        data: setup,
        url: `/clientsarea/rest/fee/setup/${setup.id}/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async generateDoc(
    setupId: TAssetManagementFeeSetup['id'],
  ): Promise<TAssetManagementFeeSetup | null> {
    try {
      const { data } = await this.fetch<TAssetManagementFeeSetup>({
        method: 'GET',
        url: `/clientsarea/rest/fee/setup/${setupId}/generate-doc/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async uploadDoc(
    setupId: TAssetManagementFeeSetup['id'],
    file: File,
  ): Promise<TAssetManagementFeeSetup | null> {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await this.fetch<TAssetManagementFeeSetup>({
        method: 'POST',
        url: `/clientsarea/rest/fee/setup/${setupId}/upload-doc/`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async uploadDocAsUser(
    feeUserId: TAssetManagementFeeUsers['id'],
    file: File,
  ): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append('file', file);
      await this.fetch<TAssetManagementFeeSetup>({
        method: 'POST',
        url: `/clientsarea/rest/fee/users/${feeUserId}/upload_doc/`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });

      return true;
    } catch (e) {
      notifyWith.genericNetworkError(e);
      return false;
    }
  }

  async sendToReview(
    setupId: TAssetManagementFeeSetup['id'],
  ): Promise<TAssetManagementFeeSetup | null> {
    try {
      const { data } = await this.fetch<TAssetManagementFeeSetup>({
        method: 'POST',
        url: `/clientsarea/rest/fee/setup/${setupId}/send_to_review/`,
      });

      return data;
    } catch (e) {
      notifyWith.genericNetworkError(e);
      return null;
    }
  }

  async completeSetup(
    setupId: TAssetManagementFeeSetup['id'],
  ): Promise<TAssetManagementFeeSetup | null> {
    try {
      const { data } = await this.fetch<TAssetManagementFeeSetup>({
        method: 'PUT',
        url: `/clientsarea/rest/fee/setup/${setupId}/complete/`,
      });

      return data;
    } catch (e) {
      notifyWith.genericNetworkError(e);
      return null;
    }
  }

  async sendLink(
    setupId: number | undefined,
    email: string,
  ): Promise<TAssetManagementFeeSetup | null> {
    try {
      if (!setupId) {
        return null;
      }
      const { data } = await this.fetch<TAssetManagementFeeSetup>({
        method: 'POST',
        url: `/clientsarea/rest/fee/setup/${setupId}/send-to/`,
        data: {
          email,
        },
      });

      return data;
    } catch (e) {
      notifyWith.genericNetworkError(e);
      return null;
    }
  }

  async getListFeeUsers(
    params: TFetchAssetManagementFeeSetupsParams,
  ): Promise<TFeeGenericList<TAssetManagementFeeUsers> | null> {
    try {
      const { data } = await this.fetch<
        TFeeGenericList<TAssetManagementFeeUsers>
      >({
        method: 'GET',
        url: '/clientsarea/rest/fee/users/',
        params,
      });

      return data;
    } catch (e) {
      notifyWith.genericNetworkError(e);
      return null;
    }
  }
}

export const assetManagementService = new AssetManagementService();
