import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { normaliseNumberDictionary } from 'helpers/dictionary';
import { useAvailableTabs } from 'hooks/index';
import { ACCOUNT_TYPE } from 'services/accounts/accounts.constants';
import { exchangeService } from 'services/exchange';
import { MENU_ALIAS } from 'services/menu';
import { selectAccounts } from 'store/accounts';

export const useExchangeAccountInfo = (
  accountId: string | null,
  lastConversionId: string | null,
) => {
  const { isTabExist } = useAvailableTabs();

  const { selectedAccount } = useSelector(selectAccounts);
  const [loadingAccountInfo, setLoadingAccountInfo] = useState(false);
  const [currencies, setCurrencies] = useState<string[]>([]);
  const [targetCurrencies, setTargetCurrencies] = useState<string[]>([]);
  const [limits, setLimits] = useState<Partial<Record<string, number>>>({});
  const isAggregated = selectedAccount?.types.includes(ACCOUNT_TYPE.AGGREGATED);

  const handleAccountUpdate = async (id: string | null) => {
    if (isTabExist(MENU_ALIAS.EXCHANGE) && id) {
      setLoadingAccountInfo(true);

      const accountInfo = await exchangeService.fetchAccountInfo(id);

      if (accountInfo) {
        const {
          account: { limits: accLimits, currencies: accCurrencies },
          navCurrencies,
        } = accountInfo;

        setLimits(normaliseNumberDictionary(accLimits));
        setCurrencies(accCurrencies);
        setTargetCurrencies(navCurrencies);
      }

      setLoadingAccountInfo(false);
    }
  };

  useEffect(() => {
    if (accountId && !isAggregated) {
      handleAccountUpdate(accountId);
    }
  }, [accountId, lastConversionId]);

  return {
    currencies,
    limits,
    targetCurrencies,
    loadingAccountInfo,
  };
};
