import { TFunction } from 'i18next';
import { IColumn } from 'react-ui-kit-exante';

import {
  formatCellToCurrencyUnrounded,
  TCreateCurrencyFormatter,
} from 'helpers/formatters';
import { TSymbol } from 'services/analysis';

type TSymbolsColumns = {
  currency: string;
  t: TFunction;
  currencyFormatter: TCreateCurrencyFormatter;
};

export const defaultColumnKeys = [
  'symbol',
  'turnover',
  'netPnl',
  'realizedPnl',
  'unrealizedPnl',
  'currency',
  'convertedNetPnl',
  'convertedRealizedPnl',
  'convertedUnrealizedPnl',
];

export const getColumns: ({
  currency,
  t,
  currencyFormatter,
}: TSymbolsColumns) => IColumn<TSymbol>[] = ({
  currency,
  t,
  currencyFormatter,
}) => [
  {
    id: 'symbol',
    required: true,
    Header: t('layout__performance__symbol'),
    accessor: 'symbol',
  },
  {
    id: 'turnover',
    Header: t('generic__turnover'),
    accessor: 'turnover',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'turnover'),
    sortType: 'numeric',
  },
  {
    id: 'netPnl',
    Header: t('net_pnl'),
    accessor: 'netPnl',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'netPnl'),
    sortType: 'numeric',
  },
  {
    id: 'realizedPnl',
    Header: t('realised_pnl'),
    accessor: 'realizedPnl',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'realizedPnl'),
    sortType: 'numeric',
  },
  {
    id: 'unrealizedPnl',
    Header: t('unrealised_pnl'),
    accessor: 'unrealizedPnl',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'unrealizedPnl'),
    sortType: 'numeric',
  },
  {
    id: 'currency',
    Header: t('generic__currency'),
    accessor: 'currency',
  },
  {
    id: 'convertedNetPnl',
    Header: `${t('net_pnl')}, ${currency}`,
    accessor: 'convertedNetPnl',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'convertedNetPnl'),
    sortType: 'numeric',
  },
  {
    id: 'commission',
    Header: t('generic__commissions'),
    accessor: 'commission',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'commission'),
    sortType: 'numeric',
  },
  {
    id: 'pnl',
    Header: t('generic__pnl'),
    accessor: 'pnl',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'pnl'),
    sortType: 'numeric',
  },
  {
    id: 'convertedRealizedPnl',
    Header: `${t('realised_pnl')}, ${currency}`,
    accessor: 'convertedRealizedPnl',
    Cell: formatCellToCurrencyUnrounded(
      currencyFormatter,
      'convertedRealizedPnl',
    ),
    sortType: 'numeric',
  },
  {
    id: 'convertedUnrealizedPnl',
    Header: `${t('unrealised_pnl')}, ${currency}`,
    accessor: 'convertedUnrealizedPnl',
    Cell: formatCellToCurrencyUnrounded(
      currencyFormatter,
      'convertedUnrealizedPnl',
    ),
    sortType: 'numeric',
  },
  {
    id: 'convertedPnl',
    Header: `${t('generic__pnl')}, ${currency}`,
    accessor: 'convertedPnl',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'convertedPnl'),
    sortType: 'numeric',
  },
  {
    id: 'convertedCommission',
    Header: `${t('generic__commissions')}, ${currency}`,
    accessor: 'convertedCommission',
    Cell: formatCellToCurrencyUnrounded(
      currencyFormatter,
      'convertedCommission',
    ),
    sortType: 'numeric',
  },
  {
    id: 'couponPayments',
    Header: t('coupon_payments'),
    accessor: 'couponPayments',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'couponPayments'),
    sortType: 'numeric',
  },
  {
    id: 'convertedCouponPayments',
    Header: t('converted_coupon_payments'),
    accessor: 'convertedCouponPayments',
    Cell: formatCellToCurrencyUnrounded(
      currencyFormatter,
      'convertedCouponPayments',
    ),
    sortType: 'numeric',
  },
  {
    id: 'dividends',
    Header: t('generic__dividends'),
    accessor: 'dividends',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'dividends'),
    sortType: 'numeric',
  },
  {
    id: 'convertedDividends',
    Header: `${t('generic__dividends')}, ${currency}`,
    accessor: 'convertedDividends',
    Cell: formatCellToCurrencyUnrounded(
      currencyFormatter,
      'convertedDividends',
    ),
    sortType: 'numeric',
  },
  {
    id: 'overnights',
    Header: t('generic__overnigths'),
    accessor: 'overnights',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'overnights'),
    sortType: 'numeric',
  },
  {
    id: 'convertedOvernights',
    Header: `${t('generic__overnigths')}, ${currency}`,
    accessor: 'convertedOvernights',
    Cell: formatCellToCurrencyUnrounded(
      currencyFormatter,
      'convertedOvernights',
    ),
    sortType: 'numeric',
  },
  {
    id: 'minPnl',
    Header: t('min_pnl'),
    accessor: 'minPnl',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'minPnl'),
    sortType: 'numeric',
  },
  {
    id: 'maxPnl',
    Header: t('max_pnl'),
    accessor: 'maxPnl',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'maxPnl'),
    sortType: 'numeric',
  },
];
