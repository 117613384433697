import { useContext, useMemo } from 'react';

import { TRadioButtonsOption } from 'components/RadioButtons';
import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';

import { CashTransferTab } from '../../../../../context';
import { useTransfers } from '../../../../../hooks';

export const useOptions = () => {
  const { branding } = useContext(BrandingContext);
  const {
    isLoading,
    isToInternalAccountEnabled,
    isToMyAccountEnabled,
    isToBankAccountTransferEnabled,
    setCashTransferTab: setTab,
  } = useTransfers();

  const { t, currentLanguage } = useContext(TranslationContext);

  return useMemo(() => {
    const result: TRadioButtonsOption<CashTransferTab>[] = [];

    if (isToBankAccountTransferEnabled) {
      result.push({
        id: 'ToBankAccount',
        label: t('transfer__cash_bank-account_tab'),
        value: 'BANK',
      });
    }

    if (isToMyAccountEnabled) {
      result.push({
        id: 'ToMyAccount',
        label: t('transfer__cash_internal_tab'),
        value: 'MY_ACCOUNT',
      });
    }

    if (isToInternalAccountEnabled) {
      result.push({
        id: 'ToInternalAccount',
        label: t('transfer__cash_wl-client_tab', {
          wl_name: branding?.wl_name,
        }),
        value: 'INTERNAL',
      });
    }

    const firstTabValue = result[0]?.value;

    if (!isLoading && firstTabValue) {
      setTab(firstTabValue);
    }

    return result;
  }, [
    branding?.wl_name,
    isLoading,
    isToInternalAccountEnabled,
    isToMyAccountEnabled,
    isToBankAccountTransferEnabled,
    currentLanguage,
  ]);
};
