import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPartnerPaymentsDetailsFormWrapper = styled('form')`
  flex: 1;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledPartnerPaymentsDetailsFormHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledPartnerPaymentsDetailsFormHeaderText = styled('h2')`
  margin: 0;

  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
`;

export const StyledPartnerPaymentsDetailsFormHeaderSaveButton = styled(Button)`
  color: ${({ theme }) => theme.color.typo.action};

  & svg {
    color: ${({ theme }) => theme.color.typo.action} !important;
  }

  &:hover svg {
    color: ${({ theme }) => theme.color.typo.promo} !important;
  }
`;

export const StyledPartnerPaymentsDetailsFormGrid = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.breakpoints.up('lr')} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;
