import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledTabsSkeleton,
  StyledTabsSkeletonWrapper,
} from './TabsSkeleton.styled';
import { TTabsSkeletonProps } from './TabsSkeleton.types';

export const TabsSkeleton: FC<TTabsSkeletonProps> = ({ length = 4 }) => (
  <StyledTabsSkeletonWrapper>
    {[...Array(length)].map((_, i) => (
      <StyledTabsSkeleton key={i}>
        <Skeleton width={18} height={18} variant="circular" animation="wave" />
        <Skeleton width={65} height={18} variant="rounded" animation="wave" />
      </StyledTabsSkeleton>
    ))}
  </StyledTabsSkeletonWrapper>
);
