import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledSigninActions,
  StyledSigninPassword,
} from '../../SigninPassword.styled';

export const SigninPasswordSkeleton: FC = () => {
  return (
    <StyledSigninPassword className="SigninPassword">
      {/* inputs */}
      <Skeleton width="100%" height={48} />
      <Skeleton width="100%" height={48} />

      {/* buttons */}
      <StyledSigninActions className="SigninActions">
        <Skeleton width="100%" height={48} />
        <Skeleton width="100%" height={48} />
      </StyledSigninActions>

      {/* reset text button */}
      <Skeleton width="50%" />
    </StyledSigninPassword>
  );
};
