import { CircleClockIcon, GlobalIcon, PercentIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledInfoSectionContainer = styled('div')`
  display: flex;

  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
`;
export const StyledInfoSectionBlock = styled('div')`
  display: flex;

  flex-direction: column;
  gap: 8px;
`;

export const StyledInfoSectionText = styled('p')`
  margin: 0;

  color: ${({ theme }) => theme.color.typo.promo};

  font-family: ${({ theme }) => theme.font.main};
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledGlobalIcon = styled(GlobalIcon)`
  width: 24px;
  height: 24px;

  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledCircleClockIcon = styled(CircleClockIcon)`
  width: 24px;
  height: 24px;

  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledPercentIcon = styled(PercentIcon)`
  width: 24px;
  height: 24px;

  color: ${({ theme }) => theme.color.typo.promo};
`;
