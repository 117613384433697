import { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { useData } from 'react-ui-kit-exante';

import { TUserDetailsState } from 'contexts/UserDetailsContext/UserDetailsContext.types';
import { userDetailsService } from 'services/userDetails';
import { fetchAccountsAction, selectAccounts } from 'store/accounts';
import { fetchApplicationSettings } from 'store/application';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchSettingsNotifications } from 'store/settingsNotifications';
import { TChildren } from 'types/TChildren';

import { TranslationContext } from '../TranslationContext';

import { useUserPreferred } from './hooks';

export const UserDetailsContext = createContext<TUserDetailsState>({
  userDetails: null,
  userPreferred: null,
  userPreferredLoading: false,
  updateUserPreferred: () => Promise.resolve(),
  userDetailsFetch: () => {},
  userDetailsIsLoading: false,
});

export const UserDetailsProvider: FC<TChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { currentLanguage, t } = useContext(TranslationContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const {
    fetchUserPreferred,
    userPreferred,
    updateUserPreferred,
    userPreferredLoading,
  } = useUserPreferred();

  const {
    data: userDetails,
    fetchData: fetchUserDetails,
    isLoading,
  } = useData({
    onFetch: () => userDetailsService.getUserDetails(currentLanguage),
  });

  useEffect(() => {
    fetchUserPreferred();
    dispatch(fetchAccountsAction({ translate: t }));
  }, []);

  useEffect(() => {
    if (currentLanguage) {
      fetchUserDetails();
      dispatch(fetchSettingsNotifications());
      dispatch(fetchApplicationSettings(currentLanguage));
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (selectedAccountId) {
      updateUserPreferred({
        preferableAccount: selectedAccountId || undefined,
      });
    }
  }, [selectedAccountId]);

  const value = useMemo<TUserDetailsState>(
    () => ({
      userDetails,
      userPreferred,
      updateUserPreferred,
      userPreferredLoading,
      userDetailsIsLoading: isLoading,
      userDetailsFetch: fetchUserDetails,
    }),
    [
      userDetails,
      userPreferred,
      fetchUserDetails,
      isLoading,
      userPreferredLoading,
    ],
  );

  return (
    <UserDetailsContext.Provider value={value}>
      {children}
    </UserDetailsContext.Provider>
  );
};
