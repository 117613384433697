import { times } from 'lodash';
import { FC } from 'react';

import { StyledManual, StyledManualList } from '../../../../HelpManuals.styled';

import {
  StyledListItemIconSkeleton,
  StyledListItemTextSkeleton,
} from './ManualListSkeleton.styled';
import { TManualListSkeleton } from './ManualListSkeleton.types';

export const ManualListSkeleton: FC<TManualListSkeleton> = ({ items = 1 }) => (
  <StyledManualList className="ManualList">
    {times(items).map((i) => (
      <StyledManual className="ManualListItem" key={i}>
        <StyledListItemIconSkeleton variant="rounded" animation="wave" />
        <StyledListItemTextSkeleton variant="rounded" animation="wave" />
      </StyledManual>
    ))}
  </StyledManualList>
);
