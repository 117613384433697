import { BASE_URL } from 'constants/endpoints';
import { trimUrlSlashes } from 'helpers/trimUrlSlashes';
import { depositService } from 'services/deposit';

import {
  DEPOSIT_DOC_LOAD_ATTEMPTS,
  DEPOSIT_DOC_LOAD_TIMEOUT,
} from './OpenDocButton.consts';
import { TOpenDepositDoc } from './OpenDocButton.types';

export const getDepositDocUrl: TOpenDepositDoc = async (
  url: string,
  signal: AbortSignal,
  attempts: number = DEPOSIT_DOC_LOAD_ATTEMPTS,
) => {
  const response = await depositService.getDepositDocUrl({
    url,
    options: { signal },
  });

  if (!response) {
    throw new Error(`Document is not available by ${url}`);
  }

  if (!response?.ready) {
    if (!attempts) {
      throw Error(`Cannot load document from ${url}`);
    }

    if (signal.aborted) {
      return null;
    }

    return new Promise((resolve) => {
      setTimeout(async () => {
        resolve(await getDepositDocUrl(url, signal, attempts - 1));
      }, DEPOSIT_DOC_LOAD_TIMEOUT);
    });
  }

  return `${BASE_URL}/${trimUrlSlashes(response.url)}/view/`;
};
