import { ToBankAccountFields } from '../../../types';

export const getErrors = (
  errors: Partial<Record<keyof ToBankAccountFields, string>>,
  payload: keyof ToBankAccountFields,
) => {
  return Object.keys(errors).reduce<
    Partial<Record<keyof ToBankAccountFields, string>>
  >((result, key) => {
    if (key === payload) {
      return result;
    }

    return {
      ...result,
      [key]: errors[key as keyof ToBankAccountFields],
    };
  }, {});
};
