import { useRef } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { repeat } from 'helpers/array';

import {
  StyledLoadingSkeleton,
  StyledLoadingSkeletonDesktopCell,
  StyledLoadingSkeletonDesktopRow,
  StyledLoadingSkeletonMobileBody,
  StyledLoadingSkeletonMobileItem,
  StyledLoadingSkeletonMobileRow,
} from './SettingsLoading.styled';

export const SettingsLoading = () => {
  const { current: gridCell } = useRef(
    <StyledLoadingSkeletonDesktopCell key={Math.random()}>
      <Skeleton height="16px" width="100%" />
    </StyledLoadingSkeletonDesktopCell>,
  );

  const { current: gridRow } = useRef(
    <StyledLoadingSkeletonDesktopRow>
      {repeat(4, gridCell)}
    </StyledLoadingSkeletonDesktopRow>,
  );

  const { current: flatItems } = useRef(
    repeat(
      2,
      <StyledLoadingSkeletonMobileItem key={Math.random()}>
        <Skeleton height="16px" width="16px" />
        <Skeleton height="16px" width="100%" />
      </StyledLoadingSkeletonMobileItem>,
    ),
  );

  const { current: flatRow } = useRef(
    <StyledLoadingSkeletonMobileRow key={Math.random()}>
      <Skeleton height="24px" width="80px" />
      <StyledLoadingSkeletonMobileBody>
        {flatItems}
      </StyledLoadingSkeletonMobileBody>
      <Skeleton height="48px" width="100%" />
    </StyledLoadingSkeletonMobileRow>,
  );

  return (
    <StyledLoadingSkeleton>
      {repeat(5, gridRow)}
      {repeat(6, flatRow)}
    </StyledLoadingSkeleton>
  );
};
