import { ApiAction } from './actions';
import { TApiReducer, TApiState } from './types';

const apiReducer: TApiReducer = (state, action): TApiState => {
  switch (action.type) {
    case ApiAction.FetchApiStateError: {
      return {
        ...state,
        fetching: false,
        succeed: true,
        fetchingError: true,
      };
    }

    case ApiAction.FetchApiStateStart: {
      return {
        ...state,
        fetching: true,
        fetchingError: false,
      };
    }

    case ApiAction.FetchApiStateSuccess: {
      const { applications, dashboard } = action.payload;

      return {
        ...state,
        ...dashboard,
        applications,
        fetching: false,
        succeed: true,
        fetchingError: false,
      };
    }

    case ApiAction.OnAddApplication: {
      return {
        ...state,
        tab: action.payload.environment,
        applications: [action.payload, ...state.applications],
      };
    }

    case ApiAction.OnUpdateApplication: {
      const existIndex = state.applications.findIndex(
        (item) => item.id === action.payload.id,
      );

      if (existIndex === -1) {
        return state;
      }

      return {
        ...state,
        applications: [
          ...state.applications.slice(0, existIndex),
          action.payload,
          ...state.applications.slice(existIndex + 1),
        ],
      };
    }

    case ApiAction.OnDeleteApplication: {
      const existIndex = state.applications.findIndex(
        (item) => item.id === action.payload,
      );

      return {
        ...state,
        applications: [
          ...state.applications.slice(0, existIndex),
          ...state.applications.slice(existIndex + 1),
        ],
      };
    }

    case ApiAction.OnSetTab: {
      return {
        ...state,
        tab: action.payload,
      };
    }

    case ApiAction.OnChangeApplicationScopes: {
      const applicationIndex = state.applications.findIndex(
        (item) => item.id === action.payload.id,
      );

      const application = state.applications[applicationIndex];

      if (applicationIndex === -1 || !application) {
        return state;
      }

      const scopes = application.scopes || [];

      const scopeIndex = scopes.findIndex(
        (item) => item === action.payload.scope,
      );

      const result = {
        ...application,
        scopes:
          scopeIndex === -1
            ? [...scopes, action.payload.scope]
            : [...scopes.slice(0, scopeIndex), ...scopes.slice(scopeIndex + 1)],
      };

      return {
        ...state,
        applications: [
          ...state.applications.slice(0, applicationIndex),
          result,
          ...state.applications.slice(applicationIndex + 1),
        ],
      };
    }

    default: {
      return state;
    }
  }
};

export default apiReducer;
