import { ApiRequest } from 'helpers/apiRequest';
import { Dates } from 'helpers/dates';
import { notifyWith } from 'helpers/notifyWith';

import { TAccountMargin } from './margin.types';

class MarginService extends ApiRequest {
  async getAccountMargin(
    account: string | null,
    date: Date,
    currency: string,
    lang: string,
  ): Promise<TAccountMargin | null> {
    try {
      if (!account) {
        return null;
      }

      const { data } = await this.fetch<TAccountMargin>({
        url: `/${lang}/clientsarea/account/margin/detail/`,
        params: {
          date: Dates.format(date, false),
          currency,
          account,
          excludeAccountPurposes: 'Card,Payment',
        },
      });

      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('getAccountMargin error: ', error?.message);
      return null;
    }
  }
}

export const marginService = new MarginService();
