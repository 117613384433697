import { Autocomplete, IconButton, Panel, Tooltip } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledWrapper = styled('div')`
  width: 100%;
  height: 100%;
`;

export const StyledBackgroundContainer = styled('div')`
  width: 100%;
  background-color: ${({ theme }) => theme.color.bg.primary};
`;

export const StyledAccountSettingsPanel = styled(Panel)`
  .PanelTitle {
    margin-bottom: 0;
  }
`;

export const StyledAccountSettingsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAccountSettingsIconButton = styled(IconButton)`
  font-family: ${({ theme }) => theme.font.main};
  font-size: 15px;
  font-weight: 500;
  padding-right: 24px;

  .IconButtonIconWrapper {
    width: 24px;
    height: 24px;
  }
`;

export const StyledAccountSettingsContainer = styled('div')`
  width: 100%;
  padding: 0 24px 24px 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledMultipleAccountSettingsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledAccountSettingsUsedLabel = styled('span')`
  font-weight: 400;
  font-size: 13px;
`;

export const StyledAccountSettingsAutocomplete = styled(Autocomplete)`
  width: 240px;
`;

export const StyledAccountSettingsTooltip = styled(Tooltip)`
  height: fit-content;
`;
