import classNames from 'classnames';
import { useCallback } from 'react';

import {
  AccordionArrow,
  AccordionButton,
  AccordionWrapper,
  StyledAccordionTitle,
} from './Accordion.styled';
import { AccordionArrowPosition, IAccordionProps } from './types';

export const Accordion = ({
  title,
  additionalContent,
  id,
  onChange,
  isActive,
  arrowPosition = AccordionArrowPosition.Left,
  className,
  children,
}: IAccordionProps) => {
  const handleAccordionClick = useCallback(() => onChange(id), [id, onChange]);

  const arrow = (
    <AccordionArrow className="AccordionArrow" isActive={isActive} />
  );

  const isArrowLeft = arrowPosition === 'left';

  return (
    <AccordionWrapper className={classNames('Accordion', className)}>
      <AccordionButton
        className="AccordionButton"
        type="button"
        onClick={handleAccordionClick}
      >
        <StyledAccordionTitle className="AccordionTitle">
          {isArrowLeft && arrow}
          {title}
          {!isArrowLeft && arrow}
        </StyledAccordionTitle>
        {!isActive && additionalContent}
      </AccordionButton>

      <div className="AccordionContent" hidden={!isActive}>
        {children}
      </div>
    </AccordionWrapper>
  );
};
