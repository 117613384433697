import { styled } from 'theme';

export const StyledTaxReportUnionSkeletonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledTaxReportUnionStatusSkeletonWrapper = styled('div')`
  display: flex;
  gap: 8px;
`;
