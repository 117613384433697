import { FC, useContext, useMemo, useState } from 'react';
import { IconButton, useData } from 'react-ui-kit-exante';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { TranslationContext } from 'contexts/TranslationContext';
import { partnerService, TPartnerLink } from 'services/partner';

import { TPartnerLinksTableLink } from '../../PartnerLinks.types';
import { PartnerLinksContext } from '../PartnerLinksContext';

import { PARTNER_EMPTY_EDIT_LINK } from './PartnerLinksActions.constants';
import {
  StyledActionWithConfirmationDeleteWrapper,
  StyledPartnerLinksActionsWrapper,
} from './PartnerLinksActions.styled';
import { TPartnerLinksActionsProps } from './PartnerLinksActions.types';
import { PartnerLinksDeleteAction, PartnerLinksEditAction } from './components';

export const PartnerLinksActions: FC<TPartnerLinksActionsProps> = ({
  linkId,
  isPageFirstElement,
}) => {
  const { currentLanguage } = useContext(TranslationContext);
  const { onEdit, onDelete, links } = useContext(PartnerLinksContext);

  const link = useMemo((): TPartnerLinksTableLink | null => {
    return links.find((current) => current.id === linkId) || null;
  }, [linkId, links]);

  const [editLink, setEditLink] = useState<TPartnerLink>(
    link || PARTNER_EMPTY_EDIT_LINK,
  );

  const { isLoading: isDeleting, fetchData: deleteLink } = useData({
    onFetch: () => {
      return partnerService.removeLink({
        lang: currentLanguage,
        id: linkId,
      });
    },
    onSuccess: (response) => {
      if (response) {
        onDelete(linkId);
      }
    },
    loading: false,
  });

  const { isLoading: isUpdating, fetchData: updateLink } = useData({
    onFetch: () => {
      const { name, id, site_url: siteUrl, url } = editLink || {};

      if (!siteUrl || !name || !id) {
        return Promise.resolve(null);
      }

      return partnerService.updateLink({
        lang: currentLanguage,
        link: {
          id,
          url,
          site_url: siteUrl,
          name,
        },
      });
    },
    onSuccess(response) {
      if (response) {
        onEdit(response);
        setEditLink(response);
      }
    },
    loading: false,
  });

  const isDeleteDisabled = !!link?.is_default;

  return !link ? null : (
    <StyledPartnerLinksActionsWrapper className="PartnerLinksActionsWrapper">
      <ActionWithConfirmation
        className="ActionWithConfirmationEdit"
        withCloseAfterConfirmation
        content={
          <PartnerLinksEditAction link={editLink} onChange={setEditLink} />
        }
        disabled={isUpdating}
        onConfirm={updateLink}
        confirmButtonNameKey="generic__action__save"
        cancelButtonNameKey="generic__action__cancel"
        closeHandler={() => setEditLink(link)}
        placement={isPageFirstElement ? 'bottom' : 'top'}
      >
        <IconButton iconName="EditIcon" iconSize={16} />
      </ActionWithConfirmation>
      <ActionWithConfirmation
        className="ActionWithConfirmationDelete"
        withCloseAfterConfirmation
        content={<PartnerLinksDeleteAction />}
        onConfirm={deleteLink}
        disabled={isDeleteDisabled || isDeleting}
        confirmButtonNameKey="generic__action__delete"
        cancelButtonNameKey="generic__action__cancel"
        TooltipContentWrapper={StyledActionWithConfirmationDeleteWrapper}
      >
        <IconButton
          iconName="DeleteIcon"
          iconSize={16}
          disabled={isDeleteDisabled}
        />
      </ActionWithConfirmation>
    </StyledPartnerLinksActionsWrapper>
  );
};
