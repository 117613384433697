import cn from 'classnames';
import { FC } from 'react';

import { StyledGenericTransferForm } from './GenericTransferForm.styled';
import { TGenericTransferFormProps } from './GenericTransferForm.types';

export const GenericTransferForm: FC<TGenericTransferFormProps> = ({
  children,
  className,
}) => (
  <StyledGenericTransferForm className={cn(className, 'GenericTransferForm')}>
    {children}
  </StyledGenericTransferForm>
);
