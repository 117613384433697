import { ToggleButtonGroup } from 'react-ui-kit-exante';

import { CurrencySelect } from 'components/CurrencySelect';
import { styled } from 'theme';

export const StyledCardSettingsOrderFormWrapper = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  flex: 0 1 400px;
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};

  ${({ theme }) => theme.breakpoints.up('lr')} {
    border-top: none;
  }
`;

export const StyledOrderInfoHeader = styled('h4')`
  margin: 0;

  font-family: ${({ theme }) => theme.font.header};
  font-size: 24px;
  font-weight: 400;
`;

export const StyledOrderCurrencySelect = styled(CurrencySelect)`
  width: 100%;
`;

export const StyledOrderCardTypeSelect = styled(ToggleButtonGroup)`
  flex: 1;

  .OrderCardTypeSelectOption {
    flex: 1;
  }
`;

export const StyledOrderCardFeeNote = styled('div')`
  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 13px;
  width: 100%;
`;
