import Cookies from 'js-cookie';
import { FC, useContext, useState } from 'react';
import { Button } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';
import { TAuthFlow } from 'services/branding';

import { SigninContext } from '../../contexts';

import {
  CONNECT_PROVIDER_COOKIE_NAME,
  CONNECT_TOKEN_COOKIE_NAME,
} from './SigninOauth.constants';
import {
  StyledSigninOauth,
  StyledSigninOauthFlowButton,
  StyledSigninOauthFlows,
  StyledSigninOauthWarning,
} from './SigninOauth.styled';
import { TSigninOauthProps } from './SigninOauth.types';
import { SigninOauthSkeleton } from './components/SigninOauthSkeleton';

export const SigninOauth: FC<TSigninOauthProps> = ({ flows }) => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { isLoadingSignin } = useContext(SigninContext);

  const connectToken = Cookies.get(CONNECT_TOKEN_COOKIE_NAME);
  const bindingProviderHint = Cookies.get(CONNECT_PROVIDER_COOKIE_NAME);

  const activeBindingFlow = flows?.find(
    (flow) => flow.hint === bindingProviderHint && flow.binding,
  );

  const [isNotAssociated, setIsNotAssociated] = useState(
    () => connectToken && activeBindingFlow,
  );

  if (isTranslationsLoading) {
    return <SigninOauthSkeleton />;
  }

  if (!flows?.length) {
    return null;
  }

  const showFlowText = flows.length === 1;

  const handleCancelClick = () => {
    Cookies.remove(CONNECT_TOKEN_COOKIE_NAME);
    Cookies.remove(CONNECT_PROVIDER_COOKIE_NAME);
    setIsNotAssociated(undefined);
  };

  const handleClick = (url: string) => {
    window.location.href = `${window.location.origin}${url}`;
  };

  return (
    <StyledSigninOauth className="SigninOauth">
      {isNotAssociated && activeBindingFlow && (
        <>
          <StyledSigninOauthWarning className="SigninOauthWarning">
            {t('generic__no_binding_description')}
          </StyledSigninOauthWarning>

          <Button color="secondary" onClick={handleCancelClick}>
            {t('generic__action__cancel')}
          </Button>
        </>
      )}

      <StyledSigninOauthFlows className="SigninOauthFlows">
        {flows.map((flow: TAuthFlow) => {
          if (!flow.hint) {
            return null;
          }

          const url = `/clientsarea/login/${flow.hint}/?login_page=True`;

          return (
            <StyledSigninOauthFlowButton
              key={flow.hint}
              onClick={() => handleClick(url)}
              disabled={isLoadingSignin}
              fullWidth
              color="transparent"
              icon={<img src={flow.iconLight} alt={flow.hint} />}
            >
              {showFlowText ? flow.text : null}
            </StyledSigninOauthFlowButton>
          );
        })}
      </StyledSigninOauthFlows>
    </StyledSigninOauth>
  );
};
