import { TFunction } from 'i18next';
import { Checkbox, ICellValue, IRowExpand } from 'react-ui-kit-exante';

import { NameCell } from 'components/Cells';
import { TTreeResponseTreeStructure } from 'services/instrumentsTree/instrumentsTree.types';

export const getColumns = (t: TFunction, loadingInstruments: Set<string>) => [
  {
    Header: t('symbol_id'),
    accessor: 'displayName',
    disableSortBy: true,
    id: 'name',
    Cell: ({ row }: { row: IRowExpand<TTreeResponseTreeStructure> }) => {
      return <NameCell row={row} loadingInstruments={loadingInstruments} />;
    },
    minWidth: 240,
  },
  {
    Header: t('layout__terms__allow_short'),
    accessor: 'short',
    disableSortBy: true,
    Cell: ({
      row: { values, original },
    }: ICellValue<TTreeResponseTreeStructure>) => {
      if (original.rowType === 'node') {
        return '';
      }

      return <Checkbox checked={values.short} disabled />;
    },
  },
];
