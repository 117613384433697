import { FC, SyntheticEvent, useContext, useMemo } from 'react';
import { ColumnSelect, IconButton, Table } from 'react-ui-kit-exante';

import { Collapse } from 'components/Collapse';
import { DEFAULT_MAX_PAGINATION_LENGTH } from 'constants/common';
import { CurrencyContext, TranslationContext } from 'contexts';
import { useSelectedColumns } from 'hooks/index';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { downloadAnalysisReport } from 'pages/Portfolio/tabs/Analysis/Analysis.helpers';
import { FilterContext } from 'pages/Portfolio/tabs/Analysis/contexts/FilterContext';
import { TAccountsMetrics, TMetricCurrency, TSymbol } from 'services/analysis';

import { defaultColumnKeys, getColumns } from './columns';

type TSymbolsTableProps = {
  data?: TAccountsMetrics['symbols'];
  isDataLoading?: boolean;
};

const tableId = 'clientsarea-analysis-symbols';

export const SymbolsTable: FC<TSymbolsTableProps> = ({
  data = [],
  isDataLoading,
}) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { currency } = useContext(CurrencyContext);
  const currencyFormatter = useCurrencyFormatter();
  const { filters } = useContext(FilterContext);

  const columns = useMemo(
    () =>
      getColumns({
        currency,
        t,
        currencyFormatter,
      }),
    [currency, t],
  );

  const { selectedColumn, handleColumnChange, resetColumns } =
    useSelectedColumns<TMetricCurrency>({
      columns,
      tableIds: ['symbols'],
      defaultTableId: 'symbols',
      defaultDisplayedColumns: defaultColumnKeys,
    });

  const displayedColumnKeys = useMemo(
    () =>
      Object.keys(selectedColumn).filter((key) => Boolean(selectedColumn[key])),
    [selectedColumn],
  );

  const downloadReport = (e: SyntheticEvent) => {
    e.stopPropagation();
    downloadAnalysisReport('symbol', filters, currentLanguage);
  };

  if (!isDataLoading && !data.length) {
    return null;
  }

  return (
    <Collapse
      title={t('metrics_per_symbols')}
      infoTooltip={
        <span>
          {t('layout__performance__use_metrics_to_evaluate_strategies')}
          <br />
          <br />
          {t('layout__performance__turnover_shows')}
          <br />
          <br />
          {t('layout__performance__pnl_shows')}
          <br />
          <br />
          {t('layout__performance__min_max_pnl_shows')}
        </span>
      }
      actions={
        <>
          <ColumnSelect
            columns={columns}
            selected={displayedColumnKeys}
            onChange={handleColumnChange}
            onReset={resetColumns}
            translator={t}
          />
          <IconButton
            iconName="XLSIcon"
            iconColor="secondary"
            iconSize={24}
            onClick={downloadReport}
            className="DailyPLDownload"
            disabled={isDataLoading}
          />
        </>
      }
      size="big"
      defaultExpanded
      withoutBorder
    >
      <Table<TSymbol>
        tableId={tableId}
        columns={columns}
        data={data}
        isLoading={isDataLoading}
        isHiddenColumnSelect
        translator={t}
        locale={currentLanguage}
        displayedColumnKeys={displayedColumnKeys}
        showScrollbar
        showTableInfo
        isFlexLayout
        hasPagination
        pageSize={DEFAULT_MAX_PAGINATION_LENGTH}
        skeletonsCount={4}
        autoResetSortBy={false}
      />
    </Collapse>
  );
};
