import { useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { TButtonsGroupOptions } from 'components/ButtonsGroup';
import { TTransfersHistoryTableRefAttributes } from 'components/TransfersHistoryTable';
import { TranslationContext } from 'contexts/TranslationContext';
import { TransferType } from 'services/transfer';
import { TWithdrawalHistoryEntry } from 'services/withdrawal';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { TTransferType } from '../../Transfers.types';
import { TransfersContext } from '../../context';

import {
  getWithdrawalAllowedAccountsByType,
  hasValidToInternalAccountLegacyPair,
  isInternalTransferShowNotAllowed,
} from './useTransfers.helpers';

export const useTransfers = () => {
  const isSuperuser = useAppSelector(
    (appState) => appState.accounts.isSuperuser,
  );
  const { t } = useContext(TranslationContext);
  const { selectedAccountId, isAccountsLoading } = useSelector(selectAccounts);
  const {
    accountDetailsState,
    transfersState,
    commissionsState,
    transfersDispatch,
    cashTransferTab,
    setCashTransferTab,
  } = useContext(TransfersContext);

  const tableRef = useRef<TTransfersHistoryTableRefAttributes>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamTransferType = searchParams.get('type') as TTransferType;
  const transferType: TTransferType = searchParamTransferType || 'funds';

  const isLoading =
    isAccountsLoading ||
    accountDetailsState.fetching.pending ||
    transfersState.fetching.pending ||
    transfersState.fetchingMetadata.pending;

  const allowedAccountsByType = getWithdrawalAllowedAccountsByType({
    allowedAccountIds: transfersState.accounts.lists.withdrawal_allowed,
    isSuperuser,
    withdrawalTypesMap: transfersState.wr_types,
    legalEntityMap: transfersState.client_id_le_map,
    subuserWithdrawalAllowedAccountIds:
      transfersState.accounts.lists.subuser_withdrawal_allowed,
    transferAllowedOwnAccountIds:
      transfersState.accounts.lists.transfer_allowed.own,
  });

  const isToMyAccountEnabled = useMemo(
    () =>
      Boolean(
        selectedAccountId &&
          accountDetailsState.targetAccounts.length > 0 &&
          accountDetailsState.currencies.length > 0,
      ),
    [accountDetailsState, selectedAccountId],
  );

  const isToInternalAccountEnabled = useMemo(() => {
    if (!selectedAccountId) {
      return false;
    }

    if (isInternalTransferShowNotAllowed(transfersState)) {
      return true;
    }

    if (
      !allowedAccountsByType.Internal?.includes(selectedAccountId) &&
      !transfersState.otherTransfer.isGbxp
    ) {
      return false;
    }

    if (
      !transfersState.otherTransfer.show ||
      transfersState.otherTransfer.disable
    ) {
      return false;
    }

    return hasValidToInternalAccountLegacyPair(
      transfersState,
      selectedAccountId,
    );
  }, [transfersState, selectedAccountId, allowedAccountsByType.Internal]);

  const legalEntity = useMemo(() => {
    if (!selectedAccountId) {
      return null;
    }

    const [id] = selectedAccountId.split('.');
    return transfersState.client_id_le_map[id];
  }, [selectedAccountId, transfersState.client_id_le_map]);

  const isWiredThirdPartyTransferEnabled =
    legalEntity &&
    transfersState.wr_types?.[TransferType.WireThirdParty]?.[legalEntity]
      ?.length;

  const isWiredSelfTransferEnabled =
    legalEntity &&
    transfersState.wr_types?.[TransferType.Wire]?.[legalEntity]?.length;

  const isToBankAccountTransferEnabled = useMemo(() => {
    if (!selectedAccountId) {
      return false;
    }

    return (
      (isWiredThirdPartyTransferEnabled || isWiredSelfTransferEnabled) &&
      (allowedAccountsByType.Wire?.includes(selectedAccountId) ||
        allowedAccountsByType['Wire third-party']?.includes(selectedAccountId))
    );
  }, [allowedAccountsByType]);

  const isSecuritiesTransferEnabled = useMemo(() => {
    if (!selectedAccountId) {
      return false;
    }

    return allowedAccountsByType.Security?.includes(selectedAccountId);
  }, [selectedAccountId, allowedAccountsByType.Security]);

  const isCryptoTransferEnabled = useMemo(() => {
    if (!selectedAccountId) {
      return false;
    }

    return allowedAccountsByType.Crypto?.includes(selectedAccountId);
  }, [selectedAccountId, allowedAccountsByType.Crypto]);

  const options = useMemo(() => {
    const result: TButtonsGroupOptions<TTransferType>[] = [];

    if (!selectedAccountId) {
      return result;
    }

    if (
      (isToMyAccountEnabled ||
        isToInternalAccountEnabled ||
        isToBankAccountTransferEnabled) &&
      transfersState.selectedAccountInfo.accountPurpose !== 'Partner'
    ) {
      result.push({
        id: 'funds',
        label: t('transfer-type__tab_cash'),
        value: 'funds',
      });
    }

    if (isSecuritiesTransferEnabled) {
      result.push({
        id: 'securities',
        label: t('transfer-type__tab_securities'),
        value: 'securities',
      });
    }

    if (isCryptoTransferEnabled) {
      result.push({
        id: 'crypto',
        label: t('transfer-type__tab_crypto'),
        value: 'crypto',
      });
    }

    if (
      transfersState.subuserWithdrawal.enable &&
      allowedAccountsByType[WITHDRAWAL_TYPE.SUBUSER_WITHDRAWAL]?.includes(
        selectedAccountId,
      )
    ) {
      result.push({
        id: 'subuser_withdrawal',
        label: t('layout__withdrawal-types__subuser_withdrawal'),
        value: 'subuser_withdrawal',
      });
    }

    return result;
  }, [
    t,
    isToMyAccountEnabled,
    isToInternalAccountEnabled,
    isSecuritiesTransferEnabled,
    isCryptoTransferEnabled,
    transfersState,
    isSuperuser,
    selectedAccountId,
  ]);

  const handleTransferTypeClick = (value: TTransferType) => {
    setSearchParams({ type: value });
  };

  const updateLastTransactionId = (payload: string | number) => {
    transfersDispatch({
      type: 'UPDATE_LAST_TRANSACTION_ID',
      payload,
    });
  };

  const handleRedirect = () => {
    const existRoute = options.find(
      (item) => item.value === searchParamTransferType,
    );
    const defaultRoute = options[0]?.value;
    if (!existRoute && defaultRoute) {
      setSearchParams({ type: defaultRoute });
    }
  };

  const setRepeatedWithdrawal = (withdrawal: TWithdrawalHistoryEntry) => {
    transfersDispatch({
      type: 'SET_REPEATED_WITHDRAWAL',
      payload: withdrawal,
    });

    setTimeout(() => {
      // scroll to transfer form view
      document.getElementsByClassName('PortfolioTabs')[0]?.scrollIntoView();
    }, 10);
  };

  const clearRepeatedWithdrawal = () =>
    transfersDispatch({ type: 'CLEAR_REPEATED_WITHDRAWAL' });

  useEffect(() => {
    if (!isLoading && options.length > 0) {
      handleRedirect();
    }
  }, [isLoading, transferType]);

  useEffect(() => {
    if (transfersState.lastTransactionId) {
      tableRef.current?.fetchLastTransactions();
    }
  }, [transfersState.lastTransactionId]);

  return {
    isLoading,

    transfersState,
    transfersDispatch,

    commissionsState,

    isSecuritiesTransferEnabled,
    isCryptoTransferEnabled,
    isToBankAccountTransferEnabled,

    options,
    tableRef,
    transferType,

    setRepeatedWithdrawal,
    clearRepeatedWithdrawal,
    handleTransferTypeClick,

    updateLastTransactionId,
    isToMyAccountEnabled,

    isToInternalAccountEnabled,

    cashTransferTab,
    setCashTransferTab,

    repeatedWithdrawal: transfersState.repeatedWithdrawal,
  };
};
