import { createContext } from 'react';
import { TIconName } from 'react-ui-kit-exante';

import { TPartnersContextData, TPartnerTabKey } from './Partner.types';

export const PARTNER_TABS: Readonly<
  Record<TPartnerTabKey, { labelKey: string; iconName: TIconName }>
> = {
  statistics: {
    labelKey: 'layout__partner__tab_statistics',
    iconName: 'UserManagementIcon',
  },
  links: {
    labelKey: 'layout__partner__tab_links',
    iconName: 'LinkCustomizationIcon',
  },
  payments: {
    labelKey: 'layout__partner__tab_payments',
    iconName: 'MyCardIcon',
  },
};
export const DEFAULT_PARTNER_URL = 'https://exante.eu';

export const PartnersContext = createContext<TPartnersContextData>({
  isPartnerLoading: false,
  partnerData: null,
  fetchPartnerData: () => Promise.resolve(),
});
