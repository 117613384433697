import { IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPartnerHeaderWrapper = styled('div')`
  padding: 0 24px;

  display: flex;
  justify-content: space-between;
`;

export const StyledPartnerHeaderCopyBlock = styled('div')`
  display: flex;
  align-items: center;
`;

export const StyledPartnerHeaderCopyBlockContent = styled('div')`
  display: flex;
  align-items: center;
`;

export const StyledPartnerHeaderCopyBlockLink = styled('span')`
  color: ${({ theme }) => theme.color.typo.action};
  align-items: center;
  gap: 16px;

  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
`;

export const StyledPartnerHeaderCopyBlockCopyTextWrapper = styled('span')`
  margin-left: 16px;
`;

export const StyledPartnerHeaderCopyBlockCopyText = styled('span')`
  color: ${({ theme }) => theme.color.typo.secondary};
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;

export const StyledPartnerHeaderCopyIconWrapper = styled('div')`
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.color.typo.action};

  border: 2px solid ${({ theme }) => theme.color.typo.action};
  border-radius: 50%;
`;

export const StyledPartnerHeaderCopyIcon = styled(IconButton)`
  svg {
    color: ${({ theme }) => theme.color.typo.action};

    path {
      stroke-width: 2px;
    }
  }

  :hover svg {
    color: ${({ theme }) => theme.color.typo.action};
  }
`;

export const StyledPartnerHeaderInfo = styled('div')`
  display: flex;
  gap: 32px;
`;

export const StyledPartnerHeaderDefaultLinkTooltip = styled('div')`
  align-self: flex-end;
  padding-bottom: 8px;
  margin-left: 8px;

  .MuiTooltip-tooltip {
    white-space: pre-wrap;
  }
`;
