import cn from 'classnames';
import React from 'react';

import { typeIconNames } from 'constants/icons';

import { InstrumentIcon } from '../../InstrumentIcon';
import { ICON_NAMES_WITH_BLACK_TEXT } from '../constants';
import { IAssetIconProps } from '../types';
import { getAssetShortName } from '../utils';

import FullIconStyles from './FullIcon.module.css';

type TFullIconProps = Pick<
  IAssetIconProps,
  'asset' | 'iconName' | 'className' | 'isCurrencySelect'
>;

export const FullIcon = (props: TFullIconProps): JSX.Element => {
  const {
    asset,
    iconName = typeIconNames.OTHER,
    isCurrencySelect = false,
    className = '',
  } = props;
  const isCFD = iconName === typeIconNames.CFD;
  const isBlackText = ICON_NAMES_WITH_BLACK_TEXT.includes(iconName);

  return (
    <div className={cn(FullIconStyles.FullIcon, className)}>
      <InstrumentIcon
        iconName={iconName}
        className={FullIconStyles.InstrumentIcon}
      />
      <span
        className={cn(FullIconStyles.NoIconUrl, {
          [FullIconStyles.TextBlack]: isBlackText,
          [FullIconStyles.CFD]: isCFD,
          [FullIconStyles.CurrencySelect]: isCurrencySelect,
        })}
      >
        {getAssetShortName(asset)}
      </span>
    </div>
  );
};
