import { TReport, TReportItem } from 'services/reports/reports.types';

import {
  REPORT_BUILDS_PERIODIC,
  REPORT_TYPES_WITH_TIME,
} from '../../../../pages/ReportEdit/components/ReportEditForm/ReportEditForm.constants';

export const hasNotNeededTime = (date = '') => {
  return date.includes('00:00:00') || date.includes('23:59:59');
};

export const requiresNoTime = (report: TReport, reportItem: TReportItem) => {
  // for periodic reports
  return (
    REPORT_BUILDS_PERIODIC.includes(report.build) ||
    // for custom reports created without time
    (report.build === 'custom' &&
      !REPORT_TYPES_WITH_TIME.includes(reportItem.report_type))
  );
};
