export const PORTFOLIO_TAB_ID = {
  SUMMARY: 'account_summary',
  ANALYSIS: 'performancereport',
  MARGIN: 'margin',
  DEPOSIT: 'deposit',
  TRANSACTIONS: 'account_transactions',
  TRADES: 'trades',
  EXCHANGE: 'exchange',
  TRANSFER: 'withdrawal:index',
  CARD_SETTINGS: 'cards-gps:index',
} as const;

export const REPORTS_TAB_ID = {
  PERIODIC_REPORTS: 'periodicReports',
  TAX_REPORTS: 'taxReports',
  CUSTOM_REPORTS: 'customReports',
  GENERATED_REPORTS: 'generatedReports',
} as const;

export const TERMS_TAB_ID = {
  COMMISSIONS: 'commissions',
  INTERESTS: 'interests',
  OVERNIGHTS: 'overnights',
  SHORT_ALLOWANCE: 'short_allowance',
} as const;

export const SECURITY_TAB_ID = {
  ACCESS_RIGHTS: 'access_rights',
  MFA: 'mfa',
  EXTERNAL_ACCOUNTS: 'external_accounts',
  PASSWORD: 'password',
} as const;

export const SETTINGS_TAB_ID = {
  PROFILE: 'profile',
  DOCUMENTS: 'documents',
  AGREEMENTS: 'agreements',
  SERVICES: 'services',
} as const;

export const PAGE_TABS_MAPPING = {
  portfolio: PORTFOLIO_TAB_ID,
  terms: TERMS_TAB_ID,
  securitysettings: SECURITY_TAB_ID,
  settingsProfile: SETTINGS_TAB_ID,
  reports: REPORTS_TAB_ID,
} as const;

export const ALL_TAB_ID: typeof PORTFOLIO_TAB_ID &
  typeof TERMS_TAB_ID &
  typeof SECURITY_TAB_ID &
  typeof SETTINGS_TAB_ID &
  typeof REPORTS_TAB_ID = {
  ...PORTFOLIO_TAB_ID,
  ...TERMS_TAB_ID,
  ...SECURITY_TAB_ID,
  ...REPORTS_TAB_ID,
  ...SETTINGS_TAB_ID,
} as const;
