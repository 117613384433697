import { AxiosError } from 'axios';

import { ApiRequest } from 'helpers/apiRequest';
import { getFormData } from 'helpers/formData';
import { notifyWith } from 'helpers/notifyWith';
import { delay } from 'helpers/timers';

import {
  TCreateDashboardApplicationPayload,
  TDashboard,
  TDashboardApplication,
  TDashboardApplicationError,
  TDashboardApplicationsResponse,
  TFetchDashboardResponse,
  TKeyScopeItem,
} from './dashboard.types';

class DashboardService extends ApiRequest {
  async fetchDashboard(lang: string): Promise<TDashboard | null> {
    try {
      const { data } = await this.fetch<TFetchDashboardResponse>({
        url: `/${lang}/clientsarea/spa-layout/?name=dashboard`,
      });

      let liveAccess = data.dashboard.opts.live_access;
      let demoAccess = data.dashboard.opts.demo_access;

      if (!liveAccess) {
        const {
          data: { clientId },
        } = await this.fetch<{ clientId: string }>({
          url: `/${lang}/clientsarea/dashboard/create-account/`,
          method: 'POST',
          ...getFormData([['env', 0]]),
        });

        liveAccess = clientId;
        await delay(1000);
      }

      if (!demoAccess) {
        const {
          data: { clientId },
        } = await this.fetch<{ clientId: string }>({
          url: `/${lang}/clientsarea/dashboard/create-account/`,
          method: 'POST',
          ...getFormData([['env', 1]]),
        });

        demoAccess = clientId;
        await delay(1000);
      }

      return {
        ...data.dashboard,
        opts: {
          ...data.dashboard.opts,
          live_access: liveAccess,
          demo_access: demoAccess,
        },
      };
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('fetchDashboard error: ', error);
      return null;
    }
  }

  async fetchDashboardApplications(): Promise<TDashboardApplication[]> {
    try {
      const { data } = await this.fetch<TDashboardApplicationsResponse>({
        url: `/clientsarea/dashboard/application/`,
      });

      return data.applications.sort((a, b) => {
        if (!a.created || !b.created) {
          return -1;
        }

        return b.created - a.created;
      });
    } catch (error) {
      const { response } = error as AxiosError;
      if (response?.status === 403) {
        notifyWith.error('layout__settings__api__forbidden');
      } else {
        notifyWith.genericNetworkError(error);
        console.error('fetchDashboardApplications error: ', error);
      }

      return [];
    }
  }

  async updateApplicationScope(id: string, scopes: TKeyScopeItem[]) {
    try {
      const { data } = await this.fetch<{ application: TDashboardApplication }>(
        {
          url: `/clientsarea/dashboard/application/change_scope/`,
          method: 'POST',
          ...getFormData([
            ['application_id', id],
            ...scopes.map((scope) => ['scopes', scope] as [string, string]),
          ]),
        },
      );

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async deleteApplication(lang: string, id: string) {
    try {
      await this.fetch({
        url: `/${lang}/clientsarea/dashboard/application/delete/`,
        method: 'POST',
        ...getFormData([['application_id', id]]),
      });
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('deleteApplication error: ', error);
    }
  }

  async createDashboardApplication(
    lang: string,
    {
      environment,
      password,
      description,
      scopes,
    }: TCreateDashboardApplicationPayload,
  ) {
    const { data } = await this.fetch<
      { application: TDashboardApplication } | TDashboardApplicationError
    >({
      url: `/${lang}/clientsarea/dashboard/application/`,
      method: 'POST',
      ...getFormData([
        ['environment', String(environment)],
        ['description', description],
        ['password', password],
        ...scopes.map((scope) => ['scopes', scope] as [string, string]),
      ]),
    });

    return data;
  }
}

export const dashboardService = new DashboardService();
