import { formatCurrency, TCreateCurrencyFormatter } from 'helpers/formatters';

export const getChallengeAccountSummaryItems = ({
  currentBalance,
  profitTarget,
  dailyDd,
  maxDd,
  currentEquity,
  dayTraded,
  remainingDays,
  currencyFormatter,
}: {
  currentBalance: number | null;
  profitTarget: string | null;
  dailyDd: number | null;
  maxDd: number | null;
  currentEquity: number | null;
  dayTraded: number | null;
  remainingDays: string | null;
  currencyFormatter: TCreateCurrencyFormatter;
}) => {
  return [
    {
      label: 'layout__challenge_account_summary__current_balance',
      value: formatCurrency(currencyFormatter, currentBalance),
      tooltip: 'layout__challenge_account_summary__current_balance_tooltip',
    },
    {
      label: 'layout__challenge_account_summary__current_equity',
      value: formatCurrency(currencyFormatter, currentEquity),
      tooltip: 'layout__challenge_account_summary__current_equity_tooltip',
    },
    {
      label: 'layout__challenge_account_summary__profit_target',
      value: formatCurrency(currencyFormatter, profitTarget),
      tooltip: 'layout__challenge_account_summary__profit_target_tooltip',
    },
    {
      label: 'layout__challenge_account_summary__daily_dd',
      value: formatCurrency(currencyFormatter, dailyDd),
      tooltip: 'layout__challenge_account_summary__daily_dd_tooltip',
    },
    {
      label: 'layout__challenge_account_summary__max_dd',
      value: formatCurrency(currencyFormatter, maxDd),
      tooltip: 'layout__challenge_account_summary__max_dd_tooltip',
    },
    {
      label: 'layout__challenge_account_summary__day_traded',
      value: dayTraded,
      tooltip: 'layout__challenge_account_summary__day_traded_tooltip',
    },
    {
      label: 'layout__challenge_account_summary__remaining_days',
      value: remainingDays,
      tooltip: 'layout__challenge_account_summary__remaining_days_tooltip',
    },
  ];
};
