import { useMediaQuery } from '@mui/material';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Table, useTableData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { prepareTableId } from 'helpers/prepareTableId';
import { reportsService } from 'services/reports';
import {
  TReportsFilters,
  TReportsResponse,
} from 'services/reports/reports.types';
import { useTheme } from 'theme';
import { TTypeOfFirstArgument } from 'types/TTypeOfFirstArgument';

import {
  PAGE_SIZES,
  PAGINATION_LENGTH,
} from './GeneratedReportsTable.constants';
import { TGeneratedReportsTableProps } from './GeneratedReportsTable.types';
import { getColumns, getColumnsMobile } from './columns';

export const GeneratedReportsTable: FC<TGeneratedReportsTableProps> = ({
  filterTypes,
  dateFrom,
  dateTo,
}) => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const abortController = useRef(new AbortController());

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [data, setData] = useState<TReportsResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const columns = useMemo(
    () => (isMobile ? getColumnsMobile({ t }) : getColumns({ t })),
    [t, isMobile],
  );

  const fetchData = useCallback(
    async ({ params }: any) => {
      const { limit, page } = params || {};

      setIsLoading(true);

      abortController.current.abort();
      abortController.current = new AbortController();

      const filters: TReportsFilters = {
        page: page + 1,
        page_size: limit,
        reportTypes: filterTypes.join(','),
        date_from: dateFrom ? `${dateFrom}T00:00:00.000000Z` : '',
        date_to: dateTo ? `${dateTo}T23:59:59.999999Z` : '',
      };

      const response = await reportsService.getGeneratedReports({
        ...filters,
        lang: currentLanguage,
        options: {
          signal: abortController.current.signal,
        },
      });

      // ignore results if canceled, there will be another attempt
      // as it cancels only when being refetched
      if (response?.canceled) {
        return;
      }

      setIsLoading(false);
      setData(response);
    },
    [currentLanguage, filterTypes, dateFrom, dateTo],
  );

  const tableDataArgs = useMemo<TTypeOfFirstArgument<typeof useTableData>>(
    () => ({
      data: { onFetch: fetchData },
      pagination: {
        getDefaultPagination: () => ({
          limit: PAGINATION_LENGTH,
          skip: 0,
        }),
      },
    }),
    [fetchData],
  );

  const { limit, page, setPage, setLimit } = useTableData(tableDataArgs);

  // reset page on filter change to prevent 404 error
  // as the length of the list may be reduced
  useEffect(() => {
    setPage(0);
  }, [filterTypes]);

  const total = data?.count || 0;
  const pageCount = Math.ceil(total / limit);

  const getCellProps = useCallback(() => {
    if (isMobile) {
      return {
        style: {
          padding: 0,
        },
      };
    }
    return {};
  }, [isMobile]);

  return (
    <Table
      tableId={prepareTableId('generatedReports')}
      locale={currentLanguage}
      translator={t}
      singleColored={isMobile}
      noHeader={isMobile}
      getCellProps={getCellProps}
      hasPagination
      columns={columns}
      showTableInfo
      isHiddenColumnSelect
      disableSortBy
      manualSortBy
      isFlexLayout
      pageSizes={PAGE_SIZES}
      data={data?.results || []}
      pageSize={PAGINATION_LENGTH}
      skeletonsCount={PAGINATION_LENGTH}
      isLoading={isLoading || isTranslationsLoading}
      serverPaginationProps={{
        total,
        setPage,
        pageCount,
        pageSize: limit,
        pageIndex: page,
        setPageSize: setLimit,
      }}
    />
  );
};
