import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledTermsHeader = styled(Panel)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  padding: 0 24px;
`;
