export const ACCOUNT_TYPE = {
  ANY: 'Any',
  PAYMENT: 'Payment',
  TRADING: 'Trading',
  PARTNER: 'Partner',
  AGGREGATED: 'Aggregated',
  LIABILITIES: 'Liabilities',
  EQUITY: 'Equity',
  ASSETS: 'Assets',
  CARD: 'Card',
} as const;
