import { styled } from 'theme';

export const StyledPartnerPaymentsSetupWrapper = styled('div')`
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.bg.basic};

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
    max-width: 424px;
    padding-bottom: 0;
    border-left: 1px solid ${({ theme }) => theme.color.bg.basic};
    border-bottom: none;
    padding-left: 24px;
  }
`;

export const StyledPartnerPaymentsSetupHeader = styled('h2')`
  margin: 0;

  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
`;

export const StyledPartnerPaymentsSetupValue = styled('span')`
  color: ${({ theme }) => theme.color.typo.primary};

  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledPartnerPaymentsSetupText = styled('span')`
  color: ${({ theme }) => theme.color.typo.secondary};

  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
`;
