import { TFunction } from 'i18next';

import { TAccount, TUserAccounts } from 'services/accounts';

import { TAccountsState } from './accounts.types';

export const mapUserAccountsResponse = (
  response: TUserAccounts | null,
  translate: TFunction,
): Partial<TAccountsState> => {
  if (!response) {
    return {};
  }

  const {
    accounts_choices_with_all: allAccounts,
    is_superuser: isSuperuser,
    tabs_mapping: accountsTabs,
    tabs_unmapping: accountsExcludeTabs,
    page_mapping: accountsPages,
    page_unmapping: accountsExcludePages,
  } = response;

  let accounts: TAccount[] = (allAccounts || []).map(({ id, text, types }) => ({
    value: id,
    label: text.replaceAll('All', translate('All', 'All')),
    types,
  }));
  if (isSuperuser) {
    accounts = accounts.filter(({ value }) => value !== 'all');
  }

  return {
    data: accounts,
    accountsTabs: accountsTabs || {},
    accountsExcludeTabs: accountsExcludeTabs || {},
    accountsPages: accountsPages || {},
    accountsExcludePages: accountsExcludePages || {},
  };
};
