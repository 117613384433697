import { reportsService } from 'services/reports';

import { TFetchCustomReportsParams } from './CustomReportsContext.types';

export const fetchCustomReports = async ({
  lang,
  params,
}: TFetchCustomReportsParams) => {
  const { limit, page } = params;

  return reportsService.getReports({
    lang,
    page: page + 1,
    pageSize: limit,
    type: 'custom',
  });
};
