import { FC, useContext } from 'react';

import { CopyButton } from 'components/CopyButton';
import { TranslationContext } from 'contexts';
import { DepositContext } from 'pages/Portfolio/tabs/Deposit/contexts';

import {
  StyledDepositSection,
  StyledDepositSectionActions,
  StyledDepositSectionBody,
  StyledDepositSectionHead,
  StyledDepositSectionHeading,
} from '../../../DepositWrapper/DepositWrapper.styled';

import { getRequisites } from './RequisitesCashSection.helpers';
import {
  StyledRequisiteItem,
  StyledRequisiteItemIconSkeleton,
  StyledRequisiteItemTextSkeleton,
  StyledRequisiteItemTitle,
  StyledRequisiteItemValue,
  StyledRequisiteList,
} from './RequisitesCashSection.styled';
import { OpenDocButton } from './components/OpenDocButton';
import { PersonalIban } from './components/PersonalIban';
import { SendEmailButton } from './components/SendEmailButton';

export const RequisitesCashSection: FC = () => {
  const { t } = useContext(TranslationContext);
  const {
    currency,
    banks,
    bank: bankAlias,
    isLoading: isDepositDataLoading,
  } = useContext(DepositContext);

  const bank = banks?.find((curr) => curr.alias === bankAlias);

  const bankInfo =
    bank &&
    getRequisites({
      bank,
      currency,
    });

  const getBankInfoText = () => {
    return (
      bankInfo?.map(({ title, value }) => `${t(title)}: ${value}`).join('\n') ||
      ''
    );
  };

  return (
    <StyledDepositSection className="DepositSection">
      <StyledDepositSectionHead className="DepositSectionHead">
        <StyledDepositSectionHeading className="DepositSectionHeading">
          {t('layout__deposit__requisites_title')}
        </StyledDepositSectionHeading>

        <StyledDepositSectionActions className="DepositSectionActions">
          <OpenDocButton />
          <SendEmailButton />
          <CopyButton
            text={getBankInfoText}
            iconColor="secondary"
            iconSize={24}
            disabled={isDepositDataLoading}
            data-test-id="requisites__copy__bank_info"
          />
        </StyledDepositSectionActions>
      </StyledDepositSectionHead>
      <StyledDepositSectionBody className="DepositSectionBody">
        {!bankInfo ? (
          t('layout__deposit__requisites_empty')
        ) : (
          <>
            <PersonalIban currency={currency} bank={bank} />
            <StyledRequisiteList className="RequisiteList">
              {bankInfo.map(({ title, value, isLoading }) => (
                <StyledRequisiteItem className="RequisiteItem" key={title}>
                  <StyledRequisiteItemTitle className="RequisiteItemTitle">
                    {t(title)}
                  </StyledRequisiteItemTitle>
                  {isLoading ? (
                    <StyledRequisiteItemValue className="RequisiteItemValue">
                      <StyledRequisiteItemTextSkeleton />
                      <StyledRequisiteItemIconSkeleton />
                    </StyledRequisiteItemValue>
                  ) : (
                    <StyledRequisiteItemValue className="RequisiteItemValue">
                      {value}
                      <CopyButton
                        text={value}
                        iconSize={16}
                        iconColor="secondary"
                        data-test-id={`requisites__copy__requisite_item__${title}`}
                      />
                    </StyledRequisiteItemValue>
                  )}
                </StyledRequisiteItem>
              ))}
            </StyledRequisiteList>
          </>
        )}
      </StyledDepositSectionBody>
    </StyledDepositSection>
  );
};
