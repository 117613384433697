import { FC, useContext, useMemo } from 'react';
import { Table } from 'react-ui-kit-exante';

import {
  ACCOUNT_ASSET_TABLE_ID,
  MAX_TABLE_PAGE_ITEMS,
} from 'components/Positions/Positions.constants';
import {
  getCommonColumns,
  getExistingColumns,
} from 'components/Positions/Positions.helpers';
import { getRowProps } from 'components/Positions/components/AssetTable/AssetTable.helpers';
import { TAssetTableProps } from 'components/Positions/components/AssetTable/AssetTable.types';
import { getFooterPropsWithTotal } from 'helpers/getFooterPropsWithTotal';
import { PortfolioTotalLoadingContext } from 'pages/Portfolio/tabs/PortfolioTotal/contexts/PortfolioTotalLoadingContext';

export const AssetTable: FC<TAssetTableProps> = ({
  tableId,
  positions,
  columns,
  displayedColumnKeys,
  requiredColumnKeys,
  isLoading,
}) => {
  const existingColumns = useMemo(
    () =>
      tableId === `${ACCOUNT_ASSET_TABLE_ID}-cash`
        ? getExistingColumns(positions, columns, requiredColumnKeys)
        : getCommonColumns(columns),
    [columns, positions, requiredColumnKeys, tableId],
  );

  const { isAccountDataLoading } = useContext(PortfolioTotalLoadingContext);

  return (
    <Table
      isLoading={isLoading || isAccountDataLoading}
      tableId={tableId}
      data={positions}
      columns={existingColumns}
      displayedColumnKeys={displayedColumnKeys}
      hasPagination={positions.length > MAX_TABLE_PAGE_ITEMS}
      pageSize={MAX_TABLE_PAGE_ITEMS}
      isFlexLayout
      getRowProps={getRowProps}
      getFooterProps={getFooterPropsWithTotal}
      autoResetSortBy={false}
    />
  );
};
