import { FC } from 'react';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { useDateFormatter } from 'hooks/useDateFormatter';

import { hasNotNeededTime, requiresNoTime } from './ReportPeriod.helpers';
import { TReportPeriodProps } from './ReportPeriod.types';

export const ReportPeriod: FC<TReportPeriodProps> = ({
  report,
  reportItem,
}) => {
  const dateFrom = reportItem.date_from;
  const dateTo = reportItem.date_to;

  const dateFormatter = useDateFormatter(false);
  const dateTimeFormatter = useDateFormatter(true);

  if (!dateFrom || !dateTo) {
    return <span>{DEFAULT_EMPTY_PLACEHOLDER}</span>;
  }

  // do not show time
  const noTime = requiresNoTime(report, reportItem);

  const hasNotTime = hasNotNeededTime(dateFrom) && hasNotNeededTime(dateTo);

  const withoutTime = noTime || hasNotTime;

  const formatter = withoutTime ? dateFormatter : dateTimeFormatter;

  const dateTimeFrom = formatter(dateFrom);
  const dateTimeTo = formatter(dateTo);

  return <span>{`${dateTimeFrom} — ${dateTimeTo}`}</span>;
};
