import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { reportsService } from 'services/reports/reports';
import { TReportsSettings } from 'services/reports/reports.types';
import { TChildren } from 'types/TChildren';

type TSettingsDataContext = {
  isLoading: boolean;
  isSaving: boolean;
  reportsSettings: TReportsSettings | null;
  saveReportsSettings: (data: TReportsSettings) => Promise<void>;
  setFtpError: Dispatch<SetStateAction<boolean>>;
  ftpError: boolean;
};

const initialState: TSettingsDataContext = {
  isLoading: false,
  isSaving: false,
  reportsSettings: null,
  saveReportsSettings: () => Promise.resolve(),
  ftpError: false,
  setFtpError: () => {},
};

export const ReportsContext = createContext<TSettingsDataContext>(initialState);

export const ReportsProvider: FC<TChildren> = ({ children }) => {
  const { isTranslationsLoading } = useContext(TranslationContext);

  const [reportsSettings, setReportsSettings] =
    useState<TReportsSettings | null>(null);

  const [ftpError, setFtpError] = useState<boolean>(false);

  const {
    fetchData: fetchReportsSettings,
    isLoading: isReportsSettingsLoading,
  } = useData({
    onFetch: () => reportsService.getReportsSettings(),
    onSuccess: (response) => setReportsSettings(response),
  });

  const {
    fetchData: saveReportsSettings,
    isLoading: isSaveReportsSettingsLoading,
  } = useData({
    loading: false,
    onFetch: (data: TReportsSettings) =>
      reportsService.saveReportsSettings(data),
    onSuccess: (response) => {
      if (response) {
        setReportsSettings(response);
      }
    },
  });

  useEffect(() => {
    fetchReportsSettings();
  }, []);

  const isLoading = isReportsSettingsLoading || isTranslationsLoading;
  const isSaving = isSaveReportsSettingsLoading;

  const value = useMemo(
    () => ({
      isLoading,
      isSaving,
      reportsSettings,
      saveReportsSettings,
      ftpError,
      setFtpError,
    }),
    [isLoading, reportsSettings, isSaving, ftpError],
  );

  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
};
