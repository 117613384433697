import { DEFAULT_MAX_ACCOUNTS_LENGTH } from 'constants/common';
import { accountsService } from 'services/accounts';
import { ACCOUNT_TYPE } from 'services/accounts/accounts.constants';
import { transferService, TransferType } from 'services/transfer';
import { TWithdrawalAccountDescriptionInfo } from 'services/withdrawal';
import { TAccountSummaryMetrics } from 'types/accounts';

import { TTargetMyAccount } from '../../../types';

import { TFetchStatePayload } from './types';

export const getAccountMetricsFromBalances = async (
  ids: string[],
  currency?: string | null,
  lang = 'en',
) => {
  const accounts = await accountsService.resolveAccountsBalance(
    ids,
    currency,
    lang,
  );

  return Promise.resolve(
    ids.map<TAccountSummaryMetrics>((id) => {
      const netAssetValue = accounts[id] || 0;

      const result: TAccountSummaryMetrics = {
        netAssetValue: String(netAssetValue),
        accountId: id,
        accountPurpose: ACCOUNT_TYPE.TRADING,
        cardNumber: '',
        cardStatus: null,
        cashFreeMoney: null,
        convertedDailyPnl: null,
        freeMoney: '0',
        label: id,
        marginUtilization: null,
        percentageTotalDailyPnl: null,
        requiredMargin: null,
        totalBlockedMargin: null,
        totalCash: null,
        totalFreeMoney: null,
        totalOrderMargin: null,
      };

      return result;
    }),
  );
};

export const fetchAccounts = async (
  ids: string[],
  lang?: string,
  currency?: string | null,
  isSuperuser: boolean | null = null,
  descriptions: Record<string, TWithdrawalAccountDescriptionInfo> = {},
) => {
  if (ids.length === 0) {
    return [];
  }

  const accountMetrics =
    ids.length > DEFAULT_MAX_ACCOUNTS_LENGTH || isSuperuser
      ? await getAccountMetricsFromBalances(ids, currency, lang)
      : await accountsService.getUserAccountsMetrics({
          currency,
          accounts: ids,
          date: new Date(),
        });

  return (
    accountMetrics
      ?.map<TTargetMyAccount>((account) => {
        const descriptionInfo = descriptions[account.accountId] || '';
        const description = descriptionInfo ? descriptionInfo.description : '';

        return {
          ...account,
          description,
        };
      })
      .filter((item) => {
        if (item.account_id) {
          return item.account_id?.length === 0;
        }

        return true;
      }) || []
  );
};

export const updateToMyAccountState = async (
  selectedAccountId: string,
  currency: string,
  targetAccounts: string[],
  currentLanguage: string,
  isSuperuser?: boolean | null,
): Promise<TFetchStatePayload> => {
  const [{ account }, accounts] = await Promise.all([
    await transferService.fetchAccountDetails(
      selectedAccountId,
      TransferType.ToMyAccount,
      currency,
    ),
    await fetchAccounts(
      targetAccounts,
      currentLanguage,
      currency,
      isSuperuser,
      {},
    ),
  ]);

  return {
    accounts,
    limit: account.limits[currency],
  };
};
