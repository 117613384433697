import { useContext, useEffect } from 'react';

import { VERSION_CHECK_INTERVAL } from 'constants/common';
import { isLocalhost } from 'constants/endpoints';
import { BrandingContext } from 'contexts/BrandingContext';
import { checkVersion } from 'helpers/checkVersion';
import { isLocalNginxStand } from 'helpers/isBranchStand';

import { usePollingEffect } from './usePollingEffect';

/**
 * Polls the latest app version to reload the page
 * if the current version does not match it
 */
export const useCheckVersion = () => {
  const { branding } = useContext(BrandingContext);

  const isDisabled = branding?.is_supervisor;

  const [, spawn] = usePollingEffect(checkVersion, [], {
    interval: VERSION_CHECK_INTERVAL,
    start: false,
  });

  useEffect(() => {
    if (!isLocalNginxStand() && !isLocalhost && !isDisabled) {
      spawn();
    }
  }, []);
};
