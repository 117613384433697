import { useMediaQuery } from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Input, Skeleton, useData } from 'react-ui-kit-exante';

import { DEFAULT_CURRENCY } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';
import { getFormData } from 'helpers/formData';
import { notifyWith } from 'helpers/notifyWith';
import { useAccountsBalances } from 'hooks/useAccountsBalances';
import { TMfaType } from 'services/mfa';
import { TransferType } from 'services/transfer';
import {
  TWithdrawalRequestTransferResponse,
  withdrawalService,
} from 'services/withdrawal';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { useTheme } from 'theme';
import { TValueOf } from 'types/TValueOf';

import {
  getFieldTooltipHint,
  getMappedCommissions,
  realizeCommission,
  withdrawalBalanceAutoupdateEffectFactory,
} from '../../Transfers.helpers';
import { AmountForm, Confirmation } from '../../components';
import { NoteText } from '../../components/NoteText/NoteText';
import { useTransfers } from '../../hooks';
import { StyledTransfersContainerWrapper } from '../shared.styled';

import {
  CRYPTO_DEFAULT_MIN_MPI,
  CRYPTO_DEFAULT_MIN_VALUE,
  CRYPTO_WITHDRAWAL_MODE,
  cryptoCurrenciesWithHint,
} from './Crypto.constants';
import { validateAmount, validateCryptoAddress } from './Crypto.helpers';
import {
  StyledCryptoContent,
  StyledCryptoHeader,
  StyledCryptoWrapper,
} from './Crypto.styled';
import { TWithdrawalCommissions } from './Crypto.types';
import { CryptoImportantNote } from './components';

export const Crypto: FC = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const {
    isLoading: isTransfersLoading,
    updateLastTransactionId,
    transfersState,
    clearRepeatedWithdrawal,
    commissionsState,
  } = useTransfers();
  const { balances, updateBalance } = useAccountsBalances(currentLanguage);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const [isAllFunds, setIsAllFunds] = useState(false);
  const [amount, setAmount] = useState('');
  const [address, setAddress] = useState('');
  const [currency, setCurrency] = useState('');
  const [isError, setIsError] = useState(false);
  const [mode, setMode] = useState<TValueOf<typeof CRYPTO_WITHDRAWAL_MODE>>(
    CRYPTO_WITHDRAWAL_MODE.EDIT,
  );
  const [isRequestingToken, setIsRequestingToken] = useState(false);

  const returnToEditMode = () => setMode(CRYPTO_WITHDRAWAL_MODE.EDIT);

  const minMpi = useMemo<{ min: number; mpi: number }>(
    () =>
      currency === 'EURS' && transfersState?.currencies
        ? {
            min: transfersState.currencies.cryptoEursMin,
            mpi: transfersState.currencies.cryptoEursMpi,
          }
        : {
            min: CRYPTO_DEFAULT_MIN_VALUE,
            mpi: CRYPTO_DEFAULT_MIN_MPI,
          },
    [currency, transfersState?.currencies],
  );

  const currencies = useMemo(
    () =>
      (selectedAccountId &&
        transfersState?.wr_types?.Crypto?.[
          transfersState?.client_id_le_map[selectedAccountId.split('.')[0]]
        ]) ||
      [],
    [selectedAccountId, transfersState],
  );

  const onAddressChange = (value: string) => {
    setIsError(
      !validateCryptoAddress({ address: value, allowEmpty: true, currency }),
    );
    setAddress(value);
  };

  const commissions = useMemo<TWithdrawalCommissions | null>(() => {
    const mappedCommissions = getMappedCommissions(
      commissionsState,
      transfersState?.commissions,
      WITHDRAWAL_TYPE.CRYPTO,
      currency,
    );

    if (!mappedCommissions) {
      return null;
    }

    const crossrate = mappedCommissions?.crossrates?.[currency];

    return {
      ...mappedCommissions,
      crossrate: crossrate || '1',
      currency: currency || DEFAULT_CURRENCY,
    };
  }, [transfersState?.commissions, currency, commissionsState]);

  const { fee, min, max, allFundsFee, available } = useMemo(() => {
    if (!selectedAccountId) {
      return {
        limit: null,
        fee: 0,
        max: 0,
        min: 0,
        allFundsFee: 0,
        available: null,
      };
    }

    const limitRaw = balances[currency]?.[selectedAccountId]?.limit || null;
    const limit = limitRaw ? Number(limitRaw) : null;

    const commission = realizeCommission(amount, commissions, minMpi.mpi);
    const allFundsCommission = realizeCommission(
      limit,
      commissions,
      minMpi.mpi,
    );

    return {
      available: limit,
      fee: commission.realized,
      allFundsFee: allFundsCommission.realized,
      limit,
      ...(commission && {
        min: `${commission.minimum} ${currency}`,
        max: `${commission.maximum} ${currency}`,
      }),
    };
  }, [
    balances[currency]?.[selectedAccountId || ''],
    selectedAccountId,
    amount,
    commissions,
    minMpi,
  ]);

  const amountValidator = (value: string) => validateAmount(value, minMpi.mpi);

  const { isLoading: isRequestingTransfer, fetchData: sendRequest } = useData({
    onFetch: async (
      token: string,
      confirmationType?: TMfaType,
    ): Promise<TWithdrawalRequestTransferResponse | null> => {
      if (!selectedAccountId || !token) {
        return Promise.resolve(null);
      }

      try {
        setIsRequestingToken(true);
      } catch (e) {
        const status = (e as AxiosError)?.response?.status;

        if (status === 400) {
          notifyWith.error('layout__code_expired');
        } else {
          notifyWith.genericNetworkError(e);
        }
      } finally {
        setIsRequestingToken(false);
      }

      if (!token) {
        return Promise.resolve(null);
      }

      const data = {
        type: WITHDRAWAL_TYPE.CRYPTO,
        amount,
        account: selectedAccountId,
        currency,
        crypto_address: address,
        confirm: '2fa',
        is_third_party: false,
        token,
        all_funds: isAllFunds,
        confirm_type: confirmationType,
      };

      return withdrawalService.requestTransfer(
        getFormData(Object.entries(data)).data,
      );
    },
    onSuccess: (response) => {
      if (response?.success) {
        updateLastTransactionId(response.withdrawal.id);
        returnToEditMode();

        notifyWith.success('layout__transfer__message_success');

        if (selectedAccountId) {
          updateBalance(selectedAccountId, currency);
        }

        setAmount('');
        setAddress('');
      }
    },
    loading: false,
  });

  const onSubmit = () => {
    if (isRequestingTransfer || isError || !address) {
      if (!address) {
        setIsError(true);
      }

      return;
    }

    setMode(CRYPTO_WITHDRAWAL_MODE.CONFIRM);
  };

  useEffect(
    withdrawalBalanceAutoupdateEffectFactory({
      selectedAccountId,
      currency,
      updateBalance,
    }),
    [selectedAccountId, currency],
  );

  useEffect(() => {
    if (!currency || !currencies.includes(currency)) {
      setCurrency(currencies[0]);
    }
  }, [balances, currencies, currency]);

  useEffect(() => {
    setIsError(!validateCryptoAddress({ address, allowEmpty: true, currency }));
  }, [currency]);

  useEffect(() => {
    const wr = transfersState.repeatedWithdrawal;

    if (!wr) {
      return;
    }

    clearRepeatedWithdrawal();

    const formattedAmount = wr.amount?.replace(/0*$/, '');
    if (!wr.all_funds) {
      setAmount(
        String(
          formattedAmount[formattedAmount.length - 1] === '.'
            ? formattedAmount.replace('.', '')
            : formattedAmount,
        ),
      );
    }
    setAddress(wr.crypto_address);
    setCurrency(wr.currency);
    setIsAllFunds(wr.all_funds);
  }, [transfersState.repeatedWithdrawal]);

  const addressProps = useMemo(
    () => ({
      value: address,
      onChange: onAddressChange,
      isError,
      label: t('layout__forms__recipient_cryptocurrency_address'),
      inputMessage: isError ? t('layout__transfer__crypto__address-error') : '',
      labelKey: 'layout__forms__recipient_cryptocurrency_address',
      placeholder: t('layout__transfer__crypto__address-placeholder'),
      disabled: mode === CRYPTO_WITHDRAWAL_MODE.CONFIRM,
    }),
    [address, onAddressChange, isError, t, mode],
  );

  const networkHint: string = cryptoCurrenciesWithHint.includes(currency)
    ? t('layout__transfer__crypto__network-hint')
    : '';

  const onAmountChange = (value: string) =>
    value.length <= 30 && setAmount(value);

  const feeProps = useMemo(
    () => ({
      effective: fee,
      visibleValue: `${commissions?.value}${commissions?.type}`,
      min,
      max,
      stdFee: commissions?.type !== '%',
      currency,
      allFunds: allFundsFee,
      mpi: minMpi.mpi,
    }),
    [fee, commissions, min, max, currency, allFundsFee],
  );

  const getFieldHint = (fieldName: string) => {
    return getFieldTooltipHint(
      fieldName,
      WITHDRAWAL_TYPE.CRYPTO,
      transfersState?.branding?.wr_fields_settings,
      t,
    );
  };

  return (
    <StyledTransfersContainerWrapper className="TransfersContainerWrapper">
      <StyledCryptoWrapper className="CryptoWrapper">
        {isDesktop && (
          <StyledCryptoContent className="CryptoContent">
            <StyledCryptoHeader className="CryptoHeader">
              {isTransfersLoading ? (
                <>
                  <Skeleton width={110} height={32} />
                  <Skeleton width={90} height={32} />
                </>
              ) : (
                <>
                  {t('layout__transfer__crypto__header')}
                  <CryptoImportantNote />
                </>
              )}
            </StyledCryptoHeader>
            {isTransfersLoading ? (
              <>
                <Skeleton width="100%" height={48} />
                <Skeleton width="100%" height={32} />
              </>
            ) : (
              <>
                {networkHint && <span>{networkHint}</span>}
                <Input
                  value={addressProps.value}
                  onChange={(e) => addressProps.onChange(e.target.value)}
                  placeholder={addressProps.placeholder}
                  disabled={addressProps.disabled}
                  error={addressProps.isError}
                  message={addressProps.inputMessage}
                  fullWidth
                  iconRight={getFieldHint('crypto_address')}
                />
                <NoteText type={WITHDRAWAL_TYPE.CRYPTO} />
              </>
            )}
          </StyledCryptoContent>
        )}
        {mode === CRYPTO_WITHDRAWAL_MODE.CONFIRM && (
          <Confirmation
            onConfirm={sendRequest}
            onCancel={returnToEditMode}
            loading={isTransfersLoading}
            requesting={isRequestingToken || isRequestingTransfer}
          />
        )}
        {mode === CRYPTO_WITHDRAWAL_MODE.EDIT && (
          <AmountForm
            balances={balances}
            type={TransferType.Crypto}
            amount={amount}
            currency={currency}
            currencies={currencies}
            commissionCurrency={commissions?.commissionCurrency || currency}
            limit={available}
            from={selectedAccountId}
            to={address}
            shortenTo
            disabled={isError}
            isLoading={isTransfersLoading || available === null}
            address={!isDesktop && addressProps}
            headHint={!isDesktop && networkHint}
            headNote={!isDesktop && <CryptoImportantNote />}
            maximumFractionDigits={8}
            noAmountDisabled
            noAmountCleanup
            fee={feeProps}
            isAllFunds={isAllFunds}
            onAmountChange={onAmountChange}
            onCurrencyChange={setCurrency}
            onAllfundsChange={setIsAllFunds}
            onSubmit={onSubmit}
            amountValidator={amountValidator}
          />
        )}
      </StyledCryptoWrapper>
    </StyledTransfersContainerWrapper>
  );
};
