import { isBefore } from 'date-fns';

import { Dates, today } from 'helpers/dates';

export function calculateDateFrom(value: string | null) {
  if (value) {
    const threeMonthsAgo = new Date(new Date().setDate(today.getDate() - 90));

    const convertedDateFrom = new Date(value);

    return isBefore(convertedDateFrom, threeMonthsAgo)
      ? Dates.format(threeMonthsAgo)
      : Dates.format(convertedDateFrom);
  }
  return null;
}
