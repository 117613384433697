import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts';

import { StyledDepositSection } from '../../../DepositWrapper/DepositWrapper.styled';

import {
  StyledCircleClockIcon,
  StyledGlobalIcon,
  StyledInfoSectionBlock,
  StyledInfoSectionContainer,
  StyledInfoSectionText,
  StyledPercentIcon,
} from './InfoCashSection.styled';

export const InfoCashSection: FC = () => {
  const { t } = useContext(TranslationContext);
  return (
    <StyledDepositSection className="DepositSection">
      <StyledInfoSectionContainer className="InfoSectionContainer">
        <StyledInfoSectionBlock className="InfoSectionBlock">
          <StyledGlobalIcon />
          <StyledInfoSectionText className="InfoSectionText">
            {t(
              'We accept wire transfers and support SEPA. Once you made your deposit, you may contact us to confirm receipt of payment.',
            )}
          </StyledInfoSectionText>
        </StyledInfoSectionBlock>
        <StyledInfoSectionBlock className="InfoSectionBlock">
          <StyledCircleClockIcon />
          <StyledInfoSectionText className="InfoSectionText">
            {t(
              'Please note that transfers can take from 1 to 4 business days.',
            )}
          </StyledInfoSectionText>
        </StyledInfoSectionBlock>
        <StyledInfoSectionBlock className="InfoSectionBlock">
          <StyledPercentIcon />
          <StyledInfoSectionText className="InfoSectionText">
            {t(
              'Please note: any fees any party involved charges for the transfer, including any intermediary bank fees, are the responsibility of the payer. We will subtract the total fee from the sum you deposit or own on your account.',
            )}
          </StyledInfoSectionText>
        </StyledInfoSectionBlock>
      </StyledInfoSectionContainer>
    </StyledDepositSection>
  );
};
