import { ReactNode } from 'react';

export type TAccordionContextValue = Record<string, boolean>;

export enum AccordionArrowPosition {
  Left = 'left',
  Right = 'right',
}
export interface IAccordionArrowProps {
  isActive: boolean;
}
export interface IAccordionProps {
  title: ReactNode;
  additionalContent?: ReactNode;
  id: string;
  onChange: (id: string) => void;
  isActive: boolean;
  children: ReactNode;
  arrowPosition?: AccordionArrowPosition;
  className?: string;
}
