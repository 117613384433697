import { useMediaQuery } from '@mui/material';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { useTheme } from 'theme';

import { StyledTooltipContentWrapper } from './ActionTooltip.styled';
import { TActionTooltipProps } from './ActionTooltip.types';

export const ActionTooltip = ({
  children,
  title,
  body,
  open,
  onClose,
}: TActionTooltipProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ActionWithConfirmation
      className="ActionTooltip"
      title={title}
      content={body}
      TooltipContentWrapper={StyledTooltipContentWrapper}
      externalOpened={open}
      openFromExternal
      placement={isMobile ? 'bottom-end' : 'right'}
      closeHandler={onClose}
      hideDefaultButtons
      withTopCloseButton
      size="max-content"
    >
      {children}
    </ActionWithConfirmation>
  );
};
