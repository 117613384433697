import { FC, useContext, useEffect, useMemo } from 'react';

import { AccountSelectionTitle } from 'components/AccountSelection/components/AccountSelectionTitle';
import { DEFAULT_MAX_ACCOUNTS_LENGTH } from 'constants/common';
import { BrandingContext, CurrencyContext, TranslationContext } from 'contexts';
import { AccountDataContext } from 'contexts/AccountDataContext/AccountDataContext';
import { useRouteAssociatedAccounts } from 'hooks';
import { ACCOUNT_SELECTOR_POSITION } from 'services/branding/branding.constants';
import { selectAccounts, selectSelectedDate } from 'store/accounts';
import { accountsActions } from 'store/accounts/accounts.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TValueOf } from 'types/TValueOf';

import { ACCOUNT_SELECTION_ACCOUNT_COUNT_KEY } from './AccountSelection.constants';
import { TAccountSelectionProps } from './AccountSelection.types';
import {
  AccountSelectionCarousel,
  AccountSelectionCarouselSkeleton,
  AccountSelectionPanel,
} from './components';
import { useAccountMetrics } from './hooks';

export const AccountSelection: FC<TAccountSelectionProps> = ({
  needToUpdateAccountData,
  titleKey,
  isHideSearch = false,
  isHideAddAccount = false,
  isHideDate = false,
  isHideBalanceButton = false,
  showBalance = false,
  onBalanceButtonClick,
  useAbsolutePositioning = false,
  hideTitle = false,
  hideActions = false,
  isHideQR = false,
  noCollapse = false,
  showCarousel = false,
  excludeAccountPurposes = [],
  className = '',
}) => {
  const dispatch = useAppDispatch();
  const accounts = useRouteAssociatedAccounts({
    excludeAccountTypes: excludeAccountPurposes,
  });
  const selectedDate = useAppSelector(selectSelectedDate);

  const { selectedAccountId, isAccountsFirstLoading } =
    useAppSelector(selectAccounts);

  const { fetchAccountData } = useContext(AccountDataContext);
  const { currency } = useContext(CurrencyContext);
  const { isTranslationsLoading } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const isAccountsCountAllowed: boolean =
    accounts.length > 1 && accounts.length <= DEFAULT_MAX_ACCOUNTS_LENGTH;

  const {
    accountsMetrics,
    isAccountsMetricsLoading,
    fetchAccountsMetrics,
    isAccountsMetricsForcedLoading,
  } = useAccountMetrics({
    accounts,
    selectedDate,
    excludeAccountPurposes,
  });

  const accountsCountFromStorage = Number(
    localStorage.getItem(ACCOUNT_SELECTION_ACCOUNT_COUNT_KEY),
  );

  const shouldConsiderMetrics: boolean =
    showCarousel ||
    (accountsCountFromStorage > 1 &&
      accountsCountFromStorage <= DEFAULT_MAX_ACCOUNTS_LENGTH);

  const isMetricsLoading: boolean =
    isAccountsMetricsForcedLoading ||
    (!accountsMetrics?.length &&
      (isAccountsFirstLoading ||
        (isAccountsCountAllowed && isAccountsMetricsLoading)));

  const isAccountSelectionCarouselLoading: boolean =
    isTranslationsLoading || (shouldConsiderMetrics && isMetricsLoading);

  const accountSelectorPosition:
    | TValueOf<typeof ACCOUNT_SELECTOR_POSITION>
    | undefined = branding?.branding?.ui_settings?.account_selector_position;

  const accountSelectorsVisible: boolean =
    accountSelectorPosition === ACCOUNT_SELECTOR_POSITION.BODY ||
    !accountSelectorPosition;

  const accountSelectionCarouselVisible: boolean =
    showCarousel ||
    (isAccountsCountAllowed &&
      accountsMetrics?.length > 0 &&
      accountSelectorsVisible);

  const onAccountSelect = (account: string) =>
    dispatch(accountsActions.selectAccount({ accountId: account }));

  const shouldAddAccountButtonBeHiddenForExante = useMemo((): boolean => {
    if (branding?.alias !== 'exante' || accounts.length > 3) {
      return false;
    }

    return (
      accounts.slice(0, 3).filter((account) => account.value.includes('.')) // not aggregated
        .length <= 2
    );
  }, [accounts.length <= 3 && accounts.length]);

  useEffect(() => {
    if (!selectedAccountId || !selectedDate) {
      return;
    }

    fetchAccountData();
  }, [selectedAccountId, String(selectedDate)]);

  useEffect(() => {
    if (needToUpdateAccountData) {
      fetchAccountsMetrics(currency);
      fetchAccountData();
    }
  }, [needToUpdateAccountData]);

  useEffect(() => {
    if (accounts.length) {
      localStorage.setItem(
        ACCOUNT_SELECTION_ACCOUNT_COUNT_KEY,
        String(accounts.length),
      );
    }
  }, [accounts]);

  const hideAddAccount = Boolean(
    isHideAddAccount ||
      branding?.branding?.isSummaryAddAccountButtonHidden ||
      shouldAddAccountButtonBeHiddenForExante,
  );

  return (
    <AccountSelectionPanel
      className={className}
      accounts={accounts}
      title={<AccountSelectionTitle titleKey={titleKey} isHideQR={isHideQR} />}
      isHideSearch={isHideSearch}
      hideDate={isHideDate}
      hideAddAccount={hideAddAccount}
      hideAccountSelector={!accountSelectorsVisible}
      hideBalanceButton={isHideBalanceButton}
      hideActions={hideActions}
      hideTitle={hideTitle}
      showBalance={showBalance}
      onBalanceButtonClick={onBalanceButtonClick}
      useAbsolutePositioning={useAbsolutePositioning}
      noCollapse={noCollapse}
    >
      {isAccountSelectionCarouselLoading ? (
        <AccountSelectionCarouselSkeleton />
      ) : (
        accountSelectionCarouselVisible && (
          <AccountSelectionCarousel
            accountMetrics={accountsMetrics}
            onSelect={onAccountSelect}
            currency={currency}
            selectedAccount={selectedAccountId}
          />
        )
      )}
    </AccountSelectionPanel>
  );
};
