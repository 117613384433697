import { FC } from 'react';

import { ReportPeriod } from 'pages/Reports/components/ReportPeriods/components/ReportPeriod';

import { ReportAccounts } from '../../../../../../../components/ReportAccounts';
import { ReportActions } from '../ReportActions';

import {
  StyledCheckmarkIcon,
  StyledReportUnion,
  StyledReportUnionBody,
  StyledReportUnionHead,
  StyledReportUnionItem,
  StyledReportUnionTitle,
  StyledReportUnionValue,
} from './ReportUnion.styled';
import { TReportUnionProps } from './ReportUnion.types';

export const ReportUnion: FC<TReportUnionProps> = ({ report }) => {
  return (
    <StyledReportUnion className="ReportUnion">
      <StyledReportUnionHead className="ReportUnionHead">
        <ReportAccounts report={report} isMobile />
        <ReportActions report={report} isMobile />
      </StyledReportUnionHead>

      <StyledReportUnionBody className="ReportUnionBody">
        {report.items.map((item) => {
          return (
            <StyledReportUnionItem className="ReportUnionItem" key={item.id}>
              <StyledReportUnionTitle className="ReportUnionTitle">
                {item.title}
              </StyledReportUnionTitle>
              <StyledReportUnionValue className="ReportUnionValue">
                <ReportPeriod report={report} reportItem={item} />
              </StyledReportUnionValue>
            </StyledReportUnionItem>
          );
        })}

        {report.format.map((fmt) => (
          <StyledReportUnionItem className="ReportUnionItem" key={fmt}>
            <StyledReportUnionTitle className="ReportUnionTitle">
              {fmt}
            </StyledReportUnionTitle>

            <StyledCheckmarkIcon />
          </StyledReportUnionItem>
        ))}
      </StyledReportUnionBody>
    </StyledReportUnion>
  );
};
