import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TReportsSaveSettings,
  TReportsSaveSettingsValues,
  TSettingsNotifications,
} from './notifications.types';

class SettingsNotificationsService extends ApiRequest {
  async getSettingsNotifications(): Promise<TSettingsNotifications | null> {
    try {
      const { data } = await this.fetch<TSettingsNotifications>({
        url: `/clientsarea/account/settings/notifications/`,
      });

      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('getSubscriptions', error);
      return null;
    }
  }

  async saveSettingsNotifications(
    values: TReportsSaveSettingsValues,
  ): Promise<TReportsSaveSettings | null> {
    try {
      const { data } = await this.fetch<TReportsSaveSettings>({
        url: '/clientsarea/account/settings/notifications/save/',
        method: 'POST',
        data: values,
      });

      notifyWith.success('generic__changes__saved');

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('ReportsService error: ', error);
      return null;
    }
  }
}

export const settingsNotificationsService = new SettingsNotificationsService();
