import { styled } from 'theme';

export const StyledCryptoScanWrapper = styled('div')`
  width: 100%;

  padding: 24px;

  border: 1px solid ${({ theme }) => theme.color.line.primary};

  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 32px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-direction: row;
    min-width: 400px;
  }
`;
