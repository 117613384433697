import { useMediaQuery } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { Button } from 'react-ui-kit-exante';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { TranslationContext } from 'contexts';
import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import {
  StyledValue,
  StyledValueName,
  StyledValueRow,
  StyledValuesContainer,
} from 'pages/Subscriptions/components/SubscriptionActionCell/SubscriptionActionCell.styled';
import { subscriptionsService } from 'services/settings/subscriptions';
import { useTheme } from 'theme';

import { StyledCell } from '../../Subscriptions.styled';

import { TSubscriptionActionCellProps } from './SubscriptionActionCell.types';

export const SubscriptionActionCell: FC<TSubscriptionActionCellProps> = ({
  feedPermission,
  paymentAccount,
  updateSubscriptions,
  isUpdating,
}) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const currencyFormatter = useCurrencyFormatter(feedPermission.currency);

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const onToggleSubscribe = async () => {
    setIsLoading(true);
    const data = {
      feedPermission,
      paymentAccount,
    };

    const response = await subscriptionsService.toggleSubscriptions(
      data,
      currentLanguage,
    );

    if (response) {
      updateSubscriptions(response);
    }
    setIsLoading(false);
  };

  const price = formatCurrency(
    currencyFormatter,
    feedPermission.feedType === 'retail'
      ? feedPermission.retailFeedPrice
      : feedPermission.professionalFeedPrice,
  );

  const buttonsIsDisabled = !paymentAccount || isLoading || isUpdating;

  return (
    <StyledCell className="TypeCell" title="">
      {feedPermission?.userFeedPermission?.subscribed ? (
        <Button
          textColor="secondary"
          color="transparent"
          onClick={onToggleSubscribe}
          disabled={buttonsIsDisabled}
        >
          {t('generic__action__unsubscribe')}
        </Button>
      ) : (
        <ActionWithConfirmation
          onConfirm={onToggleSubscribe}
          title={t('layout__settings__subscriptions_confirmation')}
          content={
            <>
              {t('layout__settings__subscriptions__renewed_every_month')}
              <StyledValuesContainer className="ValuesContainer">
                <StyledValueRow className="ValueRow">
                  <StyledValueName className="ValueName">
                    {t('layout__settings__subscriptions__payment_account')}
                  </StyledValueName>
                  <StyledValue className="Value">{paymentAccount}</StyledValue>
                </StyledValueRow>
                <StyledValueRow className="ValueRow">
                  <StyledValueName className="ValueName">
                    {t('generic__type')}
                  </StyledValueName>{' '}
                  <StyledValue className="Value">
                    {feedPermission.marketDataGroup}
                  </StyledValue>
                </StyledValueRow>
                <StyledValueRow className="ValueRow">
                  <StyledValueName className="ValueName">
                    {t('generic__price')}
                  </StyledValueName>{' '}
                  <StyledValue className="Value">{price}</StyledValue>
                </StyledValueRow>
              </StyledValuesContainer>
            </>
          }
          placement={isDesktop ? 'left' : 'bottom'}
          disabled={buttonsIsDisabled}
          confirmButtonNameKey="Confirm"
          cancelButtonNameKey="Cancel"
        >
          <Button
            textColor="action"
            color="transparent"
            disabled={buttonsIsDisabled}
          >
            {t('generic__action__subscribe')}
          </Button>
        </ActionWithConfirmation>
      )}
    </StyledCell>
  );
};
