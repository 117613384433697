import { useContext } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';
import { notifyWith } from 'helpers/notifyWith';
import { loginService } from 'services/login';
import { SigninStage } from 'services/login/login.types';

import { useCaptcha } from '../../../../../../../../hooks/useCaptcha';
import { SigninContext } from '../../../../../../contexts';

import {
  TSigninPasswordForm,
  TUseSigninByPasswordProps,
} from './useSigninByPassword.types';

export const useSigninByPassword = ({
  getValues,
  onError,
}: TUseSigninByPasswordProps) => {
  const { currentLanguage } = useContext(TranslationContext);
  const { isLoadingSignin, setIsLoadingSignin, setMfa, handleDone } =
    useContext(SigninContext);

  const {
    captcha,
    setCaptcha,
    resetCaptcha,
    captchaToken,
    setCaptchaToken,
    captchaRef,
  } = useCaptcha();

  const signin = async () => {
    if (captcha.enabled && !captchaToken) {
      captchaRef.current?.execute();
      return;
    }

    setIsLoadingSignin(true);

    const values = getValues();

    const response = await loginService.signin({
      lang: currentLanguage,
      login: values.login,
      password: values.password,
      captchaToken,
    });

    setIsLoadingSignin(false);

    if (!response) {
      return;
    }

    if (response?.errors) {
      Object.entries<string[]>(response.errors).forEach(
        ([subject, messages]) => {
          const field =
            // all non-field errors shows on the 'login' field
            subject === 'non_field_errors'
              ? 'login'
              : (subject as keyof TSigninPasswordForm);
          onError(field, messages[0]);
        },
      );

      resetCaptcha();
      return;
    }

    const { data } = response;

    switch (data?.stage) {
      case SigninStage.Captcha:
        setCaptcha((state) => ({ ...state, ...data }));
        break;
      case SigninStage.MFA:
        setMfa({
          type: data.mfa_type,
          key: data.key || '',
          tokenid: data.tokenid || 0,
        });
        break;
      case SigninStage.Done:
        handleDone(data.url);
        break;
      default:
        notifyWith.genericError();
        console.error(`Unhandled signin response`);
    }
  };

  return {
    signin,
    isLoading: isLoadingSignin,
    captcha,
    setCaptcha,
    setCaptchaToken,
    captchaRef,
  };
};
