import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TDottedItemWrapperProps } from './types';

export const DottedItemWrapper = styled('dl', {
  shouldForwardProp: blockNonNativeProps(['size']),
})<TDottedItemWrapperProps>`
  display: flex;
  justify-content: space-between;
  margin: 0 0 2px 0;
  padding: 0;
  color: ${({ theme }) => theme.color.typo.promo};
  font-size: ${({ size }) => (size === 'small' ? '13px' : '15px')};
  line-height: 24px;
  border-bottom: 1px dashed ${({ theme }) => theme.color.line.primary};
`;

export const DottedItemName = styled('dt')`
  margin-bottom: -2px;
  padding-right: 2px;

  background-color: ${({ theme }) => theme.color.bg.primary};
`;

export const DottedItemValue = styled('dd')`
  margin: 0 0 -2px 0;
  padding-left: 2px;

  font-weight: 600;
  background-color: ${({ theme }) => theme.color.bg.primary};
`;
