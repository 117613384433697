import { css } from 'react-ui-kit-exante';

import AppStoreIcon from 'assets/icons/appstore.svg';
import GooglePlayIcon from 'assets/icons/googleplay.svg';
import LinuxIcon from 'assets/icons/linux.svg';
import MacOSIcon from 'assets/icons/mac.svg';
import WindowsIcon from 'assets/icons/windows.svg';
import { styled } from 'theme';

import { Anchor } from '../Anchor';

import { StyledAnchorProps } from './Links.types';

export const StyledFooterDownloadLinks = styled('div')`
  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    flex-basis: 60%;
  }
`;

export const StyledDownloadLinksTitle = styled('h3')`
  display: none;
  margin: 15px 0 10px;
  font-weight: normal;
  font-size: 12px;
`;

export const StyledDownloadLinksMobile = styled('div')`
  display: block;

  ${({ theme }) => theme.breakpoints.down('tablet')} {
    display: flex;
    gap: 10px;
  }
`;

export const StyledDownloadLinksListItem = styled('div')`
  margin-bottom: 15px;
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const StyledDownloadLinksOthers = styled('p')`
  margin-top: 10px;
  font-size: 12px;
  text-align: left;
`;

export const StyledDownloadLinksSoon = styled('p')`
  margin: 0;
  font-size: 12px;
  font-style: italic;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0;
  }
`;

export const StyledDownloadLinksQR = styled('div')`
  position: relative;
  z-index: 1;
  display: block;
  padding: 5px;
  text-align: center;
  background: ${({ theme }) => theme.color.bg.basic};
  border: 1px solid ${({ theme }) => theme.color.icon.secondary};
  border-radius: 4px;
  transition: 0.3s;

  &:hover {
    z-index: 10;
    transform: scale(2);
  }

  ${({ theme }) => theme.breakpoints.down('tablet')} {
    display: none;
  }
`;

export const StyledDownloadLinksQRPic = styled('img')`
  width: 36px;
  height: 36px;
  max-width: 36px;
  max-height: 36px;
  vertical-align: top;
`;

export const StyledAnchor = styled(Anchor)<StyledAnchorProps>`
  ${({ isDefault, theme }) =>
    isDefault &&
    css`
      display: block;
      flex-grow: 1;
      height: 48px;

      border: 1px solid ${theme.color.icon.secondary};
      border-radius: 4px;
    `}

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      width: 100%;
    `}

  ${({ userOs, isAndroid, isIos }) => {
    let backgroundImage = null;
    if (isAndroid) {
      backgroundImage = GooglePlayIcon;
    } else if (isIos) {
      backgroundImage = AppStoreIcon;
    } else if (userOs) {
      const osImageMapping = {
        win: WindowsIcon,
        mac: MacOSIcon,
        linux: LinuxIcon,
      };
      backgroundImage = osImageMapping[userOs as keyof typeof osImageMapping];
    }

    if (!backgroundImage) {
      return null;
    }

    return css`
      background: url(${backgroundImage}) 50% no-repeat;
    `;
  }}
`;
