import { unescape } from 'lodash';
import { FC, useContext } from 'react';
import { Modal, ModalProps } from 'react-ui-kit-exante';

import { NotificationsContext, TranslationContext } from 'contexts';

export const NotificationModal: FC = () => {
  const { acceptNotification, modalNotification, isAccepting } =
    useContext(NotificationsContext);
  const { t } = useContext(TranslationContext);

  const confirmButtonProps: Partial<
    Record<'confirmButton', ModalProps['confirmButton']>
  > = modalNotification
    ? {
        confirmButton: {
          confirmButtonIsDisabled: isAccepting,
          handleConfirm: acceptNotification,
          confirmButtonName:
            modalNotification?.button_label || t('generic__action__ok'),
          hidden: !!modalNotification?.notified,
        },
      }
    : {};

  const cancelButtonProps: ModalProps['cancelButton'] = {
    hidden: true,
  };

  const modalBody = modalNotification ? unescape(modalNotification.body) : '';

  return (
    <Modal
      isOpened={modalNotification !== null}
      title={modalNotification?.title || ''}
      onClose={acceptNotification}
      fullWidth
      {...confirmButtonProps}
      cancelButton={cancelButtonProps}
    >
      <div
        className="NotificationModalBody"
        dangerouslySetInnerHTML={{ __html: modalBody }}
      />
    </Modal>
  );
};
