import { styled } from 'theme';

export const StyledPartnerPaymentsWrapper = styled('div')`
  margin-top: -24px;

  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;
