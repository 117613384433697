import { createAsyncThunk } from '@reduxjs/toolkit';

import { accountsService, TAccount } from 'services/accounts';
import { ACCOUNT_TYPE } from 'services/accounts/accounts.constants';
import { TWithdrawalState, withdrawalService } from 'services/withdrawal';
import { TWithdrawalMetadataState } from 'store/withdrawal/withdrawal.types';

export const fetchWithdrawalState = createAsyncThunk(
  'withdrawal/fetchWithdrawalState',
  async (currentLanguage: string): Promise<TWithdrawalState | null> => {
    try {
      const response = await withdrawalService.getInitialState(currentLanguage);

      return response?.state || null;
    } catch (error) {
      console.error('fetchWithdrawalStateAction', error);
      return null;
    }
  },
);

export const fetchMetadataState = createAsyncThunk(
  'withdrawal/fetchMetadataState',
  async (
    selectedAccount?: TAccount | null,
  ): Promise<TWithdrawalMetadataState | null> => {
    if (!selectedAccount) {
      return null;
    }

    if (selectedAccount.types.includes(ACCOUNT_TYPE.AGGREGATED)) {
      return null;
    }

    const selectedAccountId = selectedAccount.value;

    const selectedAccountInfo =
      await accountsService.getAccountInfo(selectedAccountId);

    return {
      selectedAccountInfo,
    };
  },
);
