import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TDownloadsListItemProps } from './DownloadsList.types';

export const StyledDownloadsListItem = styled('div', {
  shouldForwardProp: blockNonNativeProps(['orderNumMobile']),
})<TDownloadsListItemProps>`
  display: flex;
  justify-content: space-around;

  border-top: 1px solid ${({ theme }) => theme.color.line.primary};

  ${({ theme }) => theme.breakpoints.up('lr')} {
    border-top: none;
    border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};

    &:nth-child(odd) {
      border-right: 1px solid ${({ theme }) => theme.color.line.primary};
    }
  }

  ${({ theme }) => theme.breakpoints.down('lr')} {
    // reorder mobile-related items to be first on mobile version
    order: ${({ orderNumMobile }) => orderNumMobile || 'initial'};
  }
`;
export const StyledDownloadText = styled('p')`
  margin: 8px 0 0;

  font-family: ${({ theme }) => theme.font.main};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const StyledDownloadAnchor = styled('a')`
  color: ${({ theme }) => theme.color.typo.action};
`;

export const StyledDownloadLinkWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    gap: 8px;
    max-width: min-content;
  }
`;

export const StyledDownloadsList = styled('div')`
  display: grid;

  grid-template-columns: 1fr;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    grid-template-columns: 1fr 1fr;

    border-top: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;
