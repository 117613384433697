import Cookies from 'js-cookie';

import { BASE_URL } from 'constants/endpoints';
import { notifyWith } from 'helpers/notifyWith';

import { TDownloadCorporateActionsReportProps } from './CorporateActions.types';

export const downloadCorporateActionsReport = async ({
  url,
  fileName,
}: TDownloadCorporateActionsReportProps): Promise<boolean> => {
  const fixedUrl = url.toString().includes('http')
    ? url
    : BASE_URL + url.toString();

  const requestUrl = new URL(fixedUrl);
  requestUrl.searchParams.set('ca_type', 'Dividend');
  requestUrl.searchParams.set('limit', String(1_000_000));

  let fileUrl: string;
  try {
    const response = await fetch(requestUrl, {
      headers: { Authorization: `Bearer ${Cookies.get('x_access')}` },
    });

    if (!response.ok) {
      notifyWith.genericError();
      return false;
    }

    const blob = await response.blob();
    fileUrl = URL.createObjectURL(blob);
  } catch (e) {
    return false;
  }

  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.href = fileUrl;
  anchor.download = fileName;
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();

  return true;
};
