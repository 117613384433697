import { styled } from 'theme';

export const StyledGenericTransferFormTitle = styled('h3')`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0 0 24px;

  font-weight: normal;
  font-size: 20px;
  font-family: ${({ theme }) => theme.font.header};
  line-height: 1.2;
  white-space: nowrap;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 24px;
  }
`;
