import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts';
import { prepareCardExpirationDate } from 'pages/CardSettings/CardSettings.helpers';
import { CardSettingsContext } from 'pages/CardSettings/contexts/CardSettingsContext/CardSettingsContext';

import { CardSettingsCard, IssueAction } from '../CardSettingsCard';

import {
  StyledCardsCardActiveHint,
  StyledCardSettingsListWrapper,
} from './CardSettingsList.styled';
import { CardSettingsListSkeleton } from './CardSettingsListSkeleton';

export const CardSettingsList: FC = () => {
  const { t } = useContext(TranslationContext);
  const {
    cards,
    cardholderFullName: fullName,
    cardsLoading,
    onIssue,
    order,
  } = useContext(CardSettingsContext);

  return (
    <div className="CardSettingsList">
      {cardsLoading ? (
        <CardSettingsListSkeleton />
      ) : (
        <StyledCardSettingsListWrapper className="CardSettingsListWrapper">
          <StyledCardsCardActiveHint className="CardsCardActiveHint">
            {t('layout__cards__message_card_active')}
            <IssueAction
              onClick={onIssue}
              disabled={!order.available}
              {...(!order.available && {
                message: t(
                  'layout__cards__get_your_card_not_available__reason',
                ),
              })}
            />
          </StyledCardsCardActiveHint>
          {(cards || []).map((card) => (
            <CardSettingsCard
              key={card.id}
              cardId={card.id}
              cardNumber={card.pan}
              type={card.limits_group}
              holderName={fullName || ''}
              expirationDate={prepareCardExpirationDate(card.expiry_date)}
              status={card.status}
            />
          ))}
        </StyledCardSettingsListWrapper>
      )}
    </div>
  );
};
