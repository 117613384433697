import { FC } from 'react';

import { TChildren } from 'types/TChildren';

import { StyledChecklistWrapper } from './Checklist.styled';

export const Checklist: FC<TChildren> = ({ children }) => (
  <StyledChecklistWrapper className="ChecklistContainer">
    {children}
  </StyledChecklistWrapper>
);
