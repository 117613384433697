import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { isLocalhost } from 'constants/endpoints';
import { BrandingContext } from 'contexts';

export const HeaderInclude = () => {
  const { branding } = useContext(BrandingContext);
  const headerScript = isLocalhost
    ? ''
    : branding?.branding?.header_include || '';
  const parser = new DOMParser();
  const doc = parser.parseFromString(headerScript, 'text/html');
  const scripts = doc.getElementsByTagName('script');

  const bodyScript = isLocalhost ? '' : branding?.branding?.body_include || '';

  useEffect(() => {
    document.body.insertAdjacentHTML('afterbegin', bodyScript);
  }, []);

  return (
    <Helmet>
      {[...scripts].map((script, index) => {
        const attrs = Object.fromEntries(
          [...script.attributes].map((Attr) => [Attr.localName, Attr.value]),
        );
        return (
          <script key={index} {...attrs}>
            {script.innerHTML}
          </script>
        );
      })}
    </Helmet>
  );
};
