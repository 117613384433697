import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { AccountDataContext } from 'contexts/AccountDataContext/AccountDataContext';
import {
  performanceService,
  TPerformance,
  TTimeRangesTypes,
} from 'services/performance';
import { TChildren } from 'types/TChildren';

type TimeRangeOption = {
  label: string;
  value: string;
};

export type TAnalysisPageState = {
  timeRangesOptions: TimeRangeOption[];
  timeRanges?: TPerformance['timeRanges'] | null;
  defaultTimeRangeKey: TTimeRangesTypes;
  config?: TPerformance | null;
  isLoading?: boolean;
};

const initialState = {
  timeRangesOptions: [],
  timeRanges: null,
  defaultTimeRangeKey: 'current-month' as TTimeRangesTypes,
  config: null,
  isLoading: false,
};

export const AnalysisPageContext =
  createContext<TAnalysisPageState>(initialState);

const getPerformanceConfig = async (lang: string) => {
  try {
    return await performanceService.getPerformanceConfig(lang);
  } catch (e) {
    return null;
  }
};

export const AnalysisPageProvider: FC<TChildren> = ({
  children,
}): ReactElement | null => {
  const { currentLanguage } = useContext(TranslationContext);

  const { isLoading: isAccountDataLoading } = useContext(AccountDataContext);

  const {
    data,
    isLoading: isConfigLoading,
    fetchData,
  } = useData({
    onFetch: () => getPerformanceConfig(currentLanguage),
  });

  useEffect(() => {
    fetchData();
  }, [currentLanguage]);

  const preparedTimeRangesOptions = () => {
    const ranges = data?.timeRanges;
    return Object.values(ranges || {}).map(({ title }, index) => ({
      label: title,
      value: Object.keys(ranges || {})[index],
    }));
  };

  const isLoading = isConfigLoading || isAccountDataLoading;

  const value = useMemo(
    () => ({
      ...initialState,
      timeRangesOptions: preparedTimeRangesOptions(),
      timeRanges: data?.timeRanges,
      config: data,
      isLoading,
    }),
    [data, isLoading],
  );

  return (
    <AnalysisPageContext.Provider value={value}>
      {children}
    </AnalysisPageContext.Provider>
  );
};
