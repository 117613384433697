import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledEmailVerification = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 326px;
  gap: 24px;
`;

export const StyledEmailVerificationText = styled('p')`
  margin: 0;

  font-family: ${({ theme }) => theme.font.main};
  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  b {
    color: ${({ theme }) => theme.color.typo.primary};
    font-weight: 700;
  }
`;

export const StyledAuthPrivacyIcon = styled('img')`
  margin: 0 auto;
  width: 56px;
  height: 48px;
`;

export const StyledResendButton = styled(Button)`
  text-transform: none;

  &.MuiButton-root {
    line-height: 18px;
  }
`;
