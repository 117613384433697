import { FC, useContext, useMemo } from 'react';
import { Button, Skeleton } from 'react-ui-kit-exante';

import { InfoIcon } from 'assets/icons';
import { Text } from 'components/Text';
import { CurrencyContext } from 'contexts/CurrencyContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';

import { getMappedCommissions } from '../../../../Transfers.helpers';
import { Confirmation } from '../../../../components';
import { TransfersContext } from '../../../../context';
import { SecuritiesContext } from '../../contexts/SecuritiesContext/SecuritiesContext';

import { formatAmount } from './SecuritiesAmount.helpers';
import {
  StyledAmountInfo,
  StyledAmountInfoBottom,
  StyledIconButton,
  StyledSecuritiesAmountFormContainer,
  StyledSecuritiesAmountFormHeader,
  StyledValueRow,
} from './SecuritiesAmount.styled';
import { TSecuritiesAmountProps } from './SecuritiesAmount.types';
import { SecurityAmountField } from './components/SecurityAmountField';
import { SecuritiesAmountContext } from './contexts/SecurityAmountContext/SecurityAmountContext';

export const SecuritiesAmount: FC<TSecuritiesAmountProps> = ({
  loading,
  handleConfirm,
  handleCancelConfirmation,
}) => {
  const { t } = useContext(TranslationContext);
  const { currency } = useContext(CurrencyContext);
  const { transfersState, commissionsState } = useContext(TransfersContext);
  const { isShowConfirmation, isTransferProcessing } =
    useContext(SecuritiesContext);

  const {
    isRemoveAmountDisabled,
    removeAmount,
    addAmount,
    fields,
    onFieldChange,
    selectedSecurities,
    options,
    securitiesOptions,
    fieldsErrors,
    positionsIsLoading,
    instrumentsIsLoading,
    isShowFieldError,
    setIsShowFieldError,
    isNotAvailable,
  } = useContext(SecuritiesAmountContext);

  const mappedCommissions = useMemo(() => {
    return getMappedCommissions(
      commissionsState,
      transfersState.commissions,
      WITHDRAWAL_TYPE.SECURITY,
      currency,
    );
  }, [transfersState.commissions, commissionsState, currency]);

  const fee = mappedCommissions?.value;
  const feeCurrency = mappedCommissions?.type;

  const onPreventValidate = () => {
    setIsShowFieldError(true);
  };

  const isLoading =
    loading ||
    positionsIsLoading ||
    instrumentsIsLoading ||
    transfersState.fetching.idle ||
    transfersState.fetching.pending;

  if (isShowConfirmation) {
    return (
      <Confirmation
        onConfirm={handleConfirm}
        onCancel={handleCancelConfirmation}
        loading={loading}
        requesting={isTransferProcessing}
      />
    );
  }

  return (
    <StyledSecuritiesAmountFormContainer className="SecuritiesAmountFormContainer">
      <StyledSecuritiesAmountFormHeader className="SecuritiesAmountFormHeader">
        {loading ? (
          <Skeleton width={100} height={32} />
        ) : (
          <Text size="24px">
            {t('layout__subaccount_transfer__amount__title')}
          </Text>
        )}
        <StyledIconButton
          className="IconButton"
          iconName="AddIcon"
          iconSize={24}
          disabled={
            selectedSecurities.length === options.length || isNotAvailable
          }
          onClick={addAmount}
          data-test-id="add-securities-button"
          label={t('layout__fields__add_securities')}
          iconColor="action"
          showSkeleton={loading || isLoading}
        />
      </StyledSecuritiesAmountFormHeader>

      {fields.map((field, index) => {
        return (
          <SecurityAmountField
            key={index}
            loading={loading}
            options={securitiesOptions(index)}
            deleteDisabled={isRemoveAmountDisabled}
            onDelete={() => removeAmount(index)}
            field={field}
            onFieldChange={(type, value) => onFieldChange(type, value, index)}
            error={fieldsErrors[index]}
            isShowFieldError={isShowFieldError}
            disabled={isNotAvailable}
          />
        );
      })}
      <StyledAmountInfoBottom className="AmountInfoBottom">
        <StyledValueRow className="ValueRow">
          {isLoading ? (
            <>
              <Skeleton width={100} height={16} />
              <Skeleton width={100} height={16} />
            </>
          ) : (
            <>
              <Text size="13px" color="secondary" disabled={isNotAvailable}>
                {t('transfer__fee')}:
              </Text>
              {!isLoading && fee && feeCurrency && (
                <Text size="13px" weight="500" disabled={isNotAvailable}>
                  {Math.ceil(fee)} {feeCurrency}
                </Text>
              )}
              {!isLoading && !fee && !feeCurrency && (
                <Text size="13px" weight="500" disabled={isNotAvailable}>
                  0
                </Text>
              )}
            </>
          )}
        </StyledValueRow>

        {isLoading ? (
          <Skeleton width="100%" height={50} />
        ) : (
          fee &&
          feeCurrency && (
            <StyledAmountInfo className="AmountInfo" disabled={isNotAvailable}>
              <InfoIcon />
              {formatAmount(
                t(
                  'A withdrawal charge up to VALUE CURRENCY will be applied to each ISIN transferred from the account.',
                ),
                String(Math.ceil(fee)),
                feeCurrency,
              )}
            </StyledAmountInfo>
          )
        )}

        <Button
          fullWidth
          loading={isTransferProcessing}
          disabled={isTransferProcessing || isNotAvailable}
          type="submit"
          onClick={onPreventValidate}
          showSkeleton={loading || isLoading}
        >
          {t('layout__subaccount_transfer__transfer')}
        </Button>
      </StyledAmountInfoBottom>
    </StyledSecuritiesAmountFormContainer>
  );
};
