import { TAddActionState } from './types';

export const initialState: TAddActionState = {
  description: '',
  environment: 0,
  password: '',
  scopes: [
    'ohlc',
    'crossrates',
    'symbols',
    'change',
    'feed',
    'orders',
    'summary',
    'transactions',
    'accounts',
  ],

  error: '',
  saving: false,
};
