import { useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  Notification as UIKitNotification,
  useData,
} from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { BrandingContext, TranslationContext } from 'contexts';
import { accountSettingsService } from 'services/settings/accountSettings';
import { useTheme } from 'theme';

import { DemoAccountStatus } from './DemoAccount.constants';
import {
  StyledDemoHeader,
  StyledMobileResetDemoWrapper,
  StyledResetDemoButton,
  StyledRowContainer,
  StyledRowContainerHeader,
} from './DemoAccount.styled';

export const DemoAccount = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const [accountName, setAccountName] = useState<string | null>(null);
  const [demoIsAvailable, setDemoIsAvailable] = useState<boolean>(false);
  const [demoStatus, setDemoStatus] = useState<DemoAccountStatus>(
    DemoAccountStatus.READY,
  );
  const { branding } = useContext(BrandingContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [intervalID, setIntervalID] = useState<number | null>(null);

  const { fetchData: fetchDemoAccount, isLoading: isDemoAccountLoading } =
    useData({
      onFetch: () => accountSettingsService.getDemoAccountName(currentLanguage),
      onSuccess: (name: string | null) => {
        setAccountName(name || null);
      },
    });

  const {
    fetchData: fetchDemoAvailableAccount,
    isLoading: isDemoAvailableLoading,
  } = useData({
    onFetch: () => accountSettingsService.getDemoAvailable(currentLanguage),
    onSuccess: (response: boolean | null) => {
      setDemoIsAvailable(Boolean(response));
    },
    onFailure: () => {
      setDemoIsAvailable(false);
    },
  });

  const resetDemoAccount = async () => {
    UIKitNotification.success({
      title: t('demo_account_archiving_and_creating'),
    });
    setDemoStatus(DemoAccountStatus.RESETTING);
    await accountSettingsService.resetDemoAccount(currentLanguage);
  };

  const refillIfAvailable = async () => {
    const status = await accountSettingsService.getResetDemoStatus();
    if (!status) {
      return;
    }

    if (status.recreate.is_failed) {
      UIKitNotification.error({ title: t('demo_account_reset_error') });
      setDemoStatus(() => DemoAccountStatus.READY);
      return;
    }

    if (status.control.is_can_refill && status.recreate.is_success) {
      setDemoStatus(() => DemoAccountStatus.REFILLING);
      await accountSettingsService.refillDemo(currentLanguage);
    }
  };

  const checkIfDemoReady = async () => {
    const status = await accountSettingsService.getResetDemoStatus();
    if (!status) {
      return;
    }

    if (status.refill?.is_failed) {
      setDemoStatus(() => DemoAccountStatus.READY);
      UIKitNotification.error({ title: t('demo_account_reset_error') });
      return;
    }

    if (
      status.control.is_done &&
      status.control.is_success &&
      status.refill?.account
    ) {
      setDemoStatus(() => DemoAccountStatus.READY);
      setAccountName(status.refill.account);
      UIKitNotification.success({
        title: t('layout__settings__reset_demo__success'),
      });
    }
  };

  const stopInterval = () => {
    if (!intervalID) {
      return;
    }

    clearInterval(intervalID);
    setIntervalID(null);
  };

  useEffect(() => {
    if (!intervalID && demoStatus !== DemoAccountStatus.READY) {
      setIntervalID(window.setInterval(refillIfAvailable, 3000));
    } else if (intervalID && demoStatus === DemoAccountStatus.REFILLING) {
      stopInterval();
      setIntervalID(window.setInterval(checkIfDemoReady, 3000));
    } else if (intervalID && demoStatus === DemoAccountStatus.READY) {
      stopInterval();
    }

    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
  }, [demoStatus]);

  useEffect(() => {
    fetchDemoAccount();
    fetchDemoAvailableAccount();
  }, []);

  const available = Boolean(
    branding?.show_reset_demo &&
      !isDemoAccountLoading &&
      !isDemoAvailableLoading &&
      demoIsAvailable,
  );

  if (!available) {
    return null;
  }

  const demoButton = (
    <StyledResetDemoButton
      className="ResetDemoButton"
      onClick={resetDemoAccount}
      textColor="action"
      color={isMobile ? 'primary' : 'transparent'}
      disabled={demoStatus !== DemoAccountStatus.READY}
    >
      {t('account_settings_reset_demo')}
    </StyledResetDemoButton>
  );

  return (
    <div className="DemoAccountContainer">
      <StyledDemoHeader className="DemoHeader">
        {t('account_settings_demo')}
      </StyledDemoHeader>
      {isMobile ? (
        <StyledMobileResetDemoWrapper className="MobileResetDemoWrapper">
          <span>{accountName}</span>
          {demoButton}
        </StyledMobileResetDemoWrapper>
      ) : (
        <>
          <StyledRowContainerHeader className="RowContainer">
            <span>{t('generic__id')}</span>
            <span>{t('generic__action').toUpperCase()}</span>
          </StyledRowContainerHeader>
          <StyledRowContainer className="RowContainer">
            <span>{accountName || DEFAULT_EMPTY_PLACEHOLDER}</span>
            {demoButton}
          </StyledRowContainer>
        </>
      )}
    </div>
  );
};
