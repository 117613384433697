import { useContext, useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { UserDetailsContext } from 'contexts';
import { PATHS } from 'router';
import { TAccount } from 'services/accounts';
import { ACCOUNT_TYPE } from 'services/accounts/accounts.constants';
import { selectAccounts } from 'store/accounts';
import { accountsActions } from 'store/accounts/accounts.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { getInitialAccountId } from './helpers';
import { filterRouteAssociatedAccountsBy } from './useRouteAssociatedAccounts.helpers';
import { TUseRouteAssociatedAccountsProps } from './useRouteAssociatedAccounts.types';

export const useRouteAssociatedAccounts = ({
  excludeAccountTypes = [],
}: TUseRouteAssociatedAccountsProps = {}): TAccount[] => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const accountsState = useAppSelector(selectAccounts);
  const { selectedAccountId } = accountsState;

  const { userPreferred, userPreferredLoading } =
    useContext(UserDetailsContext);

  const preferredAccountId = userPreferred?.preferableAccount || null;

  const result = useMemo(() => {
    const isTermsPage =
      matchPath(PATHS.ROOT + PATHS.COMMISSIONS, location.pathname) ||
      matchPath(PATHS.ROOT + PATHS.OVERNIGHTS, location.pathname) ||
      matchPath(PATHS.ROOT + PATHS.INTERESTS, location.pathname) ||
      matchPath(PATHS.ROOT + PATHS.SHORT_ALLOWANCE_STATUS, location.pathname);

    if (isTermsPage) {
      return filterRouteAssociatedAccountsBy(accountsState, {
        pageAlias: 'terms',
        excludedTypes: excludeAccountTypes,
      });
    }

    if (
      matchPath(PATHS.ROOT + PATHS.SECURITY_ACCESS_RIGHTS, location.pathname)
    ) {
      return filterRouteAssociatedAccountsBy(accountsState, {
        excludedTypes: [ACCOUNT_TYPE.AGGREGATED, ...excludeAccountTypes],
      });
    }

    return filterRouteAssociatedAccountsBy(accountsState, {
      excludedTypes: excludeAccountTypes,
    });
  }, [accountsState, location, excludeAccountTypes.join(',')]);

  const account = useMemo(
    () => result.find((item) => item.value === selectedAccountId),
    [result, selectedAccountId],
  );

  useEffect(() => {
    if (result.length > 0 && !account && !userPreferredLoading) {
      dispatch(
        accountsActions.selectAccount({
          accountId: getInitialAccountId(result, preferredAccountId),
        }),
      );
    }
  }, [account, result, userPreferredLoading, preferredAccountId]);

  return result;
};
