import qs from 'qs';

export const getParamsSerializer = (options?: qs.IStringifyOptions) => {
  return (params: Record<string, unknown>) =>
    qs.stringify(params, {
      arrayFormat: 'comma',
      encode: false,
      skipNulls: true,
      ...options,
    });
};
