import { useContext } from 'react';
import {
  EconomicCalendar as CalendarWidget,
  Locales,
} from 'react-ts-tradingview-widgets';
import { Panel } from 'react-ui-kit-exante';

import { PageWrapper } from 'components/PageWrapper';
import { BrandingContext, TranslationContext } from 'contexts';

export const EconomicCalendar = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  if (!branding) {
    return null;
  }

  const locale = (currentLanguage || 'en') as Locales;

  return (
    <PageWrapper>
      <Panel
        title={t('challenge_economic_calendar__title')}
        className="EconomicCalendarPanel"
      >
        <CalendarWidget
          locale={locale}
          colorTheme={branding?.branding?.trading_view_calendar_settings?.theme}
          height="600"
          width="100%"
        />
      </Panel>
    </PageWrapper>
  );
};
