import { styled } from 'theme';

import { CardAction } from '../CardAction';

export const StyledBlockCardAction = styled(CardAction)`
  color: ${({ theme }) => theme.color.typo.radical};

  svg {
    color: ${({ theme }) => theme.color.typo.radical};
  }
`;

export const StyledBlockModalDescription = styled('p')`
  margin: 0;
  color: ${({ theme }) => theme.color.typo.promo};
`;
