import { createContext, Dispatch } from 'react';

import { TTreeModule } from 'services/instrumentsTree/instrumentsTree.types';

import { TActionsState, TState } from './InstrumentsTreeContext.types';

export const getInitialState = (
  module: TTreeModule = TTreeModule.PERMISSIONS,
): TState => ({
  expandedRows: {},
  downloadedPaths: new Set(),
  module,
  treeWasFiltered: false,
  loadingInstruments: new Set(),
});

export const InstrumentsTreeContext = createContext<
  [TState, Dispatch<TActionsState>]
>([getInitialState(), () => {}]);
