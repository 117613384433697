export const formatPhoneNumber = (
  phoneNumber?: string | number,
  spacer = '-',
): string => {
  if (!phoneNumber) {
    return '';
  }

  const number = phoneNumber
    .toString()
    .trim()
    .replace(/[^0-9]/g, '');

  if (number.length < 4) {
    return number;
  }

  if (number.length < 7) {
    return number.replace(/(\d{3})(\d)/, `$1${spacer}$2`);
  }

  if (number.length < 11) {
    return number.replace(/(\d{3})(\d{3})(\d)/, `$1${spacer}$2${spacer}$3`);
  }

  return number.replace(/(\d{3})(\d{4})(\d{4})/, `$1${spacer}$2${spacer}$3`);
};
