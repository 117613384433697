import { useContext } from 'react';

import { MailLockIcon, MessageLockIcon, MfaIcon } from 'assets/icons';
import { BrandingContext, TranslationContext } from 'contexts';
import { useUserDetails } from 'hooks';

import { MfaTooltipState } from './Mfa.constants';
import { StyledMfaContainer, StyledMfaHeader } from './Mfa.styled';
import { ActionRow } from './components';
import { useMfa } from './hooks';

export const Mfa = () => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const {
    activeType,
    allowedSet,
    isLoading,
    handleRefresh,
    handleOpen,
    handleClose,
    tooltipState,
  } = useMfa();

  const { phoneNumber } = useUserDetails();

  return (
    <StyledMfaContainer className="AuthorizationContainer">
      <StyledMfaHeader className="AuthorizationHeader">
        {t('layout__security_tabs__mfa_header_title')}
        <br />
        {t('layout__security_tabs__mfa_header_description')}
      </StyledMfaHeader>

      {allowedSet.has('totp') && (
        <ActionRow
          type="totp"
          open={tooltipState === MfaTooltipState.Totp}
          onOpen={handleOpen}
          onClose={handleClose}
          onSuccess={handleRefresh}
          isActive={activeType === 'totp'}
          isDisabled={Boolean(activeType) && activeType !== 'totp'}
          isLoading={isLoading}
          title={t('layout__security_tabs__mfa_action_totp_title')}
          description={t('layout__security_tabs__mfa_action_totp_description')}
          Icon={MfaIcon}
        />
      )}

      {branding?.show_2fa_email && allowedSet.has('email') && (
        <ActionRow
          type="email"
          open={tooltipState === MfaTooltipState.Email}
          onOpen={handleOpen}
          onClose={handleClose}
          onSuccess={handleRefresh}
          isActive={activeType === 'email'}
          isDisabled={Boolean(activeType) && activeType !== 'email'}
          isLoading={isLoading}
          title={t('layout__security_tabs__mfa_action_email_title')}
          description={t('layout__security_tabs__mfa_action_email_description')}
          Icon={MailLockIcon}
        />
      )}

      {allowedSet.has('sms') && Boolean(phoneNumber) && (
        <ActionRow
          type="sms"
          open={tooltipState === MfaTooltipState.Sms}
          onOpen={handleOpen}
          onClose={handleClose}
          onSuccess={handleRefresh}
          isActive={activeType === 'sms'}
          isDisabled={Boolean(activeType) && activeType !== 'sms'}
          isLoading={isLoading}
          title={t('layout__security_tabs__mfa_action_sms_title')}
          description={t('layout__security_tabs__mfa_action_sms_description')}
          Icon={MessageLockIcon}
        />
      )}
    </StyledMfaContainer>
  );
};
