import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useContext,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { IColumn } from 'react-ui-kit-exante';

import {
  TransfersHistoryTable,
  TTransfersHistoryTableRefAttributes,
} from 'components/TransfersHistoryTable';
import { TranslationContext } from 'contexts/TranslationContext';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { TRANSACTION_TYPE, TTransactionData } from 'services/transactions';
import { selectAccounts } from 'store/accounts';

import { useTransfers } from '../../../../../../hooks';
import { FundsContext } from '../../../../context';

import { getToMyAccountWithdrawalHistoryTableColumns } from './ToMyAccountWithdrawalHistoryTable.helpers';

export const ToMyAccountWithdrawalHistoryTable: ForwardRefExoticComponent<
  RefAttributes<TTransfersHistoryTableRefAttributes>
> = forwardRef(function ToMyAccountWithdrawalHistoryTable(props, ref) {
  const { selectedAccountId } = useSelector(selectAccounts);
  const { t, currentLanguage } = useContext(TranslationContext);
  const dateFormatter = useDateFormatter(true);
  const { setRepeatedWithdrawal } = useTransfers();

  const { toMyAccountState } = useContext(FundsContext);

  const columns = useMemo<IColumn<TTransactionData>[]>(
    () =>
      getToMyAccountWithdrawalHistoryTableColumns({
        t,
        currentLanguage,
        dateFormatter,
        onRepeat: setRepeatedWithdrawal,
        selectedAccountId,
        accounts: toMyAccountState.accounts,
      }),
    [toMyAccountState.accounts, selectedAccountId],
  );

  const operationTypes = useMemo(
    () => [
      TRANSACTION_TYPE.INTERNAL_TRANSFER,
      TRANSACTION_TYPE.ELECTRONIC_TRANSFER,
      TRANSACTION_TYPE.SUBACCOUNT_TRANSFER,
    ],
    [],
  );

  return (
    <TransfersHistoryTable
      ref={ref}
      className="WithdrawalHistoryTable"
      titleKey="Activity"
      operationTypes={operationTypes}
      tableId="withdrawalHistoryTable"
      columns={columns}
      accountId={selectedAccountId}
    />
  );
});
