import cn from 'classnames';
import { ChangeEvent, FC, useContext } from 'react';

import { getAssetShortName } from 'components/AssetIcon/utils';
import { TranslationContext } from 'contexts';

import { CURRENCY_ICON_CLASS_NAME } from './CurrencySelect.consts';
import {
  StyledCurrencyCode,
  StyledCurrencyFallbackIcon,
  StyledCurrencyIcon,
  StyledSelect,
} from './CurrencySelect.styled';
import { TCurrencySelectProps } from './CurrencySelect.types';

const DISABLED_VALUE = '-';

export const CurrencySelect: FC<TCurrencySelectProps> = ({
  options,
  icons,
  value,
  labelKey = 'Currency',
  onChange,
  className,
  disabled = false,
  withDisabled,
  ...rest
}) => {
  const { t } = useContext(TranslationContext);

  const disabledValue = withDisabled ? DISABLED_VALUE : '';

  const optionsWithIcons = options.map((code) => {
    const iconInfo = icons[code];

    const icon = (iconInfo?.icon && (
      <StyledCurrencyIcon
        className={CURRENCY_ICON_CLASS_NAME}
        alt={iconInfo?.symbol || code}
        src={iconInfo?.icon || undefined}
      />
    )) || (
      <StyledCurrencyFallbackIcon className={CURRENCY_ICON_CLASS_NAME}>
        {getAssetShortName(iconInfo?.symbol || code)}
      </StyledCurrencyFallbackIcon>
    );

    return {
      ...(icon ? { icon } : {}),
      value: code,
      label: (
        <StyledCurrencyCode className="CurrencyCode">{code}</StyledCurrencyCode>
      ),
    };
  });

  const currencyIcon = optionsWithIcons.find(
    (opt) => opt.value === value,
  )?.icon;

  const optionsWithDisabled = withDisabled
    ? // non-empty value required to render default option properly
      [
        { label: t('generic__disabled'), value: disabledValue },
        ...optionsWithIcons,
      ]
    : optionsWithIcons;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    // filter out non-empty default value to empty string for parent component
    onChange(val === disabledValue ? '' : val);
  };

  // convert empty string to non-empty default value
  const displayValue =
    ((disabled || !optionsWithDisabled.length) && disabledValue) ||
    value ||
    disabledValue;

  const displayIcon = disabled ? null : currencyIcon;

  return (
    <StyledSelect
      disabled={disabled}
      label={t(labelKey)}
      className={cn('CurrencySelect', className)}
      currencyIconClassName={CURRENCY_ICON_CLASS_NAME}
      hasIcon={Boolean(displayIcon)}
      iconLeft={displayIcon}
      options={optionsWithDisabled}
      value={displayValue}
      onChange={handleChange}
      fullWidth
      {...rest}
    />
  );
};
