import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TServiceFeesResponse } from './serviceFees.types';

class ServiceFeesService extends ApiRequest {
  async getServiceFees(
    accountId: string | null,
    lang = 'en',
  ): Promise<TServiceFeesResponse | null> {
    if (!accountId) {
      return null;
    }
    try {
      const { data } = await this.fetch<TServiceFeesResponse>({
        url: `/${lang}/clientsarea/rest/service-fees/`,
        params: { account: accountId },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getServiceFees error: ', error);
      return null;
    }
  }
}

export const serviceFeesService = new ServiceFeesService();
