import { TFunction } from 'i18next';
import { object, string } from 'yup';

import { TPartnerPaymentsForm } from './PartnerPayments.types';

export const PARTNER_PAYMENTS_FORM_INFO_DEFAULT: TPartnerPaymentsForm = {
  pc_pd_currency: null,
  pc_pd_beneficiary_name: null,
  pc_pd_beneficiary_address: null,
  pc_pd_beneficiary_country: null,
  pc_pd_beneficiary_city: null,
  pc_pd_beneficiary_postal_code: null,
  pc_pd_bank_name: null,
  pc_pd_swift: null,
  pc_pd_iban: null,
  pc_pd_sort_code: null,
  pc_pd_account_number: null,
  pc_vat_number: null,
};

export const PARTNER_PAYMENTS_INFO_FORM_VALIDATION_SCHEMA = (t: TFunction) =>
  object({
    pc_pd_currency: string().required(t('layout__field_is_required')),
    pc_pd_beneficiary_name: string().required(t('layout__field_is_required')),
    pc_pd_beneficiary_address: string().required(
      t('layout__field_is_required'),
    ),
    pc_pd_beneficiary_country: string().required(
      t('layout__field_is_required'),
    ),
    pc_pd_beneficiary_city: string().required(t('layout__field_is_required')),
    pc_pd_beneficiary_postal_code: string().required(
      t('layout__field_is_required'),
    ),
    pc_pd_bank_name: string().required(t('layout__field_is_required')),
    pc_pd_swift: string().required(t('layout__field_is_required')),
    pc_pd_iban: string().required(t('layout__field_is_required')),
    pc_pd_sort_code: string().nullable().notRequired(),
    pc_pd_account_number: string().nullable().notRequired(),
    pc_vat_number: string().nullable().notRequired(),
  });
