import { reduce } from 'lodash';

import { TSelectPlatformsArgs } from 'pages/Downloads/Downloads.types';
import {
  TBrandingDownloadPagePlatform,
  TBrandingDownloadPagePlatformButton,
} from 'services/branding';

/**
 * Interpolates the text using the dictionary of replacements
 *
 * @param text
 * @param replacements
 */
export const replaceDict = (
  text: string,
  replacements: Record<string, string | number | null | undefined>,
) => {
  return reduce(
    Object.entries(replacements),
    (text_replaced, [key, replacement]) =>
      text_replaced.replaceAll(
        `[${key}]`,
        replacement ? String(replacement) : '',
      ),
    text,
  );
};

/**
 * Filters the list of platforms available to the user
 * using given list of their aliases
 *
 * @param platforms data of all possible platforms
 * @param aliases   aliases of platforms required to display
 * @param branding  branding properties to substitute some platform data with
 * @param docs      doc urls from clientsarea admin if available
 */
export const selectPlatforms = ({
  platforms,
  aliases,
  branding,
  docs,
}: TSelectPlatformsArgs) => {
  if (!platforms) {
    return null;
  }

  const atpLinks = branding?.atp?.links || {};
  const atpLinksLite = branding?.atp?.lite || {};

  const urls = {
    web: branding?.atp?.web,

    // wl branding -> atp pro downloads
    win: atpLinks.win,
    mac: atpLinks.mac,
    ios: atpLinks.ios,
    linux: atpLinks.linux,
    excel: atpLinks.excel,
    android: atpLinks.android,

    // wl branding -> atp lite downloads
    lite_win: atpLinksLite.win,
    lite_mac: atpLinksLite.mac,
    lite_ios: atpLinksLite.ios,
    lite_linux: atpLinksLite.linux,
    lite_excel: atpLinksLite.excel,
    lite_android: atpLinksLite.android,

    // other
    http_api: docs?.api,
    fix_api: docs?.fix,
  };

  return platforms.reduce<TBrandingDownloadPagePlatform[]>((acc, curr) => {
    const isAllowed: boolean =
      aliases === null ||
      Boolean(curr?.alias && aliases?.includes(curr?.alias));

    // filter the platform by alias
    if (!isAllowed) {
      return acc;
    }

    // substitute button url variables where needed
    const btnsReplaced = curr?.buttons?.reduce<
      TBrandingDownloadPagePlatformButton[]
    >((acc_btn, btn) => {
      const urlReplaced = replaceDict(btn.anchor_url, urls);

      // remove the button if the url substitution is not empty
      // if the url left blank intentionally, keep it in the list
      if (!urlReplaced && btn.anchor_url) {
        return acc_btn;
      }

      return [...acc_btn, { ...btn, anchor_url: urlReplaced }];
    }, []);

    // do not show the platform if there are no valid buttons
    // and caption is empty
    if (!btnsReplaced?.length && !curr.caption) {
      return acc;
    }

    return [...acc, { ...curr, buttons: btnsReplaced }];
  }, []);
};
