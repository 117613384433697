import { FC, useContext, useState } from 'react';
import { Button, Input } from 'react-ui-kit-exante';

import { PageWrapper } from 'components/PageWrapper';
import {
  CHALLENGE_PLAN_MODE_CHAMPION,
  CHALLENGE_STATE_SUCCEED,
} from 'constants/CHALLENGE';
import {
  BrandingContext,
  TranslationContext,
  UserDetailsContext,
} from 'contexts';
import { currencyFormatter, formatCurrency } from 'helpers/formatters';
import { notifyWith } from 'helpers/notifyWith';
import { challengeService } from 'services/challenge';
import { useAppSelector } from 'store/hooks';

import {
  StyledChallengePayoutForm,
  StyledChallengePayoutFormActions,
  StyledChallengePayoutWrapper,
} from './ChallengePayout.styled';
import { ChallengePayoutSkeleton } from './ChallengePayoutSkeleton';
import { isSwift } from './helpers';
import { useFetchChallengePayoutData } from './hooks';

export const ChallengePayout: FC = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const { userDetails } = useContext(UserDetailsContext);

  const [error, setError] = useState(false);

  const [holderName, setHolderName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [swift, setSwift] = useState('');

  const [isWireLoading, setIsWireLoading] = useState(false);

  const [selectedAccountId] = useAppSelector((state) => [
    state.accounts.selectedAccountId,
  ]);

  const {
    challenge,
    activeWithdrawal,
    executedWithdrawal,
    isLoading: isChallengeLoading,
  } = useFetchChallengePayoutData();

  const resetFields = () => {
    setHolderName('');
    setAccountNumber('');
    setBankName('');
    setBankAddress('');
    setSwift('');
    setError(false);
  };

  const sendRequest = async () => {
    if (!selectedAccountId) {
      return;
    }

    if (
      holderName === '' ||
      accountNumber === '' ||
      bankName === '' ||
      bankAddress === '' ||
      (swift && !isSwift(swift))
    ) {
      setError(true);
    } else {
      setError(false);

      const data = {
        name: holderName,
        iban: accountNumber,
        bank_name: bankName,
        bank_address: bankAddress,
        swift,
      };

      setIsWireLoading(true);

      try {
        await challengeService.makeWithdrawal(data, selectedAccountId);
        notifyWith.success(t('challenge_payout__success'));

        const redirectLink =
          branding?.branding?.challenge_settings?.payout_page?.success_redirect;
        if (redirectLink) {
          const secToRedirect = 5;

          const getParams: { [key: string]: string } = {
            userId: userDetails?.info?.current.email || '',
            accountId: selectedAccountId,
            challengeId: String(challenge?.plan_override?.plan_id),
          };
          const getParamsString = new URLSearchParams(getParams).toString();

          notifyWith.success(
            t('challenge_payout__success_redirect_action', {
              SEC: secToRedirect,
            }),
          );

          setTimeout(() => {
            window.location.href = `${redirectLink}?${getParamsString}`;
          }, secToRedirect * 1000);
        }
      } catch (e: any) {
        notifyWith.genericNetworkError(e);
      }

      setIsWireLoading(false);
    }
  };

  const payoutAvailable =
    challenge?.plan_override?.payout &&
    challenge?.plan_override?.state === CHALLENGE_STATE_SUCCEED &&
    challenge?.plan_override?.plan_mode === CHALLENGE_PLAN_MODE_CHAMPION &&
    !activeWithdrawal &&
    !executedWithdrawal;

  return (
    <PageWrapper>
      <StyledChallengePayoutWrapper
        title={t('challenge_payout__title')}
        className="ChallengeAccountPayoutWrapper"
      >
        {isChallengeLoading ? (
          <ChallengePayoutSkeleton />
        ) : (
          <>
            {!payoutAvailable && !activeWithdrawal && !executedWithdrawal && (
              <p>{t('challenge_payout__not_available')}</p>
            )}
            {!payoutAvailable && activeWithdrawal && (
              <p>
                {t('challenge_payout__already_created', {
                  VALUE: formatCurrency(
                    currencyFormatter(
                      currentLanguage,
                      activeWithdrawal.currency,
                    ),
                    activeWithdrawal.amount,
                  ),
                })}
              </p>
            )}
            {!payoutAvailable && executedWithdrawal && (
              <p>
                {t('challenge_payout__already_executed', {
                  VALUE: formatCurrency(
                    currencyFormatter(
                      currentLanguage,
                      executedWithdrawal.currency,
                    ),
                    executedWithdrawal.amount,
                  ),
                })}
              </p>
            )}
            {payoutAvailable && (
              <StyledChallengePayoutForm className="ChallengePayoutForm">
                <Input
                  required
                  disabled={isWireLoading}
                  value={holderName}
                  error={error && !holderName}
                  onChange={(e) => setHolderName(e.target.value)}
                  data-test-id="challenge_payout__form__holder_name"
                  placeholder={t('challenge_payout__form__holder_name')}
                  fullWidth
                />
                <Input
                  required
                  disabled={isWireLoading}
                  value={accountNumber}
                  error={error && !accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  data-test-id="challenge_payout__form__account_number"
                  placeholder={t('challenge_payout__form__account_number')}
                  fullWidth
                />
                <Input
                  required
                  disabled={isWireLoading}
                  value={bankName}
                  error={error && !bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  data-test-id="challenge_payout__form__bank_name"
                  placeholder={t('challenge_payout__form__bank_name')}
                  fullWidth
                />
                <Input
                  required
                  disabled={isWireLoading}
                  value={bankAddress}
                  error={error && !bankAddress}
                  onChange={(e) => setBankAddress(e.target.value)}
                  data-test-id="challenge_payout__form__bank_address"
                  placeholder={t('challenge_payout__form__bank_address')}
                  fullWidth
                />
                <Input
                  disabled={isWireLoading}
                  value={swift}
                  error={error && swift !== '' && !isSwift(swift)}
                  onChange={(e) => setSwift(e.target.value)}
                  data-test-id="challenge_payout__form__bank_swift"
                  placeholder={t('challenge_payout__form__bank_swift')}
                  fullWidth
                />
                <StyledChallengePayoutFormActions className="ChallengePayoutFormActions">
                  <Button
                    onClick={resetFields}
                    disabled={isWireLoading}
                    color="secondary"
                    data-test-id="challenge_payout__form__btn_cancel"
                  >
                    {t('challenge_payout__form__btn_cancel')}
                  </Button>
                  <Button
                    onClick={sendRequest}
                    loading={isWireLoading}
                    data-test-id="challenge_payout__form__btn_request"
                  >
                    {t('challenge_payout__form__btn_request')}
                  </Button>
                </StyledChallengePayoutFormActions>
              </StyledChallengePayoutForm>
            )}
          </>
        )}
      </StyledChallengePayoutWrapper>
    </PageWrapper>
  );
};
