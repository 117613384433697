import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useData } from 'react-ui-kit-exante';

import { useLogHandleTime } from 'hooks/useLogHandleTime';
import { accountsService } from 'services/accounts';
import { TAccountSummary } from 'types/accounts';

import { CurrencyContext } from '../../CurrencyContext';
import { TranslationContext } from '../../TranslationContext';

import {
  TQueryInfo,
  TUseAccountDataProps,
  TUseAccountDataValue,
} from './useAccountData.types';

const cachedAccountData: Record<string, TAccountSummary> = {};

export const useAccountData = ({
  selectedAccountId,
  selectedDate,
}: TUseAccountDataProps): TUseAccountDataValue => {
  const { currentLanguage } = useContext(TranslationContext);
  const { currency } = useContext(CurrencyContext);

  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('summary-details');

  const [queryInfo, setQueryInfo] = useState<TQueryInfo>({
    date: null,
    accountId: null,
  });
  const [forcedLoading, setForcedLoading] = useState<boolean>(false);

  const abortController = useRef(new AbortController());

  const getAccountInfo = useCallback(async () => {
    if (selectedAccountId && selectedDate) {
      const currentAccountCache = cachedAccountData[selectedAccountId] || null;

      abortController.current.abort();
      abortController.current = new AbortController();

      try {
        setStartHandleTime();
        let response = await accountsService.getAccountSummary(
          selectedAccountId,
          selectedDate,
          currency,
          abortController.current.signal,
          currentLanguage,
        );

        if (response) {
          cachedAccountData[selectedAccountId] = response;
        } else {
          response = currentAccountCache;
        }
        logHandleTime();
        setQueryInfo({
          date: selectedDate,
          accountId: selectedAccountId,
        });
        return response;
      } catch (error: any) {
        console.error(error);
        return currentAccountCache;
      }
    }

    return null;
  }, [selectedAccountId, selectedDate, currency, currentLanguage]);

  const {
    data: accountData,
    isLoading,
    fetchData: fetchAccountData,
  } = useData<TAccountSummary | null>({
    onFetch: getAccountInfo,
    onSuccess: () => setForcedLoading(false),
  });

  const dateChanged =
    selectedDate?.toDateString() !== queryInfo.date?.toDateString();
  const accountChanged = selectedAccountId !== queryInfo.accountId;
  const isFirstLoading = isLoading && !accountData;

  useEffect(() => {
    setForcedLoading(true);
    fetchAccountData();
  }, [currency]);

  return {
    accountData,
    isAccountDataLoading: isLoading,
    isAccountDataForcedLoading: forcedLoading,
    isAccountDataFirstLoading: isFirstLoading,
    fetchAccountData,
    dateChanged,
    accountChanged,
  };
};
