import cn from 'classnames';
import { FC } from 'react';

import { StyledGenericTransferFormBlock } from 'components/GenericTransferForm/components/GenericTransferFormBlock/GenericTransferFormBlock.styled';

import { TGenericTransferFormBlockProps } from './GenericTransferFormBlock.types';

export const GenericTransferFormBlock: FC<TGenericTransferFormBlockProps> = ({
  children,
  className,
}) => (
  <StyledGenericTransferFormBlock
    className={cn(className, 'GenericTransferFormBlock')}
  >
    {children}
  </StyledGenericTransferFormBlock>
);
