import { useContext, useRef } from 'react';
import { IColumn, Table } from 'react-ui-kit-exante';

import { AccountSelection } from 'components/AccountSelection';
import { BrandingContext, TranslationContext } from 'contexts';
import { prepareTableId } from 'helpers/prepareTableId';
import { TAccountUser } from 'services/accounts';

import {
  StyledAccessRightsContainer,
  StyledAccountSelector,
  StyledFooter,
  StyledLink,
} from './AccessRights.styled';
import { useAccessRights } from './useAccessRights';

export const AccessRights = () => {
  const { currentLanguage, t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const {
    data,
    handleSetPage,
    handleSetPageSize,
    hasPagination,
    isLoading,
    pageCount,
    pageIndex,
    pageSize,
    total,
    isHidden,
  } = useAccessRights();

  const tableId = prepareTableId('clientsarea-access-rights');

  const { current: columns } = useRef<Array<IColumn<TAccountUser>>>([
    {
      id: 'user-name',
      Header: t('generic__username'),
      accessor: 'user_id',
      disableFilters: true,
      maxWidth: 100,
    },
    {
      id: 'email',
      Header: t('generic__email'),
      accessor: 'email',
      disableFilters: true,
    },
  ]);

  const [prefix, postfix] = t(
    'layout__settings__permissions__change_permissions',
  ).split('<a href="mailto:%(wl_emails_support)s">%(wl_emails_support)s</a>');

  return (
    <StyledAccessRightsContainer
      compact={isHidden}
      className="AccessRightsContainer"
    >
      {!isHidden && (
        <StyledAccountSelector className="AccountSelector">
          <AccountSelection
            isHideAddAccount
            isHideDate
            isHideBalanceButton
            isHideSearch
            useAbsolutePositioning={false}
            titleKey={' '}
            isHideQR
          />
        </StyledAccountSelector>
      )}
      <Table
        disableSortBy
        isFlexLayout
        manualSortBy
        columns={columns}
        data={data}
        hasFilters={false}
        isLoading={isLoading}
        locale={currentLanguage}
        skeletonsCount={pageSize}
        tableId={tableId}
        translator={t}
        hasPagination={hasPagination}
        serverPaginationProps={{
          total,
          pageCount,
          pageSize,
          pageIndex,
          setPage: (updater: number | ((index: number) => number)) => {
            if (typeof updater === 'function') {
              console.error('unexpected behavior on setPage');
              return;
            }

            handleSetPage(updater);
          },
          setPageSize: (size) => handleSetPageSize(size),
        }}
      />

      <StyledFooter className="Footer">
        <span>{prefix}</span>
        <StyledLink
          className="Link"
          href={`mailto:${branding?.wl_emails_support}`}
        >
          {branding?.wl_emails_support}
        </StyledLink>
        <span>{postfix}</span>
      </StyledFooter>
    </StyledAccessRightsContainer>
  );
};
