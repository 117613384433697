import { useContext, useRef } from 'react';
import { Skeleton, Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import {
  StyledNotificationsHeaderContainer,
  StyledNotificationsHeaderEmailInfoIcon,
  StyledNotificationsHeaderItem,
  StyledNotificationsHeaderItemTitle,
  StyledNotificationsHeaderSkeletonWrapper,
} from './NotificationsHeader.styled';
import { TNotificationsHeaderProps } from './NotificationsHeader.types';

export const NotificationsHeader = ({
  isLoading,
}: TNotificationsHeaderProps) => {
  const { t } = useContext(TranslationContext);

  const { current: skeleton } = useRef(
    <StyledNotificationsHeaderContainer className="HeaderContainer">
      <StyledNotificationsHeaderSkeletonWrapper>
        <Skeleton height="16px" width="100%" />
      </StyledNotificationsHeaderSkeletonWrapper>

      <StyledNotificationsHeaderSkeletonWrapper>
        <Skeleton height="16px" width="70%" />
        <Skeleton height="16px" width="30%" />
      </StyledNotificationsHeaderSkeletonWrapper>

      <StyledNotificationsHeaderSkeletonWrapper>
        <Skeleton height="16px" width="100%" />
      </StyledNotificationsHeaderSkeletonWrapper>

      <StyledNotificationsHeaderSkeletonWrapper>
        <Skeleton height="16px" width="100%" />
      </StyledNotificationsHeaderSkeletonWrapper>
    </StyledNotificationsHeaderContainer>,
  );

  if (isLoading) {
    return skeleton;
  }

  return (
    <StyledNotificationsHeaderContainer className="HeaderContainer">
      <StyledNotificationsHeaderItem className="HeaderItem" align="left">
        <StyledNotificationsHeaderItemTitle className="HeaderItemTitle">
          {t('generic__type')}
        </StyledNotificationsHeaderItemTitle>
      </StyledNotificationsHeaderItem>

      <StyledNotificationsHeaderItem className="HeaderItem" align="center">
        <StyledNotificationsHeaderItemTitle className="HeaderItemTitle">
          {t('generic__phone')}
        </StyledNotificationsHeaderItemTitle>
        <Tooltip title={t('notification_settings__phone_hint')} placement="top">
          <StyledNotificationsHeaderEmailInfoIcon />
        </Tooltip>
      </StyledNotificationsHeaderItem>

      <StyledNotificationsHeaderItem className="HeaderItem" align="center">
        <StyledNotificationsHeaderItemTitle className="HeaderItemTitle">
          {t('layout__products__clients_area')}
        </StyledNotificationsHeaderItemTitle>
      </StyledNotificationsHeaderItem>

      <StyledNotificationsHeaderItem className="HeaderItem" align="center">
        <StyledNotificationsHeaderItemTitle className="HeaderItemTitle">
          {t('layout__download__trading_terminal')}
        </StyledNotificationsHeaderItemTitle>
      </StyledNotificationsHeaderItem>

      <StyledNotificationsHeaderItem className="HeaderItem" align="left">
        <StyledNotificationsHeaderItemTitle className="HeaderItemTitle">
          {t('generic__email')}
        </StyledNotificationsHeaderItemTitle>
      </StyledNotificationsHeaderItem>
    </StyledNotificationsHeaderContainer>
  );
};
