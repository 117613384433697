import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useContext,
} from 'react';

import { ChipIcon, MastercardIcon, OrderedCardIcon } from 'assets/icons';
import { ACCOUNT_ALL_ID } from 'constants/ACCOUNT_ALL_ID';
import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { ACCOUNT_TYPE } from 'services/accounts/accounts.constants';
import { CARD_STATUS } from 'services/cards/cards.constants';
import { selectAccountDescription } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TAccountSummaryMetrics } from 'types/accounts';

import {
  StyledAccountCardAll,
  StyledAccountCardItem,
  StyledAccountCardItemCardNumber,
  StyledAccountCardItemCardNumberDigits,
  StyledAccountCardItemCardStatus,
  StyledAccountCardItemCardType,
  StyledAccountCardItemDesc,
  StyledAccountCardItemValue,
} from './AccountCard.styled';
import { TAccountCardProps, TAccountImages } from './AccountCard.types';

export const AccountCard: ForwardRefExoticComponent<
  TAccountCardProps & RefAttributes<HTMLButtonElement>
> = forwardRef(function AccountCard(
  { account, isAccountAll, onClick, currency, selectedAccountId, disabled },
  ref,
) {
  const { t } = useContext(TranslationContext);
  const formatter = useCurrencyFormatter(currency);
  const { theme } = useContext(BrandingContext);
  const accountDescription = useAppSelector((state) =>
    selectAccountDescription(state, account.accountId),
  );

  const accountImages: TAccountImages = theme?.images?.accounts_selector || {};

  const computedImageUrl = (acc: TAccountSummaryMetrics) => {
    switch (acc.accountPurpose) {
      case ACCOUNT_TYPE.PAYMENT:
        return accountImages.payment_account;
      case ACCOUNT_TYPE.PARTNER:
        return accountImages.partner_account;
      default:
        return accountImages.default_account;
    }
  };

  const clickHandler = () => {
    if (!disabled) {
      onClick?.(account.accountId);
    }
  };

  const formattedNetAssetValue: string = formatCurrency(
    formatter,
    account.netAssetValue,
    true,
  );

  if (isAccountAll) {
    return (
      <StyledAccountCardAll
        ref={ref}
        value={Number(account.percentageTotalDailyPnl)}
        selected={selectedAccountId === ACCOUNT_ALL_ID}
        onClick={clickHandler}
        className="AccountCardItem AccountCardAll"
      >
        <StyledAccountCardItemDesc className="AccountCardItemDesc">
          {t(account.label)}
        </StyledAccountCardItemDesc>
        <StyledAccountCardItemValue
          className="AccountCardItemValue"
          title={formattedNetAssetValue}
        >
          {formattedNetAssetValue}
        </StyledAccountCardItemValue>
      </StyledAccountCardAll>
    );
  }

  const accountTitle: string =
    accountDescription ?? t(account.label, account.label) ?? '';

  return (
    <StyledAccountCardItem
      disabled={disabled}
      key={account.accountId}
      className="AccountCardItem"
      onClick={clickHandler}
      value={Number(account.percentageTotalDailyPnl)}
      selected={selectedAccountId === account.accountId}
      ref={ref}
      imageUrl={computedImageUrl(account)}
      role="menuitem"
    >
      <StyledAccountCardItemDesc
        className="AccountCardItemDesc"
        title={accountTitle}
      >
        {accountTitle}
      </StyledAccountCardItemDesc>
      {account.accountPurpose === ACCOUNT_TYPE.PAYMENT &&
        account.cardNumber && (
          <>
            <StyledAccountCardItemCardNumber className="AccountCardItemCardNumber">
              <ChipIcon role="presentation" />
              <StyledAccountCardItemCardNumberDigits className="AccountCardItemCardNumberDigits">
                {account.cardNumber.slice(-4)}
              </StyledAccountCardItemCardNumberDigits>
            </StyledAccountCardItemCardNumber>
            {account.cardStatus === CARD_STATUS.REQUESTED && (
              <StyledAccountCardItemCardStatus className="AccountCardItemCardStatus">
                <OrderedCardIcon role="presentation" />
              </StyledAccountCardItemCardStatus>
            )}
            <StyledAccountCardItemCardType className="AccountCardItemCardType">
              <MastercardIcon role="presentation" />
            </StyledAccountCardItemCardType>
          </>
        )}
      <StyledAccountCardItemValue
        className="AccountCardItemValue"
        title={formattedNetAssetValue}
      >
        {formattedNetAssetValue}
      </StyledAccountCardItemValue>
    </StyledAccountCardItem>
  );
});
