import { FC } from 'react';

import { TChildren } from 'types/TChildren';

import {
  StyledChecklistItem,
  StyledCheckmarkIcon,
} from './ChecklistItem.styled';

export const ChecklistItem: FC<TChildren> = ({ children }) => {
  return (
    <StyledChecklistItem className="ChecklistItem">
      <StyledCheckmarkIcon className="CheckmarkIcon" role="presentation" />
      {children}
    </StyledChecklistItem>
  );
};
