import Cookies from 'js-cookie';
import { useContext, useEffect } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { NotificationsContext, TranslationContext } from 'contexts';

export const useShowUnsignedNotification = () => {
  const isLegalShown = Cookies.get('legalShown');
  const { hasToSign } = useContext(NotificationsContext);
  const { t, currentLanguage } = useContext(TranslationContext);

  useEffect(() => {
    if (hasToSign && !isLegalShown) {
      Notification.warning({
        title: (
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                'You have unsigned documents. Please proceed to the <a href="LINK">Legal page</a> to read and sign them.',
              ).replace('LINK', '/clientsarea/account/settings/agreements/'),
            }}
          />
        ),
        isPermanent: true,
        onClose: () => {
          Cookies.set('legalShown', 'true', { expires: 1 });
        },
      });
    }
  }, [hasToSign, isLegalShown, currentLanguage]);
};
