import { LiveChatWidget } from '@livechat/widget-react';
import React, { useContext } from 'react';

import { customIdentityProvider } from 'components/Chat/Chat.helpers';
import { BrandingContext, UserDetailsContext } from 'contexts';

import { TLiveChatInstance } from './Chat.types';

export declare const window: {
  __lc: TLiveChatInstance;
};

/* eslint-disable no-underscore-dangle */
window.__lc = window.__lc || {};
window.__lc.custom_identity_provider = customIdentityProvider;
/* eslint-enable */

export const Chat = () => {
  const { userDetails } = useContext(UserDetailsContext);
  const { branding } = useContext(BrandingContext);

  const licenseId = branding?.branding?.livechat_license_id;
  const hasChatAccess = branding?.branding?.show_chat;

  if (!hasChatAccess || !licenseId) {
    return null;
  }

  const { full_name: fullName, email } = userDetails?.info?.current || {};
  const userName = fullName || email || 'User';

  if (branding?.is_supervisor) {
    return null;
  }

  return (
    <LiveChatWidget
      license={licenseId}
      customerEmail={email}
      customerName={userName}
    />
  );
};
