import { Abort } from 'helpers/abortController';
import { isCancelledError } from 'helpers/abortController/abortController.helpers';
import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TFetchTradesResponse, TGetTradesParams } from './trades.types';

class TradesService extends ApiRequest {
  abortClient;

  constructor() {
    super();
    this.abortClient = new Abort();
  }

  async getTrades({
    accountId,
    offset,
    size,
    beginDate,
    endDate,
    sortDir: sSortDir_0,
    sortCol: iSortCol_0,
  }: TGetTradesParams): Promise<TFetchTradesResponse | null> {
    try {
      const response = await this.abortClient.withAbortController<
        Record<'data', TFetchTradesResponse>
      >(this.fetch.bind(this), {
        method: 'GET',
        url: `/clientsarea/account/trades_json/list/`,
        params: {
          id: accountId,
          limit: size,
          offset,
          beginDate,
          endDate,
          sSortDir_0,
          iSortCol_0,
        },
      });

      if (response instanceof Error) {
        console.error('getTrades error', response);
        return null;
      }

      return response?.data || null;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }

      notifyWith.genericNetworkError(error);
      console.error('getTrades error:', error);
      return null;
    }
  }
}

export const tradesService = new TradesService();
