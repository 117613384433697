import { useMediaQuery } from '@mui/material';
import cn from 'classnames';
import { useContext, useEffect, useState } from 'react';
import {
  Notification as UIKitNotification,
  Switch,
  useData,
} from 'react-ui-kit-exante';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { TranslationContext } from 'contexts';
import { accountSettingsService } from 'services/settings/accountSettings';
import { useTheme } from 'theme';

import {
  StyledButtonContainer,
  StyledTooltipContent,
  StyledTooltipHeader,
  StyledTooltipText,
} from './MarginTradingButton.styled';
import { TMarginTradingButtonProps } from './MarginTradingButton.types';

export const MarginTradingButton = ({
  marginEnabled: externalMarginEnabled,
  accountId,
  className,
}: TMarginTradingButtonProps) => {
  const { currentLanguage, t } = useContext(TranslationContext);
  const [marginEnabled, setMarginEnabled] = useState<boolean>(
    externalMarginEnabled,
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setMarginEnabled(externalMarginEnabled);
  }, [externalMarginEnabled]);

  const { fetchData: switchMarginTrading, isLoading: savingChanges } = useData<
    boolean | null
  >({
    onFetch: async () => {
      return accountSettingsService.switchMarginTrading(
        accountId,
        marginEnabled,
        currentLanguage,
      );
    },
    onSuccess: (newMarginEnabled) => {
      if (newMarginEnabled !== null) {
        UIKitNotification.success({
          title: t('account_settings_success_notification'),
        });
        setMarginEnabled(newMarginEnabled);
      }
    },
    loading: false,
  });

  const buttonText = marginEnabled
    ? t('generic__action__disable')
    : t('generic__action__enable');

  return (
    <StyledButtonContainer
      className={cn('SettingsMarginTradingButton', className)}
    >
      <ActionWithConfirmation
        onConfirm={switchMarginTrading}
        content={
          <StyledTooltipContent className="SaveButtonContainer">
            <StyledTooltipHeader className="MarginTradingTooltipHeader">
              {t('margin_trading')}
            </StyledTooltipHeader>
            <StyledTooltipText className="MarginTradingTooltipText">
              {t('account_settings_margin_trading_tooltip', {
                ACCOUNT_ID: accountId,
                ACTION: buttonText.toLowerCase(),
              })}
            </StyledTooltipText>
          </StyledTooltipContent>
        }
        confirmButtonNameKey="Confirm"
        cancelButtonNameKey="Cancel"
        placement={isMobile ? 'top' : 'left'}
        disabled={savingChanges}
        withCloseAfterConfirmation
      >
        <Switch
          checked={marginEnabled}
          label=""
          title=""
          disabled={savingChanges}
        />
      </ActionWithConfirmation>
    </StyledButtonContainer>
  );
};
