import { Button, Select } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledCheckoutWrapper = styled('div')`
  min-height: 100%;
  padding: 30px 20px 40px;
  background: #f8f8f8;
  font-size: 16px;
  font-family:
    -apple-system,
    system-ui,
    blinkmacsystemfont,
    roboto,
    'Helvetica Neue',
    'Fira Sans',
    ubuntu,
    oxygen,
    'Oxygen Sans',
    cantarell,
    'Droid Sans',
    'Lucida Grande',
    'Apple Color Emoji',
    'Hiragino Kaku Gothic Pro',
    meiryo,
    'Hiragino Sans GB W3',
    'Noto Naskh Arabic',
    'Droid Arabic Naskh',
    geeza pro,
    'Simplified Arabic',
    'Noto Sans Thai',
    thonburi,
    dokchampa,
    'Droid Sans Thai',
    '.SFNSDisplay-Regular',
    'Heiti SC',
    'Microsoft Yahei',
    helvetica,
    arial,
    sans-serif;
`;

export const StyledCheckoutTitle = styled('h1')`
  margin: 0;
  margin-bottom: 24px;

  color: #16171d;
  font-weight: bold;
  font-size: 20px;
  font-style: normal;
`;

export const StyledCheckoutStatus = styled('div')`
  text-align: center;

  h1 {
    margin-top: 24px;
  }

  p {
    margin: 24px 0;
  }

  a {
    color: #09d674;
    text-decoration: none;
  }
`;

export const StyledCheckoutAmount = styled('div')`
  margin-bottom: 24px;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr;
  align-items: start;
`;

export const StyledCurrencyIcon = styled('img')`
  width: 32px;
  height: 32px;
`;

export const StyledCheckoutAmountSelect = styled(Select)`
  .MuiFilledInput-input {
    padding-top: 14px;
    padding-bottom: 13px;
    padding-right: 40px !important;
    line-height: 1;
  }
` as typeof Select;

export const StyledCheckoutNote = styled('p')`
  margin: 24px 0;
  font-size: 14px;
`;

export const StyledCheckoutButton = styled(Button)`
  background: #09d674 !important;
  color: #16171d !important;
  font-size: 17px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

  &::after {
    display: none;
  }
` as typeof Button;
