import { TIconName } from 'react-ui-kit-exante';

import { TCorporateAction } from 'services/corporateActions';

export const CORPORATE_ACTIONS_POSTINGS_STATUS_TO_KEY_MAP: Record<
  NonNullable<TCorporateAction['status']>,
  string
> = {
  Paid: 'generic__paid',
  Confirmed: 'generic__confirmed',
  Announce: 'generic__announced',
};

export const CORPORATE_ACTIONS_POSTINGS_STATUS_TO_ICON_NAME_MAP: Record<
  NonNullable<TCorporateAction['status']>,
  TIconName
> = {
  Paid: 'CircleCheckIcon',
  Confirmed: 'CircleCheckIcon',
  Announce: 'CircleClockIcon',
};
