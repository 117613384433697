import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useCallback, useState } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { AccountSelectionQRContent } from 'components/AccountSelection/components/AccountSelectionTitle/components/AccountSelectionQRPopover/components';
import { useGetOneTimeToken } from 'components/AccountSelection/hooks';
import { Tooltip } from 'components/Tooltip';

export const AccountSelectionQRPopover = () => {
  const { getTokensDebounced, getTokens, link, isLoading, isError } =
    useGetOneTimeToken();
  const [isShowPopover, setIsShowPopover] = useState(false);

  const onPopoverChange = useCallback(() => {
    if (!isShowPopover) {
      getTokensDebounced();
    }
  }, [isShowPopover, getTokensDebounced]);

  const togglePopover = useCallback(() => {
    setIsShowPopover((prevState) => !prevState);
    onPopoverChange();
  }, [isShowPopover, onPopoverChange]);

  const handleClickAway = useCallback(() => {
    setIsShowPopover(false);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      {/* span is required to handle away clicks properly */}
      <span>
        <Tooltip
          title={
            <AccountSelectionQRContent
              getTokens={getTokens}
              link={link}
              isError={isError}
              isLoading={isLoading}
            />
          }
          open={isShowPopover}
          placement="right"
          sx={{ maxWidth: '356px' }}
          arrow
          forceCustomIcon
          disableHoverListener
        >
          <IconButton
            iconName="QrCodeIcon"
            iconSize={32}
            iconColor="primary"
            onClick={togglePopover}
            data-test-id="qr-code-login-button"
          />
        </Tooltip>
      </span>
    </ClickAwayListener>
  );
};
