import { styled } from 'theme';

export const StyledChartWrapper = styled('div')`
  width: 100%;
  height: 400px;
  font-size: 12px;

  margin-bottom: 24px;
  padding: 0 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .recharts-cartesian-grid {
    line {
      stroke: ${({ theme }) => theme.color.line.primary};
    }
  }
  .recharts-cartesian-axis {
    line {
      stroke: ${({ theme }) => theme.color.bg.promo};
    }
  }
`;

export const StyledChartLegendSkeleton = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 16px;
`;

export const StyledChartLegendItemSkeleton = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
