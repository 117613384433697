import { FC, useContext } from 'react';

import {
  CardSettingsList,
  CardSettingsOrder,
} from 'pages/CardSettings/components';
import { CardSettingsContext } from 'pages/CardSettings/contexts';

export const CardSettingsWrapper: FC = () => {
  const { issueType, cards } = useContext(CardSettingsContext);

  let showOrderForm = false;

  if (cards) {
    showOrderForm = !cards.length || !!issueType;
  }

  return (
    <div className="CardSettingsTab">
      {showOrderForm ? <CardSettingsOrder /> : <CardSettingsList />}
    </div>
  );
};
