import { useContext } from 'react';
import { Skeleton, Tooltip } from 'react-ui-kit-exante';

import { RadioButtons } from 'components/RadioButtons';
import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext';

import { CashTransferTab } from '../../../../context';
import { useTransfers } from '../../../../hooks';

import {
  StyledNoteContainer,
  StyledTabSelector,
  StyledTabSkeletonWrapper,
  StyledTabsSkeletonLoader,
  StyledTooltipDescription,
} from './TabSelector.styled';
import { useOptions } from './hooks';

export const TabSelector = () => {
  const options = useOptions();
  const { t } = useContext(TranslationContext);
  const {
    isLoading,
    cashTransferTab: tab,
    setCashTransferTab: setTab,
  } = useTransfers();

  return (
    <StyledTabSelector className="TransferTabSelector">
      {!isLoading && (
        <>
          <RadioButtons<CashTransferTab>
            onChange={setTab}
            value={tab}
            options={options}
          />
          {tab === 'INTERNAL' && (
            <Tooltip
              title={
                <StyledTooltipDescription className="TooltipDescription">
                  <Text weight="400" size="13px" color="secondary">
                    {t('layout__transfers__cash__note-tooltip')}
                  </Text>
                  <Text weight="400" size="13px" color="secondary">
                    {t('layout__transfers__cash__note-tooltip-description')}
                  </Text>
                </StyledTooltipDescription>
              }
            >
              <StyledNoteContainer className="NoteContainer">
                <Text weight="500" size="13px" color="secondary" withInfoIcon>
                  {t('label_key_important_note')}
                </Text>
              </StyledNoteContainer>
            </Tooltip>
          )}
        </>
      )}
      {isLoading && (
        <StyledTabsSkeletonLoader className="TabsSkeletonLoader">
          <StyledTabSkeletonWrapper>
            <Skeleton width="100%" height="48px" />
          </StyledTabSkeletonWrapper>
          <StyledTabSkeletonWrapper>
            <Skeleton width="100%" height="48px" />
          </StyledTabSkeletonWrapper>
          <StyledTabSkeletonWrapper>
            <Skeleton width="100%" height="48px" />
          </StyledTabSkeletonWrapper>
        </StyledTabsSkeletonLoader>
      )}
    </StyledTabSelector>
  );
};
