import { useMediaQuery } from '@mui/material';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AccountIcon, PortfolioIcon } from 'assets/icons';
import { StyledHeaderLink } from 'components/Header/Header.styled';
import {
  StyledAccountName,
  StyledSupervisorText,
} from 'components/Header/components/Account/Account.styled';
import { BrandingContext, UserDetailsContext } from 'contexts';
import { PATHS } from 'router';
import { TClientsareaTheme } from 'theme';

import { TAccountProps } from './Account.types';

export const Account: FC<TAccountProps> = ({ onClick }) => {
  const { userDetails } = useContext(UserDetailsContext);
  const { branding } = useContext(BrandingContext);

  const { full_name: fullName, email } = userDetails?.info?.current || {};
  const userName = fullName || email || 'User';

  const isDesktop = useMediaQuery((theme: TClientsareaTheme) =>
    theme.breakpoints.up('md'),
  );
  const Icon = isDesktop ? AccountIcon : PortfolioIcon;

  const isSupervisor = !!branding?.is_supervisor;

  const isComponentVisible = Boolean(
    branding?.show_header_username && userDetails,
  );

  return !isComponentVisible ? null : (
    <StyledAccountName className="account__name" onClick={onClick}>
      <Link to={`${PATHS.ROOT}${PATHS.SETTINGS_PROFILE}`}>
        <StyledHeaderLink className="account__name-link">
          <Icon className="xnt-icon" />
          {userName}
        </StyledHeaderLink>
      </Link>
      {isSupervisor && (
        <StyledSupervisorText className="account__name-supervisor">
          (supervisor mode)
        </StyledSupervisorText>
      )}
    </StyledAccountName>
  );
};
