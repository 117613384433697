import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledButtonsGroupContainer,
  StyledButtonsGroupItem,
} from './ButtonsGroup.styled';
import { TButtonsGroupProps } from './ButtonsGroup.types';

export const ButtonsGroup = <T extends string>({
  value,
  options,
  onChange,
  isLoading,
}: TButtonsGroupProps<T>) => {
  if (isLoading) {
    return <Skeleton width="100%" height="48px" />;
  }

  return (
    <StyledButtonsGroupContainer
      className="ButtonsGroupContainer"
      count={options.length}
    >
      {options.map((option) => (
        <StyledButtonsGroupItem
          className="ButtonsGroupItem"
          type="button"
          active={value === option.value}
          key={option.id}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </StyledButtonsGroupItem>
      ))}
    </StyledButtonsGroupContainer>
  );
};
