import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';

import { TAmountFormValueRowProps } from './AmountFormValueRow.types';

export const prepareAmountFormTextValue = (
  value: TAmountFormValueRowProps['value'],
  shorten: boolean,
) => {
  if (!value) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  if (!shorten) {
    return value;
  }

  // region shorten
  if (
    !(
      typeof value === 'number' ||
      (typeof value === 'string' && value.length >= 14)
    )
  ) {
    return value;
  }

  const strValue = String(value);

  return `${strValue.substring(0, 7)}...${strValue.slice(-5)}`;
  // endregion
};
