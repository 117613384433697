/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useReducer } from 'react';

import { TInitWireValue, TWithdrawalHistoryEntry } from 'services/withdrawal';

import { useTransfers } from '../../../../../hooks';

import { initialToBankAccountState } from './constants';
import { toBankAccountReducer } from './reducer';
import { TRepeatTransferPayload } from './types';

export const useToBankAccount = (
  baseName: string,
  initValues: Partial<TInitWireValue> = {},
) => {
  const fixedInitWireValues = Object.fromEntries(
    Object.entries(initValues).map(([key, value]) => {
      if (value === null) {
        return [key, ''];
      }

      return [key, value];
    }),
  );

  const fields = {
    ...initialToBankAccountState.fields,
    ...fixedInitWireValues,
    name: baseName,
  };

  const { transfersState, clearRepeatedWithdrawal, cashTransferTab } =
    useTransfers();

  const [toBankAccountState, toBankAccountDispatch] = useReducer(
    toBankAccountReducer,
    {
      ...initialToBankAccountState,
      fields,
    },
  );

  const repeatTransfer = (data: TWithdrawalHistoryEntry) => {
    const {
      name,
      client,
      bank_address,
      bank_country,
      bank_name,
      beneficiary_country,
      city,
      comment,
      correspondent_account,
      correspondent_swift,
      currency,
      iban,
      postal_code,
      short_address,
      state,
      swift,
      all_funds,
    } = data;

    const payload: TRepeatTransferPayload = {
      currency,
      isAllFunds: all_funds,
      fields: {
        name: name || client,
        bank_address,
        bank_country,
        bank_name,
        beneficiary_country,
        city,
        comment,
        correspondent_account,
        correspondent_swift,
        iban,
        postal_code,
        short_address,
        state,
        swift,
      },
    };

    toBankAccountDispatch({ type: 'REPEAT_TRANSFER', payload });
    clearRepeatedWithdrawal();
  };

  useEffect(() => {
    if (transfersState.repeatedWithdrawal && cashTransferTab === 'BANK') {
      repeatTransfer(transfersState.repeatedWithdrawal);
    }
  }, [transfersState.repeatedWithdrawal]);

  useEffect(() => {
    if (cashTransferTab !== 'BANK') {
      toBankAccountDispatch({
        type: 'RESET_STATE',
        payload: {
          ...initialToBankAccountState,
          fields,
        },
      });
    }
  }, [cashTransferTab]);

  return {
    toBankAccountState,
    toBankAccountDispatch,
  };
};
