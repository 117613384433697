import { Panel } from 'react-ui-kit-exante';

import { AccountsSearchField } from 'components/AccountsSearchField';
import { styled } from 'theme';

export const StyledCell = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const StyledTypeNameCell = styled('div')`
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledPriceCell = styled('div')`
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledCellWarning = styled('span')`
  color: ${({ theme }) => theme.color.typo.warning};
`;

export const StyledAccountSelectorContainer = styled('div')`
  padding: 0 24px 16px;
  background-color: ${({ theme }) => theme.color.bg.primary};
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 13px;
`;

export const StyledPanel = styled(Panel)`
  .PanelTitle {
    margin-bottom: 0;
  }
`;

export const StyledAccountsSearchField = styled(AccountsSearchField)`
  width: 232px;
`;
