import { FC } from 'react';

import { StyledCheckmarkIcon } from './GeneratedStatusCell.styled';
import { TGeneratedStatusCellProps } from './GeneratedStatusCell.types';

export const GeneratedStatusCell: FC<TGeneratedStatusCellProps> = ({
  data,
  columnName,
}) => {
  const isFormatAvailable = data.format?.some(
    (format) => format.toLowerCase() === columnName.toLowerCase(),
  );

  if (!isFormatAvailable) {
    return null;
  }

  return <StyledCheckmarkIcon />;
};
