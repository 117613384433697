import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

export const useAvailableTabs = () => {
  const { accountsTabs, selectedAccount } = useAppSelector(selectAccounts);

  const isTabExist = (tab: string) => {
    const availableTabs = new Set(
      selectedAccount?.types.reduce<string[]>((result, type) => {
        const tabs = accountsTabs[type] || [];

        return [...result, ...tabs];
      }, []),
    );

    return availableTabs.has(tab);
  };

  return {
    isTabExist,
  };
};
