import { TTreeModule } from 'services/instrumentsTree/instrumentsTree.types';
import { ActionMap } from 'types/action';

export enum ActionTypes {
  RESET_EXPANDED_ROWS = 'RESET_EXPANDED_ROWS',
  SET_EXPANDED_ROWS = 'SET_EXPANDED_ROWS',
  ADD_DOWNLOADED_PATHS = 'ADD_DOWNLOADED_PATHS',
  RESET_DOWNLOADED_PATHS = 'RESET_DOWNLOADED_PATHS',
  SET_TREE_WAS_FILTERED = 'SET_TREE_WAS_FILTERED',
  SET_LOADING_INSTRUMENT_ID = 'SET_LOADING_INSTRUMENT_ID',
}

export type TState = {
  expandedRows: Record<string, boolean>;
  downloadedPaths: Set<string>;
  module: TTreeModule;
  treeWasFiltered: boolean;
  loadingInstruments: Set<string>;
};

export type TPayload = {
  [ActionTypes.RESET_EXPANDED_ROWS]: null;
  [ActionTypes.SET_EXPANDED_ROWS]: Record<string, boolean>;
  [ActionTypes.ADD_DOWNLOADED_PATHS]: string;
  [ActionTypes.RESET_DOWNLOADED_PATHS]: null;
  [ActionTypes.SET_TREE_WAS_FILTERED]: boolean;
  [ActionTypes.SET_TREE_WAS_FILTERED]: boolean;
  [ActionTypes.SET_LOADING_INSTRUMENT_ID]: Set<string>;
};

export type TActionsState = ActionMap<TPayload>[keyof ActionMap<TPayload>];
