import { IconButton, Panel } from 'react-ui-kit-exante';

import { CurrencySelect } from 'components/CurrencySelect';
import { styled } from 'theme';

export const StyledReportEditFieldset = styled('fieldset')`
  border: none;
`;

export const StyledReportEditPanel = styled(Panel)`
  padding-bottom: 0;

  .PanelTitle {
    margin-bottom: 0;
  }
`;

export const StyledReportEditActions = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledReportEditIconButton = styled(IconButton)`
  font-size: 15px;

  .IconButtonLabel {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    .IconButtonLabel {
      display: inline-block;
    }
  }
`;

export const StyledCurrencySelect = styled(CurrencySelect)`
  .CurrencyCode {
    padding-top: 0;
  }
`;

export const StyledReportEditHead = styled('div')`
  display: grid;
  grid-template-rows: 1fr;
  gap: 24px;

  margin: 0 -24px;
  padding: 24px;

  border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 260px;
  }
`;

export const StyledReportEditSection = styled('div')`
  margin: 0 -24px;
  padding: 24px;

  border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledReportEditSectionTitle = styled('h4')`
  margin: 0 0 24px;

  font-family: ${({ theme }) => theme?.font?.header};
  font-size: 20px;

  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledReportEditSectionFormatList = styled('div')`
  display: flex;
`;

export const StyledFormItemsMessage = styled('div')`
  color: ${({ theme }) => theme?.color?.typo?.radical};
  font-size: 14px;
  padding: 14px 0;
`;
