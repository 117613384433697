import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Skeleton } from 'react-ui-kit-exante';

import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { useQuery } from 'hooks/useQuery';
import { getOAuthFlows } from 'pages/Security/pages/ExternalAccounts/ExternalAccouns.helpers';
import { AuthType, TAuthFlow } from 'services/branding';

import {
  StyledLoginHeading,
  StyledLoginLogo,
  StyledLoginLogoSkeleton,
} from '../../Login.styled';
import { StyledAuthPrivacyIcon } from '../EmailVerification/EmailVerification.styled';

import { StyledSigninWrapper } from './Signin.styled';
import { SigninCredentials } from './components/SigninCredentials';
import { SigninOauth } from './components/SigninOauth';
import {
  StyledSigninOauthHint,
  StyledSigninOauthHintJustifier,
  StyledSigninOauthHintText,
} from './components/SigninOauth/SigninOauth.styled';
import { SigninContext } from './contexts';

export const SigninWithContext = () => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const { mfa } = useContext(SigninContext);
  const location = useLocation();
  const query = useQuery();

  const navigatedFromFault =
    location.state?.authFault || query.get('auth_fault') !== null || false;

  const title = useMemo(
    () =>
      mfa.type
        ? t('layout__settings__mfa__2step_auth')
        : branding?.input_form_slogan_text || '',
    [mfa.type],
  );

  const flows = branding?.branding?.auth_flows;
  const iconAuthPrivacy = branding?.branding?.ui_settings?.icon_auth_privacy;

  const flowsOauth: TAuthFlow[] = getOAuthFlows(branding) || [];

  const hasCredentialsFlow = flows?.some(
    (flow: TAuthFlow) => flow.type === AuthType.Credentials,
  );
  const hasOauthFlows = !!flowsOauth.length;

  return (
    <StyledSigninWrapper className="SigninWrapper">
      {isTranslationsLoading ? (
        <StyledLoginLogoSkeleton width="60%" height={48} />
      ) : (
        <StyledLoginLogo className="LoginLogo" />
      )}

      {isTranslationsLoading ? (
        <Skeleton width="100%" height={64} />
      ) : (
        <StyledLoginHeading
          className="LoginHeading"
          dangerouslySetInnerHTML={{
            __html: navigatedFromFault ? t('layout__auth__login_fault') : title,
          }}
        />
      )}

      {hasCredentialsFlow && <SigninCredentials />}

      {hasCredentialsFlow && hasOauthFlows && (
        <StyledSigninOauthHint className="SigninOauthHint">
          <StyledSigninOauthHintJustifier className="SigninOauthHintJustifier" />
          <StyledSigninOauthHintText className="SigninOauthHintText">
            {t('layout__login__oauth_hint')}
          </StyledSigninOauthHintText>
          <StyledSigninOauthHintJustifier className="SigninOauthHintJustifier" />
        </StyledSigninOauthHint>
      )}

      {!mfa.type && <SigninOauth flows={flowsOauth} />}

      {iconAuthPrivacy && (
        <StyledAuthPrivacyIcon
          className="AuthPrivacyIcon"
          src={iconAuthPrivacy}
          alt={t('generic__privacy_protect')}
        />
      )}
    </StyledSigninWrapper>
  );
};
