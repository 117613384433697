import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from 'react-ui-kit-exante';

import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { useQuery } from 'hooks/useQuery';
import { PATHS } from 'router/router.constants';
import { partnerService } from 'services/partner';
import { PartnerKycStatuses } from 'services/partner/partner.constants';
import { useAppDispatch } from 'store/hooks';
import { fetchMenu } from 'store/menu';
import { TValueOf } from 'types/TValueOf';

import {
  DEFAULT_PATNER_TYPE,
  PARTNER_PAYMENTS_URL,
} from './PartnerKyc.constants';
import { TPartnerTypes } from './PartnerKyc.types';
import {
  PartnerKycWrapper,
  PartnerLanding,
  PartnerProceed,
} from './components';

export const PartnerKyc: FC = () => {
  const { currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useAppDispatch();

  const typeParam: TValueOf<TPartnerTypes> = query.get(
    'type',
  ) as TValueOf<TPartnerTypes>;

  const [isShowLanding, setIsShowLanding] = useState(true);
  const [partnerType, setPartnerType] =
    useState<TValueOf<TPartnerTypes>>(DEFAULT_PATNER_TYPE);

  useEffect(() => {
    if (typeParam) {
      setPartnerType(typeParam);
      setIsShowLanding(false);
    } else {
      setIsShowLanding(true);
    }
  }, []);

  useEffect(() => {
    const isPartnerKycEnabled = branding?.branding?.partner_kyc_enabled;
    const redirectUrl = branding?.branding?.default_redirect;

    if (!isPartnerKycEnabled && redirectUrl) {
      navigate(redirectUrl);
    }
  }, [branding]);

  const { data: partnerKycStatus, fetchData: fetchPartnerKycStatus } = useData({
    onFetch: () => partnerService.getKycStatus(currentLanguage),
  });

  useEffect(() => {
    fetchPartnerKycStatus();
  }, [isShowLanding]);

  useEffect(() => {
    if (partnerKycStatus?.status === PartnerKycStatuses.PRODUCT_APPROVED) {
      localStorage.setItem('partner:first-load', 'true');
      navigate(PARTNER_PAYMENTS_URL);
      return;
    }

    if (!partnerKycStatus?.partner_kyc_open) {
      partnerService.setKycOpen(true);
      dispatch(fetchMenu());
    }
  }, [partnerKycStatus]);

  const handleChooseButtonClick = (type: TValueOf<TPartnerTypes>) => {
    setPartnerType(type);
    navigate(`${PATHS.ROOT}${PATHS.PARTNER_KYC}?type=${type}`);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setIsShowLanding(false);
  };

  const handleBackButtonClick = () => {
    navigate(`${PATHS.ROOT}${PATHS.PARTNER_KYC}`);
    setIsShowLanding(true);
  };

  return (
    <PartnerKycWrapper>
      {isShowLanding && (
        <PartnerLanding onChooseButtonClick={handleChooseButtonClick} />
      )}

      {!isShowLanding && (
        <PartnerProceed
          partnerKycStatus={partnerKycStatus}
          type={partnerType}
          onBackButtonClick={handleBackButtonClick}
          fetchPartnerKycStatus={fetchPartnerKycStatus}
        />
      )}
    </PartnerKycWrapper>
  );
};
