import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts';
import { FeeUsersStatuses } from 'pages/AssetManagementClients/components/AssetManagementClientsTable/cells/StatusCell/StatusCell.constants';
import {
  StyledStatusCellItem,
  StyledStatusCellItemApproved,
  StyledStatusCellItemRejected,
} from 'pages/AssetManagementClients/components/AssetManagementClientsTable/cells/StatusCell/StatusCell.styled';
import { TStatusCellProps } from 'pages/AssetManagementClients/components/AssetManagementClientsTable/cells/StatusCell/StatusCell.types';

export const StatusCell: FC<TStatusCellProps> = ({ status }) => {
  const { t } = useContext(TranslationContext);

  switch (status) {
    case FeeUsersStatuses.APPROVED:
      return (
        <StyledStatusCellItemApproved className="StatusCellItemApproved">
          {t('asset_management__status_processed')}
        </StyledStatusCellItemApproved>
      );

    case FeeUsersStatuses.OPEN_LINK:
    case FeeUsersStatuses.DOC_UPLOADED:
      return (
        <StyledStatusCellItem className="StatusCellItem">
          {t('asset_management__status_pending')}
        </StyledStatusCellItem>
      );

    default:
      return (
        <StyledStatusCellItemRejected className="StatusCellItemRejected">
          {t('asset_management__status_rejected')}
        </StyledStatusCellItemRejected>
      );
  }
};
