import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';
import {
  TUploadDocument,
  TUploadDocumentResponse,
  TWizardState,
} from 'services/settings/documents/documents.types';

class SettingsDocumentsService extends ApiRequest {
  async getWizardState(lang = 'en'): Promise<TWizardState | null> {
    try {
      const state = await this.fetch<TWizardState>({
        url: `/${lang}/clientsarea/rest/wizard/state/`,
      });

      return state.data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('getUploadGroups', error);
      return null;
    }
  }

  async uploadFile(docType: string, file: File): Promise<TUploadDocument> {
    try {
      const formData = new FormData();
      formData.append('doc_type', docType);
      formData.append('file', file);

      const state = await this.fetch<TUploadDocumentResponse>({
        url: `/clientsarea/account/document/upload/`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });

      return state.data.raw;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('uploadFile', error);
      return error;
    }
  }

  async removeFile(doc_id: string): Promise<boolean> {
    try {
      await this.fetch<TUploadDocumentResponse>({
        url: `/clientsarea/account/document/remove/${doc_id}`,
      });

      return true;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('removeFile', error);
      return false;
    }
  }
}

export const settingsDocumentsService = new SettingsDocumentsService();
