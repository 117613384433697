import { TSignupForm } from '../../pages/Login/pages/Signup/Signup.types';
import { TMfaType } from '../mfa';

export enum SignupStage {
  Captcha = 'captcha',
  Done = 'done',
}

export type TSignupParams = {
  lang?: string;
  full_name?: string;
} & TSignupForm;

export type TSignupResponse =
  | { data: TSignupResponseData; errors: null }
  | { data: null; errors: TSignupResponseErrors };

export type TSignupResponseData =
  | {
      stage: SignupStage.Done;
      redirect: string;
    }
  | {
      stage: SignupStage.Captcha;
      captcha: string;
    };

export type TSignupResponseErrors = Record<string, string[]>;

export enum SigninStage {
  MFA = '2fa',
  Captcha = 'captcha',
  Done = 'done',
}

export type TSigninResult = {
  data: TSigninResponse | null;
  errors: TSigninErrorData | null;
};

export type TSigninParams = {
  lang?: string;
  updateToken?: boolean;
  // Stage: password
  login?: string;
  password?: string;
  // Stage: captcha
  captchaToken?: string;
  // Stage: 2fa
  code?: string;
  service?: string;
  key?: string;
};

export type TSigninResponse =
  | {
      stage: SigninStage.MFA;
      mfa_type: TMfaType;
      key?: string;
      tokenid?: number;
    }
  | {
      stage: SigninStage.Captcha;
      site_key?: string;
      enabled?: boolean;
      visible?: boolean;
      error?: string;
    }
  | {
      stage: SigninStage.Done;
      url: string;
      x_access: string;
      y_access: string;
      x_access_expires_in: number;
    };

export type TSigninErrorData = Record<string, string[]>;

export type TPasswordRecoveryResponse = {
  message: string;
  result: 'error' | 'success' | 'ratelimit';
};

export type TResetPasswordCheckParams = {
  uid: string;
  token: string;
};

export type TResetPasswordCheckResponse = {
  message: string;
  result: 'success' | 'invalid';
};

export type TResetPasswordParams = {
  uid: string;
  token: string;
  password: string;
};

export type TResetPasswordResponse = {
  message: string;
  result: 'error' | 'success' | 'invalid';
};
