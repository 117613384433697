import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { reportsService } from 'services/reports/reports';
import { TSubscription } from 'services/reports/reports.types';
import { TChildren } from 'types/TChildren';

type TSubscriptionContext = {
  isLoading: boolean;
  isSaving: boolean;
  subscriptions: TSubscription[] | null;
  updateSubscriptions: (updateSubscriptions: TSubscription[]) => Promise<void>;
};

const initialState: TSubscriptionContext = {
  isLoading: false,
  isSaving: false,
  subscriptions: null,
  updateSubscriptions: () => Promise.resolve(),
};

export const SubscriptionContext =
  createContext<TSubscriptionContext>(initialState);

export const SubscriptionProvider: FC<TChildren> = ({ children }) => {
  const { currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const [subscriptions, setSubscriptions] = useState<TSubscription[] | null>(
    null,
  );

  const { fetchData: fetchSubscriptions, isLoading: isSubscriptionsLoading } =
    useData({
      loading: false,
      onFetch: () => reportsService.fetchSubscriptions(),
      onSuccess: (response) => {
        if (response) {
          setSubscriptions(response);
        }
      },
    });

  const { fetchData: updateSubscriptions, isLoading: isSaving } = useData({
    loading: false,
    onFetch: (updatedSubscriptions: TSubscription[]) => {
      return reportsService.fetchSubscriptions('POST', updatedSubscriptions);
    },
    onSuccess: (response) => {
      if (response && subscriptions) {
        const mergedSubs = subscriptions.map((sub) => {
          const updatedSub = response.find(
            (newSub) => sub.name === newSub.name,
          );
          return {
            ...sub,
            ...updatedSub,
          };
        });
        setSubscriptions(mergedSubs);
      }
    },
  });

  useEffect(() => {
    fetchSubscriptions();
  }, [currentLanguage]);

  const isLoading = isSubscriptionsLoading || isTranslationsLoading;

  const value = useMemo(
    () => ({
      isLoading,
      isSaving,
      subscriptions,
      updateSubscriptions,
    }),
    [isLoading, subscriptions, isSaving],
  );

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};
