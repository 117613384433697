import { useContext, useState } from 'react';
import { Input } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import { TOTP_CODE_LENGTH } from '../../../../Mfa.constants';
import { TSharedActionProps } from '../../../../Mfa.types';
import { useDisableByCode } from '../../../../hooks';
import { ConfirmPassword } from '../../../ConfirmPassword';
import {
  StyledSharedMfaContainer,
  StyledSharedMfaInputWithFooterContainer,
  StyledSharedMfaText,
} from '../../../styled';

export const DisableTotp = ({ onSuccess }: TSharedActionProps) => {
  const { t } = useContext(TranslationContext);
  const [key, setKey] = useState('');

  const { code, isVerifying, verifyErrorMessage, handleCodeChange } =
    useDisableByCode(TOTP_CODE_LENGTH, key, onSuccess, {
      disableTimer: true,
    });

  if (!key) {
    return <ConfirmPassword onSuccess={setKey} />;
  }

  return (
    <StyledSharedMfaContainer className="Container">
      <StyledSharedMfaText className="Text">
        {t('layout__security_tabs__mfa_modal_totp_description')}
      </StyledSharedMfaText>
      <StyledSharedMfaInputWithFooterContainer className="InputContainer">
        <Input
          fullWidth
          error={Boolean(verifyErrorMessage)}
          message={verifyErrorMessage}
          disabled={isVerifying}
          placeholder={t('layout__security_tabs__mfa_modal_totp_placeholder')}
          value={code}
          onChange={(e) => handleCodeChange(e.target.value)}
        />
      </StyledSharedMfaInputWithFooterContainer>
    </StyledSharedMfaContainer>
  );
};
