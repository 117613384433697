import { ISelectOption } from 'react-ui-kit-exante';

export const searchInOptions = (
  options: ISelectOption[],
  searchQuery: string,
): ISelectOption[] => {
  if (!searchQuery) {
    return options;
  }

  return options.filter((item) => {
    if (item.value === undefined) {
      return false;
    }

    return String(item.value).includes(searchQuery);
  });
};
