import { TCorporateAction } from 'services/corporateActions';
import { styled, TClientsareaTheme } from 'theme';

const getIconColor = ({
  theme,
  status,
}: {
  theme: TClientsareaTheme;
  status: NonNullable<TCorporateAction['status']>;
}) => {
  switch (status) {
    case 'Paid':
      return theme.color.icon.action;
    case 'Confirmed':
      return theme.color.icon.secondary;
    case 'Announce':
      return theme.color.icon.warning;
    default:
      return theme.color.icon.secondary;
  }
};

export const StyledCorporateActionsPostingsStatusWrapper = styled('span')<{
  status: NonNullable<TCorporateAction['status']>;
}>`
  display: flex;
  align-content: center;
  gap: 8px;

  color: ${getIconColor};

  svg {
    color: ${getIconColor} !important;
  }
`;

export const StyledCorporateActionsPostingsStatusText = styled('span')`
  margin: auto 0;
`;
