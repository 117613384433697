export enum PATHS {
  ROOT = '/clientsarea/',
  TRADES = 'account/trades/',
  TRANSACTIONS = 'account/transactions/',
  CHALLENGE_ACCOUNT_SUMMARY = 'account/challenge_account_summary/',
  CHALLENGE_RULES = 'account/challenge_rules/',
  ACCOUNT_SUMMARY = 'account/summary/',
  MARGIN_STRUCTURE = 'account/margin/',
  EXCHANGE = 'account/exchange/',
  TRANSFER = 'account/withdrawal/',
  DEPOSIT = 'account/deposit/transfer/',
  CARD_SETTINGS = 'account/cards/',
  DOWNLOADS = 'account/downloads/',
  CHALLENGE_PURCHASES = 'account/challenge_purchases/',
  CHALLENGE_PAYOUT = 'account/challenge_payout/',
  ANALYSIS = 'account/performance/',
  ASSET_MANAGEMENT_SETUPS = 'account/asset-management/setups/',
  ASSET_MANAGEMENT_CLIENTS = 'account/asset-management/clients/',
  HELP = 'help/',
  CHECKOUT_DEPOSIT = '/clientsarea/account/payment/checkout/',
  CHECKOUT_DEPOSIT_SUCCESS = '/clientsarea/account/payment/checkout/success/',
  CHECKOUT_DEPOSIT_FAILURE = '/clientsarea/account/payment/checkout/failure/',
  INTERESTS = 'account/interests/',
  COMMISSIONS = 'account/commissions/',
  OVERNIGHTS = 'account/overnights/',
  SHORT_ALLOWANCE_STATUS = 'account/short_allowance/',
  ECONOMIC_CALENDAR = 'account/economic_calendar/',
  API_MANAGEMENT = 'account/settings/api/',
  API_APPLICATIONS = 'account/settings/api/:id',
  SETTINGS_PROFILE = 'account/settings/profile/',
  SETTINGS_DOCUMENTS = 'account/settings/documents/',
  SETTINGS_AGREEMENTS = 'account/settings/agreements/',
  SETTINGS_SERVICES = 'account/settings/services/',
  NOTIFICATION_SETTINGS = 'account/settings/notification_groups/',
  SETTINGS_ACCOUNT_SETTINGS = 'account/account_settings/',
  SECURITY_ACCESS_RIGHTS = 'account/security/',
  MFA = 'account/security/mfa/',
  SECURITY_EXTERNAL_ACCOUNTS = 'account/security/external_accounts/',
  SECURITY_PASSWORD = 'account/security/password/',
  SUBSCRIPTIONS = 'account/settings/subscriptions/',
  CHALLENGE_VIDEO_TUTORIAL = 'help/tutorial/',
  PERIODIC_REPORTS = 'account/reports/',
  PARTNER = 'account/partner/',
  PARTNER_KYC = 'account/partner/join/',
  TAX_REPORTS = 'account/reports/tax/',
  CUSTOM_REPORTS = 'account/reports/custom/',
  CUSTOM_REPORTS_CREATE = 'account/reports/create/',
  CUSTOM_REPORTS_EDIT = 'account/reports/edit/',
  GENERATED_REPORTS = 'account/reports/generated/',
  CORPORATE_ACTIONS = 'account/cas/',
  CORPORATE_ACTIONS_INSTRUMENT = 'instrument/',
  CORPORATE_ACTIONS_INVESTMENT_ACCOUNTS = 'investment-accounts/',
  LOGIN = '/clientsarea/accounts/login/',
  OPEN_ACCOUNT = '/clientsarea/accounts/open-account/',
  EMAIL_VERIFY = '/clientsarea/account/verify_email/',
  RECOVERY_REQUEST = '/clientsarea/account/password_recovery/',
  RECOVERY_COMPLETE = '/clientsarea/reset/:uid/:token/',
}

export const MAIN_PAGE = '/';
export const WIZARD_PAGE = `${PATHS.ROOT}account/step1/`;
export const PARTNER_PAGE = PATHS.ROOT + PATHS.PARTNER;
