import { useMediaQuery } from '@mui/material';
import cn from 'classnames';
import { isToday } from 'date-fns';
import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ButtonDatePicker,
  IconButton,
  ISelectOption,
} from 'react-ui-kit-exante';

import {
  DEFAULT_MAX_ACCOUNTS_LENGTH,
  MAX_AUTOCOMPLETE_LENGTH,
} from 'constants/common';
import { REACT_DATEPICKER_LOCALE_MAP } from 'constants/reactDatepickerLocaleMap';
import { TranslationContext } from 'contexts';
import { today } from 'helpers/dates';
import { getFilterOptions } from 'helpers/getFilterOptions';
import { useDateFormatter } from 'hooks/useDateFormatter';
import {
  accountsActions,
  selectAccountLabel,
  selectAccounts,
  selectSelectedDate,
} from 'store/accounts';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTheme } from 'theme';

import { AccountsSearchField } from '../../../AccountsSearchField';
import { AutocompleteInfiniteListbox } from '../../../AutocompleteInfiniteListbox';

import {
  StyledAccountSelectionPanel,
  StyledAccountSelectionPanelActions,
  StyledAccountSelectionPanelDateContainer,
  StyledAccountSelectionPanelDateText,
  StyledAccountSelectionPanelSearchButton,
  StyledAccountSelectionPanelSearchFieldWrapper,
} from './AccountSelectionPanel.styled';
import { TAccountSelectionPanelProps } from './AccountSelectionPanel.types';
import { AddAccountButton } from './components';

export const AccountSelectionPanel: FC<TAccountSelectionPanelProps> = ({
  accounts,
  children,
  hideAccountSelector = false,
  hideAddAccount = false,
  hideBalanceButton = false,
  hideDate = false,
  hideTitle = false,
  hideActions = false,
  isHideSearch = false,
  onBalanceButtonClick,
  showBalance = false,
  title,
  useAbsolutePositioning = true,
  noCollapse = false,
  className = '',
}) => {
  const dispatch = useAppDispatch();
  const { t, currentLanguage } = useContext(TranslationContext);

  const [search, setSearch] = useState('');
  const dateFormatter = useDateFormatter();
  const { selectedAccountId, isAccountsLoading } =
    useAppSelector(selectAccounts);

  const selectedDate = useAppSelector(selectSelectedDate);
  const selectedAccountLabel = useAppSelector(selectAccountLabel);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const selectedClientId: string = selectedAccountId?.split('.')[0] || '';

  const [isSearchExpanded, setSearchExpanded] = useState<boolean>(false);

  const toggleSearch = (): void => {
    setSearchExpanded((current) => !current);
  };

  const onSearchSelect = useCallback(
    (_: SyntheticEvent<Element, Event>, account: ISelectOption) => {
      setSearch('');
      dispatch(
        accountsActions.selectAccount({ accountId: String(account.value) }),
      );
    },
    [],
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const options = useMemo(() => {
    if (!search) {
      return accounts;
    }

    const text = search.toLowerCase();

    return accounts.filter((account) =>
      account.label.toLowerCase().includes(text),
    );
  }, [accounts, search]);

  const listboxProps = useMemo(() => {
    return { limit: MAX_AUTOCOMPLETE_LENGTH };
  }, []);

  const onCalendarChange = useCallback((date: Date | null) => {
    if (date instanceof Date) {
      dispatch(accountsActions.selectDate(date));
    }
  }, []);

  useEffect(() => {
    if (isDesktop || isHideSearch) {
      setSearchExpanded(
        noCollapse || accounts.length > DEFAULT_MAX_ACCOUNTS_LENGTH,
      );
    }
  }, [noCollapse, accounts, isDesktop]);

  const accountSearchVisible: boolean =
    accounts.length > 1 && !hideAccountSelector;

  const calendarLocale =
    REACT_DATEPICKER_LOCALE_MAP[currentLanguage] || currentLanguage;

  const panelActions = hideActions ? undefined : (
    <StyledAccountSelectionPanelActions className="AccountSelectionPanelActions">
      {accountSearchVisible && (
        <>
          <StyledAccountSelectionPanelSearchFieldWrapper
            useAbsolutePositioning={useAbsolutePositioning}
            className="AccountSelectionPanelSearchFieldWrapper"
          >
            <AccountsSearchField
              withDescriptions
              placeholder={t('layout__account_selector__placeholder')}
              options={options}
              onInput={handleSearchChange}
              onBlur={() => setSearch('')}
              onChange={onSearchSelect}
              filterOptions={getFilterOptions(accounts.length)}
              value={selectedAccountLabel || ''}
              disabled={!accounts.length}
              disableClearable
              hidden={!isSearchExpanded}
              size={isDesktop ? 'medium' : 'small'}
              className="AccountSelectionPanelSearchField"
              noOptionsText={t('layout__summary__no_options')}
              showSkeleton={isAccountsLoading}
              data-test-id="account-selection__search-field"
              // todo use autocompleteAsync component, when scroll top bug will be fixed https://jira.exante.eu/browse/RUI-414
              ListboxComponent={AutocompleteInfiniteListbox}
              // @ts-expect-error -- this prop actually accept arbitrary data but cannot be reasonably extended
              ListboxProps={listboxProps}
            />
          </StyledAccountSelectionPanelSearchFieldWrapper>

          {!isHideSearch && (
            <StyledAccountSelectionPanelSearchButton
              onClick={toggleSearch}
              hidden={isSearchExpanded}
              iconSize={32}
              iconColor="secondary"
              iconName="SearchIcon"
              data-test-id="account-selection__search-button"
            />
          )}
        </>
      )}
      <>
        {!hideDate && (
          <StyledAccountSelectionPanelDateContainer className="AccountSelectionPanelDateContainer">
            {!isToday(selectedDate) && (
              <StyledAccountSelectionPanelDateText className="AccountSelectionPanelDateText">
                {dateFormatter(selectedDate)}
              </StyledAccountSelectionPanelDateText>
            )}
            <ButtonDatePicker
              onChange={onCalendarChange}
              selected={selectedDate}
              maxDate={today}
              className="graph__period"
              iconClassName="CalendarDatePickerIcon"
              locale={calendarLocale}
              disabled={isAccountsLoading}
              data-test-id="account-selection__datepicker"
            />
          </StyledAccountSelectionPanelDateContainer>
        )}

        {!hideBalanceButton && (
          <IconButton
            className="SummaryShowBalanceButton"
            iconName="ChartIcon"
            iconSize={32}
            iconColor={showBalance ? 'primary' : 'secondary'}
            onClick={onBalanceButtonClick}
            data-test-id="show-balance-button"
          />
        )}

        {!hideAddAccount && <AddAccountButton clientId={selectedClientId} />}
      </>
    </StyledAccountSelectionPanelActions>
  );

  const visibleTitle = hideTitle ? undefined : title;

  return (
    <StyledAccountSelectionPanel
      action={panelActions}
      className={cn('filter', className)}
      isHideSearch={isHideSearch}
      isSearchExpanded={isSearchExpanded}
      title={visibleTitle}
      useAbsolutePositioning={useAbsolutePositioning}
    >
      {children}
    </StyledAccountSelectionPanel>
  );
};
