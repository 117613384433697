import { FileUpload, Skeleton } from 'react-ui-kit-exante';

import {
  StyledDocumentsSection,
  StyledDocumentsWrapper,
} from '../../Documents.styled';

import {
  StyledDocumentsHeadingSkeleton,
  StyledDocumentsUploadGroupsSkeleton,
} from './DocumentsSkeleton.styled';

export const DocumentsSkeleton = () => {
  return (
    <StyledDocumentsWrapper className="DocumentsWrapper">
      <StyledDocumentsSection className="DocumentsSection">
        <StyledDocumentsHeadingSkeleton variant="rounded" animation="wave" />
        <StyledDocumentsUploadGroupsSkeleton>
          <Skeleton width="100%" height={16} />
          <FileUpload loading />
          <FileUpload loading />
          <FileUpload loading />
        </StyledDocumentsUploadGroupsSkeleton>
      </StyledDocumentsSection>
      <StyledDocumentsSection className="DocumnetsSection">
        <StyledDocumentsHeadingSkeleton variant="rounded" animation="wave" />
        <StyledDocumentsUploadGroupsSkeleton>
          <Skeleton width="100%" height={16} />
          <Skeleton width={280} height={16} />
          <Skeleton width={280} height={16} />
          <Skeleton width={280} height={16} />
        </StyledDocumentsUploadGroupsSkeleton>
      </StyledDocumentsSection>
    </StyledDocumentsWrapper>
  );
};
