import { styled } from 'theme';

export const StyledSecuritiesForm = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 16px;
`;

export const StyledOptionalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;
