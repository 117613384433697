import { createSlice } from '@reduxjs/toolkit';

import { EStatus } from 'types/api';

import { fetchApplicationSettings } from './application.actions';
import { applicationInitialState } from './application.constants';
import { getApplicationState } from './application.helpers';

export const applicationSlice = createSlice({
  name: 'application',
  initialState: applicationInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchApplicationSettings.pending, (state) => {
      state.status = EStatus.Loading;
    });

    builder.addCase(fetchApplicationSettings.fulfilled, (state, action) => {
      const { status, mfa, accountSettings } = getApplicationState(
        action.payload,
      );

      state.accountSettings = accountSettings;
      state.mfa = mfa;
      state.status = status;
    });
  },
});
