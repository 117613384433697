import { FC, useContext } from 'react';

import { CopyButton } from 'components/CopyButton';
import { DepositCryptoContext } from 'pages/Portfolio/tabs/Deposit/contexts';

import {
  StyledCryptoAddress,
  StyledCryptoAddressIconSkeleton,
  StyledCryptoAddressText,
  StyledCryptoAddressTextSkeleton,
} from './CryptoAddress.styled';

export const CryptoAddress: FC = () => {
  const { address, isLoadingAddress } = useContext(DepositCryptoContext);

  if (isLoadingAddress) {
    return (
      <StyledCryptoAddress className="CryptoAddress">
        <StyledCryptoAddressIconSkeleton variant="rounded" animation="wave" />
        <StyledCryptoAddressTextSkeleton variant="rounded" animation="wave" />
      </StyledCryptoAddress>
    );
  }

  if (!address) {
    return null;
  }

  return (
    <StyledCryptoAddress className="CryptoAddress">
      <CopyButton
        iconSize={24}
        iconColor="action"
        text={address}
        data-test-id="crypto_address__copy__address"
      />
      <StyledCryptoAddressText
        className="CryptoAddressText"
        data-test-id="crypto_address__text"
      >
        {address}
      </StyledCryptoAddressText>
    </StyledCryptoAddress>
  );
};
