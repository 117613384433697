import React from 'react';

import { useTransfers } from '../../../hooks';

import { ToBankAccount } from './ToBankAccount';
import { ToInternalAccount } from './ToInternalAccount';
import { ToMyAccount } from './ToMyAccount';

export const FundsTabsOutlet = () => {
  const { cashTransferTab } = useTransfers();

  switch (cashTransferTab) {
    case 'MY_ACCOUNT':
      return <ToMyAccount />;

    case 'INTERNAL':
      return <ToInternalAccount />;

    case 'BANK':
      return <ToBankAccount />;

    default:
      return null;
  }
};
