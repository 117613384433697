import { FC, KeyboardEvent, useContext, useMemo } from 'react';
import { Button, Input, Skeleton } from 'react-ui-kit-exante';

import { CurrencySelect } from 'components/CurrencySelect';
import { Text } from 'components/Text';
import { ENTER_KEY_NAME } from 'constants/keycodes';
import { CurrencyContext } from 'contexts/CurrencyContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { PATHS } from 'router/router.constants';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { CRYPTO_DEFAULT_MIN_MPI } from '../../routes/Crypto/Crypto.constants';

import {
  getCommissionMinMaxHint,
  TRANSFER_INPUT_LIMIT,
} from './AmountForm.constants';
import { getAccountRestrictionsForAmountForm } from './AmountForm.helpers';
import {
  StyledAllFundsSum,
  StyledAmountButton,
  StyledAmountFormContainer,
  StyledAmountFormDescription,
  StyledAmountFormHeader,
  StyledAmountFormHeadNotice,
  StyledAmountFormInputContainer,
  StyledAmountInputsWrapper,
  StyledRemainContainer,
  StyledValuesBlock,
} from './AmountForm.styled';
import { TAmountFormProps } from './AmountForm.types';
import {
  AmountFormAllfundsToggle,
  AmountFormAllfundsToggleOption,
  AmountFormHint,
  AmountFormValueRow,
} from './components';
import { useAmountForm } from './hooks';

export const AmountForm: FC<TAmountFormProps> = (props) => {
  const { t } = useContext(TranslationContext);
  const currencyCtx = useContext(CurrencyContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const {
    balances,
    from,
    to,
    shortenTo,
    noAmountDisabled,
    currency,
    amount,
    disabled,
    isLoading = false,
    isSubmitting,
    headHint,
    address,
    headNote,
    onCurrencyChange,
    type,
    limit,
    commissionCurrency,
    formDisabled,
    isAllFunds,
    onAllfundsChange,
  } = props;

  const withdrawalAmountType = isAllFunds
    ? AmountFormAllfundsToggleOption.Allfunds
    : AmountFormAllfundsToggleOption.Amount;

  const currentAccountRestrictions = useMemo(
    () =>
      getAccountRestrictionsForAmountForm({
        selectedAccountId,
        currency,
        balances,
      }),
    [selectedAccountId, currency, balances],
  );

  const disableAllFundsButton = Boolean(
    currentAccountRestrictions.hasOpenedPositions ||
      currentAccountRestrictions.hasLockedFunds,
  );

  const {
    feeText,
    parsedFee,
    feeValue,
    feeValueText,
    yougetText,
    formattedLimit,
    isError,
    parsedAmount,
    remainSum,
    total,
    allFundsPositive,
    handleAmountChange,
    handleAmountButtonClick,
    handleSubmit,
    currencies,
  } = useAmountForm({
    ...props,
    disableAllFundsButton,
    withdrawalAllFundsValue: withdrawalAmountType,
  });

  const allFundsColor = useMemo(() => {
    if (disableAllFundsButton || !allFundsPositive) {
      return 'secondary';
    }

    return !isError ? 'action' : 'radical';
  }, [disableAllFundsButton, isError, allFundsPositive]);

  const isAmountDisabled: boolean = noAmountDisabled ? false : !to;

  const fraction = type === WITHDRAWAL_TYPE.CRYPTO ? CRYPTO_DEFAULT_MIN_MPI : 2;

  const commissionHintText = props?.fee?.stdFee
    ? t('layout__withdrawals__fee-notice_default', {
        COMMISSION: `${props?.fee?.effectiveText} ${
          commissionCurrency || currency
        }`,
      })
    : getCommissionMinMaxHint({
        t,
        effective: String((feeValueText || feeValue).toFixed(fraction)),
        currency: commissionCurrency || currency,
        maximum: props?.fee?.max,
        minimum: props?.fee?.min,
        value: props?.fee?.visibleValue || '',
      });

  const onEnter = (e: KeyboardEvent) => {
    if (e.key === ENTER_KEY_NAME) {
      if (disabled || !parsedAmount || isError || isSubmitting) {
        return;
      }
      handleSubmit();
    }
  };

  const isMultiCurrencyHintVisible = Boolean(
    !currentAccountRestrictions.hasOpenedPositions &&
      currentAccountRestrictions.isMultiCurrency &&
      type !== WITHDRAWAL_TYPE.TRANSFER &&
      isAllFunds,
  );
  const isOpenedPositionsHintVisible = Boolean(
    currentAccountRestrictions.hasOpenedPositions && isAllFunds,
  );
  const isLockedFundsHintVisible = Boolean(
    currentAccountRestrictions.hasLockedFunds && isAllFunds,
  );

  const showCommissionText = Boolean(
    props.fee && (feeValue !== 0 || (isAllFunds && props.fee.allFunds)),
  );

  const nonStandardText =
    type === WITHDRAWAL_TYPE.WIRE || type === WITHDRAWAL_TYPE.WIRE_THIRD_PARTY
      ? `</br></br>${t(
          'Please see fees applicable in non-standard cases in',
        )} <a href='${PATHS.ROOT}${PATHS.COMMISSIONS}' target='_blank'>${t(
          'Commissions',
        )}</a>`.replace('non-standard', 'non&#8209;standard')
      : '';

  const isShowTilda = useMemo(() => {
    if (!parsedFee) {
      return false;
    }

    if (
      isAllFunds ||
      (props?.fee?.isOldCommissions && props?.fee?.type !== currency)
    ) {
      return true;
    }

    return currency !== commissionCurrency;
  }, [props?.fee, parsedFee]);

  const onAllFundsChange = (value: AmountFormAllfundsToggleOption) => {
    onAllfundsChange(value === AmountFormAllfundsToggleOption.Allfunds);
  };

  const noTransferAmount: boolean = isAllFunds ? !limit : !parsedAmount;

  const allfundsTransferDisabled = Boolean(
    currentAccountRestrictions.hasOpenedPositions ||
      currentAccountRestrictions.hasLockedFunds,
  );

  return (
    <StyledAmountFormContainer className="AmountContainer" type={type}>
      <StyledAmountFormHeader className="AmountTitle">
        {isLoading ? (
          <>
            <Skeleton width={100} height={32} />
            {headNote && <Skeleton width={80} height={32} />}
          </>
        ) : (
          <>
            <Text size="24px">
              {t('layout__subaccount_transfer__amount__title')}
            </Text>
            {headNote}
          </>
        )}
      </StyledAmountFormHeader>
      {headHint && (
        <StyledAmountFormHeadNotice className="AmountFormHeadNotice">
          {headHint}
        </StyledAmountFormHeadNotice>
      )}

      {address && (
        <StyledAmountFormInputContainer className="AmountInputContainer">
          <Input
            fullWidth
            disabled={address.disabled || isSubmitting}
            label={address.label}
            value={address.value}
            placeholder={address.placeholder}
            message={address.inputMessage}
            onChange={(e) => address.onChange(e.target.value)}
            error={address.isError}
          />
        </StyledAmountFormInputContainer>
      )}

      <StyledAmountInputsWrapper className="AmountInputsWrapper">
        <CurrencySelect
          showSkeleton={isLoading}
          options={currencies}
          icons={currencyCtx.currencies?.icons || {}}
          value={currency}
          placeholder={t('layout__subaccount_transfer__amount__currency')}
          onChange={onCurrencyChange}
          disabled={formDisabled}
        />
        <AmountFormAllfundsToggle
          value={withdrawalAmountType}
          showSkeleton={isLoading}
          onChange={onAllFundsChange}
        />
        {withdrawalAmountType === AmountFormAllfundsToggleOption.Amount && (
          <StyledAmountFormInputContainer className="AmountInputContainer">
            <Input
              fullWidth
              showSkeleton={isLoading}
              disabled={isAmountDisabled || isSubmitting || formDisabled}
              label={t('transfer__amount_input')}
              value={amount}
              maxLength={TRANSFER_INPUT_LIMIT}
              onChange={handleAmountChange}
              error={isError}
              onKeyDown={onEnter}
            />

            {!formDisabled && (
              <StyledAmountFormDescription className="AmountDescription">
                {isLoading || limit === null ? (
                  <Skeleton width={152} height={14} />
                ) : (
                  <>
                    <Text size="11px" color="secondary">
                      {t('layout__subaccount-transfer-amount__available-hint')}
                    </Text>
                    <StyledAmountButton
                      disabled={disableAllFundsButton || !allFundsPositive}
                      className="AmountButton"
                      onClick={handleAmountButtonClick}
                      type="button"
                    >
                      <Text size="11px" color={allFundsColor} weight="600">
                        {formattedLimit}
                      </Text>
                    </StyledAmountButton>
                    {parsedAmount > 0 && (
                      <StyledRemainContainer className="RemainContainer">
                        <Text size="11px" color="secondary">
                          {'>'}
                        </Text>
                        <Text size="11px" color="secondary" weight="600">
                          {remainSum}
                        </Text>
                      </StyledRemainContainer>
                    )}
                  </>
                )}
              </StyledAmountFormDescription>
            )}
          </StyledAmountFormInputContainer>
        )}
        {withdrawalAmountType === AmountFormAllfundsToggleOption.Allfunds && (
          <StyledAllFundsSum className="AllFundsSum">{total}</StyledAllFundsSum>
        )}
      </StyledAmountInputsWrapper>

      {!formDisabled && (
        <>
          {isMultiCurrencyHintVisible && (
            <AmountFormHint
              loading={isLoading}
              text={t('generic__balance_notices__balance_is_multicurrency')}
            />
          )}
          {isOpenedPositionsHintVisible && (
            <AmountFormHint
              loading={isLoading}
              text={t('generic__balance_notices__positions_opened')}
            />
          )}
          {isLockedFundsHintVisible && (
            <AmountFormHint
              loading={isLoading}
              text={t('generic__balance_notices__funds_locked')}
            />
          )}

          <StyledValuesBlock className="ValuesBlock">
            <AmountFormValueRow
              loading={isLoading}
              labelKey="transfer__from"
              value={from}
            />
            <AmountFormValueRow
              loading={isLoading}
              labelKey="transfer__to"
              value={to}
              shorten={shortenTo}
            />
            <AmountFormValueRow
              loading={isLoading}
              labelKey="transfer__you_get"
              value={`${isShowTilda ? '~' : ''}${yougetText}`}
            />
            <AmountFormValueRow
              loading={isLoading}
              labelKey="transfer__fee"
              value={`${isShowTilda ? '~' : ''}${feeText}`}
            />
            <AmountFormValueRow
              loading={isLoading}
              labelKey="transfer__total"
              value={`${isShowTilda ? '~' : ''}${total}`}
              valueColor={!isError ? 'primary' : 'radical'}
            />
          </StyledValuesBlock>
          {showCommissionText && (
            <AmountFormHint
              loading={isLoading}
              text={`${commissionHintText} ${nonStandardText}`}
            />
          )}
        </>
      )}

      <Button
        fullWidth
        showSkeleton={isLoading}
        loading={isSubmitting}
        disabled={
          disabled ||
          (isAllFunds && allfundsTransferDisabled) ||
          noTransferAmount ||
          isError ||
          isSubmitting ||
          formDisabled
        }
        type="button"
        onClick={handleSubmit}
      >
        {t('layout__subaccount_transfer__transfer')}
      </Button>
    </StyledAmountFormContainer>
  );
};
