import { FC } from 'react';

import { CurrencySelectSkeleton } from 'components/CurrencySelect/components/CurrencySelectSkeleton';

import {
  StyledDepositSectionBody,
  StyledDepositSectionHeadingSkeleton,
} from '../../../../../DepositWrapper/DepositWrapper.styled';
import { StyledCashAccountSectionWrapper } from '../../AccountCashSection.styled';

export const AccountCashSkeleton: FC = () => {
  return (
    <StyledCashAccountSectionWrapper className="CashAccountSectionWrapper">
      <StyledDepositSectionHeadingSkeleton variant="rounded" animation="wave" />

      <StyledDepositSectionBody className="DepositSectionBody">
        <CurrencySelectSkeleton />
      </StyledDepositSectionBody>

      <StyledDepositSectionBody className="DepositSectionBody">
        <CurrencySelectSkeleton />
      </StyledDepositSectionBody>
    </StyledCashAccountSectionWrapper>
  );
};
