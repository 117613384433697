import '../../index.css';

import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { BrandingIncludeCSS } from 'components/BrandingIncludeCSS';
import { HeaderInclude } from 'components/Header';
import {
  BrandingProvider,
  ComposedThemeProvider,
  CurrencyProvider,
  NotificationsProvider,
  TabsProvider,
  UserDetailsProvider,
} from 'contexts';
import 'helpers/translate';
import { IntercomAppProvider } from 'contexts/IntercomAppProvider';
import { LegalDocsProvider } from 'contexts/LegalDocsContext';
import { useSessionRegistration } from 'hooks/index';
import { useLogHandlePageLoadingTime } from 'hooks/useLogHandleTime';

export const RootPrivateContainer = () => {
  useSessionRegistration();
  useLogHandlePageLoadingTime();

  return (
    <BrandingProvider>
      <LegalDocsProvider>
        <UserDetailsProvider>
          <IntercomAppProvider>
            <CurrencyProvider>
              <TabsProvider>
                <NotificationsProvider>
                  <HeaderInclude />
                  <BrandingIncludeCSS />
                  <ComposedThemeProvider>
                    <Outlet />
                  </ComposedThemeProvider>
                </NotificationsProvider>
              </TabsProvider>
            </CurrencyProvider>
          </IntercomAppProvider>
        </UserDetailsProvider>
      </LegalDocsProvider>
    </BrandingProvider>
  );
};

export const RootPrivate = memo(RootPrivateContainer);
