import { useEffect, useState } from 'react';

export const useTimer = (initialTime = 30, { startOnInit = true } = {}) => {
  const [time, setTime] = useState(startOnInit ? initialTime : 0);

  const onStart = () => {
    setTime(initialTime);
  };

  const onClear = () => {
    setTime(0);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(time ? time - 1 : 0);
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [time]);

  return {
    time,
    start: onStart,
    clear: onClear,
    timeSecondsFormat: String(time).length > 1 ? time : `0${time}`,
  };
};
