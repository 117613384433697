import {
  analysisService,
  TFetchAccountHistoryParams,
  TFetchAccountHistoryResponse,
  TFetchAccountHistoryTimeoutResponse,
  TFetchAccountHistoryUnfinishedResponse,
} from 'services/analysis';

export const isResponseTypeUnfinished = (
  response: TFetchAccountHistoryResponse | null,
): response is TFetchAccountHistoryUnfinishedResponse => {
  return !!response && typeof response === 'object' && 'is_done' in response;
};

export const isAccountHistoryResponseUnfinished = (
  response: TFetchAccountHistoryResponse | null,
): boolean => {
  return isResponseTypeUnfinished(response) && !response.is_done;
};

export const isResponseTypeTimeout = (
  response: TFetchAccountHistoryResponse | null,
): response is TFetchAccountHistoryTimeoutResponse => {
  return (
    !!response && typeof response === 'object' && 'isTimeoutError' in response
  );
};

export const isAccountHistoryResponseTimeout = (
  response: TFetchAccountHistoryResponse | null,
): boolean => {
  return isResponseTypeTimeout(response) && response.isTimeoutError;
};

export const getAccountHistory = async (
  params: TFetchAccountHistoryParams | null,
  lang: string,
): Promise<TFetchAccountHistoryResponse | null> => {
  if (!params) {
    return null;
  }

  try {
    const response = await analysisService.getAccountHistory(lang, params);

    return response || null;
  } catch (e) {
    return null;
  }
};
