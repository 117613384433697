import { TInstrumentCellProps } from 'components/Cells/InstrumentCell/InstrumentCell.types';
import { Dates } from 'helpers/dates';

import { AssetIcon } from '../../AssetIcon/AssetIcon';

import InstrumentCellStyles from './InstrumentCell.module.css';

export const InstrumentCell = ({
  asset,
  iconName,
  expirationTime,
}: TInstrumentCellProps) => {
  const expired = Dates.isExpired(expirationTime);

  return (
    <div className={InstrumentCellStyles.Wrapper}>
      <div className={InstrumentCellStyles.Icon}>
        <AssetIcon
          asset={asset}
          iconName={iconName}
          isExpired={expired}
          isFullIcon
        />
      </div>

      {asset}
    </div>
  );
};
