import {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';
import {
  dashboardService,
  TApplicationEnvironment,
  TKeyScopeItem,
} from 'services/dashboard';

import { useApi } from '../../../../../../hooks';

import { OnAddAction } from './actions';
import { initialState } from './constants';
import reducer from './reducer';
import { TAddActionReducer } from './types';

export const useAddAction = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { handleAddApplication, tab } = useApi();
  const { currentLanguage, t } = useContext(TranslationContext);
  const [state, dispatch] = useReducer<TAddActionReducer>(
    reducer,
    initialState,
  );

  const isValid = useMemo(() => {
    if (state.scopes.length === 0) {
      return false;
    }

    return state.description.length !== 0;
  }, [state]);

  const scopeItemChecked = (scope: TKeyScopeItem) => {
    return state.scopes.includes(scope);
  };

  const handleScopesChange = (payload: TKeyScopeItem) => {
    const disabled =
      state.scopes.includes(payload) && state.scopes.length === 1;

    if (!disabled) {
      dispatch({ type: OnAddAction.OnSetScopes, payload });
    }
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: OnAddAction.OnSetDescription,
      payload: event.target.value,
    });
  };

  const handleEnvironmentChange = (value: string) => {
    dispatch({
      type: OnAddAction.OnSetEnvironment,
      payload: Number(value) as TApplicationEnvironment,
    });
  };

  const handleClose = () => {
    dispatch({ type: OnAddAction.OnResetState });
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleSave = async (event: FormEvent) => {
    event.preventDefault();

    dispatch({ type: OnAddAction.OnSaveStart });

    try {
      const result = await dashboardService.createDashboardApplication(
        currentLanguage,
        state,
      );

      if ('application' in result) {
        handleClose();
        handleAddApplication({ ...state, ...result.application });
        dispatch({ type: OnAddAction.OnSaveSuccess });
      }
    } catch (error) {
      dispatch({
        type: OnAddAction.OnSaveError,
        payload: t('generic__unkown_error_occured'),
      });
    }
  };

  useEffect(() => {
    dispatch({ type: OnAddAction.OnSetEnvironment, payload: tab });
  }, [tab]);

  return {
    ...state,
    isValid,
    isOpen,
    scopeItemChecked,
    handleScopesChange,
    handleDescriptionChange,
    handleEnvironmentChange,
    handleSave,
    handleClose,
    handleOpen,
  };
};
