import {
  ReportStatus,
  TndflStatus,
  TReport,
} from 'services/reports/reports.types';

export const TNDFL_STATUS_ERROR = [
  TndflStatus.Error,
  TndflStatus.ErrorsOccurred,
];

export const isReportErrored = (report: TReport) => {
  return (
    report.last_status === ReportStatus.Error ||
    (report.tndfl_status && TNDFL_STATUS_ERROR.includes(report.tndfl_status))
  );
};

export const isReportProcessing = (report: TReport) => {
  return (
    !isReportErrored(report) &&
    report.last_status === ReportStatus.Processing &&
    !report.files.length
  );
};

export const isReportPriceReady = (report: TReport) => {
  return (
    !isReportErrored(report) &&
    report.tndfl_status === TndflStatus.PriceCalculated
  );
};

export const isReportPriceAccepted = (report: TReport) => {
  return (
    isReportProcessing(report) &&
    report.tndfl_status === TndflStatus.PriceAccepted
  );
};

export const isReportSent = (report: TReport) => {
  return (
    !isReportErrored(report) &&
    (report.last_status === ReportStatus.Sent || report.files.length)
  );
};
