import { prepareTableId } from 'helpers/prepareTableId';
import { TBlockedFunds, TMargin } from 'services/margin';

export const ACCORDION_ID = 'margin-accordion';

export const MAX_TABLE_ITEMS = 20;

export const MARGIN_DEFAULT_TABLE_ID = prepareTableId('margin');

export const MARGIN_DEFAULT_DISPLAYED_COLUMNS: (keyof TMargin)[] = [
  'assetsList',
  'convertedMargin',
  'currency',
  'margin',
  'type_title',
  'type',
  'underlying',
];

export const BLOCKED_FUNDS_DEFAULT_TABLE_ID = prepareTableId('blockedFunds');

export const BLOCKED_FUNDS_DEFAULT_DISPLAYED_COLUMNS: (keyof TBlockedFunds)[] =
  [
    'convertedMargin',
    'currency',
    'lockedQtty',
    'margin',
    'reason',
    'type_title',
    'underlying',
  ];

export const ORDER_MARGIN_DEFAULT_TABLE_ID = prepareTableId('orderMargin');

export const ORDER_MARGIN_DEFAULT_DISPLAYED_COLUMNS: (keyof TMargin)[] = [
  'assetsList',
  'convertedMargin',
  'currency',
  'margin',
  'type_title',
  'type',
  'underlying',
];
