import { FC } from 'react';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { Dates } from 'helpers/dates';

import { DocStatusCell } from '../DocStatusCell';

import {
  StyledComboText,
  StyledComboTextDate,
  StyledComboTextTitle,
  StyledComboWrapper,
} from './DocComboCell.styled';
import { TDocComboCellProps } from './DocComboCell.types';

export const DocComboCell: FC<TDocComboCellProps> = ({
  t,
  title,
  signDate,
  isSigned,
  onTitleClick,
  withStatus,
  withDate,
  dateFormatter,
}) => {
  const convertedSignDate =
    signDate && dateFormatter
      ? dateFormatter(Dates.convertDateToUtcTimezone(new Date(signDate)))
      : Dates.convertDateToUtcTimezoneWithFormat(new Date(signDate)) || '-';

  return (
    <StyledComboWrapper className="TitleWrapper">
      {withStatus && <DocStatusCell isSigned={isSigned} t={t} />}
      <StyledComboText className="ComboText">
        <StyledComboTextTitle className="ComboTextTitle" onClick={onTitleClick}>
          {title}
        </StyledComboTextTitle>
        {withDate && (
          <StyledComboTextDate className="ComboTextDate">
            {isSigned && convertedSignDate
              ? convertedSignDate
              : DEFAULT_EMPTY_PLACEHOLDER}
          </StyledComboTextDate>
        )}
      </StyledComboText>
    </StyledComboWrapper>
  );
};
