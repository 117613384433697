import { Method } from 'axios';

import { isCancelledError } from 'helpers/abortController/abortController.helpers';
import { ApiRequest } from 'helpers/apiRequest';
import { getParamsSerializer } from 'helpers/getParamsSerializer';
import { notifyWith } from 'helpers/notifyWith';

import { LANG_PARAM_DEFAULT } from '../constants';

import {
  TAcceptPolTermsResponse,
  TAcceptPolTimingsResponse,
  TAcceptRusPriceParams,
  TAcceptRusPriceResponse,
  TAcceptRusTermsResponse,
  TCheckPolFundsParams,
  TCheckPolFundsResponse,
  TGetReportData,
  TGetReportResponse,
  TGetReportsParams,
  TRemoveRusReportParams,
  TReport,
  TReportPatch,
  TReportsAccounts,
  TReportSendResponse,
  TReportsParams,
  TReportsRefsResponse,
  TReportsResponse,
  TReportsSettings,
  TReportsStateResponse,
  TRequestPolReportParams,
  TRequestReportResponse,
  TRequestRusReportParams,
  TSubscription,
} from './reports.types';

class ReportsService extends ApiRequest {
  async getReportsSettings(): Promise<TReportsSettings | null> {
    try {
      const { data } = await this.fetch<TReportsSettings>({
        url: '/clientsarea/account/reports/api/settings/',
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('ReportsService error: ', error);
      return null;
    }
  }

  async saveReportsSettings(
    value: TReportsSettings,
  ): Promise<TReportsSettings | null> {
    try {
      const { data } = await this.fetch<TReportsSettings>({
        url: '/clientsarea/account/reports/api/settings/',
        method: 'PATCH',
        data: value,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('saveReportsSettings error: ', error);
      return null;
    }
  }

  async getReports({
    lang,
    page,
    pageSize,
    type,
    id,
  }: TGetReportsParams): Promise<TReportsResponse | null> {
    try {
      const { data } = await this.fetch<TReportsResponse>(
        {
          url: `/clientsarea/account/reports/api/custom/`,
          params: {
            lang,
            page,
            page_size: pageSize,
            type,
            id,
          },
        },
        {
          paramsSerializer: getParamsSerializer({ arrayFormat: 'brackets' }),
        },
      );

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getReports error: ', error);
      return null;
    }
  }

  async requestRusReport({
    lang,
    year,
  }: TRequestRusReportParams): Promise<TRequestReportResponse | null> {
    try {
      const { data } = await this.fetch<TRequestReportResponse>({
        url: `/${lang}/clientsarea/rest/tndfl/`,
        params: {
          year,
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('requestRusReport error: ', error);

      return null;
    }
  }

  async removeRusReport({
    lang,
    id,
    year,
  }: TRemoveRusReportParams): Promise<boolean> {
    try {
      await this.fetch({
        url: `/${lang}/clientsarea/rest/tndfl/remove/`,
        params: {
          id,
          year,
        },
      });

      return true;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('removeRusReport error: ', error);

      return false;
    }
  }

  async acceptRusTerms(lang: string): Promise<TAcceptRusTermsResponse | null> {
    try {
      const { data } = await this.fetch<TAcceptRusTermsResponse>({
        url: `/${lang}/clientsarea/rest/tndfl/accept_terms/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('acceptRusTerms error: ', error);

      return null;
    }
  }

  async acceptRusPrice({
    lang,
    reportId,
    account,
  }: TAcceptRusPriceParams): Promise<TAcceptRusPriceResponse | null> {
    try {
      const { data } = await this.fetch<TAcceptRusPriceResponse>({
        url: `/${lang}/clientsarea/rest/tndfl/accept_price/`,
        params: {
          report_id: reportId,
          account,
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('acceptRusPrice error: ', error);

      return null;
    }
  }

  async requestPolReport({
    year,
    account,
  }: TRequestPolReportParams): Promise<TRequestReportResponse | null> {
    try {
      const { data } = await this.fetch<TRequestReportResponse>({
        url: `/clientsarea/rest/pol-tax/`,
        params: {
          year,
          account,
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('requestPolReport error: ', error);

      return null;
    }
  }

  async acceptPolTerms(lang: string): Promise<TAcceptPolTermsResponse | null> {
    try {
      const { data } = await this.fetch<TAcceptPolTermsResponse>({
        url: `/${lang}/clientsarea/rest/pol-tax/accept_terms/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('acceptPolTerms error: ', error);

      return null;
    }
  }

  async checkPolFunds({
    lang,
    account,
    price,
  }: TCheckPolFundsParams): Promise<TCheckPolFundsResponse | null> {
    try {
      const { data } = await this.fetch<TCheckPolFundsResponse>({
        url: `/${lang}/clientsarea/rest/pol-tax/check_funds/`,
        params: {
          account,
          price,
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);

      console.error('checkPolFunds error: ', error);

      return null;
    }
  }

  async acceptPolTimings(
    lang: string,
  ): Promise<TAcceptPolTimingsResponse | null> {
    try {
      const { data } = await this.fetch<TAcceptPolTimingsResponse>({
        url: `/${lang}/clientsarea/rest/pol-tax/accept_timings/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('acceptPolTimings error: ', error);

      return null;
    }
  }

  async getReportsState(lang: string): Promise<TReportsStateResponse | null> {
    try {
      const { data } = await this.fetch<TReportsStateResponse>({
        url: `/${lang}/clientsarea/account/reports/api/state/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getReportsState error: ', error);

      return null;
    }
  }

  async getReportsRefs(lang: string): Promise<TReportsRefsResponse | null> {
    try {
      const { data } = await this.fetch<TReportsRefsResponse>({
        url: `/${lang}/clientsarea/account/reports/api/refs/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getReportsRefs error: ', error);
      return null;
    }
  }

  async getReport(lang: string, id: number): Promise<TReport | null> {
    try {
      const { data } = await this.fetch<TReport>({
        url: `/${lang}/clientsarea/account/reports/api/custom_reports/${id}/data/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getReport error: ', error);
      return null;
    }
  }

  async createOrUpdateReport(
    lang: string,
    report: TReportPatch,
    id?: number,
  ): Promise<TReport | null> {
    try {
      const { data } = await this.fetch<TReport>({
        url: `/${lang}/clientsarea/account/reports/api/custom/${
          id != null ? `${id}/` : ''
        }`,
        method: id != null ? 'PATCH' : 'POST',
        data: report,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('createOrUpdateReport error: ', error);
      return null;
    }
  }

  async sendReport(sendUrl: string): Promise<TReportSendResponse | null> {
    try {
      const { data } = await this.fetch<TReportSendResponse>({
        url: sendUrl,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('sendReport error: ', error);
      return null;
    }
  }

  async deleteReport(deleteUrl: string): Promise<true | null> {
    try {
      await this.fetch<null>({
        method: 'DELETE',
        url: deleteUrl,
      });

      return true;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('deleteReport error: ', error);
      return null;
    }
  }

  async getPeriodicReportsAccounts(
    lang = LANG_PARAM_DEFAULT,
    page = 1,
  ): Promise<TReportsAccounts | null> {
    try {
      const { data } = await this.fetch<TReportsAccounts>({
        url: `/${lang}/clientsarea/rest/account/list/`,
        params: {
          all: true,
          grouping: true,
          aggregation: true,
          page,
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getPeriodicReportsAccounts error: ', error);
      return null;
    }
  }

  async generatePeriodicReport(
    lang: string,
    reportType: string,
    reportData: TGetReportData,
  ): Promise<TGetReportResponse | null> {
    try {
      const { data } = await this.fetch<TGetReportResponse>({
        url: `/${lang}/clientsarea/account/reports/api/send/`,
        method: 'POST',
        params: {
          name: reportType,
        },
        data: {
          ...reportData,
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('generatePeriodicReport error: ', error);
      return null;
    }
  }

  async fetchSubscriptions(
    method: Method = 'GET',
    updatedSubscriptions?: TSubscription[],
  ): Promise<TSubscription[] | null> {
    try {
      const { data } = await this.fetch<TSubscription[]>({
        url: `/clientsarea/account/reports/api/subscriptions/`,
        method,
        ...(method === 'POST' ? { data: updatedSubscriptions } : {}),
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getSubscriptions error: ', error);
      return null;
    }
  }

  async getGeneratedReports({
    lang = 'en',
    options,
    ...filters
  }: TReportsParams): Promise<TReportsResponse | null> {
    try {
      const { data } = await this.fetch<TReportsResponse>({
        url: `/clientsarea/account/reports/api/custom/`,
        params: {
          lang,
          type: 'all',
          ...filters,
        },
        signal: options.signal,
      });

      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return { count: 0, results: [], canceled: true };
      }
      notifyWith.genericNetworkError(error);
      console.error('getGeneratedReports error: ', error);
      return null;
    }
  }
}

export const reportsService = new ReportsService();
