import { TPartnerSendChangeRequestProps } from './partner.types';

export const preparePartnerFormDataForChangeRequest = (
  formData: TPartnerSendChangeRequestProps['formData'],
) => {
  return Object.fromEntries(
    Object.entries(formData).map(([key, value]) => {
      if (key === 'date_of_birth' || key === 'country_code') {
        if (!value) {
          return [key, null];
        }
      }

      if (key === 'tax_residence_is_the_same') {
        return [key, Boolean(value)];
      }

      if (value === null) {
        return [key, ''];
      }

      return [key, value];
    }),
  );
};
