import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledTransfersContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  border: 1px solid ${({ theme }) => theme.color.input.border};
  border-radius: 4px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
    border: 0;
    border-radius: 0;
    border-top: 1px solid ${({ theme }) => theme.color.line.primary};
    border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;

export const StyledTransfersContainerWrapper = styled('div')`
  display: flex;
  padding: 0 24px;
`;

export const StyledTransfersBody = styled('div')`
  border-right: 1px solid ${({ theme }) => theme.color.line.primary};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    border-right: unset;
  }
`;

export const StyledHint = styled('div', {
  shouldForwardProp: blockNonNativeProps('withMarginTop'),
})<{ withMarginTop?: boolean }>`
  display: grid;
  grid-template-columns: 24px 1fr;
  margin-top: ${({ withMarginTop }) => (withMarginTop ? '12px' : '0')};
`;
