import { useEffect } from 'react';

import { useUserDetails } from 'hooks/useUserDetails';
import { TEnableTotpResponse } from 'services/mfa';

import { EMAIL_CODE_LENGTH, SMS_CODE_LENGTH } from '../../../../Mfa.constants';
import { useCodeVerification, useTotpVerification } from '../../../../hooks';

export const useConfirmTotp = (
  onSuccess: (payload: TEnableTotpResponse) => void,
  isSmsCode = false,
) => {
  const { isVerifying, verifyErrorMessage, handleResetError, handleVerify } =
    useTotpVerification(onSuccess);

  const codeLength = isSmsCode ? SMS_CODE_LENGTH : EMAIL_CODE_LENGTH;

  const { code, handleCodeChange, seconds, handleResend, handleResetTimer } =
    useCodeVerification(codeLength, 'totp', {
      disableVerification: true,
      onChange: handleResetError,
    });

  const { email, countryCode, phoneNumber } = useUserDetails();

  const handleCodeResend = () => {
    handleResetTimer();
    handleResend();
  };

  useEffect(() => {
    handleCodeResend();
  }, []);

  useEffect(() => {
    if (!isVerifying && code.length === codeLength) {
      handleVerify(code);
    }
  }, [code]);

  return {
    code,
    email,
    phoneNumber,
    countryCode,
    handleCodeChange,
    handleResetTimer,
    handleCodeResend,
    isVerifying,
    seconds,
    verifyErrorMessage,
  };
};
