import { useMediaQuery } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  ISelectOption,
  Notification as UIKitNotification,
  Skeleton,
  useData,
} from 'react-ui-kit-exante';

import { AutocompleteInfiniteListbox } from 'components/AutocompleteInfiniteListbox';
import { StyledPageWrapper } from 'components/Main';
import { MAX_AUTOCOMPLETE_LENGTH } from 'constants/common';
import { BrandingContext, TranslationContext } from 'contexts';
import {
  accountSettingsService,
  TAccountManagementData,
} from 'services/settings/accountSettings';
import { TLimits } from 'services/settings/accountSettings/accountSettings.types';
import { selectSettings } from 'store/application';
import { useAppSelector } from 'store/hooks';
import { useTheme } from 'theme';

import {
  StyledAccountSettingsAutocomplete,
  StyledAccountSettingsContainer,
  StyledAccountSettingsHeader,
  StyledAccountSettingsPanel,
  StyledAccountSettingsUsedLabel,
  StyledBackgroundContainer,
  StyledMultipleAccountSettingsContainer,
  StyledWrapper,
} from './AccountSettings.styled';
import {
  AccountsSearch,
  AccountsTable,
  AddAccountButton,
  DemoAccount,
  MobileAccountsTable,
} from './components';
import { AccountsTableContextProvider } from './contexts';

export const AccountSettings = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const { accountSettings } = useAppSelector(selectSettings);

  const [selectedAccount, setSelectedAccount] = useState<TLimits | null>(null);
  const [isNewAccountCreating, setIsNewAccountCreating] = useState(false);
  const [restoreSelectedAccount, setRestoreSelectedAccount] = useState<
    string | null
  >(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    fetchData: fetchAccountManagementData,
    data: accountManagementData,
    isLoading: isAccountsDataLoading,
  } = useData({
    onFetch: () => accountSettingsService.getAccountManagement(currentLanguage),
    onSuccess: (response: TAccountManagementData | null) => {
      if (response) {
        const prevSelected = response.limits?.find(
          (limit) => limit.clientId === restoreSelectedAccount,
        );
        setSelectedAccount(prevSelected || response.limits[0]);
      }
    },
  });

  const availableAccounts = accountManagementData?.limits || [];
  const hasAccounts = availableAccounts.length > 0;

  const isLoading = isAccountsDataLoading || isNewAccountCreating;
  const accountSettingsVisible = isAccountsDataLoading || hasAccounts;

  const accountsSelectorList = useMemo<ISelectOption[]>(() => {
    return availableAccounts
      .map<ISelectOption>((origin) => ({
        value: origin.clientId,
        label: origin.clientId,
      }))
      .sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
  }, [availableAccounts]);

  const accountsSelectorOnChange = (_: any, option: ISelectOption) => {
    const clientId = option?.value;
    const account = availableAccounts.find((acc) => acc.clientId === clientId);

    if (!account) {
      return;
    }
    setRestoreSelectedAccount(account.clientId);
    setSelectedAccount(account);
  };

  const createNewAccountUrl: string | null =
    accountManagementData?.urls.createNew || null;

  const isAddingAvailable = useMemo<boolean>(() => {
    if (!selectedAccount) {
      return false;
    }

    const limitExceeded = selectedAccount.count >= selectedAccount.limit;
    const addingRestricted =
      !accountManagementData || !accountManagementData.allowAddAccount;
    const isByWithdrawalPermission = selectedAccount.by_withdrawal_permission;
    const isAccountManagementAvailable =
      accountSettings.is_account_management_available;

    return Boolean(
      !limitExceeded &&
        !addingRestricted &&
        !isByWithdrawalPermission &&
        isAccountManagementAvailable,
    );
  }, [selectedAccount, accountManagementData, accountSettings]);

  const accountsUsedLabel = (() => {
    if (isLoading) {
      return <Skeleton width={80} height={16} />;
    }

    if (!selectedAccount?.count || !selectedAccount?.limit) {
      return null;
    }

    return (
      <StyledAccountSettingsUsedLabel className="AccountSettingsUsedLabel">
        <Trans
          i18nKey="You already use <span>[COUNT]</span> accounts of the available <span>[LIMIT]</span>"
          values={{
            COUNT: selectedAccount.count,
            LIMIT: selectedAccount.limit,
          }}
          components={{ span: <span /> }}
        />
      </StyledAccountSettingsUsedLabel>
    );
  })();

  const addAccount = async () => {
    if (!createNewAccountUrl || !selectedAccount) {
      return;
    }

    try {
      setIsNewAccountCreating(true);
      const response = await accountSettingsService.createNewAccount(
        createNewAccountUrl,
        selectedAccount.clientId,
      );

      if (response) {
        UIKitNotification.success({
          title: t('account_settings_account_created'),
        });
        await fetchAccountManagementData();
      }
    } finally {
      setIsNewAccountCreating(false);
    }
  };

  const isAddAccountVisible = Boolean(
    branding?.show_accounts && accountsSelectorList.length,
  );

  const isAddAccountDisabled = Boolean(
    isLoading || isNewAccountCreating || !isAddingAvailable || !selectedAccount,
  );

  useEffect(() => {
    fetchAccountManagementData();
  }, []);

  return (
    <StyledPageWrapper className="PageWrapper">
      <StyledWrapper className="Wrapper">
        <StyledBackgroundContainer className="BackgroundContainer">
          <StyledAccountSettingsHeader className="AccountSettingsHeader">
            <StyledAccountSettingsPanel
              className="AccountSettingsPanel"
              title={t('account_settings')}
            />
            {isAddAccountVisible && (
              <AddAccountButton
                disabled={isAddAccountDisabled}
                onAdd={addAccount}
              />
            )}
          </StyledAccountSettingsHeader>
          {(hasAccounts || accountsUsedLabel) && (
            <StyledAccountSettingsContainer className="AccountSettingsContainer">
              {availableAccounts.length > 1 && (
                <StyledMultipleAccountSettingsContainer className="MultipleAccountSettingsContainer">
                  {accountsSelectorList.length > 1 && (
                    <StyledAccountSettingsAutocomplete
                      className="AccountSettingsSelect"
                      options={accountsSelectorList}
                      value={selectedAccount?.clientId}
                      placeholder={t('client_id')}
                      onChange={accountsSelectorOnChange}
                      showSkeleton={isLoading}
                      disableClearable
                      // todo use autocompleteAsync component, when scroll top bug will be fixed https://jira.exante.eu/browse/RUI-414
                      /* eslint-disable-next-line react/no-unstable-nested-components */
                      ListboxComponent={(props) => (
                        <AutocompleteInfiniteListbox
                          {...props}
                          limit={MAX_AUTOCOMPLETE_LENGTH}
                        />
                      )}
                    />
                  )}
                </StyledMultipleAccountSettingsContainer>
              )}
              {accountsUsedLabel}
            </StyledAccountSettingsContainer>
          )}
          <AccountsTableContextProvider client={selectedAccount}>
            {accountSettingsVisible && (
              <>
                <AccountsSearch clientId={selectedAccount?.clientId} />
                {isMobile ? <MobileAccountsTable /> : <AccountsTable />}
              </>
            )}
          </AccountsTableContextProvider>
          {accountSettings.is_demo_enabled && <DemoAccount />}
        </StyledBackgroundContainer>
      </StyledWrapper>
    </StyledPageWrapper>
  );
};
