import { useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_DEBOUNCE_DELAY } from 'constants/common';
import { TGroup } from 'services/settings/notifications';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  saveSettingsNotifications,
  selectSettingsNotifications,
  settingsNotificationsInitialState,
} from 'store/settingsNotifications';
import { EStatus } from 'types/api';

import { TNotificationPayload } from './Notifications.types';

export const useNotifications = () => {
  const dispatch = useAppDispatch();
  const { status, data } = useAppSelector(selectSettingsNotifications);

  const isLoading = status === EStatus.Loading;

  const groups = useMemo<TGroup[]>(() => {
    if (!data?.groups) {
      return settingsNotificationsInitialState.data.groups;
    }

    return data.groups;
  }, [data.groups]);

  const handleChange = useDebouncedCallback(
    async ({ channel, name, value }: TNotificationPayload) => {
      dispatch(
        saveSettingsNotifications([
          {
            name,
            values: { [channel]: value },
          },
        ]),
      );
    },
    INPUT_DEBOUNCE_DELAY,
  );

  return { groups, isLoading, handleChange, primaryEmail: data?.primary_email };
};
