import { FC, useContext } from 'react';

import { CurrencyContext, TranslationContext } from 'contexts';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { ChallengeAccountSummaryContext } from 'pages/Challenge/ChallengeAccountSummary/contexts';

import { ChallengeAccountSummaryInfoItem } from '../ChallengeAccountSummaryInfoItem/ChallengeAccountSummaryInfoItem';
import { ChallengeAccountSummarySkeleton } from '../ChallengeAccountSummaryInfoSkeleton/ChallengeAccountSummaryInfoSkeleton';

import { getChallengeAccountSummaryItems } from './ChallengeAccountSummaryInfo.helpers';
import { StyledChallengeAccountSummaryInfo } from './ChallengeAccountSummaryInfo.styled';

export const ChallengeAccountSummaryInfo: FC = () => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const {
    loading: isChallengeAccountSummaryDataLoading,
    currentBalance,
    profitTarget,
    dailyDd,
    maxDd,
    currentEquity,
    dayTraded,
    remainingDays,
  } = useContext(ChallengeAccountSummaryContext);
  const { currency } = useContext(CurrencyContext);
  const currencyFormatter = useCurrencyFormatter(currency);

  const challengeAccountSummaryItems = getChallengeAccountSummaryItems({
    currentBalance,
    profitTarget,
    dailyDd,
    maxDd,
    currentEquity,
    dayTraded,
    remainingDays,
    currencyFormatter,
  });

  return (
    <StyledChallengeAccountSummaryInfo className="ChallengeAccountSummaryInfo">
      {isChallengeAccountSummaryDataLoading || isTranslationsLoading ? (
        <ChallengeAccountSummarySkeleton />
      ) : (
        <>
          {challengeAccountSummaryItems.map((item: any) => (
            <ChallengeAccountSummaryInfoItem
              key={item.label}
              label={t(item.label)}
              value={item.value}
              tooltip={t(item.tooltip)}
            />
          ))}
        </>
      )}
    </StyledChallengeAccountSummaryInfo>
  );
};
