import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledSigninOauth = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  font-family: ${({ theme }) => theme.font.main};
`;

export const StyledSigninOauthHint = styled('div')`
  width: 100%;
  display: flex;
`;

export const StyledSigninOauthHintText = styled('span')`
  width: 100%;
  text-align: center;
  white-space: nowrap;

  margin: 0 12px;

  color: ${({ theme }) => theme.color.typo.ghost};
  font-size: 12px;
  font-weight: 400;
`;

export const StyledSigninOauthHintJustifier = styled('div')`
  width: 100%;
  height: 50%;
  border-bottom: solid 1px ${({ theme }) => theme.color.line.primary};
`;

export const StyledSigninOauthWarning = styled('p')`
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.color.typo.warning};
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export const StyledSigninOauthFlows = styled('div')`
  display: flex;
  gap: 24px;
`;

export const StyledSigninOauthFlowButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.color.input.border};
  box-sizing: content-box;
  padding: 12px;

  color: ${({ theme }) => theme.color.typo.primary};
  font-weight: 500;
  font-size: 15px;
`;
