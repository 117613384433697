export const enum MfaTooltipState {
  Email = 'email',
  Sms = 'sms',
  Totp = 'totp',
}

export const EMAIL_CODE_LENGTH = 8;
export const SMS_CODE_LENGTH = 4;
export const TOTP_CODE_LENGTH = 6;

export const MFA_TYPES_LENGTH = {
  email: EMAIL_CODE_LENGTH,
  sms: SMS_CODE_LENGTH,
  totp: TOTP_CODE_LENGTH,
} as const;
