import { useContext, useEffect } from 'react';
import { Skeleton, useData } from 'react-ui-kit-exante';

import { ManagerIcon, PhoneIcon } from 'assets/icons';
import { EXANTE_SUPPORT_EMAIL } from 'constants/WHITELABEL';
import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { BrandingContext, TranslationContext } from 'contexts';
import { SettingsDataContext } from 'pages/Settings/contexts/SettingsDataContext';
import { settingsProfileService, TManager } from 'services/settings';

import {
  StyledContactTitle,
  StyledGetPersonalManager,
  StyledGetPersonalManagerContainer,
  StyledPersonalManagerContactCard,
  StyledPersonalManagerContacts,
  StyledPersonalManagerContainer,
  StyledPersonalManagerSkeletonContainer,
  StyledPersonalManagerSkeletonRow,
  StyledPersonalManagerTitle,
} from './PersonalManager.styled';

export const PersonalManager = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const { settingsData, isLoading: isSettingsDataLoading } =
    useContext(SettingsDataContext);

  const managerIsAvailable = Boolean(
    settingsData?.state.is_personal_manager_available,
  );

  const getPersonalManager = async () => {
    const response =
      await settingsProfileService.getPersonalManager(currentLanguage);
    return response?.['personal-manager']?.manager || null;
  };

  const {
    fetchData,
    isLoading: isManagerDataLoading,
    data,
  } = useData<TManager | null>({
    onFetch: getPersonalManager,
    loading: false,
  });

  useEffect(() => {
    if (managerIsAvailable) {
      fetchData();
    }
  }, [managerIsAvailable]);

  const getManagerUnavailableText = () => {
    return t('layout__settings__get_personal_manager', {
      WL_SUPPORT_EMAIL: branding?.wl_emails_support || EXANTE_SUPPORT_EMAIL,
    });
  };

  const isLoading = isManagerDataLoading || isSettingsDataLoading;

  if (!managerIsAvailable && !isLoading) {
    return null;
  }

  return (
    <StyledPersonalManagerContainer className="PersonalManagerContainer">
      <StyledPersonalManagerTitle className="PersonalManagerTitle">
        {t('layout__settings__personal_manager')}
      </StyledPersonalManagerTitle>
      {isLoading && (
        <StyledPersonalManagerSkeletonContainer className="PersonalManagerSkeletonContainer">
          <StyledPersonalManagerSkeletonRow className="PersonalManagerSkeletonRow">
            <Skeleton width={80} height={16} />
            <Skeleton width={40} height={8} />
          </StyledPersonalManagerSkeletonRow>
          <StyledPersonalManagerSkeletonRow className="PersonalManagerSkeletonRow">
            <Skeleton width={80} height={16} />
            <Skeleton width={40} height={8} />
          </StyledPersonalManagerSkeletonRow>
        </StyledPersonalManagerSkeletonContainer>
      )}
      {!isLoading && !data && (
        <StyledGetPersonalManagerContainer className="GetPersonalManagerContainer">
          <ManagerIcon />
          <StyledPersonalManagerContacts className="PersonalManagerContacts">
            <StyledGetPersonalManager
              className="GetPersonalManager"
              dangerouslySetInnerHTML={{
                __html: getManagerUnavailableText(),
              }}
            />
          </StyledPersonalManagerContacts>
        </StyledGetPersonalManagerContainer>
      )}
      {!isLoading && data && (
        <>
          <StyledPersonalManagerContactCard className="PersonalManagerContactCard">
            <ManagerIcon />
            <StyledPersonalManagerContacts className="PersonalManagerContacts">
              <StyledContactTitle className="ContactTitle">
                {data?.name}
              </StyledContactTitle>
              <a
                href={`mailto: ${data?.email}`}
                target="_blank"
                rel="noreferrer"
              >
                {data?.email || DEFAULT_EMPTY_PLACEHOLDER}
              </a>
            </StyledPersonalManagerContacts>
          </StyledPersonalManagerContactCard>
          <StyledPersonalManagerContactCard className="PersonalManagerContactCard">
            <PhoneIcon />
            <StyledPersonalManagerContacts className="PersonalManagerContacts">
              <StyledContactTitle className="ContactTitle">
                <a
                  href={`tel: ${data?.phone}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data?.phone ? `+${data?.phone}` : DEFAULT_EMPTY_PLACEHOLDER}
                </a>
              </StyledContactTitle>
              {/* <StyledContactExtension>Extension: 123</StyledContactExtension> */}
            </StyledPersonalManagerContacts>
          </StyledPersonalManagerContactCard>
        </>
      )}
    </StyledPersonalManagerContainer>
  );
};
