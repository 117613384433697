import { createContext } from 'react';

import { TPartnerLinksContextValue } from './PartnerLinksContext.types';

export const PartnerLinksContext = createContext<TPartnerLinksContextValue>({
  isLoading: false,
  onDelete() {},
  onEdit() {},
  links: [],
});
