import { Skeleton, ToggleButtonGroup } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledDepositWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledDepositHead = styled('div')`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-direction: row;
  }
`;

export const StyledDepositMessage = styled('div')`
  width: 100%;
  padding: 24px;
`;

export const StyledDepositSection = styled('div')`
  width: 100%;

  padding: 24px;

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    border-top: 1px solid ${({ theme }) => theme.color.line.primary};

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
    border-right: 1px solid ${({ theme }) => theme.color.line.primary};

    &:last-child {
      border-right: none;
    }
  }
`;

export const StyledDepositTypeSwitch = styled('div')`
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  &.MuiToggleButtonGroup-root {
    flex-wrap: nowrap;
  }

  & .MuiButtonBase-root {
    text-transform: none;
  }
`;

export const StyledDepositSectionHead = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledDepositSectionBody = styled('div')`
  margin: 24px 0 0;
`;

export const StyledDepositSectionText = styled('p')`
  margin: 24px 0;

  color: ${({ theme }) => theme.color.typo.promo};

  font-family: ${({ theme }) => theme.font.main};
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  a {
    color: ${({ theme }) => theme.color.typo.action};

    &:hover {
      color: ${({ theme }) => theme.color.typo.hover};
    }
  }
`;

export const StyledDepositSectionHeading = styled('h3')`
  margin: 0;

  color: ${({ theme }) => theme.color.typo.primary};

  font-family: ${({ theme }) => theme.font.header};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
`;

export const StyledDepositTableHeading = styled('h3')`
  margin: 24px;

  color: ${({ theme }) => theme.color.typo.primary};

  font-family: ${({ theme }) => theme.font.header};
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

export const StyledDepositSectionActions = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledDepositSectionHeadingSkeleton = styled(Skeleton)`
  width: 80px;
  height: 24px;
`;
