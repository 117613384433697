import { useContext } from 'react';
import { IconButton, Panel } from 'react-ui-kit-exante';

import { BASE_URL } from 'constants/endpoints';
import { TranslationContext } from 'contexts';
import { InterestsTable } from 'pages/Terms/tabs/Interests/components/InterestsTable';
import { useAppSelector } from 'store/hooks';

import { StyledTermsInterestHeader } from './Interests.styled';

export const Interests = () => {
  const [selectedAccount, isAccountsLoading] = useAppSelector((state) => [
    state.accounts.selectedAccount,
    state.accounts.isAccountsLoading,
  ]);
  const { isTranslationsLoading } = useContext(TranslationContext);

  const downloadReport = () => {
    if (!selectedAccount?.value) {
      return;
    }
    const url = new URL('/clientsarea/account/interests/list/xls/', BASE_URL);
    const params = new URLSearchParams({
      id: String(selectedAccount?.value),
    });

    window.open(`${url}?${params.toString()}`);
  };

  const isLoading = isTranslationsLoading || isAccountsLoading;

  return (
    <Panel className="TermsShortAllowanceStatusWrapper" disableContentPaddings>
      <StyledTermsInterestHeader className="filter__actions">
        <IconButton
          iconName="XLSIcon"
          iconColor="secondary"
          iconSize={24}
          className="TermsInterestDownload"
          onClick={downloadReport}
          disabled={!selectedAccount}
          data-test-id="terms-interests__report_download"
        />
      </StyledTermsInterestHeader>
      <InterestsTable
        accountId={selectedAccount?.value}
        isLoading={isLoading}
      />
    </Panel>
  );
};
