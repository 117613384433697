import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, useData } from 'react-ui-kit-exante';

import { FormInputContainer } from 'components/Form/FormInputContainer/FormInputContainer';
import { BrandingContext } from 'contexts';
import { TranslationContext } from 'contexts/TranslationContext';
import { calcPasswordSecurity, securityLevelTitles } from 'helpers/password';
import { PATHS } from 'router/router.constants';
import { loginService } from 'services/login';

import { StyledSecurityLevel } from '../../../Security/pages/Password/Password.styled';
import {
  StyledLoginHeading,
  StyledLoginHint,
  StyledLoginLogo,
} from '../../Login.styled';
import { StyledSigninWrapper } from '../Signin/Signin.styled';

import { RECOVERY_COMPLETE_FORM_VALIDATION_SCHEMA } from './RecoveryComplete.consts';
import {
  StyledPasswordRepeatWrapper,
  StyledRecoveryCompleteForm,
  StyledSecurity,
} from './RecoveryComplete.styled';
import { TPasswordResetForm } from './RecoveryComplete.types';
import { RecoveryCompleteSkeleton } from './components/RecoveryCompleteSkeleton';

export const RecoveryComplete: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const params = useParams();
  const { branding } = useContext(BrandingContext);

  const [isResetLoading, setIsResetLoading] = useState(false);
  const [requestInvalid, setRequestInvalid] = useState('');

  const { t, isTranslationsLoading } = useContext(TranslationContext);

  // keep initial uid/token values
  const uid = useMemo(() => params?.uid || '', []);
  const token = useMemo(() => params?.token || '', []);

  const loginButtonCaption = t(
    branding?.login_wording || 'generic__action__login',
  );

  const methods = useForm<TPasswordResetForm>({
    resolver: yupResolver(RECOVERY_COMPLETE_FORM_VALIDATION_SCHEMA(t)),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    isLoading: isTokenChecking,
    data: checkError,
    fetchData: checkPasswordReset,
  } = useData<string | null>({
    loading: true,
    onFetch: async () => {
      const response = await loginService.checkPasswordResetToken({
        uid,
        token,
      });

      if (!response) {
        return t('generic__unkown_error_occured');
      }

      if (response?.result === 'invalid') {
        return t(response.message);
      }

      return null;
    },
  });

  useEffect(() => {
    checkPasswordReset();
  }, []);

  const {
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setError,
    setValue,
    trigger,
  } = methods;

  const hasFormErrors = Object.keys(errors).length > 0;

  const getPasswordSecurityLabel = useCallback(() => {
    const password = getValues('password');
    if (password) {
      return calcPasswordSecurity(password);
    }
    return '';
  }, [watch('password')]);

  const isPageLoading = isTokenChecking || isTranslationsLoading;

  if (isPageLoading) {
    return <RecoveryCompleteSkeleton />;
  }

  if (requestInvalid) {
    return (
      <StyledLoginHint className="LoginHint">
        {t('layout__login__password_link_invalid')}
      </StyledLoginHint>
    );
  }

  if (!isTokenChecking && checkError) {
    navigate(PATHS.LOGIN);

    return <div>{checkError}</div>;
  }

  const handleLogin = () => {
    navigate(PATHS.LOGIN);
  };

  const handleSave = async () => {
    setIsResetLoading(true);

    const values = getValues();

    const response = await loginService.resetPassword({
      uid,
      token,
      password: values.password,
    });

    setIsResetLoading(false);

    if (!response) {
      console.error('reset password: no response');
      return;
    }

    if (response.result === 'error') {
      setError('password', { message: t(response.message) });
    }

    if (response.result === 'invalid') {
      setRequestInvalid(response.message);
    }

    if (response.result === 'success') {
      navigate(PATHS.LOGIN);
    }
  };

  const passwordSecurityLabel = getPasswordSecurityLabel();
  const passwordSecurityText = passwordSecurityLabel
    ? securityLevelTitles(t)[passwordSecurityLabel]
    : '';

  return (
    <StyledSigninWrapper className="SigninWrapper">
      {isDesktop && <StyledLoginLogo className="LoginLogo" />}

      <StyledLoginHeading className="LoginHeading">
        {t('layout__auth__new_password')}
      </StyledLoginHeading>

      <FormProvider {...methods}>
        <StyledRecoveryCompleteForm
          className="RecoveryCompleteForm"
          onSubmit={handleSubmit(handleSave)}
        >
          <FormInputContainer
            type="password"
            name="password"
            autoComplete="new-password"
            label={t('generic__password')}
            fullWidth
            disabled={isResetLoading}
            // redefine onChange to re-trigger the passwordRepeat check
            onChange={(e) => {
              setValue('password', e.target.value);
              trigger();
            }}
          />

          <StyledPasswordRepeatWrapper className="PasswordRepeatWrapper">
            <FormInputContainer
              type="password"
              name="passwordRepeat"
              autoComplete="new-password"
              label={t('layout__auth__repeat_password')}
              fullWidth
              disabled={isResetLoading}
              // when custom re-trigger is used, both fields should have the same logic
              onChange={(e) => {
                setValue('passwordRepeat', e.target.value);
                trigger();
              }}
            />

            {passwordSecurityLabel && (
              <StyledSecurity className="Security">
                {t('layout_auth_security')}:
                <StyledSecurityLevel
                  level={passwordSecurityLabel}
                  className="SecurityLevel"
                >
                  {passwordSecurityText}
                </StyledSecurityLevel>
              </StyledSecurity>
            )}
          </StyledPasswordRepeatWrapper>

          <Button
            color="primary"
            type="submit"
            disabled={isResetLoading || hasFormErrors}
          >
            {t('generic__action__save')}
          </Button>

          <Button
            color="secondary"
            onClick={handleLogin}
            type="reset"
            disabled={isResetLoading}
          >
            {loginButtonCaption}
          </Button>
        </StyledRecoveryCompleteForm>
      </FormProvider>
    </StyledSigninWrapper>
  );
};
