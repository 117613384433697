import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledChallengePayoutWrapper = styled(Panel)`
  & > .PanelTitle {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    .PanelHeading {
      font-weight: 700;

      ${({ theme }) => theme.breakpoints.up('md')} {
        font-size: 30px;
      }
    }
  }
`;

export const StyledChallengePayoutForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 40%;
  }
`;

export const StyledChallengePayoutFormActions = styled('div')`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-top: 16px;
`;
