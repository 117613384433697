import { styled } from 'theme';

export const StyledSigninMfa = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  font-family: ${({ theme }) => theme.font.main};
`;

export const StyledSigninMfaDescription = styled('p')`
  margin: 0;
  text-align: center;
  font-size: 13px;
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledSigninMfaSupportNotice = styled('p')`
  margin: 0;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledSigninMfaSupportLink = styled('a')`
  color: ${({ theme }) => theme.color.typo.action};
  text-decoration: none;
`;
