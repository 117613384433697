import { styled } from 'theme';

export const StyledAccountSelectionCarouselWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  min-width: 0;
  overflow-x: auto;
  margin-top: 8px;
  margin-left: 24px;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.color.bg.primary};
    border-radius: 8px;
    opacity: 0.5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.line.primary};
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.color.bg.promo};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-basis: 100%;
    overflow: visible;
  }
`;

export const StyledAccountSelectionCarouselList = styled('div')`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  gap: 8px;
  min-width: 0;
  max-width: 100%;
  margin: 0;
  padding: 10px 8px 4px 2px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.color.bg.primary};
    border-radius: 8px;
    opacity: 0.5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.line.primary};
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.color.bg.promo};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    overflow: visible;
  }
`;
