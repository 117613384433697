import { Button, IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledSaveButton = styled(IconButton)`
  font-size: 15px;
`;

export const Styled2FATooltipHeader = styled('div')`
  margin-bottom: 16px;
`;

export const StyledNoCodeText = styled('div')`
  margin-bottom: 16px;
`;

export const StyledResendCode = styled('div')`
  margin-bottom: 16px;
  font-size: 11px;
  line-height: 16px;
`;

export const StyledResendCodeButton = styled(Button)`
  font-size: 11px;
  line-height: 16px;
`;

export const StyledCodeButtons = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;
