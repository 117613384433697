import { FC } from 'react';
import { Switch } from 'react-ui-kit-exante';

import {
  StyledProductItemContainer,
  StyledProductTitle,
  StyledProductToggle,
  StyledProductInfo,
  StyledProductDescription,
  StyledCheckmarkIcon,
} from './ProductItem.styled';
import { TProductItemProps } from './ProductItem.types';

export const ProductItem: FC<TProductItemProps> = ({
  product,
  onToggleProduct,
  isUpdating,
}) => {
  const canSwitch =
    (product.can_enable || product.can_disable) && !product.done && !isUpdating;

  const onToggle = async () => {
    if (product.can_disable) {
      await onToggleProduct(product, false);
    }
    if (product.can_enable) {
      await onToggleProduct(product, true);
    }
  };

  const switchTestId = `account-settings__button--toggle-service-${product.title
    .replace(/ /g, '-')
    .toLowerCase()}`;

  return (
    <StyledProductItemContainer className="ProductItemContainer">
      <StyledProductToggle className="ProductToggle">
        {!product.done && (
          <Switch
            disabled={!canSwitch}
            checked={product.can_disable}
            onChange={onToggle}
            label=""
            data-test-id={switchTestId}
          />
        )}
        {product.done && <StyledCheckmarkIcon className="CheckmarkIcon" />}
      </StyledProductToggle>
      <StyledProductInfo className="ProductInfo">
        <StyledProductTitle className="ProductTitle">
          {product.title}
        </StyledProductTitle>
        <StyledProductDescription className="ProductDescription">
          {product.description}
        </StyledProductDescription>
      </StyledProductInfo>
    </StyledProductItemContainer>
  );
};
