import { styled } from 'theme';

export const StyledTabsSkeletonWrapper = styled('div')`
  display: flex;
  gap: 24px;
  margin: 24px 0 0 24px;
  min-height: 40px;

  border-bottom: 1px solid ${({ theme }) => theme.color.bg.basic};
`;

export const StyledTabsSkeleton = styled('div')`
  display: flex;
  gap: 11px;
`;
