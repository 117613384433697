import { ChangeEvent, useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Input } from 'react-ui-kit-exante';

import { TFormInputContainerProps } from './FormInputContainer.types';

export const FormInputContainer = ({
  customEvents = {},
  disabled = false,
  readOnly = false,
  fullWidth = true,
  label = '',
  name,
  type,
  maxLength,
  message,
  error,
  ...rest
}: TFormInputContainerProps) => {
  const formContext = useFormContext();
  const {
    field: { onChange, onBlur: onBlurReactHookForm, value },
    fieldState: { error: fieldError },
  } = useController({
    control: formContext.control,
    name,
  });

  const { onBlur: onBlurCustomEvents } = customEvents;

  const blurHandler = useCallback(() => {
    if (onBlurCustomEvents) {
      onBlurCustomEvents(formContext);
    }

    onBlurReactHookForm();
  }, [formContext, onBlurCustomEvents, onBlurReactHookForm]);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (maxLength && event.target.value.length >= maxLength) {
      return;
    }
    onChange(event);
  };

  const visibleMessage = useMemo(
    () => [message, fieldError?.message].filter((str) => str).join('; '),
    [fieldError?.message, message],
  );

  return (
    <Input
      disabled={disabled}
      error={error || !!fieldError}
      fullWidth={fullWidth}
      label={label}
      message={visibleMessage}
      name={name}
      onBlur={blurHandler}
      onChange={onChangeHandler}
      type={type}
      value={value === null ? '' : value}
      readOnly={readOnly}
      maxLength={maxLength}
      {...rest}
    />
  );
};
