import { styled } from 'theme';

export const StyledAccountName = styled('span')`
  display: flex;
  align-items: center;
  gap: 5px;

  a {
    text-decoration: none;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    a {
      color: ${({ theme }) => theme.color.typo.secondary} !important;
    }

    & svg {
      color: ${({ theme }) => theme.color.typo.secondary} !important;
    }
  }
`;

export const StyledSupervisorText = styled('span')`
  color: ${({ theme }) => theme.color.typo.ghost};
  font-size: 13px;
  vertical-align: middle;
`;
