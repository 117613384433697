import { blockNonNativeProps, ToggleButtonGroup } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledAgreementsNoticeProps } from './Agreements.types';

export const StyledAgreementsWrapper = styled('div')`
  // prevent margin collapse at the bottom
  display: flex;
  flex-direction: column;
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin: 24px;

  &.MuiToggleButtonGroup-root {
    flex-wrap: nowrap;

    .MuiToggleButtonGroup-grouped {
      // keep non-skeleton styles untouched when is not skeleton
      width: ${({ showSkeleton }) => (showSkeleton ? '100%' : '')};

      .MuiSkeleton-root {
        width: 100% !important;
      }
    }
  }

  & .MuiButtonBase-root {
    text-transform: none;
  }
`;

export const StyledAgreementsBlock = styled('div')`
  border-top: 1px solid ${({ theme }) => theme?.color?.line?.primary};
`;

export const StyledAgreementsBlockHeading = styled('h2')`
  margin: 24px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  font-family: ${({ theme }) => theme?.font.header};

  color: ${({ theme }) => theme?.color?.typo?.primary};
`;

export const StyledAgreementsNotice = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isLoading']),
})<TStyledAgreementsNoticeProps>`
  display: grid;
  grid-gap: 16px;

  margin: 24px;
  padding: 24px;

  border: 1px solid ${({ theme }) => theme.color.line.primary};
  align-items: center;

  grid-template-rows: ${({ isLoading }) => !isLoading && '1fr 1fr'};
  grid-template-columns: ${({ isLoading }) => isLoading && '1fr 1fr'};

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-rows: none;
    grid-template-columns: ${({ isLoading }) => !isLoading && '1fr 2fr'};
    grid-gap: 48px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    grid-template-rows: none;
    grid-template-columns: ${({ isLoading }) => !isLoading && '1fr 3fr'};
    grid-gap: 48px;
  }
`;

export const StyledAgreementsNoticeTitle = styled('p')`
  color: ${({ theme }) => theme?.color?.typo?.primary};

  margin: 0;

  font-family: ${({ theme }) => theme?.font.header};
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledAgreementsNoticeDescription = styled('p')`
  margin: 0;

  color: ${({ theme }) => theme?.color?.typo?.promo};

  font-family: ${({ theme }) => theme?.font.main};

  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
`;
