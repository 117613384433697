import { Select, Table } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledTable = styled(Table)`
  div[role='cell'] > div {
    width: 100%;
  }
`;

export const StyledSelect = styled(Select)`
  .OptionDescription {
    display: none;
  }
`;

export const StyledOptionDescription = styled('div')`
  color: ${({ theme }) => theme.color.typo.ghost};

  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
