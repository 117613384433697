import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';
import { selectSettings } from 'store/application';
import { useAppSelector } from 'store/hooks';

import { AccountsTableContext } from '../../contexts';
import {
  AccountSettingsTooltip,
  AutoConversionSelector,
  DescriptionInput,
  SelectAccountForAutoDebit,
} from '../AccountsTable/components';

import {
  StyledMarginTradingButton,
  StyledMarginTradingButtonDecorator,
  StyledMobileAccountSetting,
  StyledMobileAccountSettingHeader,
  StyledMobileAccountSettingRow,
} from './MobileAccountsTable.styled';
import { AccountsTableNoData, AccountsTableSkeleton } from './components';

export const MobileAccountsTable: FC = () => {
  const { t } = useContext(TranslationContext);
  const { accountsList, accountDescriptions, isLoading } =
    useContext(AccountsTableContext);
  const { accountSettings } = useAppSelector(selectSettings);

  const {
    is_account_descriptions_available: isAccountDescriptionsAvailable,
    is_cash_conversion_available: isCashConversionAvailable,
    is_margin_trading_available: isMarginTradingAvailable,
    is_autodebit_available: isAutodebitAvailable,
  } = accountSettings;

  if (isLoading || !accountSettings) {
    return <AccountsTableSkeleton />;
  }

  if (!accountsList.length) {
    return <AccountsTableNoData />;
  }

  return (
    <>
      {accountsList.map(
        ({
          id,
          is_description_editable,
          auto_conversion_currency,
          is_auto_conversion_editable,
          is_margin_trading_editable,
          is_autodebit_editable,
          is_autodebit_showing,
          autodebit_account,
          margin_trading,
        }) => {
          const accountDescription = accountDescriptions[id]?.description || '';

          const descriptionVisible = Boolean(
            isAccountDescriptionsAvailable && is_description_editable,
          );
          const autoconversionVisible = Boolean(
            isCashConversionAvailable && is_auto_conversion_editable,
          );
          const autodebitVisible = Boolean(
            isAutodebitAvailable && is_autodebit_showing,
          );
          const autodebitEditable = Boolean(
            isAutodebitAvailable && is_autodebit_editable,
          );
          const marginTradingVisible = Boolean(
            isMarginTradingAvailable && is_margin_trading_editable,
          );

          return (
            <StyledMobileAccountSetting
              className="MobileAccountsTable"
              key={id}
            >
              <StyledMobileAccountSettingRow className="MobileAccountSettingRow">
                <StyledMobileAccountSettingHeader className="MobileAccountSettingHeader">
                  {id}
                </StyledMobileAccountSettingHeader>
              </StyledMobileAccountSettingRow>
              {descriptionVisible && (
                <StyledMobileAccountSettingRow className="MobileAccountSettingRow">
                  <DescriptionInput
                    value={accountDescription}
                    disabled={!is_description_editable}
                    accountId={id}
                  />
                  <AccountSettingsTooltip
                    tooltipText={t('accounts_table_description_tooltip')}
                  />
                </StyledMobileAccountSettingRow>
              )}
              {autoconversionVisible && (
                <StyledMobileAccountSettingRow className="MobileAccountSettingRow">
                  <AutoConversionSelector
                    autoConversionCurrency={auto_conversion_currency}
                    disabled={!is_auto_conversion_editable}
                    accountId={id}
                    labelKey="auto_conversion_to"
                  />
                  <AccountSettingsTooltip
                    tooltipText={t('accounts_table_auto_conversion_tooltip')}
                  />
                </StyledMobileAccountSettingRow>
              )}
              {autodebitVisible && (
                <StyledMobileAccountSettingRow className="MobileAccountSettingRow">
                  <SelectAccountForAutoDebit
                    accountId={id}
                    autodebitAccountId={autodebit_account}
                    isAutodebitEditable={autodebitEditable}
                  />
                  <AccountSettingsTooltip
                    tooltipText={t('accounts_table_autodebit_tooltip')}
                  />
                </StyledMobileAccountSettingRow>
              )}
              {marginTradingVisible && (
                <StyledMobileAccountSettingRow className="MobileAccountSettingRow">
                  <StyledMarginTradingButtonDecorator
                    value="Margin trading"
                    fullWidth
                    disabled
                  />
                  <StyledMarginTradingButton
                    accountId={id}
                    marginEnabled={margin_trading}
                  />
                  <AccountSettingsTooltip
                    tooltipText={t('accounts_table_margin_trading_tooltip')}
                  />
                </StyledMobileAccountSettingRow>
              )}
            </StyledMobileAccountSetting>
          );
        },
      )}
    </>
  );
};
