import { FC } from 'react';

import {
  StyledPanelHeaderSkeletonSmall,
  StyledPanelHeaderSkeletonWrapper,
} from './PanelHeaderSkeleton.styled';

export const PanelHeaderSkeleton: FC = () => (
  <StyledPanelHeaderSkeletonWrapper>
    <StyledPanelHeaderSkeletonSmall variant="rounded" animation="wave" />
    <StyledPanelHeaderSkeletonSmall variant="rounded" animation="wave" />
  </StyledPanelHeaderSkeletonWrapper>
);
