import { FC, useCallback, useContext, useMemo } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { selectAccounts } from 'store/accounts';
import { accountsActions } from 'store/accounts/accounts.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  StyledPersonalIban,
  StyledPersonalIbanInfoIcon,
  StyledPersonalIbanTextBlock,
  StyledPersonalIbanTextBlockAccount,
} from './PersonalIban.styled';
import { TPersonalIbanProps } from './PersonalIban.types';

export const PersonalIban: FC<TPersonalIbanProps> = ({ currency, bank }) => {
  const { t } = useContext(TranslationContext);
  const dispatch = useAppDispatch();
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const personalIban = useMemo<string | null | undefined>(() => {
    if (bank && currency) {
      return bank.currency[currency]?.personalIbanNumber;
    }
    return null;
  }, [bank, currency]);

  const paymentAccount = useMemo<string | null | undefined>(() => {
    if (bank && currency) {
      return bank.currency[currency]?.personalIbanAccount;
    }
    return null;
  }, [bank, currency]);

  const selectPaymentAccount = useCallback(() => {
    if (paymentAccount) {
      dispatch(accountsActions.selectAccount({ accountId: paymentAccount }));
    }
  }, [paymentAccount]);

  const formattedText = useMemo<JSX.Element | string>(() => {
    if (!paymentAccount) {
      return '';
    }
    const personalIbanText = t('layout__requisites__you_have_personal_iban', {
      ACCOUNT_ID: paymentAccount,
    }).split(paymentAccount);
    return (
      <span>
        <span
          dangerouslySetInnerHTML={{
            __html: personalIbanText[0] || '',
          }}
        />
        <StyledPersonalIbanTextBlockAccount
          className="PersonalIbanTextAccount"
          onClick={selectPaymentAccount}
        >
          {paymentAccount}
        </StyledPersonalIbanTextBlockAccount>
        <span
          dangerouslySetInnerHTML={{
            __html: personalIbanText[1] || '',
          }}
        />
      </span>
    );
  }, [paymentAccount]);

  if (
    !(personalIban && selectedAccountId && selectedAccountId !== paymentAccount)
  ) {
    return null;
  }

  return (
    <StyledPersonalIban className="PersonalIban">
      <StyledPersonalIbanTextBlock className="PersonalIbanText">
        <Tooltip title={t('layout__requisites__personal_iban__tooltip')}>
          <StyledPersonalIbanInfoIcon
            className="PersonalIbanInfoIcon"
            role="presentation"
          />
        </Tooltip>
        {formattedText}
      </StyledPersonalIbanTextBlock>
    </StyledPersonalIban>
  );
};
