import { styled } from 'theme';

export const StyledWithdrawalInfoSecurityPositionWrapper = styled('div')`
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledWithdrawalInfoSecurityPositionHeader = styled('h4')`
  margin: 0;
`;

export const StyledWithdrawalInfoSecurityPositionTable = styled('table')`
  flex: 1;
  width: 100%;

  th,
  td {
    text-align: left;
    line-height: 24px;
    vertical-align: middle;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }
`;
