import { mapValues, toString as lodashToString } from 'lodash';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from 'react-ui-kit-exante';

import { InfoIcon } from 'assets/icons';
import { PositiveNegative } from 'components/Cells';
import { DottedItem } from 'components/DottedItem';
import { SubHeader } from 'components/SubHeader/SubHeader';
import { Tooltip } from 'components/Tooltip';
import { formatCurrency, formatPercentage } from 'helpers/formatters';
import { useQuery } from 'hooks/index';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';

import {
  COMMISSION_TYPE,
  GWTIME_DATES_TYPE,
  OTHER_OPERATION_TYPE,
  QUERY_ACCOUNT_FILTER_KEY,
  QUERY_DATE_FROM_FILTER_KEY,
  QUERY_DATE_TO_FILTER_KEY,
  TIMESTAMP_DATES_TYPE,
  TRADES_OPERATION_TYPE,
} from './MetricsSection.constants';
import MetricsSectionStyles from './MetricsSection.module.css';
import {
  StyledMetricsSectionContainer,
  StyledMetricsSectionLabel,
  StyledMetricsSectionSkeletonRow,
  StyledMetricsSectionValues,
} from './MetricsSection.styled';
import { TMetricsSectionProps } from './MetricsSection.types';

const notFormatValues = ['sharpe', 'sortino'];

export const MetricsSection: FC<TMetricsSectionProps> = ({
  sectionTitle,
  otherTypes,
  commissionTypes,
  info,
  values = [],
  isDataLoading,
}) => {
  const query = useQuery();
  const isValuesEmpty = values.filter(({ value }) => value).length === 0;
  const formatter = useCurrencyFormatter();

  const accountId = query.get(QUERY_ACCOUNT_FILTER_KEY) || '';
  let dateFrom = query.get(QUERY_DATE_FROM_FILTER_KEY) || '';
  let dateTo = query.get(QUERY_DATE_TO_FILTER_KEY) || '';
  dateFrom = dateFrom ? new Date(dateFrom).toISOString().slice(0, 16) : '';
  dateTo = dateTo ? new Date(dateTo).toISOString().slice(0, 16) : '';
  const timestamp = `${dateFrom},${dateTo}`;
  const reactParams = {
    accountId,
    timestamp,
    operationType: '',
  };

  if (isValuesEmpty && !isDataLoading) {
    return null;
  }

  const mapDottedItems = () => {
    return values.map(({ value, title, suffix, tip, id, url, key }) => {
      if (id === OTHER_OPERATION_TYPE) {
        reactParams.operationType = otherTypes;
      } else if (id === COMMISSION_TYPE) {
        reactParams.operationType = encodeURIComponent(commissionTypes);
      } else {
        reactParams.operationType = key;
      }

      const queryParams = new URLSearchParams(
        mapValues(reactParams, lodashToString),
      ).toString();
      let hrefUrl = `${url}?${queryParams}`;
      if (url?.includes(TRADES_OPERATION_TYPE) && timestamp) {
        hrefUrl = hrefUrl.replace(TIMESTAMP_DATES_TYPE, GWTIME_DATES_TYPE);
      }

      const formattedValue = (
        <PositiveNegative
          value={value}
          valueFormatter={
            suffix
              ? formatPercentage
              : (value_) => formatCurrency(formatter, value_)
          }
          withoutFormat={notFormatValues.includes(id)}
        />
      );
      const Title = (
        <StyledMetricsSectionLabel className="MetricsSectionLabel">
          <Link
            target="_blank"
            to={hrefUrl}
            className={MetricsSectionStyles.TotalLink}
          >
            {title}
          </Link>
          <Tooltip title={tip}>
            <InfoIcon />
          </Tooltip>
        </StyledMetricsSectionLabel>
      );
      const label = id !== COMMISSION_TYPE && tip && url ? Title : title; // todo fix COMMISSION_TYPE url and remove condition 'id !== COMMISSION_TYPE'
      return value ? (
        <DottedItem
          key={id}
          label={label}
          value={formattedValue}
          size="medium"
        />
      ) : null;
    });
  };

  return (
    <StyledMetricsSectionContainer className="MetricsSectionContainer">
      <SubHeader title={sectionTitle} info={info} size="big" />
      {isDataLoading ? (
        <StyledMetricsSectionValues className="MetricsSectionValues">
          {[...Array(5)].map((_, index) => (
            <StyledMetricsSectionSkeletonRow key={index}>
              <Skeleton
                width={56}
                height={24}
                animation="wave"
                variant="rounded"
              />
              <Skeleton
                width={56}
                height={24}
                animation="wave"
                variant="rounded"
              />
            </StyledMetricsSectionSkeletonRow>
          ))}
        </StyledMetricsSectionValues>
      ) : (
        <StyledMetricsSectionValues className="MetricsSectionValues">
          {mapDottedItems()}
        </StyledMetricsSectionValues>
      )}
    </StyledMetricsSectionContainer>
  );
};
