import { TChildren } from 'types/TChildren';
import { TMfaTypes } from 'types/mfa';

export enum AffidavitFormStatus {
  Default,
  Requested,
  Verified,
  Confirmed,
}

export type TAffidavitFormProviderProps = TChildren & {
  signOptions: W8SignOption[];
  accountId: string;
  accountName: string;
  signUrl: string;
  legalEntity: string;
  onConfirm?: VoidFunction;
  mfaType: TMfaTypes;
};

export type TAffidavitFormState = {
  isSigned?: boolean;
  status: AffidavitFormStatus;
  requestSign: VoidFunction;
  requestConfirm: VoidFunction;
  signOptions: W8SignOption[];
  signOptionsChecked: W8SignChecked;
  setSignOptionChecked: (optionValue: string, checked: boolean) => void;
  changesText: string;
  setChangesText: (changesText: string) => void;
  code: string;
  setCode: (code: string) => void;
  confirmError: string;
  setConfirmError: (error: string) => void;
  accountId: string;
  accountName: string;
  mfaType: TMfaTypes;
};

export type W8SignOption = {
  value: string;
  label: string;
};

export type W8SignChecked = Record<string, boolean>;
