import '../../index.css';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { NotificationProvider } from 'react-ui-kit-exante';

import { BrandingProvider, ComposedThemeProvider } from 'contexts';
import { useCheckVersion } from 'hooks/useCheckVersion';

import { BrandingIncludeCSS } from '../BrandingIncludeCSS';
import { HeaderInclude } from '../Header';

export const RootPublic = () => {
  useCheckVersion();

  return (
    <BrandingProvider useDefault>
      <HeaderInclude />
      <BrandingIncludeCSS />
      <ComposedThemeProvider>
        <div id="NotificationProvider">
          <NotificationProvider />
        </div>
        <Outlet />
      </ComposedThemeProvider>
    </BrandingProvider>
  );
};
