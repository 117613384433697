import { ClickAwayListener } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';
import { formatCurrency } from 'helpers/formatters';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';

import { TPartnerHeaderProps } from '../../PartnerHeader.types';

import {
  StyledPartnerHeaderDefaultLinkText,
  StyledPartnerHeaderDefaultLinkTooltip,
  StyledPartnerHeaderMobileDefaultLinkWrapper,
  StyledPartnerHeaderMobileRow,
  StyledPartnerHeaderMobileWrapper,
} from './PartnerHeaderMobile.styled';

export const PartnerHeaderMobile: FC<TPartnerHeaderProps> = ({
  defaultLinkTextKeys,
  defaultLink,
  hasInfo,
  paidOut,
  balance,
}) => {
  const { t } = useContext(TranslationContext);

  const currencyFormatter = useCurrencyFormatter('EUR');
  const [, copy] = useCopyToClipboard();

  const [copiedTooltipOpen, setCopiedTooltipOpen] = useState(false);
  const [defaultLinkTooltipOpen, setDefaultLinkTooltipOpen] = useState(false);

  const onDefaultLinkClick = () => {
    setCopiedTooltipOpen(true);
    copy(defaultLink);
  };

  return (
    <StyledPartnerHeaderMobileWrapper className="PartnerHeaderMobileWrapper">
      {defaultLink && (
        <StyledPartnerHeaderMobileRow className="PartnerHeaderMobileRow">
          <StyledPartnerHeaderMobileDefaultLinkWrapper className="PartnerHeaderMobileDefaultLinkWrapper">
            <span>{t(defaultLinkTextKeys.type)}</span>
            {defaultLinkTextKeys.tooltip && (
              <ClickAwayListener
                onClickAway={() => setDefaultLinkTooltipOpen(false)}
              >
                <StyledPartnerHeaderDefaultLinkTooltip className="PartnerHeaderDefaultLinkTooltip">
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    title={t(defaultLinkTextKeys.tooltip).replace(/\\n/g, '\n')}
                    onClose={() => setDefaultLinkTooltipOpen(false)}
                    open={defaultLinkTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <IconButton
                      iconName="InfoIcon"
                      iconSize={16}
                      title={t(defaultLinkTextKeys.label)}
                      onClick={() => setDefaultLinkTooltipOpen(true)}
                    />
                  </Tooltip>
                </StyledPartnerHeaderDefaultLinkTooltip>
              </ClickAwayListener>
            )}
          </StyledPartnerHeaderMobileDefaultLinkWrapper>
          <Tooltip
            title={t('generic__copy_link__copied')}
            disableFocusListener
            open={copiedTooltipOpen}
            placement="top"
            onClose={() => setCopiedTooltipOpen(false)}
          >
            <StyledPartnerHeaderDefaultLinkText
              className="PartnerHeaderDefaultLinkText"
              onClick={onDefaultLinkClick}
            >
              {defaultLink}
              <IconButton iconName="CopyIcon" iconSize={16} />
            </StyledPartnerHeaderDefaultLinkText>
          </Tooltip>
        </StyledPartnerHeaderMobileRow>
      )}
      {hasInfo && (
        <>
          <StyledPartnerHeaderMobileRow className="PartnerHeaderMobileRow">
            <span>{t('layout__partners__partner_paid_out')}</span>
            <span>{formatCurrency(currencyFormatter, paidOut)}</span>
          </StyledPartnerHeaderMobileRow>
          <StyledPartnerHeaderMobileRow className="PartnerHeaderMobileRow">
            <span>{t('generic__balance')}</span>
            <span>{formatCurrency(currencyFormatter, balance)}</span>
          </StyledPartnerHeaderMobileRow>
        </>
      )}
    </StyledPartnerHeaderMobileWrapper>
  );
};
