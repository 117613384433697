import { TFunction } from 'i18next';

export const getReportTypeFilters = (t: TFunction) => {
  return [
    {
      value: 'all',
      name: t('layout__title__all_reports'),
    },
    {
      value: 'performance',
      name: t('account_performance_report'),
    },
    {
      value: 'summary',
      name: t('account_summary'),
    },
    {
      value: 'commissions',
      name: t('layout__title__commissions_report'),
    },
    {
      value: 'custom',
      name: t('layout__title__custom_report'),
    },
    {
      value: 'transactions',
      name: t('financial_transactions'),
    },
    {
      value: 'margin',
      name: t('margin_structure'),
    },
    {
      value: 'overnights',
      name: t('layout__title__overnights_report'),
    },
    {
      value: 'short allowance',
      name: t('layout__title__short_allowance_report'),
    },
    {
      value: 'tax',
      name: t('layout__title__tax_report'),
    },
    {
      value: 'trades',
      name: t('generic__trades'),
    },
    {
      value: 'daily position',
      name: t('title__daily_position_report'),
    },
  ];
};
