import { FC, useContext, useMemo } from 'react';

import { DottedItem } from 'components/DottedItem';
import { CurrencyContext, TranslationContext } from 'contexts';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { Balance } from 'pages/Portfolio/components/AccountSummary/components/SummaryBlock/components/Balance';
import {
  getAccountValues,
  getAvailableValues,
} from 'pages/Portfolio/components/AccountSummary/helpers';

import { getMarginData } from './SummaryBlock.helpers';
import {
  StyledSummaryBlockInfo,
  StyledSummaryBlockList,
} from './SummaryBlock.styled';
import { TSummaryBlockProps } from './SummaryBlock.types';

export const SummaryBlock: FC<TSummaryBlockProps> = ({
  numberedMetrics,
  showBalance,
}) => {
  const { currency } = useContext(CurrencyContext);
  const { t } = useContext(TranslationContext);

  const marginData = useMemo(
    () => getMarginData(numberedMetrics),
    [numberedMetrics],
  );

  const formatter = useCurrencyFormatter(currency);

  const accountValues = useMemo(
    () =>
      getAccountValues({
        currencyFormatter: formatter,
        metrics: numberedMetrics,
        labels: {
          accountValue: t('generic__account_value'),
          dailyPnl: t('generic__daily_pnl'),
          totalDailyPnl: t('generic__total_daily_pnl_percents'),
        },
      }),
    [formatter, numberedMetrics, t],
  );
  const accountValuesList = useMemo(() => {
    const accountValue = accountValues[0];
    return showBalance ? (
      <DottedItem
        key={accountValue?.label}
        label={accountValue?.label}
        value={accountValue?.value}
      />
    ) : (
      accountValues.map(({ label, value }) => (
        <DottedItem key={label} label={label} value={value} />
      ))
    );
  }, [showBalance, accountValues]);

  const availableValues = useMemo(
    () =>
      getAvailableValues({
        currencyFormatter: formatter,
        metrics: numberedMetrics,
        marginData,
        labels: {
          usedMargin: t('generic__used_margin'),
          positionMargin: t('generic__position_margin'),
          totalOrderMargin: t('generic__order_margin'),
          totalBlockedFunds: t('generic__blocked_funds'),
        },
      }),
    [formatter, numberedMetrics, marginData, t],
  );

  const availableValuesList = useMemo(() => {
    const availableValue = availableValues[0];
    return showBalance ? (
      <DottedItem label={availableValue?.label} value={availableValue?.value} />
    ) : (
      availableValues.map(({ label, value }, index) => {
        if (!value) {
          return null;
        }

        return <DottedItem key={`i${index}`} label={label} value={value} />;
      })
    );
  }, [showBalance, availableValues]);

  return (
    <>
      <StyledSummaryBlockInfo className="SummaryBlockInfo">
        <StyledSummaryBlockList className="SummaryBlockList">
          {accountValuesList}
        </StyledSummaryBlockList>
        <StyledSummaryBlockList className="SummaryBlockList">
          {availableValuesList}
        </StyledSummaryBlockList>
      </StyledSummaryBlockInfo>
      {showBalance && <Balance />}
    </>
  );
};
