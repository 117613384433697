import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useTranslate = () => {
  const { t, i18n, ready } = useTranslation();
  const [loading, setLoading] = useState(false);

  const getCurrentLanguage = useCallback(() => i18n.language, [i18n]);

  const changeLanguage = useCallback(
    async (lng: string) => {
      try {
        setLoading(true);
        await i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng);
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error while changing language: ', error.message);
        } else {
          console.error('Unknown error while changing language');
        }
      } finally {
        setLoading(false);
      }
    },
    [i18n],
  );

  // `ready` reflects the state of first loading in the custom backend
  const isLoading = !ready || loading;

  return {
    loading: isLoading,
    changeLanguage,
    getCurrentLanguage,
    t,
  };
};
