import { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { TOOMANY_ERROR } from 'constants/api';
import { isLocalhost } from 'constants/endpoints';
import { ApiRequest } from 'helpers/apiRequest';
import { getBaseName, isBranchStand } from 'helpers/isBranchStand';
import { notifyWith } from 'helpers/notifyWith';
import { PATHS } from 'router/router.constants';
import { NO_CACHE_HEADERS } from 'services/constants';

import {
  SigninStage,
  TPasswordRecoveryResponse,
  TResetPasswordCheckParams,
  TResetPasswordCheckResponse,
  TResetPasswordParams,
  TResetPasswordResponse,
  TSigninErrorData,
  TSigninParams,
  TSigninResponse,
  TSigninResult,
  TSignupParams,
  TSignupResponse,
  TSignupResponseData,
} from './login.types';

class LoginService extends ApiRequest {
  async signup({
    lang = 'en',
    ...formValues
  }: TSignupParams): Promise<TSignupResponse> {
    try {
      const { data } = await this.fetch<TSignupResponseData>({
        url: `/${lang}/clientsarea/rest/signup-v2/`,
        method: 'post',
        data: formValues,
      });

      return {
        data,
        errors: null,
      };
    } catch (error: any) {
      return {
        data: null,
        errors: error.response.data,
      };
    }
  }

  async signin({
    lang = 'en',
    login,
    password,
    updateToken,
    captchaToken,
    code,
    key,
  }: TSigninParams): Promise<TSigninResult | null> {
    try {
      const { data } = await this.fetch<TSigninResponse>({
        url: `/${lang}/clientsarea/rest/signin-v2/`,
        method: 'post',
        data: {
          login,
          password,
          captcha_token: captchaToken,
          update_token: updateToken,
          service: 'site',
          code2fa: code,
          key,
        },
      });

      if (isLocalhost || isBranchStand()) {
        if (data.stage === SigninStage.Done) {
          Cookies.set('x_access', data.x_access, {
            expires: new Date(
              new Date().getTime() + data.x_access_expires_in * 1000,
            ),
          });
          Cookies.set('in_cache', data.x_access);
          Cookies.set('y_access', data.y_access);

          data.url = `${getBaseName()}${data.url}`;
        }
      }

      return { data, errors: null };
    } catch (error) {
      if (
        (error as AxiosError)?.isAxiosError &&
        (error as AxiosError).response?.status === 400
      ) {
        const errors = (error as AxiosError).response?.data as TSigninErrorData;

        return errors ? { data: null, errors } : null;
      }
      notifyWith.genericNetworkError(error);
      console.error('signin error: ', error);
      return null;
    }
  }

  async sendEmailVerificationLink(email: string): Promise<null> {
    try {
      await this.fetch({
        url: `/clientsarea/rest/verify-email-request/`,
        params: {
          email: encodeURIComponent(email),
        },
      });

      return null;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('sendEmailVerificationLink', error);
      return null;
    }
  }

  async checkEmailVerification(email: string): Promise<string | false> {
    try {
      const { data } = await this.fetch<string | false>({
        url: `/clientsarea/rest/verify-email-check/`,
        params: {
          email: encodeURIComponent(email),
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('checkEmailVerification', error);
      return false;
    }
  }

  async requestPasswordRecovery(
    email: string,
    lang = 'en',
  ): Promise<TPasswordRecoveryResponse | null> {
    try {
      const formData = new FormData();
      formData.append('email', email);

      const { data } = await this.fetch<TPasswordRecoveryResponse>({
        method: 'POST',
        url: `/${lang}/clientsarea/account/password_recovery/`,
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-requested-with': 'XMLHttpRequest',
        },
        data: formData,
      });

      return data;
    } catch (error: any) {
      if (error.response.status === TOOMANY_ERROR) {
        notifyWith.error('auth__recovery__too_many_requests');
        return null;
      }

      notifyWith.genericNetworkError(error);
      console.error('requestPasswordRecovery', error);

      return null;
    }
  }

  async resetPassword({ uid, token, password }: TResetPasswordParams) {
    try {
      const formData = new FormData();
      formData.append('password', password);

      const { data } = await this.fetch<TResetPasswordResponse>({
        method: 'POST',
        url: `/clientsarea/reset-confirm/${uid}/${token}/`,
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-requested-with': 'XMLHttpRequest',
        },
        data: formData,
      });

      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('resetPassword', error);

      return null;
    }
  }

  async checkPasswordResetToken({ uid, token }: TResetPasswordCheckParams) {
    try {
      const { data } = await this.fetch<TResetPasswordCheckResponse>({
        method: 'GET',
        url: `/clientsarea/reset-check/${uid}/${token}/`,
      });

      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('checkPasswordResetToken', error);

      return null;
    }
  }

  async updateAccessTokenIfNeeded(): Promise<boolean> {
    try {
      const data = await this.fetch({
        method: 'GET',
        url: '/clientsarea/rest/oauth/update_access_token/',
        headers: NO_CACHE_HEADERS,
      });

      return Boolean(data);
    } catch (error: any) {
      console.error('updateAccessToken', error);

      return false;
    }
  }

  async setAccessTokenToCache(): Promise<boolean> {
    try {
      await this.fetch({
        method: 'GET',
        url: '/clientsarea/rest/oauth/save_access_token/',
        headers: NO_CACHE_HEADERS,
      });

      return true;
    } catch (error: any) {
      console.error('setAccessTokenToCache', error);

      return false;
    }
  }

  async checkTokenAndDefaultRedirect(): Promise<null | string> {
    try {
      const { data } = await this.fetch<string>({
        method: 'GET',
        url: '/clientsarea/rest/oauth/check_token_and_default_redirect/',
        headers: NO_CACHE_HEADERS,
      });

      return data || PATHS.ACCOUNT_SUMMARY;
    } catch (error: any) {
      console.error('checkTokenAndDefaultRedirect', error);

      return null;
    }
  }
}

export const loginService = new LoginService();
