import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { CardSettingsCardSkeleton } from 'pages/CardSettings/components/CardSettingsCard/CardSettingsCardSkeleton';

import { StyledCardSettingsListWrapper } from './CardSettingsList.styled';

export const CardSettingsListSkeleton: FC = () => (
  <StyledCardSettingsListWrapper className="CardSettingsListWrapperSkeleton">
    <Skeleton width={327} height={16} />
    <CardSettingsCardSkeleton />
  </StyledCardSettingsListWrapper>
);
