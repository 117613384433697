import { FC, useContext } from 'react';
import { TIconName, Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import { CardAction } from '../CardAction';

import { TIssueActionProps } from './IssueAction.types';

export const IssueAction: FC<TIssueActionProps> = ({
  onClick,
  reissue,
  disabled,
  message,
}) => {
  const { t } = useContext(TranslationContext);

  const label = reissue
    ? t('layout__cards__reissue_card')
    : t('layout__cards__order_card');
  const iconName: TIconName = reissue
    ? 'ReissueCardIcon'
    : 'OrderPlasticCardIcon';

  const action = (
    <CardAction
      iconName={iconName}
      label={label}
      disabled={disabled}
      onClick={onClick}
    />
  );

  return message ? <Tooltip title={message}>{action}</Tooltip> : action;
};
