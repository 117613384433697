import { Button } from '@mui/material';
import { IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledCurrencySelectorWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;

  min-height: 24px;

  :hover .xnt-icon {
    transform: scale(1.2);
    transition: 0.3s ease;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    gap: 17px;
  }
`;

export const StyledCurrencySelectorCurrentButton = styled(Button)`
  color: ${({ theme }) => theme.color.typo.secondary} !important;
  padding: 0 !important;
  min-width: 0 !important;
  font-size: 15px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.main};
  letter-spacing: 0;
  line-height: 24px;
  background: none;

  &:hover {
    transform: scale(1.1);
    transition: 0.3s ease;
    background: none;
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

export const StyledCurrencySelectorList = styled('ul')`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;

  margin: 0;
  padding: 0;

  list-style: none;
  background: ${({ theme }) => theme.color.bg.primary};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.effect.controls.drop};
`;

export const StyledCurrencySelectorSelectButton = styled(Button)`
  padding: 8px;

  color: ${({ theme }) => theme.color.typo.ghost};
  text-align: left;
  min-width: auto;
  min-height: auto;
  font-family: ${({ theme }) => theme.font.main};
  letter-spacing: 0;
  width: 100%;
  justify-content: flex-start !important;
  font-size: 13px !important;
  line-height: 16px;

  &:hover {
    background: none;
  }
`;

export const StyledCurrencySelectorMoneyIcon = styled(IconButton)`
  svg {
    color: ${({ theme }) => theme.color.typo.action};
  }

  pointer-events: none;
`;

export const StyledCurrencySelectorSelect = styled('select')`
  width: 100%;
  padding: 0;

  color: ${({ theme }) => theme.color.typo.action};
  font-weight: 500;
  font-size: 15px;
  font-family: ${({ theme }) => theme.font.main};

  border: 0;

  background: none;

  appearance: none;

  &::-ms-expand {
    display: none;
  }
`;

export const StyledCurrencyPlainSelector = styled('div')`
  display: flex;
  align-items: center;
  gap: 17px;
  width: 100%;

  .xnt-icon {
    width: 24px;
    height: 24px;
    margin-left: 2px;
  }
`;
