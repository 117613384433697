import { Text } from 'components/Text';

import { StyledEnvLabel, StyledHeaderContainer } from './Header.styled';
import { THeaderProps } from './Header.types';

export const Header = ({ name, isDemo }: THeaderProps) => {
  return (
    <StyledHeaderContainer className="HeaderContainer">
      <Text size="32px">{name}</Text>
      <StyledEnvLabel className="EnvLabel" isDemo={isDemo}>
        {isDemo ? 'Demo' : 'Live'}
      </StyledEnvLabel>
    </StyledHeaderContainer>
  );
};
