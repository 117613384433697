import { CheckmarkIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledProductItemContainer = styled('div')`
  display: flex;
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-radius: 4px;
  gap: 16px;
  padding: 16px;
  width: 364px;
  min-height: 88px;
`;

export const StyledProductToggle = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const StyledProductTitle = styled('div')`
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const StyledProductDescription = styled('div')`
  font-size: 13px;
  line-height: 16px;
`;

export const StyledProductInfo = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  color: ${({ theme }) => theme.color.icon.action};
  width: 24px;
`;
