import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledServicesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px 16px 24px 24px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 0;
  }
`;

export const StyledHeader = styled('div')`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
`;

export const StyledDescription = styled('div')`
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 24px;
`;

export const StyledProducts = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 24px;
`;

export const StyledSkeletonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 24px;
`;

export const StyledSkeletonProduct = styled('div')`
  display: flex;
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-radius: 4px;
  gap: 16px;
  padding: 16px;
  width: 366px;
  height: 72px;
`;

export const StyledProductInfoSkeleton = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

export const StyledSkeletonToggle = styled(Skeleton)`
  border-radius: 32px;
`;
