import { styled } from 'theme';

export const StyledCardSettingsOrderWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledCardOrderContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    flex-direction: row;
    flex-grow: 1;
    margin: 24px;
    border: 1px solid ${({ theme }) => theme.color.line.primary};
    border-radius: 4px;
  }
`;

export const StyledCardOrderIssueHeader = styled('div')`
  width: 100%;
  flex: 1;

  padding: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    padding-bottom: 0;
  }
`;

export const StyledCardOrderIssueHeaderText = styled('h3')`
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.typo.primary};
`;
