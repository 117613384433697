import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

type TStyledTooltipIconProps = {
  iconUrl: string;
};

export const StyledTooltipIcon = styled('div', {
  shouldForwardProp: blockNonNativeProps('iconUrl'),
})<TStyledTooltipIconProps>`
  width: 16px;
  height: 16px;
  background-image: url('${({ iconUrl }) => iconUrl}');
  background-repeat: no-repeat;
  background-size: contain;
`;
