import {
  createContext,
  Dispatch,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { isElementOverlapped } from 'helpers/layout';
import { legalDocsService } from 'services/legal-docs';
import {
  notificationsService,
  TNotification,
  TNotificationsData,
} from 'services/notifications';
import { TChildren } from 'types/TChildren';

import { BrandingContext } from './BrandingContext';
import { TranslationContext } from './TranslationContext/TranslationContext';

type TNotificationId = TNotification['pk'];

type TNotificationsState = {
  isBlockingDisabled: boolean;
  notifications: TNotification[];
  isLoading: boolean;
  acceptNotification(): void;
  showModal: Dispatch<TNotificationId | null>;
  modalNotification: TNotification | null;
  isAccepting: boolean;
  hasToSign: boolean | null;
};

const initialState: TNotificationsState = {
  isBlockingDisabled: false,
  notifications: [],
  isLoading: true,
  acceptNotification: () => {},
  showModal: () => {},
  modalNotification: null,
  isAccepting: false,
  hasToSign: false,
};

export const NotificationsContext =
  createContext<TNotificationsState>(initialState);

const getFirstBlockingNotification = (notifications: TNotification[]) => {
  return (
    notifications.find(({ notified, blocking }) => !notified && blocking) ||
    null
  );
};

export const NotificationsProvider: FC<TChildren> = ({ children }) => {
  const { currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const [notifications, setNotifications] = useState<TNotification[]>([]);
  const [modalNotification, setModalNotification] =
    useState<TNotification | null>(null);
  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const [silentUpdate, setSilentUpdate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<TNotificationsData | null>(null);

  const fetchData = async (kafkaOnly = false) => {
    setIsLoading(true);
    const res = await notificationsService.getNotifications(
      currentLanguage,
      kafkaOnly,
    );
    setIsLoading(false);
    setData(res);
  };

  const { data: hasToSign, fetchData: fetchHasToSign } = useData<boolean>({
    onFetch: () => legalDocsService.getHasToSign(),
  });

  const isSupervisor = !!branding?.is_supervisor;

  function setNextBlockingNotification() {
    if (isSupervisor) {
      return;
    }

    setModalNotification(getFirstBlockingNotification(notifications));
  }

  const value = useMemo<TNotificationsState>(() => {
    const acceptNotification: TNotificationsState['acceptNotification'] =
      async () => {
        if (isSupervisor) {
          setModalNotification(null);
          return;
        }

        if (!modalNotification || isAccepting) {
          return;
        }

        const { notified, pk } = modalNotification;
        let accepted = true;

        if (!notified) {
          setIsAccepting(true);

          accepted = await notificationsService.acceptNotification(pk);

          if (accepted && notifications) {
            const newNotifications = notifications.map((notification) => {
              if (notification.pk === pk) {
                return {
                  ...notification,
                  notified: true,
                };
              }

              return notification;
            });

            setNotifications(newNotifications);
          }

          setIsAccepting(false);
        } else {
          setNextBlockingNotification();
        }
      };

    const showModal: TNotificationsState['showModal'] = (id) => {
      if (!id) {
        setModalNotification(null);
        return;
      }

      const notification = notifications.find(({ pk }) => pk === id);

      if (notification) {
        setModalNotification(notification);
      } else {
        setModalNotification(null);
      }
    };

    return {
      isBlockingDisabled: data?.isBlockingDisabled || false,
      notifications,
      isLoading: isLoading && !silentUpdate,
      acceptNotification,
      showModal,
      isAccepting,
      modalNotification,
      hasToSign,
    };
  }, [
    isSupervisor,
    data?.isBlockingDisabled,
    notifications,
    isLoading,
    isAccepting,
    modalNotification,
    hasToSign,
  ]);

  // check notification is overlapped by any higher z-index element and move notif upper
  const fixNotificationPosition = () => {
    const notificationContainer = document.getElementById(
      'NotificationProvider',
    );
    const notification = document.querySelector(
      '[class*="Notification-module_Notification"]',
    );

    if (
      notification &&
      notificationContainer &&
      isElementOverlapped(notification)
    ) {
      (notificationContainer.firstElementChild as HTMLElement).style.inset =
        '16px 16px 90px';

      return true;
    }

    return false;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (fixNotificationPosition()) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setNotifications(data?.items || []);
  }, [data]);

  useEffect(() => {
    setNextBlockingNotification();
  }, [notifications]);

  const silentFetchData = async () => {
    setSilentUpdate(true);
    await fetchData(true);
    setSilentUpdate(false);
  };

  useEffect(() => {
    if (currentLanguage) {
      fetchData();

      const intervalId = setInterval(silentFetchData, 10000);

      return () => clearInterval(intervalId);
    }

    return () => {};
  }, [currentLanguage]);

  useEffect(() => {
    fetchHasToSign();
  }, []);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};
