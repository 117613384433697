import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Button } from 'react-ui-kit-exante';

import { RESEND_CODE_TIME } from 'constants/RESEND_CODE_TIME';
import { TranslationContext } from 'contexts';
import { codeLength } from 'helpers/codeLength';
import { useTimer } from 'hooks';
import { settingsProfileService } from 'services/settings';
import { TMfaTypes } from 'types/mfa';

import { AffidavitFormContext } from '../../contexts/AffidavitFormContext';
import { AFFIDAVIT_FORM_TERMINAL_STATUSES } from '../../contexts/AffidavitFormContext/AffidavitFormContext.consts';

import {
  StyledCodeInput,
  StyledDescriptionText,
  StyledResendCode,
} from './AffidavitFormConfirm.styled';

export const AffidavitFormConfirm: FC = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { start, timeSecondsFormat, time } = useTimer(RESEND_CODE_TIME);

  const {
    setCode,
    confirmError,
    setConfirmError,
    isSigned,
    status,
    code,
    mfaType,
  } = useContext(AffidavitFormContext);

  const [isCodeSending, setIsCodeSending] = useState(false);

  const isLoading = AFFIDAVIT_FORM_TERMINAL_STATUSES.indexOf(status) === -1;

  const isTooltipButtonDisabled =
    isLoading || isCodeSending || timeSecondsFormat > 0;

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= codeLength(mfaType)) {
      setCode(value);
    }
  };

  const onSendCode = async () => {
    if (mfaType === 'totp') {
      return;
    }

    if (time === 0) {
      start();
    }

    setConfirmError('');
    setIsCodeSending(true);

    await settingsProfileService.sendCode(mfaType, currentLanguage);

    setIsCodeSending(false);
  };

  useEffect(() => {
    if (isSigned) {
      onSendCode();
    }
  }, [isSigned]);

  const titleText: Record<TMfaTypes, string> = {
    sms: t('generic__verification__enter_sms_code'),
    totp: t('generic__verification__google_auth'),
    email: t('layout__verification__weve_sent_code_to_your'),
  };

  const inputPlaceholderText: Record<TMfaTypes, string> = {
    sms: t('generic__verification__sms_code'),
    totp: t('generic__verification__code'),
    email: t('generic__verification_code'),
  };

  return (
    <>
      <StyledDescriptionText className="DescriptionText">
        {titleText[mfaType]}
      </StyledDescriptionText>
      <StyledCodeInput
        title={inputPlaceholderText[mfaType]}
        placeholder={inputPlaceholderText[mfaType]}
        error={!!confirmError}
        message={confirmError}
        onChange={onCodeChange}
        value={code}
        fullWidth
        iconRight={
          mfaType !== 'totp' && (
            <Button
              onClick={onSendCode}
              textColor="action"
              color="transparent"
              disabled={isTooltipButtonDisabled}
            >
              {t('generic__verification_resend')}
            </Button>
          )
        }
      />
      {mfaType !== 'totp' && (
        <StyledResendCode className="ResendCode">
          {timeSecondsFormat > 0 &&
            t('layout__settings__user_details__resend_in_seconds', {
              COUNT: timeSecondsFormat,
            })}
        </StyledResendCode>
      )}
    </>
  );
};
