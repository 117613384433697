import Cookies from 'js-cookie';
import { useCallback, useEffect, useRef } from 'react';

import { LAST_LOGIN_BTN_CLICK_KEY } from 'constants/common';
import {
  AUTHDB_ID_COOKIE_NAME,
  USER_ID_COOKIE_NAME,
  USER_IS_FUNDED_COOKIE_NAME,
} from 'services/constants';
import { influxProxyService } from 'services/influxProxy';

export function useLogHandleTime(entity: string) {
  const start = useRef<string | null>(null);

  const setStartHandleTime = useCallback(() => {
    if (start.current === null) {
      start.current = new Date().toISOString();
    }
  }, []);

  const setStartHandleTimeFromLogin = useCallback(() => {
    const startLoginTime = localStorage.getItem(LAST_LOGIN_BTN_CLICK_KEY);

    if (start.current === null && startLoginTime) {
      start.current = startLoginTime;
      localStorage.removeItem(LAST_LOGIN_BTN_CLICK_KEY);

      return start.current;
    }

    return null;
  }, []);

  const authdbUserId = Cookies.get(AUTHDB_ID_COOKIE_NAME) || '';

  const logHandleTime = useCallback(async () => {
    if (start.current) {
      await influxProxyService.postLogHandleTime({
        entity,
        start: start.current,
        end: new Date().toISOString(),
        customBucket: 'cl-frontend',
        tag: `userid_${authdbUserId}`,
        extraData: {
          funded: (Cookies.get(USER_IS_FUNDED_COOKIE_NAME) || '0') === '1',
          ca_user_id: Cookies.get(USER_ID_COOKIE_NAME) || '',
          authdb_user_id: authdbUserId,
        },
      });
      start.current = null;
    }
  }, []);

  return {
    setStartHandleTime,
    setStartHandleTimeFromLogin,
    logHandleTime,
  };
}

export function useLogHandlePageLoadingTime() {
  const {
    setStartHandleTimeFromLogin: setStartHandleTimeWithLogin,
    logHandleTime: logHandleTimeWithLogin,
  } = useLogHandleTime(`render-after-login-${window.location.pathname}`);
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `render-${window.location.pathname}`,
  );

  useEffect(() => {
    setStartHandleTime();

    const interval = setInterval(() => {
      if (
        !document.querySelectorAll('[class*=MuiSkeleton]').length &&
        document.querySelectorAll('[class*=page__menu]').length
      ) {
        const loginClickTime = setStartHandleTimeWithLogin();
        if (loginClickTime) {
          logHandleTimeWithLogin();
        }
        logHandleTime();
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [logHandleTime, logHandleTimeWithLogin]);
}
