import { IRow } from 'react-ui-kit-exante';

import { Dates } from 'helpers/dates';
import { TClientsareaTheme } from 'theme';
import { TPosition } from 'types/accounts';

export function getRowProps(row: IRow<TPosition>, theme: TClientsareaTheme) {
  if (Dates.isExpired(row.original.expirationTime)) {
    return {
      style: {
        backgroundColor: theme.color.table.expired.bg,
        color: theme.color.table.expired.color,
      },
    };
  }

  return {};
}
