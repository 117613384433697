import { useCallback, useState } from 'react';

import { isKitTableSorting } from 'helpers/typeguards';
import {
  TFetchFeeUsersFn,
  TUseFetchFeeUsersReturn,
} from 'pages/AssetManagementClients/components/AssetManagementClientsTable/AssetManagementClientsTable.types';
import { assetManagementService } from 'services/assetManagement';

export function useFetchFeeUsers(): TUseFetchFeeUsersReturn {
  const [fetchFeeSetupsIsLoading, setFetchFeeSetupsIsLoading] = useState(false);

  const fetchFeeUsers = useCallback<TFetchFeeUsersFn>(async ({ params }) => {
    try {
      setFetchFeeSetupsIsLoading(true);

      const { limit, skip, page, sorting } = params;

      let ordering;
      const hasSorting = isKitTableSorting(sorting) && Boolean(sorting.length);
      if (hasSorting) {
        const [{ id, desc }] = sorting;
        ordering = `${desc ? '-' : ''}${id ?? ''}`;
      }

      const feeSetups = await assetManagementService.getListFeeUsers({
        limit,
        offset: skip,
        page,
        ordering,
      });

      setFetchFeeSetupsIsLoading(false);

      if (!feeSetups) {
        return null;
      }

      const { results: data, count: total } = feeSetups;
      return {
        total,
        data,
      };
    } catch (error: any) {
      console.error(error);
    }
    return null;
  }, []);

  return { fetchFeeUsers, fetchFeeSetupsIsLoading };
}
