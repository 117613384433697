import { styled } from 'theme';

export const StyledOrderInfoWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};

  ${({ theme }) => theme.breakpoints.up('lr')} {
    border-top: none;
    border-right: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;

export const StyledCardOrderInfoHeader = styled('h4')`
  margin: 0;

  font-family: ${({ theme }) => theme.font.header};
  font-size: 24px;
  font-weight: 400;
`;

export const StyledOrderInfoParagraph = styled('p')`
  font-size: 15px;
  margin: 0;
`;
