import { Select, Table } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPeriodicReportsDescription = styled('div')`
  padding: 24px;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledPeriodicReportsSelectors = styled('div')`
  padding: 0 24px 24px;
  display: flex;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const StyledLanguageSelect = styled(Select)`
  width: 327px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const StyledSingleAccount = styled('div')`
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.color.dropdown.border};
`;

export const StyledSingleAccountValue = styled('div')`
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 8px;
  color: ${({ theme }) => theme.color.typo.primary};
  background-color: ${({ theme }) => theme.color.dropdown.chip.bg};
  border-radius: 4px;
`;

export const StyledReportMobile = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledReportMobileHeader = styled('div')`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledReportMobileCheckboxes = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledPeriodicReportsDesktopTable = styled(Table)`
  .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0;
  }
`;
