import { Radio } from 'react-ui-kit-exante';

import {
  StyledRadioButton,
  StyledRadioButtonsContainer,
  StyledRadioButtonsTitle,
} from './RadioButtons.styled';
import { TRadioButtonsProps } from './RadioButtons.types';

export const RadioButtons = <T extends string>({
  options,
  value,
  onChange,
}: TRadioButtonsProps<T>) => {
  return (
    <StyledRadioButtonsContainer className="RadioButtonsContainer">
      {options.map((option) => (
        <StyledRadioButton
          className="RadioButton"
          key={option.id}
          active={option.value === value}
          type="button"
          onClick={() => onChange(option.value)}
        >
          <Radio checked={option.value === value} />
          <StyledRadioButtonsTitle className="RadioButtonsTitle">
            {option.label}
          </StyledRadioButtonsTitle>
        </StyledRadioButton>
      ))}
    </StyledRadioButtonsContainer>
  );
};
