import { Notification as UiKitNotification } from 'react-ui-kit-exante';

import { ReloadNotificationMessage } from 'components/ReloadNotificationMessage';
import { RELOAD_TIMEOUT } from 'constants/common';
import { metaService } from 'services/meta';

/**
 * Checks if the current version matches the remote version
 * and reloads the page if a newer version is available.
 */
export const checkVersion = async () => {
  const versionCurrent = process.env.REACT_APP_VERSION;

  if (!versionCurrent) {
    return;
  }

  try {
    const versionRemote = await metaService.getRemoteVersion();

    // most probably, the file is not available
    // to prevent unwanted behavior, ignore this case
    if (!versionRemote) {
      console.warn('Obtained remote version is empty');
      return;
    }

    if (versionRemote !== versionCurrent) {
      console.warn(`New version ${versionRemote} is available.`);

      UiKitNotification.warning({
        title: (
          <ReloadNotificationMessage
            timeout={RELOAD_TIMEOUT}
            onTimeout={() => {
              window.location.reload();
            }}
          />
        ),
        isPermanent: true,
        onClose: () => {
          window.location.reload();
        },
      });
    }
  } catch (e: any) {
    console.error('Remote version is not available');
  }
};
