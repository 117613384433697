import { css } from 'react-ui-kit-exante';

import { CopyButton } from 'components/CopyButton';
import { styled } from 'theme';

export const StyledWithdrawalInfoRowWrapper = styled('div')<{
  vertical?: boolean;
}>`
  min-width: 290px;

  display: flex;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.color.typo.secondary};

  ${({ vertical }) =>
    vertical &&
    css`
      flex-direction: column;
      gap: 8px;
    `}
`;

export const StyledWithdrawalInfoRowValueWrapper = styled('div')<{
  isComplexValue?: boolean;
}>`
  flex: 1;

  display: flex;
  flex-wrap: nowrap;

  ${({ isComplexValue }) =>
    !isComplexValue &&
    css`
      justify-content: flex-end;
      margin-left: auto;
      padding-left: 8px;
    `}
`;

export const StyledWithdrawalInfoRowTitle = styled('span')`
  display: flex;

  line-height: 24px;
`;

export const StyledWithdrawalInfoRowValue = styled('div')<{
  isComplexValue?: boolean;
}>`
  flex: 1;

  color: ${({ theme }) => theme.color.typo.primary};
  font-weight: 500;
  line-height: 24px;

  display: flex;
  align-items: center;
  ${({ isComplexValue }) =>
    isComplexValue
      ? css`
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        `
      : css`
          justify-content: flex-end;
        `}
`;

export const StyledWithdrawalInfoRowCopyButton = styled(CopyButton)<{
  hidden?: boolean;
}>`
  margin-left: 8px;

  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}
`;
