import { FC, useReducer } from 'react';

import { StyledAssetManagementWrapper } from './AssetManagementSetup.styled';
import { AssetManagementSetupCreation } from './components/AssetManagementSetupCreation';
import { AssetManagementSetupTable } from './components/AssetManagementSetupTable';
import { AssetManagementProvider } from './contexts/AssetManagementContext';

export const AssetManagementSetup: FC = () => {
  const [reloadSetupTableTrigger, reloadSetupTable] = useReducer(
    (x) => x + 1,
    0,
  );

  const contextValue = {
    reloadSetupTableTrigger,
    reloadSetupTable,
  };
  return (
    <StyledAssetManagementWrapper className="AssetManagementWrapper">
      <AssetManagementProvider value={contextValue}>
        <AssetManagementSetupCreation />
        <AssetManagementSetupTable />
      </AssetManagementProvider>
    </StyledAssetManagementWrapper>
  );
};
