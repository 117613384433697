import { useContext, useReducer } from 'react';

import { TranslationContext } from 'contexts';
import { mfaService, TEnableTotpResponse } from 'services/mfa';

import { TotpVerificationActions } from './useTotpVerification.actions';
import { totpVerificationState } from './useTotpVerification.constants';
import { useTotpVerificationReducer } from './useTotpVerification.reducer';
import { TTotpVerificationReducer } from './useTotpVerification.types';

export const useTotpVerification = (
  onSuccess: (payload: TEnableTotpResponse) => void,
) => {
  const { t } = useContext(TranslationContext);
  const [state, dispatch] = useReducer<TTotpVerificationReducer>(
    useTotpVerificationReducer,
    totpVerificationState,
  );

  const handleVerify = async (code: string) => {
    dispatch({ type: TotpVerificationActions.VerifyStart });

    try {
      const payload = await mfaService.enableTotpService(code, 'site');

      dispatch({ type: TotpVerificationActions.VerifySuccess });
      if (payload) {
        onSuccess(payload);
      } else {
        dispatch({
          type: TotpVerificationActions.VerifyError,
          payload: t('layout__actions__unknown_error_please_refresh_page'),
        });
      }
    } catch (error) {
      dispatch({
        type: TotpVerificationActions.VerifyError,
        payload: t('layout__code_expired'),
      });
    }
  };

  const handleResetError = () => {
    if (state.verifyErrorMessage) {
      dispatch({ type: TotpVerificationActions.VerifyResetError });
    }
  };

  return {
    ...state,
    handleVerify,
    handleResetError,
  };
};
