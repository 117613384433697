import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledProductHeadingSkeleton = styled(Skeleton)`
  height: 24px;

  margin: 8px 0;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    height: 32px;
  }
`;

export const StyledProductDescriptionSkeleton = styled(Skeleton)`
  height: 16px;
`;

export const StyledProductLinkSkeleton = styled(Skeleton)`
  height: 48px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: 200px;
  }
`;
