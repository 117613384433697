import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledSigninOauth,
  StyledSigninOauthFlows,
  StyledSigninOauthHint,
  StyledSigninOauthHintJustifier,
  StyledSigninOauthHintText,
} from '../../SigninOauth.styled';

export const SigninOauthSkeleton: FC = () => {
  return (
    <StyledSigninOauth className="SigninOauth">
      <StyledSigninOauthHint className="SigninOauthHint">
        <StyledSigninOauthHintJustifier className="SigninOauthHintJustifier" />
        <StyledSigninOauthHintText className="SigninOauthHintText">
          <Skeleton width="100%" />
        </StyledSigninOauthHintText>
        <StyledSigninOauthHintJustifier className="SigninOauthHintJustifier" />
      </StyledSigninOauthHint>

      <StyledSigninOauthFlows className="SigninOauthFlows">
        <Skeleton width="100%" height={48} />
        <Skeleton width="100%" height={48} />
      </StyledSigninOauthFlows>
    </StyledSigninOauth>
  );
};
