import { prepareToQueryParams } from 'helpers/prepareToQueryParams';
import { TFetchTradesResponse, tradesService } from 'services/trades';

import { TTradesFetchHelperProps } from './Trades.types';
import { columnKeys } from './columns';

export const fetchTrades = async ({
  params,
  tabAvailable,
  accountId,
}: TTradesFetchHelperProps): Promise<TFetchTradesResponse | null> => {
  const { gwTime, sorting, limit, skip } = params;

  if (!tabAvailable || !accountId || !Array.isArray(sorting)) {
    return {
      data: [],
      total: 0,
      limit: 0,
    };
  }

  const {
    dateRange: [beginDate, endDate],
    iDisplayLength,
    iDisplayStart,
    sSortDir_0: sortDir,
    iSortCol_0: sortCol,
  } = prepareToQueryParams({
    accountId,
    dateRange: gwTime,
    sorting,
    columnKeys: [...columnKeys],
    limit,
    skip,
  });

  return tradesService.getTrades({
    accountId,
    beginDate,
    endDate,
    size: iDisplayLength,
    offset: iDisplayStart,
    sortDir,
    sortCol,
  });
};
