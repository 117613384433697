import { createAsyncThunk } from '@reduxjs/toolkit';

import { TApplicationSettings, applicationService } from 'services/application';

export const fetchApplicationSettings = createAsyncThunk<
  TApplicationSettings | null,
  string
>('application/settings/fetch', async (lang) =>
  applicationService.getApplicationSettings(lang),
);
