import { styled } from 'theme';

export const StyledReportEditItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledReportEditItemHead = styled('div')`
  display: flex;
  gap: 24px;
  align-items: center;

  width: 100%;
`;

export const StyledReportEditItemFields = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }

  & > div {
    width: 100%;
  }
`;

export const StyledReportEditItemFieldsRow = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const StyledReportEditItemGroupList = styled('div')`
  display: flex;
  gap: 24px;

  .MuiFormControlLabel-root {
    margin: 12px 0;
  }
`;
