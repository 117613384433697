import { TAccountType } from 'services/accounts/accounts.types';

export type TDisplayType = {
  key: string;
  default: string;
};

export type TPosition = {
  averagePrice?: string | null;
  blockedQtty?: string | null;
  lockedValue?: string | null; // additional field for cash column
  contractMultiplier?: string | null;
  convertedDailyPnl?: string | null;
  convertedDailyTradesPnl?: string;
  convertedPnl?: string | null;
  convertedValue: string;
  currency: string;
  dailyPnl?: string | null;
  dailyPnlPercentage?: string | null;
  dailyTradesPnl?: string | null;
  description: string;
  displayType?: TDisplayType;
  exchange?: string | null;
  expirationDate?: string | null;
  expirationTime?: string | null;
  isin?: string | null;
  pnl?: string | null;
  pnlPercentage?: string | null;
  price?: string | null;
  priceUnit?: string | null;
  quantity?: string | null;
  quoteTimestamp?: string | null;
  realizedPnl?: string | null;
  sedol?: string | null;
  sharePercent: string;
  shortName?: string | null;
  symbolId?: string | null;
  ticker?: string | null;
  timestamp: string;
  type: string;
  typeOverride?: string | null;
  value: string;
  id?: string | null;
  figi?: string | null;
  cleanPrice?: string | null;
  accruedInterest?: string | null;
  convertedAccruedInterest?: string | null;
};

export type TAccountSummaryMetrics = {
  accountId: string;
  accountPurpose: TAccountType;
  account_id?: string[];
  cardNumber: string;
  cardStatus: string | null;
  cashFreeMoney: string | null;
  convertedDailyPnl: string | null;
  currency?: string[] | TPosition[];
  freeMoney: string | null;
  label: string;
  marginUtilization: string | null;
  netAssetValue: string | null;
  percentageTotalDailyPnl: string | null;
  requiredMargin: string | null;
  totalBlockedMargin: string | null;
  totalCash: string | null;
  totalFreeMoney: string | null;
  totalOrderMargin: string | null;
};

export type TNumberedMetrics = Record<
  keyof TAccountSummaryMetrics,
  number | null
>;

export const positions = [
  'bond',
  'cfd',
  'currency',
  'forex',
  'fund',
  'future',
  'fx_spot',
  'option',
  'stock',
] as const;

export type Position = (typeof positions)[number];

export type TPositions = Record<Position, TPosition[]>;

export type TAccountSummaryMeta = {
  account: string;
  selectedDate: Date | null;
  selectedCurrency: string | null;
};

export type TAccountSummary = TPositions &
  TAccountSummaryMetrics &
  TAccountSummaryMeta;

export type TFetchAddAccountLimit = {
  limits: [TAddAccountLimit] | null;
  allowAddAccount: boolean;
};

export type TAddAccountLimit = {
  by_withdrawal_permission: boolean;
  clientId: string;
  limit: number;
  count: number;
};

export type TAddAccount = {
  accountId: string;
  id: string;
  status: string;
  userId: string;
  overrideAccountStatus: boolean;
};
