import { TCurrencies } from 'services/currencies';
import { TOptionsArguments } from 'types/api';

export type TDepositHistoryCryptoResponse = TDepositCryptoTransaction[];

export type TParamsWithOptions = {
  options: TOptionsArguments;
};

export type TGetDepositParams = TParamsWithOptions & {
  accountId?: string | null;
};

export type TSetCashConversionParams = TParamsWithOptions & {
  accountId: string;
  currency: string;
};

export type TGetAccountInfoParams = TParamsWithOptions & {
  accountId: string;
};

export type TGetDepositDocUrlParams = TParamsWithOptions & {
  url: string;
};

export type TGetCryptoAddressParams = TParamsWithOptions & {
  currency: string;
  accountId: string;
  renew?: boolean;
};

export type TExportDepositDownloadParams = TParamsWithOptions & {
  accountId: string;
  currency: string;
  bank: string;
};

export type TExportDepositEmailParams = TParamsWithOptions & {
  accountId: string;
  currency: string;
  bank: string;
  email?: string;
};

export type TGetHistoryCryptoParams = TParamsWithOptions & {
  accountId: string;
};

export enum CryptoTransactionStatus {
  Failed = 'failed',
  Pending = 'pending',
  Processed = 'processed',
  Rejected = 'rejected',
}

export type TDepositCryptoTransaction = {
  id: number;
  address: string;
  address_from: string;
  created: string;
  amount: number;
  currency: string;
  status: CryptoTransactionStatus;
  account: string;
  transaction_id: string;
  confirmations: number;
};

export type TDepositCryptoAddressResponse = {
  address: string;
};

export type TDepositDocResponse = {
  ready: boolean;
  url: string;
};

export type TDepositExportDownloadResponse = {
  url: string;
};

export type TDepositExportEmailResponse = {
  result: boolean;
  message: string;
};

export type TCashConversionUpdateResponse = {
  account: string;
  currency: string;
};

export type TCashConversionResponse = {
  html: string;
  data: {
    accounts: Record<string, string>;
    currencies: string[];
    has_more: boolean;
    offset: number;
    next: number;
  };
};

export type TDepositResponse = {
  deposit: TDepositData;
};

export type TPersonalIbanData = {
  currency: string;
  legalEntity: string;
  iban: string;
};

export type TDepositData = {
  deposit: TDeposit;
  paymentAccount: string | null;
  personalIban: TPersonalIbanData[];
  accountTypes: Record<string, string | null>;
  descriptions: Record<string, TLegalEntityDescription>;
  cashConversion: TCashConversion;
  application: TApplication;
  legal: TLegal;
  urls: TDepositUrls;
};

export type TDeposit = {
  allowedAccounts: string[];
  depositAccounts: string[];
  isFundsReceived: boolean | null;
  commissions: Record<string, never>;
};

export type TLegalEntityDescription = {
  legalEntity: string;
  description: string;
};

export type TCashConversionAccount = Record<string, never>;
export type TCashConversionAccounts = Record<string, TCashConversionAccount>;

export type TCashConversion = {
  currencies: [];
  accounts: TCashConversionAccounts;
};

export type TApplication = {
  ready: string;
  legalEntity: string;
  depositGroup: string;
  clientId: string;
  legalEntities: TApplicationLegalEntities;
};

export type TApplicationLegalEntities = {
  fallback: string;
  all: string[];
  byClientId: Record<string, string>;
};

export type TDepositUrls = {
  depositExport: string;
  cashConversion: string;
};

export type TBankData = Record<string, string>;
export type TDocGroupData = Record<string, string[]>;

export type TLegal = {
  accountFilteredBanks: Record<string, TDepositBank[]>;
  address: TBankData;
  shortLicense: TBankData;
  shortestLicense: TBankData;
  name: TBankData;
  shortName: TBankData;
  fullName: TBankData;
  lawsOfCountry: TBankData;
  requiredDocGroups: TDocGroupData;
  minimalDeposit: TLegalMinimalDepositCategories;
};

export type TDepositBank = {
  alias: string;
  legalEntity: string;
  depositGroup: string | null;
  beneficiaryBank: string;
  bankAddress: string;
  swift: string;
  currency: Record<string, TLegalBankCurrency>;
  currencyIcons: TCurrencies;
  paymentDetails?: string;
};

export type TLegalBankCurrency = {
  iban?: string;
  beneficiary?: string;
  beneficiaryAddress?: string;
  personalIbanAccount?: string;
  personalIbanNumber?: string;
  overrideIBAN?: boolean;
  accountNumber: string;
  correspondentAccount?: string;
  correspondentSWIFT?: string;
  correspondentBIC?: string;
};

export type TLegalBankCurrencyWithCode = TLegalBankCurrency & {
  code: string;
};

export type TLegalMinimalDepositCategories = {
  individual: TLegalMinimalDeposit;
  corp: TLegalMinimalDeposit;
};

export type TLegalMinimalDeposit = {
  prefix: string;
  value: string;
  suffix: string;
};
