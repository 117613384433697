import { styled } from 'theme';

import { CheckmarkIcon } from '../../../assets/icons';

export const StyledChecklistItem = styled('li')`
  display: flex;
  align-items: center;
  margin: 8px 0;
  gap: 10px;

  list-style: none;
  color: ${({ theme }) => theme.color.typo.promo};
  font-size: 15px;
  line-height: 24px;
`;

export const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  flex-shrink: 0;
  color: ${({ theme }) => theme.color.icon.source};
  width: 24px;
  height: 24px;
`;
