import { createContext, FC, useContext, useMemo, useState } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';
import { TChildren } from 'types/TChildren';

import { SECURITY_TRANSFER_MIN_AMOUNT } from '../../../../Securities.consts';
import { TSecurityField } from '../../../../Securities.types';
import { useSecuritiesAmount } from '../../hooks';

import { validateAmountSecurities } from './SecuritiesAmountContext.helpers';
import { TSecuritiesAmountContextState } from './SecuritiesAmountContext.types';

const defaultField = { quantity: '', symbolId: '' };

const initialState: TSecuritiesAmountContextState = {
  addAmount: () => {},
  removeAmount: () => {},
  isRemoveAmountDisabled: false,
  isShowFieldError: false,
  setIsShowFieldError: () => {},
  fields: [defaultField],
  onFieldChange: () => {},
  selectedSecurities: [],
  options: [],
  securitiesOptions: () => [],
  fieldsErrors: [],
  positionsIsLoading: false,
  positions: [],
  instrumentsIsLoading: false,
  instruments: null,
  clearFields: () => {},
  fetchPositions: () => {},
  isNotAvailable: false,
};

export const SecuritiesAmountContext =
  createContext<TSecuritiesAmountContextState>(initialState);

export const SecuritiesAmountProvider: FC<TChildren> = ({ children }) => {
  const { t } = useContext(TranslationContext);

  const {
    positions,
    positionsIsLoading,
    instruments,
    instrumentsIsLoading,
    getUnlockedQuantity,
    fetchPositions,
  } = useSecuritiesAmount();

  const [fields, setFields] = useState<TSecurityField[]>(initialState.fields);
  const [isShowFieldError, setIsShowFieldError] = useState(false);

  const isRemoveAmountDisabled =
    fields.length === 1 && !fields[0].quantity && !fields[0].symbolId;

  const selectedSecurities = fields
    .map((secs, index) => [secs.symbolId, index])
    .filter(Boolean);

  const options =
    positions?.map((item) => {
      return {
        value: item.symbolId,
        label: item.symbolId,
      };
    }) || [];

  const securitiesOptions = (idx: number) => {
    const dynamicOpts = fields.map(() => options);
    return (
      dynamicOpts[idx]?.filter(
        (opts) =>
          !selectedSecurities?.some(
            (selected) => selected[0] === opts.value && selected[1] !== idx,
          ),
      ) || []
    );
  };

  const addAmount = () => {
    setFields((prevState) => [...prevState, defaultField]);
    setIsShowFieldError(false);
  };

  const removeAmount = (index: number) => {
    if (fields.length === 1) {
      setFields([defaultField]);
      setIsShowFieldError(false);
    } else {
      setFields(fields.filter((field, idx) => index !== idx));
    }
  };

  const onFieldChange = (
    type: keyof TSecurityField,
    value: string,
    index: number,
  ) => {
    if (type === 'symbolId') {
      setFields((prevState) =>
        prevState.map((val, idx) => {
          if (index === idx) {
            const symbol = positions?.find((item) => item.symbolId === value);
            return {
              quantity: symbol ? String(getUnlockedQuantity(symbol)) : '0',
              symbolId: value,
            };
          }
          return val;
        }),
      );
    } else {
      setFields((prevState) =>
        prevState.map((val, idx) => {
          if (index === idx) {
            const parsedValueWithDot = value.startsWith('.', 0)
              ? value.replace('.', '0.')
              : value;
            return {
              ...val,
              quantity: validateAmountSecurities(value)
                ? parsedValueWithDot
                : val.quantity,
            };
          }
          return val;
        }),
      );
    }
  };

  const fieldsErrors = useMemo(() => {
    return fields.map((field) => {
      if (field?.quantity) {
        const symbol = positions?.find(
          (item) => item?.symbolId === field?.symbolId,
        );

        const maxQuantity = symbol ? getUnlockedQuantity(symbol) : 0;

        const rangeOverflow =
          maxQuantity && maxQuantity < parseFloat(field.quantity)
            ? `${t('layout__amount__value_must_be_less_than')} ${maxQuantity}`
            : '';

        const rangeUnderflow =
          parseFloat(field.quantity) < Number(SECURITY_TRANSFER_MIN_AMOUNT)
            ? `${t(
                'layout__amount__value_must_be_greater_than',
              )} ${SECURITY_TRANSFER_MIN_AMOUNT}`
            : '';

        return {
          quantityError: rangeUnderflow || rangeOverflow,
          symbolIdError: '',
        };
      }
      return {
        quantityError: !field.quantity ? t('layout__field_is_required') : '',
        symbolIdError: !field.symbolId ? t('layout__field_is_required') : '',
      };
    });
  }, [fields]);

  const clearFields = () => {
    setFields(initialState.fields);
    setIsShowFieldError(false);
  };

  const value = useMemo<TSecuritiesAmountContextState>(
    (): TSecuritiesAmountContextState => ({
      addAmount,
      removeAmount,
      isRemoveAmountDisabled,
      fields,
      onFieldChange,
      selectedSecurities,
      options,
      securitiesOptions,
      fieldsErrors,
      positionsIsLoading,
      instrumentsIsLoading,
      positions,
      instruments,
      clearFields,
      fetchPositions,
      isShowFieldError,
      setIsShowFieldError,
      isNotAvailable:
        options.length === 0 && !positionsIsLoading && !instrumentsIsLoading,
    }),
    [
      addAmount,
      removeAmount,
      isRemoveAmountDisabled,
      fields,
      onFieldChange,
      selectedSecurities,
      options,
      securitiesOptions,
      fieldsErrors,
      positionsIsLoading,
      instrumentsIsLoading,
      positions,
      instruments,
      clearFields,
      isShowFieldError,
      fetchPositions,
      setIsShowFieldError,
    ],
  );

  return (
    <SecuritiesAmountContext.Provider value={value}>
      {children}
    </SecuritiesAmountContext.Provider>
  );
};
