import { useCallback, useContext, useMemo } from 'react';

import { TTreeResponseTreeStructure } from 'services/instrumentsTree/instrumentsTree.types';

import { TUseHandleCellClickArgs } from '../../InstrumentsTree.types';
import { InstrumentsTreeContext } from '../../context/InstrumentsTreeContext';
import { ActionTypes } from '../../context/InstrumentsTreeContext.types';
import { getAndInsertDataToNode } from '../../helpers/getAndInsertDataToNode';

import {
  TUseHandleCellClickProps,
  TUseHandleCellClickValue,
} from './useHandleCellClick.types';

export function useHandleCellClick({
  accountId,
  matchIdAndPositionInNonSearchTable,
  updateMatchIdAndPositionInNonSearchTable,
  matchIdAndPositionInSearchTable,
  setTableData,
  username,
  search,
  updateTableSizes,
}: TUseHandleCellClickArgs): TUseHandleCellClickValue<TTreeResponseTreeStructure> {
  const [state, dispatch] = useContext(InstrumentsTreeContext);
  const { expandedRows, downloadedPaths, loadingInstruments } = state;
  const handleCellClick = useCallback(
    async ({
      column,
      row,
      tableState: { expanded },
    }: TUseHandleCellClickProps<TTreeResponseTreeStructure>) => {
      if (loadingInstruments.has(row.id)) {
        return;
      }

      if (column.id !== 'name' && row.original.rowType === 'node') {
        return;
      }

      const dontHaveInstrumentsInCache =
        row.original.rowType === 'node' &&
        !downloadedPaths.has(row.original.path) &&
        !row.subRows.length;

      if (row.isExpanded) {
        row.toggleRowExpanded();

        dispatch({
          type: ActionTypes.SET_EXPANDED_ROWS,
          payload: {
            ...expandedRows,
            [row.id]: !expandedRows[row.id],
          },
        });
        updateTableSizes();
        return;
      }

      if (dontHaveInstrumentsInCache && accountId) {
        await getAndInsertDataToNode({
          accountId,
          username,
          search,
          row,
          skip: 0,
          dispatch,
          loadingInstruments,
          setTableData,
          expanded,
          matchIdAndPositionInNonSearchTable,
          updateMatchIdAndPositionInNonSearchTable,
          module: state.module,
        });

        return;
      }

      if (row.canExpand && row.toggleRowExpanded) {
        row.toggleRowExpanded();

        dispatch({
          type: ActionTypes.SET_EXPANDED_ROWS,
          payload: {
            ...expandedRows,
            [row.id]: true,
          },
        });
        updateTableSizes();
      }
    },
    [
      dispatch,
      downloadedPaths,
      expandedRows,
      matchIdAndPositionInNonSearchTable,
      matchIdAndPositionInSearchTable,
      setTableData,
      updateMatchIdAndPositionInNonSearchTable,
      loadingInstruments,
    ],
  );

  return useMemo<TUseHandleCellClickValue<TTreeResponseTreeStructure>>(
    (): TUseHandleCellClickValue<TTreeResponseTreeStructure> => ({
      handleCellClick,
    }),
    [handleCellClick, loadingInstruments],
  );
}
