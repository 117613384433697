import { styled } from 'theme';

export const StyledAccountsTableSkeletonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledAccountsTableSkeletonRow = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-radius: 4px;
`;

export const StyledAccountsTableSkeletonSubRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
