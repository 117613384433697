import Decimal from 'decimal.js';
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Button, Input, Skeleton } from 'react-ui-kit-exante';

import { CurrencySelect } from 'components/CurrencySelect';
import { Text } from 'components/Text';
import { ENTER_KEY_NAME } from 'constants/keycodes';
import { CurrencyContext } from 'contexts/CurrencyContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';

import {
  getCommissionMinMaxHint,
  TRANSFER_INPUT_LIMIT,
} from '../../../../components/AmountForm/AmountForm.constants';
import { AmountFormHint } from '../../../../components/AmountForm/components';
import { SUBUSER_WITHDRAWAL_MIN_MPI } from '../../SubuserWithdrawal.constants';

import { validateAmount } from './SubuserWithdrawalAmountForm.helpers';
import {
  StyledWithdrawalAmountFormAvailableDescription,
  StyledWithdrawalAmountFormContainer,
  StyledWithdrawalAmountFormDescription,
  StyledWithdrawalAmountFormHeader,
  StyledWithdrawalAmountFormInputContainer,
} from './SubuserWithdrawalAmountForm.styled';
import { TSubuserWithdrawalAmountFormProps } from './SubuserWithdrawalAmountForm.types';

export const SubuserWithdrawalAmountForm: FC<
  TSubuserWithdrawalAmountFormProps
> = ({
  isLoading,
  disabled = false,
  currencies,
  limit,
  currency,
  fee,
  amount,
  onSubmit,
  onAmountChange,
  onCurrencyChange,
  commissionCurrency,
  available,
}) => {
  const { t } = useContext(TranslationContext);
  const { currencies: contextCurrencies } = useContext(CurrencyContext);

  const [amountFieldIsDirty, setAmountFieldIsDirty] = useState(false);

  const formatter = useCurrencyFormatter(currency);
  const formattedLimit = formatCurrency(formatter, limit);
  const safeLimit = limit || 0;
  const parsedRemainSum = new Decimal(safeLimit).minus(amount || 0).toNumber();
  const remainSum = formatCurrency(formatter, parsedRemainSum);

  const handleSubmit = () => {
    setAmountFieldIsDirty(true);
    if (
      validateAmount(amount, SUBUSER_WITHDRAWAL_MIN_MPI.mpi) &&
      Number(amount) <= safeLimit &&
      Number(amount) > 0
    ) {
      setAmountFieldIsDirty(false);
      onSubmit();
    }
  };

  const updateAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (validateAmount(value, SUBUSER_WITHDRAWAL_MIN_MPI.mpi)) {
      setAmountFieldIsDirty(true);
      onAmountChange(value);
    }
  };

  const setAmountToLimit = () => {
    const val = new Decimal(available || 0).minus(fee.allFunds).toNumber();
    return val > 0 && onAmountChange(val.toString());
  };

  const isError = Number(amount) > safeLimit || Number(amount) < 0;
  const submitDisabled = isLoading;

  const errorText = useMemo(() => {
    if (!amountFieldIsDirty || limit === null) {
      return '';
    }

    const rangeOverflow =
      limit < Number(amount)
        ? `${t('layout__amount__value_must_be_less_than')} ${formattedLimit}`
        : '';

    const rangeUnderflow =
      parseFloat(amount) < Number(SUBUSER_WITHDRAWAL_MIN_MPI.min)
        ? `${t('layout__amount__value_must_be_greater_than')} ${formatCurrency(
            formatter,
            SUBUSER_WITHDRAWAL_MIN_MPI.min,
          )}`
        : '';

    const fieldRequiredError = String(amount).length
      ? ''
      : t('layout__field_is_required');

    return rangeOverflow || rangeUnderflow || fieldRequiredError;
  }, [amount, limit, amountFieldIsDirty]);

  const hintText = fee?.stdFee
    ? t('layout__withdrawals__fee-notice_default', {
        COMMISSION: fee.visibleValue,
      })
    : getCommissionMinMaxHint({
        t,
        effective: fee.effective,
        currency: commissionCurrency,
        maximum: fee.max,
        minimum: fee.min,
        value: fee.visibleValue,
      });

  const onEnter = (e: KeyboardEvent) => {
    if (e.key === ENTER_KEY_NAME) {
      if (submitDisabled) {
        return;
      }
      handleSubmit();
    }
  };

  return (
    <StyledWithdrawalAmountFormContainer
      className="AmountContainer"
      onSubmit={handleSubmit}
    >
      <StyledWithdrawalAmountFormHeader className="AmountTitle">
        {isLoading ? (
          <Skeleton width={100} height={32} />
        ) : (
          <Text size="24px">
            {t('layout__subaccount_transfer__amount__title')}
          </Text>
        )}
      </StyledWithdrawalAmountFormHeader>
      <StyledWithdrawalAmountFormDescription className="WithdrawalAmountFormDescription">
        {t('layout__withdrawal__subuser__description')}
      </StyledWithdrawalAmountFormDescription>
      <CurrencySelect
        showSkeleton={isLoading}
        options={currencies}
        icons={contextCurrencies?.icons || {}}
        value={currency}
        placeholder={t('layout__subaccount_transfer__amount__currency')}
        onChange={onCurrencyChange}
      />
      <StyledWithdrawalAmountFormInputContainer className="AmountInputContainer">
        {isLoading ? (
          <Skeleton width="100%" height={25} />
        ) : (
          <Input
            fullWidth
            disabled={disabled}
            label={t('transfer__amount_input')}
            value={amount}
            maxLength={TRANSFER_INPUT_LIMIT}
            onChange={updateAmount}
            error={isError || Boolean(errorText)}
            message={errorText}
            onKeyDown={onEnter}
          />
        )}
        <StyledWithdrawalAmountFormAvailableDescription className="AmountDescription">
          {isLoading || limit === null ? (
            <Skeleton width={152} height={24} />
          ) : (
            <>
              <Text size="11px" color="secondary">
                {t('layout__subaccount-transfer-amount__available-hint')}
              </Text>
              <Button
                className="AmountButton"
                onClick={setAmountToLimit}
                color="transparent"
                type="button"
              >
                <Text
                  size="11px"
                  color={!isError ? 'action' : 'radical'}
                  weight="600"
                >
                  {formattedLimit}
                </Text>
              </Button>
              {Number(amount) > 0 && (
                <>
                  <Text size="11px" color="secondary">
                    {'>'}
                  </Text>
                  <Text size="11px" color="secondary" weight="600">
                    {remainSum}
                  </Text>
                </>
              )}
            </>
          )}
        </StyledWithdrawalAmountFormAvailableDescription>
      </StyledWithdrawalAmountFormInputContainer>
      {fee && fee.effective !== 0 && (
        <AmountFormHint text={hintText} loading={isLoading} />
      )}
      <Button
        showSkeleton={isLoading}
        fullWidth
        disabled={disabled || submitDisabled}
        type="button"
        onClick={handleSubmit}
      >
        {t('layout__subaccount_transfer__transfer')}
      </Button>
    </StyledWithdrawalAmountFormContainer>
  );
};
