import { useContext } from 'react';
import { Trans } from 'react-i18next';

import { Checklist, ChecklistItem } from 'components/Checklist';
import { TranslationContext } from 'contexts';
import { DownloadLink } from 'pages/Downloads/components/DownloadLink';
import { Product } from 'pages/Downloads/components/Product';

import {
  StyledDownloadAnchor,
  StyledDownloadLinkWrapper,
  StyledDownloadsList,
  StyledDownloadsListItem,
  StyledDownloadText,
} from './DownloadsList.styled';
import { TDownloadsListProps } from './DownloadsList.types';

export const DownloadsList = ({ platforms, branding }: TDownloadsListProps) => {
  const { t } = useContext(TranslationContext);

  const atp = branding?.atp;

  const tOpts = { NAME: branding?.wl?.name };

  return (
    <StyledDownloadsList className="DownloadsList">
      {platforms.map((platform) => (
        <StyledDownloadsListItem
          orderNumMobile={platform.order_num_mobile || platforms.length}
          className="DownloadsListItem"
          key={platform.alias}
        >
          <Product
            title={platform.title && t(platform.title)}
            description={platform.description && t(platform.description, tOpts)}
            imageUrls={[platform.img_1, platform.img_2]}
            stickImageToBottom={
              !!(platform.img_2 && platform.img_1) ||
              platform.img_stick_to_bottom
            }
          >
            {platform?.checklist && (
              <Checklist>
                {platform?.checklist.map((checklist_item) => (
                  <ChecklistItem key={checklist_item}>
                    {t(checklist_item, tOpts)}
                  </ChecklistItem>
                ))}
              </Checklist>
            )}
            <StyledDownloadLinkWrapper className="ProductLinks">
              {platform?.buttons?.map((button, key) => (
                <DownloadLink
                  key={key}
                  qrUrl={atp?.qr}
                  iconUrl={button.icon}
                  productUrl={button.anchor_url}
                  anchorText={button.text && t(button.text, tOpts)}
                  isOutlined={button.is_outlined}
                />
              ))}
            </StyledDownloadLinkWrapper>
            {platform?.caption && (
              <StyledDownloadText className="DownloadText">
                <Trans
                  t={t}
                  i18nKey={platform.caption}
                  values={{
                    ...tOpts,
                    LINUX: t('generic__linux'),
                    WIN: t('generic__windows'),
                    MAC: t('generic__macos'),
                  }}
                  components={{
                    win: (
                      <StyledDownloadAnchor
                        className="DownloadAnchor"
                        href={atp?.links?.win}
                        target="_blank"
                      />
                    ),
                    linux: (
                      <StyledDownloadAnchor
                        className="DownloadAnchor"
                        href={atp?.links?.linux}
                        target="_blank"
                      />
                    ),
                    mac: (
                      <StyledDownloadAnchor
                        className="DownloadAnchor"
                        href={atp?.links?.mac}
                        target="_blank"
                      />
                    ),
                  }}
                />
              </StyledDownloadText>
            )}
          </Product>
        </StyledDownloadsListItem>
      ))}
    </StyledDownloadsList>
  );
};
