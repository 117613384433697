import { ComponentType, useContext, useMemo } from 'react';

import { ButtonsGroup } from 'components/ButtonsGroup';
import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext';

import {
  Container,
  StyledButtonsGroupWrapper,
  StyledTabsContainer,
  StyledTransfersPlaceholder,
} from './Transfers.styled';
import { TTransferType } from './Transfers.types';
import { WithdrawalHistoryTable, WithdrawalSkeleton } from './components';
import { SubuserWithdrawalHistoryTable } from './components/SubuserWithdrawalHistoryTable';
import { TransfersProvider } from './context';
import { useTransfers } from './hooks';
import { Funds, Crypto, Securities, SubuserWithdrawal } from './routes';

function withTransfersProvider(Component: ComponentType) {
  // eslint-disable-next-line react/function-component-definition -- contradicts with function name requirement
  return function Transfers() {
    return (
      <TransfersProvider>
        <Component />
      </TransfersProvider>
    );
  };
}

export const Transfers = withTransfersProvider(() => {
  const { t } = useContext(TranslationContext);

  const {
    isLoading,
    tableRef,
    options,
    transferType,
    handleTransferTypeClick,
    cashTransferTab,
  } = useTransfers();

  const table = useMemo(() => {
    if (isLoading) {
      return <WithdrawalHistoryTable loading />;
    }

    if (transferType === 'subuser_withdrawal') {
      return <SubuserWithdrawalHistoryTable ref={tableRef} />;
    }

    if (transferType === 'funds' && cashTransferTab === 'MY_ACCOUNT') {
      return null;
    }

    return <WithdrawalHistoryTable loading={isLoading} ref={tableRef} />;
  }, [isLoading, transferType, cashTransferTab, tableRef]);

  if (!isLoading && options.length === 0) {
    return (
      <StyledTransfersPlaceholder className="TransfersPlaceholder">
        <Text withInfoIcon color="secondary">
          {t('layout__subaccount_transfer__not_available')}
        </Text>
      </StyledTransfersPlaceholder>
    );
  }

  return (
    <Container className="Container">
      <StyledTabsContainer className="TabsContainer">
        <StyledButtonsGroupWrapper className="ButtonsGroupWrapper">
          <ButtonsGroup<TTransferType>
            isLoading={isLoading}
            value={transferType}
            options={options}
            onChange={handleTransferTypeClick}
          />
        </StyledButtonsGroupWrapper>
        {isLoading && <WithdrawalSkeleton />}
        {!isLoading && (
          <>
            {transferType === 'funds' && <Funds />}
            {transferType === 'securities' && <Securities />}
            {transferType === 'crypto' && <Crypto />}
            {transferType === 'subuser_withdrawal' && <SubuserWithdrawal />}
          </>
        )}
      </StyledTabsContainer>

      {table}
    </Container>
  );
});
