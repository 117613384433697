import { Input } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledResendCode = styled('div')`
  margin-bottom: 16px;
  font-size: 11px;
  line-height: 16px;
`;

export const StyledCodeInput = styled(Input)`
  & .MuiInputLabel-root {
    line-height: 22px;
  }
`;

export const StyledDescriptionText = styled('div')`
  margin-bottom: 12px;
  font-size: 11px;
  line-height: 16px;
`;
