import { IColumn } from 'react-ui-kit-exante';

import { currencyFormatter } from 'helpers/formatters';
import { TSubuserWithdrawalHistoryEntry } from 'services/withdrawal';

import { TGetSubuserWithdrawalHistoryTableColumnsProps } from './SubuserWithdrawalHistoryTable.types';

export const getSubuserWithdrawalHistoryTableColumns = ({
  t,
  dateFormatter,
  currentLanguage,
}: TGetSubuserWithdrawalHistoryTableColumnsProps): IColumn<TSubuserWithdrawalHistoryEntry>[] => {
  return [
    {
      accessor: ({ wr_id }: TSubuserWithdrawalHistoryEntry) => wr_id,
      id: 'id',
      Header: t('generic__id'),
    },
    {
      accessor: ({ datetime }: TSubuserWithdrawalHistoryEntry) => {
        return dateFormatter(datetime);
      },
      id: 'datetime',
      Header: t('generic__date'),
    },
    {
      accessor: ({ amount, currency }: TSubuserWithdrawalHistoryEntry) =>
        currencyFormatter(currentLanguage, currency).format(Number(amount)),
      id: 'amount-currency',
      Header: t('generic__amount'),
      cellStyle: { fontWeight: '500' },
    },
    {
      accessor: ({ account }: TSubuserWithdrawalHistoryEntry) => account,
      id: 'account-from',
      Header: t('generic__account'),
    },
  ];
};
