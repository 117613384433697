import { FC } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import {
  StyledChallengeAccountSummaryInfoItem,
  StyledChallengeAccountSummaryInfoItemLabel,
  StyledChallengeAccountSummaryInfoItemValue,
} from './ChallengeAccountSummaryInfoItem.styled';
import { TChallengeAccountSummaryItemProps } from './ChallengeAccountSummaryInfoItem.types';

export const ChallengeAccountSummaryInfoItem: FC<
  TChallengeAccountSummaryItemProps
> = ({ label, value, tooltip }) => {
  return (
    <Tooltip title={tooltip}>
      <StyledChallengeAccountSummaryInfoItem className="ChallengeAccountSummaryItem">
        <StyledChallengeAccountSummaryInfoItemLabel className="ChallengeAccountSummaryInfoItemLabel">
          {label}
        </StyledChallengeAccountSummaryInfoItemLabel>
        <StyledChallengeAccountSummaryInfoItemValue className="ChallengeAccountSummaryInfoItemValue">
          {value}
        </StyledChallengeAccountSummaryInfoItemValue>
      </StyledChallengeAccountSummaryInfoItem>
    </Tooltip>
  );
};
