import { useContext, useMemo } from 'react';
import { Notification, useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';
import { reportsService } from 'services/reports';

import { TaxReportsContext } from './contexts/TaxReportsContext';

export const useFundsCheck = (
  yearSelected: string,
  acctSelected: string,
  beforeRequest = false,
) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { reports, stateData } = useContext(TaxReportsContext);

  const currentReport = useMemo(
    () => reports?.find((report) => report.tax_year === yearSelected),
    [reports, yearSelected],
  );

  const {
    isLoading: isFundsChecking,
    data: fundsCheck,
    fetchData: checkFunds,
  } = useData({
    onFetch: async () => {
      const reportPrice = stateData?.isRus
        ? currentReport?.tndfl_price
        : stateData?.reportPrice;

      const result = await reportsService.checkPolFunds({
        lang: currentLanguage,
        account: acctSelected,
        price: String(reportPrice || 0),
      });

      if (result && !result?.success) {
        Notification.warning({ title: t('taxation_view_insufficient_funds') });
      }

      return result;
    },
  });

  // check funds for russian reports only after the report request
  if (beforeRequest && stateData?.isRus) {
    return { checkFunds: () => {}, hasFunds: true, isFundsChecking: false };
  }

  return {
    checkFunds,
    isFundsChecking,
    hasFunds: fundsCheck?.success,
  };
};
