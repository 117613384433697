import { FC, useContext, useEffect } from 'react';
import { useData } from 'react-ui-kit-exante';

import { Loader } from 'components/Loader';
import { TranslationContext } from 'contexts';
import { notifyWith } from 'helpers/notifyWith';
import { legalDocsService, TDocumentPdf } from 'services/legal-docs';

import { StyledModalBody, StyledModalContent } from './DocumentViewer.styled';
import { TDocumentViewerProps } from './DocumentViewer.types';

export const DocumentViewer: FC<TDocumentViewerProps> = ({ doc }) => {
  const { currentLanguage } = useContext(TranslationContext);

  const fetchDocument = async () => {
    try {
      return await legalDocsService.getDocumentPdf(
        doc.dataUrl,
        doc.isSigned,
        currentLanguage,
      );
    } catch (error: any) {
      if (error?.message) {
        notifyWith.error(error.message);
      }

      return null;
    }
  };

  const { fetchData, isLoading, data } = useData<TDocumentPdf | null>({
    onFetch: fetchDocument,
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isLoading && <Loader isCentered isInner />}
      {!isLoading && data && (
        <StyledModalBody className="document-modal__body">
          <StyledModalContent
            src={data.pdf}
            title={doc.title}
            className="document-modal__content document-modal__content--pdf"
          />
        </StyledModalBody>
      )}
    </>
  );
};
