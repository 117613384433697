export const getAllPathsSet = (
  strings: string[],
  separator = /::[^:]*$/,
): Set<string> => {
  const set = new Set(strings);

  strings.forEach((string) => {
    while (string.match(separator)) {
      string = string.replace(separator, '');
      set.add(string);
    }
  });

  return set;
};
