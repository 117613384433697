import { FC } from 'react';

import { StyledHelpHeading } from 'pages/Help/Help.styled';

import { StyledHelpFAQWrapper } from '../../HelpFAQ.styled';

import {
  StyledSearchInputSkeleton,
  StyledTextSkeleton,
  StyledTextSkeletonWrapper,
} from './HelpFAQSkeleton.styled';
import { AccordionSkeleton } from './components/AccordionSkeleton';
import { TabsSkeleton } from './components/TabsSkeleton';

export const HelpFAQSkeleton: FC = () => {
  return (
    <StyledHelpFAQWrapper className="HelpFAQWrapper">
      <div>
        <StyledHelpHeading className="HelpHeading">FAQ</StyledHelpHeading>
        <StyledTextSkeletonWrapper>
          <StyledTextSkeleton variant="rounded" animation="wave" />
        </StyledTextSkeletonWrapper>
      </div>
      <TabsSkeleton />
      <StyledSearchInputSkeleton variant="rounded" animation="wave" />
      <AccordionSkeleton />
    </StyledHelpFAQWrapper>
  );
};
