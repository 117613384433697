import { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import { Checkbox, Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import { TaxReportsContext } from '../../contexts/TaxReportsContext';

import {
  CreateTaxReportConfirmation,
  CreateTaxReportWrapper,
  InfoIconStyled,
  StyledCreateConfirmationLink,
  StyledCreateConfirmationText,
} from './CreateTaxReport.styled';
import { RequestReport } from './components/RequestReport';

export const CreateTaxReport: FC = () => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { stateData, acceptTerms, isLoadingAcceptTerms, isLoading } =
    useContext(TaxReportsContext);

  if (isLoading || isTranslationsLoading) {
    return null;
  }

  const confirmationText = stateData?.isRus
    ? 'layout__reports__tax__confirmation_personal_data'
    : 'layout__reports__tax__confirmation_terms';

  const isAccepted = stateData?.termsAccepted;
  const isCheckboxDisabled = isAccepted || isLoadingAcceptTerms || isLoading;

  return (
    <CreateTaxReportWrapper className="CreateTaxReportWrapper">
      <CreateTaxReportConfirmation className="CreateTaxReportConfirmation">
        <Checkbox
          checked={isAccepted}
          disabled={isCheckboxDisabled}
          onChange={acceptTerms}
          label={
            <StyledCreateConfirmationText className="CreateConfirmationCheckboxContent">
              <Trans
                t={t}
                i18nKey={confirmationText}
                components={{
                  a: (
                    <StyledCreateConfirmationLink
                      className="CreateConfirmationLink"
                      href={stateData?.terms || ''}
                      target="_blank"
                    />
                  ),
                }}
              />
            </StyledCreateConfirmationText>
          }
        />

        <Tooltip title={t('layout__reports__tax__confirmation_hint')}>
          <InfoIconStyled size={16} />
        </Tooltip>
      </CreateTaxReportConfirmation>
      <RequestReport />
    </CreateTaxReportWrapper>
  );
};
