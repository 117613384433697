import { useCallback, useEffect } from 'react';

import { nodeSessionService } from 'services/nodeSession';

import { useLogHandleTime } from './useLogHandleTime';

export const useSessionRegistration = () => {
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('register-session');

  const registerSession = useCallback(async () => {
    setStartHandleTime();
    await nodeSessionService.registerSession();
    logHandleTime();
  }, []);

  useEffect(() => {
    registerSession();
  }, []);
};
