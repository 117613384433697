import { IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledReportActionsWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const StyledMirroredIconButton = styled(IconButton)`
  transform: scaleX(-1);
`;

export const StyledDownloadLink = styled('a')`
  display: inline-flex;
  align-items: center;
`;
