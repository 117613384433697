import { TWithdrawalType } from 'services/withdrawal';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';

import { TTransfersState } from '../../context';

import { FIELDS_BENEFICIARY_ADDRESS } from './useTransferFormsConfig.consts';

/**
 * Returns list of fields restricted to change for the specified transfer type
 *
 * @param type
 * @param transfersState current state of the transfer
 * @param requiredFields initially required fields
 */
export const getRestrictedFieldsForType = (
  type: TWithdrawalType,
  transfersState?: TTransfersState,
  requiredFields?: string[],
) => {
  const isAccountPayment =
    transfersState?.selectedAccountInfo.accountPurpose === 'Payment';

  if (!transfersState || type !== WITHDRAWAL_TYPE.WIRE || isAccountPayment) {
    return [];
  }

  // all filled - all restricted, any not filled - all allowed
  // only if the field is initially required in the form
  return FIELDS_BENEFICIARY_ADDRESS.some(
    (field) =>
      requiredFields?.includes(field) &&
      !transfersState?.init_wire_values?.[field],
  )
    ? []
    : FIELDS_BENEFICIARY_ADDRESS;
};
