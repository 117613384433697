import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  DeleteStatus,
  FileUpload,
  TSetFileSuccessFn,
  TSetFileDeleteStatusFn,
} from 'react-ui-kit-exante';

import { TFormFileUploadContainerProps } from './FormFileUploadContainer.types';

export const FormFileUploadContainer: FC<TFormFileUploadContainerProps> = ({
  name,
  removable,
  translator,
  accept,
  ...rest
}) => {
  const formContext = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: formContext.control,
    name,
  });

  const handleFileAppend = (payload: File[], onSuccess: TSetFileSuccessFn) => {
    onChange([...(value as File[]), ...payload]);
    payload.forEach((file) =>
      onSuccess({
        name: file.name,
        id: file.name,
        removable,
      }),
    );
  };

  const handleFileDelete = (id: string, setDeleted: TSetFileDeleteStatusFn) => {
    setDeleted({ id, status: DeleteStatus.SUCCESS });
    onChange((value as File[]).filter((file) => file.name !== id));
  };

  return (
    <FileUpload
      onUpload={handleFileAppend}
      onDelete={handleFileDelete}
      error={Boolean(error)}
      message={error?.message}
      {...rest}
    />
  );
};
