import classNames from 'classnames';
import { unescape } from 'lodash';

import {
  StyledNotificationAside,
  StyledNotificationBody,
  StyledNotificationContent,
  StyledNotificationDate,
  StyledNotificationExcerpt,
  StyledNotificationHeading,
  StyledNotificationListItem,
  StyledNotificationStatus,
  StyledNotificationWrapperButton,
} from './Notification.styled';
import { TNotificationProps } from './Notification.types';

export const Notification = ({
  pk,
  short_title: shortTitle = '',
  summary = '',
  created = '',
  notified,
  blocking,
  onClick,
}: TNotificationProps) => {
  const onWrapperClick = () => onClick(pk);

  return (
    <StyledNotificationListItem onClick={onWrapperClick}>
      <StyledNotificationWrapperButton
        data-test-id={`client-page__button--open-notification-${pk}`}
        className={classNames('notifications-item', {
          'is-unread': !notified,
          'is-important': blocking,
          'is-read': notified,
        })}
        notified={notified}
        disableRipple
      >
        <StyledNotificationAside className="notifications-item__aside">
          <StyledNotificationStatus
            className="notifications-item__status"
            notified={notified}
            blocking={blocking}
          />
        </StyledNotificationAside>
        <StyledNotificationBody className="notifications-item__body">
          <StyledNotificationHeading className="notifications-item__heading">
            {shortTitle}
          </StyledNotificationHeading>
          <StyledNotificationExcerpt className="notifications-item__excerpt">
            <StyledNotificationContent
              className="NotificationContent"
              dangerouslySetInnerHTML={{ __html: unescape(summary) }}
            />
          </StyledNotificationExcerpt>
          <StyledNotificationDate className="notifications-item__date">
            {created}
          </StyledNotificationDate>
        </StyledNotificationBody>
      </StyledNotificationWrapperButton>
    </StyledNotificationListItem>
  );
};
