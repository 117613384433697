import { AxiosError } from 'axios';
import { omit } from 'lodash';
import qs from 'qs';
import { Notification } from 'react-ui-kit-exante';

import { CANCELLED_ERROR } from 'constants/api';
import { isCancelledError } from 'helpers/abortController/abortController.helpers';
import { ApiRequest } from 'helpers/apiRequest';
import { getFormData } from 'helpers/formData';
import { notifyWith } from 'helpers/notifyWith';
import {
  TAccountDetailsResponse,
  TBankAccountTransferResponse,
  TCreateSecurityTransferForm,
  TExternalTransferParams,
  TExternalTransferResponse,
  TInstrumentsInfo,
  TInternalTransferResponse,
  TLockedAssets,
  TLockedAssetsParams,
  TRequestToMyAccountTransferProps,
  TRequestWithdrawalFormatProps,
  TSearchExternalAccountIDResponse,
  TSecuritiesDetails,
  TSecuritiesDetailsParams,
  TTransferDirectionsResponse,
} from 'services/transfer/transfer.types';
import { TOptionsArguments } from 'types/api';

import {
  TCommissions,
  TWithdrawalFormat,
  TWithdrawalRequestTransferResponse,
} from '../withdrawal';

import { TransferType } from './transfer.constants';

class TransferService extends ApiRequest {
  async fetchAccountDetails(
    account: string,
    type: TransferType,
    currencies?: string | string[] | null,
  ) {
    const { data } = await this.fetch<TAccountDetailsResponse>({
      url: '/clientsarea/account/transfers/account-details/',
      params: {
        account,
        currencies: Array.isArray(currencies)
          ? currencies.join(',')
          : currencies,
        transfer_type: type,
      },
    });

    return data;
  }

  async fetchTransferDirections(account: string, type: TransferType) {
    const { data } = await this.fetch<TTransferDirectionsResponse>({
      url: `/clientsarea/rest/account/transfer-directions/${account}/`,
      params: {
        account,
        transfer_type: type,
      },
    });

    return data;
  }

  async requestToMyAccountTransfer({
    accountFrom,
    accountTo,
    currency,
    amount,
    isAllFunds,
    t,
  }: TRequestToMyAccountTransferProps) {
    try {
      const { data } = await this.fetch<TInternalTransferResponse>({
        method: 'POST',
        url: '/clientsarea/account/withdrawal/create/',
        data: {
          account: accountFrom,
          second_account: accountTo,
          currency,
          amount,
          all_funds: isAllFunds,
        },
      });

      return data;
    } catch (error) {
      const { response } = error as AxiosError;

      if (response?.data?.messages?.length) {
        const errors = `${response?.data?.messages
          ?.map((key: string) => `${t(key)}.`)
          .join(' ')}`;
        Notification.error({ title: errors });
      } else {
        notifyWith.genericNetworkError(error);
      }
      throw error as AxiosError;
    }
  }

  async requestToInternalAccountTransfer({
    account,
    targetAccountId,
    amount,
    currency,
    token,
    confirmType,
    comment,
    files,
    isAllFunds,
  }: TExternalTransferParams) {
    const payload: Array<
      [string, string | number | BinaryData | File | undefined | boolean]
    > = [
      ['amount', amount],
      ['account', account],
      ['internal_account', targetAccountId],
      ['token', token],
      ['currency', currency],
      ['comment', comment],
      ['confirm_type', confirmType],
      ['type', 'Internal'],
      ['confirm', '2fa'],
      ['is_third_party', 'true'],
      ['all_funds', isAllFunds],
    ];

    const formData = getFormData(payload).data;

    if (files) {
      files?.forEach((file) => formData.append('file', file));
    }

    try {
      const { data } = await this.fetch<TExternalTransferResponse>({
        method: 'POST',
        url: '/clientsarea/account/withdrawal/create/',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });

      return data;
    } catch (error) {
      const { response } = error as AxiosError;
      if (response?.data?.message) {
        notifyWith.serverError(response?.data?.message);
      } else {
        notifyWith.genericNetworkError(error);
      }

      return null;
    }
  }

  async requestToBankAccountTransfer(lang: string, data: FormData) {
    const response = await this.fetch<TBankAccountTransferResponse>({
      method: 'POST',
      url: `/${lang}/clientsarea/account/withdrawal/create/`,
      data,
    });

    return response.data;
  }

  async searchExternalAccountID(
    target: string,
    source: string | null,
    signal?: AbortSignal,
  ) {
    const { data } = await this.fetch<TSearchExternalAccountIDResponse>(
      {
        signal,
        method: 'GET',
        url: '/clientsarea/account/transfers/cash/internal/find_transfer_target/',
        params: {
          source,
          target,
        },
      },
      {
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      },
    );

    return data;
  }

  async getSecuritiesDetails({
    currency,
    account,
    lang = 'en',
  }: TSecuritiesDetailsParams) {
    try {
      const { data } = await this.fetch<TSecuritiesDetails>({
        url: `/${lang}/clientsarea/account/summary/details/`,
        params: {
          currency,
          account,
        },
      });

      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async getLockedAssets({ account, lang = 'en' }: TLockedAssetsParams) {
    try {
      const { data } = await this.fetch<TLockedAssets>({
        url: `/${lang}/clientsarea/rest/account/locked-assets/`,
        params: {
          account,
        },
      });

      return data || [];
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      return [];
    }
  }

  async getInstruments(lang = 'en') {
    try {
      const { data } = await this.fetch<TInstrumentsInfo>({
        url: `/${lang}/clientsarea/rest/instruments/`,
      });

      return data || null;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async createSecurityTransfer(
    form: TCreateSecurityTransferForm,
  ): Promise<TWithdrawalRequestTransferResponse<false, true>> {
    try {
      const formData = getFormData(
        Object.entries(omit(form, ['positions', 'files'])),
      ).data;

      if (form?.files) {
        form?.files?.forEach((file) => formData.append('file', file));
      }

      if (form?.positions) {
        formData.append(
          'positions',
          JSON.stringify(form.positions.filter(Boolean)),
        );
      }

      const { data } = await this.fetch<
        TWithdrawalRequestTransferResponse<false, true>
      >({
        url: `/clientsarea/account/withdrawal/create/`,
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      return {
        success: false,
        errors: {},
      };
    }
  }

  async getCommissions(
    account: string,
    options: TOptionsArguments,
    lang = 'en',
  ) {
    try {
      const { data } = await this.fetch<TCommissions>({
        url: `/${lang}/clientsarea/rest/service-fees/withdrawals/`,
        params: {
          account,
        },
        signal: options.signal,
      });

      return data || null;
    } catch (error: any) {
      if (isCancelledError(error)) {
        return CANCELLED_ERROR;
      }

      const { response } = error as AxiosError;

      if (response?.status !== 400) {
        notifyWith.genericNetworkError(error);
      }
      return null;
    }
  }

  async getWithdrawalFormat(params: TRequestWithdrawalFormatProps) {
    const { data } = await this.fetch<TWithdrawalFormat>({
      url: `/${params.lang}/clientsarea/rest/service-fees/withdrawal-format/`,
      params,
    });

    return data?.format || null;
  }
}

export const transferService = new TransferService();
