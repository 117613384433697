import { FC, KeyboardEvent, useContext, useEffect, useRef } from 'react';
import { Button, Input, Skeleton } from 'react-ui-kit-exante';

import { Anchor } from 'components/Footer/Anchor';
import { Text } from 'components/Text';
import { ENTER_KEY_NAME } from 'constants/keycodes';
import { TranslationContext } from 'contexts/TranslationContext';
import { PATHS } from 'router/router.constants';

import {
  StyledConfirmationContainer,
  StyledConfirmationInputContainer,
  StyledConfirmationInputText,
  StyledConfirmationSwitch,
  StyledConfirmFooter,
  StyledResendButton,
  StyledTitleContainer,
} from './Confirmation.styled';
import { TConfirmTransferProps } from './Confirmation.types';
import { useConfirmation } from './hooks';

export const Confirmation: FC<TConfirmTransferProps> = ({
  onConfirm,
  onCancel,
  loading = false,
  requesting = false,
}) => {
  const { t } = useContext(TranslationContext);

  const ref = useRef<HTMLInputElement>(null);

  const {
    code,
    seconds,
    handleCodeChange,
    handleResend,
    handleConfirm,
    resend,
    value,
    isTokenFetching,
    error,
    title,
    confirmationType,
    confirmationTypeOptions,
    canChangeConfirmationType,
    isCodeSent,
    handleConfirmationSwitch,
  } = useConfirmation(onConfirm);

  const disabled = requesting || isTokenFetching;
  const onEnter = (e: KeyboardEvent) => {
    if (e.key === ENTER_KEY_NAME) {
      if (disabled) {
        return;
      }
      handleConfirm();
    }
  };

  useEffect(() => {
    if (!disabled && ref.current) {
      ref.current.focus();
    }
  }, [disabled]);

  const [confirmationTextPrefix, confirmationTextPostfix] = t(
    'layout__transfer_confirmation__change_your_details',
  ).split('[LINK]');

  return (
    <StyledConfirmationContainer className="ConfirmContainer">
      {loading ? (
        <Skeleton width={100} height={32} />
      ) : (
        <Text size="24px">
          {t('layout__subaccount_transfer__amount__confirm_title')}
        </Text>
      )}
      {canChangeConfirmationType && (
        <StyledConfirmationSwitch
          disabled={requesting || isTokenFetching}
          value={confirmationType}
          onChange={handleConfirmationSwitch}
          options={confirmationTypeOptions}
          className="ConfirmationSwitch"
          exclusive
          fullWidth
          data-test-id="layout__transfer-confiramtion-toggle"
        />
      )}
      <StyledTitleContainer className="TitleContainer">
        {loading ? (
          <Skeleton width="100%" height={48} />
        ) : (
          <>
            <Text>{title}</Text>
            <Text weight="600">{value}</Text>
            <br />
            <Text>
              {confirmationTextPrefix || ''}
              <Anchor
                target="_blank"
                to={`${PATHS.ROOT}${PATHS.SETTINGS_PROFILE}`}
                className="ChangePersonalInfoLink"
                label={t(
                  'layout__transfer_confirmation__change_your_details_link_title',
                )}
              />
              {confirmationTextPostfix || ''}
            </Text>
          </>
        )}
      </StyledTitleContainer>
      <StyledConfirmationInputContainer className="FooterContainer">
        <Input
          ref={ref}
          fullWidth
          showSkeleton={loading}
          disabled={requesting || isTokenFetching}
          error={Boolean(error)}
          message={error}
          placeholder={t('layout__security_tabs__mfa_label_enter_code')}
          autoFocus={!disabled}
          iconRight={
            resend && (
              <StyledResendButton
                className="ResendButton"
                type="button"
                disabled={seconds !== 0 || requesting || isTokenFetching}
                onClick={handleResend}
              >
                {isCodeSent
                  ? t('layout__security_tabs__mfa_label_resend')
                  : t('layout__security_tabs__mfa_label_send_code')}
              </StyledResendButton>
            )
          }
          value={code}
          onChange={handleCodeChange}
          onKeyDown={onEnter}
        />
        <StyledConfirmationInputText
          visible={seconds > 0 && resend}
          className="FooterText"
        >
          {t('layout__settings__mfa__resend_after_seconds', { VALUE: seconds })}
        </StyledConfirmationInputText>
      </StyledConfirmationInputContainer>

      <StyledConfirmFooter className="ConfirmFooter">
        <Button
          type="button"
          showSkeleton={loading}
          loading={disabled}
          onClick={handleConfirm}
        >
          {t('layout__security_tabs__mfa_label_confirm')}
        </Button>
        <Button
          color="secondary"
          showSkeleton={loading}
          disabled={disabled}
          onClick={onCancel}
        >
          {t('layout__partners__links__actions_modal_cancel')}
        </Button>
      </StyledConfirmFooter>
    </StyledConfirmationContainer>
  );
};
