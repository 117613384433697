import { styled } from 'theme';

export const StyledModalBody = styled('div')`
  min-width: 80vw;
  height: 70vh;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-height: 500px;
  }
`;

export const StyledModalContent = styled('iframe')`
  width: 100%;
  height: 100%;
`;
