import { TFunction } from 'i18next';
import { isPossiblePhoneNumber } from 'react-ui-kit-exante';
import { boolean, object, string } from 'yup';

export const SIGNUP_PHONE_COUNTRIES_EXCLUDED = ['RU'];

export const LATIN_NAME_REGEX = /^[a-zA-Z0-9'\- ]+$/;

export const RESTRICTED_COUNTRY_CODES_REGEX = /^(?!\+7(?![67])).*$/;

export const EMAIL_REGEX = /^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const SIGNUP_FORM_VALIDATION_SCHEMA = (
  t: TFunction,
  isCompanyRequired: boolean,
) =>
  object({
    first_name: string()
      .required(t('layout__field_is_required'))
      .matches(LATIN_NAME_REGEX, t('layout__field_is_latin_only')),
    last_name: string()
      .required(t('layout__field_is_required'))
      .matches(LATIN_NAME_REGEX, t('layout__field_is_latin_only')),
    email: string()
      .required(t('email__required'))
      .email(t('email_input__invalid'))
      .test('email-plus-minus', t('email_input__plus_minus'), (value = '') =>
        value.endsWith('@exante.eu') ? true : EMAIL_REGEX.test(value),
      ),
    phone: string()
      .required(t('full_phone__required'))
      .matches(
        RESTRICTED_COUNTRY_CODES_REGEX,
        t('generic__phone_input__restricted_country_code'),
      )
      .test(
        'validate-phone',
        t('generic__phone_input__enter_existing'),
        (value = '') => isPossiblePhoneNumber(value),
      ),
    company: isCompanyRequired
      ? string().required(t('layout__field_is_required'))
      : string(),
    marketing_agree: boolean(),
    comment: string(),
  });
