import { useContext, useEffect, useState } from 'react';

import { UserDetailsContext } from 'contexts';
import { mfaService } from 'services/mfa';

import { EMAIL_CODE_LENGTH } from '../../../../Mfa.constants';
import { useCodeVerification } from '../../../../hooks';

export const useEnableEmail = (onSuccess: () => void) => {
  const {
    seconds,
    handleResetTimer,
    handleCodeChange,
    handleResend,
    code,
    isVerifying,
    isRequesting,
    requestErrorMessage,
    verifyErrorMessage,
  } = useCodeVerification(EMAIL_CODE_LENGTH, 'email', { onSuccess });

  const [enabled, setEnabled] = useState(false);
  const { userDetails } = useContext(UserDetailsContext);
  const email = userDetails?.info?.current.email;

  const handleResendClick = async () => {
    handleResetTimer();

    if (!enabled) {
      await mfaService.enableService('email', 'site');
      setEnabled(true);
    }

    await handleResend();
  };

  useEffect(() => {
    handleResendClick();
  }, []);

  return {
    code,
    email,
    enabled,
    isRequesting,
    isVerifying,
    requestErrorMessage,
    seconds,
    verifyErrorMessage,
    handleCodeChange,
    handleResendClick,
  };
};
