import { useMediaQuery } from '@mui/material';
import { useContext, useMemo } from 'react';
import { Table } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { prepareTableId } from 'helpers/prepareTableId';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { assetManagementService } from 'services/assetManagement';
import { TAssetManagementDocument } from 'services/legal-docs';
import { useTheme } from 'theme';

import { TSetIsUploading } from '../DocActionsCell/DocActionsCell.types';

import { TAgreementsAssetDocsProps } from './AgreementsAssetDocs.types';
import { getColumns, getKeys, TABLE_SORT_BY } from './columns';

export const AgreementsAssetDocs = ({
  docs,
  isLoading,
  skeletonsCount,
  onDocUploaded,
}: TAgreementsAssetDocsProps) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const theme = useTheme();

  const dateFormatter = useDateFormatter();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleDocUpload = async (
    doc: TAssetManagementDocument,
    file: File,
    setIsUploading: TSetIsUploading,
  ) => {
    setIsUploading(true);

    const isUploadSuccessful = await assetManagementService.uploadDocAsUser(
      doc.id,
      file,
    );

    setIsUploading(false);

    if (isUploadSuccessful) {
      onDocUploaded?.();
    }
  };

  const columnsDesktop = useMemo(
    () =>
      getColumns({
        t,
        onDocUpload: handleDocUpload,
        isMobile: false,
        dateFormatter,
      }),
    [t, dateFormatter],
  );
  const columnsMobile = useMemo(
    () =>
      getColumns({
        t,
        onDocUpload: handleDocUpload,
        isMobile: true,
        dateFormatter,
      }),
    [t, dateFormatter],
  );
  const keysDesktop = useMemo(() => getKeys({ isMobile: false }), []);
  const keysMobile = useMemo(() => getKeys({ isMobile: true }), []);

  const tableProps = {
    disableSortBy: true,
    data: docs,
    translator: t,
    locale: currentLanguage,
    isFlexLayout: true,
    manualSortBy: false,
    defaultSortBy: TABLE_SORT_BY,
  };

  return isDesktop ? (
    <Table
      {...tableProps}
      className="AssetDocsTable"
      isLoading={isLoading}
      skeletonsCount={skeletonsCount}
      tableId={prepareTableId('clientsarea-settings-agreements-desktop')}
      columns={columnsDesktop}
      displayedColumnKeys={keysDesktop}
      noHeader={isLoading}
    />
  ) : (
    <Table
      {...tableProps}
      isLoading={isLoading}
      skeletonsCount={skeletonsCount}
      tableId={prepareTableId('clientsarea-settings-agreements-mobile')}
      columns={columnsMobile}
      displayedColumnKeys={keysMobile}
      noHeader
    />
  );
};
