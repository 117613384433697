import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  fetchAccountsAction,
  fetchAllAccountDescriptionsAction,
} from 'store/accounts/accounts.actions';
import {
  TAccountsState,
  TSelectAccountPayload,
} from 'store/accounts/accounts.types';

import { accountsInitialState } from './accounts.constants';

let isFirstLoading = true;

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: accountsInitialState,

  reducers: {
    selectAccount(state, action: PayloadAction<TSelectAccountPayload>) {
      const accountId = action.payload.accountId;

      if (accountId) {
        state.selectedAccountId = accountId;
      }

      state.selectedAccount =
        state.data.find(({ value }) => value === accountId) || null;
    },

    selectDate(state, action: PayloadAction<Date>) {
      state.selectedDateIso = action.payload.toISOString();
    },
  },

  extraReducers: (builder) =>
    builder
      .addCase(fetchAccountsAction.pending, (state) => {
        state.isAccountsLoading = true;

        if (isFirstLoading) {
          state.isAccountsFirstLoading = true;
          isFirstLoading = false;
        }
      })
      .addCase(
        fetchAccountsAction.fulfilled,
        (state, action: PayloadAction<Partial<TAccountsState> | null>) => ({
          ...state,
          ...action.payload,
          isAccountsLoading: false,
          isAccountsFirstLoading: false,
        }),
      )
      .addCase(
        fetchAllAccountDescriptionsAction.fulfilled,
        (state, action: PayloadAction<Record<string, string> | null>) => {
          return {
            ...state,
            allAccountDescriptions: action.payload || {},
          };
        },
      ),
});

export const accountsActions = accountsSlice.actions;
