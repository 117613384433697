import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useQuery } from 'hooks/useQuery';
import { PATHS } from 'router/router.constants';
import { TMfaType } from 'services/mfa';
import { TChildren } from 'types/TChildren';

export type TMfaState = {
  type: TMfaType | null;
  key: string;
  tokenid: number;
};

type TSigninContextState = {
  isLoadingSignin: boolean;
  setIsLoadingSignin: Dispatch<SetStateAction<boolean>>;
  mfa: TMfaState;
  setMfa: Dispatch<SetStateAction<TMfaState>>;
  handleDone: (url?: string) => void;
};

const initialState: TSigninContextState = {
  isLoadingSignin: false,
  mfa: {
    type: null,
    key: '',
    tokenid: 0,
  },
  setIsLoadingSignin: () => {},
  setMfa: () => {},
  handleDone: () => {},
};

export const SigninContext = createContext<TSigninContextState>(initialState);

export const SigninContextProvider: FC<TChildren> = ({ children }) => {
  const query = useQuery();

  const urlNext = query.get('next');

  const [isLoadingSignin, setIsLoadingSignin] = useState(
    initialState.isLoadingSignin,
  );

  const [mfa, setMfa] = useState<TMfaState>(initialState.mfa);

  const handleDone = useCallback((url?: string) => {
    window.location.href = urlNext || url || PATHS.ACCOUNT_SUMMARY;
  }, []);

  const value = useMemo(
    () => ({
      isLoadingSignin,
      mfa,
      setIsLoadingSignin,
      setMfa,
      handleDone,
    }),
    [isLoadingSignin, setIsLoadingSignin, mfa, setMfa, handleDone],
  );

  return (
    <SigninContext.Provider value={value}>{children}</SigninContext.Provider>
  );
};
