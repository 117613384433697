import { TAccount } from 'services/accounts';
import { ACCOUNT_TYPE } from 'services/accounts/accounts.constants';

import { THeaderDepositAccountsFeatures } from './HeaderDeposit.types';

/**
 * Takes some features of the given account array to use
 * in further processing
 *
 * @param accounts
 */
export function reduceAccountsToFeatures(
  accounts: TAccount[],
): THeaderDepositAccountsFeatures {
  return accounts.reduce<THeaderDepositAccountsFeatures>(
    (reducer, account) => {
      const { accountMap: map } = reducer;
      const { value, types } = account;
      const notAggregated = !account.types.includes(ACCOUNT_TYPE.AGGREGATED);

      const clientId = value.includes('.') ? value.split('.')[0] : value;
      const newGroup = [...(map.get(clientId) || []), account];
      map.set(clientId, newGroup);

      if (
        reducer.isOnlyPartnerAccounts &&
        !types.includes(ACCOUNT_TYPE.AGGREGATED) &&
        !types.includes(ACCOUNT_TYPE.PARTNER)
      ) {
        reducer.isOnlyPartnerAccounts = false;
      }

      if (
        !reducer.firstTradingAccountId &&
        notAggregated &&
        types.includes(ACCOUNT_TYPE.TRADING)
      ) {
        reducer.firstTradingAccountId = value;
      }

      if (
        !reducer.firstPaymentAccountId &&
        notAggregated &&
        types.includes(ACCOUNT_TYPE.PAYMENT)
      ) {
        reducer.firstPaymentAccountId = value;
      }

      return reducer;
    },
    {
      accountMap: new Map(),
      isOnlyPartnerAccounts: true,
      firstPaymentAccountId: null,
      firstTradingAccountId: null,
    },
  );
}

/**
 * Returns first account ID which type is not PARTNER/AGGREGATED
 *
 * @param accounts
 */
export const getNotPartnerAccountId = (accounts: TAccount[] | undefined) => {
  return accounts?.find(
    (account) =>
      !account.types.some(
        (type) =>
          type === ACCOUNT_TYPE.AGGREGATED || type === ACCOUNT_TYPE.PARTNER,
      ),
  )?.value;
};

/**
 * Returns account ID to select by the given features
 * returned by `reduceAccountsToFeatures`
 * (functions are separated to improve performance)
 *
 * @param accountsFeatures
 * @param selectedAccount
 */
export const accountIdToSelect = (
  accountsFeatures: THeaderDepositAccountsFeatures,
  selectedAccount: TAccount | null,
): string | null => {
  const { accountMap, firstPaymentAccountId, firstTradingAccountId } =
    accountsFeatures;

  const firstPaymentOrTradingAccountId =
    firstPaymentAccountId || firstTradingAccountId;
  const isAccountPartner = selectedAccount?.types.includes(
    ACCOUNT_TYPE.PARTNER,
  );

  const isSelectedAccountAll = selectedAccount?.value === 'all';
  const isSelectedAccountAggregated = !!selectedAccount?.types.includes(
    ACCOUNT_TYPE.AGGREGATED,
  );

  if (isSelectedAccountAll) {
    if (firstPaymentOrTradingAccountId) {
      return firstPaymentOrTradingAccountId;
    }
  } else if (
    (isSelectedAccountAggregated || isAccountPartner) &&
    selectedAccount
  ) {
    const subAccounts = accountMap.get(selectedAccount.value);
    const firstNotPartnerAccountId =
      getNotPartnerAccountId(subAccounts) || firstPaymentOrTradingAccountId;

    if (firstNotPartnerAccountId) {
      return firstNotPartnerAccountId;
    }
  }

  return null;
};
