import { blockNonNativeProps, css } from 'react-ui-kit-exante';

import { TTextProps } from 'components/Text/Text.types';
import { styled } from 'theme';

export const StyledTextContainer = styled('div', {
  shouldForwardProp: blockNonNativeProps(['disabled']),
})<TTextProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      flex: 1;
      min-width: 0;
    `}
`;

export const StyledText = styled('span')<TTextProps>`
  color: ${({ theme, color }) => color && theme.color.typo[color]};
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size};

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
}
`;
