import { forwardRef, useContext } from 'react';

import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { TPartnerLandingImages } from 'services/branding';

import { PartnerTypes } from '../../../PartnerKyc.constants';

import { PARTNER_BENEFITS_TERMS } from './Benefits.constants';
import {
  StyledBenefits,
  StyledBenefitsDesc,
  StyledBenefitsFooter,
  StyledBenefitsFooterLogo,
  StyledBenefitsHeading,
  StyledBenefitsItemIcon,
  StyledBenefitsItemTerms,
  StyledBenefitsItemTermsButton,
  StyledBenefitsItemTermsElement,
  StyledBenefitsItemTermsRevshare,
  StyledBenefitsItemTermsRevsharePicture,
  StyledBenefitsItemTitle,
  StyledBenefitsList,
  StyledBenefitsListItem,
} from './Benefits.styled';
import { TPartnerBenefitsProps } from './Benefits.types';

export const Benefits = forwardRef<HTMLDivElement, TPartnerBenefitsProps>(
  function Benefits({ onChooseButtonClick }, ref) {
    const { t } = useContext(TranslationContext);
    const { branding } = useContext(BrandingContext);
    const partnerImages: TPartnerLandingImages | undefined =
      branding?.branding?.ui_settings?.partner_landing_images;

    return (
      <StyledBenefits className="Benefits" ref={ref}>
        <StyledBenefitsHeading className="BenefitsHeading">
          {t('layout__partner_landing__benefits_main_title')}
        </StyledBenefitsHeading>
        <StyledBenefitsDesc className="BenefitsDesc">
          {t('layout__partner_landing__benefits_main_desc')}
        </StyledBenefitsDesc>
        <StyledBenefitsList className="BenefitsList" role="list">
          <StyledBenefitsListItem className="BenefitsListItem">
            <StyledBenefitsItemTitle className="BenefitsItemTitle">
              <StyledBenefitsItemIcon
                className="BenefitsItemIcon"
                src={partnerImages?.benefits_cpa_icon}
                alt=""
              />
              {t('layout__partner_landing__cpa-title')}
            </StyledBenefitsItemTitle>
            <p>{t('layout__partner_landing__benefits_cpa_desc')}</p>
            <StyledBenefitsItemTerms className="BenefitsItemTerms">
              {PARTNER_BENEFITS_TERMS.map((item) => (
                <StyledBenefitsItemTermsElement
                  className="BenefitsItemTermsElement"
                  key={item.value}
                >
                  <dt>{t(item.title)}</dt>
                  <dd>{t(item.value)}</dd>
                </StyledBenefitsItemTermsElement>
              ))}
            </StyledBenefitsItemTerms>
            <StyledBenefitsItemTermsButton
              fullWidth
              onClick={() => onChooseButtonClick(PartnerTypes.CPA)}
              data-test-id="partner__benefits__choose-cpa"
            >
              {t('layout__partner_landing__benefits_choose_cpa')}
            </StyledBenefitsItemTermsButton>
          </StyledBenefitsListItem>
          <StyledBenefitsListItem className="BenefitsListItem">
            <StyledBenefitsItemTitle className="BenefitsItemTitle">
              <StyledBenefitsItemIcon
                className="BenefitsItemIcon"
                src={partnerImages?.benefits_revshare_icon}
                alt=""
              />{' '}
              {t('layout__partner_landing__revshare-title')}
            </StyledBenefitsItemTitle>
            <p>{t('layout__partner_landing__benefits_revshare_desc')}</p>
            <StyledBenefitsItemTerms className="BenefitsItemTerms">
              <StyledBenefitsItemTermsRevshare className="BenefitsItemTermsRevshare">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'layout__partner_landing__benefits_revshare_term',
                    ),
                  }}
                />

                <StyledBenefitsItemTermsRevsharePicture
                  src={partnerImages?.benefits_revshare_picture}
                  alt=""
                  className="BenefitsItemTermsRevsharePicture"
                />
              </StyledBenefitsItemTermsRevshare>
            </StyledBenefitsItemTerms>
            <StyledBenefitsItemTermsButton
              onClick={() => onChooseButtonClick(PartnerTypes.REVSHARE)}
              fullWidth
              data-test-id="partner__benefits__choose-revshare"
            >
              {t('layout__partner_landing__benefits_choose_revshare')}
            </StyledBenefitsItemTermsButton>
          </StyledBenefitsListItem>
        </StyledBenefitsList>
        <StyledBenefitsFooter className="BenefitsFooter">
          <StyledBenefitsFooterLogo
            className="BenefitsFooterLogo"
            src={partnerImages?.logo_inverted}
            alt={t('layout__partner_landing__logo_alt')}
          />
          <p>{t('layout__partner_landing__footer_1')}</p>
          <p>{t('layout__partner_landing__footer_2')}</p>
        </StyledBenefitsFooter>
      </StyledBenefits>
    );
  },
);
