import { W8SignOption } from './components/W8AffidavitForm/contexts/AffidavitFormContext/AffidavitFormContext.types';

export const w8SignOptions: W8SignOption[] = [
  {
    value: 'W-8BEN',
    label: 'W-8BEN',
  },
  {
    value: 'W-8BEN-E',
    label: 'W-8BEN-E',
  },
  {
    value: 'W-8IMY',
    label: 'W-8IMY',
  },
];
