import { useMediaQuery } from '@mui/material';
import { FC } from 'react';

import { StyledChartWrapper } from 'components/Chart';
import { ChartSkeleton } from 'components/Chart/ChartSkeleton';
import { useTheme } from 'theme';

import {
  StyledAccountSummarySkeletonBlock,
  StyledAccountSummarySkeletonColumnWrapper,
  StyledAccountSummarySkeletonWrapper,
} from './AccountSummarySkeleton.styled';
import { TSummarySkeletonProps } from './AccountSummarySkeleton.types';

export const AccountSummarySkeleton: FC<TSummarySkeletonProps> = ({
  showBalance,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const columnsCount = 2;
  const subcolumnsCount = 2;
  let rowsCount: number[];

  if (isMobile) {
    if (showBalance) {
      rowsCount = [1, 1];
    } else {
      rowsCount = [7, 7];
    }
  } else if (showBalance) {
    rowsCount = [1, 1];
  } else {
    rowsCount = [3, 5];
  }

  return (
    <>
      <StyledAccountSummarySkeletonWrapper>
        {[...Array(columnsCount)].map((_, i) => (
          <StyledAccountSummarySkeletonColumnWrapper key={`colwrap_${i}`}>
            {[...Array(subcolumnsCount)].map((__, j) => (
              <div key={`col_${j}`}>
                {[...Array(rowsCount[j])].map((___, k) => (
                  <StyledAccountSummarySkeletonBlock key={`block_${k}`} />
                ))}
              </div>
            ))}
          </StyledAccountSummarySkeletonColumnWrapper>
        ))}
      </StyledAccountSummarySkeletonWrapper>
      {showBalance && (
        <StyledChartWrapper className="ChartWrapper">
          <ChartSkeleton />
        </StyledChartWrapper>
      )}
    </>
  );
};
