import { startOfMonth } from 'date-fns';
import { FC, useContext } from 'react';
import { getBrowserDateFormat, getBrowserLocale } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { Dates } from 'helpers/dates';
import { StyledCellWarning } from 'pages/Subscriptions/Subscriptions.styled';
import { TSubscriptionDateCellProps } from 'pages/Subscriptions/components/SubscriptionDateCell/SubscriptionDateCell.types';

export const SubscriptionDateCell: FC<TSubscriptionDateCellProps> = ({
  feedPermission,
}) => {
  const { t } = useContext(TranslationContext);

  const browserFormat = getBrowserDateFormat(false, true, getBrowserLocale);

  if (feedPermission?.userFeedPermission?.endDate) {
    const date = Dates.format(
      new Date(feedPermission?.userFeedPermission?.endDate),
      false,
      browserFormat,
    );
    const text = `${t('layout__settings__subscriptions_expired')} ${date}`;
    return (
      <StyledCellWarning title={text} className="DateCell">
        {text}
      </StyledCellWarning>
    );
  }

  if (feedPermission?.userFeedPermission?.subscribed) {
    const date = Dates.format(
      startOfMonth(new Date().setMonth(new Date().getMonth() + 1)),
      false,
      browserFormat,
    );
    const text = `${t('layout__settings__subscriptions_renewed')} ${date}`;
    return (
      <span className="DateCell" title={text}>
        {text}
      </span>
    );
  }

  return null;
};
