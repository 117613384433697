import { css, IconButton as MUIIconButton } from '@mui/material';
import {
  blockNonNativeProps,
  Button,
  Panel,
  ToggleButtonGroup,
} from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TProgressBarProps } from './SetupItem.types';

export const StyledSetupItem = styled(Panel)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 24px;
  column-gap: 8px;
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    padding: 24px;
    grid-template-columns: minmax(120px, 1fr) 10% 10% 48px minmax(386px, 1fr);
    gap: 16px;
    width: 100%;
  }
`;

export const StyledSetupItemInputWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.down('lr')} {
    grid-column: 1 / -1;
  }
`;

export const StyledSetupItemLinkEmailSwitch = styled(ToggleButtonGroup)`
  flex-wrap: nowrap !important;
` as typeof ToggleButtonGroup;

export const StyledSetupItemLinkForm = styled('div')`
  margin-top: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0;
    display: flex;
    gap: 16px;
    flex-grow: 1;
  }
`;

export const StyledSetupItemLinkButton = styled(Button)`
  margin-top: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0;
    max-width: 96px;
  }
`;

export const SetupItemButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.up('lr')} {
    max-width: 48px;

    .MuiButton-startIcon {
      margin: 0;
    }

    .SetupItemButtonText {
      position: absolute !important;

      width: 1px;
      height: 1px;
      overflow: hidden;

      white-space: nowrap;

      clip: rect(1px, 1px, 1px, 1px);
    }
  }
`;

export const StyledSetupStatusApproved = styled('div')`
  padding: 11px 12px;
  height: 48px;
  background: ${({ theme }) => theme.color.bg.source};
  color: ${({ theme }) => theme.color.typo.action};
  border-radius: 4px;
  text-align: center;

  svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  span {
    margin-left: 10px;
    vertical-align: middle;

    ${({ theme }) => theme.breakpoints.up('md')} {
      position: absolute !important;

      width: 1px;
      height: 1px;
      overflow: hidden;

      white-space: nowrap;

      clip: rect(1px, 1px, 1px, 1px);
    }
  }
`;

export const StyledSetupStatusRejected = styled('div')`
  padding: 11px 12px;
  height: 48px;
  background: ${({ theme }) => theme.color.bg.radical};
  color: ${({ theme }) => theme.color.typo.radical};
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
  line-height: 24px;

  svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  span {
    margin-left: 10px;
    vertical-align: middle;

    ${({ theme }) => theme.breakpoints.up('md')} {
      position: absolute !important;

      width: 1px;
      height: 1px;
      overflow: hidden;

      white-space: nowrap;

      clip: rect(1px, 1px, 1px, 1px);
    }
  }
`;

export const StyledSetupItemInputHorizontalWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    gap: 16px;
    align-items: start;
  }
`;

export const SetupItemProgressBar = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isSuccess']),
})<TProgressBarProps>`
  width: 100%;
  height: 8px;
  ${({ theme, isSuccess }) => {
    const { success, warning } = theme.color.notifications;

    if (isSuccess) {
      return css`
        background: ${success};
      `;
    }
    return css`
      background: ${warning};
    `;
  }}
`;

export const StyledSetupItemUpload = styled(MUIIconButton)`
  padding: 11px 12px;
  height: 48px;
  width: 100%;

  background: ${({ theme }) => theme.color.bg.secondary};
  color: ${({ theme }) => theme.color.typo.secondary};
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
  line-height: 24px;

  &:hover {
    background: ${({ theme }) => theme.color.bg.secondary};
  }

  span {
    margin-left: 8px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      position: absolute !important;

      width: 1px;
      height: 1px;
      overflow: hidden;

      white-space: nowrap;

      clip: rect(1px, 1px, 1px, 1px);
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 48px;
  }

  svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
` as typeof MUIIconButton;
