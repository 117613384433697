import { FC } from 'react';

import { DEFAULT_MAX_ACCOUNTS_LENGTH } from 'constants/common';

import {
  StyledAccountSelectionCarouselSkeleton,
  StyledAccountSelectionCarouselSkeletonWrapper,
} from './AccountSelectionCarouselSkeleton.styled';

export const AccountSelectionCarouselSkeleton: FC = () => {
  return (
    <StyledAccountSelectionCarouselSkeletonWrapper>
      {[...Array(DEFAULT_MAX_ACCOUNTS_LENGTH)].map((_, i) => (
        <StyledAccountSelectionCarouselSkeleton key={i} />
      ))}
    </StyledAccountSelectionCarouselSkeletonWrapper>
  );
};
