import { styled } from 'theme';

export const StyledGenericTransferFormBlock = styled('div')`
  flex: 1;
  padding: 0 24px;
  margin-bottom: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 24px;
    margin-bottom: 0;

    border-style: solid;
    border-color: ${({ theme }) => theme.color.line.primary};
    border-width: 0 1px 1px 0;

    &:last-child {
      border-width: 0 0 1px 0;
    }
  }
`;
