import { FolderIcon, ResidencyIcon, ValidIdIcon } from 'assets/icons';
import { ACCEPT_FILE_FORMATS } from 'pages/Settings/pages/Documents/Documents.consts';

import { TDocUploadGroupProps } from './UploadGroup.types';
import { OtherDocumentsTooltipContent } from './components/OtherDocumentTooltipContent';
import { ProofOfResidenceTooltipContent } from './components/ProofOfResidenceTooltipContent';
import { ValidIdTooltipContent } from './components/ValidIdTooltipContent';

export enum DocumentGroup {
  ValidId = 'valid_id',
  ProofOfResidence = 'proof_of_residence',
  Other = 'other_document',
}

export const DOC_UPLOAD_GROUP_PROPS = {
  [DocumentGroup.ValidId]: {
    tooltipContent: ValidIdTooltipContent,
    icon: <ValidIdIcon />,
  },
  [DocumentGroup.ProofOfResidence]: {
    tooltipContent: ProofOfResidenceTooltipContent,
    icon: <ResidencyIcon />,
  },
  [DocumentGroup.Other]: {
    tooltipContent: OtherDocumentsTooltipContent,
    icon: <FolderIcon />,
  },
} as Record<string, TDocUploadGroupProps>;

export const ACCEPT_FILE_TYPES = ACCEPT_FILE_FORMATS.map(
  (fmt) => `.${fmt}`,
).join(', ');
