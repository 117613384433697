export const useMenuOpened = () => {
  const onToggleMenu = (isOpened: boolean) => {
    const el = document.body;
    const className = 'MenuOpened';
    if (isOpened) {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  };

  return { onToggleMenu };
};
