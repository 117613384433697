import { ClickAwayListener } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';
import { formatCurrency } from 'helpers/formatters';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';

import { TPartnerHeaderProps } from '../../PartnerHeader.types';
import { PartnerHeaderInfoBlock } from '../PartnerHeaderInfoBlock';

import {
  StyledPartnerHeaderCopyBlock,
  StyledPartnerHeaderCopyBlockContent,
  StyledPartnerHeaderCopyBlockCopyText,
  StyledPartnerHeaderCopyBlockCopyTextWrapper,
  StyledPartnerHeaderCopyBlockLink,
  StyledPartnerHeaderCopyIcon,
  StyledPartnerHeaderCopyIconWrapper,
  StyledPartnerHeaderDefaultLinkTooltip,
  StyledPartnerHeaderInfo,
  StyledPartnerHeaderWrapper,
} from './PartnerHeaderDesktop.styled';

export const PartnerHeaderDesktop: FC<TPartnerHeaderProps> = ({
  balance,
  paidOut,
  hasInfo,
  defaultLink,
  defaultLinkTextKeys,
}) => {
  const { t } = useContext(TranslationContext);

  const currencyFormatter = useCurrencyFormatter('EUR');
  const [, copy] = useCopyToClipboard();

  const [copiedTooltipOpen, setCopiedTooltipOpen] = useState(false);
  const [defaultLinkTooltipOpen, setDefaultLinkTooltipOpen] = useState(false);
  const onDefaultLinkClick = () => {
    setCopiedTooltipOpen(true);
    copy(defaultLink);
  };

  return (
    <StyledPartnerHeaderWrapper className="PartnerHeaderWrapper">
      {defaultLink && (
        <Tooltip
          title={t('generic__copy_link__copied')}
          disableFocusListener
          open={copiedTooltipOpen}
          placement="top"
          onClose={() => setCopiedTooltipOpen(false)}
        >
          <StyledPartnerHeaderCopyBlock className="PartnerCopyBlock">
            <StyledPartnerHeaderCopyBlockContent
              className="PartnerHeaderCopyBlockContent"
              onClick={onDefaultLinkClick}
            >
              <StyledPartnerHeaderCopyIconWrapper className="PartnerCopyIconWrapper">
                <StyledPartnerHeaderCopyIcon
                  iconName="CopyIcon"
                  iconSize={22}
                  iconColor="inverse"
                />
              </StyledPartnerHeaderCopyIconWrapper>
              <StyledPartnerHeaderCopyBlockCopyTextWrapper className="PartnerHeaderCopyBlockCopyTextWrapper">
                <StyledPartnerHeaderCopyBlockCopyText className="PartnerHeaderCopyBlockCopyText">
                  {t(defaultLinkTextKeys.type)}
                </StyledPartnerHeaderCopyBlockCopyText>
                <br />
                <StyledPartnerHeaderCopyBlockLink className="PartnerCopyBlockLink">
                  {defaultLink}
                </StyledPartnerHeaderCopyBlockLink>
              </StyledPartnerHeaderCopyBlockCopyTextWrapper>
            </StyledPartnerHeaderCopyBlockContent>
            {defaultLinkTextKeys.tooltip && (
              <ClickAwayListener
                onClickAway={() => setDefaultLinkTooltipOpen(false)}
              >
                <StyledPartnerHeaderDefaultLinkTooltip className="PartnerHeaderDefaultLinkTooltip">
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    title={t(defaultLinkTextKeys.tooltip).replace(/\\n/g, '\n')}
                    onClose={() => setDefaultLinkTooltipOpen(false)}
                    open={defaultLinkTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <IconButton
                      iconName="InfoIcon"
                      iconSize={16}
                      iconColor="secondary"
                      title={t(defaultLinkTextKeys.label)}
                      onClick={() => setDefaultLinkTooltipOpen(true)}
                    />
                  </Tooltip>
                </StyledPartnerHeaderDefaultLinkTooltip>
              </ClickAwayListener>
            )}
          </StyledPartnerHeaderCopyBlock>
        </Tooltip>
      )}
      {hasInfo && (
        <StyledPartnerHeaderInfo className="PartnerHeaderInfo">
          <PartnerHeaderInfoBlock
            value={formatCurrency(currencyFormatter, paidOut)}
            label={t('layout__partners__partner_paid_out')}
          />
          <PartnerHeaderInfoBlock
            value={formatCurrency(currencyFormatter, balance)}
            label={t('generic__balance')}
          />
        </StyledPartnerHeaderInfo>
      )}
    </StyledPartnerHeaderWrapper>
  );
};
