import { FC } from 'react';

import { SigninWithContext } from './SigninWithContext';
import { SigninContextProvider } from './contexts';

export const Signin: FC = () => {
  return (
    <SigninContextProvider>
      <SigninWithContext />
    </SigninContextProvider>
  );
};
