import { useMemo } from 'react';

import { StyledSettings } from './Settings.styled';
import { TSettingsProps } from './Settings.types';
import { SettingsItem, SettingsLoading } from './components';

export const Settings = ({
  isLoading,
  onChange,
  groups,
  primaryEmail,
}: TSettingsProps) => {
  const options = useMemo(() => {
    const raw = groups.reduce<string[]>(
      (result, group) => [...result, ...(group.values.email || [])],
      [],
    );

    return Array.from(new Set(raw));
  }, [groups]);

  if (isLoading) {
    return <SettingsLoading />;
  }

  return (
    <StyledSettings className="Settings">
      {groups.map(
        ({ name, title, is_editable, values, available_channels }) => (
          <SettingsItem
            key={name}
            allowed={available_channels}
            disabled={!is_editable}
            emails={values.email || []}
            internalCA={values.internalCA}
            name={name}
            sms={values.sms}
            terminal={values.terminal}
            options={options}
            title={title}
            primaryEmail={primaryEmail}
            onChange={onChange}
          />
        ),
      )}
    </StyledSettings>
  );
};
