import { Autocomplete, blockNonNativeProps, css } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAccountsSearchField = styled(Autocomplete, {
  shouldForwardProp: blockNonNativeProps(['hidden', 'withDescriptions']),
})`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
` as typeof Autocomplete;
