import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Checkbox,
  DeleteStatus,
  FileUpload,
  IconButton,
  InfoIcon,
  Input,
  ISelectOption,
  Select,
  Tooltip,
  TSetFileDeleteStatusFn,
  TSetFileSuccessFn,
} from 'react-ui-kit-exante';

import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext';
import { UserDetailsContext } from 'contexts/UserDetailsContext';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';

import { getFieldTooltipHint } from '../../../../Transfers.helpers';
import { NoteText } from '../../../../components/NoteText/NoteText';
import { useTransfers } from '../../../../hooks';
import { FIELDS_BENEFICIARY_ADDRESS } from '../../../../hooks/useTransferFormsConfig/useTransferFormsConfig.consts';
import { acceptsFiles } from '../../../Securities/Securities.consts';
import { StyledHint } from '../../../shared.styled';
import { FundsContext } from '../../context';
import { TBeneficiaryField } from '../../context/hooks';
import { useToBankAccountData } from '../../hooks/useToBankAccountData';
import { ToBankAccountFields } from '../../types';

import {
  StyledAddressFields,
  StyledCheckboxWrapper,
  StyledCommentHeaderWrapper,
  StyledCommentTooltipList,
  StyledDetailPurpose,
  StyledDetailPurposeContainer,
  StyledDetailPurposeTitle,
  StyledExpandedFields,
  StyledFileUploadContainer,
  StyledFileUploadHeader,
  StyledHandlePersonalIbanContainer,
  StyledHandlePersonalIbanDescription,
  StyledTargetContainer,
  StyledToBankAccountContainer,
} from './ToBankAccount.styled';

export const ToBankAccount = () => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useContext(TranslationContext);
  const { userDetails } = useContext(UserDetailsContext);
  const { transfersState } = useTransfers();

  const {
    baseName,
    toBankAccountDispatch,
    toBankAccountState: {
      fields,
      errors,
      mode,
      requestingTransactionState,
      type,
    },
  } = useContext(FundsContext);

  const {
    isThirdPartyEnabled,
    currencies,
    isPersonalIbanVisible,
    requiredFields,
    hiddenFields,
    isWiredToSelf,
    isSelfEnabled,
    maxDescriptionLength,
    isPersonalIbanInfoTextVisible,
    isAccountPayment,
  } = useToBankAccountData();

  const locked = mode === 'CONFIRM';

  const shownBeneficiaryFields = useMemo(
    () =>
      FIELDS_BENEFICIARY_ADDRESS.filter((key) =>
        requiredFields.has(key as keyof ToBankAccountFields),
      ),
    [requiredFields],
  );

  const isBeneficiaryLocked = useMemo(
    () =>
      Object.fromEntries(
        shownBeneficiaryFields.map((key) => [
          key,
          !isAccountPayment && !!transfersState?.init_wire_values?.[key],
        ]),
      ),
    [shownBeneficiaryFields, transfersState, type],
  );

  const isTopSectionShown = shownBeneficiaryFields.length > 0;

  const isBottomSectionShown = useMemo(() => {
    return (
      [
        'correspondent_account',
        'bank_name',
        'bank_country',
        'bank_address',
        'correspondent_swift',
      ].filter((key) => requiredFields.has(key as keyof ToBankAccountFields))
        .length > 0
    );
  }, [requiredFields]);

  const { current: countries } = useRef<ISelectOption[]>(
    userDetails?.refs?.countries?.map((country) => {
      return {
        label: country,
        value: country,
      };
    }) || [],
  );

  const handleUsePersonalIBANChange = () => {
    toBankAccountDispatch({
      type: 'SET_USE_PERSONAL_IBAN',
      payload: !fields.use_personal_iban,
    });
  };

  const handleNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;

    const isBeneficiaryChanged = shownBeneficiaryFields.some(
      (key) => transfersState.init_wire_values?.[key] !== fields[key],
    );

    toBankAccountDispatch({
      type: 'SET_NAME',
      payload: {
        baseName,
        preventChecking: !isThirdPartyEnabled,
        name: value,
        isBeneficiaryChanged,
      },
    });
  };

  const handleFormInputChange =
    (key: keyof ToBankAccountFields) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;

      toBankAccountDispatch({ type: 'SET_FIELD', payload: { key, value } });
    };

  const handleBeneficiaryFieldChange =
    (key: TBeneficiaryField) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;

      const initValue =
        transfersState.init_wire_values && transfersState.init_wire_values[key];

      const isNameChanged = baseName !== fields.name;

      const preventChecking = !isThirdPartyEnabled;

      toBankAccountDispatch({
        type: 'SET_BENEFICIARY_FIELD',
        payload: {
          key,
          value,
          preventChecking,
          initValue,
          isNameChanged,
        },
      });
    };

  const handleFileAppend = (payload: File[], onSuccess: TSetFileSuccessFn) => {
    toBankAccountDispatch({
      type: 'SET_FILES',
      payload,
    });

    payload.forEach((file) =>
      onSuccess({
        name: file.name,
        id: file.name,
        removable: true,
      }),
    );
  };

  const handleFileDelete = (
    payload: string,
    setDeleted: TSetFileDeleteStatusFn,
  ) => {
    setDeleted({ id: payload, status: DeleteStatus.SUCCESS });

    toBankAccountDispatch({
      type: 'DELETE_FILE',
      payload,
    });
  };

  const getFieldHint = (fieldName: string, onlyText?: boolean) => {
    return getFieldTooltipHint(
      fieldName,
      isWiredToSelf ? WITHDRAWAL_TYPE.WIRE : WITHDRAWAL_TYPE.WIRE_THIRD_PARTY,
      transfersState?.branding?.wr_fields_settings,
      t,
      onlyText,
    );
  };

  useEffect(() => {
    if (currencies.length) {
      toBankAccountDispatch({ type: 'SET_CURRENCY', payload: currencies[0] });
    }
  }, [currencies]);

  useEffect(() => {
    toBankAccountDispatch({
      type: 'SET_USE_PERSONAL_IBAN',
      payload: isPersonalIbanVisible,
    });
  }, [isPersonalIbanVisible]);

  return (
    <StyledToBankAccountContainer className="ClientAccountContainer">
      {isPersonalIbanVisible && (
        <StyledHandlePersonalIbanContainer className="HandlePersonalIbanContainer">
          <StyledCheckboxWrapper className="CheckboxWrapper">
            <Checkbox
              disabled={isAccountPayment || locked}
              required={requiredFields.has('use_personal_iban')}
              checked={fields.use_personal_iban}
              label={t('layout__transfers__personal_iban_transfer')}
              onChange={handleUsePersonalIBANChange}
            />
            <Tooltip title={t('layout__requisites__personal_iban__tooltip')}>
              <InfoIcon width={18} height={18} />
            </Tooltip>
          </StyledCheckboxWrapper>

          {isPersonalIbanInfoTextVisible && (
            <StyledHandlePersonalIbanDescription className="HandlePersonalIbanDescription">
              {isAccountPayment
                ? t('layout__fields__using_personal_iban__payment_tooltip')
                : t('layout__fields__using_personal_iban__trade_tooltip')}
            </StyledHandlePersonalIbanDescription>
          )}
        </StyledHandlePersonalIbanContainer>
      )}

      {isSelfEnabled && (
        <Text color="secondary" size="13px">
          {t('layout__fields__beneficiary_name_description')}
        </Text>
      )}

      <StyledTargetContainer className="TargetContainer">
        <Input
          fullWidth
          required
          placeholder={t('layout__fields__beneficiary_name')}
          name="name"
          disabled={locked}
          key="name"
          value={fields.name}
          message={errors.name}
          error={Boolean(errors.name)}
          onChange={handleNameChange}
          iconRight={
            <Tooltip
              placement="top"
              title={t('layout__fields__beneficiary_name_must_always_be')}
            >
              <InfoIcon />
            </Tooltip>
          }
        />

        <Input
          fullWidth
          required
          name="iban"
          key="iban"
          value={fields.iban}
          message={errors.iban}
          disabled={locked}
          error={Boolean(errors.iban)}
          onChange={handleFormInputChange('iban')}
          placeholder={t('layout__fields__to_bankaccount_iban')}
          iconRight={getFieldHint('iban')}
        />
      </StyledTargetContainer>

      <StyledTargetContainer className="TargetContainer">
        {requiredFields.has('swift') && (
          <Input
            fullWidth
            required
            maxLength={11}
            name="swift"
            key="swift"
            disabled={locked}
            value={fields.swift}
            message={errors.swift}
            error={Boolean(errors.swift)}
            onChange={handleFormInputChange('swift')}
            placeholder={t('generic__swift')}
            iconRight={getFieldHint('swift')}
          />
        )}
      </StyledTargetContainer>

      {isTopSectionShown && (
        <StyledAddressFields className="AddressFields">
          {requiredFields.has('beneficiary_country') && (
            <Select
              fullWidth
              required
              label={t('layout__fields__transfers__beneficiary_country')}
              name="beneficiary_country"
              disabled={locked || isBeneficiaryLocked.beneficiary_country}
              onChange={handleBeneficiaryFieldChange('beneficiary_country')}
              options={countries}
              key="beneficiary_country"
              value={fields.beneficiary_country as string}
              message={errors.beneficiary_country}
              error={Boolean(errors.beneficiary_country)}
            />
          )}

          {requiredFields.has('city') && (
            <Input
              fullWidth
              required
              disabled={locked || isBeneficiaryLocked.city}
              name="city"
              key="city"
              value={fields.city}
              message={errors.city}
              error={Boolean(errors.city)}
              onChange={handleBeneficiaryFieldChange('city')}
              placeholder={t('layout__fields__city')}
              iconRight={getFieldHint('city')}
            />
          )}

          {requiredFields.has('state') && fields.state && (
            <Input
              fullWidth
              required
              disabled={locked || isBeneficiaryLocked.state}
              name="state"
              key="state"
              value={fields.state}
              message={errors.state}
              error={Boolean(errors.state)}
              onChange={handleFormInputChange('state')}
              placeholder={t('generic__beneficiary_state')}
              iconRight={getFieldHint('state')}
            />
          )}

          {requiredFields.has('short_address') && (
            <Input
              fullWidth
              required
              disabled={locked || isBeneficiaryLocked.short_address}
              name="short_address"
              key="short_address"
              value={fields.short_address}
              message={errors.short_address}
              error={Boolean(errors.short_address)}
              onChange={handleBeneficiaryFieldChange('short_address')}
              placeholder={t('beneficiary_address')}
              iconRight={getFieldHint('short_address')}
            />
          )}

          {requiredFields.has('postal_code') && (
            <Input
              fullWidth
              required
              disabled={locked || isBeneficiaryLocked.postal_code}
              name="postal_code"
              key="postal_code"
              value={fields.postal_code}
              message={errors.postal_code}
              error={Boolean(errors.postal_code)}
              onChange={handleBeneficiaryFieldChange('postal_code')}
              placeholder={t('layout__fields__beneficiary_postal_code')}
              iconRight={getFieldHint('postal_code')}
            />
          )}
        </StyledAddressFields>
      )}

      {requiredFields.has('comment') && (
        <StyledDetailPurposeContainer className="DetailPurposeContainer">
          <StyledCommentHeaderWrapper className="CommentHeaderWrapper">
            <StyledDetailPurposeTitle className="DetailPurposeTitle">
              {t('layout__subaccount_transfer__external_reference_title')}
            </StyledDetailPurposeTitle>
            <Tooltip
              title={
                <div>
                  <span>
                    {t(
                      'Please specify the purpose and details of the transaction:',
                    )}
                  </span>
                  <StyledCommentTooltipList className="CommentTooltipList">
                    <li>{t('layout__comment_own_funds__tooltip')}</li>
                  </StyledCommentTooltipList>
                </div>
              }
            >
              <InfoIcon width={18} height={18} />
            </Tooltip>
          </StyledCommentHeaderWrapper>
          <StyledDetailPurpose
            maxLength={maxDescriptionLength}
            disabled={
              (isWiredToSelf &&
                Boolean(transfersState.init_wire_values?.comment)) ||
              locked
            }
            name="comment"
            key="comment"
            value={fields.comment as string}
            onChange={handleFormInputChange('comment')}
            className="DetailPurpose"
          />
        </StyledDetailPurposeContainer>
      )}

      {isBottomSectionShown && (
        <StyledAddressFields className="AddressFields">
          {requiredFields.has('correspondent_account') && (
            <Input
              fullWidth
              required
              disabled={locked}
              name="correspondent_account"
              key="correspondent_account"
              value={fields.correspondent_account}
              message={errors.correspondent_account}
              error={Boolean(errors.correspondent_account)}
              onChange={handleFormInputChange('correspondent_account')}
              placeholder={t(
                'layout__fields__beneficiary_correspondent_bank_account',
              )}
              iconRight={getFieldHint('correspondent_account')}
            />
          )}

          {requiredFields.has('bank_name') && (
            <Input
              fullWidth
              required
              disabled={locked}
              name="bank_name"
              key="bank_name"
              value={fields.bank_name}
              message={errors.bank_name}
              error={Boolean(errors.bank_name)}
              onChange={handleFormInputChange('bank_name')}
              placeholder={t('bank_name')}
              iconRight={getFieldHint('bank_name')}
            />
          )}

          {requiredFields.has('bank_country') && (
            <Select
              required
              fullWidth
              label={t('layout__fields__bank_country')}
              disabled={locked}
              onChange={handleFormInputChange('bank_country')}
              options={countries}
              name="bank_country"
              key="bank_country"
              value={fields.bank_country as string}
              message={errors.bank_country}
              error={Boolean(errors.bank_country)}
            />
          )}

          {requiredFields.has('bank_address') && (
            <Input
              fullWidth
              required
              disabled={locked}
              name="bank_address"
              key="bank_address"
              value={fields.bank_address}
              message={errors.bank_address}
              error={Boolean(errors.bank_address)}
              onChange={handleFormInputChange('bank_address')}
              placeholder={t('bank_address')}
              iconRight={getFieldHint('bank_address')}
            />
          )}

          {requiredFields.has('correspondent_swift') && (
            <Input
              fullWidth
              required
              disabled={locked}
              name="correspondent_swift"
              key="correspondent_swift"
              value={fields.correspondent_swift}
              message={errors.correspondent_swift}
              error={Boolean(errors.correspondent_swift)}
              onChange={handleFormInputChange('correspondent_swift')}
              placeholder={t('generic__correspondent_swift')}
              iconRight={getFieldHint('correspondent_swift')}
            />
          )}
        </StyledAddressFields>
      )}

      <div>
        <IconButton
          iconColor="secondary"
          onClick={() => setExpanded(!expanded)}
          iconName={expanded ? 'MinusIcon' : 'AddIcon'}
          label={t('layout__subaccount_transfer__external_optional')}
        />
      </div>

      {expanded && (
        <StyledExpandedFields className="ExpandedFields">
          <StyledAddressFields className="AddressFields">
            {!requiredFields.has('beneficiary_country') &&
              !hiddenFields.has('beneficiary_country') && (
                <Select
                  fullWidth
                  label={t('layout__fields__transfers__beneficiary_country')}
                  name="beneficiary_country"
                  disabled={locked || isBeneficiaryLocked.beneficiary_country}
                  onChange={handleFormInputChange('beneficiary_country')}
                  options={countries}
                  key="beneficiary_country"
                  value={fields.beneficiary_country as string}
                  message={errors.beneficiary_country}
                  error={Boolean(errors.beneficiary_country)}
                />
              )}

            {!requiredFields.has('city') && !hiddenFields.has('city') && (
              <Input
                fullWidth
                disabled={locked || isBeneficiaryLocked.city}
                name="city"
                key="city"
                value={fields.city}
                message={errors.city}
                error={Boolean(errors.city)}
                onChange={handleFormInputChange('city')}
                placeholder={t('layout__fields__city')}
                iconRight={getFieldHint('city')}
              />
            )}

            {!requiredFields.has('short_address') &&
              !hiddenFields.has('short_address') && (
                <Input
                  fullWidth
                  disabled={locked || isBeneficiaryLocked.short_address}
                  name="short_address"
                  key="short_address"
                  value={fields.short_address}
                  message={errors.short_address}
                  error={Boolean(errors.short_address)}
                  onChange={handleFormInputChange('short_address')}
                  placeholder={t('beneficiary_address')}
                  iconRight={getFieldHint('short_address')}
                />
              )}

            {!requiredFields.has('postal_code') &&
              !hiddenFields.has('postal_code') && (
                <Input
                  fullWidth
                  disabled={locked || isBeneficiaryLocked.postal_code}
                  name="postal_code"
                  key="postal_code"
                  value={fields.postal_code}
                  message={errors.postal_code}
                  error={Boolean(errors.postal_code)}
                  onChange={handleFormInputChange('postal_code')}
                  placeholder={t('layout__fields__beneficiary_postal_code')}
                  iconRight={getFieldHint('postal_code')}
                />
              )}
          </StyledAddressFields>

          {!requiredFields.has('comment') && !hiddenFields.has('comment') && (
            <StyledDetailPurposeContainer className="DetailPurposeContainer">
              <StyledCommentHeaderWrapper className="CommentHeaderWrapper">
                <StyledDetailPurposeTitle className="DetailPurposeTitle">
                  {t('layout__subaccount_transfer__external_reference_title')}
                </StyledDetailPurposeTitle>
                <Tooltip
                  title={
                    <div>
                      <span>
                        {t(
                          'Please specify the purpose and details of the transaction:',
                        )}
                      </span>
                      <StyledCommentTooltipList className="CommentTooltipList">
                        <li>{t('layout__comment_own_funds__tooltip')}</li>
                      </StyledCommentTooltipList>
                    </div>
                  }
                >
                  <InfoIcon width={18} height={18} />
                </Tooltip>
              </StyledCommentHeaderWrapper>
              <StyledDetailPurpose
                maxLength={maxDescriptionLength}
                disabled={locked}
                name="comment"
                key="comment"
                value={fields.comment as string}
                onChange={handleFormInputChange('comment')}
                className="DetailPurpose"
              />
            </StyledDetailPurposeContainer>
          )}

          <StyledAddressFields className="AddressFields">
            {!requiredFields.has('correspondent_account') &&
              !hiddenFields.has('correspondent_account') && (
                <Input
                  fullWidth
                  disabled={locked}
                  name="correspondent_account"
                  key="correspondent_account"
                  value={fields.correspondent_account}
                  message={errors.correspondent_account}
                  error={Boolean(errors.correspondent_account)}
                  onChange={handleFormInputChange('correspondent_account')}
                  placeholder={t(
                    'layout__fields__beneficiary_correspondent_bank_account',
                  )}
                  iconRight={getFieldHint('correspondent_account')}
                />
              )}

            {!requiredFields.has('bank_name') &&
              !hiddenFields.has('bank_name') && (
                <Input
                  fullWidth
                  name="bank_name"
                  key="bank_name"
                  disabled={locked}
                  value={fields.bank_name}
                  message={errors.bank_name}
                  error={Boolean(errors.bank_name)}
                  onChange={handleFormInputChange('bank_name')}
                  placeholder={t('bank_name')}
                  iconRight={getFieldHint('bank_name')}
                />
              )}

            {!requiredFields.has('bank_country') &&
              !hiddenFields.has('bank_country') && (
                <Select
                  fullWidth
                  label={t('layout__fields__bank_country')}
                  disabled={locked}
                  onChange={handleFormInputChange('bank_country')}
                  options={countries}
                  name="bank_country"
                  key="bank_country"
                  value={fields.bank_country as string}
                  message={errors.bank_country}
                  error={Boolean(errors.bank_country)}
                />
              )}

            {!requiredFields.has('bank_address') &&
              !hiddenFields.has('bank_address') && (
                <Input
                  fullWidth
                  name="bank_address"
                  key="bank_address"
                  disabled={locked}
                  value={fields.bank_address}
                  message={errors.bank_address}
                  error={Boolean(errors.bank_address)}
                  onChange={handleFormInputChange('bank_address')}
                  placeholder={t('bank_address')}
                  iconRight={getFieldHint('bank_address')}
                />
              )}

            {!requiredFields.has('correspondent_swift') &&
              !hiddenFields.has('correspondent_swift') && (
                <Input
                  fullWidth
                  name="correspondent_swift"
                  key="correspondent_swift"
                  disabled={locked}
                  value={fields.correspondent_swift}
                  message={errors.correspondent_swift}
                  error={Boolean(errors.correspondent_swift)}
                  onChange={handleFormInputChange('correspondent_swift')}
                  placeholder={t('generic__correspondent_swift')}
                  iconRight={getFieldHint('correspondent_swift')}
                />
              )}

            {!requiredFields.has('swift') && !hiddenFields.has('swift') && (
              <Input
                fullWidth
                maxLength={11}
                name="swift"
                key="swift"
                disabled={locked}
                value={fields.swift}
                message={errors.swift}
                error={Boolean(errors.swift)}
                onChange={handleFormInputChange('swift')}
                placeholder={t('generic__swift')}
                iconRight={getFieldHint('swift')}
              />
            )}
          </StyledAddressFields>

          <StyledFileUploadContainer className="FileUploadContainer">
            {!requestingTransactionState.pending &&
              !requiredFields.has('file') &&
              !hiddenFields.has('file') && (
                <>
                  <StyledFileUploadHeader className="FileUploadHeader">
                    {t('layout__fields__accompanying_documents')}
                  </StyledFileUploadHeader>
                  <FileUpload
                    disabled={locked}
                    onDelete={handleFileDelete}
                    onUpload={handleFileAppend}
                    translator={t}
                    accept={acceptsFiles}
                    dndLabel={t('layout__fields__drag_n_drop')}
                    buttonLabel={t('generic__action__upload')}
                  />
                  {getFieldHint('file', true) && (
                    <StyledHint className="Hint" withMarginTop>
                      <InfoIcon className="InfoIcon" />
                      <Text size="13px" color="secondary">
                        {getFieldHint('file', true)}
                      </Text>
                    </StyledHint>
                  )}
                </>
              )}
            {requestingTransactionState.pending && <FileUpload loading />}
          </StyledFileUploadContainer>
        </StyledExpandedFields>
      )}
      <NoteText
        type={
          isWiredToSelf
            ? WITHDRAWAL_TYPE.WIRE
            : WITHDRAWAL_TYPE.WIRE_THIRD_PARTY
        }
      />
    </StyledToBankAccountContainer>
  );
};
