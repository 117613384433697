import { styled } from 'theme';

export const StyledPartnerLinksShortUtmWrapper = styled('div')`
  display: flex;
  gap: 8px;

  color: ${({ theme }) => theme.color.typo.action};

  & svg {
    color: ${({ theme }) => theme.color.typo.action} !important;
  }
`;
