import { useMediaQuery } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Checkbox,
  IconButton,
  Modal,
  ModalProps,
  useModal,
} from 'react-ui-kit-exante';

import { DocumentViewer } from 'components/Footer/DocumentViewer';
import { TranslationContext } from 'contexts/TranslationContext';
import { legalDocsService, TDocument } from 'services/legal-docs';
import { partnerService } from 'services/partner';
import { PartnerKycStatuses } from 'services/partner/partner.constants';
import { theme } from 'theme';

import { PARTNER_PAYMENTS_URL, PartnerTypes } from '../../PartnerKyc.constants';

import {
  PARTNER_PROCEED_TEXTS,
  PartnerDocTypes,
  PartnerDocUrls,
} from './PartnerProceed.constants';
import { checkDocType } from './PartnerProceed.helpers';
import {
  StyledPartnerProceed,
  StyledPartnerProceedButton,
  StyledPartnerProceedDesc,
  StyledPartnerProceedDoc,
  StyledPartnerProceedForm,
  StyledPartnerProceedHeading,
  StyledPartnerProceedLabel,
  StyledPartnerProceedLabelLink,
  StyledPartnerProceedNote,
} from './PartnerProceed.styled';
import { TPartnerProceedProps } from './PartnerProceed.types';
import { PartnerProceedPicture } from './components/PartnerProceedPicture';

export const PartnerProceed: FC<TPartnerProceedProps> = ({
  type,
  partnerKycStatus,
  fetchPartnerKycStatus,
  onBackButtonClick,
}) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t, currentLanguage } = useContext(TranslationContext);
  const docModal = useModal();
  const navigate = useNavigate();

  const [isProceedActive, setIsProceedActive] = useState(false);
  const [isTobAccepted, setIsTobAccepted] = useState(false);
  const [isPartnerAccepted, setIsPartnerAccepted] = useState(false);
  const [isSentToReview, setIsSentToReview] = useState(false);
  const [needToUpdateKycStatus, setNeedToUpdateKycStatus] = useState(false);
  const [isTobDisabled, setIsTobDisabled] = useState(true);
  const [isPartnerDisabled, setIsPartnerDisabled] = useState(true);
  const [openedDoc, setOpenedDoc] = useState<TDocument | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const docTob: TDocument | undefined = partnerKycStatus?.docs.find(
    (doc) =>
      doc.url === PartnerDocUrls.CFSC_TOB_IND ||
      doc.url === PartnerDocUrls.CFSC_TOB_CORP,
  );

  let docPartner: TDocument | undefined;

  if (type === PartnerTypes.CPA) {
    docPartner = partnerKycStatus?.docs.find(
      (doc) =>
        doc.url === PartnerDocUrls.CPA_IND ||
        doc.url === PartnerDocUrls.CPA_CORP,
    );
  } else {
    docPartner = partnerKycStatus?.docs.find(
      (doc) =>
        doc.url === PartnerDocUrls.REVSHARE_IND ||
        doc.url === PartnerDocUrls.REVSHARE_CORP,
    );
  }

  const proceedData = PARTNER_PROCEED_TEXTS[type];

  useEffect(() => {
    if (partnerKycStatus?.status === PartnerKycStatuses.PRODUCT_ON_REVIEW) {
      setIsSentToReview(true);
    }

    if (docTob?.is_signed) {
      setIsTobAccepted(true);
    } else {
      setIsTobDisabled(false);
    }

    if (docPartner?.is_signed) {
      setIsPartnerAccepted(true);
    } else {
      setIsPartnerDisabled(false);
    }
  }, [partnerKycStatus]);

  useEffect(() => {
    if (isTobAccepted && isPartnerAccepted) {
      setIsProceedActive(true);
    }
  }, [isTobAccepted, isPartnerAccepted]);

  const openedDocUrl = openedDoc?.preview_data_url || openedDoc?.data_url;
  const openedDocNotToSign = openedDoc ? openedDoc?.is_signed : true;

  const onOpenModal = (doc?: TDocument) => {
    if (doc) {
      setOpenedDoc(doc);
      docModal.onOpen();
    }
  };

  const onCloseModal = () => {
    setOpenedDoc(null);
    docModal.onClose();
  };

  const signDocument = async (doc?: TDocument) => {
    if (doc) {
      const docType = checkDocType(doc);
      const docSigned = await legalDocsService.signDoc(doc.id, currentLanguage);

      if (docSigned?.sign_date && docType === PartnerDocTypes.PARTNER_DOC_TOB) {
        setIsTobAccepted(true);
      }

      if (
        docSigned?.sign_date &&
        docType === PartnerDocTypes.PARTNER_DOC_PARTNER
      ) {
        setIsPartnerAccepted(true);
      }
    }
  };

  const handleConfirm = async (doc: TDocument) => {
    setIsConfirmLoading(true);
    signDocument(doc);
    setOpenedDoc(null);
    setIsConfirmLoading(false);
  };

  const modalConfirmButton: ModalProps['confirmButton'] = openedDocNotToSign
    ? undefined
    : {
        confirmButtonName: t('generic__modal__i_agree'),
        handleConfirm: () => openedDoc && handleConfirm(openedDoc),
        confirmButtonIsDisabled: isConfirmLoading,
      };

  const sendToReview = async () => {
    setIsSentToReview(true);

    const sentToReview = await partnerService.sendToReviewCFSCLanding(type);

    if (sentToReview) {
      setNeedToUpdateKycStatus(true);
      return;
    }

    setIsSentToReview(false);
  };

  const handleProceed = () => {
    setIsProceedActive(false);
    sendToReview();
    setIsProceedActive(true);
  };

  useEffect(() => {
    const checkStatus = () => {
      if (partnerKycStatus?.status === PartnerKycStatuses.PRODUCT_APPROVED) {
        setNeedToUpdateKycStatus(false);
        navigate(PARTNER_PAYMENTS_URL);
        return;
      }

      if (needToUpdateKycStatus) {
        fetchPartnerKycStatus();
      }
    };

    checkStatus();

    const intervalId = setInterval(checkStatus, 4_000);

    return () => clearInterval(intervalId);
  }, [partnerKycStatus?.status, needToUpdateKycStatus]);

  return (
    <StyledPartnerProceed className="PartnerProceed">
      <PartnerProceedPicture type={type} />
      <StyledPartnerProceedHeading className="PartnerProceedHeading">
        {t(proceedData.heading)}
      </StyledPartnerProceedHeading>
      <StyledPartnerProceedDesc className="PartnerProceedDesc">
        {t(proceedData.desc)}
      </StyledPartnerProceedDesc>
      <StyledPartnerProceedForm columns={1}>
        <StyledPartnerProceedDoc className="PartnerProceedDoc">
          <Checkbox
            checked={isTobAccepted}
            disabled={isTobAccepted || isTobDisabled}
            name="partner__doc__tob"
            data-test-id="partner__doc__tob"
            label={
              <StyledPartnerProceedLabel className="PartnerProceedLabel">
                <Trans
                  t={t}
                  i18nKey={proceedData.tob}
                  components={{
                    a: (
                      <StyledPartnerProceedLabelLink
                        className="PartnerProceedLabelLink"
                        href={docTob?.open_url || ''}
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault();
                          onOpenModal(docTob);
                        }}
                      />
                    ),
                  }}
                />
              </StyledPartnerProceedLabel>
            }
            className="PartnerProceedCheckbox"
            onChange={() => signDocument(docTob)}
          />
        </StyledPartnerProceedDoc>
        <StyledPartnerProceedDoc className="PartnerProceedDoc">
          <Checkbox
            checked={isPartnerAccepted}
            disabled={isPartnerAccepted || isPartnerDisabled}
            name="partner__doc__partner"
            data-test-id="partner__doc__partner"
            label={
              <StyledPartnerProceedLabel className="PartnerProceedLabel">
                <Trans
                  t={t}
                  i18nKey={proceedData.partner}
                  components={{
                    a: (
                      <StyledPartnerProceedLabelLink
                        className="PartnerProceedLabelLink"
                        href={
                          docPartner?.preview_data_url ||
                          docPartner?.open_url ||
                          ''
                        }
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault();
                          onOpenModal(docPartner);
                        }}
                      />
                    ),
                  }}
                />
              </StyledPartnerProceedLabel>
            }
            className="PartnerProceedCheckbox"
            onChange={() => signDocument(docPartner)}
          />
        </StyledPartnerProceedDoc>
      </StyledPartnerProceedForm>
      <StyledPartnerProceedButton
        fullWidth={!isDesktop}
        disabled={!isProceedActive || isSentToReview}
        loading={isSentToReview}
        onClick={handleProceed}
        data-test-id="partner__proceed__proceed-btn"
      >
        {t('layout__partner_landing__proceed__proceed')}
      </StyledPartnerProceedButton>
      {isSentToReview && (
        <StyledPartnerProceedNote className="PartnerProceedNote">
          {t('layout__partner_landing__proceed__sent_to_review')}
        </StyledPartnerProceedNote>
      )}
      <IconButton
        iconName="ArrowLeftIcon"
        label={t('layout__partner_landing__proceed__back')}
        iconColor="secondary"
        onClick={onBackButtonClick}
        data-test-id="partner__proceed__back-btn"
      />
      <Modal
        title={openedDoc?.title || ''}
        isOpened={Boolean(docModal.isOpened && openedDoc)}
        onClose={onCloseModal}
        confirmButton={modalConfirmButton}
        cancelButton={{ cancelButtonName: t('generic__action__cancel') }}
        maxWidth="lg"
        disableContentPaddings
        fullWidth
      >
        {openedDoc && openedDocUrl && (
          <DocumentViewer
            doc={{
              title: openedDoc.title,
              dataUrl: openedDocUrl,
              isSigned: openedDoc.is_signed,
            }}
          />
        )}
      </Modal>
    </StyledPartnerProceed>
  );
};
