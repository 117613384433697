import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledSecurityPanel = styled(Panel)`
  padding: 24px 0 0;

  .PanelTitle {
    padding: 0 24px;
  }

  .SecurityTabs {
    padding: 0 24px;
  }
`;
