import { styled } from 'theme';

export const StyledWithdrawalInfoWrapper = styled('div')`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;

  max-width: 800px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 85vw;
  }
`;

export const StyledWithdrawalInfoBlock = styled('div')`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledWithdrawalDatetime = styled('span')`
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledWithdrawalAmount = styled('span')`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledWithdrawalHeaderRow = styled('div')`
  flex: 1;

  display: flex;
  justify-content: center;
`;
