import { styled } from 'theme';

export const StyledNoDataNotification = styled('div')`
  padding: 8px 24px;
  color: ${({ theme }) => theme.color.typo.promo};

  line-height: 1.5;
  font-size: 13px;

  background-color: ${({ theme }) => theme.color.bg.primary};

  a {
    color: ${({ theme }) => theme.color.typo.action};
  }

  a:hover {
    color: ${({ theme }) => theme.color.typo.hover};
  }
`;
