import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';

import { StyledPartnerLinksDeleteActionWrapper } from './PartnerLinksDeleteAction.styled';

export const PartnerLinksDeleteAction: FC = () => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledPartnerLinksDeleteActionWrapper className="PartnerLinksDeleteActionWrapper">
      <h2>{t('layout__partners__links__delete_link_confirmation_header')}</h2>
      <span>{t('layout__partners__links__delete_link_confirmation_text')}</span>
    </StyledPartnerLinksDeleteActionWrapper>
  );
};
