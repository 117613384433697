import { createFilterOptions } from '@mui/material';
import { ISelectOption } from 'react-ui-kit-exante';

import { MAX_AUTOCOMPLETE_LENGTH } from 'constants/common';

export const getFilterOptions = (count: number) => {
  if (count > MAX_AUTOCOMPLETE_LENGTH) {
    return createFilterOptions<ISelectOption>({
      limit: MAX_AUTOCOMPLETE_LENGTH,
    });
  }
  return undefined;
};
