import { IRequestArguments } from '../apiRequest/apiRequest';

export class Abort {
  controllers: AbortController[] = [];

  lastResponse: unknown = {};

  async withAbortController<Response>(
    cb: (params: IRequestArguments) => Promise<Response>,
    params: IRequestArguments,
  ): Promise<Response | Error> {
    if (this.controllers.length) {
      this.controllers.forEach((c) => c.abort());
      this.controllers = [];
    }

    try {
      const abortController = new AbortController();

      this.controllers.push(abortController);

      const response = await cb({ ...params, signal: abortController.signal });
      this.lastResponse = response;

      return response;
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        return this.lastResponse as Response;
      }

      if (error instanceof Error) {
        return error;
      }

      throw error;
    }
  }
}
