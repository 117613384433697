import { FC, useContext } from 'react';
import { Panel } from 'react-ui-kit-exante';

import { PageWrapper } from 'components/PageWrapper';
import { BrandingContext, TranslationContext } from 'contexts';

export const VideoTutorial: FC = () => {
  const { t, templateT } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const rawContent =
    branding?.branding?.challenge_settings?.tutorial_page?.html_template;

  return (
    <PageWrapper className="VideoTutorialPageWrapper">
      <Panel
        title={t('page__video_tutorial')}
        className="VideoTutorialPagePanel"
      >
        {!!rawContent && (
          <div
            className="VideoTutorialPageContent"
            dangerouslySetInnerHTML={{ __html: templateT(rawContent) }}
          />
        )}
      </Panel>
    </PageWrapper>
  );
};
