import { styled } from 'theme';

export const StyledFooterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;

export const StyledUrls = styled('div')`
  display: flex;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const StyledUrlsItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledMessage = styled('span')`
  font-size: 13px;
  color: ${({ theme }) => theme.color.typo.promo};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.typo.action};
  }
`;
