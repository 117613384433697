import { Tooltip } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledDownloadProductAnchor = styled('a')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.typo.secondary};
  text-decoration: none;
  min-width: max-content;
`;

export const StyledDownloadProductAnchorOutlined = styled(
  StyledDownloadProductAnchor,
)`
  justify-content: center;
  gap: 4px;

  width: 200px;
  height: 48px;

  border: 1px solid ${({ theme }) => theme.color.icon.secondary};
  border-radius: 4px;

  font-weight: 600;
  line-height: 16px;

  ${({ theme }) => theme.breakpoints.down('lr')} {
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledQrTooltip = styled(Tooltip)`
  & .MuiTooltip-tooltip {
    display: flex;
    padding: 0;

    img {
      border-radius: 6px;
      width: 70px;
      height: 70px;
    }
  }

  & .MuiTooltip-arrow {
    &::before {
      border-bottom-left-radius: 3px;
    }
  }
`;

export const StyledQrImage = styled('img')`
  width: 100%;
  height: 100%;
`;
