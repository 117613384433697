import { useContext } from 'react';
import { Table } from 'react-ui-kit-exante';

import { AccordionWithContext } from 'components/Accordion';
import { TranslationContext } from 'contexts/TranslationContext';

import { TDataType } from '../MarginAccordion/MarginAccordion.types';

import { ItemTitleWrapper, ItemTitle } from './MarginAccordionItem.styled';
import { TMarginAccordionItemProps } from './MarginAccordionItem.types';

export const MarginAccordionItem = <DataType extends TDataType>({
  columns,
  data,
  displayedColumns,
  id,
  isLoading,
  maxTableItems,
  title,
  onChange,
}: TMarginAccordionItemProps<DataType>) => {
  const { t } = useContext(TranslationContext);

  return (
    <AccordionWithContext
      id={id}
      title={
        <ItemTitleWrapper className="AccordionItemTitle">
          <ItemTitle className="AccordionItemTitleText">{title}</ItemTitle>
        </ItemTitleWrapper>
      }
      onChange={onChange}
    >
      <Table<DataType>
        hasPagination
        isFlexLayout
        showTableInfo
        autoResetSortBy={false}
        columns={columns}
        data={data}
        displayedColumnKeys={displayedColumns}
        isLoading={isLoading}
        noDataHeight="auto"
        pageSize={maxTableItems}
        tableId={id}
        translator={t}
      />
    </AccordionWithContext>
  );
};
