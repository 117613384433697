import { FC, useContext } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';
import { prepareSPAUrl } from 'helpers/temporarySPAFixUrls';

import {
  StyledHeaderLink,
  StyledHeaderWebTradeIcon,
} from '../../Header.styled';

export const HeaderWebTrade: FC = () => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);

  return isTranslationsLoading ? (
    <Skeleton width={80} height={24} animation="wave" variant="rounded" />
  ) : (
    <StyledHeaderLink
      className="webtrade__name"
      href={prepareSPAUrl('/clientsarea/webtrade/')}
    >
      <StyledHeaderWebTradeIcon className="xnt-icon" />
      {t('layout__webtrade__web_platform', 'Trade')}
    </StyledHeaderLink>
  );
};
