export const enum CodeVerificationActions {
  OnCodeChange = 'CodeVerificationActions/OnCodeChange',

  RequestStart = 'CodeVerificationActions/RequestStart',
  RequestSuccess = 'CodeVerificationActions/RequestSuccess',
  RequestError = 'CodeVerificationActions/RequestError',

  VerifyStart = 'CodeVerificationActions/VerifyStart',
  VerifySuccess = 'CodeVerificationActions/VerifySuccess',
  VerifyError = 'CodeVerificationActions/VerifyError',
}
