import { NODE_BACK_API_URL } from 'constants/endpoints';
import { ApiRequest } from 'helpers/apiRequest';

class NodeSessionService extends ApiRequest {
  catchUnauthorized = false;

  async registerSession(): Promise<Record<any, any> | null | boolean> {
    try {
      return await this.fetch<Record<any, any>>({
        url: `${NODE_BACK_API_URL}/api/register-session/`,
      });
    } catch (error) {
      return false;
    }
  }
}

export const nodeSessionService = new NodeSessionService();
