import { PasswordVerificationActions } from './usePasswordVerification.actions';
import {
  TPasswordVerificationReducer,
  TPasswordVerificationState,
} from './usePasswordVerification.types';

export const usePasswordVerificationReducer: TPasswordVerificationReducer = (
  state,
  action,
): TPasswordVerificationState => {
  switch (action.type) {
    case PasswordVerificationActions.OnPasswordChange:
      return {
        ...state,
        password: action.payload,
        verifyErrorMessage: '',
      };

    case PasswordVerificationActions.VerifyError:
      return {
        ...state,
        isVerifying: false,
        verifyErrorMessage: action.payload,
      };

    case PasswordVerificationActions.VerifyStart:
      return {
        ...state,
        verifyErrorMessage: '',
        isVerifying: true,
      };

    case PasswordVerificationActions.VerifySuccess:
      return {
        ...state,
        isVerifying: false,
      };

    default:
      return state;
  }
};
