import { FC } from 'react';
import { Tab, Tabs } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledTabs = styled(Tabs)`
  margin: 24px 24px 0;
` as typeof Tabs;

export const StyledTab = styled(Tab)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
` as typeof Tab;

export const makeStyledTabIcon = (component: FC) => styled(component)`
  height: 24px;
  width: 24px;

  margin-bottom: 0 !important;
  margin-right: 11px;
`;
