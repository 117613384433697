import { blockNonNativeProps, Input, Loader } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledToInternalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding-top: 24px;
  }
`;

export const StyledToInternalUnavailable = styled('span')`
  a {
    color: ${({ theme }) => theme.color.typo.action};
  }

  a:hover {
    color: ${({ theme }) => theme.color.typo.hover};
  }
`;

export const StyledToInternalSearchContainer = styled('div')`
  display: flex;
  position: relative;
`;

export const StyledValidateLoader = styled(Loader)`
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  transform: translate(-12px, 12px);

  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const StyledValidateFooter = styled('div')`
  color: ${({ theme }) => theme.color.typo.secondary};
  margin-left: 12px;
  gap: 6px;
  font-size: 11px;

  & span:not(:last-of-type) {
    margin-right: 1px;
  }
`;

export const StyledValidateTarget = styled('span')`
  font-weight: 600;

  color: ${({ theme }) => {
    return theme.color.typo.promo;
  }};
`;

export const StyledTargetAccount = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const StyledOptionalContainer = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .IconButtonLabel {
    font-size: 13px;
  }
`;

export const StyledReferenceContainer = styled('div')`
  width: 100%;
`;

export const StyledOptionalContent = styled('span', {
  shouldForwardProp: blockNonNativeProps('isHidden'),
})<{ isHidden?: boolean }>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'contents')};
`;

export const StyledReference = styled(Input)`
  &:focus {
    outline: 0;
  }
`;

export const StyledFileUploadContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .RowContainerLeft {
      width: 100%;
    }

    & .RowContainerLeft > label {
      width: 100%;
    }
  }
`;

export const StyledFileUploadHeader = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledNoteContainer = styled('div', {
  shouldForwardProp: blockNonNativeProps('noHide'),
})<{ noHide?: boolean }>`
  display: ${({ noHide }) => (noHide ? 'flex' : 'none')};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: flex;
    width: fit-content;
  }
`;

export const StyledTooltipContainer = styled('div')`
  width: fit-content;
  display: flow;
`;

export const StyledTooltipDescription = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
