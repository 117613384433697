import { TFunction } from 'i18next';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

import { TClientsareaTheme } from 'theme';

export const NAME_MAP = (t: TFunction): Record<string, Payload['value']> => ({
  nav: t('generic__nav'),
  requiredMargin: t('layout__performance__required_margin', 'Required Margin'),
  grossPnl: t('generic__gross_pnl'),
  dailyPnl: t('generic__daily_pnl'),
  netPnl: t('generic__net_pnl'),
  balance: t('generic__balance'),
});

export const getChartColors = (theme: TClientsareaTheme) => ({
  positive: theme.color.typo.source,
  negative: theme.color.typo.radical,
  warning: theme.color.typo.warning,
});
