import { Input, Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledReportsPanel = styled(Panel)`
  .PanelTitle {
    margin-bottom: 0;
  }
`;

export const StyledReportsHeader = styled('div')`
  background-color: ${({ theme }) => theme.color.bg.primary};
  padding: 0 24px;
`;

export const StyledSettings = styled('div')`
  padding: 24px 0 0;
  display: flex;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    flex-direction: column;
  }
`;

export const StyledReportsDescription = styled('div')`
  a {
    color: ${({ theme }) => theme.color.typo.action};
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.color.typo.hover};
      text-decoration: underline;
    }
  }
`;

export const StyledFtpInput = styled(Input)`
  width: 327px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const StyledReportsContainer = styled('div')`
  background-color: ${({ theme }) => theme.color.bg.primary};
`;

export const StyledMobileCell = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};
  padding: 24px;
  width: 100vw;
`;

export const StyledMobileCellRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledMobileCellTitleRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
  gap: 8px;
  color: ${({ theme }) => theme.color.typo.primary};
  font-family: ${({ theme }) => theme.font.header};
`;

export const StyledMobileCellRowValue = styled('div')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-weight: 500;
`;
