import { blockNonNativeProps, Skeleton } from 'react-ui-kit-exante';

import { TStyledCryptoQrImgProps } from 'components/CurrencySelect/CurrencySelect.types';
import { styled } from 'theme';

export const StyledCryptoQrImg = styled('img', {
  shouldForwardProp: blockNonNativeProps(['hidden']),
})<TStyledCryptoQrImgProps>`
  width: 100%;
  max-width: 170px;
  max-height: 170px;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;

export const StyledCryptoScanText = styled('div')`
  margin: auto 0;
`;

export const StyledCryptoScanHeading = styled('h4')`
  margin-top: 0;
  margin-bottom: 16px;

  color: ${({ theme }) => theme.color.typo.primary};

  font-family: ${({ theme }) => theme.font.header};
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

export const StyledCryptoScanSubheading = styled('p')`
  margin: 0;

  color: ${({ theme }) => theme.color.typo.secondary};

  font-family: ${({ theme }) => theme.font.main};
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
`;

export const StyledCryptoQrSkeleton = styled(Skeleton)`
  min-width: 170px;
  height: 170px;
`;

export const StyledCryptoScanHeadingSkeleton = styled(Skeleton)`
  width: 200px;
  height: 24px;

  margin-bottom: 16px;
`;

export const StyledCryptoScanSubheadingSkeleton = styled(Skeleton)`
  width: 250px;
  height: 15px;
  margin-bottom: 8px;
`;
