import { styled } from 'theme';

// NOTE: sizes of the wrapper are used in inner components to fit,
// see StyledSigninCaptchaWrapper

export const SIGNIN_WRAPPER_WIDTH = 326;
export const SIGNIN_WRAPPER_WIDTH_LG = 360;

export const StyledSigninWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${SIGNIN_WRAPPER_WIDTH}px;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: ${SIGNIN_WRAPPER_WIDTH_LG}px;
  }
`;
