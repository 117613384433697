import { useContext } from 'react';

import { BrandingContext } from 'contexts';

import { ChallengeHeaderContext } from '../../../context/ChallengeContext';

import { StyledChallengeName } from './ChallengeName.styled';

export const ChallengeName = () => {
  const { branding } = useContext(BrandingContext);
  const { challenge_name: challengeName } = useContext(ChallengeHeaderContext);

  if (!challengeName) {
    return null;
  }

  return !branding?.branding?.challenge_settings?.header_settings
    ?.show_client_challenge ? null : (
    <StyledChallengeName className="HeaderCustomText ChallengeName">
      {challengeName}
    </StyledChallengeName>
  );
};
