import { FC, useContext } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { IconButton, RadioGroup } from 'react-ui-kit-exante';

import { FormDatePickerContainer } from 'components/Form/FormDatePickerContainer/FormDatePickerContainer';
import { FormInputContainer } from 'components/Form/FormInputContainer/FormInputContainer';
import { todayEnd, todayStart } from 'constants/dates';
import { TranslationContext } from 'contexts';

import {
  REPORT_TYPES_TERMS,
  REPORT_TYPES_WITH_SYMBOLS_IDS,
  REPORT_TYPES_WITH_TIME,
} from '../../../../ReportEditForm.constants';
import { ReportFormContext } from '../../../../contexts/ReportFormContext';

import {
  StyledReportEditItem,
  StyledReportEditItemFields,
  StyledReportEditItemFieldsRow,
  StyledReportEditItemGroupList,
  StyledReportEditItemHead,
} from './ReportEditItem.styled';
import { TReportEditItemProps } from './ReportEditItem.types';

const TIME_PICKER_PROPS = {
  defaults: {
    hours: 23,
    minutes: 59,
    seconds: 59,
  },
};

export const ReportEditItem: FC<TReportEditItemProps> = ({
  item,
  groups,
  onDelete,
}) => {
  const { t } = useContext(TranslationContext);
  const { formControl } = useContext(ReportFormContext);

  const nameDateFrom = `items.${item.index}.date_from` as const;
  const nameDateTo = `items.${item.index}.date_to` as const;

  const handleDeleteClick = () => {
    onDelete(item.index);
  };

  const [dateFrom, dateTo] = useWatch({
    control: formControl,
    name: [nameDateFrom, nameDateTo],
  });

  const reportType = item.report_type;
  const isTermsReport = reportType && REPORT_TYPES_TERMS.includes(reportType);

  const dateFromStrict = isTermsReport ? todayStart : dateFrom;
  const dateToStrict = isTermsReport ? todayEnd : dateTo;
  const dateFromMin = isTermsReport ? todayStart : undefined;

  const withTime = reportType
    ? REPORT_TYPES_WITH_TIME.includes(reportType)
    : false;

  const withSymbolsIds = reportType
    ? REPORT_TYPES_WITH_SYMBOLS_IDS.includes(reportType)
    : false;

  return (
    <StyledReportEditItem className="ReportEditItem">
      <StyledReportEditItemHead className="ReportEditItemHead">
        <StyledReportEditItemFieldsRow className="ReportEditItemFieldsRow">
          <StyledReportEditItemFields className="ReportEditItemFields">
            <FormDatePickerContainer
              name={nameDateFrom}
              minDate={dateFromMin}
              maxDate={dateToStrict || todayEnd}
              showTimeInput={withTime}
              size="small"
              autoComplete="off"
              fullWidth
              hideErrors
              withSeconds
            />
            <FormDatePickerContainer
              name={nameDateTo}
              minDate={dateFromStrict}
              maxDate={todayEnd}
              showTimeInput={withTime}
              size="small"
              autoComplete="off"
              fullWidth
              hideErrors
              withSeconds
              timePickerProps={TIME_PICKER_PROPS}
            />
          </StyledReportEditItemFields>
        </StyledReportEditItemFieldsRow>

        <IconButton
          onClick={handleDeleteClick}
          iconName="DeleteIcon"
          title={t('generic__action__delete')}
          aria-label={t('generic__action__delete')}
        />
      </StyledReportEditItemHead>
      {withSymbolsIds && (
        <StyledReportEditItemFields className="ReportEditItemFields">
          <FormInputContainer
            name={`items.${item.index}.symbol_ids`}
            label="AAPL.NASDAQ, AMZN.NASDAQ, SONY.NYSE"
          />
        </StyledReportEditItemFields>
      )}

      {!!groups.length && (
        <StyledReportEditItemGroupList className="ReportEditItemGroupList">
          <Controller
            name={`items.${item.index}.trades_grouping`}
            control={formControl}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                value={value}
                handleChange={onChange}
                radioOptions={groups.map(({ key, title }) => ({
                  label: title,
                  value: key,
                }))}
              />
            )}
          />
        </StyledReportEditItemGroupList>
      )}
    </StyledReportEditItem>
  );
};
