import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledHelpPanel = styled(Panel)`
  && {
    padding: 0;
  }

  & .PanelTitle {
    padding: 24px;
    margin-bottom: 0;
  }
`;

export const StyledHelpWrapper = styled('div')`
  display: grid;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-columns: 406px 1fr;
  }
`;

export const StyledHelpSection = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.color.bg.basic};

  margin: 0 24px;
  padding: 24px 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 0;

    &:first-of-type {
      margin-left: 24px;
    }

    &:last-of-type {
      margin-right: 24px;
      border-left: 1px solid ${({ theme }) => theme.color.bg.basic};
    }
  }
`;

export const StyledHelpHeading = styled('h1')`
  margin: 0 0 8px;

  color: ${({ theme }) => theme.color.typo.primary};
  font-family: ${({ theme }) => theme.font.header};
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
`;

export const StyledHelpSubheading = styled('h2')`
  margin: 0;

  color: ${({ theme }) => theme.color.typo.primary};
  font-family: ${({ theme }) => theme.font.header};
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

export const StyledHelpText = styled('p')`
  margin: 0;
  color: ${({ theme }) => theme.color.typo.promo};
  font-family: ${({ theme }) => theme.font.main};
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  cursor: text;
`;

export const StyledHelpAnchor = styled('a')`
  color: ${({ theme }) => theme.color.typo.action};
  text-decoration: none;

  &[href=''],
  &[href='#'],
  &:not([href]) {
    pointer-events: none;
    color: inherit;
  }
`;
