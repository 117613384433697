import { styled } from 'theme';

export const StyledFooterFollowLinks = styled('div')`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const StyledFollowLinksIcon = styled('a')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: middle;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.icon.secondary};
  color: ${({ theme }) => theme.color.icon.secondary};
`;
