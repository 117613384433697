import { Link } from '@mui/material';

import { styled } from 'theme';

export const StyledHeaderCustomButton = styled(Link)`
  display: block;
  padding: 12px 13px;

  color: ${({ theme }) => theme.color.typo.action};
  font-size: 15px;
  line-height: 16px;
  font-weight: 600;

  text-decoration: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 8px;

    border: 1px solid ${({ theme }) => theme.color.typo.ghost};
    border-radius: 4px;
    color: ${({ theme }) => theme.color.typo.ghost};

    &:hover {
      transform: scale(1.1);
      transition: 0.3s ease;
    }
  }
`;
