import { ICellValue } from 'react-ui-kit-exante';

import { TCreateCurrencyFormatter } from './currencyFormatter';
import { formatCurrency } from './formatCurrency';

export const formatCellToCurrency = <
  Data extends Partial<Record<keyof Data, unknown>>,
>(
  currencyFormatter: TCreateCurrencyFormatter,
  field: keyof Data,
) => {
  return ({ row: { values } }: ICellValue<Data>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore -- TODO fix raw Run UI code https://jira.exante.eu/browse/CL-2647
    formatCurrency(currencyFormatter, values[field]);
};
