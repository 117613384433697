import { blockNonNativeProps, Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledW8IframeProps } from './AgreementsW8Form.types';

export const StyledAgreementsW8FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 24px;
  gap: 24px;
`;

export const StyledW8FormIframe = styled('iframe', {
  shouldForwardProp: blockNonNativeProps(['isVisible']),
})<TStyledW8IframeProps>`
  width: 100%;
  height: 500px;

  border: none;

  display: ${({ isVisible }) => (isVisible ? 'default' : 'none')};
`;

export const StyledW8FormSkeleton = styled(Skeleton)`
  width: 100%;
  height: 500px;
`;

export const StyledW8FormText = styled('div')`
  color: ${({ theme }) => theme.color.typo.promo};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledW8FormAnchor = styled('a')`
  color: ${({ theme }) => theme.color.typo.action};
`;
