import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { DEFAULT_CURRENCY } from 'constants/common';
import { UserDetailsContext } from 'contexts/UserDetailsContext';
import {
  currenciesService,
  TCurrencies,
  TCurrenciesData,
} from 'services/currencies';
import { TChildren } from 'types/TChildren';

type TCurrencyState = {
  currency: string;
  currencies: TCurrencies | null;
  changeCurrency(newCurrency: string): Promise<void>;
  isCurrencyLoading: boolean;
};

const initialState: TCurrencyState = {
  currency: DEFAULT_CURRENCY,
  currencies: null,
  changeCurrency() {
    return Promise.resolve();
  },
  isCurrencyLoading: false,
};

export const CurrencyContext = createContext<TCurrencyState>(initialState);

export const CurrencyProvider: FC<TChildren> = ({ children }) => {
  const { updateUserPreferred } = useContext(UserDetailsContext);

  const [currency, setCurrency] = useState<string>(initialState.currency);

  const {
    data,
    fetchData: fetchCurrencies,
    isLoading: isLoadingCurrencies,
  } = useData<TCurrenciesData | null>({
    onFetch: () => currenciesService.getCurrencies(),
    onSuccess: (responseData) => {
      const { userCurrency, defaultCurrency } = responseData || {};
      setCurrency(userCurrency || defaultCurrency || DEFAULT_CURRENCY);
    },
  });

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const changeCurrency = async (newCurrency: string) => {
    await updateUserPreferred({ preferableCurrency: newCurrency });
    setCurrency(newCurrency);
  };

  const isLoading = isLoadingCurrencies;

  const value = useMemo<TCurrencyState>(
    () => ({
      currency,
      currencies: data?.currencies || null,
      changeCurrency,
      isCurrencyLoading: isLoading,
    }),
    [data, currency, isLoading],
  );

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};
