import {
  createElement,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Link, useMatches, useNavigate } from 'react-router-dom';

import { FolderIcon } from 'assets/icons';
import { TabsContextData, TranslationContext } from 'contexts';
import { useRouteAssociatedAccounts } from 'hooks';
import { TTab } from 'services/tabs';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TTabComponentProps } from 'types';

import { READY_FOR_PRODUCTION_TABS, tabIconMap } from './Tabs.constants';
import { makeStyledTabIcon, StyledTab, StyledTabs } from './Tabs.styled';
import { TCurrentTab, TTabsProps } from './Tabs.types';
import { TabsSkeleton } from './components';

export const Tabs: FC<TTabsProps> = ({
  tabsForShow,
  disableAccountValidation,
}) => {
  const navigate = useNavigate();
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const routeMatches = useMatches();

  const {
    isTabsLoading,
    availableTabs,
    tabs: allTabs,
  } = useContext(TabsContextData);

  const accounts = useRouteAssociatedAccounts();
  const { isAccountsLoading } = useAppSelector(selectAccounts);

  const tabsData = useMemo(
    () => (disableAccountValidation ? allTabs : availableTabs),
    [disableAccountValidation, allTabs, availableTabs],
  );

  const tabs = useMemo(() => {
    return Object.values(tabsForShow).reduce<TTab[]>((result, id) => {
      const tab = tabsData.find((tabItem) => tabItem.id === id);

      if (!READY_FOR_PRODUCTION_TABS.includes(id) || !tab) {
        return result;
      }

      return [...result, tab];
    }, []);
  }, [tabsData]);

  const tabsList: ReactNode[] = useMemo<ReactNode[]>(() => {
    const createTabIcon = (alias: string) => {
      const iconKey = alias.replace(':index', '');
      const icon = iconKey in tabIconMap ? tabIconMap[iconKey] : FolderIcon;

      if (typeof icon === 'string') {
        return icon;
      }

      return createElement(makeStyledTabIcon(icon));
    };

    return tabs.reduce<ReactNode[]>((tabComponents, { titleKey, url, id }) => {
      tabComponents.push(
        <StyledTab<TTabComponentProps<typeof Link>>
          icon={createTabIcon(id)}
          label={t(titleKey)}
          key={titleKey}
          component={Link}
          to={url}
        />,
      );

      return tabComponents;
    }, []);
  }, [tabs, t]);

  const currentTab = useMemo<TCurrentTab>(() => {
    const match = routeMatches[routeMatches.length - 1];

    const tab = tabs.find(({ url }) => url === match.pathname);
    const tabIdx = tabs.findIndex(({ url }) => url === match.pathname);

    return {
      tab,
      number: tabIdx,
    };
  }, [routeMatches, tabs]);

  const tabsVisible = useMemo(() => {
    if (tabs.length === 0 || currentTab?.number === -1) {
      return false;
    }

    if (!disableAccountValidation) {
      return Boolean(accounts.length);
    }

    return true;
  }, [disableAccountValidation, tabs, currentTab, accounts]);

  useEffect(() => {
    if (tabs.length && currentTab.number === -1) {
      navigate(tabs[0].url);
    }
  }, [tabs, currentTab]);

  if (isTabsLoading || isAccountsLoading || isTranslationsLoading) {
    return <TabsSkeleton />;
  }

  return !tabsVisible ? null : (
    <StyledTabs
      value={currentTab?.number || 0}
      variant="scrollable"
      scrollButtons={false}
      className="PortfolioTabs"
    >
      {tabsList}
    </StyledTabs>
  );
};
