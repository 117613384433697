import { FC, useContext, useMemo } from 'react';
import {
  ToggleButtonGroup,
  TToggleButtonGroupOption,
} from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';

import { AmountFormAllfundsToggleOption } from './AmountFormAllfundsToggle.contants';
import { TAmountFormAllfundsToggleProps } from './AmountFormAllfundsToggle.types';

export const AmountFormAllfundsToggle: FC<TAmountFormAllfundsToggleProps> = ({
  value,
  showSkeleton = false,
  onChange,
}) => {
  const { t } = useContext(TranslationContext);

  const options: TToggleButtonGroupOption[] = useMemo(
    () => [
      {
        label: t('generic__amount'),
        value: AmountFormAllfundsToggleOption.Amount,
      },
      {
        label: t('generic__all_funds'),
        value: AmountFormAllfundsToggleOption.Allfunds,
      },
    ],
    [t],
  );

  return (
    <ToggleButtonGroup
      className="AmountFormAllfundsToggle"
      exclusive
      fullWidth
      size="small"
      showSkeleton={showSkeleton}
      value={value || 'amount'}
      options={options}
      onChange={(val) => onChange(val as AmountFormAllfundsToggleOption)}
    />
  );
};
