import { TFunction } from 'i18next';
import { IColumn } from 'react-ui-kit-exante';

import {
  TCreateCurrencyFormatter,
  formatCellToCurrencyUnrounded,
  formatCurrency,
} from 'helpers/formatters';
import { TSelectedColumns } from 'hooks/useSelectedColumns';
import { TAccountMargin, TBlockedFunds, TMargin } from 'services/margin';

import { ACCORDION_ID } from './MarginAccordion.constants';
import { TMarignAccordionConfig } from './MarginAccordion.types';

export function createAccordionPrefix(key: string) {
  return `${ACCORDION_ID}-${key}`;
}

export function getTableIds(accordionConfig: TMarignAccordionConfig) {
  return Object.keys(accordionConfig).map((key) => `${ACCORDION_ID}-${key}`);
}

export function getSum<T, K extends keyof T>(arr: T[], fieldName: K) {
  return arr.reduce((acc, t) => acc + Number(t[fieldName]), 0);
}

export function getAccordionItems(
  data: TAccountMargin | null,
): TMarignAccordionConfig {
  return {
    margin: {
      id: 'margin',
      type: 'margin',
      title: 'Margin',
      data: data?.margin ?? [],
      order: 0,
    },
    blockedFunds: {
      id: 'blockedFunds',
      type: 'blockedFunds',
      title: 'Funds on hold',
      data: data?.blocked_funds ?? [],
      order: 1,
    },
    orderMargin: {
      id: 'orderMargin',
      type: 'orderMargin',
      title: 'Order margin',
      data: data?.order_margin ?? [],
      order: 2,
    },
  };
}

export function getMarginColumns(
  t: TFunction,
  sum: number,
  currency: string,
  currencyFormatter: TCreateCurrencyFormatter,
): IColumn<TMargin>[] {
  return [
    {
      id: 'type',
      Header: t('generic__type'),
      accessor: 'type_title',
      Footer: () => <b>{t('generic__datatables__total').toUpperCase()}</b>,
    },
    {
      id: 'underlying',
      Header: t('generic__underlying'),
      accessor: 'underlying',
    },
    {
      id: 'asset',
      Header: t('generic__assets'),
      accessor: 'asset',
      required: true,
    },
    {
      id: 'margin',
      Header: t('generic__margin'),
      accessor: 'margin',
      Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'margin'),
    },
    {
      id: 'currency',
      Header: t('generic__ccy'),
      accessor: 'currency',
    },
    {
      id: 'convertedMargin',
      Header: `${t('layout__margin__margin_in')} ${currency}`,
      accessor: 'convertedMargin',
      Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'convertedMargin'),
      Footer: () => <b>{formatCurrency(currencyFormatter, sum)}</b>,
    },
  ];
}

export function getBlockedFundsColumns(
  t: TFunction,
  sum: number,
  currency: string,
  currencyFormatter: TCreateCurrencyFormatter,
): IColumn<TBlockedFunds>[] {
  return [
    {
      id: 'type',
      Header: t('generic__type'),
      accessor: 'type_title',
      Footer: () => <b>{t('generic__datatables__total').toUpperCase()}</b>,
    },
    {
      id: 'reason',
      Header: t('layout__margin__reason'),
      accessor: 'reason' as const,
    },
    {
      id: 'quantity',
      Header: t('layout__margin__quantity'),
      accessor: 'lockedQtty',
    },
    {
      id: 'assets',
      Header: t('generic__assets'),
      accessor: 'asset',
      required: true,
    },
    {
      id: 'margin',
      Header: t('generic__margin'),
      accessor: 'margin',
      Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'margin'),
    },
    {
      id: 'currency',
      Header: t('generic__ccy'),
      accessor: 'currency',
    },
    {
      id: 'convertedMargin',
      Header: `${t('layout__margin__margin_in')} ${currency}`,
      accessor: 'convertedMargin',
      Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'convertedMargin'),
      Footer: () => <b>{formatCurrency(currencyFormatter, sum)}</b>,
    },
  ];
}

export function getOrderMarginColumns(
  t: TFunction,
  sum: number,
  currency: string,
  currencyFormatter: TCreateCurrencyFormatter,
): IColumn<TMargin>[] {
  return [
    {
      id: 'type',
      Header: t('generic__type'),
      accessor: 'type_title',
      Footer: () => <b>{t('generic__datatables__total').toUpperCase()}</b>,
    },
    {
      id: 'reason',
      Header: t('layout__margin__reason'),
      accessor: 'reason' as const,
    },
    {
      id: 'quantity',
      Header: t('layout__margin__quantity'),
      accessor: 'lockedQtty',
    },
    {
      id: 'assets',
      Header: t('generic__assets'),
      accessor: 'asset',
      required: true,
    },
    {
      id: 'margin',
      Header: t('generic__margin'),
      accessor: 'margin',
      Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'margin'),
    },
    {
      id: 'currency',
      Header: t('generic__ccy'),
      accessor: 'currency',
    },
    {
      id: 'convertedMargin',
      Header: `${t('layout__margin__margin_in')} ${currency}`,
      accessor: 'convertedMargin',
      Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'convertedMargin'),
      Footer: () => <b>{formatCurrency(currencyFormatter, sum)}</b>,
    },
  ];
}

export function getDisplayedColumns(
  isFirstLoading: boolean,
  selectedColumn: TSelectedColumns,
  cachedSelectedColumn: TSelectedColumns | undefined,
) {
  if (cachedSelectedColumn && isFirstLoading) {
    selectedColumn = cachedSelectedColumn;
  }

  return Object.keys(selectedColumn).filter((key) =>
    Boolean(selectedColumn[key]),
  );
}
