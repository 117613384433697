import {
  createContext,
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { useLogHandleTime } from 'hooks/useLogHandleTime';
import { depositService, TCashConversionResponse } from 'services/deposit';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TChildren } from 'types/TChildren';

import { initialState } from './CashConversionContext.consts';
import { TCashCovnersionState } from './CashConversionContext.types';

export const CashConversionContext =
  createContext<TCashCovnersionState>(initialState);

export const CashConversionProvider: FC<TChildren> = ({
  children,
}): ReactElement | null => {
  const [currency, setCurrency] = useState<string | null>(
    initialState.currency,
  );

  const [isCurrencyUpdating, setIsCurrencyUpdating] = useState(false);

  const { selectedAccountId } = useAppSelector(selectAccounts);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('cash-conversion');
  const abortControllerGet = useRef(new AbortController());
  const abortControllerSet = useRef(new AbortController());

  const {
    isLoading: isLoadingData,
    data: cashConversionData,
    fetchData: fetchCashConversion,
  } = useData<TCashConversionResponse | null>({
    onFetch: async () => {
      abortControllerGet.current.abort();
      abortControllerGet.current = new AbortController();
      setStartHandleTime();
      const response = await depositService.getCashConversion({
        options: {
          signal: abortControllerGet.current.signal,
        },
      });
      logHandleTime();
      return response;
    },
  });

  useEffect(() => {
    fetchCashConversion();

    return () => {
      abortControllerGet.current.abort();
    };
  }, []);

  const accounts = cashConversionData?.data?.accounts || {};
  const currencies = cashConversionData?.data?.currencies || [];
  const currencySafe =
    currency != null
      ? // can be an empty string ('')
        currency
      : // if null, use the value from requested data
        (selectedAccountId && accounts[selectedAccountId]) || null;

  const isAccountAllowed = useMemo(
    () =>
      !!(
        selectedAccountId && Object.keys(accounts).includes(selectedAccountId)
      ),
    [accounts, selectedAccountId],
  );

  const handleCurrencyChange = async (code: string) => {
    if (selectedAccountId) {
      abortControllerSet.current.abort();
      abortControllerSet.current = new AbortController();

      setIsCurrencyUpdating(true);

      await depositService.setCashConversion({
        accountId: selectedAccountId,
        currency: code,
        options: {
          signal: abortControllerSet.current.signal,
        },
      });

      setIsCurrencyUpdating(false);
    }

    setCurrency(code);
  };

  const value = useMemo<TCashCovnersionState>(
    () => ({
      isLoading: isLoadingData,
      isCurrencyUpdating,
      isAccountAllowed,
      currencies,
      currency: currencySafe,
      setCurrency: handleCurrencyChange,
    }),
    [
      isLoadingData,
      isCurrencyUpdating,
      isAccountAllowed,
      currencies,
      currencySafe,
      handleCurrencyChange,
    ],
  );

  return (
    <CashConversionContext.Provider value={value}>
      {children}
    </CashConversionContext.Provider>
  );
};
