import { Button, Input } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledEmailTooltipForm = styled('form')`
  display: flex;

  flex-direction: column;
  gap: 16px;
`;

export const StyledEmailTooltipActionRow = styled('div')`
  display: flex;

  flex-direction: row;
  gap: 16px;
`;

export const StyledEmailTooltipInput = styled(Input)`
  width: 100%;
`;

export const StyledEmailTooltipActionButton = styled(Button)`
  width: 50%;
`;
