import { FC } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import {
  StyledFormCheckbox,
  StyledFormCheckboxGroupContainer,
  StyledFormCheckboxMessage,
  StyledFormCheckboxRow,
} from './FormCheckboxGroupContainer.styled';
import { TFormCheckboxGroupContainerProps } from './FormCheckboxGroupContainer.types';

export const FormCheckboxGroupContainer: FC<
  TFormCheckboxGroupContainerProps
> = ({ name, options, noWrap, hideErrors = false, disabled = false }) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value = [] },
    formState: { errors },
  } = useController({
    control,
    name,
  });

  const checkboxIds = useWatch({ control, name }) || [];

  const handleChange = (optionChanged: string) => {
    onChange(
      options.reduce<string[]>((acc, { value: optionValue }) => {
        const checked = checkboxIds.includes(optionValue);
        const toggled = optionChanged === optionValue;

        if ((!toggled && checked) || (toggled && !checked)) {
          return [...acc, optionValue];
        }

        return acc;
      }, []),
    );
  };

  const message = !hideErrors && String(errors?.[name]?.message || '');
  const showError = !hideErrors && !!message;

  return (
    <StyledFormCheckboxGroupContainer className="FormCheckboxGroupContainer">
      <StyledFormCheckboxRow className="FormCheckboxRow">
        {options.map((option) => (
          <StyledFormCheckbox
            key={option.value}
            checked={value?.some((checked: string) => checked === option.value)}
            label={option.label}
            name={name}
            onBlur={onBlur}
            disabled={disabled}
            onChange={() => handleChange(option.value)}
            noWrap={noWrap}
            aria-label={`${name}: ${option.label}`}
          />
        ))}
      </StyledFormCheckboxRow>
      {showError && (
        <StyledFormCheckboxMessage className="FormCheckboxMessage">
          {message}
        </StyledFormCheckboxMessage>
      )}
    </StyledFormCheckboxGroupContainer>
  );
};
