export const enum TransferType {
  ToMyAccount = 'Transfer',
  ToInternalAccount = 'Internal',
  Card = 'Card',
  Wire = 'Wire',
  Crypto = 'Crypto',
  Security = 'Security',
  SubuserWithdrawal = 'Subuser Withdrawal',
  OppositeWl = 'Opposite WL',
  WireThirdParty = 'Wire third-party',
}
