import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TPerformance } from './performance.types';

class PerformanceService extends ApiRequest {
  async getPerformanceConfig(lang: string): Promise<TPerformance | null> {
    try {
      const { data } = await this.fetch<{ performance: TPerformance }>({
        url: `/${lang}/clientsarea/spa-layout/`,
        params: { name: 'performance' },
      });

      return data.performance;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getPermormanceConfig error:', error);
      return null;
    }
  }
}

export const performanceService = new PerformanceService();
