import { IconButton, Table } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPartnerStatisticsWrapper = styled('div')`
  position: relative;
`;

export const StyledPartnerStatisticsTable = styled(Table)`
  > header {
    padding-top: 0;
  }

  button:has(.PartnerStatisticsExportButton) {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  .Table:first-child {
    margin-top: 40px;
  }
` as typeof Table;

export const StyledDateRangePickerWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;

  .react-datepicker__header {
    svg {
      width: unset;
      height: unset;
    }
  }
`;

export const StyledDateRangePickerDateText = styled('span')`
  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 15px;

  display: flex;
  gap: 4px;

  ${({ theme }) => theme?.breakpoints?.down('md')} {
    display: none;
  }
`;

export const StyledPartnerStatisticsExportButton = styled(IconButton)`
  width: 24px;
  height: 24px;
`;

export const StyledPartnerStatisticsAdditionalActions = styled('div')`
  z-index: 2;
  position: absolute;
  right: 60px;
  top: 8px;

  display: flex;
  gap: 8px;
`;
