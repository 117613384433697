import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { formatCurrency, formatPercentage } from 'helpers/formatters';
import { TGetAssetManagementSetupTableColumnsParams } from 'pages/AssetManagementSetup/components/AssetManagementSetupTable/AssetManagementSetupTable.types';
import { TAssetManagementFeeSetup } from 'services/assetManagement/assetManagement.types';

import { InviteCell } from './cells/InviteCell';

export const getAssetManagementSetupTableColumns = ({
  t,
  currencyFormatter,
}: TGetAssetManagementSetupTableColumnsParams): IColumn<TAssetManagementFeeSetup>[] => [
  {
    id: 'name',
    Header: t('asset_management__table__name'),
    accessor: 'name',
  },
  {
    id: 'value',
    Header: t('asset_management__setup_item__profit_fee'),
    accessor: 'value',
    Cell: ({ row: { values } }: ICellValue<TAssetManagementFeeSetup>) => {
      return formatPercentage(values.value);
    },
  },
  {
    id: 'management_fee',
    Header: t('asset_management__setup_item__total_fee'),
    accessor: 'management_fee',
    Cell: ({ row: { values } }: ICellValue<TAssetManagementFeeSetup>) => {
      return (
        <>&#8364;{formatCurrency(currencyFormatter, values.management_fee)}</>
      );
    },
  },
  {
    id: 'link',
    Header: t('asset_management__link'),
    accessor: 'link',
    Cell: ({ row: { values } }: ICellValue<TAssetManagementFeeSetup>) => (
      <InviteCell asset={values.link} />
    ),
  },
];
