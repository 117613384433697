import { FC, useContext } from 'react';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';

import { getRowVisibleValue } from './WithdrawalInfoRow.helpers';
import {
  StyledWithdrawalInfoRowCopyButton,
  StyledWithdrawalInfoRowTitle,
  StyledWithdrawalInfoRowValue,
  StyledWithdrawalInfoRowValueWrapper,
  StyledWithdrawalInfoRowWrapper,
} from './WithdrawalInfoRow.styled';
import { TWithdrawalInfoRowProps } from './WithdrawalInfoRow.types';

export const WithdrawalInfoRow: FC<TWithdrawalInfoRowProps> = ({
  value,
  titleKey,
}) => {
  const { t } = useContext(TranslationContext);
  const rowVisibleValue = getRowVisibleValue(value);
  const isValueVisible: boolean = rowVisibleValue !== DEFAULT_EMPTY_PLACEHOLDER;

  const isValueCopyable =
    typeof value === 'number' || typeof value === 'string';

  if (!isValueVisible) {
    return null;
  }

  const isComplexValue = typeof rowVisibleValue === 'object';

  return (
    <StyledWithdrawalInfoRowWrapper
      className="WithdrawalInfoRowWrapper"
      vertical={titleKey === 'Positions'}
    >
      <StyledWithdrawalInfoRowTitle className="WithdrawalInfoRowTitle">
        {t(titleKey)}
      </StyledWithdrawalInfoRowTitle>
      <StyledWithdrawalInfoRowValueWrapper
        isComplexValue={isComplexValue}
        className="WithdrawalInfoRowValueWrapper"
      >
        <StyledWithdrawalInfoRowValue
          isComplexValue={isComplexValue}
          className="WithdrawalInfoRowValue"
        >
          {rowVisibleValue}
        </StyledWithdrawalInfoRowValue>
        {!isComplexValue && (
          <StyledWithdrawalInfoRowCopyButton
            text={String(value)}
            hidden={!isValueCopyable}
          />
        )}
      </StyledWithdrawalInfoRowValueWrapper>
    </StyledWithdrawalInfoRowWrapper>
  );
};
