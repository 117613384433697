import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledTransfersBody,
  StyledTransfersContainer,
} from '../../routes/shared.styled';
import { AmountForm } from '../AmountForm';

import {
  StyledWithdrawalSkeletonAccounts,
  StyledWithdrawalSkeletonContainer,
  StyledWithdrawalSkeletonContainerWrapper,
  StyledWithdrawalSkeletonTabs,
} from './WithdrawalSkeleton.styled';

export const WithdrawalSkeleton = () => {
  return (
    <StyledWithdrawalSkeletonContainerWrapper>
      <StyledTransfersContainer className="TransfersContainer">
        <StyledTransfersBody className="TransfersBody">
          <StyledWithdrawalSkeletonContainer>
            <StyledWithdrawalSkeletonTabs>
              <Skeleton height={48} width={163} />
              <Skeleton height={48} width={163} />
              <Skeleton height={48} width={163} />
            </StyledWithdrawalSkeletonTabs>
            <Skeleton height={48} width={463} />
            <StyledWithdrawalSkeletonAccounts>
              <Skeleton width={166} height={104} />
              <Skeleton width={166} height={104} />
              <Skeleton width={166} height={104} />
              <Skeleton width={166} height={104} />
              <Skeleton width={166} height={104} />
              <Skeleton width={166} height={104} />
            </StyledWithdrawalSkeletonAccounts>
          </StyledWithdrawalSkeletonContainer>
        </StyledTransfersBody>
        <AmountForm
          balances={{}}
          isLoading
          amount=""
          currency=""
          currencies={[]}
          limit={null}
          isAllFunds={false}
          onAmountChange={() => {}}
          onCurrencyChange={() => {}}
          onSubmit={() => {}}
          onAllfundsChange={() => {}}
        />
      </StyledTransfersContainer>
    </StyledWithdrawalSkeletonContainerWrapper>
  );
};
