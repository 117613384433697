import { IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledCustomReportsHeader = styled('div')`
  width: 100%;
  padding: 24px;

  display: flex;
  justify-content: flex-end;
`;

export const StyledAddReportIconButton = styled(IconButton)`
  font-size: 15px;
`;
