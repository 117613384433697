import { PropsWithChildren, useMemo, useState } from 'react';

import { TransfersContext } from './constants';
import {
  useAccountDetailsState,
  useCommissionsState,
  useTransfersState,
} from './hooks';
import { CashTransferTab, TTransfersContext } from './types';

export const TransfersProvider = ({ children }: PropsWithChildren) => {
  const { transfersState, transfersDispatch } = useTransfersState();
  const { commissionsState, commissionsDispatch, fetchServiceFees } =
    useCommissionsState();

  const [cashTransferTab, setCashTransferTab] =
    useState<CashTransferTab | null>(null);

  const { accountDetailsState, accountDetailsDispatch } =
    useAccountDetailsState();

  const value = useMemo<TTransfersContext>(
    (): TTransfersContext => ({
      accountDetailsState,
      accountDetailsDispatch,
      transfersState,
      transfersDispatch,
      commissionsState,
      commissionsDispatch,
      cashTransferTab,
      setCashTransferTab,
      fetchServiceFees,
    }),
    [
      accountDetailsState,
      accountDetailsDispatch,
      transfersDispatch,
      transfersState,
      commissionsState,
      commissionsDispatch,
      cashTransferTab,
      setCashTransferTab,
      fetchServiceFees,
    ],
  );

  return (
    <TransfersContext.Provider value={value}>
      {children}
    </TransfersContext.Provider>
  );
};
