import { ClickAwayListener, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { IconButton, useModal } from 'react-ui-kit-exante';

import { useTheme } from 'theme';

import { StyledWithdrawalInfoTooltip } from '../../WithdrawalHistoryTable.styled';
import { WithdrawalInfo } from '../WithdrawalInfo';

import { TWithdrawalInfoTooltipProps } from './WithdrawalInfoTooltip.types';

export const WithdrawalInfoTooltip: FC<TWithdrawalInfoTooltipProps> = ({
  value,
}) => {
  const { isOpened, onClose, onToggle } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ClickAwayListener onClickAway={onClose}>
      <span>
        <StyledWithdrawalInfoTooltip
          placement={isMobile ? 'bottom-start' : 'left-start'}
          title={<WithdrawalInfo data={value} />}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={isOpened}
          onClick={onToggle}
          onClose={onClose}
        >
          <IconButton iconName="InfoIcon" iconSize={16} />
        </StyledWithdrawalInfoTooltip>
      </span>
    </ClickAwayListener>
  );
};
