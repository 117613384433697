import { Outlet } from 'react-router-dom';

import { Chat } from 'components/Chat';
import { useCheckVersion } from 'hooks/useCheckVersion';
import { useIntercomApp } from 'hooks/useIntercomApp';

import { Footer } from '../Footer';
import { Header } from '../Header';

import { StyledMainContent } from './Main.styled';

export const Main = () => {
  useIntercomApp();
  useCheckVersion();

  return (
    <>
      <Header />

      <StyledMainContent className="page__content">
        <Outlet />
      </StyledMainContent>

      <Footer />
      <Chat />
    </>
  );
};
