import { ApiRequest } from 'helpers/apiRequest';
import { Dates } from 'helpers/dates';
import { getPhoneWithoutCode } from 'helpers/formatPhone';
import { notifyWith } from 'helpers/notifyWith';
import {
  TCheckEmail,
  TCodeError,
  TPersonalInfoForm,
  TPersonalManager,
  TUserInfo,
} from 'services/settings/profile/profile.types';

class SettingsProfileService extends ApiRequest {
  async sendEmail(lang = 'en'): Promise<boolean> {
    try {
      await this.fetch<any>({
        url: `/${lang}/clientsarea/rest/gdpr/export-data/`,
      });

      return true;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('sendEmail', error);
      return false;
    }
  }

  async getPersonalManager(lang = 'en'): Promise<TPersonalManager | null> {
    try {
      const { data } = await this.fetch<TPersonalManager>({
        url: `/${lang}/clientsarea/spa-layout/?name=personal-manager`,
      });
      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('getPersonalManager', error);
      return null;
    }
  }

  async saveUserInfo(
    userInfo: TPersonalInfoForm,
    code?: string,
    lang = 'en',
  ): Promise<(TUserInfo & TCodeError) | null> {
    try {
      const res = await this.fetch<TUserInfo & TCodeError>({
        url: `/${lang}/clientsarea/rest/change-request/`,
        method: 'POST',
        data: {
          ...userInfo,
          phone: getPhoneWithoutCode(
            userInfo?.full_phone,
            userInfo?.country_code,
          ),
          date_of_birth: userInfo.date_of_birth
            ? Dates.format(new Date(userInfo.date_of_birth))
            : null,
          code,
        },
      });
      return res.data;
    } catch (error: any) {
      if (error.response?.status === 400 && error.response?.data?.code) {
        return error.response?.data;
      }
      notifyWith.genericNetworkError(error);
      console.error('saveUserInfo', error);
      return null;
    }
  }

  async restrictProcessing(lang = 'en'): Promise<boolean> {
    try {
      await this.fetch<any>({
        url: `/${lang}/clientsarea/rest/gdpr/restrict-processing/`,
        method: 'POST',
      });
      return true;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('restrictProcessing', error);
      return false;
    }
  }

  async deleteAccount(lang = 'en'): Promise<boolean> {
    try {
      await this.fetch<any>({
        url: `/${lang}/clientsarea/rest/gdpr/remove-account/`,
        method: 'POST',
      });
      return true;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('deleteAccount', error);
      return false;
    }
  }

  async checkEmail(email: string, lang = 'en'): Promise<TCheckEmail | null> {
    try {
      const response = await this.fetch<TCheckEmail>({
        url: `/${lang}/clientsarea/rest/check-email/`,
        params: {
          email: encodeURIComponent(email),
        },
      });
      return response.data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('checkEmail', error);
      return null;
    }
  }

  async sendCode(type: 'sms' | 'email' = 'sms', lang = 'en'): Promise<boolean> {
    try {
      const { data } = await this.fetch<Record<any, any>>({
        url: `/${lang}/clientsarea/rest/mfa/${type}/verification-resend/`,
        method: 'POST',
      });
      return !!data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('sendCode', error);
      return false;
    }
  }
}

export const settingsProfileService = new SettingsProfileService();
