export type TProceedTextsItem = {
  heading: string;
  desc: string;
  tob: string;
  partner: string;
};

export const PARTNER_PROCEED_TEXTS: Record<string, TProceedTextsItem> = {
  cpa: {
    heading: 'layout__partner_landing__cpa_title',
    desc: 'layout__partner_landing__proceed__cpa_desc',
    tob: 'layout__partner_landing__proceed__terms_doc',
    partner: 'layout__partner_landing__proceed__cpa_doc',
  },
  revshare: {
    heading: 'layout__partner_landing__revshare_title',
    desc: 'layout__partner_landing__proceed__revshare_desc',
    tob: 'layout__partner_landing__proceed__terms_doc',
    partner: 'layout__partner_landing__proceed__revshare_doc',
  },
};

export const PartnerDocUrls = {
  CFSC_TOB_IND: 'CFSC_TOB',
  CFSC_TOB_CORP: 'CFSC_TOB_Corporate',
  CPA_IND: 'cfsc_cpa',
  REVSHARE_IND: 'cfsc_partner_returns_ind',
  CPA_CORP: 'cfsc_cpa_corporate',
  REVSHARE_CORP: 'cfsc_partner_returns_corp',
} as const;

export const PartnerDocTypes = {
  PARTNER_DOC_TOB: 'tob',
  PARTNER_DOC_PARTNER: 'partner',
} as const;
