import { FC, useContext } from 'react';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';

import { StyledWithdrawalInfoRowCopyButton } from '../../../WithdrawalInfoRow/WithdrawalInfoRow.styled';

import { StyledWithdrawalInfoSecurityPositionValue } from './WithdrawalInfoSecurityPosition.styled';
import { TWithdrawalInfoSecurityPositionProps } from './WithdrawalInfoSecurityPosition.types';

export const WithdrawalInfoSecurityPosition: FC<
  TWithdrawalInfoSecurityPositionProps
> = ({ value: propValue, textKey }) => {
  const { t } = useContext(TranslationContext);

  const value = propValue || DEFAULT_EMPTY_PLACEHOLDER;

  return (
    <tr>
      <th scope="row">{t(textKey)}</th>
      <td>
        <StyledWithdrawalInfoSecurityPositionValue className="WithdrawalInfoSecurityPositionValue">
          <span>{value}</span>
          <StyledWithdrawalInfoRowCopyButton
            text={String(value)}
            hidden={value === DEFAULT_EMPTY_PLACEHOLDER}
          />
        </StyledWithdrawalInfoSecurityPositionValue>
      </td>
    </tr>
  );
};
