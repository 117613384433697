import { FC, memo, useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { getBaseName } from 'helpers/isBranchStand';
import { createRouteList } from 'router/createRouteList';

import { TMemoWithRouterProps } from './withRouter.types';

export const withRouter = (Component: typeof RouterProvider): FC => {
  const MemoComponent: FC<TMemoWithRouterProps> = memo(Component);

  // noinspection UnnecessaryLocalVariableJS -- intended by eslint rule (react/function-component-definition)
  const WithRouter: FC = () => {
    const router = useMemo(
      () =>
        createBrowserRouter(createRouteList(), {
          basename: `${getBaseName()}/`,
        }),
      [],
    );

    return <MemoComponent router={router} />;
  };

  return WithRouter;
};
