import { styled } from 'theme';

export const StyledPersonalInfoContainer = styled('div')`
  display: flex;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  }
`;

export const StyledLine = styled('div')`
  display: none;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: block;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.line.primary};
  }
`;
