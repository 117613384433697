import { TFunction } from 'i18next';
import { ICellValue, IColumn, IRowExpand } from 'react-ui-kit-exante';

import { NameCell } from 'components/Cells';
import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { formatIntegerFloatNumber } from 'helpers/formatAmount';
import {
  formatCurrencyUnrounded,
  TCreateCurrencyFormatter,
} from 'helpers/formatters';
import { TTreeResponseTreeStructure } from 'services/instrumentsTree/instrumentsTree.types';

export const getColumns = (
  t: TFunction,
  loadingInstruments: Set<string>,
  currencyFormatter: TCreateCurrencyFormatter,
): IColumn<TTreeResponseTreeStructure>[] => [
  {
    Header: t('symbol_id'),
    accessor: 'displayName',
    disableSortBy: true,
    id: 'name',
    Cell: ({ row }: { row: IRowExpand<TTreeResponseTreeStructure> }) => {
      return <NameCell row={row} loadingInstruments={loadingInstruments} />;
    },
    minWidth: 240,
  },
  {
    Header: t('generic__short_position'),
    accessor: 'negative',
    disableSortBy: true,
    Cell: ({
      row: { values, original },
    }: ICellValue<TTreeResponseTreeStructure>) => {
      if (original.rowType === 'node') {
        return '';
      }

      return values.negative === null
        ? DEFAULT_EMPTY_PLACEHOLDER
        : formatCurrencyUnrounded(
            currencyFormatter,
            formatIntegerFloatNumber(values.negative * 100),
          );
    },
  },
  {
    Header: t('generic__long_position'),
    accessor: 'positive',
    disableSortBy: true,
    Cell: ({
      row: { values, original },
    }: ICellValue<TTreeResponseTreeStructure>) => {
      if (original.rowType === 'node') {
        return '';
      }

      return values.positive === null
        ? DEFAULT_EMPTY_PLACEHOLDER
        : formatCurrencyUnrounded(
            currencyFormatter,
            formatIntegerFloatNumber(values.positive * 100),
          );
    },
  },
];
