import {
  ActionTypes,
  TActionsState,
  TState,
} from './context/InstrumentsTreeContext.types';

export const reducer = (state: TState, { type, payload }: TActionsState) => {
  switch (type) {
    case ActionTypes.RESET_EXPANDED_ROWS: {
      return {
        ...state,
        expandedRows: {},
      };
    }
    case ActionTypes.SET_EXPANDED_ROWS: {
      return {
        ...state,
        expandedRows: payload,
      };
    }
    case ActionTypes.ADD_DOWNLOADED_PATHS: {
      const clonedDownloadedPaths = new Set(state.downloadedPaths);
      clonedDownloadedPaths.add(payload);

      return {
        ...state,
        downloadedPaths: clonedDownloadedPaths,
      };
    }
    case ActionTypes.RESET_DOWNLOADED_PATHS: {
      return {
        ...state,
        downloadedPaths: new Set<string>(),
      };
    }
    case ActionTypes.SET_TREE_WAS_FILTERED: {
      return {
        ...state,
        treeWasFiltered: payload,
      };
    }
    case ActionTypes.SET_LOADING_INSTRUMENT_ID: {
      return {
        ...state,
        loadingInstruments: payload,
      };
    }
    default:
      return state;
  }
};
