import { FC, useContext, useEffect, useState } from 'react';

import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';

import { ActionTooltip } from '../ActionTooltip';

import { TMfaTooltipProps } from './TotpTooltip.types';
import { DisableTotp } from './components/DisableTotp';
import { EnableTotp } from './components/EnableTotp';

export const TotpTooltip: FC<TMfaTooltipProps> = ({
  isActive,
  children,
  open,
  onClose,
  onSuccess,
}) => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const [totpVerified, setTotpVerified] = useState(false);

  useEffect(() => {
    if (!open) {
      setTotpVerified(false);
    }
  }, [open]);

  const isSmsConfirmation = !branding?.show_2fa_email;

  const totpConfirmationTitle = isSmsConfirmation
    ? t('layout__security_tabs__mfa_modal_sms_activate_header')
    : t('layout__settings__mfa__email_confirmation');

  return !isActive ? (
    <ActionTooltip
      title={
        totpVerified
          ? t('layout__security_tabs__mfa_modal_totp_activate_header')
          : totpConfirmationTitle
      }
      open={open}
      onClose={onClose}
      body={
        <EnableTotp
          onSuccess={onSuccess}
          onSetValue={() => setTotpVerified(true)}
          isSmsConfirmation={isSmsConfirmation}
        />
      }
    >
      {children}
    </ActionTooltip>
  ) : (
    <ActionTooltip
      title={t('layout__security_tabs__mfa_modal_totp_deactivate_header')}
      open={open}
      onClose={onClose}
      body={<DisableTotp onSuccess={onSuccess} />}
    >
      {children}
    </ActionTooltip>
  );
};
