import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { TranslationContext } from 'contexts';
import { TMfaType } from 'services/mfa';
import { fetchApplicationSettings, selectSettings } from 'store/application';
import { useAppDispatch } from 'store/hooks';
import { EStatus } from 'types/api';

import { MfaTooltipState } from '../Mfa.constants';
import { getTooltipState } from '../Mfa.helpers';

export const useMfa = () => {
  const dispatch = useAppDispatch();
  const settings = useSelector(selectSettings);
  const { currentLanguage } = useContext(TranslationContext);

  const [tooltipState, setTooltipState] = useState<MfaTooltipState | null>(
    null,
  );

  const {
    mfa: { allowed, atp, site },
  } = settings;

  const isLoading = settings.status === EStatus.Loading;
  const activeType = atp.type_name || site.type_name;

  const allowedSet = useMemo(() => new Set(allowed), [allowed]);

  const handleOpen = (type: TMfaType) => {
    setTooltipState(getTooltipState(type));
  };

  const handleClose = () => {
    setTooltipState(null);
  };

  const handleRefresh = async () => {
    handleClose();
    dispatch(fetchApplicationSettings(currentLanguage));
  };

  return {
    activeType,
    allowedSet,
    isLoading,
    tooltipState,
    handleOpen,
    handleClose,
    handleRefresh,
  };
};
