import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { notifyWith } from 'helpers/notifyWith';
import { PATHS } from 'router';
import { reportsService } from 'services/reports';
import { ReportStatus, ReportType } from 'services/reports/reports.types';

import { CustomReportsContext } from '../../../contexts/CustomReportsContext';

import {
  StyledDownloadLink,
  StyledMirroredIconButton,
  StyledReportActionsWrapper,
} from './ReportActions.styled';
import { TReportActionsProps } from './ReportActions.types';

export const ReportActions: FC<TReportActionsProps> = ({
  report,
  isMobile,
}) => {
  const { t } = useContext(TranslationContext);

  const { addProcessingId, fetchReports, processingIds } =
    useContext(CustomReportsContext);

  const navigate = useNavigate();

  const isSubuserWithdrawal =
    report.items.length === 1 &&
    report.items[0].report_type === ReportType.SubuserWithdrawals;

  const noFiles = !report.files?.length;

  const isLocalProcessing = processingIds.includes(report.id);
  const isProcessing =
    report.last_status === ReportStatus.Processing || isLocalProcessing;
  const statusText = isLocalProcessing
    ? t('generic__processing_state')
    : report.last_status_text;

  const hasTndflStatus = !!report.tndfl_status;

  const iconSize = isMobile ? 24 : 16;

  const handleSend = async () => {
    const response = await reportsService.sendReport(report.send_url);

    if (response === null) {
      return;
    }

    if (response.success) {
      notifyWith.success(response.message);
      addProcessingId(report.id);
    } else {
      notifyWith.error(response.message);
    }
  };

  const handleDelete = async () => {
    if (await reportsService.deleteReport(report.delete_url)) {
      fetchReports();
    }
  };

  const handleEdit = () => {
    navigate(`${PATHS.ROOT}${PATHS.CUSTOM_REPORTS_EDIT}?id=${report.id}`);
  };

  return (
    <StyledReportActionsWrapper className="ReportActionsWrapper">
      {noFiles || isProcessing ? (
        statusText
      ) : (
        <>
          {!isSubuserWithdrawal && (
            <StyledMirroredIconButton
              className="MirroredIconButton"
              iconName="CircleArrowForwardIcon"
              iconSize={iconSize}
              onClick={handleSend}
            />
          )}
          <StyledDownloadLink
            className="DownloadLink"
            href={report.export_url}
            target="_blank"
            rel="noreferrer"
          >
            <IconButton iconName="DownloadIcon" iconSize={iconSize} />
          </StyledDownloadLink>
          {!isSubuserWithdrawal && (
            <>
              {!hasTndflStatus && (
                <IconButton
                  iconName="EditIcon"
                  iconSize={iconSize}
                  onClick={handleEdit}
                />
              )}
              <IconButton
                iconName="DeleteIcon"
                iconSize={iconSize}
                onClick={handleDelete}
              />
            </>
          )}
        </>
      )}
    </StyledReportActionsWrapper>
  );
};
