import { FC } from 'react';

import { Tooltip } from 'components/Tooltip';

import { StyledInfoIcon } from './AccountSettingsTooltip.styled';

type TAccountSettingsTooltip = {
  tooltipText: string;
};

export const AccountSettingsTooltip: FC<TAccountSettingsTooltip> = ({
  tooltipText,
}) => {
  return (
    <Tooltip title={tooltipText} forceCustomIcon>
      <StyledInfoIcon className="TableHeaderTooltipIcon" />
    </Tooltip>
  );
};
