import { FC, useMemo, useContext } from 'react';
import {
  calculateCountOfPages,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useFetchFeeUsers } from 'pages/AssetManagementClients/components/AssetManagementClientsTable/hooks';

import { StyledAssetManagementClientsTablePanel } from './AssetManagementClientsTable.styled';
import { getAssetManagementClientsTableColumns } from './columns';

export const AssetManagementClientsTable: FC = () => {
  const { t } = useContext(TranslationContext);
  const { fetchFeeUsers, fetchFeeSetupsIsLoading } = useFetchFeeUsers();
  const currencyFormatter = useCurrencyFormatter();

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: fetchFeeUsers },
    }),
    [fetchFeeUsers],
  );

  const { data, limit, setLimit, setPage, page, setSorting } =
    useTableData(tableDataArgs);

  const { data: feeUsers, total } = data || {};

  return (
    <StyledAssetManagementClientsTablePanel className="AssetManagementClientsTablePanel">
      <Table
        tableId="assetManagementTable"
        title={t('asset_management__table__clients')}
        columns={getAssetManagementClientsTableColumns({
          t,
          currencyFormatter,
        })}
        data={feeUsers || []}
        noDataHeight="auto"
        showTableInfo={false}
        isFlexLayout
        translator={t}
        manualSortBy
        hasPagination
        defaultSortBy={[{ id: 'updated', desc: true }]}
        onSort={setSorting}
        isLoading={fetchFeeSetupsIsLoading}
        serverPaginationProps={{
          pageSize: limit ?? 20,
          setPage,
          setPageSize: setLimit,
          pageIndex: page,
          total: total ?? 0,
          pageCount: calculateCountOfPages(total ?? 0, limit),
        }}
      />
    </StyledAssetManagementClientsTablePanel>
  );
};
