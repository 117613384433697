import { useContext, useEffect } from 'react';
import { useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';
import { challengeService } from 'services/challenge';
import { withdrawalService } from 'services/withdrawal';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

export function useFetchChallengePayoutData() {
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const { currentLanguage } = useContext(TranslationContext);

  const getChallenge = async () => {
    if (!selectedAccountId) {
      return null;
    }

    return (await challengeService.getChallenge(selectedAccountId))?.data;
  };

  const {
    fetchData: fetchChallengeHeader,
    data: challenge,
    isLoading: isChallengeHeaderLoading,
  } = useData({ onFetch: getChallenge });

  const {
    fetchData: fetchWithdrawalHistory,
    data: withdrawalHistory,
    isLoading: isWithdrawalHistoryLoading,
  } = useData({
    onFetch: async () =>
      (await withdrawalService.getHistory({ lang: currentLanguage }))?.results,
  });

  useEffect(() => {
    fetchChallengeHeader();
  }, [selectedAccountId]);

  useEffect(() => {
    fetchWithdrawalHistory();
  }, []);

  const value = {
    challenge,
    activeWithdrawal: (withdrawalHistory || []).filter(
      (item) => item.account === selectedAccountId && item.status === 'active',
    )?.[0],
    executedWithdrawal: (withdrawalHistory || []).filter(
      (item) =>
        item.account === selectedAccountId && item.status === 'executed',
    )?.[0],
    isLoading:
      !challenge ||
      isChallengeHeaderLoading ||
      !withdrawalHistory ||
      isWithdrawalHistoryLoading,
  };

  return value;
}
