export const clearNumberString = (
  value?: number | ((amount: number) => number) | string,
) => {
  const formattedEffectiveClear = String(value).includes('.')
    ? String(value).replace(/0*$/, '')
    : String(value);

  return formattedEffectiveClear[formattedEffectiveClear.length - 1] === '.'
    ? formattedEffectiveClear.replace('.', '')
    : formattedEffectiveClear;
};
