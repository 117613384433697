import { Tooltip } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledWithdrawalActionsWrapper = styled('div')`
  min-width: 80px;

  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const StyledWithdrawalToCellText = styled('span')`
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledWithdrawalInfoTooltip = styled(Tooltip)`
  .MuiTooltip-tooltip {
    max-width: none;
  }
`;
