import { FC, ReactNode, useCallback, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { CopyButton } from 'components/CopyButton';
import { TranslationContext } from 'contexts/TranslationContext';
import { TPartnerLinkUpdate } from 'services/partner';

import {
  DEFAULT_PARTNER_URL,
  PartnersContext,
} from '../../../../../../Partner.constants';
import {
  getPartnerShortUrl,
  getPartnerUtmUrl,
  preparePartnerUrl,
} from '../../../../../../Partner.helpers';

import {
  StyledPartnerLinksEditActionInput,
  StyledPartnerLinksEditActionPartnerButton,
  StyledPartnerLinksEditActionWrapper,
} from './PartnerLinksEditAction.styled';
import { TPartnerLinksEditActionProps } from './PartnerLinksEditAction.types';

export const PartnerLinksEditAction: FC<TPartnerLinksEditActionProps> = ({
  link,
  onChange,
}) => {
  const { t } = useContext(TranslationContext);
  const { partnerData } = useContext(PartnersContext);

  const updateField = useCallback(
    <T extends keyof TPartnerLinkUpdate>(
      field: T,
      value: TPartnerLinkUpdate[T],
    ) => {
      onChange((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    [onChange],
  );

  const utmUrl = getPartnerUtmUrl(link);
  const shortUrl = getPartnerShortUrl(link);

  const destinationUrlTooltip = useMemo((): ReactNode => {
    const url = partnerData?.defaultDestinationUrl || DEFAULT_PARTNER_URL;

    return (
      <Trans
        i18nKey="layout__partners__links__tooltip_destination_url"
        components={{
          a: (
            <StyledPartnerLinksEditActionPartnerButton
              className="PartnerLinksEditActionPartnerButton"
              color="transparent"
            />
          ),
        }}
        values={{
          WL_NAME: preparePartnerUrl(url),
        }}
      />
    );
  }, [partnerData?.defaultDestinationUrl]);

  return (
    <StyledPartnerLinksEditActionWrapper className="PartnerLinksEditActionWrapper">
      <StyledPartnerLinksEditActionInput
        label={t('layout__partners__links__name')}
        value={link.name}
        iconRight={
          <Tooltip title={destinationUrlTooltip}>
            <IconButton iconName="InfoIcon" iconSize={16} />
          </Tooltip>
        }
        onChange={(e) => updateField('name', e.target.value)}
        fullWidth
      />
      <StyledPartnerLinksEditActionInput
        label={t('layout__partners__links__destination_url')}
        value={link.site_url}
        iconRight={
          <Tooltip title={t('layout__partners__links__tooltip_name')}>
            <IconButton iconName="InfoIcon" iconSize={16} />
          </Tooltip>
        }
        onChange={(e) => updateField('site_url', e.target.value)}
        fullWidth
      />
      <StyledPartnerLinksEditActionInput
        label={t('layout__partners__links__source')}
        value={link.url}
        iconRight={
          <Tooltip title={t('layout__partners__links__tooltip_source')}>
            <IconButton iconName="InfoIcon" iconSize={16} />
          </Tooltip>
        }
        onChange={(e) => updateField('url', e.target.value)}
        fullWidth
      />
      <StyledPartnerLinksEditActionInput
        label={t('layout__partners__links__full_link')}
        value={utmUrl}
        iconRight={
          <CopyButton
            text={utmUrl}
            iconColor="action"
            tooltipProps={{ placement: 'top' }}
          />
        }
        readOnly
        fullWidth
      />
      <StyledPartnerLinksEditActionInput
        label={t('layout__partners__links__short_link')}
        value={shortUrl}
        iconRight={
          <CopyButton
            text={shortUrl}
            iconColor="action"
            tooltipProps={{ placement: 'top' }}
          />
        }
        readOnly
        fullWidth
      />
    </StyledPartnerLinksEditActionWrapper>
  );
};
