import { FC, useContext } from 'react';
import { IconButton, Notification } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import {
  StyledCardOrderContentWrapper,
  StyledCardOrderIssueHeader,
  StyledCardOrderIssueHeaderText,
  StyledCardSettingsOrderWrapper,
} from 'pages/CardSettings/components/CardSettingsOrder/CardSettingsOrder.styled';
import {
  CardSettingsOrderForm,
  CardSettingsOrderInfo,
  CardSettingsOrderNotAvailable,
} from 'pages/CardSettings/components/CardSettingsOrder/components';
import { CardSettingsContext } from 'pages/CardSettings/contexts';

import { IssueType } from '../../contexts/CardSettingsContext/CardSettingsContext.constants';

import { CardSettingsOrderSkeleton } from './CardSettingsOrderSkeleton';

export const CardSettingsOrder: FC = () => {
  const { t } = useContext(TranslationContext);
  const { issueType, onCloseIssue, order, cardsLoading } =
    useContext(CardSettingsContext);

  const onOrderSuccess = () => {
    Notification.success({
      title: t('layout__cards__card_ordered'),
      description: (
        <p>
          <span>{`${t('layout__cards__card_will_be_delivered')} `}</span>
          <span>{t('layout__cards__card_will_be_issued')}</span>
        </p>
      ),
    });
  };

  return (
    <StyledCardSettingsOrderWrapper className="CardSettingsOrderWrapper">
      {!!issueType && (
        <StyledCardOrderIssueHeader className="CardOrderIssueHeader">
          <StyledCardOrderIssueHeaderText className="CardOrderIssueHeaderText">
            {issueType === IssueType.Reissue
              ? t('layout__cards__reissue_the_card')
              : t('layout__cards__order_card')}
          </StyledCardOrderIssueHeaderText>
          <IconButton
            iconSize={24}
            iconName="CloseIcon"
            onClick={onCloseIssue}
            disabled={cardsLoading}
          />
        </StyledCardOrderIssueHeader>
      )}

      <StyledCardOrderContentWrapper className="CardOrderContentWrapper">
        {cardsLoading ? (
          <CardSettingsOrderSkeleton />
        ) : (
          <>
            {order.available && <CardSettingsOrderInfo />}
            {!order.available && <CardSettingsOrderNotAvailable />}
          </>
        )}

        <CardSettingsOrderForm
          orderIsDisabled={!order.available}
          onOrderSuccess={onOrderSuccess}
        />
      </StyledCardOrderContentWrapper>
    </StyledCardSettingsOrderWrapper>
  );
};
