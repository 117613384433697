import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts';
import {
  StyledCardOrderInfoHeader,
  StyledOrderInfoParagraph,
  StyledOrderInfoWrapper,
} from 'pages/CardSettings/components/CardSettingsOrder/components/CardSettingsOrderNotAvailable/CardSettingsOrderNotAvailable.styled';

export const CardSettingsOrderNotAvailable: FC = () => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledOrderInfoWrapper className="OrderInfoWrapper">
      <StyledCardOrderInfoHeader className="CardOrderInfoHeader">
        {t('layout__cards__get_your_card_not_available')}
      </StyledCardOrderInfoHeader>
      <StyledOrderInfoParagraph className="OrderInfoParagraph">
        {t('layout__cards__get_your_card_not_available__reason')}
      </StyledOrderInfoParagraph>
    </StyledOrderInfoWrapper>
  );
};
