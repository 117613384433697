import { FC } from 'react';

import {
  StyledAccountSummarySkeletonBlock,
  StyledAccountSummarySkeletonDeleteBlock,
  StyledAccountSummarySkeletonProgressBlock,
  StyledAssetManagementSetupSkeletonWrapper,
} from './AssetManagementSetupSkeleton.styled';

export const AssetManagementSetupSkeleton: FC = () => {
  const skeletonsCount = 5;

  return (
    <StyledAssetManagementSetupSkeletonWrapper>
      {[...Array(skeletonsCount)].map((_, i) => (
        <StyledAccountSummarySkeletonProgressBlock
          key={`block_${i}`}
          variant="rounded"
          animation="wave"
        />
      ))}
      {[...Array(skeletonsCount)].map((_, i) => (
        <StyledAccountSummarySkeletonBlock
          key={`block_${i}`}
          variant="rounded"
          animation="wave"
        />
      ))}
      <StyledAccountSummarySkeletonDeleteBlock
        variant="rounded"
        animation="wave"
      />
    </StyledAssetManagementSetupSkeletonWrapper>
  );
};
