import { type TCardSettingsContextValue } from './CardSettingsContext.types';

export enum IssueType {
  Issue = 'issue',
  Reissue = 'reissue',
}

export const cardSettingsContextInitialValue: TCardSettingsContextValue = {
  cardsLoading: false,
  cards: null,
  order: {
    available: null,
    cardholderName: null,
    deliveryLocation: null,
    availableCardTypes: null,
  },
  issueType: null,
  isIssueAvailable: false,
  cardholderFullName: null,
  currencies: null,
  cardFeesCurrency: null,
  isOrdering: false,
  onCloseIssue: () => {},
  onOrder: () => Promise.resolve(false),
  onIssue: () => {},
  onReissue: () => {},
  updateCards: () => {},
  getCardFeeByType: () => undefined,
};
