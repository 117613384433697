import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { StyledPageWrapper } from 'components/Main';
import { Tabs } from 'components/Tabs';
import { TranslationContext } from 'contexts';
import { TabsCacheProvider } from 'contexts/TabsCacheContext';
import { StyledSettingsPanel } from 'pages/Settings/Settings.styled';
import { SETTINGS_TAB_ID } from 'services/tabs';

export const SettingsWithContext = () => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledPageWrapper className="SettingsWrapper">
      <StyledSettingsPanel title={t('menu_item__profile')}>
        <Tabs disableAccountValidation tabsForShow={SETTINGS_TAB_ID} />
        <TabsCacheProvider>
          <Outlet />
        </TabsCacheProvider>
      </StyledSettingsPanel>
    </StyledPageWrapper>
  );
};
