import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledTextSkeletonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledTextSkeleton = styled(Skeleton)`
  width: 300px;
  height: 16px;
`;

export const StyledSearchInputSkeleton = styled(Skeleton)`
  height: 48px;
`;
