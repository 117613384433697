import { CheckmarkIcon, CircleClockIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledStatusWrapper = styled('div')`
  display: flex;
  gap: 8px;
`;

export const StyledStatusWrapperSuccess = styled(StyledStatusWrapper)`
  color: ${({ theme }) => theme.color.icon.action};
`;

export const StyledStatusWrapperWarning = styled(StyledStatusWrapper)`
  color: ${({ theme }) => theme.color.icon.warning};
`;

export const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
`;

export const StyledCircleClockIcon = styled(CircleClockIcon)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
`;
