import { createContext, FC, useContext, useMemo, useState } from 'react';
import { useTableData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { TReport, TReportsResponse } from 'services/reports/reports.types';
import { TUseTableDataProps } from 'types';
import { TChildren } from 'types/TChildren';

import { CUSTOM_REPORTS_TABLE_ID } from '../../CustomReportsTable.constants';

import { fetchCustomReports } from './CustomReportsContext.helpers';

type TCustomReportsTableData = {
  isLoading: boolean;
  data: TReportsResponse | null;
  limit: number;
  page: number;
  setLimit: (newLimit: number) => void;
  setPage: (updater: number | ((pageIndex: number) => number)) => void;
};

type TCustomReportsContextState = {
  processingIds: TReport['id'][];
  addProcessingId: (id: TReport['id']) => void;
  fetchReports: () => void;
  tableData: TCustomReportsTableData;
};

const initialState: TCustomReportsContextState = {
  processingIds: [],
  addProcessingId: () => {},
  fetchReports: () => {},
  tableData: {
    isLoading: true,
    data: null,
    limit: 0,
    page: 0,
    setLimit: () => {},
    setPage: () => {},
  },
};

export const CustomReportsContext =
  createContext<TCustomReportsContextState>(initialState);

export const CustomReportsProvider: FC<TChildren> = ({ children }) => {
  const { currentLanguage } = useContext(TranslationContext);

  const [processingIds, setProcessingIds] = useState<TReport['id'][]>([]);

  const tableDataArgs = useMemo<TUseTableDataProps<TReportsResponse | null>>(
    () => ({
      tableId: CUSTOM_REPORTS_TABLE_ID,
      data: {
        onFetch: (args) => {
          setProcessingIds([]);
          return fetchCustomReports({ ...args, lang: currentLanguage });
        },
      },
    }),
    [currentLanguage],
  );

  const { fetchData: fetchReports, ...tableData } =
    useTableData<TReportsResponse | null>(tableDataArgs);

  const addProcessingId = (id: TReport['id']) => {
    setProcessingIds((ids) => [...ids, id]);
  };

  const value = useMemo(
    () => ({
      processingIds,
      addProcessingId,
      fetchReports,
      tableData,
    }),
    [processingIds, addProcessingId, fetchReports, tableData],
  );

  return (
    <CustomReportsContext.Provider value={value}>
      {children}
    </CustomReportsContext.Provider>
  );
};
