import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { INITIAL_CONTEXT, INITIAL_STATE } from './constants';
import { reducer } from './reducer';
import { TContext } from './types';

const TermsContext = createContext<TContext>(INITIAL_CONTEXT);

export const TermsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const value = useMemo<TContext>(() => ({ state, dispatch }), [state]);

  return (
    <TermsContext.Provider value={value}>{children}</TermsContext.Provider>
  );
};

export function useTermsContext() {
  return useContext(TermsContext);
}

export * from './actions';
