import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { StyledFormCheckboxContainer } from './FormCheckboxContainer.styled';
import { TFormCheckboxContainerProps } from './FormCheckboxContainer.types';

export const FormCheckboxContainer: FC<TFormCheckboxContainerProps> = ({
  label,
  name,
  noWrap = false,
  disabled = false,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value = false },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  return (
    <StyledFormCheckboxContainer
      checked={value}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      noWrap={noWrap}
      error={!!error?.message}
      message={error?.message}
      {...rest}
    />
  );
};
