import { TFunction } from 'i18next';
import { ICellValue, IColumn } from 'react-ui-kit-exante';

import {
  StyledMobileCell,
  StyledMobileCellRow,
  StyledMobileCellRowValue,
  StyledMobileCellTitleRow,
} from 'pages/Reports/Reports.styled';
import { TReport } from 'services/reports/reports.types';

import { ReportAccounts } from '../../../../components/ReportAccounts';
import { ReportNames } from '../../../../components/ReportNames';
import { ReportPeriods } from '../../../../components/ReportPeriods';

import { GeneratedActionCell } from './GeneratedActionCell';
import { StyledAccountsMobile } from './GeneratedReportsTable.styled';
import { GeneratedStatusCell } from './GeneratedStatusCell';

type TPeriodicReportsColumns = {
  t: TFunction;
};

const renderStatusCell = {
  align: 'center',
  Cell: ({ row: { original }, column }: ICellValue<TReport>) => {
    return <GeneratedStatusCell data={original} columnName={column.id} />;
  },
};

export const getColumns: ({
  t,
}: TPeriodicReportsColumns) => IColumn<TReport>[] = ({ t }) => [
  {
    id: 'build_name',
    Header: t('layout__reports__table__type'),
    accessor: 'build_name',
  },
  {
    id: 'accounts',
    Header: t('generic__account'),
    accessor: 'accounts',
    minWidth: 230,
    Cell: ({ row: { original } }: ICellValue<TReport>) => {
      return <ReportAccounts report={original} />;
    },
  },
  {
    id: 'name',
    Header: t('layout__reports__table__name'),
    accessor: 'name',
    minWidth: 200,
    Cell: ({ row: { original } }: ICellValue<TReport>) => {
      return <ReportNames report={original} />;
    },
  },
  {
    id: 'period',
    Header: t('generic__period'),
    accessor: 'period',
    minWidth: 220,
    Cell: ({ row: { original } }: ICellValue<TReport>) => {
      return <ReportPeriods report={original} />;
    },
  },
  {
    id: 'csv',
    Header: t('generic__csv'),
    accessor: 'csv',
    maxWidth: 90,
    ...renderStatusCell,
  },
  {
    id: 'pdf',
    Header: t('generic__pdf'),
    accessor: 'pdf',
    maxWidth: 90,
    ...renderStatusCell,
  },
  {
    id: 'xls',
    Header: t('generic__xls'),
    accessor: 'xls',
    maxWidth: 90,
    ...renderStatusCell,
  },
  {
    id: 'action',
    Header: t('generic__actions'),
    accessor: 'action',
    align: 'right',
    width: 90,
    Cell: ({ row: { original } }: ICellValue<TReport>) => (
      <GeneratedActionCell data={original} />
    ),
  },
];

export const getColumnsMobile: ({
  t,
}: TPeriodicReportsColumns) => IColumn<TReport>[] = ({ t }) => [
  {
    id: 'info',
    Cell: ({ row: { original } }: ICellValue<TReport>) => {
      const getIsFormatAvailable = (columnName: string) =>
        original.format?.some(
          (format) => format.toLowerCase() === columnName.toLowerCase(),
        );
      return (
        <StyledMobileCell className="MobileCell" title="">
          <StyledMobileCellTitleRow className="MobileCellRow">
            {original.build_name}
            <GeneratedActionCell data={original} iconSize={24} />
          </StyledMobileCellTitleRow>
          <StyledMobileCellRow className="MobileCellRow">
            {t('generic__account')}
            <StyledMobileCellRowValue className="MobileCellRowValue">
              <StyledAccountsMobile className="AccountsMobile">
                <ReportAccounts report={original} />
              </StyledAccountsMobile>
            </StyledMobileCellRowValue>
          </StyledMobileCellRow>
          <StyledMobileCellRow className="MobileCellRow">
            {t('layout__reports__table__name')}{' '}
            <StyledMobileCellRowValue className="MobileCellRowValue">
              <ReportNames report={original} />
            </StyledMobileCellRowValue>
          </StyledMobileCellRow>
          <StyledMobileCellRow className="MobileCellRow">
            {t('generic__period')}
            <StyledMobileCellRowValue className="MobileCellRowValue">
              <ReportPeriods report={original} />
            </StyledMobileCellRowValue>
          </StyledMobileCellRow>
          {getIsFormatAvailable('csv') && (
            <StyledMobileCellRow className="MobileCellRow">
              {t('generic__csv')}
              <GeneratedStatusCell data={original} columnName="csv" />
            </StyledMobileCellRow>
          )}
          {getIsFormatAvailable('pdf') && (
            <StyledMobileCellRow className="MobileCellRow">
              {t('generic__pdf')}
              <GeneratedStatusCell data={original} columnName="pdf" />
            </StyledMobileCellRow>
          )}
          {getIsFormatAvailable('xls') && (
            <StyledMobileCellRow className="MobileCellRow">
              {t('generic__xls')}
              <GeneratedStatusCell data={original} columnName="xls" />
            </StyledMobileCellRow>
          )}
        </StyledMobileCell>
      );
    },
  },
];
