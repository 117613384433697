export const MAX_FILE_SIZE_MB = 10;
export const ACCEPT_FILE_FORMATS = [
  'png',
  'jpeg',
  'jpg',
  'tif',
  'bmp',
  'gif',
  'pdf',
  'doc',
  'docx',
];

export const ACCEPT_FILE_LIST = ACCEPT_FILE_FORMATS.join(', ');
