import { blockNonNativeProps, css } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TItemStyledProps } from './AccountCard.types';

export const StyledAccountCardItem = styled('button', {
  shouldForwardProp: blockNonNativeProps(['value', 'selected', 'imageUrl']),
})<TItemStyledProps>`
  position: relative;

  width: 166px;
  height: 104px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 8px 16px 16px;

  text-align: left;
  font-size: 13px;
  font-family: Inter, sans-serif;

  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  transition: transform 0.4s;

  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-color: ${({ theme }) => theme.color.bg.primary};

  ${({ imageUrl }) => imageUrl && `background-image: url(${imageUrl});`}
  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.color.typo.action};
  }

  ${({ theme, selected }) => {
    const bgColor = selected
      ? theme.color.bg.secondary
      : theme.color.bg.primary;
    const veilColor = theme.color.bg.primary;

    if (selected) {
      return css`
        background-color: ${bgColor};
      `;
    }
    return css`
      &::before {
        content: '';
        background-color: ${veilColor};
        width: 100%;
        height: 100%;
        opacity: 0.9;
        top: 0;
        left: 0;
        position: absolute;
        border-radius: 2px;
      }
    `;
  }}
  ${({ theme, disabled }) => !disabled && theme.breakpoints.up('md')} {
    &:hover {
      transform: translate(0, -8px);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const StyledAccountCardAll = styled(StyledAccountCardItem)`
  margin-right: 8px;
  margin-top: 8px;
`;

export const StyledAccountCardItemDesc = styled('span')`
  width: 100%;
  overflow-x: hidden;

  font-size: 13px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.typo.secondary};
  position: relative;
`;

export const StyledAccountCardItemValue = styled('span')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  position: relative;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledAccountCardItemCardNumber = styled('span')`
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.ghost};
  position: relative;
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const StyledAccountCardItemCardNumberDigits = styled('span')`
  &::before {
    display: inline;
    content: '•';
    margin-right: 4px;
  }
`;

export const StyledAccountCardItemCardStatus = styled('span')`
  position: absolute;
  right: 16px;
  top: 12px;
  width: 24px;
  height: 24px;
`;

export const StyledAccountCardItemCardType = styled('span')`
  position: absolute;
  right: 16px;
  bottom: 12px;
  width: 24px;
  height: 24px;
`;
