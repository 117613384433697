import { styled } from 'theme';

export const StyledTotpStepIcons = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

export const StyledTotpStepIcon = styled('a')`
  border: 1px solid ${({ theme }) => theme.color.icon.secondary};
  color: ${({ theme }) => theme.color.icon.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 48px;
`;
