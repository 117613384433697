import { styled } from 'theme';

export const StyledAddActionContainer = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 256px;
  justify-content: stretch;

  .MuiToggleButtonGroup-root {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledAddActionFooter = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;
