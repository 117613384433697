import { FC, useContext, useMemo } from 'react';
import { Button } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { codeLength } from 'helpers/codeLength';

import {
  StyledW8AffidavitFormFooter,
  StyledW8AffidavitFormHeader,
  StyledW8AffidavitFormWrapper,
} from './W8AffidavitForm.styled';
import { TW8AffidavitFormProps } from './W8AffidavitForm.types';
import { AffidavitFormConfirm } from './components/AffidavitFormConfirm';
import { AffidavitFormSign } from './components/AffidavitFormSign';
import { AffidavitFormContext } from './contexts/AffidavitFormContext';
import { AFFIDAVIT_FORM_TERMINAL_STATUSES } from './contexts/AffidavitFormContext/AffidavitFormContext.consts';
import { AffidavitFormStatus } from './contexts/AffidavitFormContext/AffidavitFormContext.types';

export const W8AffidavitForm: FC<TW8AffidavitFormProps> = ({ onCancel }) => {
  const { t } = useContext(TranslationContext);

  const {
    status,
    signOptions,
    signOptionsChecked,
    isSigned,
    requestSign,
    requestConfirm,
    code,
    mfaType,
  } = useContext(AffidavitFormContext);

  const isSomeOptionsChecked = useMemo(
    () => signOptions.some(({ value }) => signOptionsChecked[value]),
    [signOptions, signOptionsChecked],
  );

  const handleSign = () => {
    requestSign();
  };

  const handleConfirm = () => {
    if (code) {
      requestConfirm();
    }
  };

  const isLoading = !AFFIDAVIT_FORM_TERMINAL_STATUSES.includes(status);

  const signDisabled = !isSomeOptionsChecked;
  const acceptDisabled =
    code.length < codeLength(mfaType) || status !== AffidavitFormStatus.Default;

  return (
    <StyledW8AffidavitFormWrapper className="W8AffidavitFormWrapper">
      <StyledW8AffidavitFormHeader className="W8AffidavitFormHeader">
        {t('layout__w8_affidavit_form_header')}
      </StyledW8AffidavitFormHeader>
      {!isSigned ? <AffidavitFormSign /> : <AffidavitFormConfirm />}

      <StyledW8AffidavitFormFooter className="W8AffidavitFormFooter">
        {!isSigned ? (
          <Button
            textColor="action"
            color="transparent"
            disabled={signDisabled}
            onClick={handleSign}
          >
            {t('generic__action__sign')}
          </Button>
        ) : (
          <Button
            textColor="action"
            color="transparent"
            disabled={acceptDisabled || isLoading}
            onClick={handleConfirm}
            loading={isLoading}
          >
            {t('generic__action__confirm')}
          </Button>
        )}
        <Button onClick={onCancel} textColor="secondary" color="transparent">
          {t('generic__action__cancel')}
        </Button>
      </StyledW8AffidavitFormFooter>
    </StyledW8AffidavitFormWrapper>
  );
};
