import { styled } from 'theme';

export const StyledPersonalManagerContainer = styled('div')`
  padding: 24px;
  border-right: 1px solid ${({ theme }) => theme.color.line.primary};
  max-width: 256px;
  min-width: 256px;

  a {
    color: ${({ theme }) => theme.color.typo.action};
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.color.typo.hover};
      text-decoration: underline;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    border: none;
  }
`;

export const StyledPersonalManagerTitle = styled('div')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
`;

export const StyledGetPersonalManager = styled('div')`
  font-size: 13px;
  line-height: 16px;
`;

export const StyledGetPersonalManagerContainer = styled('div')`
  display: flex;

  svg {
    min-width: 36px;
    margin-right: 10px;
  }
`;

export const StyledPersonalManagerContactCard = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const StyledPersonalManagerContacts = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 24px;
`;

export const StyledContactTitle = styled('div')`
  font-weight: 500;
`;

export const StyledContactExtension = styled('div')`
  color: ${({ theme }) => theme.color.typo.promo};
  font-weight: 400;
`;

export const StyledPersonalManagerSkeletonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledPersonalManagerSkeletonRow = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
