import { useContext, useMemo } from 'react';

import { TranslationContext } from 'contexts/index';
import { currencyFormatter } from 'helpers/formatters';

export const useCurrencyFormatter = (currency = '') => {
  const { currentLanguage } = useContext(TranslationContext);

  return useMemo(
    () => currencyFormatter(currentLanguage, currency),
    [currentLanguage, currency],
  );
};
