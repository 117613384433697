import { FC, useContext } from 'react';

import { CurrencySelect } from 'components/CurrencySelect';
import { TranslationContext } from 'contexts';
import { DepositCryptoContext } from 'pages/Portfolio/tabs/Deposit/contexts';

import {
  StyledDepositSectionBody,
  StyledDepositSectionHeading,
} from '../../../DepositWrapper/DepositWrapper.styled';

import { StyledCryptoAccountSectionWrapper } from './AccountCryptoSection.styled';

export const AccountCryptoSection: FC = () => {
  const { t } = useContext(TranslationContext);
  const { currencies, setCurrency, currency } =
    useContext(DepositCryptoContext);

  return (
    <StyledCryptoAccountSectionWrapper className="CryptoAccountSectionWrapper">
      <StyledDepositSectionHeading className="DepositSectionHeading">
        {t('layout__deposit__choose_a_coin')}
      </StyledDepositSectionHeading>
      <StyledDepositSectionBody className="DepositSectionBody">
        <CurrencySelect
          options={currencies?.list || []}
          icons={currencies?.icons || {}}
          value={currency}
          onChange={setCurrency}
          data-test-id="account_crypto__select__currency"
        />
      </StyledDepositSectionBody>
    </StyledCryptoAccountSectionWrapper>
  );
};
