import { FC, ReactElement, useContext, useState } from 'react';
import { useData } from 'react-ui-kit-exante';

import { BlockCardIcon } from 'assets/icons';
import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { TranslationContext } from 'contexts';
import {
  TooltipContent,
  TTooltipContentButton,
} from 'pages/CardSettings/components/CardSettingsCard/components/TooltipContent';
import { cardService } from 'services/cards';

import {
  StyledBlockCardAction,
  StyledBlockModalDescription,
} from './BlockAction.styled';
import { TBlockActionProps } from './BlockAction.types';

export const BlockAction: FC<TBlockActionProps> = ({ cardId, onBlock }) => {
  const { t, currentLanguage } = useContext(TranslationContext);

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const { isLoading: isBlocking, fetchData: blockCard } = useData({
    onFetch: () => cardService.blockCard({ cardId, lang: currentLanguage }),
    onSuccess: () => {
      onBlock?.();
      hideTooltip();
    },
  });

  const confirmButton: TTooltipContentButton = {
    label: t('generic__action__block'),
    handler: blockCard,
    disabled: !isBlocking,
    dangerStyle: true,
  };

  const cancelButton: TTooltipContentButton = {
    label: t('generic__action__cancel'),
    handler: hideTooltip,
  };

  const tooltip: ReactElement = (
    <TooltipContent
      icon={<BlockCardIcon />}
      title={t('layout__cards__card_blocking')}
      cancel={cancelButton}
      confirm={confirmButton}
    >
      <StyledBlockModalDescription className="BlockModalDescription">
        {t('layout__cards__card_blocked_and_irreversible')}
      </StyledBlockModalDescription>
    </TooltipContent>
  );

  return (
    <ActionWithConfirmation
      content={tooltip}
      externalOpened={tooltipVisible}
      closeHandler={hideTooltip}
      openFromExternal
      hideDefaultButtons
    >
      <StyledBlockCardAction
        iconName="BlockCardIcon"
        label={t('generic__action__block')}
        onClick={showTooltip}
      />
    </ActionWithConfirmation>
  );
};
