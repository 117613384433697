import { pick } from 'lodash';

import {
  TInstrumentsInfo,
  TransferType,
  TSecuritiesSymbol,
  TSecuritiesSymbolForSend,
} from 'services/transfer';

import { positionFields } from './Securities.consts';
import { TSecurityField, TTransferSecuritiesForm } from './Securities.types';

export const getTransferSecuritiesForm = (
  fields: string[],
  accountId: string,
): TTransferSecuritiesForm => {
  return (fields as Array<keyof TTransferSecuritiesForm>).reduce(
    (acc, curr) => {
      if (curr === 'authorize_confirm') {
        return { ...acc, [curr]: false };
      }
      if (curr === 'account') {
        return { ...acc, [curr]: accountId };
      }

      if (curr === 'files') {
        return { ...acc, [curr]: [] };
      }

      if (curr === 'type_of_transaction') {
        return { ...acc, [curr]: 'Free Delivery' };
      }

      if (curr === 'type') {
        return { ...acc, [curr]: TransferType.Security };
      }

      // we are validating positions fields without react-hook-form
      if (curr === 'positions') {
        return {
          ...acc,
        };
      }
      return { ...acc, [curr]: '' };
    },
    {},
  );
};

export const getMappedPositions = (
  fields: TSecurityField[] = [],
  positions: TSecuritiesSymbol[] = [],
  instruments: TInstrumentsInfo | null = null,
) => {
  return fields.reduce<TSecuritiesSymbolForSend[]>((acc, curr) => {
    const selectedPosition = positions.find(
      (pos) => pos.symbolId === curr.symbolId,
    );

    if (!selectedPosition) {
      return acc;
    }

    const instrumentInfo = instruments?.[selectedPosition.type.toLowerCase()];

    return [
      ...acc,
      {
        instrumentType: selectedPosition.type,
        value: curr.quantity,
        instrumentTypeName: instrumentInfo?.name || '',
        image: instrumentInfo?.image || '',
        ...pick(selectedPosition, positionFields),
      },
    ];
  }, []);
};
