import { useContext } from 'react';

import { Text } from 'components/Text';
import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';

import {
  StyledFooterContainer,
  StyledUrls,
  StyledUrlsItem,
  StyledMessage,
} from './Footer.styled';
import { TFooterProps } from './Footer.types';

export const Footer = ({ clientID, dataURL, tradeURL }: TFooterProps) => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const message = t('layout__settings__api__usage_message').replace(
    '[URL]',
    `<a href="${branding?.help_center}">${t('generic__help')}</a>`,
  );

  return (
    <StyledFooterContainer className="FooterContainer">
      <StyledMessage
        className="Message"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <StyledUrls className="Urls">
        <StyledUrlsItem className="UrlsItem">
          <Text withCopyButton weight="600">
            {tradeURL}
          </Text>
          <Text size="13px" color="secondary">
            {t('layout__dashboard__api_trading_endpoint')}
          </Text>
        </StyledUrlsItem>

        <StyledUrlsItem className="UrlsItem">
          <Text withCopyButton weight="600">
            {dataURL}
          </Text>
          <Text size="13px" color="secondary">
            {t('layout__dashboard__api_data_endpoint')}
          </Text>
        </StyledUrlsItem>

        <StyledUrlsItem className="UrlsItem">
          <Text withCopyButton weight="600">
            {clientID}
          </Text>
          <Text size="13px" color="secondary">
            {t('client_id')}
          </Text>
        </StyledUrlsItem>
      </StyledUrls>
    </StyledFooterContainer>
  );
};
