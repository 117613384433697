export const CONNECT_STATUS_COOKIE_NAME = 'connect_status';

export enum ConnectStatus {
  BindingSuccess = 'binding_success',
  BindingError = 'binding_error_user_bound',
}

export const CONNECT_STATUS_TEXTS: Record<ConnectStatus, string> = {
  [ConnectStatus.BindingSuccess]: 'layout__binding__account_connected',
  [ConnectStatus.BindingError]: 'layout__binding__error_account_used',
};
