export const enum OnAddAction {
  OnSaveError = 'AddAction/On/Save/Error',
  OnSaveStart = 'AddAction/On/Save/Start',
  OnSaveSuccess = 'AddAction/On/Save/Success',
  OnSetDescription = 'AddAction/On/Set/Description',
  OnSetEnvironment = 'AddAction/On/Set/Environment',
  OnSetPassword = 'AddAction/On/Set/Password',
  OnSetScopes = 'AddAction/On/Set/Scopes',
  OnResetState = 'AddAction/On/Reset/State',
}
