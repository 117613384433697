import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@mui/material';
import { debounce } from 'lodash';
import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  IconButton,
  Input,
  Skeleton,
  Notification as UiKitNotification,
} from 'react-ui-kit-exante';

import { LockFullIcon, ProfileBorderIcon } from 'assets/icons';
import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { RESEND_CODE_TIME } from 'constants/RESEND_CODE_TIME';
import { EXANTE_SUPPORT_EMAIL } from 'constants/WHITELABEL';
import { INPUT_DEBOUNCE_DELAY } from 'constants/common';
import {
  BrandingContext,
  TranslationContext,
  UserDetailsContext,
} from 'contexts';
import { splitByCountryCode } from 'helpers/phone';
import { useTimer } from 'hooks/useTimer';
import { SettingsDataContext } from 'pages/Settings/contexts/SettingsDataContext';
import { TPersonalInfoForm, settingsProfileService } from 'services/settings';
import { useTheme } from 'theme';

import { PERSONAL_INFO_FORM_VALIDATION_SCHEMA } from '../../PersonalInfo.consts';
import { getPersonalInfoForm } from '../../PersonalInfo.helpers';

import { notifyMessages } from './UserInfo.consts';
import {
  StyledCodeButtons,
  StyledDeleteAccountButton,
  StyledDeleteAccountContainer,
  StyledDeleteAccountIcon,
  StyledNoCodeText,
  StyledResendCode,
  StyledResendCodeButton,
  StyledSaveButton,
  StyledUserInfoActions,
  StyledUserInfoContainer,
  StyledUserInfoHeader,
  StyledUserInfoName,
  StyledUserInfoNoPermissionText,
} from './UserInfo.styled';
import { UserInfoForm } from './UserInfoForm';

export const UserInfo: FC = () => {
  const { isLoading: isPageLoading } = useContext(SettingsDataContext);

  const { t, currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const { start, timeSecondsFormat, time, clear } = useTimer(RESEND_CODE_TIME);

  const { userDetails, userDetailsFetch, userDetailsIsLoading } =
    useContext(UserDetailsContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const notifies = notifyMessages(t, branding);

  const [isDataSaving, setIsDataSaving] = useState(false);
  const [isEmailChecking, setIsEmailChecking] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [show2FAPopup, setShow2FAPopup] = useState(false);
  const [codeIsSending, setCodeIsSending] = useState(false);
  const [codeIsSend, setCodeIsSend] = useState(false);
  const [code, setCode] = useState('');
  const [isCodeError, setIsCodeError] = useState(false);

  const {
    current: userValues,
    diff,
    isCorporate = false,
    is_can_edit: canEdit,
    has_application: hasApplication,
  } = userDetails?.info || {};

  const {
    is_can_remove_account: isCanRemove,
    is_can_restrict_processing: isCanRestricting,
  } = userDetails?.gdpr || {};

  const defaultValues = getPersonalInfoForm(diff?.fields, isCorporate);

  const resolver = useMemo(
    () =>
      yupResolver(
        PERSONAL_INFO_FORM_VALIDATION_SCHEMA(t, isCorporate, isEmailError),
      ),
    [isCorporate, isEmailError, t],
  );

  const methods = useForm<TPersonalInfoForm>({
    defaultValues,
    resolver,
  });

  const {
    handleSubmit,
    getValues,
    formState: { dirtyFields },
    reset,
    watch,
    setError,
    clearErrors,
  } = methods;

  const isLoading = userDetailsIsLoading || isDataSaving || isPageLoading;

  const isDisabled =
    !canEdit ||
    !Object.keys(dirtyFields).length ||
    isLoading ||
    isEmailChecking;

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 4) {
      setCode(e.target.value);
    }
  };

  const onClose2FAPopup = () => {
    setShow2FAPopup(false);
    setCode('');
    setIsCodeError(false);
    setCodeIsSend(false);
  };

  const onSendCode = async () => {
    if (time === 0) {
      start();
    }
    setCodeIsSending(true);
    const result = await settingsProfileService.sendCode(
      'sms',
      currentLanguage,
    );
    setCodeIsSending(false);
    setCodeIsSend(true);
    return result;
  };

  const onSave = async () => {
    try {
      setIsDataSaving(true);
      setIsCodeError(false);

      const formData = getValues();

      const payload = {
        ...formData,
        full_phone: splitByCountryCode(
          formData.full_phone,
          formData.country_code,
        ),
      };

      const response = await settingsProfileService.saveUserInfo(
        payload,
        code,
        currentLanguage,
      );

      if (response) {
        if (response?.code) {
          if (!codeIsSend) {
            const sendCodeResult = await onSendCode();
            if (sendCodeResult) {
              setShow2FAPopup(true);
            }
          } else {
            setIsCodeError(true);
          }
        } else {
          clear();
          onClose2FAPopup();
          userDetailsFetch();
          reset();
          UiKitNotification.success({
            title: t('layout__actions__your_request_has_been_sent'),
          });
        }
      }
    } catch (error: any) {
      UiKitNotification.error(error?.message);
    } finally {
      setIsDataSaving(false);
    }
  };

  const onSendEmail = async () => {
    const response = await settingsProfileService.sendEmail(currentLanguage);
    if (response) {
      UiKitNotification.success({
        title: (
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                'layout__settings__user_details__email_sent_notification',
                {
                  EMAIL: userValues?.email,
                },
              ),
            }}
          />
        ),
      });
    }
  };

  const wlSupportEmail = branding?.wl_emails_support || EXANTE_SUPPORT_EMAIL;

  const onConfirmRestricting = async () => {
    const response =
      await settingsProfileService.restrictProcessing(currentLanguage);
    if (response) {
      UiKitNotification.success({
        title: t('layout__settings__user_details__restrict_processing'),
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                'layout__settings__user_details_archived_notification',
                { WL_SUPPORT_EMAIL: wlSupportEmail },
              ),
            }}
          />
        ),
      });
      window.location.href = '/clientsarea/logout/';
    }
  };

  const onConfirmDeleteAccount = async () => {
    const response =
      await settingsProfileService.deleteAccount(currentLanguage);
    if (response) {
      UiKitNotification.success({
        title: t('layout__settings__delete_account'),
        description: t('layout__settings__user_details_deleted_notification', {
          WL_NAME: branding?.wl_name,
        }),
      });
      window.location.href = '/clientsarea/logout/';
    }
  };

  useEffect(() => {
    if (diff?.fields) {
      reset(getPersonalInfoForm(diff.fields, isCorporate));
    }
  }, [diff?.fields]);

  const validateEmail = useMemo(
    () =>
      debounce(async () => {
        if (!dirtyFields.email) {
          clearErrors('email');
          setIsEmailError(false);
          return;
        }

        const email = getValues('email');

        if (!email) {
          return;
        }

        setIsEmailChecking(true);

        try {
          const res = await settingsProfileService.checkEmail(
            email,
            currentLanguage,
          );

          if (!res || !res.valid) {
            setError('email', { message: t('email_input__invalid') });
            setIsEmailError(true);
          } else if (res.exists) {
            setError('email', { message: t('email_input__exists') });
            setIsEmailError(true);
          } else if (res.in_blacklist) {
            setError('email', { message: t('email_input__in_blacklist') });
            setIsEmailError(true);
          } else {
            clearErrors('email');
            setIsEmailError(false);
          }
        } finally {
          setIsEmailChecking(false);
        }
      }, INPUT_DEBOUNCE_DELAY),
    [dirtyFields],
  );

  useEffect(() => {
    validateEmail();
  }, [watch('email')]);

  return (
    <FormProvider {...methods}>
      <StyledUserInfoContainer
        onSubmit={handleSubmit(onSave)}
        className="UserInfoContainer"
      >
        <StyledUserInfoHeader className="UserInfoHeader">
          <StyledUserInfoName className="UserInfoName">
            <ProfileBorderIcon className="ProfileBorderIcon" />
            {isLoading ? (
              <Skeleton width={100} height={24} />
            ) : (
              userValues?.full_name
            )}
          </StyledUserInfoName>
          <StyledUserInfoActions className="UserInfoActions">
            <ActionWithConfirmation
              content={
                <div>
                  <Input
                    placeholder={t('2step_auth_code')}
                    value={code}
                    onChange={onCodeChange}
                    error={isCodeError}
                    message={isCodeError ? t('code_is_invalid') : ''}
                    fullWidth
                    className="CodeInput"
                  />
                  <StyledResendCode className="ResendCode">
                    {timeSecondsFormat > 0 ? (
                      t('layout__settings__user_details__resend_in_seconds', {
                        COUNT: timeSecondsFormat,
                      })
                    ) : (
                      <StyledResendCodeButton
                        onClick={onSendCode}
                        textColor="action"
                        color="transparent"
                        disabled={codeIsSending}
                        className="ResendCodeButton"
                      >
                        {t('generic__verification_resend')}
                      </StyledResendCodeButton>
                    )}
                  </StyledResendCode>
                  <StyledNoCodeText
                    className="NoCodeText"
                    dangerouslySetInnerHTML={{
                      __html: notifies.didNotReceiveCodeText,
                    }}
                  />
                  <StyledCodeButtons className="CodeButtons">
                    <Button
                      onClick={onSave}
                      loading={isDataSaving}
                      disabled={code.length < 4 || codeIsSending}
                      className="TwoFAConfirmButton"
                      color="transparent"
                      textColor="action"
                    >
                      {t('generic__action__confirm')}
                    </Button>
                    <Button
                      onClick={onClose2FAPopup}
                      color="transparent"
                      textColor="secondary"
                    >
                      {t('generic__action__cancel')}
                    </Button>
                  </StyledCodeButtons>
                </div>
              }
              placement="bottom"
              externalOpened={show2FAPopup}
              closeHandler={onClose2FAPopup}
              disabled={isDisabled}
              hideDefaultButtons
              openFromExternal
            >
              <StyledSaveButton
                iconColor="action"
                label={!isMobile ? t('generic__action__save') : ''}
                iconName="SaveIcon"
                type="submit"
                disabled={isDisabled}
                data-test-id="account-settings-edit__button--change"
                className="SaveButton"
              />
            </ActionWithConfirmation>

            <IconButton
              iconName="EmailMeAccountInfoIcon"
              iconSize={24}
              onClick={onSendEmail}
              title={t('layout__settings__user_details__email_me')}
              data-test-id="user-details-settings__button--send-data-via-email"
              disabled={isLoading}
              className="SendEmailButton"
            />
            <ActionWithConfirmation
              icon={<LockFullIcon />}
              onConfirm={isCanRestricting ? onConfirmRestricting : null}
              title={t('layout__settings__user_details__restrict_processing')}
              contentHtml={
                isCanRestricting
                  ? notifies.restrictConfirmMessage
                  : notifies.disabledRestrictionText
              }
              placement="bottom"
              disabled={isLoading}
            >
              <IconButton
                iconName="LockIcon"
                iconSize={25}
                title={t('layout__settings__user_details__restrict_processing')}
                data-test-id="user-details-settings__button--gdpr-restrict-processing"
                disabled={isLoading}
                className="RestrictProcessingButton"
              />
            </ActionWithConfirmation>
          </StyledUserInfoActions>
        </StyledUserInfoHeader>
        {!canEdit && (
          <StyledUserInfoNoPermissionText
            className="UserInfoNoPermissionText"
            dangerouslySetInnerHTML={{
              __html: t('layout__settings__user_details__no_edit_permission', {
                WL_SUPPORT_EMAIL: wlSupportEmail,
              }),
            }}
          />
        )}
        <UserInfoForm
          className="UserInfoForm"
          isLoading={isLoading}
          isEmailChecking={isEmailChecking}
        />
        {!isLoading && (
          <StyledDeleteAccountContainer className="DeleteAccountContainer">
            <ActionWithConfirmation
              icon={<StyledDeleteAccountIcon />}
              onConfirm={isCanRemove ? onConfirmDeleteAccount : null}
              title={t('layout__settings__delete_account')}
              contentHtml={
                isCanRemove
                  ? t('layout__settings__user_details__remove_confirm')
                  : notifies.disabledDeletedText
              }
              placement="top"
              disabled={!hasApplication}
            >
              <StyledDeleteAccountButton
                className="DeleteAccountButton"
                textColor="radical"
                color="transparent"
                disabled={!hasApplication}
                data-test-id="user-details-settings__button--delete-account"
              >
                {t('layout__settings__delete_account')}
              </StyledDeleteAccountButton>
            </ActionWithConfirmation>
          </StyledDeleteAccountContainer>
        )}
      </StyledUserInfoContainer>
    </FormProvider>
  );
};
