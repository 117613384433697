import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { StyledFormCheckboxRow } from 'components/Form/FormCheckboxGroupContainer/FormCheckboxGroupContainer.styled';

import { StyledFormCheckboxGroupSkeletonWrapper } from './FormCheckboxGroupSkeleton.styled';
import { TFormCheckboxGroupSkeletonProps } from './FormCheckboxGroupSkeleton.types';

export const CHECKBOX_GROUP_SKELETON_SIZE_DEFAULT = 3;

export const FormCheckboxGroupSkeleton: FC<TFormCheckboxGroupSkeletonProps> = ({
  circular = false,
  items = CHECKBOX_GROUP_SKELETON_SIZE_DEFAULT,
}) => {
  return (
    <StyledFormCheckboxRow className="FormCheckboxRow">
      {[...Array(items)].map((_, i) => (
        <StyledFormCheckboxGroupSkeletonWrapper key={i}>
          <Skeleton
            width={16}
            height={16}
            variant={circular ? 'circular' : undefined}
          />
          <Skeleton width={48} height={16} />
        </StyledFormCheckboxGroupSkeletonWrapper>
      ))}
    </StyledFormCheckboxRow>
  );
};
