import { PropsWithChildren, useMemo } from 'react';

import { useTransfers } from '../../../hooks';

import { FundsContext } from './constants';
import {
  useToInternalAccount,
  useToBankAccount,
  useToMyAccount,
} from './hooks';
import { FundsProviderProps } from './types';

export const FundsProvider = ({
  children,
  selectedAccountId,
  baseName,
}: PropsWithChildren<FundsProviderProps>) => {
  const { transfersState } = useTransfers();

  const { toBankAccountDispatch, toBankAccountState } = useToBankAccount(
    baseName,
    transfersState.init_wire_values,
  );

  const { toInternalAccountState, toInternalAccountDispatch } =
    useToInternalAccount(selectedAccountId);

  const { toMyAccountState, toMyAccountDispatch } =
    useToMyAccount(selectedAccountId);

  const value = useMemo(
    () => ({
      baseName,
      selectedAccountId,
      toBankAccountDispatch,
      toBankAccountState,
      toInternalAccountDispatch,
      toInternalAccountState,
      toMyAccountDispatch,
      toMyAccountState,
    }),
    [
      baseName,
      selectedAccountId,
      toBankAccountDispatch,
      toBankAccountState,
      toInternalAccountDispatch,
      toInternalAccountState,
      toMyAccountDispatch,
      toMyAccountState,
    ],
  );

  return (
    <FundsContext.Provider value={value}>{children}</FundsContext.Provider>
  );
};
