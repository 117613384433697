import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import {
  TTabsContextApi,
  TTabsContextData,
} from 'contexts/TabsContext/TabsContext.types';
import { TAccountType, TTabsMapping } from 'services/accounts';
import { tabsService, TTab } from 'services/tabs';
import { useAppSelector } from 'store/hooks';
import { TChildren } from 'types/TChildren';

import { TranslationContext } from '../TranslationContext';

export const TabsContextData = createContext<TTabsContextData>({
  isTabsLoading: false,
  tabs: [],
  availableTabs: [],
});

export const TabsContextApi = createContext<TTabsContextApi>({
  updateAvailableTabs() {},
  fetchTabs() {},
});

export const TabsProvider: FC<TChildren> = ({ children }) => {
  const { isTranslationsLoading } = useContext(TranslationContext);
  const [availableTabs, setAvailableTabs] = useState<TTab[]>([]);

  const [
    selectedAccountIdState,
    selectedAccountState,
    accountsExcludeTabsState,
    accountsTabsState,
  ] = useAppSelector((state) => [
    state.accounts.selectedAccountId,
    state.accounts.selectedAccount,
    state.accounts.accountsExcludeTabs,
    state.accounts.accountsTabs,
  ]);

  const {
    data: tabs,
    fetchData,
    isLoading,
  } = useData<TTab[] | null>({
    onFetch: () => tabsService.getTabs(),
  });

  useEffect(() => {
    fetchData();
  }, []);

  const getAvailableTabs = (
    accountsTabs: Partial<TTabsMapping>,
    accountsExcludeTabs: Partial<TTabsMapping>,
    tabsTypes?: TAccountType[],
  ) => {
    if (!tabsTypes) {
      return [];
    }

    const availableAcc = new Set(
      tabsTypes.reduce<string[]>((acc, curr) => {
        return [...acc, ...(accountsTabs[curr] || [])];
      }, []),
    );

    const nonAvailableAcc = new Set(
      tabsTypes.reduce<string[]>((acc, curr) => {
        return [...acc, ...(accountsExcludeTabs[curr] || [])];
      }, []),
    );

    return (
      tabs?.filter(
        (tab) =>
          [...availableAcc].includes(tab.id) &&
          ![...nonAvailableAcc].includes(tab.id),
      ) || []
    );
  };

  const updateAvailableTabs = (
    currentTabs: Partial<TTabsMapping>,
    accountsExcludeTabs: Partial<TTabsMapping>,
    tabsTypes?: TAccountType[],
  ) => {
    setAvailableTabs(
      getAvailableTabs(currentTabs, accountsExcludeTabs, tabsTypes),
    );
  };

  const dataValue = useMemo<TTabsContextData>(
    () => ({
      tabs: tabs || [],
      isTabsLoading: isLoading || isTranslationsLoading,
      availableTabs: availableTabs.length ? availableTabs : [],
    }),
    [tabs, isLoading, isTranslationsLoading, availableTabs],
  );

  useEffect(() => {
    updateAvailableTabs(
      accountsTabsState,
      accountsExcludeTabsState,
      selectedAccountState?.types,
    );
  }, [selectedAccountIdState, tabs]);

  const api = useMemo<TTabsContextApi>(
    () => ({
      fetchTabs: fetchData,
      updateAvailableTabs,
    }),
    [fetchData, updateAvailableTabs],
  );

  return (
    <TabsContextData.Provider value={dataValue}>
      <TabsContextApi.Provider value={api}>{children}</TabsContextApi.Provider>
    </TabsContextData.Provider>
  );
};
