import { FC, useContext, useEffect, useMemo } from 'react';
import {
  calculateCountOfPages,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { DEFAULT_MAX_PAGINATION_LENGTH } from 'constants/common';
import { TranslationContext } from 'contexts';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { assetManagementContext } from 'pages/AssetManagementSetup/contexts/AssetManagementContext';

import { StyledAssetManagementSetupTablePanel } from './AssetManagementSetupTable.styled';
import { getAssetManagementSetupTableColumns } from './columns';
import { useFetchFeeSetups } from './hooks';

export const AssetManagementSetupTable: FC = () => {
  const { t } = useContext(TranslationContext);
  const currencyFormatter = useCurrencyFormatter();
  const { fetchFeeSetups, fetchFeeSetupsIsLoading } = useFetchFeeSetups();
  const { reloadSetupTableTrigger } = useContext(assetManagementContext);

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: fetchFeeSetups },
      filters: {
        getDefaultFilters: () => ({
          status: 'approved',
          setup_is_complete: true,
        }),
      },
    }),
    [fetchFeeSetups],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    filters,
    fetchData,
    setSorting,
  } = useTableData(tableDataArgs);

  useEffect(() => {
    fetchData();
  }, [reloadSetupTableTrigger]);

  const { data: feeSetups, total } = data || {};

  return (
    <StyledAssetManagementSetupTablePanel className="AssetManagementSetupTablePanel">
      <Table
        tableId="assetManagementTable"
        title={t('asset_management__table__setups')}
        titleSize={3}
        columns={getAssetManagementSetupTableColumns({ t, currencyFormatter })}
        data={feeSetups || []}
        noDataHeight="auto"
        translator={t}
        showTableInfo={false}
        isFlexLayout
        manualSortBy
        hasPagination
        defaultSortBy={[{ id: 'updated', desc: true }]}
        onSort={setSorting}
        filteringProps={filters}
        isLoading={fetchFeeSetupsIsLoading}
        serverPaginationProps={{
          pageSize: limit ?? DEFAULT_MAX_PAGINATION_LENGTH,
          setPage,
          setPageSize: setLimit,
          pageIndex: page,
          total: total ?? 0,
          pageCount: calculateCountOfPages(total ?? 0, limit),
        }}
      />
    </StyledAssetManagementSetupTablePanel>
  );
};
