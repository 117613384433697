import { FC, useContext, useEffect } from 'react';
import { Skeleton, useData } from 'react-ui-kit-exante';

import { Collapse } from 'components/Collapse';
import { TranslationContext } from 'contexts';
import { InstrumentsTree } from 'modules/instrumentsTree';
import {
  StyledNoticeSkeletonContainer,
  StyledNoticeSkeletonRow,
  StyledTermsFeeNoticeContainer,
  StyledTermsFeeNoticeContent,
} from 'pages/Terms/tabs/Commissions/Commissions.styled';
import { TTreeModule } from 'services/instrumentsTree/instrumentsTree.types';
import { termsService } from 'services/terms/terms';
import { TTermsData } from 'services/terms/terms.types';

import { getColumns } from './columns';

export const Commissions: FC = () => {
  const { currentLanguage, isTranslationsLoading, t } =
    useContext(TranslationContext);

  const { data, fetchData, isLoading } = useData<TTermsData | null>({
    onFetch: () => termsService.getTermsInfo(currentLanguage),
  });

  useEffect(() => {
    fetchData();
  }, [currentLanguage]);

  const { fee_notices: feeNotices = [] } = data || {};
  const showNoticeLoader = isTranslationsLoading || isLoading;

  return (
    <>
      <InstrumentsTree
        module={TTreeModule.COMMISSION}
        getColumns={getColumns}
      />

      {showNoticeLoader && (
        <StyledNoticeSkeletonContainer className="NoticeSkeletonContainer">
          {[...Array(4)].map((_, idx) => (
            <StyledNoticeSkeletonRow key={idx} className="NoticeSkeletonRow">
              <Skeleton
                width={24}
                height={24}
                animation="wave"
                variant="rounded"
              />
              <Skeleton
                width="100%"
                height={24}
                animation="wave"
                variant="rounded"
              />
            </StyledNoticeSkeletonRow>
          ))}
        </StyledNoticeSkeletonContainer>
      )}
      <StyledTermsFeeNoticeContainer
        className="TermsFeeNoticeContainer"
        data-test-id="terms-commissions__notices"
      >
        {!showNoticeLoader &&
          feeNotices?.map(({ title, content }) => {
            return (
              !!title && (
                <Collapse key={title} title={t(title)}>
                  <StyledTermsFeeNoticeContent
                    className="TermsFeeNoticeContent"
                    dangerouslySetInnerHTML={{ __html: t(content) }}
                  />
                </Collapse>
              )
            );
          })}
      </StyledTermsFeeNoticeContainer>
    </>
  );
};
