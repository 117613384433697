import { Input } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledDescriptionWrapper = styled('span')`
  flex-grow: 1;
`;

export const StyledDescriptionInput = styled(Input)`
  flex: 1;
`;
