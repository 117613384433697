import { FC } from 'react';

import { SecuritiesWithContext } from './SecuritiesWithContext';
import { SecuritiesAmountProvider } from './components/SecuritiesAmount/contexts/SecurityAmountContext';
import { SecuritiesProvider } from './contexts/SecuritiesContext';

export const Securities: FC = () => {
  return (
    <SecuritiesProvider>
      <SecuritiesAmountProvider>
        <SecuritiesWithContext />
      </SecuritiesAmountProvider>
    </SecuritiesProvider>
  );
};
