import { FC, memo, useEffect, useMemo, useState } from 'react';

import { TitleUpdater } from 'components/TitleUpdater';
import { useMenuOpened } from 'hooks/index';
import { fetchAllAccountDescriptionsAction } from 'store/accounts';
import { useAppDispatch } from 'store/hooks';

import { Logo } from '../Logo';
import { Main } from '../Main';
import { Navigation } from '../Navigation';

import {
  StyledLayoutAside,
  StyledLayoutMain,
  StyledLayoutWrapper,
} from './Layout.styled';
import { ILayoutContext, LayoutContext } from './context';

export const Layout: FC = memo(function Layout() {
  const [menuOpened, setMenuOpened] = useState(false);
  const { onToggleMenu } = useMenuOpened();
  const dispatch = useAppDispatch();
  const layoutProviderValue = useMemo<ILayoutContext>(
    () => ({
      menu: {
        opened: menuOpened,
        setOpened: (isOpened: boolean) => {
          onToggleMenu(isOpened);
          setMenuOpened(isOpened);
        },
      },
    }),
    [menuOpened],
  );

  useEffect(() => {
    dispatch(fetchAllAccountDescriptionsAction());
  }, []);

  return (
    <LayoutContext.Provider value={layoutProviderValue}>
      <StyledLayoutWrapper className="page">
        <StyledLayoutAside className="page__menu" isMenuOpen={menuOpened}>
          <Logo className="menu__logo" />
          <Navigation />
        </StyledLayoutAside>
        <StyledLayoutMain className="page__main">
          <Main />
        </StyledLayoutMain>
      </StyledLayoutWrapper>
      <TitleUpdater />
    </LayoutContext.Provider>
  );
});
