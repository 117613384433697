import { blockNonNativeProps, css } from 'react-ui-kit-exante';

import { TLayoutAsideProps } from 'components/Layout/Layout.types';
import { styled } from 'theme';

export const StyledLayoutWrapper = styled('div')`
  display: flex;
  min-height: 100%;
  color: ${({ theme }) => theme.color.typo.primary};
  background-color: ${({ theme }) => theme.color.bg.basic};
`;

export const StyledLayoutAside = styled('aside', {
  shouldForwardProp: blockNonNativeProps(['isMenuOpen']),
})<TLayoutAsideProps>`
  display: none;
  flex: 1 0 ${({ theme }) => theme.sizes.desktopMenuWidth};
  background-color: ${({ theme }) => theme.color.bg.primary};

  ${({ theme }) => theme.breakpoints.down('md')} {
    .menu__logo {
      display: none;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
  }

  ${({ isMenuOpen, theme }) => {
    if (isMenuOpen) {
      return css`
        position: fixed;
        top: ${theme.sizes.mobileHeaderHeight};
        z-index: 10;

        display: block;
        width: 100%;
        height: calc(100vh - ${theme.sizes.mobileHeaderHeight});
        overflow-y: auto;
      `;
    }
    return '';
  }}
`;

export const StyledLayoutMain = styled('main')`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-grow: 1;
  }
`;
