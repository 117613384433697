import { IconButton } from 'react-ui-kit-exante';

import {
  GenericTransferFormBlock,
  GenericTransferFormTitle,
} from 'components/GenericTransferForm';
import { styled } from 'theme';

export const StyledExchangeAmountBlock = styled(GenericTransferFormBlock)`
  flex: 1.5;
`;

export const StyledExchangeAmountTitle = styled(GenericTransferFormTitle)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const StyledExchangeAmountFields = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    align-items: normal;
    flex-direction: row;
  }
`;

export const StyledExchangeAmountSwapButton = styled(IconButton)`
  height: 48px;
  width: 32px;

  & svg {
    color: ${({ theme }) => theme.color.typo.secondary};
  }

  &:hover svg {
    color: ${({ theme }) => theme.color.typo.secondary};
  }
`;

export const StyledExchangeAmountSelectWrapper = styled('div')`
  width: 100%;
`;
