export const PARTNER_BENEFITS_TERMS = [
  {
    title: 'layout__partner_landing__benefits_terms_1_title',
    value: 'layout__partner_landing__benefits_terms_1_value',
  },
  {
    title: 'layout__partner_landing__benefits_terms_2_title',
    value: 'layout__partner_landing__benefits_terms_2_value',
  },
  {
    title: 'layout__partner_landing__benefits_terms_3_title',
    value: 'layout__partner_landing__benefits_terms_3_value',
  },
  {
    title: 'layout__partner_landing__benefits_terms_4_title',
    value: 'layout__partner_landing__benefits_terms_4_value',
  },
  {
    title: 'layout__partner_landing__benefits_terms_5_title',
    value: 'layout__partner_landing__benefits_terms_5_value',
  },
  {
    title: 'layout__partner_landing__benefits_terms_6_title',
    value: 'layout__partner_landing__benefits_terms_6_value',
  },
  {
    title: 'layout__partner_landing__benefits_terms_7_title',
    value: 'layout__partner_landing__benefits_terms_7_value',
  },
  {
    title: 'layout__partner_landing__benefits_terms_8_title',
    value: 'layout__partner_landing__benefits_terms_8_value',
  },
];
