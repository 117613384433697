import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAssetManagementSetupSkeletonWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 24px;
  column-gap: 8px;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    grid-template-columns: minmax(120px, 1fr) 10% 10% 48px minmax(386px, 1fr);
    gap: 16px;
    width: 100%;
  }
`;

export const StyledAccountSummarySkeletonBlock = styled(Skeleton)`
  width: 100%;
  height: 48px;
`;

export const StyledAccountSummarySkeletonProgressBlock = styled(Skeleton)`
  width: 100%;
  height: 8px;
`;

export const StyledAccountSummarySkeletonDeleteBlock = styled(Skeleton)`
  width: 71px;
  height: 16px;
`;
