import {
  blockNonNativeProps,
  Button,
  ButtonDatePicker,
  css,
  Skeleton,
  Table,
} from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledPartnerLinksAddLinkProps } from './PartnerLinks.types';

export const StyledPartnerLinksWrapper = styled('div')`
  position: relative;

  .TableTopPanel {
    padding-top: 0 !important;
  }
`;

export const StyledPartnerLinksTableHeader = styled('div', {
  shouldForwardProp: blockNonNativeProps(['noRightPadding', 'boolean']),
})<TStyledPartnerLinksAddLinkProps>`
  z-index: 1;
  position: absolute;
  right: ${({ noRightPadding }) => (noRightPadding ? '27px' : '65px')};
  top: ${({ loading }) => (loading ? '8px' : '0')};

  display: flex;
  gap: 16px;

  .MuiSkeleton-root {
    margin-right: 0;
  }
`;

export const StyledPartnerLinksDatePicker = styled(ButtonDatePicker)`
  ${({ showSkeleton }) =>
    showSkeleton &&
    css`
      width: 16px;
      height: 16px;
    `}
` as typeof ButtonDatePicker;

export const StyledPartnerLinksAddLink = styled(Button)`
  color: ${({ theme }) => theme.color.typo.action};

  & svg {
    color: ${({ theme }) => theme.color.typo.action} !important;
  }

  &:hover svg {
    color: ${({ theme }) => theme.color.typo.primary} !important;
  }
`;

export const StyledPartnerLinksTable = styled(Table)`
  .TableInfoBlock {
    visibility: hidden;
  }

  .Table:first-child {
    margin-top: 40px;
  }

  [data-test-id='table__head--cell'] > div:nth-child(3) {
    z-index: 0;
  }
` as typeof Table;

export const StyledPartnerLinksAddLinkSkeleton = styled(Skeleton)`
  margin-top: 0;
  margin-right: 35px;
`;

export const StyledDateRangePickerWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;

  .react-datepicker__header {
    svg {
      width: unset;
      height: unset;
    }
  }
`;

export const StyledDateRangePickerDateText = styled('span')`
  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 15px;

  display: flex;
  gap: 4px;

  ${({ theme }) => theme?.breakpoints?.down('md')} {
    display: none;
  }
`;
