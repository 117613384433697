import { ReactNode } from 'react';

import { TTransactionData } from 'services/transactions';
import { TWithdrawalHistoryEntry } from 'services/withdrawal';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';

import { isTransactionWithdrawal } from '../../../../Transfers.helpers';

import {
  TGetMainWithdrawalValue,
  TGetWithdrawalContentRowsProps,
} from './WithdrawalInfo.types';
import { WithdrawalInfoSecurityPositions } from './components';

export const getMainWithdrawalInfo = (
  data: TWithdrawalHistoryEntry | TTransactionData,
): TGetMainWithdrawalValue => {
  if (isTransactionWithdrawal(data)) {
    return {
      status: data.status,
      amount: data.amount,
      created: data.created,
      targetAccount: data.account,
      currency: data.currency,
    };
  }

  return {
    status: 'executed',
    amount: data.sum,
    created: data.timestamp,
    targetAccount: data.accountId,
    currency: data.baseCurrency,
  };
};

export const getWithdrawalContentRows = ({
  data,
  currencyFormatter,
  dateFormatter,
  dateFormatterWithoutTime,
  t,
}: TGetWithdrawalContentRowsProps): Array<[string, ReactNode]> => {
  if (!isTransactionWithdrawal(data)) {
    return Object.entries<string>({});
  }

  switch (data.type) {
    case WITHDRAWAL_TYPE.SECURITY:
      return Object.entries<ReactNode>({
        'Type of transaction': data.type_of_transaction,
        'Name of the Financial Institution': data.fi_name,
        layout__fields__contact_person_and_details: data.contact_person,
        'Account number at the Financial Institution': data.fi_account_number,
        layout__fields__account_holder_name: data.account_holder_name,
        layout__fields__transfer_reference: data.transfer_reference,
        'Standard Settlement Instruction (SSI)': data.ssi,
        'Transfer details': data.transfer_details,
        Positions: <WithdrawalInfoSecurityPositions data={data} />,
        'Request date': dateFormatter(data.created),
        ...(data.updated &&
          data.status === 'executed' && {
            layout__item__approval_date: dateFormatter(data.updated),
          }),
      });
    case WITHDRAWAL_TYPE.CRYPTO:
      return Object.entries<string>({
        Account: data.account,
        Amount: currencyFormatter(data.amount),
        To: data.crypto_address,
        'Request date': dateFormatter(data.created),
      });
    default:
      // noinspection JSNonASCIINames -- non-ascii characters are part of translation keys
      return Object.entries<string>({
        Address: data.address,
        beneficiary_name: data.name,
        beneficiary_address: data.address,
        bank_name: data.bank_name,
        bank_address: data.bank_address,
        layout__fields__bank_country: data.bank_country,
        IBAN: data.iban,
        SWIFT: data.swift,
        account_number: data.account_number,
        'Correspondent Bank’s SWIFT': data.correspondent_swift,
        'Correspondent Bank’s Account No.': data.correspondent_account,
        Comment: data.comment,
        layout__fields__value_date: dateFormatterWithoutTime(data.value_date),
        layout__fields__using_personal_iban: data.use_personal_iban
          ? t('generic__action__yes')
          : t('generic__action__no'),
        layout__fields__beneficiary_country: data.beneficiary_country,
        layout__fields__postal_code: data.postal_code,
        layout__fields__city: data.city,
        layout__fields__state: data.state,
        layout__fields__short_address: data.short_address,
      });
  }
};
