import {
  ACCOUNT_SELECTOR_HEIGHT,
  ACCOUNT_SELECTOR_LINE_WIDTH,
} from './AccountsSearchField.constants';

export const calcOptionHeight = (
  label: string | undefined | null = '',
  description: string | undefined | null = '',
): number => {
  const descriptionHeight =
    (description ? 1 : 0) * ACCOUNT_SELECTOR_HEIGHT.LINE_SINGLE;

  const labelLines = Math.ceil(
    (label || '').length / ACCOUNT_SELECTOR_LINE_WIDTH,
  );

  const labelHeight =
    labelLines *
    (labelLines > 1
      ? ACCOUNT_SELECTOR_HEIGHT.LINE_MULTIPLE
      : ACCOUNT_SELECTOR_HEIGHT.LINE_SINGLE);

  return labelHeight + descriptionHeight + ACCOUNT_SELECTOR_HEIGHT.SPACING;
};
