import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  IColumn,
  IFilteringProps,
  IOnFetchArguments,
  ISortBy,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { getTransfersHistoryTableColumns } from 'components/TransfersHistoryTable/TransfersHistoryTable.helpers';
import {
  TTransfersHistoryTableProps,
  TTransfersHistoryTableRefAttributes,
} from 'components/TransfersHistoryTable/TransfersHistoryTable.types';
import { DEFAULT_MAX_PAGINATION_LENGTH } from 'constants/common';
import { TranslationContext } from 'contexts';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useLogHandleTime } from 'hooks/useLogHandleTime';
import {
  TFetchTransactionsProps,
  transactionsService,
  TTransactionData,
  TTransactionsResponse,
} from 'services/transactions';
import { TTypeOfFirstArgument } from 'types/TTypeOfFirstArgument';

export const TransfersHistoryTable: ForwardRefExoticComponent<
  TTransfersHistoryTableProps &
    RefAttributes<TTransfersHistoryTableRefAttributes>
> = forwardRef(function TransfersHistoryTable(
  {
    operationTypes,
    columns: propColumns,
    titleKey,
    tableId = 'transfersHistoryTable',
    size = DEFAULT_MAX_PAGINATION_LENGTH,
    className,
    accountId,
    hasFilters,
  },
  ref,
) {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('transfers-history');

  const [data, setData] = useState<TTransactionsResponse | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async ({ params }: IOnFetchArguments) => {
      if (!accountId) {
        return;
      }

      const { skip, limit } = params || {};
      const operationType = params.operationType as string[] | undefined;
      setLoading(true);
      setStartHandleTime();
      const requestData: TFetchTransactionsProps = {
        operationTypes: operationType?.length ? operationType : operationTypes,
        offset: skip,
        size: limit,
        accountId,
      };
      const response = await transactionsService.getTransactions(requestData);
      setLoading(false);
      logHandleTime();

      setData(response);
    },
    [accountId, operationTypes],
  );

  const tableDataArgs = useMemo<TTypeOfFirstArgument<typeof useTableData>>(
    () => ({
      data: { onFetch: fetchData },
      pagination: { getDefaultPagination: () => ({ limit: size, skip: 0 }) },
    }),
    [fetchData, size],
  );

  const defaultSortBy = useMemo<ISortBy[]>(
    () => [{ id: 'timestamp', desc: true }],
    [],
  );

  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter(true);

  const {
    limit,
    page,
    setPage,
    setLimit,
    fetchData: refetchTableData,
    filters: tableFilters,
    resetFilters,
    setFilter,
    removeFilter,
  } = useTableData(tableDataArgs);
  const total = data?.total || 0;
  const pageCount = Math.ceil(total / limit);

  const columns = useMemo<IColumn<TTransactionData>[]>(() => {
    return (
      propColumns ||
      getTransfersHistoryTableColumns({
        t,
        currencyFormatter,
        dateFormatter,
        onFilter: setFilter,
        onRemove: removeFilter,
        operationTypes,
      })
    );
  }, [t, currencyFormatter, dateFormatter, operationTypes, propColumns]);

  const filteringProps = useMemo<IFilteringProps<TTransactionData>>(() => {
    const { type, ...filters } = tableFilters;
    return {
      removeAllFilters: resetFilters,
      filters,
      manualFilters: true,
    };
  }, [resetFilters, tableFilters]);

  useImperativeHandle<
    TTransfersHistoryTableRefAttributes,
    TTransfersHistoryTableRefAttributes
  >(
    ref,
    () => ({
      fetchLastTransactions: () => {
        if (page === 0) {
          refetchTableData();
        } else {
          setPage(0);
        }
      },
    }),
    [page, setPage, refetchTableData],
  );

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <Table<TTransactionData>
      className={className}
      columns={columns}
      data={data?.data || []}
      tableId={tableId}
      locale={currentLanguage}
      translator={t}
      defaultSortBy={defaultSortBy}
      disableSortBy
      isFlexLayout
      hasPagination
      manualSortBy
      title={t(titleKey)}
      titleSize={3}
      pageSize={limit}
      isLoading={loading || isTranslationsLoading}
      serverPaginationProps={{
        total,
        setPage,
        pageCount,
        pageSize: limit,
        pageIndex: page,
        setPageSize: setLimit,
      }}
      hasFilters={hasFilters}
      filteringProps={hasFilters ? filteringProps : undefined}
    />
  );
});
