import { useCallback, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Country, getCountryCallingCode, Phone } from 'react-ui-kit-exante';

import { TFormInputContainerProps } from './FormPhoneInputContainer.types';

export const FormPhoneInputContainer = ({
  customEvents = {},
  disabled = false,
  readOnly = false,
  fullWidth = true,
  label = '',
  countriesExcluded,
  name,
  type,
  ...rest
}: TFormInputContainerProps) => {
  const formContext = useFormContext();

  const {
    field: { onChange, onBlur: onBlurReactHookForm, value },
    fieldState: { error },
  } = useController({
    control: formContext.control,
    name,
  });

  const {
    field: { onChange: onChangeCode },
  } = useController({
    control: formContext.control,
    name: 'country_code',
  });

  useEffect(() => {
    formContext.register(name, { disabled });
    formContext.register('country_code', { disabled });

    return () => {
      formContext.unregister(name);
      formContext.unregister('country_code');
    };
  }, []);

  const { onBlur: onBlurCustomEvents } = customEvents;

  const blurHandler = useCallback(() => {
    if (onBlurCustomEvents) {
      onBlurCustomEvents(formContext);
    }

    onBlurReactHookForm();
  }, [formContext, onBlurCustomEvents, onBlurReactHookForm]);

  const changeHandler = (val: string) => {
    onChange(val || '');
  };

  const onCountryChange = (countryCode?: string) => {
    if (countryCode) {
      const code = getCountryCallingCode(countryCode as Country);
      onChangeCode(Number(code));
    }
  };

  return (
    <Phone
      numberInputProps={{
        fullWidth,
        label,
        error: !!error,
        message: error?.message,
        ...rest,
      }}
      countrySelectProps={{
        showSkeleton: rest?.showSkeleton,
      }}
      countriesExcluded={countriesExcluded}
      disabled={disabled}
      name={name}
      onBlur={blurHandler}
      type={type}
      readOnly={readOnly}
      value={value}
      onChange={changeHandler}
      onCountryChange={onCountryChange}
      className="PhoneInput"
    />
  );
};
