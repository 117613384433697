import { styled } from 'theme';

export const StyledMainContent = styled('div')`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const StyledPageWrapper = styled('div')`
  min-height: 100%;
  padding: 0 18px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    position: relative;
    padding: 0;
  }
`;
