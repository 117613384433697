export const enum DisableByCodeActions {
  OnCodeChange = 'DisableByCode/OnCodeChange',

  RequestStart = 'DisableByCode/RequestStart',
  RequestSuccess = 'DisableByCode/RequestSuccess',
  RequestError = 'DisableByCode/RequestError',

  VerifyStart = 'DisableByCode/VerifyStart',
  VerifySuccess = 'DisableByCode/VerifySuccess',
  VerifyError = 'DisableByCode/VerifyError',
}
