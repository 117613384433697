import { Panel, Tabs } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledApplicationContainer = styled(Panel)`
  .PanelTitle {
    padding: 24px;
    margin: 0;
  }

  padding: 0;
`;

export const StyledApplicationActions = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledTabs = styled(Tabs)`
  padding: 0 24px;
`;

export const StyledKeysContainer = styled('div')`
  display: flex;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const StyledKeyItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledKeyPermissionsContainer = styled('div')`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
`;

export const StyledKeyScopes = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const StyledFooter = styled('div')`
  padding: 24px;
  display: flex;
  gap: 6px;
`;

export const StyledDeleteContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 256px;
  justify-content: stretch;
`;

export const StyledDeleteContainerFooter = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;
