import { FC } from 'react';

import { StyledAssetManagementClientsTableWrapper } from './AssetManagementPage.styled';
import { AssetManagementClientsTable } from './components/AssetManagementClientsTable';

export const AssetManagementClientsPage: FC = () => {
  return (
    <StyledAssetManagementClientsTableWrapper className="AssetManagementClientsTableWrapper">
      <AssetManagementClientsTable />
    </StyledAssetManagementClientsTableWrapper>
  );
};
