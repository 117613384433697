import { componentInitialState } from './constants';
import { TComponentReducer, TComponentState } from './types';

export const componentReducer: TComponentReducer = (
  state,
  action,
): TComponentState => {
  switch (action.type) {
    case 'CLEAR': {
      return componentInitialState;
    }

    case 'SET_SEARCH': {
      const { validating } = state;

      return {
        ...state,
        dirty: true,
        search: action.payload,
        validating: {
          ...validating,
          error: false,
          errorMessage: '',
        },
      };
    }

    case 'TOGGLE_EXPANDED': {
      return {
        ...state,
        expanded: !state.expanded,
      };
    }

    case 'VALIDATE_ERROR': {
      return {
        ...state,
        validating: {
          ...state.validating,

          error: true,
          errorMessage: action.payload,
          pending: false,
        },
      };
    }

    case 'VALIDATE_START': {
      return {
        ...state,

        dirty: false,
        validating: {
          ...state.validating,
          error: false,
          errorMessage: '',
          idle: false,
          pending: true,
        },
      };
    }

    case 'VALIDATE_SUCCESS': {
      return {
        ...state,
        validating: {
          ...state.validating,
          pending: false,
          succeed: true,
        },
      };
    }

    default: {
      return state;
    }
  }
};
