import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';

import { TranslationContext } from 'contexts';
import { settingsAgreementsService } from 'services/settings/agreements';

import { initialState } from './AffidavitFormContext.consts';
import {
  AffidavitFormStatus,
  TAffidavitFormProviderProps,
  TAffidavitFormState,
  W8SignChecked,
} from './AffidavitFormContext.types';

export const AffidavitFormContext =
  createContext<TAffidavitFormState>(initialState);

export const AffidavitFormProvider: FC<TAffidavitFormProviderProps> = ({
  children,
  accountId,
  accountName,
  signOptions,
  signUrl,
  legalEntity,
  onConfirm,
  mfaType,
}): ReactElement | null => {
  const { t } = useContext(TranslationContext);

  const [isSigned, setIsSigned] = useState(false);
  const [status, setStatus] = useState<AffidavitFormStatus>(
    AffidavitFormStatus.Default,
  );
  const [confirmError, setConfirmError] = useState('');
  const [code, setCode] = useState('');
  const [changesText, setChangesText] = useState('');
  const [signOptionsChecked, setSignOptionsChecked] = useState<W8SignChecked>(
    {},
  );

  const requestSign = () => setIsSigned(true);

  const requestConfirm = async () => {
    setConfirmError('');
    setStatus(AffidavitFormStatus.Requested);

    // get token
    const token = await settingsAgreementsService.getToken(code, 'site');

    if (!token) {
      setStatus(AffidavitFormStatus.Default);
      setConfirmError(t('generic__verification__incorrect_code'));
      return;
    }

    setStatus(AffidavitFormStatus.Verified);

    const success = await settingsAgreementsService.signW8AffidavitWithToken({
      token,
      signUrl,
      legalEntity,
      changesText,
      w8Types: Object.keys(signOptionsChecked),
    });

    if (!success) {
      setStatus(AffidavitFormStatus.Default);
      setConfirmError(t('generic__unkown_error_occured'));
      return;
    }

    setStatus(AffidavitFormStatus.Confirmed);

    onConfirm?.();
  };

  const setSignOptionState = (optionValue: string, checked: boolean) => {
    setSignOptionsChecked((items) => ({ ...items, [optionValue]: checked }));
  };

  const value: TAffidavitFormState = useMemo(
    () => ({
      status,
      isSigned,
      confirmError,
      setConfirmError,
      requestSign,
      requestConfirm,
      signOptionsChecked,
      setSignOptionChecked: setSignOptionState,
      changesText,
      setChangesText,
      code,
      setCode,
      accountId,
      accountName,
      signOptions,
      mfaType,
    }),
    [
      status,
      isSigned,
      confirmError,
      setConfirmError,
      requestSign,
      requestConfirm,
      signOptionsChecked,
      setSignOptionState,
      changesText,
      setChangesText,
      code,
      setCode,
      accountId,
      accountName,
      signOptions,
      mfaType,
    ],
  );

  return (
    <AffidavitFormContext.Provider value={value}>
      {children}
    </AffidavitFormContext.Provider>
  );
};
