import { FC, useContext, useEffect, useMemo } from 'react';
import { Tooltip as TitleTooltip, useData } from 'react-ui-kit-exante';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { InfoIcon } from 'assets/icons';
import { getChartColors } from 'components/Chart/Chart.constants';
import { getYWidth } from 'components/Chart/Chart.helpers';
import { ChartSkeleton } from 'components/Chart/ChartSkeleton';
import { CustomLegend } from 'components/Chart/CustomLegend';
import { CustomTooltip } from 'components/Chart/CustomTooltip';
import { CurrencyContext, TranslationContext } from 'contexts';
import { Dates, today } from 'helpers/dates';
import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { ChallengeAccountSummaryContext } from 'pages/Challenge/ChallengeAccountSummary/contexts';
import { accountsService, TAccountNavReportResponse } from 'services/accounts';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { useTheme } from 'theme';

import { calculateDateFrom } from './ChallengeAccountSummaryBalance.helpers';
import {
  StyledChallengeAccountSummaryBalance,
  StyledChallengeAccountSummaryBalanceTitle,
  StyledChartWrapper,
} from './ChallengeAccountSummaryBalance.styled';
import { TChartData } from './ChallengeAccountSummaryBalance.types';

export const ChallengeAccountSummaryBalance: FC = () => {
  const theme = useTheme();
  const { t, isTranslationsLoading, currentLanguage } =
    useContext(TranslationContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const currencyFormatter = useCurrencyFormatter();
  const { currency } = useContext(CurrencyContext);
  const { challengeDateStart } = useContext(ChallengeAccountSummaryContext);

  const dateTo = Dates.format(today);
  // used to aggregated account
  const fakeStartDate = Dates.format(
    new Date(new Date().setDate(today.getDate() - 90)),
  );
  const dateFrom =
    challengeDateStart !== '-'
      ? calculateDateFrom(challengeDateStart)
      : calculateDateFrom(fakeStartDate);

  const {
    data: challengeAccountBalance,
    fetchData: fetchChallengeAccountBalance,
    isLoading: isChallengeAccountBalanceLoading,
  } = useData<TAccountNavReportResponse | null>({
    onFetch: () => {
      if (dateFrom && selectedAccountId !== null) {
        return accountsService.getAccountNavReport(
          selectedAccountId,
          currency,
          dateFrom,
          dateTo,
          currentLanguage,
        );
      }
      return Promise.resolve(null);
    },
  });

  useEffect(() => {
    fetchChallengeAccountBalance();
  }, [selectedAccountId, currency, dateFrom, dateTo]);

  const metrics = challengeAccountBalance?.metrics || {};

  const chartData = useMemo<TChartData>(() => {
    let dates: string[] = Object.keys(metrics || {});
    dates = dates.sort(
      (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime(),
    );
    return dates.map((date) => ({
      date,
      balance: Number(metrics[date].nav),
    }));
  }, [metrics]);

  const chartColors = getChartColors(theme);
  const yWidth = getYWidth(chartData, ['balance']);

  const isDataLoading: boolean =
    isChallengeAccountBalanceLoading ||
    isTranslationsLoading ||
    chartData.length === 0;

  return (
    <StyledChallengeAccountSummaryBalance className="ChallengeAccountSummaryBalance">
      <StyledChallengeAccountSummaryBalanceTitle className="ChallengeAccountSummaryBalanceTitle">
        {t('layout__challenge_account_summary__balance')}
        <TitleTooltip
          title={t('layout__challenge_account_summary__balance-tooltip')}
        >
          <InfoIcon className="ChallengeAccountSummaryBalanceTooltip" />
        </TitleTooltip>
      </StyledChallengeAccountSummaryBalanceTitle>

      <StyledChartWrapper className="ChartWrapper">
        {isDataLoading ? (
          <ChartSkeleton />
        ) : (
          <ResponsiveContainer minWidth={theme?.breakpoints?.values?.sm}>
            <LineChart data={chartData}>
              <CartesianGrid />
              <Line
                type="monotone"
                dataKey="balance"
                stroke={chartColors.positive}
                dot={<span />}
              />
              <XAxis
                dataKey="date"
                minTickGap={12}
                padding={{ left: 10 }}
                interval="preserveStartEnd"
              />
              <YAxis
                orientation="left"
                width={yWidth}
                minTickGap={5}
                tickCount={10}
                tickFormatter={(value) =>
                  formatCurrency(currencyFormatter, value)
                }
              />
              <Legend
                content={<CustomLegend yWidth={yWidth} />}
                verticalAlign="top"
                align="left"
                wrapperStyle={{
                  paddingBottom: '50px',
                }}
              />
              <Tooltip
                content={<CustomTooltip />}
                wrapperStyle={{ outline: 'none' }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </StyledChartWrapper>
    </StyledChallengeAccountSummaryBalance>
  );
};
