import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';
import { TBinding } from 'pages/Security/pages/ExternalAccounts/ExternalAccounts.types';

import { TSecuritySettingsResponse } from './externalAccounts.types';

class ExternalAccountsService extends ApiRequest {
  async getExternalAccountsInfo(lang = 'en'): Promise<TBinding[]> {
    try {
      const response = await this.fetch<TSecuritySettingsResponse>({
        url: `/${lang}/clientsarea/spa-layout/?name=security-settings`,
      });
      const bindingsArray = response.data?.['security-settings'].state.bindings;

      return Array.isArray(bindingsArray) ? bindingsArray : [];
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('externalAccountsService', error);

      return [];
    }
  }

  async disconnectProvider(serviceName: string): Promise<void> {
    try {
      await this.fetch(
        {
          method: 'POST',
          url: `/clientsarea/disconnect/${serviceName}/`,
        },
        { maxRedirects: 0 },
      );
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('externalAccountsService', error);
    }
  }
}

export const externalAccountsService = new ExternalAccountsService();
