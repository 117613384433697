import { createContext } from 'react';

import {
  initialToBankAccountState,
  initialToInternalAccountState,
  initialToMyAccountState,
} from './hooks';
import { TFundsContext } from './types';

export const FundsContext = createContext<TFundsContext>({
  baseName: '',
  selectedAccountId: '',
  toBankAccountDispatch: () => {},
  toBankAccountState: initialToBankAccountState,
  toInternalAccountDispatch: () => {},
  toInternalAccountState: initialToInternalAccountState,
  toMyAccountDispatch: () => {},
  toMyAccountState: initialToMyAccountState,
});
