import { ReactNode } from 'react';
import {
  ArrowsClockwiseIcon,
  CircleClockIcon,
  CircleCloseIcon,
  CircleCheckIcon,
} from 'react-ui-kit-exante';

export const WITHDRAWAL_TYPE = {
  CARD: 'Card',
  WIRE: 'Wire',
  TRANSFER: 'Transfer',
  CRYPTO: 'Crypto',
  SECURITY: 'Security',
  INTERNAL: 'Internal',
  SUBUSER_WITHDRAWAL: 'Subuser Withdrawal',
  OPPOSITE_WL: 'Opposite WL',
  WIRE_THIRD_PARTY: 'Wire third-party',
} as const;

export const WITHDRAWAL_FORMAT = {
  SEPA: 'SEPA',
  SWIFT: 'SWIFT',
} as const;

export const WITHDRAWAL_STATUS_KEY_MAP = {
  active: 'Working',
  'on-hold': 'Pending',
  executed: 'Executed',
  rejected: 'Rejected',
  old: 'Processed',
} as const;

export const WITHDRAWAL_STATUS_ICON_MAP: Record<
  keyof typeof WITHDRAWAL_STATUS_KEY_MAP,
  ReactNode
> = {
  active: <ArrowsClockwiseIcon />,
  'on-hold': <CircleClockIcon />,
  executed: <CircleCheckIcon />,
  rejected: <CircleCloseIcon />,
  old: <CircleCheckIcon />,
} as const;
