import {
  StyledDocumentUploadTooltipContainer,
  StyledDocumentUploadTooltipList,
  StyledDocumentUploadTooltipText,
} from '../../UploadGroup.styled';
import { TTooltipContentProps } from '../../UploadGroup.types';

export const ValidIdTooltipContent = ({ t }: TTooltipContentProps) => (
  <StyledDocumentUploadTooltipContainer className="DocumentUploadTooltipContainer">
    <StyledDocumentUploadTooltipText className="DocumentUploadTooltipText">
      {t('layout__tooltip__note')}
    </StyledDocumentUploadTooltipText>
    <StyledDocumentUploadTooltipText className="DocumentUploadTooltipText">
      {t('layout__tooltip__allowed_docs')}
      <StyledDocumentUploadTooltipList className="DocumentUploadTooltipList">
        <li>{t('layout__tooltip__passport')}</li>
        <li>{t('layout__tooltip__id_card')}</li>
      </StyledDocumentUploadTooltipList>
    </StyledDocumentUploadTooltipText>
  </StyledDocumentUploadTooltipContainer>
);
