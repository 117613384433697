import { styled } from 'theme';

export const StyledTaxReportUnionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px;

  border-bottom: 1px solid ${({ theme }) => theme?.color?.line?.primary};

  &:not(&:last-of-type) {
    border-top: 1px solid ${({ theme }) => theme?.color?.line?.primary};
  }
`;

export const StyledTaxReportUnionHead = styled('div')`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  width: 100%;
`;

export const StyledTaxReportUnionTitle = styled('h3')`
  color: ${({ theme }) => theme?.color?.typo?.primary};

  margin: 0;

  font-family: ${({ theme }) => theme?.font?.header};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledTaxReportDetailsList = styled('ul')`
  display: flex;
  flex-direction: column;
  padding-inline: 0;
  gap: 8px;
`;

export const StyledTaxReportDetailsListItem = styled('li')`
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  gap: 8px;
`;

export const StyledTaxReportDetailTitle = styled('span')`
  color: ${({ theme }) => theme?.color?.typo?.promo};
`;

export const StyledTaxReportDetailValue = styled('span')`
  color: ${({ theme }) => theme?.color?.typo?.primary};
`;
