import { Button, FormLayout } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPartnerProceed = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: start;

  padding: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
    padding: 96px 47% 40px 80px;
  }
`;

export const StyledPartnerProceedHeading = styled('h2')`
  margin: 0;

  font-family: ${({ theme }) => theme.font.main};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    z-index: 2;
    position: relative;

    font-size: 32px;
    line-height: 48px;
  }
`;

export const StyledPartnerProceedDesc = styled('p')`
  margin: 0;

  font-size: 15px;
  font-weight: 400;
  line-height: 24px;

  color: #49575b;

  ${({ theme }) => theme.breakpoints.up('md')} {
    z-index: 2;
    position: relative;
  }
`;

export const StyledPartnerProceedForm = styled(FormLayout)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    z-index: 2;
    position: relative;
  }
`;

export const StyledPartnerProceedDoc = styled('div')`
  display: flex;
  gap: 8px;
`;

export const StyledPartnerProceedLabel = styled('span')`
  margin: 0;

  color: ${({ theme }) => theme?.color?.typo?.secondary};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledPartnerProceedLabelLink = styled('a')`
  color: ${({ theme }) => theme?.color?.typo?.action};
`;

export const StyledPartnerProceedButton = styled(Button)`
  margin: 8px 0;
`;

export const StyledPartnerProceedNote = styled('p')`
  margin: 0 0 16px;
  color: ${({ theme }) => theme?.color?.typo?.secondary};
`;
