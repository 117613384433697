import { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconButton } from 'react-ui-kit-exante';

import { FormInputContainer } from 'components/Form/FormInputContainer/FormInputContainer';
import { FormSelectContainer } from 'components/Form/FormSelectContainer/FormSelectContainer';
import { TranslationContext } from 'contexts/TranslationContext';

import {
  StyledPartnerPaymentsDetailsFormGrid,
  StyledPartnerPaymentsDetailsFormHeader,
  StyledPartnerPaymentsDetailsFormHeaderSaveButton,
  StyledPartnerPaymentsDetailsFormHeaderText,
  StyledPartnerPaymentsDetailsFormWrapper,
} from './PartnerPaymentsDetailsForm.styled';
import { TPartnerPaymentsDetailsFormProps } from './PartnerPaymentsDetailsForm.types';
import { PartnerPaymentsDetailsFormNote } from './components';

export const PartnerPaymentsDetailsForm: FC<
  TPartnerPaymentsDetailsFormProps
> = ({
  countries,
  currencies,
  isLoading,
  onSubmit,
  previousChangeRequest,
  diffList,
}) => {
  const { t } = useContext(TranslationContext);
  const { formState } = useFormContext();

  const saveDisabled: boolean =
    isLoading || !formState.isDirty || !formState.isValid;

  return (
    <StyledPartnerPaymentsDetailsFormWrapper className="PartnerPaymentsDetailsFormWrapper">
      <StyledPartnerPaymentsDetailsFormHeader className="PartnerPaymentsDetailsFormHeader">
        <StyledPartnerPaymentsDetailsFormHeaderText className="PartnerPaymentsDetailsFormHeaderText">
          {t('payment_details')}
        </StyledPartnerPaymentsDetailsFormHeaderText>
        <StyledPartnerPaymentsDetailsFormHeaderSaveButton
          className="PartnerPaymentsDetailsFormHeaderSaveButton"
          color="transparent"
          icon={<IconButton iconName="SaveIcon" iconSize={24} />}
          disabled={saveDisabled}
          onClick={onSubmit}
        >
          {t('generic__action__save')}
        </StyledPartnerPaymentsDetailsFormHeaderSaveButton>
      </StyledPartnerPaymentsDetailsFormHeader>
      <StyledPartnerPaymentsDetailsFormGrid className="PartnerPaymentsDetailsFormGrid">
        <FormSelectContainer
          name="pc_pd_currency"
          disabled={isLoading}
          options={currencies}
          label={t('generic__currency')}
          data-test-id="partner-payments-details__select--payment-currency"
          required
        />
        <FormInputContainer
          name="pc_pd_beneficiary_name"
          disabled={isLoading}
          label={t('layout__fields__beneficiary_name')}
          data-test-id="partner-payments-details__input--beneficiary-name"
          required
        />
        <FormInputContainer
          name="pc_pd_beneficiary_address"
          disabled={isLoading}
          label={t('layout__fields__beneficiary_address')}
          data-test-id="partner-payments-details__input--beneficiary-address"
          required
        />
        <FormSelectContainer
          name="pc_pd_beneficiary_country"
          disabled={isLoading}
          options={countries}
          label={t('layout__fields__country')}
          data-test-id="partner-payments-details__select--beneficiary-country"
          required
        />
        <FormInputContainer
          name="pc_pd_beneficiary_city"
          disabled={isLoading}
          label={t('layout__fields__city')}
          data-test-id="partner-payments-details__input--beneficiary-city"
          required
        />
        <FormInputContainer
          name="pc_pd_beneficiary_postal_code"
          disabled={isLoading}
          label={t('layout__fields__postal_code')}
          data-test-id="partner-payments-details__input--beneficiary-postal-code"
          required
        />
        <FormInputContainer
          name="pc_pd_bank_name"
          disabled={isLoading}
          label={t('layout__fields__bank_name')}
          data-test-id="partner-payments-details__input--bank-name"
          required
        />
        <FormInputContainer
          name="pc_pd_swift"
          disabled={isLoading}
          label={t('generic__swift')}
          data-test-id="partner-payments-details__input--swift"
          required
        />
        <FormInputContainer
          name="pc_pd_iban"
          disabled={isLoading}
          label={t('layout__fields__iban')}
          data-test-id="partner-payments-details__input--iban"
          required
        />
        <FormInputContainer
          name="pc_pd_sort_code"
          disabled={isLoading}
          label={t('layout__fields__sort_code')}
          data-test-id="partner-payments-details__input--sort-code"
        />
        <FormInputContainer
          name="pc_pd_account_number"
          disabled={isLoading}
          label={t('layout__partner_payments_details_form__account_number')}
          data-test-id="partner-payments-details__input--account-number"
        />
        <FormInputContainer
          name="pc_vat_number"
          disabled={isLoading}
          label={t('layout__fields__vat_number')}
          data-test-id="partner-payments-details__input--vat"
        />
      </StyledPartnerPaymentsDetailsFormGrid>
      <PartnerPaymentsDetailsFormNote
        diffList={diffList}
        previousChangeRequest={previousChangeRequest}
      />
    </StyledPartnerPaymentsDetailsFormWrapper>
  );
};
