import { styled } from 'theme';

export const StyledToBankAccountContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 24px;
  }
`;

export const StyledHandlePersonalIbanContainer = styled('div')`
  display: flex;
  background-color: ${({ theme }) => theme.color.bg.secondary};
  padding: 16px;
  border-radius: 4px;
  flex-direction: column;
`;

export const StyledHandlePersonalIbanDescription = styled('p')`
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.ghost};
  padding-left: 24px;
`;

export const StyledTargetContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledAddressFields = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledDetailPurposeContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 12px 12px;
  border-radius: 4px;
  align-self: stretch;
  gap: 1px;
  border: 1px solid
    ${({ theme }) => {
      return theme.color.bg.line;
    }};

  background-color: transparent;
`;

export const StyledDetailPurposeTitle = styled('span')`
  font-size: 15px;
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledDetailPurpose = styled('textarea')`
  resize: vertical;
  border: 0;
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  box-sizing: border-box;
  min-height: 96px;
  font-family: inherit;
  padding: 8px 0;
  font-size: 15px;
  color: ${({ theme }) => theme.color.typo.primary};

  &:disabled {
    color: ${({ theme }) => theme.color.typo.ghost};
  }

  &:focus {
    outline: 0;
  }
`;

export const StyledFileUploadContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .RowContainerLeft {
      width: 100%;
    }

    & .RowContainerLeft > label {
      width: 100%;
    }
  }
`;

export const StyledFileUploadHeader = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledExpandedFields = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledCheckboxWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledCommentHeaderWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCommentTooltipList = styled('ul')`
  padding: 0;
  list-style-type: none;
`;
