export const getFormData = (
  payload?: Array<
    [string, string | number | BinaryData | File | boolean | undefined | null]
  >,
) => {
  const formData = new FormData();

  if (payload && payload.length > 0) {
    payload.forEach(([key, value]) => {
      if (value) {
        formData.append(key, value.toString());
      }
    });
  }

  return {
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
};
