import { EStatus } from 'types/api';

import { TApplicationState } from './application.types';

export const applicationInitialState: TApplicationState = {
  status: EStatus.Idle,
  mfa: {
    allowed: ['email', 'totp', 'sms'],
    atp: { enabled: false, type_name: null },
    site: { enabled: true, type_name: null },
  },
  accountSettings: {
    is_account_descriptions_available: false,
    is_autodebit_available: false,
    is_cash_conversion_available: false,
    is_margin_trading_available: false,
    is_reset_demo_available: false,
    is_account_management_available: false,
    is_demo_enabled: false,
  },
};
