import { TPositionConfigItem } from 'components/Positions/Positions.types';
import { prepareTableId } from 'helpers/prepareTableId';

export const POSITIONS_CONFIG: Record<string, TPositionConfigItem> = {
  cash: {
    id: 'cash',
    title: 'layout__summary__cash_balance',
    order: 0,
    iconName: 'R',
    types: ['CURRENCY', 'FOREX'],
    requiredColumnKeys: ['description'],
  },
  forex: {
    id: 'forex',
    title: 'layout__summary__forex',
    order: 1,
    iconName: 'F',
    types: ['FX_SPOT'],
    requiredColumnKeys: ['symbolId'],
  },
  futures: {
    id: 'futures',
    title: 'layout__summary__futures',
    order: 2,
    iconName: 'I',
    types: ['FUTURE', 'CALENDAR_SPREAD'],
    requiredColumnKeys: ['symbolId'],
  },
  options: {
    id: 'options',
    title: 'layout__summary__options',
    order: 3,
    iconName: 'C',
    types: ['OPTION'],
    requiredColumnKeys: ['symbolId'],
  },
  bonds: {
    id: 'bonds',
    title: 'layout__summary__bonds',
    order: 4,
    iconName: 'B',
    types: ['BOND'],
    requiredColumnKeys: ['symbolId'],
  },
  stocks: {
    id: 'stocks',
    title: 'layout__summary__stocks_etfs',
    order: 5,
    iconName: 'S',
    types: ['STOCK'],
    requiredColumnKeys: ['symbolId'],
  },
  funds: {
    id: 'funds',
    title: 'layout__summary__funds_otc',
    order: 6,
    iconName: 'O',
    types: ['FUND'],
    requiredColumnKeys: ['symbolId'],
  },
  swaps: {
    id: 'swaps',
    title: 'layout__summary__swaps',
    order: 7,
    iconName: 'CFD',
    types: ['CFD'],
    requiredColumnKeys: ['symbolId'],
  },
};

export const MAX_TABLE_PAGE_ITEMS = 20;
export const CASH_GROUP = 'CURRENCY';
export const ACCOUNT_ASSET_ACCORDION_LS_KEY = 'asset-accordion';
export const REQUIRED_COLUMNS = ['symbolId'];
export const ACCOUNT_ASSET_TABLE_ID = prepareTableId('asset');
export const TABLE_IDS = Object.keys(POSITIONS_CONFIG).map(
  (key) => `${ACCOUNT_ASSET_TABLE_ID}-${key}`,
);

enum ValueType {
  ConvertedValue = 'convertedValue',
  ConvertedPnl = 'convertedPnl',
}

export const valueTypeForTotalMap: Record<string, ValueType> = {
  cash: ValueType.ConvertedValue,
  options: ValueType.ConvertedValue,
  bonds: ValueType.ConvertedValue,
  stocks: ValueType.ConvertedValue,
  funds: ValueType.ConvertedValue,
  swaps: ValueType.ConvertedPnl,
  forex: ValueType.ConvertedPnl,
  futures: ValueType.ConvertedPnl,
};

export const POSITIONS_DEFAULT_DISPLAYED_COLUMN_KEYS = [
  'symbolId',
  'description',
  'quantity',
  'averagePrice',
  'price',
  'currency',
  'pnl',
  'convertedPnl',
  'value',
  'convertedValue',
];
