import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

export const ChallengePurchaseSkeleton: FC = () => {
  return (
    <>
      {[...Array(3)].map((_, k) => (
        <Skeleton
          key={`block_${k}`}
          variant="rounded"
          animation="wave"
          height={10}
        />
      ))}
    </>
  );
};
