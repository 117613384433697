import { CheckmarkBoldIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledReportUnion = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledReportUnionHead = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  .ReportAccountList {
    font-size: 20px;
  }
`;

export const StyledReportUnionBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledReportUnionItem = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const StyledCheckmarkIcon = styled(CheckmarkBoldIcon)`
  flex-shrink: 0;

  width: 16px;
  height: 16px;

  color: ${({ theme }) => theme.color.icon.source};
`;

export const StyledReportUnionTitle = styled('span')`
  color: ${({ theme }) => theme.color.typo.secondary};

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledReportUnionValue = styled('span')`
  color: ${({ theme }) => theme.color.typo.primary};

  text-align: end;
  white-space: break-spaces;
`;
