import { FC } from 'react';

import { ExchangeWrapper } from './components';
import { ExchangeProvider } from './contexts';

export const Exchange: FC = () => (
  <ExchangeProvider>
    <ExchangeWrapper />
  </ExchangeProvider>
);
