import { ButtonBase, css, ListItem } from '@mui/material';
import { blockNonNativeProps } from 'react-ui-kit-exante';

import { TNotification } from 'services/notifications';
import { styled } from 'theme';

type TNotificationStyledProps = Pick<TNotification, 'notified'>;
type TNotificationStatusProps = Pick<TNotification, 'blocking' | 'notified'>;

export const StyledNotificationListItem = styled(ListItem)`
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
`;

export const StyledNotificationWrapperButton = styled(ButtonBase, {
  shouldForwardProp: blockNonNativeProps(['notified']),
})<TNotificationStyledProps>`
  display: flex;
  padding: 8px 16px !important;
  width: 100%;
  justify-content: flex-start !important;
  align-items: flex-start !important;

  border-radius: 4px !important;
  cursor: pointer !important;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  background: ${({ theme, notified }) =>
    notified ? theme.color.bg.basic : theme.color.bg.primary} !important;
`;

export const StyledNotificationStatus = styled('div', {
  shouldForwardProp: blockNonNativeProps(['notified', 'blocking']),
})<TNotificationStatusProps>`
  width: 8px;
  height: 8px;
  margin-top: 8px;

  border-radius: 50%;

  ${({ theme, notified, blocking }) => {
    const { ghost, radical, source } = theme.color.typo;

    if (notified) {
      return css`
        background: ${ghost};
      `;
    }

    if (blocking) {
      return css`
        background: ${radical};
      `;
    }

    if (!notified) {
      return css`
        background: ${source};
      `;
    }

    return css`
      background: none;
    `;
  }};
`;

export const StyledNotificationAside = styled('div')`
  flex: 0 0 24px;
`;

export const StyledNotificationBody = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledNotificationHeading = styled('div')`
  margin-bottom: 2px;

  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 15px;
  line-height: 24px;
  text-align: left;
`;

export const StyledNotificationExcerpt = styled('div')`
  flex: 0 0 24px;

  margin-bottom: 2px;

  color: ${({ theme }) => theme.color.typo.promo};
  font-size: 13px;
  line-height: 16px;

  & p {
    margin: 0 0 5px;
  }
`;

export const StyledNotificationContent = styled('div')`
  text-align: left;
`;

export const StyledNotificationDate = styled('div')`
  color: ${({ theme }) => theme.color.typo.ghost}
  font-size: 11px;
`;
