import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { formatCellToCurrencyUnrounded } from 'helpers/formatters';
import { TTransactionData } from 'services/transactions';
import { TPosition } from 'types/accounts';

import { TGetTransfersHistoryTableColumnsProps } from './TransfersHistoryTable.types';

export const getTransfersHistoryTableColumns = ({
  t,
  dateFormatter,
  currencyFormatter,
  operationTypes,
  onFilter,
  onRemove,
}: TGetTransfersHistoryTableColumnsProps): IColumn<TTransactionData>[] => [
  {
    accessor: 'id',
    Header: t('layout__id'),
    required: true,
    disableFilters: true,
  },
  {
    accessor: 'operationType',
    Header: t('layout__transactions__transaction_type'),
    onFilter,
    onRemove,
    type: 'multiSelect',
    filterOptions: operationTypes,
  },
  {
    accessor: 'accountId',
    Header: t('layout__transactions__account_num'),
    disableFilters: true,
  },
  {
    accessor: 'timestamp',
    Header: t('layout__transactions__date_time'),
    Cell: ({ row: { values } }: ICellValue<TPosition>) =>
      dateFormatter(values.timestamp),
    disableFilters: true,
  },
  {
    accessor: 'sum',
    Header: t('layout__transactions__sum'),
    isNumeric: true,
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'sum'),
    disableFilters: true,
  },
  {
    accessor: 'asset',
    Header: t('layout__transactions__asset'),
    disableFilters: true,
  },
];
