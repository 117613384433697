import { Cancel } from 'axios';

import { CANCELLED_ERROR } from 'constants/api';
import { MAX_REQUEST_LIMIT, MAX_SEARCH_LIMIT } from 'constants/instrumentsTree';
import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';
import { INSTRUMENTS_TREE_APIS_MAP } from 'services/instrumentsTree/instrumentsTree.constants';
import { TDataWithPagination } from 'types/api';

import {
  convertStructureFromFlatToTree,
  DEFAULT_TREE_SET,
} from './instrumentsTree.helpers';
import {
  TGetInstrumentsParams,
  TSearchInstrumentsParams,
  TTreeModule,
  TTreeParams,
  TTreeResponse,
  TTreeResponseTreeStructure,
} from './instrumentsTree.types';

class InstrumentsTreeService extends ApiRequest {
  async getTree(
    params: TTreeParams,
    module: TTreeModule = TTreeModule.PERMISSIONS,
  ) {
    try {
      const { data } = await this.fetch<{
        data: TTreeResponse[];
      }>({
        url: INSTRUMENTS_TREE_APIS_MAP[module].tree,
        params: {
          ...params,
          username:
            module === TTreeModule.PERMISSIONS
              ? encodeURIComponent(params.username)
              : null,
        },
        signal: params.options.signal,
      });

      if (data.data) {
        return convertStructureFromFlatToTree(data.data, module);
      }

      return null;
    } catch (error) {
      if ((error as Cancel)?.message !== CANCELLED_ERROR) {
        notifyWith.genericNetworkError(error);
        return null;
      }
      console.error('getAccountSymbols error:', error);
      return CANCELLED_ERROR;
    }
  }

  async getInstruments(
    params: TGetInstrumentsParams,
    module: TTreeModule = TTreeModule.PERMISSIONS,
  ): Promise<TDataWithPagination<TTreeResponseTreeStructure[]> | null> {
    try {
      const { data } = await this.fetch<TDataWithPagination<TTreeResponse[]>>({
        url: INSTRUMENTS_TREE_APIS_MAP[module].instruments,
        params: {
          ...params,
          username:
            module === TTreeModule.PERMISSIONS
              ? encodeURIComponent(params.username)
              : null,
        },
      });

      return {
        ...data,
        data: data.data.map(({ displayName, id, path, ...rest }) => {
          return {
            icon: null,
            displayName,
            path,
            id,
            rowType: 'instrument',
            ...DEFAULT_TREE_SET[module],
            ...rest[module],
            ...(module === TTreeModule.COMMISSION
              ? {
                  currency: rest?.[module]?.currency || rest?.currency,
                }
              : {}),
          };
        }),
      };
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getAccountSymbols error:', error);
      return null;
    }
  }

  async searchInstruments(
    {
      search,
      limit = MAX_REQUEST_LIMIT,
      skip = 0,
      instruments = [],
      accountId,
      username,
      lang,
      pathForSymbol,
      options,
    }: TSearchInstrumentsParams,
    module: TTreeModule = TTreeModule.PERMISSIONS,
  ): Promise<TDataWithPagination<TTreeResponseTreeStructure[] | null>> {
    try {
      const { data } = await this.fetch<TDataWithPagination<TTreeResponse[]>>({
        url: INSTRUMENTS_TREE_APIS_MAP[module].instruments,
        params: {
          search,
          limit,
          skip,
          lang,
          path: pathForSymbol,
          accountId,
          username:
            module === TTreeModule.PERMISSIONS
              ? encodeURIComponent(username)
              : null,
        },
        signal: options.signal,
      });

      if (!data) {
        return {
          data: null,
          pagination: {
            total: 0,
          },
        };
      }

      const { pagination, data: newInstruments } = data;

      const resultInstruments = [
        ...instruments,
        ...newInstruments.map<TTreeResponseTreeStructure>(
          ({ displayName, id, path, ...rest }) => ({
            icon: null,
            id,
            displayName,
            rowType: 'instrument',
            path,
            ...DEFAULT_TREE_SET[module],
            ...rest[module],
            ...(module === TTreeModule.COMMISSION
              ? {
                  currency: rest?.[module]?.currency || rest?.currency,
                }
              : {}),
          }),
        ),
      ];

      if (
        pagination.total > skip + MAX_REQUEST_LIMIT &&
        (pagination.total < MAX_SEARCH_LIMIT || pathForSymbol)
      ) {
        return await this.searchInstruments(
          {
            search,
            limit,
            skip: skip + limit,
            lang,
            instruments: resultInstruments,
            accountId,
            username,
            options,
          },
          module,
        );
      }

      return {
        data: resultInstruments,
        pagination: {
          total: pagination.total,
        },
      };
    } catch (error) {
      if ((error as Cancel)?.message !== CANCELLED_ERROR) {
        notifyWith.genericNetworkError(error);
      }
      console.error('searchInstruments error:', error);
      return {
        data: null,
        pagination: {
          total: 0,
        },
      };
    }
  }
}

export const instrumentsTreeService = new InstrumentsTreeService();
