import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IMenuItem, Menu, MenuSkeleton } from 'react-ui-kit-exante';

import {
  BrandingContext,
  TranslationContext,
  UserDetailsContext,
} from 'contexts';
import { TabsContextData } from 'contexts/TabsContext';
import { TMenuItem } from 'services/menu';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { selectMenu, selectMenuItems } from 'store/menu';
import { EStatus } from 'types/api';

import {
  getChallengeMenu,
  getMenu,
  sanitizeMenuItems,
} from './Navigation.helper';
import { TExtendedMenuItem, TMenuIcons } from './Navigation.types';

export const Navigation: FC = () => {
  const { theme: brandingTheme, branding } = useContext(BrandingContext);
  const { userDetails } = useContext(UserDetailsContext);
  const items = useAppSelector(selectMenuItems);
  const { status } = useAppSelector(selectMenu);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const [challengeMenu, setChallengeMenu] = useState<TExtendedMenuItem[]>([]);

  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);
  const { tabs } = useContext(TabsContextData);

  const location = useLocation();

  const menuItems: TMenuItem[] = sanitizeMenuItems(items);

  const menuIcons: TMenuIcons = useMemo(
    () => brandingTheme?.images?.menu || {},
    [brandingTheme],
  );

  useEffect(() => {
    getChallengeMenu(
      branding,
      selectedAccountId,
      userDetails?.info?.current.email,
    ).then((menu) => {
      if (JSON.stringify(menu) !== JSON.stringify(challengeMenu)) {
        setChallengeMenu(menu);
      }
    });
  }, [selectedAccountId, branding, userDetails?.info]);

  const mappedMenu = useMemo<IMenuItem[]>(
    () => getMenu(menuIcons, [...menuItems, ...challengeMenu], tabs, t),
    [menuItems, menuIcons, tabs, t, isTranslationsLoading, challengeMenu],
  );

  if (
    [EStatus.Loading, EStatus.Idle].includes(status) ||
    !currentLanguage ||
    isTranslationsLoading ||
    !tabs.length
  ) {
    return <MenuSkeleton itemCount={7} />;
  }

  return <Menu items={mappedMenu} location={location} className="menu" />;
};
