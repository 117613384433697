import { useContext, useMemo } from 'react';

import { BrandingContext } from 'contexts/BrandingContext';

import { useApi } from '../../../hooks';

export const useSources = () => {
  const { branding } = useContext(BrandingContext);
  const { applications, tab, opts } = useApi();

  const clientID = useMemo<string>(() => {
    if (tab === 0) {
      return opts.live_access;
    }

    return opts.demo_access;
  }, [tab, opts]);

  const dataURL = useMemo<string>(() => {
    if (tab === 0) {
      return opts.API_ENDPOINT_DATA_LIVE;
    }

    return opts.API_ENDPOINT_DATA_DEMO;
  }, [tab, opts]);

  const tradeURL = useMemo<string>(() => {
    if (tab === 0) {
      return opts.API_ENDPOINT_TRADE_LIVE;
    }

    return opts.API_ENDPOINT_TRADE_DEMO;
  }, [tab, opts]);

  const sources = useMemo(() => {
    if (tab === 0) {
      return applications.filter((item) => item.environment === 0);
    }

    return applications.filter((item) => item.environment === 1);
  }, [applications, tab]);

  return {
    clientID,
    dataURL,
    tradeURL,
    sources,
    isSuperVisor: branding?.is_supervisor,
  };
};
