import { AxiosError } from 'axios';

import { TIMEOUT_ERRORS } from 'constants/api';
import { Abort } from 'helpers/abortController';
import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TFetchAccountHistoryParams,
  TFetchAccountHistoryResponse,
  TGetXLSReportResponse,
  TReportType,
} from './analysis.types';

class AnalysisService extends ApiRequest {
  abortClient;

  constructor() {
    super();
    this.abortClient = new Abort();
  }

  async getAccountHistory(
    lang: string,
    params: Partial<TFetchAccountHistoryParams>,
  ): Promise<TFetchAccountHistoryResponse | null> {
    try {
      const { data } = await this.fetch<TFetchAccountHistoryResponse>({
        url: `/${lang}/clientsarea/rest/bo/history/json/all/?timeout=180`,
        params,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getAccountHistory error:', error);
      const status = (error as AxiosError)?.response?.status;
      if (status && TIMEOUT_ERRORS.includes(status)) {
        return { isTimeoutError: true };
      }

      return null;
    }
  }

  async getAnalysisReport(params: TFetchAccountHistoryParams) {
    try {
      const { data } = await this.fetch<string>({
        url: `/clientsarea/account/reports/api/custom_reports/shortcut/`,
        params: {
          ...params,
          type: 'performance',
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getAccountReports error:', error);
      return null;
    }
  }

  async getXLSReport(
    lang: string,
    reportType: TReportType,
    filters: TFetchAccountHistoryParams,
  ) {
    try {
      const { data } = await this.fetch<TGetXLSReportResponse>({
        url: `/${lang}/clientsarea/rest/bo/history/xls/${reportType}/`,
        params: filters,
      });

      return data?.is_done !== false;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getAccountReports error:', error);
      return false;
    }
  }
}

export const analysisService = new AnalysisService();
