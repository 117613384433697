import { FC, useContext, useState } from 'react';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { TranslationContext } from 'contexts';

import { CardAction } from '../CardAction';
import { PinTooltip } from '../PinTooltip';

import { TShowPinActionProps } from './ShowPinAction.types';

export const ShowPinAction: FC<TShowPinActionProps> = ({ cardId }) => {
  const { t } = useContext(TranslationContext);

  const [modalVisible, setModalVisible] = useState(false);

  const showTooltip = () => setModalVisible(true);
  const closeTooltip = () => setModalVisible(false);

  return (
    <ActionWithConfirmation
      content={<PinTooltip onClose={closeTooltip} cardId={cardId} />}
      externalOpened={modalVisible}
      closeHandler={closeTooltip}
      openFromExternal
      hideDefaultButtons
    >
      <CardAction
        iconName="ChangeCardPinIcon"
        label={t('layout__cards__show_pin')}
        onClick={showTooltip}
      />
    </ActionWithConfirmation>
  );
};
