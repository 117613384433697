import { useMediaQuery } from '@mui/material';
import { Children, FC, useContext } from 'react';

import { StyledHeaderButton } from 'components/Header/Header.styled';
import {
  StyledLangSelectorAccountMenuIcon,
  StyledLangSelectorCloseIcon,
  StyledLangSelectorList,
} from 'components/Header/components/MobileMenu/MobileMenu.styled';
import { TranslationContext } from 'contexts';
import { useTheme } from 'theme';

import { TMobileMenuProps } from './MobileMenu.types';

export const MobileMenu: FC<TMobileMenuProps> = ({
  children,
  opened,
  onClick,
}) => {
  const { t } = useContext(TranslationContext);
  const theme = useTheme();
  const MobileMenuButtonIcon = opened
    ? StyledLangSelectorCloseIcon
    : StyledLangSelectorAccountMenuIcon;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const buttonTitle = !opened
    ? t('layout__header__open_account_menu')
    : t('layout__header__close_account_menu');

  return isDesktop ? null : (
    <>
      <StyledHeaderButton
        onClick={onClick}
        className="header__account"
        title={buttonTitle}
        disableRipple
        opened={opened}
      >
        <MobileMenuButtonIcon className="xnt-icon" />
      </StyledHeaderButton>
      {opened && !!Children.count(children) && (
        <StyledLangSelectorList className="LangSelectorList">
          {children}
        </StyledLangSelectorList>
      )}
    </>
  );
};
