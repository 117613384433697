import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledCryptoAddress = styled('div')`
  width: 100%;

  margin: 24px 0;

  display: flex;

  align-items: center;
  justify-content: center;
  gap: 8px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    justify-content: flex-start;
  }
`;

export const StyledCryptoAddressText = styled('div')`
  color: ${({ theme }) => theme.color.typo.action};

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  text-align: center;
  word-break: break-all;
`;

export const StyledCryptoAddressIconSkeleton = styled(Skeleton)`
  width: 24px;
  height: 24px;
`;

export const StyledCryptoAddressTextSkeleton = styled(Skeleton)`
  width: 100%;
  height: 24px;
`;
