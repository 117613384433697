import { cloneDeep, groupBy, set, unset } from 'lodash';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { LAST_SEGMENT_IN_PATH_REGEXP } from 'constants/instrumentsTree';
import { getAllPathsSet } from 'helpers/getAllPathsSet';
import { instrumentsTreeService } from 'services/instrumentsTree/instrumentsTree';
import { TTreeResponseTreeStructure } from 'services/instrumentsTree/instrumentsTree.types';

import { InstrumentsTreeContext } from '../../context/InstrumentsTreeContext';
import { ActionTypes } from '../../context/InstrumentsTreeContext.types';

import { getExpandedRowsIds } from './helpers/getExpandedRowsIds';
import { TUseSearchInstrumentsParams } from './useSearchInstruments.types';

export const useSearchInstruments = ({
  matchIdAndPositionInSearchTable,
  setTableData,
  accountId,
  username,
  currentTree,
  initialTree,
  fetchTree,
  lang,
  resetTableMetaData,
  updateTableSizes,
}: TUseSearchInstrumentsParams) => {
  const abortController = useRef(new AbortController());
  const [state, dispatch] = useContext(InstrumentsTreeContext);
  const [searchIsLoading, setSearchIsLoading] = useState(false);

  const resetTable = useCallback(() => {
    setSearchIsLoading(true);
    resetTableMetaData();
    setTableData(initialTree.current);
    setSearchIsLoading(false);
    updateTableSizes();
  }, [dispatch, setTableData, initialTree, updateTableSizes]);

  const updateTableWithInstruments = (
    downloadedInstruments: TTreeResponseTreeStructure[] | null,
    pathForSymbol?: string,
  ) => {
    const tree = cloneDeep(currentTree.current);

    if (!tree) {
      return;
    }

    const groupedInstrumentsByPath = groupBy(downloadedInstruments, (item) =>
      item.path.replace(LAST_SEGMENT_IN_PATH_REGEXP, ''),
    );

    const pathsWithInstruments: string[] = [];

    const defaultPositionByIdInTree = {
      ...matchIdAndPositionInSearchTable.current,
    };

    Object.entries(groupedInstrumentsByPath).forEach(([path, instruments]) => {
      const position = defaultPositionByIdInTree[path];
      set(tree, `${position}.subRows`, instruments);
      pathsWithInstruments.push(path);
      delete defaultPositionByIdInTree[path];
    });

    const allPathsSet = getAllPathsSet(
      pathsWithInstruments,
      LAST_SEGMENT_IN_PATH_REGEXP,
    );

    Object.entries(defaultPositionByIdInTree)
      .filter(([p]) => !pathForSymbol || p.includes(pathForSymbol))
      .forEach(([path, position]) => {
        if (!allPathsSet.has(path)) {
          unset(tree, position);
        }
      });

    if (pathForSymbol) {
      const firstTreeElemIdx = tree.findIndex((i) => i);
      if (firstTreeElemIdx !== -1) {
        const rowsForExpand = getExpandedRowsIds(
          tree[firstTreeElemIdx],
          firstTreeElemIdx,
        );

        dispatch({
          type: ActionTypes.SET_EXPANDED_ROWS,
          payload: rowsForExpand,
        });
      }
    } else {
      const rowsForExpand = tree.reduce((acc, curr, idx) => {
        if (curr) {
          return { ...acc, ...getExpandedRowsIds(curr, idx) };
        }
        return acc;
      }, {});

      dispatch({
        type: ActionTypes.SET_EXPANDED_ROWS,
        payload: rowsForExpand,
      });
    }

    setTableData(tree);
  };

  const debounceSearchInstruments = useCallback(
    async (searchValue: string) => {
      abortController.current.abort();
      abortController.current = new AbortController();

      const search = searchValue.trim();

      if (!accountId) {
        return;
      }

      if (search.length < 3) {
        resetTable();
        return;
      }

      setSearchIsLoading(true);

      const { data: instrumentsFromServer, pagination } =
        await instrumentsTreeService.searchInstruments(
          {
            search,
            accountId,
            username,
            lang,
            options: {
              signal: abortController.current.signal,
            },
          },
          state.module,
        );

      if (
        !instrumentsFromServer ||
        !matchIdAndPositionInSearchTable.current ||
        !currentTree.current
      ) {
        setSearchIsLoading(false);
        return;
      }

      // if is there more instruments we should get full tree without instruments and get instruments with first symbol ID
      if (instrumentsFromServer.length < pagination.total) {
        await fetchTree(search);

        const pathForSymbol = currentTree.current[0].path;
        const { data: instrumentsFromServerForFirstNode } =
          await instrumentsTreeService.searchInstruments(
            {
              search,
              accountId,
              username,
              pathForSymbol,
              options: {
                signal: abortController.current.signal,
              },
            },
            state.module,
          );

        updateTableWithInstruments(
          instrumentsFromServerForFirstNode,
          pathForSymbol,
        );
      } else {
        // if tree was load with search filter we should update tree for correct match instruments
        if (state.treeWasFiltered) {
          await fetchTree();
        }
        updateTableWithInstruments(instrumentsFromServer);
      }
      setSearchIsLoading(false);
    },
    [
      state.treeWasFiltered,
      accountId,
      matchIdAndPositionInSearchTable,
      setSearchIsLoading,
      setTableData,
      currentTree,
      resetTable,
      fetchTree,
    ],
  );

  useEffect(() => {
    return () => {
      setSearchIsLoading(false);
    };
  }, []);

  return useMemo(
    () => ({
      debounceSearchInstruments,
      searchIsLoading,
    }),
    [
      searchIsLoading,
      debounceSearchInstruments,
      accountId,
      fetchTree,
      state.treeWasFiltered,
    ],
  );
};
