import { useEffect, useState } from 'react';

import { useUserDetails } from 'hooks';
import { mfaService } from 'services/mfa';

import { SMS_CODE_LENGTH } from '../../../../Mfa.constants';
import { useCodeVerification } from '../../../../hooks';

export const useEnableSms = (onSuccess: () => void) => {
  const {
    seconds,
    handleCodeChange,
    handleResetTimer,
    handleResend,
    code,
    isVerifying,
    isRequesting,
    requestErrorMessage,
    verifyErrorMessage,
  } = useCodeVerification(SMS_CODE_LENGTH, 'sms', { onSuccess });

  const [enabled, setEnabled] = useState(false);
  const { countryCode, phoneNumber } = useUserDetails();

  const handleResendClick = async () => {
    handleResetTimer();

    if (!enabled) {
      await mfaService.enableService('sms', 'site');
      setEnabled(true);
    }

    await handleResend();
  };

  useEffect(() => {
    handleResendClick();
  }, []);

  return {
    code,
    enabled,
    countryCode,
    phoneNumber,
    isRequesting,
    isVerifying,
    requestErrorMessage,
    seconds,
    verifyErrorMessage,
    handleCodeChange,
    handleResendClick,
  };
};
