import { Tooltip } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const Container = styled('div')`
  display: grid;
`;

export const StyledTabsContainer = styled('div')`
  display: grid;
  padding: 24px 0;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 24px 0;
  }
`;

export const StyledButtonsGroupWrapper = styled('div')`
  padding: 0 24px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 24px;
  }
`;

export const StyledTransfersPlaceholder = styled('div')`
  display: flex;
  padding: 24px;
`;

export const StyledTooltip = styled(Tooltip)`
  color: ${({ theme }) => theme.color.typo.secondary};
`;
