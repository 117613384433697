import HCaptcha from '@hcaptcha/react-hcaptcha';
import { FC, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Notification as UiKitNotification } from 'react-ui-kit-exante';

import { FormInputContainer } from 'components/Form/FormInputContainer/FormInputContainer';
import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import {
  StyledCaptchaErrorAction,
  StyledCaptchaErrorText,
  StyledCaptchaErrorWrapper,
} from 'pages/Login/Login.styled';
import { PATHS } from 'router/router.constants';

import { saveLoginMetric } from '../../../../helpers';

import {
  StyledResetPasswordButton,
  StyledSigninActions,
  StyledSigninButton,
  StyledSigninCaptchaWrapper,
  StyledSigninPassword,
} from './SigninPassword.styled';
import { SigninPasswordSkeleton } from './components/SigninPasswordSkeleton';
import {
  TSigninPasswordForm,
  useSigninByPassword,
} from './hooks/useSigninByPassword';

export const SigninPassword: FC = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const navigate = useNavigate();

  const methods = useForm<TSigninPasswordForm>();

  const { handleSubmit, setError, getValues } = methods;

  const onError = (field: keyof TSigninPasswordForm, message: string) => {
    setError(field, { message: t(message) });
  };

  const {
    signin,
    isLoading,
    captcha,
    setCaptcha,
    setCaptchaToken,
    captchaRef,
  } = useSigninByPassword({
    getValues,
    onError,
  });

  if (isTranslationsLoading) {
    return <SigninPasswordSkeleton />;
  }

  const handleCaptchaExpire = () => {
    setCaptchaToken('');
  };

  const handleCaptchaError = (err: string) => {
    console.error(`hCaptcha Error: ${err}`);
    UiKitNotification.error({ title: err });

    setCaptcha((state) => ({ ...state, visible: true, error: '' }));
  };

  const handleReset = () => {
    navigate(PATHS.RECOVERY_REQUEST, { state: { fromLogin: true } });
  };

  const handleSignup = () => {
    navigate(PATHS.OPEN_ACCOUNT);
  };

  const handleCaptchaErrorClick = () => {
    setCaptcha((state) => ({
      ...state,
      visible: true,
      error: '',
    }));
  };

  const isOpenAccountHidden =
    branding?.hide_open_account_type?.includes('individual') &&
    branding?.hide_open_account_type?.includes('corporate');

  const isAuthOnly = !branding?.signup_available || isOpenAccountHidden;

  const loginButtonCaption = t(
    branding?.login_wording || 'generic__action__login',
  );
  const loginPlaceholder =
    branding?.login_placeholder ||
    (isAuthOnly ? t('generic__action__login') : t('generic__email_or_login'));

  return (
    <FormProvider {...methods}>
      <StyledSigninPassword
        className="SigninPassword"
        onSubmit={handleSubmit(signin)}
      >
        <FormInputContainer
          type="text"
          name="login"
          autoComplete="username"
          label={loginPlaceholder}
          fullWidth
        />
        <FormInputContainer
          type="password"
          name="password"
          autoComplete="current-password"
          label={t('generic__password')}
          fullWidth
        />

        {captcha.enabled && (
          <StyledSigninCaptchaWrapper className="SigninCaptchaWrapper">
            {captcha.visible && (
              <HCaptcha
                sitekey={captcha.site_key}
                languageOverride={currentLanguage}
                reCaptchaCompat={false}
                onVerify={setCaptchaToken}
                onError={handleCaptchaError}
                onExpire={handleCaptchaExpire}
                ref={captchaRef}
              />
            )}

            {captcha.error && !captcha.visible && (
              <StyledCaptchaErrorWrapper className="CaptchaErrorWrapper">
                <StyledCaptchaErrorText className="CaptchaErrorText">
                  {t('generic__something_went_wrong')}.
                </StyledCaptchaErrorText>
                <StyledCaptchaErrorAction
                  className="CaptchaErrorButton"
                  color="transparent"
                  onClick={handleCaptchaErrorClick}
                >
                  {t('generic__action__retry')}
                </StyledCaptchaErrorAction>
              </StyledCaptchaErrorWrapper>
            )}
          </StyledSigninCaptchaWrapper>
        )}

        <StyledSigninActions className="SigninActions">
          <StyledSigninButton
            className="SigninButton"
            color="primary"
            fullWidth
            type="submit"
            loading={isLoading}
            disabled={Boolean(captcha.error)}
            onClick={saveLoginMetric}
          >
            {loginButtonCaption}
          </StyledSigninButton>

          {!isAuthOnly && (
            <StyledSigninButton
              className="SigninButton"
              color="secondary"
              onClick={handleSignup}
              disabled={isLoading}
              fullWidth
            >
              {t('layout__signin__open_account')}
            </StyledSigninButton>
          )}
        </StyledSigninActions>

        <StyledResetPasswordButton
          className="ResetPasswordButton"
          color="transparent"
          onClick={handleReset}
          disabled={isLoading}
        >
          {t('layout__auth__forgot_password')}
        </StyledResetPasswordButton>
      </StyledSigninPassword>
    </FormProvider>
  );
};
