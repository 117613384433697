import { useMediaQuery } from '@mui/material';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { StyledHeaderDepositIcon } from 'components/Header/components/HeaderDeposit/HeaderDeposit.styled';
import { THeaderDepositAccountsFeatures } from 'components/Header/components/HeaderDeposit/HeaderDeposit.types';
import { TabsContextData, TranslationContext } from 'contexts';
import { useQuery, useRouteAssociatedAccounts } from 'hooks';
import { PATHS } from 'router';
import { ALL_TAB_ID } from 'services/tabs';
import { accountsActions, selectAccounts } from 'store/accounts';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTheme } from 'theme';

import {
  accountIdToSelect,
  reduceAccountsToFeatures,
} from './HeaderDeposit.helpers';

export const HeaderDeposit: FC = () => {
  const { t } = useContext(TranslationContext);
  const { selectedAccount } = useAppSelector(selectAccounts);
  const isSuperuser = useAppSelector((state) => state.accounts.isSuperuser);
  const { tabs, availableTabs } = useContext(TabsContextData);

  const query = useQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accounts = useRouteAssociatedAccounts();
  const theme = useTheme();

  const [
    [shouldHandleVueRedirect, waitingForDepositTab],
    updateRedirectValues,
  ] = useState([query.get('resolve') === 'true', false]);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const navigateToDeposit = () => navigate(PATHS.ROOT + PATHS.DEPOSIT);

  const accountsFeatures = useMemo<THeaderDepositAccountsFeatures>(
    () => reduceAccountsToFeatures(accounts),
    [accounts],
  );

  const { isOnlyPartnerAccounts } = accountsFeatures;

  const depositDisabled = useMemo<boolean>(
    () => !!tabs.find((tab) => tab.id === ALL_TAB_ID.DEPOSIT),
    [tabs],
  );

  const depositAvailable = useMemo<boolean>(
    () => !!availableTabs.find((tab) => tab.id === ALL_TAB_ID.DEPOSIT),
    [availableTabs],
  );

  const openDepositPage = () => {
    const accountId = accountIdToSelect(accountsFeatures, selectedAccount);

    if (accountId) {
      dispatch(
        accountsActions.selectAccount({
          accountId,
        }),
      );
    }

    if (shouldHandleVueRedirect) {
      updateRedirectValues((values) => [false, values[1]]);

      if (depositAvailable) {
        navigateToDeposit();
      } else {
        updateRedirectValues((values) => [values[0], true]);
      }
    } else {
      updateRedirectValues((values) => [values[0], true]);
    }
  };

  const buttonHidden: boolean =
    !depositDisabled || isSuperuser || isOnlyPartnerAccounts;

  useEffect(() => {
    if (shouldHandleVueRedirect && !buttonHidden && selectedAccount) {
      openDepositPage();
    }
  }, [shouldHandleVueRedirect, buttonHidden, selectedAccount]);

  useEffect(() => {
    if (waitingForDepositTab && depositAvailable) {
      updateRedirectValues((values) => [values[0], false]);
      navigateToDeposit();
    }
  }, [waitingForDepositTab, depositAvailable]);

  return buttonHidden ? null : (
    <StyledHeaderDepositIcon
      iconName="DepositIcon"
      iconSize={24}
      className="HeaderDepositIcon"
      title={t('generic__action__deposit')}
      iconColor="action"
      onClick={openDepositPage}
      label={(isDesktop && t('generic__action__deposit')) || undefined}
    />
  );
};
