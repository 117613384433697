import { ICellValue } from 'react-ui-kit-exante';

import { TAccountSetting } from 'services/settings/accountSettings';

import {
  TAccountsTableColumns,
  TGetColumnsParams,
} from './AccountsTable.types';
import {
  AutoConversionSelector,
  DescriptionInput,
  MarginTradingButton,
  SelectAccountForAutoDebit,
  TableHeaderWithTooltip,
} from './components';

export function getColumns({
  t,
  accountSettings,
  isAnyDescriptionEditable,
  isAnyAutoDebitShowing,
  isAnyAutoConversionEditable,
  isAnyMarginTradingEditable,
}: TGetColumnsParams): TAccountsTableColumns<TAccountSetting>[] {
  const columns: TAccountsTableColumns<TAccountSetting>[] = [
    {
      Header: t('id'),
      accessor: 'id',
      id: 'id',
      width: 70,
    },
    {
      HeaderWithIcon: (
        <TableHeaderWithTooltip
          headerText={t('description')}
          tooltipText={t('accounts_table_description_tooltip')}
        />
      ),
      Cell: ({
        row: {
          original: {
            id,
            description,
            is_description_editable: isDescriptionEditable,
          },
        },
      }: ICellValue<TAccountSetting>) =>
        !isDescriptionEditable ? null : (
          <DescriptionInput accountId={id} value={description} />
        ),
      accessor: 'description',
      id: 'description',
      showInTable:
        accountSettings.is_account_descriptions_available &&
        isAnyDescriptionEditable,
    },
    {
      HeaderWithIcon: (
        <TableHeaderWithTooltip
          headerText={t('auto_conversion_to')}
          tooltipText={t('accounts_table_auto_conversion_tooltip')}
        />
      ),
      Cell: ({
        row: {
          original: {
            id,
            is_auto_conversion_editable: isAutoConversionEditable,
          },
        },
        value,
      }: ICellValue<TAccountSetting>) =>
        !isAutoConversionEditable ? null : (
          <AutoConversionSelector
            autoConversionCurrency={value}
            accountId={id}
          />
        ),
      accessor: 'auto_conversion_currency',
      id: 'auto_conversion_currency',
      width: 70,
      showInTable:
        accountSettings.is_cash_conversion_available &&
        isAnyAutoConversionEditable,
    },
    {
      HeaderWithIcon: (
        <TableHeaderWithTooltip
          headerText={t('account_settings__auto_debit')}
          tooltipText={t('accounts_table_autodebit_tooltip')}
        />
      ),
      Cell: ({
        row: {
          original: {
            id,
            is_autodebit_editable: isAutodebitEditable,
            is_autodebit_showing: isAutodebitShowing,
            autodebit_account: autodebitAccount,
          },
        },
      }: ICellValue<TAccountSetting>) => {
        return !isAutodebitShowing ? null : (
          <SelectAccountForAutoDebit
            accountId={id}
            autodebitAccountId={autodebitAccount}
            isAutodebitEditable={isAutodebitEditable}
          />
        );
      },
      accessor: 'account_for_autodebit',
      id: 'account_for_autodebit',
      tooltip: 'off',
      showInTable:
        accountSettings.is_autodebit_available && isAnyAutoDebitShowing,
      minWidth: 115,
    },
    {
      HeaderWithIcon: (
        <TableHeaderWithTooltip
          headerText={t('margin_trading')}
          tooltipText={t('accounts_table_margin_trading_tooltip')}
        />
      ),
      Cell: ({
        row: {
          original: {
            is_margin_trading_editable: isMarginTradingEditable,
            id,
            margin_trading: marginTrading,
          },
        },
      }: ICellValue<TAccountSetting>) => {
        return !isMarginTradingEditable ? null : (
          <MarginTradingButton accountId={id} marginEnabled={marginTrading} />
        );
      },
      accessor: 'margin_trading',
      id: 'margin_trading',
      width: 115,
      showInTable:
        accountSettings.is_margin_trading_available &&
        isAnyMarginTradingEditable,
    },
  ];

  return columns.filter(
    (column) => !('showInTable' in column) || column.showInTable,
  );
}
