import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TChallengeHeader,
  TChallengePlan,
  TChallengeRulesResponse,
  TChallengeSummaryResponse,
} from './challenge.types';

class ChallengeService extends ApiRequest {
  async getChallengeSummary(
    accountId: string,
    currency: string,
  ): Promise<{ data: TChallengeSummaryResponse | null; error: string | null }> {
    try {
      const { data } = await this.fetch<TChallengeSummaryResponse>({
        url: '/clientsarea/rest/challenge/challenge_summary/',
        params: { accountId, currency },
      });

      return { data, error: null };
    } catch (errorRes: any) {
      if ([400, 403].includes(errorRes.response.status)) {
        return { data: null, error: errorRes.response.data.status };
      }
      notifyWith.genericNetworkError(errorRes);
      return { data: null, error: null };
    }
  }

  async getChallengeHeader(
    accountId: string,
  ): Promise<{ data: TChallengeHeader } | null> {
    try {
      return await this.fetch<TChallengeHeader>({
        url: '/clientsarea/rest/challenge/challenge_header/',
        params: {
          accountId,
        },
      });
    } catch (errorRes: any) {
      if ([400, 403].includes(errorRes.response.status)) {
        return { data: {} as TChallengeHeader };
      }
      notifyWith.serverError(errorRes?.message);
      return null;
    }
  }

  async getChallenge(
    accountId: string,
  ): Promise<{ data: TChallengePlan | null; error: string | null } | null> {
    try {
      const { data } = await this.fetch<TChallengePlan>({
        url: `/clientsarea/rest/challenge/plan/`,
        params: { accountId },
      });

      return { data, error: null };
    } catch (errorRes: any) {
      if ([400, 403].includes(errorRes.response.status)) {
        return {
          data: {} as TChallengePlan,
          error: null,
        };
      }

      notifyWith.serverError(errorRes?.message);
      return { data: null, error: errorRes.message };
    }
  }

  async makeWithdrawal(
    data: Record<string, unknown>,
    accountId: string,
  ): Promise<{ data: Record<string, unknown> }> {
    return this.fetch<Record<string, unknown>>({
      url: '/clientsarea/rest/challenge/withdrawal/',
      method: 'post',
      params: {
        accountId,
      },
      data,
    });
  }

  async getChallengeRules(
    accountId: string,
  ): Promise<TChallengeRulesResponse | null> {
    try {
      const { data } = await this.fetch<TChallengeRulesResponse>({
        url: '/clientsarea/rest/challenge/challenge_rules/',
        params: {
          accountId,
        },
      });
      return data;
    } catch (errorRes: any) {
      if ([400, 403].includes(errorRes.response.status)) {
        return null;
      }

      notifyWith.serverError(errorRes?.message);
      return null;
    }
  }
}

export const challengeService = new ChallengeService();
