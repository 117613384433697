import type { TOneTimeToken } from 'services/tokens/tokens.types';

export const generateQRLink = (
  deepLinkHost: string | null,
  oneTimeToken: TOneTimeToken | null,
  accountId: string | null,
) => {
  if (!deepLinkHost || !oneTimeToken) {
    return null;
  }

  const { one_time_token: token, username } = oneTimeToken;
  let url = `https://${deepLinkHost}/oauth?one_time_token=${token}&username=${username}&env=live`;

  if (accountId) {
    url = url.concat(`&account=${accountId}`);
  }

  return url;
};
