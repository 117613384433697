import { TFunction } from 'i18next';
import { object, ref, string } from 'yup';

import { passwordValidator } from 'helpers/password';

export const RECOVERY_COMPLETE_FORM_VALIDATION_SCHEMA = (t: TFunction) =>
  object({
    password: passwordValidator(t),
    passwordRepeat: string()
      .required(t('repeat_password__required'))
      .oneOf([ref('password')], t('passwords_not_match')),
  });
