import { styled } from 'theme';

export const StyledRecoveryCompleteForm = styled('form')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledPasswordRepeatWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  gap: 4px;
`;

export const StyledSecurity = styled('div')`
  display: flex;
  gap: 4px;
  margin-left: 12px;
  font-size: 13px;
`;
