import { css, List as ListMUI } from '@mui/material';
import { blockNonNativeProps } from 'react-ui-kit-exante';

import { BellIcon } from 'assets/icons';
import { styled } from 'theme';

import { iconStyle } from '../../Header.styled';

interface INotificationsStyledProps {
  hasUnread?: boolean;
  opened?: boolean;
}

export const StyledNotificationsWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
    display: inline-block;
  }
`;

export const StyledNotificationsToggleButton = styled('button', {
  shouldForwardProp: blockNonNativeProps('hasUnread'),
})<INotificationsStyledProps>`
  display: flex;
  align-items: center;
  position: relative;

  gap: 16px;
  line-height: 1;
  font-size: 15px;
  font-weight: 500;
  border: 0;
  background: none;
  padding: 0;
  font-family: ${({ theme }) => theme.font.main};

  color: ${({ theme }) => theme.color.typo.action};

  cursor: pointer;

  ${({ theme }) => theme.breakpoints.up('md')} {
    color: ${({ theme }) => theme.color.typo.secondary};
  }

  &:hover .xnt-icon {
    transform: scale(1.2);
    transition: 0.3s ease;
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  ${({ theme, hasUnread }) => {
    const backgroundColor = theme.color.typo.radical;

    return (
      hasUnread &&
      css`
        &::before {
          position: absolute;
          top: 1px;
          left: 15px;

          display: block;
          box-sizing: content-box;
          width: 6px;
          height: 6px;

          background-color: ${backgroundColor};
          border-radius: 50%;

          content: '';
          z-index: 0;
        }
      `
    );
  }}
`;

export const StyledNotificationsDropdown = styled(
  'div',
)<INotificationsStyledProps>`
  position: absolute;
  top: 100%;
  left: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    left: unset;
    right: 0;
  }

  z-index: 100;

  display: block;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 100vw;
  }

  height: auto;
  margin: 0;
  overflow: visible;

  text-align: left;

  list-style-type: none;
  background: ${({ theme }) => theme.color.bg.basic};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.effect.controls.drop};

  transition-timing-function: ease;
  transition-duration: 0.3s;
  transition-property: opacity, height, padding-top, padding-bottom;

  backdrop-filter: blur(14px);

  ${({ theme }) => theme.breakpoints.up('md')} {
    right: 15px;

    width: 294px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    position: fixed;

    box-shadow: none;

    top: ${({ theme }) => theme.sizes.mobileHeaderHeight};
    right: auto;
    z-index: 100;

    display: flex;
    flex-direction: column;
    height: calc(100% - ${({ theme }) => theme.sizes.mobileHeaderHeight});
  }
`;

export const StyledNotificationsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 0 16px;

  color: ${({ theme }) => theme.color.typo.promo};
  font-size: 15px;
`;

export const StyledNotificationsCount = styled('span')`
  margin-left: 4px;
  padding: 6px 6px 4px;

  color: ${({ theme }) => theme.color.typo.inverse};
  font-size: 12px;
  line-height: 12px;
  text-align: left;

  background-color: ${({ theme }) => theme.color.typo.source};
  border-radius: 4px;
`;

export const StyledNotificationsSearch = styled('div')`
  margin-bottom: 16px;
  padding: 0 16px;
`;

export const StyledNotificationsList = styled(
  ListMUI,
)<INotificationsStyledProps>`
  max-height: 376px;
  margin: 0;
  padding: 0 16px 16px;
  overflow-y: auto;

  list-style: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-grow: 1;
    max-height: none;
  }
`;

export const StyledNotificationsBellIcon = styled(BellIcon)(iconStyle);
