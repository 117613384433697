import { IconButton, Panel, Tab, Table, Tabs } from 'react-ui-kit-exante';

import { AccountSelection } from 'components/AccountSelection';
import { styled } from 'theme';

export const StyledCorporateActionsPostingsAccountSelection = styled(
  AccountSelection,
)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    .AccountSelectionPanelSearchField {
      width: 170px;
    }
  }
`;

export const StyledCorporateActionsPostingsPanel = styled(Panel)`
  margin: 0 18px;
  padding: 0;

  .PanelTitle {
    margin: 24px 24px 16px 24px;
    padding: 0 !important;
  }
`;

export const StyledCorporateActionsPostingsTabs = styled(Tabs)`
  margin: 24px 24px 0;

  .MuiTab-root {
    display: inline-flex !important;
    flex-direction: row;
    gap: 8px;
  }
`;

export const StyledCorporateActionsPostingsTab = styled(Tab)`
  display: flex;

  &.Mui-selected {
    svg {
      color: ${({ theme }) => theme.color.icon.primary};
    }
  }
`;

export const StyledCorporateActionsPostingsTabIcon = styled(IconButton)`
  color: ${({ theme }) => theme.color.icon.action};

  svg {
    color: ${({ theme }) => theme.color.icon.action};

    &:hover {
      color: ${({ theme }) => theme.color.icon.action};
    }
  }
`;

export const StyledCorporateActionsPostingsTable = styled(Table)`
  > header {
    padding: 24px 54px 16px 24px;

    > div > button {
      min-width: 24px;
      height: 24px;
    }
  }

  .ColumnSelect {
    position: absolute;
    top: 22px;
    right: 20px;
  }
` as typeof Table;
