import { initialFetchState, initialUpdateState } from 'constants/state';
import { TransferType } from 'services/transfer';

import {
  ToBankAccountFields,
  ToBankAccountInitFields,
  ToBankAccountState,
} from '../../../types';

export const initialToBankAccountInitFields: ToBankAccountInitFields = {
  name: '',
  use_personal_iban: true,
};

export const initialToBankAccountFields: ToBankAccountFields = {
  bank_address: '',
  bank_country: null,
  bank_name: '',
  beneficiary_country: null,
  city: '',
  comment: '',
  correspondent_account: '',
  correspondent_swift: '',
  files: [],
  file: [],
  iban: '',
  postal_code: '',
  short_address: '',
  state: '',
  swift: '',
  ...initialToBankAccountInitFields,
};

export const initialToBankAccountState: ToBankAccountState = {
  currency: '',
  isAllFunds: false,
  errors: {},
  fetchingLimitsState: initialFetchState,
  fields: initialToBankAccountFields,
  mode: 'IDLE',
  requestingTransactionState: initialUpdateState,
  type: TransferType.Wire,
  repeated: false,
  wrFormat: '',
};
