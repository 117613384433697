import { TFunction } from 'i18next';
import { findLast } from 'lodash';
import { ISelectOption } from 'react-ui-kit-exante';

import { TSubscription } from 'services/reports/reports.types';

import { CURRENT_LANGUAGE_KEY } from './PeriodicReports.constants';
import { TPeriodicCell } from './PeriodicReports.types';

const isSubscriptionAllowed = (
  subscription: string,
  subscriptions: TSubscription[] | null,
) => {
  return !!subscriptions?.find((sub) => sub.name === subscription);
};

export const getPeriodicReportsData = (
  t: TFunction,
  subscriptions: TSubscription[] | null,
): TPeriodicCell[] => {
  return [
    {
      key: 'summary',
      name: t('account_summary'),
    },
    {
      key: 'margin',
      name: t('margin_structure'),
    },
    {
      key: 'transactions',
      name: t('financial_transactions'),
    },
    {
      key: 'trades',
      name: t('generic__trades'),
    },
    {
      key: 'performance',
      name: t('account_performance_report'),
    },
    {
      key: 'commissions',
      name: t('layout__title__commissions_report'),
    },
    {
      key: 'overnights',
      name: t('layout__title__overnights_report'),
    },
    {
      key: 'short allowance',
      name: t('layout__title__short_allowance_report'),
    },
  ].filter((item) => isSubscriptionAllowed(item.key, subscriptions));
};

export const getCurrentLanguageOption = (t: TFunction) => ({
  value: CURRENT_LANGUAGE_KEY,
  label: t(CURRENT_LANGUAGE_KEY),
});

// logic for account selection moved from old CA
export const calculateSelectedAccounts = (
  newAccounts: ISelectOption[],
  selectedAccounts: ISelectOption[],
): string[] => {
  let calculatedAccounts: (string | ISelectOption)[] = [...newAccounts];

  const isAllAlreadyAdded = Boolean(
    selectedAccounts.find(
      (acc) => acc.value === 'all' || acc.value === 'all_aggregate',
    ),
  );
  const isAllInNewList = Boolean(
    newAccounts.find(
      (acc) => acc.value === 'all' || acc.value === 'all_aggregate',
    ),
  );

  if (
    newAccounts.length === 2 &&
    newAccounts[0].value === 'all' &&
    newAccounts[1].value === 'all_aggregate'
  ) {
    calculatedAccounts = ['all_aggregate'];
  } else if (
    newAccounts.length === 2 &&
    newAccounts[0].value === 'all_aggregate' &&
    newAccounts[1].value === 'all'
  ) {
    calculatedAccounts = ['all'];
  } else if (newAccounts.length > 1 && isAllAlreadyAdded && isAllInNewList) {
    calculatedAccounts = newAccounts.filter(
      (acc) => acc.value !== 'all' && acc.value !== 'all_aggregate',
    );
  } else if (newAccounts.length > 1 && !isAllAlreadyAdded && isAllInNewList) {
    const lastAll = findLast(
      newAccounts,
      (acc) => acc.value === 'all' || acc.value === 'all_aggregate',
    );
    calculatedAccounts = lastAll ? [lastAll].filter((i) => i) : [];
  }

  return calculatedAccounts.map((item) =>
    typeof item === 'string' ? item : String(item.value),
  );
};
