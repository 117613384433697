import { useState } from 'react';

type TCopiedValue = string | null;
type TCopyFn = (text: string) => Promise<boolean>;

export function useCopyToClipboard(
  initialValue?: string,
): [TCopiedValue, TCopyFn] {
  const [copiedText, setCopiedText] = useState<TCopiedValue>(
    initialValue ?? null,
  );

  const copy: TCopyFn = async (text) => {
    try {
      if (!navigator?.clipboard) {
        // Это запасной метод копирования.
        const textarea = document.createElement('textarea');

        textarea.value = text;
        textarea.style.top = '0';
        textarea.style.left = '0';
        textarea.style.position = 'fixed';

        const activeElement = document.activeElement;

        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        (activeElement?.previousElementSibling as HTMLElement).focus();
        setCopiedText(text);
      } else {
        await navigator.clipboard.writeText(text);
        setCopiedText(text);
      }
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}
