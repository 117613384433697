import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TToggleSubscriptionsData,
  TToggleSubscriptionsResponse,
  TUserFeedPermission,
  TUserSubscriptions,
} from './subscriptions.types';

class SubscriptionsService extends ApiRequest {
  async getSubscriptions(
    lang = 'en',
  ): Promise<TUserSubscriptions['user-subscriptions'] | null> {
    try {
      const { data } = await this.fetch<TUserSubscriptions>({
        url: `/${lang}/clientsarea/spa-layout/?name=user-subscriptions`,
      });

      return data?.['user-subscriptions'] || null;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('getSubscriptions', error);
      return null;
    }
  }

  async toggleSubscriptions(
    subscriptionData: TToggleSubscriptionsData,
    lang = 'en',
  ): Promise<TUserFeedPermission | null> {
    try {
      const { data } = await this.fetch<TToggleSubscriptionsResponse>({
        url: `/${lang}/clientsarea/user-feed-permissions/toggle_subscription/`,
        method: 'POST',
        data: subscriptionData,
      });

      return data?.userFeedPermission;
    } catch (error: any) {
      const message = error?.response?.data?.error;
      if (message) {
        notifyWith.serverError(message);
      } else {
        notifyWith.genericNetworkError(error);
      }
      console.error('getSubscriptions', error);
      return null;
    }
  }

  async updatePaymentAccount(
    paymentAccount: string,
    lang = 'en',
  ): Promise<boolean> {
    try {
      await this.fetch({
        url: `/${lang}/clientsarea/user-feed-permissions/bulk_update/`,
        method: 'POST',
        data: {
          paymentAccount,
        },
      });

      return true;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('updatePaymentAccount', error);
      return false;
    }
  }
}

export const subscriptionsService = new SubscriptionsService();
