import { format } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  ButtonDatePicker,
  calculateCountOfPages,
  IColumn,
  IOnFetchArguments,
  Skeleton,
  useData,
  useTableData,
} from 'react-ui-kit-exante';

import { DEFAULT_MAX_PAGINATION_LENGTH } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';
import { UserDetailsContext } from 'contexts/UserDetailsContext';
import { today } from 'helpers/dates';
import { notifyWith } from 'helpers/notifyWith';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useQuery } from 'hooks/useQuery';
import {
  partnerService,
  TPartnerGetStatisticsResponse,
  TPartnerStatisticsRecord,
} from 'services/partner';

import {
  dateRangeFilterName,
  defaultFromToRange,
  partnerStatisticsFetchDateFormat,
  tableId,
} from './PartnerStatistics.constants';
import {
  getPartnersDateForDeps,
  getPartnerStatisticsColumns,
} from './PartnerStatistics.helpers';
import {
  StyledDateRangePickerDateText,
  StyledDateRangePickerWrapper,
  StyledPartnerStatisticsAdditionalActions,
  StyledPartnerStatisticsExportButton,
  StyledPartnerStatisticsTable,
  StyledPartnerStatisticsWrapper,
} from './PartnerStatistics.styled';

export const PartnerStatistics = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { userDetails } = useContext(UserDetailsContext);

  const userEmail = userDetails?.info?.current?.email;
  const calendarLocale = currentLanguage === 'en' ? 'en-us' : currentLanguage;

  const dateFormatter = useDateFormatter();
  const query = useQuery();

  const [startDate, setStartDate] = useState<Date | null>(
    defaultFromToRange[0],
  );
  const [endDate, setEndDate] = useState<Date | null>(defaultFromToRange[1]);
  const [dateRange, setDateRange] = useState<Date[]>([
    defaultFromToRange[0],
    defaultFromToRange[1],
  ]);

  const fetchStatistics = useCallback(
    ({ params }: IOnFetchArguments) => {
      try {
        const { limit, skip } = params;

        return partnerService.getStatistics({
          lang: currentLanguage,
          dateFrom: format(dateRange[0], partnerStatisticsFetchDateFormat),
          dateTo: format(dateRange[1], partnerStatisticsFetchDateFormat),
          offset: skip,
          size: limit,
        });
      } catch (e) {
        console.error('fetchStatistics', e);
        return Promise.resolve(null);
      }
    },
    [currentLanguage, dateRange],
  );

  const tableDataArgs = useMemo(
    (): Parameters<
      typeof useTableData<TPartnerGetStatisticsResponse['data']>
    > => [
      {
        tableId,
        data: { onFetch: fetchStatistics },
        pagination: {
          getDefaultPagination: () => ({
            limit: DEFAULT_MAX_PAGINATION_LENGTH,
            skip: 0,
          }),
        },
      },
    ],
    [fetchStatistics],
  );

  const {
    data: rawData,
    limit,
    setLimit,
    setPage,
    page,
    setSorting,
    isLoading,
  } = useTableData(tableDataArgs[0]);
  const { data: statistics = [], iTotalRecords: total } = rawData || {};

  const { isLoading: isExporting, fetchData: exportToCsv } = useData({
    onFetch: async () => {
      if (!startDate || !endDate) {
        return Promise.resolve(null);
      }

      return partnerService.exportLinksToCsv({
        lang: currentLanguage,
        dateFrom: startDate,
        dateTo: endDate,
      });
    },
    onSuccess: (response) => {
      if (response && userEmail) {
        notifyWith.success(
          t('layout__actions__request_queued_you_will_receive').replaceAll(
            'EMAIL',
            userEmail,
          ),
        );
      }
    },
    loading: false,
  });

  const columns = useMemo((): Array<IColumn<TPartnerStatisticsRecord>> => {
    return getPartnerStatisticsColumns({ t });
  }, [t]);

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total: total ?? 0,
      pageCount: calculateCountOfPages(total ?? 0, limit),
    }),
    [limit, setPage, setLimit, page, total],
  );

  const onCalendarChange = ([start, end]: [Date | null, Date | null]) => {
    setStartDate(start);
    setEndDate(end);
  };

  const defaultSortBy = useMemo(() => [{ id: 'created', desc: false }], []);

  useEffect(() => {
    if (startDate && endDate) {
      setDateRange([startDate, endDate]);
    }
  }, [getPartnersDateForDeps(startDate), getPartnersDateForDeps(endDate)]);

  useEffect(() => {
    return () => query.delete(dateRangeFilterName);
  }, []);

  return (
    <StyledPartnerStatisticsWrapper className="PartnerStatisticsWrapper">
      <StyledPartnerStatisticsAdditionalActions className="PartnerStatisticsAdditionalActions">
        <StyledDateRangePickerWrapper className="DateRangePickerWrapper">
          {isLoading ? (
            <Skeleton width={162} height={24} />
          ) : (
            <StyledDateRangePickerDateText className="DateRangePickerDateText">
              {startDate && <span>{dateFormatter(startDate)}</span>}
              {startDate && endDate && <span>–</span>}
              {endDate && <span>{dateFormatter(endDate)}</span>}
            </StyledDateRangePickerDateText>
          )}
          <ButtonDatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={onCalendarChange}
            maxDate={today}
            className="graph__period"
            iconClassName="CalendarDatePickerIcon"
            locale={calendarLocale}
            disabled={isLoading}
            data-test-id="account-selection__datepicker"
          />
        </StyledDateRangePickerWrapper>
        {userEmail && (
          <StyledPartnerStatisticsExportButton
            className="PartnerStatisticsExportButton"
            iconName="ExportIcon"
            iconSize={24}
            disabled={isExporting}
            iconColor="secondary"
            onClick={exportToCsv}
            title={t('layout__partner__statistics_export_to_csv')}
          />
        )}
      </StyledPartnerStatisticsAdditionalActions>

      <StyledPartnerStatisticsTable<TPartnerStatisticsRecord>
        data={statistics}
        tableId={tableId}
        columns={columns}
        isLoading={isLoading}
        translator={t}
        locale={currentLanguage}
        isFlexLayout
        onSort={setSorting}
        manualSortBy
        hasPagination
        defaultSortBy={defaultSortBy}
        skeletonsCount={10}
        pageSize={limit}
        showTableInfo
        serverPaginationProps={serverPaginationProps}
      />
    </StyledPartnerStatisticsWrapper>
  );
};
