import { times } from 'lodash';

import { StyledChecklistSkeletonWrapper } from './ChecklistSkeleton.styled';
import { ChecklistSkeletonItem } from './ChecklistSkeletonItem';

export const ChecklistSkeleton = () => (
  <StyledChecklistSkeletonWrapper>
    {times(3, (i) => (
      <ChecklistSkeletonItem key={i} />
    ))}
  </StyledChecklistSkeletonWrapper>
);
