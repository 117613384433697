import { initialFetchState } from 'constants/state';

import { TComponentState } from './types';

export const SEARCH_DEBOUNCE_TIMEOUT = 1000; // 1 second

export const componentInitialState: TComponentState = {
  dirty: false,
  search: '',
  expanded: false,
  validating: initialFetchState,
};
