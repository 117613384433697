import { FC } from 'react';

import { InfoIcon } from 'assets/icons';
import {
  StyledSubHeaderContainer,
  StyledSubHeaderTitle,
} from 'components/SubHeader/SubHeader.styled';
import { Tooltip } from 'components/Tooltip';

import { TSubHeaderProps } from './SubHeader.types';

export const SubHeader: FC<TSubHeaderProps> = ({
  title,
  info,
  action,
  size = 'small',
}) => {
  return (
    <StyledSubHeaderContainer className="SubHeaderContainer">
      <StyledSubHeaderTitle className="SubHeaderTitle" size={size}>
        {title}
        {info && (
          <Tooltip title={info}>
            <InfoIcon className="sub-header__tooltip" />
          </Tooltip>
        )}
      </StyledSubHeaderTitle>
      <div className="SubHeaderAction">{action}</div>
    </StyledSubHeaderContainer>
  );
};
