import { styled } from 'theme';

export const StyledChallengeAccountSummaryInfo = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  .TooltipTextWrapper {
    display: block;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-columns: repeat(5, 1fr);

    > *:nth-of-type(1) {
      grid-column: 1 / 3;
    }

    > *:nth-of-type(2) {
      grid-column: 4 / -1;
    }
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding-right: 28%;
  }
`;
