import { blockNonNativeProps, css } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TAvailableValueProps } from './AvailableValue.types';

export const StyledAvailableValue = styled('span', {
  shouldForwardProp: blockNonNativeProps(['isMarginCall']),
})<TAvailableValueProps>`
  ${({ isMarginCall, theme }) => {
    if (isMarginCall) {
      return css`
        color: ${theme.color.typo.radical};
      `;
    }
    return '';
  }}
`;
