import { styled } from 'theme';

export const StyledValuesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
`;

export const StyledValueRow = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledValueName = styled('span')`
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledValue = styled('span')`
  font-weight: 600;
  color: ${({ theme }) => theme.color.typo.primary};
`;
