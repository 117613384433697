import { TOptionsArguments } from 'types/api';

export type TTreeRowType = 'node' | 'instrument';

export enum TTreeModule {
  PERMISSIONS = 'permissions',
  COMMISSION = 'commission',
  OVERNIGHT = 'overnight',
}

export type TPermissions = {
  short?: boolean;
};

export type TCommission = {
  commissionPercent?: string;
  commissionFixed?: string;
  minimumFee?: string;
  currency?: string;
};

export type TOvernight = {
  positive?: string;
  negative?: string;
};

export type TOptions = {
  options: TOptionsArguments;
};

export type TDefaultParams = {
  search?: string;
  path?: string;
  limit?: number;
  skip?: number;
  accountId: string | null;
  username: string;
  lang?: string;
};

export type TTreeParams = TOptions & TDefaultParams;
export type TGetInstrumentsParams = TDefaultParams;

export type TSearchInstrumentsParams = TTreeParams & {
  instruments?: TTreeResponseTreeStructure[];
  pathForSymbol?: string;
};

export type TTreeResponse = {
  displayName: string;
  icon: string | null;
  id: string;
  path: string;
  currency?: string;
  permissions?: TPermissions;
  commission?: TCommission;
  overnight?: TOvernight;
};

export type TTreeResponseTreeStructure = {
  icon: string | null;
  id: string;
  displayName: string;
  path: string;
  currency?: string;
  subRows?: TTreeResponseTreeStructure[];
  rowType: TTreeRowType;
} & (TPermissions & TCommission & TOvernight);

export type TMappedTreeResponse = {
  tree: TTreeResponseTreeStructure[];
  matchIdAndPositionInNonSearchTable: Record<string, string | number>;
  newInstrumentsByPath: Record<string, TPermissions | TCommission | TOvernight>;
};
