import { createSlice } from '@reduxjs/toolkit';

import { TTableVisibilityColumn } from 'services/tables';
import { EStatus } from 'types/api';
import { TStateField } from 'types/state';

import {
  fetchColumnsVisibility,
  fetchTablesVisibility,
} from './tables.actions';

export type TTablesInitialState = {
  visibleTables: TStateField<Record<string, boolean> | null>;
  visibleColumns: {
    [key: string]: TStateField<TTableVisibilityColumn | null>;
  };
};

const initialState: TTablesInitialState = {
  visibleTables: {
    status: EStatus.Idle,
    data: null,
  },
  visibleColumns: {
    summary: {
      status: EStatus.Idle,
      data: null,
    },
  },
};

export const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTablesVisibility.pending, (state) => {
      state.visibleTables.status = EStatus.Loading;
    });

    builder.addCase(fetchTablesVisibility.fulfilled, (state, action) => {
      state.visibleTables.data = action.payload;
      state.visibleTables.status = EStatus.Succeeded;
    });
    builder.addCase(fetchColumnsVisibility.pending, (state, action) => {
      if (action?.meta?.arg) {
        state.visibleColumns[action?.meta?.arg].status = EStatus.Loading;
      }
    });

    builder.addCase(fetchColumnsVisibility.fulfilled, (state, action) => {
      const tableId = action?.meta?.arg;
      if (tableId) {
        state.visibleColumns[tableId].data = action.payload;
        state.visibleColumns[tableId].status = EStatus.Succeeded;
      }
    });
  },
});
