import { Autocomplete } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledHeaderAccountSelector = styled(Autocomplete)`
  width: 180px;

  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.color.input.bg.hover};

    &:hover {
      background-color: ${({ theme }) => theme.color.input.bg.default};
    }
  }
` as typeof Autocomplete;
