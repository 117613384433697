import { useMediaQuery } from '@mui/material';
import { ChangeEvent, FC, useContext, useState } from 'react';
import { Button, Input } from 'react-ui-kit-exante';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { EXANTE_SUPPORT_EMAIL } from 'constants/WHITELABEL';
import { BrandingContext, TranslationContext } from 'contexts';
import { codeLength } from 'helpers/codeLength';
import { passwordService } from 'services/settings/password/password';
import { useTheme } from 'theme';

import {
  Styled2FATooltipHeader,
  StyledCodeButtons,
  StyledNoCodeText,
  StyledResendCode,
  StyledResendCodeButton,
  StyledSaveButton,
} from './TwoFAConfirmation.styled';
import { TTwoFAConfirmationProps } from './TwoFAConfirmation.types';

export const TwoFAConfirmation: FC<TTwoFAConfirmationProps> = ({
  changeResult,
  dirtyFields,
  isDataSaving,
  onSave,
  onClose2FAPopup,
  startTimer,
  isCodeError,
  show2FAPopup,
  timeSecondsFormat,
  code,
  setCode,
}) => {
  const { branding } = useContext(BrandingContext);
  const { t, currentLanguage } = useContext(TranslationContext);

  const [codeIsSending, setCodeIsSending] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isDisabled = !Object.keys(dirtyFields).length || isDataSaving;
  const isSave2FAPasswordDisabled =
    code.length < codeLength(changeResult.mfa_type) || codeIsSending;

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= codeLength(changeResult.mfa_type)) {
      setCode(value);
    }
  };

  const onResendCode = async () => {
    if (changeResult.key && changeResult.tokenid) {
      setCodeIsSending(true);
      const result = await passwordService.resendCode(
        changeResult.key,
        changeResult.tokenid,
        currentLanguage,
      );
      if (result) {
        startTimer();
      }
      setCodeIsSending(false);
    }
  };

  return (
    <ActionWithConfirmation
      content={
        <div className="SaveButtonContainer">
          <Styled2FATooltipHeader className="2FATooltipHeader">
            {changeResult.mfa_type === 'sms' && t('enter_code_from_sms')}
            {changeResult.mfa_type === 'totp' && t('enter_code_from_app')}
            {changeResult.mfa_type === 'email' && t('enter_code_from_email')}
          </Styled2FATooltipHeader>
          <Input
            placeholder={t('2step_auth_code')}
            value={code}
            onChange={onCodeChange}
            error={isCodeError}
            message={isCodeError ? t('code_is_invalid') : ''}
            maxLength={codeLength(changeResult.mfa_type)}
            minLength={codeLength(changeResult.mfa_type)}
            fullWidth
            className="CodeInput"
          />
          {changeResult.mfa_type !== 'totp' && (
            <>
              <StyledResendCode className="ResendCode">
                {timeSecondsFormat > 0 ? (
                  t('layout__settings__mfa__resend_after_seconds', {
                    VALUE: timeSecondsFormat,
                  })
                ) : (
                  <StyledResendCodeButton
                    onClick={onResendCode}
                    textColor="action"
                    color="transparent"
                    disabled={codeIsSending || !changeResult.key}
                    className="ResendCodeButton"
                  >
                    {t('generic__verification_resend')}
                  </StyledResendCodeButton>
                )}
              </StyledResendCode>
              <StyledNoCodeText
                className="NoCodeText"
                dangerouslySetInnerHTML={{
                  __html: t('if_you_didnt_receive_the_code_contact_us_at', {
                    SUPPORT_EMAIL:
                      branding?.wl_emails_support || EXANTE_SUPPORT_EMAIL,
                  }),
                }}
              />
            </>
          )}
          <StyledCodeButtons className="CodeButtons">
            <Button
              onClick={onSave}
              loading={isDataSaving}
              disabled={isSave2FAPasswordDisabled}
              className="TwoFAConfirmButton"
              color="transparent"
              textColor="action"
            >
              {t('generic__action__confirm')}
            </Button>
            <Button
              textColor="secondary"
              onClick={onClose2FAPopup}
              color="transparent"
            >
              {t('generic__action__cancel')}
            </Button>
          </StyledCodeButtons>
        </div>
      }
      placement="bottom"
      externalOpened={show2FAPopup}
      closeHandler={onClose2FAPopup}
      disabled={isDisabled}
      hideDefaultButtons
      openFromExternal
    >
      <StyledSaveButton
        iconColor="action"
        label={!isMobile ? t('generic__action__save') : ''}
        iconName="SaveIcon"
        type="submit"
        disabled={isDisabled}
        data-test-id="account-settings-save__button--change-password"
        className="SaveButton"
      />
    </ActionWithConfirmation>
  );
};
