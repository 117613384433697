export const INTERPOLATION = {
  BEFORE: '[',
  AFTER: ']',
  LEGACY: {
    CURLY: {
      BEFORE: '{',
      AFTER: '}',
    },
  },
};
