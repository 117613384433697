import { useContext, useEffect, useMemo, useState } from 'react';
import { Panel, useData } from 'react-ui-kit-exante';

import { StyledNoDataNotification } from 'components/NoDataNotification';
import { TranslationContext } from 'contexts';
import { AccountDataContext } from 'contexts/AccountDataContext/AccountDataContext';
import {
  TabsCacheApiContext,
  TabsCacheDataContext,
} from 'contexts/TabsCacheContext';
import { delay } from 'helpers/timers';
import { useLogHandleTime } from 'hooks/index';
import { AnalysisHeader } from 'pages/Portfolio/tabs/Analysis/components/AnalysisHeader';
import { AnalysisMetrics } from 'pages/Portfolio/tabs/Analysis/components/AnalysisMetrics';
import { FilterContext } from 'pages/Portfolio/tabs/Analysis/contexts/FilterContext';
import {
  TAccountsMetrics,
  TFetchAccountHistoryResponse,
} from 'services/analysis';

import {
  getAccountHistory,
  isAccountHistoryResponseTimeout,
  isAccountHistoryResponseUnfinished,
  isResponseTypeTimeout,
  isResponseTypeUnfinished,
} from './AnalysisContainer.helpers';
import {
  TAnalysisContainerCache,
  TAnalysisMetricsResponse,
} from './AnalysisContainer.types';

export const AnalysisContainer = () => {
  const { currentLanguage, t, isTranslationsLoading } =
    useContext(TranslationContext);
  const { filters } = useContext(FilterContext);
  const { getCache } = useContext(TabsCacheDataContext);
  const { setCache } = useContext(TabsCacheApiContext);
  const { isLoading: isAccountDataLoading } = useContext(AccountDataContext);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('performance-tab');
  const [cacheId, setCacheId] = useState<string | null>();

  const cachedAccountHistory = useMemo<TAnalysisMetricsResponse>(() => {
    return (
      (cacheId && getCache<TAnalysisContainerCache>(cacheId)?.accountHistory) ||
      null
    );
  }, [getCache, cacheId]);

  const fetchAccountHistory =
    async (): Promise<TFetchAccountHistoryResponse | null> => {
      const response = await getAccountHistory(filters, currentLanguage);
      if (isAccountHistoryResponseUnfinished(response)) {
        await delay(4000);

        return fetchAccountHistory();
      }

      if (isAccountHistoryResponseTimeout(response)) {
        return null;
      }

      return response;
    };

  const {
    data: serverAccountHistory,
    fetchData,
    isLoading: isHistoryLoading,
  } = useData<TAnalysisMetricsResponse>({
    onFetch: async () => {
      setCacheId(
        ['analysis-container', Object.entries(filters).toString()].join('.'),
      );

      setStartHandleTime();
      const response = await fetchAccountHistory();
      logHandleTime();
      return response;
    },
  });

  const accountHistory = useMemo<TAnalysisMetricsResponse>(() => {
    if (
      (isHistoryLoading ||
        isAccountHistoryResponseUnfinished(serverAccountHistory)) &&
      cachedAccountHistory
    ) {
      return cachedAccountHistory;
    }

    return serverAccountHistory;
  }, [isHistoryLoading, cachedAccountHistory, serverAccountHistory]);

  useEffect(() => {
    if (
      cacheId &&
      !isHistoryLoading &&
      serverAccountHistory &&
      !isResponseTypeTimeout(serverAccountHistory) &&
      !isResponseTypeUnfinished(serverAccountHistory)
    ) {
      setCache<TAnalysisContainerCache>(cacheId, {
        accountHistory: serverAccountHistory,
      });
    }
  }, [serverAccountHistory, setCache, cacheId, isHistoryLoading]);

  const isMetricsLoading: boolean =
    (isHistoryLoading || isResponseTypeUnfinished(serverAccountHistory)) &&
    !cachedAccountHistory;

  const isLoading: boolean =
    isMetricsLoading || isAccountDataLoading || isTranslationsLoading;

  const passedMetrics: TAccountsMetrics | null =
    isResponseTypeUnfinished(accountHistory) ||
    isResponseTypeTimeout(accountHistory)
      ? null
      : accountHistory;

  return (
    <Panel className="PortfolioAnalysisWrapper" disableContentPaddings>
      <AnalysisHeader onFetch={fetchData} />

      {!isLoading && isAccountHistoryResponseTimeout(accountHistory) ? (
        <StyledNoDataNotification className="NoDataNotification">
          {t('layout__performance__requested_report_is_too_large')}
        </StyledNoDataNotification>
      ) : (
        <AnalysisMetrics metrics={passedMetrics} isMetricsLoading={isLoading} />
      )}
    </Panel>
  );
};
