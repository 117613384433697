import { TApiContext, TApiState } from './types';

export const apiStateInitialState: TApiState = {
  fetching: false,
  succeed: false,
  fetchingError: false,

  tab: 0,

  applications: [],

  isSupervisor: false,
  opts: {
    API_ENDPOINT_DATA_DEMO: '',
    API_ENDPOINT_DATA_LIVE: '',
    API_ENDPOINT_TRADE_DEMO: '',
    API_ENDPOINT_TRADE_LIVE: '',
    demo_access: '',
    demo_enabled: false,
    jwt_enabled: false,
    live_access: '',
  },
  refs: {
    app: '',
    app_delete: '',
    app_keys_delete: '',
    app_keys_list: '',
    create: '',
    status: '',
  },
  username: '',
};

export const apiInitialContext: TApiContext = {
  state: apiStateInitialState,
  dispatch: () => {},
  handleRefreshApiState: () => {},
};
