import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TTab } from './tabs.types';

class TabsService extends ApiRequest {
  async getTabs(): Promise<TTab[]> {
    try {
      const { data } = await this.fetch<TTab[]>({
        url: `/clientsarea/layout/tabs/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getUserAccounts error: ', error);
      return [];
    }
  }
}

export const tabsService = new TabsService();
