import { useMediaQuery } from '@mui/material';
import Cookies from 'js-cookie';
import { unescape } from 'lodash';
import { FC, useContext, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, useData } from 'react-ui-kit-exante';

import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { usePollingEffect } from 'hooks/usePollingEffect';
import { useTimer } from 'hooks/useTimer';
import { PATHS } from 'router';
import { loginService } from 'services/login';
import { useTheme } from 'theme';

import { StyledLoginHeading, StyledLoginLogo } from '../../Login.styled';

import { EMAIL_VERIFICATION_COOKIE_NAME } from './EmailVerification.constants';
import {
  StyledAuthPrivacyIcon,
  StyledEmailVerification,
  StyledEmailVerificationText,
  StyledResendButton,
} from './EmailVerification.styled';
import { EmailVerificationSkeleton } from './components/EmailVerificationSkeleton';

export const EmailVerification: FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const navigate = useNavigate();

  const iconAuthPrivacy = branding?.branding?.ui_settings?.icon_auth_privacy;

  // email is persisted, preventing invalid state of the component
  // (the cookie may be removed by side effects of another request)
  const email = useMemo(
    () => Cookies.get(unescape(EMAIL_VERIFICATION_COOKIE_NAME)),
    [],
  );

  const [isResendLoading, setIsResendLoading] = useState(false);

  const { data: verificationRedirectUrl, fetchData: updateVerified } = useData<
    string | false
  >({
    onFetch: async () => {
      return !!email && loginService.checkEmailVerification(email);
    },
  });

  // poll success verification
  usePollingEffect(updateVerified, [email], {
    interval: 10_000,
  });

  // count resend button lock
  const {
    time: resendCountdown,
    clear: stopTimer,
    start: startTimer,
  } = useTimer(30, { startOnInit: false });

  if (isTranslationsLoading) {
    return <EmailVerificationSkeleton />;
  }

  if (verificationRedirectUrl) {
    window.location.href = verificationRedirectUrl;
    return <span>{t('layout__email_verification__success')}</span>;
  }

  if (!email) {
    navigate(PATHS.LOGIN);
    return <span>{t('layout__email_verification__cancel_text')}</span>;
  }

  const handleResendLink = async () => {
    if (!email || resendCountdown) {
      return;
    }

    startTimer();

    setIsResendLoading(true);

    await loginService.sendEmailVerificationLink(email);

    setIsResendLoading(false);
  };

  const handleGoBack = () => {
    stopTimer();

    navigate(PATHS.LOGIN);
  };

  const resendButtonTitle = resendCountdown
    ? t('layout__email_verification__countdown_label', {
        COUNT: resendCountdown,
      })
    : t('layout__email_verification__btn__resend');

  return (
    <StyledEmailVerification className="EmailVerification">
      {isDesktop && <StyledLoginLogo className="LoginLogo" />}
      <StyledLoginHeading className="LoginHeading">
        {t('layout__email_verification__welcome').replace(
          '%s',
          branding?.wl_name || '',
        )}
      </StyledLoginHeading>
      <StyledEmailVerificationText className="EmailVerificationText">
        <Trans
          i18nKey="layout__email_verification__follow_prompt"
          values={{
            EMAIL: email,
          }}
          components={{ b: <b /> }}
          shouldUnescape
        />
      </StyledEmailVerificationText>
      <StyledEmailVerificationText className="EmailVerificationText">
        {t('layout__email_verification__change_prompt')}
      </StyledEmailVerificationText>
      <StyledResendButton
        className="ResendButton"
        color="primary"
        loading={isResendLoading}
        disabled={!!resendCountdown}
        onClick={handleResendLink}
      >
        {resendButtonTitle}
      </StyledResendButton>
      <Button color="secondary" onClick={handleGoBack}>
        {t('generic__action__back')}
      </Button>
      {iconAuthPrivacy && (
        <StyledAuthPrivacyIcon
          className="AuthPrivacyIcon"
          src={iconAuthPrivacy}
          alt={t('generic__privacy_protect')}
        />
      )}
    </StyledEmailVerification>
  );
};
