import { ApiRequest } from 'helpers/apiRequest';

import {
  TCreateHostedPaymentRequestData,
  TCreateHostedPaymentResponseData,
} from './checkout.types';

class CheckoutService extends ApiRequest {
  async getCurrencies(): Promise<{ data: string[] }> {
    return this.fetch<string[]>({
      url: `/clientsarea/rest/payment/checkout/currencies/`,
    });
  }

  async getLimits(): Promise<{ data: Record<string, number> }> {
    return this.fetch<Record<string, number>>({
      url: `/clientsarea/rest/payment/checkout/limits/`,
    });
  }

  async createHostedPayment(
    data: TCreateHostedPaymentRequestData,
  ): Promise<{ data: TCreateHostedPaymentResponseData }> {
    return this.fetch<TCreateHostedPaymentResponseData>({
      url: `/clientsarea/rest/payment/checkout/create_hosted_payment/`,
      data,
      method: 'POST',
    });
  }
}

export const checkoutService = new CheckoutService();
