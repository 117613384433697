import { blockNonNativeProps, css } from 'react-ui-kit-exante';

import { ArrowDownIcon } from 'assets/icons';
import { styled } from 'theme';

import {
  ExpandIconProps,
  StyledExpandHeaderProps,
  TStyledExpandTitleProps,
} from './Collapse.types';

export const StyledContainer = styled('div')`
  width: 100%;
`;

export const StyledCollapseHeader = styled('div', {
  shouldForwardProp: blockNonNativeProps(['withoutBorder']),
})<StyledExpandHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 8px 24px;
  gap: 16px;

  background-color: ${({ theme }) => theme.color.bg.primary};
  color: ${({ theme }) => theme.color.typo.primary};
  font-family: ${({ theme }) => theme.font.main};

  ${({ withoutBorder, theme }) => {
    if (!withoutBorder) {
      return css`
        border-top: 1px solid ${theme.color.line.primary};
      `;
    }
    return '';
  }}
`;

export const StyledExpandButton = styled('button')`
  width: 100%;
  display: flex;
  border: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.bg.primary};
  padding: 0;
  font-family: Inter, sans-serif;
`;

export const StyledExpandTitle = styled('div')<TStyledExpandTitleProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 32px;
  text-align: left;
  color: ${({ theme }) => theme.color.typo.primary};
  gap: 10px;
  font-size: ${({ size }) => (size === 'big' ? '24px' : '20px')};
`;

export const StyledActions = styled('div')`
  display: flex;
  gap: 8px;
`;

export const StyledExpandIcon = styled(ArrowDownIcon, {
  shouldForwardProp: blockNonNativeProps(['expanded']),
})<ExpandIconProps>`
  color: ${({ theme }) => theme.color.typo.ghost};
  display: inline-block;
  width: 24px;
  height: 24px;

  ${({ expanded }) => {
    if (!expanded) {
      return css`
        transform: rotate(-90deg);
      `;
    }
    return '';
  }}
`;
