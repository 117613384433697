import { QRCodeSVG } from 'qrcode.react';
import { FC, useContext } from 'react';

import {
  StyledAccountSelectionQRCodeWrapper,
  StyledAccountSelectionQRContentBackdrop,
  StyledAccountSelectionQRContentContainer,
  StyledAccountSelectionQRContentDescription,
  StyledAccountSelectionQRContentErrorContainer,
  StyledAccountSelectionQRContentLoader,
  StyledAccountSelectionQRContentText,
  StyledAccountSelectionQRContentTitle,
  StyledRetryIcon,
  StyledSpinnerIcon,
} from 'components/AccountSelection/components/AccountSelectionTitle/components/AccountSelectionQRPopover/components/AccountSelectionQRContent/AccountSelectionQRContent.styled';
import { TAccountSelectionQRContent } from 'components/AccountSelection/components/AccountSelectionTitle/components/AccountSelectionQRPopover/components/AccountSelectionQRContent/AccountSelectionQRContent.types';
import { TranslationContext } from 'contexts/TranslationContext';

export const AccountSelectionQRContent: FC<TAccountSelectionQRContent> = ({
  getTokens,
  link,
  isLoading,
  isError,
}) => {
  const { t } = useContext(TranslationContext);
  const isBackdrop = isLoading || isError;

  return (
    <StyledAccountSelectionQRContentContainer className="AccountSelectionQRContentContainer">
      <StyledAccountSelectionQRCodeWrapper className="AccountSelectionQRCodeWrapper">
        <QRCodeSVG value={link || window.location.href} size={120} />
        {isBackdrop && (
          <>
            <StyledAccountSelectionQRContentBackdrop className="AccountSelectionQRContentBackdrop" />
            {isLoading && (
              <StyledAccountSelectionQRContentLoader className="AccountSelectionQRContentLoader">
                <StyledSpinnerIcon />
              </StyledAccountSelectionQRContentLoader>
            )}
            {isError && (
              <StyledAccountSelectionQRContentErrorContainer className="AccountSelectionQRContentErrorContainer">
                <StyledRetryIcon
                  iconColor="secondary"
                  iconName="CircleArrowForwardIcon"
                  iconSize={32}
                  onClick={getTokens}
                  data-test-id="qr-code-retry-button"
                />
              </StyledAccountSelectionQRContentErrorContainer>
            )}
          </>
        )}
      </StyledAccountSelectionQRCodeWrapper>
      <StyledAccountSelectionQRContentText className="AccountSelectionQRContentText">
        <StyledAccountSelectionQRContentTitle className="AccountSelectionQRContentTitle">
          {isError
            ? t('portfolio__qr_code__error_title')
            : t('portfolio__qr_code__title')}
        </StyledAccountSelectionQRContentTitle>
        <StyledAccountSelectionQRContentDescription
          className="AccountSelectionQRContentDescription"
          isError={isError}
        >
          {isError
            ? t('portfolio__qr_code__error_message')
            : t('portfolio__qr_code__description')}
        </StyledAccountSelectionQRContentDescription>
      </StyledAccountSelectionQRContentText>
    </StyledAccountSelectionQRContentContainer>
  );
};
