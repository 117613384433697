import { FC, useContext } from 'react';

import { NoDataIcon } from 'assets/icons';
import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';

import { StyledAccountsTableNoData } from './AccountsTableNoData.styled';

export const AccountsTableNoData: FC = () => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledAccountsTableNoData className="AccountsTableNoData">
      <NoDataIcon />
      <p>{t('ui__table__no_data')}</p>
    </StyledAccountsTableNoData>
  );
};
