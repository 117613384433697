import { Method } from 'axios';

import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TProductsResponse } from './products.types';

class ProductsService extends ApiRequest {
  async getProducts(lang = 'en'): Promise<TProductsResponse | null> {
    try {
      const { data } = await this.fetch<TProductsResponse>({
        url: `/${lang}/clientsarea/rest/products/`,
      });

      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('getProducts', error);

      return null;
    }
  }

  async updateProducts(
    productAlias: string,
    method: Method,
    lang = 'en',
  ): Promise<TProductsResponse | null> {
    try {
      const { data } = await this.fetch<TProductsResponse>({
        url: `/${lang}/clientsarea/rest/products/`,
        method,
        data: {
          alias: productAlias,
        },
      });

      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('getProducts', error);

      return null;
    }
  }
}

export const productsService = new ProductsService();
