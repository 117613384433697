import { FC } from 'react';

import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/icons/linkedin.svg';
import { ReactComponent as RssIcon } from '../../../assets/icons/rss.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/icons/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/youtube.svg';

import {
  StyledFollowLinksIcon,
  StyledFooterFollowLinks,
} from './FollowLinks.styled';
import { TFollowUsLinksProps } from './FollowLinks.types';

export const FollowLinks: FC<TFollowUsLinksProps> = ({
  followUsLinks = {},
}) => {
  return (
    <StyledFooterFollowLinks className="footer__follow follow-links">
      {followUsLinks.facebook && (
        <StyledFollowLinksIcon
          href={followUsLinks.facebook}
          className="follow-links__icon follow-links__icon--facebook"
        >
          <FacebookIcon />
        </StyledFollowLinksIcon>
      )}

      {followUsLinks.linkedin && (
        <StyledFollowLinksIcon
          href={followUsLinks.linkedin}
          className="follow-links__icon follow-links__icon--linkedin"
        >
          <LinkedinIcon />
        </StyledFollowLinksIcon>
      )}

      {followUsLinks.youtube && (
        <StyledFollowLinksIcon
          href={followUsLinks.youtube}
          className="follow-links__icon follow-links__icon--youtube"
        >
          <YoutubeIcon />
        </StyledFollowLinksIcon>
      )}

      {followUsLinks.twitter && (
        <StyledFollowLinksIcon
          href={followUsLinks.twitter}
          className="follow-links__icon follow-links__icon--twitter"
        >
          <TwitterIcon />
        </StyledFollowLinksIcon>
      )}

      {followUsLinks.instagram && (
        <StyledFollowLinksIcon
          href={followUsLinks.instagram}
          className="follow-links__icon follow-links__icon--instagram"
        >
          <InstagramIcon />
        </StyledFollowLinksIcon>
      )}

      {followUsLinks.rss && (
        <StyledFollowLinksIcon
          href={followUsLinks.rss}
          className="follow-links__icon follow-links__icon--rss"
        >
          <RssIcon />
        </StyledFollowLinksIcon>
      )}
    </StyledFooterFollowLinks>
  );
};
