import { ApiRequest } from 'helpers/apiRequest';
import {
  TIntercomInfoResponse,
  TLiveChatInfoResponse,
} from 'services/chat/chat.types';

class ChatService extends ApiRequest {
  async getLiveChatInfo(renew = false): Promise<TLiveChatInfoResponse | null> {
    try {
      const { data } = await this.fetch<TLiveChatInfoResponse>({
        url: `/clientsarea/chat/info/${renew ? '?renew=true' : ''}`,
      });

      return data;
    } catch (error) {
      return null;
    }
  }

  async getIntercomInfo(): Promise<TIntercomInfoResponse | null> {
    try {
      const { data } = await this.fetch<TIntercomInfoResponse>({
        url: `/clientsarea/chat/intercom/`,
      });

      return data;
    } catch (error) {
      return null;
    }
  }
}
export const chatService = new ChatService();
