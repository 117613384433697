import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts';

import { REPORT_TYPE_GROUPED } from '../../ReportEditForm.constants';
import { ReportFormContext } from '../../contexts/ReportFormContext';
import { TReportFormItemWithIndex } from '../../contexts/ReportFormContext/ReportFormContext.types';

import {
  StyledReportEdit,
  StyledReportEditTypeHead,
  StyledReportEditTypeIconButton,
  StyledReportEditTypeTitle,
} from './ReportEditType.styled';
import { TReportEditTypeProps } from './ReportEditType.types';
import { ReportEditItem } from './components/ReportEditItem';
import { ReportEditItemSkeleton } from './components/ReportEditItemSkeleton';

export const ReportEditType: FC<TReportEditTypeProps> = ({ type, title }) => {
  const { t } = useContext(TranslationContext);
  const { refs, items, appendItem, removeItem, isLoading } =
    useContext(ReportFormContext);

  const isGrouped = type === REPORT_TYPE_GROUPED;
  const tradesGroupingSafe: Record<string, string> =
    (isGrouped && refs?.tradesGrouping) || {};
  const groups = Object.entries(tradesGroupingSafe).map(
    ([key, groupTitle]) => ({
      key,
      title: groupTitle,
    }),
  );

  const itemsLocal = items?.reduce<TReportFormItemWithIndex[]>(
    (acc, item, index) =>
      item.report_type !== type ? acc : [...acc, { ...item, index }],
    [],
  );

  const handleAddClick = () => {
    appendItem({
      report_type: type,
      trades_grouping: (isGrouped && groups?.[0]?.key) || '',
      date_from: undefined,
      date_to: undefined,
    });
  };

  return (
    <StyledReportEdit className="ReportEdit">
      <StyledReportEditTypeHead className="ReportEditTypeHead">
        <StyledReportEditTypeTitle className="ReportEditTypeTitle">
          {title}
        </StyledReportEditTypeTitle>
        <StyledReportEditTypeIconButton
          onClick={handleAddClick}
          color="action"
          iconColor="action"
          iconSize={24}
          label={t('generic__action__add')}
          aria-label={t('generic__action__add')}
          iconName="AddIcon"
          data-test-id="report-custom-edit__button--add"
          className="AddButton"
          disabled={isLoading}
        />
      </StyledReportEditTypeHead>

      {isLoading ? (
        <ReportEditItemSkeleton withGroups={!!groups.length} />
      ) : (
        itemsLocal?.map((entry) => (
          <ReportEditItem
            key={entry.formId}
            item={entry}
            groups={groups}
            onDelete={removeItem}
          />
        ))
      )}
    </StyledReportEdit>
  );
};
