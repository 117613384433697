import { TotpVerificationActions } from './useTotpVerification.actions';
import {
  TTotpVerificationReducer,
  TTotpVerificationState,
} from './useTotpVerification.types';

export const useTotpVerificationReducer: TTotpVerificationReducer = (
  state,
  action,
): TTotpVerificationState => {
  switch (action.type) {
    case TotpVerificationActions.VerifyError:
      return {
        ...state,
        isVerifying: false,
        verifyErrorMessage: action.payload,
      };

    case TotpVerificationActions.VerifyStart:
      return {
        ...state,
        verifyErrorMessage: '',
        isVerifying: true,
      };

    case TotpVerificationActions.VerifySuccess:
      return {
        ...state,
        isVerifying: false,
      };

    case TotpVerificationActions.VerifyResetError:
      return {
        ...state,
        verifyErrorMessage: '',
      };

    default:
      return state;
  }
};
