import { blockNonNativeProps, IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledActionButtonProps } from '../CreateTaxReport/components/RequestReport/RequestReport.types';

export const StyledAcceptPriceWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledAcceptPriceHeading = styled('h3')`
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme?.color?.typo?.primary};
  font-family: ${({ theme }) => theme?.font?.header};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledAcceptPriceText = styled('p')`
  margin: 0;

  color: ${({ theme }) => theme?.color?.typo?.ghost};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledAcceptPriceFooter = styled('div')`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const StyledActionButton = styled('button', {
  shouldForwardProp: blockNonNativeProps(['color']),
})<TStyledActionButtonProps>`
  padding: 0;

  border: none;
  cursor: pointer;

  background: none;
  color: ${({ theme, color }) => theme?.color?.typo?.[color || 'primary']};
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  // for loader
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledOrderIconButton = styled(IconButton)`
  justify-content: flex-end;
`;
