import { styled } from 'theme';

export const StyledPartnerHeaderInfoBlockWrapper = styled('div')`
  display: flex;
  align-items: center;

  gap: 5px;
`;

export const StyledPartnerHeaderInfoBlockContent = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledPartnerHeaderInfoBlockHeader = styled('span')`
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

export const StyledPartnerHeaderInfoBlockLabel = styled('span')`
  margin: 0;
  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 13px;
  line-height: 16px;
`;
