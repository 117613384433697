import { EStatus } from 'types/api';

import { AccessRightsAction } from './AccessRights.actions';
import { accessRightsInitialState } from './AccessRights.constants';
import { TAccessRightsReducer } from './AccessRights.types';

export const accessRightsReducer: TAccessRightsReducer = (state, action) => {
  switch (action.type) {
    case AccessRightsAction.FetchUsersError:
      return {
        ...accessRightsInitialState,
        fetchStatus: EStatus.Failed,
      };

    case AccessRightsAction.FetchUsersStart:
      return {
        ...state,
        data: [],
        fetchStatus: EStatus.Loading,
      };

    case AccessRightsAction.FetchUsersSuccess: {
      const data = action.payload?.items || accessRightsInitialState.data;
      const total = action.payload?.count || accessRightsInitialState.total;

      const pageCount =
        state.pageSize > 0
          ? Math.ceil(total / state.pageSize)
          : accessRightsInitialState.pageCount;

      return {
        ...state,
        data,
        total,
        pageCount,
        hasPagination: Boolean(
          action.payload?.count && action.payload?.count > 20,
        ),
        fetchStatus: EStatus.Succeeded,
      };
    }

    case AccessRightsAction.Reset:
      return {
        ...accessRightsInitialState,
        fetchStatus: EStatus.Idle,
      };

    case AccessRightsAction.SetPageIndex:
      return {
        ...state,
        pageIndex: action.payload,
      };

    case AccessRightsAction.SetPageSize:
      return {
        ...state,
        pageIndex: accessRightsInitialState.pageIndex,
        pageSize: action.payload,
      };

    default:
      return state;
  }
};
