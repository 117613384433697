import { FC, useContext } from 'react';

import { AccountSearchFieldContext } from './AccountSearchFieldContext';
import { getAccountSearchDescription } from './AccountSearchFieldRow.helpers';
import {
  StyledAccountSelectionOptionDescription,
  StyledAccountSelectionOptionWrapper,
} from './AccountSearchFieldRow.styled';
import { TAccountSelectionPanelSelectorRowProps } from './AccountSearchFieldRow.types';

export const AccountSearchFieldRow: FC<
  TAccountSelectionPanelSelectorRowProps
> = ({ label = '' }) => {
  const { descriptions, partialDescriptionSearch } = useContext(
    AccountSearchFieldContext,
  );

  const description = getAccountSearchDescription(
    partialDescriptionSearch,
    descriptions,
    label,
  );

  return (
    <StyledAccountSelectionOptionWrapper className="AutocompleteOptionLabelWrapper">
      <span className="AutocompleteOptionLabel">{label}</span>
      {description && (
        <StyledAccountSelectionOptionDescription className="OptionDescription">
          {description}
        </StyledAccountSelectionOptionDescription>
      )}
    </StyledAccountSelectionOptionWrapper>
  );
};
