export const calcAmount = (
  amount: number | string,
  sourceCurrencyCrossrate: number | string,
  targetCurrencyCrossrate: number | string,
  commission: number | string,
): number => {
  return Number(
    (
      ((Number(amount) * Number(sourceCurrencyCrossrate)) /
        Number(targetCurrencyCrossrate)) *
      Number(commission)
    ).toFixed(2),
  );
};
