import { FC } from 'react';

import {
  StyledHelpHeading,
  StyledHelpSubheading,
  StyledHelpText,
} from 'pages/Help/Help.styled';

import { StyledHelpManualsWrapper } from '../../HelpManuals.styled';

import {
  StyledHelpSubheadingTextSkeleton,
  StyledHelpTextSkeleton,
} from './HelpManualsSkeleton.styled';
import { ManualListSkeleton } from './components/ManualListSkeleton';

export const HelpManualsSkeleton: FC = () => {
  return (
    <StyledHelpManualsWrapper className="HelpManualsWrapper">
      <div>
        <StyledHelpHeading className="HelpHeading">
          Guides and Manuals
        </StyledHelpHeading>
        <StyledHelpText className="HelpText">
          <StyledHelpTextSkeleton variant="rounded" animation="wave" />
        </StyledHelpText>
      </div>
      <div>
        <StyledHelpSubheading className="HelpSubheading">
          <StyledHelpSubheadingTextSkeleton
            variant="rounded"
            animation="wave"
          />
          <StyledHelpSubheadingTextSkeleton
            variant="rounded"
            animation="wave"
          />
        </StyledHelpSubheading>
        <ManualListSkeleton />
      </div>
      <div>
        <StyledHelpSubheading className="HelpSubheading">
          <StyledHelpSubheadingTextSkeleton
            variant="rounded"
            animation="wave"
          />
        </StyledHelpSubheading>
        <ManualListSkeleton items={6} />
      </div>
    </StyledHelpManualsWrapper>
  );
};
