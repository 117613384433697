import { FC, useContext } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext';

import { prepareAmountFormTextValue } from './AmountFormValueRow.helpers';
import { StyledAmountFormValueRow } from './AmountFormValueRow.styled';
import { TAmountFormValueRowProps } from './AmountFormValueRow.types';

export const AmountFormValueRow: FC<TAmountFormValueRowProps> = ({
  loading,
  value,
  labelKey,
  keyColor = 'secondary',
  valueColor,
  ellipsis = false,
  shorten = false,
}) => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledAmountFormValueRow className="Value">
      {loading ? (
        <Skeleton width={70} height={24} />
      ) : (
        <Text size="13px" color={keyColor}>
          {t(labelKey)}:
        </Text>
      )}
      {loading ? (
        <Skeleton width={70} height={24} />
      ) : (
        <Text size="13px" color={valueColor} ellipsis={ellipsis}>
          {prepareAmountFormTextValue(value, shorten)}
        </Text>
      )}
    </StyledAmountFormValueRow>
  );
};
