import { TExchangeContextValue } from 'pages/Portfolio/tabs/Exchange/contexts/ExchangeContext/ExchangeContext.types';

export const EXCHANGE_FIXED_AMOUNT = {
  ACTUAL: 'actual',
  EXPECTED: 'expected',
} as const;

export const ExchangeContextInitialValue: TExchangeContextValue = {
  loading: false,
  limit: null,
  amount: null,
  updateAmount() {},
  expectedAmount: null,
  updateExpectedAmount() {},
  accountId: null,
  currency: null,
  updateCurrency() {},
  currencies: [],
  targetCurrency: null,
  updateTargetCurrency() {},
  targetCurrencies: [],
  crossrate: null,
  crossrates: null,
  lastConversionId: null,
  isConversionAvailable: false,
  convert() {},
  commission: null,
  isCommissionLoading: false,
};

export const EXCHANGE_MIN_TRANSFER_VALUE = 4.99;

export const EXCHANGE_INPUT_LIMIT = 16;
