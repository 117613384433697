import { Skeleton } from 'react-ui-kit-exante';

import { repeat } from 'helpers/array';

import {
  StyledSourcesSkeletonContainer,
  StyledSourcesSkeletonHeader,
  StyledSourcesSkeletonHeaderItem,
  StyledSourcesSkeletonSubHeader,
  StyledSourcesMdRow,
  StyledSourcesContext,
  StyledSourcesContextItem,
  StyledSourcesSmRow,
  StyledSourcesSmRowBody,
  StyledSourcesSmRowItem,
} from './SourcesSkeleton.styled';

export const SourcesSkeleton = () => (
  <StyledSourcesSkeletonContainer title="API Management">
    <StyledSourcesSkeletonHeader>
      {repeat(
        2,
        <StyledSourcesSkeletonHeaderItem key={Math.random()}>
          <Skeleton width={16} height={16} />
          <Skeleton width={56} height={24} />
        </StyledSourcesSkeletonHeaderItem>,
      )}
    </StyledSourcesSkeletonHeader>
    <StyledSourcesSkeletonSubHeader>
      <Skeleton width={200} height={16} />
    </StyledSourcesSkeletonSubHeader>
    {repeat(
      3,
      <StyledSourcesMdRow className="SourcesRow" key={Math.random()}>
        <Skeleton height={16} />
        <Skeleton height={16} />
      </StyledSourcesMdRow>,
    )}
    {repeat(
      3,
      <StyledSourcesSmRow className="SourcesSmRow" key={Math.random()}>
        <Skeleton width={80} height={24} />
        <StyledSourcesSmRowBody className="SourcesSmRowBody">
          {repeat(
            2,
            <StyledSourcesSmRowItem
              className="SourcesSmRowItem"
              key={Math.random()}
            >
              <Skeleton width={56} height={16} />
              <Skeleton width={56} height={16} />
            </StyledSourcesSmRowItem>,
          )}
        </StyledSourcesSmRowBody>
      </StyledSourcesSmRow>,
    )}
    <StyledSourcesSkeletonSubHeader>
      <Skeleton width={200} height={16} />
    </StyledSourcesSkeletonSubHeader>
    <StyledSourcesContext className="SourcesContext">
      {repeat(
        3,
        <StyledSourcesContextItem
          className="SourcesContextItem"
          key={Math.random()}
        >
          <Skeleton width={80} height={16} />
          <Skeleton width={40} height={8} />
        </StyledSourcesContextItem>,
      )}
    </StyledSourcesContext>
  </StyledSourcesSkeletonContainer>
);
