import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAccordionSkeletonRow = styled('div')`
  display: flex;
  justify-content: space-between;

  padding: 16px 24px;

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.color.bg.basic};
  }

  border-bottom: 1px solid ${({ theme }) => theme.color.bg.basic};
`;

export const StyledAccordionSkeletonRowPart = styled('div')`
  display: flex;
`;

export const StyledAccordionSkeletonSmall = styled(Skeleton)`
  width: 24px;
  height: 24px;

  &:nth-child(2) {
    margin-left: 16px;
    margin-right: 8px;
  }
`;

export const StyledAccordionSkeletonMedium = styled(Skeleton)`
  width: 64px;
  height: 24px;
  margin-left: 16px;
`;

export const StyledAccordionSkeletonBig = styled(Skeleton)`
  width: 166px;
  height: 24px;
`;
