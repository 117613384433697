import {
  FC,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useData } from 'react-ui-kit-exante';

import { StyledPageWrapper } from 'components/Main';
import { TranslationContext } from 'contexts/TranslationContext';
import { partnerService } from 'services/partner';

import { PARTNER_TABS, PartnersContext } from './Partner.constants';
import { getPartnerInitialTab } from './Partner.helpers';
import {
  StyledPartnerPanel,
  StyledPartnerPanelTabs,
  StyledPartnerTab,
  StyledPartnerTabIcon,
} from './Partner.styled';
import {
  TFetchPartnerDataProps,
  TPartnersContextData,
  TPartnerTabKey,
} from './Partner.types';
import {
  PartnerHeader,
  PartnerLinks,
  PartnerPayments,
  PartnerStatistics,
} from './components';

export const Partner: FC = () => {
  const { t } = useContext(TranslationContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<TPartnerTabKey>(
    getPartnerInitialTab(searchParams.get('tab')),
  );

  const onTabChange = (_: SyntheticEvent, value: string) => {
    setSelectedTab(value as TPartnerTabKey);
  };

  const {
    data: partnersData,
    fetchData: fetchPartnersData,
    isLoading: isPartnersDataLoading,
  } = useData({
    onFetch: ({ statisticsPeriod = [] }: TFetchPartnerDataProps = {}) =>
      partnerService.getInfo(statisticsPeriod),
  });

  const isPaymentDetailsAvailable =
    !!partnersData?.is_payment_details_available;

  const tabs = useMemo(() => {
    if (!isPaymentDetailsAvailable) {
      const { payments, ...restTabs } = PARTNER_TABS;
      return restTabs;
    }

    return PARTNER_TABS;
  }, [isPaymentDetailsAvailable]);

  const contextValue = useMemo(
    (): TPartnersContextData => ({
      isPartnerLoading: isPartnersDataLoading,
      partnerData: partnersData,
      fetchPartnerData: fetchPartnersData,
    }),
    [isPartnersDataLoading, partnersData],
  );

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('tab', selectedTab);
      return prev;
    });
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab !== 'links') {
      fetchPartnersData();
    }
  }, []);

  return (
    <PartnersContext.Provider value={contextValue}>
      <StyledPageWrapper className="PartnerPageWrapper">
        <StyledPartnerPanel title={t('layout__partners__page_title')}>
          <PartnerHeader />
          <StyledPartnerPanelTabs value={selectedTab} onChange={onTabChange}>
            {Object.entries(tabs).map(([key, { labelKey, iconName }]) => (
              <StyledPartnerTab
                key={labelKey}
                label={t(labelKey)}
                icon={<StyledPartnerTabIcon iconName={iconName} />}
                value={key}
              />
            ))}
          </StyledPartnerPanelTabs>
          {selectedTab === 'statistics' && <PartnerStatistics />}
          {selectedTab === 'links' && <PartnerLinks />}
          {selectedTab === 'payments' && isPaymentDetailsAvailable && (
            <PartnerPayments />
          )}
        </StyledPartnerPanel>
      </StyledPageWrapper>
    </PartnersContext.Provider>
  );
};
