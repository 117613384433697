import { styled } from 'theme';

import { StyledSharedContainer } from '../../../styled';

export const StyledLoadingSkeleton = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledLoadingSkeletonDesktopRow = styled(StyledSharedContainer)`
  height: 32px;
  align-items: center;

  border-bottom: 1px solid
    ${({ theme }) => {
      return theme.color.line.primary;
    }};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const StyledLoadingSkeletonDesktopCell = styled('div')`
  padding: 0 24px;
  display: flex;
  gap: 8px;
`;

export const StyledLoadingSkeletonMobileRow = styled('div')`
  display: none;
  flex-direction: column;
  padding: 24px;
  gap: 24px;

  border-bottom: 1px solid
    ${({ theme }) => {
      return theme.color.line.primary;
    }};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
  }
`;

export const StyledLoadingSkeletonMobileBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledLoadingSkeletonMobileItem = styled('div')`
  gap: 8px;
  padding: 16px;
  display: flex;
  border-radius: 4px;

  border: 1px solid
    ${({ theme }) => {
      return theme.color.line.primary;
    }};
`;
