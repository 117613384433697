import { FC } from 'react';

import {
  StyledCheckmarkIcon,
  StyledCircleClockIcon,
  StyledStatusWrapperSuccess,
  StyledStatusWrapperWarning,
} from './DocStatusCell.styled';
import { TDocStatusCellProps } from './DocStatusCell.types';

export const DocStatusCell: FC<TDocStatusCellProps> = ({ isSigned, t }) => {
  return isSigned ? (
    <StyledStatusWrapperWarning className="StatusWrapperWarning">
      <StyledCircleClockIcon className="CircleClockIcon" />
      {t && t('layout__labels__status__in_review')}
    </StyledStatusWrapperWarning>
  ) : (
    <StyledStatusWrapperSuccess className="StatusWrapperSuccess">
      <StyledCheckmarkIcon className="CheckmarkIcon" />
      {t && t('layout__labels__status__need_to_sign')}
    </StyledStatusWrapperSuccess>
  );
};
