import { TSettingsItemError } from './useSettingsItem.types';

export const getValidationMessageKey = (error: TSettingsItemError): string => {
  switch (error) {
    case 'validation':
      return 'layout__settings__notifications__email_validation_error';

    case 'require':
      return 'layout__settings__notifications__email_deleting_primary';

    default:
      return '';
  }
};
