import { FC, useContext } from 'react';

import { StyledPageWrapper } from 'components/Main';
import { BrandingContext, TranslationContext } from 'contexts';

import { DownloadsPageContext } from '../../contexts';
import { DownloadsInitialSkeleton } from '../DownloadsInitialSkeleton';
import { DownloadsList } from '../DownloadsList';

import {
  StyledDownloadsEmptyText,
  StyledDownloadsPanel,
} from './DownloadsContainer.styled';

export const DownloadsContainer: FC = () => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { platforms, isLoading, isError } = useContext(DownloadsPageContext);
  const { branding } = useContext(BrandingContext);

  if (isTranslationsLoading || isLoading || !branding) {
    return <DownloadsInitialSkeleton />;
  }

  return (
    <StyledPageWrapper className="DownloadsWrapper">
      <StyledDownloadsPanel className="DownloadsPanel" title={t('Trading', '')}>
        {!platforms?.length ? (
          <StyledDownloadsEmptyText className="DownloadsEmptyText">
            {t(
              isError
                ? 'layout__downloads__error'
                : 'layout__downloads__no_products',
            )}
          </StyledDownloadsEmptyText>
        ) : (
          <DownloadsList branding={branding} platforms={platforms} />
        )}
      </StyledDownloadsPanel>
    </StyledPageWrapper>
  );
};
