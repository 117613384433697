import { ACCOUNT_NUMBER_SEARCH_REGEX } from './AccountSearchFieldRow.constants';

export const getAccountSearchDescription = (
  partialSearch: boolean,
  descriptions: Record<string, string>,
  label: string,
) => {
  if (!partialSearch) {
    return descriptions[label];
  }

  const accountNumber = label.match(ACCOUNT_NUMBER_SEARCH_REGEX)?.[0];
  if (accountNumber) {
    return descriptions[accountNumber];
  }

  return '';
};
