import { styled } from 'theme';

export const StyledHeaderTitle = styled('h1')`
  margin: 0 auto;

  color: ${({ theme }) => theme.color.typo.primary};
  font-weight: 400;
  font-size: 20px;
  font-family: ${({ theme }) => theme.font.header};

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute !important;

    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;

    border: 0 !important;

    clip: rect(1px, 1px, 1px, 1px) !important;
  }
`;
