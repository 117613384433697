import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TReport } from 'services/reports/reports.types';

import { TTaxReportsGetColumns } from './columns.types';
import { TaxReportAction } from './components/TaxReportAction';
import { TaxReportConditions } from './components/TaxReportConditions';
import { TaxReportUnion } from './components/TaxReportUnion';

export const TABLE_SORT_BY = [{ id: 'year', desc: true }];

export const getColumns: (
  params: TTaxReportsGetColumns,
) => IColumn<TReport>[] = ({
  t,
  reportTypeTitle,
  supportEmail,
  taxResidence,
  isMobile,
}) =>
  isMobile
    ? [
        {
          id: 'report',
          accessor: 'id',
          Header: t('generic__report'),
          Cell: ({ row: { original } }: ICellValue<TReport>) => {
            return (
              <TaxReportUnion
                report={original}
                title={reportTypeTitle}
                supportEmail={supportEmail}
              />
            );
          },
        },
      ]
    : [
        {
          id: 'report',
          accessor: 'id',
          Header: t('generic__report'),
          Cell: t(reportTypeTitle),
        },
        {
          id: 'year',
          accessor: 'tax_year',
          Header: t('generic__year'),
          width: 80,
        },
        {
          id: 'account',
          accessor: 'tndfl_account',
          Header: t('reports__tax__table__account'),
        },
        {
          id: 'conditions',
          accessor: 'last_status',
          Header: t('reports__tax__table__conditions'),
          width: 400,
          Cell: ({ row: { original } }: ICellValue<TReport>) => {
            return (
              <TaxReportConditions
                report={original}
                taxResidence={taxResidence}
                supportEmail={supportEmail}
              />
            );
          },
        },
        {
          id: 'action',
          accessor: 'last_status',
          Header: t('generic__action'),
          align: 'right',
          width: 80,
          Cell: ({ row: { original } }: ICellValue<TReport>) => (
            <TaxReportAction report={original} />
          ),
        },
      ];
