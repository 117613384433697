import { FC, useContext } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { ReportsContext } from 'pages/Reports/contexts/ReportsContext/ReportsContext';

import { SubscriptionContext } from '../contexts/SubscriptionContext';

import { TPeriodicActionCellProps } from './PeriodicActionCell.types';

export const PeriodicActionCell: FC<TPeriodicActionCellProps> = ({
  data,
  onDownloadReport,
  iconSize = 24,
}) => {
  const { t } = useContext(TranslationContext);
  const { subscriptions } = useContext(SubscriptionContext);
  const { ftpError } = useContext(ReportsContext);

  const { key } = data;

  const subscription = subscriptions?.find((sub) => sub.name === key);
  const downloadReport = () => {
    if (subscription && !ftpError) {
      onDownloadReport(key);
    }
  };

  const requestEnabled =
    (subscription?.daily || subscription?.monthly) &&
    (subscription?.pdf || subscription?.csv || subscription?.xls);

  return (
    <Tooltip
      className="PeriodicActionCellTooltip"
      title={t('layout__periodic_request__request_report')}
      placement="top"
    >
      <IconButton
        className="PeriodicActionCellReportButton"
        iconName="RequestReport1Icon"
        iconColor="action"
        data-test-id="download-periodic-report-button"
        onClick={downloadReport}
        disabled={ftpError || !requestEnabled}
        iconSize={iconSize}
      />
    </Tooltip>
  );
};
