import { useContext } from 'react';

import { BrandingContext, TranslationContext } from 'contexts';

import { ChallengeHeaderContext } from '../../../context/ChallengeContext';

import { StyledChallengeAccountStatus } from './ChallengeAccountStatus.styled';

export const ChallengeAccountStatus = () => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const { account_status: accountStatus } = useContext(ChallengeHeaderContext);

  if (!accountStatus) {
    return null;
  }

  return !branding?.branding?.challenge_settings?.header_settings
    ?.show_account_status ? null : (
    <StyledChallengeAccountStatus className="HeaderCustomText ChallengeAccountStatus">
      {t(`challenge_state__${accountStatus}`)}
    </StyledChallengeAccountStatus>
  );
};
