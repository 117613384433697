import { AxiosError } from 'axios';
import { ChangeEvent, useContext, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  DeleteStatus,
  TSetFileDeleteStatusFn,
  TSetFileSuccessFn,
} from 'react-ui-kit-exante';
import { useDebounce } from 'use-debounce';

import { TranslationContext } from 'contexts/TranslationContext';
import { isCancelledError } from 'helpers/abortController/abortController.helpers';
import { transferService } from 'services/transfer';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';
import { selectAccounts } from 'store/accounts';

import { getFieldTooltipHint } from '../../../../../Transfers.helpers';
import { useTransfers } from '../../../../../hooks';
import { FundsContext } from '../../../context';

import { componentInitialState, SEARCH_DEBOUNCE_TIMEOUT } from './constants';
import { extractValidationError } from './helpers';
import { componentReducer } from './reducer';

export const useComponentState = () => {
  const { toInternalAccountDispatch, toInternalAccountState } =
    useContext(FundsContext);

  const { transfersState, cashTransferTab } = useTransfers();

  const validateAbortController = useRef(new AbortController());

  const [componentState, componentDispatch] = useReducer(
    componentReducer,
    componentInitialState,
  );

  const { t } = useContext(TranslationContext);
  const { selectedAccountId } = useSelector(selectAccounts);
  const {
    cashTransferTab: tab,
    clearRepeatedWithdrawal,
    repeatedWithdrawal,
  } = useTransfers();

  const [debouncedSearch] = useDebounce<string>(
    componentState.search,
    SEARCH_DEBOUNCE_TIMEOUT,
  );

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: payload } = event.target;

    if (payload === '') {
      toInternalAccountDispatch({
        type: 'SET_TARGET',
        payload: { targetAccountId: '', name: '' },
      });
    }

    componentDispatch({
      type: 'SET_SEARCH',
      payload: payload.replace(/\s/g, ''),
    });
  };

  const handleReferenceInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: payload } = event.target;
    toInternalAccountDispatch({ type: 'SET_REFERENCE', payload });
  };

  const handleOptionalButtonClick = () => {
    componentDispatch({ type: 'TOGGLE_EXPANDED' });
  };

  const handleValidate = async () => {
    if (debouncedSearch && componentState.search) {
      validateAbortController.current.abort();
      validateAbortController.current = new AbortController();

      componentDispatch({ type: 'VALIDATE_START' });
      try {
        const payload = await transferService.searchExternalAccountID(
          debouncedSearch.replace(/\s/g, ''),
          selectedAccountId,
          validateAbortController.current.signal,
        );

        componentDispatch({
          type: 'VALIDATE_SUCCESS',
          payload,
        });

        toInternalAccountDispatch({
          type: 'SET_TARGET',
          payload: {
            targetAccountId: payload.target_account,
            name: payload.target_name,
          },
        });
      } catch (error) {
        const { response } = error as AxiosError;
        if (!isCancelledError(error) && response?.status === 400) {
          const message = extractValidationError(t, response);

          componentDispatch({
            type: 'VALIDATE_ERROR',
            payload:
              message ||
              t('layout__subaccount_transfer__validate_external_error'),
          });

          return;
        }

        if (!isCancelledError(error)) {
          componentDispatch({
            type: 'VALIDATE_ERROR',
            payload: 'Unable perform validate request',
          });

          toInternalAccountDispatch({
            type: 'SET_TARGET',
            payload: {
              targetAccountId: '',
              name: '',
            },
          });
        }
      }
    }
  };

  const getFieldHint = (fieldName: string, onlyText?: boolean) => {
    return getFieldTooltipHint(
      fieldName,
      WITHDRAWAL_TYPE.INTERNAL,
      transfersState?.branding?.wr_fields_settings,
      t,
      onlyText,
    );
  };

  const handleFileAppend = async (
    payload: File[],
    onSuccess: TSetFileSuccessFn,
  ) => {
    toInternalAccountDispatch({
      type: 'SET_FILES',
      payload,
    });

    payload.forEach((file) =>
      onSuccess({
        name: file.name,
        id: file.name,
        removable: true,
      }),
    );
  };

  const handleFileDelete = async (
    payload: string,
    setDeleted: TSetFileDeleteStatusFn,
  ) => {
    setDeleted({ id: payload, status: DeleteStatus.SUCCESS });

    toInternalAccountDispatch({
      type: 'DELETE_FILE',
      payload,
    });
  };

  useEffect(() => {
    if (debouncedSearch) {
      handleValidate();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (tab !== 'INTERNAL') {
      toInternalAccountDispatch({
        type: 'RESET',
      });
    }

    return () => {
      toInternalAccountDispatch({
        type: 'RESET',
      });
    };
  }, [tab]);

  useEffect(() => {
    if (
      repeatedWithdrawal?.internal_account &&
      repeatedWithdrawal?.currency &&
      cashTransferTab === 'INTERNAL'
    ) {
      clearRepeatedWithdrawal();

      componentDispatch({
        type: 'SET_SEARCH',
        payload: repeatedWithdrawal?.internal_account.replace(/\s/g, ''),
      });

      if (repeatedWithdrawal?.currency !== toInternalAccountState.currency) {
        toInternalAccountDispatch({
          type: 'SET_CURRENCY',
          payload: repeatedWithdrawal?.currency,
        });
      }

      if (repeatedWithdrawal?.comment) {
        componentDispatch({ type: 'TOGGLE_EXPANDED' });
        toInternalAccountDispatch({
          type: 'SET_REFERENCE',
          payload: repeatedWithdrawal?.comment?.replace(
            `Third party transfer to ${repeatedWithdrawal?.internal_account}\n`,
            '',
          ),
        });
      }
    }
  }, [repeatedWithdrawal]);

  useEffect(() => {
    if (!toInternalAccountState.targetAccountId) {
      componentDispatch({ type: 'CLEAR' });
    }
  }, [toInternalAccountState.targetAccountId]);

  return {
    ...componentState,
    ...toInternalAccountState,

    getFieldHint,
    handleFileDelete,
    handleFileAppend,
    handleOptionalButtonClick,
    handleReferenceInputChange,
    handleSearchInputChange,
    handleValidate,
  };
};
