import { TFunction } from 'i18next';
import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { TPartnerLink } from 'services/partner';

import {
  getPartnerShortUrl,
  getPartnerUtmUrl,
  preparePartnerUrl,
} from '../../Partner.helpers';

import { TPartnerLinksTableLink } from './PartnerLinks.types';
import { PartnerLinksActions, PartnerLinksShortUtm } from './components';

export const getPartnerLinksColumns = (
  t: TFunction,
): Array<IColumn<TPartnerLinksTableLink>> => [
  {
    name: 'id',
    accessor: 'id',
    Header: '#',
    isNumeric: true,
    width: 80,
  },
  {
    name: 'name',
    accessor: 'name',
    Header: t('layout__partners__links__name'),
  },
  {
    name: 'site_url',
    accessor: 'siteUrl',
    Header: t('layout__partners__links__destination_url'),
  },
  {
    name: 'url',
    accessor: 'url',
    Header: t('layout__partners__links__source'),
  },
  {
    name: 'utmUrl',
    Cell: ({
      row: {
        original: { utmUrl: url },
      },
    }: ICellValue<TPartnerLinksTableLink>) => {
      const limit = 32;

      return url.length <= limit ? url : `${url.substring(0, limit)}...`;
    },
    Header: t('layout__partners__links__full_link'),
  },
  {
    name: 'short_link',
    Header: t('layout__partners__links__short_link'),
    Cell: ({ row: { original } }: ICellValue<TPartnerLinksTableLink>) => {
      return <PartnerLinksShortUtm shortUtm={original.shortUtm} />;
    },
  },
  {
    name: 'visits',
    Header: t('layout__partner__links__visits'),
    Cell: ({ row: { original } }: ICellValue<TPartnerLinksTableLink>) => {
      return original.visit_count ?? DEFAULT_EMPTY_PLACEHOLDER;
    },
  },
  {
    name: 'actions',
    Header: t('layout__partners__links__actions'),
    align: 'right',
    width: 100,
    Cell: (
      cellprops: ICellValue<TPartnerLinksTableLink> & {
        page: Array<ICellValue<TPartnerLinksTableLink>['row']>;
      },
    ) => {
      return (
        <PartnerLinksActions
          linkId={cellprops.row.original.id}
          isPageFirstElement={cellprops.page[0]?.id === cellprops.row.id}
        />
      );
    },
  },
];

export const preparePartnerLinkForTable = (
  partnerDomain: string,
  item: TPartnerLink,
): TPartnerLinksTableLink => {
  const itemByPartnerDomain = {
    ...item,
    site_url: partnerDomain,
  };

  return {
    ...item,
    utmUrl: getPartnerUtmUrl(item),
    shortUtm: getPartnerShortUrl(itemByPartnerDomain),
    siteUrl: preparePartnerUrl(item.site_url),
    url: preparePartnerUrl(item.url),
  };
};
