import { FC, MouseEvent, useContext, useState } from 'react';
import { Modal, useModal } from 'react-ui-kit-exante';

import { DocumentViewer } from 'components/Footer/DocumentViewer';
import { TModalDoc } from 'components/Footer/DocumentViewer/DocumentViewer.types';
import { BrandingContext, TranslationContext } from 'contexts';
import { TManual } from 'services/help';

import {
  StyledHelpHeading,
  StyledHelpSubheading,
  StyledHelpText,
} from '../../Help.styled';

import {
  StyledArrowSquareRightUpIcon,
  StyledDownloadCompactIcon,
  StyledHelpManualsWrapper,
  StyledManual,
  StyledManualAnchor,
  StyledManualIconAnchor,
  StyledManualList,
} from './HelpManuals.styled';
import { THelpManualsProps } from './HelpManuals.types';

const iconDownload = (
  <StyledDownloadCompactIcon
    className="DownloadCompactIcon"
    role="presentation"
  />
);

const iconArrow = (
  <StyledArrowSquareRightUpIcon
    className="ArrowSquareRightUpIcon"
    role="presentation"
  />
);

export const HelpManuals: FC<THelpManualsProps> = ({ groups }) => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const modal = useModal();

  const [modalDoc, setModalDoc] = useState<null | TModalDoc>(null);

  const handleOpen = (manual: TManual) => {
    if (!manual.preview_url) {
      return;
    }

    setModalDoc({
      title: manual.title,
      isSigned: false,
      dataUrl: manual.preview_url,
    });
    modal.onOpen();
  };

  const handleClose = () => {
    setModalDoc(null);
    modal.onClose();
  };

  return (
    <StyledHelpManualsWrapper className="HelpManualsWrapper">
      <div>
        <StyledHelpHeading className="HelpHeading">
          {t('guides_and_manuals')}
        </StyledHelpHeading>
        <StyledHelpText className="HelpText">
          {t('layout__help__here_can_find_materials', {
            NAME: branding?.wl?.name,
          })}
        </StyledHelpText>
      </div>

      {groups?.map((group) => (
        <div key={group.title}>
          <StyledHelpSubheading className="HelpSubheading">
            {t(group.title)}
          </StyledHelpSubheading>
          <StyledManualList className="ManualList">
            {group.items?.map((manual) => {
              let target = '_self';

              if (manual.popup || (manual.blank && manual.open)) {
                target = '_blank';
              }

              const description = manual.description
                ? ` (${manual.description})`
                : null;

              const handleClick = manual.popup
                ? (e: MouseEvent<HTMLAnchorElement>) => {
                    e.preventDefault();
                    handleOpen(manual);
                  }
                : undefined;

              return (
                <StyledManual key={manual.title} className="Manual">
                  <StyledManualIconAnchor
                    className="ManualIconAnchor"
                    href={manual.download || manual.open}
                    target="_blank"
                  >
                    {manual.download ? iconDownload : iconArrow}
                  </StyledManualIconAnchor>

                  <span>
                    <StyledManualAnchor
                      className="ManualAnchor"
                      href={manual.open}
                      target={target}
                      onClick={handleClick}
                    >
                      {t(manual.title)}
                    </StyledManualAnchor>
                    {description}
                  </span>
                </StyledManual>
              );
            })}
          </StyledManualList>
        </div>
      ))}
      <Modal
        title={modalDoc?.title || ''}
        isOpened={Boolean(modal.isOpened && modalDoc)}
        onClose={handleClose}
        cancelButton={{
          cancelButtonName: t('generic__action__cancel'),
        }}
        maxWidth="lg"
        disableContentPaddings
        fullWidth
      >
        {modalDoc && <DocumentViewer doc={modalDoc} />}
      </Modal>
    </StyledHelpManualsWrapper>
  );
};
