import { RefObject, useCallback, useEffect } from 'react';

export function useOutsideClick(
  ref: RefObject<HTMLElement>,
  onOutside: () => void,
) {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutside();
      }
    },
    [onOutside, ref],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, ref]);
}
