import { accountSettingsService } from 'services/settings/accountSettings';

import { TAccountsTableContext } from './AccountsTableContext.types';

export const accountsTableContextInitialValue: TAccountsTableContext = {
  autoDebitData: accountSettingsService.autoDebitDataEmpty,
  isAutoDebitDataLoading: false,
  setPaymentData: () => {},
  accountsList: [],
  totalRecords: 0,
  setPage: () => {},
  setLimit: () => {},
  page: 0,
  limit: 0,
  isLoading: true,
  setSorting: () => {},
  isAnyDescriptionEditable: false,
  isAnyMarginTradingEditable: false,
  isAnyAutoDebitEditable: false,
  isAnyAutoDebitShowing: false,
  isAnyAutoConversionEditable: false,
  accountDescriptions: {},
  updateAccountDescription() {},
  setAccountsSearch() {},
  searchedAccountId: '',
  initialAccountsLength: 0,
};
