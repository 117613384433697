import { TKeyScopeItem } from 'services/dashboard';

export const ScopesMap = new Map<TKeyScopeItem, string>([
  ['ohlc', 'OHLC'],
  ['crossrates', 'Crossrates'],
  ['symbols', 'Symbols'],
  ['change', 'Change'],
  ['feed', 'Feed'],
  ['orders', 'Orders'],
  ['summary', 'Summary'],
  ['transactions', 'Transactions'],
  ['accounts', 'Accounts'],
]);
