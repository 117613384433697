import {
  TGetRequisitesParameters,
  TRequisites,
} from './RequisitesCashSection.types';

/**
 * Collects bank & account requisite data to render-convenient format
 *
 * @param bank bank data from API
 * @param currency user-selected currency to extract currency-related bank data
 */
export const getRequisites = ({
  bank,
  currency,
}: TGetRequisitesParameters): TRequisites[] => {
  // admin-defined data
  const bankCurrency = bank.currency[currency];

  return [
    {
      title: 'Beneficiary',
      value: bankCurrency.beneficiary,
    },
    {
      title: 'beneficiary_address',
      value: bankCurrency.beneficiaryAddress,
      isRequired: true,
    },
    {
      title: 'Bank',
      value: bank.beneficiaryBank,
    },
    {
      title: 'bank_address',
      value: bank.bankAddress,
    },
    {
      title: 'SWIFT',
      value: bank.swift,
    },
    {
      title: 'account_number',
      value: bankCurrency.accountNumber,
    },
    {
      title: 'IBAN',
      value: bankCurrency.iban,
    },
    {
      title: 'layout__deposit__correspondent_bic_swift',
      value: bankCurrency.correspondentSWIFT,
    },
    {
      title: 'layout__deposit__correspondent_bank_acc_number',
      value: bankCurrency.correspondentAccount,
    },
    {
      title: 'BIK',
      value: bankCurrency.correspondentBIC,
    },
    {
      title: 'payment_details',
      value: bank.paymentDetails,
      isRequired: true,
    },
  ].filter((item): item is TRequisites => item.isRequired || !!item.value);
};
