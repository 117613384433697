import { createContext, PropsWithChildren } from 'react';

import { apiInitialContext } from './constants';
import { TApiContext } from './types';
import { useApiContext } from './useApiContext';

export const ApiContext = createContext<TApiContext>(apiInitialContext);

export const ApiContextProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const value = useApiContext();

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};
