import { TAccountsState } from './accounts.types';

export const accountsInitialState: TAccountsState = {
  accountsExcludePages: {},
  accountsExcludeTabs: {},
  accountsPages: {},
  accountsTabs: {},
  data: [],

  isAccountsFirstLoading: false,
  isAccountsLoading: false,
  isSuperuser: false,

  selectedAccount: null,
  selectedAccountId: null,
  selectedDateIso: new Date().toJSON(),

  allAccountDescriptions: {},
};
