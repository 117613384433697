import { useContext, useEffect } from 'react';
import {
  DeleteStatus,
  TSetFileDeleteStatusFn,
  TSetFileErrorFn,
  TSetFileSuccessFn,
  useData,
} from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { notifyWith } from 'helpers/notifyWith';
import {
  settingsDocumentsService,
  TWizardState,
} from 'services/settings/documents';

import { ACCEPT_FILE_LIST, MAX_FILE_SIZE_MB } from './Documents.consts';
import {
  StyledDocumentsHeading,
  StyledDocumentsInfoBlock,
  StyledDocumentsInfoText,
  StyledDocumentsSection,
  StyledDocumentsUploadGroups,
  StyledDocumentsWrapper,
} from './Documents.styled';
import { DocumentsSkeleton } from './components/DocumentsSkeleton';
import { UploadGroup } from './components/UploadGroup';

export const Documents = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const {
    fetchData: fetchWizardState,
    isLoading: isWizardStateLoading,
    data: wizardState,
  } = useData<TWizardState | null>({
    onFetch: () => settingsDocumentsService.getWizardState(currentLanguage),
  });

  useEffect(() => {
    fetchWizardState();
  }, [currentLanguage]);

  const handleUpload = (
    docType: string,
    files: File[],
    setSuccess: TSetFileSuccessFn,
    setError: TSetFileErrorFn,
  ) => {
    files.forEach(async (file) => {
      try {
        const fileData = await settingsDocumentsService.uploadFile(
          docType,
          file,
        );

        setSuccess({
          name: file.name,
          newName: fileData.name,
          id: String(fileData.id),
          removable: fileData.removable,
        });
      } catch (error: any) {
        notifyWith.genericError();
        console.error('uploadFile', error);

        setError({ name: file.name, error });
      }
    });
  };

  const handleDelete = async (
    id: string,
    setStatus: TSetFileDeleteStatusFn,
  ) => {
    const success = await settingsDocumentsService.removeFile(id);
    setStatus({
      id,
      status: success ? DeleteStatus.SUCCESS : DeleteStatus.ERROR,
    });
  };

  if (isTranslationsLoading || isWizardStateLoading) {
    return <DocumentsSkeleton />;
  }

  return (
    <StyledDocumentsWrapper className="DocumentsWrapper">
      <StyledDocumentsSection className="DocumentsSection">
        <StyledDocumentsHeading className="DocumentsHeading">
          {t('layout__settings__documents__title')}
        </StyledDocumentsHeading>
        <StyledDocumentsInfoText className="DocumentsInfoText">
          {t('layout__settings__documents__text')}
        </StyledDocumentsInfoText>
        <StyledDocumentsUploadGroups className="DocumentsUploadGroups">
          {wizardState?.uploadGroups?.map((group) => (
            <UploadGroup
              key={group.doc_type}
              group={group}
              onUpload={handleUpload}
              onDelete={handleDelete}
              isApproved={wizardState?.wizard.isApproved}
            />
          ))}
        </StyledDocumentsUploadGroups>
      </StyledDocumentsSection>
      <StyledDocumentsSection className="DocumentsSection">
        <StyledDocumentsHeading className="DocumentsHeading">
          {t('layout__settings__documents__title_attention')}
        </StyledDocumentsHeading>
        <StyledDocumentsInfoBlock className="DocumentsInfoBlock">
          <StyledDocumentsInfoText
            className="DocumentsInfoText"
            dangerouslySetInnerHTML={{
              __html: t('layout__settings__documents__requirements', {
                VALID_FORMATS: ACCEPT_FILE_LIST,
                FILE_SIZE_MB: MAX_FILE_SIZE_MB,
              }),
            }}
          />
        </StyledDocumentsInfoBlock>
      </StyledDocumentsSection>
    </StyledDocumentsWrapper>
  );
};
