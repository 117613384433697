import { FC, useContext } from 'react';

import { CopyButton } from 'components/CopyButton';
import { TranslationContext } from 'contexts';

import {
  StyledDepositHistorySubRow,
  StyledDepositHistorySubRowAddress,
  StyledDepositHistorySubRowAddressText,
} from '../../DepositHistoryTable.styled';

import { TCryptoDetailsSubRowProps } from './CryptoDetailsSubRow.types';

export const CryptoDetailsSubRow: FC<TCryptoDetailsSubRowProps> = ({
  address_from,
  address_to,
}) => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledDepositHistorySubRow className="DepositHistorySubRow">
      <StyledDepositHistorySubRowAddress className="DepositHistorySubRowAddress">
        {t('generic__email_from')}:
        <StyledDepositHistorySubRowAddressText className="DepositHistorySubRowAddressText">
          {address_from}
        </StyledDepositHistorySubRowAddressText>
        <CopyButton
          iconSize={16}
          iconColor="action"
          text={address_from}
          data-test-id="deposit_table__row__copy__addr_from"
        />
      </StyledDepositHistorySubRowAddress>

      <StyledDepositHistorySubRowAddress className="DepositHistorySubRowAddress">
        {t('generic__email_to')}:
        <StyledDepositHistorySubRowAddressText className="DepositHistorySubRowAddressText">
          {address_to}
        </StyledDepositHistorySubRowAddressText>
        <CopyButton
          iconSize={16}
          iconColor="action"
          text={address_to}
          data-test-id="deposit_table__row__copy__addr_to"
        />
      </StyledDepositHistorySubRowAddress>
    </StyledDepositHistorySubRow>
  );
};
