import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { CashSum, PositiveNegative } from 'components/Cells';
import { InstrumentCell } from 'components/Cells/InstrumentCell';
import { TotalFooter } from 'components/Cells/TotalFooter';
import {
  CASH_GROUP,
  POSITIONS_CONFIG,
  REQUIRED_COLUMNS,
  valueTypeForTotalMap,
} from 'components/Positions/Positions.constants';
import {
  TGetColumnsParams,
  TNormalizedPositions,
} from 'components/Positions/Positions.types';
import { DescriptionFooter } from 'components/Positions/components/DescriptionFooter';
import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { typeIconNames } from 'constants/icons';
import { calculateColumnValuesSum } from 'helpers/calculateColumnValuesSum';
import { Dates } from 'helpers/dates';
import { formatCellToCurrency, formatPercentage } from 'helpers/formatters';
import { formatCellToCurrencyUnrounded } from 'helpers/formatters/formatCellToCurrencyUnrounded';
import { formatCurrencyUnrounded } from 'helpers/formatters/formatCurrencyUnrounded';
import { TPosition } from 'types/accounts';

import { StyledValueContainer } from './Positions.styled';

/**
 * Groups array of object by POSITIONS_CONFIG.types.
 * And sets these groups in right POSITION_CONFIG.order
 */
export function normalizePositions(
  positions: TPosition[],
): TNormalizedPositions[] {
  const configKeys = Object.keys(POSITIONS_CONFIG);
  const groupedPositions = configKeys.reduce((acc, key) => {
    const { id, title, types, order, iconName } = POSITIONS_CONFIG[key];
    const group = positions.filter((pos) => types.includes(pos.type));
    const position = {
      id,
      title,
      data: group,
      iconName,
    };

    acc.splice(order, 1, position);

    return acc;
  }, new Array(configKeys.length));

  return groupedPositions.filter((item) => Boolean(item.data.length));
}

export function omitRequiredColumns(
  columns: IColumn<Record<string, unknown>>[],
) {
  return columns.filter(
    (column) => !REQUIRED_COLUMNS.includes(column.accessor as string),
  );
}

export function setTableIdsPrefix(ids: string[], prefix?: string) {
  if (!prefix) {
    return ids;
  }

  return ids.map((id) => `${prefix}-${id}`);
}

export function setAccordionLsPrefix(lsKey: string, prefix?: string) {
  if (!prefix) {
    return lsKey;
  }

  return `${prefix}-${lsKey}`;
}

export const getAggregatedValueForTable = (
  id: string,
  data: TPosition[],
  currency?: string,
) => {
  const key = valueTypeForTotalMap[id];
  const values = data.map((item) => item[key]);
  return (
    <StyledValueContainer className="ValueContainer">
      <CashSum values={values} currency={currency} />
    </StyledValueContainer>
  );
};

//region columns
export function getColumns({
  t,
  currency,
  currencyFormatter,
  dateFormatter,
}: TGetColumnsParams): IColumn<TPosition>[] {
  return [
    {
      Header: t('generic__instrument'),
      accessor: 'symbolId',
      id: 'symbolId',
      Cell: ({
        row: {
          values,
          original: { type, expirationTime },
        },
      }: ICellValue<TPosition>) => (
        <InstrumentCell
          iconName={typeIconNames[type]}
          asset={values.symbolId}
          expirationTime={expirationTime}
        />
      ),
      required: true,
      Footer: TotalFooter,
    },
    {
      Header: t('layout__summary__short_name'),
      accessor: 'shortName',
      id: 'shortName',
    },
    {
      Header: t('generic__description'),
      accessor: 'description',
      id: 'description',
      Cell: ({
        row: {
          values,
          original: { type, expirationTime },
        },
      }: ICellValue<TPosition>) =>
        type === CASH_GROUP ? (
          <InstrumentCell
            iconName={typeIconNames.CASH}
            asset={values.currency}
            expirationTime={expirationTime}
          />
        ) : (
          values.description
        ),
      Footer: DescriptionFooter,
    },
    { Header: t('layout__summary__id'), accessor: 'id', id: 'id' },
    { Header: t('generic__ticker'), accessor: 'ticker', id: 'ticker' },
    { Header: t('generic__isin'), accessor: 'isin', id: 'isin' },
    { Header: t('generic__sedol'), accessor: 'sedol', id: 'sedol' },
    { Header: t('generic__figi'), accessor: 'figi', id: 'figi' },
    { Header: t('generic__exchange'), accessor: 'exchange', id: 'exchange' },
    {
      Header: t('layout__summary__qtty'),
      accessor: 'quantity',
      id: 'quantity',
      Cell: formatCellToCurrency<TPosition>(currencyFormatter, 'quantity'),
      sortType: 'numeric',
    },
    {
      Header: t('layout__summary__blocked_qtty'),
      accessor: 'blockedQtty',
      id: 'blockedQtty',
      Cell: formatCellToCurrency<TPosition>(currencyFormatter, 'blockedQtty'),
      sortType: 'numeric',
    },
    {
      Header: t('layout__summary__locked_value'),
      accessor: 'lockedValue',
      id: 'lockedValue',
      Cell: formatCellToCurrency<TPosition>(currencyFormatter, 'lockedValue'),
      sortType: 'numeric',
    },
    {
      Header: t('layout__summary__avg_price'),
      accessor: 'averagePrice',
      id: 'averagePrice',
      Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'averagePrice'),
      sortType: 'numeric',
    },
    {
      Header: t('generic__price'),
      accessor: 'price',
      id: 'price',
      Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'price'),
      sortType: 'numeric',
    },
    {
      Header: t('layout__summary__quote_timestamp'),
      accessor: 'quoteTimestamp',
      id: 'quoteTimestamp',
      Cell: ({ row: { values } }: ICellValue<TPosition>) => {
        const value = values.quoteTimestamp
          ? dateFormatter(
              Dates.convertDateToUtcTimezone(new Date(values.quoteTimestamp)),
            )
          : DEFAULT_EMPTY_PLACEHOLDER;
        return <div title={value || ''}>{value}</div>;
      },
    },
    { Header: t('generic__ccy'), accessor: 'currency', id: 'currency' },
    {
      Header: t('layout__summary__value'),
      accessor: 'value',
      id: 'value',
      Cell: formatCellToCurrency<TPosition>(currencyFormatter, 'value'),
      sortType: 'numeric',
    },
    {
      Header: `${t('layout__summary__value')}, ${currency}`,
      accessor: 'convertedValue',
      id: 'convertedValue',
      Cell: formatCellToCurrency<TPosition>(
        currencyFormatter,
        'convertedValue',
      ),
      sortType: 'numeric',
      Footer: calculateColumnValuesSum<TPosition>('convertedValue'),
    },
    {
      Header: t('layout__summary__pl'),
      accessor: 'pnl',
      id: 'pnl',
      Cell: ({ row: { values, original } }: ICellValue<TPosition>) => (
        <PositiveNegative
          value={values.pnl}
          expirationTime={original.expirationTime}
          valueFormatter={(value) =>
            formatCurrencyUnrounded(currencyFormatter, value)
          }
        />
      ),
      sortType: 'numeric',
    },
    {
      Header: `${t('layout__summary__pl')}, ${currency}`,
      accessor: 'convertedPnl',
      id: 'convertedPnl',
      Cell: ({ row: { values, original } }: ICellValue<TPosition>) => (
        <PositiveNegative
          value={values.convertedPnl}
          expirationTime={original.expirationTime}
          valueFormatter={(value) =>
            formatCurrencyUnrounded(currencyFormatter, value)
          }
        />
      ),
      sortType: 'numeric',
      Footer: calculateColumnValuesSum<TPosition>('convertedPnl', {
        colored: true,
      }),
    },
    {
      Header: `${t('layout__summary__pl')}, %`,
      accessor: 'pnlPercentage',
      id: 'pnlPercentage',
      Cell: ({ row: { values, original } }: ICellValue<TPosition>) => (
        <PositiveNegative
          value={values.pnlPercentage}
          valueFormatter={formatPercentage}
          expirationTime={original.expirationTime}
        />
      ),
      sortType: 'numeric',
    },
    {
      Header: t('generic__daily_pnl'),
      accessor: 'dailyPnl',
      id: 'dailyPnl',
      Cell: ({ row: { values, original } }: ICellValue<TPosition>) => (
        <PositiveNegative
          value={values.dailyPnl}
          expirationTime={original.expirationTime}
          valueFormatter={(value) =>
            formatCurrencyUnrounded(currencyFormatter, value)
          }
        />
      ),
      sortType: 'numeric',
    },
    {
      Header: `${t('generic__daily_pnl')}, ${currency}`,
      accessor: 'convertedDailyPnl',
      id: 'convertedDailyPnl',
      Cell: ({ row: { values, original } }: ICellValue<TPosition>) => (
        <PositiveNegative
          value={values.convertedDailyPnl}
          expirationTime={original.expirationTime}
          valueFormatter={(value) =>
            formatCurrencyUnrounded(currencyFormatter, value)
          }
        />
      ),
      sortType: 'numeric',
      Footer: calculateColumnValuesSum<TPosition>('convertedDailyPnl'),
    },
    {
      Header: t('layout__summary__daily_pnl_percent'),
      accessor: 'dailyPnlPercentage',
      id: 'dailyPnlPercentage',
      Cell: ({ row: { values } }: ICellValue<TPosition>) =>
        formatPercentage(values.dailyPnlPercentage),
      sortType: 'numeric',
    },
    {
      Header: t('layout__summary__daily_trades_pl'),
      accessor: 'dailyTradesPnl',
      id: 'dailyTradesPnl',
      Cell: formatCellToCurrencyUnrounded<TPosition>(
        currencyFormatter,
        'dailyTradesPnl',
      ),
      sortType: 'numeric',
    },
    {
      Header: `${t('layout__summary__daily_trades_pl')}, ${currency}`,
      accessor: 'convertedDailyTradesPnl',
      id: 'convertedDailyTradesPnl',
      Cell: formatCellToCurrencyUnrounded<TPosition>(
        currencyFormatter,
        'convertedDailyTradesPnl',
      ),
      sortType: 'numeric',
    },
    {
      Header: t('layout__summary__contract_multiplier'),
      accessor: 'contractMultiplier',
      id: 'contractMultiplier',
      Cell: formatCellToCurrencyUnrounded<TPosition>(
        currencyFormatter,
        'contractMultiplier',
      ),
      sortType: 'numeric',
    },
    {
      Header: t('layout__summary__price_unit'),
      accessor: 'priceUnit',
      id: 'priceUnit',
      Cell: formatCellToCurrencyUnrounded<TPosition>(
        currencyFormatter,
        'priceUnit',
      ),
      sortType: 'numeric',
    },
    {
      Header: t('layout__summary__expiration_date'),
      accessor: 'expirationDate',
      id: 'expirationDate',
      Cell: ({ row: { values } }: ICellValue<TPosition>) => {
        const value = values.expirationDate
          ? dateFormatter(
              Dates.convertDateToUtcTimezone(new Date(values.expirationDate)),
            )
          : DEFAULT_EMPTY_PLACEHOLDER;
        return <div title={value || ''}>{value}</div>;
      },
    },
    {
      Header: t('layout__summary__expiration_time'),
      accessor: 'expirationTime',
      id: 'expirationTime',
      Cell: ({ row: { values } }: ICellValue<TPosition>) => {
        const value = values.expirationTime
          ? dateFormatter(
              Dates.convertDateToUtcTimezone(new Date(values.expirationTime)),
            )
          : DEFAULT_EMPTY_PLACEHOLDER;
        return <div title={value || ''}>{value}</div>;
      },
    },
    {
      Header: t('layout__summary__share_percent'),
      accessor: 'sharePercent',
      id: 'sharePercent',
      Cell: ({ row: { values } }: ICellValue<TPosition>) =>
        formatPercentage(values.sharePercent),
      sortType: 'numeric',
    },
    {
      Header: t('generic__realized_pnl'),
      accessor: 'realizedPnl',
      id: 'realizedPnl',
    },
    {
      Header: t('layout__summary__accrued_interest'),
      accessor: 'accruedInterest',
      id: 'accruedInterest',
      Cell: formatCellToCurrencyUnrounded<TPosition>(
        currencyFormatter,
        'accruedInterest',
      ),
      sortType: 'numeric',
    },
    {
      Header: `${t('layout__summary__accrued_interest')}, ${currency}`,
      accessor: 'convertedAccruedInterest',
      id: 'convertedAccruedInterest',
      Cell: formatCellToCurrencyUnrounded<TPosition>(
        currencyFormatter,
        'convertedAccruedInterest',
      ),
      sortType: 'numeric',
    },
    {
      Header: `${t('layout__summary__clean_price')}, ${currency}`,
      accessor: 'cleanPrice',
      id: 'cleanPrice',
      Cell: formatCellToCurrencyUnrounded<TPosition>(
        currencyFormatter,
        'cleanPrice',
      ),
      sortType: 'numeric',
    },
  ];
}

export function getCommonColumns(columns: IColumn<TPosition>[]) {
  const omitColumns = ['lockedValue'];
  return columns.filter((col) => !omitColumns.includes(col.accessor as string));
}

export function getExistingColumns(
  positions: TPosition[],
  columns: IColumn<TPosition>[],
  requiredColumnKeys?: string[],
): IColumn<TPosition>[] {
  const position = positions[0];

  if (!position) {
    return [];
  }

  const allFields = Object.keys(position).reduce<Record<string, boolean>>(
    (acc, curr) => {
      return { ...acc, [curr]: true };
    },
    {},
  );

  return columns.reduce((acc, curr) => {
    const currentItem = { ...curr };
    if (
      requiredColumnKeys &&
      requiredColumnKeys.includes(currentItem.accessor as string)
    ) {
      return [...acc, { ...currentItem, required: true }];
    }

    if (allFields[currentItem.accessor as string]) {
      return [...acc, currentItem];
    }

    return acc;
  }, [] as IColumn<TPosition>[]);
}

//endregion
