import cn from 'classnames';
import { FC, useContext } from 'react';

import { CardSettingsContext } from 'pages/CardSettings/contexts';

import {
  StyledCardInfoBlockHeader,
  StyledCardInfoBlockIconWrapper,
  StyledCardInfoBlockText,
  StyledCardInfoBlockTextWrapper,
  StyledCardInfoBlockWrapper,
} from './CardInfoBlock.styled';
import { TCardInfoBlockProps } from './CardInfoBlock.types';

export const CardInfoBlock: FC<TCardInfoBlockProps> = ({
  hint,
  title,
  icon,
  className,
  disabled,
}) => {
  const { cardsLoading: loading } = useContext(CardSettingsContext);

  return (
    <StyledCardInfoBlockWrapper
      className={cn('CardInfoBlock', className)}
      disabled={disabled}
      loading={loading}
    >
      <StyledCardInfoBlockIconWrapper className="CardInfoBlockIconWrapper">
        {icon}
      </StyledCardInfoBlockIconWrapper>
      <StyledCardInfoBlockTextWrapper className="CardInfoBlockTextWrapper">
        <StyledCardInfoBlockHeader className="CardInfoBlockHeader">
          {title}
        </StyledCardInfoBlockHeader>
        <StyledCardInfoBlockText className="CardInfoBlockText">
          {hint}
        </StyledCardInfoBlockText>
      </StyledCardInfoBlockTextWrapper>
    </StyledCardInfoBlockWrapper>
  );
};
