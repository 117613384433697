import { useContext } from 'react';
import { Trans } from 'react-i18next';

import { Text } from 'components/Text';
import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';
import { PATHS } from 'router';

import { StyledHeaderContainer, StyledHeaderLink } from './Header.styled';
import { THeaderProps } from './Header.types';

export const Header = ({ count }: THeaderProps) => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const helpCenterLink = branding?.help_center || PATHS.ROOT + PATHS.HELP;

  return (
    <StyledHeaderContainer className="HeaderContainer">
      <Text color="secondary">
        <Trans
          t={t}
          i18nKey="layout__settings__api__limit_message"
          components={{
            a: <StyledHeaderLink color="transparent" href={helpCenterLink} />,
          }}
          values={{ COUNT: String(count) }}
        />
      </Text>
    </StyledHeaderContainer>
  );
};
