import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';

import { RESEND_CODE_TIME } from 'constants/RESEND_CODE_TIME';
import { TOOMANY_ERROR } from 'constants/api';
import { TranslationContext } from 'contexts/TranslationContext';
import { isCodeValid } from 'helpers/string';
import { useTimer } from 'hooks/useTimer';
import { loginService } from 'services/login';
import { SigninStage } from 'services/login/login.types';
import { mfaService } from 'services/mfa';

import { saveLoginMetric } from '../../../../../../helpers';

export const useSigninByCode = (
  codeLength: number,
  key: string,
  tokenid: number,
  onDone: (url?: string) => void,
) => {
  const timer = useTimer(RESEND_CODE_TIME, { startOnInit: true });
  const { t, currentLanguage } = useContext(TranslationContext);

  const [state, setState] = useState({
    code: '',
    isLoadingResend: false,
    isLoadingVerify: false,
    errorResend: '',
    errorVerify: '',
  });

  const handleCodeChange = (code: string) => {
    if (isCodeValid(code, codeLength)) {
      setState((prev) => ({ ...prev, code }));
    }
  };

  const handleResend = async () => {
    timer.start();

    setState((prev) => ({
      ...prev,
      code: '',
      isLoadingResend: true,
      errorResend: '',
      errorVerify: '',
    }));

    try {
      await mfaService.resendByKey(key, tokenid);
      setState((prev) => ({ ...prev, isLoadingResend: false }));
    } catch (error) {
      const { response } = error as AxiosError;

      let payload =
        response?.data.detail ||
        t('layout__actions__unknown_error_please_refresh_page');

      if (response?.status === TOOMANY_ERROR) {
        payload = t('layout__settings__mfa__resend_429');
      }

      setState((prev) => ({
        ...prev,
        isLoadingResend: false,
        errorResend: payload,
      }));
    }
  };

  const handleVerify = async () => {
    saveLoginMetric();

    setState((prev) => ({
      ...prev,
      isLoadingVerify: true,
      errorResend: '',
      errorVerify: '',
    }));

    const response = await loginService.signin({
      lang: currentLanguage,
      code: state.code,
      key,
    });

    if (response?.errors) {
      const errorVerify = response.errors.non_field_errors?.[0];

      setState((prev) => ({
        ...prev,
        isLoadingVerify: false,
        errorVerify,
      }));

      return;
    }

    if (!response?.data) {
      setState((prev) => ({ ...prev, isLoadingVerify: false }));
      return;
    }

    setState((prev) => ({ ...prev, isLoadingVerify: false }));

    if (response?.data.stage === SigninStage.Done) {
      onDone(response.data?.url);
    }
  };

  useEffect(() => {
    if (!state.isLoadingVerify && state.code.length === codeLength) {
      handleVerify();
    }
  }, [state.code]);

  return {
    ...state,
    seconds: timer.time,
    handleCodeChange,
    handleResend,
    handleVerify,
  };
};
