import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { usePollingEffect } from 'hooks/usePollingEffect';
import { reportsService } from 'services/reports';
import {
  TaxResidence,
  TReport,
  TReportsStateResponse,
} from 'services/reports/reports.types';
import { TChildren } from 'types/TChildren';

import {
  initialState,
  TAX_REPORTS_ITEM_LIMIT,
  TAX_REPORTS_REFRESH_RATE_MILLISECONDS,
} from './TaxReportsContext.consts';
import {
  mergeTaxReportsState,
  overlapReportsUnfinished,
} from './TaxReportsContext.helpers';
import { TTaxReportsState } from './TaxReportsContext.types';

export const TaxReportsContext = createContext<TTaxReportsState>(initialState);

export const TaxReportsProvider: FC<TChildren> = ({
  children,
}): ReactElement | null => {
  const { currentLanguage } = useContext(TranslationContext);

  const {
    data: stateDataRaw,
    fetchData: fetchStateData,
    isLoading,
  } = useData<TReportsStateResponse | null>({
    onFetch: async () => {
      return reportsService.getReportsState(currentLanguage);
    },
  });

  const isRus = stateDataRaw?.tax_residence === TaxResidence.Russia;

  const {
    data: isTermsAccepted,
    fetchData: acceptTerms,
    isLoading: isLoadingAcceptTerms,
  } = useData<boolean>({
    loading: false,
    onFetch: async () => {
      if (isRus) {
        const response = await reportsService.acceptRusTerms(currentLanguage);
        return response?.rus_terms_accepted || null;
      }
      const response = await reportsService.acceptPolTerms(currentLanguage);
      return response?.pol_terms_accepted || null;
    },
  });

  const {
    data: reports,
    fetchData: updateReports,
    isLoading: isLoadingReports,
  } = useData<TReport[]>({
    onFetch: async () => {
      const result = await reportsService.getReports({
        lang: currentLanguage,
        type: 'tax',
        page: 1,
        pageSize: TAX_REPORTS_ITEM_LIMIT,
      });

      return result?.results || null;
    },
  });

  const unfinishedIds = useMemo(
    () =>
      reports?.filter((report) => !report.is_done).map((report) => report.id),
    [reports],
  );

  const { data: reportsUnfinished, fetchData: updateUnfinished } = useData<
    TReport[]
  >({
    onFetch: async () => {
      if (!unfinishedIds?.length || isLoadingReports) {
        return null;
      }

      const result = await reportsService.getReports({
        lang: currentLanguage,
        id: unfinishedIds,
      });

      return result?.results || null;
    },
  });

  const [yearsRequested, setYearsRequested] = useState<number[]>([]);

  const stateData = useMemo(
    () => mergeTaxReportsState(stateDataRaw, isTermsAccepted, yearsRequested),
    [stateDataRaw, isTermsAccepted, yearsRequested],
  );

  const reportsSafe = useMemo(
    () => overlapReportsUnfinished(reports, reportsUnfinished) || [],
    [reports, reportsUnfinished],
  );

  usePollingEffect(updateUnfinished, [unfinishedIds, isLoadingReports], {
    interval: TAX_REPORTS_REFRESH_RATE_MILLISECONDS,
  });

  useEffect(() => {
    fetchStateData();
    updateReports();
  }, []);

  const value = useMemo<TTaxReportsState>(
    () => ({
      isLoading,
      stateData,
      acceptTerms,
      isLoadingAcceptTerms,
      reports: reportsSafe,
      isLoadingReports,
      updateReports,
      setYearsRequested,
    }),
    [
      isLoading,
      stateData,
      acceptTerms,
      isLoadingAcceptTerms,
      reportsSafe,
      isLoadingReports,
      updateReports,
      setYearsRequested,
    ],
  );

  return (
    <TaxReportsContext.Provider value={value}>
      {children}
    </TaxReportsContext.Provider>
  );
};
