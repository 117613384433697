export const getFloatingMenuPositioningHack = (
  selectRef: React.RefObject<HTMLDivElement>,
) => {
  return () =>
    ({
      nodeType: 1,
      getBoundingClientRect: () => {
        const top = selectRef?.current?.offsetTop || 0;
        const left = selectRef?.current?.offsetLeft || 0;
        const width = selectRef?.current?.offsetWidth || 0;
        const height = selectRef?.current?.offsetHeight || 0;
        const right = left + width;
        const bottom = top + height;

        return { top, left, right, bottom, width, height };
      },
    }) as unknown as Element;
};
