import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { BrandingContext } from 'contexts';
import { PATHS } from 'router';
import { useAppSelector } from 'store/hooks';
import { selectMenuItems } from 'store/menu';

export const Redirect = () => {
  const navigate = useNavigate();

  const { branding } = useContext(BrandingContext);

  const menuItems = useAppSelector(selectMenuItems);

  const defaultRedirectUrl = useMemo(
    () =>
      menuItems.find(
        ({ url }) => url && url === branding?.branding?.default_redirect_url,
      )?.url || null,
    [menuItems, branding],
  );
  const redirectUrl = defaultRedirectUrl || PATHS.ROOT + PATHS.ACCOUNT_SUMMARY;

  useEffect(() => {
    navigate(redirectUrl);
  }, [redirectUrl]);

  return null;
};
