import { Button, css } from '@mui/material';
import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledLangSelectorCurrentButtonProps } from './LangSelector.types';

export const StyledLangSelectorWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledLangSelectorList = styled('ul')`
  position: absolute;
  top: 100%;
  right: 15px;
  z-index: 10;

  margin: 0;
  padding: 0;

  list-style: none;
  background: ${({ theme }) => theme.color.bg.primary};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.effect.controls.drop};
`;

export const StyledLangSelectorCurrentButton = styled(Button, {
  shouldForwardProp: blockNonNativeProps(['desktopOnly']),
})<TStyledLangSelectorCurrentButtonProps>`
  display: flex;
  min-width: 0 !important;
  padding: 0 !important;
  font-size: 15px;

  & .xnt-icon {
    width: 24px;
    height: 24px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      ${({ desktopOnly }) =>
        !desktopOnly &&
        css`
          position: absolute;
          top: 0;
          left: 2px;

          width: 20px;
          height: 20px;

          pointer-events: none;
        `}
    }

    &:hover {
      background: none;

      & .xnt-icon {
        transform: scale(1.2);
        transition: 0.3s ease;
      }
    }

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
`;

export const StyledLangSelectorCurrentLangName = styled('span')`
  margin-left: 8px;
  vertical-align: middle;
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledLangSelectorSelectButton = styled(Button)`
  width: 100%;

  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;

  padding: 8px;
  gap: 5px;

  :hover {
    background: none;

    & .xnt-icon {
      transform: scale(1.2);
      transition: 0.3s ease;
    }
  }

  & .xnt-icon {
    height: 16px;
    width: 18px;
    flex: 0 0 18px;
  }
`;

export const StyledLangSelectorSelect = styled('select')`
  width: 100%;
  padding: 0 2.5em 0 37px;
  height: 24px;

  color: ${({ theme }) => theme.color.typo.action};
  font-weight: 500;
  font-size: 15px;
  font-family: ${({ theme }) => theme.font.main};

  border: 0;
  background: none;

  appearance: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
  }

  &::-ms-expand {
    display: none;
  }
`;

export const StyledLangSelectorListItem = styled('li')`
  width: 100%;
`;

export const StyledLangSelectorFlagIconWraper = styled('span')`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 18px;
    height: 16px;
  }
`;

export const StyledLangSelectorLanguageName = styled('span')`
  vertical-align: middle;

  color: ${({ theme }) => theme.color.typo.promo};
  text-decoration: none;
  font-size: 13px;
  line-height: 16px;
  text-transform: none;
`;
