import {
  createContext,
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { useLogHandleTime } from 'hooks/useLogHandleTime';
import { depositService, TDepositResponse } from 'services/deposit';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TChildren } from 'types/TChildren';

import { CURRENCIES_EMPTY, initialState } from './DepositContext.consts';
import { useDepositAccountChoices } from './DepositContext.hooks';
import { TDepositState } from './DepositContext.types';

export const DepositContext = createContext<TDepositState>(initialState);

export const DepositProvider: FC<TChildren> = ({
  children,
}): ReactElement | null => {
  const { choices, setAccountChoices } = useDepositAccountChoices();

  const { selectedAccountId, isAccountsFirstLoading } =
    useAppSelector(selectAccounts);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('deposit-layout');
  const abortControllerDeposit = useRef(new AbortController());

  const {
    isLoading: isDepositLoading,
    data: depositData,
    fetchData: fetchDeposit,
  } = useData<TDepositResponse | null>({
    onFetch: async () => {
      abortControllerDeposit.current.abort();
      abortControllerDeposit.current = new AbortController();

      setStartHandleTime();
      const response = await depositService.getDeposit({
        accountId: selectedAccountId,
        options: {
          signal: abortControllerDeposit.current.signal,
        },
      });
      logHandleTime();
      return response;
    },
    onlyLastRequest: true,
  });

  useEffect(() => {
    fetchDeposit();

    return () => {
      abortControllerDeposit.current.abort();
    };
  }, [selectedAccountId]);

  const isLoading = isDepositLoading || isAccountsFirstLoading;

  const accountChoices =
    (selectedAccountId && choices?.[selectedAccountId]) || {};

  // list of banks for selected account
  const banks = useMemo(
    () =>
      selectedAccountId
        ? depositData?.deposit?.legal?.accountFilteredBanks?.[
            selectedAccountId
          ] || []
        : [],
    [depositData?.deposit, selectedAccountId],
  );

  const bankAliasSafe = accountChoices.bank || banks?.[0]?.alias || null;
  const selectedBank = useMemo(() => {
    const res = banks.find((bank) => bank.alias === bankAliasSafe);

    if (!res) {
      setAccountChoices(
        selectedAccountId,
        banks[0]?.alias,
        banks[0]?.currency ? Object(banks[0]?.currency || {}).keys()[0] : null,
      );

      return banks[0];
    }

    return res;
  }, [banks, bankAliasSafe]);

  const currencies = useMemo(
    () =>
      selectedBank
        ? {
            list: Object.entries(selectedBank.currency).map(([code, ccy]) => ({
              ...ccy,
              code,
            })),
            icons: selectedBank.currencyIcons?.icons || {},
          }
        : CURRENCIES_EMPTY,
    [selectedBank],
  );
  const currencyExisting = currencies?.list?.find(
    (ccy) => ccy.code === accountChoices.currency,
  );
  const currencyCodeSafe =
    currencyExisting?.code || currencies?.list?.[0]?.code;

  const deposit = depositData?.deposit?.deposit || null;

  const setBankAlias = (bankAlias: string) => {
    setAccountChoices(selectedAccountId, bankAlias, currencyCodeSafe);
  };

  const setCurrencyCode = (currencyCode: string) => {
    setAccountChoices(selectedAccountId, bankAliasSafe, currencyCode);
  };

  const value = useMemo<TDepositState>(
    () => ({
      isLoading,
      currency: currencyCodeSafe,
      setCurrency: setCurrencyCode,
      banks,
      currencies,
      bank: bankAliasSafe,
      setBank: setBankAlias,
      deposit,
    }),
    [
      isLoading,
      currencies,
      currencyCodeSafe,
      setCurrencyCode,
      banks,
      bankAliasSafe,
      setBankAlias,
      deposit,
    ],
  );

  return (
    <DepositContext.Provider value={value}>{children}</DepositContext.Provider>
  );
};
