import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledAccountsTableSkeletonContainer,
  StyledAccountsTableSkeletonRow,
  StyledAccountsTableSkeletonSubRow,
} from './AccountsTableSkeleton.styled';

export const AccountsTableSkeleton: FC = () => {
  return (
    <>
      {[...Array(2)].map((__, idx) => (
        <StyledAccountsTableSkeletonContainer key={idx}>
          <Skeleton width={64} height={24} />
          {[...Array(2)].map((_, i) => (
            <StyledAccountsTableSkeletonRow key={i}>
              <Skeleton width={39} height={24} />
              <Skeleton width="100%" height={16} />
              <Skeleton width={21} height={16} variant="circular" />
            </StyledAccountsTableSkeletonRow>
          ))}
          <Skeleton width="100%" height={48} />
          <StyledAccountsTableSkeletonSubRow>
            <Skeleton width={56} height={24} />
            <Skeleton width={56} height={24} />
          </StyledAccountsTableSkeletonSubRow>
        </StyledAccountsTableSkeletonContainer>
      ))}
    </>
  );
};
