import { useMediaQuery } from '@mui/material';
import cn from 'classnames';
import { FC, useContext, useMemo } from 'react';

import { BrandingContext, TranslationContext } from 'contexts';
import {
  TAX_REPORT_TYPE_BY_RESIDENCE,
  TAX_REPORTS_TABLE_ID,
} from 'pages/Reports/tabs/TaxReports/TaxReports.consts';
import { TaxResidence, TReport } from 'services/reports/reports.types';
import { useTheme } from 'theme';

import {
  isReportErrored,
  isReportPriceReady,
  isReportProcessing,
  isReportSent,
} from '../../TaxReports.helpers';
import { TaxReportsContext } from '../../contexts/TaxReportsContext';

import { StyledTaxReportsTable } from './TaxReportsTable.styled';
import { getColumns, TABLE_SORT_BY } from './columns';
import { TaxReportUnionSkeleton } from './columns/components/TaxReportUnion/components/TaxReportUnionSkeleton';

export const TaxReportsTable: FC = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const {
    isLoading: isLoadingState,
    stateData,
    reports,
    isLoadingReports,
  } = useContext(TaxReportsContext);

  const theme = useTheme();

  const taxResidence = stateData?.taxResidence;
  const reportTypeTitle = taxResidence
    ? TAX_REPORT_TYPE_BY_RESIDENCE[taxResidence]
    : '';

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const columns = useMemo(
    () =>
      getColumns({
        t,
        reportTypeTitle,
        supportEmail: branding?.wl_emails_support || '',
        taxResidence: taxResidence || TaxResidence.Russia,
        isMobile,
      }),
    [reportTypeTitle, isMobile],
  );

  const isLoading = isLoadingReports || isLoadingState || isTranslationsLoading;

  if (isMobile && isLoading) {
    return <TaxReportUnionSkeleton title={reportTypeTitle} items={2} />;
  }

  return (
    <StyledTaxReportsTable<TReport>
      className="TaxReportsTable"
      data={reports}
      isLoading={isLoading}
      skeletonsCount={5}
      tableId={TAX_REPORTS_TABLE_ID}
      columns={columns}
      noHeader={isLoading}
      translator={t}
      locale={currentLanguage}
      isFlexLayout
      disableSortBy
      manualSortBy={false}
      defaultSortBy={TABLE_SORT_BY}
      getRowProps={({ original: report }) => ({
        className: cn({
          Row: true,
          ...(!isReportSent(report) && {
            RowSuccess: isReportPriceReady(report),
            RowWarning: isReportProcessing(report),
            RowError: isReportErrored(report),
          }),
        }),
      })}
    />
  );
};
