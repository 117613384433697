import { NODE_BACK_API_URL } from 'constants/endpoints';
import { ApiRequest } from 'helpers/apiRequest';

import { TLogHandleTimeRequest } from './influxProxy.types';

class InfluxProxyService extends ApiRequest {
  catchUnauthorized = false;

  async postLogHandleTime(
    data: TLogHandleTimeRequest,
  ): Promise<Record<any, any> | null | boolean> {
    if (!NODE_BACK_API_URL) {
      return true;
    }

    try {
      await this.fetch<Record<any, any>>({
        method: 'POST',
        url: `${NODE_BACK_API_URL}/api/inner-metrics-proxy/log-handle-time/`,
        data,
      });
      return true;
    } catch (error) {
      return false;
    }
  }
}

export const influxProxyService = new InfluxProxyService();
