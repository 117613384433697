import { useContext, useEffect, useState } from 'react';
import { useData } from 'react-ui-kit-exante';

import { BrandingContext, TranslationContext } from 'contexts';
import {
  cardService,
  TCardsInfo,
  TGetCardsInfoProps,
  TPollOrderedCardProps,
} from 'services/cards';
import { serviceFeesService, TServiceFeesResponse } from 'services/serviceFees';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { TUseCardsValue } from './useCards.types';

export const useCards = (): TUseCardsValue => {
  const { currentLanguage } = useContext(TranslationContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const { branding } = useContext(BrandingContext);
  const [cardsInfo, setCardsInfo] = useState<TCardsInfo | null>(null);
  const [cardsFees, setCardsFees] = useState<TServiceFeesResponse | null>(null);
  const [abortPolling, setAbortPolling] = useState<() => void>(() => {});
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const fetchCardsInfoParams: TGetCardsInfoProps = {
    lang: currentLanguage,
    accountId: selectedAccountId,
  };

  const { isLoading: cardsInfoLoading, fetchData: fetchCardsInfo } = useData({
    onFetch: () => cardService.getCardsInfo(fetchCardsInfoParams),
    loading: false,
    onSuccess: setCardsInfo,
  });

  const { isLoading: cardsFeeLoading, fetchData: fetchCardsFees } = useData({
    onFetch: () => serviceFeesService.getServiceFees(selectedAccountId),
    loading: false,
    onSuccess: setCardsFees,
  });

  const pollCards = async (
    timeoutSeconds: TPollOrderedCardProps['timeoutSeconds'],
  ): Promise<void> => {
    const previousQuantity = cardsInfo?.cards.length || 0;
    setIsPolling(true);

    const polling = cardService.pollOrderedCard({
      ...fetchCardsInfoParams,
      timeoutSeconds,
      previousQuantity,
    });
    setAbortPolling(() => polling.abort);
    const response = await polling.response;
    setIsPolling(false);

    setCardsInfo(response || cardsInfo);
  };

  useEffect(() => {
    fetchCardsInfo();
  }, [selectedAccountId]);

  useEffect(() => {
    if (branding?.branding?.service_fees_card_enabled) {
      fetchCardsFees();
    }
  }, [selectedAccountId]);

  useEffect(() => {
    return abortPolling;
  }, []);

  return {
    cardsLoading: cardsInfoLoading || isPolling || cardsFeeLoading,
    cardsInfo,
    cardsFees,
    updateCards: fetchCardsInfo,
    pollCards,
    abortPolling,
  };
};
