import { HTTP_PROTOCOL_REGEXP } from 'constants/REGEXP';
import { BASE_URL } from 'constants/endpoints';
import { trimUrlSlashes } from 'helpers/trimUrlSlashes';

export const getAbsoluteUrl = (url: string) => {
  if (HTTP_PROTOCOL_REGEXP.test(url)) {
    return url;
  }

  return `${BASE_URL}/${trimUrlSlashes(url)}`;
};
