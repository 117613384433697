import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts';
import { AccountDataContext } from 'contexts/AccountDataContext/AccountDataContext';
import {
  CashConversionContext,
  DepositContext,
} from 'pages/Portfolio/tabs/Deposit/contexts';

import { StyledDepositHead } from '../../DepositWrapper/DepositWrapper.styled';

import { AccountCashSection } from './AccountCashSection';
import { AccountCashSkeleton } from './AccountCashSection/components/AccountCashSkeleton';
import { CashConversionSection } from './CashConversionSection';
import { InfoCashSection } from './InfoCashSection';
import { InfoCashSkeleton } from './InfoCashSection/components/InfoCashSkeleton/InfoCashSkeleton';
import { RequisitesCashSection } from './RequisitesCashSection';
import { RequisitesCashSkeleton } from './RequisitesCashSection/components/RequisitesCashSkeleton';

export const DepositCash: FC = () => {
  const { isTranslationsLoading } = useContext(TranslationContext);

  const { isLoading: isLoadingDeposit, deposit } = useContext(DepositContext);

  const {
    isLoading: isLoadingCashConversion,
    currencies,
    isAccountAllowed,
  } = useContext(CashConversionContext);

  const { isLoading: isAccountDataLoading } = useContext(AccountDataContext);

  const isDataLoading =
    isLoadingDeposit || isLoadingCashConversion || isAccountDataLoading;
  const isLoading = isDataLoading || isTranslationsLoading;

  const hasCashConversion =
    isAccountAllowed && !!currencies.length && !deposit?.isFundsReceived;

  if (isLoading) {
    return (
      <>
        <StyledDepositHead className="DepositHead">
          <AccountCashSkeleton />
          <RequisitesCashSkeleton />
        </StyledDepositHead>

        <InfoCashSkeleton />
      </>
    );
  }

  return (
    <>
      <StyledDepositHead className="DepositHead">
        {hasCashConversion && <CashConversionSection />}
        <AccountCashSection />
        <RequisitesCashSection />
      </StyledDepositHead>

      <InfoCashSection />
    </>
  );
};
