import { SyntheticEvent, useContext } from 'react';

import {
  TApplicationEnvironment,
  TDashboardApplication,
  TKeyScopeItem,
} from 'services/dashboard';

import { ApiAction, ApiContext } from '../../context';

export const useApi = () => {
  const { state, dispatch } = useContext(ApiContext);

  const handleAddApplication = (payload: TDashboardApplication) => {
    dispatch({ type: ApiAction.OnAddApplication, payload });
  };

  const handleUpdateApplication = (payload: TDashboardApplication) => {
    dispatch({ type: ApiAction.OnUpdateApplication, payload });
  };

  const handleSetTab = (_: SyntheticEvent<Element, Event>, tab: number) => {
    dispatch({
      type: ApiAction.OnSetTab,
      payload: tab as TApplicationEnvironment,
    });
  };

  const handleApplicationScopesChange = (scope: TKeyScopeItem, id?: string) => {
    if (id) {
      dispatch({
        type: ApiAction.OnChangeApplicationScopes,
        payload: { id, scope },
      });
    }
  };

  const handleApplicationDelete = (payload?: string) => {
    if (payload) {
      dispatch({ type: ApiAction.OnDeleteApplication, payload });
    }
  };

  return {
    ...state,
    handleAddApplication,
    handleSetTab,
    handleApplicationScopesChange,
    handleApplicationDelete,
    handleUpdateApplication,
  };
};
