import { FC, useContext } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import {
  StyledDialogTooltipActions,
  StyledDialogTooltipButton,
  StyledDialogTooltipButtonSecondary,
  StyledDialogTooltipHeading,
  StyledDialogTooltipSubheading,
} from 'components/DialogTooltip/DialogTooltip.styled';
import { TDialogTooltipProps } from 'components/DialogTooltip/DialogTooltip.types';
import { TranslationContext } from 'contexts';
import { DepositCryptoContext } from 'pages/Portfolio/tabs/Deposit/contexts';

// todo replace on ActionWithConfirmation component

export const DialogTooltip: FC<TDialogTooltipProps> = ({
  children,
  onAccept,
  onDecline,
  ...rest
}) => {
  const { t } = useContext(TranslationContext);
  const { currency } = useContext(DepositCryptoContext);

  const title = (
    <div>
      <StyledDialogTooltipHeading className="DialogTooltipHeading">
        {t('layout__deposit__requisites__crypto__renew__prompt', {
          CURRENCY: currency,
        })}
      </StyledDialogTooltipHeading>
      <StyledDialogTooltipSubheading className="DialogTooltipSubheading">
        {t('layout__deposit__requisites__crypto__renew__warning')}
      </StyledDialogTooltipSubheading>
      <StyledDialogTooltipActions className="DialogTooltipActions">
        <StyledDialogTooltipButton
          className="DialogTooltipButton"
          onClick={onAccept}
        >
          {t('layout__deposit__requisites__crypto__renew__generate')}
        </StyledDialogTooltipButton>
        <StyledDialogTooltipButtonSecondary
          className="DialogTooltipButtonSecondary"
          onClick={onDecline}
        >
          {t('layout__deposit__requisites__crypto__renew__cancel')}
        </StyledDialogTooltipButtonSecondary>
      </StyledDialogTooltipActions>
    </div>
  );

  return (
    <Tooltip {...rest} title={title}>
      {children}
    </Tooltip>
  );
};
