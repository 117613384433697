import { ApiRequest } from 'helpers/apiRequest';
import { getFormData } from 'helpers/formData';
import { notifyWith } from 'helpers/notifyWith';
import { delay } from 'helpers/timers';

import {
  TCheckPasswordResponse,
  TDisableBySessionResponse,
  TEnableServiceResponse,
  TEnableTotpResponse,
  TMfaService,
  TMfaType,
} from './mfa.types';

class MfaService extends ApiRequest {
  async checkCode(value: string, service: TMfaService) {
    const { data } = await this.fetch({
      method: 'POST',
      url: `/clientsarea/rest/mfa/check/`,
      ...getFormData([
        ['value', value],
        ['service', service],
      ]),
    });

    if (!data) {
      throw new Error('Code is not valid');
    }

    return data;
  }

  async checkPassword(password: string, service: TMfaService) {
    const { data } = await this.fetch<TCheckPasswordResponse>({
      method: 'POST',
      url: `/clientsarea/rest/mfa/disable/`,
      data: { password, service, login: '' },
    });

    return data;
  }

  async disableByKey(code: string, key: string, service: TMfaService) {
    const { data } = await this.fetch<TDisableBySessionResponse>({
      method: 'POST',
      url: `/clientsarea/rest/mfa/disable/`,
      data: { code, service, key },
    });

    return data;
  }

  async enableService(type: TMfaType, service: TMfaService) {
    try {
      const { data } = await this.fetch<TEnableServiceResponse>({
        method: 'POST',
        url: `/clientsarea/rest/mfa/${type}/enable/`,
        ...getFormData([['service', service]]),
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      throw error;
    }
  }

  async enableTotpService(code: string, service: TMfaService) {
    const { data } = await this.fetch<TEnableTotpResponse>({
      method: 'POST',
      url: `/clientsarea/rest/mfa/totp/enable/`,
      ...getFormData([
        ['service', service],
        ['value', code],
      ]),
    });

    return data;
  }

  async resendByType(type: TMfaType, service: TMfaService, delayTime = 0) {
    await delay(delayTime);
    await this.fetch({
      method: 'POST',
      url: `/clientsarea/rest/mfa/${type}/resend/`,
      ...getFormData([['service', service]]),
    });
  }

  async resendByKey(key: string, tokenid: number | null = null) {
    try {
      await this.fetch({
        method: 'POST',
        url: `/clientsarea/rest/mfa/resend/`,
        ...getFormData([
          ['key', key],
          ['tokenid', tokenid],
        ]),
      });
    } catch (error) {
      notifyWith.genericNetworkError(error);
    }
  }

  async verificationResend(type: 'sms' | 'email' | 'totp' = 'sms') {
    try {
      await this.fetch({
        method: 'POST',
        url: `/clientsarea/rest/mfa/${type}/verification-resend/`,
      });
    } catch (error) {
      notifyWith.genericNetworkError(error);
    }
  }

  async fetchToken(
    code: string,
    type: 'sms' | 'email' | 'totp' = 'sms',
  ): Promise<string> {
    const { data } = await this.fetch<string>({
      method: 'POST',
      url: `/clientsarea/rest/mfa/token/`,
      data: {
        value: code,
        type,
      },
    });

    return data;
  }
}

export const mfaService = new MfaService();
