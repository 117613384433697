import { FieldArrayWithId } from 'react-hook-form';

import { Dates } from 'helpers/dates';
import {
  TReportItem,
  TReportsRefsResponse,
} from 'services/reports/reports.types';

import { REPORT_TYPES_TERMS } from '../../ReportEditForm.constants';

import {
  TCustomReportForm,
  TReportFormItem,
  TReportFormItemEssentials,
  TReportFormItemEssentialsStrict,
} from './ReportFormContext.types';

export const isFormatSupported = (
  type: string | undefined,
  format: string[],
) => {
  // as the default, undefined type is allowed by any format
  if (!type || !format) {
    return true;
  }

  if (format.includes('XLS') && !REPORT_TYPES_TERMS.includes(type)) {
    return false;
  }

  if (
    (format.includes('CSV') || format.includes('PDF')) &&
    REPORT_TYPES_TERMS.includes(type)
  ) {
    return false;
  }

  return true;
};

/**
 * Filters out items with non-filled date fields
 * possibly generated by `generateFillers()`
 * Filters out items not supported by given formats
 *
 * @param reportItems items to filter
 * @param format formats to filter non-compatible items
 */
export function filterEmptyReportFormItems<T>(
  reportItems: (T & TReportFormItemEssentials)[],
  format: string[],
): (T & TReportFormItemEssentialsStrict)[] {
  return reportItems.filter(
    (item): item is T & TReportFormItemEssentialsStrict =>
      isFormatSupported(item.report_type, format) &&
      item.date_from != null &&
      item.date_to != null,
  );
}

/**
 * Converts all form date values to strings
 * Filters out items not supported by given formats
 *
 * @param reportItems original items acquired by the form
 * @param format list of formats acquired by the form
 */
export const formatReportFormItems = (
  reportItems: TReportFormItem[],
  format: string[],
) => {
  return filterEmptyReportFormItems(reportItems, format).map((item) => ({
    ...item,
    date_from: Dates.format(item.date_from || null, true),
    date_to: Dates.format(item.date_to || null, true),
  }));
};

/**
 * Generates an array of empty form items for each report type
 * to display the initial form with every report category containing
 * at least one item.
 * If there are no report items of some type, the filler is generated.
 * Otherwise, no filler item is required.
 *
 * @param types all report types available to set in the form
 * @param items existing report items in the form
 */
export const generateFillers = (
  types: TReportsRefsResponse['types'],
  items: TReportFormItem[],
) => {
  return Object.keys(types).reduce<TReportFormItem[]>(
    (acc, type) =>
      items.some(({ report_type }) => report_type === type)
        ? acc
        : [
            ...acc,
            {
              report_type: type,
              date_from: undefined,
              date_to: undefined,
              trades_grouping: '',
            },
          ],
    [],
  );
};

/**
 * Applies remote updates to the local items and adds fillers
 * to keep each report type containing at least one item
 *
 * Ensures correct date format in items
 *
 * @param reportItems remote items (already saved)
 * @param fields new items (now editing in the form)
 * @param deletedIds ids of deleted remote items
 * @param types all report types available in the form
 */
export const getFieldsUpdated = (
  reportItems: TReportItem[],
  fields: FieldArrayWithId<TCustomReportForm, 'items', 'formId'>[],
  deletedIds: number[],
  types: TReportsRefsResponse['types'],
) => {
  const remoteItems = reportItems
    .filter((field) => field?.id && !deletedIds.includes(field.id))
    .map((item) => ({
      ...item,
      date_from: item.date_from ? new Date(item.date_from) : undefined,
      date_to: item.date_to ? new Date(item.date_to) : undefined,
    }));

  const localItems = fields
    .filter((field) => field.id === undefined)
    .map((item) => ({
      ...item,
      date_from: item.date_from && new Date(item.date_from),
      date_to: item.date_to && new Date(item.date_to),
    }));

  const fillers = generateFillers(types, [...remoteItems, ...localItems]);

  return [...remoteItems, ...fillers, ...localItems];
};
