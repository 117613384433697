import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TReport } from 'services/reports/reports.types';

import { StyledMobileCell } from '../../../../../Reports.styled';
import { ReportAccounts } from '../../../../../components/ReportAccounts';
import { ReportNames } from '../../../../../components/ReportNames';
import { ReportPeriods } from '../../../../../components/ReportPeriods';

import { TCustomReportsGetColumnsParams } from './columns.types';
import { ReportActions } from './components/ReportActions';
import { ReportFormatCheck } from './components/ReportFormatCheck';
import { ReportUnion } from './components/ReportUnion';

export const TABLE_SORT_BY = [{ id: 'updated', desc: false }];

export const getColumns: (
  params: TCustomReportsGetColumnsParams,
) => IColumn<TReport>[] = ({ t, isMobile }) =>
  isMobile
    ? [
        {
          id: 'id',
          accessor: 'id',
          tooltip: 'off',
          Cell: ({ row: { original } }: ICellValue<TReport>) => {
            return (
              <StyledMobileCell className="MobileCell">
                <ReportUnion report={original} />
              </StyledMobileCell>
            );
          },
        },
      ]
    : [
        // desktop only
        {
          id: 'accounts',
          accessor: 'accounts',
          Header: t('generic__account'),
          tooltip: 'off',
          Cell: ({ row: { original } }: ICellValue<TReport>) => {
            return <ReportAccounts report={original} />;
          },
        },
        {
          id: 'name',
          accessor: 'name',
          Header: t('layout__reports__table__name'),
          tooltip: 'off',
          Cell: ({ row: { original } }: ICellValue<TReport>) => {
            return <ReportNames report={original} />;
          },
        },
        {
          id: 'period',
          accessor: 'period',
          Header: t('generic__period'),
          Cell: ({ row: { original } }: ICellValue<TReport>) => {
            return <ReportPeriods report={original} />;
          },
        },
        {
          id: 'csv',
          accessor: 'csv',
          width: 100,
          Header: t('generic__csv'),
          align: 'center',
          Cell: ({ row: { original } }: ICellValue<TReport>) => {
            return <ReportFormatCheck report={original} format="CSV" />;
          },
        },
        {
          id: 'pdf',
          accessor: 'pdf',
          width: 100,
          Header: t('generic__pdf'),
          align: 'center',
          Cell: ({ row: { original } }: ICellValue<TReport>) => {
            return <ReportFormatCheck report={original} format="PDF" />;
          },
        },
        {
          id: 'xls',
          accessor: 'xls',
          width: 100,
          Header: t('generic__xls'),
          align: 'center',
          Cell: ({ row: { original } }: ICellValue<TReport>) => {
            return <ReportFormatCheck report={original} format="XLS" />;
          },
        },
        {
          id: 'action',
          accessor: 'last_status',
          Header: t('generic__action'),
          align: 'right',
          tooltip: 'off',
          Cell: ({ row: { original } }: ICellValue<TReport>) => {
            return <ReportActions report={original} />;
          },
        },
      ];
