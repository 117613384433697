import { TOptions } from '../instrumentsTree/instrumentsTree.types';

export enum TaxResidence {
  Russia = 'Russia',
  Poland = 'Poland',
}

export type TReportsSettings = {
  accounts: string[];
  currency: string;
  ftp: string;
  language: string;
};

export type TRequestRusReportParams = {
  lang: string;
  year: string;
};

export type TRequestPolReportParams = {
  year: string;
  account: string;
};

export type TRemoveRusReportParams = {
  lang: string;
  id: string;
  year: string;
};

export type TAcceptRusPriceParams = {
  lang: string;
  reportId: string;
  account: string;
};

export type TCheckPolFundsParams = {
  lang: string;
  account: string;
  price: string;
};

export type TRequestReportResponse = {
  success: boolean;
  message: string;
  item: TReport;
};

export type TAcceptRusTermsResponse = {
  success: boolean;
  message: string;
  rus_terms_accepted: boolean;
};

export type TAcceptRusPriceResponse = {
  success: boolean;
  message: string;
  rus_price_accepted: boolean;
};

export type TAcceptPolTermsResponse = {
  success: boolean;
  message: string;
  pol_terms_accepted: boolean;
};

export type TAcceptPolTimingsResponse = {
  success: boolean;
  message: string;
  pol_timings_accepted: boolean;
};

export type TCheckPolFundsResponse = {
  success: boolean;
  message: string;
};

export type TReportsStateResponse = {
  pol_terms: string | null;
  rus_terms: string | null;
  rus_terms_accepted: boolean;
  rus_price_accepted: boolean;
  ownAccounts: string[];
  pol_terms_accepted: boolean;
  pol_report_price: number;
  pol_timings_accepted: boolean;
  tax_residence: TaxResidence;
  taxation_country: string;
  taxation_country_code: string;
  taxation_enabled: boolean;
  taxation_years: number[];
  requested_tax_years: number[];
};

export type TReport = {
  id: number;
  name: string;
  name_or_default: string;
  build: string;
  build_name: string;
  currency: string;
  tndfl_account?: string;
  tndfl_price?: string;
  tndfl_status?: TndflStatus | null;
  accounts: string[];
  format: string[];
  items: TReportItem[];
  files: TReportFile[];
  send_url: string;
  export_url: string;
  delete_url: string;
  is_done: string;
  last_status: ReportStatus;
  last_status_text: string;
  last_summary_text: string;
  tax_year: string;
  created: string;
  updated: string;
};

export type TGetReportsParams = {
  lang: string;
  page?: number;
  pageSize?: number;
  type?: string;
  id?: number[];
};

export type TReportLanguage = {
  id: string;
  label: string;
};

export type TReportsRefsResponse = {
  accountsLimit: number;
  currencies: string[];
  accounts: string[];
  languages: TReportLanguage[];
  currency: string;
  formats: string[];
  types: Partial<Record<ReportType | string, string>>;
  tradesGrouping: Record<string, string>;
};

export type TReportSendResponse = {
  success: boolean;
  message: string;
};

export type TReportItem = {
  id: number;
  title: string;
  report_type: ReportType | string;
  trades_grouping: string;
  date_from: string;
  date_to: string;
};

export type TReportPatch = Partial<
  Omit<TReport, 'items'> & {
    items: Partial<TReportItem>[];
  }
>;

export enum ReportType {
  Summary = 'summary',
  Margin = 'margin',
  Transactions = 'transactions',
  Trades = 'trades',
  Performance = 'performance',
  Costs = 'costs',
  Partner = 'partner',
  Tax = 'tndfl',
  SubuserWithdrawals = 'subuser withdrawals',
  Commissions = 'commissions',
  Overnights = 'overnights',
  ShortAllowance = 'short allowance',
  WLPCashBalance = 'wlp cash balance',
  DailyPosition = 'daily position',
}

export enum TndflStatus {
  DataReceived = 'data_received',
  PriceCalculated = 'price_calculated',
  PriceAccepted = 'price_accepted',
  FileGenerated = 'file_generated',
  ErrorsOccurred = 'errors_occurred',
  Error = 'error',
}

export enum ReportStatus {
  New = 'new',
  Processing = 'processing',
  NotAvailable = 'not_available',
  Loading = 'loading',
  Failed = 'failed',
  Error = 'error',
  Sent = 'sent',
}

export type TReportsAccounts = {
  count: number;
  items: TReportAccount[];
  more: boolean;
};

export type TReportAccount = {
  group: boolean;
  id: string;
  text: string;
};

export type TSubscription = {
  csv: boolean;
  daily: boolean;
  id: number;
  is_daily: boolean;
  is_monthly: boolean;
  monthly: boolean;
  name: string;
  pdf: boolean;
  title: string;
  xls: boolean;
};

export type TGetReportData = {
  accounts: string[];
  subscriptions: TSubscription[];
};

export type TGetReportResponse = {
  message: string;
  success: boolean;
};

export type TReportFile = {
  id: number;
  format: string;
  url: string;
};

export type TReportsResponse = {
  count: number;
  next?: number;
  results: TReport[];
  canceled?: boolean;
};

export type TReportsFilters = {
  page: number;
  page_size: number;
  reportTypes: string;
  date_from: string;
  date_to: string;
};

export type TReportsParams = TOptions &
  TReportsFilters & {
    lang?: string;
  };
