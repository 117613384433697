import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Notification as UIKitNotification,
  useData,
} from 'react-ui-kit-exante';

import { CurrencySelect } from 'components/CurrencySelect';
import { CurrencyContext, TranslationContext } from 'contexts';
import { accountSettingsService } from 'services/settings/accountSettings';

import { TAutoConversionSelectorProps } from './AutoConversionSelector.types';

export const AutoConversionSelector = ({
  autoConversionCurrency: externalCurrency,
  disabled = false,
  accountId,
  labelKey,
}: TAutoConversionSelectorProps) => {
  const { currentLanguage, t } = useContext(TranslationContext);
  const { currencies } = useContext(CurrencyContext);
  const [currency, setCurrency] = useState<string>(externalCurrency);

  const setCurrencyRepresented = useCallback((newCurrency: string) => {
    setCurrency(newCurrency);
  }, []);

  useEffect(() => {
    setCurrencyRepresented(externalCurrency);
  }, [externalCurrency]);

  const { fetchData: setAutoConversionCurrency, isLoading: savingChanges } =
    useData<string>({
      onFetch: async (selected) => {
        return accountSettingsService.setAutoConversionCurrency(
          accountId,
          selected,
          currentLanguage,
        );
      },
      onSuccess: (newCurrency) => {
        if (newCurrency !== null) {
          UIKitNotification.success({
            title: t('account_settings_success_notification'),
          });

          setCurrencyRepresented(newCurrency);
        }
      },
      loading: false,
    });

  return (
    <CurrencySelect
      options={currencies?.list || []}
      icons={currencies?.icons || {}}
      value={currency}
      labelKey={labelKey}
      onChange={setAutoConversionCurrency}
      disabled={disabled || savingChanges}
      withDisabled
    />
  );
};
