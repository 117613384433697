import { IconButton, InfoIcon } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const CreateTaxReportWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  width: 100%;

  padding: 24px;
`;

export const CreateTaxReportConfirmation = styled('div')`
  display: flex;
  gap: 8px;
  align-items: baseline;
`;

export const InfoIconStyled = styled(InfoIcon)`
  cursor: pointer;
`;

export const StyledCreateButton = styled(IconButton)`
  font-size: 15px;

  .IconButtonLabel {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    .IconButtonLabel {
      display: inline-block;
    }
  }
`;

export const StyledCreateConfirmationText = styled('p')`
  margin: 0;

  color: ${({ theme }) => theme?.color?.typo?.secondary};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledCreateConfirmationLink = styled('a')`
  color: ${({ theme }) => theme?.color?.typo?.action};
`;
