import { useContext } from 'react';
import { Input } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import { getTimerValue } from '../helpers';
import {
  StyledSharedMfaContainer,
  StyledSharedMfaFooterText,
  StyledSharedMfaInputWithFooterContainer,
  StyledSharedMfaResendButton,
  StyledSharedMfaTarget,
  StyledSharedMfaText,
} from '../styled';

import { TActionEmailViewProps } from './ActionEmailView.types';

export const ActionEmailView = ({
  code,
  disabled,
  email,
  message,
  seconds,
  onChange,
  onResend,
}: TActionEmailViewProps) => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledSharedMfaContainer className="ActionEmailViewContainer">
      <StyledSharedMfaText className="Text">
        {t('layout__security_tabs__mfa_modal_email_tooltip')}
      </StyledSharedMfaText>
      <StyledSharedMfaTarget className="Target">{email}</StyledSharedMfaTarget>
      <StyledSharedMfaText className="Text">
        {t('layout__security_tabs__mfa_modal_email_message')}
      </StyledSharedMfaText>
      <StyledSharedMfaInputWithFooterContainer className="InputContainer">
        <Input
          fullWidth
          error={Boolean(message)}
          message={message}
          disabled={disabled}
          placeholder={t('layout__security_tabs__mfa_modal_email_placeholder')}
          iconRight={
            <StyledSharedMfaResendButton
              className="ResendButton"
              type="button"
              disabled={seconds !== 0 || disabled}
              onClick={onResend}
            >
              {t('layout__security_tabs__mfa_label_resend')}
            </StyledSharedMfaResendButton>
          }
          value={code}
          onChange={(e) => onChange(e.target.value)}
        />
        <StyledSharedMfaFooterText
          visible={seconds !== 0}
          className="FooterText"
        >
          {getTimerValue(
            t('layout__settings__mfa__resend_after_seconds'),
            seconds,
          )}
        </StyledSharedMfaFooterText>
      </StyledSharedMfaInputWithFooterContainer>
    </StyledSharedMfaContainer>
  );
};
