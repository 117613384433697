import { ActionEmailView } from '../../../ActionEmailView';
import { ActionSmsView } from '../../../ActionSmsView';

import { TConfirmTotpProps } from './ConfirmTotp.types';
import { useConfirmTotp } from './useConfirmTotp';

export const ConfirmTotp = ({
  onSuccess,
  isSmsConfirmation,
}: TConfirmTotpProps) => {
  const {
    code,
    email,
    phoneNumber,
    countryCode,
    handleCodeChange,
    handleCodeResend,
    isVerifying,
    seconds,
    verifyErrorMessage,
  } = useConfirmTotp(onSuccess, isSmsConfirmation);

  if (isSmsConfirmation) {
    return (
      <ActionSmsView
        code={code}
        onChange={handleCodeChange}
        onResend={handleCodeResend}
        seconds={seconds}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
      />
    );
  }

  return (
    <ActionEmailView
      code={code}
      email={email}
      disabled={isVerifying}
      message={verifyErrorMessage}
      onChange={handleCodeChange}
      onResend={handleCodeResend}
      seconds={seconds}
    />
  );
};
