import { FC, useContext, useEffect } from 'react';
import {
  Notification as UiKitNotification,
  useData,
} from 'react-ui-kit-exante';

import { StyledPageWrapper } from 'components/Main';
import { TranslationContext } from 'contexts';
import { helpService } from 'services/help';

import {
  StyledHelpPanel,
  StyledHelpSection,
  StyledHelpWrapper,
} from './Help.styled';
import { HelpFAQ } from './components/HelpFAQ';
import { HelpFAQSkeleton } from './components/HelpFAQ/components/HelpFAQSkeleton';
import { HelpManuals } from './components/HelpManuals';
import { HelpManualsSkeleton } from './components/HelpManuals/components/HelpManualsSkeleton';

export const Help: FC = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const getHelpData = async () => {
    try {
      return {
        manuals: await helpService.getManuals(currentLanguage),
        faq: await helpService.getFAQ(),
      };
    } catch (error: any) {
      if (error?.message) {
        UiKitNotification.error({ title: error?.message });
      }

      return null;
    }
  };

  const {
    fetchData: fetchHelp,
    data: help,
    isLoading: isHelpLoading,
  } = useData({ onFetch: getHelpData });

  useEffect(() => {
    fetchHelp();
  }, [currentLanguage]);

  const isLoading = isHelpLoading || isTranslationsLoading;

  return (
    <StyledPageWrapper className="HelpPageWrapper">
      <StyledHelpPanel className="HelpPanel" title={t('Help', '')}>
        <StyledHelpWrapper className="HelpWrapper">
          <StyledHelpSection className="HelpSection">
            {isLoading ? (
              <HelpManualsSkeleton />
            ) : (
              <HelpManuals groups={help?.manuals?.help?.groups} />
            )}
          </StyledHelpSection>
          <StyledHelpSection className="HelpSection">
            {isLoading ? (
              <HelpFAQSkeleton />
            ) : (
              <HelpFAQ
                tabs={help?.faq?.tabs || []}
                fallbackUrl={help?.faq?.fallback_url}
              />
            )}
          </StyledHelpSection>
        </StyledHelpWrapper>
      </StyledHelpPanel>
    </StyledPageWrapper>
  );
};
