import { useMediaQuery } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import { useTheme } from 'theme';

import { PartnersContext } from '../../Partner.constants';
import { getPartnerShortUrl } from '../../Partner.helpers';

import { getDefaultLinkTextKeys } from './PartnerHeader.helpers';
import { TPartnerHeaderProps } from './PartnerHeader.types';
import { PartnerHeaderDesktop, PartnerHeaderMobile } from './components';

export const PartnerHeader: FC = () => {
  const { partnerData } = useContext(PartnersContext);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { default_link: rawDefaultLink } = partnerData || {};

  const info = partnerData?.additional;
  const hasInfo = Boolean(
    info?.type && info?.balance !== null && info?.paidOut !== null,
  );

  const defaultLinkTextKeys = useMemo(
    () => getDefaultLinkTextKeys(info?.type),
    [info?.type],
  );

  const defaultLink = useMemo(() => {
    const localDefaultLink = rawDefaultLink;

    const short = localDefaultLink ? getPartnerShortUrl(localDefaultLink) : '';

    if (!short.length) {
      return short;
    }

    return short.endsWith('/') ? short : `${short}/`;
  }, [rawDefaultLink]);

  const Layout: FC<TPartnerHeaderProps> = isDesktop
    ? PartnerHeaderDesktop
    : PartnerHeaderMobile;

  const balance = info?.balance || 0;
  const paidOut = info?.paidOut || 0;

  return (
    <Layout
      balance={balance}
      hasInfo={hasInfo}
      paidOut={paidOut}
      defaultLink={defaultLink}
      defaultLinkTextKeys={defaultLinkTextKeys}
    />
  );
};
