import { useCallback, useContext, useState } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';
import { isKitTableSorting } from 'helpers/typeguards';
import { assetManagementService } from 'services/assetManagement';

import {
  TFetchFeeSetupsFn,
  TUseFetchFeeSetupsReturn,
} from '../AssetManagementSetupTable.types';

export function useFetchFeeSetups(): TUseFetchFeeSetupsReturn {
  const [fetchFeeSetupsIsLoading, setFetchFeeSetupsIsLoading] = useState(false);
  const { currentLanguage } = useContext(TranslationContext);

  const fetchFeeSetups = useCallback<TFetchFeeSetupsFn>(async ({ params }) => {
    try {
      setFetchFeeSetupsIsLoading(true);

      const {
        limit,
        skip,
        page,
        sorting,
        status,
        setup_is_complete: isComplete,
      } = params;

      let ordering;
      const hasSorting = isKitTableSorting(sorting) && Boolean(sorting.length);
      if (hasSorting) {
        const [{ id, desc }] = sorting;
        ordering = `${desc ? '-' : ''}${id ?? ''}`;
      }

      const feeSetups = await assetManagementService.getListFeeSetups(
        {
          limit,
          offset: skip,
          page,
          ordering,
          status,
          setup_is_complete: Boolean(isComplete),
        },
        currentLanguage,
      );

      setFetchFeeSetupsIsLoading(false);

      if (!feeSetups) {
        return null;
      }

      const { results: data, count: total } = feeSetups;
      return {
        total,
        data,
      };
    } catch (error: any) {
      console.error(error);
    }
    return null;
  }, []);

  return { fetchFeeSetups, fetchFeeSetupsIsLoading };
}
