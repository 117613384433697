import { TFetchState, TUpdateState } from 'types/state';

export const initialFetchState: TFetchState = {
  error: false,
  errorMessage: '',
  idle: true,
  pending: false,
  succeed: false,
};

export const initialUpdateState: TUpdateState = {
  error: false,
  errorMessage: '',
  pending: false,
};
