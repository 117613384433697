import { Panel, Skeleton } from 'react-ui-kit-exante';

import { Logo } from 'components/Logo';
import { styled } from 'theme';

export const StyledLoginPanel = styled(Panel)`
  display: flex;
  padding: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 64px;
  }
`;

export const StyledLoginWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: center;
  }
`;

export const StyledLoginContent = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledLoginHeading = styled('h4')`
  margin: 0;

  text-align: center;

  font-family: ${({ theme }) => theme.font.header};
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
`;

export const StyledLoginHint = styled('p')`
  margin: 0;

  text-align: center;

  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
`;

export const StyledLoginLogo = styled(Logo)`
  width: 140px;
  margin: 0 auto;
`;

export const StyledLoginLogoSkeleton = styled(Skeleton)`
  margin: 0 auto;
`;

export const StyledCaptchaWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;

export const StyledCaptchaErrorWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledCaptchaErrorText = styled('span')`
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledCaptchaErrorAction = styled('span')`
  cursor: pointer;
  color: ${({ theme }) => theme.color.typo.action};

  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.typo.hover};
    text-decoration: underline;
  }
`;
