import { FC } from 'react';
import { Button } from 'react-ui-kit-exante';

import { TTooltipContentProps } from 'pages/CardSettings/components/CardSettingsCard/components/TooltipContent/TooltipContent.types';

import { getButtonColor } from './TooltipContent.helpers';
import {
  StyledTooltipContentButtonWrapper,
  StyledTooltipContentHeader,
  StyledTooltipContentWrapper,
} from './TooltipContent.styled';

export const TooltipContent: FC<TTooltipContentProps> = ({
  confirm,
  icon,
  title,
  cancel,
  children,
}) => (
  <StyledTooltipContentWrapper className="TooltipContentWrapper">
    <StyledTooltipContentHeader className="TooltipContentHeader">
      {icon}
      {title}
    </StyledTooltipContentHeader>
    {children}
    <StyledTooltipContentButtonWrapper className="TooltipContentButtonWrapper">
      {[confirm, cancel].map(
        (button, i) =>
          button &&
          !button.hidden && (
            <Button
              key={i}
              disabled={button.disabled}
              onClick={button.handler}
              color="transparent"
              textColor={getButtonColor(button.dangerStyle, button.actionStyle)}
            >
              {button.label}
            </Button>
          ),
      )}
    </StyledTooltipContentButtonWrapper>
  </StyledTooltipContentWrapper>
);
