import { BASE_URL } from 'constants/endpoints';
import { Dates } from 'helpers/dates';
import { TDownloadReportProps } from 'pages/Portfolio/tabs/PortfolioTotal/PortfolioTotal.types';
import {
  Position,
  positions,
  TAccountSummary,
  TPosition,
} from 'types/accounts';

export function getPositions(account: TAccountSummary | null): TPosition[] {
  if (!account) {
    return [];
  }

  const positionsArr: TPosition[][] = [];
  const positionKeys = Object.keys(account).filter((key) =>
    positions.find((keyPos) => key === keyPos),
  ) as Position[];

  positionKeys.forEach((key) => {
    const val = account[key];
    if (val.length) {
      positionsArr.push(val as TPosition[]);
    }
  });

  return positionsArr.flat();
}

export const downloadSummaryReport = ({
  selectedAccountId,
  selectedDate,
  currency,
}: TDownloadReportProps): void => {
  const date = Dates.format(selectedDate);

  if (!selectedAccountId || !currency || !date) {
    return;
  }

  const url = new URL('/clientsarea/account/summary/details/xls/', BASE_URL);
  const params = new URLSearchParams({
    account: selectedAccountId,
    currency,
    date,
  });

  window.open(`${url}?${params.toString()}`);
};
