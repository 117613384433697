import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPartnerPaymentsDetailsFormNotificationWrapper = styled(
  'div',
)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledPartnerPaymentsDetailsFormNotificationLoader = styled(
  Button,
)`
  margin-right: 8px;

  .loader > div {
    height: 16px;
    width: 16px;
    line-height: 16px;
  }
`;
