import { InfoIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledPersonalIban = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.color.dropdown.chip.bg};
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.primary};
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const StyledPersonalIbanTextBlock = styled('div')`
  display: inherit;
  gap: inherit;
  align-items: inherit;
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: row;
  }
`;

export const StyledPersonalIbanTextBlockAccount = styled('span')`
  cursor: pointer;
  color: ${({ theme }) => theme.color.typo.action};
`;

export const StyledPersonalIbanInfoIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.color.icon.secondary};
  width: 16px;
  height: 16px;
  align-self: flex-start;
  flex-basis: 16px;
  flex-shrink: 0;
`;
