import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledSourcesSkeletonContainer = styled(Panel)`
  .PanelTitle {
    padding: 24px;
    margin: 0;
  }

  padding: 0;
`;

export const StyledSourcesSkeletonHeader = styled('div')`
  display: flex;
  padding: 24px;
  gap: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
`;

export const StyledSourcesSkeletonHeaderItem = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledSourcesSkeletonSubHeader = styled('div')`
  display: flex;
  padding: 24px;
`;

export const StyledSourcesMdRow = styled('div')`
  display: grid;
  grid-template-columns: 192px 1fr;
  gap: 24px;
  padding: 0 24px;
  height: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
  background: ${({ theme }) => theme.color.bg.secondary};
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const StyledSourcesSmRow = styled('div')`
  display: none;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
  }
`;

export const StyledSourcesSmRowBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledSourcesSmRowItem = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledSourcesContext = styled('div')`
  display: flex;
  gap: 24px;
  padding: 0 24px 24px 24px;
`;

export const StyledSourcesContextItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;
