import { styled } from 'theme';

export const StyledPinTooltipDescription = styled('p')`
  margin: 0;
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledPinTooltipCountdown = styled('p')`
  margin: 0;
  padding-left: 14px;
  font-weight: 400;
  font-size: 11px;
  color: ${({ theme }) => theme.color.typo.ghost};
`;

export const StyledPinTooltipTextWithSpace = styled('span')`
  padding-left: 5px;
`;
