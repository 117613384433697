import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledAgreementsBlock,
  StyledAgreementsBlockHeading,
  StyledAgreementsNotice,
  StyledAgreementsNoticeDescription,
} from '../../Agreements.styled';
import { AgreementsLegalDocs } from '../AgreementsLegalDocs';

export const AgreementsSkeleton: FC = () => {
  return (
    <>
      <StyledAgreementsBlock className="AgreementsBlock">
        <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
          <Skeleton width={300} />
        </StyledAgreementsBlockHeading>
        <AgreementsLegalDocs isLoading skeletonsCount={3} />
      </StyledAgreementsBlock>
      <StyledAgreementsBlock className="AgreementsBlock">
        <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
          <Skeleton width={300} />
        </StyledAgreementsBlockHeading>
        <AgreementsLegalDocs isLoading skeletonsCount={12} />
      </StyledAgreementsBlock>
      <StyledAgreementsBlock className="AgreementsBlock">
        <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
          <Skeleton width={300} />
        </StyledAgreementsBlockHeading>
        <AgreementsLegalDocs isLoading skeletonsCount={2} />
      </StyledAgreementsBlock>
      <StyledAgreementsBlock className="AgreementsBlock">
        <StyledAgreementsBlockHeading className="AgreementsBlockHeading">
          <Skeleton width={300} />
        </StyledAgreementsBlockHeading>
        <AgreementsLegalDocs isLoading skeletonsCount={3} />
      </StyledAgreementsBlock>
      <StyledAgreementsBlock className="AgreementsBlock">
        <StyledAgreementsNotice className="AgreementsNotice" isLoading>
          <StyledAgreementsNoticeDescription className="AgreementsNoticeDescription">
            <Skeleton />
          </StyledAgreementsNoticeDescription>
          <StyledAgreementsNoticeDescription className="AgreementsNoticeDescription">
            <Skeleton />
          </StyledAgreementsNoticeDescription>
        </StyledAgreementsNotice>
      </StyledAgreementsBlock>
    </>
  );
};
