import { ReactNode } from 'react';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';

export const getRowVisibleValue = (value: ReactNode) => {
  if (typeof value === 'number' && Number.isNaN(value)) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  // noinspection SuspiciousTypeOfGuard -- weird IDEA bug
  if (typeof value === 'string' && !value.length) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  return value;
};
