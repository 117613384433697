import { isEqual } from 'lodash';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';
import { Autocomplete, Skeleton } from 'react-ui-kit-exante';
import { useDebouncedCallback } from 'use-debounce';

import { Tabs } from 'components/Tabs';
import { INPUT_DEBOUNCE_DELAY_LONG } from 'constants/common';
import { BrandingContext, TranslationContext } from 'contexts';
import { validateEmail } from 'helpers/url';
import { urlPattern } from 'helpers/urlsValidate';
import {
  StyledFtpInput,
  StyledReportsContainer,
  StyledReportsDescription,
  StyledReportsHeader,
  StyledReportsPanel,
  StyledSettings,
} from 'pages/Reports/Reports.styled';
import { ReportsContext } from 'pages/Reports/contexts/ReportsContext/ReportsContext';
import { PATHS } from 'router';
import { REPORTS_TAB_ID } from 'services/tabs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  saveSettingsNotifications,
  selectSettingsNotifications,
} from 'store/settingsNotifications';

export const ReportsIndex = () => {
  const dispatch = useAppDispatch();

  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const {
    reportsSettings,
    isLoading: isReportsSettingsLoading,
    saveReportsSettings,
    ftpError,
    setFtpError,
  } = useContext(ReportsContext);
  const { branding } = useContext(BrandingContext);

  const { data: settingsNotifications, status: settingsNotificationsStatus } =
    useAppSelector(selectSettingsNotifications);

  const [emailValues, setEmailValues] = useState<string[]>([]);
  const [ftpValue, setFtpValue] = useState<string>('');

  const isShowEmails = !branding?.no_emails;

  const reportGroup = settingsNotifications?.groups.find(
    ({ name }) => name === 'reports',
  );

  const emailOptions = useMemo(() => {
    const resultEmails = new Set();
    settingsNotifications?.groups?.forEach((group) => {
      const emails = group?.values?.email || [];
      emails.forEach((email) => {
        resultEmails.add(email);
      });
    });

    return Array.from(resultEmails).sort();
  }, [settingsNotifications]);

  const debounceHandleChangeEmails = useDebouncedCallback(
    async (newValues: string[]) => {
      if (reportGroup?.name) {
        dispatch(
          saveSettingsNotifications([
            {
              name: reportGroup.name,
              values: { email: newValues },
            },
          ]),
        );
      }
    },
    INPUT_DEBOUNCE_DELAY_LONG,
  );

  const onEmailChange = (_: any, newValues: Array<string>) => {
    const validEmails = newValues?.filter((val) => validateEmail(val));

    if (isEqual(emailValues, validEmails)) {
      return;
    }

    setEmailValues(validEmails);
    debounceHandleChangeEmails(validEmails);
  };

  const onFtpUpdate = useCallback(
    async (value: string) => {
      if (reportsSettings) {
        saveReportsSettings({
          ...reportsSettings,
          ftp: value,
        });
      }
    },
    [reportsSettings],
  );

  const debounceHandleChangeFtpInput = useDebouncedCallback(
    async (value: string) => {
      await onFtpUpdate(value);
    },
    INPUT_DEBOUNCE_DELAY_LONG,
  );

  const onFtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFtpError(false);
    setFtpValue(value);

    if (value !== reportsSettings?.ftp) {
      const isFtpValid = urlPattern(true).test(value);
      if (!isFtpValid && value) {
        setFtpError(!isFtpValid);
      } else {
        debounceHandleChangeFtpInput(value);
      }
    }
  };

  useEffect(() => {
    setEmailValues(reportGroup?.values?.email || []);
  }, [reportGroup?.values?.email]);

  useEffect(() => {
    if (reportsSettings?.ftp) {
      setFtpValue(reportsSettings.ftp);
    }
  }, [reportsSettings?.ftp]);

  const isShowSkeleton =
    (settingsNotificationsStatus === 'loading' && !settingsNotifications) ||
    isReportsSettingsLoading;

  return (
    <StyledReportsContainer className="ReportsContainer">
      <StyledReportsPanel title={t('generic__reports')} />
      <StyledReportsHeader className="ReportsHeader">
        {!isTranslationsLoading ? (
          <StyledReportsDescription
            className="ReportsDescription"
            dangerouslySetInnerHTML={{
              __html: t('layout__reports__trades_link', {
                TRADES_URL: `${PATHS.ROOT}${PATHS.TRADES}`,
              }),
            }}
          />
        ) : (
          <StyledReportsDescription className="ReportsDescription">
            <Skeleton width={327} height={16} />
          </StyledReportsDescription>
        )}
        {isShowEmails && (
          <StyledSettings className="Settings">
            <Autocomplete
              options={emailOptions}
              placeholder={t('generic__email')}
              showSkeleton={isShowSkeleton}
              value={emailValues}
              onChange={onEmailChange}
              isMultiple
              clearOnBlur
              freeSolo
              fullWidth
              dataTestId="account-reports__input--emails"
              floating={false}
            />
            <StyledFtpInput
              className="Input"
              value={ftpValue}
              onChange={onFtpChange}
              placeholder={`${t(
                'FTP',
              )} (ftp://user:password@example.com:21/path/to/folder)`}
              message={ftpError ? t('layout__settings__address_invalid') : ''}
              disabled={isShowSkeleton}
              showSkeleton={isShowSkeleton}
              error={ftpError}
              hiddenLabel
              data-test-id="account-reports__input--ftp"
            />
          </StyledSettings>
        )}
      </StyledReportsHeader>
      <Tabs tabsForShow={REPORTS_TAB_ID} disableAccountValidation />
      <Outlet />
    </StyledReportsContainer>
  );
};
