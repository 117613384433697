import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledApplicationSkeletonContainer,
  StyledApplicationSkeletonFooter,
  StyledApplicationSkeletonHeader,
  StyledApplicationSkeletonKeys,
  StyledApplicationSkeletonKeysItem,
  StyledApplicationSkeletonPermissions,
  StyledApplicationSkeletonPermissionsItem,
  StyledApplicationSkeletonPermissionsItems,
  StyledApplicationSkeletonTags,
  StyledApplicationSkeletonTitle,
} from './ApplicationSkeleton.styled';

export const ApplicationSkeleton = () => (
  <StyledApplicationSkeletonContainer>
    <StyledApplicationSkeletonHeader>
      <StyledApplicationSkeletonTitle>
        <Skeleton width={80} height={24} />
        <StyledApplicationSkeletonTags>
          <Skeleton width={56} height={24} />
          <Skeleton width={56} height={24} />
        </StyledApplicationSkeletonTags>
      </StyledApplicationSkeletonTitle>
      <StyledApplicationSkeletonKeys>
        <StyledApplicationSkeletonKeysItem>
          <Skeleton width={80} height={16} />
          <Skeleton width={40} height={8} />
        </StyledApplicationSkeletonKeysItem>
        <StyledApplicationSkeletonKeysItem>
          <Skeleton width={80} height={16} />
          <Skeleton width={40} height={8} />
        </StyledApplicationSkeletonKeysItem>
      </StyledApplicationSkeletonKeys>
    </StyledApplicationSkeletonHeader>
    <StyledApplicationSkeletonPermissions>
      <Skeleton width={80} height={24} />
      <StyledApplicationSkeletonPermissionsItems>
        <StyledApplicationSkeletonPermissionsItem>
          <Skeleton width={16} height={16} />
          <Skeleton width={48} height={16} />
        </StyledApplicationSkeletonPermissionsItem>
        <StyledApplicationSkeletonPermissionsItem>
          <Skeleton width={16} height={16} />
          <Skeleton width={48} height={16} />
        </StyledApplicationSkeletonPermissionsItem>
        <StyledApplicationSkeletonPermissionsItem>
          <Skeleton width={16} height={16} />
          <Skeleton width={48} height={16} />
        </StyledApplicationSkeletonPermissionsItem>
        <StyledApplicationSkeletonPermissionsItem>
          <Skeleton width={16} height={16} />
          <Skeleton width={48} height={16} />
        </StyledApplicationSkeletonPermissionsItem>
        <StyledApplicationSkeletonPermissionsItem>
          <Skeleton width={16} height={16} />
          <Skeleton width={48} height={16} />
        </StyledApplicationSkeletonPermissionsItem>
        <StyledApplicationSkeletonPermissionsItem>
          <Skeleton width={16} height={16} />
          <Skeleton width={48} height={16} />
        </StyledApplicationSkeletonPermissionsItem>
        <StyledApplicationSkeletonPermissionsItem>
          <Skeleton width={16} height={16} />
          <Skeleton width={48} height={16} />
        </StyledApplicationSkeletonPermissionsItem>
        <StyledApplicationSkeletonPermissionsItem>
          <Skeleton width={16} height={16} />
          <Skeleton width={48} height={16} />
        </StyledApplicationSkeletonPermissionsItem>
        <StyledApplicationSkeletonPermissionsItem>
          <Skeleton width={16} height={16} />
          <Skeleton width={48} height={16} />
        </StyledApplicationSkeletonPermissionsItem>
      </StyledApplicationSkeletonPermissionsItems>
    </StyledApplicationSkeletonPermissions>
    <StyledApplicationSkeletonFooter>
      <Skeleton width={200} height={16} />
    </StyledApplicationSkeletonFooter>
  </StyledApplicationSkeletonContainer>
);
