import { styled } from 'theme';

import { TStyledConditionsProps } from './TaxReportConditions.types';

export const StyledConditions = styled('div')<TStyledConditionsProps>`
  display: flex;
  gap: 8px;

  white-space: break-spaces;

  color: ${({ theme, color }) => theme?.color?.typo?.[color || 'primary']};
`;

export const StyledConditionsText = styled('span')`
  a {
    color: ${({ theme }) => theme?.color?.typo?.action};
  }
`;
