import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { Dates } from 'helpers/dates';
import { TLegalEntityDocument } from 'services/legal-docs';

import { DocActionsCell } from '../../DocActionsCell';
import { DocComboCell } from '../../DocComboCell';
import { DocStatusCell } from '../../DocStatusCell';

import {
  TAgreementsLegalDocsGetColumnsParams,
  TAgreementsLegalDocsGetKeysParams,
} from './columns.types';

export const TABLE_SORT_BY = [{ id: 'order', desc: false }];

export const getKeys: (
  params: TAgreementsLegalDocsGetKeysParams,
) => string[] = ({ isMobile }) => {
  return isMobile
    ? ['doc.title', 'actions']
    : ['doc.title', 'sign_date', 'status', 'actions'];
};

export const getColumns: (
  params: TAgreementsLegalDocsGetColumnsParams,
) => IColumn<TLegalEntityDocument>[] = ({
  t,
  isMobile,
  withStatus,
  onOpenModal,
  dateFormatter,
}) => [
  ...(isMobile
    ? [
        {
          id: 'title',
          accessor: 'doc.title',
          Cell: ({ row: { original } }: ICellValue<TLegalEntityDocument>) => {
            return (
              <DocComboCell
                title={t(original.doc.title)}
                signDate={original.sign_date}
                isSigned={original.doc.is_signed}
                onTitleClick={() => onOpenModal?.(original)}
                withStatus={withStatus}
                withDate
                dateFormatter={dateFormatter}
              />
            );
          },
        },
        {
          id: 'doc_actions',
          Header: t('generic__action'),
          accessor: 'actions',
          align: 'right',
          width: 48,
          Cell: ({ row: { original } }: ICellValue<TLegalEntityDocument>) => {
            return <DocActionsCell downloadUrl={original?.url} />;
          },
        },
      ]
    : [
        {
          id: 'title',
          Header: t('generic__document'),
          accessor: 'doc.title',
          disabledFilters: true,
          width: 300,
          Cell: ({ row: { original } }: ICellValue<TLegalEntityDocument>) => {
            return (
              <DocComboCell
                t={t}
                title={t(original.doc.title)}
                signDate={original.sign_date}
                isSigned={original.doc.is_signed}
                onTitleClick={() => onOpenModal?.(original)}
                dateFormatter={dateFormatter}
              />
            );
          },
        },
        {
          id: 'created',
          Header: t('generic__date'),
          accessor: 'sign_date',
          disableFilters: true,
          Cell: ({ row: { original } }: ICellValue<TLegalEntityDocument>) => {
            return original.sign_date
              ? dateFormatter(
                  Dates.convertDateToUtcTimezone(new Date(original.sign_date)),
                )
              : DEFAULT_EMPTY_PLACEHOLDER;
          },
        },
        {
          id: 'status',
          Header: !withStatus ? '' : t('generic__status'),
          accessor: 'status',
          disableFilters: true,
          Cell: !withStatus
            ? ''
            : ({ row: { original } }: ICellValue<TLegalEntityDocument>) => {
                return (
                  <DocStatusCell isSigned={original.is_filled_doc} t={t} />
                );
              },
        },
        {
          id: 'doc_actions',
          Header: t('generic__action'),
          accessor: 'actions',
          align: 'right',
          Cell: ({ row: { original } }: ICellValue<TLegalEntityDocument>) => {
            return <DocActionsCell downloadUrl={original?.url} />;
          },
        },
      ]),

  {
    id: 'order',
    accessor: 'order',
    isVisible: false,
  },
];
