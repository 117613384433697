import { useContext } from 'react';
import {
  IconButton,
  ToggleButtonGroup,
  Input,
  Checkbox,
  Button,
} from 'react-ui-kit-exante';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';

import {
  StyledAddActionContainer,
  StyledAddActionFooter,
} from './AddAction.styled';
import { useAddAction } from './hooks';

export const AddAction = () => {
  const {
    isOpen,
    handleOpen,
    handleClose,
    description,
    environment,
    handleDescriptionChange,
    handleEnvironmentChange,
    scopeItemChecked,
    handleSave,
    handleScopesChange,
    isValid,
    saving,
  } = useAddAction();

  const { t } = useContext(TranslationContext);

  return (
    <ActionWithConfirmation
      hideDefaultButtons
      openFromExternal
      externalOpened={isOpen}
      withCloseAfterConfirmation
      closeHandler={handleClose}
      placement="bottom"
      content={
        <StyledAddActionContainer
          className="AddActionContainer"
          onSubmit={handleSave}
        >
          <ToggleButtonGroup
            size="small"
            exclusive
            value={String(environment)}
            onChange={handleEnvironmentChange}
            options={[
              { label: 'Live', value: '0' },
              { label: 'Demo', value: '1' },
            ]}
          />
          <Input
            fullWidth
            autoFocus
            placeholder={t('layout__settings__api__application_name')}
            value={description}
            onChange={handleDescriptionChange}
          />
          <Text size="16px">{t('layout__settings__api__key_permissions')}</Text>
          <Checkbox
            label="Symbols"
            value="symbols"
            checked={scopeItemChecked('symbols')}
            onChange={() => handleScopesChange('symbols')}
          />
          <Checkbox
            label="Feed"
            value="feed"
            checked={scopeItemChecked('feed')}
            onChange={() => handleScopesChange('feed')}
          />
          <Checkbox
            label="Change"
            value="change"
            checked={scopeItemChecked('change')}
            onChange={() => handleScopesChange('change')}
          />
          <Checkbox
            label="Orders"
            value="orders"
            checked={scopeItemChecked('orders')}
            onChange={() => handleScopesChange('orders')}
          />
          <Checkbox
            label="OHLC"
            value="ohlc"
            checked={scopeItemChecked('ohlc')}
            onChange={() => handleScopesChange('ohlc')}
          />
          <Checkbox
            label="Crossrates"
            value="crossrates"
            checked={scopeItemChecked('crossrates')}
            onChange={() => handleScopesChange('crossrates')}
          />
          <Checkbox
            label="Summary"
            value="summary"
            checked={scopeItemChecked('summary')}
            onChange={() => handleScopesChange('summary')}
          />
          <Checkbox
            label="Accounts"
            value="accounts"
            checked={scopeItemChecked('accounts')}
            onChange={() => handleScopesChange('accounts')}
          />
          <Checkbox
            label="Transactions"
            value="transactions"
            checked={scopeItemChecked('transactions')}
            onChange={() => handleScopesChange('transactions')}
          />
          <StyledAddActionFooter className="AddActionPasswordContainer">
            <Button
              type="submit"
              loading={saving}
              color="transparent"
              textColor="action"
              disabled={!isValid}
            >
              {t('generic__action__add')}
            </Button>
            <Button onClick={handleClose} color="transparent">
              {t('generic__action__cancel')}
            </Button>
          </StyledAddActionFooter>
        </StyledAddActionContainer>
      }
    >
      <IconButton
        iconColor="action"
        iconName="AddIcon"
        label={t('layout__settings__api__add_button')}
        onClick={handleOpen}
      />
    </ActionWithConfirmation>
  );
};
