import { TAccount } from 'services/accounts';

export const getInitialAccountId = (
  accounts: TAccount[],
  preferredAccountId: string | null,
): string => {
  const query = new URLSearchParams(window.location.search);
  const fromQueryAccount = query.get('accountId') || query.get('account_id');
  const predefinedAccount = fromQueryAccount || preferredAccountId;

  const predefinedAccountLoaded: boolean = accounts.some(
    (item) => item.value === predefinedAccount,
  );

  return predefinedAccount && predefinedAccountLoaded
    ? predefinedAccount
    : String(accounts[0].value);
};
