import { styled } from 'theme';

export const StyledAccountsTableNoData = styled('div')`
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme?.color.typo.primary};
  background-color: ${({ theme }) => theme?.color.table.bg.basic2};
  padding: 64px 24px;
  text-align: center;

  & p {
    margin: 0;
  }
`;
