import { styled } from 'theme';

export const StyledConfirmButton = styled('button')`
  outline: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  color: ${({ theme }) => theme.color.typo.action};
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
`;
