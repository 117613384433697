import { styled } from 'theme';

export const StyledW8AffidavitFormWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  max-height: 400px;
  min-width: 350px;
`;

export const StyledW8AffidavitFormHeader = styled('h3')`
  margin: 0 0 16px;

  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 20px;
  font-family: ${({ theme }) => theme.font.header};
  font-weight: 400;
  line-height: 24px;
`;

export const StyledW8AffidavitFormFooter = styled('div')`
  display: flex;
  gap: 8px;
  margin-top: 16px;
`;
