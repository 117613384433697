import { FC } from 'react';

import { CardSettingsWrapper } from 'pages/CardSettings/CardSettingsWrapper';
import { CardSettingsProvider } from 'pages/CardSettings/contexts';

export const CardSettings: FC = () => (
  <CardSettingsProvider>
    <CardSettingsWrapper />
  </CardSettingsProvider>
);
