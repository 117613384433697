import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';
import {
  TActivateCardProps,
  TBlockCardProps,
  TBlockCardResponse,
  TCardsInfo,
  TGetCardsInfoProps,
  TGetCardsInfoResponse,
  TOrderCardProps,
  TPollOrderedCardProps,
  TPollOrderedCardResponse,
  TShowCardPinProps,
  TShowCardPinResponse,
} from 'services/cards/cards.types';

class CardService extends ApiRequest {
  defaultPollingTimeout = 30;

  defaultPollingInterval = 3;

  async getCardsInfo({
    lang,
    accountId,
  }: TGetCardsInfoProps): Promise<TCardsInfo | null> {
    try {
      const { data } = await this.fetch<TGetCardsInfoResponse>({
        url: `/${lang}/clientsarea/spa-layout/`,
        params: { name: 'my-cards', accountId },
      });

      return data['my-cards'];
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }

  async activateCard({
    lang,
    cardId,
    cvv,
  }: TActivateCardProps): Promise<boolean> {
    try {
      await this.fetch<TGetCardsInfoResponse>({
        url: `/${lang}/clientsarea/account/cards/api/activate/`,
        method: 'POST',
        data: {
          card_id: cardId,
          cvv,
        },
      });

      return true;
    } catch (error: any) {
      return false;
    }
  }

  async getPin({ cardId, lang }: TShowCardPinProps): Promise<string | null> {
    try {
      const { data } = await this.fetch<TShowCardPinResponse>({
        url: `/${lang}/clientsarea/account/cards/api/pin/`,
        method: 'POST',
        data: { card_id: cardId },
      });

      return data.value || null;
    } catch (e) {
      notifyWith.genericNetworkError(e);
      return null;
    }
  }

  async orderCard(props: TOrderCardProps): Promise<boolean> {
    try {
      const { lang, ...requestParams } = props;

      await this.fetch({
        url: `/${lang}/clientsarea/account/cards/api/create/`,
        method: 'POST',
        data: requestParams,
      });

      return true;
    } catch (e) {
      notifyWith.genericNetworkError(e);
      return false;
    }
  }

  async blockCard({
    cardId,
    lang,
  }: TBlockCardProps): Promise<TBlockCardResponse | null> {
    try {
      const { data } = await this.fetch<TBlockCardResponse>({
        url: `/${lang}/clientsarea/account/cards/api/block/`,
        method: 'POST',
        data: { card_id: cardId },
      });

      return data;
    } catch (e) {
      notifyWith.genericNetworkError(e);
      return null;
    }
  }

  pollOrderedCard({
    previousQuantity,
    timeoutSeconds = this.defaultPollingTimeout,
    lang,
    accountId,
    intervalSeconds = this.defaultPollingInterval,
  }: TPollOrderedCardProps): TPollOrderedCardResponse {
    const promises: Promise<TCardsInfo | null>[] = [];
    let aborted = false;
    const timeout = Number.isNaN(timeoutSeconds)
      ? this.defaultPollingTimeout
      : timeoutSeconds;
    const interval = !Number.isFinite(intervalSeconds)
      ? this.defaultPollingInterval
      : intervalSeconds;

    if (timeoutSeconds !== Infinity) {
      promises.push(
        new Promise<null>((resolve) => {
          setTimeout(() => {
            aborted = true;
            resolve(null);
          }, timeout * 1000);
        }),
      );
    }

    const polling = new Promise<TCardsInfo | null>((resolve) => {
      let result: TCardsInfo | null = null;

      const poll = async () => {
        if (aborted) {
          resolve(result);
          return;
        }

        const cardsInfo = await this.getCardsInfo({ lang, accountId });
        result = cardsInfo;

        const newQuantity = cardsInfo?.cards.length;

        if (
          typeof newQuantity === 'number' &&
          newQuantity !== previousQuantity
        ) {
          resolve(cardsInfo);
        } else {
          setTimeout(poll, interval * 1000);
        }
      };

      poll();
    });

    promises.push(polling);

    return {
      abort: () => {
        aborted = true;
      },
      response: Promise.race(promises),
    };
  }
}

export const cardService = new CardService();
