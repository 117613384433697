import { FC, useContext, useEffect, useRef, useState } from 'react';
import { IconButton, Loader, Tooltip, useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { DepositContext } from 'pages/Portfolio/tabs/Deposit/contexts';
import {
  depositService,
  TDepositExportDownloadResponse,
} from 'services/deposit';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { getDepositDocUrl } from './OpenDocButton.helpers';
import { LoadingTooltipStyled } from './OpenDocButton.styled';

export const OpenDocButton: FC = () => {
  const { t } = useContext(TranslationContext);

  const { selectedAccountId } = useAppSelector(selectAccounts);

  const { currency, bank } = useContext(DepositContext);

  const [isOpening, setIsOpening] = useState(false);

  const abortController = useRef(new AbortController());
  const abortControllerOpen = useRef(new AbortController());

  const {
    isLoading,
    data: downloadData,
    fetchData: exportDownload,
  } = useData<TDepositExportDownloadResponse | null>({
    loading: false,
    onFetch: async () => {
      if (!(selectedAccountId && bank)) {
        return null;
      }

      abortController.current.abort();
      abortController.current = new AbortController();

      return depositService.exportDepositDownload({
        accountId: selectedAccountId,
        bank,
        currency,
        options: {
          signal: abortController.current.signal,
        },
      });
    },
  });

  useEffect(() => {
    if (downloadData?.url) {
      abortControllerOpen.current.abort();
      abortControllerOpen.current = new AbortController();

      setIsOpening(true);

      getDepositDocUrl(downloadData?.url, abortControllerOpen.current.signal)
        .then((url) => {
          if (url) {
            window.open(url, '_newtab');
          }
        })
        .catch((e) => console.error(e))
        .finally(() => setIsOpening(false));
    }

    return () => {
      abortControllerOpen.current.abort();
    };
  }, [downloadData?.url]);

  // cancel the request if parameters changed when the request is in progress
  useEffect(() => {
    return () => {
      abortController.current.abort();
      abortControllerOpen.current.abort();
    };
  }, [selectedAccountId, currency, bank]);

  return (
    <Tooltip
      open={isLoading || isOpening}
      title={
        <LoadingTooltipStyled className="LoadingTooltip">
          <Loader />
          {t('generic__loading_state')}
        </LoadingTooltipStyled>
      }
    >
      <IconButton
        iconName="FileTextIcon"
        iconColor="secondary"
        iconSize={24}
        onClick={exportDownload}
        data-test-id="requisites__button__open_doc"
      />
    </Tooltip>
  );
};
