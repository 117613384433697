import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Loader, useData } from 'react-ui-kit-exante';

import { setFavicon } from 'helpers/setFavicon';
import { brandingService, TBrandingResponse } from 'services/branding';
import { TClientsareaTheme } from 'theme';
import { TChildren } from 'types/TChildren';

import { TranslationContext } from './TranslationContext';

export type BrandingState = {
  branding: TBrandingResponse | null;
  theme: TClientsareaTheme | null;
};

const initialState: BrandingState = {
  branding: null,
  theme: null,
};

export const BrandingContext = createContext<BrandingState>(initialState);

export type BrandingProviderProps = TChildren & {
  useDefault?: boolean;
};

export const BrandingProvider: FC<BrandingProviderProps> = ({
  children,
  useDefault,
}): ReactElement | null => {
  const { currentLanguage } = useContext(TranslationContext);
  const getBranding =
    useCallback(async (): Promise<TBrandingResponse | null> => {
      try {
        return await brandingService.getBranding(
          currentLanguage,
          useDefault ? 'default' : 'my',
        );
      } catch (error: any) {
        return null;
      }
    }, [currentLanguage]);

  const setBrandingGlobalSettings = useCallback(
    (response: TBrandingResponse | null) => {
      const favicon = response?.branding?.favicon;

      if (favicon) {
        setFavicon(favicon);
      }
    },
    [],
  );

  const { fetchData, isLoading, data } = useData<TBrandingResponse | null>({
    onFetch: getBranding,
    onSuccess: setBrandingGlobalSettings,
  });

  useEffect(() => {
    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage]);

  const brandingValue = useMemo((): BrandingState => {
    if (!data) {
      return initialState;
    }

    return {
      branding: data,
      theme: data?.branding?.ui_settings?.clientsarea_2_0_v2 || null,
    };
  }, [data]);

  if (!data && isLoading) {
    return <Loader isCentered />;
  }

  return (
    <BrandingContext.Provider value={brandingValue}>
      {children}
    </BrandingContext.Provider>
  );
};
