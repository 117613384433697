import { FormEvent, useContext } from 'react';
import { Input } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import { usePasswordVerification } from '../../hooks';
import {
  StyledSharedMfaContainer,
  StyledSharedMfaInputContainer,
  StyledSharedMfaText,
} from '../styled';

import { StyledConfirmButton } from './ConfirmPassword.styled';
import { TConfirmPasswordProps } from './ConfirmPassword.types';

export const ConfirmPassword = ({ onSuccess }: TConfirmPasswordProps) => {
  const { t } = useContext(TranslationContext);

  const {
    isVerifying,
    password,
    verifyErrorMessage,
    handlePasswordChange,
    handleVerify,
  } = usePasswordVerification(onSuccess);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await handleVerify();
  };

  return (
    <StyledSharedMfaContainer className="Container">
      <StyledSharedMfaText className="Text">
        {t('layout__security_tabs__mfa_modal_password_title')}
      </StyledSharedMfaText>
      <form onSubmit={handleSubmit}>
        <StyledSharedMfaInputContainer className="InputContainer">
          <Input
            autoFocus
            fullWidth
            error={Boolean(verifyErrorMessage)}
            message={verifyErrorMessage}
            disabled={isVerifying}
            type="password"
            placeholder={t('layout__security_tabs__mfa_label_password')}
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
          <StyledConfirmButton
            className="ConfirmButton"
            type="submit"
            disabled={isVerifying || password.length === 0}
          >
            {t('layout__security_tabs__mfa_label_confirm')}
          </StyledConfirmButton>
        </StyledSharedMfaInputContainer>
      </form>
    </StyledSharedMfaContainer>
  );
};
