import { REFETCH_STATUS } from 'constants/api';
import { Abort } from 'helpers/abortController';
import { isCancelledError } from 'helpers/abortController/abortController.helpers';
import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TFetchClientsAreaInterestsResponse,
  TFetchInterestsParams,
} from './interests.types';

class InterestsService extends ApiRequest {
  abortClient;

  constructor() {
    super();
    this.abortClient = new Abort();
  }

  async getInterests(
    {
      accountId,
      iDisplayStart,
      date_to,
      iDisplayLength,
      date_from,
      sSortDir_0,
      iSortCol_0,
    }: TFetchInterestsParams,
    lang: string,
  ) {
    try {
      const response =
        await this.abortClient.withAbortController<TFetchClientsAreaInterestsResponse>(
          this.fetch.bind(this),
          {
            method: 'GET',
            url: `/${lang}/clientsarea/account/interests/list/`,
            params: {
              id: accountId,
              iDisplayStart,
              date_to,
              iDisplayLength,
              date_from,
              sSortDir_0,
              iSortCol_0,
            },
          },
        );

      if (response instanceof Error) {
        console.error('getInterests error', response);
        return null;
      }
      return response.data;
    } catch (error) {
      if (isCancelledError(error)) {
        return {
          interests: [],
          iTotalRecords: 0,
          status: REFETCH_STATUS,
        };
      }
      console.error('getInterests error:', error);
      notifyWith.genericNetworkError(error);
      return null;
    }
  }
}

export const interestsService = new InterestsService();
