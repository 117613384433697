import { styled } from 'theme';

export const StyledWithdrawalSkeletonContainerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

export const StyledWithdrawalSkeletonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

export const StyledWithdrawalSkeletonTabs = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const StyledWithdrawalSkeletonAccounts = styled('div')`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
