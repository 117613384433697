import { AxiosError } from 'axios';

import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';
import {
  TExchangeCrossRates,
  TExchangeRequestProps,
  TExchangeRequestResponse,
  TExchangeResponse,
} from 'services/exchange/exchange.types';

class ExchangeService extends ApiRequest {
  async fetchAccountInfo(
    accountId?: string,
  ): Promise<TExchangeResponse | null> {
    try {
      const { data } = await this.fetch<TExchangeResponse>({
        url: '/clientsarea/account/exchange/details/',
        params: { accountId },
      });

      return data;
    } catch (e) {
      notifyWith.genericNetworkError(e);
      return null;
    }
  }

  async fetchCrossrates(): Promise<TExchangeCrossRates | null> {
    try {
      const { data } = await this.fetch<TExchangeCrossRates>({
        url: '/clientsarea/account/exchange/crossrates/',
      });

      if (typeof data !== 'object') {
        return null;
      }

      return data;
    } catch (e) {
      notifyWith.genericNetworkError(e);
      return null;
    }
  }

  async fetchCommission(
    accountId: string,
    currency: string,
    targetCurrency: string,
  ): Promise<string | null> {
    if (currency === targetCurrency) {
      return null;
    }

    try {
      const { data } = await this.fetch<string>({
        url: '/clientsarea/account/exchange/request/commission/',
        params: {
          accountId,
          currency,
          targetCurrency,
        },
      });

      return data;
    } catch (error) {
      const { response } = error as AxiosError;
      if (response?.status !== 400) {
        notifyWith.genericNetworkError(error);
      }
      return null;
    }
  }

  async requestExchange({
    accountId,
    currency,
    targetCurrency,
    amount,
  }: TExchangeRequestProps): Promise<TExchangeRequestResponse | null> {
    try {
      const { data } = await this.fetch<TExchangeRequestResponse>({
        url: '/clientsarea/account/exchange/request/',
        method: 'post',
        data: {
          accountId,
          currency,
          targetCurrency,
          amount,
        },
      });

      if (!data?.success) {
        notifyWith.genericError();
        return null;
      }

      notifyWith.success('Transfer completed successfully.'); // фраза "Transfer completed successfully." и есть ключ

      return data;
    } catch (e) {
      const isAmountError = Boolean(
        ((e as AxiosError)?.response?.data?.errors?.amount || []).length,
      );

      if (isAmountError) {
        notifyWith.error('layout__exchange__error__insufficient_funds');
      } else {
        notifyWith.genericNetworkError(e);
      }

      return null;
    }
  }
}

export const exchangeService = new ExchangeService();
