import { TMfaTypes } from 'types/mfa';

export const codeLength = (mfaType?: TMfaTypes) => {
  if (!mfaType) {
    return 8;
  }
  return {
    email: 8,
    sms: 4,
    totp: 6,
  }[mfaType];
};
