import { debounce } from 'lodash';
import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IconButton, Panel, SearchInput, Table } from 'react-ui-kit-exante';

import { INPUT_DEBOUNCE_DELAY } from 'constants/common';
import { BASE_URL } from 'constants/endpoints';
import { TranslationContext, UserDetailsContext } from 'contexts';
import { prepareTableId } from 'helpers/prepareTableId';
import { useDynamicTableSize } from 'hooks';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { INSTRUMENTS_TREE_APIS_MAP } from 'services/instrumentsTree/instrumentsTree.constants';
import {
  TTreeModule,
  TTreeResponseTreeStructure,
} from 'services/instrumentsTree/instrumentsTree.types';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { StyledTermsHeader } from './InstrumentsTree.styled';
import { TInstrumentsTreeWithContextProps } from './InstrumentsTree.types';
import { InstrumentsTreeContext } from './context/InstrumentsTreeContext';
import { ActionTypes } from './context/InstrumentsTreeContext.types';
import { canExpandDefinition } from './helpers/canExpandDefinition';
import { useHandleCellClick } from './hooks/useHandleCellClick';
import { useInstrumentsTree } from './hooks/useInstrumentsTree';
import { useMatchIdAndPositionInTable } from './hooks/useMatchIdAndPositionInTable';
import { useSearchInstruments } from './hooks/useSearchInstruments';

export const InstrumentsTreeWithContext: FC<
  TInstrumentsTreeWithContextProps
> = ({ getColumns }) => {
  const { t, isTranslationsLoading, currentLanguage } =
    useContext(TranslationContext);
  const currencyFormatter = useCurrencyFormatter();
  const { userDetails } = useContext(UserDetailsContext);

  const { selectedAccountId: rawSelectedAccountId, selectedAccount } =
    useAppSelector(selectAccounts);

  const [instrumentsState, dispatchInstruments] = useContext(
    InstrumentsTreeContext,
  );
  const { expandedRows, module, loadingInstruments } = instrumentsState;
  const { info } = userDetails || {};

  const selectedAccountId: string =
    rawSelectedAccountId && selectedAccount ? rawSelectedAccountId : '';

  const username = info?.current?.email;
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<
    TTreeResponseTreeStructure[] | null
  >(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const prevLang = useRef(currentLanguage);

  const { tableWidth, tableHeight, updateTableSizes } = useDynamicTableSize(
    () => {
      return searchRef.current
        ? searchRef.current.getBoundingClientRect().top
        : 0;
    },
  );

  const {
    updateMatchIdAndPositionInSearchTable,
    matchIdAndPositionInNonSearchTable,
    matchIdAndPositionInSearchTable,
    updateMatchIdAndPositionInNonSearchTable,
  } = useMatchIdAndPositionInTable();

  const { fetchTree, currentTree, initialTree, isLoading } = useInstrumentsTree(
    {
      accountId: selectedAccountId || '',
      username: username || '',
      lang: currentLanguage,
    },
    setTableData,
    updateMatchIdAndPositionInSearchTable,
    updateMatchIdAndPositionInNonSearchTable,
    dispatchInstruments,
    module,
  );

  const { handleCellClick } = useHandleCellClick({
    accountId: selectedAccountId || '',
    username: username || '',
    search: searchValue || '',
    matchIdAndPositionInNonSearchTable,
    matchIdAndPositionInSearchTable,
    updateMatchIdAndPositionInNonSearchTable,
    setTableData,
    updateTableSizes,
  });

  const resetTableMetaData = () => {
    dispatchInstruments({
      type: ActionTypes.RESET_DOWNLOADED_PATHS,
      payload: null,
    });
    dispatchInstruments({
      type: ActionTypes.RESET_EXPANDED_ROWS,
      payload: null,
    });
  };

  const { debounceSearchInstruments, searchIsLoading } = useSearchInstruments({
    matchIdAndPositionInSearchTable,
    setTableData,
    accountId: selectedAccountId,
    username: username || '',
    lang: currentLanguage,
    currentTree,
    initialTree,
    fetchTree,
    resetTableMetaData,
    updateTableSizes,
  });

  const onDownload = () => {
    const getParams: { [key: string]: string } = {
      search: searchValue,
      username: username || '',
      accountId: selectedAccountId || '',
      limit: '1000000',
      skip: '0',
      lang: currentLanguage,
    };
    if (module !== TTreeModule.PERMISSIONS) {
      delete getParams.username;
    }
    const getParamsString = new URLSearchParams(getParams).toString();

    const endpoint = INSTRUMENTS_TREE_APIS_MAP[module].report;
    if (endpoint) {
      const url = new URL(endpoint, BASE_URL);
      window.open(`${url}?${getParamsString}`);
    }
  };

  useEffect(() => {
    if (selectedAccountId && username) {
      if (searchValue) {
        debounceSearchInstruments(searchValue);
      } else {
        resetTableMetaData();
        fetchTree();
      }
    }
  }, [selectedAccountId, username]);

  useEffect(() => {
    // refetch tree and search if lang was changed
    if (prevLang.current !== currentLanguage) {
      prevLang.current = currentLanguage;

      if (selectedAccountId && username) {
        resetTableMetaData();
        fetchTree();
        prevLang.current = currentLanguage;
        if (searchValue) {
          debounceSearchInstruments(searchValue);
        }
      }
    }
  }, [currentLanguage]);

  const expanded: Record<string, unknown> = {
    canExpandDefinition,
    customExpandControl: true,
    listOfInitialExpandedRowKeys: expandedRows,
  };

  const debounceHandleChangeInput = useMemo(
    () => debounce(debounceSearchInstruments, INPUT_DEBOUNCE_DELAY),
    [selectedAccountId, username, instrumentsState.treeWasFiltered],
  );

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value;
    setSearchValue(value);
    debounceHandleChangeInput(value);
  };

  const isTableLoading =
    isLoading ||
    !selectedAccountId ||
    !username ||
    isTranslationsLoading ||
    searchIsLoading;

  const getRowProps = useCallback(() => {
    return { style: { boxShadow: 'none' } };
  }, []);

  const safeTableNotEmpty = tableData?.filter((i) => i).length;

  const columns = getColumns(t, loadingInstruments, currencyFormatter);

  const virtualized = useMemo(
    () => ({
      height: tableHeight,
      width: tableWidth,
      itemSize: 32,
    }),
    [tableWidth, tableHeight],
  );

  return (
    <Panel className={`TermsWrapper-${module}`} disableContentPaddings>
      <div ref={searchRef}>
        <StyledTermsHeader className="TermsHeader">
          <SearchInput
            size="small"
            inputProps={{ sx: { width: 240 }, onChange: handleChangeInput }}
            placeholder={t('generic__search')}
            data-test-id={`instruments-tree-${module}__search-input`}
          />
          <IconButton
            iconName="XLSIcon"
            iconColor="secondary"
            iconSize={24}
            onClick={onDownload}
            className={`TermsDownload-${module}`}
            data-test-id={`instruments-tree-${module}__download-report`}
          />
        </StyledTermsHeader>
      </div>
      <Table<TTreeResponseTreeStructure>
        columns={columns}
        data={safeTableNotEmpty ? tableData : []}
        getRowProps={getRowProps}
        expanded={expanded}
        defaultSortBy={[]}
        handleCellClick={handleCellClick}
        isLoading={isTableLoading}
        isFlexLayout
        tableId={prepareTableId(module)}
        skeletonsCount={9}
        translator={t}
        virtualized={virtualized}
        showTableInfo={false}
      />
    </Panel>
  );
};
