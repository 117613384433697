import { FC, useContext, useState } from 'react';
import { IconButton, Modal, TabPanel } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { SetupItem } from 'pages/AssetManagementSetup/components/AssetManagementSetupCreation/components/SetupItem';
import { setupsContext } from 'pages/AssetManagementSetup/components/AssetManagementSetupCreation/contexts';

import { StyledSetupTabDeleteButton } from './SetupTab.styled';
import { TSetupTabProps } from './SetupTab.types';

export const SetupTab: FC<TSetupTabProps> = ({ setup, value, index }) => {
  const { t } = useContext(TranslationContext);
  const { deleteSetup, isDeletePossible } = useContext(setupsContext);
  const [open, setIsOpen] = useState(false);

  const handleToggleModal = () => setIsOpen(!open);

  const handleDelete = () => {
    deleteSetup?.(setup);
    handleToggleModal();
  };

  return (
    <TabPanel value={value} index={index}>
      <SetupItem setup={setup} />
      <StyledSetupTabDeleteButton className="SetupTabDeleteButton">
        <IconButton
          iconColor="secondary"
          iconName="DeleteIcon"
          iconSize={16}
          onClick={handleToggleModal}
          disabled={!isDeletePossible}
          label={t('asset_management__setup_tab_delete')}
        />
      </StyledSetupTabDeleteButton>
      <Modal
        isOpened={open}
        onClose={handleToggleModal}
        title=""
        confirmButton={{
          confirmButtonName: t(
            'asset_management__confirm_setup_delete_confirm_button',
          ),
          handleConfirm: handleDelete,
        }}
      >
        <div>{t('asset_management__confirm_setup_delete_notice')}</div>
      </Modal>
    </TabPanel>
  );
};
