import { LoaderFunction } from 'react-router-dom';

import { Layout } from 'components/Layout';
import { Root } from 'components/Root';
import { RootPrivate } from 'components/RootPrivate';
import { RootPublic } from 'components/RootPublic';
import { AccountSettings } from 'pages/AccountSettings';
import { AssetManagementClientsPage } from 'pages/AssetManagementClients';
import { AssetManagementSetup } from 'pages/AssetManagementSetup';
import { CardSettings } from 'pages/CardSettings';
import { ChallengeAccountSummary } from 'pages/Challenge/ChallengeAccountSummary';
import { ChallengePayout } from 'pages/Challenge/ChallengePayout';
import { ChallengePurchases } from 'pages/Challenge/ChallengePurchases';
import { ChallengeRules } from 'pages/Challenge/ChallengeRules';
import { EconomicCalendar } from 'pages/Challenge/EconomicCalendar';
import { VideoTutorial } from 'pages/Challenge/VideoTutorial';
import {
  CheckoutDeposit,
  CheckoutFailure,
  CheckoutSuccess,
} from 'pages/Checkout';
import { Downloads } from 'pages/Downloads';
import { Help } from 'pages/Help';
import { LoginLayout } from 'pages/Login/components/LoginLayout';
import { RecoveryRequest } from 'pages/Login/pages/RecoveryRequest';
import { Signup } from 'pages/Login/pages/Signup';
import { PartnerKyc } from 'pages/Partner/PartnerKyc';
import { Portfolio } from 'pages/Portfolio';
import {
  Analysis,
  Deposit,
  Exchange,
  MarginStructure,
  PortfolioTotal,
  Trades,
  Transactions,
  Transfers,
} from 'pages/Portfolio/tabs';
import { Redirect } from 'pages/Redirect';
import { Reports } from 'pages/Reports';
import { ReportEdit } from 'pages/Reports/pages/ReportEdit';
import { CustomReports } from 'pages/Reports/tabs/CustomReports';
import { GeneratedReports } from 'pages/Reports/tabs/GeneratedReports';
import { PeriodicReports } from 'pages/Reports/tabs/PeriodicReports';
import { TaxReports } from 'pages/Reports/tabs/TaxReports';
import { Security } from 'pages/Security';
import { AccessRights } from 'pages/Security/pages/AccessRights';
import { ExternalAccounts } from 'pages/Security/pages/ExternalAccounts';
import { Mfa } from 'pages/Security/pages/Mfa';
import { Password } from 'pages/Security/pages/Password';
import { Settings } from 'pages/Settings';
import { Agreements } from 'pages/Settings/pages/Agreements';
import { Api, Application, Sources } from 'pages/Settings/pages/Api';
import { Documents } from 'pages/Settings/pages/Documents';
import { Notifications } from 'pages/Settings/pages/Notifications';
import { PersonalInfo } from 'pages/Settings/pages/PersonalInfo';
import { Services } from 'pages/Settings/pages/Services';
import { Subscriptions } from 'pages/Subscriptions';
import { Terms } from 'pages/Terms';
import {
  Commissions,
  Interests,
  Overnights,
  ShortAllowanceStatus,
} from 'pages/Terms/tabs';
import { RouterErrorBoundary } from 'router/components/RouterErrorBoundary';
import { TExtendedRouteObject, TRouteData } from 'router/router.types';

import { CorporateActions } from '../pages/CorporateActions';
import { CorporateActionsList } from '../pages/CorporateActions/components/CorporateActionsList';
import { CorporateActionsPostings } from '../pages/CorporateActions/components/CorporateActionsPostings';
import { Login } from '../pages/Login';
import { EmailVerification } from '../pages/Login/pages/EmailVerification';
import { RecoveryComplete } from '../pages/Login/pages/RecoveryComplete';
import { Signin } from '../pages/Login/pages/Signin';
import { Partner } from '../pages/Partner';

import { PATHS } from './router.constants';

export const createRouteList = (): Array<TExtendedRouteObject> => {
  const createLoader = (routeData: TRouteData): LoaderFunction => {
    return () => routeData;
  };

  return [
    {
      element: <Root />,
      children: [
        {
          element: <RootPublic />,
          children: [
            {
              element: <LoginLayout />,
              loader: createLoader({ titleKey: 'Clients Area' }),
              errorElement: <RouterErrorBoundary />,
              children: [
                {
                  element: <Login />,
                  loader: createLoader({
                    titleKey: 'generic__action__login',
                  }),
                  children: [
                    {
                      element: <Signin />,
                      path: PATHS.LOGIN,
                      loader: createLoader({
                        titleKey: 'generic__action__login',
                      }),
                    },
                    {
                      element: <Signup />,
                      path: PATHS.OPEN_ACCOUNT,
                      loader: createLoader({
                        titleKey: 'generic__open_account',
                        disableTokenCheck: true,
                      }),
                    },
                    {
                      element: <EmailVerification />,
                      path: PATHS.EMAIL_VERIFY,
                      loader: createLoader({
                        titleKey: 'layout__email_verification__title',
                        disableTokenCheck: true,
                        emptyFooter: true,
                      }),
                    },
                    {
                      element: <RecoveryRequest />,
                      path: PATHS.RECOVERY_REQUEST,
                      loader: createLoader({
                        titleKey: 'layout__auth__reset_password',
                        disableTokenCheck: true,
                      }),
                    },
                    {
                      element: <RecoveryComplete />,
                      path: PATHS.RECOVERY_COMPLETE,
                      loader: createLoader({
                        titleKey: 'layout__auth__new_password',
                        disableTokenCheck: true,
                      }),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          element: <RootPrivate />,
          children: [
            {
              element: <Layout />,
              path: PATHS.ROOT,
              loader: createLoader({ titleKey: 'Home' }),
              errorElement: <RouterErrorBoundary />,
              children: [
                {
                  element: <Portfolio />,
                  loader: createLoader({
                    titleKey: 'menu_item__portfolio',
                    hideBreadcrumbsChildren: true,
                    useOnlyCustomLink: true,
                  }),
                  children: [
                    {
                      path: PATHS.ACCOUNT_SUMMARY,
                      element: <PortfolioTotal />,
                      loader: createLoader({
                        titleKey: 'portfolio__tab_name__summary',
                      }),
                    },
                    {
                      path: PATHS.MARGIN_STRUCTURE,
                      element: <MarginStructure />,
                      loader: createLoader({
                        titleKey: 'portfolio__tab_name__margin',
                      }),
                    },
                    {
                      path: PATHS.TRADES,
                      element: <Trades />,
                      loader: createLoader({
                        titleKey: 'portfolio__tab_name__trades',
                      }),
                    },
                    {
                      path: PATHS.TRANSACTIONS,
                      element: <Transactions />,
                      loader: createLoader({
                        titleKey: 'portfolio__tab_name__transactions',
                      }),
                    },
                    {
                      path: PATHS.EXCHANGE,
                      element: <Exchange />,
                      loader: createLoader({
                        titleKey: 'portfolio__tab_name__exchange',
                      }),
                    },
                    {
                      path: PATHS.TRANSFER,
                      element: <Transfers />,
                      loader: createLoader({
                        titleKey: 'portfolio__tab_name__transfer',
                      }),
                    },
                    {
                      path: PATHS.ANALYSIS,
                      element: <Analysis />,
                      loader: createLoader({
                        titleKey: 'portfolio__tab_name__analysis',
                      }),
                    },
                    {
                      path: PATHS.DEPOSIT,
                      element: <Deposit />,
                      loader: createLoader({
                        titleKey: 'portfolio__tab_name__deposit',
                      }),
                    },
                    {
                      path: PATHS.CARD_SETTINGS,
                      element: <CardSettings />,
                      loader: createLoader({
                        titleKey: 'portfolio__tab_name__card_settings',
                      }),
                    },
                  ],
                },
                {
                  path: PATHS.CUSTOM_REPORTS_CREATE,
                  element: <ReportEdit isNew />,
                  loader: createLoader({
                    titleKey: 'reports__tab_name__custom_create',
                    useOnlyCustomLink: true,
                  }),
                },
                {
                  path: PATHS.CUSTOM_REPORTS_EDIT,
                  element: <ReportEdit />,
                  loader: createLoader({
                    titleKey: 'reports__tab_name__custom_edit',
                    useOnlyCustomLink: true,
                  }),
                },
                {
                  element: <Reports />,
                  loader: createLoader({
                    titleKey: 'menu_item__reports',
                    useOnlyCustomLink: true,
                    hideBreadcrumbsChildren: true,
                  }),
                  children: [
                    {
                      path: PATHS.PERIODIC_REPORTS,
                      element: <PeriodicReports />,
                      loader: createLoader({
                        titleKey: 'reports__tab_name__periodic',
                      }),
                    },
                    {
                      path: PATHS.TAX_REPORTS,
                      element: <TaxReports />,
                      loader: createLoader({
                        titleKey: 'reports__tab_name__tax',
                      }),
                    },
                    {
                      path: PATHS.CUSTOM_REPORTS,
                      element: <CustomReports />,
                      loader: createLoader({
                        titleKey: 'reports__tab_name__custom',
                      }),
                    },
                    {
                      path: PATHS.GENERATED_REPORTS,
                      element: <GeneratedReports />,
                      loader: createLoader({
                        titleKey: 'reports__tab_name__generated',
                      }),
                    },
                  ],
                },
                {
                  path: PATHS.PARTNER,
                  element: <Partner />,
                  loader: createLoader({
                    titleKey: 'menu_item__partner',
                    useOnlyCustomLink: true,
                  }),
                },
                {
                  element: <Terms />,
                  loader: createLoader({
                    titleKey: 'menu_item__terms',
                    hideBreadcrumbsChildren: true,
                    useOnlyCustomLink: true,
                  }),
                  children: [
                    {
                      path: PATHS.COMMISSIONS,
                      element: <Commissions />,
                      loader: createLoader({
                        titleKey: 'terms__tab_name__commissions',
                      }),
                    },
                    {
                      path: PATHS.INTERESTS,
                      element: <Interests />,
                      loader: createLoader({
                        titleKey: 'terms__tab_name__interests',
                      }),
                    },
                    {
                      path: PATHS.OVERNIGHTS,
                      element: <Overnights />,
                      loader: createLoader({
                        titleKey: 'terms__tab_name__overnights',
                      }),
                    },
                    {
                      path: PATHS.SHORT_ALLOWANCE_STATUS,
                      element: <ShortAllowanceStatus />,
                      loader: createLoader({
                        titleKey: 'terms__tab_name__short_allowance',
                      }),
                    },
                  ],
                },
                {
                  path: PATHS.CHALLENGE_ACCOUNT_SUMMARY,
                  element: <ChallengeAccountSummary />,
                  loader: createLoader({
                    titleKey: 'menu_item__challenge_account_summary',
                  }),
                },
                {
                  path: PATHS.CHALLENGE_RULES,
                  element: <ChallengeRules />,
                  loader: createLoader({
                    titleKey: 'menu_item__challenge_rules',
                  }),
                },
                {
                  path: PATHS.CHALLENGE_PAYOUT,
                  element: <ChallengePayout />,
                  loader: createLoader({
                    titleKey: 'menu_item__challenge_payout',
                  }),
                },
                {
                  path: PATHS.DOWNLOADS,
                  element: <Downloads />,
                  loader: createLoader({
                    titleKey: 'menu_item__downloads',
                    useOnlyCustomLink: true,
                  }),
                },
                {
                  path: PATHS.ECONOMIC_CALENDAR,
                  element: <EconomicCalendar />,
                  loader: createLoader({
                    titleKey: 'menu_item__economic_calendar',
                    useOnlyCustomLink: true,
                  }),
                },
                {
                  path: PATHS.CHALLENGE_PURCHASES,
                  element: <ChallengePurchases />,
                  loader: createLoader({
                    titleKey: 'menu_item__challenge_purchases',
                  }),
                },
                {
                  loader: createLoader({
                    titleKey: 'menu_item__asset_management',
                    useOnlyCustomLink: true,
                    hideBreadcrumbsChildren: true,
                  }),
                  children: [
                    {
                      path: PATHS.ASSET_MANAGEMENT_SETUPS,
                      element: <AssetManagementSetup />,
                      loader: createLoader({
                        titleKey: 'menu_item__asset_management__setups',
                      }),
                    },
                    {
                      path: PATHS.ASSET_MANAGEMENT_CLIENTS,
                      element: <AssetManagementClientsPage />,
                      loader: createLoader({
                        titleKey: 'menu_item__asset_management__clients',
                      }),
                    },
                  ],
                },
                {
                  path: PATHS.HELP,
                  element: <Help />,
                  loader: createLoader({
                    titleKey: 'menu_item__help',
                    useOnlyCustomLink: true,
                  }),
                },
                {
                  path: PATHS.PARTNER_KYC,
                  element: <PartnerKyc />,
                  loader: createLoader({
                    titleKey: 'menu_item__partner_kyc',
                  }),
                },
                {
                  element: <Settings />,
                  loader: createLoader({
                    titleKey: 'menu_item__profile',
                    useOnlyCustomLink: true,
                    hideBreadcrumbsChildren: true,
                  }),
                  children: [
                    {
                      path: PATHS.SETTINGS_PROFILE,
                      element: <PersonalInfo />,
                      loader: createLoader({
                        titleKey: 'layout__settings__tab-personal_info',
                      }),
                    },
                    {
                      path: PATHS.SETTINGS_DOCUMENTS,
                      element: <Documents />,
                      loader: createLoader({
                        titleKey: 'layout__settings__tab-documents',
                      }),
                    },
                    {
                      path: PATHS.SETTINGS_AGREEMENTS,
                      element: <Agreements />,
                      loader: createLoader({
                        titleKey: 'layout__settings__tab-agreements',
                      }),
                    },
                    {
                      path: PATHS.SETTINGS_SERVICES,
                      element: <Services />,
                      loader: createLoader({
                        titleKey: 'layout__settings__tab-services',
                      }),
                    },
                  ],
                },
                {
                  element: <Security />,
                  loader: createLoader({
                    titleKey: 'menu_item__security',
                    useOnlyCustomLink: true,
                    hideBreadcrumbsChildren: true,
                  }),
                  children: [
                    {
                      path: PATHS.SECURITY_ACCESS_RIGHTS,
                      element: <AccessRights />,
                      loader: createLoader({
                        titleKey: 'layout__security_tabs__access_rights',
                      }),
                    },
                    {
                      path: PATHS.MFA,
                      element: <Mfa />,
                      loader: createLoader({
                        titleKey: 'layout__settings__mfa__2step_auth',
                      }),
                    },
                    {
                      path: PATHS.SECURITY_EXTERNAL_ACCOUNTS,
                      element: <ExternalAccounts />,
                      loader: createLoader({
                        titleKey: 'layout__security_tabs__external_accounts',
                      }),
                    },
                    {
                      path: PATHS.SECURITY_PASSWORD,
                      element: <Password />,
                      loader: createLoader({
                        titleKey: 'layout__security_tabs__password',
                      }),
                    },
                  ],
                },
                {
                  path: PATHS.SETTINGS_ACCOUNT_SETTINGS,
                  element: <AccountSettings />,
                  loader: createLoader({
                    titleKey: 'menu__item__account_settings',
                    useOnlyCustomLink: true,
                  }),
                },
                {
                  element: <Api />,
                  loader: createLoader({
                    titleKey: 'menu__item__api_settings',
                    useOnlyCustomLink: true,
                    hideBreadcrumbsChildren: true,
                  }),
                  children: [
                    {
                      path: PATHS.API_MANAGEMENT,
                      element: <Sources />,
                      loader: createLoader({
                        titleKey: 'menu__item__api_settings',
                        useOnlyCustomLink: true,
                      }),
                    },
                    {
                      path: PATHS.API_APPLICATIONS,
                      element: <Application />,
                      loader: createLoader({
                        titleKey: 'menu__item__api_settings',
                        useOnlyCustomLink: true,
                      }),
                    },
                  ],
                },
                {
                  path: PATHS.NOTIFICATION_SETTINGS,
                  element: <Notifications />,
                  loader: createLoader({
                    titleKey: 'menu_item__notification_settings',
                    useOnlyCustomLink: true,
                  }),
                },
                {
                  path: PATHS.SUBSCRIPTIONS,
                  element: <Subscriptions />,
                  loader: createLoader({
                    titleKey: 'menu_item__subscriptions',
                    useOnlyCustomLink: true,
                  }),
                },
                {
                  path: PATHS.CHALLENGE_VIDEO_TUTORIAL,
                  element: <VideoTutorial />,
                  loader: createLoader({
                    titleKey: 'menu_item__tutorial_page',
                  }),
                },
                {
                  path: PATHS.CORPORATE_ACTIONS,
                  element: <CorporateActions />,
                  loader: createLoader({
                    titleKey: 'menu_item__corporate_actions',
                    useOnlyCustomLink: true,
                  }),
                  children: [
                    {
                      path: PATHS.CORPORATE_ACTIONS_INSTRUMENT,
                      element: <CorporateActionsList />,
                      loader: createLoader({
                        titleKey: 'generic__instrument',
                        hideBreadcrumbsChildren: true,
                        useOnlyCustomLink: true,
                      }),
                    },
                    {
                      path: PATHS.CORPORATE_ACTIONS_INVESTMENT_ACCOUNTS,
                      element: <CorporateActionsPostings />,
                      loader: createLoader({
                        titleKey:
                          'corporate_actions__investment_accounts__title',
                        hideBreadcrumbsChildren: true,
                        useOnlyCustomLink: true,
                      }),
                    },
                  ],
                },
              ],
            },
            {
              path: PATHS.CHECKOUT_DEPOSIT,
              element: <CheckoutDeposit />,
              loader: createLoader({
                titleKey: 'menu_item__checkout_deposit',
              }),
            },
            {
              path: PATHS.CHECKOUT_DEPOSIT_SUCCESS,
              element: <CheckoutSuccess />,
              loader: createLoader({
                titleKey: 'menu_item__checkout_deposit_success',
              }),
            },
            {
              path: PATHS.CHECKOUT_DEPOSIT_FAILURE,
              element: <CheckoutFailure />,
              loader: createLoader({
                titleKey: 'menu_item__checkout_deposit_failure',
              }),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <Redirect />,
    },
  ];
};
