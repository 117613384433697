import { ArrowSquareRightUpIcon, DownloadCompactIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledHelpManualsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-right: 24px;
  }
`;

export const StyledDownloadCompactIcon = styled(DownloadCompactIcon)`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
`;

export const StyledArrowSquareRightUpIcon = styled(ArrowSquareRightUpIcon)`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
`;

export const StyledManualList = styled('ul')`
  padding: 0;
  margin-bottom: -8px;
`;
export const StyledManual = styled('li')`
  display: flex;
  margin: 8px 0;
  gap: 8px;

  list-style: none;

  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.typo.promo};
`;
export const StyledManualAnchor = styled('a')`
  vertical-align: top;

  text-decoration: none;
  color: ${({ theme }) => theme.color.typo.action};
`;

export const StyledManualIconAnchor = styled(StyledManualAnchor)`
  width: 24px;
  height: 24px;
`;
