import { FC } from 'react';

import {
  StyledProductImage,
  StyledProductImageDouble,
  StyledProductImageWrapper,
  StyledProductImageWrapperDouble,
} from './ProductImage.styled';
import { TProductImageProps } from './ProductImage.types';

export const ProductImage: FC<TProductImageProps> = ({
  imageUrls,
  stickToBottom,
}) => {
  if (!imageUrls) {
    return null;
  }

  if (imageUrls[1] && imageUrls[0]) {
    return (
      <StyledProductImageWrapperDouble
        className="ProductImageWrapperDouble"
        stickToBottom={stickToBottom}
      >
        <StyledProductImageDouble
          className="ProductImageDouble"
          imageUrls={imageUrls as [string, string]}
          stickToBottom={stickToBottom}
        />
      </StyledProductImageWrapperDouble>
    );
  }

  return (
    <StyledProductImageWrapper
      className="ProductImageWrapper"
      stickToBottom={stickToBottom}
    >
      <StyledProductImage
        className="ProductImage"
        imageUrl={imageUrls[0]}
        stickToBottom={stickToBottom}
      />
    </StyledProductImageWrapper>
  );
};
