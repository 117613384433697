import { ToInternalAccountState } from '../../../types';

import { initialToInternalAccountState } from './constants';
import { getErrors } from './helpers';
import { ToInternalAccountReducer } from './types';

export const toInternalAccountReducer: ToInternalAccountReducer = (
  state,
  action,
): ToInternalAccountState => {
  switch (action.type) {
    case 'DELETE_FILE': {
      const { files } = state;

      return {
        ...state,
        files: files.filter((file) => file.name !== action.payload),
      };
    }

    case 'RESET': {
      const { fetchingLimits, updatingLimits, ...rest } =
        initialToInternalAccountState;

      return {
        ...state,
        ...rest,
        currency: state.currency,
      };
    }

    case 'REQUEST_TRANSACTION_ERROR': {
      const { requestingTransactionState } = state;

      return {
        ...state,
        requestingTransactionState: {
          ...requestingTransactionState,
          error: true,
          errorMessage: 'Unable request internal transfer',
          pending: false,
        },
      };
    }

    case 'REQUEST_TRANSACTION_START': {
      const { requestingTransactionState } = state;

      return {
        ...state,
        requestingTransactionState: {
          ...requestingTransactionState,
          pending: true,
          error: false,
          errorMessage: '',
        },
      };
    }

    case 'REQUEST_TRANSACTION_SUCCESS': {
      const { requestingTransactionState, currency, fetchingLimits } = state;

      return {
        ...initialToInternalAccountState,
        currency,
        fetchingLimits: {
          ...fetchingLimits,
          idle: false,
        },
        requestingTransactionState: {
          ...requestingTransactionState,
          pending: false,
        },
      };
    }

    case 'SET_CURRENCY': {
      return {
        ...state,
        currency: action.payload,
      };
    }

    case 'SET_IS_ALL_FUNDS': {
      return {
        ...state,
        isAllFunds: action.payload,
      };
    }

    case 'SET_FILES': {
      const errors = getErrors(state.errors, 'files');
      const { files } = state;

      return {
        ...state,
        errors,
        files: [...files, ...action.payload],
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    case 'SET_REFERENCE': {
      const errors = getErrors(state.errors, 'reference');

      return {
        ...state,
        errors,
        reference: action.payload,
      };
    }

    case 'SET_MODE': {
      return {
        ...state,
        mode: action.payload,
      };
    }

    case 'SET_TARGET': {
      const { targetAccountId, name } = action.payload;

      return {
        ...state,
        targetAccountId,
        name,
      };
    }

    case 'FETCH_LIMITS_ERROR': {
      const { fetchingLimits } = state;

      return {
        ...state,
        fetchingLimits: {
          ...fetchingLimits,
          error: true,
          errorMessage: action.payload,
          pending: false,
          succeed: false,
        },
      };
    }

    case 'FETCH_LIMITS_START': {
      const { fetchingLimits } = state;

      return {
        ...state,
        fetchingLimits: {
          ...fetchingLimits,
          error: false,
          errorMessage: '',
          pending: true,
          idle: false,
        },
      };
    }

    case 'FETCH_LIMITS_SUCCESS': {
      const { fetchingLimits } = state;

      return {
        ...state,
        limit: action.payload,
        fetchingLimits: {
          ...fetchingLimits,
          succeed: true,
          pending: false,
        },
      };
    }

    case 'UPDATE_LIMITS_ERROR': {
      const { updatingLimits } = state;

      return {
        ...state,
        updatingLimits: {
          ...updatingLimits,
          error: true,
          errorMessage: action.payload,
          pending: false,
          succeed: false,
        },
      };
    }

    case 'UPDATE_LIMITS_START': {
      const { updatingLimits } = state;

      return {
        ...state,
        updatingLimits: {
          ...updatingLimits,
          error: false,
          errorMessage: '',
          pending: true,
          idle: false,
        },
      };
    }

    case 'UPDATE_LIMITS_SUCCESS': {
      const { updatingLimits } = state;

      return {
        ...state,
        limit: action.payload,
        updatingLimits: {
          ...updatingLimits,
          succeed: true,
          pending: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};
