import { useContext, useEffect, useMemo } from 'react';

import {
  TabsCacheApiContext,
  TabsCacheDataContext,
} from 'contexts/TabsCacheContext';

type TUseTableCache<DataType, FiltersType> = {
  cacheId: string;
  tableDataLoading: boolean;
  tableData?: DataType | null;
  tableFilters?: FiltersType | null;
};

type TTableCache<DataType, FiltersType> = {
  tableData: DataType;
  tableFilters?: FiltersType | null;
};

export function useTableTabCache<DataType, FiltersType>({
  cacheId,
  tableDataLoading,
  tableData,
  tableFilters,
}: TUseTableCache<DataType, FiltersType>) {
  const { getCache } = useContext(TabsCacheDataContext);
  const { setCache } = useContext(TabsCacheApiContext);

  const cache = useMemo<TTableCache<DataType, FiltersType> | null>(() => {
    return getCache<TTableCache<DataType, FiltersType>>(cacheId);
  }, [getCache, cacheId]);

  useEffect(() => {
    if (!tableData) {
      return;
    }

    setCache<TTableCache<DataType, FiltersType>>(cacheId, {
      tableData,
      tableFilters,
    });
  }, [tableData, tableFilters]);

  const useCache = !!(tableDataLoading && cache && !tableData);

  return {
    cache,
    useCache,
  };
}
