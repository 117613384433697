import { styled } from 'theme';

export const StyledAccountSelectionOptionWrapper = styled('span')`
  display: flex;
  flex-direction: column;
`;

export const StyledAccountSelectionOptionDescription = styled('span')`
  color: ${({ theme }) => theme.color.typo.ghost};

  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
