import { styled } from 'theme';

export const StyledBalance = styled('div')`
  padding: 24px 0 0;
`;

export const StyledChartWrapper = styled('div')`
  width: 100%;
  height: 400px;
  font-size: 12px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .recharts-cartesian-grid {
    line {
      stroke: ${({ theme }) => theme.color.line.primary};
    }
  }
  .recharts-cartesian-axis {
    line {
      stroke: ${({ theme }) => theme.color.bg.promo};
    }
  }
`;

export const StyledBalanceActions = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: end;
    align-items: center;
    flex-direction: row;
  }
  margin-bottom: 24px;
`;

export const StyledBalanceCalendars = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;
