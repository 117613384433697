import {
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Autocomplete,
  getBrowserDateFormat,
  getBrowserLocale,
  InputDatePicker,
  ToggleButtonGroup,
  useData,
} from 'react-ui-kit-exante';

import { TabsContextData, TranslationContext } from 'contexts';
import { Dates, today } from 'helpers/dates';
import { delay } from 'helpers/timers';
import {
  analysisService,
  TAccountsMetrics,
  TFetchAccountSymbolsParams,
} from 'services/analysis';
import { MENU_ALIAS } from 'services/menu';
import { TTimeRangesTypes } from 'services/performance';

import { AnalysisPageContext } from '../../contexts/AnalysisPageContext';
import { FilterContext } from '../../contexts/FilterContext';
import {
  isAccountHistoryResponseTimeout,
  isAccountHistoryResponseUnfinished,
} from '../AnalysisContainer/AnalysisContainer.helpers';
import { THeaderProps } from '../AnalysisContainer/AnalysisContainer.types';

import {
  StyledAnalysisHeader,
  StyledAnalysisHeaderActions,
  StyledAnalysisHeaderCalendars,
} from './AnalysisHeader.styled';

export const AnalysisHeader = ({ onFetch }: THeaderProps) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { isLoading: isConfigLoading } = useContext(AnalysisPageContext);

  const { timeRangesOptions, defaultTimeRangeKey, timeRanges } =
    useContext(AnalysisPageContext);
  const { filters, setFilters } = useContext(FilterContext);
  const { availableTabs } = useContext(TabsContextData);

  const [dateToggleFilter, setDateToggleFilter] = useState<
    TTimeRangesTypes | ''
  >(defaultTimeRangeKey);

  const onSearchSelect = (_: unknown, symbol: string[]) => {
    setFilters({ ...filters, symbol_ids: symbol });
  };

  const onDateFilterChange = (value: any) => {
    setDateToggleFilter(value);
    const range = timeRanges?.[value as TTimeRangesTypes];

    if (range) {
      setFilters({
        ...filters,
        date_from: range.date_from,
        date_to: range.date_to,
      });
    }
  };

  const browserFormat = getBrowserDateFormat(false, true, getBrowserLocale);

  const onChangeTo = (date: Date | null, event?: SyntheticEvent) => {
    const value = (event?.target as HTMLInputElement)?.value;
    if (date && (value?.length === browserFormat.length || !value)) {
      const to = Dates.format(date, false);
      setFilters({
        ...filters,
        date_to: to,
      });
    }
  };

  const onChangeFrom = (date: Date | null, event?: SyntheticEvent) => {
    const value = (event?.target as HTMLInputElement)?.value;
    if (date && (value?.length === browserFormat.length || !value)) {
      const from = Dates.format(date, false);
      setFilters({
        ...filters,
        date_from: from,
      });
    }
  };

  const onSetTimeRanges = () => {
    if (timeRanges) {
      const foundRange = Object.keys(timeRanges).find((key) => {
        const range = timeRanges[key as TTimeRangesTypes];
        return (
          range.date_to === filters.date_to &&
          range.date_from === filters.date_from
        );
      });

      if (foundRange) {
        setDateToggleFilter(foundRange as TTimeRangesTypes);
      } else {
        setDateToggleFilter('');
      }
    }
  };
  useEffect(() => {
    onSetTimeRanges();
  }, [filters.date_to, filters.date_from]);

  const getAccountSymbols = async (
    params: TFetchAccountSymbolsParams,
  ): Promise<string[]> => {
    if (!params) {
      return [];
    }

    const response = await analysisService.getAccountHistory(currentLanguage, {
      date_from: params.date_from,
      date_to: params.date_to,
      account_id: params.account_id,
    });

    if (isAccountHistoryResponseUnfinished(response)) {
      await delay(4000);

      return getAccountSymbols(params);
    }

    if (isAccountHistoryResponseTimeout(response)) {
      return [];
    }

    return (response as TAccountsMetrics).symbols.map((item) => item.symbol);
  };

  const {
    data: accountSymbols,
    fetchData: fetchSymbolsOptions,
    isLoading,
  } = useData({
    onFetch: () => getAccountSymbols(filters),
  });

  useEffect(() => {
    if (timeRanges && !filters.date_to && !filters.date_from) {
      const range = timeRanges[defaultTimeRangeKey];
      setFilters({
        ...filters,
        date_from: range.date_from,
        date_to: range.date_to,
      });
    } else {
      onSetTimeRanges();
    }
  }, [timeRanges]);

  const tabAvailable = useMemo<boolean>(
    () => !!availableTabs.find((tab) => tab.id === MENU_ALIAS.ANALYSIS),
    [availableTabs],
  );

  const isSymbolsLoading = isConfigLoading || isLoading;
  const dateTo = filters.date_to ? new Date(filters.date_to) : null;
  const dateFrom = filters.date_from ? new Date(filters.date_from) : null;

  const filtersIsValid = Boolean(
    filters.account_id && filters.date_to && filters.date_from,
  );

  useEffect(() => {
    if (tabAvailable && filtersIsValid) {
      onFetch();
    }
  }, [filters, tabAvailable]);

  useEffect(() => {
    if (tabAvailable && filtersIsValid) {
      fetchSymbolsOptions();
    }
  }, [
    filters.account_id && filters.date_to && filters.date_from,
    tabAvailable,
  ]);

  return (
    <StyledAnalysisHeader className="AnalysisHeader">
      <StyledAnalysisHeaderActions className="AnalysisHeaderActions">
        <Autocomplete
          placeholder={t('layout__performance__symbols')}
          options={accountSymbols || []}
          onChange={onSearchSelect}
          disabled={!accountSymbols?.length}
          isMultiple
          disableCloseOnSelect
          value={filters?.symbol_ids}
          size="small"
          className="filter__search-symbols"
          noOptionsText={t('layout__summary__no_options')}
          showSkeleton={isSymbolsLoading}
        />
      </StyledAnalysisHeaderActions>
      <StyledAnalysisHeaderActions className="AnalysisHeaderActions">
        <ToggleButtonGroup
          options={timeRangesOptions}
          exclusive
          value={dateToggleFilter}
          onChange={onDateFilterChange}
          disabled={isConfigLoading}
          showSkeleton={isConfigLoading}
          skeletonsCount={4}
          size="small"
        />
        <StyledAnalysisHeaderCalendars className="AnalysisHeaderCalendars">
          <InputDatePicker
            inputProps={{
              size: 'small',
              sx: {
                width: 170,
              },
            }}
            onChange={onChangeFrom}
            selected={dateFrom}
            maxDate={today}
            locale={currentLanguage}
            disabled={isConfigLoading}
            autoComplete="off"
            selectsStart
            startDate={dateFrom}
            endDate={dateTo}
          />
          <InputDatePicker
            inputProps={{
              size: 'small',
              sx: {
                width: 170,
              },
            }}
            onChange={onChangeTo}
            selected={dateTo}
            minDate={dateFrom}
            maxDate={today}
            locale={currentLanguage}
            disabled={isConfigLoading}
            autoComplete="off"
            selectsEnd
            startDate={dateFrom}
            endDate={dateTo}
          />
        </StyledAnalysisHeaderCalendars>
      </StyledAnalysisHeaderActions>
    </StyledAnalysisHeader>
  );
};
