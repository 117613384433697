import { Accordion } from 'components/Accordion';
import { styled } from 'theme';

export const StyledFAQTabContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledAccordionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledQuestionAccordion = styled(Accordion)`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-radius: 4px;

  & .AccordionButton {
    padding: 0;
    border: none;
  }

  & .AccordionTitle {
    width: 100%;

    text-align: left;

    color: ${({ theme }) => theme.color.typo.primary};
    font-family: ${({ theme }) => theme.font.header};
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  & .AccordionArrow {
    margin: 0;
  }

  & .AccordionContent {
    margin-top: 8px;
  }
`;

export const StyledAccordionTitleText = styled('div')`
  flex-grow: 1;
  line-clamp: 4;
`;

export const StyledQuestionText = styled('p')`
  margin: 0;

  color: ${({ theme }) => theme.color.typo.promo};
  font-family: ${({ theme }) => theme.font.main};
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  & a {
    color: ${({ theme }) => theme.color.typo.action};
    text-decoration: none;
  }
`;
