import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';

import { TCreateCurrencyFormatter } from './currencyFormatter';
import { formatNumber } from './formatNumber';

export function formatCurrency(
  formatter: TCreateCurrencyFormatter,
  value?: string | number | null,
  shorten?: boolean,
  fractionDigits = 2,
): string {
  const fixedNumber = formatNumber(value, fractionDigits);
  let numberedValue = Number(fixedNumber);

  if (Number.isNaN(numberedValue)) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  if (shorten) {
    const absVal = Math.abs(numberedValue);
    let label = '';
    if (absVal > 10 ** 8) {
      label = 'M';
      numberedValue = Number((numberedValue / 10 ** 6).toFixed(1));
    } else if (absVal > 10 ** 5) {
      label = 'K';
      numberedValue = Number((numberedValue / 10 ** 3).toFixed(1));
    }

    const formattedNumber = formatter.format(numberedValue);
    const lastDigitIndex = formattedNumber.search(/(\d)(?!.*\d)/g);
    const rawNumberWithLabel = formattedNumber.split('');
    rawNumberWithLabel.splice(lastDigitIndex + 1, 0, label);

    return rawNumberWithLabel.join('');
  }

  return formatter.format(numberedValue);
}
