import { ApiRequest } from 'helpers/apiRequest';
import { NO_CACHE_HEADERS } from 'services/constants';

import { TVersionResponse } from './types';

class MetaService extends ApiRequest {
  async getRemoteVersion() {
    const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    const { data } = await this.fetch<TVersionResponse>({
      url: `${baseUrl}static/media/version.json`,
      headers: NO_CACHE_HEADERS,
    });

    if (!data) {
      throw new Error('Remote version is not available.');
    }

    return data.version;
  }
}

export const metaService = new MetaService();
