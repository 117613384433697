import { styled } from 'theme';

export const StyledCryptoWrapper = styled('div')`
  display: flex;

  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-radius: 4px;
`;

export const StyledCryptoHeader = styled('h2')`
  margin: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
`;

export const StyledCryptoContent = styled('div')`
  flex: 1;
  padding: 24px;
  border-right: 1px solid ${({ theme }) => theme.color.line.primary};

  display: flex;
  flex-direction: column;
  gap: 24px;
`;
