import Decimal from 'decimal.js';
import WAValidator from 'wallet-address-validator';

import {
  cryptoCurrencyMapping,
  validatedCryptoCurrencies,
  VALIDATORS_MAP,
} from './Crypto.constants';
import { TValidateCryptoAddressProps } from './Crypto.types';

export const validateCryptoAddress = ({
  address,
  currency = '',
  allowEmpty = false,
}: TValidateCryptoAddressProps): boolean => {
  const value = (address || '').replace(/^\s+|\s+$/g, '');

  if (!value && allowEmpty) {
    return true;
  }

  currency = cryptoCurrencyMapping[currency] || currency;

  if (!validatedCryptoCurrencies.includes(currency)) {
    const validator = VALIDATORS_MAP[currency?.toLowerCase()];

    if (validator) {
      return validator(value);
    }

    return true;
  }

  return WAValidator.validate(value, currency);
};

export const validateAmount = (value: string, mpi: number): boolean => {
  return !(
    /[^\d.]/.test(value) || // only numbers and dots
    [...value.matchAll(/\./g)].length > 1 || // one dot
    new RegExp(`\\.\\d{${mpi + 1},}`).test(value)
  );
};

export const getMaxAmountAndComission = (
  realizedComission: number,
  mpi: number,
  availableFunds?: string | number | null,
): number | null => {
  if (availableFunds === null || availableFunds === 0) {
    return availableFunds;
  }

  if (availableFunds) {
    const value = new Decimal(availableFunds).sub(realizedComission);

    if (value.isNegative() || value.isZero()) {
      return 0;
    }

    return Number(value.toFixed(mpi, Decimal.ROUND_UP));
  }

  return Number(availableFunds);
};
