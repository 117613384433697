import { styled } from 'theme';

export const StyledSubuserWithdrawalWrapper = styled('div')`
  display: flex;
  flex: 1;

  min-height: 455px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    min-height: 360px;
  }
`;

export const StyledSubuserWithdrawalHeader = styled('h2')`
  margin: 0;
  font-weight: normal;
`;

export const StyledSubuserWithdrawalContent = styled('div')`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 24px;

  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 24px;
`;

export const StyledDisabledPlaceholder = styled('div')`
  padding: 8px 24px 0;
`;

export const StyledSkeletonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
