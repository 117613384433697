import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';
import { TWithdrawalHistoryEntry } from 'services/withdrawal';
import {
  WITHDRAWAL_STATUS_ICON_MAP,
  WITHDRAWAL_STATUS_KEY_MAP,
} from 'services/withdrawal/withdrawal.constants';

import { StyledWithdrawalStatus } from './WithdrawalStatus.styled';

export const WithdrawalStatus: FC<{
  status: TWithdrawalHistoryEntry['status'];
}> = ({ status }) => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledWithdrawalStatus
      className="WithdrawalStatus"
      status={status}
      title={t(WITHDRAWAL_STATUS_KEY_MAP[status])}
    >
      {WITHDRAWAL_STATUS_ICON_MAP[status]}
      {t(WITHDRAWAL_STATUS_KEY_MAP[status])}
    </StyledWithdrawalStatus>
  );
};
