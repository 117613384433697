import {
  TaxResidence,
  TReport,
  TReportsStateResponse,
} from 'services/reports/reports.types';

import { TMergedReportsState } from './TaxReportsContext.types';

export const mergeTaxReportsState = (
  state: TReportsStateResponse | null,
  isTermsAccepted: boolean | null,
  yearsRequested: number[],
): TMergedReportsState | null => {
  if (!state) {
    return null;
  }

  const isRus = state?.tax_residence === TaxResidence.Russia;

  return {
    isRus,
    terms: isRus ? state.rus_terms : state.pol_terms,
    termsAccepted: Boolean(
      (isRus ? state.rus_terms_accepted : state.pol_terms_accepted) ||
        isTermsAccepted,
    ),
    ownAccounts: state.ownAccounts,
    reportPrice: isRus ? null : state.pol_report_price,
    timingsAccepted: isRus ? null : state.pol_timings_accepted,
    taxResidence: state.tax_residence,
    taxationCountry: state.taxation_country,
    taxationCountryCode: state.taxation_country_code,
    taxationEnabled: state.taxation_enabled,
    taxationYears: state.taxation_years,
    requestedTaxYears: Array.from(
      new Set([...(state.requested_tax_years || []), ...yearsRequested]),
    ).sort((a, b) => a - b),
  };
};

export const overlapReportsUnfinished = (
  reports: TReport[] | null,
  reportsUnfinished: TReport[] | null,
) => {
  if (!reports) {
    return null;
  }

  if (!reportsUnfinished) {
    return reports;
  }

  return reports.map((report) => {
    const unfinished = reportsUnfinished.find(
      (unfin) => unfin.id === report.id,
    );

    return unfinished ? { ...report, ...unfinished } : report;
  });
};
