import { Panel, Tab } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledSourcesContainer = styled(Panel)`
  .PanelTitle {
    padding: 24px;
    margin: 0;
  }

  padding: 0;
`;

export const StyledTab = styled(Tab)`
  display: flex;
  flex-direction: row;
  align-items: center;

  .MuiTab-iconWrapper {
    margin-right: 8px;
    margin-bottom: 0;
    color: ${({ theme }) => theme.color.typo.action};
  }
`;

export const StyledBody = styled('div')`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid
    ${({ theme }) => {
      return theme.color.line.primary;
    }};
`;
