import { subMonths } from 'date-fns';

export const tableId = 'PartnerStatisticsTable';

export const dateRangeFilterName = 'fromTo';

export const defaultFromToRange = (() => {
  const today = new Date();

  return [subMonths(today, 1), today];
})();

export const partnerStatisticsFetchDateFormat = 'yyyy-MM-dd';
