import { FC, useContext, useMemo } from 'react';
import { UploadStatus } from 'react-ui-kit-exante';

import { FolderIcon, InfoIcon } from 'assets/icons';
import { BrandingContext, TranslationContext } from 'contexts';

import {
  ACCEPT_FILE_TYPES,
  DOC_UPLOAD_GROUP_PROPS,
  DocumentGroup,
} from './UploadGroup.consts';
import {
  StyledDocumentUploadGroupNotice,
  StyledDocumentUploadGroupNoticeIconWrapper,
  StyledDocumentUploadGroupNoticeText,
  StyledDocumentUploadGroupNoticeTextWrapper,
  StyledFileUpload,
  StyledFileUploadHeader,
  StyledFileUploadHeaderIconWrapper,
  StyledFileUploadHeaderText,
} from './UploadGroup.styled';
import { TUploadGroupProps } from './UploadGroup.types';

export const UploadGroup: FC<TUploadGroupProps> = ({
  group,
  onUpload,
  onDelete,
  isApproved,
}) => {
  const { branding } = useContext(BrandingContext);
  const { t } = useContext(TranslationContext);

  const supportEmail = branding?.wl?.emails?.support;

  const TooltipContent = DOC_UPLOAD_GROUP_PROPS[group.doc_type]?.tooltipContent;
  const groupIcon = DOC_UPLOAD_GROUP_PROPS[group.doc_type]?.icon || (
    <FolderIcon />
  );

  const filesInitial = useMemo(
    () =>
      group.raw.map((f) => ({
        ...f,
        uploadStatus: UploadStatus.SUCCESS,
        id: String(f.id),
      })),
    [group.raw],
  );

  return (
    <>
      <StyledFileUpload
        key={group.doc_type}
        className="FileUpload"
        accept={ACCEPT_FILE_TYPES}
        translator={t}
        customHeader={
          <StyledFileUploadHeader className="FileUploadHeader">
            <StyledFileUploadHeaderIconWrapper className="FileUploadHeaderIcon">
              {groupIcon}
            </StyledFileUploadHeaderIconWrapper>
            <StyledFileUploadHeaderText className="FileUploadHeaderText">
              {group.title}
            </StyledFileUploadHeaderText>
          </StyledFileUploadHeader>
        }
        filesInitial={filesInitial}
        onUpload={(...args) => onUpload(group.doc_type, ...args)}
        onDelete={onDelete}
        showFileSize={false}
        tooltip={TooltipContent && <TooltipContent t={t} branding={branding} />}
      />
      {group.notice && (
        <StyledDocumentUploadGroupNotice
          key={`${group.doc_type}__notice`}
          className="DocumentUploadGroupNotice"
        >
          <StyledDocumentUploadGroupNoticeIconWrapper className="DocumentUploadGroupNoticeIconWrapper">
            <InfoIcon />
          </StyledDocumentUploadGroupNoticeIconWrapper>
          <StyledDocumentUploadGroupNoticeText
            className="DocumentUploadGroupNoticeText"
            dangerouslySetInnerHTML={{
              __html: group.notice,
            }}
          />
        </StyledDocumentUploadGroupNotice>
      )}
      {group.doc_type === DocumentGroup.Other && (
        <StyledDocumentUploadGroupNotice className="DocumentUploadGroupNotice">
          <StyledDocumentUploadGroupNoticeIconWrapper className="DocumentUploadGroupNoticeIconWrapper">
            <InfoIcon />
          </StyledDocumentUploadGroupNoticeIconWrapper>
          <StyledDocumentUploadGroupNoticeTextWrapper className="DocumentUploadGroupNoticeTextWrapper">
            <StyledDocumentUploadGroupNoticeText
              className="DocumentUploadGroupNoticeText"
              dangerouslySetInnerHTML={{
                __html: `${t('layout__upload_docs__other_statement', {
                  SUPPORT_EMAIL: supportEmail,
                })}. ${t('layout__upload_docs__only_complete_apps_accepted')}`,
              }}
            />
            {!isApproved && (
              <StyledDocumentUploadGroupNoticeText className="DocumentUploadGroupNoticeText">
                {t('layout__upload_docs__it_takes_one_day_to_review')}
              </StyledDocumentUploadGroupNoticeText>
            )}
          </StyledDocumentUploadGroupNoticeTextWrapper>
        </StyledDocumentUploadGroupNotice>
      )}
    </>
  );
};
