import {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { StyledNoDataNotification } from 'components/NoDataNotification';
import { Positions } from 'components/Positions';
import {
  TPositionsCache,
  TPositionsContainerCaching,
  TPositionsContainerProps,
} from 'components/Positions/Positions.types';
import { WHITESPACE_NON_BREAKING } from 'constants/common';
import { CurrencyContext, TranslationContext } from 'contexts';
import { AccountDataContext } from 'contexts/AccountDataContext/AccountDataContext';
import { TAccountDataContextState } from 'contexts/AccountDataContext/AccountDataContext.types';
import {
  TabsCacheApiContext,
  TabsCacheDataContext,
} from 'contexts/TabsCacheContext';
import { TPortfolioTotalCache } from 'pages/Portfolio/tabs/PortfolioTotal/PortfolioTotal.types';
import { selectAccounts, selectSelectedDate } from 'store/accounts';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchColumnsVisibility, fetchTablesVisibility } from 'store/tables';
import { TPosition } from 'types/accounts';

import { POSITIONS_DEFAULT_TABLE_COLUMN_VISIBILITY_NAME } from './PortfolioTotal.constants';
import { downloadSummaryReport, getPositions } from './PortfolioTotal.helpers';
import {
  StyledPortfolioTotalPanel,
  StyledPortfolioTotalWrapper,
} from './PortfolioTotal.styled';
import { TotalSkeleton } from './components';
import {
  PortfolioTotalLoadingContext,
  TPortfolioTotalLoadingProviderValue,
} from './contexts/PortfolioTotalLoadingContext';

export const PortfolioTotal: FC = () => {
  const dispatch = useAppDispatch();

  const { selectedAccountId } = useAppSelector(selectAccounts);
  const selectedDate = useAppSelector(selectSelectedDate);

  const { t } = useContext(TranslationContext);
  const { accountData: providedAccountData, isLoading } =
    useContext(AccountDataContext);
  const { currency } = useContext(CurrencyContext);
  const { getCache } = useContext(TabsCacheDataContext);
  const { setCache } = useContext(TabsCacheApiContext);

  const cacheId = useMemo<string>(() => {
    return ['portfolio-total', currency, selectedAccountId].join('.');
  }, [currency, selectedAccountId]);

  const cachedAccountData = useMemo<
    TAccountDataContextState['accountData']
  >(() => {
    return getCache<TPortfolioTotalCache>(cacheId)?.accountData || null;
  }, [getCache, cacheId]);

  const accountData = useMemo<TAccountDataContextState['accountData']>(() => {
    if (isLoading && cachedAccountData) {
      return cachedAccountData;
    }

    return providedAccountData;
  }, [cachedAccountData, isLoading, providedAccountData]);

  const positions: TPosition[] = useMemo(
    () => getPositions(accountData),
    [accountData],
  );

  const getPositionsCache = useCallback<TPositionsContainerCaching['get']>(
    () => getCache<TPositionsCache>(cacheId),
    [getCache, cacheId],
  );

  const setPositionsCache = useCallback<TPositionsContainerCaching['set']>(
    (value) => setCache<TPositionsCache>(cacheId, value),
    [setCache, cacheId],
  );

  const positionsCaching: TPositionsContainerProps['caching'] = {
    get: getPositionsCache,
    set: setPositionsCache,
  };

  const onDownloadClick = () =>
    downloadSummaryReport({ selectedDate, selectedAccountId, currency });

  const totalAction: ReactElement = (
    <IconButton
      iconName="XLSIcon"
      iconColor="secondary"
      iconSize={24}
      onClick={onDownloadClick}
      className="TotalActionDownload"
    />
  );

  const shouldRenderTotalPanel = Boolean(
    selectedAccountId && accountData && positions.length,
  );

  const loadingProviderValue: TPortfolioTotalLoadingProviderValue = useMemo(
    () => ({
      isAccountDataLoading: isLoading && !cachedAccountData,
    }),
    [isLoading, cachedAccountData],
  );

  useEffect(() => {
    if (!isLoading) {
      setCache<TPortfolioTotalCache>(cacheId, { accountData });
    }
  }, [isLoading, setCache, cacheId, accountData]);

  useEffect(() => {
    dispatch(fetchTablesVisibility());
    dispatch(
      fetchColumnsVisibility(POSITIONS_DEFAULT_TABLE_COLUMN_VISIBILITY_NAME),
    );
  }, []);

  return (
    <StyledPortfolioTotalWrapper className="PortfolioTotalWrapper">
      {isLoading && <TotalSkeleton />}
      {!isLoading &&
        (!shouldRenderTotalPanel ? (
          <>
            {accountData !== null && (
              <StyledNoDataNotification className="NoDataNotification">
                {t('layout__account_summary__not_available')}
              </StyledNoDataNotification>
            )}
            {accountData === null && <TotalSkeleton />}
          </>
        ) : (
          <StyledPortfolioTotalPanel
            title={WHITESPACE_NON_BREAKING}
            action={totalAction}
            className="PortfolioTotalPanel"
            disableBodyPaddings
          >
            <PortfolioTotalLoadingContext.Provider value={loadingProviderValue}>
              {!!positions.length && (
                <Positions
                  positions={positions}
                  currency={currency}
                  idPrefix="AccountSummary"
                  caching={positionsCaching}
                  tableId={POSITIONS_DEFAULT_TABLE_COLUMN_VISIBILITY_NAME}
                />
              )}
            </PortfolioTotalLoadingContext.Provider>
          </StyledPortfolioTotalPanel>
        ))}
    </StyledPortfolioTotalWrapper>
  );
};
