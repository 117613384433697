import { useContext, useMemo } from 'react';

import { getOrderedCurrencies } from '../../../Transfers.helpers';
import { useTransfers } from '../../../hooks';
import { FundsContext } from '../context';

export const useToInternalAccountData = () => {
  const { transfersState } = useTransfers();
  const { selectedAccountId } = useContext(FundsContext);

  const legalEntity = useMemo(() => {
    const [id] = selectedAccountId.split('.');
    return transfersState.client_id_le_map[id];
  }, [selectedAccountId, transfersState.client_id_le_map]);

  const currencies = useMemo(() => {
    return getOrderedCurrencies(
      transfersState.wr_types?.Internal?.[legalEntity] || [],
    );
  }, [legalEntity, transfersState.wr_types?.Internal]);

  return {
    currencies,
    legalEntity,
  };
};
