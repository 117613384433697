import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledRequisiteTitleSkeleton = styled(Skeleton)`
  width: 56px;
  height: 16px;
`;

export const StyledRequisiteValueSkeleton = styled(Skeleton)`
  width: 100%;
  height: 16px;
`;
