const branchNameSearch = (p: string) => /CL-\d*|release/.test(p);

export function isBranchStand() {
  const url = window.location.href;
  return url.includes('CL-');
}

export function isStageReactStand() {
  const url = window.location.href;
  return url.includes('stage');
}

export function isLocalNginxStand() {
  const url = window.location.href;
  return url.includes('site.exante');
}

export function isExanteStand() {
  const url = window.location.href;
  return url.includes('exante.eu');
}

export const getBaseName = () => {
  const path = window.location.pathname.split('/');
  const branchName = path.find(branchNameSearch);
  return branchName ? `/${branchName}` : '';
};
