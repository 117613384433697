import { AxiosError } from 'axios';
import { useContext, useEffect, useReducer } from 'react';

import { TOOMANY_ERROR } from 'constants/api';
import { RESEND_CODE_TIME } from 'constants/index';
import { TranslationContext } from 'contexts';
import { isCodeValid } from 'helpers/string';
import { useTimer } from 'hooks';
import { mfaService, TMfaType } from 'services/mfa';

import { CodeVerificationActions } from './useCodeVerification.actions';
import {
  codeVerificationInitialState,
  INIT_DELAY,
} from './useCodeVerification.constants';
import { useCodeVerificationReducer } from './useCodeVerification.reducer';
import {
  TCodeVerificationOptions,
  TCodeVerificationReducer,
} from './useCodeVerification.types';

export const useCodeVerification = (
  codeLength: number,
  type: TMfaType,
  options?: TCodeVerificationOptions,
) => {
  const timer = useTimer(RESEND_CODE_TIME, { startOnInit: false });
  const { t } = useContext(TranslationContext);
  const [state, dispatch] = useReducer<TCodeVerificationReducer>(
    useCodeVerificationReducer,
    codeVerificationInitialState,
  );

  const handleCodeChange = (payload: string) => {
    if (isCodeValid(payload, codeLength)) {
      dispatch({ type: CodeVerificationActions.OnCodeChange, payload });

      if (options?.onChange) {
        options?.onChange(payload);
      }
    }
  };

  const handleResetTimer = () => {
    if (!options?.disableTimer) {
      timer.start();
    }
  };

  const handleResend = async () => {
    dispatch({ type: CodeVerificationActions.RequestStart });

    try {
      await mfaService.resendByType(type, 'site', INIT_DELAY);
      dispatch({ type: CodeVerificationActions.RequestSuccess });
    } catch (error) {
      const { response } = error as AxiosError;

      let payload =
        response?.data.detail ||
        t('layout__actions__unknown_error_please_refresh_page');

      if (response?.status === TOOMANY_ERROR) {
        payload = t('layout__settings__mfa__resend_429');
      }

      dispatch({ type: CodeVerificationActions.RequestError, payload });
    }
  };

  const handleVerify = async () => {
    dispatch({ type: CodeVerificationActions.VerifyStart });

    try {
      await mfaService.checkCode(state.code, 'site');

      dispatch({ type: CodeVerificationActions.VerifySuccess });
      if (options?.onSuccess) {
        options?.onSuccess();
      }
    } catch (error) {
      dispatch({
        type: CodeVerificationActions.VerifyError,
        payload: t('layout__code_expired'),
      });
    }
  };

  useEffect(() => {
    if (
      !state.isVerifying &&
      state.code.length === codeLength &&
      !options?.disableVerification
    ) {
      handleVerify();
    }
  }, [state.code]);

  return {
    ...state,
    seconds: timer.time,
    handleResetTimer,
    handleCodeChange,
    handleResend,
  };
};
