import {
  createElement,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Input, ISelectOption, Loader, useData } from 'react-ui-kit-exante';

import { CheckoutDepositErrorIcon } from 'assets/icons';
import { CurrencyContext, TranslationContext } from 'contexts';
import { notifyWith } from 'helpers/notifyWith';
import { useQuery } from 'hooks/index';
import { checkoutService } from 'services/checkout/checkout';

import {
  StyledCheckoutAmount,
  StyledCheckoutAmountSelect,
  StyledCheckoutButton,
  StyledCheckoutNote,
  StyledCheckoutStatus,
  StyledCheckoutTitle,
  StyledCheckoutWrapper,
  StyledCurrencyIcon,
} from '../Checkout.styled';

export const CheckoutDeposit: FC = () => {
  const { t } = useContext(TranslationContext);
  const { currencies: currenciesInfo, currency: preferableUserCurrency } =
    useContext(CurrencyContext);

  const [forbidden, setForbidden] = useState(false);
  const [chosenCurrency, setChosenCurrency] = useState(preferableUserCurrency);
  const [amount, setAmount] = useState('');
  const [isMakeDepositPageLoading, setIsMakeDepositPageLoading] =
    useState(false);
  const query = useQuery();
  const accountId = query.get('account_id');

  const makeDepositPageAndRedirect = async () => {
    const data = {
      amount: parseFloat(amount).toFixed(2),
      currency: chosenCurrency,
      account_id: accountId,
    };
    setIsMakeDepositPageLoading(true);

    try {
      const { data: hostedPage } =
        await checkoutService.createHostedPayment(data);
      window.localStorage.setItem('last_checkout_id', hostedPage.id);
      window.location.href = hostedPage.redirect_url;
    } catch (error: any) {
      setIsMakeDepositPageLoading(false);

      notifyWith.serverError(error?.response?.data?.error);
    }
  };

  const getCheckoutAvailableCurrencies = async () => {
    try {
      const { data: availableCurrencies } =
        await checkoutService.getCurrencies();

      if (!availableCurrencies.includes(chosenCurrency)) {
        setChosenCurrency(availableCurrencies[0]);
      }

      return availableCurrencies;
    } catch (error: any) {
      if (error.response.status === 403) {
        setForbidden(true);
        notifyWith.error('checkout__create_deposit__forbidden');
      } else {
        notifyWith.serverError(error?.message);
      }

      return null;
    }
  };
  const getCheckoutLimits = async () => {
    try {
      return (await checkoutService.getLimits()).data;
    } catch (error: any) {
      if (error.response.status !== 403) {
        notifyWith.serverError(error?.message);
      }

      return null;
    }
  };

  const {
    fetchData: fetchAvailableCurrencies,
    data: availableCurrencies,
    isLoading: isAvailableCurrenciesLoading,
  } = useData({ onFetch: getCheckoutAvailableCurrencies });

  const {
    fetchData: fetchLimits,
    data: limits,
    isLoading: isLimitsLoading,
  } = useData({ onFetch: getCheckoutLimits });

  useEffect(() => {
    fetchAvailableCurrencies();
    fetchLimits();
  }, []);

  const currentCurrencyLimit = useMemo(() => {
    return limits?.[chosenCurrency] || 0;
  }, [chosenCurrency, limits]);

  const isLimitExceeded = useMemo(() => {
    return parseFloat(amount || '0') > currentCurrencyLimit;
  }, [amount, currentCurrencyLimit]);

  const isDepositButtonDisabled = useMemo(() => {
    return !amount || parseFloat(amount) <= 0 || isLimitExceeded;
  }, [amount, limits]);

  const currenciesOptions = useMemo<ISelectOption[] | undefined>(() => {
    return availableCurrencies?.map((currency: string) => {
      const currencyInfo = currenciesInfo?.icons?.[currency];

      return {
        value: currency,
        label: currencyInfo?.icon ? '' : currencyInfo?.symbol || currency,
        icon: currencyInfo?.icon ? (
          <StyledCurrencyIcon
            className="CurrencyIcon"
            alt={currencyInfo?.symbol || currency}
            src={currencyInfo.icon}
          />
        ) : undefined,
      };
    });
  }, [availableCurrencies, currenciesInfo]);

  const inputPlaceholder = useMemo(() => {
    return t('checkout__create_deposit__amount_placeholder', {
      CURRENCY: chosenCurrency,
    });
  }, [chosenCurrency, t]);

  const limitText = useMemo(() => {
    return t('checkout__create_deposit__max_deposit', {
      AMOUNT: String(currentCurrencyLimit),
      CURRENCY: chosenCurrency,
    });
  }, [chosenCurrency, currentCurrencyLimit, t]);

  if (!currenciesInfo || isAvailableCurrenciesLoading || isLimitsLoading) {
    return <Loader isCentered />;
  }

  if (forbidden) {
    return (
      <StyledCheckoutWrapper className="CheckoutWrapper">
        <StyledCheckoutStatus className="CheckoutStatus">
          {createElement(CheckoutDepositErrorIcon)}
          <p>{t('checkout__create_deposit__forbidden')}</p>
        </StyledCheckoutStatus>
      </StyledCheckoutWrapper>
    );
  }

  return (
    <StyledCheckoutWrapper className="CheckoutWrapper">
      <StyledCheckoutTitle className="CheckoutTitle">
        {t('checkout__create_deposit__title')}
      </StyledCheckoutTitle>
      <StyledCheckoutAmount className="CheckoutAmount">
        <StyledCheckoutAmountSelect
          options={currenciesOptions || []}
          value={chosenCurrency}
          onChange={(e) => setChosenCurrency(e.target.value)}
        />
        <Input
          placeholder={inputPlaceholder}
          error={isLimitExceeded}
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
        />
      </StyledCheckoutAmount>
      {isLimitExceeded ? (
        <StyledCheckoutNote className="CheckoutNote">
          {limitText}
        </StyledCheckoutNote>
      ) : null}
      <StyledCheckoutButton
        disabled={isDepositButtonDisabled}
        onClick={makeDepositPageAndRedirect}
        loading={isMakeDepositPageLoading}
        fullWidth
      >
        {t('checkout__deposit__ok_btn')}
      </StyledCheckoutButton>
    </StyledCheckoutWrapper>
  );
};
