import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Notification as UiKitNotification } from 'react-ui-kit-exante';

import { RESEND_CODE_TIME } from 'constants/RESEND_CODE_TIME';
import { BrandingContext, TranslationContext } from 'contexts';
import { calcPasswordSecurity, securityLevelTitles } from 'helpers/password';
import { useTimer } from 'hooks';
import { TwoFAConfirmation } from 'pages/Security/pages/Password/components/TwoFAConfirmation/TwoFAConfirmation';
import { passwordService } from 'services/settings/password/password';
import {
  TChangePasswordForm,
  TChangePasswordRequest,
  TChangePasswordResponse,
} from 'services/settings/password/password.types';

import {
  CHANGE_PASSWORD_FORM_VALIDATION_SCHEMA,
  EPasswordType,
} from './Password.consts';
import {
  StyledFormInputContainer,
  StyledFormRow,
  StyledHeader,
  StyledPanel,
  StyledSecurity,
  StyledSecurityLevel,
} from './Password.styled';

export const Password: FC = () => {
  const { branding } = useContext(BrandingContext);
  const { t, currentLanguage } = useContext(TranslationContext);

  const [changeResult, setChangeResult] = useState<
    Omit<
      TChangePasswordResponse,
      EPasswordType.OldPassword & EPasswordType.NewPassword
    >
  >({
    key: undefined,
    tokenid: undefined,
    mfa_type: undefined,
    stage: undefined,
  });

  const [isDataSaving, setIsDataSaving] = useState(false);

  const { start, timeSecondsFormat, time, clear } = useTimer(RESEND_CODE_TIME);
  const [show2FAPopup, setShow2FAPopup] = useState(false);
  const [code, setCode] = useState('');
  const [isCodeError, setIsCodeError] = useState(false);

  const defaultValues = {
    [EPasswordType.OldPassword]: '',
    [EPasswordType.NewPassword]: '',
    [EPasswordType.RepeatPassword]: '',
  };

  const methods = useForm<TChangePasswordForm>({
    defaultValues,
    resolver: yupResolver(CHANGE_PASSWORD_FORM_VALIDATION_SCHEMA(t)),
  });

  const {
    handleSubmit,
    getValues,
    formState: { dirtyFields },
    reset,
    watch,
    setError,
  } = methods;

  const clearChangeResult = () => {
    setChangeResult({
      stage: undefined,
      key: undefined,
      mfa_type: undefined,
    });
  };

  const onClose2FAPopup = () => {
    setShow2FAPopup(false);
    setCode('');
    setIsCodeError(false);
    clearChangeResult();
  };

  const onSave = async () => {
    setIsDataSaving(true);

    const values = getValues();
    const data: TChangePasswordRequest = {
      new_password: values.new_password,
      old_password: values.old_password,
    };

    if (changeResult.stage === '2fa') {
      data.key = changeResult.key;
      data.code2fa = code;
    }

    const response = await passwordService.changePassword(
      data,
      currentLanguage,
    );

    setIsDataSaving(false);

    if (!response) {
      return;
    }

    if (response?.new_password) {
      setError(EPasswordType.NewPassword, {
        message: response.new_password[0],
      });
    }
    if (response?.old_password) {
      setError(EPasswordType.OldPassword, {
        message: response.old_password[0],
      });
    }

    if (response?.stage) {
      switch (response?.stage) {
        case 'done': {
          reset();
          clear();
          onClose2FAPopup();
          if (branding?.password_change_success_text) {
            UiKitNotification.success({
              title: t(branding.password_change_success_text),
            });
          }
          break;
        }
        case '2fa': {
          setChangeResult({
            key: response.key,
            tokenid: response.tokenid,
            mfa_type: response.mfa_type,
            stage: response.stage,
          });
          break;
        }
        default:
          break;
      }
      return;
    }

    if (response?.error && changeResult?.stage === '2fa') {
      setIsCodeError(true);
    }
  };

  const getPasswordSecurityLabel = useCallback(() => {
    const newPassword = getValues(EPasswordType.NewPassword);
    if (newPassword) {
      return calcPasswordSecurity(newPassword);
    }
    return '';
  }, [watch(EPasswordType.NewPassword)]);

  const passwordSecurityLabel = getPasswordSecurityLabel();
  const passwordSecurityText = passwordSecurityLabel
    ? securityLevelTitles(t)[passwordSecurityLabel]
    : '';

  useEffect(() => {
    if (changeResult.stage === '2fa') {
      if (time === 0) {
        start();
      }
      setShow2FAPopup(true);
    }
  }, [changeResult]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)}>
        <StyledPanel>
          <StyledHeader className="Header">
            <span className="Title">
              {t('layout__settings__here_you_can_change_password_to')}
            </span>
            <TwoFAConfirmation
              onClose2FAPopup={onClose2FAPopup}
              dirtyFields={dirtyFields}
              changeResult={changeResult}
              isDataSaving={isDataSaving}
              isCodeError={isCodeError}
              code={code}
              setCode={setCode}
              show2FAPopup={show2FAPopup}
              startTimer={start}
              onSave={onSave}
              timeSecondsFormat={timeSecondsFormat}
            />
          </StyledHeader>
          <StyledFormRow className="FormRow">
            <StyledFormInputContainer
              type="password"
              label={t('layout__settings__old_password')}
              name={EPasswordType.OldPassword}
              readOnly={isDataSaving}
              fullWidth={false}
              className="FormInputContainer"
            />
            <StyledFormInputContainer
              type="password"
              label={t('layout__auth__new_password')}
              name={EPasswordType.NewPassword}
              readOnly={isDataSaving}
              fullWidth={false}
              className="FormInputContainer"
            />
            <StyledFormInputContainer
              type="password"
              label={t('layout__auth__repeat_password')}
              name={EPasswordType.RepeatPassword}
              readOnly={isDataSaving}
              fullWidth={false}
              className="FormInputContainer"
            />
          </StyledFormRow>
          {passwordSecurityLabel && (
            <StyledSecurity className="Security">
              {t('layout__auth__security')}:
              <StyledSecurityLevel
                level={passwordSecurityLabel}
                className="SecurityLevel"
              >
                {passwordSecurityText}
              </StyledSecurityLevel>
            </StyledSecurity>
          )}
        </StyledPanel>
      </form>
    </FormProvider>
  );
};
