import { TFunction } from 'i18next';
import { createContext, FC, ReactNode, useMemo, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { languagesService } from 'services/languages';

import { useTranslate } from './hooks';

export type TTranslationState = {
  t: TFunction;
  templateT: (template: string) => string;
  isTranslationsLoading: boolean;
  currentLanguage: string;
  onChangeLanguage: (value: string) => void;
  changeLanguage: (lng: string) => Promise<void>;
};

const initialState: TTranslationState = {
  // @ts-expect-error -- i18n TFunction return type will be fixed in verion 23+
  t: (() => '') as TFunction,
  templateT: () => '',
  isTranslationsLoading: false,
  currentLanguage: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeLanguage: (_: string) => {},
  changeLanguage: () => Promise.resolve(),
};

export const TranslationContext =
  createContext<TTranslationState>(initialState);

export const TranslationProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { t, changeLanguage, loading, getCurrentLanguage } = useTranslate();

  const [isSettingLanguage, setIsSettingLanguage] = useState<boolean>(false);

  const currentLanguage = useMemo(
    () => getCurrentLanguage(),
    [getCurrentLanguage()],
  );

  // used with html templates <div>$(some__key__to_translate)</div>;
  // totally the same as in library function t(), but correct
  const templateT = (template: string): string => {
    const regex = /\$t\(([^)]*)\)/g;

    const matches = template.match(regex) || [];

    matches.forEach((match) => {
      template = template.replaceAll(match, t(match));
    });

    return template;
  };

  const onChangeLanguage = async (value: string) => {
    setIsSettingLanguage(true);
    const changed = await languagesService.setCurrentLanguage(value);
    if (changed) {
      await changeLanguage(value);
    } else {
      Notification.error({ title: 'Error while changing language' });
    }
    setIsSettingLanguage(false);
  };

  const isLoading = loading || isSettingLanguage;

  const value = useMemo<TTranslationState>((): TTranslationState => {
    return {
      t,
      templateT,
      isTranslationsLoading: isLoading,
      currentLanguage,
      onChangeLanguage,
      changeLanguage,
    };
  }, [isLoading, currentLanguage, t]);

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};
