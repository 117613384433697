import { KJUR } from 'jsrsasign';
import { eq } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';
import { notifyWith } from 'helpers/notifyWith';
import { usePrevious } from 'hooks/index';
import { PATHS } from 'router/router.constants';
import { dashboardService, TKeyScopeItem } from 'services/dashboard';

import { useApi } from '../../../hooks';

export const useApplication = () => {
  const { currentLanguage, t } = useContext(TranslationContext);
  const [tab, setTab] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    applications,
    fetching,
    succeed,
    opts,
    handleApplicationScopesChange,
    handleUpdateApplication,
    handleApplicationDelete,
  } = useApi();
  const [isSaving, setIsSaving] = useState(false);
  const params = useParams<{ id: string }>();
  const nav = useNavigate();

  const result = applications.find((item) => item.id === params.id);
  const prevResult = usePrevious(result);

  const decodedJson = useMemo(() => {
    if (!result) {
      return {};
    }

    return {
      iss: result.environment === 0 ? opts.live_access : opts.demo_access,
      sub: result.id,
      // eslint-disable-next-line no-bitwise
      iat: ~~(Date.now() / 1000),
      // eslint-disable-next-line no-bitwise
      exp: ~~(Date.now() / 1000 + 3600 * 6),
      aud: result.scopes,
    };
  }, [result]);

  const decoded = useMemo(() => {
    try {
      return JSON.stringify(decodedJson, null, 2);
    } catch (e) {
      return '';
    }
  }, [decodedJson]);

  const encoded = useMemo(() => {
    if (!result?.key) {
      return '';
    }

    const sHeader = JSON.stringify({ alg: 'HS256', typ: 'JWT' });

    return KJUR.jws.JWS.sign(null, sHeader, decodedJson, result.key);
  }, [decodedJson]);

  const handleClose = () => {
    nav(`../${PATHS.API_MANAGEMENT}`);
  };

  const handleSave = async (scopes: TKeyScopeItem[]) => {
    if (params.id && result && !isSaving) {
      setIsSaving(true);
      const response = await dashboardService.updateApplicationScope(
        params.id,
        scopes,
      );

      if (response?.application) {
        handleUpdateApplication({
          ...result,
          created: response.application.created,
          secret: response.application.secret,
        });

        setIsSaving(false);

        notifyWith.success(t('layout__settings__api__update_success'));
      }
    }
  };

  const scopeItemChecked = (scope: TKeyScopeItem) => {
    return result?.scopes?.includes(scope);
  };

  const handleScopesChange = (payload: TKeyScopeItem) => {
    const disabled =
      result?.scopes?.length === 1 && result?.scopes?.includes(payload);

    if (!disabled) {
      handleApplicationScopesChange(payload, params.id);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      if (params.id) {
        await dashboardService.deleteApplication(currentLanguage, params.id);
        setIsDeleting(false);
        handleApplicationDelete(params.id);
        nav(`../${PATHS.API_MANAGEMENT}`);
      }
    } catch (e) {
      setIsDeleting(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (
      result?.scopes &&
      prevResult?.scopes &&
      !eq(prevResult.scopes, result.scopes) &&
      !isSaving
    ) {
      handleSave(result.scopes);
    }
  }, [result?.scopes]);

  useEffect(() => {
    if (!fetching && succeed && !result) {
      handleClose();
    }
  }, [result, succeed]);

  return {
    decoded,
    encoded,
    handleClose,
    handleDelete,
    handleScopesChange,
    isDeleting,
    isLoading: fetching,
    isSaving,
    result,
    scopeItemChecked,
    setTab,
    tab,
  };
};
