import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  settingsNotificationsService,
  TReportsSaveSettingsValues,
  TSettingsNotifications,
} from 'services/settings/notifications';

export const fetchSettingsNotifications = createAsyncThunk<
  TSettingsNotifications | null,
  undefined
>('settingsNotifications/fetch', async () => {
  return settingsNotificationsService.getSettingsNotifications();
});

export const saveSettingsNotifications = createAsyncThunk<
  TSettingsNotifications | null,
  TReportsSaveSettingsValues
>('settingsNotifications/save', async (values: TReportsSaveSettingsValues) => {
  return settingsNotificationsService.saveSettingsNotifications(values);
});
