import { AutocompleteRenderGetTagProps } from '@mui/material';
import { useCallback } from 'react';

import { StyledChipItem } from './SettingsItem.styled';

export const useRenderTags = (primaryEmail?: string, disabled?: boolean) => {
  return useCallback(
    (tagValue: string[], getTagProps: AutocompleteRenderGetTagProps) =>
      tagValue.map((option, index) => {
        const props = getTagProps({ index });
        const isDisabled = disabled && option === primaryEmail;

        return (
          <StyledChipItem
            {...props}
            key={props.key}
            label={option}
            isDisabled={isDisabled}
          />
        );
      }),
    [primaryEmail, disabled],
  );
};
