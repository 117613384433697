export const INSTRUMENTS_TREE_APIS_MAP = {
  permissions: {
    tree: '/clientsarea/rest/symbols/short_allowance/permissions_effective_tree/',
    instruments:
      '/clientsarea/rest/symbols/short_allowance/permissions_effective/',
    report:
      '/clientsarea/rest/symbols/short_allowance/permissions_effective_xls/',
  },
  commission: {
    tree: '/clientsarea/rest/symbols/commissions/commissions_tree/',
    instruments: '/clientsarea/rest/symbols/commissions/commissions/',
    report: '/clientsarea/rest/symbols/commissions/commissions_xls/',
  },
  overnight: {
    tree: '/clientsarea/rest/symbols/overnights/overnights_tree/',
    instruments: '/clientsarea/rest/symbols/overnights/overnights/',
    report: '/clientsarea/rest/symbols/overnights/overnights_xls/',
  },
};
