import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useData, useTableData, TCommonParams } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/index';
import {
  accountSettingsService,
  TAccountSetting,
} from 'services/settings/accountSettings';
import { TPaymentAccountData } from 'services/settings/accountSettings/accountSettings.types';

import { accountsTableContextInitialValue } from './AccountsTableContext.constants';
import {
  TAccountsTableContext,
  TAccountsTableContextProps,
} from './AccountsTableContext.types';
import { useAccountsDescriptions } from './hooks/useAccountsDescriptions/useAccountsDescriptions';

export const AccountsTableContext = createContext<TAccountsTableContext>(
  accountsTableContextInitialValue,
);

export const AccountsTableContextProvider: FC<TAccountsTableContextProps> = ({
  children,
  client,
}: TAccountsTableContextProps) => {
  const clientId = client?.clientId;
  const { currentLanguage } = useContext(TranslationContext);
  const [changedAccountList, setChangedAccountList] = useState<
    TAccountSetting[] | null
  >(null);

  const [searchedAccountId, setSearchedAccountId] = useState<string>('');
  const [initialAccountsLength, setInitialAccountsLength] = useState<number>(0);

  const abortController = useRef(new AbortController());

  const tableDataArgs = useMemo(
    () => ({
      data: {
        onFetch: async ({ params }: { params: TCommonParams }) => {
          if (!clientId) {
            return accountSettingsService.accountsTableEmpty;
          }

          abortController.current.abort();
          abortController.current = new AbortController();

          return accountSettingsService.getTableAccounts({
            params,
            clientId,
            lang: currentLanguage,
            searchedAccountId,
            options: {
              signal: abortController.current.signal,
            },
          });
        },
      },
    }),
    [client, currentLanguage, searchedAccountId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    setSorting,
    isLoading: isAccountsLoading,
  } = useTableData(tableDataArgs);

  const {
    fetchData: fetchAutoDebitData,
    data: fetchedAutoDebitData,
    isLoading: isAutoDebitDataLoading,
  } = useData({
    onFetch: async () => {
      return accountSettingsService.getAutoDebitData(currentLanguage);
    },
  });

  const accountsList = useMemo(() => {
    return data?.data.accounts || [];
  }, [data?.data.accounts]);

  const totalRecords = data?.iTotalRecords || 0;

  const setPaymentData = (
    accountId: string,
    paymentData: TPaymentAccountData,
  ) => {
    const newData = [...accountsList];

    newData.forEach((accountSettings) => {
      if (accountSettings.id === accountId) {
        accountSettings.autodebit_account = paymentData.autodebitAccount;
      }
    });

    setChangedAccountList(newData);
  };

  const isAnyDescriptionEditable = Boolean(
    accountsList.find((accountData) => accountData.is_description_editable),
  );
  const isAnyMarginTradingEditable = Boolean(
    accountsList.find((accountData) => accountData.is_margin_trading_editable),
  );
  const isAnyAutoDebitEditable = Boolean(
    accountsList.find((accountData) => accountData.is_autodebit_editable),
  );
  const isAnyAutoDebitShowing = Boolean(
    accountsList.find((accountData) => accountData.is_autodebit_showing),
  );
  const isAnyAutoConversionEditable = Boolean(
    accountsList.find((accountData) => accountData.is_auto_conversion_editable),
  );

  const autoDebitData =
    fetchedAutoDebitData || accountSettingsService.autoDebitDataEmpty;

  const { accountDescriptions, updateAccountDescription } =
    useAccountsDescriptions(autoDebitData.descriptions);

  const setAccountsSearch = (accountId: string) => {
    setSearchedAccountId(accountId);
  };

  useEffect(() => {
    setChangedAccountList(null);
  }, [isAccountsLoading]);

  useEffect(() => {
    setSearchedAccountId('');
  }, [clientId]);

  useEffect(() => {
    setSearchedAccountId('');
    if (clientId && !fetchedAutoDebitData) {
      fetchAutoDebitData();
    }
  }, [clientId]);

  useEffect(() => {
    if (data?.data?.accounts?.length && !searchedAccountId) {
      setInitialAccountsLength(data.data.accounts.length);
    }
  }, [data]);

  const isLoading: boolean =
    isAccountsLoading || isAutoDebitDataLoading || !client;

  const value = useMemo<TAccountsTableContext>(
    (): TAccountsTableContext => ({
      autoDebitData,
      isAutoDebitDataLoading,
      setPaymentData,
      accountsList: changedAccountList || accountsList,
      isLoading,
      setSorting,
      limit,
      setPage,
      setLimit,
      page,
      totalRecords,
      isAnyDescriptionEditable,
      isAnyAutoDebitEditable,
      isAnyAutoDebitShowing,
      isAnyAutoConversionEditable,
      isAnyMarginTradingEditable,
      accountDescriptions,
      updateAccountDescription,
      setAccountsSearch,
      searchedAccountId,
      initialAccountsLength,
    }),
    [
      autoDebitData,
      isAutoDebitDataLoading,
      accountsList,
      isLoading,
      setSorting,
      limit,
      setPage,
      setLimit,
      page,
      totalRecords,
      isAnyDescriptionEditable,
      isAnyAutoDebitEditable,
      isAnyAutoDebitShowing,
      isAnyAutoConversionEditable,
      isAnyMarginTradingEditable,
      fetchAutoDebitData,
      accountDescriptions,
      setAccountsSearch,
      searchedAccountId,
      initialAccountsLength,
    ],
  );

  return (
    <AccountsTableContext.Provider value={value}>
      {children}
    </AccountsTableContext.Provider>
  );
};
