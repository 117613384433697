import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TranslationContext } from 'contexts';
import { useCards } from 'pages/CardSettings/hooks';
import { cardService, TCard } from 'services/cards';
import { CARD_STATUS } from 'services/cards/cards.constants';
import { CardFeesTypes } from 'services/serviceFees';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TChildren } from 'types/TChildren';

import {
  cardSettingsContextInitialValue,
  IssueType,
} from './CardSettingsContext.constants';
import {
  TCardSettingsContextValue,
  TOnOrderProps,
} from './CardSettingsContext.types';

export const CardSettingsContext = createContext<TCardSettingsContextValue>(
  cardSettingsContextInitialValue,
);

export const CardSettingsProvider: FC<TChildren> = ({ children }) => {
  const { cardsInfo, cardsFees, updateCards, cardsLoading, pollCards } =
    useCards();
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const { currentLanguage } = useContext(TranslationContext);
  const [issueType, setIssueType] = useState<IssueType | null>(null);
  const [isOrdering, setIsOrdering] = useState<boolean>(false);

  const cards = useMemo<TCard[] | null>(
    () =>
      cardsInfo?.cards.filter(
        (card) => card.account_id === selectedAccountId,
      ) || null,
    [cardsInfo?.cards, selectedAccountId],
  );

  const cardFeesCurrency = useMemo<string | null>(
    () => cardsFees?.currency || null,
    [cardsFees],
  );

  const getCardFeeByType = useCallback(
    (type: CardFeesTypes) => {
      return cardsFees?.serviceFees?.find((fee) => fee.name === type);
    },
    [cardsFees],
  );

  const orderCard = async (props: TOnOrderProps): Promise<boolean> => {
    if (!selectedAccountId) {
      return false;
    }

    const cardsQuantity = cards?.length || 0;
    const pollTimeout = cardsQuantity === 0 ? Infinity : 30;

    setIsOrdering(true);
    const res = await cardService.orderCard({
      ...props,
      account: selectedAccountId,
      lang: currentLanguage,
    });
    setIsOrdering(false);

    setIssueType(null);
    pollCards(pollTimeout);

    return res;
  };

  const value = useMemo((): TCardSettingsContextValue => {
    const orderData = cardsInfo?.order.data;
    const refs = cardsInfo?.refs;

    return {
      cardsLoading,
      cards,
      order: {
        available: cardsInfo?.order.available || null,
        deliveryLocation: orderData?.deliveryLocation || '',
        cardholderName: orderData?.cardholder || '',
        availableCardTypes: (refs?.limitGroups || []).map(
          ([cardTypeValue = '', labelKey = '']) => ({
            value: cardTypeValue,
            labelKey,
          }),
        ),
      },
      cardholderFullName: refs?.fullName || '',
      currencies: refs?.currencies || null,
      cardFeesCurrency,
      issueType,
      isIssueAvailable:
        !cards?.some(
          ({ status }) =>
            status !== CARD_STATUS.BLOCKED && status !== CARD_STATUS.DELETED,
        ) || false,
      isOrdering,
      onOrder: orderCard,
      onIssue: () => setIssueType(IssueType.Issue),
      onReissue: () => setIssueType(IssueType.Reissue),
      onCloseIssue: () => setIssueType(null),
      updateCards,
      getCardFeeByType,
    };
  }, [cardsInfo, cardsFees, issueType, orderCard, isOrdering]);

  useEffect(() => {
    setIssueType(null);
    setIsOrdering(false);
  }, [selectedAccountId]);

  return (
    <CardSettingsContext.Provider value={value}>
      {children}
    </CardSettingsContext.Provider>
  );
};
