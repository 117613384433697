import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { StyledChallengePayoutFormActions } from './ChallengePayout.styled';

export const ChallengePayoutSkeleton: FC = () => {
  return (
    <>
      {[...Array(5)].map((_, k) => (
        <Skeleton
          key={`block_${k}`}
          variant="rounded"
          animation="wave"
          height={46}
        />
      ))}
      <StyledChallengePayoutFormActions className="ChallengePayoutFormActions">
        <Skeleton variant="rounded" animation="wave" height={48} width={100} />
        <Skeleton variant="rounded" animation="wave" height={48} width={200} />
      </StyledChallengePayoutFormActions>
    </>
  );
};
