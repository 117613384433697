import { useContext, useState } from 'react';

import { TranslationContext } from 'contexts';
import { notifyWith } from 'helpers/notifyWith';
import { splitByCountryCode } from 'helpers/phone';
import { loginService } from 'services/login';
import { SignupStage } from 'services/login/login.types';

import { useCaptcha } from '../../../../hooks/useCaptcha';
import { TSignupForm } from '../../Signup.types';

import { TUseSignupProps } from './useSignup.types';

export const useSignup = ({ getValues, onError }: TUseSignupProps) => {
  const { currentLanguage } = useContext(TranslationContext);

  const [isLoading, setIsLoading] = useState(false);

  const { captcha, setCaptcha, setCaptchaToken, captchaRef } = useCaptcha();

  const signup = async () => {
    const values = getValues();

    setIsLoading(true);

    const { data, errors } = await loginService.signup({
      ...values,
      lang: currentLanguage,
      phone: splitByCountryCode(values.phone, values.country_code),
      full_name: [values.first_name, values.last_name]
        .filter((value) => !!value)
        .join(' '),
    });

    if (errors) {
      Object.entries<string[]>(errors).forEach(([subject, messages]) => {
        const field = ({ non_field_errors: 'email', full_name: 'first_name' }[
          subject
        ] || subject) as keyof TSignupForm;

        onError(field, messages[0]);
      });

      setIsLoading(false);
      return;
    }

    switch (data.stage) {
      case SignupStage.Captcha: {
        setCaptcha((state) => ({ ...state, ...data }));
        setIsLoading(false);
        break;
      }

      case SignupStage.Done: {
        window.location.href = data.redirect;
        break;
      }

      default: {
        notifyWith.genericError();
        console.error(`Unhandled signup response`);
        setIsLoading(false);
      }
    }
  };

  return {
    signup,
    isLoading,
    captcha,
    captchaRef,
    setCaptcha,
    setCaptchaToken,
  };
};
