import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TMenuResponse } from './menu.types';

class MenuService extends ApiRequest {
  async getMenu() {
    try {
      const { data } = await this.fetch<TMenuResponse>({
        url: '/clientsarea/html/menu/',
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      return null;
    }
  }
}

export const menuService = new MenuService();
