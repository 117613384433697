import { useContext } from 'react';
import { BuildIcon, DemoIcon } from 'react-ui-kit-exante';

import { StyledTabs } from 'components/Tabs';
import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';

import { useApi } from '../../hooks';

import {
  StyledBody,
  StyledSourcesContainer,
  StyledTab,
} from './Sources.styled';
import {
  AddAction,
  Footer,
  Header,
  SourceItem,
  SourcesSkeleton,
} from './comonents';
import { useSources } from './hooks';

export const Sources = () => {
  const { t } = useContext(TranslationContext);
  const { tab, handleSetTab, fetching } = useApi();
  const { sources, clientID, dataURL, tradeURL, isSuperVisor } = useSources();

  if (fetching) {
    return <SourcesSkeleton />;
  }

  return (
    <StyledSourcesContainer
      title={t('menu__item__api_settings')}
      action={sources.length < 30 && !isSuperVisor ? <AddAction /> : undefined}
    >
      <StyledTabs onChange={handleSetTab} value={tab}>
        <StyledTab
          label="Live"
          value={0}
          icon={<BuildIcon size={24} color="action" />}
        />
        <StyledTab
          label="Demo"
          value={1}
          icon={<DemoIcon size={24} color="action" />}
        />
      </StyledTabs>
      <Header count={sources.length} />
      <StyledBody className="Body">
        {sources.map((source) => (
          <SourceItem value={source} key={source.id} />
        ))}
      </StyledBody>
      <Footer clientID={clientID} dataURL={dataURL} tradeURL={tradeURL} />
    </StyledSourcesContainer>
  );
};
