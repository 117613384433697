import { uniq, without } from 'lodash';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BrandingContext } from 'contexts/BrandingContext';
import { TWRFields } from 'services/branding';
import { TransferType } from 'services/transfer';
import { TWithdrawalType } from 'services/withdrawal';
import { WITHDRAWAL_FORMAT } from 'services/withdrawal/withdrawal.constants';
import { selectAccounts } from 'store/accounts';

import { TTransfersState } from '../../context';

import { getRestrictedFieldsForType } from './helpers';
import { getDefaultFields } from './useTransferFormsConfig.consts';
import {
  TBrandingFieldsSettings,
  TFieldsConfig,
} from './useTransferFormsConfig.types';

const initialConfig = {
  requiredFields: [],
  notRequiredFields: [],
  visibleFields: [],
  hiddenFields: [],
};

export const useTransferFormsConfig = (
  type: TWithdrawalType,
  transfersState?: TTransfersState,
) => {
  const { branding } = useContext(BrandingContext);
  const { selectedAccountId } = useSelector(selectAccounts);

  const le = useMemo(() => {
    if (selectedAccountId) {
      const [id] = selectedAccountId.split('.');
      return transfersState?.client_id_le_map[id] || '';
    }

    return '';
  }, [selectedAccountId]);

  const wrForm = branding?.branding?.wr_form_settings?.wr_forms as TWRFields[];

  const brandingFieldsSettings = wrForm.reduce<TBrandingFieldsSettings>(
    (acc, curr) => {
      const state = curr.wr_fields.reduce<TFieldsConfig>((res, field) => {
        const fieldsConfig = { ...res };

        if (field.required) {
          fieldsConfig.requiredFields = [
            ...(res?.requiredFields || []),
            field.name,
          ];
        }
        if (!field.required) {
          fieldsConfig.notRequiredFields = [
            ...(res?.notRequiredFields || []),
            field.name,
          ];
        }

        if (field.visible) {
          fieldsConfig.visibleFields = [
            ...(res?.visibleFields || []),
            field.name,
          ];
        }

        if (!field.visible) {
          fieldsConfig.hiddenFields = [
            ...(res?.hiddenFields || []),
            field.name,
          ];
        }

        return fieldsConfig;
      }, initialConfig);

      return {
        ...acc,
        [curr.wr_type]: state,
      };
    },
    {},
  );

  const getVisibleFields = () => {
    const hiddenFields = brandingFieldsSettings[type]?.hiddenFields || [];

    const visibleFields = without(
      getDefaultFields(type, { branding }),
      ...hiddenFields,
    );
    return without(visibleFields, ...hiddenFields);
  };

  const getRequiredFields = (currency?: string, wrFormat?: string) => {
    const fields = getVisibleFields();
    const notRequiredFields =
      brandingFieldsSettings[type]?.notRequiredFields || [];
    const requiredFields = brandingFieldsSettings[type]?.requiredFields || [];

    let result;

    switch (type) {
      case TransferType.Security:
        result = without(
          fields,
          ...[
            'comment',
            branding?.branding?.wr_documents_required ? 'files' : '',
          ],
        );
        break;

      case TransferType.WireThirdParty:
      case TransferType.Wire: {
        result = without(
          fields,
          ...[
            wrFormat === WITHDRAWAL_FORMAT.SEPA ? 'swift' : '',
            transfersState?.user?.is_corporate && le === 'GBXP' ? '' : 'files',
            transfersState?.user?.is_corporate && le === 'GBXP'
              ? ''
              : 'correspondent_swift',
            currency === 'RUB' ||
            (transfersState?.user?.is_corporate && le === 'GBXP')
              ? ''
              : 'correspondent_account',
          ],
        );
        break;
      }

      default:
        result = without(fields, ...['files']);
        break;
    }

    const allFields = uniq([
      ...without(result, ...notRequiredFields),
      ...requiredFields,
    ]);

    return without(
      allFields,
      ...getRestrictedFieldsForType(type, transfersState, allFields),
    );
  };

  const getVisibleNotRequiredFields = () => {
    return uniq([...without(getVisibleFields(), ...getRequiredFields())]);
  };

  return {
    getRequiredFields,
    visibleFields: getVisibleFields(),
    requiredFields: getRequiredFields(),
    notRequiredFields: getVisibleNotRequiredFields(),
  };
};
