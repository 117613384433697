import { IconButton } from 'react-ui-kit-exante';

import { DownloadCompactIcon, ExportCompactIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledActionsWrapper = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledDownloadCompactIcon = styled(DownloadCompactIcon)`
  width: 16px;
  height: 16px;

  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledExportCompactIcon = styled(ExportCompactIcon)`
  width: 16px;
  height: 16px;

  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledActionIconAnchor = styled('a')`
  width: 16px;
  height: 16px;
`;

export const StyledActionIconButton = styled(IconButton)`
  width: 16px;
  height: 16px;
`;

export const StyledHiddenInput = styled('input')`
  display: none !important;
`;
