import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { StyledCardInfoBlockWrapper } from 'pages/CardSettings/components/CardSettingsCard/components/CardInfoBlock/CardInfoBlock.styled';

export const CardInfoBlockSkeleton: FC = () => (
  <StyledCardInfoBlockWrapper className="CardInfoBlockSkeleton" loading>
    <Skeleton width={80} height={24} />
    <Skeleton width={40} height={16} />
  </StyledCardInfoBlockWrapper>
);
