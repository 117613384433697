import { FC, useContext } from 'react';
import { Button, Skeleton } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';

import {
  StyledSubuserWithdrawalConfirmationButtonsWrapper,
  StyledSubuserWithdrawalConfirmationFormHeader,
  StyledSubuserWithdrawalConfirmationFormQuestion,
  StyledSubuserWithdrawalConfirmationFormWrapper,
} from './SubuserWithdrawalConfirmationForm.styled';
import { TSubuserWithdrawalConfirmationFormProps } from './SubuserWithdrawalConfirmationForm.types';

export const SubuserWithdrawalConfirmationForm: FC<
  TSubuserWithdrawalConfirmationFormProps
> = ({
  amount,
  currency,
  disabled,
  loading,
  requesting,
  onConfirm,
  onCancel,
}) => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledSubuserWithdrawalConfirmationFormWrapper className="SubuserWithdrawalConfirmationFormWrapper">
      <StyledSubuserWithdrawalConfirmationFormHeader className="SubuserWithdrawalConfirmationFormHeader">
        {loading ? (
          <Skeleton width={100} height={32} />
        ) : (
          t('layout__withdrawal__subuser_confirmation')
        )}
      </StyledSubuserWithdrawalConfirmationFormHeader>
      <StyledSubuserWithdrawalConfirmationFormQuestion className="SubuserWithdrawalConfirmationFormQuestion">
        {loading ? (
          <Skeleton width="100%" height={48} />
        ) : (
          t('layout__withdrawal__subuser_confirm', {
            AMOUNT: amount,
            CURRENCY: currency,
          })
        )}
      </StyledSubuserWithdrawalConfirmationFormQuestion>
      <StyledSubuserWithdrawalConfirmationButtonsWrapper className="SubuserWithdrawalConfirmationButtonsWrapper">
        <Button
          fullWidth
          onClick={onConfirm}
          showSkeleton={loading}
          loading={requesting}
          disabled={disabled}
        >
          {t('generic__action__confirm')}
        </Button>
        <Button
          fullWidth
          color="secondary"
          onClick={onCancel}
          showSkeleton={loading}
          disabled={disabled || requesting}
        >
          {t('generic__action__cancel')}
        </Button>
      </StyledSubuserWithdrawalConfirmationButtonsWrapper>
    </StyledSubuserWithdrawalConfirmationFormWrapper>
  );
};
