import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';

import { useBindingCheck } from 'hooks/useBindingCheck';
import { useRouteData } from 'hooks/useRouteData';
import { useTokensUpdate } from 'hooks/useTokensUpdate';
import { useAppDispatch } from 'store/hooks';
import { fetchMenu } from 'store/menu';

export const Root: FC = () => {
  useBindingCheck();

  const dispatch = useAppDispatch();

  const routeData = useRouteData();

  const tokensEnabled = !routeData.disableTokenCheck;
  const { isWaiting: isLocked } = useTokensUpdate(tokensEnabled);

  useEffect(() => {
    if (isLocked) {
      return;
    }

    dispatch(fetchMenu());
  }, [isLocked]);

  if (isLocked) {
    return <Loader isCentered />;
  }

  return <Outlet />;
};
