import { createAsyncThunk } from '@reduxjs/toolkit';

import { accountsService } from 'services/accounts';
import {
  TAccountsState,
  TFetchAccountsProps,
} from 'store/accounts/accounts.types';

import { mapUserAccountsResponse } from './accounts.helpers';

export const fetchAccountsAction = createAsyncThunk(
  'accounts/fetch',
  async ({
    getUserAccountsParams,
    translate,
  }: TFetchAccountsProps): Promise<Partial<TAccountsState> | null> => {
    try {
      const response = await accountsService.getUserAccounts(
        getUserAccountsParams,
      );

      return mapUserAccountsResponse(response, translate);
    } catch (error) {
      console.error('fetchAccountsAction', error);
      return null;
    }
  },
);

export const fetchAllAccountDescriptionsAction = createAsyncThunk(
  'accountDescriptions/fetch',
  async (): Promise<Record<string, string> | null> => {
    const response = await accountsService.getAllAccountDescriptions();

    if (!response) {
      return null;
    }

    return Object.fromEntries(
      response.results.map(({ account_id, description }) => [
        account_id,
        description,
      ]),
    );
  },
);
