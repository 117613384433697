import { combineReducers } from '@reduxjs/toolkit';

import { accountsSlice } from 'store/accounts/accounts.slice';
import { applicationSlice } from 'store/application/application.slice';
import { menuSlice } from 'store/menu/menu.slice';
import { settingsNotificationsSlice } from 'store/settingsNotifications/settingsNotifications.slice';
import { tablesSlice } from 'store/tables/tables.slice';
import { withdrawalSlice } from 'store/withdrawal/withdrawal.slice';

export const rootReducer = combineReducers({
  [applicationSlice.name]: applicationSlice.reducer,
  [menuSlice.name]: menuSlice.reducer,
  [accountsSlice.name]: accountsSlice.reducer,
  [tablesSlice.name]: tablesSlice.reducer,
  [settingsNotificationsSlice.name]: settingsNotificationsSlice.reducer,
  [withdrawalSlice.name]: withdrawalSlice.reducer,
});
