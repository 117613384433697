import { sha512_256 } from 'js-sha512';
import { base32 } from 'rfc4648';

export const CRYPTO_WITHDRAWAL_MODE = {
  EDIT: 'edit',
  CONFIRM: 'confirm',
} as const;

export const cryptoValidator = {
  VEO(address: string): boolean {
    let valid = true;
    try {
      const decodedAddress = window.atob(address);
      if (decodedAddress.length !== 65 || decodedAddress[0] !== '\u0004') {
        valid = false;
      }
    } catch (error) {
      valid = false;
    }
    return valid;
  },

  Algo(address: string): boolean {
    // https://developer.algorand.org/docs/features/accounts/
    // https://github.com/Swyftx/crypto-address-validator/blob/dev/src/algo_validator.js
    function correctPadding(a: string): string | number {
      if (a.length % 8 === 0) {
        return a.length;
      }

      return a + '='.repeat(8 - (a.length % 8));
    }

    if (address.length !== 58) {
      return false;
    }
    // Decode base32 Address
    const decoded = Buffer.from(base32.parse(String(correctPadding(address))));
    const addr = decoded.slice(0, decoded.length - 4);
    const checksum = decoded.slice(-4).toString('hex');

    // Hash Address - Checksum
    const hash = sha512_256.create();
    hash.update(addr);
    const code = hash.hex().slice(-8);
    return code === checksum;
  },
} as const;

export const VALIDATORS_MAP: Record<string, (address: string) => boolean> = {
  veo: cryptoValidator.VEO,
  algo: cryptoValidator.Algo,
  eursa: cryptoValidator.Algo,
};

export const cryptoCurrencyMapping: Record<string, string> = {
  EURS: 'ETH',
  USDT: 'ETH',
};

export const validatedCryptoCurrencies: string[] = [
  'AUR',
  'BVC',
  'BIO',
  'BTC',
  'BCH',
  'BTG',
  'BTCP',
  'BTCZ',
  'CLO',
  'DASH',
  'DCR',
  'DGB',
  'DOGE',
  'ETH',
  'ETC',
  'ETZ',
  'FRC',
  'GRLC',
  'HUSH',
  'KMD',
  'LTC',
  'MEC',
  'NMC',
  'NEO',
  'GAS',
  'PPC',
  'XPM',
  'PTS',
  'QTUM',
  'XRP',
  'SNG',
  'VTC',
  'VOT',
  'ZEC',
  'ZCL',
  'ZEN',
];

export const CRYPTO_DEFAULT_MIN_VALUE = 0.00000001;
export const CRYPTO_DEFAULT_MIN_MPI = 8;

export const cryptoCurrenciesWithHint: ReadonlyArray<string> = [
  'ETH',
  'USDT',
  'EURS',
];
