import { FC, useContext } from 'react';

import { BrandingContext } from 'contexts/BrandingContext';
import { PartnerTypes } from 'pages/Partner/PartnerKyc/PartnerKyc.constants';
import { TPartnerLandingImages } from 'services/branding';

import { StyledPartnerProceedPicture } from './PartnerProceedPicture.styled';
import { TPartnerProceedPictureProps } from './PartnerProceedPicture.types';

export const PartnerProceedPicture: FC<TPartnerProceedPictureProps> = ({
  type,
}) => {
  const { branding } = useContext(BrandingContext);

  const partnerImages: TPartnerLandingImages | undefined =
    branding?.branding?.ui_settings?.partner_landing_images;
  const proceedPictureDesktop =
    type === PartnerTypes.CPA
      ? partnerImages?.proceed_cpa_bg_desktop
      : partnerImages?.proceed_revshare_bg_desktop;
  const proceedPictureMobile =
    type === PartnerTypes.CPA
      ? partnerImages?.proceed_cpa_bg_mobile
      : partnerImages?.proceed_revshare_bg_mobile;

  return (
    <picture>
      <source media="(max-width: 1023px)" srcSet={proceedPictureMobile} />
      <source media="(min-width: 1024px)" srcSet={proceedPictureDesktop} />
      <StyledPartnerProceedPicture
        className="PartnerProceedPicture"
        src={proceedPictureMobile}
        alt=""
        loading="lazy"
      />
    </picture>
  );
};
