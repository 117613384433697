import { useContext, useEffect, useMemo } from 'react';

import { BrandingContext } from 'contexts/BrandingContext';
import { TransfersContext } from 'pages/Portfolio/tabs/Transfers/context';
import { TransferType } from 'services/transfer';

import { getOrderedCurrencies } from '../../../Transfers.helpers';
import { useTransferFormsConfig, useTransfers } from '../../../hooks';
import { getRestrictedFieldsForType } from '../../../hooks/useTransferFormsConfig/helpers';
import { FundsContext } from '../context';
import { ToBankAccountFields } from '../types';

export const useToBankAccountData = () => {
  const { transfersState } = useTransfers();

  const { branding } = useContext(BrandingContext);
  const { fetchServiceFees, cashTransferTab } = useContext(TransfersContext);
  const { toBankAccountState, selectedAccountId } = useContext(FundsContext);

  const { getRequiredFields } = useTransferFormsConfig(
    toBankAccountState.type,
    transfersState,
  );

  const isPersonalIbanInfoTextVisible = useMemo(() => {
    if (
      !branding?.branding?.wr_form_settings?.wr_forms ||
      !Array.isArray(branding?.branding?.wr_form_settings?.wr_forms) ||
      branding?.branding?.wr_form_settings?.wr_forms.length === 0
    ) {
      return false;
    }

    const settingsFields = branding.branding.wr_form_settings.wr_forms.find(
      (item: any) => item.wr_type === toBankAccountState.type,
    );

    if (
      !settingsFields?.wr_fields ||
      !Array.isArray(settingsFields.wr_fields) ||
      settingsFields?.wr_fields.length === 0
    ) {
      return false;
    }

    const settings = settingsFields.wr_fields.find(
      (item: any) => item.name === 'use_personal_iban',
    );

    return Boolean(settings?.info_text_visible);
  }, [branding?.branding?.wr_form_settings?.wr_forms, toBankAccountState.type]);

  const legalEntity = useMemo(() => {
    const [id] = selectedAccountId.split('.');
    return transfersState.client_id_le_map[id];
  }, [selectedAccountId, transfersState.client_id_le_map]);

  const personalIbanLegalEntity = useMemo(() => {
    if (
      toBankAccountState.fields.use_personal_iban &&
      transfersState.accounts.paymentAccount
    ) {
      const clientId =
        transfersState.accounts.paymentAccount.split('.')[0] || '';
      return transfersState.client_id_le_map[clientId];
    }
    return null;
  }, [
    toBankAccountState.fields.use_personal_iban,
    transfersState.accounts.paymentAccount,
    transfersState.client_id_le_map,
  ]);

  const hiddenFields = useMemo(() => {
    const result: Array<keyof ToBankAccountFields> = [];

    const settings = transfersState.branding?.wr_fields_settings;
    if (settings && settings[toBankAccountState.type]) {
      return new Set<keyof ToBankAccountFields>([
        ...result,
        ...settings[toBankAccountState.type].hidden_fields,
        ...getRestrictedFieldsForType(toBankAccountState.type, transfersState),
      ]);
    }

    return new Set<keyof ToBankAccountFields>(result);
  }, [toBankAccountState.type, transfersState]);

  const requiredFields = useMemo(() => {
    return new Set<keyof ToBankAccountFields>(
      getRequiredFields(
        toBankAccountState.currency,
        toBankAccountState.wrFormat,
      ) as Array<keyof ToBankAccountFields>,
    );
  }, [
    toBankAccountState.currency,
    toBankAccountState.wrFormat,
    getRequiredFields,
  ]);

  const isWiredToSelf = toBankAccountState.type === TransferType.Wire;

  const isSelfEnabled =
    transfersState.wr_types?.[TransferType.Wire]?.[legalEntity]?.length;

  const maxDescriptionLength =
    transfersState.wr_types?.[toBankAccountState.type]
      ?.max_description_length &&
    transfersState.wr_types?.[toBankAccountState.type]?.max_description_length[
      legalEntity
    ];

  const currencies = useMemo(() => {
    const le = personalIbanLegalEntity || legalEntity;
    return getOrderedCurrencies(
      transfersState.wr_types?.[toBankAccountState.type]?.[le] || [],
    );
  }, [
    transfersState.wr_types,
    toBankAccountState.type,
    legalEntity,
    personalIbanLegalEntity,
  ]);

  const isAccountPayment =
    transfersState.selectedAccountInfo.accountPurpose === 'Payment';

  const isThirdPartyEnabled = useMemo(() => {
    return (
      isAccountPayment &&
      Boolean(
        transfersState?.wr_types?.['Wire third-party']?.[legalEntity]?.length,
      )
    );
  }, [isAccountPayment, legalEntity, transfersState?.wr_types]);

  const isPersonalIbanVisible = useMemo(() => {
    if (hiddenFields.has('use_personal_iban')) {
      return false;
    }

    if (!transfersState.accounts.paymentAccount) {
      return false;
    }

    return transfersState.accounts.ibansData.some(
      (account) => account.currency === toBankAccountState.currency,
    );
  }, [hiddenFields, transfersState.accounts, toBankAccountState.currency]);

  const commissionAccount = useMemo(() => {
    return cashTransferTab === 'BANK' &&
      toBankAccountState.fields.use_personal_iban
      ? transfersState.accounts.ownPaymentAccount
      : selectedAccountId;
  }, [
    selectedAccountId,
    cashTransferTab,
    transfersState.accounts.paymentAccount,
    toBankAccountState.fields.use_personal_iban,
    transfersState.selectedAccountInfo.accountPurpose,
  ]);

  useEffect(() => {
    if (commissionAccount) {
      fetchServiceFees(commissionAccount);
    }
  }, [commissionAccount]);

  return {
    isThirdPartyEnabled,
    isPersonalIbanVisible,
    isAccountPayment,
    currencies,
    legalEntity,
    hiddenFields,
    requiredFields,
    isWiredToSelf,
    isSelfEnabled,
    maxDescriptionLength,
    isPersonalIbanInfoTextVisible,
    commissionAccount,
  };
};
