import { blockNonNativeProps, css, Select } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledSelectProps } from './CurrencySelect.types';

export const StyledSelect = styled(Select, {
  shouldForwardProp: blockNonNativeProps(['hasIcon', 'currencyIconClassName']),
})<TStyledSelectProps>`
  & .MuiInputLabel-root {
    padding: 1px;
  }

  & .MuiFilledInput-input span {
    margin: 0;
  }

  & .CurrencyCode {
    margin: 0;
    ${({ hasIcon }) =>
      hasIcon &&
      css`
        padding-top: 8px;
      `}
  }

  & .${({ currencyIconClassName }) => currencyIconClassName} {
    display: none;
  }
`;

export const StyledCurrencyIcon = styled('img')`
  width: 24px;
  height: 24px;
`;

export const StyledCurrencyFallbackIcon = styled('div')`
  min-width: 24px;
  min-height: 24px;

  color: ${({ theme }) => theme.color.typo.primary};
  background-color: ${({ theme }) => theme.color.icon.ghost};
  border-radius: 50%;

  text-align: center;
  font-size: 11px;

  line-height: 24px;
`;

export const StyledCurrencyCode = styled('div')`
  margin-left: 8px;

  // if there are no icon before the text
  &:first-child {
    margin: 0;
  }
`;
