import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { AccountSelection } from 'components/AccountSelection';
import { StyledNoDataNotification } from 'components/NoDataNotification';
import { Tabs } from 'components/Tabs';
import { BrandingContext, TranslationContext } from 'contexts';
import { TabsCacheProvider } from 'contexts/TabsCacheContext/TabsCacheContext';
import { getNoAccountsText } from 'helpers/getNoAccountsText';
import { useRouteAssociatedAccounts } from 'hooks';
import { StyledPortfolioPanel } from 'pages/Portfolio/Portfolio.styled';
import { AccountSummary } from 'pages/Portfolio/components';
import { UpdatePortfolioContext } from 'pages/Portfolio/contexts';
import { PORTFOLIO_TAB_ID } from 'services/tabs';
import { selectAccounts } from 'store/accounts';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchMetadataState, fetchWithdrawalState } from 'store/withdrawal';

export const PortfolioWithContext = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const dispatch = useAppDispatch();
  const { selectedAccount } = useSelector(selectAccounts);

  const accounts = useRouteAssociatedAccounts();
  const { isAccountsLoading } = useAppSelector(selectAccounts);
  const { needToUpdateAccountData } = useContext(UpdatePortfolioContext);

  const [showBalance, setShowBalance] = useState(false);

  const noAccountText: string = getNoAccountsText(branding, t);

  const isNoAccounts = !isAccountsLoading && !accounts.length;

  const toggleBalance = () => setShowBalance((value) => !value);

  useEffect(() => {
    dispatch(fetchWithdrawalState(currentLanguage));
  }, []);

  useEffect(() => {
    if (selectedAccount?.value) {
      dispatch(fetchMetadataState(selectedAccount));
    }
  }, [selectedAccount?.value]);

  return isNoAccounts ? (
    <StyledNoDataNotification
      className="NoDataNotification"
      dangerouslySetInnerHTML={{ __html: noAccountText }}
    />
  ) : (
    <StyledPortfolioPanel>
      <AccountSelection
        titleKey="Portfolio"
        needToUpdateAccountData={needToUpdateAccountData}
        showBalance={showBalance}
        onBalanceButtonClick={toggleBalance}
      />

      <AccountSummary showBalance={showBalance} />

      <Tabs tabsForShow={PORTFOLIO_TAB_ID} />
      <TabsCacheProvider>
        <Outlet />
      </TabsCacheProvider>
    </StyledPortfolioPanel>
  );
};
