import { FC, useContext, useEffect } from 'react';
import { Button, Panel, Tooltip, useData } from 'react-ui-kit-exante';

import { InfoIcon } from 'assets/icons';
import { PageWrapper } from 'components/PageWrapper';
import { CHALLENGE_STATE_ACTIVE } from 'constants/CHALLENGE';
import {
  BrandingContext,
  TranslationContext,
  UserDetailsContext,
} from 'contexts';
import { challengeService } from 'services/challenge';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import {
  StyledChallengePurchaseAction,
  StyledChallengePurchaseActions,
} from './ChallengePurchase.styled';
import { ChallengePurchaseSkeleton } from './ChallengePurchaseSkeleton';

export const ChallengePurchases: FC = () => {
  const { branding } = useContext(BrandingContext);
  const { t } = useContext(TranslationContext);
  const { userDetails } = useContext(UserDetailsContext);

  const { selectedAccountId } = useAppSelector(selectAccounts);

  const purchasesPageSettings =
    branding?.branding?.challenge_settings?.purchases_page;

  const getChallenge = async () => {
    if (!selectedAccountId) {
      return null;
    }

    return (await challengeService.getChallenge(selectedAccountId))?.data;
  };

  const {
    fetchData: fetchChallenge,
    data: challenge,
    isLoading: isChallengeLoading,
  } = useData({ onFetch: getChallenge });

  useEffect(() => {
    fetchChallenge();
  }, [selectedAccountId]);

  const isShowPurchaseResetBtn =
    purchasesPageSettings?.show_purchase_reset_btn &&
    challenge?.plan_override?.reset &&
    challenge?.plan_override?.state === CHALLENGE_STATE_ACTIVE;

  const isShowPurchaseExtendBtn =
    purchasesPageSettings?.show_purchase_extend_btn &&
    challenge?.plan_override?.extend &&
    challenge?.plan_override?.state === CHALLENGE_STATE_ACTIVE;

  const isShowPurchaseNewChallengeBtn =
    purchasesPageSettings?.show_purchase_new_challenge_btn &&
    challenge?.plan_override?.new_challenge;

  const getParams: Record<string, string> = {
    userId: userDetails?.info?.current.email || '',
    accountId: selectedAccountId || '',
    challengeId: String(challenge?.plan_override?.plan_id),
  };
  const getParamsString = new URLSearchParams(getParams).toString();

  const resetBtnHref = `${purchasesPageSettings?.purchase_reset_btn__redirect}?${getParamsString}`;
  const extendBtnHref = `${purchasesPageSettings?.purchase_extend_btn__redirect}?${getParamsString}`;
  const newChallengeBtnHref = `${purchasesPageSettings?.purchase_new_challenge_btn__redirect}?${getParamsString}`;

  const isLoading = isChallengeLoading || !challenge || !branding;

  const isPurchasesNotAvailable =
    !isShowPurchaseResetBtn &&
    !isShowPurchaseExtendBtn &&
    !isShowPurchaseNewChallengeBtn;

  const redirect = (href: string) => {
    window.open(href, '_blank');
  };

  return (
    <PageWrapper>
      <Panel
        title={t('challenge_purchase__title')}
        className="ChallengeAccountPurchaseWrapper"
      >
        <StyledChallengePurchaseActions className="ChallengePurchaseActions">
          {isLoading ? (
            <ChallengePurchaseSkeleton />
          ) : (
            <>
              {isPurchasesNotAvailable && (
                <p>
                  {t('challenge_purchases__account_purchases_not_available')}
                </p>
              )}
              {isShowPurchaseResetBtn && (
                <StyledChallengePurchaseAction className="ChallengePurchaseAction">
                  <Button
                    onClick={() => redirect(resetBtnHref)}
                    className="ChallengePurchaseButton"
                    data-test-id="challenge_purchases__reset_btn"
                  >
                    {t('challenge_purchases__reset_btn')}
                  </Button>

                  <Tooltip
                    title={t('challenge_purchases__reset_btn__hover')}
                    data-test-id="challenge_purchases__reset_btn__hover"
                  >
                    <InfoIcon className="ChallengePurchaseTooltip" />
                  </Tooltip>
                </StyledChallengePurchaseAction>
              )}
              {isShowPurchaseExtendBtn && (
                <StyledChallengePurchaseAction className="ChallengePurchaseAction">
                  <Button
                    onClick={() => redirect(extendBtnHref)}
                    className="ChallengePurchaseButton"
                    data-test-id="challenge_purchases__extend_btn__hover"
                  >
                    {t('challenge_purchases__extend_btn')}
                  </Button>

                  <Tooltip
                    title={t('challenge_purchases__extend_btn__hover')}
                    data-test-id="challenge_purchases__extend_btn__hover"
                  >
                    <InfoIcon className="ChallengePurchaseTooltip" />
                  </Tooltip>
                </StyledChallengePurchaseAction>
              )}
              {isShowPurchaseNewChallengeBtn && (
                <StyledChallengePurchaseAction className="ChallengePurchaseAction">
                  <Button
                    onClick={() => redirect(newChallengeBtnHref)}
                    className="ChallengePurchaseButton"
                    data-test-id="challenge_purchases__new_challenge_btn"
                  >
                    {t('challenge_purchases__new_challenge_btn')}
                  </Button>
                  <Tooltip
                    title={t('challenge_purchases__new_challenge_btn__hover')}
                    data-test-id="challenge_purchases__new_challenge_btn__hover"
                  >
                    <InfoIcon className="ChallengePurchaseTooltip" />
                  </Tooltip>
                </StyledChallengePurchaseAction>
              )}
            </>
          )}
        </StyledChallengePurchaseActions>
      </Panel>
    </PageWrapper>
  );
};
