import { useMediaQuery } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { calculateCountOfPages, Table } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { useTheme } from 'theme';

import { CUSTOM_REPORTS_TABLE_ID } from './CustomReportsTable.constants';
import { getColumns, TABLE_SORT_BY } from './columns';
import { CustomReportsContext } from './contexts/CustomReportsContext';

export const CustomReportsTable: FC = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { tableData } = useContext(CustomReportsContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const columns = useMemo(
    () => getColumns({ t, isMobile }),
    [currentLanguage, isMobile],
  );

  const { isLoading, data, limit, setLimit, setPage, page } = tableData;

  const { results: reports, count: total } = data || {};

  const getCellProps = useCallback(() => {
    if (isMobile) {
      return {
        style: {
          padding: 0,
        },
      };
    }
    return {};
  }, [isMobile]);

  return (
    <Table
      data={reports || []}
      isLoading={isLoading}
      skeletonsCount={9}
      tableId={CUSTOM_REPORTS_TABLE_ID}
      columns={columns}
      getCellProps={getCellProps}
      noHeader={isLoading || isMobile}
      singleColored={isMobile}
      translator={t}
      locale={currentLanguage}
      isHiddenColumnSelect
      isFlexLayout
      disableSortBy
      manualSortBy
      defaultSortBy={TABLE_SORT_BY}
      hasPagination
      serverPaginationProps={{
        pageSize: limit,
        setPage,
        setPageSize: setLimit,
        pageIndex: page,
        total: total ?? 0,
        pageCount: calculateCountOfPages(total ?? 0, limit),
      }}
    />
  );
};
