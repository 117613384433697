import { TStyledDialogTooltipButtonProps } from 'components/DialogTooltip/DialogTooltip.types';
import { styled } from 'theme';

export const StyledDialogTooltipHeading = styled('p')`
  margin: 8px 0;
  font-family: ${({ theme }) => theme.font.main};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: ${({ theme }) => theme.color.typo.primary};
`;

export const StyledDialogTooltipSubheading = styled(StyledDialogTooltipHeading)`
  color: ${({ theme }) => theme.color.typo.ghost};
`;

export const StyledDialogTooltipActions = styled('div')`
  display: flex;
  gap: 8px;
  margin-top: 16px;
`;

export const StyledDialogTooltipButton = styled('button')`
  display: flex;
  gap: 4px;
  background: none;
  border: none;
  padding: 0;
  outline: inherit;

  font-family: ${({ theme }) => theme.font.main};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: ${({ theme, disabled }) =>
    disabled ? theme.color.typo.ghost : theme.color.typo.action};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const StyledDialogTooltipButtonSecondary = styled(
  StyledDialogTooltipButton,
)<TStyledDialogTooltipButtonProps>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.typo.ghost : theme.color.typo.secondary};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;
