import { styled } from 'theme';

export const StyledSecuritiesHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const StyledSecuritiesTitle = styled('div')`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;

export const StyledTransferContent = styled('div')`
  padding: 24px;
`;

export const StyledSecuritiesForm = styled('form')`
  display: flex;
  width: 100%;
`;
