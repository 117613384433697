import { TFunction } from 'i18next';

import { THelpTab } from '../../../../services/help';

export const translateQuestions = (tabs: THelpTab[], t: TFunction) =>
  tabs.map((tab) => ({
    ...tab,
    questions: tab.questions.map((question) => {
      const translatedText = t(question.text, {
        LINK: question.link,
      });
      const translatedTitle = t(question.title, question.title);

      return { ...question, text: translatedText, title: translatedTitle };
    }),
  }));
