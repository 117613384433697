export enum PartnerKycStatuses {
  PRODUCT_NOT_SENT = 'not sent',
  PRODUCT_ON_REVIEW = 'on review',
  PRODUCT_REJECTED = 'pending',
  PRODUCT_APPROVED = 'approved',
}

export const PARTNER_TYPE = {
  CPA: 'Cost per Action',
  REVSHARE: 'Revenue Share',
} as const;
