import { css } from 'react-ui-kit-exante';

import { AccountMenuIcon, CloseIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledLangSelectorList = styled('ul')`
  margin: 0;
  padding: 0;

  list-style: none;
  background-color: ${({ theme }) => theme.color.bg.primary};

  position: fixed;
  top: ${({ theme }) => theme.sizes.mobileHeaderHeight};
  left: 0;
  z-index: 10;

  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.sizes.mobileHeaderHeight});
  overflow-y: auto;

  & > * {
    padding: 12px 13px;
  }
`;

const IconStyle = css`
  :hover {
    transform: scale(1.2);
    transition: 0.3s ease;
  }
`;

export const StyledLangSelectorCloseIcon = styled(CloseIcon)(IconStyle);
export const StyledLangSelectorAccountMenuIcon =
  styled(AccountMenuIcon)(IconStyle);
