import { Button, IconButton } from 'react-ui-kit-exante';

import { DeleteAccountIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledUserInfoContainer = styled('form')`
  padding: 24px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledUserInfoHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledUserInfoName = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 32px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    line-height: 24px;
  }
`;

export const StyledUserInfoActions = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const StyledSaveButton = styled(IconButton)`
  font-size: 15px;
`;

export const StyledNoCodeText = styled('div')`
  margin-bottom: 16px;
`;

export const StyledResendCode = styled('div')`
  margin-bottom: 16px;
  font-size: 11px;
  line-height: 16px;
`;

export const StyledResendCodeButton = styled(Button)`
  font-size: 11px;
  line-height: 16px;
`;

export const StyledCodeButtons = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledDeleteAccountContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const StyledDeleteAccountButton = styled(Button)`
  font-weight: 600;
`;

export const StyledDeleteAccountIcon = styled(DeleteAccountIcon)`
  color: ${({ theme }) => theme.color.icon.radical};
`;

export const StyledUserInfoNoPermissionText = styled('div')`
  a {
    color: ${({ theme }) => theme.color.typo.action};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.color.typo.hover};
      text-decoration: underline;
    }
  }
`;
