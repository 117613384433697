import { Button, blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledBenefitsProps } from './Benefits.types';

export const StyledBenefits = styled('div', {
  shouldForwardProp: blockNonNativeProps(['ref']),
})<TStyledBenefitsProps>`
  padding: 24px;
  margin-bottom: 24px;
  text-align: center;
  background: #242b2d;
  color: #fff;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 64px 13% 44px;
  }
`;

export const StyledBenefitsHeading = styled('h2')`
  margin: 0 24px 16px;
  font-family: ${({ theme }) => theme.font.header};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;

  strong {
    color: #32b4ff;
    font-weight: 700;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 32px;
    line-height: 48px;
  }
`;

export const StyledBenefitsDesc = styled('p')`
  font-size: 15px;
  font-family: ${({ theme }) => theme.font.main};
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 16px;
  color: #fff;
  opacity: 0.7;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 24%;
    margin-bottom: 32px;
  }
`;

export const StyledBenefitsList = styled('ul')`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: start;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    gap: 24px;
  }
`;

export const StyledBenefitsListItem = styled('li')`
  background-color: #fff;
  color: #49575b;
  border-radius: 12px;
  margin: 0 0 24px;
  padding: 16px 24px;
  flex-basis: 50%;

  > p {
    margin: 0 0 16px;
    font-size: 13px;
    line-height: 24px;
  }

  :last-of-type {
    .BenefitsItemTitle {
      color: #039c47;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
  }
`;

export const StyledBenefitsItemIcon = styled('img')`
  width: 48px;
  height: 48px;
`;

export const StyledBenefitsItemTitle = styled('h3')`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0 0 16px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: ${({ theme }) => theme.font.header};
  color: #3359df;
`;

export const StyledBenefitsItemTerms = styled('div')`
  border-radius: 8px;
  border: 1px solid #eef1f1;
  background: #f9fafa;
  padding: 16px;
  margin-bottom: 24px;

  dl:last-of-type {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 16px 24px;
  }
`;

export const StyledBenefitsItemTermsElement = styled('dl')`
  margin: 0 0 8px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  dt {
    margin: 0;
    padding: 0;
  }
  dd {
    margin: 0;
    padding: 0;
    color: #3359df;
    font-weight: bold;
  }
`;

export const StyledBenefitsItemTermsRevshare = styled('p')`
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;

  strong {
    color: #039c47;
    font-weight: 700;
  }

  .BenefitsItemTermsRevsharePicture {
    display: none;
    filter: drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.04))
      drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.04))
      drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04))
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .BenefitsItemTermsRevsharePicture {
      display: block;
    }
  }
`;

export const StyledBenefitsItemTermsRevsharePicture = styled('img')`
  width: 80px;
  height: 80px;
`;

export const StyledBenefitsItemTermsButton = styled(Button)`
  margin-top: auto;
`;

export const StyledBenefitsFooter = styled('div')`
  p {
    color: #99a9ad;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 16px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
`;
export const StyledBenefitsFooterLogo = styled('img')`
  display: block;
  margin: 0 auto 16px;
  width: 80px;
  height: 16px;
`;
