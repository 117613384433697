import { ApiRequest } from 'helpers/apiRequest';

import { TOneTimeToken, TTokens } from './tokens.types';

class TokensService extends ApiRequest {
  async fetchTokens(): Promise<TTokens | null> {
    try {
      const { data } = await this.fetch<TTokens>({
        url: '/en/clientsarea/rest/tokens/',
      });
      return data;
    } catch (error) {
      console.error('fetch tokens error', error);
      return null;
    }
  }

  async refreshToken(): Promise<TTokens | null> {
    try {
      const { data } = await this.fetch<TTokens>({
        url: '/en/clientsarea/rest/refresh-token/',
      });
      return data;
    } catch (error) {
      console.error('refresh-token error', error);
      return null;
    }
  }

  async getOneTimeToken(): Promise<TOneTimeToken | null> {
    try {
      const { data } = await this.fetch<TOneTimeToken>({
        url: '/api/qr_one_time_token/',
      });
      return data;
    } catch (error) {
      console.error('one-time-token error', error);
      return null;
    }
  }
}

export const tokensService = new TokensService();
