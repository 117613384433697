import { blockNonNativeProps, Select } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledActionButtonProps } from './RequestReport.types';

export const StyledRequestReportWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledRequestReportHeading = styled('h3')`
  margin: 0;

  color: ${({ theme }) => theme?.color?.typo?.primary};
  font-family: ${({ theme }) => theme?.font?.header};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledSelectionPanel = styled('div')`
  width: 100%;
  display: flex;
  grid-gap: 16px;
`;

export const StyledAccountSelect = styled(Select)`
  width: 160px;
  z-index: 1000;
`;

export const StyledSelectWrapper = styled('div')`
  width: 100%;
`;

export const StyledRequestReportText = styled('p')`
  margin: 0;

  color: ${({ theme }) => theme?.color?.typo?.promo};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledRequestReportFooter = styled('div')`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const StyledActionButton = styled('button', {
  shouldForwardProp: blockNonNativeProps(['color']),
})<TStyledActionButtonProps>`
  padding: 0;

  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  background: none;
  color: ${({ theme, color }) => theme?.color?.typo?.[color || 'primary']};
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  // for loader
  display: flex;
  align-items: center;
  gap: 4px;
`;
