import { css } from 'react-ui-kit-exante';

import { TWithdrawalHistoryEntry } from 'services/withdrawal';
import { styled } from 'theme';

export const StyledWithdrawalStatus = styled('div')<{
  status: TWithdrawalHistoryEntry['status'];
}>`
  ${({ theme, status }) => {
    const colorMap: Record<TWithdrawalHistoryEntry['status'], string> = {
      // todo add 2196F3 to ui-kit
      active: '#2196F3',
      'on-hold': theme.color.typo.warning,
      executed: theme.color.icon.source,
      rejected: theme.color.icon.radical,
      old: theme.color.icon.source,
    } as const;

    const color = colorMap[status];

    return css`
      color: ${color};
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        color: ${color};
      }
    `;
  }};
`;
