import { styled } from 'theme';

export const StyledHeaderWithTooltipContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const StyledAccountSettingsText = styled('div')`
  white-space: break-spaces;
`;
