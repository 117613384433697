export const CARD_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'pending-activation',
  BLOCKED: 'blocked',
  REQUESTED: 'requested',
  DELETED: 'deleted',
} as const;

export const CARD_TYPE = {
  PREMIUM: 'Premium',
  STANDARD: 'Standard',
} as const;
