import { AxiosResponse } from 'axios';
import { TFunction } from 'i18next';

import { ValidationError } from './types';

export const extractValidationError = (
  t: TFunction,
  error?: AxiosResponse<ValidationError>,
): string | undefined => {
  if (error?.data) {
    return `${error.data?.map((key) => `${t(key)}`).join('. ')}`;
  }

  return undefined;
};
