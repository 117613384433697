import { FC } from 'react';

import {
  StyledAccordionSkeletonBig,
  StyledAccordionSkeletonMedium,
  StyledAccordionSkeletonRow,
  StyledAccordionSkeletonRowPart,
  StyledAccordionSkeletonSmall,
} from 'components/AccordionSkeleton/AccordionSkeleton.styled';

export const AccordionSkeleton: FC = () => (
  <div>
    {[...Array(5)].map((_, i) => (
      <StyledAccordionSkeletonRow key={i}>
        <StyledAccordionSkeletonRowPart>
          <StyledAccordionSkeletonSmall variant="rounded" animation="wave" />
          <StyledAccordionSkeletonSmall variant="rounded" animation="wave" />
          <StyledAccordionSkeletonBig variant="rounded" animation="wave" />
        </StyledAccordionSkeletonRowPart>
        <StyledAccordionSkeletonRowPart>
          <StyledAccordionSkeletonBig variant="rounded" animation="wave" />
          <StyledAccordionSkeletonMedium variant="rounded" animation="wave" />
        </StyledAccordionSkeletonRowPart>
      </StyledAccordionSkeletonRow>
    ))}
  </div>
);
