import { useContext, useState } from 'react';
import { Button, Checkbox, IconButton, Tab } from 'react-ui-kit-exante';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';
import { Dates } from 'helpers/dates';

import {
  StyledApplicationActions,
  StyledApplicationContainer,
  StyledKeysContainer,
  StyledKeyItem,
  StyledKeyPermissionsContainer,
  StyledKeyScopes,
  StyledFooter,
  StyledDeleteContainer,
  StyledDeleteContainerFooter,
  StyledTabs,
} from './Application.styled';
import { ApplicationSkeleton, Header } from './components';
import { useApplication } from './hooks/useApplication';

export const Application = () => {
  const { t } = useContext(TranslationContext);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const {
    decoded,
    encoded,
    handleClose,
    handleDelete,
    handleScopesChange,
    isDeleting,
    isLoading,
    isSaving,
    result,
    scopeItemChecked,
    setTab,
    tab,
  } = useApplication();

  const createdAt = Dates.format(new Date(result?.created || 0), true);

  if (isLoading) {
    return <ApplicationSkeleton />;
  }

  return (
    <StyledApplicationContainer
      title={
        <Header name={result?.description} isDemo={result?.environment === 1} />
      }
      action={
        <StyledApplicationActions className="ApplicationActions">
          <ActionWithConfirmation
            hideDefaultButtons
            openFromExternal
            externalOpened={isDeleteOpen}
            withCloseAfterConfirmation
            closeHandler={() => setIsDeleteOpen(false)}
            placement="bottom"
            content={
              <StyledDeleteContainer className="DeleteContainer">
                <Text size="20px">
                  {t('layout__settings__api__delete_key')}
                </Text>
                <Text size="13px" color="secondary">
                  {t('layout__settings__api__delete_confirmation')}
                </Text>
                <StyledDeleteContainerFooter className="DeleteContainerFooter">
                  <Button
                    onClick={handleDelete}
                    loading={isDeleting}
                    color="transparent"
                    textColor="action"
                  >
                    {t('generic__action__confirm')}
                  </Button>
                  <Button
                    onClick={() => setIsDeleteOpen(false)}
                    color="transparent"
                  >
                    {t('generic__action__cancel')}
                  </Button>
                </StyledDeleteContainerFooter>
              </StyledDeleteContainer>
            }
          >
            <IconButton
              iconName="DeleteIcon"
              onClick={() => setIsDeleteOpen(true)}
            />
          </ActionWithConfirmation>
          <IconButton iconName="CloseIcon" onClick={handleClose} />
        </StyledApplicationActions>
      }
    >
      <StyledTabs value={tab} onChange={(_, value) => setTab(value)}>
        <Tab value={0} label={t('layout__settings__api__tab_basic_auth')} />
        <Tab value={1} label={t('layout__settings__api__tab_json')} />
      </StyledTabs>
      <StyledKeysContainer className="KeysContainer">
        <StyledKeyItem className="KeyItem">
          <Text withCopyButton weight="500">
            {result?.id}
          </Text>
          <Text size="13px" color="secondary">
            {t('layout__settings__api__api_key')}
          </Text>
        </StyledKeyItem>

        {tab === 1 && (
          <>
            <StyledKeyItem className="KeyItem">
              <Text withCopyButton weight="500">
                {result?.key}
              </Text>
              <Text size="13px" color="secondary">
                {t('layout__dashboard__shared_key')}
              </Text>
            </StyledKeyItem>

            <StyledKeyItem className="KeyItem">
              <Text
                withCopyButton
                weight="500"
                copyButtonProps={{ text: encoded }}
              >
                {t('layout__settings__api__encoded')}
              </Text>
              <Text size="13px" color="secondary">
                {t('layout__settings__api__token')}
              </Text>
            </StyledKeyItem>

            <StyledKeyItem className="KeyItem">
              <Text
                withCopyButton
                weight="500"
                copyButtonProps={{ text: decoded }}
              >
                {t('layout__settings__api__decoded')}
              </Text>
              <Text size="13px" color="secondary">
                {t('layout__settings__api__token')}
              </Text>
            </StyledKeyItem>
          </>
        )}
        {tab === 0 && (
          <StyledKeyItem className="KeyItem">
            <Text withCopyButton weight="500">
              {result?.secret}
            </Text>
            <Text size="13px" color="secondary">
              {t('layout__settings__api__secret_key')}
            </Text>
          </StyledKeyItem>
        )}
      </StyledKeysContainer>
      <StyledKeyPermissionsContainer className="KeyPermissionsContainer">
        <Text size="24px">{t('layout__settings__api__key_permissions')}</Text>
        <StyledKeyScopes className="KeyScopes">
          <Checkbox
            disabled={isSaving}
            label="Symbols"
            value="symbols"
            checked={scopeItemChecked('symbols')}
            onChange={() => handleScopesChange('symbols')}
          />
          <Checkbox
            disabled={isSaving}
            label="Feed"
            value="feed"
            checked={scopeItemChecked('feed')}
            onChange={() => handleScopesChange('feed')}
          />
          <Checkbox
            disabled={isSaving}
            label="Change"
            value="change"
            checked={scopeItemChecked('change')}
            onChange={() => handleScopesChange('change')}
          />
          <Checkbox
            disabled={isSaving}
            label="Orders"
            value="orders"
            checked={scopeItemChecked('orders')}
            onChange={() => handleScopesChange('orders')}
          />
          <Checkbox
            disabled={isSaving}
            label="OHLC"
            value="ohlc"
            checked={scopeItemChecked('ohlc')}
            onChange={() => handleScopesChange('ohlc')}
          />
          <Checkbox
            disabled={isSaving}
            label="Crossrates"
            value="crossrates"
            checked={scopeItemChecked('crossrates')}
            onChange={() => handleScopesChange('crossrates')}
          />
          <Checkbox
            disabled={isSaving}
            label="Summary"
            value="summary"
            checked={scopeItemChecked('summary')}
            onChange={() => handleScopesChange('summary')}
          />
          <Checkbox
            disabled={isSaving}
            label="Accounts"
            value="accounts"
            checked={scopeItemChecked('accounts')}
            onChange={() => handleScopesChange('accounts')}
          />
          <Checkbox
            disabled={isSaving}
            label="Transactions"
            value="transactions"
            checked={scopeItemChecked('transactions')}
            onChange={() => handleScopesChange('transactions')}
          />
        </StyledKeyScopes>
      </StyledKeyPermissionsContainer>
      <StyledFooter className="Footer">
        <Text color="secondary" size="13px">
          {t('layout__value__created', { TIME: createdAt })}
        </Text>
        {tab === 1 && (
          <Text color="secondary" size="13px">
            {t('layout__settings__api__application_footer', {
              TIME: createdAt,
            })}
          </Text>
        )}
      </StyledFooter>
    </StyledApplicationContainer>
  );
};
