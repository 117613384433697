import {
  defaultLocaleFormatter,
  formatCurrency,
  formatPercentage,
} from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';

import { PositiveNegative } from '../PositiveNegative';

import { StyledCashSum } from './CashSum.styled';
import { TCashSumProps } from './CashSum.types';

export const CashSum = ({
  values,
  colored = false,
  currency,
  isPercentageSum = false,
  hideForOneValue = false,
}: TCashSumProps) => {
  const formatter = useCurrencyFormatter(currency);

  if (values.length <= 1 && hideForOneValue) {
    return null;
  }

  const valuesSum = values.reduce<number>((acc, curr) => {
    const numberedValue = Number(curr);

    if (Number.isNaN(numberedValue)) {
      return acc;
    }

    return numberedValue + acc;
  }, 0);

  const formatted = isPercentageSum
    ? formatPercentage(valuesSum)
    : formatCurrency(formatter || defaultLocaleFormatter, valuesSum);

  return (
    <StyledCashSum className="CashSum">
      {colored ? (
        <PositiveNegative value={valuesSum} valueFormatter={() => formatted} />
      ) : (
        formatted
      )}
    </StyledCashSum>
  );
};
