import { styled } from 'theme';

export const StyledTabSelector = styled('div')`
  padding: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  & > div {
    ${({ theme }) => theme.breakpoints.down('lg')} {
      flex-direction: column;
      gap: 16px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;

export const StyledTabsSkeletonLoader = styled('div')`
  flex: 1;

  display: flex;
  gap: 16px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const StyledTabSkeletonWrapper = styled('div')`
  width: 180px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const StyledNoteContainer = styled('div')`
  width: max-content;
  display: flex;

  * {
    flex-shrink: 0;
  }

  color: ${({ theme }) => {
    return theme.color.typo.secondary;
  }};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`;

export const StyledTooltipDescription = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
