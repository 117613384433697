import { useContext } from 'react';
import { Autocomplete, Checkbox } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import {
  StyledSettingsItem,
  StyledSettingsItemCell,
  StyledSettingsItemCheckboxCell,
  StyledSettingsItemCheckboxLabel,
  StyledSettingsItemCheckboxWrapper,
  StyledSettingsItemEmailsCell,
  StyledSettingsItemTitle,
} from './SettingsItem.styled';
import { TSettingsItemProps } from './SettingsItem.types';
import { useRenderTags } from './useRenderTags';
import { useSettingsItem } from './useSettingsItem';
import { getValidationMessageKey } from './useSettingsItem/useSettingsItem.helpers';

export const SettingsItem = (props: TSettingsItemProps) => {
  const { t } = useContext(TranslationContext);

  const { disabled, title, options, primaryEmail } = props;
  const renderTags = useRenderTags(primaryEmail, disabled);

  const {
    allowedSet,
    sms,
    emails,
    error,
    internalCA,
    terminal,
    handleSmsChange,
    handleResetWarnings,
    handleEmailsSubmit,
    handleTerminalChange,
    handleInternalCAChange,
  } = useSettingsItem(props);

  const isSmsDisabled = disabled || !allowedSet.has('sms');
  const isInternalCADisabled = disabled || !allowedSet.has('internalCA');
  const isTerminalDisabled = disabled || !allowedSet.has('terminal');

  return (
    <StyledSettingsItem className="SettingsItem">
      <StyledSettingsItemCell className="SettingsItemCell">
        <StyledSettingsItemTitle className="SettingsItemTitle">
          {title}
        </StyledSettingsItemTitle>
      </StyledSettingsItemCell>

      <StyledSettingsItemCheckboxCell className="SettingsItemCheckboxCell">
        <StyledSettingsItemCheckboxWrapper
          className="SettingsItemCheckboxWrapper"
          type="button"
          onClick={handleSmsChange}
          disabled={isSmsDisabled}
        >
          <Checkbox
            id="settingsItemSmsCheckbox"
            disabled={isSmsDisabled}
            checked={sms}
          />
          <StyledSettingsItemCheckboxLabel
            className="SettingsItemCheckboxLabel"
            htmlFor="settingsItemSmsCheckbox"
          >
            {t('generic__phone')}
          </StyledSettingsItemCheckboxLabel>
        </StyledSettingsItemCheckboxWrapper>
      </StyledSettingsItemCheckboxCell>

      <StyledSettingsItemCheckboxCell className="SettingsItemCheckboxCell">
        <StyledSettingsItemCheckboxWrapper
          className="SettingsItemCheckboxWrapper"
          type="button"
          disabled={isInternalCADisabled}
          onClick={handleInternalCAChange}
        >
          <Checkbox
            id="settingsItemInternalCACheckbox"
            checked={internalCA}
            disabled={isInternalCADisabled}
          />
          <StyledSettingsItemCheckboxLabel
            className="SettingsItemCheckboxLabel"
            htmlFor="settingsItemInternalCACheckbox"
          >
            {t('layout__products__clients_area')}
          </StyledSettingsItemCheckboxLabel>
        </StyledSettingsItemCheckboxWrapper>
      </StyledSettingsItemCheckboxCell>

      <StyledSettingsItemCheckboxCell className="SettingsItemCheckboxCell">
        <StyledSettingsItemCheckboxWrapper
          className="SettingsItemCheckboxWrapper"
          type="button"
          disabled={isTerminalDisabled}
          onClick={handleTerminalChange}
        >
          <Checkbox
            id="settingsItemTerminalCheckbox"
            checked={terminal}
            disabled={isTerminalDisabled}
          />
          <StyledSettingsItemCheckboxLabel
            className="SettingsItemCheckboxLabel"
            htmlFor="settingsItemTerminalCheckbox"
          >
            {t('layout__download__trading_terminal')}
          </StyledSettingsItemCheckboxLabel>
        </StyledSettingsItemCheckboxWrapper>
      </StyledSettingsItemCheckboxCell>

      <StyledSettingsItemEmailsCell className="SettingsItemEmailsCell">
        <Autocomplete
          isMultiple
          freeSolo
          fullWidth
          floating={false}
          placeholder={t('generic__email')}
          disabled={!allowedSet.has('email')}
          onChange={handleEmailsSubmit}
          onInput={handleResetWarnings}
          onFocus={handleResetWarnings}
          onBlur={handleResetWarnings}
          options={options}
          value={emails}
          warning={error === 'require'}
          renderTags={renderTags}
          controlProps={{
            message: t(getValidationMessageKey(error)),
            error: error === 'validation',
          }}
        />
      </StyledSettingsItemEmailsCell>
    </StyledSettingsItem>
  );
};
