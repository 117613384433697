import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { StyledGroupFilter, StyledGroupFilters } from './FiltersGroup.styled';
import { TFiltersGroupProps } from './FiltersGroup.types';

export const FiltersGroup: FC<TFiltersGroupProps> = ({
  filters,
  values,
  onChange,
  showSkeleton,
  skeletonCount = 10,
}) => {
  const onSelectFilter = (filter: string) => {
    if (filter === 'all') {
      onChange([filter]);
      return;
    }

    if (values.includes(filter)) {
      onChange(values.filter((val) => val !== filter && val !== 'all'));
    } else {
      onChange([...values.filter((val) => val !== 'all'), filter]);
    }
  };

  if (showSkeleton) {
    const skeletonLength = filters.length || skeletonCount;
    return (
      <StyledGroupFilters className="TypeFilters">
        {[...Array(skeletonLength)].map((item) => {
          return <Skeleton key={item} width={66} height={32} />;
        })}
      </StyledGroupFilters>
    );
  }

  return (
    <StyledGroupFilters
      className="TypeFilters"
      data-test-id="filters-group__filters-container"
    >
      {filters.map(({ value, name }) => {
        return (
          <StyledGroupFilter
            className="TypeFilter"
            key={value}
            onClick={() => onSelectFilter(value)}
            isSelected={values.includes(value)}
            data-test-id={`filters-group__filter-item-${value}`}
          >
            {name}
          </StyledGroupFilter>
        );
      })}
    </StyledGroupFilters>
  );
};
