import { ClickAwayListener } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { DialogTooltip } from 'components/DialogTooltip';
import { TranslationContext } from 'contexts';
import { DepositCryptoContext } from 'pages/Portfolio/tabs/Deposit/contexts';

export const RenewAddressButton: FC = () => {
  const { isLoading, isLoadingAddress, renewAddress } =
    useContext(DepositCryptoContext);

  const { t } = useContext(TranslationContext);

  const [open, setOpen] = useState(false);

  const handleTooltipAccept = () => {
    setOpen(false);
    renewAddress();
  };

  const handleTooltipDecline = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipDecline}>
      {/* span is required to handle away clicks properly */}
      <span>
        <DialogTooltip
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{
            disablePortal: true,
          }}
          onAccept={handleTooltipAccept}
          onDecline={handleTooltipDecline}
        >
          <IconButton
            iconName="ArrowsClockwiseIcon"
            iconColor="secondary"
            iconSize={24}
            label={t('generic__wallet__generate')}
            disabled={isLoading || isLoadingAddress}
            onClick={handleTooltipOpen}
            data-test-id="crypto_address__button__renew"
          />
        </DialogTooltip>
      </span>
    </ClickAwayListener>
  );
};
