import { FC, useContext } from 'react';
import { ITooltipProps, Tooltip as TooltipRUI } from 'react-ui-kit-exante';

import { BrandingContext } from 'contexts/BrandingContext';

import { StyledTooltipIcon } from './TooltipIcon.styled';

type TTooltip = {
  forceCustomIcon?: boolean;
};

/**
 * Puts an icon from the branding, if available, to the Tooltip from RUI
 *
 * If context is not available or icon is empty,
 * uses the icon passed as a children
 */
export const Tooltip: FC<ITooltipProps & TTooltip> = ({
  children,
  forceCustomIcon = false,
  ...rest
}) => {
  const { branding } = useContext(BrandingContext);
  const tooltipIconUrl = branding?.branding?.ui_settings?.icon_tooltip_info;

  return (
    <TooltipRUI {...rest}>
      {tooltipIconUrl && !forceCustomIcon ? (
        <StyledTooltipIcon className="TooltipIcon" iconUrl={tooltipIconUrl} />
      ) : (
        children
      )}
    </TooltipRUI>
  );
};
