import { SearchIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledHelpFAQWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: 24px;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  width: 24px;
  height: 24px;

  color: ${({ theme }) => theme.color.icon.secondary};
`;
