import { styled } from 'theme';

export const StyledAccountSelectionTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledAccountSelectionTitleText = styled('span')`
  margin-right: 8px;
`;
