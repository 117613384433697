import { Panel } from 'react-ui-kit-exante';

import { FormInputContainer } from 'components/Form/FormInputContainer/FormInputContainer';
import { styled } from 'theme';
import { TPasswordLevel } from 'types/password';

export const StyledPanel = styled(Panel)`
  padding: 24px;
`;

export const StyledHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 24px;
`;

export const StyledFormRow = styled('div')`
  display: flex;
  gap: 24px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    flex-direction: column;
  }
`;

export const StyledFormInputContainer = styled(FormInputContainer)`
  width: 312px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
  }
`;

export const StyledSecurity = styled('div')`
  margin-top: 16px;
  display: flex;
  gap: 8px;
`;

export const StyledSecurityLevel = styled('div')<{ level: TPasswordLevel }>`
  color: ${({ theme, level }) => {
    switch (level) {
      case 'weak':
        return theme.color.notifications.error;
      case 'medium':
        return theme.color.notifications.warning;
      case 'strong':
        return theme.color.notifications.success;
      default:
        return 'initial';
    }
  }};
`;
