import { TBrandingResponse } from 'services/branding';
import { TWithdrawalType } from 'services/withdrawal';

export type TStateFields = {
  branding: TBrandingResponse | null;
};
export const getDefaultFields = (
  type: TWithdrawalType,
  state: TStateFields,
) => {
  switch (type) {
    case 'Security':
      return [
        'type',
        'account',
        'type_of_transaction',
        'positions',
        'fi_name',
        'contact_person',
        'fi_account_number',
        'account_holder_name',
        'transfer_reference',
        'ssi',
        'transfer_details',
        'files',
        'authorize_confirm',
        ...(!state?.branding?.branding?.show_security_comment
          ? []
          : ['comment']),
      ];

    case 'Wire':
    case 'Wire third-party':
      return [
        'name',
        'iban',
        'swift',
        'beneficiary_country',
        'city',
        'state',
        'short_address',
        'postal_code',
        'comment',
        'correspondent_account',
        'bank_name',
        'bank_country',
        'bank_address',
        'correspondent_swift',
      ];

    default:
      return [];
  }
};

export const FIELDS_BENEFICIARY_ADDRESS = [
  'beneficiary_country',
  'city',
  'state',
  'short_address',
  'postal_code',
] as const;
