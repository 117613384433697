import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TChangePasswordRequest,
  TChangePasswordResponse,
} from './password.types';

class PasswordService extends ApiRequest {
  async changePassword(
    formData: TChangePasswordRequest,
    lang = 'en',
  ): Promise<TChangePasswordResponse | null> {
    try {
      const { data } = await this.fetch<TChangePasswordResponse>({
        url: `/${lang}/clientsarea/rest/set-password/`,
        method: 'POST',
        data: formData,
      });

      return data;
    } catch (error: any) {
      if (error.response?.status === 400) {
        return {
          ...error.response?.data,
          error:
            !error.response?.data?.old_password &&
            !error.response?.data?.new_password,
        };
      }
      notifyWith.genericNetworkError(error);
      console.error('changePassword', error);

      return null;
    }
  }

  async resendCode(
    key: string,
    tokenid: number,
    lang = 'en',
  ): Promise<boolean> {
    try {
      await this.fetch<TChangePasswordResponse>({
        url: `/${lang}/clientsarea/rest/mfa/resend/`,
        method: 'POST',
        data: { key, tokenid },
      });
      return true;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('changePassword', error);
      return false;
    }
  }
}

export const passwordService = new PasswordService();
