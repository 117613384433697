import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledChartLegendItemSkeleton,
  StyledChartLegendSkeleton,
} from './Chart.styled';

export const ChartSkeleton: FC = () => {
  return (
    <>
      <StyledChartLegendSkeleton>
        {[...Array(2)].map((_, i) => (
          <StyledChartLegendItemSkeleton key={i}>
            <Skeleton
              animation="wave"
              width={24}
              height={8}
              variant="rounded"
            />
            <Skeleton
              animation="wave"
              width={40}
              height={16}
              variant="rounded"
            />
          </StyledChartLegendItemSkeleton>
        ))}
      </StyledChartLegendSkeleton>
      <Skeleton animation="wave" width="100%" height={352} variant="rounded" />
    </>
  );
};
