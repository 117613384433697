export const MFA_INPUT_TITLES = {
  sms: 'generic__mfa__sms_code',
  email: 'generic__mfa__email_code',
  totp: 'generic__mfa__totp_code',
} as const;

export const MFA_DESCRIPTIONS = {
  sms: 'enter_code_from_sms',
  email: 'enter_code_from_email',
  totp: 'enter_code_from_app',
} as const;
