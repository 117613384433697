import { PositiveNegative } from 'components/Cells';
import {
  formatCurrency,
  formatPercentage,
  TCreateCurrencyFormatter,
} from 'helpers/formatters';
import { AvailableLabel } from 'pages/Portfolio/components/AccountSummary/components/SummaryBlock/components/AvailableLabel';
import {
  TAccountSummary,
  TAccountSummaryMetrics,
  TNumberedMetrics,
} from 'types/accounts';
import { IMarginData } from 'types/margin';

import { AvailableValue } from './components/SummaryBlock/components/AvailableValue';

type TGetAccountValuesArguments = {
  currencyFormatter: TCreateCurrencyFormatter;
  metrics: TNumberedMetrics;
  labels: Record<'accountValue' | 'dailyPnl' | 'totalDailyPnl', string>;
};

export function getAccountValues({
  currencyFormatter,
  metrics: { percentageTotalDailyPnl, convertedDailyPnl, netAssetValue },
  labels,
}: TGetAccountValuesArguments) {
  const result = [];

  result.push({
    label: labels.accountValue,
    value: formatCurrency(currencyFormatter, netAssetValue),
  });

  if (convertedDailyPnl !== null) {
    result.push({
      label: labels.dailyPnl,
      value: (
        <PositiveNegative
          value={convertedDailyPnl}
          valueFormatter={(value) => formatCurrency(currencyFormatter, value)}
        />
      ),
    });
  }

  if (percentageTotalDailyPnl !== null) {
    result.push({
      label: labels.totalDailyPnl,
      value: (
        <PositiveNegative
          value={percentageTotalDailyPnl}
          valueFormatter={(value) => formatPercentage(value)}
        />
      ),
    });
  }

  return result;
}

interface IGetAvailableValuesArguments {
  currencyFormatter: TCreateCurrencyFormatter;
  metrics: TNumberedMetrics;
  marginData: IMarginData;
  labels: Record<
    'usedMargin' | 'positionMargin' | 'totalOrderMargin' | 'totalBlockedFunds',
    string
  >;
}

export function getAvailableValues({
  metrics: {
    marginUtilization,
    totalBlockedMargin,
    totalOrderMargin,
    requiredMargin,
  },
  currencyFormatter,
  marginData: { available, positionMargin, isMarginCall },
  labels,
}: IGetAvailableValuesArguments) {
  const result = [];

  if (available !== null) {
    result.push({
      label: <AvailableLabel isMarginCall={isMarginCall} />,
      value: (
        <AvailableValue isMarginCall={isMarginCall}>
          {formatCurrency(currencyFormatter, available)}
        </AvailableValue>
      ),
    });
  }

  if (requiredMargin !== null) {
    result.push({
      label: labels.usedMargin,
      value: `${formatPercentage(
        Number(marginUtilization) * 100,
      )} / ${formatCurrency(currencyFormatter, requiredMargin)}`,
    });
  }

  if (positionMargin !== null) {
    result.push({
      label: labels.positionMargin,
      value: formatCurrency(currencyFormatter, positionMargin),
    });
  }

  if (totalOrderMargin !== null) {
    result.push({
      label: labels.totalOrderMargin,
      value: formatCurrency(currencyFormatter, totalOrderMargin),
    });
  }

  if (totalBlockedMargin !== null) {
    result.push({
      label: labels.totalBlockedFunds,
      value: formatCurrency(currencyFormatter, totalBlockedMargin),
    });
  }

  return result;
}

export function getAccountMetrics(
  account: TAccountSummary,
): TAccountSummaryMetrics {
  return {
    convertedDailyPnl: account.convertedDailyPnl,
    freeMoney: account.freeMoney,
    cashFreeMoney: account.cashFreeMoney,
    marginUtilization: account.marginUtilization,
    percentageTotalDailyPnl: account.percentageTotalDailyPnl,
    requiredMargin: account.requiredMargin,
    totalBlockedMargin: account.totalBlockedMargin,
    totalCash: account.totalCash,
    totalOrderMargin: account.totalOrderMargin,
    totalFreeMoney: account.totalFreeMoney,
    netAssetValue: account.netAssetValue,
    accountId: account.accountId,
    label: account.label,
    accountPurpose: account.accountPurpose,
    cardNumber: account.cardNumber,
    cardStatus: account.cardStatus,
  };
}

export function getMetricNumbers(
  metrics: TAccountSummaryMetrics,
): TNumberedMetrics {
  return Object.keys(metrics).reduce<TNumberedMetrics>((acc, curr) => {
    const value = metrics[curr as keyof TAccountSummaryMetrics];

    return {
      ...acc,
      [curr]: value !== null ? Number(value) : value,
    };
  }, {} as TNumberedMetrics);
}
