import { isPlainObject } from 'lodash';
import { createContext, FC, useMemo, useState } from 'react';

import { TChildren } from 'types/TChildren';

import {
  TabsCacheApiContextInitialState,
  TabsCacheDataContextInitialState,
} from './TabsCacheContext.constants';
import {
  TTabsCacheApiContextValue,
  TTabsCacheDataContextValue,
} from './TabsCacheContext.types';

export const TabsCacheDataContext = createContext<TTabsCacheDataContextValue>(
  TabsCacheDataContextInitialState,
);

export const TabsCacheApiContext = createContext<TTabsCacheApiContextValue>(
  TabsCacheApiContextInitialState,
);

export const TabsCacheProvider: FC<TChildren> = ({ children }) => {
  const [storage, setStorage] = useState<Record<string, any>>({});

  const providedData = useMemo<TTabsCacheDataContextValue>(
    (): TTabsCacheDataContextValue => ({
      getCache: (id) => storage[id],
    }),
    [storage],
  );

  const providedApi = useMemo<TTabsCacheApiContextValue>(
    () => ({
      setCache: (id, newValue) => {
        setStorage((prevStorage) => {
          const prevVal = prevStorage[id];
          let newVal: any;

          if (isPlainObject(prevVal) && isPlainObject(newValue)) {
            newVal = {
              ...prevVal,
              ...newValue,
            };
          } else {
            newVal = newValue;
          }

          return {
            ...prevStorage,
            [id]: newVal,
          };
        });
      },
    }),
    [],
  );

  return (
    <TabsCacheDataContext.Provider value={providedData}>
      <TabsCacheApiContext.Provider value={providedApi}>
        {children}
      </TabsCacheApiContext.Provider>
    </TabsCacheDataContext.Provider>
  );
};
