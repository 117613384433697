import { TAssetManagementFeeSetup } from 'services/assetManagement';
import { TMfaTypes } from 'types/mfa';

export type TDocument = {
  is_file: boolean;
  is_signed: boolean;
  title: string;
  client_type?: unknown;
  data_url?: string;
  id: number;
  legal_entity?: string;
  open_url?: string;
  preview_data_url?: string;
  order?: number;
  type?: number;
  url?: string;
};

export type TLegalEntityDocument = {
  id: number;
  url: string;
  doc: TDocument;
  application: number;
  is_filled_doc: boolean;
  sign_date: string;
  order: number;
  client: unknown;
  confirm_type: unknown;
  sign_ip: unknown;
  preview_data_url?: string;
  sign_unavailable?: boolean;
};

export enum AssetManagementDocumentStatus {
  DocUploaded = 'doc_uploaded',
  OpenLink = 'open_link',
}

export type TAssetManagementDocument = {
  id: number;
  status: AssetManagementDocumentStatus;
  fee_setup: TAssetManagementFeeSetup;
  second_side_doc_url: string;
  client: number;
  client_name: string;
  asset_manager: string;
  created: string;
  updated: string;
};

export type TLegalEntityAdapter = {
  asset_management_docs: TAssetManagementDocument[];
  filtered_docs: TLegalEntityDocument[];
  legal_signed_by_exante: TLegalEntityDocument[];
  legal_signed_by_user: TLegalEntityDocument[];
  other_docs: TLegalEntityDocument[];
  to_sign: TLegalEntityDocument[];
  w8_affidavit_form_context: {
    client_id: string;
    client_name: string;
    enable: boolean;
    sign_url: string;
  };
  w8ben_url: string;
};

export type TLegalDocsResponse = {
  'legal-docs'?: {
    docs: TDocument[];
    le_adapters: Record<string, TLegalEntityAdapter>;
    mfa_type: TMfaTypes;
    urls: {
      docSign: string;
      mfa: {
        'email:resend': string;
        'sms:resend': string;
        'token:': string;
        'totp:resend': string;
      };
    };
  };
};

export type TDocumentPdf = {
  id: string;
  is_signed: boolean;
  pdf: string;
  url: string;
};

export type TDocumentPreviewPdf = {
  ready: boolean;
  url: string | null;
};

export type TSignDocResponse = {
  id: number;
  order: number;
  url: string;
  legal_entity: string;
  client_type: string | null;
  type: number;
  title: string;
  is_file: boolean;
  is_signed: boolean;
  open_url: string;
  data_url: string;
  sign_date: string;
};
