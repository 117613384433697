import { FC, Fragment, useCallback, useContext } from 'react';

import { BASE_URL } from 'constants/endpoints';
import { BrandingContext, TranslationContext } from 'contexts';
import { TBrandingAtp } from 'services/branding';

import { StyledLinkItem } from '../Footer.styled';

import { qrParams } from './Links.consts';
import {
  StyledAnchor,
  StyledDownloadLinksListItem,
  StyledDownloadLinksMobile,
  StyledDownloadLinksOthers,
  StyledDownloadLinksQR,
  StyledDownloadLinksQRPic,
  StyledDownloadLinksSoon,
  StyledDownloadLinksTitle,
  StyledFooterDownloadLinks,
} from './Links.styled';

type LinksProps = {
  atp: TBrandingAtp;
  withqr?: boolean;
};

type Titles = 'mac' | 'win' | 'linux' | 'excel';

type Link = {
  active: boolean;
  platform: Titles;
  url: string;
  title: string;
};

export const Links: FC<LinksProps> = ({ atp, withqr = true }) => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const userOs = atp.user_os;

  const titles = {
    mac: t('generic__macos'),
    win: t('generic__windows'),
    linux: t('generic__linux'),
    excel: t('excel_distributives'),
  };

  const links = () => {
    const makeLink = (platform: Titles, active: boolean) => {
      return {
        active,
        platform,
        url: atp.links?.[platform] || '',
        title: titles[platform],
      };
    };

    const platforms = ['mac', 'linux', 'win'] as const;
    const current = platforms.reduce<Link[]>((memo, platform) => {
      if (userOs === platform && atp.links?.[platform]) {
        memo.push(makeLink(platform, true));
      }
      return memo;
    }, []);
    const inactive = platforms.reduce<Link[]>((memo, platform) => {
      if (userOs !== platform && atp.links?.[platform]) {
        memo.push(makeLink(platform, false));
      }
      return memo;
    }, []);
    const other =
      atp.links?.excel && userOs === 'win' ? [makeLink('excel', false)] : [];

    return { activeLinks: current, inactiveLinks: [...inactive, ...other] };
  };

  const { activeLinks, inactiveLinks } = links();
  const showLinksDesktop =
    (activeLinks.length > 0 || inactiveLinks.length > 0) && atp.is_desktop;

  const isIosLinksVisible =
    atp.links?.ios && !(atp.is_mobile && userOs !== 'ios');
  const isAndroidLinksVisible =
    atp.links?.android && !(atp.is_mobile && userOs !== 'android');
  const isSoonLinksVisible = !atp.links?.ios && !atp.links?.android;
  const isIosSoonLinksVisible = !atp.links?.ios && atp.links?.android;

  const showWebPlatform =
    !branding?.branding?.hidden_menu?.includes('webtrade');

  const generateQrUrl = useCallback(
    (content = ''): string => {
      const url = new URL(`${BASE_URL}${atp.qr}`);
      url.searchParams.set('content', content);
      Object.entries(qrParams).forEach(([key, value]) =>
        url.searchParams.set(key, value.toString()),
      );

      return url.toString();
    },
    [atp],
  );

  return (
    <StyledFooterDownloadLinks className="footer__download download-links">
      {branding?.branding?.show_mobile_apps_footer && (
        <>
          <StyledDownloadLinksTitle className="download-links__title">
            {t('generic__links__for_mobile')}
          </StyledDownloadLinksTitle>
          <StyledDownloadLinksMobile className="download-links__mobile">
            {isSoonLinksVisible && (
              <StyledDownloadLinksSoon className="download-links__soon">
                {t('generic__links__soon')}
              </StyledDownloadLinksSoon>
            )}
            {isIosLinksVisible && (
              <StyledDownloadLinksListItem className="download-links__list-item">
                <StyledAnchor
                  href={atp.links?.ios}
                  label=""
                  target="_blank"
                  className="download-links__link download-links__link--ios"
                  dataTestId="downloads__link--download-ios"
                  isIos
                  isDefault
                />
                {withqr && (
                  <StyledDownloadLinksQR className="download-links__qr">
                    <StyledDownloadLinksQRPic
                      className="download-links__qr-pic"
                      src={generateQrUrl(atp.links?.ios || '')}
                      alt={t('layout__components__qr_code_for_download')}
                    />
                  </StyledDownloadLinksQR>
                )}
              </StyledDownloadLinksListItem>
            )}
            {isAndroidLinksVisible && (
              <StyledDownloadLinksListItem className="download-links__list-item">
                <StyledAnchor
                  href={atp.links?.android}
                  label=""
                  className="download-links__link download-links__link--android"
                  dataTestId="downloads__link--download-android"
                  isAndroid
                  isDefault
                />
                {withqr && (
                  <StyledDownloadLinksQR className="download-links__qr">
                    <StyledDownloadLinksQRPic
                      className="download-links__qr-pic"
                      src={generateQrUrl(atp.links?.android)}
                      alt={t('layout__components__qr_code_for_download')}
                    />
                  </StyledDownloadLinksQR>
                )}
              </StyledDownloadLinksListItem>
            )}
            {isIosSoonLinksVisible && (
              <StyledDownloadLinksSoon className="download-links__soon">
                {t('generic__links__ios_soon')}
              </StyledDownloadLinksSoon>
            )}
          </StyledDownloadLinksMobile>
        </>
      )}

      {showLinksDesktop && (
        <>
          <StyledDownloadLinksTitle className="download-links__title">
            {t('generic__links__for_desktop')}
          </StyledDownloadLinksTitle>
          <div className="download-links__desktop">
            {activeLinks?.map((item) => (
              <StyledDownloadLinksListItem
                key={item.title}
                className="DownloadLinksDesktop"
              >
                <StyledAnchor
                  href={item.url}
                  label=""
                  className="DownloadLinksDesktopLink"
                  dataTestId="DownloadLinksDesktopLink"
                  userOs={userOs}
                  isDefault
                />
                {withqr && (
                  <StyledDownloadLinksQR className="DownloadLinksQr">
                    <StyledDownloadLinksQRPic
                      className="DownloadLinksQrPicture"
                      src={generateQrUrl(item.url)}
                      alt={t('layout__components__qr_code_for_download')}
                    />
                  </StyledDownloadLinksQR>
                )}
              </StyledDownloadLinksListItem>
            ))}
            <StyledDownloadLinksOthers className="download-links__others">
              {`${t('layout__links__download_for')} `}
              {inactiveLinks?.map((item, index, all) => (
                <Fragment key={item.url + index}>
                  <StyledAnchor
                    key={item.title}
                    href={item.url}
                    label={item.title}
                    target="_blank"
                    dataTestId={`account-downloads__link--${item.title}`}
                  />
                  {index < all.length - 1 && ` ${t('or')} `}
                </Fragment>
              ))}
              {showWebPlatform && (
                <>
                  {` ${t('or')} ${t('use')} `}
                  <StyledLinkItem
                    className="LinkItem"
                    href={atp.web}
                    data-test-id="account-downloads__link--web"
                  >
                    {t('generic__links__web_platform')}
                  </StyledLinkItem>
                </>
              )}
            </StyledDownloadLinksOthers>
          </div>
        </>
      )}
    </StyledFooterDownloadLinks>
  );
};
