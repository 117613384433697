import {
  isBranchStand,
  isExanteStand,
  isLocalNginxStand,
  isStageReactStand,
} from 'helpers/isBranchStand';

export const isLocalhost = window.location.origin.includes('localhost');

const STAGE_URL = 'https://stage.exante.eu';

export const BASE_URL =
  isBranchStand() || isLocalhost
    ? STAGE_URL
    : `${window.location.protocol}//${window.location.host}`;

export const LOCALIZATION_BASE_URL =
  isLocalNginxStand() || isStageReactStand() || isBranchStand() || isLocalhost
    ? 'https://d31zn0932i565e.cloudfront.net'
    : 'https://d26c5itwi1bs2o.cloudfront.net'; // Production url

// eslint-disable-next-line import/no-mutable-exports
export let NODE_BACK_API_URL = '';
export const IS_DEVELOPER_STAND = isLocalNginxStand() || isLocalhost;

if (
  isLocalNginxStand() ||
  isStageReactStand() ||
  isBranchStand() ||
  isLocalhost
) {
  NODE_BACK_API_URL = 'https://node-back-stage.exante.eu';
} else if (isExanteStand()) {
  NODE_BACK_API_URL = 'https://node-back.exante.eu';
}
