import { Button } from 'react-ui-kit-exante';

import {
  SIGNIN_WRAPPER_WIDTH,
  SIGNIN_WRAPPER_WIDTH_LG,
} from 'pages/Login/pages/Signin/Signin.styled';
import { styled } from 'theme';

// hcaptcha widget original width (px)
const HCAP_W = 303;
// hcaptcha widget original height (px)
const HCAP_H = 78;

export const StyledSigninPassword = styled('form')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  font-family: ${({ theme }) => theme.font.main};
`;

export const StyledSigninActions = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const StyledSigninButton = styled(Button)`
  padding: 0;
  font-size: 15px;

  text-transform: none;
`;

export const StyledSigninCaptchaWrapper = styled('div')`
  display: flex;
  justify-content: center;

  /* scale the widget to fit the wrapper (i.e. the form) */
  transform: scale(${SIGNIN_WRAPPER_WIDTH / HCAP_W});
  /* fix the horizontal margin lack caused by the transform */
  margin: ${((SIGNIN_WRAPPER_WIDTH / HCAP_W) * HCAP_H - HCAP_H) / 2}px 0;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    transform: scale(${SIGNIN_WRAPPER_WIDTH_LG / HCAP_W});
    margin: ${((SIGNIN_WRAPPER_WIDTH_LG / HCAP_W) * HCAP_H - HCAP_H) / 2}px 0;
  }
`;

export const StyledResetPasswordButton = styled(Button)`
  text-transform: initial;
  color: ${({ theme }) => theme.color.typo.action};
  font-weight: 400;
`;
