import { IconButton, Panel, Tab, Tabs } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPartnerTab = styled(Tab)`
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 14px;

  &[aria-selected='true'] .IconButton svg {
    color: ${({ theme }) => theme.color.icon.primary};
  }
`;

export const StyledPartnerTabIcon = styled(IconButton)`
  margin-bottom: 0 !important;

  & svg {
    color: ${({ theme }) => theme.color.icon.action};
  }
`;

export const StyledPartnerPanel = styled(Panel)`
  position: relative;
  min-height: 200px;

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;

  .PanelHeading {
    font-family: ${({ theme }) => theme.font.header};
  }

  .PanelTitle {
    margin-bottom: 0;
    padding: 24px 24px 0 24px;
  }
`;

export const StyledPartnerPanelTabs = styled(Tabs)`
  margin: 0 24px;
` as typeof Tabs;
