import { BASE_URL } from 'constants/endpoints';
import { isCancelledError } from 'helpers/abortController/abortController.helpers';
import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TCashConversionResponse,
  TCashConversionUpdateResponse,
  TDepositCryptoAddressResponse,
  TDepositDocResponse,
  TDepositExportDownloadResponse,
  TDepositExportEmailResponse,
  TDepositHistoryCryptoResponse,
  TDepositResponse,
  TExportDepositDownloadParams,
  TExportDepositEmailParams,
  TGetCryptoAddressParams,
  TGetDepositDocUrlParams,
  TGetDepositParams,
  TGetHistoryCryptoParams,
  TParamsWithOptions,
  TSetCashConversionParams,
} from './deposit.types';

class DepositService extends ApiRequest {
  async getDeposit({ options, accountId }: TGetDepositParams) {
    try {
      const { data } = await this.fetch<TDepositResponse>({
        url: `/en/clientsarea/spa-layout/?name=deposit`,
        params: {
          account: accountId,
        },
        signal: options.signal,
      });

      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }

      notifyWith.genericNetworkError(error);
      console.error('getDeposit error: ', error);
      return null;
    }
  }

  async getCashConversion({ options }: TParamsWithOptions) {
    try {
      const { data } = await this.fetch<TCashConversionResponse>({
        url: `/clientsarea/account/cash-conversion/`,
        signal: options.signal,
      });

      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }

      notifyWith.genericNetworkError(error);
      console.error('getCashConversion error: ', error);
      return null;
    }
  }

  async setCashConversion({
    accountId,
    currency,
    options,
  }: TSetCashConversionParams) {
    try {
      const { data } = await this.fetch<TCashConversionUpdateResponse>({
        url: `/clientsarea/account/cash-conversion/update/${accountId}/`,
        method: 'post',
        data: { currency },
        signal: options.signal,
      });
      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }

      notifyWith.genericNetworkError(error);
      console.error('setCashConversion error: ', error);
      return null;
    }
  }

  async exportDepositEmail({
    accountId,
    currency,
    bank,
    email,
    options,
  }: TExportDepositEmailParams) {
    try {
      const url = new URL(
        `/clientsarea/account/deposit/transfer/export/`,
        BASE_URL,
      );
      url.searchParams.set('account', accountId);
      url.searchParams.set('currency', currency);
      url.searchParams.set('bank', bank);

      if (email) {
        url.searchParams.set('to', email);
      }

      const { data } = await this.fetch<TDepositExportEmailResponse>({
        url: url.toString(),
        signal: options.signal,
      });

      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }

      notifyWith.genericNetworkError(error);
      console.error('exportDepositEmail error: ', error);
      return null;
    }
  }

  async exportDepositDownload({
    accountId,
    currency,
    bank,
    options,
  }: TExportDepositDownloadParams) {
    try {
      const url = new URL(
        `/clientsarea/account/deposit/transfer/export/`,
        BASE_URL,
      );
      url.searchParams.set('account', accountId);
      url.searchParams.set('currency', currency);
      url.searchParams.set('bank', bank);
      url.searchParams.set('json', 'true');

      const { data } = await this.fetch<TDepositExportDownloadResponse>({
        url: url.toString(),
        signal: options.signal,
      });

      if (!data.url) {
        throw new Error('Document is not generated');
      }

      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }

      notifyWith.genericNetworkError(error);
      console.error('exportDepositDownload error: ', error);
      return null;
    }
  }

  async getDepositDocUrl({ url, options }: TGetDepositDocUrlParams) {
    try {
      const { data } = await this.fetch<TDepositDocResponse>({
        url: `${url}?inline=true`,
        signal: options.signal,
      });

      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }

      notifyWith.genericNetworkError(error);
      console.error('getDepositDocUrl error: ', error);
      return null;
    }
  }

  async getCryptoAddress({
    accountId,
    currency,
    renew = false,
    options,
  }: TGetCryptoAddressParams) {
    try {
      const params = renew ? '?renew=true' : '';

      const { data } = await this.fetch<TDepositCryptoAddressResponse>({
        url: `/en/clientsarea/crypto/address/${currency}/${accountId}/${params}`,
        signal: options.signal,
      });

      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }

      notifyWith.genericNetworkError(error);
      console.error('getCryptoAddress error: ', error);
      return null;
    }
  }

  async getHistoryCrypto({ accountId, options }: TGetHistoryCryptoParams) {
    try {
      const { data } = await this.fetch<TDepositHistoryCryptoResponse>({
        url: `/clientsarea/crypto/transactions/?account_id=${accountId}`,
        signal: options.signal,
      });

      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }

      notifyWith.genericNetworkError(error);
      console.error('getHistoryCrypto error: ', error);
      return null;
    }
  }
}

export const depositService = new DepositService();
