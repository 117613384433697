import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled, TClientsareaTheme } from 'theme';

import {
  TInlineNotificationProps,
  TInlineNotificationWrapperProps,
} from './InlineNotification.types';

const getColor = (
  type: TInlineNotificationProps['type'],
  theme: TClientsareaTheme,
  key: 'bg' | 'txt',
): string => {
  if (key === 'bg') {
    switch (type) {
      case 'error':
        return theme.color.bg.radical;
      case 'warning':
        return theme.color.bg.warning;
      case 'success':
      default:
        return theme.color.bg.source;
    }
  }

  switch (type) {
    case 'error':
      return theme.color.notifications.error;
    case 'warning':
      return theme.color.notifications.warning;
    case 'success':
    default:
      return theme.color.notifications.success;
  }
};

export const StyledInlineNotificationWrapper = styled('div', {
  shouldForwardProp: blockNonNativeProps(['type']),
})<TInlineNotificationWrapperProps>`
  padding: 8px 10px;
  display: flex;
  align-items: center;

  background-color: ${({ theme, type }) => getColor(type, theme, 'bg')};

  color: ${({ theme, type }) => getColor(type, theme, 'txt')};
  font-size: 13px;
  line-height: 16px;
`;
