import { TIconName } from 'react-ui-kit-exante';

export const typeIconNames: Record<string, string> = {
  FOREX: 'R',
  CASH: 'R',
  FX_SPOT: 'F',
  FXSPOT: 'F',
  FUTURE: 'I',
  CALENDAR_SPREAD: 'I',
  OPTION: 'C',
  OPTION_ON_FUTURE: 'C',
  BOND: 'B',
  STOCK: 'S',
  FUND: 'O',
  CFD: 'CFD',
  CRYPTO: 'CRYPTO',
  'STRUCTURED PRODUCTS': 'STRUCTURED',
  OTHER: 'OTHER',
};

export const REPORT_DOWNLOAD_ICON_NAME: Readonly<Record<string, TIconName>> = {
  xls: 'XLSIcon',
  pdf: 'PDFIcon',
};
