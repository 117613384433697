import { styled } from 'theme';

export const StyledTooltipContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledTooltipContentHeader = styled('h3')`
  margin: 0 0 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  font-family: ${({ theme }) => theme.font.header};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const StyledTooltipContentButtonWrapper = styled('div')`
  margin-top: 16px;

  display: flex;
  gap: 8px;
`;
