import { styled } from 'theme';

import { CardAction } from '../CardAction';

export const StyledActivateCardAction = styled(CardAction)`
  color: ${({ theme }) => theme.color.typo.action};

  svg {
    color: ${({ theme }) => theme.color.typo.action};
  }
`;

export const StyledActivateModalDescription = styled('p')`
  margin: 0;
  color: ${({ theme }) => theme.color.typo.promo};
`;
