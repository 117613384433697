import { useContext, useMemo } from 'react';

import { Accordion } from '../Accordion';
import { accordionContext } from '../context';
import { IAccordionProps } from '../types';

type TAccordionWithContextProps = Omit<IAccordionProps, 'isActive'>;

export const AccordionWithContext = ({
  id,
  children,
  ...rest
}: TAccordionWithContextProps) => {
  const context = useContext(accordionContext);
  const isActive = useMemo(() => context[id], [context, id]);

  return (
    <Accordion {...rest} id={id} isActive={isActive}>
      {children}
    </Accordion>
  );
};
