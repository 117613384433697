import { TContext, TState } from './types';

export const INITIAL_STATE: TState = {
  isInterestsEmpty: false,
  isInterestsLoading: false,
};

export const INITIAL_CONTEXT: TContext = {
  state: INITIAL_STATE,
  dispatch: () => {},
};
