import { FC, useContext } from 'react';
import { useMatches } from 'react-router-dom';
import { Skeleton } from 'react-ui-kit-exante';

import { getBreadcrumbs } from 'components/Header/components/Breadcrumbs/Breadcrumbs.helpers';
import { StyledHeaderTitle } from 'components/Header/components/HeaderTitle/HeaderTitle.styled';
import { BrandingContext, TranslationContext } from 'contexts';

export const HeaderTitle: FC = () => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const breadcrumbs = getBreadcrumbs({
    defaultUrl: null,
    customHomeTitle: branding?.branding?.breadcrumb_settings?.home_name,
    path: useMatches(),
    translate: t,
  });

  const title = breadcrumbs[breadcrumbs.length - 1]?.text || '';

  return (
    <StyledHeaderTitle className="header__title">
      {isTranslationsLoading ? (
        <Skeleton width={120} height={24} animation="wave" variant="rounded" />
      ) : (
        title
      )}
    </StyledHeaderTitle>
  );
};
