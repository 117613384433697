import { useContext, useState } from 'react';
import { Input } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import { SMS_CODE_LENGTH } from '../../../../Mfa.constants';
import { TSharedActionProps } from '../../../../Mfa.types';
import { useDisableByCode } from '../../../../hooks';
import { ConfirmPassword } from '../../../ConfirmPassword';
import { getTimerValue } from '../../../helpers';
import {
  StyledSharedMfaContainer,
  StyledSharedMfaFooterText,
  StyledSharedMfaInputWithFooterContainer,
  StyledSharedMfaResendButton,
  StyledSharedMfaText,
} from '../../../styled';

export const DisableSms = ({ onSuccess }: TSharedActionProps) => {
  const { t } = useContext(TranslationContext);
  const [key, setKey] = useState('');

  const {
    code,
    isVerifying,
    seconds,
    verifyErrorMessage,
    handleCodeChange,
    handleResend,
  } = useDisableByCode(SMS_CODE_LENGTH, key, onSuccess);

  if (!key) {
    return <ConfirmPassword onSuccess={setKey} />;
  }

  return (
    <StyledSharedMfaContainer className="Container">
      <StyledSharedMfaText className="Text">
        {t('layout__security_tabs__mfa_modal_sms_message')}
      </StyledSharedMfaText>
      <StyledSharedMfaInputWithFooterContainer className="InputContainer">
        <Input
          fullWidth
          message={verifyErrorMessage}
          error={Boolean(verifyErrorMessage)}
          disabled={isVerifying}
          placeholder={t('layout__security_tabs__mfa_modal_sms_placeholder')}
          iconRight={
            <StyledSharedMfaResendButton
              className="ResendButton"
              type="button"
              disabled={seconds !== 0 || isVerifying}
              onClick={handleResend}
            >
              {t('layout__security_tabs__mfa_label_resend')}
            </StyledSharedMfaResendButton>
          }
          value={code}
          onChange={(e) => handleCodeChange(e.target.value)}
        />
        <StyledSharedMfaFooterText
          visible={seconds !== 0}
          className="FooterText"
        >
          {getTimerValue(
            t('layout__settings__mfa__resend_after_seconds'),
            seconds,
          )}
        </StyledSharedMfaFooterText>
      </StyledSharedMfaInputWithFooterContainer>
    </StyledSharedMfaContainer>
  );
};
