import { CryptoTransactionStatus } from 'services/deposit';

export const STATUSES: Record<
  CryptoTransactionStatus,
  {
    key: 'Failed' | 'Pending' | 'Success' | 'Rejected';
    color: 'radical' | 'warning' | 'action';
  }
> = {
  [CryptoTransactionStatus.Failed]: {
    key: 'Failed',
    color: 'radical',
  },
  [CryptoTransactionStatus.Pending]: {
    key: 'Pending',
    color: 'warning',
  },
  [CryptoTransactionStatus.Processed]: {
    key: 'Success',
    color: 'action',
  },
  [CryptoTransactionStatus.Rejected]: {
    key: 'Rejected',
    color: 'radical',
  },
};

export const TERMINAL_STATUSES = [
  CryptoTransactionStatus.Pending,
  CryptoTransactionStatus.Processed,
];
