import { createSlice } from '@reduxjs/toolkit';

import { TMenuResponse } from 'services/menu';
import { EStatus } from 'types/api';
import { TStateField } from 'types/state';

import { fetchMenu } from './menu.actions';

const initialState: TStateField<TMenuResponse | null> = {
  status: EStatus.Idle,
  data: null,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMenu.pending, (state) => {
      state.status = EStatus.Loading;
    });

    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = EStatus.Succeeded;
    });
  },
});
