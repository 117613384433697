import { AnalysisContainer } from './components/AnalysisContainer';
import { AnalysisPageProvider } from './contexts/AnalysisPageContext';
import { FilterProvider } from './contexts/FilterContext';

export const Analysis = () => (
  <AnalysisPageProvider>
    <FilterProvider>
      <AnalysisContainer />
    </FilterProvider>
  </AnalysisPageProvider>
);
