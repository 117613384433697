import { FC, useContext } from 'react';

import { PageWrapper } from 'components/PageWrapper';
import { TranslationContext } from 'contexts';

import { ChallengeAccountSummaryContext } from '../../contexts';
import { ChallengeAccountSummaryBalance } from '../ChallengeAccountSummaryBalance/ChallengeAccountSummaryBalance';
import { ChallengeAccountSummaryInfo } from '../ChallengeAccountSummaryInfo/ChallengeAccountSummaryInfo';

import { StyledChallengeAccountSummaryWrapper } from './ChallengeAccountSummaryWrapper.styled';

export const ChallengeAccountSummaryWrapper: FC = () => {
  const { t } = useContext(TranslationContext);
  const { accountJoinedToChallengeStatus: statusKey } = useContext(
    ChallengeAccountSummaryContext,
  );

  return (
    <PageWrapper>
      <StyledChallengeAccountSummaryWrapper
        title={t('page__challenge_account_summary')}
        className="ChallengeAccountSummaryWrapper"
      >
        {statusKey ? (
          <p>{t(statusKey)}</p>
        ) : (
          <>
            <ChallengeAccountSummaryInfo />
            <ChallengeAccountSummaryBalance />
          </>
        )}
      </StyledChallengeAccountSummaryWrapper>
    </PageWrapper>
  );
};
