import Cookies from 'js-cookie';
import { useContext } from 'react';
import { useData } from 'react-ui-kit-exante';

import { TUserPreferred, userDetailsService } from 'services/userDetails';

import { BrandingContext } from '../../BrandingContext';

export type TUseUserPreferredValue = {
  userPreferred: TUserPreferred | null;
  userPreferredLoading: boolean;
  fetchUserPreferred: () => Promise<void>;
  updateUserPreferred: (preferredData: TUserPreferred) => Promise<void>;
};

export const useUserPreferred = (): TUseUserPreferredValue => {
  const { branding } = useContext(BrandingContext);

  const {
    data,
    fetchData: fetchUserPreferred,
    isLoading: isLoadingFetch,
  } = useData({
    onFetch: () => userDetailsService.getUserPreferred(),
  });

  const { fetchData: updateUserPreferred, isLoading: isLoadingUpdate } =
    useData({
      loading: false,
      onFetch: async (preferredData) => {
        if (branding?.is_supervisor) {
          return;
        }

        if (preferredData.preferableAccount) {
          // WORKAROUND: сохранение выбранного аккаунта для vue-страниц
          Cookies.set('account', preferredData.preferableAccount);
        }

        await userDetailsService.setUserPreferred(preferredData);
      },
    });

  const isLoading = isLoadingFetch || isLoadingUpdate;

  return {
    userPreferred: data,
    userPreferredLoading: isLoading,
    fetchUserPreferred,
    updateUserPreferred,
  };
};
