import { SyntheticEvent, useContext, useState } from 'react';
import {
  getBrowserDateFormat,
  getBrowserLocale,
  InputDatePicker,
} from 'react-ui-kit-exante';

import { FiltersGroup } from 'components/FiltersGroup';
import { TFilterOption } from 'components/FiltersGroup/FiltersGroup.types';
import { TranslationContext } from 'contexts';
import { Dates, today } from 'helpers/dates';

import { getReportTypeFilters } from './GeneratedReports.helpers';
import {
  StyledGeneratedReportsDateFilters,
  StyledGeneratedReportsDateLine,
  StyledGeneratedReportsFilters,
} from './GeneratedReports.styled';
import { GeneratedReportsTable } from './components/GeneratedReportsTable';

export const GeneratedReports = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const [typeFilterValue, setTypeFilterValue] = useState<string[]>(['all']);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);

  const browserFormat = getBrowserDateFormat(false, true, getBrowserLocale);
  const calendarLocale = currentLanguage === 'en' ? 'en-us' : currentLanguage;

  const typeFilters = getReportTypeFilters(t);

  const onTypeFilterChange = (value: TFilterOption['value'][]) => {
    setTypeFilterValue(value);
  };

  const onChangeFrom = (date: Date | null, event?: SyntheticEvent) => {
    const value = (event?.target as HTMLInputElement)?.value;
    if (date && (value?.length === browserFormat.length || !value)) {
      const from = Dates.format(date, false);
      setDateFrom(from);
    } else if (!date) {
      setDateFrom(date);
    }
  };
  const onChangeTo = (date: Date | null, event?: SyntheticEvent) => {
    const value = (event?.target as HTMLInputElement)?.value;
    if (date && (value?.length === browserFormat.length || !value)) {
      const from = Dates.format(date, false);
      setDateTo(from);
    } else if (!date) {
      setDateTo(date);
    }
  };

  const dateFromValue = dateFrom ? new Date(dateFrom) : null;
  const dateToValue = dateTo ? new Date(dateTo) : null;

  return (
    <div>
      <StyledGeneratedReportsFilters className="GeneratedReportsFilters">
        <StyledGeneratedReportsDateFilters className="GeneratedReportsDateFilters">
          <InputDatePicker
            inputProps={{
              size: 'small',
              sx: {
                width: 148,
              },
            }}
            onChange={onChangeFrom}
            selected={dateFromValue}
            maxDate={dateToValue || today}
            locale={calendarLocale}
            autoComplete="off"
            selectsStart
            startDate={dateFromValue}
            endDate={dateToValue}
            data-test-id="generated_reports__date-from"
            showSkeleton={isTranslationsLoading}
          />
          <StyledGeneratedReportsDateLine className="GeneratedReportsDateLine" />
          <InputDatePicker
            inputProps={{
              size: 'small',
              sx: {
                width: 148,
              },
            }}
            onChange={onChangeTo}
            selected={dateToValue}
            minDate={dateFromValue}
            maxDate={today}
            locale={calendarLocale}
            autoComplete="off"
            selectsEnd
            startDate={dateFromValue}
            endDate={dateToValue}
            data-test-id="generated_reports__date-to"
            showSkeleton={isTranslationsLoading}
          />
        </StyledGeneratedReportsDateFilters>
        <FiltersGroup
          filters={typeFilters}
          values={typeFilterValue}
          onChange={onTypeFilterChange}
          showSkeleton={isTranslationsLoading}
        />
      </StyledGeneratedReportsFilters>
      <GeneratedReportsTable
        filterTypes={typeFilterValue}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
    </div>
  );
};
