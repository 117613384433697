import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios';
import Cookies from 'js-cookie';

import { UNAUTHORIZE_ERRORS } from 'constants/api';
import { BASE_URL } from 'constants/endpoints';
import { PATHS } from 'router/router.constants';
import { NO_CACHE_HEADERS } from 'services/constants';

import { getParamsSerializer } from '../getParamsSerializer';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface IRequestArguments<T extends Record<keyof T, unknown> = {}> {
  url: string;
  method?: Method;
  headers?: AxiosRequestHeaders;
  params?: T;
  data?: unknown;
  signal?: AbortSignal;
}

export class ApiRequest {
  noUseAuth = false;

  catchUnauthorized = true;

  async fetch<T>(
    args: IRequestArguments,
    options?: Omit<AxiosRequestConfig, keyof IRequestArguments>,
  ): Promise<AxiosResponse<T>> {
    const { url: originalUrl, method = 'GET', data, params, signal } = args;

    let headers: AxiosRequestHeaders = {};

    if (!this.noUseAuth) {
      headers.Authorization = `Bearer ${Cookies.get('x_access')}`;
    }

    headers = { ...headers, ...args.headers };

    const fullApiUrl = originalUrl.includes('http')
      ? originalUrl
      : `${BASE_URL}${originalUrl}`;

    const config: AxiosRequestConfig = {
      url: fullApiUrl,
      method,
      data: method !== 'GET' ? data : null,
      headers,
      params,
      paramsSerializer: getParamsSerializer(),
      signal,
      ...options,
    };

    try {
      return await axios(config);
    } catch (error) {
      const status = (error as AxiosError)?.response?.status;

      if (
        this.catchUnauthorized &&
        status &&
        UNAUTHORIZE_ERRORS.includes(status)
      ) {
        const goToLogin = () => {
          Cookies.remove('x_access');
          Cookies.remove('y_access');
          window.location.href = `${PATHS.LOGIN}?auth_fault`;
        };

        try {
          await axios({
            method: 'GET',
            url: '/clientsarea/rest/oauth/update_access_token/',
            headers: NO_CACHE_HEADERS,
          });

          return await axios(config);
        } catch (updateError) {
          goToLogin();

          throw updateError;
        }
      }

      throw error;
    }
  }
}
