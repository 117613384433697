import { FC, useContext } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';
import { TCorporateAction } from 'services/corporateActions';

import {
  CORPORATE_ACTIONS_POSTINGS_STATUS_TO_ICON_NAME_MAP,
  CORPORATE_ACTIONS_POSTINGS_STATUS_TO_KEY_MAP,
} from './CorporateActionsPostingsStatus.constants';
import {
  StyledCorporateActionsPostingsStatusText,
  StyledCorporateActionsPostingsStatusWrapper,
} from './CorporateActionsPostingsStatus.styled';

export const CorporateActionsPostingsStatus: FC<{
  status: TCorporateAction['status'] | null;
}> = ({ status }) => {
  const { t } = useContext(TranslationContext);

  if (!status) {
    return <span>{DEFAULT_EMPTY_PLACEHOLDER}</span>;
  }

  const textKey: string =
    CORPORATE_ACTIONS_POSTINGS_STATUS_TO_KEY_MAP[status] || status;

  return (
    <StyledCorporateActionsPostingsStatusWrapper
      className="CorporateActionsPostingsStatusWrapper"
      status={status}
    >
      <IconButton
        iconName={CORPORATE_ACTIONS_POSTINGS_STATUS_TO_ICON_NAME_MAP[status]}
        iconSize={16}
      />
      <StyledCorporateActionsPostingsStatusText className="CorporateActionsPostingsStatusText">
        {t(textKey)}
      </StyledCorporateActionsPostingsStatusText>
    </StyledCorporateActionsPostingsStatusWrapper>
  );
};
