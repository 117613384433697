import { BASE_URL } from 'constants/endpoints';
import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';
import { trimUrlSlashes } from 'helpers/trimUrlSlashes';

import {
  TDocumentPdf,
  TDocumentPreviewPdf,
  TLegalDocsResponse,
  TSignDocResponse,
} from './legal-docs.types';

class LegalDocsService extends ApiRequest {
  async getLegalDocs(lang: string) {
    try {
      const { data } = await this.fetch<TLegalDocsResponse>({
        url: `/${lang}/clientsarea/spa-layout/?name=legal-docs`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getLegalDocs error: ', error);
      return null;
    }
  }

  async getDocumentPreviewPdf(url: string): Promise<string> {
    try {
      const { data } = await this.fetch<TDocumentPreviewPdf>({
        url,
        params: {
          download: false,
        },
      });

      if (data.ready && data.url) {
        return data.url;
      }

      return await new Promise((resolve) => {
        setTimeout(async () => {
          resolve(await this.getDocumentPreviewPdf(url));
        }, 1000);
      });
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getDocumentPreviewPdf error: ', error);
      return '';
    }
  }

  async getDocumentPdf(
    url: string,
    isSigned?: boolean,
    lang = 'en',
  ): Promise<TDocumentPdf | null> {
    try {
      const type = isSigned ? 'signed_form' : 'not_signed_form';

      const urlParsed = new URL(url, BASE_URL);
      urlParsed.searchParams.set('type', type);
      urlParsed.searchParams.set('language', lang);

      const { data } = await this.fetch<TDocumentPdf>({
        url: urlParsed.toString(),
      });

      if (data.url && !data.pdf) {
        const previewUrl = await this.getDocumentPreviewPdf(data.url);

        if (previewUrl) {
          // to show doc in localhost set mod header X-Frame-Options: *
          data.pdf = `${BASE_URL}/${trimUrlSlashes(previewUrl)}/`;
        }
      }
      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('geDocumentPdf error: ', error);
      return null;
    }
  }

  async getHasToSign(): Promise<boolean> {
    try {
      const { data } = await this.fetch<boolean>({
        url: `/clientsarea/application/legal_docs/has_to_sign/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getHasToSign error: ', error);
      return false;
    }
  }

  async signDoc(id: number, lang: string): Promise<TSignDocResponse | null> {
    try {
      const { data } = await this.fetch<TSignDocResponse>({
        url: '/clientsarea/rest/wizard/legal-docs/sign/',
        method: 'POST',
        data: {
          id,
          token: null,
          language: lang,
        },
      });

      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('signDoc', error);

      return null;
    }
  }
}

export const legalDocsService = new LegalDocsService();
