import { FC, useContext } from 'react';
import { IntercomProvider } from 'react-use-intercom';

import { TChildren } from 'types/TChildren';

import { BrandingContext } from '../BrandingContext';

import { INTERCOM_API_BASE_DEFAULT } from './constants';

export const IntercomAppProvider: FC<TChildren> = ({ children }) => {
  const { branding } = useContext(BrandingContext);

  const appId = branding?.branding?.intercom_app_id;

  const isEnabled =
    !!appId &&
    branding &&
    branding?.branding?.show_intercom_chat &&
    !branding?.is_supervisor;

  const apiBase =
    branding?.branding?.intercom_api_base || INTERCOM_API_BASE_DEFAULT;

  return (
    <IntercomProvider
      appId={appId || ''}
      shouldInitialize={isEnabled}
      apiBase={apiBase}
    >
      {children}
    </IntercomProvider>
  );
};
