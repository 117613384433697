import cn from 'classnames';
import { FC } from 'react';
import { InfoIcon } from 'react-ui-kit-exante';

import { CopyButton } from 'components/CopyButton';

import { StyledText, StyledTextContainer } from './Text.styled';
import { TTextProps } from './Text.types';

export const Text: FC<TTextProps> = ({
  children,
  color = 'primary',
  size = '14px',
  weight = '400',
  withCopyButton,
  withInfoIcon,
  copyButtonProps = {
    iconSize: 16,
    iconColor: 'secondary',
  },
  disabled,
  ellipsis,
  className,
}) => {
  return (
    <StyledTextContainer
      className={cn('TextContainer', className)}
      disabled={disabled}
      ellipsis={ellipsis}
    >
      {withInfoIcon && <InfoIcon width={18} height={18} />}
      <StyledText
        className="Text"
        color={color}
        size={size}
        weight={weight}
        ellipsis={ellipsis}
      >
        {children}
      </StyledText>
      {withCopyButton && children && typeof children === 'string' && (
        <CopyButton text={children} {...copyButtonProps} />
      )}
    </StyledTextContainer>
  );
};
