import { AxiosError } from 'axios';
import { useContext, useEffect, useReducer } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';

import { TransfersContext } from '../../../../../context';

import { initialToMyAccountState } from './constants';
import { toMyAccountReducer } from './reducer';
import { updateToMyAccountState } from './service';

export const useToMyAccount = (selectedAccountId: string) => {
  const {
    accountDetailsState: { targetAccounts },
    transfersState,
    cashTransferTab,
  } = useContext(TransfersContext);

  const { currentLanguage } = useContext(TranslationContext);
  const [toMyAccountState, toMyAccountDispatch] = useReducer(
    toMyAccountReducer,
    initialToMyAccountState,
  );

  const fetchToMyAccountState = async (currency: string) => {
    toMyAccountDispatch({ type: 'FETCH_STATE_START' });

    try {
      const payload = await updateToMyAccountState(
        selectedAccountId,
        currency,
        targetAccounts,
        currentLanguage,
        transfersState.user?.is_superuser,
      );

      toMyAccountDispatch({ type: 'FETCH_STATE_SUCCESS', payload });
    } catch (error) {
      const { message } = error as AxiosError;

      toMyAccountDispatch({ type: 'FETCH_STATE_ERROR', payload: message });
    }
  };

  useEffect(() => {
    if (toMyAccountState.currency && cashTransferTab === 'MY_ACCOUNT') {
      fetchToMyAccountState(toMyAccountState.currency);
    }
  }, [toMyAccountState.currency, cashTransferTab]);

  return {
    toMyAccountState,
    toMyAccountDispatch,
  };
};
