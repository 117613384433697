import { TFunction } from 'i18next';
import { filter, identity } from 'lodash';

import { clearNumberString } from 'helpers/numbers';

export const TRANSFER_INPUT_LIMIT = 16;

export const validateAmountDefault = (value: string): boolean =>
  !(
    /[^\d.]/.test(value) || // only numbers and dots
    [...value.matchAll(/\./g)].length > 1 || // one dot
    /\.\d{3,}/.test(value) || // only two digit fraction
    value.split('.')[0].length > TRANSFER_INPUT_LIMIT
  );

const formatAmount = (frmt: string, value: string, currency: string) =>
  frmt.replace('VALUE', value).replace('CURRENCY', currency);

export type TGetCommissionHint = {
  t: TFunction;
  minimum?: string | number;
  maximum?: string | number;
  value: string;
  effective?: number | ((amount: number) => number) | string;
  currency: string;
};

export const getCommissionMinMaxHint = ({
  t,
  minimum,
  maximum,
  value,
  effective,
  currency,
}: TGetCommissionHint) => {
  const minmax = filter(
    [
      minimum === `0 ${currency}` || !minimum
        ? null
        : formatAmount(
            t('layout__commissions__minimum_value_currency'),
            String(minimum),
            '',
          ).trim(),
      maximum === `0 ${currency}` || !maximum
        ? null
        : formatAmount(
            t('layout__commissions__maximum_value_currency'),
            String(maximum),
            '',
          ).trim(),
    ],
    identity,
  ).join('; ');

  const formattedEffective = clearNumberString(effective);

  return [
    t('layout__commissions__minmax_commission', {
      VALUE: value.replace('%', ''), // value already has % sign
      MINMAX: minmax.length <= 0 ? '' : `(${minmax})`,
    }),
    t('layout__commissions__effective_commission', {
      VALUE: formattedEffective,
      CURRENCY: currency,
    }),
  ].join('\n');
};
