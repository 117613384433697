import { FC } from 'react';

import { StyledChallengeAccountSummaryInfoSkeletonBlock } from './ChallengeAccountSummaryInfoSkeleton.styled';

export const ChallengeAccountSummarySkeleton: FC = () => {
  return (
    <>
      {[...Array(7)].map((_, i) => (
        <StyledChallengeAccountSummaryInfoSkeletonBlock
          key={`block_${i}`}
          variant="rounded"
          animation="wave"
        />
      ))}
    </>
  );
};
