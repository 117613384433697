import { IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledInviteCellItem = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.color.icon.action};
`;

export const StyledInviteCellItemCopyButton = styled(IconButton)`
  color: ${({ theme }) => theme.color.icon.action};
`;
