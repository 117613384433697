import {
  StyledProductImageSkeleton,
  StyledProductImageSkeletonWrapper,
} from './ProductImageSkeleton.styled';

export const ProductImageSkeleton = () => (
  <StyledProductImageSkeletonWrapper className="ProductImageWrapper">
    <StyledProductImageSkeleton variant="rounded" animation="wave" />
  </StyledProductImageSkeletonWrapper>
);
