import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAssetManagementSetupTablePanel = styled(Panel)`
  padding: 0;

  position: relative;

  .PanelTitle {
    padding: 24px 24px 0;
    margin-bottom: 0;
  }
`;
