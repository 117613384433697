import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledDownloadsPanel = styled(Panel)`
  && {
    padding: 0;
  }

  & .PanelTitle {
    padding: 24px;
    margin-bottom: 0;
  }
`;

export const StyledDownloadsEmptyText = styled('p')`
  padding: 24px;
`;
