import { FC, useContext } from 'react';
import { IconButton } from 'react-ui-kit-exante';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { StyledChartWrapper } from 'components/Chart';
import { getChartColors } from 'components/Chart/Chart.constants';
import { getYWidth } from 'components/Chart/Chart.helpers';
import { ChartSkeleton } from 'components/Chart/ChartSkeleton';
import { CustomLegend } from 'components/Chart/CustomLegend';
import { CustomTooltip } from 'components/Chart/CustomTooltip';
import { SubHeader } from 'components/SubHeader/SubHeader';
import { TranslationContext } from 'contexts';
import { Dates } from 'helpers/dates';
import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { downloadAnalysisReport } from 'pages/Portfolio/tabs/Analysis/Analysis.helpers';
import { FilterContext } from 'pages/Portfolio/tabs/Analysis/contexts/FilterContext';
import { TAccountsMetrics } from 'services/analysis';
import { useTheme } from 'theme';

export type TNetAssetValueProps = {
  data?: TAccountsMetrics['nav'];
  isDataLoading?: boolean;
};
export const NetAssetValue: FC<TNetAssetValueProps> = ({
  data = {},
  isDataLoading,
}) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter();
  const { filters } = useContext(FilterContext);
  const theme = useTheme();

  const chartData = Object.keys(data || {}).map((key) => {
    return {
      date: Dates.format(new Date(key), false),
      ...data[key],
    };
  });

  const downloadReport = () => {
    downloadAnalysisReport('nav', filters, currentLanguage);
  };

  const chartColors = getChartColors(theme);

  const yWidth = getYWidth(chartData, ['nav', 'requiredMargin']);

  const isEmptyDataForChart = !chartData.find(
    ({ nav, requiredMargin }) => nav || requiredMargin,
  );

  if (!isDataLoading && isEmptyDataForChart) {
    return null;
  }

  return (
    <>
      <SubHeader
        title={t('layout__performance__net_asset_value')}
        info={
          <>
            <p>{t('layout__performance__net_asset_shows')}</p>
            <p>{t('layout__performance__nav_reflects')}</p>
            <p>{t('layout__performance__required_margin_shows')}</p>
          </>
        }
        action={
          <IconButton
            iconName="XLSIcon"
            iconColor="secondary"
            iconSize={24}
            onClick={downloadReport}
            className="NetAssetValueDownload"
            disabled={isDataLoading}
          />
        }
      />
      <StyledChartWrapper className="ChartWrapper">
        {isDataLoading ? (
          <ChartSkeleton />
        ) : (
          <ResponsiveContainer minWidth={theme?.breakpoints?.values?.sm}>
            <LineChart data={chartData}>
              <CartesianGrid />
              <Line
                type="monotone"
                dataKey="nav"
                stroke={chartColors.positive}
                dot={<span />}
              />
              <Line
                type="monotone"
                dataKey="requiredMargin"
                stroke={chartColors.warning}
                dot={<span />}
              />
              <XAxis
                dataKey="date"
                minTickGap={12}
                padding={{ left: 10 }}
                interval="preserveStartEnd"
                tickFormatter={dateFormatter}
              />
              <YAxis
                orientation="right"
                width={yWidth}
                minTickGap={5}
                tickCount={10}
                tickFormatter={(value) =>
                  formatCurrency(currencyFormatter, value, true)
                }
              />
              <Legend
                content={<CustomLegend yWidth={yWidth} hideAxisName />}
                verticalAlign="top"
                align="right"
              />
              <Tooltip
                content={<CustomTooltip />}
                wrapperStyle={{ outline: 'none' }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </StyledChartWrapper>
    </>
  );
};
