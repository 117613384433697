import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TNotification,
  TNotificationsData,
  TNotificationsResponse,
} from './notifications.types';

class NotificationsService extends ApiRequest {
  async getNotifications(
    lang: string,
    kafkaOnly = false,
  ): Promise<TNotificationsData | null> {
    try {
      const { data } = await this.fetch<TNotificationsResponse>({
        url: `/${lang}/clientsarea/rest/client-notifications/`,
        params: kafkaOnly
          ? {
              kf_only: true,
            }
          : undefined,
      });

      return {
        ...data,
        items: data.items || [],
      };
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getNotifications', error);
      return null;
    }
  }

  async acceptNotification(pk: TNotification['pk']) {
    try {
      await this.fetch({
        url: '/clientsarea/rest/client-notifications/agree/',
        method: 'post',
        data: { pk },
      });

      return true;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('acceptNotification', error);
      return false;
    }
  }
}

export const notificationsService = new NotificationsService();
