import { createTheme, ThemeOptions } from '@mui/material/styles';
import { merge } from 'lodash';
import {
  defaultTheme as kitTheme,
  useTheme as useKitTheme,
} from 'react-ui-kit-exante';

import { ClientsareaThemeExtensions, TClientsareaTheme } from './theme.types';

export const caThemeValues: ClientsareaThemeExtensions = {
  color: {
    table: {
      total: {
        bg: '#FEFBF5',
        color: '#252C2E',
      },
      expired: {
        bg: '#FFFFFF',
        color: '#B8BBB7',
      },
    },
    icon: {
      premium: '#3A4FCE',
    },
    tableHeader: {
      default: '#818C99',
    },
  },
  size: {
    loader: {
      lg: '24px',
    },
  },
  sizes: {
    mobileHeaderHeight: '96px',
    desktopMenuWidth: '216px',
    logoWidth: '143px',
    logoHeight: '32px',
  },
  filters: {
    popup:
      'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.04)) drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.04))',
  },
};

export const theme = createTheme(
  merge(kitTheme, caThemeValues) as ThemeOptions,
) as TClientsareaTheme;
export const useTheme = useKitTheme<TClientsareaTheme>;
