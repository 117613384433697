import { TApplicationSettings } from 'services/application';
import { EStatus } from 'types/api';

import { applicationInitialState } from './application.constants';
import { TApplicationState } from './application.types';

export const getApplicationState = (
  response?: TApplicationSettings | null,
): TApplicationState => {
  if (!response || !response.state?.mfa?.services || !response.mfa) {
    return {
      ...applicationInitialState,
      status: EStatus.Failed,
    };
  }

  const { mfa, state } = response;
  const services = new Set(state.mfa.services.map((service) => service.alias));

  return {
    mfa: {
      allowed: mfa,
      atp: {
        enabled: services.has('atp'),
        type_name: state.mfa.site.type_name,
      },
      site: {
        enabled: services.has('site'),
        type_name: state.mfa.site.type_name,
      },
    },
    accountSettings: {
      is_account_descriptions_available:
        state.is_account_descriptions_available,
      is_autodebit_available: state.is_autodebit_available,
      is_cash_conversion_available: state.is_cash_conversion_available,
      is_margin_trading_available: state.is_margin_trading_available,
      is_reset_demo_available: state.is_reset_demo_available,
      is_account_management_available: state.is_account_management_available,
      is_demo_enabled: state.is_demo_enabled,
    },
    status: EStatus.Succeeded,
  };
};
