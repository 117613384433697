import {
  FC,
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';
import { useDebouncedCallback } from 'use-debounce';

import { StyledNoDataNotification } from 'components/NoDataNotification';
import { TabsSkeleton } from 'components/Tabs/components';
import { TranslationContext } from 'contexts';
import { FeeSetupStatuses } from 'pages/AssetManagementSetup/AseetManagementSetup.constants';
import { SetupTab } from 'pages/AssetManagementSetup/components/AssetManagementSetupCreation/components/SetupTab';
import { assetManagementContext } from 'pages/AssetManagementSetup/contexts/AssetManagementContext';
import {
  assetManagementService,
  TAssetManagementFeeSetup,
} from 'services/assetManagement';

import {
  StyledAssetManagementSetupCreationAdd,
  StyledAssetManagementSetupCreationNumber,
  StyledAssetManagementSetupCreationPanel,
  StyledAssetManagementSetupCreationTab,
  StyledAssetManagementSetupCreationTabs,
  StyledAssetManagementSetupCreationTabWrapper,
} from './AssetManagementSetupCreation.styled';
import { AssetManagementSetupSkeleton } from './AssetManagementSetupSkeleton';
import { SetupsProvider } from './contexts';

const checkFields = ['name', 'value', 'management_fee', 'link'];

export const AssetManagementSetupCreation: FC = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [setups, setSetups] = useState<TAssetManagementFeeSetup[]>([]);
  const { reloadSetupTable } = useContext(assetManagementContext);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: unknown,
  ) => {
    setSelectedTab(newValue as number);
  };

  const { fetchData: fetchFeeSetups, isLoading } = useData({
    onFetch: () =>
      assetManagementService.getListFeeSetups(
        {
          setup_is_complete: false,
        },
        currentLanguage,
      ),
    onSuccess: (responseData) => {
      const { results } = responseData || {};
      if (results) {
        setSetups(results);
      }
    },
  });

  useEffect(() => {
    fetchFeeSetups();
  }, []);

  const addSetup = useDebouncedCallback(async () => {
    const newSetup = await assetManagementService.createFeeSetup();
    if (newSetup) {
      setSetups([newSetup, ...setups]);
      setSelectedTab(0);
    }

    return newSetup;
  }, 500);

  const isDeletePossible = (setup: TAssetManagementFeeSetup) => {
    return !setup.setup_is_complete;
  };

  const deleteSetup = async (setup: TAssetManagementFeeSetup) => {
    if (!isDeletePossible(setup)) {
      return;
    }
    const setupId = setup.id;
    const isDeleted = await assetManagementService.deleteFeeSetup(setup.id);
    if (isDeleted) {
      const newSetups = setups.filter((item) => item.id !== setupId);
      setSetups(newSetups);
    }
  };

  const changeSetup = useDebouncedCallback(
    async (setup: TAssetManagementFeeSetup, commit = true) => {
      let updatedSetup: TAssetManagementFeeSetup = setup;
      if (commit) {
        const newSetup = await assetManagementService.updateSetup(setup);
        if (newSetup) {
          updatedSetup = newSetup;
        } else {
          const changedSetups = setups.map((item) =>
            item.id === setup.id ? { ...setup, id: item.id } : item,
          );
          // reload if error
          setSetups(changedSetups);
          setSetups({ ...setups });
          return;
        }
      }

      if (updatedSetup) {
        const changedSetups = setups.map((item) =>
          item.id === updatedSetup.id ? { ...updatedSetup, id: item.id } : item,
        );
        setSetups(changedSetups);
      }
    },
    1000,
  );

  const completeSetup = async (setup: TAssetManagementFeeSetup) => {
    const newSetup = await assetManagementService.completeSetup(setup.id);
    if (newSetup) {
      const changedSetups = setups.filter((item) => item.id !== setup.id);
      setSetups(changedSetups);
      reloadSetupTable?.();
    }
  };

  const generateDoc = async (setup: TAssetManagementFeeSetup) => {
    const newSetup = await assetManagementService.generateDoc(setup.id);
    if (newSetup) {
      changeSetup(newSetup, false);
    }
    return newSetup || setup;
  };

  const uploadDoc = async (setup: TAssetManagementFeeSetup, file: File) => {
    const newSetup = await assetManagementService.uploadDoc(setup.id, file);
    if (newSetup) {
      changeSetup(newSetup, false);
    }
    return newSetup || setup;
  };

  const sendToReview = async (setup: TAssetManagementFeeSetup) => {
    const newSetup = await assetManagementService.sendToReview(setup.id);
    if (newSetup) {
      changeSetup(newSetup, false);
    }
    return newSetup || setup;
  };

  const formattingTabLabel = (setup: TAssetManagementFeeSetup) => {
    const completeFields =
      Object.entries(setup).filter(
        ([prop, value]) =>
          (!!value || value === 0) && checkFields.includes(prop),
      ).length + (setup.status === FeeSetupStatuses.APPROVED ? 1 : 0);
    return (
      <>
        <StyledAssetManagementSetupCreationNumber className="AssetManagementSetupCreationNumber">
          {`${completeFields}/${checkFields.length + 1}`}
        </StyledAssetManagementSetupCreationNumber>

        {setup.name}
      </>
    );
  };
  const providerContextValue = {
    changeSetup,
    addSetup,
    deleteSetup,
    completeSetup,
    generateDoc,
    uploadDoc,
    sendToReview,
    isDeletePossible,
  };
  const addAction: ReactElement = (
    <StyledAssetManagementSetupCreationAdd
      iconColor="action"
      iconSize={32}
      onClick={addSetup}
      iconName="AddIcon"
      label={t('asset_management__setup_tab_add_setup')}
    />
  );

  return (
    <StyledAssetManagementSetupCreationPanel
      title={t('asset_management__setup_creation')}
      className="AssetManagementSetupCreationPanel"
      action={addAction}
    >
      <SetupsProvider value={providerContextValue}>
        {setups.length > 0 || isLoading ? (
          <>
            {!isLoading ? (
              <StyledAssetManagementSetupCreationTabs
                value={selectedTab}
                onChange={handleChange}
                variant="scrollable"
                orientation="horizontal"
                scrollButtons={false}
                className="AssetManagementSetupCreationTabs"
              >
                {setups.map((setup, index) => (
                  <StyledAssetManagementSetupCreationTab
                    label={formattingTabLabel(setup)}
                    value={index}
                    key={setup.id || setup.name}
                  />
                ))}
              </StyledAssetManagementSetupCreationTabs>
            ) : (
              <TabsSkeleton />
            )}
            <StyledAssetManagementSetupCreationTabWrapper className="AssetManagementSetupCreationTabWrapper">
              {!isLoading ? (
                setups.map((setup, index) => (
                  <SetupTab
                    value={selectedTab}
                    index={index}
                    key={setup.id || setup.name}
                    setup={setup}
                  />
                ))
              ) : (
                <AssetManagementSetupSkeleton />
              )}
            </StyledAssetManagementSetupCreationTabWrapper>
          </>
        ) : (
          <StyledNoDataNotification className="NoDataNotification">
            {t('asset_management__no_setup_yet')}
          </StyledNoDataNotification>
        )}
      </SetupsProvider>
    </StyledAssetManagementSetupCreationPanel>
  );
};
