import { Panel, Tabs, Tab, IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAssetManagementSetupCreationPanel = styled(Panel)`
  margin-bottom: 16px;
  padding: 0;

  position: relative;

  .PanelTitle {
    padding: 24px 24px 0;
    margin-bottom: 0;
  }

  .MuiTabs-root {
    margin: 24px 24px 0;
  }
`;

export const StyledAssetManagementSetupCreationAdd = styled(IconButton)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    span:nth-of-type(2) {
      position: absolute !important;

      width: 1px;
      height: 1px;
      overflow: hidden;

      white-space: nowrap;

      clip: rect(1px, 1px, 1px, 1px);
    }
  }
`;

export const StyledAssetManagementSetupCreationTabs = styled(Tabs)`
  margin-bottom: 16px;
`;

export const StyledAssetManagementSetupCreationTabWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    background-color: ${({ theme }) => theme.color.bg.secondary};
    padding: 24px;
  }
`;

export const StyledAssetManagementSetupCreationTab = styled(Tab)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 7px;
`;

export const StyledAssetManagementSetupCreationNumber = styled('span')`
  padding: 0 5px;
  border: 1px solid currentcolor;
  border-radius: 30% / 50%;
`;
