import { createElement, FC, useContext, useMemo } from 'react';

import { CheckoutDepositErrorIcon } from 'assets/icons';
import { BrandingContext, TranslationContext } from 'contexts';
import { useQuery } from 'hooks/index';
import { PATHS } from 'router';

import {
  StyledCheckoutButton,
  StyledCheckoutStatus,
  StyledCheckoutTitle,
  StyledCheckoutWrapper,
} from '../Checkout.styled';

export const CheckoutFailure: FC = () => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const query = useQuery();
  const accountId = query.get('account_id');

  const depositHref = useMemo(() => {
    if (accountId) {
      return `${PATHS.CHECKOUT_DEPOSIT}?account_id=${accountId}`;
    }
    return PATHS.CHECKOUT_DEPOSIT;
  }, [accountId]);

  const phone = branding?.legal_texts?.contact;

  return (
    <StyledCheckoutWrapper className="CheckoutWrapper">
      <StyledCheckoutStatus className="CheckoutStatus">
        {createElement(CheckoutDepositErrorIcon)}

        <StyledCheckoutTitle className="CheckoutTitle">
          {t('checkout__failure__title')}
        </StyledCheckoutTitle>
        <p>
          {t('checkout__failure__transaction_id')}:{' '}
          {window.localStorage.getItem('last_checkout_id')}
        </p>
        <p>
          {t('checkout__failure__message')}{' '}
          {!!phone && <a href={`tel:${phone}`}>{phone}</a>}
        </p>
      </StyledCheckoutStatus>

      <StyledCheckoutButton href={depositHref} fullWidth>
        {t('checkout__failure__ok_btn')}
      </StyledCheckoutButton>
    </StyledCheckoutWrapper>
  );
};
