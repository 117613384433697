import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

type TStyledLogoProps = {
  logoUrl: string;
  small?: boolean;
};

export const StyledLogo = styled('div', {
  shouldForwardProp: blockNonNativeProps(['logoUrl', 'small']),
})<TStyledLogoProps>`
  width: ${({ theme, small }) =>
    small ? theme.sizes.logoHeight : theme.sizes.logoWidth};
  height: ${({ theme }) => theme.sizes.logoHeight};
  margin: ${({ small }) => (small ? 0 : '16px 0 8px 16px')};
  background-image: url('${({ logoUrl }) => logoUrl}');
  background-repeat: no-repeat;
  background-size: contain;
`;

export const StyledVisuallyHidden = styled('span')`
  position: absolute !important;

  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;

  border: 0 !important;

  clip: rect(1px, 1px, 1px, 1px) !important;
`;
