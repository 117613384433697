import { OVERRUN_MODE_VALUE } from 'constants/margin';
import { TNumberedMetrics } from 'types/accounts';
import { IMarginData } from 'types/margin';

export function getMarginData({
  netAssetValue,
  requiredMargin,
  freeMoney,
  totalBlockedMargin,
  marginUtilization,
}: TNumberedMetrics): IMarginData {
  const isMarginCall = Number(netAssetValue) < Number(requiredMargin);
  const available = isMarginCall
    ? Number(requiredMargin) - Number(netAssetValue)
    : freeMoney;
  const positionMargin =
    requiredMargin === null || totalBlockedMargin === null
      ? null
      : requiredMargin - totalBlockedMargin;
  const moneyUsedFromMarginPercent = Number(marginUtilization) * 100;
  const mode =
    moneyUsedFromMarginPercent > OVERRUN_MODE_VALUE ? 'overrun' : 'normal';

  return {
    isMarginCall,
    available,
    moneyUsedFromMarginPercent,
    positionMargin,
    mode,
  };
}
