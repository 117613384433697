export enum FeeSetupStatuses {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  WAITING_DOC_GENERATE = 'waiting_doc_generate',
  WAITING = 'waiting',
  ON_REVIEW = 'on_review',
  WAITING_TERMINATED = 'waiting_termination',
  TERMINATED = 'terminated',
  DISABLED = 'disabled',
}
