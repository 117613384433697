import { PARTNER_TYPE } from 'services/partner/partner.constants';
import { TValueOf } from 'types/TValueOf';

import { TDefaultLinkTextKeys } from './PartnerHeader.types';

export const getDefaultLinkTextKeys = (
  type: TValueOf<typeof PARTNER_TYPE> | undefined,
): TDefaultLinkTextKeys => {
  switch (type) {
    case PARTNER_TYPE.CPA:
      return {
        type: 'layout__partners__cpa',
        label: 'layout__partners__partner__tooltip_label_cpa',
        tooltip: 'layout__partners__partner__tooltip_text_cpa',
      };
    case PARTNER_TYPE.REVSHARE:
      return {
        type: 'layout__partners__revshare',
        label: 'layout__partners__partner__tooltip_label_revshare',
        tooltip: 'layout__partners__partner__tooltip_text_revshare',
      };
    default:
      return {
        type: 'generic__copy_link__copy_link',
        tooltip: '',
        label: '',
      };
  }
};
