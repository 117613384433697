import { ChangeEvent, FC, useContext, useRef, useState } from 'react';
import { IconButton, Input } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';

import { SecuritiesAmountContext } from '../../contexts/SecurityAmountContext/SecurityAmountContext';

import {
  StyledFormContainer,
  StyledFormSelect,
  StyledFormWrapper,
} from './SecurityAmountField.styled';
import { TSecurityAmountFieldProps } from './SecurityAmountField.types';

export const SecurityAmountField: FC<TSecurityAmountFieldProps> = ({
  loading,
  options,
  onDelete,
  deleteDisabled,
  field,
  onFieldChange,
  error,
  isShowFieldError,
  disabled,
}) => {
  const { t } = useContext(TranslationContext);
  const { positionsIsLoading } = useContext(SecuritiesAmountContext);

  const refSelect = useRef(null);

  const [isSymbolIdChanged, setIsSymbolIdChanged] = useState(false);
  const [isQuantityChanged, setIsQuantityChanged] = useState(false);

  const onChangeSymbolId = (e: ChangeEvent<HTMLInputElement>) => {
    onFieldChange('symbolId', e.target.value);
    setIsSymbolIdChanged(true);
  };

  const onChangeQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    onFieldChange('quantity', e.target.value);
    setIsQuantityChanged(true);
  };

  const symbolIdError =
    isShowFieldError || isSymbolIdChanged ? error?.symbolIdError : '';
  const quantityError =
    isShowFieldError || isQuantityChanged ? error?.quantityError : '';

  const isShowSkeleton = loading || positionsIsLoading;

  return (
    <StyledFormWrapper className="FormWrapper">
      <StyledFormContainer className="FormContainer" ref={refSelect}>
        <StyledFormSelect
          className="FormSelect"
          options={options}
          label={t('generic__securities')}
          fullWidth
          value={field.symbolId}
          onChange={onChangeSymbolId}
          error={Boolean(symbolIdError)}
          message={symbolIdError}
          showSkeleton={isShowSkeleton}
          disabled={disabled}
        />
        <Input
          type="text"
          label={t('generic__amount')}
          fullWidth
          value={field.quantity}
          disabled={!field.symbolId || disabled}
          onChange={onChangeQuantity}
          error={Boolean(quantityError)}
          message={quantityError}
          showSkeleton={isShowSkeleton}
        />
      </StyledFormContainer>
      <IconButton
        iconName="DeleteIcon"
        onClick={onDelete}
        disabled={deleteDisabled || disabled}
        showSkeleton={isShowSkeleton}
      />
    </StyledFormWrapper>
  );
};
