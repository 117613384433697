import { TFunction } from 'i18next';
import { object, string, boolean, array } from 'yup';

export const TRANSFER_SECURITIES_FORM_VALIDATION_SCHEMA = (
  t: TFunction,
  requiredFields: string[],
) => {
  const validationConfig = requiredFields.reduce((acc, curr) => {
    if (curr === 'authorize_confirm') {
      return {
        ...acc,
        [curr]: boolean().oneOf([true], t('layout__field_is_required')),
      };
    }

    if (curr === 'positions') {
      // we are validating positions fields without react-hook-form
      return {
        ...acc,
      };
    }

    if (curr === 'files') {
      return {
        ...acc,
        [curr]: array()
          .min(1, t('layout__field_is_required'))
          .required(t('layout__field_is_required')),
      };
    }

    return {
      ...acc,
      [curr]: string().required(t('layout__field_is_required')),
    };
  }, {});

  return object(validationConfig);
};

export const acceptsFiles = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/tiff',
  'image/bmp',
  'image/x-ms-bmp',
  'image/gif',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.doc',
  '.docx',
].join(',');

export const positionFields = [
  'isin',
  'price',
  'exchange',
  'currency',
  'symbolId',
  'quantity',
] as const;

export const SECURITY_TRANSFER_MIN_AMOUNT = '0.00000001';
