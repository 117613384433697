import { DEFAULT_CURRENCY } from 'constants/common';

import { TDepositCurrencies, TDepositState } from './DepositContext.types';

export const CURRENCIES_EMPTY: TDepositCurrencies = { list: [], icons: {} };

export const initialState: TDepositState = {
  isLoading: false,
  currencies: CURRENCIES_EMPTY,
  currency: DEFAULT_CURRENCY,
  setCurrency() {},
  banks: [],
  bank: null,
  setBank() {},
  deposit: null,
};

export const DEPOSIT_ACCOUNT_CHOICES_KEY = 'deposit-account-choices';
