import { mapValues, toString as lodashToString } from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  calculateCountOfPages,
  IconButton,
  IFilteringProps,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { PDF, XLS } from 'constants/common';
import { defaultDateRange } from 'constants/dates';
import { BASE_URL } from 'constants/endpoints';
import { REPORT_DOWNLOAD_ICON_NAME } from 'constants/icons';
import { TabsContextData, TranslationContext } from 'contexts';
import { Dates } from 'helpers/dates';
import { getUploadLink } from 'helpers/getUploadLink';
import { prepareToQueryParams } from 'helpers/prepareToQueryParams';
import { useLogHandleTime, usePrevious } from 'hooks/index';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useTableTabCache } from 'hooks/useTableTabCache';
import { UpdatePortfolioContext } from 'pages/Portfolio/contexts';
import {
  TRADES_CACHE_ID,
  TRADES_TABLE_ID,
} from 'pages/Portfolio/tabs/Trades/Trades.constants';
import { fetchTrades } from 'pages/Portfolio/tabs/Trades/Trades.helpers';
import { TTradesFilters } from 'pages/Portfolio/tabs/Trades/Trades.types';
import { MENU_ALIAS } from 'services/menu';
import { TFetchTradesResponse, TTrade } from 'services/trades';
import { selectSelectedDate } from 'store/accounts/accounts.selectors';
import { useAppSelector } from 'store/hooks';
import { TUseTableDataProps } from 'types/TUseTableDataProps';

import { columnKeys, getColumns } from './columns';

export const Trades: FC = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);
  const { needToUpdateAccountData } = useContext(UpdatePortfolioContext);
  const { availableTabs } = useContext(TabsContextData);
  const [selectedAccountId, isAccountsFirstLoading] = useAppSelector(
    (state) => [
      state.accounts.selectedAccountId,
      state.accounts.isAccountsFirstLoading,
    ],
  );
  const selectedDate = useAppSelector(selectSelectedDate);
  const [silentUpdate, setSilentUpdate] = useState<boolean>(false);
  const { setStartHandleTime, logHandleTime } = useLogHandleTime('trades-tab');

  const accountId = selectedAccountId || '';

  const prevSelectedDate = usePrevious(selectedDate);

  const tabAvailable = useMemo<boolean>(
    () => !!availableTabs.find((tab) => tab.id === MENU_ALIAS.TRADES),
    [availableTabs],
  );

  const tableDataArgs = useMemo<
    TUseTableDataProps<TFetchTradesResponse | null>
  >(
    () => ({
      tableId: TRADES_TABLE_ID,
      data: {
        onFetch: (args) => {
          setStartHandleTime();
          return fetchTrades({
            ...args,
            accountId,
            tabAvailable,
          }).finally(() => logHandleTime());
        },
      },
      filters: {
        getDefaultFilters: () => {
          return { gwTime: defaultDateRange };
        },
      },
    }),
    [accountId, tabAvailable],
  );

  const {
    isLoading: tradesLoading,
    data,
    limit,
    skip,
    setLimit,
    setPage,
    page,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters: tableFilters,
    sorting,
    fetchData,
  } = useTableData<TFetchTradesResponse | null>(tableDataArgs);

  const { data: serverTrades, total } = data || {};

  const { cache, useCache } = useTableTabCache<TTrade[], TTradesFilters>({
    cacheId: TRADES_CACHE_ID,
    tableFilters,
    tableDataLoading: tradesLoading,
    tableData: serverTrades,
  });

  const cachedTrades = cache?.tableData;
  const cachedFilters = cache?.tableFilters;

  const filters: TTradesFilters = (useCache && cachedFilters) || tableFilters;

  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter(true);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        t,
        currencyFormatter,
        dateFormatter,
      }),
    [setFilter, removeFilter, t, currentLanguage],
  );

  const filteringProps = useMemo<IFilteringProps<TTrade>>(
    () => ({
      removeAllFilters: resetFilters,
      filters: {
        ...filters,
        // FIXME: nullize empty date values until fixed in ui-kit
        ...(filters.gwTime && {
          gwTime: filters.gwTime.map((v) => v || undefined),
        }),
      },
      manualFilters: true,
    }),
    [resetFilters, filters],
  );

  const trades = useMemo<TTrade[]>(() => {
    return (useCache && cachedTrades) || serverTrades || [];
  }, [cachedTrades, serverTrades, useCache]);

  const uploadParams = useMemo(() => {
    const { gwTime } = filters;

    const {
      dateRange: [dateFrom, dateTo],
      ...preparedParams
    } = prepareToQueryParams({
      accountId: accountId || '',
      dateRange: gwTime,
      sorting,
      columnKeys: [...columnKeys],
      limit,
      skip,
      recordsAmount: 10000,
    });

    const tradesParams = {
      ...preparedParams,
      id: accountId,
      date_from: dateFrom,
      date_to: dateTo,
    };

    return new URLSearchParams(
      mapValues(tradesParams, lodashToString),
    ).toString();
  }, [accountId, filters, sorting, limit, skip]);

  const uploadLinks = useMemo(
    () => ({
      pdf: getUploadLink(PDF, 'trades', BASE_URL, uploadParams),
      xls: getUploadLink(XLS, 'trades', BASE_URL, uploadParams),
    }),
    [uploadParams],
  );

  useEffect(() => {
    if (
      selectedDate &&
      prevSelectedDate &&
      selectedDate.getTime() !== prevSelectedDate.getTime()
    ) {
      setFilter(
        'gwTime',
        Dates.getDateRangeForTable([selectedDate, selectedDate]),
      );
    }
  }, [selectedDate]);

  const updateData = async (silent = false) => {
    if (silent) {
      setSilentUpdate(true);
    }

    await fetchData();

    if (silent) {
      setSilentUpdate(false);
    }
  };

  useEffect(() => {
    if (cachedFilters && cachedFilters.gwTime !== undefined) {
      setFilter('gwTime', cachedFilters.gwTime);
    }
  }, []);

  useEffect(() => {
    if (!filters || !accountId) {
      return;
    }

    if (filters.gwTime !== undefined) {
      setFilter('gwTime', filters.gwTime);
    }
  }, [accountId]);

  useEffect(() => {
    if (needToUpdateAccountData) {
      updateData(true);
    }
  }, [needToUpdateAccountData]);

  const isTableLoading: boolean =
    !silentUpdate &&
    !useCache &&
    (!accountId ||
      tradesLoading ||
      isAccountsFirstLoading ||
      isTranslationsLoading);

  return (
    <Table<TTrade>
      tableId={TRADES_TABLE_ID}
      columns={columns}
      data={trades}
      isLoading={isTableLoading}
      translator={t}
      locale={currentLanguage}
      showScrollbar
      showTableInfo
      hasFilters
      isFlexLayout
      filteringProps={filteringProps}
      manualSortBy
      hasPagination
      defaultSortBy={[{ id: 'gwTime', desc: true }]}
      onSort={setSorting}
      serverPaginationProps={{
        pageSize: limit,
        setPage,
        setPageSize: setLimit,
        pageIndex: page,
        total: total ?? 0,
        pageCount: calculateCountOfPages(total ?? 0, limit),
      }}
      additionalActions={([PDF, XLS] as const).map((format) => ({
        component: (
          <IconButton
            iconName={REPORT_DOWNLOAD_ICON_NAME[format] || 'DownloadIcon'}
            iconSize={24}
            iconColor="secondary"
            className="TradesLink"
            onClick={() => window.open(uploadLinks[format])}
            title={format.toUpperCase()}
          />
        ),
      }))}
    />
  );
};
