import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledInfoIconSkeleton = styled(Skeleton)`
  width: 24px;
  height: 24px;
`;

export const StyledInfoTextSkeleton = styled(Skeleton)`
  width: 100%;
  height: 24px;
`;
