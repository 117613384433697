import { FC, SyntheticEvent, useContext, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Tab, Tabs } from 'react-ui-kit-exante';

import { BrandingContext, TranslationContext } from 'contexts';

import {
  StyledHelpAnchor,
  StyledHelpHeading,
  StyledHelpText,
} from '../../Help.styled';

import { translateQuestions } from './HelpFAQ.helpers';
import { StyledHelpFAQWrapper } from './HelpFAQ.styled';
import { THelpFAQProps } from './HelpFAQ.types';
import { QuestionBlock } from './components/QuestionBlock';

export const HelpFAQ: FC<THelpFAQProps> = ({ tabs: tabsRaw, fallbackUrl }) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = useMemo(
    () => (tabsRaw ? translateQuestions(tabsRaw, t) : []),
    [tabsRaw, currentLanguage],
  );

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const tabQuestions = tabs?.[selectedTab]?.questions;
  const hasQuestions = Boolean(tabQuestions);
  const hasTabs = Boolean(tabs?.length);

  return (
    <StyledHelpFAQWrapper className="HelpFAQWrapper">
      <div>
        <StyledHelpHeading className="HelpHeading">
          {t('layout__help__faq')}
        </StyledHelpHeading>
        <StyledHelpText className="HelpText">
          {t('layout__help__we_are_here_to_help', {
            NAME: branding?.wl?.name,
          })}
        </StyledHelpText>
      </div>
      {hasTabs ? (
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={false}
        >
          {tabs?.map((tab) => (
            <Tab
              key={tab.tab_key}
              label={t(`layout__help__tab__${tab.tab_key}`, tab.tab_key)}
            />
          ))}
        </Tabs>
      ) : (
        <StyledHelpText className="HelpText">
          <Trans
            t={t}
            i18nKey="layout__help__faq__coming_soon"
            components={{
              a: <StyledHelpAnchor className="HelpAnchor" href={fallbackUrl} />,
            }}
          />
        </StyledHelpText>
      )}
      {hasQuestions && (
        <QuestionBlock questions={tabQuestions} fallbackUrl={fallbackUrl} />
      )}
    </StyledHelpFAQWrapper>
  );
};
