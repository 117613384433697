import { useMediaQuery } from '@mui/material';
import { FC, useContext, useMemo } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import { AutocompleteInfiniteListbox } from 'components/AutocompleteInfiniteListbox';
import { getAccountSelectorFilterOptions } from 'components/Header/components/HeaderAccountSelector/HeaderAccountSelector.helpers';
import { StyledHeaderAccountSelector } from 'components/Header/components/HeaderAccountSelector/HeaderAccountSelector.styled';
import { MAX_AUTOCOMPLETE_LENGTH } from 'constants/common';
import { BrandingContext, TranslationContext } from 'contexts';
import { useRouteAssociatedAccounts } from 'hooks';
import { ACCOUNT_SELECTOR_POSITION } from 'services/branding/branding.constants';
import { selectAccountLabel, selectAccounts } from 'store/accounts';
import { accountsActions } from 'store/accounts/accounts.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTheme } from 'theme';
import { TValueOf } from 'types/TValueOf';

export const HeaderAccountSelector: FC = () => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const dispatch = useAppDispatch();

  const position: TValueOf<typeof ACCOUNT_SELECTOR_POSITION> | undefined =
    branding?.branding?.ui_settings?.account_selector_position;

  const accounts = useRouteAssociatedAccounts();
  const { isAccountsLoading } = useAppSelector(selectAccounts);

  const selectedAccountLabel = useAppSelector(selectAccountLabel);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const onSearchSelect = (_: any, account: ISelectOption) => {
    dispatch(
      accountsActions.selectAccount({ accountId: String(account.value) }),
    );
  };

  const listboxProps = useMemo(() => {
    return { limit: MAX_AUTOCOMPLETE_LENGTH };
  }, []);

  const visible: boolean = position === ACCOUNT_SELECTOR_POSITION.HEADER;

  return !visible ? null : (
    <StyledHeaderAccountSelector
      placeholder={t('layout__account_selector__placeholder')}
      options={accounts}
      onChange={onSearchSelect}
      filterOptions={getAccountSelectorFilterOptions(accounts.length)}
      value={selectedAccountLabel || ''}
      disabled={!accounts.length}
      disableClearable
      size={isDesktop ? 'medium' : 'small'}
      className="filter__search-field"
      noOptionsText={t('layout__summary__no_options')}
      showSkeleton={isAccountsLoading}
      // todo use autocompleteAsync component, when scroll top bug will be fixed https://jira.exante.eu/browse/RUI-414
      /* eslint-disable-next-line react/no-unstable-nested-components */
      ListboxComponent={AutocompleteInfiniteListbox}
      // @ts-expect-error -- this prop actually accept arbitrary data but cannot be reasonably extended
      ListboxProps={listboxProps}
    />
  );
};
