import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { StyledCardActionWrapper } from 'pages/CardSettings/components/CardSettingsCard/components/CardAction/CardAction.styled';

export const CardActionSkeleton: FC = () => (
  <StyledCardActionWrapper>
    <Skeleton width={16} height={16} />
    <Skeleton width={48} height={16} />
  </StyledCardActionWrapper>
);
