import './index.css';
import 'core-js/actual';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { GlobalStyles, NotificationProvider } from 'react-ui-kit-exante';

import { ErrorFallback } from 'components/ErrorFallback';
import 'helpers/translate';
import { TranslationProvider } from 'contexts';
import { LocalRouterProvider } from 'contexts/LocalRouterProvider';
import { store } from 'store/store';

const Application = () => {
  return (
    <TranslationProvider>
      <GlobalStyles />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <LocalRouterProvider />
        <div id="NotificationProvider">
          <NotificationProvider />
        </div>
      </ErrorBoundary>
    </TranslationProvider>
  );
};

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  if (rootNode) {
    createRoot(rootNode).render(
      <Provider store={store}>
        <Application />
      </Provider>,
    );
  }
};

initApp();
