import { css } from 'react-ui-kit-exante';

import { TStyledCardInfoBlockWrapperProps } from 'pages/CardSettings/components/CardSettingsCard/components/CardInfoBlock/CardInfoBlock.types';
import { styled } from 'theme';

export const StyledCardInfoBlockWrapper = styled(
  'div',
)<TStyledCardInfoBlockWrapperProps>`
  display: flex;
  gap: 16px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}

  ${({ loading }) =>
    loading &&
    css`
      flex-direction: column;
    `}

  min-width: 160px;
`;

export const StyledCardInfoBlockIconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  color: ${({ theme }) => theme.color.icon.ghost};
`;

export const StyledCardInfoBlockTextWrapper = styled('p')`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0;
`;

export const StyledCardInfoBlockHeader = styled('h4')`
  margin: 0;

  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.typo.primary} !important;
`;

export const StyledCardInfoBlockText = styled('p')`
  margin: 0;

  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.promo};
`;
