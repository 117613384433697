import { flatten, uniq } from 'lodash';
import { IColumn } from 'react-ui-kit-exante';

import { TSelectedColumns } from './useSelectedColumns.types';

export function setColumnKeysToLocalStorage(
  columnIds: string[],
  columnKeys: TSelectedColumns,
) {
  columnIds.forEach((id) => {
    const preparedColumnKeys = Object.keys(columnKeys).filter(
      (item) => columnKeys[item],
    );

    return localStorage.setItem(id, JSON.stringify(preparedColumnKeys));
  });
}

export function prepareDefaultColumnKeys<T extends Record<string, unknown>>(
  columns: IColumn<T>[],
  defaultColumnKeys: string[],
): TSelectedColumns {
  return columns.reduce<TSelectedColumns>(
    (acc, { accessor }) => ({
      ...acc,
      [accessor as string]: defaultColumnKeys.includes(<string>accessor),
    }),
    {},
  );
}

export function prepareColumnKeys<T extends Record<string, unknown>>(
  columns: IColumn<T>[],
  show: boolean,
) {
  return columns.reduce(
    (acc, { accessor, required }) => ({
      ...acc,
      [accessor as string]: required ? true : show,
    }),
    {},
  );
}

export function prepareInitialSelectedColumns<
  T extends Record<string, unknown>,
>(
  columns: IColumn<T>[],
  tableIds: string[],
  defaultDisplayedColumns: string[],
): TSelectedColumns {
  const tablesColumnKeys = tableIds.reduce<string[][]>((acc, id) => {
    const key = localStorage.getItem(id);
    try {
      return key ? [...acc, JSON.parse(key)] : acc;
    } catch (e: any) {
      return acc;
    }
  }, []);
  const lsColumnKeys = uniq(flatten(tablesColumnKeys));

  const parsedKeys = !lsColumnKeys?.length
    ? defaultDisplayedColumns
    : lsColumnKeys;

  return prepareDefaultColumnKeys(columns, parsedKeys);
}
