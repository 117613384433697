import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  IColumn,
  IconButton,
  IOnFetchArguments,
  useTableData,
} from 'react-ui-kit-exante';
import { ActionButtonProps } from 'react-ui-kit-exante/build/Components/Table/ActionButton/ActionButton.types';

import { SORTING_ORDER } from 'constants/SORTING_ORDER';
import { DEFAULT_MAX_PAGINATION_LENGTH } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';
import { corporateActionsService } from 'services/corporateActions/corporateActions';
import {
  TCorporateAction,
  TCorporateActionsGetListResponse,
} from 'services/corporateActions/corporateActions.types';
import { TKitTableSorting } from 'types/TKitTableSorting';
import { TUseTableDataProps } from 'types/TUseTableDataProps';

import { downloadCorporateActionsReport } from '../../CorporateActions.helpers';

import { getCorporateActionsListTableColumns } from './CorporateActionsList.helpers';
import {
  StyledCorporateActionsListPanel,
  StyledCorporateActionsListTab,
  StyledCorporateActionsListTabIcon,
  StyledCorporateActionsListTable,
  StyledCorporateActionsListTabs,
} from './CorporateActionsList.styled';

export const CorporateActionsList: FC = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const tableDataArgs = useMemo<
    TUseTableDataProps<TCorporateActionsGetListResponse | null>
  >(
    () => ({
      data: {
        onFetch: ({
          params: { skip, limit, ...restParams },
        }: IOnFetchArguments) => {
          const { sorting, page, ...tableParams } = restParams;
          const { id, desc } = (sorting as TKitTableSorting[])[0] || {};

          return corporateActionsService.getList({
            offset: Math.floor(skip / limit),
            limit,
            order: desc ? SORTING_ORDER.DESC : SORTING_ORDER.ASC,
            orderBy: id,
            tableParams,
          });
        },
      },

      sorting: {
        getDefaultSorting: () => {
          return [{ id: 'id', desc: true }];
        },
      },
      pagination: {
        getDefaultPagination: () => ({
          limit: DEFAULT_MAX_PAGINATION_LENGTH,
          skip: 0,
        }),
      },
    }),
    [],
  );

  const {
    limit,
    page,
    setPage,
    setLimit,
    fetchData: fetchTableData,
    data: tableData,
    isLoading,
    sorting,
    setSorting,
    filters,
    setFilter,
    removeFilter,
    resetFilters,
  } = useTableData<TCorporateActionsGetListResponse | null>(tableDataArgs);

  const columns = useMemo((): IColumn<TCorporateAction>[] => {
    return getCorporateActionsListTableColumns({
      t,
      onRemove: removeFilter,
      onFilter: setFilter,
    });
  }, [t]);

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      manualFilters: true,
    }),
    [filters, resetFilters],
  );

  const downloadReport = async () => {
    setIsDownloadingReport(true);
    await downloadCorporateActionsReport({
      url: '/clientsarea/rest/cas/all_dividend_events/xls/',
      fileName: 'all_dividend_events.xls',
    });
    setIsDownloadingReport(false);
  };

  const tableActions = useMemo(
    (): ActionButtonProps[] => [
      {
        component: (
          <IconButton
            iconName="XLSIcon"
            iconColor="secondary"
            iconSize={24}
            className="CorporateActionsListDownload"
            onClick={downloadReport}
            disabled={isDownloadingReport}
            title={
              isDownloadingReport ? t('generic__loading_state') : undefined
            }
            data-test-id="corporate_actions__list_download"
          />
        ),
      },
    ],
    [isDownloadingReport, t],
  );

  const total = tableData?.pagination.total || 0;
  const data = useMemo(() => tableData?.content || [], [tableData?.content]);
  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount: Math.ceil(total / limit),
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [total, setPage, limit, page, setLimit],
  );

  useEffect(() => {
    fetchTableData();
  }, []);

  return (
    <StyledCorporateActionsListPanel
      title={t('corporate_actions__instrument__title')}
    >
      <StyledCorporateActionsListTabs value="dividends">
        <StyledCorporateActionsListTab
          className="CorporateActionsListTab"
          icon={
            <StyledCorporateActionsListTabIcon
              iconName="DividentsIcon"
              color="transparent"
            />
          }
          label={t('generic__dividends')}
          key="dividends"
          value="dividends"
        />
      </StyledCorporateActionsListTabs>
      <StyledCorporateActionsListTable<TCorporateAction>
        hasPagination
        isFlexLayout
        translator={t}
        className="CorporateActionsListTable"
        showTableInfo
        columns={columns}
        data={data}
        manualSortBy
        onSort={setSorting}
        defaultSortBy={sorting}
        additionalActions={tableActions}
        hasFilters
        filteringProps={filteringProps}
        tableId="corporateActionsListTable"
        locale={currentLanguage}
        isLoading={isLoading || isTranslationsLoading}
        pageSize={limit}
        serverPaginationProps={serverPaginationProps}
      />
    </StyledCorporateActionsListPanel>
  );
};
