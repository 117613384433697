import { TDocument } from 'services/legal-docs';

import { PartnerDocTypes, PartnerDocUrls } from './PartnerProceed.constants';

export const checkDocType = (doc: TDocument) => {
  let documentType;

  if (
    doc.url === PartnerDocUrls.CFSC_TOB_IND ||
    doc.url === PartnerDocUrls.CFSC_TOB_CORP
  ) {
    documentType = PartnerDocTypes.PARTNER_DOC_TOB;
  }

  if (
    doc.url === PartnerDocUrls.CPA_IND ||
    doc.url === PartnerDocUrls.CPA_CORP ||
    doc.url === PartnerDocUrls.REVSHARE_IND ||
    doc.url === PartnerDocUrls.REVSHARE_CORP
  ) {
    documentType = PartnerDocTypes.PARTNER_DOC_PARTNER;
  }

  return documentType;
};
