import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Input, ISelectOption, Skeleton } from 'react-ui-kit-exante';

import { CircleClockIcon, PercentIcon, PlanetIcon } from 'assets/icons';
import { AccountCard } from 'components/AccountCard';
import { AccountsSearchField } from 'components/AccountsSearchField';
import { AutocompleteInfiniteListbox } from 'components/AutocompleteInfiniteListbox';
import { Text } from 'components/Text';
import {
  DEFAULT_MAX_ACCOUNTS_LENGTH,
  MAX_AUTOCOMPLETE_LENGTH,
  OPTIMIZABLE_ACCOUNTS_LENGTH,
} from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';
import { repeat } from 'helpers/array';
import { formatCurrency } from 'helpers/formatters';
import { delay } from 'helpers/timers';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { TAccountSummaryMetrics } from 'types/accounts';

import {
  TRANSFERS_REFRESH_EXTENDED_TIMEOUT,
  TRANSFERS_REFRESH_TIMEOUT,
} from '../../../../Transfers.constants';
import { TransfersContext } from '../../../../context';
import { useTransfers } from '../../../../hooks';
import { FundsSkeleton } from '../../components';
import { FundsContext } from '../../context';
import { updateToMyAccountState } from '../../context/hooks';

import { TO_MY_ACCOUNT_DEBOUNCE_MS } from './ToMyAccount.constants';
import { searchInOptions } from './ToMyAccount.helpers';
import {
  StyledAccountSelection,
  StyledAutocompleteWrapper,
  StyledInternalCards,
  StyledInternalCardsContainer,
  StyledInternalCardsWrapper,
  StyledInternalNoteContainer,
  StyledToMyAccountContainer,
} from './ToMyAccount.styled';
import { ToMyAccountInternalNote } from './components/ToMyAccountInternalNote';

export const ToMyAccount = () => {
  const currencyRef = useRef<string | null>(null);
  const { t, currentLanguage } = useContext(TranslationContext);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const {
    transfersState,
    repeatedWithdrawal,
    transfersDispatch,
    cashTransferTab,
  } = useTransfers();

  const {
    accountDetailsState: { currencies, targetAccounts },
  } = useContext(TransfersContext);

  const {
    selectedAccountId,
    toMyAccountState: {
      accounts,
      fetchingState,
      currency,
      targetAccountId,
      requestingTransactionState,
    },
    toMyAccountDispatch,
  } = useContext(FundsContext);

  const formatter = useCurrencyFormatter(currency);
  const isLoading = fetchingState.pending;
  const isSuperuser = transfersState.user?.is_superuser;

  const [options, setOptions] = useState<ISelectOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    setOptions(
      accounts.map((item) => ({
        label: `${item.accountId} / ${formatCurrency(
          formatter,
          item.netAssetValue,
        )}`,
        value: item.accountId,
      })),
    );
  }, [accounts]);

  useEffect(() => {
    const iSelectOptions = searchInOptions(options, searchQuery) || [];
    setFilteredOptions(iSelectOptions);
  }, [options, searchQuery]);

  const summaryAccounts = useMemo<TAccountSummaryMetrics[]>(
    () =>
      accounts.filter((item) =>
        item.accountId.includes(searchQuery.toUpperCase()),
      ),
    [accounts, searchQuery],
  );

  const listboxProps = useMemo(() => {
    return { limit: MAX_AUTOCOMPLETE_LENGTH };
  }, []);

  const handleSelectAccount = (payload: string) => {
    toMyAccountDispatch({ type: 'SET_TARGET_ACCOUNT_ID', payload });
  };

  const debouncedSearch = useMemo(() => {
    return debounce(setSearchQuery, TO_MY_ACCOUNT_DEBOUNCE_MS, {
      leading: false,
    });
  }, []);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);
    setSearchValue(value);
  }, []);

  const onAccountSearchFieldChange = useCallback(
    (_: SyntheticEvent, option: ISelectOption) => {
      handleSelectAccount(String(option.value));
    },
    [],
  );

  const isAutocompleteView = Boolean(
    accounts.length > DEFAULT_MAX_ACCOUNTS_LENGTH || isSuperuser,
  );

  const filterOptions = useCallback(
    (
      optionsToFilter: ISelectOption[],
      state: Parameters<
        // @ts-expect-error -- it will not be undefined
        AutocompleteProps<ISelectOption, false, false, false>['filterOptions']
      >[1],
    ) => {
      debouncedSearch(state.inputValue);
      return optionsToFilter;
    },
    [],
  );

  const updateState = async () => {
    await delay(
      accounts.length > OPTIMIZABLE_ACCOUNTS_LENGTH
        ? TRANSFERS_REFRESH_EXTENDED_TIMEOUT
        : TRANSFERS_REFRESH_TIMEOUT,
    );

    if (currencyRef.current) {
      toMyAccountDispatch({ type: 'UPDATE_STATE_START' });
      try {
        const payload = await updateToMyAccountState(
          selectedAccountId,
          currencyRef.current,
          targetAccounts,
          currentLanguage,
          transfersState.user?.is_superuser,
        );

        toMyAccountDispatch({ type: 'UPDATE_STATE_SUCCESS', payload });
      } catch (error) {
        const { message } = error as AxiosError;
        toMyAccountDispatch({ type: 'UPDATE_STATE_ERROR', payload: message });
      } finally {
        updateState();
      }
    }
  };

  useEffect(() => {
    if (currency) {
      currencyRef.current = currency;
    }

    return () => {
      currencyRef.current = null;
    };
  }, [currency]);

  useEffect(() => {
    updateState();
  }, []);

  useEffect(() => {
    if (
      repeatedWithdrawal?.extraData?.tags?.transfer?.to?.account &&
      repeatedWithdrawal?.asset &&
      cashTransferTab === 'MY_ACCOUNT'
    ) {
      transfersDispatch({ type: 'CLEAR_REPEATED_WITHDRAWAL' });

      toMyAccountDispatch({
        type: 'REPEAT_TRANSFER',
        payload: {
          currency: repeatedWithdrawal.asset,
          isAllFunds: repeatedWithdrawal.all_funds,
          targetAccountId:
            repeatedWithdrawal?.extraData?.tags?.transfer?.to?.account,
        },
      });
    }
  }, [repeatedWithdrawal]);

  useEffect(() => {
    if (currencies[0]) {
      toMyAccountDispatch({ type: 'SET_CURRENCY', payload: currencies[0] });
    }
  }, [currencies[0]]);

  if (isLoading) {
    return <FundsSkeleton />;
  }

  return (
    <StyledToMyAccountContainer className="InternalContainer">
      <StyledAccountSelection className="AccountSelection">
        {isAutocompleteView ? (
          <StyledAutocompleteWrapper className="AutocompleteWrapper">
            <AccountsSearchField
              withDescriptions
              partialDescriptionSearch
              fullWidth
              disableClearable
              showSkeleton={isLoading}
              options={filteredOptions}
              value={targetAccountId}
              filterOptions={filterOptions}
              onChange={onAccountSearchFieldChange}
              placeholder={t(
                'layout__subaccount_transfer__account__to_account',
              )}
              ListboxComponent={AutocompleteInfiniteListbox}
              // @ts-expect-error -- this prop actually accept arbitrary data but cannot be reasonably extended
              ListboxProps={listboxProps}
            />
          </StyledAutocompleteWrapper>
        ) : (
          <StyledInternalCardsContainer className="InternalCardsContainer">
            {(isLoading || accounts.length > 5) && (
              <Input
                fullWidth
                showSkeleton={isLoading}
                value={searchValue}
                onChange={handleSearch}
                placeholder="Search"
              />
            )}
            <StyledInternalCardsWrapper className="InternalCardsWrapper">
              <StyledInternalCards className="InternalCards">
                {!isLoading &&
                  summaryAccounts.map((account) => (
                    <AccountCard
                      disabled={requestingTransactionState.pending}
                      key={account.accountId}
                      account={account}
                      currency={currency}
                      selectedAccountId={targetAccountId}
                      onClick={() => handleSelectAccount(account.accountId)}
                    />
                  ))}
                {isLoading &&
                  repeat(
                    accounts.length || 6,
                    <Skeleton key={Math.random()} width={166} height={104} />,
                  )}
              </StyledInternalCards>
            </StyledInternalCardsWrapper>
          </StyledInternalCardsContainer>
        )}
      </StyledAccountSelection>

      <StyledInternalNoteContainer className="InternalNoteContainer">
        <ToMyAccountInternalNote className="ToMyAccountInternalNote">
          <PlanetIcon />
          <Text color="secondary" size="15px">
            {t('transfer__cash_internal_can-make')}
          </Text>
        </ToMyAccountInternalNote>
        <ToMyAccountInternalNote className="ToMyAccountInternalNote">
          <CircleClockIcon />
          <Text color="secondary" size="15px">
            {t('transfer__cash_internal_made-immediately')}
          </Text>
        </ToMyAccountInternalNote>
        <ToMyAccountInternalNote className="ToMyAccountInternalNote">
          <PercentIcon />
          <Text color="secondary" size="15px">
            {t('transfer__cash_internal_no-fees')}
          </Text>
        </ToMyAccountInternalNote>
      </StyledInternalNoteContainer>
    </StyledToMyAccountContainer>
  );
};
