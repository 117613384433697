import { styled } from 'theme';

export const StyledProductWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 375px;

  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    flex-direction: row;
    gap: 20px;

    width: 100%;
    max-width: 922px;

    padding: 0 24px;
  }
`;

export const StyledProductInfo = styled('div')`
  width: 100%;

  padding: 24px 0;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    max-width: 50%;

    padding: 40px 0;
  }
`;

export const StyledProductHeading = styled('h2')`
  margin: 8px 0;

  color: ${({ theme }) => theme.color.typo.primary};
  font-family: ${({ theme }) => theme.font.header};
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const StyledProductDescription = styled('p')`
  margin: 0;

  color: ${({ theme }) => theme.color.typo.promo};
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
`;
