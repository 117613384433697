import { createSlice } from '@reduxjs/toolkit';

import { EStatus } from 'types/api';

import {
  fetchSettingsNotifications,
  saveSettingsNotifications,
} from './settingsNotifications.actions';
import { TSettingsNotificationsState } from './settingsNotifications.types';

export const settingsNotificationsInitialState: TSettingsNotificationsState = {
  status: EStatus.Idle,
  saving: EStatus.Idle,
  data: {
    groups: [],
    channels: [],
    primary_email: '',
  },
};

export const settingsNotificationsSlice = createSlice({
  name: 'settingsNotifications',
  initialState: settingsNotificationsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSettingsNotifications.pending, (state) => {
      state.status = EStatus.Loading;
    });

    builder.addCase(fetchSettingsNotifications.fulfilled, (state, action) => {
      if (action.payload) {
        state.data = action.payload;
        state.status = EStatus.Succeeded;
      } else {
        state.status = EStatus.Failed;
      }
    });

    builder.addCase(saveSettingsNotifications.pending, (state) => {
      state.saving = EStatus.Loading;
    });

    builder.addCase(saveSettingsNotifications.fulfilled, (state, action) => {
      if (action.payload) {
        state.data = { ...state.data, ...action.payload };

        state.saving = EStatus.Succeeded;
      } else {
        state.saving = EStatus.Failed;
      }
    });
  },
});
