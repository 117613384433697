import { FC, useContext } from 'react';

import { MastercardPlainIcon, PercentIcon, TransferIcon } from 'assets/icons';
import { TranslationContext } from 'contexts';
import {
  StyledCardOrderInfoHeader,
  StyledCardOrderInfoIconWrapper,
  StyledOrderInfoCard,
  StyledOrderInfoCardsWrapper,
  StyledOrderInfoParagraph,
  StyledOrderInfoWrapper,
} from 'pages/CardSettings/components/CardSettingsOrder/components/CardSettingsOrderInfo/CardSettingsOrderInfo.styled';

export const CardSettingsOrderInfo: FC = () => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledOrderInfoWrapper className="OrderInfoWrapper">
      <StyledCardOrderInfoHeader className="CardOrderInfoHeader">
        {t('layout__cards__get_your_card')}
      </StyledCardOrderInfoHeader>
      <StyledOrderInfoParagraph className="OrderInfoParagraph">
        {t('layout__cards__order_now_v2')}
      </StyledOrderInfoParagraph>
      <StyledOrderInfoCardsWrapper className="OrderInfoCardsWrapper">
        <StyledOrderInfoCard className="OrderInfoCard">
          <StyledCardOrderInfoIconWrapper className="CardOrderInfoIconWrapper">
            <TransferIcon />
          </StyledCardOrderInfoIconWrapper>
          <StyledOrderInfoParagraph className="OrderInfoParagraph">
            {t('layout__cards__no_excessive_money')}
          </StyledOrderInfoParagraph>
        </StyledOrderInfoCard>
        <StyledOrderInfoCard className="OrderInfoCard">
          <StyledCardOrderInfoIconWrapper className="CardOrderInfoIconWrapper">
            <MastercardPlainIcon />
          </StyledCardOrderInfoIconWrapper>
          <StyledOrderInfoParagraph className="OrderInfoParagraph">
            {t('layout__cards__pay_wherever_accepted')}
          </StyledOrderInfoParagraph>
        </StyledOrderInfoCard>
        <StyledOrderInfoCard className="OrderInfoCard">
          <StyledCardOrderInfoIconWrapper className="CardOrderInfoIconWrapper">
            <PercentIcon />
          </StyledCardOrderInfoIconWrapper>
          <StyledOrderInfoParagraph className="OrderInfoParagraph">
            {t('layout__cards__no_fees')}
          </StyledOrderInfoParagraph>
        </StyledOrderInfoCard>
      </StyledOrderInfoCardsWrapper>
    </StyledOrderInfoWrapper>
  );
};
