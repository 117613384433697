import { styled } from 'theme';

export const StyledAccountSearchContainer = styled('div')`
  padding: 24px 24px 16px;
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-bottom: 24px;
  }
`;

export const StyledSearchInputContainer = styled('div')`
  max-width: 240px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`;
