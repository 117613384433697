import React, { FC, useContext } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';

import {
  StyledNoteContainer,
  StyledTooltipContainer,
} from '../../../Funds/tabs/ToInternalAccount/styled';

import {
  StyledCryptoImportantNoteTooltip,
  StyledCryptoImportantNoteTooltipText,
} from './CryptoImportantNote.styled';

export const CryptoImportantNote: FC = () => {
  const { t } = useContext(TranslationContext);

  const notice = t('layout__withdrawals__crypto__important_notice').replaceAll(
    '\\n',
    '\n',
  );

  return (
    <StyledTooltipContainer className="TooltipContainer">
      <StyledCryptoImportantNoteTooltip
        className="StyledCryptoImportantNoteTooltip"
        placement="bottom"
        title={notice}
      >
        <StyledNoteContainer className="NoteContainer" noHide>
          <StyledCryptoImportantNoteTooltipText
            weight="500"
            size="13px"
            color="secondary"
            withInfoIcon
          >
            {t('label_key_important_note')}
          </StyledCryptoImportantNoteTooltipText>
        </StyledNoteContainer>
      </StyledCryptoImportantNoteTooltip>
    </StyledTooltipContainer>
  );
};
