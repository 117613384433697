import { FC, SyntheticEvent, useContext, useMemo } from 'react';
import { ColumnSelect, IconButton, Table } from 'react-ui-kit-exante';

import { Collapse } from 'components/Collapse';
import { DEFAULT_MAX_PAGINATION_LENGTH } from 'constants/common';
import { CurrencyContext, TranslationContext } from 'contexts';
import { useSelectedColumns } from 'hooks/index';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { downloadAnalysisReport } from 'pages/Portfolio/tabs/Analysis/Analysis.helpers';
import { FilterContext } from 'pages/Portfolio/tabs/Analysis/contexts/FilterContext';
import { TAccountsMetrics, TMetricCurrency } from 'services/analysis';

import { defaultColumnKeys, getColumns } from './columns';

type TCurrenciesTableProps = {
  data?: TAccountsMetrics['currencies'];
  isDataLoading?: boolean;
};

const tableId = 'clientsarea-analysis-currencies';

export const CurrenciesTable: FC<TCurrenciesTableProps> = ({
  data = [],
  isDataLoading,
}) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { currency } = useContext(CurrencyContext);
  const currencyFormatter = useCurrencyFormatter();
  const { filters } = useContext(FilterContext);

  const columns = useMemo(
    () =>
      getColumns({
        currency,
        t,
        currencyFormatter,
      }),
    [currency, t],
  );

  const { selectedColumn, handleColumnChange, resetColumns } =
    useSelectedColumns<TMetricCurrency>({
      columns,
      tableIds: ['currencies'],
      defaultTableId: 'currencies',
      defaultDisplayedColumns: defaultColumnKeys,
    });

  const displayedColumnKeys = useMemo(
    () =>
      Object.keys(selectedColumn).filter((key) => Boolean(selectedColumn[key])),
    [selectedColumn],
  );

  const downloadReport = (e: SyntheticEvent) => {
    e.stopPropagation();
    downloadAnalysisReport('currency', filters, currentLanguage);
  };

  if (!isDataLoading && !data.length) {
    return null;
  }

  return (
    <Collapse
      title={t('metrics_per_currencies')}
      infoTooltip={
        <span>
          {t('layout__performance__use_metrics_to_evaluate_balance')}
          <br />
          <br />
          {t('converted_volumes_show_adjusted_volume')}
        </span>
      }
      actions={
        <>
          <ColumnSelect
            columns={columns}
            selected={displayedColumnKeys}
            onChange={handleColumnChange}
            onReset={resetColumns}
            translator={t}
          />
          <IconButton
            iconName="XLSIcon"
            iconColor="secondary"
            iconSize={24}
            onClick={downloadReport}
            className="DailyPLDownload"
            disabled={isDataLoading}
          />
        </>
      }
      size="big"
      defaultExpanded
      withoutBorder
    >
      <Table<TMetricCurrency>
        tableId={tableId}
        columns={columns}
        displayedColumnKeys={displayedColumnKeys}
        data={data}
        isLoading={isDataLoading}
        isHiddenColumnSelect
        translator={t}
        locale={currentLanguage}
        showScrollbar
        showTableInfo
        isFlexLayout
        hasPagination
        pageSize={DEFAULT_MAX_PAGINATION_LENGTH}
        skeletonsCount={4}
        autoResetSortBy={false}
      />
    </Collapse>
  );
};
