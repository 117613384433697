import { ButtonBase, css, Link } from '@mui/material';
import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { PowerIcon, WebTradingIcon } from '../../assets/icons';

import { TStyledHeaderButtonProps } from './Header.types';

export const StyledHeaderWrapper = styled('div')`
  background-color: ${({ theme }) => theme.color.bg.primary};

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: ${({ theme }) => theme.sizes.mobileHeaderHeight};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 6px;
    padding-top: 4px;

    background: none;
  }
`;

export const StyledHeaderContent = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  color: ${({ theme }) => theme.color.typo.ghost};

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: 16px;
    padding: 12px 46px;
  }
`;

export const StyledHeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 17px;

  line-height: 1.2;

  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  text-decoration: none !important;

  ${({ theme }) => theme.breakpoints.up('md')} {
    color: ${({ theme }) => theme.color.typo.secondary} !important;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    gap: 5px;
  }

  &:hover .xnt-icon {
    transform: scale(1.2);
    transition: 0.3s ease;
  }
`;

export const StyledHeaderLinkLogout = styled(StyledHeaderLink)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    color: ${({ theme }) => theme.color.typo.secondary};
  }
`;

export const StyledHeaderButton = styled(ButtonBase, {
  shouldForwardProp: blockNonNativeProps('opened'),
})<TStyledHeaderButtonProps>`
  padding: 8px !important;
  transition: 0.3s;

  color: ${({ theme }) => theme.color.typo.action} !important;

  &:hover .xnt-icon {
    transform: scale(1.2);
    transition: 0.3s ease;
  }

  ${({ opened }) =>
    opened &&
    css`
      &:hover {
        transform: rotate(90deg);
      }
    `}
`;

export const iconStyle = css`
  width: 24px !important;
  height: 24px !important;
`;

export const StyledHeaderPowerIcon = styled(PowerIcon)`
  svg {
    color: ${({ theme }) => theme.color.typo.secondary};
  }

  width: 24px !important;
  height: 24px !important;
`;
export const StyledHeaderWebTradeIcon = styled(WebTradingIcon)`
  color: ${({ theme }) => theme.color.typo.secondary};
  width: 24px !important;
  height: 24px !important;
`;

export const StyledHeaderSkeletonContainer = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledHeaderSkeletonContainerMobile = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
