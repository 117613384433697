import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/material';
import { useReducer, useRef } from 'react';

import { validateEmail } from 'helpers/url';

import { TSettingsItemProps } from '../SettingsItem.types';

import { SettingsItemActions } from './useSettingsItem.actions';
import { useSettingsItemReducer } from './useSettingsItem.reducer';
import { TSettingsItemReducer } from './useSettingsItem.types';

export const useSettingsItem = ({
  allowed,
  disabled,
  primaryEmail,
  internalCA,
  name,
  sms,
  terminal,
  emails,
  onChange,
}: TSettingsItemProps) => {
  const [state, dispatch] = useReducer<TSettingsItemReducer>(
    useSettingsItemReducer,
    {
      sms,
      internalCA,
      terminal,
      emails,
      error: null,
    },
  );

  const { current: allowedSet } = useRef(new Set(allowed));

  const handleTerminalChange = () => {
    dispatch({
      type: SettingsItemActions.OnChangeTerminal,
      payload: !state.terminal,
    });
    onChange({ name, channel: 'terminal', value: !terminal });
  };

  const handleSmsChange = () => {
    dispatch({ type: SettingsItemActions.OnChangeSms, payload: !state.sms });
    onChange({ name, channel: 'sms', value: !sms });
  };

  const handleInternalCAChange = () => {
    dispatch({
      type: SettingsItemActions.OnChangeInternalCA,
      payload: !state.internalCA,
    });
    onChange({ name, channel: 'internalCA', value: !internalCA });
  };

  const handleEmailsChange = (values: string[]) => {
    dispatch({ type: SettingsItemActions.OnChangeEmails, payload: values });
    onChange({ name, channel: 'email', value: values });
  };

  const handleClear = (values: string[]) => {
    if (
      values.length === 1 &&
      disabled &&
      primaryEmail &&
      values.includes(primaryEmail)
    ) {
      dispatch({ type: SettingsItemActions.OnSetError, payload: 'require' });
    } else {
      const payload =
        disabled && primaryEmail
          ? values.filter((option) => option === primaryEmail)
          : [];

      handleEmailsChange(payload);
    }
  };

  const handleCreateOption = (values: string[], option: string) => {
    if (validateEmail(option)) {
      handleEmailsChange(values);
    } else {
      dispatch({ type: SettingsItemActions.OnSetError, payload: 'validation' });
    }
  };

  const handleRemoveOption = (values: string[], option: string) => {
    if (disabled && option === primaryEmail) {
      dispatch({ type: SettingsItemActions.OnSetError, payload: 'require' });
    } else {
      handleEmailsChange(values);
    }
  };

  const handleEmailsSubmit = (
    _event: any,
    values: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown>,
  ) => {
    switch (reason) {
      case 'clear': {
        handleClear(state.emails);
        break;
      }

      case 'createOption': {
        handleCreateOption(values, details?.option as string);
        break;
      }

      case 'removeOption': {
        handleRemoveOption(values, details?.option as string);
        break;
      }

      case 'selectOption': {
        handleEmailsChange(values);
        break;
      }

      default: {
        dispatch({ type: SettingsItemActions.OnResetError });
      }
    }
  };

  const handleResetWarnings = () => {
    if (state.error) {
      dispatch({ type: SettingsItemActions.OnResetError });
    }
  };

  return {
    ...state,
    allowedSet,
    handleResetWarnings,
    handleEmailsSubmit,
    handleInternalCAChange,
    handleTerminalChange,
    handleSmsChange,
  };
};
