import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPortfolioTotalWrapper = styled('div')`
  min-height: 100%;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
  }
`;

export const StyledPortfolioTotalPanel = styled(Panel)`
  padding: 16px 0 0;

  .PanelHeading {
    font-size: 0;
    line-height: 1;
  }

  .PanelTitle {
    margin-bottom: 16px;
    padding-right: 68px;
  }
`;
