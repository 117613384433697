import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { CANCELLED_ERROR } from 'constants/api';
import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { notifyWith } from 'helpers/notifyWith';
import { useLogHandleTime } from 'hooks/useLogHandleTime';
import { transferService } from 'services/transfer';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { commissionsInitialState } from './constants';
import { commissionsReducer } from './reducer';

// commissionsState - commissions from new api
export const useCommissionsState = () => {
  const { currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const [commissionsState, commissionsDispatch] = useReducer(
    commissionsReducer,
    commissionsInitialState,
  );
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'withdrawals-service-fees',
  );

  const abortController = useRef(new AbortController());
  const fetchTransfersState = async (account: string) => {
    abortController.current.abort();
    abortController.current = new AbortController();

    commissionsDispatch({ type: 'FETCH_STATE_START' });

    try {
      setStartHandleTime();
      const response = await transferService.getCommissions(
        account,
        {
          signal: abortController.current.signal,
        },
        currentLanguage,
      );

      if (response === CANCELLED_ERROR) {
        return;
      }

      logHandleTime();
      if (!response) {
        commissionsDispatch({ type: 'FETCH_STATE_ERROR', payload: '' });
        notifyWith.genericError();
        return;
      }

      commissionsDispatch({
        type: 'FETCH_STATE_SUCCESS',
        payload: response,
      });
    } catch (error) {
      const { message, response } = error as AxiosError;
      if (response?.status !== 400) {
        commissionsDispatch({ type: 'FETCH_STATE_ERROR', payload: message });
      }
    }
  };

  const fetchServiceFees = useDebouncedCallback(
    useCallback(
      (account: string) => {
        const isAccountAggregated = !!account?.includes('.');

        if (
          account &&
          branding?.branding?.service_fees_for_wr_enabled &&
          isAccountAggregated
        ) {
          fetchTransfersState(account);
        }
      },
      [branding?.branding?.service_fees_for_wr_enabled],
    ),
    500,
  );

  useEffect(() => {
    if (selectedAccountId) {
      fetchServiceFees(selectedAccountId);
    }
  }, [selectedAccountId]);

  return {
    commissionsState,
    commissionsDispatch,
    fetchServiceFees,
  };
};
