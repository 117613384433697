import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';
import { UserDetailsContext } from 'contexts/UserDetailsContext';
import { partnerService, TPartnerPaymentInfo } from 'services/partner';

import {
  PARTNER_PAYMENTS_FORM_INFO_DEFAULT,
  PARTNER_PAYMENTS_INFO_FORM_VALIDATION_SCHEMA,
} from './PartnerPayments.constants';
import { StyledPartnerPaymentsWrapper } from './PartnerPayments.styled';
import { TPartnerPaymentsForm } from './PartnerPayments.types';
import { PartnerPaymentsDetailsForm, PartnerPaymentsSetup } from './components';

export const PartnerPayments: FC = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { userDetails } = useContext(UserDetailsContext);

  const {
    data: paymentInfo,
    fetchData: fetchPaymentInfo,
    isLoading: isPaymentInfoLoading,
  } = useData({
    onFetch: () => partnerService.getPaymentInfo(currentLanguage),
  });

  const resolver = useMemo(
    () => yupResolver(PARTNER_PAYMENTS_INFO_FORM_VALIDATION_SCHEMA(t)),
    [t],
  );

  const serverValues = paymentInfo?.info?.current;
  const defaultValues = serverValues || PARTNER_PAYMENTS_FORM_INFO_DEFAULT;

  const methods = useForm<TPartnerPaymentsForm>({
    defaultValues,
    resolver,
  });
  const { reset, getValues } = methods;

  const { fetchData: sendChangeRequest, isLoading: isRequestingChange } =
    useData<TPartnerPaymentInfo | null>({
      onFetch: (): Promise<TPartnerPaymentInfo | null> => {
        const userEmail = userDetails?.info?.current.email;

        if (!serverValues || !userEmail) {
          return Promise.resolve(null);
        }

        const requestValues: TPartnerPaymentInfo = {
          ...serverValues,
          ...getValues(),
        };

        return partnerService.sendChangeRequest({
          lang: currentLanguage,
          formData: requestValues,
          email: userEmail,
        });
      },
      onSuccess: (response) => {
        if (response) {
          fetchPaymentInfo();
        }
      },
      loading: false,
    });

  useEffect(() => {
    fetchPaymentInfo();
  }, []);

  useEffect(() => {
    if (defaultValues !== PARTNER_PAYMENTS_FORM_INFO_DEFAULT) {
      reset(defaultValues);
    }
  }, [paymentInfo?.info?.current]);

  return (
    <FormProvider {...methods}>
      <StyledPartnerPaymentsWrapper className="PartnerPaymentsWrapper">
        <PartnerPaymentsDetailsForm
          isLoading={isPaymentInfoLoading || isRequestingChange}
          currencies={paymentInfo?.refs?.currencies || []}
          countries={paymentInfo?.refs?.countries || []}
          onSubmit={sendChangeRequest}
          previousChangeRequest={
            paymentInfo?.info?.previous_change_request || null
          }
          diffList={paymentInfo?.info?.diff.list || []}
        />
        <PartnerPaymentsSetup
          currency={paymentInfo?.info?.current.pc_pd_currency || null}
        />
      </StyledPartnerPaymentsWrapper>
    </FormProvider>
  );
};
