import { IColumn, IconButton } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { currencyFormatter } from 'helpers/formatters';
import { TWithdrawalHistoryEntry, TWithdrawalType } from 'services/withdrawal';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';

import { TTransferType } from '../../Transfers.types';
import { CashTransferTab } from '../../context';

import {
  StyledWithdrawalActionsWrapper,
  StyledWithdrawalToCellText,
} from './WithdrawalHistoryTable.styled';
import { TGetWithdrawalHistoryTableColumnsProps } from './WithdrawalHistoryTable.types';
import { WithdrawalInfoTooltip, WithdrawalStatus } from './components';

export const getWithdrawalHistoryTableColumns = ({
  t,
  currentLanguage,
  onRepeat,
  dateFormatter,
}: TGetWithdrawalHistoryTableColumnsProps): IColumn<TWithdrawalHistoryEntry>[] => {
  return [
    {
      accessor: ({ created }: TWithdrawalHistoryEntry) => {
        return created ? dateFormatter(created) : DEFAULT_EMPTY_PLACEHOLDER;
      },
      Header: t('generic__date'),
      id: 'datetime',
      width: 180,
      disableFilters: true,
    },
    {
      Cell: ({ row }: IColumn<TWithdrawalHistoryEntry>) => {
        const { type, positions, amount, currency } = row.values?.actions || {};
        if (type === WITHDRAWAL_TYPE.SECURITY) {
          if (!positions[0]) {
            return DEFAULT_EMPTY_PLACEHOLDER;
          }

          const { symbolId, value } = positions[0];

          return `${Number(value)} ${symbolId}`;
        }

        const tilda = row.original.all_funds ? '~' : '';

        return `${tilda}${currencyFormatter(currentLanguage, currency).format(
          Number(amount),
        )}`;
      },
      id: 'subject',
      Header: t('generic__asset'),
      cellStyle: { fontWeight: '500' },
      disableFilters: true,
      width: 200,
    },
    {
      accessor: ({
        type,
        account,
        account_number,
        internal_account,
        fi_name,
        currency,
        crypto_address,
        fi_account_number,
        iban,
      }: TWithdrawalHistoryEntry) => {
        let to = '';

        switch (type) {
          case WITHDRAWAL_TYPE.CRYPTO:
            to = crypto_address || currency;
            break;
          case WITHDRAWAL_TYPE.SECURITY:
            to = fi_account_number || fi_name;
            break;
          case WITHDRAWAL_TYPE.TRANSFER:
            to = account;
            break;
          case WITHDRAWAL_TYPE.INTERNAL:
            to = internal_account;
            break;
          case WITHDRAWAL_TYPE.WIRE_THIRD_PARTY:
          case WITHDRAWAL_TYPE.WIRE:
            to = iban || account_number;
            break;
          default:
            break;
        }

        return to.length
          ? `${t('layout__transfers__to')} ${to}`
          : DEFAULT_EMPTY_PLACEHOLDER;
      },
      id: 'to',
      Header: t('layout__transfers__to'),
      Cell: ({ value }: IColumn<TWithdrawalHistoryEntry>) => {
        return (
          <StyledWithdrawalToCellText className="WithdrawalToCellText">
            {value}
          </StyledWithdrawalToCellText>
        );
      },
      width: 370,
      disableFilters: true,
    },
    {
      accessor: (data: TWithdrawalHistoryEntry) => data,
      id: 'status',
      Header: t('generic__status'),
      Cell: ({ value }: IColumn<TWithdrawalHistoryEntry>) => {
        return <WithdrawalStatus status={value.status} />;
      },
      width: 180,
      disableFilters: true,
    },
    {
      accessor: 'comment',
      id: 'comment',
      Header: t('generic__comment'),
      minWidth: 320,
      disableFilters: true,
    },
    {
      accessor: (data: TWithdrawalHistoryEntry) => data,
      id: 'actions',
      Header: t('generic__actions'),
      align: 'right',
      cellStyle: { paddingRight: '25px' },
      Cell: ({ value }: IColumn<TWithdrawalHistoryEntry>) => {
        const onDownload = () => {
          (value.uploads as TWithdrawalHistoryEntry['uploads']).forEach(
            (file) => {
              if (file.file && !file.is_removed) {
                window.open(file.file, '_blank');
              }
            },
          );
        };
        return (
          <StyledWithdrawalActionsWrapper
            className="WithdrawalActionsWrapper"
            title=""
          >
            {value.type === WITHDRAWAL_TYPE.SECURITY &&
              value.uploads &&
              value.uploads?.length > 0 && (
                <IconButton
                  iconName="DownloadIcon"
                  iconSize={16}
                  onClick={onDownload}
                />
              )}{' '}
            <IconButton
              iconName="RotateCcwIcon"
              iconSize={16}
              onClick={() => onRepeat(value)}
            />
            {value.type !== WITHDRAWAL_TYPE.INTERNAL && (
              <WithdrawalInfoTooltip value={value} />
            )}
          </StyledWithdrawalActionsWrapper>
        );
      },
      width: 115,
    },
  ];
};

export const getTransferTypeFilter = (
  transferType: TTransferType,
  cashType?: CashTransferTab | null,
): TWithdrawalType | undefined => {
  if (transferType === 'funds' && cashType) {
    if (cashType === 'BANK') {
      // TODO: should be both types
      return WITHDRAWAL_TYPE.WIRE;
    }

    if (cashType === 'INTERNAL') {
      return WITHDRAWAL_TYPE.INTERNAL;
    }
  }

  if (transferType === 'securities') {
    return WITHDRAWAL_TYPE.SECURITY;
  }

  if (transferType === 'crypto') {
    return WITHDRAWAL_TYPE.CRYPTO;
  }

  return undefined;
};
