import { setHours, setMinutes, setSeconds } from 'date-fns';

import { Dates } from 'helpers/dates';

export const today = new Date();
export const threeDaysAgo = new Date(new Date().setDate(today.getDate() - 3));
export const twoWeeksAgo = new Date(new Date().setDate(today.getDate() - 14));
export const todayStart = setSeconds(setMinutes(setHours(today, 0), 0), 0);
export const todayEnd = setSeconds(setMinutes(setHours(today, 23), 59), 59);

export const defaultDateRange = [
  Dates.convertDateToUTCString(twoWeeksAgo),
  Dates.convertDateToUTCString(today),
];
