import { FC } from 'react';

import { ReportNameList } from './ReportNames.styled';
import { TReportNamesProps } from './ReportNames.types';

export const ReportNames: FC<TReportNamesProps> = ({ report }) => {
  return (
    <ReportNameList className="ReportNameList">
      {report.items.map((item) => (
        <li key={item.id}>{item.title}</li>
      ))}
    </ReportNameList>
  );
};
