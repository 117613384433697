import { IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledCardActionWrapper = styled(IconButton)`
  .IconButtonIconWrapper {
    gap: 8px;
  }

  color: ${({ theme }) => theme.color.typo.secondary};

  .IconButtonLabel {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: ${({ theme }) => theme.font.main};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
  }
`;
