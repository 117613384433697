import { PropsWithChildren, useContext, useEffect } from 'react';
import { useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';
import { legalDocsService } from 'services/legal-docs';

import { LegalDocsContext } from './LegalDocsContext.constants';

export const LegalDocsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { currentLanguage } = useContext(TranslationContext);

  const value = useData({
    onFetch: () => legalDocsService.getLegalDocs(currentLanguage),
  });

  useEffect(() => {
    if (currentLanguage) {
      value.fetchData();
    }
  }, [currentLanguage]);

  return (
    <LegalDocsContext.Provider value={value}>
      {children}
    </LegalDocsContext.Provider>
  );
};
