import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { FormCheckboxGroupSkeleton } from '../../../FormCheckboxGroupSkeleton';
import {
  StyledReportEditItem,
  StyledReportEditItemFields,
  StyledReportEditItemHead,
} from '../ReportEditItem/ReportEditItem.styled';

import { TReportEditItemSkeletonProps } from './ReportEditItemSkeleton.types';

export const ReportEditItemSkeleton: FC<TReportEditItemSkeletonProps> = ({
  withGroups,
}) => {
  return (
    <StyledReportEditItem className="ReportEditItem">
      <StyledReportEditItemHead className="ReportEditItemHead">
        <StyledReportEditItemFields className="ReportEditItemFields">
          <Skeleton width="100%" height={32} />
          <Skeleton width="100%" height={32} />
        </StyledReportEditItemFields>
        <Skeleton width={16} height={16} />
      </StyledReportEditItemHead>

      {withGroups && <FormCheckboxGroupSkeleton circular />}
    </StyledReportEditItem>
  );
};
