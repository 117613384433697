import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledReportEdit,
  StyledReportEditTypeHead,
} from '../ReportEditType/ReportEditType.styled';
import { ReportEditItemSkeleton } from '../ReportEditType/components/ReportEditItemSkeleton';

import { StyledReportEditTypeAddSkeletonWrapper } from './ReportEditTypeSkeleton.styled';

export const ReportEditTypeSkeleton: FC = () => {
  return (
    <StyledReportEdit className="ReportEdit">
      <StyledReportEditTypeHead className="ReportEditTypeHead">
        <Skeleton width={200} height={32} />
        <StyledReportEditTypeAddSkeletonWrapper>
          <Skeleton width={24} height={24} />
          <Skeleton width={60} height={24} />
        </StyledReportEditTypeAddSkeletonWrapper>
      </StyledReportEditTypeHead>

      <ReportEditItemSkeleton />
    </StyledReportEdit>
  );
};
