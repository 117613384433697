import { error } from './error';
import { genericError } from './genericError';
import { genericNetworkError } from './genericNetworkError';
import { serverError } from './serverError';
import { success } from './success';

export const notifyWith = {
  success,
  error,
  serverError,
  genericError,
  genericNetworkError,
};
