import { useCallback, useEffect, useMemo, useState } from 'react';
import { TColumnResetType } from 'react-ui-kit-exante';
import { useDebouncedCallback } from 'use-debounce';

import { TTableVisibilityColumn } from 'services/tables';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateColumnsVisibility } from 'store/tables';
import { EStatus } from 'types/api';

import {
  prepareColumnKeys,
  prepareDefaultColumnKeys,
  prepareInitialSelectedColumns,
  setColumnKeysToLocalStorage,
} from './useSelectedColumns.helpers';
import {
  TSelectedColumns,
  TUseSelectedColumnsProps,
  TUseSelectedColumnsValue,
} from './useSelectedColumns.types';

export function useSelectedColumns<T extends Record<string, unknown>>({
  columns,
  tableIds,
  defaultDisplayedColumns = [],
  defaultTableId = '',
}: TUseSelectedColumnsProps<T>): TUseSelectedColumnsValue {
  const dispatch = useAppDispatch();

  const [visibleColumns] = useAppSelector((state) => [
    state.tables.visibleColumns,
  ]);

  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const columnIds = useMemo(
    () => tableIds.map((id) => `${id}-columns`),
    [tableIds],
  );
  const [selectedColumn, setSelectedColumn] = useState<TSelectedColumns>(() =>
    prepareInitialSelectedColumns<T>(
      columns,
      columnIds,
      defaultDisplayedColumns,
    ),
  );

  const debouncedUpdateState = useDebouncedCallback(
    (newState: { table: string; columns: string[] }) => {
      dispatch(updateColumnsVisibility(newState));
    },
    2000,
  );

  const setColumnDataToApi = useCallback((newKeys: TSelectedColumns) => {
    if (defaultTableId) {
      const columnsArr = Object.entries(newKeys)
        .map(([k, v]) => (v ? k : ''))
        .filter((k) => k);

      debouncedUpdateState({ table: defaultTableId, columns: columnsArr });
    }
  }, []);

  const setInitialColumns = useCallback(
    (response: TTableVisibilityColumn | null) => {
      if (response?.columns?.length) {
        const columnKeys = columns.reduce<Array<string>>(
          (arr, { accessor }) => {
            if (typeof accessor === 'string') {
              arr.push(accessor);
            } else {
              console.error(`accessor type ${typeof accessor} not implemented`);
            }

            return arr;
          },
          [],
        );

        setSelectedColumn(
          columnKeys.reduce<TSelectedColumns>(
            (acc, curr) => ({
              ...acc,
              [curr]: response.columns.includes(curr),
            }),
            {},
          ),
        );
      }
    },
    [],
  );

  useEffect(() => {
    if (defaultTableId && visibleColumns[defaultTableId]?.data) {
      setInitialColumns(visibleColumns[defaultTableId].data);
    }
  }, [visibleColumns[defaultTableId]]);

  const visibleColumnsStatus = visibleColumns[defaultTableId]?.status;

  useEffect(() => {
    if (visibleColumnsStatus !== EStatus.Loading) {
      setIsFirstLoading(false);
    }
  }, [visibleColumnsStatus]);

  const handleColumnChange = useCallback(
    (key: string | number, value: boolean) => {
      setSelectedColumn((prev) => {
        const newKeys: TSelectedColumns = { ...prev, [key]: value };

        setColumnKeysToLocalStorage(columnIds, newKeys);

        setColumnDataToApi(newKeys);

        return newKeys;
      });
    },
    [columnIds],
  );
  const resetColumns = useCallback(
    (type: TColumnResetType) => {
      let columnKeys;

      switch (type) {
        case 'all': {
          columnKeys = prepareColumnKeys<T>(columns, true);
          break;
        }
        case 'none': {
          columnKeys = prepareColumnKeys<T>(columns, false);
          break;
        }
        case 'default':
        default: {
          columnKeys = prepareDefaultColumnKeys<T>(
            columns,
            defaultDisplayedColumns,
          );
          break;
        }
      }

      setSelectedColumn(columnKeys);
      setColumnKeysToLocalStorage(columnIds, columnKeys);
      setColumnDataToApi(columnKeys);
    },
    [columnIds, columns, defaultDisplayedColumns],
  );

  return {
    selectedColumn,
    handleColumnChange,
    resetColumns,
    isColumnsLoading: visibleColumnsStatus === EStatus.Loading,
    isColumnsFirstLoading: isFirstLoading,
  };
}
