import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';

export function formatPercentage(
  value?: string | number | null,
  digits = 2,
): string {
  const numberedValue = Number(value);

  if (Number.isNaN(numberedValue)) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }
  const percentage = numberedValue.toFixed(digits).replace(/\.00$/, '');

  return `${percentage}%`;
}
