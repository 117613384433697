import { Chip } from '@mui/material';

import { styled } from 'theme';

import { StyledSharedContainer } from '../../../styled';

export const StyledSettingsItem = styled(StyledSharedContainer)`
  min-height: 64px;
  align-items: center;
  padding: 8px 0;

  &:nth-child(odd) {
    background-color: ${({ theme }) => {
      return theme.color.input.bg.hover;
    }};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0;

    &:nth-child(odd) {
      background-color: inherit;
    }

    border-bottom: 1px solid
      ${({ theme }) => {
        return theme.color.line.primary;
      }};
  }
`;

export const StyledSettingsItemCell = styled('div')`
  display: flex;
  padding: 0 24px;
  justify-content: flex-start;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const StyledSettingsItemCheckboxCell = styled(StyledSettingsItemCell)`
  justify-content: center;
`;

export const StyledSettingsItemEmailsCell = styled(StyledSettingsItemCell)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    &:last-child {
      margin-top: 16px;
    }
  }
`;

export const StyledSettingsItemCheckboxWrapper = styled('button')`
  .MuiButtonBase-root {
    padding: 0;
  }

  display: flex;
  gap: 8px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: transparent;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    margin-bottom: 8px;
    height: 48px;
    padding: 0 16px;
    justify-content: flex-start;

    border: 1px solid
      ${({ theme }) => {
        return theme.color.line.primary;
      }};
  }
`;

export const StyledSettingsItemCheckboxLabel = styled('label')`
  font-size: 15px;
  font-weight: 400;
  display: none;

  color: ${({ theme }) => {
    return theme.color.typo.secondary;
  }};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: inherit;
  }
`;

export const StyledSettingsItemTitle = styled('span')`
  font-size: 13px;
  font-weight: 400;

  color: ${({ theme }) => {
    return theme.color.typo.primary;
  }};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 20px;
    margin-bottom: 24px;
  }
`;

export const StyledChipItem = styled(Chip)<{ isDisabled?: boolean }>`
  & .MuiSvgIcon-root {
    display: ${({ isDisabled }) => (isDisabled ? 'none' : 'inline-block')};
    opacity: 1;
  }
`;
