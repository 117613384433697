import { useMediaQuery } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import {
  StyledAccountSelectionTitleContainer,
  StyledAccountSelectionTitleText,
} from 'components/AccountSelection/components/AccountSelectionTitle/AccountSelectionTitle.styled';
import type { TAccountSelectionTitleProps } from 'components/AccountSelection/components/AccountSelectionTitle/AccountSelectionTitle.types';
import { AccountSelectionQRPopover } from 'components/AccountSelection/components/AccountSelectionTitle/components';
import { AccountDataContext } from 'contexts/AccountDataContext';
import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { selectIsHasAtpPerms } from 'store/menu';
import { useTheme } from 'theme';

import { qrHideAccountPurposes } from './AccountSelectionTitle.constants';

export const AccountSelectionTitle: FC<TAccountSelectionTitleProps> = ({
  titleKey = '',
  isHideQR,
}) => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const { accountData } = useContext(AccountDataContext);
  const isHasAtpPerms = useAppSelector(selectIsHasAtpPerms);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('tablet'));
  const isEnableQR = branding?.branding?.enable_qr;

  const { data: accounts } = useAppSelector(selectAccounts);

  const isValidAccountPurpose = useMemo(() => {
    const accountTypes =
      accounts.find((account) => account.value === accountData?.account)
        ?.types || [];

    return !accountTypes.some((item) => qrHideAccountPurposes.includes(item));
  }, [accountData]);

  const isShowQR =
    !isHideQR &&
    isHasAtpPerms &&
    isEnableQR &&
    !isTranslationsLoading &&
    isDesktop &&
    isValidAccountPurpose;

  return (
    <StyledAccountSelectionTitleContainer className="AccountSelectionTitleContainer">
      <StyledAccountSelectionTitleText className="AccountSelectionTitleText">
        {t(titleKey, '')}
      </StyledAccountSelectionTitleText>
      {isShowQR && <AccountSelectionQRPopover />}
    </StyledAccountSelectionTitleContainer>
  );
};
