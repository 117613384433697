import { FC, useContext, useEffect } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';
import { useTimer } from 'hooks/useTimer';

import { TReloadNotificationTitleProps } from './ReloadNotificationMessage.types';

export const ReloadNotificationMessage: FC<TReloadNotificationTitleProps> = ({
  timeout,
  onTimeout,
}) => {
  const { t } = useContext(TranslationContext);

  const { time: countdown } = useTimer(timeout, { startOnInit: true });

  useEffect(() => {
    if (countdown === 0) {
      onTimeout();
    }
  }, [countdown]);

  return (
    <span>{t('layout__app_version_updated', { COUNTDOWN: countdown })}</span>
  );
};
