import { styled } from 'theme';

export const StyledMetricsColumns = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
  margin-bottom: 8px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    border-top: 1px solid ${({ theme }) => theme.color.line.primary};
    gap: 24px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`;

export const StyledMetricsEmailLink = styled('a')`
  text-decoration: none;
  color: ${({ theme }) => theme.color.typo.action};
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledMetricsEmail = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const StyledMetricsFooter = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 0;
  gap: 16px;
  font-size: 15px;
  line-height: 24px;
`;
