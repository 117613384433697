import { isDate } from 'date-fns';
import { TFunction } from 'i18next';
import { array, date, object, ref, string, TestContext } from 'yup';

import { Dates } from 'helpers/dates';
import { ReportType } from 'services/reports/reports.types';

import { filterEmptyReportFormItems } from './ReportFormContext.helpers';
import { TReportFormContextState } from './ReportFormContext.types';

export const initialState: TReportFormContextState = {
  isLoading: false,
  isSaving: false,
  refs: null,
  appendItem: () => {},
  removeItem: () => {},
  report: null,
  items: null,
};

const transformSymbolsField = (value: any, original: any) => {
  return typeof original === 'string'
    ? original
        ?.replace(/ /g, '')
        .split(',')
        .filter((v) => v)
    : original;
};

const transformDateField = (value: any, original: any) => {
  if (!Dates.isValid(value)) {
    return null;
  }

  return isDate(original) ? original : Dates.convertToDate(original);
};

export const EDIT_REPORT_FORM_ITEM_VALIDATION_SCHEMA = (t: TFunction) => {
  const hintNoAccounts = t('layout__reports__errors__no_accounts');
  const hintNoFormats = t('layout__reports__errors__no_formats');
  const hintNoDate = t('layout__reports__errors__no_date');
  const hintNoItemsWithDates = t(
    'layout__reports__errors__no_items_with_dates',
  );
  const hintNoSymbols = t('layout__reports__errors__no_symbols');

  return object({
    currency: string().required(),
    accounts: array().required(hintNoAccounts).min(1, hintNoAccounts),
    format: array().min(1, hintNoFormats).required(hintNoFormats),
    items: array()
      .of(
        object().shape(
          {
            report_type: string().required().min(0),
            trades_grouping: string().default(''),
            symbol_ids: array()
              .when(['date_to', 'date_from', 'report_type'], {
                is: (date_to: any, date_from: any, report_type: string) =>
                  report_type === ReportType.DailyPosition &&
                  (!!date_to || !!date_from),
                then: (schema) =>
                  schema
                    .required(hintNoSymbols)
                    .min(1, hintNoSymbols)
                    .transform(transformSymbolsField),
              })
              .min(1, hintNoSymbols),
            date_from: date()
              .nullable()
              .transform(transformDateField)
              .when('date_to', {
                is: (date_to: any) => !!date_to,
                then: (schema) =>
                  schema
                    .required(hintNoDate)
                    .max(
                      ref('date_to'),
                      t('reports__custom-report-item__date-comparing-error'),
                    ),
              }),
            date_to: date()
              .nullable()
              .transform(transformDateField)
              .when('date_from', {
                is: (date_from: any) => !!date_from,
                then: (schema) => schema.required(hintNoDate),
              }),
          },
          [['date_to', 'date_from']],
        ),
      )
      .test('min_1', t(hintNoItemsWithDates), (value, ctx: TestContext) => {
        if (
          value &&
          filterEmptyReportFormItems(value, ctx.parent.format).length >= 1
        ) {
          return true;
        }

        return ctx.createError({
          message: hintNoItemsWithDates,
          path: 'items',
        });
      }),
  });
};
