import { useMediaQuery } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import {
  ISelectOption,
  Notification as UiKitNotification,
  Skeleton,
  useData,
} from 'react-ui-kit-exante';

import { AccountsSearchField } from 'components/AccountsSearchField';
import { AutocompleteInfiniteListbox } from 'components/AutocompleteInfiniteListbox';
import { MAX_AUTOCOMPLETE_LENGTH } from 'constants/common';
import { CurrencyContext, TranslationContext } from 'contexts';
import { getFilterOptions } from 'helpers/getFilterOptions';
import { ReportsContext } from 'pages/Reports/contexts/ReportsContext/ReportsContext';
import { PeriodicActionCell } from 'pages/Reports/tabs/PeriodicReports/PeriodicActionCell';
import { CURRENT_LANGUAGE_KEY } from 'pages/Reports/tabs/PeriodicReports/PeriodicReports.constants';
import {
  calculateSelectedAccounts,
  getCurrentLanguageOption,
  getPeriodicReportsData,
} from 'pages/Reports/tabs/PeriodicReports/PeriodicReports.helpers';
import { reportsService } from 'services/reports';
import { useAppSelector } from 'store/hooks';
import { selectMenuData } from 'store/menu';
import { useTheme } from 'theme';
import { ReactI18NextChildren } from 'types/ReactI18NextChildren';

import {
  StyledLanguageSelect,
  StyledPeriodicReportsDescription,
  StyledPeriodicReportsDesktopTable,
  StyledPeriodicReportsSelectors,
  StyledReportMobile,
  StyledReportMobileCheckboxes,
  StyledReportMobileHeader,
  StyledSingleAccount,
  StyledSingleAccountValue,
} from './PeriodicReports.styled';
import { getColumns, getMobileCheckboxColumns } from './columns';
import { SubscriptionContext } from './contexts/SubscriptionContext';

export const PeriodicReportsWithContext = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { currency, isCurrencyLoading } = useContext(CurrencyContext);

  const { t, isTranslationsLoading, currentLanguage } =
    useContext(TranslationContext);
  const { languages } = useAppSelector(selectMenuData) || {};
  const {
    reportsSettings,
    isLoading: isReportsSettingsLoading,
    isSaving: isReportsSettingsSaving,
    saveReportsSettings,
  } = useContext(ReportsContext);

  const { subscriptions, isLoading: isSubscriptionsLoading } =
    useContext(SubscriptionContext);

  // update currency in settings after first load / currency changes
  useEffect(() => {
    if (!isCurrencyLoading && reportsSettings) {
      saveReportsSettings({
        ...reportsSettings,
        currency,
      });
    }
  }, [currency, isCurrencyLoading, isReportsSettingsLoading]);

  const [noAccountsError, setNoAccountsError] = useState(false);
  const [currentLang, setCurrentLang] = useState(reportsSettings?.language);

  const onDownloadReport = async (reportName: string) => {
    if (!subscriptions) {
      return;
    }
    if (!reportsSettings?.accounts.length) {
      setNoAccountsError(true);
    } else {
      const reportData = {
        accounts: reportsSettings.accounts,
        subscriptions,
      };
      const response = await reportsService.generatePeriodicReport(
        currentLanguage,
        reportName,
        reportData,
      );
      if (response) {
        if (response.success) {
          UiKitNotification.success({
            title: response.message,
          });
        } else {
          UiKitNotification.error({
            title: response.message,
          });
        }
      }
    }
  };

  const columns = useMemo(
    () =>
      getColumns({
        t,
        onDownloadReport,
      }),
    [t, isTranslationsLoading, onDownloadReport, subscriptions],
  );

  const tableData = useMemo(
    () => getPeriodicReportsData(t, subscriptions),
    [t, subscriptions],
  );

  const languageOptions = useMemo(
    () => [
      getCurrentLanguageOption(t),
      ...(languages?.map((lang) => ({
        value: lang.code,
        label: lang.name,
      })) || []),
    ],
    [languages, t],
  );

  useEffect(() => {
    setCurrentLang(
      reportsSettings?.language || getCurrentLanguageOption(t).value,
    );
  }, [reportsSettings?.language]);

  const onChangeLanguage = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (reportsSettings && value !== currentLang) {
      setCurrentLang(value || getCurrentLanguageOption(t).value);
      saveReportsSettings({
        ...reportsSettings,
        language: value === CURRENT_LANGUAGE_KEY ? '' : value,
      });
    }
  };

  const {
    data: accounts,
    isLoading: isAccountsLoading,
    fetchData: fetchAccounts,
  } = useData({
    onFetch: () => reportsService.getPeriodicReportsAccounts(currentLanguage),
  });

  const accountsOptions =
    accounts?.items.map((account) => ({
      value: account.id,
      label: account.text,
    })) || [];

  const mapAccountsValueToOptions = useMemo(() => {
    return (
      reportsSettings?.accounts?.reduce<ISelectOption[]>((acc, cur) => {
        const option = accountsOptions.find((opt) => opt.value === cur);
        if (option) {
          return [...acc, option];
        }
        return acc;
      }, []) || []
    );
  }, [reportsSettings?.accounts, accountsOptions]);

  const onChangeAccounts = (_: any, newValues: (string | ISelectOption)[]) => {
    if (reportsSettings) {
      setNoAccountsError(false);
      const accountsForSave = calculateSelectedAccounts(
        newValues as ISelectOption[],
        mapAccountsValueToOptions,
      );
      saveReportsSettings({
        ...reportsSettings,
        accounts: accountsForSave,
      });
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  const isShowAutocomplete =
    isAccountsLoading || (!isAccountsLoading && accountsOptions.length > 1);

  const isShowSingleAccount =
    !isAccountsLoading &&
    accountsOptions.length === 1 &&
    mapAccountsValueToOptions?.[0];

  const singleAccountValue = mapAccountsValueToOptions[0]
    ?.label as ReactI18NextChildren;

  return (
    <>
      <StyledPeriodicReportsDescription className="PeriodicReportsDescription">
        {isReportsSettingsLoading ? (
          <Skeleton height={16} />
        ) : (
          t('reports__periodic__help_text')
        )}
      </StyledPeriodicReportsDescription>
      <StyledPeriodicReportsSelectors className="PeriodicReportsSelectors">
        <StyledLanguageSelect
          className="LanguageSelect"
          options={languageOptions}
          label={t('generic__language')}
          showSkeleton={isReportsSettingsLoading}
          disabled={isReportsSettingsSaving}
          onChange={onChangeLanguage}
          value={currentLang}
          data-test-id="account-reports__button--toggle-language-list"
        />
        {isShowAutocomplete && (
          <AccountsSearchField
            withDescriptions
            options={accountsOptions}
            value={mapAccountsValueToOptions}
            filterOptions={getFilterOptions(accountsOptions.length)}
            onChange={onChangeAccounts}
            placeholder={t('generic__accounts')}
            showSkeleton={isAccountsLoading}
            disabled={isReportsSettingsSaving}
            isMultiple
            filterSelectedOptions
            fullWidth
            dataTestId="account-reports"
            controlProps={{
              error: noAccountsError,
              message: noAccountsError
                ? t('layout__account_selector__error_required')
                : '',
            }}
            /* eslint-disable-next-line react/no-unstable-nested-components */
            ListboxComponent={(props) => (
              <AutocompleteInfiniteListbox
                limit={MAX_AUTOCOMPLETE_LENGTH}
                {...props}
              />
            )}
          />
        )}
        {isShowSingleAccount && (
          <StyledSingleAccount className="SingleAccount">
            <StyledSingleAccountValue className="SingleAccountValue">
              {singleAccountValue}
            </StyledSingleAccountValue>
          </StyledSingleAccount>
        )}
      </StyledPeriodicReportsSelectors>

      {!isMobile && (
        <StyledPeriodicReportsDesktopTable
          tableId="reportsPeriodicTable"
          disableSortBy
          columns={columns}
          data={tableData || []}
          isLoading={isSubscriptionsLoading}
          isHiddenColumnSelect
          translator={t}
          isFlexLayout
          skeletonsCount={8}
          noHeader={isSubscriptionsLoading}
          manualSortBy
          getHeaderProps={() => ({
            style: { display: 'flex' },
          })}
        />
      )}
      {isMobile &&
        tableData.map((item) => {
          return (
            <StyledReportMobile className="ReportMobile" key={item.key}>
              <StyledReportMobileHeader className="ReportMobileHeader">
                {item.name}
                <PeriodicActionCell
                  data={item}
                  onDownloadReport={onDownloadReport}
                />
              </StyledReportMobileHeader>

              <StyledReportMobileCheckboxes className="StylerReportMobileCheckboxes">
                {getMobileCheckboxColumns(t, item).map((checkbox) => checkbox)}
              </StyledReportMobileCheckboxes>
            </StyledReportMobile>
          );
        })}
    </>
  );
};
