import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { TranslationContext } from 'contexts';
import { PATHS } from 'router';

import {
  StyledAddReportIconButton,
  StyledCustomReportsHeader,
} from './CustomReports.styled';
import { CustomReportsTable } from './components/CustomReportsTable';
import { CustomReportsProvider } from './components/CustomReportsTable/contexts/CustomReportsContext';

export const CustomReports: FC = () => {
  const { t } = useContext(TranslationContext);

  const navigate = useNavigate();

  const handleCreateClick = () => {
    navigate(`${PATHS.ROOT}${PATHS.CUSTOM_REPORTS_CREATE}`);
  };

  return (
    <div>
      <StyledCustomReportsHeader className="CustomReportsHeader">
        <StyledAddReportIconButton
          iconColor="action"
          iconSize={24}
          label={t('reports__tab_name__custom_create')}
          iconName="AddIcon"
          type="submit"
          data-test-id="account-settings-edit__button--change"
          className="SaveButton"
          onClick={handleCreateClick}
        />
      </StyledCustomReportsHeader>
      <CustomReportsProvider>
        <CustomReportsTable />
      </CustomReportsProvider>
    </div>
  );
};
