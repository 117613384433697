import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Tooltip,
  useData,
  Notification as UIKitNotification,
} from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { accountSettingsService } from 'services/settings/accountSettings';
import { fetchAllAccountDescriptionsAction } from 'store/accounts';
import { useAppDispatch } from 'store/hooks';

import { AccountsTableContext } from '../../../../contexts';

import {
  StyledDescriptionInput,
  StyledDescriptionWrapper,
} from './DescriptionInput.styled';
import { TDescriptionInputProps } from './DescriptionInput.types';

export const DescriptionInput = ({
  value: externalValue,
  disabled = false,
  accountId,
}: TDescriptionInputProps) => {
  const { updateAccountDescription } = useContext(AccountsTableContext);
  const { t, currentLanguage } = useContext(TranslationContext);
  const dispatch = useAppDispatch();

  const [description, setDescription] = useState<string>(externalValue);

  const { fetchData: saveDescription, isLoading: savingChanges } =
    useData<string>({
      onFetch: async (event: FocusEvent<HTMLInputElement>) => {
        return accountSettingsService.changeAccountDescription(
          accountId,
          event.target.value,
          currentLanguage,
        );
      },
      onSuccess: (newDescription) => {
        if (newDescription !== null) {
          UIKitNotification.success({
            title: t('account_settings_success_notification'),
          });

          setDescription(newDescription);
          updateAccountDescription(accountId, newDescription);
          dispatch(fetchAllAccountDescriptionsAction());
        }
      },
      loading: false,
    });

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
    },
    [setDescription],
  );

  useEffect(() => {
    setDescription(externalValue);
  }, [externalValue]);

  return (
    <StyledDescriptionWrapper className="DescriptionWrapper">
      <Tooltip title={externalValue}>
        <StyledDescriptionInput
          title=""
          placeholder={t('description')}
          value={description}
          fullWidth
          disabled={disabled || savingChanges}
          onChange={onChange}
          onBlur={saveDescription}
        />
      </Tooltip>
    </StyledDescriptionWrapper>
  );
};
