import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { BrandingContext, CurrencyContext, TranslationContext } from 'contexts';
import { challengeService } from 'services/challenge';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TChildren } from 'types/TChildren';

import { challengeAccountSummaryContextInitialValue } from './ChallengeAccountSummary.constants';
import { TChallengeAccountSummaryContextValue } from './ChallengeAccountSummaryContext.types';

export const ChallengeAccountSummaryContext =
  createContext<TChallengeAccountSummaryContextValue>(
    challengeAccountSummaryContextInitialValue,
  );

export const ChallengeAccountSummaryProvider: FC<TChildren> = ({
  children,
}) => {
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const { currency } = useContext(CurrencyContext);
  const { branding } = useContext(BrandingContext);
  const { isTranslationsLoading } = useContext(TranslationContext);
  const [accountJoinedToChallengeStatus, setAccountJoinedToChallengeStatus] =
    useState<string | null>(null);
  const [silentUpdate, setSilentUpdate] = useState<boolean>(false);

  const {
    data: challengeAccountInfo,
    fetchData: fetchChallengeAccountInfo,
    isLoading: isChallengeAccountInfoLoading,
  } = useData({
    onFetch: async () => {
      if (selectedAccountId !== null) {
        const response = await challengeService.getChallengeSummary(
          selectedAccountId,
          currency,
        );
        setAccountJoinedToChallengeStatus(response.error);
        return response.data;
      }
      return null;
    },
  });

  const contextLoading =
    (isChallengeAccountInfoLoading && !silentUpdate) || isTranslationsLoading;

  const updateData = async (silent: boolean) => {
    if (selectedAccountId && currency) {
      if (silent) {
        setSilentUpdate(true);
      }

      await fetchChallengeAccountInfo();

      if (silent) {
        setSilentUpdate(false);
      }
    }
  };

  let interval: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (!branding?.account_info_refresh_rate) {
      return () => {};
    }

    interval = setInterval(() => {
      updateData(true);
    }, branding?.account_info_refresh_rate);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [selectedAccountId, currency, branding?.account_info_refresh_rate]);

  useEffect(() => {
    updateData(false);
  }, [selectedAccountId, currency]);

  const value = useMemo<TChallengeAccountSummaryContextValue>(() => {
    if (accountJoinedToChallengeStatus) {
      const challengeAccountSummaryError = {
        ...challengeAccountSummaryContextInitialValue,
        loading: false,
        accountJoinedToChallengeStatus,
      };
      return challengeAccountSummaryError;
    }
    if (!challengeAccountInfo) {
      return challengeAccountSummaryContextInitialValue;
    }
    const {
      current_balance: currentBalance,
      profit_target: profitTarget,
      daily_dd: dailyDd,
      max_dd: maxDd,
      current_equity: currentEquity,
      day_traded: dayTraded,
      remaining_days: remainingDays,
      challenge_date_start: challengeDateStart,
    } = challengeAccountInfo;

    return {
      loading: contextLoading,
      accountJoinedToChallengeStatus,
      currentBalance,
      profitTarget,
      dailyDd,
      maxDd,
      currentEquity,
      dayTraded,
      remainingDays,
      challengeDateStart,
    };
  }, [contextLoading, challengeAccountInfo]);

  return (
    <ChallengeAccountSummaryContext.Provider value={value}>
      {children}
    </ChallengeAccountSummaryContext.Provider>
  );
};
