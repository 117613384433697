import { times } from 'lodash';

import { StyledPageWrapper } from 'components/Main';

import { StyledDownloadsPanel } from './DownloadsContainer/DownloadsContainer.styled';
import {
  StyledDownloadsList,
  StyledDownloadsListItem,
} from './DownloadsList/DownloadsList.styled';
import { ProductSkeleton } from './Product/components/ProductSkeleton';

export const DownloadsInitialSkeleton = () => (
  <StyledPageWrapper className="DownloadsWrapper">
    <StyledDownloadsPanel className="DownloadsPanel" title="Trading">
      <StyledDownloadsList className="DownloadsList">
        {times(6, (i) => (
          <StyledDownloadsListItem className="DownloadsListItem" key={i}>
            <ProductSkeleton />
          </StyledDownloadsListItem>
        ))}
      </StyledDownloadsList>
    </StyledDownloadsPanel>
  </StyledPageWrapper>
);
