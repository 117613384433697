import { useMediaQuery } from '@mui/material';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Skeleton, useData } from 'react-ui-kit-exante';

import { Text } from 'components/Text';
import { DEFAULT_CURRENCY } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';
import { getFormData } from 'helpers/formData';
import { notifyWith } from 'helpers/notifyWith';
import { sortCurrenciesArray } from 'helpers/sortCurrenciesArray';
import { useAccountsBalances } from 'hooks/useAccountsBalances';
import { TAccountBalance } from 'services/accounts';
import { withdrawalService } from 'services/withdrawal';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { useTheme } from 'theme';
import { TValueOf } from 'types/TValueOf';

import {
  getMappedCommissions,
  realizeCommission,
  withdrawalBalanceAutoupdateEffectFactory,
} from '../../Transfers.helpers';
import { NoteText } from '../../components/NoteText/NoteText';
import { useTransfers } from '../../hooks';
import { getMaxAmountAndComission } from '../Crypto/Crypto.helpers';
import { TWithdrawalCommissions } from '../Crypto/Crypto.types';
import { StyledTransfersContainerWrapper } from '../shared.styled';

import {
  SUBUSER_WITHDRAWAL_MIN_MPI,
  SUBUSER_WITHDRAWAL_MODE,
} from './SubuserWithdrawal.constants';
import {
  StyledDisabledPlaceholder,
  StyledSkeletonWrapper,
  StyledSubuserWithdrawalContent,
  StyledSubuserWithdrawalHeader,
  StyledSubuserWithdrawalWrapper,
} from './SubuserWithdrawal.styled';
import {
  SubuserWithdrawalAmountForm,
  SubuserWithdrawalConfirmationForm,
} from './components';
import { TSubuserWithdrawalAmountFormProps } from './components/SubuserWithdrawalAmountForm/SubuserWithdrawalAmountForm.types';

export const SubuserWithdrawal: FC = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const {
    isLoading: isTransfersLoading,
    updateLastTransactionId,
    transfersState: initialState,
    commissionsState,
  } = useTransfers();
  const { balances, updateBalance, isBalancesLoading } =
    useAccountsBalances(currentLanguage);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [currency, setCurrency] = useState('');
  const [amount, setAmount] = useState('');
  const [mode, setMode] = useState<TValueOf<typeof SUBUSER_WITHDRAWAL_MODE>>(
    SUBUSER_WITHDRAWAL_MODE.EDIT,
  );

  const commissions = useMemo<TWithdrawalCommissions | null>(() => {
    const mappedCommissions = getMappedCommissions(
      commissionsState,
      initialState?.commissions,
      WITHDRAWAL_TYPE.SUBUSER_WITHDRAWAL,
      currency,
    );

    if (!mappedCommissions) {
      return null;
    }

    const crossrate =
      mappedCommissions?.crossrates?.[
        mappedCommissions?.commissionCurrency || currency
      ];

    return {
      ...mappedCommissions,
      crossrate: crossrate || '1',
      currency: currency || DEFAULT_CURRENCY,
    };
  }, [commissionsState, initialState?.commissions, currency]);

  const selectedAccountBalances = useMemo<TAccountBalance | undefined>(
    () => balances[currency]?.[selectedAccountId || ''],
    [balances, currency, selectedAccountId],
  );

  const { limit, fee, min, max, allFundsFee, available } = useMemo(() => {
    if (!selectedAccountId) {
      return {
        limit: null,
        fee: 0,
        max: 0,
        min: 0,
        allFundsFee: 0,
        available: 0,
      };
    }

    const freeMoney = selectedAccountBalances?.free_money;
    const availableFunds = freeMoney !== null ? Number(freeMoney) : null;

    const commission = realizeCommission(amount, commissions);
    const allFundsCommission = realizeCommission(availableFunds, commissions);

    return {
      available: availableFunds,
      fee: commission.realized,
      allFundsFee: allFundsCommission.realized,
      limit: getMaxAmountAndComission(
        commission.realized,
        SUBUSER_WITHDRAWAL_MIN_MPI.mpi,
        availableFunds,
      ),
      ...(commission && {
        min: `${commission.minimum} ${currency}`,
        max: `${commission.maximum} ${currency}`,
      }),
    };
  }, [selectedAccountBalances, commissions, amount]);

  const currencies = useMemo(
    () =>
      sortCurrenciesArray(
        (selectedAccountId &&
          initialState?.wr_types?.[WITHDRAWAL_TYPE.SUBUSER_WITHDRAWAL]?.[
            initialState?.client_id_le_map[selectedAccountId.split('.')[0]]
          ]) ||
          [],
      ),
    [selectedAccountId, initialState],
  );

  const returnToEditMode = () => {
    setMode(SUBUSER_WITHDRAWAL_MODE.EDIT);
  };

  const { isLoading: isRequesting, fetchData: sendRequest } = useData({
    onFetch: async () => {
      if (!selectedAccountId) {
        return Promise.resolve(null);
      }

      const data = {
        type: WITHDRAWAL_TYPE.SUBUSER_WITHDRAWAL,
        amount,
        account: selectedAccountId,
        currency,
        confirm: '2fa',
        is_third_party: false,
      };

      return withdrawalService.requestTransfer<true>(
        getFormData(Object.entries(data)).data,
      );
    },
    onSuccess: (response) => {
      if (response?.success) {
        notifyWith.success('layout__withdrawal__request_sent');

        if (response.subuserWithdrawal) {
          notifyWith.success('layout__withdrawal__subuser_wr_executed_soon');
        }

        updateLastTransactionId(response.subuserWithdrawal.id);
        if (selectedAccountId) {
          updateBalance(selectedAccountId, currency);
        }

        returnToEditMode();
        setAmount('');
      }
    },
    loading: false,
  });

  const feeProps = useMemo(
    (): TSubuserWithdrawalAmountFormProps['fee'] => ({
      effective: fee,
      visibleValue: commissions
        ? `${commissions.value} ${commissions.type}`
        : '',
      min: String(min),
      max: String(max),
      stdFee: commissions?.type !== '%',
      allFunds: allFundsFee,
    }),
    [fee, commissions, min, max, allFundsFee],
  );

  useEffect(
    withdrawalBalanceAutoupdateEffectFactory({
      selectedAccountId,
      currency,
      updateBalance,
    }),
    [selectedAccountId, currency],
  );

  useEffect(() => {
    if (!currency || !currencies.includes(currency)) {
      setCurrency(currencies[0]);
    }
  }, [selectedAccountBalances, currencies, currency]);

  if (
    !isTransfersLoading &&
    !(Number(selectedAccountBalances?.net_asset) > 0) &&
    !isBalancesLoading
  ) {
    return (
      <StyledDisabledPlaceholder className="DisabledPlaceholder">
        <Text size="15px" weight="400" color="secondary">
          {t('layout__subaccount_transfer__not_available')}
        </Text>
      </StyledDisabledPlaceholder>
    );
  }

  return (
    <StyledTransfersContainerWrapper className="TransfersContainerWrapper">
      <StyledSubuserWithdrawalWrapper className="SubuserWithdrawalWrapper">
        {isDesktop && (
          <StyledSubuserWithdrawalContent className="SubuserWithdrawalContent">
            {isTransfersLoading || isBalancesLoading ? (
              <StyledSkeletonWrapper>
                <Skeleton width={160} height={25} />
                <Skeleton width={360} height={25} />
              </StyledSkeletonWrapper>
            ) : (
              <>
                <StyledSubuserWithdrawalHeader className="SubuserWithdrawalHeader">
                  {t('layout__withdrawal__subuser_withdrawal__header')}
                </StyledSubuserWithdrawalHeader>
                <span>{t('layout__withdrawal__subuser__description')}</span>
                <NoteText type={WITHDRAWAL_TYPE.SUBUSER_WITHDRAWAL} />
              </>
            )}
          </StyledSubuserWithdrawalContent>
        )}
        {mode === SUBUSER_WITHDRAWAL_MODE.CONFIRM && (
          <SubuserWithdrawalConfirmationForm
            currency={currency}
            amount={amount}
            loading={isTransfersLoading}
            requesting={isRequesting}
            onConfirm={sendRequest}
            onCancel={returnToEditMode}
          />
        )}
        {mode === SUBUSER_WITHDRAWAL_MODE.EDIT && (
          <SubuserWithdrawalAmountForm
            limit={limit}
            available={available}
            currencies={currencies}
            isLoading={isTransfersLoading || isBalancesLoading}
            amount={amount}
            currency={currency}
            commissionCurrency={commissions?.commissionCurrency || currency}
            fee={feeProps}
            onSubmit={() => setMode(SUBUSER_WITHDRAWAL_MODE.CONFIRM)}
            onAmountChange={setAmount}
            onCurrencyChange={setCurrency}
          />
        )}
      </StyledSubuserWithdrawalWrapper>
    </StyledTransfersContainerWrapper>
  );
};
