import { styled } from 'theme';

export const StyledStatusCellItem = styled('div')`
  color: ${({ theme }) => theme.color.icon.secondary};
`;

export const StyledStatusCellItemRejected = styled('div')`
  color: ${({ theme }) => theme.color.icon.radical};
`;

export const StyledStatusCellItemApproved = styled('div')`
  color: ${({ theme }) => theme.color.icon.action};
`;
