import {
  StyledLayoutWrapper,
  StyledLayoutMain,
} from 'components/Layout/Layout.styled';
import { TitleUpdater } from 'components/TitleUpdater';

import { LoginMain } from '../LoginMain';

export const LoginLayout = () => {
  return (
    <StyledLayoutWrapper className="page">
      <StyledLayoutMain className="page__main">
        <LoginMain />
      </StyledLayoutMain>

      <TitleUpdater />
    </StyledLayoutWrapper>
  );
};
