import { StyledDocumentUploadTooltipText } from '../../UploadGroup.styled';
import { TTooltipContentProps } from '../../UploadGroup.types';

export const OtherDocumentsTooltipContent = ({ t }: TTooltipContentProps) => (
  <StyledDocumentUploadTooltipText className="DocumentUploadTooltipText">
    {t(
      'Upload any additional documents requested by your account manager here.',
    )}
  </StyledDocumentUploadTooltipText>
);
