import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledAccountContainer,
  StyledAccountContainerLeftSide,
  StyledAccountContainerRightSide,
} from './Account.styled';

export const AccountSkeleton = () => {
  return (
    <StyledAccountContainer className="AccountContainer">
      <StyledAccountContainerLeftSide className="AccountContainerLeftSide">
        <Skeleton variant="rounded" width={24} height={24} />
        <Skeleton variant="rounded" width={96} height={16} />
      </StyledAccountContainerLeftSide>
      <StyledAccountContainerRightSide className="AccountContainerRightSide">
        <Skeleton variant="rounded" width={24} height={24} />
        <Skeleton variant="rounded" width={39} height={24} />
      </StyledAccountContainerRightSide>
    </StyledAccountContainer>
  );
};
