import { useMediaQuery } from '@mui/material';
import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';
import { useTheme } from 'theme';

import {
  StyledAccountSettingsIconButton,
  StyledAccountSettingsTooltip,
} from '../../AccountSettings.styled';

import { TAddAccountButtonProps } from './AddAccountButton.types';

export const AddAccountButton: FC<TAddAccountButtonProps> = ({
  disabled,
  onAdd,
}) => {
  const { t } = useContext(TranslationContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const iconLabel = isMobile ? '' : t('create_account');

  const addButton = (
    <StyledAccountSettingsIconButton
      className="IconButton"
      iconName="AddIcon"
      iconColor="action"
      label={iconLabel}
      onClick={onAdd}
      disabled={disabled}
    />
  );

  return disabled ? (
    <StyledAccountSettingsTooltip
      className="AccountSettingsTooltip"
      title={t('account_settings_account_creation_not_allowed')}
    >
      {addButton}
    </StyledAccountSettingsTooltip>
  ) : (
    addButton
  );
};
