import { FC } from 'react';

import { CreateTaxReport } from './components/CreateTaxReport';
import { TaxReportsTable } from './components/TaxReportsTable';
import { TaxReportsProvider } from './contexts/TaxReportsContext/TaxReportsContext';

export const TaxReports: FC = () => {
  return (
    <TaxReportsProvider>
      <CreateTaxReport />
      <TaxReportsTable />
    </TaxReportsProvider>
  );
};
