import { TFunction } from 'i18next';
import {
  ICellValue,
  IColumn,
  ISelectOption,
  TableCellProps,
} from 'react-ui-kit-exante';

import { TCreateCurrencyFormatter } from 'helpers/formatters';
import { formatCurrencyUnrounded } from 'helpers/formatters/formatCurrencyUnrounded';
import { TDateFormatter } from 'hooks/useDateFormatter';
import { TTransactionData } from 'services/transactions';

export const columnKeys = [
  'id',
  'isin',
  'operationType',
  'timestamp',
  'sum',
  'asset',
] as const;

export const transactionsDisplayedColumnKeys = ['timestamp', 'sum', 'asset'];

type TTransactionsColumns = {
  onFilter: (col: string, value: unknown) => void;
  onRemove: (col: string) => void;
  operationTypes: ISelectOption[];
  t: TFunction;
  currencyFormatter: TCreateCurrencyFormatter;
  dateFormatter: TDateFormatter;
};

export const getColumns: ({
  onFilter,
  onRemove,
  operationTypes,
  t,
  currencyFormatter,
  dateFormatter,
}: TTransactionsColumns) => IColumn<TTransactionData>[] = ({
  onFilter,
  onRemove,
  operationTypes,
  t,
  currencyFormatter,
  dateFormatter,
}) => {
  const setFilterDate = (column: string, value: (Date | null)[]) => {
    let dateFilter: string[] | string = value
      .map((v) => v && new Date(v))
      .map((v) => (v?.setMilliseconds(0) && v.toISOString()) || '');
    if (dateFilter.every((date) => !date)) {
      dateFilter = '';
    }
    onFilter(column, dateFilter);
  };

  return [
    {
      id: 'id',
      Header: t('layout__id'),
      accessor: 'id',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      id: 'isin',
      Header: t('generic__isin'),
      accessor: 'isin',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      id: 'operationType',
      Header: t('generic__operation_type'),
      accessor: (data: TTransactionData) => data,
      Cell: ({ row: { original } }: ICellValue<TTransactionData>) => {
        return original.operationType;
      },
      tooltip: {
        type: 'custom',
        options: {
          Title: (value: TTransactionData) => value.comment,
        },
      },
      onFilter,
      onRemove,
      type: 'multiSelect',
      filterOptions: operationTypes,
      disableSortBy: true,
    },
    {
      id: 'timestamp',
      Header: t('generic__date'),
      accessor: 'timestamp',
      type: 'dateRange',
      onFilter: setFilterDate,
      onRemove,
      onToday: setFilterDate,
      onYesterday: setFilterDate,
      onLastWeek: setFilterDate,
      Cell: ({ value }: TableCellProps<TTransactionData>) => {
        if (typeof value === 'string') {
          return dateFormatter(value);
        }
        return null;
      },
      useLocale: true,
      required: true,
    },
    {
      id: 'sum',
      Header: t('generic__sum'),
      accessor: 'sum',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({
        value,
      }: {
        value: TTransactionData['sum'];
      } & Omit<TableCellProps<TTransactionData>, 'sum'>) =>
        formatCurrencyUnrounded(currencyFormatter, value),
    },
    {
      id: 'asset',
      Header: t('generic__asset'),
      accessor: 'asset',
      disableFilters: true,
      disableSortBy: true,
    },
  ];
};
