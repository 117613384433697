import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledXntAnchor = styled('a')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  inline-size: fit-content;
  text-decoration: none;

  transition: 0.3s;
  color: ${({ theme }) => theme.color.typo.action};

  &:hover {
    color: ${({ theme }) => theme.color.typo.hover};
  }

  &::before,
  &::after {
    box-sizing: border-box;
  }
`;

export const StyledXntButton = styled(Button)`
  color: inherit !important;

  &:hover {
    text-decoration: none !important;
  }
`;
