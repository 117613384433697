import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PATHS } from 'router/router.constants';
import { loginService } from 'services/login';

export const useTokensUpdate = (isEnabled = true) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isWaiting, setIsWaiting] = useState(false);

  const checkTokens = async () => {
    setIsWaiting(true);

    let xAccess = Cookies.get('x_access');
    const yAccess = Cookies.get('y_access');
    const xAccessInCache = Cookies.get('in_cache');

    const isLogin = pathname === PATHS.LOGIN;

    // нет токенов редиректим на логин
    if (!xAccess && !yAccess && !isLogin) {
      navigate(PATHS.LOGIN);
    }

    // значит был логин через wp/какую-то другую платформу и сначала надо положить сессию в кеш
    if (xAccess && xAccess !== xAccessInCache) {
      const cached = await loginService.setAccessTokenToCache();

      // если этот токен невалидный то
      if (!cached) {
        Cookies.remove('x_access');
        xAccess = undefined;
      }
    }

    // есть только рефреш, а акксесс заэкспайрился
    if (!xAccess && yAccess) {
      const updated = await loginService.updateAccessTokenIfNeeded();

      if (!updated) {
        Cookies.remove('y_access');
        window.location.href = PATHS.LOGIN;
        navigate(PATHS.LOGIN);
      }
    }

    if (xAccess && isLogin) {
      const url = await loginService.checkTokenAndDefaultRedirect();

      if (url) {
        window.location.href = url;
      }
    }

    setIsWaiting(false);
  };

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    // not needed to re-chek after redirect inside app
    checkTokens();
  }, []);

  useEffect(() => {
    if (!isEnabled) {
      return () => {};
    }

    // раз в минуту отправляем запрос на апдейт токена, он обновится только если expire подходит к концу (меньше 2х минут)
    const interval = setInterval(() => {
      loginService.updateAccessTokenIfNeeded();
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isEnabled]);

  return {
    isWaiting: isEnabled && isWaiting,
  };
};
