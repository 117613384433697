export enum EStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export type TPaginationResponse = {
  total: number;
};

export type TDataWithPagination<T> = {
  data: T;
  pagination: TPaginationResponse;
};

export type TOptionsArguments = {
  signal: AbortSignal;
};

export type TWithStatus = {
  status?: string;
};
