import {
  AffidavitFormStatus,
  TAffidavitFormState,
} from './AffidavitFormContext.types';

export const AFFIDAVIT_FORM_TERMINAL_STATUSES = [
  AffidavitFormStatus.Default,
  AffidavitFormStatus.Confirmed,
];

export const initialState: TAffidavitFormState = {
  status: AffidavitFormStatus.Default,
  requestSign: () => {},
  requestConfirm: () => {},
  signOptions: [],
  signOptionsChecked: {},
  setSignOptionChecked: () => {},
  changesText: '',
  setChangesText: () => {},
  code: '',
  setCode: () => {},
  confirmError: '',
  setConfirmError: () => {},
  accountId: '',
  accountName: '',
  mfaType: 'sms',
};
