import { set } from 'lodash';
import { Dispatch } from 'react';

import { MAX_REQUEST_LIMIT } from 'constants/instrumentsTree';
import { instrumentsTreeService } from 'services/instrumentsTree/instrumentsTree';
import { TTreeModule } from 'services/instrumentsTree/instrumentsTree.types';

import { TRow, TUseHandleCellClickArgs } from '../InstrumentsTree.types';
import {
  ActionTypes,
  TActionsState,
} from '../context/InstrumentsTreeContext.types';

type TGetAndInsertDataToNodeParams = {
  row: TRow;
  skip: number;
  dispatch: Dispatch<TActionsState>;
  expanded: any;
  module: TTreeModule;
  loadingInstruments: Set<string>;
} & Pick<
  TUseHandleCellClickArgs,
  | 'matchIdAndPositionInNonSearchTable'
  | 'accountId'
  | 'username'
  | 'updateMatchIdAndPositionInNonSearchTable'
  | 'setTableData'
  | 'search'
>;

export const getAndInsertDataToNode = async ({
  accountId,
  username,
  search,
  row,
  skip,
  dispatch,
  loadingInstruments,
  expanded,
  setTableData,
  matchIdAndPositionInNonSearchTable,
  updateMatchIdAndPositionInNonSearchTable,
  module,
}: TGetAndInsertDataToNodeParams) => {
  if (!row.original.id) {
    return;
  }

  dispatch({
    type: ActionTypes.SET_LOADING_INSTRUMENT_ID,
    payload: loadingInstruments.add(row.id),
  });

  const shouldExpandRowAfterFirstInstruments = skip === 0;

  const response = await instrumentsTreeService.getInstruments(
    {
      accountId,
      username,
      search,
      path: row.original.id,
      limit: MAX_REQUEST_LIMIT,
      skip,
    },
    module,
  );

  if (response === null) {
    return;
  }

  const { data, pagination } = response;

  if (row.original.path) {
    dispatch({
      type: ActionTypes.ADD_DOWNLOADED_PATHS,
      payload: row.original.path,
    });
  }

  setTableData((prevState) => {
    if (
      row.original.id &&
      prevState &&
      matchIdAndPositionInNonSearchTable.current
    ) {
      const pathToSubrows = `${
        matchIdAndPositionInNonSearchTable.current[row.original.id]
      }.subRows`;

      updateMatchIdAndPositionInNonSearchTable({
        ...matchIdAndPositionInNonSearchTable.current,
        ...data.reduce<Record<string, string>>((acc, instrument, index) => {
          acc[instrument.id] = `${pathToSubrows}.${index}`;
          return acc;
        }, {}),
      });

      set(prevState, pathToSubrows, [...data]);
      const res = [...prevState];

      // HACK: lodash fills empty array positions with undefined. React Table cannot handle such array.
      res.forEach((e, i) => e === undefined && delete res?.[i]);

      return res;
    }

    return prevState;
  });

  if (shouldExpandRowAfterFirstInstruments && row.toggleRowExpanded) {
    row.toggleRowExpanded();
    dispatch({
      type: ActionTypes.SET_EXPANDED_ROWS,
      payload: {
        ...expanded,
        [row.id]: true,
      },
    });
  }

  loadingInstruments.delete(row.id);
  dispatch({
    type: ActionTypes.SET_LOADING_INSTRUMENT_ID,
    payload: loadingInstruments,
  });

  if (pagination.total > skip + MAX_REQUEST_LIMIT) {
    await getAndInsertDataToNode({
      accountId,
      username,
      search,
      dispatch,
      loadingInstruments,
      expanded,
      matchIdAndPositionInNonSearchTable,
      row,
      setTableData,
      skip: skip + MAX_REQUEST_LIMIT,
      updateMatchIdAndPositionInNonSearchTable,
      module,
    });
  }
};
