import { CodeVerificationActions } from './useCodeVerification.actions';
import {
  TCodeVerificationReducer,
  TCodeVerificationState,
} from './useCodeVerification.types';

export const useCodeVerificationReducer: TCodeVerificationReducer = (
  state,
  action,
): TCodeVerificationState => {
  switch (action.type) {
    case CodeVerificationActions.OnCodeChange:
      return {
        ...state,
        code: action.payload,
        verifyErrorMessage: '',
      };

    case CodeVerificationActions.VerifyError:
      return {
        ...state,
        isVerifying: false,
        verifyErrorMessage: action.payload,
      };

    case CodeVerificationActions.VerifyStart:
      return {
        ...state,
        verifyErrorMessage: '',
        isVerifying: true,
      };

    case CodeVerificationActions.VerifySuccess:
      return {
        ...state,
        isVerifying: false,
      };

    case CodeVerificationActions.RequestStart:
      return {
        ...state,
        isRequesting: true,
        requestErrorMessage: '',
      };

    case CodeVerificationActions.RequestError:
      return {
        ...state,
        isRequesting: false,
        requestErrorMessage: action.payload,
      };

    case CodeVerificationActions.RequestSuccess:
      return {
        ...state,
        isRequesting: false,
        requestErrorMessage: '',
      };

    default:
      return state;
  }
};
