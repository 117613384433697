import { OnAddAction } from './actions';
import { initialState } from './constants';
import { TAddActionReducer } from './types';

const reducer: TAddActionReducer = (state, action) => {
  switch (action.type) {
    case OnAddAction.OnSetEnvironment: {
      return {
        ...state,
        environment: action.payload,
      };
    }

    case OnAddAction.OnSetPassword: {
      return {
        ...state,
        error: '',
        password: action.payload,
      };
    }

    case OnAddAction.OnSetDescription: {
      return {
        ...state,
        description: action.payload,
      };
    }

    case OnAddAction.OnSetScopes: {
      const existIndex = state.scopes.indexOf(action.payload);

      const scopes =
        existIndex === -1
          ? [...state.scopes, action.payload]
          : [
              ...state.scopes.slice(0, existIndex),
              ...state.scopes.slice(existIndex + 1),
            ];

      return {
        ...state,
        scopes,
      };
    }

    case OnAddAction.OnSaveStart: {
      return {
        ...state,
        saving: true,
        error: '',
      };
    }

    case OnAddAction.OnSaveError: {
      return {
        ...state,
        saving: false,
        error: action.payload,
      };
    }

    case OnAddAction.OnSaveSuccess: {
      return {
        ...state,
        saving: false,
      };
    }

    case OnAddAction.OnResetState: {
      return {
        ...state,
        scopes: initialState.scopes,
        password: '',
        description: '',
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
