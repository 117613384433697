import { ApiRequest } from 'helpers/apiRequest';

class LanguagesService extends ApiRequest {
  async getCurrentLanguage(): Promise<string> {
    try {
      const { data } = await this.fetch<string>({
        url: `/clientsarea/rest/lang/`,
      });
      return data;
    } catch (error) {
      console.error('getCurrentLanguage error:', error);
      return '';
    }
  }

  async setCurrentLanguage(lang: string): Promise<boolean> {
    try {
      await this.fetch({
        url: `/clientsarea/set-language/${lang}/`,
      });

      return true;
    } catch (error) {
      console.error('setCurrentLanguage error:', error);
      return false;
    }
  }
}

export const languagesService = new LanguagesService();
