import { TWithdrawalType } from 'services/withdrawal';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';

import { TTransfersState } from '../../context';

import { TGetWithdrawalAllowedAccountsProps } from './useTransfers.types';

export const hasValidToInternalAccountLegacyPair = (
  state: TTransfersState,
  selectedAccountId?: string | null,
): boolean => {
  if (!selectedAccountId) {
    return false;
  }

  const [id] = selectedAccountId.split('.');
  const accountLegacy = state.client_id_le_map[id];
  const currencies = state.wr_types?.Internal[accountLegacy];

  if (!currencies) {
    return false;
  }

  return currencies?.length > 0;
};

export const getWithdrawalAllowedAccountsByType = ({
  withdrawalTypesMap,
  isSuperuser,
  allowedAccountIds,
  transferAllowedOwnAccountIds,
  subuserWithdrawalAllowedAccountIds,
  legalEntityMap,
}: TGetWithdrawalAllowedAccountsProps): Partial<
  Record<TWithdrawalType, string[]>
> => {
  if (!withdrawalTypesMap) {
    return {};
  }

  const withdrawalTypes = Object.keys(withdrawalTypesMap) as TWithdrawalType[];

  return withdrawalTypes.reduce<Partial<Record<TWithdrawalType, string[]>>>(
    (map, type) => {
      const legalEntities = Object.keys(withdrawalTypesMap[type]);
      const accounts = new Set(allowedAccountIds);

      if (type === WITHDRAWAL_TYPE.TRANSFER) {
        transferAllowedOwnAccountIds.forEach((id) => accounts.add(id));
      }

      if (type === WITHDRAWAL_TYPE.SUBUSER_WITHDRAWAL) {
        subuserWithdrawalAllowedAccountIds.forEach((id) => accounts.add(id));
      }

      map[type] = [...accounts].reduce<string[]>((arr, id) => {
        const clientId = id.split('.')[0];
        const legalEntity = legalEntityMap[clientId];

        if (legalEntities.includes(legalEntity) || isSuperuser) {
          arr.push(id);
        }

        return arr;
      }, []);

      return map;
    },
    {},
  );
};

export const isInternalTransferShowNotAllowed = (
  transfersState: TTransfersState,
) => {
  if (transfersState.otherTransfer.show) {
    const transferNotAllowed = Boolean(
      !transfersState.accounts.lists.transfer_allowed.other.length,
    );
    if (transferNotAllowed && !transfersState.otherTransfer.isGbxp) {
      return true;
    }
  }
  return false;
};
