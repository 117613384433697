import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts';

import {
  StyledDepositSection,
  StyledDepositSectionActions,
  StyledDepositSectionBody,
  StyledDepositSectionHead,
  StyledDepositSectionHeading,
} from '../../../DepositWrapper/DepositWrapper.styled';

import { CryptoAddress } from './components/CryptoAddress';
import { CryptoScan } from './components/CryptoScan';
import { RenewAddressButton } from './components/RenewAddressButton';

export const RequisitesCryptoSection: FC = () => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledDepositSection className="DepositSection">
      <StyledDepositSectionHead className="DepositSectionHead">
        <StyledDepositSectionHeading className="DepositSectionHeading">
          {t('layout__deposit__requisites_title')}
        </StyledDepositSectionHeading>
        <StyledDepositSectionActions className="DepositSectionActions">
          <RenewAddressButton />
        </StyledDepositSectionActions>
      </StyledDepositSectionHead>
      <StyledDepositSectionBody className="DepositSectionBody">
        <CryptoScan />
        <CryptoAddress />
      </StyledDepositSectionBody>
    </StyledDepositSection>
  );
};
