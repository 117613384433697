import { BASE_URL } from 'constants/endpoints';
import {
  analysisService,
  TFetchAccountHistoryParams,
  TReportType,
} from 'services/analysis';

const openReport = (
  reportType: TReportType,
  filters: TFetchAccountHistoryParams,
  lang: string,
) => {
  const url = new URL(
    `/${lang}/clientsarea/rest/bo/history/xls/${reportType}/`,
    BASE_URL,
  );
  const params = new URLSearchParams({
    ...filters,
    symbol_ids: filters.symbol_ids.toString(),
  });
  window.open(`${url}?${params.toString()}`);
  return true;
};

export const downloadAnalysisReport = async (
  reportType: TReportType,
  filters: TFetchAccountHistoryParams,
  lang: string,
) => {
  const response = await analysisService.getXLSReport(
    lang,
    reportType,
    filters,
  );
  if (!response) {
    setTimeout(() => {
      downloadAnalysisReport(reportType, filters, lang);
    }, 4000);
  } else {
    openReport(reportType, filters, lang);
  }
};
