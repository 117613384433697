import { initialFetchState } from 'constants/state';
import {
  initialSelectedAccountInfo,
  initialWithdrawalState,
} from 'store/withdrawal/withdrawal.constants';

import { TTransfersState } from './types';

export const initialTransfersState: TTransfersState = {
  ...initialWithdrawalState,

  fetching: initialFetchState,
  lastTransactionId: null,
  repeatedWithdrawal: null,
  selectedAccountInfo: initialSelectedAccountInfo,
  fetchingMetadata: initialFetchState,
  fetchMetadataAccount: initialFetchState,
};
