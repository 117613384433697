import { blockNonNativeProps, css } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TAvailableLabelProps } from './AvailableLabel.types';

export const StyledAvailableLabel = styled('span', {
  shouldForwardProp: blockNonNativeProps(['isMarginCall']),
})<TAvailableLabelProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  ${({ isMarginCall, theme }) => {
    if (isMarginCall) {
      return css`
        color: ${theme.color.typo.radical};
      `;
    }
    return '';
  }}
`;
