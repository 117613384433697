import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledSplashScreen = styled('article')`
  padding: 24px;
  margin-bottom: 24px;
  text-align: center;
  background-image: linear-gradient(#ffffff 0%, #ededed 100%);

  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: start;
    padding: 80px 54% 80px 80px;
    position: relative;
    min-height: 575px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }
`;

export const StyledSplashScreenLogo = styled('img')`
  width: 120px;
  height: 24px;
`;

export const StyledSplashScreenHeading = styled('h1')`
  margin: 0 0 12px;
  font-family: ${({ theme }) => theme.font.header};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;

  img {
    vertical-align: top;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    z-index: 2;
    position: relative;
  }
`;

export const StyledSplashScreenSubheading = styled('h2')`
  margin: 0 0 16px;
  font-family: ${({ theme }) => theme.font.header};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;

  strong {
    display: block;
    color: #007f39;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin: 8px 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 32px;
    line-height: 48px;
    z-index: 2;
    position: relative;

    strong {
      font-size: 56px;
      line-height: 56px;
    }
  }
`;

export const StyledSplashScreenDesc = styled('p')`
  font-size: 15px;
  font-family: ${({ theme }) => theme.font.main};
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 16px;
  color: ${({ theme }) => theme.color.typo.secondary};
  opacity: 0.7;

  ${({ theme }) => theme.breakpoints.up('md')} {
    z-index: 2;
    position: relative;
  }
`;

export const StyledSplashScreenPicture = styled('img')`
  width: 100%;
  display: block;
  margin-bottom: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute;
    top: 0;
    right: -60px;
    width: 750px;
    margin-bottom: 0;
    z-index: 1;
  }
  ${({ theme }) => theme.breakpoints.up('lr')} {
    right: 0;
  }
`;

export const StyledSplashScreenButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    z-index: 2;
    position: relative;
    align-self: start;
  }
`;
