function isReadyForSPA(url) {
  const notReadyPaths = [
    'account/deposit',
    'account/withdrawal',
    'account/reports',
    'account/step',
    'waiting-for-onboarding',
    'account/transactions/list/pdf',
    'pdf-export',
    'webtrade',
    'logout',
  ];

  return !notReadyPaths.some(
    (path) => url.includes(path) || document.location.pathname.includes(path),
  );
}

function prepareSPAUrl(url) {
  if (!url || url.includes('http') || isReadyForSPA(url)) {
    return url;
  }

  return document.location.origin + url;
}

export { isReadyForSPA, prepareSPAUrl };
