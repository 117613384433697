import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useData } from 'react-ui-kit-exante';
import { useDebouncedCallback } from 'use-debounce';

import { TranslationContext } from 'contexts/TranslationContext';
import { clearNumberString } from 'helpers/numbers';
import { transferService, TransferType } from 'services/transfer';
import { selectAccounts } from 'store/accounts';

import {
  getMappedCommissions,
  getRoundedCommissionWithRate,
  getRoundUp,
} from '../../../../Transfers.helpers';
import {
  AmountForm,
  Confirmation,
  TAmountFormProps,
} from '../../../../components';
import { useTransfers } from '../../../../hooks';
import { isInternalTransferShowNotAllowed } from '../../../../hooks/useTransfers/useTransfers.helpers';
import { FundsContext } from '../../context';

import { useAmount } from './hooks';

export const Amount = () => {
  const { selectedAccountId } = useSelector(selectAccounts);
  const { isLoading: isTransfersLoading, cashTransferTab } = useTransfers();
  const { currentLanguage } = useContext(TranslationContext);

  const {
    amount,
    limit,
    target,
    currency,
    iban,
    currencies,
    isLoading,
    isSubmitting,
    handleAmountChange,
    handleConfirm,
    handleCurrencyChange,
    handleSubmit,
    isConfirming,
    handleCloseConfirmForm,
    isAllFunds,
    handleAllfundsChange,
    balances,
    commissionAccount,
  } = useAmount();

  const { toBankAccountState, toBankAccountDispatch, toMyAccountState } =
    useContext(FundsContext);

  const { transfersState, commissionsState } = useTransfers();

  const withdrawalType = useMemo(() => {
    if (cashTransferTab === 'BANK') {
      return toBankAccountState.type;
    }

    if (cashTransferTab === 'MY_ACCOUNT') {
      return TransferType.ToMyAccount;
    }

    return TransferType.ToInternalAccount;
  }, [cashTransferTab, toBankAccountState.type]);

  const { data: withdrawalFormat, fetchData: fetchWithdrawalFormat } = useData({
    loading: false,
    onFetch: () =>
      transferService.getWithdrawalFormat({
        iban,
        currency,
        lang: currentLanguage,
        wr_type: withdrawalType,
        account: commissionAccount,
      }),
    onSuccess: (wrFormat) => {
      toBankAccountDispatch({
        type: 'SET_WITHDRAWAL_FORMAT',
        payload: wrFormat || '',
      });
    },
  });

  const otherFormatKey = useMemo(() => {
    if (withdrawalFormat) {
      // other key for serviceFee
      return `WR.${withdrawalType}.${withdrawalFormat}`;
    }

    return '';
  }, [withdrawalType, withdrawalFormat]);

  const debouncedGetWithdrawalFormat = useDebouncedCallback(() => {
    if (
      [TransferType.Wire, TransferType.WireThirdParty].includes(withdrawalType)
    ) {
      fetchWithdrawalFormat();
    }
  }, 1000);

  useEffect(() => {
    debouncedGetWithdrawalFormat();
  }, [withdrawalType, currency, iban, commissionAccount]);

  const skipCommission = useMemo(() => {
    if (withdrawalType === TransferType.ToMyAccount) {
      const selectedClientId = selectedAccountId?.split('.')[0] || '';
      const targetClientId =
        toMyAccountState.targetAccountId?.split('.')[0] || '';
      return selectedClientId === targetClientId;
    }
    return false;
  }, [withdrawalType, toMyAccountState.targetAccountId, selectedAccountId]);

  const mappedCommissions = useMemo(() => {
    if (skipCommission) {
      return null;
    }

    return getMappedCommissions(
      commissionsState,
      transfersState.commissions,
      withdrawalType,
      currency,
      otherFormatKey,
    );
  }, [
    transfersState.commissions,
    commissionsState,
    withdrawalType,
    otherFormatKey,
    currency,
    toBankAccountState.type,
    skipCommission,
  ]);

  const visibleValue = useMemo(() => {
    if (!mappedCommissions) {
      return '';
    }

    const { type, value, crossrates } = mappedCommissions;

    if (type === '%') {
      return `${value}${type}`;
    }

    const rate = crossrates?.[type];
    const valueWithRate = getRoundedCommissionWithRate(value, rate).toFixed(2);

    return `${type} ${clearNumberString(valueWithRate)}`;
  }, [mappedCommissions]);

  const getEffective = (
    value: string,
    selectedCurr: string,
    commissionCurr?: string,
  ) => {
    if (!mappedCommissions) {
      return 0;
    }

    const {
      type,
      value: commissionValue,
      crossrates,
      minimum,
      maximum,
    } = mappedCommissions;

    const crossValue =
      parseFloat(value) / parseFloat(crossrates?.[selectedCurr] || '1');

    const rate = crossrates?.[commissionCurr || selectedCurr];

    if (type === '%') {
      const computedCommissionWithoutCrossrate = Number(
        getRoundUp((commissionValue / 100) * parseFloat(value)).toFixed(2),
      );

      const computedCommissionCrossrate = Number(
        getRoundUp(
          (commissionValue / 100) * crossValue * parseFloat(rate || '1'),
        ).toFixed(2),
      );

      const roundedMinimum = getRoundedCommissionWithRate(minimum, rate);
      const roundedMaximum = getRoundedCommissionWithRate(maximum, rate);

      if (roundedMinimum && computedCommissionCrossrate < roundedMinimum) {
        return roundedMinimum;
      }
      if (roundedMaximum && computedCommissionCrossrate > roundedMaximum) {
        return roundedMaximum;
      }

      if (commissionCurr === selectedCurr) {
        return computedCommissionWithoutCrossrate;
      }

      return commissionCurr
        ? computedCommissionCrossrate
        : computedCommissionWithoutCrossrate;
    }

    if (
      (mappedCommissions.commissionCurrency === selectedCurr &&
        mappedCommissions.fixedCurrency) ||
      (commissionCurr && mappedCommissions.fixedCurrency)
    ) {
      return Number(
        getRoundUp(commissionValue * parseFloat(rate || '1')).toFixed(2),
      );
    }

    return getRoundedCommissionWithRate(commissionValue, rate);
  };

  const { min, max } = useMemo(() => {
    if (!mappedCommissions) {
      return { min: '0', max: '0' };
    }

    const rate =
      mappedCommissions.crossrates?.[
        mappedCommissions?.commissionCurrency || currency
      ];

    const minimum = getRoundedCommissionWithRate(
      mappedCommissions.minimum,
      rate,
    ).toFixed(2);

    const maximum = getRoundedCommissionWithRate(
      mappedCommissions.maximum,
      rate,
    ).toFixed(2);

    const formattedMinimum = clearNumberString(minimum);
    const formattedMaximum = clearNumberString(maximum);

    return {
      min: `${formattedMinimum} ${
        mappedCommissions?.commissionCurrency || currency
      }`,
      max: `${formattedMaximum} ${
        mappedCommissions?.commissionCurrency || currency
      }`,
    };
  }, [
    mappedCommissions,
    cashTransferTab,
    currency,
    commissionsState,
    mappedCommissions?.commissionCurrency,
  ]);

  const stdFee = useMemo(() => {
    if (!mappedCommissions) {
      return false;
    }

    return mappedCommissions.type !== '%';
  }, [mappedCommissions, cashTransferTab, commissionsState]);

  const feeProps = useMemo((): TAmountFormProps['fee'] => {
    const allFundsFee = getEffective(String(limit), currency);

    if (
      (isAllFunds
        ? !allFundsFee
        : Number.isNaN(getEffective(amount, currency))) ||
      !visibleValue ||
      !selectedAccountId
    ) {
      return {
        effective: 0,
        visibleValue: '',
        currency: '',
        allFunds: allFundsFee,
      };
    }

    return {
      effective: getEffective(amount, currency),
      effectiveText: getEffective(
        amount,
        currency,
        mappedCommissions?.commissionCurrency || currency,
      ),
      visibleValue,
      min,
      max,
      stdFee,
      currency,
      allFunds: allFundsFee,
      type: mappedCommissions?.type,
      isOldCommissions: mappedCommissions?.isOldCommissions,
    };
  }, [
    getEffective,
    limit,
    currency,
    amount,
    mappedCommissions?.commissionCurrency,
    visibleValue,
    cashTransferTab,
    min,
    max,
    stdFee,
  ]);

  const internalDisabled =
    isInternalTransferShowNotAllowed(transfersState) &&
    cashTransferTab === 'INTERNAL';

  const disabled = (!isAllFunds && !amount) || !target;

  if (isConfirming) {
    return (
      <Confirmation
        loading={isTransfersLoading}
        requesting={isSubmitting}
        onConfirm={handleConfirm}
        onCancel={handleCloseConfirmForm}
      />
    );
  }

  return (
    <AmountForm
      balances={balances}
      type={withdrawalType}
      noAmountCleanup
      noAmountDisabled
      formDisabled={internalDisabled}
      to={target}
      from={selectedAccountId}
      disabled={disabled}
      isLoading={isTransfersLoading || isLoading}
      isSubmitting={isSubmitting}
      amount={amount}
      currency={currency}
      commissionCurrency={mappedCommissions?.commissionCurrency || currency}
      currencies={currencies}
      onAmountChange={handleAmountChange}
      onCurrencyChange={handleCurrencyChange}
      isAllFunds={isAllFunds}
      onAllfundsChange={handleAllfundsChange}
      onSubmit={handleSubmit}
      limit={limit}
      fee={feeProps}
    />
  );
};
