import { cloneDeep } from 'lodash';
import { ChangeEvent, FC, useContext } from 'react';
import { Checkbox } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import { SubscriptionContext } from '../contexts/SubscriptionContext';

import { StyledLabel } from './PeriodicCheckboxCell.styled';
import { TPeriodicCheckboxCellProps } from './PeriodicCheckboxCell.types';

const xlsOnlyFields = ['short allowance', 'overnights', 'commissions'];
const noPerformanceReportFields = ['daily'];

type TColumnCheckboxes = 'daily' | 'monthly' | 'pdf' | 'csv' | 'xls';

export const PeriodicCheckboxCell: FC<TPeriodicCheckboxCellProps> = ({
  data,
  columnId,
  withLabel,
}) => {
  const { t } = useContext(TranslationContext);

  const { key } = data;
  const { subscriptions, updateSubscriptions, isSaving } =
    useContext(SubscriptionContext);
  const subscription = subscriptions?.find((sub) => sub.name === key);

  const currentValue = subscription
    ? subscription[columnId as TColumnCheckboxes]
    : false;

  const shouldBeVisible = () => {
    let result = true;

    if (columnId === 'monthly' && subscription) {
      result = result && subscription.is_monthly;
    }
    if (columnId === 'daily' && subscription) {
      result = result && subscription.is_daily;
    }

    if (key === 'performance' && noPerformanceReportFields.includes(columnId)) {
      return false;
    }
    if (columnId === 'xls') {
      return result && xlsOnlyFields.includes(key);
    }
    if (['pdf', 'csv'].includes(columnId)) {
      return result && !xlsOnlyFields.includes(key);
    }
    return result;
  };

  const onCheckboxChange = async (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (subscription && subscriptions) {
      const updatedSubs = cloneDeep(subscriptions);

      updatedSubs.forEach((sub) => {
        if (sub.name === key) {
          sub[columnId as TColumnCheckboxes] = checked;
        }
      });

      await updateSubscriptions(updatedSubs);
    }
  };

  if (!shouldBeVisible()) {
    return null;
  }

  return (
    <Checkbox
      checked={Boolean(currentValue)}
      onChange={onCheckboxChange}
      disabled={isSaving}
      label={
        withLabel ? (
          <StyledLabel className="Label">{t(columnId)}</StyledLabel>
        ) : (
          ''
        )
      }
    />
  );
};
