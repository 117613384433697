import { MAJOR_CURRENCIES } from 'constants/MAJOR_CURRENCIES';

export const sortCurrenciesArray = (currencies: string[]) => {
  const isMajorCurrency = (currency: string): boolean => {
    return MAJOR_CURRENCIES.includes(currency);
  };

  return [...currencies].sort((a: string, b: string) => {
    if (isMajorCurrency(a) && isMajorCurrency(b)) {
      return MAJOR_CURRENCIES.indexOf(a) < MAJOR_CURRENCIES.indexOf(b) ? -1 : 1;
    }

    if (isMajorCurrency(a)) {
      return -1;
    }

    if (isMajorCurrency(b)) {
      return 1;
    }

    return 0;
  });
};
