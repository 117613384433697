import { HTTP_PROTOCOL_REGEXP } from 'constants/REGEXP';
import { TPartnerLink } from 'services/partner';

import { PARTNER_TABS } from './Partner.constants';
import { TPartnerTabKey } from './Partner.types';

export const getPartnerInitialTab = (tab: string | null): TPartnerTabKey => {
  return (
    typeof tab === 'string' && tab in PARTNER_TABS
      ? tab
      : Object.keys(PARTNER_TABS)[0]
  ) as TPartnerTabKey;
};

const rebuildPartnerUrl = (url: string | null, params: Record<string, any>) => {
  if (!url) {
    return '';
  }

  if (!HTTP_PROTOCOL_REGEXP.test(url)) {
    url = `https://${url}`;
  }

  const parser = new URL(url);

  const query = {
    ...Object.fromEntries(new URLSearchParams(parser.search).entries()),
    ...params,
  };

  const searchParams = new URLSearchParams(parser.search);

  Object.entries(query).forEach(([key, value]) => {
    searchParams.append(key, value);
  });

  parser.search = searchParams.toString();
  parser.pathname = parser.pathname.split(/\/{2,}/).join('/');

  return parser.href;
};

export const preparePartnerUrl = (url: string | null): string => {
  if (!url) {
    return '';
  }

  // Remove protocol and last slash
  return url.replace(HTTP_PROTOCOL_REGEXP, '').replace(/\/$/, '');
};

export const getPartnerShortUrl = ({ site_url, id }: TPartnerLink): string => {
  try {
    if (!site_url) {
      return '';
    }

    if (!HTTP_PROTOCOL_REGEXP.test(site_url)) {
      site_url = `https://${site_url}`;
    }

    const url = new URL(site_url);
    url.search = '';
    url.pathname = `/p/${id}/`;
    return url.toString().replace(HTTP_PROTOCOL_REGEXP, '');
  } catch (e) {
    return '';
  }
};

export const getPartnerUtmUrl = ({
  site_url: siteUrl,
  url,
  id,
  name = '',
}: TPartnerLink): string => {
  return preparePartnerUrl(
    rebuildPartnerUrl(siteUrl, {
      utm_medium: 'affiliate',
      utm_campaign: name,
      utm_source: url ? encodeURIComponent(url) : '',
      utm_term: id,
    }),
  );
};
