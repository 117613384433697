import { styled } from 'theme';

export const StyledPartnerLinksActionsWrapper = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledActionWithConfirmationDeleteWrapper = styled('div')`
  .ActionWithConfirmationConfirmButton {
    color: ${({ theme }) => theme.color.typo.radical};
  }
`;
