import { TSharedActionProps } from '../../../../Mfa.types';
import { ActionEmailView } from '../../../ActionEmailView';

import { useEnableEmail } from './useEnableEmail';

export const EnableEmail = ({ onSuccess }: TSharedActionProps) => {
  const {
    code,
    email,
    enabled,
    isVerifying,
    seconds,
    verifyErrorMessage,
    handleCodeChange,
    handleResendClick,
  } = useEnableEmail(onSuccess);

  return (
    <ActionEmailView
      code={code}
      email={email}
      disabled={isVerifying || !enabled}
      message={verifyErrorMessage}
      onChange={handleCodeChange}
      onResend={handleResendClick}
      seconds={seconds}
    />
  );
};
