import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TTermsData, TTermsResponse } from './terms.types';

class TermsService extends ApiRequest {
  async getTermsInfo(lang: string): Promise<TTermsData | null> {
    try {
      const { data } = await this.fetch<TTermsResponse>({
        url: `/${lang}/clientsarea/spa-layout/?name=terms`,
      });

      return data.terms;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getTermsInfo error: ', error);
      return null;
    }
  }
}

export const termsService = new TermsService();
