import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAccordionSkeletonRow = styled('div')`
  display: flex;
  border: 1px solid ${({ theme }) => theme.color.bg.basic};
  border-radius: 4px;
  gap: 8px;
  padding: 16px;
`;

export const StyledAccordionIconSkeleton = styled(Skeleton)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

export const StyledAccordionTextSkeleton = styled(Skeleton)`
  width: 100%;
  height: 24px;
`;
