import { styled } from 'theme';

export const StyledAmountFormHintWrapper = styled('div')`
  display: flex;
  gap: 8px;

  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledAmountFormHintIconWrapper = styled('div')`
  width: 16px;
  color: ${({ theme }) => theme.color.icon.secondary};
`;

export const StyledAmountFormHintText = styled('span')`
  a {
    color: ${({ theme }) => theme.color.typo.action};
  }

  a:hover {
    color: ${({ theme }) => theme.color.typo.hover};
  }
`;
