import { FC } from 'react';

import {
  StyledActionsWrapper,
  StyledCardSettingsCardWrapper,
  StyledInfoBlocksWrapper,
} from 'pages/CardSettings/components/CardSettingsCard/CardSettingsCard.styled';
import { CardActionSkeleton } from 'pages/CardSettings/components/CardSettingsCard/components/CardAction/CardActionSkeleton';
import { CardInfoBlockSkeleton } from 'pages/CardSettings/components/CardSettingsCard/components/CardInfoBlock/CardInfoBlockSkeleton';

export const CardSettingsCardSkeleton: FC = () => (
  <StyledCardSettingsCardWrapper className="CardSettingsCardSkeleton">
    <StyledInfoBlocksWrapper className="InfoBlocksWrapperSkeleton">
      <CardInfoBlockSkeleton />
      <CardInfoBlockSkeleton />
      <CardInfoBlockSkeleton />
    </StyledInfoBlocksWrapper>
    <StyledActionsWrapper className="ActionsWrapperSkeleton">
      <CardActionSkeleton />
      <CardActionSkeleton />
    </StyledActionsWrapper>
  </StyledCardSettingsCardWrapper>
);
