import { FC, useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import { CalendarIcon, UserCircleIcon } from 'assets/icons';
import { BrandingContext, TranslationContext } from 'contexts';
import { CardSettingsContext } from 'pages/CardSettings/contexts';
import { CARD_STATUS, CARD_TYPE } from 'services/cards/cards.constants';

import {
  StyledActionsWrapper,
  StyledCardIcon,
  StyledCardSettingsCardWrapper,
  StyledInfoBlocksWrapper,
  StyledOrderedCardAction,
} from './CardSettingsCard.styled';
import { TCardSettingsCardProps } from './CardSettingsCard.types';
import {
  ActivateAction,
  BlockAction,
  CardInfoBlock,
  IssueAction,
  ShowPinAction,
} from './components';

export const CardSettingsCard: FC<TCardSettingsCardProps> = ({
  cardId,
  cardNumber,
  expirationDate,
  holderName,
  type,
  status,
}) => {
  const { t } = useContext(TranslationContext);
  const { onReissue, updateCards, isIssueAvailable } =
    useContext(CardSettingsContext);
  const [localStatus, setLocalStatus] =
    useState<TCardSettingsCardProps['status']>(status);
  const { branding, theme: brandingTheme } = useContext(BrandingContext);

  const isSupervisor = !!branding?.is_supervisor;

  const cardBlocked = status === CARD_STATUS.BLOCKED;
  const setCardActive = () => setLocalStatus(CARD_STATUS.ACTIVE);
  const issueVisible: boolean =
    localStatus === CARD_STATUS.BLOCKED && isIssueAvailable;

  const cardIconUrl =
    type === CARD_TYPE.PREMIUM
      ? brandingTheme?.images?.cards?.premium
      : brandingTheme?.images?.cards?.standart;

  const formattedCardNumber = `•••• ${cardNumber.slice(-4)}`;

  useEffect(() => {
    setLocalStatus(status);
  }, [status]);

  return (
    <StyledCardSettingsCardWrapper className="CardSettingsCard">
      <CardInfoBlock
        disabled={cardBlocked}
        className="CardType"
        hint={formattedCardNumber}
        icon={<StyledCardIcon imageUrl={cardIconUrl} className="CardIcon" />}
        title={t(type).toUpperCase()}
      />
      <StyledInfoBlocksWrapper className="InfoBlocksWrapper">
        <CardInfoBlock
          disabled={cardBlocked}
          className="CardholderName"
          hint={t('layout__cards__cardholder_name')}
          icon={<UserCircleIcon />}
          title={holderName.toUpperCase()}
        />
        <CardInfoBlock
          disabled={cardBlocked}
          className="ExpirationDate"
          hint={t('layout__cards__expiration_date')}
          icon={<CalendarIcon />}
          title={expirationDate}
        />
      </StyledInfoBlocksWrapper>
      <StyledActionsWrapper className="ActionsWrapper">
        {localStatus === CARD_STATUS.REQUESTED && (
          <Tooltip
            placement="top"
            title={t('layout__cards__message_after_order')}
          >
            <StyledOrderedCardAction
              iconName="CircleCheckIcon"
              label={t('layout__cards__order_success')}
              disabled
            />
          </Tooltip>
        )}
        {localStatus === CARD_STATUS.ACTIVE && !isSupervisor && (
          <>
            <ShowPinAction cardId={cardId} />
            <BlockAction cardId={cardId} onBlock={updateCards} />
          </>
        )}
        {localStatus === CARD_STATUS.INACTIVE && !isSupervisor && (
          <ActivateAction cardId={cardId} onActivate={setCardActive} />
        )}
        {issueVisible && <IssueAction reissue onClick={onReissue} />}
      </StyledActionsWrapper>
    </StyledCardSettingsCardWrapper>
  );
};
