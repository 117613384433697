import { format, isValid } from 'date-fns';

import { DATE_FORMAT } from 'helpers/dates';

export const prepareTableParamsDates = (
  payload: Record<string, any>,
  additionalFieldsList?: string[],
) => {
  return Object.keys(payload).reduce((acc: Record<string, unknown>, key) => {
    if (key.includes('timestamp')) {
      const [first, second] = payload[key] as string[];

      const prepareDate = (dateStr: string) => {
        if (isValid(dateStr)) {
          const date = new Date(dateStr);
          date.setHours(0, 0, 0, 0);
          return date.toISOString();
        }

        return '';
      };

      const firstDate = prepareDate(first);
      const secondDate = prepareDate(second);

      return {
        ...acc,
        [key]: `${firstDate},${secondDate}`,
      };
    }

    if (key.includes('date') || additionalFieldsList?.includes(key)) {
      const dates = payload[key] as string;

      if (Array.isArray(dates)) {
        return {
          ...acc,
          [key]: dates.map((date) =>
            isValid(date) ? format(new Date(date), DATE_FORMAT) : '',
          ),
        };
      }

      return {
        ...acc,
        [key]: isValid(dates) ? format(new Date(dates), DATE_FORMAT) : '',
      };
    }
    return { ...acc, [key]: payload[key] };
  }, {});
};
