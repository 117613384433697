import { FC, useContext, useMemo } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import {
  GenericTransferFormBlock,
  GenericTransferFormTitle,
} from 'components/GenericTransferForm';
import { INTERPOLATION } from 'constants/INTERPOLATION';
import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { TranslationContext } from 'contexts';
import {
  StyledExchangeSummaryButton,
  StyledExchangeSummaryRow,
  StyledExchangeSummaryWarning,
} from 'pages/Portfolio/tabs/Exchange/components/ExchangeSummaryBlock/ExchangeSummaryBlock.styled';
import { ExchangeContext } from 'pages/Portfolio/tabs/Exchange/contexts';
import { EXCHANGE_MIN_TRANSFER_VALUE } from 'pages/Portfolio/tabs/Exchange/contexts/ExchangeContext/ExchangeContext.constants';

export const ExchangeSummaryBlock: FC = () => {
  const { t } = useContext(TranslationContext);
  const {
    expectedAmount,
    targetCurrency,
    crossrate,
    crossrates,
    currency,
    amount,
    isConversionAvailable,
    convert,
    commission,
    isCommissionLoading,
  } = useContext(ExchangeContext);

  const visibleExpectedAmount =
    expectedAmount && targetCurrency
      ? `≈ ${expectedAmount} ${targetCurrency}`
      : DEFAULT_EMPTY_PLACEHOLDER;

  const visibleCrossrate =
    commission && crossrate
      ? `≈ ${((1 - parseFloat(commission)) * parseFloat(crossrate)).toFixed(4)}`
      : DEFAULT_EMPTY_PLACEHOLDER;

  const errorReason = useMemo<string>(() => {
    if (currency && targetCurrency) {
      if (currency === targetCurrency) {
        return t('layout__exchange__summary__request_error__currency_the_same');
      }

      if (
        crossrates &&
        (!(targetCurrency in crossrates) || !(currency in crossrates))
      ) {
        return t('layout__exchange__summary__request_error__default_error');
      }

      if (crossrates && !isCommissionLoading && commission === null) {
        return t('layout__exchange__summary__request_error__default_error');
      }
    }

    if (amount && amount <= EXCHANGE_MIN_TRANSFER_VALUE) {
      return t(
        'layout__exchange__summary__request_error__min_value_triggered',
        {
          replace: {
            MIN_AMOUNT: `5 ${currency}`,
          },
          interpolation: {
            prefix: INTERPOLATION.LEGACY.CURLY.BEFORE,
            suffix: INTERPOLATION.LEGACY.CURLY.AFTER,
          },
        },
      );
    }

    if (!crossrate) {
      return t('layout__exchange__summary__request_error__default_error');
    }

    return '';
  }, [
    currency,
    targetCurrency,
    amount,
    crossrate,
    isCommissionLoading,
    commission,
  ]);

  return (
    <GenericTransferFormBlock className="ExchangeSummaryBlock">
      <GenericTransferFormTitle
        className="ExchangeSummaryTitle"
        hint={t('layout__exchange__summary__crossrate_diff_notification')}
      >
        {t('layout__exchange__summary__title')}
      </GenericTransferFormTitle>
      <StyledExchangeSummaryRow className="ExchangeSummaryExpectedAmount">
        <dt>{t('layout__exchange__summary_amount')}</dt>
        <dd>{visibleExpectedAmount}</dd>
      </StyledExchangeSummaryRow>
      <StyledExchangeSummaryRow className="ExchangeSummaryCrossrate">
        <dt>{t('layout__exchange__summary_rate')}</dt>
        {crossrate == null || isCommissionLoading ? (
          <Skeleton width={40} height={24} />
        ) : (
          <dd>{visibleCrossrate}</dd>
        )}
      </StyledExchangeSummaryRow>
      <StyledExchangeSummaryButton
        className="ExchangeSummaryActionButton"
        fullWidth
        disabled={!isConversionAvailable}
        onClick={convert}
      >
        {t('layout__exchange__convert')}
      </StyledExchangeSummaryButton>
      <StyledExchangeSummaryWarning className="ExchangeSummaryError">
        {errorReason}
      </StyledExchangeSummaryWarning>
    </GenericTransferFormBlock>
  );
};
