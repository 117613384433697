import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';

import { ActionTooltip } from '../ActionTooltip';
import { TMfaTooltipProps } from '../TotpTooltip/TotpTooltip.types';

import { DisableEmail } from './components/DisableEmail';
import { EnableEmail } from './components/EnableEmail';

export const EmailTooltip: FC<TMfaTooltipProps> = ({
  isActive,
  children,
  open,
  onClose,
  onSuccess,
}) => {
  const { t } = useContext(TranslationContext);

  return !isActive ? (
    <ActionTooltip
      title={t('layout__security_tabs__mfa_modal_email_activate_header')}
      open={open}
      onClose={onClose}
      body={<EnableEmail onSuccess={onSuccess} />}
    >
      {children}
    </ActionTooltip>
  ) : (
    <ActionTooltip
      title={t('layout__security_tabs__mfa_modal_email_deactivate_header')}
      open={open}
      onClose={onClose}
      body={<DisableEmail onSuccess={onSuccess} />}
    >
      {children}
    </ActionTooltip>
  );
};
