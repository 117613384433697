import { styled } from 'theme';

export const AssetTitleWrapper = styled('span')`
  display: inline-flex;
  align-items: center;

  .AssetTitleIcon {
    margin-right: 8px;
    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-right: 16px;
    }
  }
`;

export const AssetTitleStyled = styled('span')`
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.primary};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
  }
`;
