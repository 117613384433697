import { ChangeEvent, FC, useContext, useState } from 'react';
import { Input, useData } from 'react-ui-kit-exante';

import { ActivateCardIcon } from 'assets/icons';
import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { TranslationContext } from 'contexts';
import {
  TooltipContent,
  TTooltipContentButton,
} from 'pages/CardSettings/components/CardSettingsCard/components/TooltipContent';
import { cardService, TCard } from 'services/cards';
import { CARD_STATUS } from 'services/cards/cards.constants';

import { PinTooltip } from '../PinTooltip';

import {
  StyledActivateCardAction,
  StyledActivateModalDescription,
} from './ActivateAction.styled';
import { TActivateActionProps } from './ActivateAction.types';

export const ActivateAction: FC<TActivateActionProps> = ({
  cardId,
  onActivate,
}) => {
  const { t, currentLanguage } = useContext(TranslationContext);

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [cvv, setCvv] = useState('');
  const [cvvInvalid, setCvvInvalid] = useState<boolean>(false);
  const [cardStatus, setCardStatus] = useState<TCard['status']>(
    CARD_STATUS.INACTIVE,
  );

  const showTooltip = () => {
    setTooltipVisible(true);
  };
  const hideTooltip = () => {
    setCvv('');
    setCvvInvalid(false);
    setTooltipVisible(false);
  };
  const onCvvInput = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (val.length <= 3) {
      setCvv(val);
    }
  };
  const confirmButtonDisabled: boolean = cvv.length !== 3;

  const { isLoading: isActivating, fetchData: activateCard } = useData({
    onFetch: () => {
      setCvvInvalid(false);
      return cardService.activateCard({ lang: currentLanguage, cardId, cvv });
    },
    onSuccess: (value) => {
      if (!value) {
        setCvvInvalid(true);
      } else {
        onActivate?.();
        setCardStatus(CARD_STATUS.ACTIVE);
      }
    },
    loading: false,
  });

  const cvvInvalidMessage = cvvInvalid
    ? t('layout__cards__incorrect__cvv')
    : undefined;

  const cancelButton: TTooltipContentButton = {
    label: t('generic__action__cancel'),
    handler: hideTooltip,
  };

  const confirmButton: TTooltipContentButton = {
    label: t('generic__action__confirm'),
    disabled: confirmButtonDisabled || isActivating,
    handler: activateCard,
    actionStyle: true,
  };

  const tooltip =
    cardStatus === CARD_STATUS.ACTIVE ? (
      <PinTooltip activation cardId={cardId} onClose={hideTooltip} />
    ) : (
      <TooltipContent
        icon={<ActivateCardIcon />}
        title={t('layout__cards__card_activation')}
        cancel={cancelButton}
        confirm={confirmButton}
      >
        <StyledActivateModalDescription className="ActivateModalDescription">
          {t('layout__cards__insert_cvv')}
        </StyledActivateModalDescription>
        <Input
          value={cvv}
          onInput={onCvvInput}
          placeholder={t('layout__cards__enter_cvv')}
          type="password"
          name="cvv"
          autoComplete="off"
          required
          maxLength={3}
          error={cvvInvalid}
          message={cvvInvalidMessage}
        />
      </TooltipContent>
    );

  return (
    <ActionWithConfirmation
      content={tooltip}
      externalOpened={tooltipVisible}
      closeHandler={hideTooltip}
      openFromExternal
      hideDefaultButtons
    >
      <StyledActivateCardAction
        iconName="ActivateCardIcon"
        label={t('layout__cards__activate_card')}
        onClick={showTooltip}
      />
    </ActionWithConfirmation>
  );
};
