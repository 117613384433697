import { startOfMonth } from 'date-fns';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  InputDatePicker,
  ToggleButtonGroup,
  useData,
} from 'react-ui-kit-exante';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { getChartColors } from 'components/Chart/Chart.constants';
import { getYWidth } from 'components/Chart/Chart.helpers';
import { ChartSkeleton } from 'components/Chart/ChartSkeleton';
import { CustomTooltip } from 'components/Chart/CustomTooltip';
import { CurrencyContext, TranslationContext } from 'contexts';
import { Dates, today } from 'helpers/dates';
import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { accountsService, TAccountNavReportResponse } from 'services/accounts';
import { performanceService, TTimeRangesTypes } from 'services/performance';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { useTheme } from 'theme';

import {
  StyledBalance,
  StyledBalanceActions,
  StyledBalanceCalendars,
  StyledChartWrapper,
} from './Balance.styled';
import { TChartData, TDateRange } from './Balance.types';

export const Balance: FC = () => {
  const theme = useTheme();
  const { t, isTranslationsLoading, currentLanguage } =
    useContext(TranslationContext);
  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter();
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const { currency } = useContext(CurrencyContext);

  //#region ChartFilters
  const {
    data: performanceConfig,
    isLoading: isPerformanceLoading,
    fetchData: fetchPerformanceConfig,
  } = useData({
    onFetch: () => performanceService.getPerformanceConfig(currentLanguage),
  });

  useEffect(() => {
    fetchPerformanceConfig();
  }, [isTranslationsLoading]);

  const preparedTimeRangesOptions = () => {
    const ranges = performanceConfig?.timeRanges;
    return Object.values(ranges || {}).map(({ title }, index) => ({
      label: title,
      value: Object.keys(ranges || {})[index],
    }));
  };

  const dateToggleRangeOptions = preparedTimeRangesOptions();
  const defaultToggleRangeKey: TTimeRangesTypes = 'current-month';
  const timeRanges = performanceConfig?.timeRanges;
  const initialDateRange: TDateRange = {
    dateTo: today,
    dateFrom: startOfMonth(today),
  };
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [dateToggleFilter, setDateToggleFilter] = useState<
    TTimeRangesTypes | ''
  >(defaultToggleRangeKey);

  const onDateFilterChange = (value: any) => {
    setDateToggleFilter(value);
    const range = timeRanges?.[value as TTimeRangesTypes];
    if (range) {
      setDateRange({
        dateFrom: new Date(range.date_from),
        dateTo: new Date(range.date_to),
      });
    }
  };

  const changeDateFrom = (date: Date | null) => {
    if (date) {
      setDateRange({
        ...dateRange,
        dateFrom: date,
      });
    }
  };

  const changeDateTo = (date: Date | null) => {
    if (date) {
      setDateRange({
        ...dateRange,
        dateTo: date,
      });
    }
  };
  //#endregion

  //#region Chart
  const {
    data: accountBalance,
    fetchData: fetchAccountBalance,
    isLoading: isAccountBalanceLoading,
  } = useData<TAccountNavReportResponse | null>({
    onFetch: () => {
      if (Dates.format(dateRange.dateTo) && selectedAccountId !== null) {
        return accountsService.getAccountNavReport(
          selectedAccountId,
          currency,
          Dates.format(dateRange.dateFrom),
          Dates.format(dateRange.dateTo),
          currentLanguage,
        );
      }
      return Promise.resolve(null);
    },
  });

  useEffect(() => {
    fetchAccountBalance();
  }, [selectedAccountId, currency, dateRange.dateTo, dateRange.dateFrom]);

  const metrics = accountBalance?.metrics || {};

  const chartData = useMemo<TChartData>(() => {
    return Object.keys(metrics || {})
      .sort(
        (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime(),
      )
      .map((date) => ({
        date: Dates.format(new Date(date), false),
        balance: Number(metrics[date].nav),
      }));
  }, [metrics]);

  const chartColors = getChartColors(theme);
  const yWidth = getYWidth(chartData, ['balance']);
  //#endregion

  const isDataLoading: boolean =
    isAccountBalanceLoading || isTranslationsLoading || isPerformanceLoading;
  const isAccountExists = !!Object.keys(metrics).length;
  const isZeroPeriod =
    isAccountExists &&
    !Object.values(metrics).some((metric) => parseFloat(metric.nav) !== 0);
  const chartVisible: boolean =
    !isDataLoading && !(!isAccountExists || isZeroPeriod);

  return (
    <StyledBalance className="Balance">
      <StyledBalanceActions className="BalanceActions">
        <ToggleButtonGroup
          options={dateToggleRangeOptions}
          exclusive
          value={dateToggleFilter}
          onChange={onDateFilterChange}
          disabled={isPerformanceLoading}
          showSkeleton={isDataLoading}
          skeletonsCount={4}
          size="small"
          data-test-id="balance__daterange-toggle"
        />
        <StyledBalanceCalendars className="BalanceCalendars">
          <InputDatePicker
            locale={currentLanguage}
            inputProps={{
              size: 'small',
              sx: { width: 170 },
            }}
            onChange={changeDateFrom}
            selected={dateRange.dateFrom}
            maxDate={today}
            disabled={isDataLoading}
            autoComplete="off"
            selectsStart
            startDate={dateRange.dateFrom}
            endDate={dateRange.dateTo}
            data-test-id="balance__date-from"
          />
          <InputDatePicker
            inputProps={{
              size: 'small',
              sx: { width: 170 },
            }}
            onChange={changeDateTo}
            selected={dateRange.dateTo}
            minDate={dateRange.dateFrom}
            maxDate={today}
            locale={currentLanguage}
            disabled={isDataLoading}
            autoComplete="off"
            selectsEnd
            startDate={dateRange.dateFrom}
            endDate={dateRange.dateTo}
            data-test-id="balance__date-to"
          />
        </StyledBalanceCalendars>
      </StyledBalanceActions>
      {!isDataLoading &&
        !isAccountExists &&
        t('generic__account__history_unavailable_period')}
      {!isDataLoading && isZeroPeriod && t('generic__account__empty_period')}
      {(isDataLoading || chartVisible) && (
        <StyledChartWrapper className="ChartWrapper">
          {isDataLoading && <ChartSkeleton />}
          {chartVisible && (
            <ResponsiveContainer minWidth={theme?.breakpoints?.values?.sm}>
              <AreaChart data={chartData}>
                <CartesianGrid />
                <Area
                  type="monotone"
                  dataKey="balance"
                  stroke={chartColors.positive}
                  fill={chartColors.positive}
                  dot={<span />}
                />
                <XAxis
                  dataKey="date"
                  minTickGap={12}
                  padding={{ left: 10 }}
                  interval="preserveStartEnd"
                  tickFormatter={dateFormatter}
                />
                <YAxis
                  orientation="right"
                  width={yWidth}
                  minTickGap={5}
                  tickCount={10}
                  tickFormatter={(value) =>
                    formatCurrency(currencyFormatter, value, true)
                  }
                />
                <Tooltip
                  content={<CustomTooltip />}
                  wrapperStyle={{ outline: 'none' }}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </StyledChartWrapper>
      )}
    </StyledBalance>
  );
};
