import { FC, useContext, useEffect } from 'react';
import { Loader, Panel, useData } from 'react-ui-kit-exante';

import { PageWrapper } from 'components/PageWrapper';
import { TranslationContext } from 'contexts';
import { challengeService } from 'services/challenge';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

export const ChallengeRules: FC = () => {
  const { t, templateT } = useContext(TranslationContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const {
    data: rules,
    fetchData: fetchChallengeRules,
    isLoading,
  } = useData({
    onlyLastRequest: true,
    onFetch: async () => {
      if (selectedAccountId !== null) {
        const data =
          await challengeService.getChallengeRules(selectedAccountId);
        return data?.content;
      }
      return null;
    },
  });

  useEffect(() => {
    fetchChallengeRules();
  }, [selectedAccountId]);

  return (
    <PageWrapper className="ChallengeRulesPageWrapper">
      <Panel
        title={t('page__challenge_rules__title')}
        className="ChallengeRulesPagePanel"
      >
        {isLoading && <Loader />}
        {!isLoading && rules && (
          <div
            className="ChallengeRulesPageContent"
            dangerouslySetInnerHTML={{ __html: templateT(rules) }}
          />
        )}
        {!isLoading && !rules && (
          <p
            dangerouslySetInnerHTML={{
              __html: t('page__challenge_rules__no_rules', {
                LINK: '/clientsarea/account/challenge_purchases/',
              }),
            }}
          />
        )}
      </Panel>
    </PageWrapper>
  );
};
