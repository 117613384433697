export const formatAmount = (
  value: number,
  options?: TFormatAmountOptions,
): string => {
  const { precision, decimalSeparator, thousandSeparator } = options || {};
  let result = String(value);

  if (Number.isNaN(value)) {
    return result;
  }

  if (value === 0) {
    return '0.0';
  }

  if (precision && precision >= 0) {
    result = value.toFixed(precision);
  }

  // локаль xh-ZA использует пробелы и точку в качестве разделителей
  result = value.toLocaleString('xh-ZA');

  if (thousandSeparator) {
    result = result.replace(/ /g, thousandSeparator);
  }

  if (decimalSeparator) {
    result = result.replace('.', decimalSeparator);
  }

  return result;
};

export type TFormatAmountOptions = {
  precision?: number;
  thousandSeparator?: string;
  decimalSeparator?: string;
};

export const formatIntegerFloatNumber = (value: number) => {
  if (Number.isInteger(value)) {
    return value;
  }
  return value.toFixed(1);
};
