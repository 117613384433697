import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledDocumentsUploadGroupsSkeleton = styled('div')`
  display: flex;

  flex-direction: column;
  gap: 24px;
`;

export const StyledDocumentsHeadingSkeleton = styled(Skeleton)`
  width: 166px;
  height: 32px;
  margin-bottom: 32px;
`;
