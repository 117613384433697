import { createContext, FC, useContext, useEffect, useMemo } from 'react';
import {
  Notification as UiKitNotification,
  useData,
} from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { settingsService, TProfileSettings } from 'services/settings';
import { TChildren } from 'types/TChildren';

type TSettingsDataContext = {
  isLoading: boolean;
  settingsData: TProfileSettings | null;
};

const initialState: TSettingsDataContext = {
  isLoading: false,
  settingsData: null,
};

export const SettingsDataContext =
  createContext<TSettingsDataContext>(initialState);

export const SettingsDataProvider: FC<TChildren> = ({ children }) => {
  const { currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const getSettingsData = async () => {
    try {
      return await settingsService.getProfileSettings(currentLanguage);
    } catch (error: any) {
      if (error?.message) {
        UiKitNotification.error({ title: error?.message });
      }

      return null;
    }
  };

  const {
    fetchData: fetchSettingsData,
    data: settingsData,
    isLoading: isSettingsDataLoading,
  } = useData({ onFetch: getSettingsData });

  useEffect(() => {
    fetchSettingsData();
  }, [currentLanguage]);

  const isLoading = isSettingsDataLoading || isTranslationsLoading;

  const value = useMemo(
    () => ({
      isLoading,
      settingsData,
    }),
    [isLoading, settingsData],
  );

  return (
    <SettingsDataContext.Provider value={value}>
      {children}
    </SettingsDataContext.Provider>
  );
};
