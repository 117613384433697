import { createSlice } from '@reduxjs/toolkit';

import { initialFetchState } from 'constants/state';
import {
  fetchMetadataState,
  fetchWithdrawalState,
} from 'store/withdrawal/withdrawal.actions';

import {
  initialMetadataState,
  initialWithdrawalState,
} from './withdrawal.constants';

export const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState: {
    withdrawalState: initialWithdrawalState,
    metadataState: initialMetadataState,

    fetchWithdrawalState: initialFetchState,
    fetchMetadataState: initialFetchState,
    fetchMetadataAccount: initialFetchState,
  },

  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchWithdrawalState.pending, (state) => {
        return {
          ...state,
          ...initialWithdrawalState,
          fetchWithdrawalState: {
            ...state.fetchWithdrawalState,
            idle: false,
            errorMessage: '',
            error: false,
            pending: true,
          },
        };
      })
      .addCase(fetchMetadataState.pending, (state) => {
        return {
          ...state,
          metadataState: initialMetadataState,
          fetchMetadataState: {
            ...state.fetchMetadataState,
            idle: false,
            errorMessage: '',
            error: false,
            pending: true,
          },
        };
      })
      .addCase(fetchWithdrawalState.fulfilled, (state, action) => {
        return {
          ...state,
          fetchWithdrawalState: {
            ...state.fetchWithdrawalState,
            idle: false,
            pending: false,
            succeed: true,
          },
          withdrawalState: action.payload || initialWithdrawalState,
        };
      })
      .addCase(fetchMetadataState.fulfilled, (state, action) => {
        return {
          ...state,
          fetchMetadataState: {
            ...state.fetchMetadataState,
            idle: false,
            pending: false,
            succeed: true,
          },
          metadataState: action.payload || initialMetadataState,
        };
      }),
});
