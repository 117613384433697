import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledApplicationSkeletonContainer = styled(Panel)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const StyledApplicationSkeletonHeader = styled('div')`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
  padding: 0 24px;
`;

export const StyledApplicationSkeletonTitle = styled('div')`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
  padding: 24px 0;
  gap: 24px;
`;

export const StyledApplicationSkeletonTags = styled('div')`
  display: flex;
  gap: 24px;
`;

export const StyledApplicationSkeletonKeys = styled('div')`
  display: flex;
  gap: 24px;
  padding: 24px 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: space-between;
  }
`;

export const StyledApplicationSkeletonKeysItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    &:last-child {
      align-items: flex-end;
    }
  }
`;

export const StyledApplicationSkeletonPermissions = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
`;

export const StyledApplicationSkeletonPermissionsItems = styled('div')`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StyledApplicationSkeletonPermissionsItem = styled('div')`
  display: flex;
  gap: 8px;
`;

export const StyledApplicationSkeletonFooter = styled('div')`
  display: flex;
  padding: 24px;
`;
