import { styled } from 'theme';

export const StyledChallengeAccountSummaryInfoItem = styled('dl')`
  margin: 0;
  padding: 20px 10px;
  background: ${({ theme }) => theme.color.bg.source};
  text-align: center;
  border-radius: 15px;
  font-size: 18px;
  line-height: 20px;
`;

export const StyledChallengeAccountSummaryInfoItemLabel = styled('dt')`
  font-weight: bold;
  margin-bottom: 7px;
`;

export const StyledChallengeAccountSummaryInfoItemValue = styled('dd')`
  margin: 0;
  padding: 0;
`;
