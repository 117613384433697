export const DEFAULT_LOCALE = 'en-GB';
export const DEFAULT_EMPTY_PLACEHOLDER = '—';
export const DEFAULT_CURRENCY = 'EUR';
export const DEFAULT_CURRENCY_CRYPTO = 'BTC';
export const PDF = 'pdf';
export const XLS = 'xls';
export const DEFAULT_MAX_ACCOUNTS_LENGTH = 20;
export const OPTIMIZABLE_ACCOUNTS_LENGTH = 10_000;
export const DEFAULT_MAX_PAGINATION_LENGTH = 20;
export const WHITESPACE_NON_BREAKING = ' ';
export const MAX_AUTOCOMPLETE_LENGTH = 1_000;
export const INPUT_DEBOUNCE_DELAY = 500;
export const INPUT_DEBOUNCE_DELAY_LONG = 2_000;
export const VERSION_CHECK_INTERVAL = 60_000;
export const RELOAD_TIMEOUT = 30;
export const LAST_LOGIN_BTN_CLICK_KEY = 'last-login-btn-click';
