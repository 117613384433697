import { useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, FC, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Input,
  Notification as UiKitNotification,
} from 'react-ui-kit-exante';

import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { PATHS } from 'router/router.constants';
import { loginService } from 'services/login';

import {
  StyledLoginHeading,
  StyledLoginHint,
  StyledLoginLogo,
} from '../../Login.styled';
import { StyledSigninWrapper } from '../Signin/Signin.styled';

import { RecoveryRequestSkeleton } from './components/RecoveryRequestSkeleton';

export const RecoveryRequest: FC = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();

  const navigatedFromLogin = location.state?.fromLogin;

  const isAuthOnly = !branding?.signup_available;

  const loginButtonCaption = t(
    branding?.login_wording || 'generic__action__login',
  );

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  if (isTranslationsLoading) {
    return <RecoveryRequestSkeleton />;
  }

  const handleBack = () => {
    navigate(PATHS.LOGIN);
  };

  const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    setError('');
    setIsLoading(true);
    setIsSuccess(false);

    const response = await loginService.requestPasswordRecovery(
      email,
      currentLanguage,
    );

    if (response?.result === 'error') {
      setError(response?.message);
    }

    if (response?.result === 'success') {
      UiKitNotification.success({
        title: response?.message,
      });

      setIsSuccess(true);
    }

    if (response?.result === 'ratelimit') {
      UiKitNotification.warning({
        title: t('auth__recovery__too_many_requests'),
      });

      setIsSuccess(false);
    }

    setIsLoading(false);
  };

  return (
    <StyledSigninWrapper className="SigninWrapper">
      {isDesktop && <StyledLoginLogo className="LoginLogo" />}

      <StyledLoginHeading className="LoginHeading">
        {t('layout__login__forgot_password')}
      </StyledLoginHeading>

      {isSuccess ? (
        <StyledLoginHint className="LoginHint">
          {t('layout__auth__we_emailed_instructions')}
        </StyledLoginHint>
      ) : (
        <>
          <StyledLoginHint className="LoginHint">
            {t('layout__login__password_hint')}
          </StyledLoginHint>

          <Input
            type="text"
            name="email"
            value={email}
            onChange={handleEmailInputChange}
            placeholder={t(
              isAuthOnly ? 'generic__action__login' : 'generic__email_or_login',
            )}
            error={Boolean(error)}
            message={error}
            fullWidth
            disabled={isLoading}
          />

          <Button
            color="primary"
            onClick={handleSubmit}
            type="submit"
            loading={isLoading}
            fullWidth
          >
            {t('generic__action__send')}
          </Button>
        </>
      )}

      <Button
        color="secondary"
        onClick={handleBack}
        type="reset"
        disabled={isLoading}
        fullWidth
      >
        {navigatedFromLogin ? t('generic__action__back') : loginButtonCaption}
      </Button>
    </StyledSigninWrapper>
  );
};
