import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledListItemIconSkeleton = styled(Skeleton)`
  width: 16px;
  height: 16px;
`;

export const StyledListItemTextSkeleton = styled(Skeleton)`
  width: 140px;
  height: 16px;
`;
