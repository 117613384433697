import { EStatus } from 'types/api';

import { TAccessRightsState } from './AccessRights.types';

export const accessRightsInitialState: TAccessRightsState = {
  fetchStatus: EStatus.Loading,
  hasPagination: false,
  pageCount: 0,
  pageIndex: 0,
  pageSize: 20,
  total: 0,
  data: [],
};
