import { IconButton, Panel, Tab, Table, Tabs } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledCorporateActionsListPanel = styled(Panel)`
  margin: 0 18px;
  padding: 0;

  .PanelTitle {
    margin: 24px 24px 16px 24px;
  }
`;

export const StyledCorporateActionsListTabs = styled(Tabs)`
  margin: 0 24px;

  .MuiTab-root {
    display: inline-flex !important;
    flex-direction: row;
    gap: 8px;
  }
`;

export const StyledCorporateActionsListTab = styled(Tab)`
  display: flex;

  &.Mui-selected {
    svg {
      color: ${({ theme }) => theme.color.icon.primary};
    }
  }
`;

export const StyledCorporateActionsListTabIcon = styled(IconButton)`
  color: ${({ theme }) => theme.color.icon.action};

  svg {
    color: ${({ theme }) => theme.color.icon.action};

    &:hover {
      color: ${({ theme }) => theme.color.icon.action};
    }
  }
`;

export const StyledCorporateActionsListTable = styled(Table)`
  > header {
    padding: 24px 54px 16px 24px;

    > div > button {
      min-width: 24px;
      height: 24px;
    }
  }

  .ColumnSelect {
    position: absolute;
    top: 22px;
    right: 20px;
  }
` as typeof Table;
