import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData, Skeleton } from 'react-ui-kit-exante';

import { BrandingContext, TranslationContext } from 'contexts';
import { PATHS, WIZARD_PAGE } from 'router';
import { productsService } from 'services/settings/products/products';
import { ProductAliases } from 'services/settings/products/products.constants';
import {
  TProduct,
  TProductsResponse,
} from 'services/settings/products/products.types';
import { useAppDispatch } from 'store/hooks';
import { fetchMenu } from 'store/menu';

import {
  StyledDescription,
  StyledHeader,
  StyledProductInfoSkeleton,
  StyledProducts,
  StyledServicesContainer,
  StyledSkeletonContainer,
  StyledSkeletonProduct,
  StyledSkeletonToggle,
} from './Services.styled';
import { ProductItem } from './components/ProductItem';

export const Services = () => {
  const dispatch = useAppDispatch();
  const { t, currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const navigate = useNavigate();

  const [updatingProductAlias, setUpdatingProductAlias] = useState<
    string | null
  >(null);
  const [updatedProducts, setUpdatedProducts] =
    useState<TProductsResponse | null>(null);
  const productsText = t('layout__settings__services__products', {
    EXANTE: branding?.wl_name,
  });

  const {
    data: products,
    fetchData: fetchProducts,
    isLoading: productsIsLoading,
  } = useData({
    onFetch: async () => productsService.getProducts(currentLanguage),
  });

  useEffect(() => {
    fetchProducts();
  }, [currentLanguage]);

  const visibleProducts = (updatedProducts || products)?.filter((product) => {
    const isProductShow = !product.hidden;

    if (product.alias === 'residential program') {
      return (
        isProductShow && (product.is_approved_or_on_review || product.done)
      );
    }

    return isProductShow;
  });

  const isShowProducts =
    visibleProducts && visibleProducts.length > 0 && !productsIsLoading;

  const onToggleProduct = async (product: TProduct, enabled: boolean) => {
    setUpdatingProductAlias(product.alias);
    let response;
    if (enabled) {
      if (
        product.alias === ProductAliases.PARTNERSHIP &&
        branding?.branding?.partner_kyc_enabled
      ) {
        navigate(`${PATHS.ROOT}${PATHS.PARTNER_KYC}`);
        return;
      }
      response = await productsService.updateProducts(
        product.alias,
        'POST',
        currentLanguage,
      );
    } else {
      response = await productsService.updateProducts(
        product.alias,
        'DELETE',
        currentLanguage,
      );
    }
    if (response) {
      const someNotFinished = response.reduce((prev, current) => {
        return prev || (current.enabled && !current.is_approved_or_on_review);
      }, false);
      if (enabled && someNotFinished) {
        window.location.href = WIZARD_PAGE;
        return;
      }
      setUpdatedProducts(response);
      dispatch(fetchMenu());
    }
    setUpdatingProductAlias(null);
  };

  return (
    <StyledServicesContainer className="ServicesContainer">
      <StyledHeader className="Header">{productsText}</StyledHeader>
      <StyledDescription className="Description">
        {t('layout__settings__serivices__choose_products_to_enable')}
      </StyledDescription>
      {productsIsLoading && (
        <StyledSkeletonContainer className="SkeletonContainer">
          {[...Array(3)].map((_, i) => (
            <StyledSkeletonProduct key={`block_${i}`}>
              <StyledSkeletonToggle
                width={39}
                height={24}
                className="SkeletonToggle"
              />
              <StyledProductInfoSkeleton className="ProductInfoSkeleton">
                <Skeleton height={16} width={72} />
                <Skeleton height={16} />
              </StyledProductInfoSkeleton>
            </StyledSkeletonProduct>
          ))}
        </StyledSkeletonContainer>
      )}
      {isShowProducts && (
        <StyledProducts className="Products">
          {visibleProducts.map((product) => {
            return (
              <ProductItem
                key={product.alias}
                product={product}
                onToggleProduct={onToggleProduct}
                isUpdating={product.alias === updatingProductAlias}
              />
            );
          })}
        </StyledProducts>
      )}
    </StyledServicesContainer>
  );
};
