import { FC, useContext, useEffect, useMemo, useRef } from 'react';

import {
  TransfersHistoryTable,
  TTransfersHistoryTableRefAttributes,
} from 'components/TransfersHistoryTable';
import { ExchangeAmountBlock } from 'pages/Portfolio/tabs/Exchange/components/ExchangeAmountBlock';
import { ExchangeSummaryBlock } from 'pages/Portfolio/tabs/Exchange/components/ExchangeSummaryBlock/ExchangeSummaryBlock';
import { StyledExchangeWrapper } from 'pages/Portfolio/tabs/Exchange/components/ExchangeWrapper/ExchangeWrapper.styled';
import { ExchangeContext } from 'pages/Portfolio/tabs/Exchange/contexts';
import { TRANSACTION_TYPE } from 'services/transactions/transactions.constants';

export const ExchangeWrapper: FC = () => {
  const tableRef = useRef<TTransfersHistoryTableRefAttributes>(null);
  const { lastConversionId, accountId } = useContext(ExchangeContext);

  const operationTypes = useMemo(() => [TRANSACTION_TYPE.AUTOCONVERSION], []);

  useEffect(() => {
    if (lastConversionId) {
      tableRef.current?.fetchLastTransactions();
    }
  }, [lastConversionId]);

  return (
    <>
      <StyledExchangeWrapper className="ExchangeWrapper">
        <ExchangeAmountBlock />
        <ExchangeSummaryBlock />
      </StyledExchangeWrapper>
      <TransfersHistoryTable
        className="ExchangeHistoryTable"
        ref={tableRef}
        titleKey="layout__exchange__history__title"
        operationTypes={operationTypes}
        accountId={accountId}
      />
    </>
  );
};
