import { PeriodicReportsWithContext } from './PeriodicReportsWithContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';

export const PeriodicReports = () => {
  return (
    <SubscriptionProvider>
      <PeriodicReportsWithContext />
    </SubscriptionProvider>
  );
};
