import { DEFAULT_CURRENCY } from 'constants/common';

import {
  TGetAccountRestrictionsForAmountFormProps,
  TGetAccountRestrictionsForAmountFormValue,
} from './AmountForm.types';

export const getAccountRestrictionsForAmountForm = ({
  balances,
  currency,
  selectedAccountId,
}: TGetAccountRestrictionsForAmountFormProps): TGetAccountRestrictionsForAmountFormValue => {
  const data =
    balances[currency || DEFAULT_CURRENCY]?.[selectedAccountId || ''];

  return {
    hasOpenedPositions: data?.is_has_positions ?? null,
    hasLockedFunds: data?.has_locked_funds ?? null,
    isMultiCurrency: data?.is_multicurrency ?? null,
  };
};
