import { TCommissionsReducer, TCommissionsState } from './types';

export const commissionsReducer: TCommissionsReducer = (
  state,
  action,
): TCommissionsState => {
  switch (action.type) {
    case 'FETCH_STATE_START': {
      return {
        ...state,
        fetching: {
          ...state.fetching,

          idle: false,
          errorMessage: '',
          error: false,
          pending: true,
        },
      };
    }

    case 'FETCH_STATE_ERROR': {
      return {
        ...state,
        fetching: {
          ...state.fetching,

          idle: false,
          error: true,
          pending: false,
          succeed: false,
          errorMessage: action.payload,
        },
      };
    }

    case 'FETCH_STATE_SUCCESS': {
      return {
        ...state,
        ...action.payload,

        fetching: {
          ...state.fetching,

          idle: false,
          pending: false,
          succeed: true,
        },
      };
    }

    default: {
      return state;
    }
  }
};
