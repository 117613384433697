import { useContext, useEffect, useMemo } from 'react';
import { ISelectOption, useData } from 'react-ui-kit-exante';

import { AccountsSearchField } from 'components/AccountsSearchField';
import { AutocompleteInfiniteListbox } from 'components/AutocompleteInfiniteListbox';
import { TranslationContext } from 'contexts';
import { reportsService } from 'services/reports';

import { removeAccountDuplicates } from './AccountAutocomplete.helpers';
import { TAccountAutocompleteProps } from './AccountAutocomplete.types';

export const AccountAutocomplete = ({
  value = [],
  onChange,
  error,
  message,
  dataTestId,
  disabled,
  name,
  forceSkeleton = false,
  lockSingleOption = false,
}: TAccountAutocompleteProps) => {
  const { t, currentLanguage } = useContext(TranslationContext);

  const {
    data: accounts,
    isLoading: isAccountsLoading,
    fetchData: fetchAccounts,
  } = useData({
    onFetch: () => reportsService.getPeriodicReportsAccounts(currentLanguage),
  });

  useEffect(() => {
    fetchAccounts();
  }, []);

  const accountsOptions =
    accounts?.items.map(
      (account): ISelectOption => ({
        value: account.id,
        label: account.text,
      }),
    ) || [];

  const optionValue = useMemo(
    () =>
      value.reduce<ISelectOption[]>((arr, accId) => {
        const option = accountsOptions.find((curr) => curr.value === accId);

        if (option) {
          arr.push(option);
        }

        return arr;
      }, []),
    [value, accountsOptions],
  );

  const onChangeAccounts = (
    _: any,
    newOptions: Array<string | ISelectOption>,
  ) => {
    const valueNew = newOptions.map((acc) =>
      typeof acc !== 'string' ? String(acc.value) : acc,
    );

    const valueFixed = removeAccountDuplicates(value, valueNew);

    onChange?.(valueFixed);
  };

  const singleOptionDisabled =
    lockSingleOption && accountsOptions?.length === 1 && !!optionValue;

  return (
    <AccountsSearchField
      withDescriptions
      value={optionValue}
      options={accountsOptions}
      onChange={onChangeAccounts}
      placeholder={t('generic__accounts')}
      floating={false}
      showSkeleton={isAccountsLoading || forceSkeleton}
      isMultiple
      fullWidth
      disabled={disabled || singleOptionDisabled}
      dataTestId={dataTestId}
      controlProps={{
        name,
        error,
        message,
      }}
      ListboxComponent={AutocompleteInfiniteListbox}
    />
  );
};
