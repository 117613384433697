import { FC, useContext } from 'react';
import { IconButton } from 'react-ui-kit-exante';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { StyledChartWrapper } from 'components/Chart';
import { getChartColors } from 'components/Chart/Chart.constants';
import { getYWidth } from 'components/Chart/Chart.helpers';
import { ChartSkeleton } from 'components/Chart/ChartSkeleton';
import { CustomLegend } from 'components/Chart/CustomLegend/CustomLegend';
import { CustomTooltip } from 'components/Chart/CustomTooltip';
import { SubHeader } from 'components/SubHeader/SubHeader';
import { TranslationContext } from 'contexts';
import { Dates } from 'helpers/dates';
import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { downloadAnalysisReport } from 'pages/Portfolio/tabs/Analysis/Analysis.helpers';
import { FilterContext } from 'pages/Portfolio/tabs/Analysis/contexts/FilterContext';
import { useTheme } from 'theme';

import { TDailyPLProps } from './DailyPL.types';

export const DailyPL: FC<TDailyPLProps> = ({ data, isDataLoading }) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter();
  const theme = useTheme();
  const { filters } = useContext(FilterContext);

  const chartData =
    data?.keys.map((date, index) => {
      return {
        date: Dates.format(new Date(date), false),
        grossPnl: data?.cumulative?.[index],
        netPnl: data?.net_pnl?.[index],
        dailyPnl: data?.pnl?.[index],
      };
    }) || [];

  const downloadReport = () => {
    downloadAnalysisReport('pnl', filters, currentLanguage);
  };

  const isEmptyDataForChart = !chartData.find(
    ({ grossPnl, netPnl, dailyPnl }) => grossPnl || dailyPnl || netPnl,
  );

  const chartColors = getChartColors(theme);
  const yWidth = getYWidth(chartData, ['grossPnl', 'netPnl', 'dailyPnl']);

  if (!isDataLoading && isEmptyDataForChart) {
    return null;
  }

  return (
    <>
      <SubHeader
        title={t('generic__daily_pnl')}
        info={
          <>
            <p>{t('layout__performance__pnl_reflects')}</p>
            <p>{t('layout__performance__gross_pnl_indicates')}</p>
          </>
        }
        action={
          <IconButton
            iconName="XLSIcon"
            iconColor="secondary"
            iconSize={24}
            onClick={downloadReport}
            className="DailyPLDownload"
            disabled={isDataLoading}
          />
        }
      />

      <StyledChartWrapper className="ChartWrapper">
        {isDataLoading ? (
          <ChartSkeleton />
        ) : (
          <ResponsiveContainer minWidth={theme?.breakpoints?.values?.sm}>
            <ComposedChart data={chartData}>
              <CartesianGrid />
              <Bar dataKey="dailyPnl">
                {chartData?.map((datum, entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      Number(datum?.dailyPnl) >= 0
                        ? chartColors.positive
                        : chartColors.negative
                    }
                  />
                ))}
              </Bar>
              <Line
                type="monotone"
                dataKey="grossPnl"
                stroke={chartColors.positive}
                dot={<span />}
              />
              <Line
                type="monotone"
                dataKey="netPnl"
                stroke={chartColors.warning}
                dot={<span />}
              />
              <XAxis
                dataKey="date"
                minTickGap={12}
                padding={{ left: 10 }}
                interval="preserveStartEnd"
                tickFormatter={dateFormatter}
              />
              <YAxis
                orientation="right"
                width={yWidth}
                minTickGap={5}
                tickCount={10}
                tickFormatter={(value) =>
                  formatCurrency(currencyFormatter, value, true)
                }
              />
              <Legend
                content={<CustomLegend yWidth={yWidth} hideAxisName />}
                verticalAlign="top"
              />
              <Tooltip
                content={<CustomTooltip />}
                wrapperStyle={{ outline: 'none' }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </StyledChartWrapper>
    </>
  );
};
