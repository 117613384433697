import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useContext,
  useImperativeHandle,
  useMemo,
} from 'react';
import { IOnFetchArguments, Table, useTableData } from 'react-ui-kit-exante';

import { TTransfersHistoryTableRefAttributes } from 'components/TransfersHistoryTable';
import { DEFAULT_MAX_PAGINATION_LENGTH } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';
import { useDateFormatter } from 'hooks/useDateFormatter';
import {
  TSubuserWithdrawalHistoryEntry,
  TWithdrawalHistoryResponse,
  withdrawalService,
} from 'services/withdrawal';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';
import { TUseTableDataProps } from 'types/TUseTableDataProps';

import { getSubuserWithdrawalHistoryTableColumns } from './SubuserWithdrawalHistoryTable.helpers';
import { TSubuserWithdrawalHistoryTableRefAttributes } from './SubuserWithdrawalHistoryTable.types';

export const SubuserWithdrawalHistoryTable: ForwardRefExoticComponent<
  RefAttributes<TSubuserWithdrawalHistoryTableRefAttributes>
> = forwardRef(function WithdrawalHistoryTable(props, ref) {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const tableDataArgs = useMemo<
    TUseTableDataProps<TWithdrawalHistoryResponse<true> | null>
  >(
    () => ({
      data: {
        onFetch: ({ params: { skip, limit } }: IOnFetchArguments) => {
          return withdrawalService.getHistory<true>({
            lang: currentLanguage,
            type: WITHDRAWAL_TYPE.SUBUSER_WITHDRAWAL,
            offset: skip,
            size: limit,
          });
        },
      },
      pagination: {
        getDefaultPagination: () => ({
          limit: DEFAULT_MAX_PAGINATION_LENGTH,
          skip: 0,
        }),
      },
    }),
    [],
  );

  const {
    limit,
    page,
    setPage,
    setLimit,
    fetchData: refetchTableData,
    data: tableData,
    isLoading,
  } = useTableData<TWithdrawalHistoryResponse<true> | null>(tableDataArgs);

  const dateFormatter = useDateFormatter(true);

  const columns = useMemo(
    () =>
      getSubuserWithdrawalHistoryTableColumns({
        t,
        currentLanguage,
        dateFormatter,
      }),
    [t, currentLanguage],
  );

  useImperativeHandle<
    TTransfersHistoryTableRefAttributes,
    TTransfersHistoryTableRefAttributes
  >(
    ref,
    () => ({
      fetchLastTransactions: () => {
        if (page === 0) {
          refetchTableData();
        } else {
          setPage(0);
        }
      },
    }),
    [page, setPage, refetchTableData],
  );

  const total = tableData?.count || 0;

  return (
    <Table<TSubuserWithdrawalHistoryEntry>
      hasPagination
      isFlexLayout
      translator={t}
      className="WithdrawalHistoryTable"
      columns={columns}
      data={tableData?.results || []}
      manualSortBy
      tableId="withdrawalsHistoryTable"
      locale={currentLanguage}
      title={t('generic__activity')}
      titleSize={3}
      isLoading={isLoading || isTranslationsLoading}
      pageSize={limit}
      serverPaginationProps={{
        total,
        setPage,
        pageCount: Math.ceil(total / limit),
        pageSize: limit,
        pageIndex: page,
        setPageSize: setLimit,
      }}
    />
  );
});
