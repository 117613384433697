import { styled } from 'theme';

export const StyledButtonsGroupContainer = styled('div')<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, 1fr);
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-radius: 4px;
`;

export const StyledButtonsGroupItem = styled('button')<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  color: ${({ active, theme }) => {
    return active ? theme.color.typo.primary : theme.color.typo.action;
  }};
  background-color: ${({ active, theme }) =>
    active ? theme.color.bg.secondary : 'transparent'};
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
