import { useReducer } from 'react';
import { useSelector } from 'react-redux';

import { selectWithdrawalState } from 'store/withdrawal';

import { initialTransfersState } from './constants';
import { transfersReducer } from './reducer';

export const useTransfersState = () => {
  const {
    fetchWithdrawalState,
    withdrawalState,
    metadataState,
    fetchMetadataState,
    fetchMetadataAccount,
  } = useSelector(selectWithdrawalState);

  const [transfersState, transfersDispatch] = useReducer(
    transfersReducer,
    initialTransfersState,
  );

  return {
    transfersState: {
      ...transfersState,
      ...withdrawalState,
      ...metadataState,
      fetching: fetchWithdrawalState,
      fetchingMetadata: fetchMetadataState,
      fetchMetadataAccount,
    },
    transfersDispatch,
  };
};
