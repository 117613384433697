import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { Table, useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { prepareTableId } from 'helpers/prepareTableId';
import { useDateFormatter } from 'hooks/useDateFormatter';
import {
  depositService,
  TDepositHistoryCryptoResponse,
} from 'services/deposit';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { TDepositTableTransaction } from './DepositHistoryTable.types';
import { getColumns } from './columns';
import { CryptoDetailsSubRow } from './components/CryptoDetailsSubRow';

export const DepositHistoryTable: FC = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);
  const dateFormatter = useDateFormatter(true);

  const { selectedAccountId } = useAppSelector(selectAccounts);

  const abortController = useRef(new AbortController());

  const { isLoading, data, fetchData } =
    useData<TDepositHistoryCryptoResponse | null>({
      onFetch: async () => {
        if (!selectedAccountId) {
          return null;
        }

        abortController.current.abort();
        abortController.current = new AbortController();

        return depositService.getHistoryCrypto({
          accountId: selectedAccountId,
          options: {
            signal: abortController.current.signal,
          },
        });
      },
    });

  useEffect(() => {
    fetchData();

    return () => {
      abortController.current.abort();
    };
  }, [selectedAccountId]);

  const columns = useMemo(
    () => getColumns({ t, dateFormatter }),
    [isTranslationsLoading, t, dateFormatter],
  );

  const transactions: TDepositTableTransaction[] = !data
    ? []
    : data.map((tr) => ({
        ...tr,
        subRows: [{ address: tr.address, address_from: tr.address_from }],
      }));

  return (
    <Table
      disableSortBy
      tableId={prepareTableId('clientsarea-deposit-crypto')}
      columns={columns}
      data={transactions}
      isLoading={isLoading}
      translator={t}
      locale={currentLanguage}
      isFlexLayout
      expanded
      renderRowSubComponent={(row) => {
        const { address_from: from, address: to } = row.original.subRows[0];

        return <CryptoDetailsSubRow address_from={from} address_to={to} />;
      }}
    />
  );
};
