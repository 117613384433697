import { FC, useContext, useMemo } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { BASE_URL } from 'constants/endpoints';
import { TranslationContext } from 'contexts';
import { ReportStatus } from 'services/reports/reports.types';

import {
  StyledAnimateSpinnerIcon,
  StyledErrorIcon,
} from './GeneratedActionCell.styled';
import { TGeneratedActionCellProps } from './GeneratedActionCell.types';

export const GeneratedActionCell: FC<TGeneratedActionCellProps> = ({
  data,
  iconSize = 16,
}) => {
  const { t } = useContext(TranslationContext);

  const isPending = data.last_status === ReportStatus.Processing;

  const isError = useMemo(() => {
    if (data.files?.length) {
      return false;
    }
    return [ReportStatus.Failed, ReportStatus.Error].includes(data.last_status);
  }, [data.files, data.last_status]);

  const isCanDownload =
    data.files?.length > 0 && data.last_status === ReportStatus.Sent;

  const getTooltipTitle = () => {
    if (isPending) {
      return t('generic__processing_state');
    }
    if (isError) {
      return t('generic__processing_failed');
    }
    if (isCanDownload) {
      return t('generic__action__download');
    }
    return '';
  };

  const downloadReport = () => {
    if (data.export_url) {
      const url = new URL(data.export_url, BASE_URL);
      window.open(url, '_blank');
    }
  };

  return (
    <Tooltip title={getTooltipTitle()} placement="top-start">
      <>
        {isPending && <StyledAnimateSpinnerIcon />}
        {isError && <StyledErrorIcon />}
        {!isPending && !isError && (
          <IconButton
            iconName="DownloadIcon"
            iconColor="action"
            data-test-id="download-periodic-report-button"
            onClick={downloadReport}
            disabled={!isCanDownload}
            iconSize={iconSize}
          />
        )}
      </>
    </Tooltip>
  );
};
