import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledRequisiteList = styled('ul')`
  padding: 0;
`;

export const StyledRequisiteItem = styled('li')`
  list-style: none;

  display: flex;
  justify-content: space-between;
  gap: 8px;

  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledRequisiteItemTitle = styled('span')`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
`;
export const StyledRequisiteItemValue = styled('span')`
  display: flex;
  gap: 8px;
  align-items: center;

  font-weight: 600;
  text-align: right;
  font-size: 15px;
  line-height: 24px;
`;

export const StyledRequisiteItemTextSkeleton = styled(Skeleton)`
  width: 200px;
  height: 15px;
`;

export const StyledRequisiteItemIconSkeleton = styled(Skeleton)`
  width: 16px;
  height: 16px;
`;
