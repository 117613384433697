import { styled } from 'theme';

export const StyledSubuserWithdrawalConfirmationFormWrapper = styled('div')`
  padding: 24px;
  width: 334px;
  min-width: 334px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const StyledSubuserWithdrawalConfirmationFormHeader = styled('h2')`
  margin: 0;
  font-weight: 400;
`;

export const StyledSubuserWithdrawalConfirmationFormQuestion = styled('p')`
  margin: 0;
`;

export const StyledSubuserWithdrawalConfirmationButtonsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;
