import { NOTIFICATION } from 'constants/NOTIFICATION';

import { TNotificationDictionary } from './notifyWith.types';

export const notificationErrorsTextMap: Record<
  string,
  TNotificationDictionary
> = {
  400: NOTIFICATION.ERROR.BAD_REQUEST,
  403: NOTIFICATION.ERROR.FORBIDDEN,
};
