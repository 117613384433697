import { FC, ReactNode, useContext } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';
import { currencyFormatter } from 'helpers/formatters';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { TTransactionData } from 'services/transactions';
import { TWithdrawalHistoryEntry } from 'services/withdrawal';

import { WithdrawalStatus } from '../WithdrawalStatus';

import {
  getMainWithdrawalInfo,
  getWithdrawalContentRows,
} from './WithdrawalInfo.helpers';
import {
  StyledWithdrawalAmount,
  StyledWithdrawalDatetime,
  StyledWithdrawalHeaderRow,
  StyledWithdrawalInfoBlock,
  StyledWithdrawalInfoWrapper,
} from './WithdrawalInfo.styled';
import { WithdrawalInfoRow } from './components';

export const WithdrawalInfo: FC<{
  data: TWithdrawalHistoryEntry | TTransactionData;
}> = ({ data }) => {
  const { currentLanguage, t } = useContext(TranslationContext);

  const { amount, status, targetAccount, created, currency } =
    getMainWithdrawalInfo(data);

  const localCurrencyFormatter = (amountValue: string) =>
    currencyFormatter(currentLanguage, currency).format(Number(amountValue));

  const dateFormatter = useDateFormatter(true);
  const dateFormatterWithoutTime = useDateFormatter();

  const headerRows = Object.entries<ReactNode>({
    datetime: (
      <StyledWithdrawalDatetime className="WithdrawalDatetime">
        {created.length ? dateFormatter(created) : ''}
      </StyledWithdrawalDatetime>
    ),
    targetAccount,
    amount: (
      <StyledWithdrawalAmount className="WithdrawalAmount">
        {localCurrencyFormatter(amount)}
      </StyledWithdrawalAmount>
    ),
    status: <WithdrawalStatus status={status} />,
  });

  const contentRows = getWithdrawalContentRows({
    data,
    currencyFormatter: localCurrencyFormatter,
    dateFormatter,
    dateFormatterWithoutTime,
    t,
  });

  return (
    <StyledWithdrawalInfoWrapper className="WithdrawalInfoWrapper">
      <StyledWithdrawalInfoBlock className="WithdrawalInfoBlock">
        {headerRows.map(([key, value]) => (
          <StyledWithdrawalHeaderRow key={key} className="WithdrawalHeaderRow">
            {value}
          </StyledWithdrawalHeaderRow>
        ))}
      </StyledWithdrawalInfoBlock>
      {!!contentRows.length && (
        <StyledWithdrawalInfoBlock className="WithdrawalInfoBlock">
          {contentRows.map(([key, value]) => (
            <WithdrawalInfoRow key={key + value} titleKey={key} value={value} />
          ))}
        </StyledWithdrawalInfoBlock>
      )}
    </StyledWithdrawalInfoWrapper>
  );
};
