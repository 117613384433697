import { FC } from 'react';

import { StyledInlineNotificationWrapper } from './InlineNotification.styled';
import { TInlineNotificationProps } from './InlineNotification.types';

export const InlineNotification: FC<TInlineNotificationProps> = ({
  children,
  type,
}) => {
  return (
    <StyledInlineNotificationWrapper type={type} className="InlineNotification">
      {children}
    </StyledInlineNotificationWrapper>
  );
};
