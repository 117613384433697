import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Switch, Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { notifyWith } from 'helpers/notifyWith';
import { externalAccountsService } from 'services/settings/externalAccounts';

import {
  StyledAccountContainer,
  StyledAccountContainerLeftSide,
  StyledAccountContainerRightSide,
  StyledAccountInfoIcon,
  StyledAccountServiceIcon,
  StyledAccountServiceName,
} from './Account.styled';
import { TAccountProps } from './Account.types';

export const Account = ({
  name,
  icon,
  id,
  isActive,
  refreshData,
}: TAccountProps) => {
  const { t } = useContext(TranslationContext);
  const [switcherDisabled, setSwitcherDisabled] = useState(false);

  const onChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (switcherDisabled) {
        return;
      }

      setSwitcherDisabled(true);
      if (checked) {
        window.location.href = `${window.location.origin}/clientsarea/login/${id}/`;
      } else {
        await externalAccountsService.disconnectProvider(id);
        await refreshData();
        setSwitcherDisabled(false);

        notifyWith.success('layout__binding__account_disconnected');
      }
    },
    [],
  );

  const tooltipTitle = useMemo(() => {
    const i18nKey = isActive
      ? 'layout__security_tabs__external_accounts__tooltip_connected'
      : 'layout__security_tabs__external_accounts__tooltip_not_connected';
    return t(i18nKey, { PROVIDER_NAME: name });
  }, [name, isActive]);

  return (
    <StyledAccountContainer className="Account">
      <StyledAccountContainerLeftSide className="AccountLeftContainer">
        <StyledAccountServiceIcon
          className="AccountServiceIcon"
          src={icon}
          alt={name}
        />
        <StyledAccountServiceName className="AccountServiceName">
          {t(name)}
        </StyledAccountServiceName>
      </StyledAccountContainerLeftSide>
      <StyledAccountContainerRightSide className="AccountRightContainer">
        <Tooltip
          title={t(tooltipTitle)}
          placement="top"
          className="AccountTooltip"
        >
          <StyledAccountInfoIcon className="AccountInfoIcon" title="info" />
        </Tooltip>
        <Switch
          className="AccountSwitcher"
          label=""
          checked={isActive}
          onChange={onChange}
          disabled={switcherDisabled}
        />
      </StyledAccountContainerRightSide>
    </StyledAccountContainer>
  );
};
