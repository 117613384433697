import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { IconButton, Skeleton } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { notifyWith } from 'helpers/notifyWith';
import { accountsService } from 'services/accounts';
import { fetchAccountsAction } from 'store/accounts/accounts.actions';
import { accountsActions } from 'store/accounts/accounts.slice';
import { useAppDispatch } from 'store/hooks';
import { TAddAccountLimit } from 'types/accounts';

import { TAddAccountButtonProps } from './AddAccountButton.types';

export const AddAccountButton: FC<TAddAccountButtonProps> = ({ clientId }) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const dispatch = useAppDispatch();

  const [limits, setLimits] = useState<TAddAccountLimit[]>([]);

  const [isAllowedAddAccount, setIsAllowedAddAccount] =
    useState<boolean>(false);

  const [isLoading, setLoading] = useState<boolean>(true);

  const getLimits = async () => {
    try {
      setLoading(true);

      const data = await accountsService.getAddAccountLimits(currentLanguage);

      if (data) {
        setLimits(data.limits || []);
        setIsAllowedAddAccount(data.allowAddAccount);
      }
    } finally {
      setLoading(false);
    }
  };

  const isCanAddAccount = useMemo(() => {
    const current = limits.find((limit) => limit.clientId === clientId);
    const hasLimits = !!current;
    const isByWithdrawalPermission = current?.by_withdrawal_permission;
    const isLimitNotExceeded = hasLimits && current.limit > current.count;

    return (
      !isLoading &&
      isAllowedAddAccount &&
      !isByWithdrawalPermission &&
      isLimitNotExceeded
    );
  }, [clientId, limits, isLoading, isAllowedAddAccount]);

  const btnTitle = isCanAddAccount
    ? ''
    : t('layout__user_management__account_creation_not_allowed');

  const addAccountHandler = async (): Promise<void> => {
    if (isCanAddAccount) {
      try {
        setLoading(true);
        const data = await accountsService.addAccount(clientId);

        if (data) {
          await dispatch(fetchAccountsAction({ translate: t }));
          dispatch(
            accountsActions.selectAccount({ accountId: data.accountId }),
          );
          notifyWith.success('layout__actions__your_account_has_been_created');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getLimits();
  }, []);

  return isLoading ? (
    <Skeleton
      className="SummaryAddAccountButton"
      width={32}
      height={32}
      variant="rectangular"
      animation="wave"
    />
  ) : (
    <IconButton
      className="SummaryAddAccountButton"
      iconName="AddIcon"
      iconColor="secondary"
      iconSize={32}
      disabled={!isCanAddAccount}
      onClick={addAccountHandler}
      title={btnTitle}
      data-test-id="add-account-button"
    />
  );
};
