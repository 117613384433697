import { AxiosError } from 'axios';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { useLogHandleTime } from 'hooks/useLogHandleTime';
import { ACCOUNT_TYPE } from 'services/accounts/accounts.constants';
import { transferService, TransferType } from 'services/transfer';
import { selectAccounts } from 'store/accounts';

import { initialAccountDetailsState } from './constants';
import { accountDetailsReducer } from './reducer';

export const useAccountDetailsState = () => {
  const { selectedAccount } = useSelector(selectAccounts);
  const [accountDetailsState, accountDetailsDispatch] = useReducer(
    accountDetailsReducer,
    initialAccountDetailsState,
  );
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('account-details');

  const fetchAccountDetails = async (selectedAccountId: string) => {
    accountDetailsDispatch({ type: 'FETCH_START' });

    try {
      setStartHandleTime();
      const payload = await transferService.fetchAccountDetails(
        selectedAccountId,
        TransferType.ToMyAccount,
        null,
      );
      logHandleTime();

      if (payload.account.currencies.length) {
        accountDetailsDispatch({ type: 'FETCH_SUCCESS', payload });
      } else {
        accountDetailsDispatch({
          type: 'FETCH_ERROR',
          payload: 'Currencies list is empty for selected account',
        });
      }
    } catch (error) {
      const { message } = error as AxiosError;

      accountDetailsDispatch({ type: 'FETCH_ERROR', payload: message });
    }
  };

  useEffect(() => {
    if (
      selectedAccount?.value &&
      !selectedAccount?.types.includes(ACCOUNT_TYPE.AGGREGATED)
    ) {
      fetchAccountDetails(selectedAccount.value);
    }
  }, [selectedAccount?.value]);

  return {
    accountDetailsState,
    accountDetailsDispatch,
  };
};
