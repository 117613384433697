import { useContext } from 'react';

import { PageWrapper } from 'components/PageWrapper';
import { TranslationContext } from 'contexts';

import {
  StyledNotificationsContainer,
  StyledNotificationsTitle,
} from './Notifications.styled';
import { NotificationsHeader, Settings } from './components';
import { useNotifications } from './useNotifications';

export const Notifications = () => {
  const { t } = useContext(TranslationContext);
  const { handleChange, isLoading, groups, primaryEmail } = useNotifications();

  return (
    <PageWrapper className="NotificationSettings">
      <StyledNotificationsContainer
        className="NotificationSettingsContainer"
        title={t('generic__notifications')}
      >
        <StyledNotificationsTitle className="Title">
          {t('layout__settings__specify_additional_email')}
        </StyledNotificationsTitle>
        <NotificationsHeader isLoading={isLoading} />
        <Settings
          isLoading={isLoading}
          groups={groups}
          primaryEmail={primaryEmail}
          onChange={handleChange}
        />
      </StyledNotificationsContainer>
    </PageWrapper>
  );
};
