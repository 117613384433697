import { FC, useContext } from 'react';

import { CurrencySelect } from 'components/CurrencySelect';
import { CurrencySelectSkeleton } from 'components/CurrencySelect/components/CurrencySelectSkeleton';
import { TranslationContext } from 'contexts';
import {
  CashConversionContext,
  DepositContext,
} from 'pages/Portfolio/tabs/Deposit/contexts';

import {
  StyledDepositSectionBody,
  StyledDepositSectionHeading,
  StyledDepositSectionText,
} from '../../../DepositWrapper/DepositWrapper.styled';

import { StyledCashConversionSectionWrapper } from './CashConversionSection.styled';

export const CashConversionSection: FC = () => {
  const { t } = useContext(TranslationContext);

  const { isCurrencyUpdating, currencies, currency, setCurrency } = useContext(
    CashConversionContext,
  );

  const { currencies: currenciesDeposit } = useContext(DepositContext);

  return (
    <StyledCashConversionSectionWrapper className="CashConversionSectionWrapper">
      <StyledDepositSectionHeading className="DepositSectionHeading">
        {t('layout__deposit__automatic_currency_conversion')}
      </StyledDepositSectionHeading>
      <StyledDepositSectionBody className="DepositSectionBody">
        <StyledDepositSectionText className="DepositSectionText">
          {t('automatic_currency_conversion_allows')}
        </StyledDepositSectionText>
        <StyledDepositSectionText
          className="DepositSectionText"
          dangerouslySetInnerHTML={{
            __html: t('layout__deposit__cash_conversion_auto_conversion'),
          }}
        />
        <StyledDepositSectionText className="DepositSectionText">
          {t('layout__deposit__cash_conversion_no_extra_fees')}
        </StyledDepositSectionText>
        {isCurrencyUpdating ? (
          <CurrencySelectSkeleton />
        ) : (
          <CurrencySelect
            withDisabled
            value={currency}
            onChange={setCurrency}
            options={currencies}
            icons={currenciesDeposit.icons}
            data-test-id="conversion__select__currency"
          />
        )}
      </StyledDepositSectionBody>
    </StyledCashConversionSectionWrapper>
  );
};
