import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IColumn, IconButton, Skeleton, useData } from 'react-ui-kit-exante';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { today } from 'helpers/dates';
import { hasCyrillic } from 'helpers/hasCyrillic';
import { useDateFormatter } from 'hooks/useDateFormatter';
import {
  partnerService,
  TPartnerLink,
  TPartnerLinkNew,
} from 'services/partner';

import { PartnersContext } from '../../Partner.constants';
import { defaultFromToRange } from '../PartnerStatistics/PartnerStatistics.constants';

import {
  getPartnerLinksColumns,
  preparePartnerLinkForTable,
} from './PartnerLinks.helpers';
import {
  StyledDateRangePickerDateText,
  StyledDateRangePickerWrapper,
  StyledPartnerLinksAddLink,
  StyledPartnerLinksAddLinkSkeleton,
  StyledPartnerLinksDatePicker,
  StyledPartnerLinksTable,
  StyledPartnerLinksTableHeader,
  StyledPartnerLinksWrapper,
} from './PartnerLinks.styled';
import { TPartnerLinksTableLink } from './PartnerLinks.types';
import {
  PartnerLinksAdd,
  PartnerLinksContext,
  TPartnerLinksContextValue,
} from './components';

export const PartnerLinks: FC = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const {
    partnerData,
    isPartnerLoading: isInitDataLoading,
    fetchPartnerData,
  } = useContext(PartnersContext);
  const { branding } = useContext(BrandingContext);

  const dateFormatter = useDateFormatter();

  const [links, setLinks] = useState<TPartnerLinksTableLink[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(
    defaultFromToRange[0],
  );
  const [endDate, setEndDate] = useState<Date | null>(defaultFromToRange[1]);

  const serverLinks = partnerData?.links;

  const defaultPartnerDestinationUrl =
    branding?.branding?.default_partner_destination_url;
  const calendarLocale = currentLanguage === 'en' ? 'en-us' : currentLanguage;
  const isTableLoading = isInitDataLoading;

  const newLinkEmpty = useMemo(
    () => ({
      site_url: defaultPartnerDestinationUrl || '',
      url: '',
      name: '',
    }),
    [defaultPartnerDestinationUrl],
  );

  const [newLink, setNewLink] = useState<TPartnerLinkNew>(newLinkEmpty);

  const { fetchData: createLink, isLoading: isCreating } = useData({
    onFetch: () =>
      partnerService.createLink({ lang: currentLanguage, link: newLink }),
    onSuccess: (createdLink) => {
      if (partnerData && createdLink) {
        setLinks((prevLinks) => {
          const newLinks = [...prevLinks];

          newLinks.unshift(
            preparePartnerLinkForTable(partnerData.partner_domain, createdLink),
          );

          return newLinks;
        });
      }
    },
    loading: false,
  });

  const onCalendarChange = ([start, end]: [Date | null, Date | null]) => {
    setStartDate(start);
    setEndDate(end);
  };

  const requestNewPeriod = () => {
    if (startDate && endDate) {
      fetchPartnerData({
        statisticsPeriod: [startDate, endDate],
      });
    }
  };

  const onAdd = () => {
    createLink();
    setNewLink(newLinkEmpty);
  };

  const columns = useMemo((): Array<IColumn<TPartnerLinksTableLink>> => {
    return getPartnerLinksColumns(t);
  }, [t, links]);

  const onDelete = useCallback((id: number): void => {
    setLinks((prevLinks) => {
      return prevLinks.filter((link) => link.id !== id);
    });
  }, []);

  const onEdit = useCallback(
    (updatedLink: TPartnerLink): void => {
      if (!partnerData) {
        return;
      }

      setLinks((prevLinks) => {
        const newLinks = [...prevLinks];
        const index = newLinks.findIndex((link) => link.id === updatedLink.id);

        newLinks.splice(
          index,
          1,
          preparePartnerLinkForTable(partnerData.partner_domain, {
            ...updatedLink,
            visit_count: newLinks[index].visit_count, // taking previous visit_count because backend does not provide updated data
          }),
        );
        return newLinks;
      });
    },
    [partnerData],
  );

  const contextValue = useMemo(
    (): TPartnerLinksContextValue => ({
      links,
      isLoading: isInitDataLoading,
      onDelete,
      onEdit,
    }),
    [links, isInitDataLoading],
  );

  useEffect(() => {
    if (serverLinks) {
      setLinks(
        serverLinks.map(
          preparePartnerLinkForTable.bind(null, partnerData.partner_domain),
        ),
      );
    }
  }, [serverLinks]);

  useEffect(() => {
    requestNewPeriod();
  }, []);

  const confirmButtonDisabled =
    !newLink.site_url ||
    !newLink.name ||
    hasCyrillic(newLink.site_url) ||
    hasCyrillic(newLink.name);

  return (
    <PartnerLinksContext.Provider value={contextValue}>
      <StyledPartnerLinksWrapper className="PartnerLinksWrapper">
        <StyledPartnerLinksTableHeader
          className="PartnerLinksTableHeader"
          noRightPadding={!links.length}
          loading={isTableLoading}
        >
          <ActionWithConfirmation
            className="PartnerLinksAddLinkWrapper"
            withCloseAfterConfirmation
            content={<PartnerLinksAdd link={newLink} onChange={setNewLink} />}
            onConfirm={onAdd}
            disabled={isCreating}
            confirmButtonNameKey="generic__action__add"
            confirmButtonDisabled={confirmButtonDisabled}
            cancelButtonNameKey="generic__action__cancel"
          >
            {isTableLoading ? (
              <StyledPartnerLinksAddLinkSkeleton width={130} height={24} />
            ) : (
              <StyledPartnerLinksAddLink
                className="PartnerLinksAddLink"
                color="transparent"
                icon={<IconButton iconName="AddIcon" />}
              >
                {t('layout__partners__links__add_link', 'Add Link')}
              </StyledPartnerLinksAddLink>
            )}
          </ActionWithConfirmation>
          <StyledDateRangePickerWrapper className="DateRangePickerWrapper">
            {isTableLoading ? (
              <Skeleton width={162} height={24} />
            ) : (
              <StyledDateRangePickerDateText className="DateRangePickerDateText">
                {startDate && <span>{dateFormatter(startDate)}</span>}
                {startDate && endDate && <span>–</span>}
                {endDate && <span>{dateFormatter(endDate)}</span>}
              </StyledDateRangePickerDateText>
            )}
            <StyledPartnerLinksDatePicker
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={onCalendarChange}
              onCalendarClose={requestNewPeriod}
              maxDate={today}
              className="graph__period"
              iconClassName="CalendarDatePickerIcon"
              locale={calendarLocale}
              disabled={isTableLoading}
              showSkeleton={isTableLoading}
              skeletonSize={24}
              data-test-id="account-selection__datepicker"
            />
          </StyledDateRangePickerWrapper>
        </StyledPartnerLinksTableHeader>
        <StyledPartnerLinksTable<TPartnerLinksTableLink>
          data={links}
          tableId="PartnerLinksTable"
          columns={columns}
          isLoading={isTableLoading}
          translator={t}
          locale={currentLanguage}
          isFlexLayout
          hasPagination
          showTableInfo
          defaultSortBy={[{ id: 'id', desc: true }]}
          skeletonsCount={10}
          pageSize={20}
        />
      </StyledPartnerLinksWrapper>
    </PartnerLinksContext.Provider>
  );
};
