import { ACCOUNT_SELECTOR_POSITION } from 'services/branding/branding.constants';
import { TClientsareaTheme } from 'theme';
import { TValueOf } from 'types/TValueOf';

export type TBrandingResponse = Partial<{
  branding: TBrandingBranding;

  alias: string;
  ui_settings: TBrandingUISettings;
  header_custom_button: Partial<{
    text: string;
    link: string;
  }>;
  wl: Partial<{
    name: string;
    emails: Partial<{
      support: string;
      noreply: string;
    }>;
  }>;
  wl_name: string;
  wl_emails_support: string;
  no_emails: boolean;
  is_supervisor: boolean;
  is_whitelabel: boolean;
  hide_trading: boolean;

  show_accounts: boolean;
  show_cash_conversion: boolean;
  show_reset_demo: boolean;
  show_account_descriptions: boolean;
  show_header_username: boolean;
  show_2fa_email: boolean;
  password_change_success_text: string;

  input_form_slogan_text?: string;
  login_wording?: string;
  login_placeholder?: string;
  signup_available?: boolean;
  hide_open_account_type?: string[];

  follow_us_links: Record<string, string | null>;
  atp: TBrandingAtp;
  legal_texts: TBrandingLeLegalTexts;
  le_legal_texts: Record<string, TBrandingLeLegalTexts>;
  help_center: string;
  wl_contact_us: string;
  account_info_refresh_rate: number;
}>;

export type TBrandingBranding = Partial<{
  header_include: string;
  body_include: string;
  breadcrumb_settings: Partial<{
    home_name: string;
    custom_home_link: string;
  }>;
  default_partner_destination_url: string;
  default_redirect_url: string;
  show_autodebit: boolean;
  show_margin_trading: boolean;
  wr_form_settings: {
    wr_forms?: TWRFields[];
  };
  auth_flows?: TAuthFlow[];
  apple_login_enabled?: boolean;
  google_login_enabled?: boolean;
  open_account_url?: string;
  favicon: string;
  partner_kyc_enabled: boolean;
  enable_qr: boolean;
  deep_link_host: string;
  livechat_license_id: string;
  show_chat: boolean;
  show_mobile_apps_footer: boolean;
  hidden_menu: string[];
  intercom_app_id: string;
  intercom_api_base: string;
  show_intercom_chat: boolean;
  default_redirect: string;
  challenge_settings: TBrandingChallengeSettings;
  service_fees_card_enabled: boolean;
  trading_view_calendar_settings: Partial<{
    theme: 'light' | 'dark';
    enable: boolean;
  }>;
  ui_settings: TBrandingBrandingUISettings;
  show_2fa_email: boolean;
  configure_wr_verification_enabled: boolean;
  service_fees_for_wr_enabled: boolean;
  show_security_comment: boolean;
  wr_documents_required: boolean;
  isSummaryAddAccountButtonHidden: boolean;
}>;

export type TBrandingDownloadPagePlatform = Partial<{
  alias: string;
  img_1: string;
  title: string;
  buttons: Array<TBrandingDownloadPagePlatformButton>;
  caption?: string;
  checklist: string[];
  description: string;
  caption_conditional: Array<{
    caption: string;
    show_condition: string;
  }>;
  img_stick_to_bottom: boolean;
  img_2?: string;
  order_num_mobile: number;
}>;

export type TBrandingDownloadPagePlatformButton = {
  icon: string;
  text?: string;
  anchor_url: string;
  is_outlined: boolean;
  show_condition?: string;
};

export type TBrandingChallengeSettings = {
  squawkbox: TBrandingChallengeSettingParams;
  rules_page: TBrandingChallengeSettingParams;
  payout_page: TBrandingChallengeSettingParams & {
    success_redirect: string;
  };
  summary_page: TBrandingChallengeSettingParams;
  pit_community: TBrandingChallengeSettingParams;
  tutorial_page: TBrandingChallengeSettingParams & {
    html_template: string;
  };
  downloads_page: {
    challenge_enabled: boolean;
  };
  purchases_page: TBrandingChallengeSettingParams & {
    show_purchase_reset_btn: boolean;
    show_purchase_extend_btn: boolean;
    purchase_reset_btn__redirect: string;
    purchase_extend_btn__redirect: string;
    show_purchase_new_challenge_btn: boolean;
    purchase_new_challenge_btn__redirect: string;
  };
  header_settings: {
    show_account_status: boolean;
    show_client_challenge: boolean;
  };
};

export type TBrandingChallengeSettingParams = {
  is_showing: boolean;
  redirect_url: string;
};

export type TBrandingLeLegalTexts = {
  name: string;
  address: string | null;
  stamp: string | null;
  contact: string | null;
  short_license: string | null;
  aol_footer_text: string;
  por_months: number;
  signature: {
    text: string | null;
    image: string | null;
  };
  footer: {
    footer_contact_text: string | null;
    text: string | null;
    restriction: string | null;
  };
};

export type TBrandingAtp = Partial<{
  user_os: string;
  is_desktop: boolean;
  is_mobile: boolean;
  is_x64: boolean;
  links: Partial<{
    win: string;
    linux: string;
    excel: string;
    mac: string;
    ios: string;
    android: string;
    current: string;
  }>;
  lite: Partial<{
    win: string;
    linux: string;
    excel: string;
    mac: string;
    ios: string;
    android: string;
    current: string;
  }>;
  qr: string;
  web: string;
}>;

export enum AuthType {
  Credentials = 'credentials',
  OAuth = 'oauth',
}

export enum AuthHint {
  Google = 'google',
  AppleId = 'appleid',
}

export type TAuthFlow = {
  type: AuthType;
  name?: string;
  text?: string;
  hint?: AuthHint;
  binding?: boolean;
  iconDark?: string;
  iconLight?: string;
  authError?: string;
  bindError?: string;
  unbindError?: string;
  connectedEvent?: string;
  disconnectedEvent?: string;
  noBindingDescription?: string;
};

export type TWRFields = {
  wr_type: string;
  wr_fields: TWRField[];
};

export type TWRField = {
  info_text: Record<string, string>;
  info_text_visible: boolean;
  name: string;
  required: boolean;
  visible: boolean;
};

export type TPartnerLandingImages = {
  logo?: string;
  logo_inverted?: string;
  features_item_1?: string;
  features_item_2?: string;
  features_item_3?: string;
  features_item_4?: string;
  splash_bg_mobile?: string;
  benefits_cpa_icon?: string;
  splash_bg_desktop?: string;
  proceed_cpa_bg_mobile?: string;
  benefits_revshare_icon?: string;
  proceed_cpa_bg_desktop?: string;
  benefits_revshare_picture?: string;
  proceed_revshare_bg_mobile?: string;
  proceed_revshare_bg_desktop?: string;
};

export type TBrandingUISettings = {
  id: number;
  alias: string;
};

export type TBrandingBrandingUISettings = Partial<{
  include_css: string;
  icon_logo: string;
  icon_logo_small: string;
  icon_loader: string;
  icon_tooltip_info: string;
  icon_auth_privacy: string;
  download_page_platforms: TBrandingDownloadPagePlatform[];
  download_page_images: Partial<{
    api_fix: string;
    api_http: string;
    desktop: string;
    mobile_android: string;
    mobile_ios: string;
    online: string;
    wealth_android: string;
    wealth_ios: string;
  }>;
  account_selector_position: TValueOf<typeof ACCOUNT_SELECTOR_POSITION>;
  partner_landing_images: TPartnerLandingImages;
  clientsarea_2_0_v2: TClientsareaTheme;
}>;
