import { TFunction } from 'i18next';
import { isPossiblePhoneNumber } from 'react-ui-kit-exante';
import { object, string } from 'yup';

export const PERSONAL_INFO_FORM_VALIDATION_SCHEMA = (
  t: TFunction,
  isCorporate?: boolean,
  isEmailError?: boolean,
) =>
  object().shape(
    {
      full_name: string().required(t('full_name__required')),
      email: string()
        .required(t('email__required'))
        .test('validate-email', t('email_input__exists'), () => {
          return !isEmailError;
        }),
      full_phone: string()
        .required(t('full_phone__required'))
        .test(
          'validate-phone',
          t('generic__phone_input__enter_existing'),
          (value = '') => {
            return isPossiblePhoneNumber(value);
          },
        ),
      tax_payer_number: !isCorporate
        ? string().when('tax_residence', {
            is: (tax_residence: any) => !!tax_residence,
            then: (schema) => schema.required(t('tax_payer_number__required')),
          })
        : string().notRequired(),
      tax_residence: string().when('tax_payer_number', {
        is: (tax_payer_number: any) => !!tax_payer_number,
        then: (schema) => schema.required(t('tax_residence__required')),
      }),
      place_of_birth: !isCorporate
        ? string().required(t('place_of_birth__required'))
        : string().notRequired(),
      date_of_birth: !isCorporate
        ? string().nullable().required(t('date_of_birth__required'))
        : string().notRequired(),
      company_name: isCorporate
        ? string().required(t('company_name__required'))
        : string().notRequired(),
      legal_entity_identifier: string().notRequired(),
      company_classification: string().notRequired(),
    },
    [['tax_payer_number', 'tax_residence']],
  );
