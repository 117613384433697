import { Outlet } from 'react-router-dom';

import { Footer } from 'components/Footer';
import { StyledMainContent } from 'components/Main/Main.styled';
import { useRouteData } from 'hooks/useRouteData';

import { StyledLoginFooter } from '../LoginFooter/LoginFooter.styled';
import { LoginHeader } from '../LoginHeader';

export const LoginMain = () => {
  const isFooterEmpty = useRouteData().emptyFooter;

  return (
    <>
      <LoginHeader />
      <StyledMainContent className="page__content">
        <Outlet />
      </StyledMainContent>
      {isFooterEmpty ? (
        <StyledLoginFooter className="EmptyLoginFooter" />
      ) : (
        <Footer />
      )}
    </>
  );
};
