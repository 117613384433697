import axios from 'axios';
import { Notification } from 'react-ui-kit-exante';

import { NOTIFICATION } from 'constants/NOTIFICATION';
import { i18nLocal } from 'helpers/translate';

import { notificationErrorsTextMap } from './notificationErrors';
import { TNotificationDictionary } from './notifyWith.types';

export const genericNetworkError = (error: any) => {
  let errorDict: TNotificationDictionary = NOTIFICATION.ERROR.GENERIC;

  if (axios.isAxiosError(error)) {
    const status = error.request.status;

    if (status in notificationErrorsTextMap) {
      errorDict = notificationErrorsTextMap[status];
    } else if (
      ['get', 'head', 'options', undefined].includes(
        error.config.method?.toLowerCase(),
      )
    ) {
      errorDict = NOTIFICATION.ERROR.GENERIC;
    } else {
      errorDict = NOTIFICATION.ERROR.POST;
    }
  }

  const translation = i18nLocal.translate(errorDict.KEY, errorDict.MESSAGE);

  Notification.error({ title: translation });
};
