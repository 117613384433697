import { TTaxReportsState } from './TaxReportsContext.types';

export const initialState: TTaxReportsState = {
  isLoading: false,
  stateData: null,
  acceptTerms: () => {},
  isLoadingAcceptTerms: false,
  updateReports: () => {},
  isLoadingReports: false,
  reports: [],
  setYearsRequested: () => {},
};

export const TAX_REPORTS_ITEM_LIMIT = 100;

export const TAX_REPORTS_REFRESH_RATE_MILLISECONDS = 1000;
