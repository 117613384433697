import { SettingsItemActions } from './useSettingsItem.actions';
import {
  TSettingsItemReducer,
  TSettingsItemState,
} from './useSettingsItem.types';

export const useSettingsItemReducer: TSettingsItemReducer = (
  state,
  action,
): TSettingsItemState => {
  switch (action.type) {
    case SettingsItemActions.OnChangeEmails: {
      return {
        ...state,
        error: null,
        emails: action.payload,
      };
    }

    case SettingsItemActions.OnChangeTerminal: {
      return {
        ...state,
        terminal: action.payload,
      };
    }

    case SettingsItemActions.OnChangeInternalCA: {
      return {
        ...state,
        internalCA: action.payload,
      };
    }

    case SettingsItemActions.OnChangeSms: {
      return {
        ...state,
        sms: action.payload,
      };
    }

    case SettingsItemActions.OnResetError: {
      return {
        ...state,
        error: null,
      };
    }

    case SettingsItemActions.OnSetError: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};
