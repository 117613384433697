import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  tableVisibilityService,
  TTableVisibilityColumn,
} from 'services/tables';

export const fetchTablesVisibility = createAsyncThunk<
  Record<string, boolean> | null,
  undefined
>('visibleTables/fetch', async () => tableVisibilityService.getTables());

export const updateTableVisibility = createAsyncThunk(
  'updateVisibleTables/fetch',
  async (tables: Record<string, boolean>) => {
    await tableVisibilityService.updateTableVisibility(tables);
  },
);

export const fetchColumnsVisibility = createAsyncThunk(
  'visibleColumns/fetch',
  (tableId: string) => tableVisibilityService.getTableColumns(tableId),
);

export const updateColumnsVisibility = createAsyncThunk(
  'updateVisibleColumns/fetch',
  async (data: TTableVisibilityColumn) => {
    await tableVisibilityService.updateTableColumnVisibility(data);
  },
);
