/**
 * Removes one or multiple forward slash occurrences
 * at the beginning and at the end of the string
 *
 * Example:
 * - '/trades/' => 'trades'
 * - '//deposit/transfer/' => 'deposit/transfer'
 *
 * @param url
 */
export const trimUrlSlashes = (url: string) => {
  return url.replace(/^(\/+)|(\/+)$/g, '');
};
