import Cookies from 'js-cookie';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useData } from 'react-ui-kit-exante';

import { BrandingContext, TranslationContext } from 'contexts';
import { TAuthFlow } from 'services/branding';
import { externalAccountsService } from 'services/settings/externalAccounts';

import {
  getOAuthFlows,
  removeGoogleAlreadyUsedCookie,
} from './ExternalAccouns.helpers';
import { GOOGLE_LOGIN_USED_COOKIE_NAME } from './ExternalAccounts.constants';
import {
  StyledErrorLabel,
  StyledExternalAccountsBlock,
  StyledExternalAccountsContainer,
  StyledExternalAccountsLabel,
} from './ExternalAccounts.styled';
import { TBinding } from './ExternalAccounts.types';
import { ExternalAccountsSkeleton } from './ExternalAccountsSkeleton';
import { Account } from './components/Account';

export const ExternalAccounts = () => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const { fetchData, data } = useData({
    onFetch: async () =>
      externalAccountsService.getExternalAccountsInfo(currentLanguage),
  });

  const flowsOauth: TAuthFlow[] = getOAuthFlows(branding, true) || [];

  const isActiveAccount = useCallback(
    (serviceId: string) => {
      if (!data) {
        return false;
      }
      return data.some(
        (binding: TBinding) => binding.providerName === serviceId,
      );
    },
    [data],
  );

  const errorLabel = useMemo(() => {
    let errorText = null;
    if (Cookies.get(GOOGLE_LOGIN_USED_COOKIE_NAME) === 'True') {
      errorText = 'layout__settings__google_used';
    }

    if (errorText) {
      return (
        <StyledErrorLabel className="ExternalAccountsErrorLabel">
          {t(errorText, { NAME: branding?.wl_name })}
        </StyledErrorLabel>
      );
    }
    return null;
  }, []);

  useEffect(() => {
    fetchData();
    removeGoogleAlreadyUsedCookie();
  }, []);

  if (!data) {
    return <ExternalAccountsSkeleton />;
  }

  return (
    <StyledExternalAccountsBlock className="ExternalAccounts">
      <StyledExternalAccountsLabel className="ExternalAccountsLabel">
        {t('layout__security_tabs__external_accounts__label', {
          WL_NAME: branding?.wl_name,
        })}
      </StyledExternalAccountsLabel>
      <StyledExternalAccountsContainer className="ExternalAccountsContainer">
        {flowsOauth.map(
          (flow) =>
            flow.hint &&
            flow.name &&
            flow.iconLight && (
              <Account
                key={flow.hint}
                name={t(flow.name)}
                icon={flow.iconLight}
                id={flow.hint}
                isActive={isActiveAccount(flow.hint)}
                refreshData={fetchData}
              />
            ),
        )}
      </StyledExternalAccountsContainer>
      {errorLabel}
    </StyledExternalAccountsBlock>
  );
};
