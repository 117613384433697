import { yupResolver } from '@hookform/resolvers/yup';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useData } from 'react-ui-kit-exante';

import { CurrencyContext, TranslationContext } from 'contexts';
import { notifyWith } from 'helpers/notifyWith';
import { PATHS } from 'router/router.constants';
import { reportsService } from 'services/reports';
import { TReportItem } from 'services/reports/reports.types';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import {
  EDIT_REPORT_FORM_ITEM_VALIDATION_SCHEMA,
  initialState,
} from './ReportFormContext.constants';
import {
  formatReportFormItems,
  getFieldsUpdated,
} from './ReportFormContext.helpers';
import {
  TCustomReportForm,
  TReportFormContextProps,
  TReportFormContextState,
} from './ReportFormContext.types';

export const ReportFormContext =
  createContext<TReportFormContextState>(initialState);

export const ReportFormProvider: FC<TReportFormContextProps> = ({
  children,
  reportId,
  onSave,
}) => {
  const navigate = useNavigate();

  const { t, currentLanguage } = useContext(TranslationContext);
  const { currency: currencyGlobal, isCurrencyLoading } =
    useContext(CurrencyContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const [isSaving, setIsSaving] = useState(false);
  const [deletedIds, setDeletedIds] = useState<TReportItem['id'][]>([]);

  const {
    data: report,
    isLoading: isLoadingReport,
    fetchData: fetchReport,
  } = useData({
    onFetch: async () => {
      if (reportId && currentLanguage) {
        const response = await reportsService.getReport(
          currentLanguage,
          reportId,
        );

        // if not found, or any error that leads to non-loaded report,
        // redirect to the list
        if (!response) {
          const listUrl = `${PATHS.ROOT}${PATHS.CUSTOM_REPORTS}`;
          // redirect
          navigate(listUrl);
        }

        return response;
      }

      return null;
    },
  });

  const {
    data: refs,
    isLoading: isLoadingRefs,
    fetchData: fetchRefs,
  } = useData({
    onFetch: async () => {
      if (currentLanguage) {
        return reportsService.getReportsRefs(currentLanguage);
      }

      return null;
    },
  });

  useEffect(() => {
    fetchRefs();
  }, [currentLanguage]);

  useEffect(() => {
    fetchReport();
  }, []);

  const schema = EDIT_REPORT_FORM_ITEM_VALIDATION_SCHEMA(t);

  const methods = useForm<TCustomReportForm>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { fields, append, replace, remove, update } = useFieldArray({
    control: methods.control,
    keyName: 'formId',
    name: 'items',
  });

  const types = useMemo(() => refs?.types || {}, [refs?.types]);

  const handleRemove = (index: number) => {
    const id = fields[index].id;

    if (id) {
      setDeletedIds((prev) => [...prev, id]);
    }

    const reportType = fields[index].report_type;

    const numReportsOfType = fields.filter(
      (field) => field.report_type === reportType,
    ).length;

    if (numReportsOfType > 1) {
      remove(index);
    } else {
      // do not remove the latest item to prevent
      // unneeded disposal of the item of the type,
      // just clean it
      update(index, {
        report_type: reportType,
        date_from: undefined,
        date_to: undefined,
        trades_grouping: '',
      });
    }
  };

  // in some cases it's necessary to fill the form with these
  // "initial" values of existing report loaded after the form initialized
  useEffect(() => {
    if (!report) {
      return;
    }

    const format = methods.getValues('format');
    const currency = methods.getValues('currency');
    const accounts = methods.getValues('accounts');

    methods.setValue('format', format || report.format || []);
    methods.setValue('currency', currency || report.currency);
    methods.setValue('accounts', accounts || report.accounts);
  }, [report]);

  // in some cases it's necessary to fill the form with these
  // "initial" values of selected account loaded after the form initialized
  useEffect(() => {
    if (reportId == null && selectedAccountId) {
      const accounts = methods.getValues('accounts');

      methods.setValue('accounts', accounts || [selectedAccountId]);
    }
  }, [selectedAccountId]);

  // in some cases it's necessary to fill the form with this
  // "initial" value of user currency loaded after the form initialized
  useEffect(() => {
    if (reportId == null && currencyGlobal && !isCurrencyLoading) {
      const currency = methods.getValues('currency');

      methods.setValue('currency', currency || currencyGlobal);
    }
  }, [currencyGlobal, isCurrencyLoading]);

  // overlay remote values with local changes,
  // add fillers for each report type if needed
  useEffect(() => {
    const reportItems = reportId ? report?.items : [];

    if (reportItems == null) {
      return;
    }

    const newItems = getFieldsUpdated(reportItems, fields, deletedIds, types);

    replace(newItems);
  }, [report?.items, types, deletedIds, fields.length]);

  const handleSubmit = methods.handleSubmit(
    async (formData: TCustomReportForm) => {
      const dataFiltered = {
        ...formData,
        items: formatReportFormItems(formData.items, formData.format),
        name: '',
      };

      setIsSaving(true);

      const reportSaved = await reportsService.createOrUpdateReport(
        currentLanguage,
        dataFiltered,
        reportId,
      );

      if (reportSaved) {
        notifyWith.success('Request is queued.');
        onSave?.();
      }

      setIsSaving(false);
    },
  );

  const isLoading = isLoadingReport || isLoadingRefs || isCurrencyLoading;

  const value = useMemo(
    () => ({
      isLoading,
      isSaving,
      refs,
      report,
      items: fields,
      appendItem: append,
      removeItem: handleRemove,
      handleSubmit,
      formControl: methods.control,
    }),
    [
      isLoadingRefs,
      isLoadingReport,
      isSaving,
      refs,
      report,
      fields,
      append,
      handleRemove,
      handleSubmit,
      methods.control,
    ],
  );

  return (
    <ReportFormContext.Provider value={value}>
      <FormProvider {...methods}>{children}</FormProvider>
    </ReportFormContext.Provider>
  );
};
