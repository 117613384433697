import { FC, useContext } from 'react';

import { CurrencyContext, TranslationContext } from 'contexts';
import { useTheme } from 'theme';

import { NAME_MAP } from '../Chart.constants';

import {
  StyledChartYAxisName,
  StyledLegendBar,
  StyledLegendBars,
  StyledLegendContainer,
  StyledLegendItem,
  StyledLegendLine,
} from './CustomLegend.styled';
import { TLegendProps } from './CustomLegend.types';

export const CustomLegend: FC<TLegendProps> = (props) => {
  const { t } = useContext(TranslationContext);
  const { currency } = useContext(CurrencyContext);

  const theme = useTheme();

  const { payload = [], yWidth, hideAxisName, alignAxisName } = props;

  const positiveColor = theme.color.icon.source;
  const negativeColor = theme.color.icon.radical;

  return (
    <StyledLegendContainer className="LegendContainer">
      {payload.map((entry, index: number) => {
        const label = NAME_MAP(t)[entry.value] || t(entry.value);

        return (
          <StyledLegendItem key={`item-${index}`} className="LegendItem">
            <>
              {entry.type === 'line' && (
                <StyledLegendLine
                  style={{ backgroundColor: entry.color }}
                  className="LegendLine"
                />
              )}
              {entry.type === 'rect' && (
                <StyledLegendBars className="LegendBars">
                  <StyledLegendBar
                    style={{ backgroundColor: positiveColor }}
                    className="LegendBarPositive"
                  />
                  <StyledLegendBar
                    style={{ backgroundColor: negativeColor }}
                    className="LegendLineNegative"
                  />
                </StyledLegendBars>
              )}
              {t(label, label)}
            </>
          </StyledLegendItem>
        );
      })}
      {!hideAxisName && (
        <StyledChartYAxisName
          className="ChartYAxisName"
          width={yWidth}
          alignAxisName={alignAxisName}
        >
          {currency}
        </StyledChartYAxisName>
      )}
    </StyledLegendContainer>
  );
};
