import { ReportType } from 'services/reports/reports.types';

export const REPORT_TYPE_GROUPED = 'trades';

export const REPORT_TYPES_TERMS: string[] = [
  'short allowance',
  'overnights',
  'commissions',
];

export const REPORT_TYPES_WITH_TIME = ['trades', 'transactions'];
export const REPORT_TYPES_WITH_SYMBOLS_IDS = ['daily position'];

export const REPORT_BUILDS_PERIODIC = ['daily', 'monthly'];

/**
 * Report type title overrides
 *
 * Used specifically for design purposes
 */
export const REPORT_TYPE_TITLES: Record<ReportType | string, string> = {
  // NOTE: Spaces are required because the values are the i18n keys
  [ReportType.Summary]: 'Account Summary ',
  [ReportType.Margin]: 'margin_structure',
  [ReportType.Transactions]: 'financial_transactions',
  [ReportType.Trades]: 'Trades',
  [ReportType.Performance]: 'Performance Report',
  [ReportType.Costs]: 'Costs and Charges Report',
  [ReportType.Partner]: "Partner's Statistics",
  [ReportType.Tax]: 'Tax report',
  [ReportType.SubuserWithdrawals]: 'Subuser Withdrawals Report',
  [ReportType.Commissions]: 'short_title__commissions_report',
  [ReportType.Overnights]: 'short_title__overnights_report',
  [ReportType.ShortAllowance]: 'short_title__short_allowance_report',
  [ReportType.WLPCashBalance]: 'short_title__wlp_cash_balance_report',
  [ReportType.DailyPosition]: 'short_title__daily_position_report',
};
