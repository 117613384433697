import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useRef, useState } from 'react';

export const useCaptcha = () => {
  const [captchaToken, setCaptchaToken] = useState('');

  const captchaRef = useRef<HCaptcha>(null);

  const [captcha, setCaptcha] = useState({
    enabled: false,
    visible: false,
    site_key: '',
    error: '',
  });

  const resetCaptcha = () => {
    setCaptcha({ enabled: false, visible: false, site_key: '', error: '' });
    setCaptchaToken('');
  };

  return {
    captcha,
    setCaptcha,
    resetCaptcha,
    captchaRef,
    captchaToken,
    setCaptchaToken,
  };
};
