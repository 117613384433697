import Decimal from 'decimal.js';
import { ChangeEvent, useEffect } from 'react';

import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';

import { validateAmountDefault } from '../AmountForm.constants';
import { AmountFormAllfundsToggleOption } from '../components';

import { TUseAmountFormProps } from './useAmountForm.types';

export const useAmountForm = ({
  currencies,
  amount,
  limit,
  onAmountChange,
  currency,
  onSubmit,
  isSubmitting,
  fee,
  amountValidator = validateAmountDefault,
  maximumFractionDigits,
  noAmountCleanup,
  withdrawalAllFundsValue,
  disableAllFundsButton = false,
}: TUseAmountFormProps) => {
  const safeLimit = limit || 0;
  const formatter = useCurrencyFormatter(currency);
  const mpi = fee?.mpi || 2;
  const isAllFunds =
    withdrawalAllFundsValue === AmountFormAllfundsToggleOption.Allfunds;

  const parsedAmount = Number(amount) || 0;
  const parsedFeeText = fee?.effectiveText || 0;
  const allFundsFee = fee?.allFunds || 0;
  const parsedFee =
    (isAllFunds && allFundsFee) ||
    (typeof fee?.effective === 'function'
      ? fee?.effective(parsedAmount)
      : fee?.effective || 0);

  const feeValue = formatCurrency(
    formatter,
    isAllFunds ? allFundsFee : parsedFee,
    false,
    maximumFractionDigits,
  );

  const effectiveLimit: number =
    safeLimit < allFundsFee
      ? 0
      : new Decimal(safeLimit).minus(allFundsFee).toNumber();

  const parsedRemainSum: number = new Decimal(effectiveLimit)
    .minus(parsedAmount)
    .toNumber();
  const remainSum = formatCurrency(
    formatter,
    parsedRemainSum,
    false,
    maximumFractionDigits,
  );

  const amountWithFee = parsedAmount + parsedFee;
  const total = formatCurrency(
    formatter,
    amountWithFee,
    false,
    maximumFractionDigits,
  );
  const formattedLimit = formatCurrency(
    formatter,
    effectiveLimit,
    false,
    maximumFractionDigits,
  );
  const formattedAllFundsTotal = formatCurrency(
    formatter,
    safeLimit,
    false,
    maximumFractionDigits,
  );

  const yougetValue = isAllFunds ? effectiveLimit : parsedAmount;

  const yougetText = formatCurrency(
    formatter,
    yougetValue,
    false,
    maximumFractionDigits,
  );
  const isError = !isAllFunds && parsedRemainSum < 0;

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (!amountValidator(value)) {
      return;
    }

    onAmountChange(value);
  };

  const allFunds = new Decimal(safeLimit).minus(allFundsFee);
  const allFundsPositive = allFunds.toNumber() > 0;

  const handleAmountButtonClick = () => {
    if (!isSubmitting && !disableAllFundsButton && allFundsPositive) {
      onAmountChange(allFunds.toFixed(mpi, Decimal.ROUND_DOWN).toString());
    }
  };

  useEffect(() => {
    if (!noAmountCleanup) {
      onAmountChange('');
    }
  }, [currency]);

  return {
    parsedAmount,
    parsedFee,
    feeText: feeValue,
    feeValue: parsedFee,
    feeValueText: parsedFeeText,
    yougetText,
    remainSum,
    allFundsPositive,
    amountWithFee,
    total: isAllFunds ? formattedAllFundsTotal : total,
    effectiveLimit,
    formattedLimit,
    isError,
    handleAmountChange,
    handleAmountButtonClick,
    handleSubmit: onSubmit,
    currencies,
  };
};
