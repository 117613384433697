import { styled } from 'theme';

export const StyledPageWrapper = styled('div')`
  min-height: 100%;
  padding: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
    padding: 0 18px;
  }
`;
