import { mapValues, toString as lodashToString } from 'lodash';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import {
  calculateCountOfPages,
  IconButton,
  IFilteringProps,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { PDF, XLS } from 'constants/common';
import { defaultDateRange } from 'constants/dates';
import { BASE_URL } from 'constants/endpoints';
import { REPORT_DOWNLOAD_ICON_NAME } from 'constants/icons';
import { TabsContextData, TranslationContext } from 'contexts';
import { Dates } from 'helpers/dates';
import { getUploadLink } from 'helpers/getUploadLink';
import { prepareToQueryParams } from 'helpers/prepareToQueryParams';
import { useLogHandleTime, usePrevious } from 'hooks/index';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useTableTabCache } from 'hooks/useTableTabCache';
import { UpdatePortfolioContext } from 'pages/Portfolio/contexts';
import {
  TRANSACTIONS_CACHE_ID,
  TRANSACTIONS_QUERY_FILTER_KEYS,
  TRANSACTIONS_TABLE_ID,
} from 'pages/Portfolio/tabs/Transactions/Transactions.constants';
import {
  fetchTransactions,
  multiSelectFilterToArray,
} from 'pages/Portfolio/tabs/Transactions/Transactions.helpers';
import {
  TFetchTransactionsHelperValue,
  TTransactionsFilters,
} from 'pages/Portfolio/tabs/Transactions/Transactions.types';
import {
  columnKeys,
  getColumns,
  transactionsDisplayedColumnKeys,
} from 'pages/Portfolio/tabs/Transactions/columns';
import { MENU_ALIAS } from 'services/menu';
import { TTransactionData } from 'services/transactions';
import { selectSelectedDate } from 'store/accounts/accounts.selectors';
import { useAppSelector } from 'store/hooks';
import { TUseTableDataProps } from 'types/TUseTableDataProps';

export const TransactionsContainer = () => {
  const [selectedAccountId, isAccountsFirstLoading] = useAppSelector(
    (state) => [
      state.accounts.selectedAccountId,
      state.accounts.isAccountsFirstLoading,
    ],
  );
  const selectedDate = useAppSelector(selectSelectedDate);
  const { needToUpdateAccountData } = useContext(UpdatePortfolioContext);
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);
  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter(true);
  const { availableTabs } = useContext(TabsContextData);
  const [silentUpdate, setSilentUpdate] = useState<boolean>(false);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('transactions-tab');

  const accountId = selectedAccountId || '';
  const prevSelectedDate = usePrevious(selectedDate);

  const tabAvailable = useMemo<boolean>(
    () => !!availableTabs.find((tab) => tab.id === MENU_ALIAS.TRANSACTIONS),
    [availableTabs],
  );

  const tableDataArgs = useMemo<
    TUseTableDataProps<TFetchTransactionsHelperValue | null>
  >(
    () => ({
      tableId: TRANSACTIONS_TABLE_ID,
      data: {
        onFetch: (args) => {
          setStartHandleTime();

          return fetchTransactions({
            ...args,
            accountId,
            tabAvailable,
          }).finally(() => logHandleTime());
        },
      },
      filters: {
        getDefaultFilters: () => ({
          timestamp: defaultDateRange,
        }),
      },
    }),
    [accountId, tabAvailable],
  );

  const {
    isLoading: transactionsLoading,
    data,
    limit,
    skip,
    setLimit,
    setPage,
    page,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters: tableFilters,
    sorting,
    fetchData,
  } = useTableData<TFetchTransactionsHelperValue | null>(tableDataArgs);

  const { data: serverTransactions, total } = data || {};

  const { cache, useCache } = useTableTabCache<
    TTransactionData[],
    TTransactionsFilters
  >({
    cacheId: TRANSACTIONS_CACHE_ID,
    tableFilters,
    tableDataLoading: transactionsLoading,
    tableData: serverTransactions,
  });
  const cachedTransactions = cache?.tableData;
  const cachedFilters = cache?.tableFilters;

  const filters: TTransactionsFilters =
    (useCache && cachedFilters) || tableFilters;

  const typeFilters = filters?.operationType as string[] | string | undefined;

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        operationTypes:
          data?.operationTypes.map((type) => ({
            value: type,
            label: type,
          })) || [],
        t,
        currencyFormatter,
        dateFormatter,
      }),
    [setFilter, removeFilter, data?.operationTypes, t],
  );

  const filteringProps = useMemo<IFilteringProps<TTransactionData>>(
    () => ({
      removeAllFilters: resetFilters,
      filters: {
        ...filters,
        // FIXME: nullize empty date values until fixed in ui-kit
        ...(filters.timestamp && {
          timestamp: filters.timestamp.map((v) => v || undefined),
        }),
      },
      manualFilters: true,
    }),
    [resetFilters, filters],
  );

  const transactions = useMemo<TTransactionData[]>(() => {
    return (useCache && cachedTransactions) || serverTransactions || [];
  }, [useCache, cachedTransactions, serverTransactions]);

  const uploadParams = useMemo(() => {
    const { timestamp, operationType } = filters;
    const {
      dateRange: [dateFrom, dateTo],
      ...preparedParams
    } = prepareToQueryParams({
      accountId,
      dateRange: timestamp,
      sorting,
      columnKeys: [...columnKeys],
      limit,
      skip,
      operationTypes: multiSelectFilterToArray(operationType),
      recordsAmount: 10000,
    });

    const transactionsParams = {
      ...preparedParams,
      id: accountId,
      date_from: dateFrom,
      date_to: dateTo,
      operation_type: preparedParams.operationTypes,
    };

    return new URLSearchParams(
      mapValues(transactionsParams, lodashToString),
    ).toString();
  }, [accountId, filters, limit, skip, sorting]);

  const uploadLinks = useMemo(
    () => ({
      pdf: getUploadLink(PDF, 'transactions', BASE_URL, uploadParams),
      xls: getUploadLink(XLS, 'transactions', BASE_URL, uploadParams),
    }),
    [uploadParams],
  );

  const availableOperationTypes: string[] | undefined = data?.operationTypes;

  useEffect(() => {
    if (!typeFilters || !availableOperationTypes) {
      return;
    }

    const actualFilters: string[] = multiSelectFilterToArray(
      typeFilters,
    ).filter((type) => availableOperationTypes.includes(type));

    if (actualFilters.length === typeFilters.length) {
      return;
    }

    setFilter(TRANSACTIONS_QUERY_FILTER_KEYS.OPERATION_TYPE, actualFilters);
  }, [availableOperationTypes, typeFilters]);

  useEffect(() => {
    if (
      selectedDate &&
      prevSelectedDate &&
      selectedDate.getTime() !== prevSelectedDate.getTime()
    ) {
      setFilter(
        TRANSACTIONS_QUERY_FILTER_KEYS.DATE,
        Dates.getDateRangeForTable([selectedDate, selectedDate]),
      );
    }
  }, [selectedDate]);

  useEffect(() => {
    if (needToUpdateAccountData) {
      if (transactionsLoading) {
        return;
      }

      setSilentUpdate(true);
      fetchData().finally(() => setSilentUpdate(false));
    }
  }, [needToUpdateAccountData]);

  useEffect(() => {
    if (!filters || !accountId) {
      return;
    }

    if (filters.timestamp !== undefined) {
      setFilter(TRANSACTIONS_QUERY_FILTER_KEYS.DATE, filters.timestamp);
    }
    if (filters.operationType) {
      setFilter(
        TRANSACTIONS_QUERY_FILTER_KEYS.OPERATION_TYPE,
        filters.operationType,
      );
    }
  }, [accountId]);

  const isTableLoading: boolean =
    !silentUpdate &&
    !useCache &&
    (!accountId ||
      transactionsLoading ||
      isAccountsFirstLoading ||
      isTranslationsLoading);

  return (
    <Table<TTransactionData>
      useBrandedDatePicker
      tableId={TRANSACTIONS_TABLE_ID}
      displayedColumnKeys={transactionsDisplayedColumnKeys}
      columns={columns}
      data={transactions}
      isLoading={isTableLoading}
      translator={t}
      locale={currentLanguage}
      showScrollbar
      showTableInfo
      hasFilters
      isFlexLayout
      filteringProps={filteringProps}
      manualSortBy
      hasPagination
      defaultSortBy={[{ id: TRANSACTIONS_QUERY_FILTER_KEYS.DATE, desc: true }]}
      onSort={setSorting}
      serverPaginationProps={{
        pageSize: limit,
        setPage,
        setPageSize: setLimit,
        pageIndex: page,
        total: total || 0,
        pageCount: calculateCountOfPages(total || 0, limit),
      }}
      additionalActions={([PDF, XLS] as const).map((format) => ({
        component: (
          <IconButton
            iconName={REPORT_DOWNLOAD_ICON_NAME[format] || 'DownloadIcon'}
            iconSize={24}
            iconColor="secondary"
            className="TransactionsLink"
            onClick={() => window.open(uploadLinks[format])}
            title={format.toUpperCase()}
          />
        ),
      }))}
    />
  );
};

export const Transactions = memo(TransactionsContainer);
