import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { TUseAccountsBalancesValue } from 'hooks/useAccountsBalances/useAccountsBalances.types';
import { useLogHandleTime } from 'hooks/useLogHandleTime';
import { accountsService, TAccountsBalances } from 'services/accounts';
import { ACCOUNT_TYPE } from 'services/accounts/accounts.constants';
import { selectAccounts } from 'store/accounts';

export const useAccountsBalances = (
  currentLanguage: string,
  excludeAsset = true,
): TUseAccountsBalancesValue => {
  const [isBalancesLoading, setIsBalancesLoading] = useState(true);

  const [balances, setBalances] = useState<
    Partial<Record<string, TAccountsBalances>>
  >({});

  const { selectedAccount } = useSelector(selectAccounts);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('accounts-balances');
  const updateBalance: TUseAccountsBalancesValue['updateBalance'] = async (
    accountId,
    currency,
    force,
  ) => {
    if (force) {
      setIsBalancesLoading(true);
    }

    const isAggregated = selectedAccount?.types.includes(
      ACCOUNT_TYPE.AGGREGATED,
    );

    try {
      if (isAggregated) {
        return;
      }
      setStartHandleTime();
      const balancesResp = await accountsService.fetchAccountsBalance({
        accountIds: accountId,
        currency,
        lang: currentLanguage,
        excludeAsset,
      });
      logHandleTime();

      if (!balancesResp) {
        return;
      }

      setBalances((prev) => ({
        ...prev,
        [currency]: {
          ...prev?.[currency],
          [accountId]: balancesResp[accountId],
        },
      }));
    } finally {
      setIsBalancesLoading(false);
    }
  };

  return useMemo<TUseAccountsBalancesValue>(
    (): TUseAccountsBalancesValue => ({
      isBalancesLoading,
      balances,
      updateBalance,
    }),
    [isBalancesLoading, balances, updateBalance],
  );
};
