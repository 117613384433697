import { AxiosError } from 'axios';
import { useContext, useReducer } from 'react';

import { TOOMANY_ERROR } from 'constants/api';
import { TranslationContext } from 'contexts';
import { mfaService } from 'services/mfa';

import { PasswordVerificationActions } from './usePasswordVerification.actions';
import { passwordVerificationInitialState } from './usePasswordVerification.constants';
import { usePasswordVerificationReducer } from './usePasswordVerification.reducer';
import { TPasswordVerificationReducer } from './usePasswordVerification.types';

export const usePasswordVerification = (onSuccess: (key: string) => void) => {
  const { t } = useContext(TranslationContext);
  const [state, dispatch] = useReducer<TPasswordVerificationReducer>(
    usePasswordVerificationReducer,
    passwordVerificationInitialState,
  );

  const handlePasswordChange = (payload: string) => {
    dispatch({ type: PasswordVerificationActions.OnPasswordChange, payload });
  };

  const handleVerify = async () => {
    dispatch({ type: PasswordVerificationActions.VerifyStart });

    try {
      const response = await mfaService.checkPassword(state.password, 'site');

      if (response) {
        dispatch({ type: PasswordVerificationActions.VerifySuccess });
        onSuccess(response.key);
      } else {
        dispatch({
          type: PasswordVerificationActions.VerifyError,
          payload: t('layout__actions__unknown_error_please_refresh_page'),
        });
      }
    } catch (error) {
      const { response } = error as AxiosError;

      let payload = t('layout__settings__mfa__invalid_password');

      if (response?.status === TOOMANY_ERROR) {
        payload = t('layout__settings__mfa__resend_429');
      }

      dispatch({
        payload,
        type: PasswordVerificationActions.VerifyError,
      });
    }
  };

  return {
    ...state,
    handlePasswordChange,
    handleVerify,
  };
};
