import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

import {
  TProductImageContainerProps,
  TProductImageItemDoubleProps,
  TProductImageItemProps,
} from './ProductImage.types';

export const StyledProductImageWrapper = styled('div', {
  shouldForwardProp: blockNonNativeProps(['stickToBottom']),
})<TProductImageContainerProps>`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  overflow: hidden;

  padding: ${({ stickToBottom }) => (stickToBottom ? '0' : '0 0 24px')};

  ${({ theme }) => theme.breakpoints.up('lr')} {
    width: 100%;

    padding: ${({ stickToBottom }) => (stickToBottom ? '48px 0 0' : '48px 0')};
  }
`;

export const StyledProductImageWrapperDouble = styled(
  StyledProductImageWrapper,
  {
    shouldForwardProp: blockNonNativeProps(['stickToBottom']),
  },
)<TProductImageContainerProps>`
  ${({ theme }) => theme.breakpoints.up('lr')} {
    width: 100%;

    height: ${({ stickToBottom }) =>
      stickToBottom ? 'calc(100% + 96px)' : '100%'};

    margin-bottom: 0;
  }
`;

export const StyledProductImage = styled('div', {
  shouldForwardProp: blockNonNativeProps(['stickToBottom', 'imageUrl']),
})<TProductImageItemProps>`
  width: 100%;

  margin: 0 auto;

  height: ${({ stickToBottom }) => (stickToBottom ? '345px' : '210px')};

  background-size: ${({ stickToBottom }) =>
    stickToBottom ? 'cover' : 'contain'};
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: ${({ stickToBottom }) =>
    stickToBottom ? 'initial' : 'center'};
  ${({ imageUrl }) => imageUrl && `background-image: url(${imageUrl});`}

  ${({ theme }) => theme.breakpoints.up('lr')} {
    height: 100%;

    min-height: ${({ stickToBottom }) => (stickToBottom ? '345px' : 'initial')};

    background-position-y: ${({ stickToBottom }) =>
      stickToBottom ? 'top' : 'center'};

    background-size: ${({ stickToBottom }) =>
      stickToBottom ? 'cover' : 'contain'};
    max-width: 380px;
  }
`;

export const StyledProductImageDouble = styled(StyledProductImage, {
  shouldForwardProp: blockNonNativeProps(['stickToBottom', 'imageUrls']),
})<TProductImageItemDoubleProps>`
  max-width: 320px;

  height: ${({ stickToBottom }) => (stickToBottom ? '260px' : '210px')};

  background-size: 220px, 220px;
  background-repeat: no-repeat;
  background-image: ${({ imageUrls }) =>
    imageUrls?.map((u: string) => `url(${u})`).join(', ')};

  background-position-x: left, right;
  background-position-y: ${({ stickToBottom }) =>
    stickToBottom ? 'top, 15px' : 'center'};

  ${({ theme }) => theme.breakpoints.up('lr')} {
    max-width: 380px;

    position: relative;

    background-position-y: ${({ stickToBottom }) => {
      return stickToBottom ? 'top, 15px' : 'center';
    }};

    background-size: contain;
  }
`;
