import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { formatCurrency, formatPercentage } from 'helpers/formatters';
import {
  TAssetManagementFeeSetup,
  TAssetManagementFeeUsers,
} from 'services/assetManagement/assetManagement.types';

import { TGetAssetManagementClientsTableColumnsParams } from './AssetManagementClientsTable.types';
import { StatusCell } from './cells/StatusCell';

export const getAssetManagementClientsTableColumns = ({
  t,
  currencyFormatter,
}: TGetAssetManagementClientsTableColumnsParams): IColumn<TAssetManagementFeeUsers>[] => [
  {
    id: 'client_name',
    Header: t('asset_management__table__client_name'),
    accessor: 'client_name',
  },
  {
    id: 'setup_name',
    Header: t('asset_management__table__setup_name'),
    accessor: 'fee_setup.name',
  },
  {
    id: 'value',
    Header: t('asset_management__setup_item__profit_fee'),
    accessor: 'fee_setup.value',
    Cell: ({ row: { values } }: ICellValue<TAssetManagementFeeSetup>) => {
      return formatPercentage(values.value);
    },
  },
  {
    id: 'management_fee',
    Header: t('asset_management__setup_item__total_fee'),
    accessor: 'fee_setup.management_fee',
    Cell: ({ row: { values } }: ICellValue<TAssetManagementFeeSetup>) => {
      return (
        <>
          &#8364;
          {formatCurrency(currencyFormatter, values.management_fee)}
        </>
      );
    },
  },
  {
    id: 'status',
    Header: t('asset_management__status'),
    accessor: 'status',
    Cell: ({ row: { values } }: ICellValue<TAssetManagementFeeUsers>) => (
      <StatusCell status={values.status} />
    ),
  },
];
