import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { TranslationContext } from 'contexts/TranslationContext';
import { UserDetailsContext } from 'contexts/UserDetailsContext';
import { notifyWith } from 'helpers/notifyWith';
import { useAccountsBalances } from 'hooks/useAccountsBalances';
import { TMfaType } from 'services/mfa';
import { transferService } from 'services/transfer';
import { selectAccounts } from 'store/accounts';

import { withdrawalBalanceAutoupdateEffectFactory } from '../../../../../../Transfers.helpers';
import { TransfersContext } from '../../../../../../context';
import { useTransfers } from '../../../../../../hooks';
import { FundsContext } from '../../../../context';
import { ToBankAccountDefaultValues } from '../../../../context/hooks';
import { useToBankAccountData } from '../../../../hooks/useToBankAccountData';
import { useToInternalAccountData } from '../../../../hooks/useToInternalAccountData';
import {
  ToBankAccountErrors,
  ToBankAccountFields,
  ToInternalAccountErrors,
  ToInternalAccountFields,
} from '../../../../types';

import {
  getBankAddressError,
  getBankCountryError,
  getBankNameError,
  getBeneficiaryCountryError,
  getCityError,
  getCorrespondentAccountError,
  getCorrespondentSwiftError,
  getIbanError,
  getNameError,
  getPostalCodeError,
  getShortAddressError,
  getSwiftError,
} from './helpers';

export const useAmount = () => {
  const [amount, setAmount] = useState('');
  const { currentLanguage, t } = useContext(TranslationContext);
  const { selectedAccountId } = useSelector(selectAccounts);
  const { userDetails } = useContext(UserDetailsContext);

  const {
    repeatedWithdrawal,
    transfersState,
    updateLastTransactionId,
    cashTransferTab,
  } = useTransfers();

  const { accountDetailsState } = useContext(TransfersContext);

  const {
    toMyAccountState,
    toMyAccountDispatch,
    toBankAccountState,
    toBankAccountDispatch,
    toInternalAccountState,
    toInternalAccountDispatch,
    baseName,
  } = useContext(FundsContext);

  const toInternalAccountData = useToInternalAccountData();
  const {
    requiredFields,
    isWiredToSelf,
    hiddenFields,
    commissionAccount,
    ...toBankAccountData
  } = useToBankAccountData();

  const { balances, updateBalance, isBalancesLoading } = useAccountsBalances(
    currentLanguage,
    false,
  );

  const stateValues = useMemo(() => {
    switch (cashTransferTab) {
      case 'MY_ACCOUNT': {
        const { currencies } = accountDetailsState;
        const {
          currency,
          targetAccountId,
          requestingTransactionState,
          fetchingState,
        } = toMyAccountState;

        return {
          currencies,
          currency,
          target: targetAccountId,
          isConfirming: false,
          isLoading: fetchingState.pending || fetchingState.idle,
          isSubmitting: requestingTransactionState.pending,
        };
      }

      case 'INTERNAL': {
        const {
          currency,
          targetAccountId,
          mode,
          requestingTransactionState,
          fetchingLimits,
        } = toInternalAccountState;
        const { currencies } = toInternalAccountData;

        return {
          currencies,
          currency,
          target: targetAccountId,
          isConfirming: mode === 'CONFIRM',
          isLoading: fetchingLimits.pending || fetchingLimits.idle,
          isSubmitting: requestingTransactionState.pending,
        };
      }

      case 'BANK': {
        const { currency, fields, mode, requestingTransactionState } =
          toBankAccountState;
        const { currencies } = toBankAccountData;

        return {
          currencies,
          currency,
          target: fields.iban,
          iban: fields.iban,
          isConfirming: mode === 'CONFIRM',
          isLoading: isBalancesLoading,
          isSubmitting: requestingTransactionState.pending,
        };
      }

      default: {
        return {
          currencies: [],
          currency: '',
          iban: '',
          target: '',
          isSubmitting: false,
          isLoading: false,
          isConfirming: false,
        };
      }
    }
  }, [
    accountDetailsState,
    toBankAccountData,
    toBankAccountState,
    toInternalAccountData,
    toInternalAccountState,
    toMyAccountState,
    isBalancesLoading,
  ]);

  const limit = useMemo((): number | null => {
    if (!selectedAccountId) {
      return null;
    }

    if (cashTransferTab === 'MY_ACCOUNT') {
      return parseFloat(toMyAccountState.limit);
    }

    if (cashTransferTab === 'INTERNAL') {
      return parseFloat(toInternalAccountState.limit);
    }

    const result = parseFloat(
      String(balances[stateValues.currency]?.[selectedAccountId]?.free_money),
    );

    return parseFloat(result.toFixed(2));
  }, [
    accountDetailsState,
    selectedAccountId,
    balances,
    stateValues.currency,
    toBankAccountData,
    transfersState,
    toBankAccountState,
    toMyAccountState,
    toInternalAccountState,
  ]);

  const handleAmountChange = (value: string) => {
    setAmount(value);
  };

  const handleClearAmount = () => {
    setAmount('');
  };

  const handleCurrencyChange = (payload: string) => {
    if (cashTransferTab === 'MY_ACCOUNT') {
      toMyAccountDispatch({ type: 'SET_CURRENCY', payload });
    }

    if (cashTransferTab === 'INTERNAL') {
      toInternalAccountDispatch({ type: 'SET_CURRENCY', payload });
    }

    if (cashTransferTab === 'BANK') {
      toBankAccountDispatch({ type: 'SET_CURRENCY', payload });
    }
  };

  const isAllFunds = useMemo(() => {
    switch (cashTransferTab) {
      case 'MY_ACCOUNT':
        return toMyAccountState.isAllFunds;
      case 'INTERNAL':
        return toInternalAccountState.isAllFunds;
      case 'BANK':
        return toBankAccountState.isAllFunds;
      default:
        return false;
    }
  }, [
    cashTransferTab,
    toMyAccountState.isAllFunds,
    toInternalAccountState.isAllFunds,
    toBankAccountState.isAllFunds,
  ]);

  const handleAllfundsChange = (value: boolean) => {
    if (cashTransferTab === 'MY_ACCOUNT') {
      toMyAccountDispatch({ type: 'SET_IS_ALL_FUNDS', payload: value });
    }

    if (cashTransferTab === 'INTERNAL') {
      toInternalAccountDispatch({
        type: 'SET_IS_ALL_FUNDS',
        payload: value,
      });
    }

    if (cashTransferTab === 'BANK') {
      toBankAccountDispatch({ type: 'SET_IS_ALL_FUNDS', payload: value });
    }
  };

  const resetAllFundsValue = () => {
    handleAllfundsChange(false);
  };

  const createToMyAccountTransaction = async () => {
    if (selectedAccountId && limit) {
      toMyAccountDispatch({ type: 'REQUEST_TRANSACTION_START' });

      try {
        const { uuid } = await transferService.requestToMyAccountTransfer({
          accountFrom: selectedAccountId,
          accountTo: toMyAccountState.targetAccountId,
          currency: toMyAccountState.currency,
          amount: isAllFunds ? limit : amount,
          isAllFunds: toMyAccountState.isAllFunds,
          t,
        });

        updateLastTransactionId(uuid);
        updateBalance(selectedAccountId, stateValues.currency, true);
        notifyWith.success('layout__transfer__message_success');

        toMyAccountDispatch({ type: 'SET_CURRENCY', payload: '' });
        toMyAccountDispatch({
          type: 'SET_CURRENCY',
          payload: toMyAccountState.currency,
        });
        toMyAccountDispatch({ type: 'REQUEST_TRANSACTION_SUCCESS' });
      } catch (error) {
        toMyAccountDispatch({ type: 'REQUEST_TRANSACTION_ERROR' });
      } finally {
        handleClearAmount();
        resetAllFundsValue();
      }
    }
  };

  const isInternalTransferValid = (stateFields: ToInternalAccountFields) => {
    if (!userDetails?.info?.isCorporate) {
      return true;
    }

    let payload: ToInternalAccountErrors = {};
    if (stateFields.files.length === 0) {
      payload = {
        ...payload,
        files: t('layout__field_is_required'),
      };
    }

    if (stateFields.reference.length === 0) {
      payload = {
        ...payload,
        reference: t('layout__field_is_required'),
      };
    }

    if (Object.keys(payload).length > 0) {
      toInternalAccountDispatch({ type: 'SET_ERRORS', payload });
      return false;
    }

    return true;
  };

  const isWiredTransferValid = (stateFields: ToBankAccountFields): boolean => {
    let payload: ToBankAccountErrors = {};
    let value: string;

    value = getNameError(stateFields.iban, t);
    if (value) {
      payload = {
        ...payload,
        name: value,
      };
    }

    value = getIbanError(
      stateFields.iban,
      transfersState.branding?.show_iban_swift_validation,
      t,
    );
    if (value) {
      payload = {
        ...payload,
        iban: value,
      };
    }

    value = getSwiftError(
      stateFields.swift,
      transfersState.branding?.show_iban_swift_validation,
      requiredFields,
      t,
    );
    if (value) {
      payload = {
        ...payload,
        swift: value,
      };
    }

    value = getBeneficiaryCountryError(
      stateFields.beneficiary_country,
      requiredFields,
      t,
    );
    if (value) {
      payload = {
        ...payload,
        beneficiary_country: value,
      };
    }

    value = getCityError(stateFields.city, requiredFields, t);
    if (value) {
      payload = {
        ...payload,
        city: value,
      };
    }

    value = getShortAddressError(stateFields.short_address, requiredFields, t);
    if (value) {
      payload = {
        ...payload,
        short_address: value,
      };
    }

    value = getPostalCodeError(stateFields.postal_code, requiredFields, t);
    if (value) {
      payload = {
        ...payload,
        postal_code: value,
      };
    }

    value = getCorrespondentAccountError(
      stateFields.correspondent_account,
      requiredFields,
      t,
    );
    if (value) {
      payload = {
        ...payload,
        correspondent_account: value,
      };
    }

    value = getBankNameError(stateFields.bank_name, requiredFields, t);
    if (value) {
      payload = {
        ...payload,
        bank_name: value,
      };
    }

    value = getBankCountryError(stateFields.bank_country, requiredFields, t);
    if (value) {
      payload = {
        ...payload,
        bank_country: value,
      };
    }

    value = getBankAddressError(stateFields.bank_address, requiredFields, t);
    if (value) {
      payload = {
        ...payload,
        bank_address: value,
      };
    }

    value = getCorrespondentSwiftError(
      stateFields.correspondent_swift,
      requiredFields,
      transfersState.branding?.show_iban_swift_validation,
      t,
    );
    if (value) {
      payload = {
        ...payload,
        correspondent_swift: value,
      };
    }

    if (Object.keys(payload).length > 0) {
      toBankAccountDispatch({ type: 'SET_ERRORS', payload });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (cashTransferTab === 'MY_ACCOUNT') {
      await createToMyAccountTransaction();
    }

    if (cashTransferTab === 'INTERNAL') {
      if (isInternalTransferValid(toInternalAccountState)) {
        toInternalAccountDispatch({ type: 'SET_MODE', payload: 'CONFIRM' });
      }
    }

    if (cashTransferTab === 'BANK') {
      if (isWiredTransferValid(toBankAccountState.fields)) {
        toBankAccountDispatch({ type: 'SET_MODE', payload: 'CONFIRM' });
      }
    }
  };

  const handleCloseConfirmForm = () => {
    if (cashTransferTab === 'INTERNAL') {
      toInternalAccountDispatch({ type: 'SET_MODE', payload: 'IDLE' });
    }

    if (cashTransferTab === 'BANK') {
      toBankAccountDispatch({ type: 'SET_MODE', payload: 'IDLE' });
    }
  };

  const handleConfirm = async (token: string, confirmationType?: TMfaType) => {
    if (!selectedAccountId || !limit) {
      return;
    }

    if (cashTransferTab === 'INTERNAL') {
      toInternalAccountDispatch({ type: 'REQUEST_TRANSACTION_START' });
      const response = await transferService.requestToInternalAccountTransfer({
        amount: isAllFunds ? limit : amount,
        account: selectedAccountId,
        currency: toInternalAccountState.currency,
        targetAccountId: toInternalAccountState.targetAccountId,
        token,
        confirmType: confirmationType,
        comment: toInternalAccountState.reference,
        files: toInternalAccountState.files,
        isAllFunds: toInternalAccountState.isAllFunds,
      });

      if (response?.success) {
        updateLastTransactionId(response.withdrawal.id);

        const successMessage = `${t(
          'Your request for funds transfer to %s was successfully created',
        ).replace('%s', toInternalAccountState.name)} ${t(
          'layout__subaccount_transfer__external_description',
        )} ${toInternalAccountState.targetAccountId}`;

        notifyWith.success(successMessage);
        updateBalance(selectedAccountId, stateValues.currency, true);
        toInternalAccountDispatch({ type: 'SET_CURRENCY', payload: '' });
        toInternalAccountDispatch({
          type: 'SET_CURRENCY',
          payload: toInternalAccountState.currency,
        });
        toInternalAccountDispatch({
          type: 'REQUEST_TRANSACTION_SUCCESS',
        });
      } else {
        toInternalAccountDispatch({ type: 'REQUEST_TRANSACTION_ERROR' });
      }

      handleClearAmount();
      resetAllFundsValue();
    }

    if (cashTransferTab === 'BANK') {
      toBankAccountDispatch({ type: 'REQUEST_TRANSACTION_START' });
      const {
        fields,
        currency,
        type,
        isAllFunds: isToBankAccountAllFunds,
      } = toBankAccountState;

      const files = fields.files;
      const data = [
        ['currency', currency],
        ['name', fields.name.trim()],
        ['beneficiary_country', fields.beneficiary_country],
        ['city', fields.city],
        ['state', fields.state],
        ['postal_code', fields.postal_code],
        ['short_address', fields.short_address],
        ['iban', fields.iban],
        ['bank_name', fields.bank_name],
        ['swift', fields.swift],
        ['bank_address', fields.bank_address],
        ['bank_country', fields.bank_country],
        ['correspondent_swift', fields.correspondent_swift],
        ['correspondent_account', fields.correspondent_account],
        ['comment', fields.comment],
      ].filter(([, value]) => value !== undefined && value !== '');

      const payload = [
        ...data,
        ['type', type],
        ['amount', isAllFunds ? limit : amount],
        ['account', selectedAccountId],
        ['confirm', '2fa'],
        ['is_third_party', !isWiredToSelf],
        ['token', token],
        ['all_funds', isToBankAccountAllFunds],
        ['confirm_type', confirmationType],
      ];

      if (!hiddenFields.has('use_personal_iban')) {
        payload.push(['use_personal_iban', fields.use_personal_iban]);
      }

      const formData = new FormData();
      payload.forEach(([key, value]) => {
        formData.append(String(key), String(value));
      });

      files.forEach((file) => {
        formData.append('file', file);
      });

      try {
        const response = await transferService.requestToBankAccountTransfer(
          currentLanguage,
          formData,
        );

        if (response.success) {
          updateLastTransactionId(response.withdrawal.id);
          notifyWith.success('Withdrawal request has been sent.');
          updateBalance(selectedAccountId, stateValues.currency, true);

          const fixedInitWireValues = Object.fromEntries(
            Object.entries(transfersState.init_wire_values || {}).map(
              ([key, value]) => {
                if (value === null) {
                  return [key, ''];
                }

                return [key, value];
              },
            ),
          );

          const initFields: ToBankAccountDefaultValues = {
            ...fixedInitWireValues,
            name: baseName,
          };

          toBankAccountDispatch({
            type: 'REQUEST_TRANSACTION_SUCCESS',
            payload: initFields,
          });
        } else {
          toBankAccountDispatch({ type: 'REQUEST_TRANSACTION_ERROR' });
        }
      } catch (error) {
        toBankAccountDispatch({ type: 'REQUEST_TRANSACTION_ERROR' });
        notifyWith.genericNetworkError(error);
      } finally {
        handleClearAmount();
        resetAllFundsValue();
      }
    }

    handleCloseConfirmForm();
  };

  useEffect(() => {
    handleClearAmount();
    resetAllFundsValue();
  }, [cashTransferTab]);

  useEffect(() => {
    let sum: string;

    switch (cashTransferTab) {
      case 'MY_ACCOUNT':
        sum = repeatedWithdrawal?.sum || '';
        break;
      case 'INTERNAL':
        sum = repeatedWithdrawal?.amount || '';
        break;
      case 'BANK':
        sum = repeatedWithdrawal?.amount || '';
        break;
      default:
        sum = '';
    }

    const preparedSum = parseFloat(sum.replace('-', ''));

    if (!Number.isNaN(preparedSum) && !repeatedWithdrawal?.all_funds) {
      setAmount(String(preparedSum));
    }
  }, [repeatedWithdrawal, cashTransferTab]);

  useEffect(
    withdrawalBalanceAutoupdateEffectFactory({
      selectedAccountId,
      currency: toBankAccountState.currency,
      updateBalance,
    }),
    [selectedAccountId, stateValues.currency],
  );

  return {
    ...stateValues,

    amount,
    balances,
    limit,
    isAllFunds,
    commissionAccount,
    handleAmountChange,
    handleConfirm,
    handleCurrencyChange,
    handleAllfundsChange,
    handleCloseConfirmForm,
    handleSubmit,
  };
};
