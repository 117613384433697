import { TTooltipContentWrapperProps } from 'components/ActionWithConfirmation/ActionWithConfirmation.types';
import { styled } from 'theme';

export const StyledTooltipContentWrapper = styled(
  'div',
)<TTooltipContentWrapperProps>`
  width: ${({ lang }) =>
    lang === 'uk-ua' // todo remove language specific code https://jira.exante.eu/browse/CL-4022
      ? '420px'
      : '350px'};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 90vw;
  }

  display: flex;
  flex-direction: column;
  gap: 16px;

  .ActionWithConfirmationTitle {
    margin-bottom: 0;

    font-size: 20px;
    font-weight: 400;
    font-family: FoxSansPro, sans-serif;
  }
`;
