import { LangSelector, NotificationModal } from 'components/Header/components';

import {
  StyledLoginHeaderContent,
  StyledLoginHeaderContentItem,
  StyledLoginHeaderWrapper,
} from './LoginHeader.styled';

export const LoginHeader = () => (
  <StyledLoginHeaderWrapper className="header LoginHeader">
    <StyledLoginHeaderContent className="header__cnt LoginHeaderContent">
      <StyledLoginHeaderContentItem className="LoginHeaderContentItem" />
      <StyledLoginHeaderContentItem className="LoginHeaderContentItem" />
      <StyledLoginHeaderContentItem className="LoginHeaderContentItem">
        <LangSelector />
      </StyledLoginHeaderContentItem>
    </StyledLoginHeaderContent>
    <NotificationModal />
  </StyledLoginHeaderWrapper>
);
