import { styled } from 'theme';

export const StyledAgreementsW8AffidavitWrapper = styled('div')`
  margin: 24px;
  display: flex;

  & .TooltipTextWrapper {
    display: inline;
  }
`;

export const StyledW8AffidavitLink = styled('span')`
  color: ${({ theme }) => theme?.color?.typo?.action};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  cursor: pointer;
`;
