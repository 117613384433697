import { FC } from 'react';

import {
  StyledPartnerHeaderInfoBlockContent,
  StyledPartnerHeaderInfoBlockHeader,
  StyledPartnerHeaderInfoBlockLabel,
  StyledPartnerHeaderInfoBlockWrapper,
} from './PartnerHeaderInfoBlock.styled';
import { TPartnerHeaderInfoBlockProps } from './PartnerHeaderInfoBlock.types';

export const PartnerHeaderInfoBlock: FC<TPartnerHeaderInfoBlockProps> = ({
  value,
  label,
}) => (
  <StyledPartnerHeaderInfoBlockWrapper className="PartnerHeaderInfoBlockWrapper">
    <StyledPartnerHeaderInfoBlockContent className="PartnerHeaderInfoBlockContent">
      <StyledPartnerHeaderInfoBlockLabel className="PartnerHeaderInfoBlockLabel">
        {label}
      </StyledPartnerHeaderInfoBlockLabel>
      <StyledPartnerHeaderInfoBlockHeader className="PartnerHeaderInfoBlockHeader">
        {value}
      </StyledPartnerHeaderInfoBlockHeader>
    </StyledPartnerHeaderInfoBlockContent>
  </StyledPartnerHeaderInfoBlockWrapper>
);
