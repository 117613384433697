import { DEFAULT_CURRENCY_CRYPTO } from 'constants/common';
import { TCurrencies } from 'services/currencies';

import { TDepositCryptoState } from './DepositCryptoContext.types';

export const CURRENCIES_CRYPTO_EMPTY: TCurrencies = { icons: {}, list: [] };

export const initialState: TDepositCryptoState = {
  isLoading: false,
  currency: DEFAULT_CURRENCY_CRYPTO,
  currencies: CURRENCIES_CRYPTO_EMPTY,
  setCurrency() {},
  isLoadingAddress: false,
  address: null,
  renewAddress() {},
};
