import { TFunction } from 'i18next';
import { object, ref, string } from 'yup';

import { passwordValidator } from 'helpers/password';

export enum EPasswordType {
  NewPassword = 'new_password',
  OldPassword = 'old_password',
  RepeatPassword = 'repeat_password',
}

export const CHANGE_PASSWORD_FORM_VALIDATION_SCHEMA = (t: TFunction) =>
  object({
    old_password: string().required(t('old_password__required')),
    new_password: passwordValidator(t),
    repeat_password: string()
      .required(t('repeat_password__required'))
      .oneOf([ref(EPasswordType.NewPassword)], t('passwords_not_match')),
  });
