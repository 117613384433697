import { styled } from 'theme';

export const StyledSharedMfaContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .MuiInputLabel-root {
    transform: translate(12px, 12px) scale(1);
  }

  .MuiInputLabel-root.Mui-focused,
  .MuiFormLabel-filled {
    transform: translate(12px, 6px) scale(1);
  }
`;

export const StyledSharedMfaText = styled('span')`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledSharedMfaTarget = styled('span')`
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
`;

export const StyledSharedMfaResendButton = styled('button')<{
  disabled?: boolean;
}>`
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  font-size: 13px;
  font-weight: 400;
  background-color: transparent;
  color: ${({ disabled, theme }) =>
    !disabled ? theme.color.typo.action : theme.color.typo.ghost};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
`;

export const StyledSharedMfaFooterText = styled('span')<{ visible: boolean }>`
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: ${({ theme }) => theme.color.typo.secondary};
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  padding: 0 14px;
`;

export const StyledSharedMfaBarcodeKeyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledSharedMfaBarcodeKeyValue = styled('div')`
  display: flex;
  gap: 8px;
`;

export const StyledSharedMfaBarcodeKeyText = styled('span')`
  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
`;

export const StyledSharedMfaInputWithFooterContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const StyledSharedMfaInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
