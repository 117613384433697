import { css, IconButton, Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledAccountSelectionPanelProps } from './AccountSelectionPanel.types';

export const StyledAccountSelectionPanel = styled(
  Panel,
)<TStyledAccountSelectionPanelProps>`
  padding: 0;

  .PanelTitle {
    padding: ${(props) => (props.isHideSearch ? '0 24px' : '24px 24px 0')};
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ isSearchExpanded, useAbsolutePositioning }) =>
      isSearchExpanded &&
      useAbsolutePositioning &&
      css`
        .PanelTitle {
          margin-bottom: 72px;
        }
      `}
  }
`;

export const StyledAccountSelectionPanelSearchButton = styled(IconButton)`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
` as typeof IconButton;

export const StyledAccountSelectionPanelDateText = styled('span')`
  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 15px;

  ${({ theme }) => theme?.breakpoints?.down('md')} {
    display: none;
  }
`;

export const StyledAccountSelectionPanelDateContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;

  .CalendarDatePickerIcon {
    width: 32px;
    height: 32px;
  }
`;

export const StyledAccountSelectionPanelActions = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledAccountSelectionPanelSearchFieldWrapper = styled('div')<{
  useAbsolutePositioning?: boolean;
}>`
  ${({ theme, useAbsolutePositioning }) =>
    useAbsolutePositioning && theme.breakpoints.down('md')} {
    position: absolute;
    top: 72px;
    left: 24px;
  }
`;
