import { Tooltip } from 'react-ui-kit-exante';

import { Text } from 'components/Text';
import { styled } from 'theme';

export const StyledCryptoImportantNoteTooltip = styled(Tooltip)`
  white-space: pre-wrap;
`;

export const StyledCryptoImportantNoteTooltipText = styled(Text)`
  color: ${({ theme }) => theme.color.typo.secondary};

  svg {
    color: ${({ theme }) => theme.color.typo.secondary};
  }
`;
