import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { Dates } from 'helpers/dates';
import {
  AssetManagementDocumentStatus,
  TAssetManagementDocument,
  TLegalEntityDocument,
} from 'services/legal-docs';

import { DocActionsCell } from '../../DocActionsCell';
import { DocComboCell } from '../../DocComboCell';

import {
  TAgreementsAssetDocsGetColumnsParams,
  TAgreementsDocsGetKeysParams,
} from './columns.types';

export const TABLE_SORT_BY = [{ id: 'order', desc: false }];

export const getKeys: (params: TAgreementsDocsGetKeysParams) => string[] = ({
  isMobile,
}) => {
  return isMobile
    ? ['doc.title', 'actions']
    : ['doc.title', 'sign_date', 'actions'];
};

export const getColumns: (
  params: TAgreementsAssetDocsGetColumnsParams,
) => IColumn<TLegalEntityDocument>[] = ({
  t,
  isMobile,
  onDocUpload,
  dateFormatter,
}) => [
  ...(isMobile
    ? [
        {
          id: 'title',
          accessor: 'doc.title',
          Cell: ({
            row: { original },
          }: ICellValue<TAssetManagementDocument>) => (
            <DocComboCell
              title={
                original.fee_setup.name
                  ? t(original.fee_setup.name)
                  : String(original.fee_setup.id)
              }
              signDate={original.created}
              withDate
              dateFormatter={dateFormatter}
            />
          ),
        },
        {
          id: 'doc_actions',
          Header: t('generic__action'),
          accessor: 'actions',
          align: 'right',
          width: 48,
          Cell: ({
            row: { original },
          }: ICellValue<TAssetManagementDocument>) => (
            <DocActionsCell
              downloadUrl={original.second_side_doc_url}
              onUploadClick={
                original.status === AssetManagementDocumentStatus.OpenLink
                  ? (file, setIsUploading) =>
                      onDocUpload(original, file, setIsUploading)
                  : undefined
              }
            />
          ),
        },
      ]
    : [
        {
          id: 'name',
          Header: t('layout__legal_docs__asset_management_doc_name'),
          accessor: 'doc.title',
          disabledFilters: true,
          width: 200,
          Cell: ({
            row: { original },
          }: ICellValue<TAssetManagementDocument>) => (
            <DocComboCell
              t={t}
              title={
                original.fee_setup.name
                  ? t(original.fee_setup.name)
                  : String(original.fee_setup.id)
              }
              signDate={original.created}
              dateFormatter={dateFormatter}
            />
          ),
        },
        {
          id: 'created',
          Header: t('generic__date'),
          accessor: 'sign_date',
          disableFilters: true,
          Cell: ({
            row: { original },
          }: ICellValue<TAssetManagementDocument>) => {
            return original.updated
              ? dateFormatter(
                  Dates.convertDateToUtcTimezone(new Date(original.updated)),
                )
              : DEFAULT_EMPTY_PLACEHOLDER;
          },
        },
        {
          id: 'doc_actions',
          Header: t('generic__action'),
          accessor: 'actions',
          align: 'right',
          Cell: ({
            row: { original },
          }: ICellValue<TAssetManagementDocument>) => (
            <DocActionsCell
              downloadUrl={original.second_side_doc_url}
              onUploadClick={
                original.status === AssetManagementDocumentStatus.OpenLink
                  ? (file, setIsUploading) =>
                      onDocUpload(original, file, setIsUploading)
                  : undefined
              }
            />
          ),
        },
      ]),

  {
    id: 'order',
    accessor: 'order',
    isVisible: false,
  },
];
