import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TProfileSettings, TProfileSettingsResponse } from './settings.types';

class SettingsService extends ApiRequest {
  async getProfileSettings(lang = 'en'): Promise<TProfileSettings | null> {
    try {
      const { data: response } = await this.fetch<TProfileSettingsResponse>({
        url: `/${lang}/clientsarea/spa-layout/?name=profile-settings`,
      });

      const { 'profile-settings': profileSettings } = response;

      return profileSettings;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getUsername', error);
      return null;
    }
  }
}

export const settingsService = new SettingsService();
