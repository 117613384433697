import { Input } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPartnerLinksAddWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

export const StyledPartnerLinksAddInput = styled(Input)`
  background: ${({ theme }) => theme.color.bg.secondary};
`;

export const StyledPartnerLinksAddLinkPartnerButton = styled('a')`
  text-transform: none;
  text-decoration: none;
  color: ${({ theme }) => theme.color.typo.action};
`;
