import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TApplicationSettings,
  TApplicationSettingsResponse,
} from './application.types';

class ApplicationService extends ApiRequest {
  async getApplicationSettings(
    lang: string,
  ): Promise<TApplicationSettings | null> {
    try {
      const settings = await this.fetch<TApplicationSettingsResponse>({
        url: `/${lang}/clientsarea/spa-layout/?name=application-settings`,
      });

      const mfa = await this.fetch<{ allowed: TApplicationSettings['mfa'] }>({
        url: `/${lang}/clientsarea/rest/mfa/allowed_types/`,
      });

      return {
        ...settings.data['application-settings'],
        mfa: mfa.data.allowed,
      };
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('fetch application state error: ', error);
      return null;
    }
  }
}

export const applicationService = new ApplicationService();
