import { TMfaType } from 'services/mfa';

import { MfaTooltipState } from './Mfa.constants';

export const getTooltipState = (type: TMfaType): MfaTooltipState | null => {
  switch (type) {
    case 'email':
      return MfaTooltipState.Email;

    case 'sms':
      return MfaTooltipState.Sms;

    case 'totp':
      return MfaTooltipState.Totp;

    default:
      return null;
  }
};
