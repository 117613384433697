import { uniqueId } from 'lodash';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CurrencyContext, TranslationContext } from 'contexts';
import {
  EXCHANGE_FIXED_AMOUNT,
  EXCHANGE_MIN_TRANSFER_VALUE,
  ExchangeContextInitialValue,
} from 'pages/Portfolio/tabs/Exchange/contexts/ExchangeContext/ExchangeContext.constants';
import { calcAmount } from 'pages/Portfolio/tabs/Exchange/contexts/ExchangeContext/ExchangeContext.helpers';
import { TExchangeContextValue } from 'pages/Portfolio/tabs/Exchange/contexts/ExchangeContext/ExchangeContext.types';
import { useExchangeCrossrates } from 'pages/Portfolio/tabs/Exchange/contexts/ExchangeContext/hooks';
import { useExchangeAccountInfo } from 'pages/Portfolio/tabs/Exchange/contexts/ExchangeContext/hooks/useExchangeAccountInfo';
import { exchangeService } from 'services/exchange';
import { useAppSelector } from 'store/hooks';
import { TChildren } from 'types/TChildren';
import { TValueOf } from 'types/TValueOf';

export const ExchangeContext = createContext<TExchangeContextValue>(
  ExchangeContextInitialValue,
);

const { ACTUAL, EXPECTED } = EXCHANGE_FIXED_AMOUNT;

export const ExchangeProvider: FC<TChildren> = ({ children }) => {
  const [accountId, isAccountsLoading] = useAppSelector((state) => [
    state.accounts.selectedAccountId,
    state.accounts.isAccountsLoading,
  ]);
  const { currency: portfolioCurrency } = useContext(CurrencyContext);
  const { isTranslationsLoading } = useContext(TranslationContext);

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [expectedAmount, setExpectedAmount] = useState<number | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);
  const [targetCurrency, setTargetCurrency] = useState<string | null>(null);
  const [lastConversionId, setLastConversionId] = useState<string | null>(null);
  const [fixedAmount, setFixedAmount] =
    useState<TValueOf<typeof EXCHANGE_FIXED_AMOUNT>>(ACTUAL);
  const [commission, setCommission] = useState<string | null>(null);
  const [isCommissionLoading, setIsCommissionLoading] =
    useState<boolean>(false);

  const { currencies, targetCurrencies, limits, loadingAccountInfo } =
    useExchangeAccountInfo(accountId, lastConversionId);

  const { crossrate, crossrates } = useExchangeCrossrates({
    currency,
    targetCurrency,
  });

  const limit = useMemo((): number | null => {
    if (!currency) {
      return null;
    }

    return limits[currency] ?? null;
  }, [limits, currency]);

  const isConversionAvailable = useMemo<boolean>(() => {
    return Boolean(
      !loading &&
        currency !== targetCurrency &&
        currency &&
        targetCurrency &&
        crossrate &&
        amount !== null &&
        limit !== null &&
        commission !== null &&
        amount <= limit &&
        amount >= EXCHANGE_MIN_TRANSFER_VALUE,
    );
  }, [loading, currency, targetCurrency, crossrate, amount, limit, commission]);

  const contextLoading = useMemo<boolean>(
    () =>
      loading ||
      isAccountsLoading ||
      loadingAccountInfo ||
      isTranslationsLoading,
    [loading, isAccountsLoading, loadingAccountInfo, isTranslationsLoading],
  );

  const updateAmount = useCallback<TExchangeContextValue['updateAmount']>(
    (value) => {
      setAmount(value);
      setFixedAmount(ACTUAL);

      if (value === null || !crossrates || !commission) {
        setExpectedAmount(null);
      } else if (currency && targetCurrency) {
        setExpectedAmount(
          calcAmount(
            value,
            crossrates[targetCurrency],
            crossrates[currency],
            1 - parseFloat(commission),
          ),
        );
      }
    },
    [currency, targetCurrency, crossrates, commission],
  );

  const updateExpectedAmount = useCallback<
    TExchangeContextValue['updateExpectedAmount']
  >(
    (value) => {
      setExpectedAmount(value);
      setFixedAmount(EXPECTED);

      if (value === null || !crossrates || !commission) {
        setAmount(null);
      } else if (currency && targetCurrency) {
        setAmount(
          calcAmount(
            value,
            crossrates[currency],
            crossrates[targetCurrency],
            1 / (1 - parseFloat(commission)),
          ),
        );
      }
    },
    [currency, targetCurrency, crossrates, commission],
  );

  const convert = useCallback(async () => {
    if (
      !isConversionAvailable ||
      !accountId ||
      !currency ||
      !targetCurrency ||
      !amount ||
      !limit ||
      amount > limit ||
      !crossrates ||
      !(targetCurrency in crossrates) ||
      !(currency in crossrates) ||
      !commission
    ) {
      return;
    }

    setLoading(true);
    const response = await exchangeService.requestExchange({
      accountId,
      currency,
      targetCurrency,
      amount,
    });
    setLoading(false);

    if (response?.success) {
      setLastConversionId(uniqueId()); // бэкенд не возвращает id операции
    }
  }, [
    isConversionAvailable,
    accountId,
    currency,
    targetCurrency,
    amount,
    limit,
    commission,
  ]);

  //region useEffect
  useEffect(() => {
    if (!(currency === null && currencies.length && targetCurrencies.length)) {
      return;
    }

    const newCurrency = currencies.includes(portfolioCurrency)
      ? portfolioCurrency
      : currencies[0];

    setCurrency(newCurrency);

    if (
      targetCurrencies.length &&
      (newCurrency === targetCurrency || targetCurrency === null)
    ) {
      const anotherCurrency = targetCurrencies.find(
        (curr) => curr !== newCurrency,
      );

      if (anotherCurrency) {
        setTargetCurrency(anotherCurrency);
      }
    }
  }, [
    currency,
    currencies,
    targetCurrency,
    targetCurrencies,
    portfolioCurrency,
  ]);

  const handleUpdateCommission = async () => {
    if (!accountId || !currency || !targetCurrency) {
      return;
    }

    setIsCommissionLoading(true);
    setCommission('1'); // mean 100% (иbecause null dosent work)
    setExpectedAmount(null);
    const commissionData = await exchangeService.fetchCommission(
      accountId,
      currency,
      targetCurrency,
    );
    setCommission(commissionData);
    setIsCommissionLoading(false);
  };

  useEffect(() => {
    handleUpdateCommission();
  }, [accountId, currency, targetCurrency]);

  useEffect(() => {
    if (!crossrates || !currency || !targetCurrency || !commission) {
      return;
    }

    if (currency === targetCurrency) {
      setExpectedAmount(amount);
      return;
    }

    const targetCrossrate = crossrates[targetCurrency];
    const sourceCrossrate = crossrates[currency];

    if (fixedAmount === ACTUAL) {
      if (!amount) {
        return;
      }

      setExpectedAmount(
        calcAmount(
          amount,
          targetCrossrate,
          sourceCrossrate,
          1 - parseFloat(commission),
        ),
      );
    } else if (fixedAmount === EXPECTED) {
      if (!expectedAmount) {
        return;
      }

      setAmount(
        calcAmount(
          expectedAmount,
          sourceCrossrate,
          targetCrossrate,
          1 / (1 - parseFloat(commission)),
        ),
      );
    }
  }, [currency, targetCurrency, commission]);
  //endregion

  const value = useMemo<TExchangeContextValue>(
    () => ({
      loading: contextLoading,
      amount,
      updateAmount,
      expectedAmount,
      updateExpectedAmount,
      limit,
      accountId,
      currency,
      updateCurrency: setCurrency,
      currencies,
      targetCurrency,
      updateTargetCurrency: setTargetCurrency,
      targetCurrencies,
      crossrate,
      crossrates,
      lastConversionId,
      isConversionAvailable,
      convert,
      commission,
      isCommissionLoading,
    }),
    [
      contextLoading,
      amount,
      updateAmount,
      expectedAmount,
      updateExpectedAmount,
      limit,
      accountId,
      currency,
      currencies,
      setCurrency,
      targetCurrency,
      setTargetCurrency,
      targetCurrencies,
      crossrate,
      crossrates,
      lastConversionId,
      isConversionAvailable,
      convert,
      commission,
      isCommissionLoading,
    ],
  );

  return (
    <ExchangeContext.Provider value={value}>
      {children}
    </ExchangeContext.Provider>
  );
};
