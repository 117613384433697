import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { BrandingContext, TranslationContext } from 'contexts';
import { selectPlatforms } from 'pages/Downloads/Downloads.helpers';
import {
  getConditionalButtons,
  getConditionalCaption,
  getPlatformsWithUrls,
} from 'pages/Downloads/components/DownloadsList/DownloadsList.helpers';
import { TBrandingDownloadPagePlatform } from 'services/branding';
import { challengeService } from 'services/challenge';
import { helpService } from 'services/help';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TChildren } from 'types/TChildren';

import { initialState } from './DownloadsPageContext.consts';
import { TDownloadsState } from './DownloadsPageContext.types';

export const DownloadsPageContext =
  createContext<TDownloadsState>(initialState);

export const DownloadsPageProvider: FC<TChildren> = ({
  children,
}): ReactElement | null => {
  const { branding } = useContext(BrandingContext);
  const { currentLanguage } = useContext(TranslationContext);

  const { selectedAccountId } = useAppSelector(selectAccounts);

  const platforms: TBrandingDownloadPagePlatform[] | undefined =
    branding?.branding?.ui_settings?.download_page_platforms;

  const showAllPlatforms =
    !branding?.branding?.challenge_settings?.downloads_page?.challenge_enabled;

  const {
    fetchData: fetchDocs,
    data: docs,
    isLoading: isDocsLoading,
  } = useData({
    onFetch: async () => helpService.getApiDocs(),
  });

  const {
    fetchData: fetchChallenge,
    data: challenge,
    isLoading: isPlatformAliasesLoading,
  } = useData({
    onFetch: async () => {
      if (!selectedAccountId) {
        return null;
      }

      return challengeService.getChallenge(selectedAccountId);
    },
  });

  useEffect(() => {
    fetchDocs();
    if (!showAllPlatforms) {
      fetchChallenge();
    }
  }, [currentLanguage, selectedAccountId]);

  const aliases = challenge?.data?.plan_override?.plan_platforms || [];
  const isError = challenge?.error != null;

  const atp = branding?.atp;

  const platformsWithConditionals: TBrandingDownloadPagePlatform[] =
    useMemo(() => {
      const os = atp?.user_os;
      return (
        platforms?.map((platform: TBrandingDownloadPagePlatform) => ({
          ...platform,
          caption: getConditionalCaption(platform, os),
          buttons: getConditionalButtons(platform, os),
        })) || []
      );
    }, [platforms, atp]);

  const platformsWithCorrectUrls: TBrandingDownloadPagePlatform[] = useMemo(
    () => getPlatformsWithUrls(platformsWithConditionals),
    [platforms],
  );

  const platformsSelected = useMemo(
    () =>
      selectPlatforms({
        platforms: platformsWithCorrectUrls,
        aliases: showAllPlatforms ? null : aliases,
        branding,
        docs,
      }),
    [platforms, aliases, branding, docs],
  );

  const isLoading =
    isDocsLoading ||
    (isPlatformAliasesLoading && !showAllPlatforms) ||
    !branding;

  const value = useMemo<TDownloadsState>(
    () => ({
      platforms: platformsSelected,
      isLoading,
      isError,
    }),
    [platformsSelected, isLoading, isError],
  );

  return (
    <DownloadsPageContext.Provider value={value}>
      {children}
    </DownloadsPageContext.Provider>
  );
};
