import { FC } from 'react';

import {
  StyledProductDescription,
  StyledProductHeading,
  StyledProductInfo,
  StyledProductWrapper,
} from './Product.styled';
import { TProductProps } from './Product.types';
import { ProductImage } from './components/ProductImage/ProductImage';

export const Product: FC<TProductProps> = ({
  title,
  description,
  children,
  imageUrls,
  stickImageToBottom,
}) => {
  return (
    <StyledProductWrapper className="Product">
      <StyledProductInfo className="ProductInfo">
        <StyledProductHeading className="ProductHeading">
          {title}
        </StyledProductHeading>
        <StyledProductDescription className="ProductDescription">
          {description}
        </StyledProductDescription>
        {children}
      </StyledProductInfo>
      {!!imageUrls && (
        <ProductImage
          imageUrls={imageUrls}
          stickToBottom={stickImageToBottom}
        />
      )}
    </StyledProductWrapper>
  );
};
