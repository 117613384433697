import { useContext } from 'react';

import { TranslationContext } from 'contexts';

import { MFA_TYPE_TO_TOOLTIP } from './ActionRow.constants';
import {
  StyledActionBody,
  StyledActionDescription,
  StyledActionDescriptionSkeleton,
  StyledActionIconWrapper,
  StyledActionRow,
  StyledActionSkeleton,
  StyledActionTitle,
  StyledActionTitleSkeleton,
  StyledButton,
} from './ActionRow.styled';
import { TActionRowProps } from './ActionRow.types';

export const ActionRow = ({
  description,
  isActive,
  isDisabled,
  isLoading,
  onSuccess,
  title,
  type,
  open,
  onOpen,
  onClose,
  Icon,
}: TActionRowProps) => {
  const { t } = useContext(TranslationContext);

  const MfaTooltip = MFA_TYPE_TO_TOOLTIP[type];

  const handleActivateClick = () => {
    if (!isDisabled) {
      onOpen(type);
    }
  };

  const handleDeactivateClick = () => {
    onOpen(type);
  };

  const handleClose = () => {
    onClose();
  };

  if (isLoading) {
    return (
      <StyledActionSkeleton>
        <StyledActionTitleSkeleton />
        <StyledActionDescriptionSkeleton />
      </StyledActionSkeleton>
    );
  }

  return (
    <StyledActionRow
      isActive={isActive}
      isDisabled={isDisabled}
      className="ActionRow"
    >
      <StyledActionIconWrapper
        isActive={isActive}
        className="ActionIconWrapper"
      >
        <Icon />
      </StyledActionIconWrapper>
      <StyledActionBody className="ActionBody">
        <StyledActionTitle className="ActionTitle">{title}</StyledActionTitle>
        <StyledActionDescription className="ActionDescription">
          {description}
        </StyledActionDescription>
      </StyledActionBody>
      <MfaTooltip
        isActive={isActive}
        open={open}
        onClose={handleClose}
        onSuccess={onSuccess}
      >
        {isActive ? (
          <StyledButton
            className="ActionButton"
            type="button"
            onClick={handleDeactivateClick}
          >
            {t('layout__security_tabs__mfa_label_deactivate')}
          </StyledButton>
        ) : (
          <StyledButton
            className="ActionButton"
            disabled={isDisabled}
            type="button"
            onClick={handleActivateClick}
          >
            {t('layout__security_tabs__mfa_label_activate')}
          </StyledButton>
        )}
      </MfaTooltip>
    </StyledActionRow>
  );
};
