import { FC, useContext } from 'react';

import { InlineNotification } from 'components/InlineNotification';
import { TranslationContext } from 'contexts/TranslationContext';

import {
  StyledPartnerPaymentsDetailsFormNotificationLoader,
  StyledPartnerPaymentsDetailsFormNotificationWrapper,
} from './PartnerPaymentsDetailsFormNote.styled';
import { TPartnerPaymentsDetailsFormNoteProps } from './PartnerPaymentsDetailsFormNote.types';

export const PartnerPaymentsDetailsFormNote: FC<
  TPartnerPaymentsDetailsFormNoteProps
> = ({ previousChangeRequest, diffList }) => {
  const { t } = useContext(TranslationContext);

  const diffString = diffList.join(', ').toLowerCase();

  const warningNotificationVisible = !!diffList.length;
  const errorNotificationVisible = previousChangeRequest?.status === 'rejected';

  return !errorNotificationVisible && !warningNotificationVisible ? null : (
    <StyledPartnerPaymentsDetailsFormNotificationWrapper className="PartnerPaymentsDetailsFormNotificationWrapper">
      {warningNotificationVisible && (
        <InlineNotification type="warning">
          <StyledPartnerPaymentsDetailsFormNotificationLoader
            color="transparent"
            loading
          />
          <span>
            {t('layout__settings__user_details__change_of_data')}
            {diffString}
            {t('layout__settings__user_details__request_is_pending')}
          </span>
        </InlineNotification>
      )}
      {errorNotificationVisible && (
        <InlineNotification type="error">
          {previousChangeRequest.comment || ''}
        </InlineNotification>
      )}
    </StyledPartnerPaymentsDetailsFormNotificationWrapper>
  );
};
