import { ChecklistSkeleton } from '../../../../../../components/ChecklistSkeleton';
import { StyledProductInfo, StyledProductWrapper } from '../../Product.styled';
import { ProductImageSkeleton } from '../ProductImage/components/ProductImageSkeleton';

import {
  StyledProductDescriptionSkeleton,
  StyledProductHeadingSkeleton,
  StyledProductLinkSkeleton,
} from './ProductSkeleton.styled';

export const ProductSkeleton = () => {
  return (
    <StyledProductWrapper className="ProductWrapper">
      <StyledProductInfo className="ProductInfo">
        <StyledProductHeadingSkeleton variant="rounded" animation="wave" />
        <StyledProductDescriptionSkeleton variant="rounded" animation="wave" />

        <ChecklistSkeleton />

        <StyledProductLinkSkeleton variant="rounded" animation="wave" />
      </StyledProductInfo>
      <ProductImageSkeleton />
    </StyledProductWrapper>
  );
};
