import { ApiRequest } from 'helpers/apiRequest';
import { getParamsSerializer } from 'helpers/getParamsSerializer';
import { notifyWith } from 'helpers/notifyWith';

import { WITHDRAWAL_TYPE } from './withdrawal.constants';
import {
  TGetWithdrawalHistoryProps,
  TWithdrawalHistoryResponse,
  TWithdrawalRequestTransferResponse,
  TWithdrawalResponse,
} from './withdrawal.types';

class WithdrawalService extends ApiRequest {
  async getInitialState(lang = 'en'): Promise<TWithdrawalResponse | null> {
    try {
      const { data } = await this.fetch<TWithdrawalResponse>({
        url: `/${lang}/clientsarea/rest/withdrawal/state/`,
        params: { refs: true },
      });

      return data || null;
    } catch (error) {
      notifyWith.genericNetworkError(error);

      throw error;
    }
  }

  async getHistory<TIsSubuserWithdrawal extends boolean = false>({
    lang,
    type,
    offset,
    size,
    account,
  }: TGetWithdrawalHistoryProps): Promise<TWithdrawalHistoryResponse<TIsSubuserWithdrawal> | null> {
    let typeParam;
    if (
      type === WITHDRAWAL_TYPE.WIRE ||
      type === WITHDRAWAL_TYPE.WIRE_THIRD_PARTY
    ) {
      // hack for both wire types
      typeParam = [WITHDRAWAL_TYPE.WIRE, WITHDRAWAL_TYPE.WIRE_THIRD_PARTY];
    } else {
      typeParam = type;
    }
    try {
      const { data } = await this.fetch<
        TWithdrawalHistoryResponse<TIsSubuserWithdrawal>
      >(
        {
          url: `/${lang}/clientsarea/rest/withdrawal/history/`,
          params: {
            limit: size,
            offset,
            type: typeParam,
            account,
          },
        },
        {
          paramsSerializer: getParamsSerializer({ arrayFormat: 'brackets' }),
        },
      );

      return data || null;
    } catch (e) {
      notifyWith.genericNetworkError(e);

      return null;
    }
  }

  async requestTransfer<TIsSubuserWithdrawal extends boolean = false>(
    formData: FormData,
  ): Promise<TWithdrawalRequestTransferResponse<TIsSubuserWithdrawal> | null> {
    try {
      const { data } = await this.fetch<
        TWithdrawalRequestTransferResponse<TIsSubuserWithdrawal>
      >({
        url: `/clientsarea/account/withdrawal/create/`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });

      return data || null;
    } catch (e) {
      notifyWith.genericNetworkError(e);

      return null;
    }
  }
}

export const withdrawalService = new WithdrawalService();
