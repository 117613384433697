import { FC, useContext } from 'react';

import { qrParams } from 'components/Footer/Links/Links.consts';
import { BASE_URL } from 'constants/endpoints';
import { TranslationContext } from 'contexts';
import { getQueryString } from 'helpers/getQueryString';

import { getAbsoluteUrl } from './DownloadLink.helpers';
import {
  StyledDownloadProductAnchor,
  StyledDownloadProductAnchorOutlined,
  StyledQrImage,
  StyledQrTooltip,
} from './DownloadLink.styled';
import { TDownloadLinkProps } from './DownloadLink.types';

export const DownloadLink: FC<TDownloadLinkProps> = ({
  anchorText,
  isOutlined,
  productUrl,
  iconUrl,
  qrUrl,
}) => {
  const { t } = useContext(TranslationContext);

  if (!productUrl) {
    return null;
  }

  const productUrlAbsolute = getAbsoluteUrl(productUrl);

  const AnchorComponent = isOutlined
    ? StyledDownloadProductAnchorOutlined
    : StyledDownloadProductAnchor;

  const anchorComponentCN = isOutlined
    ? 'DownloadProductAnchorOutlined'
    : 'DownloadProductAnchor';

  if (qrUrl) {
    const qrUrlFull = `${BASE_URL}${qrUrl}?content=${productUrlAbsolute}&${getQueryString(
      qrParams,
    )}`;

    return (
      <StyledQrTooltip
        title={
          <StyledQrImage
            className="download-links__qr-pic"
            src={qrUrlFull}
            alt={t('layout__components__qr_code_for_download')}
          />
        }
        placement="right"
        className="QrTooltip"
      >
        <AnchorComponent
          href={productUrl || 'anything'}
          target="_blank"
          className={anchorComponentCN}
        >
          {iconUrl && <img src={iconUrl} alt="link icon" />}
          {anchorText}
        </AnchorComponent>
      </StyledQrTooltip>
    );
  }

  return (
    <AnchorComponent href={productUrlAbsolute} target="_blank">
      {iconUrl && <img src={iconUrl} alt="link icon" />}
      {anchorText}
    </AnchorComponent>
  );
};
