import { styled } from 'theme';

export const StyledMfaContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledMfaHeader = styled('section')`
  padding: 24px;
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.typo.secondary};
`;
