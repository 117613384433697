import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { BrandingContext } from 'contexts';
import { TChildren } from 'types/TChildren';

type TPortfolioContext = {
  needToUpdateAccountData: boolean;
};

const initialState: TPortfolioContext = {
  needToUpdateAccountData: false,
};

export const UpdatePortfolioContext =
  createContext<TPortfolioContext>(initialState);

export const UpdatePortfolioProvider: FC<TChildren> = ({ children }) => {
  const [needToUpdateAccountData, setNeedToUpdateAccountData] =
    useState<boolean>(initialState.needToUpdateAccountData);
  const { branding } = useContext(BrandingContext);

  const accountInfoRefreshRate = branding?.account_info_refresh_rate;

  useEffect(() => {
    const interval = setInterval(
      () => setNeedToUpdateAccountData(true),
      accountInfoRefreshRate,
    );

    return () => clearInterval(interval);
  }, [accountInfoRefreshRate]);

  useEffect(() => {
    if (needToUpdateAccountData) {
      setNeedToUpdateAccountData(false);
    }
  }, [needToUpdateAccountData]);

  const value = useMemo(
    () => ({
      needToUpdateAccountData,
    }),
    [needToUpdateAccountData],
  );

  return (
    <UpdatePortfolioContext.Provider value={value}>
      {children}
    </UpdatePortfolioContext.Provider>
  );
};
