import { AxiosResponse } from 'axios';

import { ApiRequest } from 'helpers/apiRequest';

import { TTranslateResponse } from './translate.types';

class TranslateService extends ApiRequest {
  noUseAuth = true;

  async getTranslations(
    url: string,
  ): Promise<AxiosResponse<TTranslateResponse> | null> {
    try {
      return await this.fetch<TTranslateResponse>(
        { url },
        { timeout: 30 * 1000 },
      );
    } catch (error) {
      console.error('getTranslations error:', error);
      return null;
    }
  }
}

export const translateService = new TranslateService();
