import { THelpQuestion } from 'services/help';

/**
 * Returns true if the question text and title contains all the given words
 *
 * @param words
 * @param question
 */
export const hasInQuestion = (words: string[], question: THelpQuestion) =>
  words.every((word) =>
    `${question.text} ${question.title}`.toLowerCase().includes(word),
  );
