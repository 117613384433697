import {
  StyledChecklistSkeletonIcon,
  StyledChecklistSkeletonItem,
  StyledChecklistSkeletonText,
} from './ChecklistSkeletonItem.styled';

export const ChecklistSkeletonItem = () => {
  return (
    <StyledChecklistSkeletonItem>
      <StyledChecklistSkeletonIcon variant="rounded" animation="wave" />
      <StyledChecklistSkeletonText variant="rounded" animation="wave" />
    </StyledChecklistSkeletonItem>
  );
};
