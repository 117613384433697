import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { TKeyScopeItem } from 'services/dashboard';

import { ScopesMap } from '../../../../Api.constants';

export const getScopesString = (scopes?: TKeyScopeItem[]): string => {
  if (!scopes || scopes.length === 0) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  return scopes.map((item) => ScopesMap.get(item)).join(', ');
};
