import { Autocomplete } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAccountSelectorAutocomplete = styled(Autocomplete)`
  flex-basis: 100%;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    flex-basis: calc(50% - 24px);
  }
`;
