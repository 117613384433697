import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { AccountSelection } from 'components/AccountSelection';
import { StyledNoDataNotification } from 'components/NoDataNotification';
import { Tabs } from 'components/Tabs';
import { BrandingContext, TranslationContext } from 'contexts';
import { getNoAccountsText } from 'helpers/getNoAccountsText';
import { useRouteAssociatedAccounts } from 'hooks';
import { StyledTermsPanel } from 'pages/Terms/Terms.styled';
import { ACCOUNT_TYPE } from 'services/accounts/accounts.constants';
import { TERMS_TAB_ID } from 'services/tabs';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { useTermsContext } from './context';

export const TermsWithContext = () => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);

  const termsContext = useTermsContext();
  const { isInterestsLoading, isInterestsEmpty } = termsContext.state;

  const accounts = useRouteAssociatedAccounts();
  const { isAccountsLoading } = useAppSelector(selectAccounts);

  const noAccountText: string = getNoAccountsText(branding, t);

  const isNoAccounts = !isAccountsLoading && !accounts.length;

  return isNoAccounts ? (
    <StyledNoDataNotification
      className="NoDataNotification"
      dangerouslySetInnerHTML={{ __html: noAccountText }}
    />
  ) : (
    <StyledTermsPanel>
      <AccountSelection
        titleKey="Terms"
        noCollapse={isInterestsLoading || isInterestsEmpty}
        isHideAddAccount
        isHideBalanceButton
        isHideDate
        isHideQR
        showCarousel
        excludeAccountPurposes={[ACCOUNT_TYPE.CARD, ACCOUNT_TYPE.PAYMENT]}
      />
      <Tabs tabsForShow={TERMS_TAB_ID} />
      <Outlet />
    </StyledTermsPanel>
  );
};
