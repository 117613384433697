import { FC, useCallback, useContext, useMemo } from 'react';
import { IColumn, IconButton } from 'react-ui-kit-exante';

import { AccordionProvider } from 'components/Accordion';
import { WHITESPACE_NON_BREAKING } from 'constants/common';
import { TranslationContext, CurrencyContext } from 'contexts';
import {
  TabsCacheApiContext,
  TabsCacheDataContext,
} from 'contexts/TabsCacheContext';
import { Dates } from 'helpers/dates';
import { useAccordion } from 'hooks/useAccordion';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useSelectedColumns } from 'hooks/useSelectedColumns';
import { TBlockedFunds, TMargin } from 'services/margin';
import { selectAccounts, selectSelectedDate } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { MarginAccordionItem } from '../MarginAccordionItem';

import {
  ACCORDION_ID,
  MARGIN_DEFAULT_DISPLAYED_COLUMNS,
  MARGIN_DEFAULT_TABLE_ID,
  MAX_TABLE_ITEMS,
  BLOCKED_FUNDS_DEFAULT_DISPLAYED_COLUMNS,
  BLOCKED_FUNDS_DEFAULT_TABLE_ID,
  ORDER_MARGIN_DEFAULT_DISPLAYED_COLUMNS,
  ORDER_MARGIN_DEFAULT_TABLE_ID,
} from './MarginAccordion.constants';
import {
  getAccordionItems,
  getBlockedFundsColumns,
  getDisplayedColumns,
  getMarginColumns,
  getOrderMarginColumns,
  getSum,
  getTableIds,
} from './MarginAccordion.helpers';
import {
  StyledMarginWrapper,
  StyledMarginPanel,
} from './MarginAccordion.styled';
import {
  TMarginAccordionProps,
  TMarginAccordionCache,
  TMarginAccordionCaching,
} from './MarginAccordion.types';

export const MarginAccordion: FC<TMarginAccordionProps> = ({
  data,
  isLoading,
  closeDefault = false,
}) => {
  const { currency } = useContext(CurrencyContext);
  const { getCache } = useContext(TabsCacheDataContext);
  const { setCache } = useContext(TabsCacheApiContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const { t, currentLanguage } = useContext(TranslationContext);
  const currencyFormatter = useCurrencyFormatter();
  const selectedDate = useAppSelector(selectSelectedDate);

  const accordionConfig = useMemo(() => getAccordionItems(data), [data]);

  const { accordion, isAccordionLoading, handleAccordionChange } = useAccordion(
    {
      items: accordionConfig,
      lsKey: ACCORDION_ID,
      closeDefault,
    },
  );

  const cacheId = useMemo<string>(() => {
    return ['margin', currency, selectedAccountId].join('.');
  }, [currency, selectedAccountId]);

  const getAccordionCache = useCallback<TMarginAccordionCaching['get']>(
    () => getCache<TMarginAccordionCache>(cacheId),
    [getCache, cacheId],
  );

  const setAccordionCache = useCallback<TMarginAccordionCaching['set']>(
    (value) => setCache<TMarginAccordionCache>(cacheId, value),
    [setCache, cacheId],
  );

  const caching: TMarginAccordionCaching = {
    get: getAccordionCache,
    set: setAccordionCache,
  };

  const providedAccordion = useMemo<typeof accordion>(() => {
    if (isAccordionLoading) {
      return caching?.get()?.accordion || accordion;
    }

    return accordion;
  }, [caching?.get, accordion, isAccordionLoading]);

  const marginData = data?.margin || [];
  const blockedFundsData = data?.blocked_funds || [];
  const orderMarginData = data?.order_margin || [];

  const marginSum = getSum(marginData, 'convertedMargin');
  const blockedFundsSum = getSum(blockedFundsData, 'convertedMargin');
  const orderMarginSum = getSum(orderMarginData, 'convertedMargin');

  const marginColumns = useMemo<IColumn<TMargin>[]>(
    () => getMarginColumns(t, marginSum, currency, currencyFormatter),
    [t, currentLanguage, marginSum, currency, currencyFormatter],
  );

  const blockedFundsColumns = useMemo<IColumn<TBlockedFunds>[]>(
    () =>
      getBlockedFundsColumns(t, blockedFundsSum, currency, currencyFormatter),
    [t, currentLanguage, blockedFundsSum, currency, currencyFormatter],
  );

  const orderMarginColumns = useMemo<IColumn<TMargin>[]>(
    () => getOrderMarginColumns(t, orderMarginSum, currency, currencyFormatter),
    [t, currentLanguage, orderMarginSum, currency, currencyFormatter],
  );

  const tableIds = useMemo(() => {
    return getTableIds(accordionConfig);
  }, [accordionConfig]);

  const marginSelectedCols = useSelectedColumns({
    tableIds,
    columns: marginColumns,
    defaultDisplayedColumns: MARGIN_DEFAULT_DISPLAYED_COLUMNS,
    defaultTableId: MARGIN_DEFAULT_TABLE_ID,
  });

  const blockedFundsSelectedCols = useSelectedColumns({
    tableIds,
    columns: blockedFundsColumns,
    defaultDisplayedColumns: BLOCKED_FUNDS_DEFAULT_DISPLAYED_COLUMNS,
    defaultTableId: BLOCKED_FUNDS_DEFAULT_TABLE_ID,
  });

  const orderMarginSelectedCols = useSelectedColumns({
    tableIds,
    columns: orderMarginColumns,
    defaultDisplayedColumns: ORDER_MARGIN_DEFAULT_DISPLAYED_COLUMNS,
    defaultTableId: ORDER_MARGIN_DEFAULT_TABLE_ID,
  });

  const marginDisplayedColumns = useMemo<string[]>(
    () =>
      getDisplayedColumns(
        marginSelectedCols.isColumnsFirstLoading,
        marginSelectedCols.selectedColumn,
        caching.get()?.selectedColumn,
      ),
    [
      caching?.get,
      marginSelectedCols.selectedColumn,
      marginSelectedCols.isColumnsFirstLoading,
    ],
  );

  const blockedFundsDisplayedColumns = useMemo<string[]>(
    () =>
      getDisplayedColumns(
        blockedFundsSelectedCols.isColumnsFirstLoading,
        blockedFundsSelectedCols.selectedColumn,
        caching.get()?.selectedColumn,
      ),
    [
      caching?.get,
      blockedFundsSelectedCols.selectedColumn,
      blockedFundsSelectedCols.isColumnsFirstLoading,
    ],
  );

  const orderMarginDisplayedColumns = useMemo<string[]>(
    () =>
      getDisplayedColumns(
        orderMarginSelectedCols.isColumnsFirstLoading,
        orderMarginSelectedCols.selectedColumn,
        caching.get()?.selectedColumn,
      ),
    [
      caching?.get,
      orderMarginSelectedCols.selectedColumn,
      orderMarginSelectedCols.isColumnsFirstLoading,
    ],
  );

  const DownloadLink = useMemo(() => {
    const params = new URLSearchParams({
      currency,
      date: Dates.format(selectedDate),
      account: String(selectedAccountId),
    });

    const url = `/clientsarea/account/margin/details/xls/?${params}`;

    return (
      <IconButton
        iconName="XLSIcon"
        iconSize={24}
        iconColor="secondary"
        onClick={() => window.open(url)}
        title={t('layout__margin__download_report')}
      />
    );
  }, [selectedAccountId, selectedDate, currency]);

  return (
    <StyledMarginWrapper className="MarginWrapper">
      <StyledMarginPanel
        disableBodyPaddings
        title={WHITESPACE_NON_BREAKING}
        action={DownloadLink}
      >
        <AccordionProvider value={providedAccordion}>
          <MarginAccordionItem
            columns={marginColumns}
            data={marginData}
            displayedColumns={marginDisplayedColumns}
            id="margin"
            isLoading={isLoading || marginSelectedCols.isColumnsLoading}
            maxTableItems={MAX_TABLE_ITEMS}
            title={t('generic__margin')}
            onChange={handleAccordionChange}
          />

          {blockedFundsData.length > 0 && (
            <MarginAccordionItem
              columns={blockedFundsColumns}
              data={blockedFundsData}
              displayedColumns={blockedFundsDisplayedColumns}
              id="blockedFunds"
              isLoading={isLoading || blockedFundsSelectedCols.isColumnsLoading}
              maxTableItems={MAX_TABLE_ITEMS}
              title={t('generic__funds_on_hold')}
              onChange={handleAccordionChange}
            />
          )}

          {orderMarginData.length > 0 && (
            <MarginAccordionItem
              columns={orderMarginColumns}
              data={orderMarginData}
              displayedColumns={orderMarginDisplayedColumns}
              id="orderMargin"
              isLoading={isLoading || orderMarginSelectedCols.isColumnsLoading}
              maxTableItems={MAX_TABLE_ITEMS}
              title={t('generic__order_margin')}
              onChange={handleAccordionChange}
            />
          )}
        </AccordionProvider>
      </StyledMarginPanel>
    </StyledMarginWrapper>
  );
};
