import { isCancelledError } from 'helpers/abortController/abortController.helpers';
import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TCurrenciesCryptoResponse,
  TCurrenciesData,
  TCurrenciesResponse,
  TParamsWithOptions,
} from './currencies.types';

class CurrenciesService extends ApiRequest {
  async getCurrencies(): Promise<TCurrenciesData | null> {
    try {
      const { data } = await this.fetch<TCurrenciesResponse>({
        url: `/clientsarea/layout/currencies/`,
      });

      return {
        ...data,
        defaultCurrency: data.default_currency,
        userCurrency: data.user_currency,
      };
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }
      notifyWith.genericNetworkError(error);
      console.error('getCurrencies', error);
      return null;
    }
  }

  async getCurrenciesCrypto({
    options,
  }: TParamsWithOptions): Promise<TCurrenciesCryptoResponse | null> {
    try {
      const { data } = await this.fetch<TCurrenciesCryptoResponse>({
        url: `/clientsarea/layout/currencies-crypto/`,
        signal: options.signal,
      });

      return data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }
      notifyWith.genericNetworkError(error);
      console.error('getCurrenciesCrypto', error);
      return null;
    }
  }
}

export const currenciesService = new CurrenciesService();
