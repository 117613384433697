import Cookies from 'js-cookie';
import { useContext, useEffect } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';
import { notifyWith } from 'helpers/notifyWith';

import {
  CONNECT_STATUS_COOKIE_NAME,
  CONNECT_STATUS_TEXTS,
  ConnectStatus,
} from './useBindingCheck.constants';

export const useBindingCheck = () => {
  const { isTranslationsLoading } = useContext(TranslationContext);

  useEffect(() => {
    const connectStatus = Cookies.get(
      CONNECT_STATUS_COOKIE_NAME,
    ) as ConnectStatus;

    if (isTranslationsLoading || !connectStatus) {
      return;
    }

    if (connectStatus === ConnectStatus.BindingSuccess) {
      notifyWith.success(CONNECT_STATUS_TEXTS[connectStatus]);
    }

    if (connectStatus === ConnectStatus.BindingError) {
      notifyWith.error(CONNECT_STATUS_TEXTS[connectStatus]);
    }

    Cookies.remove(CONNECT_STATUS_COOKIE_NAME);
  }, [isTranslationsLoading]);
};
