import { IColumn, IconButton } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { currencyFormatter } from 'helpers/formatters';
import { TTransactionData } from 'services/transactions';

import { WithdrawalStatus } from '../../../../../../components';
import {
  StyledWithdrawalActionsWrapper,
  StyledWithdrawalToCellText,
} from '../../../../../../components/WithdrawalHistoryTable/WithdrawalHistoryTable.styled';
import { TGetWithdrawalHistoryTableColumnsProps } from '../../../../../../components/WithdrawalHistoryTable/WithdrawalHistoryTable.types';
import { TTargetMyAccount } from '../../../../types';

export const getToMyAccountWithdrawalHistoryTableColumns = ({
  t,
  currentLanguage,
  onRepeat,
  dateFormatter,
  selectedAccountId,
  accounts,
}: TGetWithdrawalHistoryTableColumnsProps & {
  accounts: TTargetMyAccount[];
}): IColumn<TTransactionData>[] => {
  return [
    {
      accessor: ({ timestamp }: TTransactionData) => {
        return timestamp ? dateFormatter(timestamp) : DEFAULT_EMPTY_PLACEHOLDER;
      },
      Header: t('generic__date'),
      id: 'datetime',
      width: 180,
      disableFilters: true,
    },
    {
      Cell: ({ row: { original: value } }: IColumn<TTransactionData>) => {
        const { asset, sum } = value;

        return currencyFormatter(currentLanguage, asset).format(Number(sum));
      },
      id: 'subject',
      Header: t('generic__asset'),
      cellStyle: { fontWeight: '500' },
      disableFilters: true,
      width: 200,
    },
    {
      accessor: ({ extraData }: TTransactionData) => {
        const accountTo = extraData?.tags?.transfer?.to?.account;

        return accountTo?.length
          ? `${t('layout__transfers__to')} ${accountTo}`
          : DEFAULT_EMPTY_PLACEHOLDER;
      },
      id: 'to',
      Header: t('layout__transfers__to'),
      Cell: ({ value }: IColumn<TTransactionData>) => {
        return (
          <StyledWithdrawalToCellText className="WithdrawalToCellText">
            {value}
          </StyledWithdrawalToCellText>
        );
      },
      width: 370,
      disableFilters: true,
    },
    {
      accessor: (data: TTransactionData) => data,
      id: 'status',
      Header: t('generic__status'),
      Cell: () => <WithdrawalStatus status="executed" />,
      width: 180,
      disableFilters: true,
    },
    {
      accessor: 'comment',
      id: 'comment',
      Header: t('generic__comment'),
      minWidth: 320,
      disableFilters: true,
    },
    {
      accessor: (data: TTransactionData) => data,
      id: 'actions',
      Header: t('generic__actions'),
      align: 'right',
      cellStyle: { paddingRight: '25px' },
      Cell: (props: IColumn<TTransactionData>) => {
        const value = props.value as TTransactionData;
        const target = value.extraData?.tags?.transfer?.to?.account;
        const isAccountExist = accounts.some(
          (item) => item.accountId === target,
        );

        if (target === selectedAccountId || !isAccountExist) {
          return null;
        }

        return (
          <StyledWithdrawalActionsWrapper
            className="WithdrawalActionsWrapper"
            title=""
          >
            <IconButton
              iconName="RotateCcwIcon"
              iconSize={16}
              onClick={() => onRepeat(value)}
            />
          </StyledWithdrawalActionsWrapper>
        );
      },
      width: 115,
    },
  ];
};
