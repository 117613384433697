import { styled } from 'theme';

export const StyledWithdrawalSkeletonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

export const StyledWithdrawalSkeletonSearch = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledWithdrawalSkeletonAccounts = styled('div')`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const StyledTransfersContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: 4px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
    border: 0;
    border-radius: 0;
    border-top: 1px solid ${({ theme }) => theme.color.line.primary};
    border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;

export const StyledTransfersBody = styled('div')`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    border-right: unset;
  }
`;
