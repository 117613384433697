import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  IColumn,
  IconButton,
  IOnFetchArguments,
  useData,
  useTableData,
} from 'react-ui-kit-exante';
import { ActionButtonProps } from 'react-ui-kit-exante/build/Components/Table/ActionButton/ActionButton.types';

import { SORTING_ORDER } from 'constants/SORTING_ORDER';
import { DEFAULT_MAX_PAGINATION_LENGTH } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { ACCOUNT_TYPE } from 'services/accounts/accounts.constants';
import { corporateActionsService } from 'services/corporateActions/corporateActions';
import {
  TCorporateActionPaid,
  TCorporateActionsGetPaidListResponse,
} from 'services/corporateActions/corporateActions.types';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TKitTableSorting } from 'types/TKitTableSorting';
import { TUseTableDataProps } from 'types/TUseTableDataProps';

import { downloadCorporateActionsReport } from '../../CorporateActions.helpers';

import { getCorporateActionsListTableColumns } from './CorporateActionsPostings.helpers';
import {
  StyledCorporateActionsPostingsAccountSelection,
  StyledCorporateActionsPostingsPanel,
  StyledCorporateActionsPostingsTab,
  StyledCorporateActionsPostingsTabIcon,
  StyledCorporateActionsPostingsTable,
  StyledCorporateActionsPostingsTabs,
} from './CorporateActionsPostings.styled';

export const CorporateActionsPostings: FC = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const dateFormatter = useDateFormatter();
  const currencyFormatter = useCurrencyFormatter();

  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const {
    // data: accounts,
    // isLoading: isAccountsLoading,
    fetchData: fetchAccounts,
  } = useData({
    onFetch: () => corporateActionsService.getAllowedAccounts(),
  });

  const { selectedAccountId } = useAppSelector(selectAccounts);
  const isSelectedAccountAggregated = !selectedAccountId?.includes('.');

  const tableDataArgs = useMemo<
    TUseTableDataProps<TCorporateActionsGetPaidListResponse | null>
  >(
    () => ({
      data: {
        onFetch: ({
          params: { skip, limit, ...restParams },
        }: IOnFetchArguments): Promise<TCorporateActionsGetPaidListResponse | null> => {
          const { sorting, page, ...tableParams } = restParams;
          const { id, desc } = (sorting as TKitTableSorting[])[0] || {};

          if (!selectedAccountId) {
            return Promise.resolve(null);
          }

          return corporateActionsService.getPaidList({
            offset: Math.floor(skip / limit),
            limit,
            order: desc ? SORTING_ORDER.DESC : SORTING_ORDER.ASC,
            orderBy: id,
            selectedAccountId,
            tableParams,
          });
        },
        onlyLastRequest: true,
      },
      sorting: {
        getDefaultSorting: () => {
          return [{ id: 'id', desc: true }];
        },
      },
      pagination: {
        getDefaultPagination: () => ({
          limit: DEFAULT_MAX_PAGINATION_LENGTH,
          skip: 0,
        }),
      },
    }),
    [selectedAccountId],
  );

  const {
    limit,
    page,
    setPage,
    setLimit,
    fetchData: fetchTableData,
    data: tableData,
    isLoading: isTableLoading,
    sorting,
    setSorting,
    filters,
    setFilter,
    removeFilter,
    resetFilters,
  } = useTableData<TCorporateActionsGetPaidListResponse | null>(tableDataArgs);

  const columns = useMemo((): IColumn<TCorporateActionPaid>[] => {
    return getCorporateActionsListTableColumns({
      t,
      onRemove: removeFilter,
      onFilter: setFilter,
      hideAccountId: isSelectedAccountAggregated,
      dateFormatter,
      currencyFormatter,
    });
  }, [t, isSelectedAccountAggregated]);

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      manualFilters: true,
    }),
    [filters, resetFilters],
  );

  const downloadReport = async () => {
    if (!selectedAccountId) {
      return;
    }

    setIsDownloadingReport(true);

    await downloadCorporateActionsReport({
      url: `/clientsarea/rest/cas/paid_dividend_events/xls/?account_id=${selectedAccountId}`,
      fileName: 'paid_dividend_events.xls',
    });
    setIsDownloadingReport(false);
  };

  const tableActions = useMemo(
    (): ActionButtonProps[] => [
      {
        component: (
          <IconButton
            iconName="XLSIcon"
            iconColor="secondary"
            iconSize={24}
            className="CorporateActionsPostingsDownload"
            onClick={downloadReport}
            disabled={isDownloadingReport || !selectedAccountId}
            title={
              isDownloadingReport ? t('generic__loading_state') : undefined
            }
            data-test-id="corporate_actions__postings_download"
          />
        ),
      },
    ],
    [isDownloadingReport, selectedAccountId, t],
  );

  const total = tableData?.pagination.total || 0;
  const data = useMemo(() => tableData?.content || [], [tableData?.content]);
  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount: Math.ceil(total / limit),
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [total, setPage, limit, page, setLimit],
  );

  useEffect(() => {
    fetchTableData();
    fetchAccounts();
  }, []);

  return (
    <StyledCorporateActionsPostingsPanel>
      <StyledCorporateActionsPostingsAccountSelection
        titleKey={t('corporate_actions__investment_accounts__title')}
        showCarousel
        isHideAddAccount
        isHideQR
        isHideDate
        isHideBalanceButton
        excludeAccountPurposes={[
          ACCOUNT_TYPE.PARTNER,
          ACCOUNT_TYPE.PAYMENT,
          ACCOUNT_TYPE.CARD,
        ]}
      />
      <StyledCorporateActionsPostingsTabs value="dividends">
        <StyledCorporateActionsPostingsTab
          className="CorporateActionsPostingsTab"
          icon={
            <StyledCorporateActionsPostingsTabIcon
              iconName="DividentsIcon"
              color="transparent"
            />
          }
          label={t('generic__dividends')}
          key="dividends"
          value="dividends"
        />
      </StyledCorporateActionsPostingsTabs>
      <StyledCorporateActionsPostingsTable<TCorporateActionPaid>
        hasPagination
        isFlexLayout
        translator={t}
        className="CorporateActionsPaidListTable"
        showTableInfo
        columns={columns}
        data={data}
        onSort={setSorting}
        defaultSortBy={sorting}
        manualSortBy
        additionalActions={tableActions}
        hasFilters
        filteringProps={filteringProps}
        tableId="corporateActionsPaidListTable"
        locale={currentLanguage}
        isLoading={isTableLoading || isTranslationsLoading}
        pageSize={limit}
        serverPaginationProps={serverPaginationProps}
      />
    </StyledCorporateActionsPostingsPanel>
  );
};
