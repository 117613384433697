import { RefObject, useCallback, useMemo, useRef } from 'react';

import { TMatchIdAndPositionInTable } from '../InstrumentsTree.types';

type TUseMatchIdAndPositionInTable = {
  updateMatchIdAndPositionInSearchTable: (
    newData: TMatchIdAndPositionInTable,
  ) => void;
  matchIdAndPositionInNonSearchTable: RefObject<TMatchIdAndPositionInTable>;
  matchIdAndPositionInSearchTable: RefObject<TMatchIdAndPositionInTable>;
  updateMatchIdAndPositionInNonSearchTable: (
    newData: TMatchIdAndPositionInTable,
  ) => void;
};

export function useMatchIdAndPositionInTable(): TUseMatchIdAndPositionInTable {
  const matchIdAndPositionInSearchTable = useRef<TMatchIdAndPositionInTable>(
    {},
  );
  const matchIdAndPositionInNonSearchTable = useRef<TMatchIdAndPositionInTable>(
    {},
  );
  const matchIdAndPositionInSearchTableWithoutInstruments =
    useRef<TMatchIdAndPositionInTable | null>(null);

  const updateMatchIdAndPositionInSearchTable = useCallback(
    (newData: TMatchIdAndPositionInTable) => {
      matchIdAndPositionInSearchTable.current = {
        ...matchIdAndPositionInSearchTable.current,
        ...newData,
      };

      if (!matchIdAndPositionInSearchTableWithoutInstruments.current) {
        matchIdAndPositionInSearchTableWithoutInstruments.current = {
          ...newData,
        };
      }
    },
    [],
  );

  const updateMatchIdAndPositionInNonSearchTable = useCallback(
    (newData: TMatchIdAndPositionInTable) => {
      matchIdAndPositionInNonSearchTable.current = newData;
    },
    [],
  );

  return useMemo(
    () => ({
      updateMatchIdAndPositionInSearchTable,
      matchIdAndPositionInNonSearchTable,
      matchIdAndPositionInSearchTable,
      updateMatchIdAndPositionInNonSearchTable,
    }),
    [
      matchIdAndPositionInNonSearchTable,
      matchIdAndPositionInSearchTable,
      updateMatchIdAndPositionInNonSearchTable,
      updateMatchIdAndPositionInSearchTable,
    ],
  );
}
