import { FC } from 'react';

import {
  AccountIcon,
  AssetManagementIcon,
  ChallengeCommunityIcon,
  ChallengePayoutIcon,
  ChallengePurchasesIcon,
  ChallengeSquawkboxIcon,
  GbxpFeesIcon,
  HelpIcon,
  KycFormIcon,
  MyCardsIcon,
  PartnerIcon,
  PortfolioIcon,
  ReportsIcon,
  SettingsIcon,
  TermsIcon,
  TradingIcon,
  UserManagementIcon,
} from 'assets/icons';
import { PATHS } from 'router/router.constants';

export const NAVIGATION_ITEM_ICONS: Record<string, FC> = {
  account: AccountIcon,
  assetManagement: AssetManagementIcon,
  challengePayout: ChallengePayoutIcon,
  challengePurchases: ChallengePurchasesIcon,
  gbxpFees: GbxpFeesIcon,
  help: HelpIcon,
  kycForm: KycFormIcon,
  myCards: MyCardsIcon,
  partner: PartnerIcon,
  partnerKyc: PartnerIcon,
  portfolio: PortfolioIcon,
  pitCommunity: ChallengeCommunityIcon,
  reports: ReportsIcon,
  settings: SettingsIcon,
  squawkbox: ChallengeSquawkboxIcon,
  terms: TermsIcon,
  trading: TradingIcon,
  userManagement: UserManagementIcon,
  cas: PortfolioIcon,
};

const { ROOT, ...preparedNonRootPaths } = PATHS;
export const NAVIGATION_NON_ROOT_PATHS: Record<string, string> =
  preparedNonRootPaths;
