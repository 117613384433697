import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TCustomLegendAxisName } from './CustomLegend.types';

export const StyledLegendContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 32px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledLegendItem = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledLegendLine = styled('div')`
  width: 24px;
  height: 1px;
`;

export const StyledLegendBars = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledLegendBar = styled('div')`
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export const StyledChartYAxisName = styled('div', {
  shouldForwardProp: blockNonNativeProps(['width', 'alignAxisName']),
})<TCustomLegendAxisName>`
  position: absolute;
  top: 24px;
  ${({ alignAxisName }) => `${alignAxisName}`}: 0;
  width: ${({ width }) => `${width}px`};
  font-size: 11px;
`;
