import { dashboardService } from 'services/dashboard';

import { apiStateInitialState } from './constants';
import { TFetchApiStateResponse } from './types';

export const fetchApiState = async (
  lang: string,
): Promise<TFetchApiStateResponse> => {
  const dashboard = await dashboardService.fetchDashboard(lang);
  const applications = await dashboardService.fetchDashboardApplications();

  return { applications, dashboard: dashboard || apiStateInitialState };
};
