import { TFunction } from 'i18next';
import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { PeriodicActionCell } from 'pages/Reports/tabs/PeriodicReports/PeriodicActionCell';
import { TPeriodicCell } from 'pages/Reports/tabs/PeriodicReports/PeriodicReports.types';

import { PeriodicCheckboxCell } from './PeriodicCheckboxCell';

type TPeriodicReportsColumns = {
  t: TFunction;
  onDownloadReport: (reportName: string) => Promise<void>;
};

const checkboxCellConfig = {
  width: 90,
  Cell: ({ row: { original }, column }: ICellValue<TPeriodicCell>) => {
    return <PeriodicCheckboxCell data={original} columnId={column.id} />;
  },
  align: 'center',
  style: { flexGrow: 0 },
};

export const getColumns: ({
  t,
}: TPeriodicReportsColumns) => IColumn<TPeriodicCell>[] = ({
  t,
  onDownloadReport,
}) => [
  {
    id: 'name',
    Header: t('report'),
    accessor: 'name',
    width: 600,
  },
  {
    id: 'daily',
    Header: t('generic__daily'),
    accessor: 'daily',
    ...checkboxCellConfig,
  },
  {
    id: 'monthly',
    Header: t('generic__montly'),
    accessor: 'monthly',
    ...checkboxCellConfig,
  },
  {
    id: 'pdf',
    Header: t('generic__pdf'),
    accessor: 'pdf',
    ...checkboxCellConfig,
  },
  {
    id: 'csv',
    Header: t('generic__csv'),
    accessor: 'csv',
    ...checkboxCellConfig,
  },
  {
    id: 'xls',
    Header: t('generic__xls'),
    accessor: 'xls',
    ...checkboxCellConfig,
  },
  {
    id: 'action',
    Header: t('Order'),
    accessor: 'action',
    align: 'right',
    width: 90,
    Cell: ({ row: { original } }: ICellValue<TPeriodicCell>) => (
      <PeriodicActionCell data={original} onDownloadReport={onDownloadReport} />
    ),
  },
];

export const getMobileCheckboxColumns = (
  t: TFunction,
  column: TPeriodicCell,
) => {
  return ['daily', 'monthly', 'pdf', 'csv', 'xls'].map((item) => (
    <PeriodicCheckboxCell key={item} data={column} columnId={item} withLabel />
  ));
};
