import { AxiosError } from 'axios';
import { useContext, useEffect, useReducer } from 'react';

import { transferService, TransferType } from 'services/transfer';

import { TransfersContext } from '../../../../../context';

import { initialToInternalAccountState } from './constants';
import { toInternalAccountReducer } from './reducer';

export const useToInternalAccount = (selectedAccountId: string) => {
  const [toInternalAccountState, toInternalAccountDispatch] = useReducer(
    toInternalAccountReducer,
    initialToInternalAccountState,
  );

  const { cashTransferTab } = useContext(TransfersContext);

  const fetchToMyAccountState = async (currency: string) => {
    toInternalAccountDispatch({ type: 'FETCH_LIMITS_START' });

    try {
      const { limits } = await transferService.fetchTransferDirections(
        selectedAccountId,
        TransferType.ToInternalAccount,
      );

      toInternalAccountDispatch({
        type: 'FETCH_LIMITS_SUCCESS',
        payload: String(limits[currency] || 0),
      });
    } catch (error) {
      const { message } = error as AxiosError;
      toInternalAccountDispatch({
        type: 'FETCH_LIMITS_ERROR',
        payload: message,
      });
    }
  };

  useEffect(() => {
    if (toInternalAccountState.currency && cashTransferTab === 'INTERNAL') {
      fetchToMyAccountState(toInternalAccountState.currency);
    }
  }, [toInternalAccountState.currency, cashTransferTab]);

  return {
    toInternalAccountState,
    toInternalAccountDispatch,
  };
};
