import { TChallengeAccountSummaryContextValue } from './ChallengeAccountSummaryContext.types';

export const challengeAccountSummaryContextInitialValue: TChallengeAccountSummaryContextValue =
  {
    loading: true,
    accountJoinedToChallengeStatus: null,
    currentBalance: null,
    profitTarget: null,
    dailyDd: null,
    maxDd: null,
    currentEquity: null,
    dayTraded: null,
    remainingDays: null,
    challengeDateStart: null,
  };
