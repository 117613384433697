import { FC, useContext, useMemo } from 'react';
import { Controller, useFormState, useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-ui-kit-exante';

import { FormCheckboxGroupContainer } from 'components/Form/FormCheckboxGroupContainer/FormCheckboxGroupContainer';
import { StyledPageWrapper } from 'components/PageWrapper/PageWrapper.styled';
import { CurrencyContext, TranslationContext } from 'contexts';
import { AccountAutocomplete } from 'pages/Reports/components/AccountAutocomplete';

import { StyledReportsContainer } from '../../../../Reports.styled';
import { TReportEditProps } from '../../ReportEdit.types';

import { REPORT_TYPE_TITLES } from './ReportEditForm.constants';
import {
  StyledCurrencySelect,
  StyledReportEditActions,
  StyledReportEditFieldset,
  StyledReportEditHead,
  StyledReportEditIconButton,
  StyledReportEditPanel,
  StyledReportEditSection,
  StyledReportEditSectionFormatList,
  StyledReportEditSectionTitle,
} from './ReportEditForm.styled';
import {
  FormCheckboxGroupSkeleton,
  ReportEditType,
  ReportEditTypeSkeleton,
} from './components';
import { ReportFormContext } from './contexts/ReportFormContext';
import { isFormatSupported } from './contexts/ReportFormContext/ReportFormContext.helpers';

const REPORT_EDIT_TYPE_SKELETON_COUNT = 6;

export const ReportEditForm: FC<TReportEditProps> = ({ isNew = false }) => {
  const { t } = useContext(TranslationContext);

  const { refs, handleSubmit, formControl, isLoading, isSaving } =
    useContext(ReportFormContext);

  const { currencies } = useContext(CurrencyContext);

  const location = useLocation();
  const navigate = useNavigate();
  const isFirstLocation = location.key === 'default';
  const goBack = () => {
    navigate(-1);
  };

  const values = useWatch({ control: formControl });
  const formatValue = values?.format || [];

  const typesSafe = refs?.types || {};

  const types = useMemo(
    () =>
      Object.entries(typesSafe)
        .map(([key, title]) => ({
          key,
          title,
        }))
        .filter((type) => isFormatSupported(type.key, formatValue)),
    [typesSafe, formatValue],
  );

  const { isValid } = useFormState({ control: formControl });

  return (
    <form onSubmit={handleSubmit}>
      <StyledReportEditFieldset
        className="ReportEditFieldset"
        disabled={isSaving}
      >
        <StyledPageWrapper className="ReportsWrapper">
          <StyledReportsContainer className="ReportsContainer">
            <StyledReportEditPanel
              title={t(
                isNew
                  ? 'reports__tab_name__custom_create'
                  : 'reports__tab_name__custom_edit',
              )}
              action={
                <StyledReportEditActions className="ReportEditActions">
                  <Tooltip
                    title={
                      isValid ? '' : t('layout__reports__custom__form__hint')
                    }
                  >
                    <StyledReportEditIconButton
                      color="action"
                      iconColor="action"
                      iconSize={32}
                      label={t('layout__reports__edit_form__save')}
                      iconName="SaveIcon"
                      type="submit"
                      data-test-id="report-custom-edit__button--save"
                      className="SaveButton"
                      disabled={isLoading || !isValid}
                    />
                  </Tooltip>
                  <StyledReportEditIconButton
                    iconName="CloseIcon"
                    iconSize={32}
                    onClick={goBack}
                    disabled={isFirstLocation}
                    className="CloseButton"
                  />
                </StyledReportEditActions>
              }
            >
              <StyledReportEditHead className="ReportEditHead">
                <Controller
                  name="accounts"
                  control={formControl}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <AccountAutocomplete
                        value={value}
                        onChange={onChange}
                        dataTestId="report-custom__select__account"
                        forceSkeleton={isLoading}
                        lockSingleOption
                      />
                    );
                  }}
                />
                <Controller
                  name="currency"
                  control={formControl}
                  render={({ field: { onChange, value } }) => (
                    <StyledCurrencySelect
                      className="CurrencySelect"
                      onChange={onChange}
                      value={value}
                      options={currencies?.list || []}
                      icons={currencies?.icons || {}}
                      showSkeleton={isLoading}
                      data-test-id="report-custom__select__currency"
                    />
                  )}
                />
              </StyledReportEditHead>
              <StyledReportEditSection className="ReportEditSection">
                <StyledReportEditSectionTitle className="ReportEditSectionTitle">
                  {t('layout__reports__edit_form__build_in')}
                </StyledReportEditSectionTitle>
                <StyledReportEditSectionFormatList className="ReportEditSectionFormatList">
                  {isLoading ? (
                    <FormCheckboxGroupSkeleton />
                  ) : (
                    <FormCheckboxGroupContainer
                      hideErrors
                      name="format"
                      options={
                        refs?.formats?.map((format) => ({
                          label: format,
                          value: format,
                        })) || []
                      }
                    />
                  )}
                </StyledReportEditSectionFormatList>
              </StyledReportEditSection>
              {isLoading
                ? [...Array(REPORT_EDIT_TYPE_SKELETON_COUNT)].map((_, i) => (
                    <StyledReportEditSection
                      className="EditReportSection"
                      key={i}
                    >
                      <ReportEditTypeSkeleton />
                    </StyledReportEditSection>
                  ))
                : types.map((type) => (
                    <StyledReportEditSection
                      className="EditReportSection"
                      key={type.key}
                    >
                      <ReportEditType
                        type={type.key}
                        title={t(
                          REPORT_TYPE_TITLES[type.key] || type.title || '',
                        )}
                      />
                    </StyledReportEditSection>
                  ))}
            </StyledReportEditPanel>
          </StyledReportsContainer>
        </StyledPageWrapper>
      </StyledReportEditFieldset>
    </form>
  );
};
