import { PATHS } from 'router/router.constants';

export const PartnerTypes = {
  CPA: 'cpa',
  REVSHARE: 'revshare',
} as const;

export const PARTNER_PAYMENTS_URL = `${
  PATHS.ROOT + PATHS.PARTNER
}?tab=payments`;

export const DEFAULT_PATNER_TYPE = PartnerTypes.CPA;
