import { FC, useContext } from 'react';
import { Loader as LoaderRUI, TLoaderProps } from 'react-ui-kit-exante';

import { BrandingContext } from 'contexts/BrandingContext';

import { StyledLoaderIcon } from './LoaderIcon.styled';

/**
 * Puts an icon from the branding, if available, to the Tooltip from RUI
 *
 * If context is not available or icon is empty,
 * defaults to RUI loader behavior
 */
export const Loader: FC<TLoaderProps> = (loaderProps) => {
  const { branding } = useContext(BrandingContext);
  const loaderIconUrl = branding?.branding?.ui_settings?.icon_loader;

  return (
    <LoaderRUI {...loaderProps}>
      {loaderIconUrl ? <StyledLoaderIcon iconUrl={loaderIconUrl} /> : null}
    </LoaderRUI>
  );
};
