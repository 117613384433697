import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TDepositHistoryStatusCellStyledProps } from './DepositHistoryTable.types';

export const StyledDepositHistoryStatusCell = styled('div', {
  shouldForwardProp: blockNonNativeProps(['color']),
})<TDepositHistoryStatusCellStyledProps>`
  color: ${({ theme, color }) =>
    theme.color.icon[color] || theme.color.typo.primary};

  font-family: ${({ theme }) => theme.font.main};

  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;

export const StyledDepositHistorySubRow = styled('div')`
  padding: 20px;
  font-size: 13px;
`;

export const StyledDepositHistorySubRowAddress = styled('div')`
  width: 100%;

  font-size: 13px;

  display: flex;

  align-items: flex-start;
  gap: 8px;
`;

export const StyledDepositHistorySubRowAddressText = styled('div')`
  color: ${({ theme }) => theme.color.typo.action};
`;

export const StyledDepositHistoryAddressTextClipped = styled('span')`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
