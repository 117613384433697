import cn from 'classnames';
import { FC } from 'react';

import { TTreeResponseTreeStructure } from 'services/instrumentsTree/instrumentsTree.types';

import { NODE_FOR_MANUAL_BORDER } from './NameCell.constants';
import {
  StyledCellRoot,
  StyledIcon,
  StyledLoader,
  StyledName,
  StyledParthenonIcon,
} from './NameCell.styled';
import { TCellProps, TNameCellProps } from './NameCell.types';

export const NameCell: FC<
  TCellProps<TTreeResponseTreeStructure> & TNameCellProps
> = ({ row, loadingInstruments, className }) => {
  const {
    original,
    depth,
    values: { name },
    id,
  } = row;

  const isSecondLevel = depth === 1;
  const isFirstLevel = depth === 0;
  const isColored = !isSecondLevel && !!original.icon;
  const displayParthenon = isSecondLevel && original.rowType === 'node';
  const icon = original.icon || '';

  return (
    <StyledCellRoot
      className={cn(['CellRoot', className])}
      data-parthenon={displayParthenon}
      data-colored={isColored}
    >
      {isColored && (
        <StyledIcon
          className="Icon"
          data-border={name?.toString() === NODE_FOR_MANUAL_BORDER}
          style={{ backgroundImage: `url(${icon})` }}
        />
      )}

      {loadingInstruments?.has(id) && (
        <StyledLoader className="Loader" isInner />
      )}

      {!isColored && isFirstLevel && <StyledIcon className="IconEmpty" />}

      {displayParthenon && <StyledParthenonIcon />}

      <StyledName className="Name">{name}</StyledName>
    </StyledCellRoot>
  );
};
