import { blockNonNativeProps, Checkbox } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledFormCheckboxContainerProps } from './FormCheckboxContainer.types';

export const StyledFormCheckboxContainer = styled(Checkbox, {
  shouldForwardProp: blockNonNativeProps(['noWrap']),
})<TStyledFormCheckboxContainerProps>`
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'inherit')});
  text-color: ${({ theme }) => theme.color.typo.primary};
`;
