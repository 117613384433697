import { Dispatch, SetStateAction } from 'react';

import { TGetAvailableTextProps } from 'pages/Portfolio/tabs/Exchange/components/ExchangeAmountBlock/ExchangeAmountBlock.types';
import { EXCHANGE_INPUT_LIMIT } from 'pages/Portfolio/tabs/Exchange/contexts/ExchangeContext/ExchangeContext.constants';

export const getAvailableText = ({
  limit,
  currency,
  amount,
  translateFunction: t,
}: TGetAvailableTextProps): string => {
  const defaultText = '';

  if (!currency || limit === null) {
    return defaultText;
  }

  let res: number;

  if (!Number.isFinite(amount)) {
    res = limit;
  } else {
    res = limit - <number>amount;
  }

  if (res < 0) {
    res = 0;
  } else {
    res = Number(res.toFixed(2));
  }

  return `${t(
    'layout__subaccount-transfer-amount__available-hint',
  )} ${res} ${currency}`;
};

export const validateAndUpdateAmount = (
  value: string,
  localUpdater: Dispatch<SetStateAction<string>>,
  updater: (value: number | null) => void,
) => {
  const number = Number(value);

  if (
    /[^\d.]/.test(value) || // only numbers and dots
    [...value.matchAll(/\./g)].length > 1 || // one dot
    /\.\d{3,}/.test(value) || // only two digit fraction
    value.split('.')[0].length > EXCHANGE_INPUT_LIMIT // integer part no longer than limit
  ) {
    return;
  }

  localUpdater(value);

  if (!value.length) {
    updater(null);
    return;
  }

  if (Number.isFinite(number)) {
    updater(number);
  }
};
