import { ChangeEvent, FC, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { Input } from 'react-ui-kit-exante';

import { AccordionArrowPosition } from 'components/Accordion';
import { TranslationContext } from 'contexts';
import { StyledHelpAnchor, StyledHelpText } from 'pages/Help/Help.styled';

import { StyledSearchIcon } from '../../HelpFAQ.styled';

import { hasInQuestion } from './QuestionBlock.helpers';
import {
  StyledAccordionTitleText,
  StyledAccordionWrapper,
  StyledFAQTabContentWrapper,
  StyledQuestionAccordion,
  StyledQuestionText,
} from './QuestionBlock.styled';
import { TQuestionBlockProps } from './QuestionBlock.types';

export const QuestionBlock: FC<TQuestionBlockProps> = ({
  questions,
  fallbackUrl,
}) => {
  const { t } = useContext(TranslationContext);

  const [active, setActive] = useState<string | null>(null);
  const [query, setQuery] = useState('');

  const words = query.toLowerCase().trim().split(' ');

  const questionsFiltered = questions
    ? questions.filter((question) => hasInQuestion(words, question))
    : [];

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleAccordionChange = (id: string) =>
    setActive(id === active ? null : id);

  const hasQuestions = Boolean(questions?.length);
  const hasQuestionsFiltered = Boolean(questionsFiltered?.length);

  const noQuestions = hasQuestions ? null : (
    <StyledHelpText className="HelpText">
      <Trans
        t={t}
        i18nKey="layout__help__faq__coming_soon"
        components={{
          a: <StyledHelpAnchor className="HelpAnchor" href={fallbackUrl} />,
        }}
      />
    </StyledHelpText>
  );

  const noQuestionsFiltered =
    hasQuestionsFiltered || !hasQuestions
      ? null
      : t(`layout__help__no_results`, { QUERY: query });

  return (
    <StyledFAQTabContentWrapper className="FAQTabContentWrapper">
      {noQuestions || (
        <Input
          fullWidth
          iconLeft={
            <StyledSearchIcon className="SearchIcon" role="presentation" />
          }
          value={query}
          placeholder={t('generic__search')}
          onChange={handleSearchInput}
        />
      )}
      <StyledAccordionWrapper className="AccordionWrapper">
        {noQuestionsFiltered ||
          questionsFiltered.map((question, i) => {
            const id = `${question.title}-${i}`;

            return (
              <StyledQuestionAccordion
                id={id}
                key={id}
                onChange={handleAccordionChange}
                isActive={id === active}
                arrowPosition={AccordionArrowPosition.Right}
                className="QuestionAccordion"
                title={
                  <StyledAccordionTitleText className="AccordionTitleText">
                    {question.title}
                  </StyledAccordionTitleText>
                }
              >
                <StyledQuestionText
                  className="QuestionText"
                  dangerouslySetInnerHTML={{ __html: question.text }}
                />
              </StyledQuestionAccordion>
            );
          })}
      </StyledAccordionWrapper>
    </StyledFAQTabContentWrapper>
  );
};
