import { createContext, FC, useMemo, useState } from 'react';

import { TChildren } from 'types/TChildren';

import { useTransferFormsConfig } from '../../../../hooks';

import { TSecuritiesContextState } from './SecuritiesContext.types';

const initialState: TSecuritiesContextState = {
  isShowConfirmation: false,
  isTransferProcessing: false,
  setIsShowConfirmation: () => {},
  setIsTransferProcessing: () => {},
  requiredFields: [],
  notRequiredFields: [],
  visibleFields: [],
};

export const SecuritiesContext =
  createContext<TSecuritiesContextState>(initialState);

export const SecuritiesProvider: FC<TChildren> = ({ children }) => {
  const { visibleFields, requiredFields, notRequiredFields } =
    useTransferFormsConfig('Security');

  const [isShowConfirmation, setIsShowConfirmation] = useState(false);
  const [isTransferProcessing, setIsTransferProcessing] = useState(false);

  const value = useMemo<TSecuritiesContextState>(
    (): TSecuritiesContextState => ({
      visibleFields,
      requiredFields,
      notRequiredFields,
      isShowConfirmation,
      isTransferProcessing,
      setIsShowConfirmation,
      setIsTransferProcessing,
    }),
    [
      visibleFields,
      requiredFields,
      notRequiredFields,
      isShowConfirmation,
      isTransferProcessing,
      setIsShowConfirmation,
      setIsTransferProcessing,
    ],
  );

  return (
    <SecuritiesContext.Provider value={value}>
      {children}
    </SecuritiesContext.Provider>
  );
};
