import { times } from 'lodash';
import { FC } from 'react';

import { StyledAccordionWrapper } from '../../../QuestionBlock/QuestionBlock.styled';

import {
  StyledAccordionIconSkeleton,
  StyledAccordionSkeletonRow,
  StyledAccordionTextSkeleton,
} from './AccordionSkeleton.styled';

export const AccordionSkeleton: FC = () => (
  <StyledAccordionWrapper className="AccordionWrapper">
    {times(6, (i) => (
      <StyledAccordionSkeletonRow key={i}>
        <StyledAccordionTextSkeleton variant="rounded" animation="wave" />
        <StyledAccordionIconSkeleton variant="rounded" animation="wave" />
      </StyledAccordionSkeletonRow>
    ))}
  </StyledAccordionWrapper>
);
