import { styled } from 'theme';

export const StyledHeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledEnvLabel = styled('span')<{ isDemo?: boolean }>`
  padding: 0 16px;
  font-size: 16px;
  font-weight: 400;
  transform: translateY(2px);
  line-height: 16px;
  border-radius: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.typo.inverse};
  background: ${({ theme, isDemo }) =>
    isDemo ? theme.color.typo.secondary : theme.color.typo.source};
`;
