import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useData } from 'react-ui-kit-exante';

import { DEFAULT_CURRENCY } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';
import {
  TLockedAssets,
  transferService,
  TSecuritiesSymbol,
} from 'services/transfer';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { useTransfers } from '../../../../../hooks';

const groups = ['bond', 'stock', 'future', 'option'] as const; // Bonds, Stocks, Futures, Options

const unlockedQuantity = (locks: TLockedAssets, item: TSecuritiesSymbol) => {
  const matchedLocks = locks.filter((lock) => lock.asset === item.symbolId);
  let quantity = parseFloat(item.quantity);

  for (let i = 0; i < matchedLocks.length; i++) {
    const lockAmount = parseFloat(matchedLocks[i].amount);
    quantity -= lockAmount;
  }
  return quantity;
};

export const useSecuritiesAmount = () => {
  const { currentLanguage } = useContext(TranslationContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const { isSecuritiesTransferEnabled } = useTransfers();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const {
    data: positionsData,
    fetchData: fetchPositionsData,
    isLoading: positionsIsLoading,
  } = useData({
    onFetch: () =>
      transferService.getSecuritiesDetails({
        currency: DEFAULT_CURRENCY,
        account: selectedAccountId || '',
        lang: currentLanguage,
      }),
  });

  const {
    data: lockedAssetsData,
    fetchData: fetchLockedAssets,
    isLoading: lockedAssetsIsLoading,
  } = useData({
    onFetch: () =>
      transferService.getLockedAssets({
        account: selectedAccountId || '',
        lang: currentLanguage,
      }),
  });

  const {
    data: instrumentsData,
    fetchData: fetchInstruments,
    isLoading: instrumentsIsLoading,
  } = useData({
    onFetch: () => transferService.getInstruments(currentLanguage),
  });

  const fetchPositions = async () => {
    await fetchLockedAssets();
    await fetchPositionsData();
    setIsFirstLoad(false);
  };

  useEffect(() => {
    if (isSecuritiesTransferEnabled) {
      const positionsUpdater = setInterval(fetchPositions, 30 * 1000);
      fetchPositions();

      return () => clearInterval(positionsUpdater);
    }
    return () => {};
  }, [isSecuritiesTransferEnabled]);

  useEffect(() => {
    fetchInstruments();
  }, []);

  const availablePositions = useMemo(() => {
    const symbols = [
      ...groups.map((group) => positionsData?.[group] || []).flat(),
    ];
    return symbols?.filter(
      (item) => unlockedQuantity(lockedAssetsData || [], item) > 0,
    );
  }, [positionsData, lockedAssetsData]);

  const getUnlockedQuantity = useCallback(
    (item: TSecuritiesSymbol) => {
      return unlockedQuantity(lockedAssetsData || [], item);
    },
    [lockedAssetsData],
  );

  return {
    positions: availablePositions,
    instruments: instrumentsData,
    lockedAsset: lockedAssetsData,
    positionsIsLoading: positionsIsLoading && isFirstLoad,
    lockedAssetsIsLoading: lockedAssetsIsLoading && isFirstLoad,
    instrumentsIsLoading,
    getUnlockedQuantity,
    fetchPositions,
  };
};
