import { useMediaQuery } from '@mui/material';
import { FC, useContext } from 'react';

import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { TPartnerLandingImages } from 'services/branding';
import { theme } from 'theme';

import {
  StyledSplashScreen,
  StyledSplashScreenButton,
  StyledSplashScreenDesc,
  StyledSplashScreenHeading,
  StyledSplashScreenLogo,
  StyledSplashScreenPicture,
  StyledSplashScreenSubheading,
} from './SplashScreen.styled';
import { TSplashScreenProps } from './SplashScreen.types';

export const SplashScreen: FC<TSplashScreenProps> = ({ onChooseType }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const partnerImages: TPartnerLandingImages | undefined =
    branding?.branding?.ui_settings?.partner_landing_images;

  return (
    <StyledSplashScreen className="SplashScreen">
      <StyledSplashScreenHeading className="SplashScreenHeading">
        <StyledSplashScreenLogo
          className="SplashScreenLogo"
          src={partnerImages?.logo}
          alt={t('layout__partner_landing__logo_alt')}
        />
      </StyledSplashScreenHeading>
      <StyledSplashScreenSubheading
        className="SplashScreenSubheading"
        dangerouslySetInnerHTML={{
          __html: t('layout__partner_landing__main_title'),
        }}
      />
      <StyledSplashScreenDesc className="SplashScreenDesc">
        {t('layout__partner_landing__main_desc')}
      </StyledSplashScreenDesc>
      <picture>
        <source
          media="(max-width: 1023px)"
          srcSet={partnerImages?.splash_bg_mobile}
        />
        <source
          media="(min-width: 1024px)"
          srcSet={partnerImages?.splash_bg_desktop}
        />
        <StyledSplashScreenPicture
          className="SplashScreenPicture"
          src={partnerImages?.splash_bg_mobile}
          alt=""
          loading="lazy"
        />
      </picture>
      <StyledSplashScreenButton
        onClick={onChooseType}
        className="SplashScreenButton"
        fullWidth={!isDesktop}
        data-test-id="partner__splash-screen__choose"
      >
        {t('layout__partner_landing__choose_btn')}
      </StyledSplashScreenButton>
    </StyledSplashScreen>
  );
};
