import { FC, useContext, useState } from 'react';

import { TranslationContext } from 'contexts';

import {
  StyledAgreementsW8FormWrapper,
  StyledW8FormAnchor,
  StyledW8FormIframe,
  StyledW8FormSkeleton,
  StyledW8FormText,
} from './AgreementsW8Form.styled';
import { TAgreementsW8FormProps } from './AgreementsW8Form.types';

export const AgreementsW8Form: FC<TAgreementsW8FormProps> = ({ url }) => {
  const { t } = useContext(TranslationContext);

  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <StyledAgreementsW8FormWrapper className="AgreementsW8FormWrapper">
      <StyledW8FormText className="W8FormText">
        {t('layout__legal_docs__form_available_via')}&nbsp;
        <StyledW8FormAnchor className="W8FormAnchor" href={url} target="_blank">
          {t('layout__legal_docs__irs_w-8_form')}
        </StyledW8FormAnchor>
      </StyledW8FormText>
      {isLoading && <StyledW8FormSkeleton variant="rounded" animation="wave" />}
      <StyledW8FormIframe
        onLoad={handleIframeLoad}
        className="W8FormIframe"
        title={t('layout__legal_docs__irs_w-8_forms')}
        isVisible={!isLoading}
        src={url}
      />
    </StyledAgreementsW8FormWrapper>
  );
};
