import { blockNonNativeProps, css } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledGroupFilterProps } from './FiltersGroup.types';

export const StyledGroupFilters = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export const StyledGroupFilter = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isSelected']),
})<TStyledGroupFilterProps>`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.input.border};
  background-color: ${({ theme }) => theme.color.input.bg.default};
  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 13px;
  line-height: 16px;

  ${({ theme, isSelected }) => {
    if (isSelected) {
      return css`
        background-color: ${theme.color.dropdown.chip.bg};
        color: ${theme.color.typo.primary};
      `;
    }
    return '';
  }}

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.color.typo.primary};
  }
`;
