import { ICellValue } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';

import { TCreateCurrencyFormatter } from './currencyFormatter';
import { formatCurrencyUnrounded } from './formatCurrencyUnrounded';

export const formatCellToCurrencyUnrounded = <
  Data extends Partial<Record<keyof Data, unknown>>,
>(
  currencyFormatter: TCreateCurrencyFormatter,
  field: keyof Data,
) => {
  return ({ row: { values } }: ICellValue<Data>): string => {
    const numberedValue = Number(values[field]);

    if (Number.isNaN(numberedValue)) {
      return DEFAULT_EMPTY_PLACEHOLDER;
    }

    return formatCurrencyUnrounded(currencyFormatter, numberedValue);
  };
};
