import { FC } from 'react';

import { StyledDepositSection } from '../../../../../DepositWrapper/DepositWrapper.styled';
import {
  StyledInfoSectionBlock,
  StyledInfoSectionContainer,
} from '../../InfoCashSection.styled';

import {
  StyledInfoIconSkeleton,
  StyledInfoTextSkeleton,
} from './InfoCashSkeleton.styled';

export const InfoCashSkeleton: FC = () => {
  return (
    <StyledDepositSection className="DepositSection">
      <StyledInfoSectionContainer className="InfoSectionContainer">
        {[...Array(3)].map((_, i) => (
          <StyledInfoSectionBlock className="InfoSectionBlock" key={i}>
            <StyledInfoIconSkeleton variant="rounded" animation="wave" />
            <StyledInfoTextSkeleton variant="rounded" animation="wave" />
          </StyledInfoSectionBlock>
        ))}
      </StyledInfoSectionContainer>
    </StyledDepositSection>
  );
};
