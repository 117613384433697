import cn from 'classnames';
import { FC } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import { InfoIcon } from 'assets/icons';

import { StyledGenericTransferFormTitle } from './GenericTransferFormTitle.styled';
import { TGenericTransferFormTitleProps } from './GenericTransferFormTitle.types';

export const GenericTransferFormTitle: FC<TGenericTransferFormTitleProps> = ({
  children,
  hint,
  className,
}) => (
  <StyledGenericTransferFormTitle
    className={cn(className, 'GenericTransferFormTitle')}
  >
    {children}
    {!!hint && (
      <Tooltip
        title={hint}
        className={cn(className, 'GenericTransferFormHint')}
      >
        <InfoIcon />
      </Tooltip>
    )}
  </StyledGenericTransferFormTitle>
);
