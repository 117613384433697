import { capitalize } from 'lodash';
import { useState } from 'react';

import { InfoIcon } from 'assets/icons';
import { Tooltip } from 'components/Tooltip';

import {
  StyledActions,
  StyledContainer,
  StyledExpandButton,
  StyledExpandIcon,
  StyledExpandTitle,
  StyledCollapseHeader,
} from './Collapse.styled';
import { CollapseProps } from './Collapse.types';

export const Collapse = ({
  title,
  defaultExpanded = false,
  withoutBorder = false,
  actions,
  infoTooltip,
  size = 'small',
  children,
}: CollapseProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const onToggle = () => {
    setExpanded((current) => !current);
  };

  return (
    <StyledContainer className="Container">
      <StyledCollapseHeader
        withoutBorder={withoutBorder}
        className="CollapseHeader"
      >
        <StyledExpandButton
          onClick={onToggle}
          aria-controls={title}
          aria-expanded={expanded}
          className="CollapseExpand"
        >
          <StyledExpandTitle size={size} className="CollapseExpandTitle">
            <StyledExpandIcon
              expanded={expanded}
              className="CollapseExpandIcon"
            />
            {capitalize(title)}
            {infoTooltip && (
              <Tooltip title={infoTooltip} onClick={(e) => e.stopPropagation()}>
                <InfoIcon className="sub-header__tooltip" />
              </Tooltip>
            )}
          </StyledExpandTitle>
        </StyledExpandButton>

        <StyledActions className="CollapseActions">{actions}</StyledActions>
      </StyledCollapseHeader>

      <div id={title} role="region" tabIndex={-1} hidden={!expanded}>
        {children}
      </div>
    </StyledContainer>
  );
};
