import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { StyledMetricsEmail } from 'pages/Portfolio/tabs/Analysis/components/AnalysisMetrics/AnalysisMetrics.styled';

export const AnalysisMetricsSkeleton: FC = () => (
  <>
    <StyledMetricsEmail className="MetricsEmail">
      <Skeleton width={79} height={32} animation="wave" variant="rounded" />
      <Skeleton width={144} height={16} animation="wave" variant="rounded" />
    </StyledMetricsEmail>
    <Skeleton width={144} height={16} animation="wave" variant="rounded" />
  </>
);
