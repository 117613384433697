import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { StyledSigninMfa } from '../../SigninMfa.styled';

export const SigninMfaSkeleton: FC = () => {
  return (
    <StyledSigninMfa className="SigninMfa">
      {/* description */}
      <Skeleton width="70%" />

      {/* input */}
      <Skeleton width="100%" height={48} />

      {/* button */}
      <Skeleton width="100%" height={48} />

      {/* support notice */}
      <Skeleton width="100%" height={32} />

      {/* button */}
      <Skeleton width="100%" height={48} />
    </StyledSigninMfa>
  );
};
