import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { SearchInput } from 'react-ui-kit-exante';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_DEBOUNCE_DELAY } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';

import { AccountsTableContext } from '../../contexts';

import { ACCOUNTS_LENGTH_FOR_SEARCH } from './AccountsSearch.constants';
import {
  StyledAccountSearchContainer,
  StyledSearchInputContainer,
} from './AccountsSearch.styled';

type TAccountsSearchProps = {
  clientId?: string;
};
export const AccountsSearch: FC<TAccountsSearchProps> = ({ clientId }) => {
  const { t } = useContext(TranslationContext);
  const [search, setSearch] = useState('');

  const { setAccountsSearch, initialAccountsLength } =
    useContext(AccountsTableContext);

  const debounceHandleChangeFtpInput = useDebouncedCallback(
    async (value) => setAccountsSearch(value),
    INPUT_DEBOUNCE_DELAY,
  );

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value;
    setSearch(value);
    debounceHandleChangeFtpInput(value);
  };

  useEffect(() => {
    setSearch('');
  }, [clientId]);

  if (initialAccountsLength <= ACCOUNTS_LENGTH_FOR_SEARCH) {
    return null;
  }

  return (
    <StyledAccountSearchContainer className="AccountSearchContainer">
      <StyledSearchInputContainer className="SearchInputContainer">
        <SearchInput
          inputProps={{
            onChange: handleChangeInput,
            value: search,
            fullWidth: true,
          }}
          placeholder={t('generic__search')}
          size="small"
        />
      </StyledSearchInputContainer>
    </StyledAccountSearchContainer>
  );
};
