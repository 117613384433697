import { Loader } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledLoader = styled(Loader)`
  position: relative;

  .spinner-icon {
    color: ${({ theme }) => theme?.color?.icon?.warning};
    position: absolute;
  }
`;

export const StyledTaxReportDownloadAnchor = styled('a')`
  color: ${({ theme }) => theme.color.typo.primary};
`;
