import cn from 'classnames';
import { FC, useContext, useMemo } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import { ErrorIcon, SpinnerIcon } from 'assets/icons';
import { FormDatePickerContainer } from 'components/Form/FormDatePickerContainer/FormDatePickerContainer';
import { FormInputContainer } from 'components/Form/FormInputContainer/FormInputContainer';
import { FormPhoneInputContainer } from 'components/Form/FormPhoneInputContainer/FormPhoneInputContainer';
import { FormSelectContainer } from 'components/Form/FormSelectContainer/FormSelectContainer';
import { InlineNotification } from 'components/InlineNotification';
import { TranslationContext, UserDetailsContext } from 'contexts';
import { minDate, today } from 'helpers/dates';
import { getPendingValues } from 'pages/Settings/pages/PersonalInfo/PersonalInfo.helpers';

import {
  StyledAnimateSpinnerIcon,
  StyledFormGroup,
  StyledNotificationContent,
  StyledSpinnerIcon,
} from './UserInfoForm.styled';
import { TUserInfoFormProps } from './UserInfoForm.types';

export const UserInfoForm: FC<TUserInfoFormProps> = ({
  isLoading = false,
  isEmailChecking,
  className,
}) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const { userDetails } = useContext(UserDetailsContext);

  const {
    companyEditable,
    diff,
    isCorporate,
    is_can_edit: canEdit,
    previous_change_request: previousChangeRequest,
  } = userDetails?.info || {};

  const pendingValues = useMemo(
    () => getPendingValues(diff?.fields),
    [diff?.fields],
  );

  const checkIsPendingValue = (key: string) => pendingValues.includes(key);

  const calendarLocale = currentLanguage === 'en' ? 'en-us' : currentLanguage;

  const residenceOptions: ISelectOption[] =
    userDetails?.refs?.countries?.map((country) => {
      return {
        label: country,
        value: country,
      };
    }) || [];

  const companyClassificationOptions: ISelectOption[] =
    userDetails?.refs?.companyClassificationChoices?.map(([value, label]) => {
      return {
        label,
        value,
      };
    }) || [];

  const inputIsDisabled: boolean = !canEdit || isLoading;
  const isShowListChanges = Boolean(diff?.list?.length);

  const listChanges = useMemo<string>(
    () =>
      diff?.list
        ?.map((name) => t(name))
        ?.join(', ')
        .toLowerCase() || '',
    [diff?.list],
  );

  const previousChangeRequestVisible = Boolean(
    !isLoading &&
      previousChangeRequest?.comment &&
      previousChangeRequest?.status === 'rejected',
  );

  const previousChangeRequestComment: string =
    previousChangeRequest?.comment || '';

  return (
    <StyledFormGroup className={cn('FormGroup', className)}>
      <FormInputContainer
        type="text"
        label={t('layout__settings__user_details__full_name')}
        fullWidth
        name="full_name"
        readOnly={inputIsDisabled}
        showSkeleton={isLoading}
        {...(checkIsPendingValue('full_name') && {
          iconLeft: <StyledSpinnerIcon />,
        })}
      />
      <FormInputContainer
        type="text"
        label={t('generic__email')}
        fullWidth
        name="email"
        readOnly={inputIsDisabled}
        showSkeleton={isLoading}
        {...(checkIsPendingValue('email') && {
          iconLeft: <StyledSpinnerIcon />,
        })}
        iconRight={isEmailChecking ? <StyledAnimateSpinnerIcon /> : null}
      />
      <FormPhoneInputContainer
        label={t('layout__settings__user_details__phone')}
        fullWidth
        name="full_phone"
        readOnly={inputIsDisabled}
        showSkeleton={isLoading}
        {...(checkIsPendingValue('full_phone') && {
          iconLeft: <StyledSpinnerIcon />,
        })}
      />
      {!isCorporate && (
        <FormDatePickerContainer
          label={t('date_of_birth')}
          fullWidth
          name="date_of_birth"
          locale={calendarLocale}
          maxDate={today}
          minDate={minDate}
          // todo remove disabled prop after https://jira.exante.eu/browse/RUI-596
          disabled={inputIsDisabled}
          readOnly={inputIsDisabled}
          showSkeleton={isLoading}
          {...(checkIsPendingValue('date_of_birth') && {
            iconLeft: <StyledSpinnerIcon />,
          })}
        />
      )}
      <FormSelectContainer
        label={t('tax_residence')}
        options={residenceOptions}
        fullWidth
        withNone
        name="tax_residence"
        readOnly={inputIsDisabled}
        showSkeleton={isLoading}
        {...(checkIsPendingValue('tax_residence') && {
          iconLeft: <StyledSpinnerIcon />,
        })}
      />
      <FormInputContainer
        label={t('tax_payer_number')}
        fullWidth
        type="text"
        name="tax_payer_number"
        readOnly={inputIsDisabled}
        showSkeleton={isLoading}
        {...(checkIsPendingValue('tax_payer_number') && {
          iconLeft: <StyledSpinnerIcon />,
        })}
      />
      {isCorporate && (
        <FormInputContainer
          label={t('company_name')}
          fullWidth
          type="text"
          name="company_name"
          readOnly={inputIsDisabled || !companyEditable}
          showSkeleton={isLoading}
          {...(checkIsPendingValue('company_name') && {
            iconLeft: <StyledSpinnerIcon />,
          })}
        />
      )}
      {isCorporate ? (
        <>
          <FormSelectContainer
            label={t('company_classification')}
            options={companyClassificationOptions}
            fullWidth
            name="company_classification"
            readOnly={inputIsDisabled}
            showSkeleton={isLoading}
            {...(checkIsPendingValue('company_classification') && {
              iconLeft: <StyledSpinnerIcon />,
            })}
          />
          <FormInputContainer
            label={t('legal_entity_identifier')}
            fullWidth
            type="text"
            name="legal_entity_identifier"
            readOnly={inputIsDisabled}
            showSkeleton={isLoading}
            {...(checkIsPendingValue('legal_entity_identifier') && {
              iconLeft: <StyledSpinnerIcon />,
            })}
          />
        </>
      ) : (
        <FormInputContainer
          label={t('place_of_birth')}
          fullWidth
          type="text"
          name="place_of_birth"
          readOnly={inputIsDisabled}
          showSkeleton={isLoading}
          {...(checkIsPendingValue('place_of_birth') && {
            iconLeft: <StyledSpinnerIcon />,
          })}
        />
      )}
      {isShowListChanges && !isLoading && (
        <InlineNotification type="warning">
          <StyledNotificationContent className="NotificationContent">
            <SpinnerIcon />
            {t('layout__settings__user_details__change_of_data')}
            {listChanges}
            {t('layout__settings__user_details__request_is_pending')}
          </StyledNotificationContent>
        </InlineNotification>
      )}
      {previousChangeRequestVisible && (
        <InlineNotification type="error">
          <StyledNotificationContent className="NotificationContent">
            <ErrorIcon />
            {previousChangeRequestComment}
          </StyledNotificationContent>
        </InlineNotification>
      )}
    </StyledFormGroup>
  );
};
