export type TSignupForm = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  country_code: string;
  company?: string;
  marketing_agree: boolean;
  comment?: string;
};

export enum AccountType {
  Individual = 'individual',
  Corporate = 'corporate',
}

export type TAccountTypeOption = {
  value: AccountType;
  label: string;
};
