import { FC, useContext } from 'react';
import { Skeleton, InfoIcon } from 'react-ui-kit-exante';

import { FormFileUploadContainer } from 'components/Form/FormFileUploadContainer/FormFileUploadContainer';
import { FormInputContainer } from 'components/Form/FormInputContainer/FormInputContainer';
import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext';
import { getFieldTooltipHint } from 'pages/Portfolio/tabs/Transfers/Transfers.helpers';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { TransfersContext } from '../../../../../context';
import { StyledHint } from '../../../../shared.styled';
import { acceptsFiles } from '../../../Securities.consts';
import { SecuritiesContext } from '../../../contexts/SecuritiesContext/SecuritiesContext';

import { getSecuritiesServerErrorsString } from './SecuritiesFormGeneric.helpers';
import {
  StyledFileUploadSection,
  StyledSubTitle,
} from './SecuritiesFormGeneric.styled';
import { TSecuritiesFormGenericProps } from './SecuritiesFormGeneric.types';

export const SecuritiesFormGeneric: FC<TSecuritiesFormGenericProps> = ({
  loading,
  fields,
  formIsDisabled,
}) => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { transfersState } = useContext(TransfersContext);
  const { isTransferProcessing } = useContext(SecuritiesContext);

  const { selectedAccountId } = useAppSelector(selectAccounts);

  // TODO: it was use empty object from store
  const serverErrors: Record<string, string[]> = {};

  const [id] = (selectedAccountId || '').split('.');
  const legalEntity = transfersState.client_id_le_map?.[id];

  const showSkeletons =
    loading ||
    transfersState?.fetching?.idle ||
    transfersState?.fetching?.pending ||
    isTranslationsLoading;

  const getFieldHint = (fieldName: string, onlyText?: boolean) => {
    return getFieldTooltipHint(
      fieldName,
      WITHDRAWAL_TYPE.SECURITY,
      transfersState?.branding?.wr_fields_settings,
      t,
      onlyText,
    );
  };

  return (
    <>
      {fields.includes('account_holder_name') && (
        <FormInputContainer
          label={t('layout__fields__account_holder_name')}
          name="account_holder_name"
          fullWidth
          type="text"
          message={getSecuritiesServerErrorsString(
            serverErrors.account_holder_name,
          )}
          error={Object.hasOwn(serverErrors, 'account_holder_name')}
          showSkeleton={showSkeletons}
          disabled={formIsDisabled}
          iconRight={getFieldHint('account_holder_name')}
        />
      )}
      {fields.includes('fi_account_number') && (
        <FormInputContainer
          label={t('layout__fields__fi_account_number')}
          name="fi_account_number"
          fullWidth
          type="text"
          message={getSecuritiesServerErrorsString(
            serverErrors.fi_account_number,
          )}
          error={Object.hasOwn(serverErrors, 'fi_account_number')}
          showSkeleton={showSkeletons}
          disabled={formIsDisabled}
          iconRight={getFieldHint('fi_account_number')}
        />
      )}
      {fields.includes('fi_name') && (
        <FormInputContainer
          label={t('layout__fields__fi_name')}
          name="fi_name"
          fullWidth
          type="text"
          message={getSecuritiesServerErrorsString(serverErrors.fi_name)}
          error={Object.hasOwn(serverErrors, 'fi_name')}
          showSkeleton={showSkeletons}
          disabled={formIsDisabled}
          iconRight={getFieldHint('fi_name')}
        />
      )}
      {fields.includes('contact_person') && (
        <FormInputContainer
          label={t('layout__fields__contact_person_and_details')}
          name="contact_person"
          fullWidth
          type="text"
          message={getSecuritiesServerErrorsString(serverErrors.contact_person)}
          error={Object.hasOwn(serverErrors, 'contact_person')}
          showSkeleton={showSkeletons}
          disabled={formIsDisabled}
          iconRight={getFieldHint('contact_person')}
        />
      )}
      {fields.includes('transfer_reference') && (
        <FormInputContainer
          label={t('layout__fields__transfer_reference')}
          name="transfer_reference"
          fullWidth
          type="text"
          message={getSecuritiesServerErrorsString(
            serverErrors.transfer_reference,
          )}
          error={Object.hasOwn(serverErrors, 'transfer_reference')}
          iconRight={getFieldHint('transfer_reference')}
          showSkeleton={showSkeletons}
          disabled={formIsDisabled}
        />
      )}

      {fields.includes('ssi') && (
        <FormInputContainer
          label={t('generic__ssi')}
          name="ssi"
          fullWidth
          type="text"
          message={getSecuritiesServerErrorsString(serverErrors.ssi)}
          error={Object.hasOwn(serverErrors, 'ssi')}
          iconRight={getFieldHint('ssi')}
          showSkeleton={showSkeletons}
          disabled={formIsDisabled}
        />
      )}

      {fields.includes('transfer_details') && (
        <FormInputContainer
          label={t('layout__fields__transfer_details')}
          name="transfer_details"
          fullWidth
          type="text"
          message={getSecuritiesServerErrorsString(
            serverErrors.transfer_details,
          )}
          error={Object.hasOwn(serverErrors, 'transfer_details')}
          showSkeleton={showSkeletons}
          disabled={formIsDisabled}
          iconRight={getFieldHint('transfer_details')}
        />
      )}

      {!isTransferProcessing && fields.includes('files') && (
        <StyledFileUploadSection className="StyledFileUploadSection">
          {loading ? (
            <Skeleton width={200} height={32} />
          ) : (
            <StyledSubTitle className="SubTitle">
              {t('layout__fields__accompanying_documents')}
            </StyledSubTitle>
          )}
          {!isTransferProcessing && (
            <>
              <FormFileUploadContainer
                translator={t}
                name="files"
                buttonLabel={t('generic__action__upload')}
                dndLabel={t('layout__fields__drag_n_drop')}
                accept={acceptsFiles}
                removable
                loading={showSkeletons}
                disabled={formIsDisabled}
              />
              {getFieldHint('files', true) && (
                <StyledHint className="Hint">
                  <InfoIcon className="InfoIcon" />
                  <Text size="13px" color="secondary">
                    {getFieldHint('files', true)}
                  </Text>
                </StyledHint>
              )}
            </>
          )}
          {isTransferProcessing && <Skeleton height={24} width="100%" />}
        </StyledFileUploadSection>
      )}

      {fields.includes('comment') && (
        <FormInputContainer
          multiline
          inputProps={{
            maxLength:
              transfersState?.wr_types?.Security?.max_description_length?.[
                legalEntity
              ],
          }}
          label={t('generic__comments')}
          name="comment"
          fullWidth
          type="text"
          message={getSecuritiesServerErrorsString(serverErrors.comment)}
          error={Object.hasOwn(serverErrors, 'comment')}
          showSkeleton={showSkeletons}
          disabled={formIsDisabled}
          iconRight={getFieldHint('comment')}
        />
      )}
    </>
  );
};
