import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { prepareTableParamsDates } from './corporateActions.helpers';
import {
  TCorporateActionsGetListProps,
  TCorporateActionsGetListResponse,
  TCorporateActionsGetPaidListProps,
  TCorporateActionsGetPaidListResponse,
  TCorporateActionsServerTableColumn,
} from './corporateActions.types';

class CorporateActionsService extends ApiRequest {
  async getList({
    offset,
    order,
    orderBy,
    with_transactions = true,
    limit,
    tableParams,
  }: TCorporateActionsGetListProps): Promise<TCorporateActionsGetListResponse | null> {
    try {
      const { data } = await this.fetch<TCorporateActionsGetListResponse>({
        url: '/clientsarea/rest/cas/all_events/',
        params: {
          offset,
          order,
          order_by: orderBy,
          with_transactions,
          limit,
          ...prepareTableParamsDates(tableParams, ['deadline']),
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericError();
      console.error('getList error: ', error);
      return null;
    }
  }

  async getListColumns(): Promise<TCorporateActionsServerTableColumn[] | null> {
    try {
      const { data } = await this.fetch<{
        content: TCorporateActionsServerTableColumn[];
      }>({
        url: `https://corporate-actions-api-stage.exante.eu/api/v1.0/events/fields`,
      });

      return data?.content || null;
    } catch (error) {
      notifyWith.genericError();
      console.error('getListColumns error: ', error);
      return null;
    }
  }

  async getPaidList({
    offset,
    order,
    orderBy,
    limit,
    selectedAccountId,
    tableParams,
  }: TCorporateActionsGetPaidListProps): Promise<TCorporateActionsGetPaidListResponse | null> {
    try {
      const { data } = await this.fetch<TCorporateActionsGetPaidListResponse>({
        url: '/clientsarea/rest/cas/paid_events/',
        params: {
          offset,
          order,
          order_by: orderBy,
          limit,
          account_id: selectedAccountId,
          ...prepareTableParamsDates(tableParams, ['deadline']),
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericError();
      console.error('getPaidList error: ', error);
      return null;
    }
  }

  async getPaidListColumns(): Promise<
    TCorporateActionsServerTableColumn[] | null
  > {
    try {
      const { data } = await this.fetch<{
        content: TCorporateActionsServerTableColumn[];
      }>({
        url: `https://corporate-actions-api-stage.exante.eu/api/v1.0/events/paid/fields`,
      });

      return data?.content || null;
    } catch (error) {
      notifyWith.genericError();
      console.error('getPaidListColumns error: ', error);
      return null;
    }
  }

  async getAllowedAccounts(): Promise<any> {
    try {
      const { data } = await this.fetch<any>({
        url: `/clientsarea/rest/cas/paid_events/allowed_accounts/`,
      });

      return data || null;
    } catch (error) {
      notifyWith.genericError();
      console.error('getAllowedAccounts error: ', error);
      return null;
    }
  }
}

export const corporateActionsService = new CorporateActionsService();
