import { FC, useContext } from 'react';
import { Button, Input } from 'react-ui-kit-exante';

import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { MFA_TYPES_LENGTH } from 'pages/Security/pages/Mfa/Mfa.constants';
import { getTimerValue } from 'pages/Security/pages/Mfa/components/helpers';
import {
  StyledSharedMfaFooterText,
  StyledSharedMfaInputWithFooterContainer,
  StyledSharedMfaResendButton,
} from 'pages/Security/pages/Mfa/components/styled';

import { SigninContext } from '../../../../contexts';

import { MFA_DESCRIPTIONS, MFA_INPUT_TITLES } from './SigninMfa.constants';
import {
  StyledSigninMfa,
  StyledSigninMfaDescription,
  StyledSigninMfaSupportLink,
  StyledSigninMfaSupportNotice,
} from './SigninMfa.styled';
import { SigninMfaSkeleton } from './components/SigninMfaSkeleton';
import { useSigninByCode } from './hooks/useSigninByCode';

export const SigninMfa: FC = () => {
  const { t, isTranslationsLoading } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const { mfa, setMfa, handleDone } = useContext(SigninContext);

  const supportEmail = branding?.wl_emails_support;

  const loginButtonCaption = t(
    branding?.login_wording || 'generic__action__login',
  );

  const mfaDescription = mfa.type ? MFA_DESCRIPTIONS[mfa.type] : '';
  const mfaInputTitle = mfa.type ? MFA_INPUT_TITLES[mfa.type] : '';

  const mfaInputLength = mfa.type ? MFA_TYPES_LENGTH[mfa.type] : 0;

  const {
    code,
    isLoadingVerify,
    seconds,
    errorVerify,
    errorResend,
    handleCodeChange,
    handleResend,
    handleVerify,
  } = useSigninByCode(mfaInputLength, mfa.key, mfa.tokenid, handleDone);

  const handleResetClick = () => {
    setMfa({ type: null, key: '', tokenid: 0 });
  };

  if (isTranslationsLoading) {
    return <SigninMfaSkeleton />;
  }

  const hasError = Boolean(errorResend) || Boolean(errorVerify);
  const errorMessage = errorResend || errorVerify;

  return (
    <StyledSigninMfa className="SigninMfa">
      <StyledSigninMfaDescription className="SigninMfaDescription">
        {t(mfaDescription)}
      </StyledSigninMfaDescription>

      <StyledSharedMfaInputWithFooterContainer className="SharedMfaInputWithFooterContainer">
        <Input
          fullWidth
          type="text"
          value={code}
          error={hasError}
          message={t(errorMessage)}
          disabled={isLoadingVerify}
          placeholder={t(mfaInputTitle)}
          onChange={(e) => handleCodeChange(e.target.value)}
          iconRight={
            mfa.type !== 'totp' ? (
              <StyledSharedMfaResendButton
                className="ResendButton"
                type="button"
                onClick={handleResend}
                disabled={seconds !== 0 || isLoadingVerify}
              >
                {t('layout__security_tabs__mfa_label_resend')}
              </StyledSharedMfaResendButton>
            ) : null
          }
        />
        <StyledSharedMfaFooterText
          visible={mfa.type !== 'totp' && seconds !== 0}
          className="FooterText"
        >
          {getTimerValue(
            t('layout__settings__mfa__resend_after_seconds'),
            seconds,
          )}
        </StyledSharedMfaFooterText>
      </StyledSharedMfaInputWithFooterContainer>

      <Button
        color="primary"
        onClick={handleVerify}
        type="submit"
        fullWidth
        loading={isLoadingVerify}
        disabled={!code}
      >
        {loginButtonCaption}
      </Button>

      <StyledSigninMfaSupportNotice className="SigninMfaSupportNotice">
        {t('layout__login__2fa_problem')}{' '}
        <StyledSigninMfaSupportLink
          className="SigninMfaSupportLink"
          href={`mailto:${supportEmail}`}
        >
          {t('layout__login__tech_support')}.
        </StyledSigninMfaSupportLink>
      </StyledSigninMfaSupportNotice>

      <Button
        color="secondary"
        onClick={handleResetClick}
        type="reset"
        fullWidth
      >
        {t('generic__action__back')}
      </Button>
    </StyledSigninMfa>
  );
};
