export const MENU_ALIAS = {
  ACCESS_RIGHTS: 'access_rights',
  AGREEMENTS: 'agreements',
  ANALYSIS: 'performancereport',
  COMMISSIONS: 'commissions',
  DEPOSIT: 'deposit',
  DOCUMENTS: 'documents',
  EXCHANGE: 'exchange',
  EXTERNAL_ACCOUNTS: 'external_accounts',
  INTERESTS: 'interests',
  TRANSFER: 'withdrawal:index',
  MARGIN: 'margin',
  MFA: 'mfa',
  MY_CARDS: 'my_cards',
  OVERNIGHTS: 'overnights',
  PARTNER: 'partner',
  PASSWORD: 'password',
  CARD_SETTINGS: 'cards-gps:index',
  PIT_COMMUNITY: 'pit_community',
  PROFILE: 'profile',
  SERVICES: 'services',
  SHORT_ALLOWANCE: 'short_allowance',
  SQUAWKBOX: 'squawkbox',
  SUMMARY: 'account_summary',
  TRADES: 'trades',
  TRANSACTIONS: 'account_transactions',
  PERIODIC_REPORTS: 'periodicReports',
  TAX_REPORTS: 'taxReports',
  CUSTOM_REPORTS: 'customReports',
  GENERATED_REPORTS: 'generatedReports',
} as const;
