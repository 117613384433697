import { FC, MouseEvent, useContext, useState } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { useCopyToClipboard } from 'hooks/index';

import { StyledCopyButtonWrapper } from './CopyButton.styled';
import { TCopyButtonProps } from './CopyButton.types';

export const CopyButton: FC<TCopyButtonProps> = ({
  text,
  tooltipProps,
  ...buttonProps
}) => {
  const { t } = useContext(TranslationContext);

  const [open, setOpen] = useState(false);
  const [, copy] = useCopyToClipboard();

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await copy(typeof text === 'string' ? text : text());
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <StyledCopyButtonWrapper className="CopyButtonWrapper">
      <Tooltip
        // this one is needed to prevent tooltip from closing
        // when focus is changed programmatically by calling `copy()`
        disableFocusListener
        onClose={handleTooltipClose}
        title={t('layout__user_management__copied')}
        open={open}
        {...tooltipProps}
      >
        <IconButton
          onClick={handleClick}
          aria-label={t('layout__user_management__click_to_copy')}
          iconName="CopyIcon"
          iconSize={24}
          iconColor="secondary"
          {...buttonProps}
        />
      </Tooltip>
    </StyledCopyButtonWrapper>
  );
};
