import { FC, useContext } from 'react';
import { Helmet } from 'react-helmet';

import { BrandingContext } from 'contexts/BrandingContext';

export const BrandingIncludeCSS: FC = () => {
  const { theme } = useContext(BrandingContext);
  const includeCss = theme?.includeCss;

  return !includeCss ? null : (
    <Helmet>
      <style type="text/css" data-branding="include-css">
        {includeCss}
      </style>
    </Helmet>
  );
};
