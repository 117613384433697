import { Notification } from 'react-ui-kit-exante';

import { i18nLocal } from 'helpers/translate';

export const success = (messageKey: string) => {
  const translation = i18nLocal.translate(messageKey, '');

  if (!translation) {
    return;
  }

  Notification.success({ title: translation });
};
