import { isArray } from 'lodash';

import { SORTING_ORDER } from 'constants/SORTING_ORDER';
import { TKitTableSorting } from 'types/TKitTableSorting';

import { Dates } from './dates';
import { isKitTableSorting } from './typeguards';

const prepareDateFiltersForParams = (dateRange: string[]) => {
  const [dateFrom, dateTo] = dateRange;

  const format = (str: string) => {
    const iso = Dates.convertDateToISO(str);

    if (iso) {
      return Dates.format(new Date(iso));
    }

    return '';
  };

  return {
    date_from: `${format(dateFrom)} 00:00:00`,
    date_to: `${format(dateTo)} 23:59:59`,
  };
};

const prepareSortingForParams = (
  sorting: TKitTableSorting[],
  columnKeys: string[],
) => {
  const [{ id, desc }] = sorting;

  // todo hack before sorting refactoring on backend https://jira.exante.eu/browse/CL-2733
  if (id === 'timestamp') {
    return {
      iSortCol_0: 5,
      sSortDir_0: desc ? SORTING_ORDER.DESC : SORTING_ORDER.ASC,
    };
  }

  return {
    iSortCol_0: columnKeys.findIndex((key) => key === id),
    sSortDir_0: desc ? SORTING_ORDER.DESC : SORTING_ORDER.ASC,
  };
};

export type TPrepareToQueryParams = Record<string, unknown> & {
  accountId: string;
  dateRange?: unknown;
  sorting?: unknown;
  limit?: number;
  skip?: number;
  columnKeys?: string[];
  operationTypes?: string[];
};

export type TPrepareToQueryParamsResult = {
  accountId: string;
  iDisplayStart: number;
  iDisplayLength: number;
  operationTypes: string[];
  dateRange: string[];
  iSortCol_0?: number;
  sSortDir_0?: string;
};

export const prepareToQueryParams = ({
  accountId,
  dateRange,
  sorting,
  limit = 0,
  skip = 0,
  columnKeys = [],
  operationTypes = [],
}: TPrepareToQueryParams): TPrepareToQueryParamsResult => {
  const result: TPrepareToQueryParamsResult = {
    accountId,
    iDisplayStart: skip,
    iDisplayLength: limit,
    operationTypes,
    dateRange: [],
  };

  const hasDateRangeFilter =
    isArray(dateRange) &&
    dateRange.length &&
    dateRange.every((d) => {
      const iso = Dates.convertDateToISO(d);

      if (iso) {
        const date = new Date(iso);
        return Dates.isValid(date);
      }

      return false;
    });

  if (hasDateRangeFilter) {
    const { date_from: dateFrom, date_to: dateTo } =
      prepareDateFiltersForParams(dateRange);

    result.dateRange = [dateFrom, dateTo];
  }

  const hasSorting = isKitTableSorting(sorting) && Boolean(sorting.length);

  if (hasSorting) {
    const { iSortCol_0: sortinColumn, sSortDir_0: sortingDirection } =
      prepareSortingForParams(sorting, columnKeys);

    result.iSortCol_0 = sortinColumn;
    result.sSortDir_0 = sortingDirection;
  }

  return result;
};
