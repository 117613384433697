import { FC, useContext } from 'react';
import { InfoIcon } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import {
  isReportErrored,
  isReportPriceAccepted,
  isReportPriceReady,
  isReportProcessing,
  isReportSent,
} from 'pages/Reports/tabs/TaxReports/TaxReports.helpers';
import { TaxResidence } from 'services/reports/reports.types';

import {
  StyledConditions,
  StyledConditionsText,
} from './TaxReportConditions.styled';
import { TTaxReportConditionsProps } from './TaxReportConditions.types';

export const TaxReportConditions: FC<TTaxReportConditionsProps> = ({
  report,
  taxResidence,
  supportEmail,
}) => {
  const { t } = useContext(TranslationContext);

  if (isReportSent(report)) {
    return null;
  }

  if (isReportPriceReady(report)) {
    return (
      <StyledConditions className="Conditions">
        {taxResidence === TaxResidence.Poland
          ? t('layout__reports__tax__bear_with_us')
          : t('layout__reports__tax__service_fee_charge', {
              FEE: report.tndfl_price,
            })}
      </StyledConditions>
    );
  }

  if (isReportPriceAccepted(report)) {
    return (
      <StyledConditions className="Conditions" color="warning">
        {t('layout__reports__tax__bear_with_us')}
      </StyledConditions>
    );
  }

  if (isReportProcessing(report)) {
    return (
      <StyledConditions className="Conditions" color="warning">
        <InfoIcon />
        {taxResidence === TaxResidence.Poland
          ? t('layout__reports__tax__processing_poland')
          : t('layout__reports__tax__processing')}
      </StyledConditions>
    );
  }

  if (isReportErrored(report)) {
    return (
      <StyledConditions className="Conditions">
        <StyledConditionsText
          className="ConditionsText"
          dangerouslySetInnerHTML={{
            __html: t('layout__reports__tax__error').replace(
              /wl/gi,
              supportEmail,
            ),
          }}
        />
      </StyledConditions>
    );
  }

  return null;
};
