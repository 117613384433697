import {
  createContext,
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { useLogHandleTime } from 'hooks/useLogHandleTime';
import {
  currenciesService,
  TCurrenciesCryptoResponse,
} from 'services/currencies';
import { depositService } from 'services/deposit';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TChildren } from 'types/TChildren';

import {
  CURRENCIES_CRYPTO_EMPTY,
  initialState,
} from './DepositCryptoContext.consts';
import {
  TAddressRenewState,
  TDepositCryptoState,
} from './DepositCryptoContext.types';

export const DepositCryptoContext =
  createContext<TDepositCryptoState>(initialState);

export const DepositCryptoProvider: FC<TChildren> = ({
  children,
}): ReactElement => {
  const [currencyCode, setCurrencyCode] = useState<string>(
    initialState.currency,
  );

  const { selectedAccountId } = useAppSelector(selectAccounts);

  const [addressState, setAddressState] = useState<TAddressRenewState>({
    isLoading: false,
    address: null,
  });
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('currencies-crypto');
  const abortControllerCurrencies = useRef(new AbortController());
  const abortControllerAddress = useRef(new AbortController());

  const {
    isLoading,
    data: dataCurrencies,
    fetchData: fetchCurrencies,
  } = useData<TCurrenciesCryptoResponse | null>({
    onFetch: async () => {
      abortControllerCurrencies.current.abort();
      abortControllerCurrencies.current = new AbortController();

      setStartHandleTime();
      const response = await currenciesService.getCurrenciesCrypto({
        options: {
          signal: abortControllerCurrencies.current.signal,
        },
      });
      logHandleTime();

      return response;
    },
    onSuccess: (response) => {
      const currencies = response?.currencies.list || [];
      const safeCurrency = currencies.includes(currencyCode)
        ? currencyCode
        : currencies[0];

      setCurrencyCode(safeCurrency);
    },
  });

  const fetchCryptoAddress = async (renew = false) => {
    if (!selectedAccountId) {
      return;
    }

    abortControllerAddress.current.abort();
    abortControllerAddress.current = new AbortController();

    setAddressState((state) => ({ ...state, isLoading: true }));

    const dataAddress = await depositService.getCryptoAddress({
      renew,
      currency: currencyCode.toLowerCase(),
      accountId: selectedAccountId,
      options: {
        signal: abortControllerAddress.current.signal,
      },
    });

    setAddressState({
      address: dataAddress?.address || null,
      isLoading: false,
    });
  };

  useEffect(() => {
    fetchCurrencies();

    return () => {
      abortControllerCurrencies.current.abort();
    };
  }, []);

  useEffect(() => {
    const currList = dataCurrencies?.currencies?.list || [];

    if (currList.length && currList.includes(currencyCode)) {
      fetchCryptoAddress();
    }

    return () => {
      abortControllerAddress.current.abort();
    };
  }, [currencyCode, selectedAccountId, dataCurrencies]);

  const currencies = dataCurrencies?.currencies || CURRENCIES_CRYPTO_EMPTY;

  const value = useMemo<TDepositCryptoState>(
    () => ({
      isLoading,
      currency: currencyCode,
      currencies,
      setCurrency: setCurrencyCode,
      isLoadingAddress: addressState.isLoading,
      address: addressState.address,
      // fetch with renew
      renewAddress: fetchCryptoAddress.bind(null, true),
    }),
    [
      isLoading,
      currencyCode,
      currencies,
      setCurrencyCode,
      addressState.isLoading,
      addressState.address,
      fetchCryptoAddress,
    ],
  );

  return (
    <DepositCryptoContext.Provider value={value}>
      {children}
    </DepositCryptoContext.Provider>
  );
};
