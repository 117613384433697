import { useCallback, useEffect, useState } from 'react';

import { TDepositChoices } from 'pages/Portfolio/tabs/Deposit/contexts/DepositContext/DepositContext.types';

import { DEPOSIT_ACCOUNT_CHOICES_KEY } from './DepositContext.consts';

export const useDepositAccountChoices = () => {
  const [choices, setChoices] = useState<TDepositChoices>({});

  const setAccountChoices = useCallback(
    (
      accountId: string | null,
      bankAlias: string | null,
      currencyCode: string | null,
    ) => {
      if (!accountId) {
        return;
      }

      setChoices((acctChoices) => ({
        ...acctChoices,
        [accountId]: {
          bank: bankAlias,
          currency: currencyCode,
        },
      }));
    },
    [],
  );

  // load initial local storage choices
  useEffect(() => {
    const choicesLoaded = window.localStorage.getItem(
      DEPOSIT_ACCOUNT_CHOICES_KEY,
    );

    let choicesParsed = {};

    try {
      choicesParsed = choicesLoaded && JSON.parse(choicesLoaded);
    } catch (e) {
      console.error('Choices parse error', e);
    }

    setChoices(choicesParsed);
  }, []);

  // update local storage choices
  useEffect(() => {
    const choicesSerialized = JSON.stringify(choices);

    window.localStorage.setItem(DEPOSIT_ACCOUNT_CHOICES_KEY, choicesSerialized);
  }, [choices]);

  return { choices, setAccountChoices };
};
