import { StyledPageWrapper } from 'components/Main';
import { AccountDataProvider } from 'contexts/AccountDataContext';

import { PortfolioWithContext } from './PortfolioWithContext';
import { UpdatePortfolioProvider } from './contexts';

export const Portfolio = () => (
  <UpdatePortfolioProvider>
    <AccountDataProvider>
      <StyledPageWrapper className="PortfolioWrapper">
        <PortfolioWithContext />
      </StyledPageWrapper>
    </AccountDataProvider>
  </UpdatePortfolioProvider>
);
