import {
  TTabsCacheApiContextValue,
  TTabsCacheDataContextValue,
} from 'contexts/TabsCacheContext/TabsCacheContext.types';

export const TabsCacheDataContextInitialState: TTabsCacheDataContextValue = {
  getCache: () => null,
};

export const TabsCacheApiContextInitialState: TTabsCacheApiContextValue = {
  setCache: () => {},
};
