import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { TCreateCurrencyFormatter } from 'helpers/formatters';
import { TDateFormatter } from 'hooks/useDateFormatter';
import { TCorporateActionPaid } from 'services/corporateActions/corporateActions.types';

import { CORPORATE_ACTIONS_POSTINGS_DISPLAYED_COLUMNS } from './CorporateActionsPostings.constants';
import { TGetCorporateActionsPaidListTableColumnsProps } from './CorporateActionsPostings.types';
import { CorporateActionsPostingsStatus } from './components';

const prepareCorporateActionsCell = ({
  accessor,
  cell: { value },
  currencyFormatter,
  dateFormatter,
}: {
  accessor: string;
  cell: ICellValue<TCorporateActionPaid>;
  currencyFormatter: TCreateCurrencyFormatter;
  dateFormatter: TDateFormatter;
}) => {
  const typedAccessor = accessor as keyof TCorporateActionPaid;

  if ([undefined, null, ''].includes(value)) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  if (typedAccessor === 'status') {
    return <CorporateActionsPostingsStatus status={value} />;
  }

  if (typedAccessor.includes('date')) {
    return dateFormatter(value);
  }

  const numberKeys: Array<keyof TCorporateActionPaid> = [
    'sum',
    'tax_sum',
    'fee_sum',
    'gross_amount',
    'issuance_fee',
  ];

  if (numberKeys.includes(typedAccessor)) {
    return currencyFormatter.format(value);
  }

  return value;
};

export const getCorporateActionsListTableColumns = ({
  t,
  onRemove,
  onFilter,
  hideAccountId,
  dateFormatter,
  currencyFormatter,
}: TGetCorporateActionsPaidListTableColumnsProps): IColumn<TCorporateActionPaid>[] =>
  Object.entries(CORPORATE_ACTIONS_POSTINGS_DISPLAYED_COLUMNS)
    .filter(([accessor]) => {
      if (hideAccountId!) {
        return true;
      }

      return (
        (accessor as keyof typeof CORPORATE_ACTIONS_POSTINGS_DISPLAYED_COLUMNS) !==
        'account_id'
      );
    })
    .map(([accessor, colKey]) => {
      const typedAccessor = accessor as keyof TCorporateActionPaid;
      const localizedName = t(colKey);
      const allowedSortBy: Array<keyof TCorporateActionPaid> = [
        'symbol',
        'ex_date',
        'post_date',
        'pay_date',
        'status',
      ];

      return {
        Header: localizedName,
        accessor: typedAccessor,
        Cell: (cellValue: ICellValue<TCorporateActionPaid>) =>
          prepareCorporateActionsCell({
            accessor,
            cell: cellValue,
            currencyFormatter,
            dateFormatter,
          }),
        minWidth: (() => {
          if (typedAccessor === 'symbol') {
            return 180;
          }

          if (typedAccessor === 'status') {
            return 180;
          }

          const approximateLength = typedAccessor.length * 12;
          const localizedLength = localizedName.length;

          return localizedLength > approximateLength
            ? localizedLength
            : approximateLength;
        })(),
        type: typedAccessor.includes('date') ? 'dateRange' : 'textInput',
        required: typedAccessor === 'symbol',
        onFilter,
        onRemove,
        disableSortBy: !allowedSortBy.includes(typedAccessor),
        disableFilters: typedAccessor === 'name',
      };
    });
