import { useContext, useState } from 'react';
import { Button, Notification } from 'react-ui-kit-exante';

import { StyledNoDataNotification } from 'components/NoDataNotification';
import { CurrencyContext, TranslationContext } from 'contexts';
import { TAnalysisMetricsProps } from 'pages/Portfolio/tabs/Analysis/components/AnalysisMetrics/AnalysisMetrics.types';
import { AnalysisMetricsSkeleton } from 'pages/Portfolio/tabs/Analysis/components/AnalysisMetrics/components/AnalysisMetricsSkeleton';
import { CurrenciesTable } from 'pages/Portfolio/tabs/Analysis/components/CurrenciesTable';
import { SymbolsTable } from 'pages/Portfolio/tabs/Analysis/components/SymbolsTable';
import { AnalysisPageContext } from 'pages/Portfolio/tabs/Analysis/contexts/AnalysisPageContext';
import { FilterContext } from 'pages/Portfolio/tabs/Analysis/contexts/FilterContext';
import { analysisService } from 'services/analysis';

import { DailyPL } from '../DailyPL';
import { MetricsSection } from '../MetricsSection';
import { NetAssetValue } from '../NetAssetValue';

import { checkIsMetricsEmpty, getPeriodData } from './AnalysisMetrics.helpers';
import {
  StyledMetricsColumns,
  StyledMetricsEmail,
  StyledMetricsEmailLink,
  StyledMetricsFooter,
} from './AnalysisMetrics.styled';

export const AnalysisMetrics = ({
  metrics,
  isMetricsLoading,
}: TAnalysisMetricsProps) => {
  const { t } = useContext(TranslationContext);
  const { currency } = useContext(CurrencyContext);
  const { config } = useContext(AnalysisPageContext);
  const { filters } = useContext(FilterContext);

  const [isEmailReportLoading, setIsEmailReportLoading] = useState(false);

  const { total } = metrics || {};

  const {
    periodMetrics,
    periodStatistic,
    otherTransactionTypes,
    otherCommissionTypes,
  } = getPeriodData(t, total, config);

  const onSendByEmail = async () => {
    setIsEmailReportLoading(true);
    const response = await analysisService.getAnalysisReport(filters);
    if (response) {
      Notification.success({ title: response });
    }
    setIsEmailReportLoading(false);
  };

  if (!isMetricsLoading && checkIsMetricsEmpty(metrics)) {
    return (
      <StyledNoDataNotification className="NoDataNotification">
        {t('layout__metrics__not_available')}
      </StyledNoDataNotification>
    );
  }

  return (
    <div className="MetricsWrapper">
      <NetAssetValue data={metrics?.nav} isDataLoading={isMetricsLoading} />
      <DailyPL
        data={metrics?.dailyPnL || null}
        isDataLoading={isMetricsLoading || false}
      />

      <StyledMetricsColumns className="MetricsColumns">
        <MetricsSection
          sectionTitle={`${t('total_metrics_for_period')}, ${currency}`}
          info={t('layout__performance__move_cursor_for_tips')}
          values={periodMetrics}
          otherTypes={otherTransactionTypes}
          commissionTypes={otherCommissionTypes}
          isDataLoading={isMetricsLoading}
        />
        <MetricsSection
          sectionTitle={`${t(
            'layout__performance__stats_for_period',
          )}, ${currency}`}
          values={periodStatistic}
          otherTypes={otherTransactionTypes}
          commissionTypes={otherCommissionTypes}
          isDataLoading={isMetricsLoading}
        />
      </StyledMetricsColumns>

      <CurrenciesTable
        data={metrics?.currencies}
        isDataLoading={isMetricsLoading}
      />
      <SymbolsTable data={metrics?.symbols} isDataLoading={isMetricsLoading} />

      <StyledMetricsFooter className="MetricsFooter">
        {isMetricsLoading ? (
          <AnalysisMetricsSkeleton />
        ) : (
          <>
            <StyledMetricsEmail className="MetricsEmail">
              <Button
                onClick={onSendByEmail}
                color="secondary"
                size="small"
                loading={isEmailReportLoading}
              >
                {t('layout__performance__send_by_email')}
              </Button>
              <div className="MetricsEmailLinkWrapper">
                <StyledMetricsEmailLink
                  className="MetricsEmailLink"
                  href="/clientsarea/account/reports/"
                >
                  {t('set_up')}
                </StyledMetricsEmailLink>{' '}
                {t('layout__performance__recurring_dispatch')}
              </div>
            </StyledMetricsEmail>
            <div
              dangerouslySetInnerHTML={{
                __html: t('layout__performance__perfreport_note'),
              }}
              className="MetricsNote"
            />
          </>
        )}
      </StyledMetricsFooter>
    </div>
  );
};
