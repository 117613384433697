import { Table } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledTaxReportsTable = styled(Table)`
  & .Row {
    background: ${({ theme }) => theme?.color?.bg?.primary};

    &:hover {
      background: ${({ theme }) => theme?.color?.bg?.primary};
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      // inner react-table cell wrapper which is not available to customise

      & [role='cell'] {
        padding: 0;

        & > div {
          // expand the wrapper full width to make inner expansion possible
          width: 100%;
        }
      }
    }
  }

  & .RowSuccess {
    background: ${({ theme }) => theme?.color?.bg?.source};

    &:hover {
      background: ${({ theme }) => theme?.color?.bg?.source};
    }
  }

  & .RowWarning {
    background: ${({ theme }) => theme?.color?.bg?.warning};

    &:hover {
      background: ${({ theme }) => theme?.color?.bg?.warning};
    }
  }

  & .RowError {
    background: ${({ theme }) => theme?.color?.bg?.radical};

    &:hover {
      background: ${({ theme }) => theme?.color?.bg?.radical};
    }
  }
` as typeof Table;
