import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts';
import { DepositCryptoContext } from 'pages/Portfolio/tabs/Deposit/contexts';

import {
  StyledCircleClockIcon,
  StyledGlobalIcon,
  StyledInfoSectionBlock,
  StyledInfoSectionContainer,
  StyledInfoSectionText,
  StyledPercentIcon,
} from '../../../DepositCash/components/InfoCashSection/InfoCashSection.styled';
import { StyledDepositSection } from '../../../DepositWrapper/DepositWrapper.styled';

export const InfoCryptoSection: FC = () => {
  const { t } = useContext(TranslationContext);

  const { currencies } = useContext(DepositCryptoContext);

  const currenciesCopy = [...currencies.list];

  const currencyLast = currenciesCopy.pop() || '';
  const currenciesListed = currenciesCopy.join(', ') || '';

  return (
    <StyledDepositSection className="CryptoInfoSectionWrapper">
      <StyledInfoSectionContainer className="InfoSectionContainer">
        <StyledInfoSectionBlock className="InfoSectionBlock">
          <StyledGlobalIcon />
          <StyledInfoSectionText className="InfoSectionText">
            {currenciesListed.length
              ? t('layout__deposit__crypto__we_accept_currencies', {
                  CURRENCIES: currenciesListed,
                  CURRENCY: currencyLast,
                })
              : t('layout__deposit__crypto__we_accept_currency', {
                  CURRENCY: currencyLast,
                })}
          </StyledInfoSectionText>
        </StyledInfoSectionBlock>
        <StyledInfoSectionBlock className="InfoSectionBlock">
          <StyledCircleClockIcon />
          <StyledInfoSectionText className="InfoSectionText">
            {t(
              'Please note that transfers can take from 1 to 4 business days.',
            )}
          </StyledInfoSectionText>
          <StyledInfoSectionText className="InfoSectionText">
            {t(
              'Once the network confirms the payment, funds will be credited to your account.',
            )}
          </StyledInfoSectionText>
          <StyledInfoSectionText className="InfoSectionText">
            {t(
              'Please note that you take full responsibility for the accuracy of wallet and currency details that you enter while depositing funds. Cryptocurrency transactions are irreversible and you are unable to cancel them.',
            )}
          </StyledInfoSectionText>
        </StyledInfoSectionBlock>
        <StyledInfoSectionBlock className="InfoSectionBlock">
          <StyledPercentIcon />
          <StyledInfoSectionText className="InfoSectionText">
            {t(
              'Please note: any fees any party involved charges for the transfer, including any intermediary bank fees, are the responsibility of the payer. We will subtract the total fee from the sum you deposit or own on your account.',
            )}
          </StyledInfoSectionText>
        </StyledInfoSectionBlock>
      </StyledInfoSectionContainer>
    </StyledDepositSection>
  );
};
