import { InfoIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledAccountContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  min-width: 268px;
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-radius: 4px;
  padding: 16px;
  ${({ theme }) => theme?.breakpoints?.down('tablet')} {
    width: 100%;
  }
`;

export const StyledAccountContainerLeftSide = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledAccountContainerRightSide = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
  text-align: center;
`;

export const StyledAccountServiceIcon = styled('img')`
  width: 24px;
  height: 24px;
`;

export const StyledAccountServiceName = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;

export const StyledAccountInfoIcon = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  pointer-events: none;
  color: ${({ theme }) => theme.color.icon.secondary};
`;
