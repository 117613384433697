import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useContext,
  useImperativeHandle,
  useMemo,
} from 'react';
import {
  IColumn,
  IOnFetchArguments,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { TTransfersHistoryTableRefAttributes } from 'components/TransfersHistoryTable';
import { DEFAULT_MAX_PAGINATION_LENGTH } from 'constants/common';
import { TranslationContext } from 'contexts/TranslationContext';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useLogHandleTime } from 'hooks/useLogHandleTime';
import {
  TWithdrawalHistoryEntry,
  TWithdrawalHistoryResponse,
  withdrawalService,
} from 'services/withdrawal';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TUseTableDataProps } from 'types/TUseTableDataProps';

import { useTransfers } from '../../hooks';

import {
  getTransferTypeFilter,
  getWithdrawalHistoryTableColumns,
} from './WithdrawalHistoryTable.helpers';
import {
  TWithdrawalHistoryTableProps,
  TWithdrawalHistoryTableRefAttributes,
} from './WithdrawalHistoryTable.types';

export const WithdrawalHistoryTable: ForwardRefExoticComponent<
  TWithdrawalHistoryTableProps &
    RefAttributes<TWithdrawalHistoryTableRefAttributes>
> = forwardRef(function WithdrawalHistoryTable({ loading = false }, ref) {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const { setRepeatedWithdrawal, transferType, cashTransferTab } =
    useTransfers();
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'withdrawals-history-table',
  );
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const dateFormatter = useDateFormatter(true);

  const tableDataArgs = useMemo<
    TUseTableDataProps<TWithdrawalHistoryResponse | null>
  >(
    () => ({
      data: {
        onFetch: async ({ params }: IOnFetchArguments) => {
          const { skip, limit } = params;

          setStartHandleTime();
          const response = await withdrawalService.getHistory({
            type: getTransferTypeFilter(transferType, cashTransferTab),
            offset: skip,
            size: limit,
            lang: currentLanguage,
            account: selectedAccountId || '',
          });
          logHandleTime();
          return response;
        },
        loading,
      },
      pagination: {
        getDefaultPagination: () => ({
          limit: DEFAULT_MAX_PAGINATION_LENGTH,
          skip: 0,
        }),
      },
    }),
    [selectedAccountId, currentLanguage, transferType, cashTransferTab],
  );

  const {
    limit,
    page,
    setPage,
    setLimit,
    fetchData: refetchTableData,
    data: tableData,
    isLoading: tableLoading,
  } = useTableData<TWithdrawalHistoryResponse | null>(tableDataArgs);

  const columns = useMemo(
    (): IColumn<TWithdrawalHistoryEntry>[] =>
      getWithdrawalHistoryTableColumns({
        onRepeat: setRepeatedWithdrawal,
        currentLanguage,
        dateFormatter,
        t,
      }),
    [currentLanguage, t],
  );

  useImperativeHandle<
    TTransfersHistoryTableRefAttributes,
    TTransfersHistoryTableRefAttributes
  >(
    ref,
    () => ({
      fetchLastTransactions: () => {
        if (page === 0) {
          refetchTableData();
        } else {
          setPage(0);
        }
      },
    }),
    [page, setPage, refetchTableData],
  );

  const total = tableData?.count || 0;

  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount: Math.ceil(total / limit),
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [total, limit, page],
  );

  const data = useMemo(() => tableData?.results || [], [tableData?.results]);

  return (
    <Table<TWithdrawalHistoryEntry>
      isFlexLayout
      hasPagination
      translator={t}
      className="WithdrawalHistoryTable"
      columns={columns}
      data={data}
      tableId="withdrawalsHistoryTable"
      locale={currentLanguage}
      title={t('generic__activity')}
      titleSize={3}
      isLoading={loading || tableLoading || isTranslationsLoading}
      pageSize={limit}
      manualSortBy
      disableSortBy
      serverPaginationProps={serverPaginationProps}
    />
  );
});
