import { useMediaQuery } from '@mui/material';
import { upperFirst } from 'lodash';
import { FC, ReactNode, useContext, useRef, useState } from 'react';

import * as icons from 'assets/icons';
import { StyledCurrencyPlainSelector } from 'components/Header/components/CurrencySelector/CurrencySelector.styled';
import {
  StyledLangSelectorCurrentButton,
  StyledLangSelectorCurrentLangName,
  StyledLangSelectorFlagIconWraper,
  StyledLangSelectorLanguageName,
  StyledLangSelectorList,
  StyledLangSelectorListItem,
  StyledLangSelectorSelect,
  StyledLangSelectorSelectButton,
  StyledLangSelectorWrapper,
} from 'components/Header/components/LangSelector/LangSelector.styled';
import { BrandingContext, TranslationContext } from 'contexts';
import { useOutsideClick } from 'hooks/index';
import { useAppSelector } from 'store/hooks';
import { selectMenuData } from 'store/menu';
import { useTheme } from 'theme';

import { LANG_SHORT_CODES } from './LangSelector.constants';
import { TLangSelectorProps } from './LangSelector.types';

export const LangSelector: FC<TLangSelectorProps> = ({ desktopOnly }) => {
  const {
    t,
    currentLanguage,
    isTranslationsLoading,
    onChangeLanguage: changeLanguage,
  } = useContext(TranslationContext);
  const menuData = useAppSelector(selectMenuData);
  const { branding } = useContext(BrandingContext);

  const [opened, setOpened] = useState(false);
  const toggle = () => setOpened(!opened);
  const ref = useRef(null);
  useOutsideClick(ref, () => setOpened(false));

  const { languages = [] } = menuData || {};
  const notSelectedLanguages = languages.filter(
    ({ code }) => currentLanguage !== code,
  );
  const isSupervisor = !!branding?.is_supervisor;

  const onLanguageChange = async (value: string) => {
    setOpened(false);
    changeLanguage(value);
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')) || desktopOnly;

  const getFlagIcon = (code?: string): ReactNode => {
    if (!code) {
      return null;
    }

    const codeKey = `Flag${upperFirst(code.replace(/\W/g, ''))}Icon`;
    // eslint-disable-next-line import/namespace -- codeKey is computed so eslint obviously cannot check if it is exported
    const Component = icons[codeKey as keyof typeof icons];

    return (
      <StyledLangSelectorFlagIconWraper className="LangSelectorFlagIconWraper">
        {Component && <Component className="lang-selector__icon xnt-icon" />}
      </StyledLangSelectorFlagIconWraper>
    );
  };

  const shouldRender = !(
    isSupervisor ||
    isTranslationsLoading ||
    !languages.length
  );

  return !shouldRender ? null : (
    <StyledLangSelectorWrapper className="lang-selector" ref={ref}>
      {isDesktop ? (
        <>
          <StyledLangSelectorCurrentButton
            className="lang-selector__current"
            aria-haspopup="listbox"
            aria-expanded={opened}
            aria-describedby="lang_menu"
            aria-controls="lang_menu"
            data-test-id="button--change-lang"
            aria-label={
              t('layout__lang_selector__select_lang') + currentLanguage
            }
            onClick={toggle}
            disableRipple
            desktopOnly={desktopOnly}
          >
            {getFlagIcon(currentLanguage)}
            <StyledLangSelectorCurrentLangName className="LangSelectorCurrentLangName">
              {LANG_SHORT_CODES[currentLanguage] || currentLanguage}
            </StyledLangSelectorCurrentLangName>
          </StyledLangSelectorCurrentButton>

          {opened && !!notSelectedLanguages.length && (
            <StyledLangSelectorList
              id="lang_menu"
              className="lang-selector__list"
              aria-hidden={!opened}
            >
              {notSelectedLanguages.map(({ name, code }) => (
                <StyledLangSelectorListItem
                  className="LangSelectorListItem"
                  key={code}
                >
                  <StyledLangSelectorSelectButton
                    className="lang-selector__link"
                    data-test-id={`login-page__link--change-lang-${code}`}
                    data-language={code}
                    onClick={() => onLanguageChange(code)}
                    disableRipple
                  >
                    {getFlagIcon(code)}
                    <StyledLangSelectorLanguageName className="LangSelectorLanguageName">
                      {name}
                    </StyledLangSelectorLanguageName>
                  </StyledLangSelectorSelectButton>
                </StyledLangSelectorListItem>
              ))}
            </StyledLangSelectorList>
          )}
        </>
      ) : (
        <StyledCurrencyPlainSelector className="lang-selector__plain">
          {getFlagIcon(currentLanguage)}
          <StyledLangSelectorSelect
            className="lang-selector__plain-select"
            value={currentLanguage}
            onChange={({ target: { value } }) => onLanguageChange(value)}
          >
            {languages.map(({ name, code }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </StyledLangSelectorSelect>
        </StyledCurrencyPlainSelector>
      )}
    </StyledLangSelectorWrapper>
  );
};
