import { TAccountDetailsReducer, TAccountDetailsState } from './types';

export const accountDetailsReducer: TAccountDetailsReducer = (
  state,
  action,
): TAccountDetailsState => {
  switch (action.type) {
    case 'FETCH_ERROR': {
      const { fetching } = state;

      return {
        ...state,
        fetching: {
          ...fetching,
          pending: false,
          error: true,
          errorMessage: action.payload,
          succeed: false,
        },
      };
    }

    case 'FETCH_SUCCESS': {
      const { fetching } = state;
      const {
        account: { currencies, limits },
        targetAccounts,
      } = action.payload;

      return {
        ...state,
        currencies,
        targetAccounts,
        limits,
        fetching: {
          ...fetching,
          pending: false,
          error: false,
          errorMessage: '',
          succeed: true,
        },
      };
    }

    case 'FETCH_START': {
      const { fetching } = state;

      return {
        ...state,
        currencies: [],
        targetAccounts: [],
        fetching: {
          ...fetching,
          idle: false,
          pending: true,
        },
      };
    }

    default: {
      return state;
    }
  }
};
