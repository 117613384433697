import { FC, FormEvent, useContext } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import {
  StyledEmailTooltipActionButton,
  StyledEmailTooltipActionRow,
  StyledEmailTooltipForm,
  StyledEmailTooltipInput,
} from './EmailInputTooltip.styled';
import { TInputTooltipProps } from './EmailInputTooltip.types';

export const EmailInputTooltip: FC<TInputTooltipProps> = ({
  children,
  onChange,
  value,
  onAccept,
  onDecline,
  loading,
  ...rest
}) => {
  const { t } = useContext(TranslationContext);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onAccept();
  };

  return (
    <Tooltip
      {...rest}
      title={
        <StyledEmailTooltipForm
          className="EmailTooltipForm"
          onSubmit={handleSubmit}
        >
          <StyledEmailTooltipInput
            className="EmailTooltipInput"
            type="email"
            placeholder={t('generic__email')}
            required
            value={value}
            onChange={onChange}
            data-test-id="email_input_tooltip__input"
          />
          <StyledEmailTooltipActionRow className="EmailTooltipActionRow">
            <StyledEmailTooltipActionButton
              className="EmailTooltipActionButton"
              type="submit"
              color="primary"
              loading={loading}
              data-test-id="email_input_tooltip__button__submit"
            >
              {t('generic__action__send')}
            </StyledEmailTooltipActionButton>
            <StyledEmailTooltipActionButton
              className="EmailTooltipActionButton"
              color="secondary"
              onClick={onDecline}
              data-test-id="email_input_tooltip__button__decline"
            >
              {t('generic__action__cancel')}
            </StyledEmailTooltipActionButton>
          </StyledEmailTooltipActionRow>
        </StyledEmailTooltipForm>
      }
    >
      {children}
    </Tooltip>
  );
};
