import { blockNonNativeProps } from 'react-ui-kit-exante';

import { CardAction } from 'pages/CardSettings/components/CardSettingsCard/components';
import { styled } from 'theme';

import { TStyledCardIcon } from './CardSettingsCard.types';

export const StyledInfoBlocksWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

export const StyledCardSettingsCardWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  padding: 24px;
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-radius: 4px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: grid;
    grid-template-columns: 1fr 2fr 255px;
  }
`;

export const StyledCardIcon = styled('div', {
  shouldForwardProp: blockNonNativeProps(['imageUrl']),
})<TStyledCardIcon>`
  ${({ imageUrl }) => imageUrl && `background-image: url(${imageUrl});`}
  width: 48px;
  height: 48px;
`;

export const StyledActionsWrapper = styled('div')`
  &:empty {
    display: none;
  }

  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const StyledOrderedCardAction = styled(CardAction)`
  color: ${({ theme }) => theme.color.typo.source} !important;
`;
