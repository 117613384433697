import { styled } from 'theme';

export const StyledOrderInfoWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};

  ${({ theme }) => theme.breakpoints.up('lr')} {
    border-top: none;
    border-right: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;

export const StyledCardOrderInfoHeader = styled('h4')`
  margin: 0;

  font-family: ${({ theme }) => theme.font.header};
  font-size: 24px;
  font-weight: 400;
`;

export const StyledCardOrderInfoIconWrapper = styled('span')`
  color: ${({ theme }) => theme.color.icon.ghost};
`;

export const StyledOrderInfoCardsWrapper = styled('div')`
  display: grid;
  grid-template: repeat(3, 1fr) / 1fr;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template: 1fr / repeat(3, 1fr);
  }
`;

export const StyledOrderInfoCard = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;

  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-radius: 4px;

  padding: 24px;
`;

export const StyledOrderInfoParagraph = styled('p')`
  font-size: 15px;
  margin: 0;
`;
