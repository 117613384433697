import { TFunction } from 'i18next';

import { TransferType } from 'services/transfer';

import { TTransfersState } from '../../../../../../context';
import { ToBankAccountFields } from '../../../../types';

const isValidIBAN = (input: string): boolean => {
  const IBAN = input.toUpperCase().replace(/[^A-Z0-9]/g, ''); // remove space and convert to upper case
  return /^([A-Z]{2}\d{2})([A-Z\d]+)$/.test(IBAN);
};

const isValidSWIFT = (input: string): boolean => {
  const SWIFT = input.toUpperCase(); // convert to upper case
  return /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(SWIFT);
};

export const getNameError = (
  value: string,
  t: TFunction<'translation', undefined>,
): string => {
  if (!value.length) {
    return t('layout__field_is_required');
  }

  return '';
};

export const getIbanError = (
  value: string,
  hasStrongValidation: boolean,
  t: TFunction<'translation', undefined>,
): string => {
  if (!value.length) {
    return t('layout__field_is_required');
  }

  if (hasStrongValidation && !isValidIBAN(value)) {
    return 'Incorrect format';
  }

  return '';
};

export const getSwiftError = (
  value: string,
  hasStrongValidation: boolean,
  requiredFields: Set<keyof ToBankAccountFields>,
  t: TFunction<'translation', undefined>,
): string => {
  if (requiredFields.has('swift') && !value.length) {
    return t('layout__field_is_required');
  }

  if (hasStrongValidation && !isValidSWIFT(value)) {
    return 'Incorrect format';
  }

  if (value.length > 11) {
    return t('layout__field_is_long', { COUNT: 11 });
  }

  return '';
};

export const getBeneficiaryCountryError = (
  value: string | null,
  requiredFields: Set<keyof ToBankAccountFields>,
  t: TFunction<'translation', undefined>,
): string => {
  if (
    requiredFields.has('beneficiary_country') &&
    (value === null || !value.length)
  ) {
    return t('layout__field_is_required');
  }

  if (value && value.length > 100) {
    return t('layout__field_is_long', { COUNT: 100 });
  }

  return '';
};

export const getCityError = (
  value: string,
  requiredFields: Set<keyof ToBankAccountFields>,
  t: TFunction<'translation', undefined>,
): string => {
  if (!value.length && requiredFields.has('city')) {
    return t('layout__field_is_required');
  }

  if (value.length > 100) {
    return t('layout__field_is_long', { COUNT: 100 });
  }

  return '';
};

export const getShortAddressError = (
  value: string,
  requiredFields: Set<keyof ToBankAccountFields>,
  t: TFunction<'translation', undefined>,
): string => {
  if ((!value || !value.length) && requiredFields.has('short_address')) {
    return t('layout__field_is_required');
  }

  if (value && value.length > 255) {
    return t('layout__field_is_long', { COUNT: 255 });
  }

  return '';
};

export const getPostalCodeError = (
  value: string,
  requiredFields: Set<keyof ToBankAccountFields>,
  t: TFunction<'translation', undefined>,
): string => {
  if (!value.length && requiredFields.has('postal_code')) {
    return t('layout__field_is_required');
  }

  if (value.length > 15) {
    return t('layout__field_is_long', { COUNT: 15 });
  }

  return '';
};

export const getCorrespondentAccountError = (
  value: string,
  requiredFields: Set<keyof ToBankAccountFields>,
  t: TFunction<'translation', undefined>,
): string => {
  if (!value.length && requiredFields.has('correspondent_account')) {
    return t('layout__field_is_required');
  }

  return '';
};

export const getBankNameError = (
  value: string,
  requiredFields: Set<keyof ToBankAccountFields>,
  t: TFunction<'translation', undefined>,
): string => {
  if (!value.length && requiredFields.has('bank_name')) {
    return t('layout__field_is_required');
  }

  return '';
};

export const getBankCountryError = (
  value: string | null,
  requiredFields: Set<keyof ToBankAccountFields>,
  t: TFunction<'translation', undefined>,
): string => {
  if (requiredFields.has('bank_country') && (value === null || !value.length)) {
    return t('layout__field_is_required');
  }

  return '';
};

export const getBankAddressError = (
  value: string,
  requiredFields: Set<keyof ToBankAccountFields>,
  t: TFunction<'translation', undefined>,
): string => {
  if (!value.length && requiredFields.has('bank_address')) {
    return t('layout__field_is_required');
  }

  return '';
};

export const getCorrespondentSwiftError = (
  value: string,
  requiredFields: Set<keyof ToBankAccountFields>,
  hasStrongValidation: boolean,
  t: TFunction<'translation', undefined>,
): string => {
  if (!value.length && requiredFields.has('correspondent_swift')) {
    return t('layout__field_is_required');
  }

  if (
    hasStrongValidation &&
    !isValidSWIFT(value) &&
    requiredFields.has('correspondent_swift')
  ) {
    return 'Incorrect format';
  }

  if (value.length !== 0 && value.length > 11) {
    return t('layout__field_is_long', { COUNT: 11 });
  }

  return '';
};

export const fixNullStrict = (
  data?: Record<string, any>,
): Record<string, string> => {
  if (!data) {
    return {};
  }

  return Object.keys(data).reduce<Record<string, string>>((result, key) => {
    if (!result[key]) {
      return result;
    }

    return {
      ...result,
      [key]: result[key],
    };
  }, {});
};

export const getToBankAccountLimit = (
  amount: number | string,
  currency: string,
  commissions: TTransfersState['commissions'],
  wireType: TransferType,
): number | null => {
  const raw = parseFloat(String(amount));
  if (Number.isNaN(raw) || !commissions.list || !commissions.crossrates) {
    return null;
  }

  let commission = 0;
  const { minimum, maximum, type, value } = commissions.list[wireType];

  if (type === '%') {
    commission = raw * (value / 100);
  } else {
    const rate = commissions.crossrates[currency] || 1;
    commission = value * parseFloat(String(rate));
  }

  if (maximum !== 0 && commission > maximum) {
    return maximum;
  }

  if (minimum !== 0 && commission < minimum) {
    return minimum;
  }

  return raw - commission;
};
