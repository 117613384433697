import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledChecklistSkeletonItem = styled('li')`
  list-style: none;

  display: flex;
  align-items: center;
  margin: 8px 0;
`;

export const StyledChecklistSkeletonIcon = styled(Skeleton)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const StyledChecklistSkeletonText = styled(Skeleton)`
  width: 136px;
  height: 16px;
`;
