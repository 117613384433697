import { ToMyAccountState } from '../../../types';

import { ToMyAccountReducer } from './types';

export const toMyAccountReducer: ToMyAccountReducer = (
  state,
  action,
): ToMyAccountState => {
  switch (action.type) {
    case 'FETCH_STATE_START': {
      const { fetchingState } = state;

      return {
        ...state,
        fetchingState: {
          ...fetchingState,

          idle: false,
          error: false,
          errorMessage: '',
          pending: true,
        },
      };
    }

    case 'FETCH_STATE_ERROR': {
      const { fetchingState } = state;

      return {
        ...state,
        fetchingState: {
          ...fetchingState,

          error: true,
          errorMessage: action.payload,
          pending: false,
          succeed: false,
        },
      };
    }

    case 'FETCH_STATE_SUCCESS': {
      const { fetchingState } = state;
      const { accounts, limit } = action.payload;

      return {
        ...state,
        limit,
        accounts: accounts.sort((accountA, accountB) =>
          accountA.accountId > accountB.accountId ? 1 : -1,
        ),
        fetchingState: {
          ...fetchingState,

          pending: false,
          succeed: true,
        },
      };
    }

    case 'UPDATE_STATE_START': {
      const { updatingState } = state;

      return {
        ...state,
        updatingState: {
          ...updatingState,

          idle: false,
          error: false,
          errorMessage: '',
          pending: true,
        },
      };
    }

    case 'UPDATE_STATE_ERROR': {
      const { updatingState } = state;

      return {
        ...state,
        updatingState: {
          ...updatingState,

          error: true,
          errorMessage: action.payload,
          pending: false,
          succeed: false,
        },
      };
    }

    case 'UPDATE_STATE_SUCCESS': {
      const { updatingState } = state;
      const { accounts, limit } = action.payload;

      return {
        ...state,
        limit,
        accounts: accounts.sort((accountA, accountB) =>
          accountA.accountId > accountB.accountId ? 1 : -1,
        ),
        updatingState: {
          ...updatingState,

          pending: false,
          succeed: true,
        },
      };
    }

    case 'RESET': {
      return {
        ...state,
        currency: '',
        targetAccountId: '',
        accounts: [],
      };
    }

    case 'REPEAT_TRANSFER': {
      const { currency, targetAccountId, isAllFunds } = action.payload;

      return {
        ...state,
        isAllFunds,
        currency,
        targetAccountId,
      };
    }

    case 'SET_CURRENCY': {
      return {
        ...state,
        currency: action.payload,
      };
    }

    case 'SET_IS_ALL_FUNDS': {
      return {
        ...state,
        isAllFunds: action.payload,
      };
    }

    case 'SET_TARGET_ACCOUNT_ID': {
      return {
        ...state,
        targetAccountId: action.payload,
      };
    }

    case 'REQUEST_TRANSACTION_ERROR': {
      const { requestingTransactionState } = state;
      return {
        ...state,
        requestingTransactionState: {
          ...requestingTransactionState,
          pending: false,
          error: true,
          errorMessage: 'Unable create transaction',
        },
      };
    }

    case 'REQUEST_TRANSACTION_START': {
      const { requestingTransactionState } = state;
      return {
        ...state,
        requestingTransactionState: {
          ...requestingTransactionState,
          pending: true,
          error: false,
          errorMessage: '',
        },
      };
    }

    case 'REQUEST_TRANSACTION_SUCCESS': {
      const { requestingTransactionState } = state;
      return {
        ...state,
        requestingTransactionState: {
          ...requestingTransactionState,
          pending: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};
