import { styled } from 'theme';

export const StyledPartnerHeaderMobileWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  gap: 8px;
`;

export const StyledPartnerHeaderMobileRow = styled('div')`
  display: flex;
  justify-content: space-between;

  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;

  > :last-child {
    color: ${({ theme }) => theme.color.typo.primary};
    font-weight: 600;
  }
`;

export const StyledPartnerHeaderMobileDefaultLinkWrapper = styled('div')`
  display: flex;
  align-items: center;

  gap: 8px;
`;

export const StyledPartnerHeaderDefaultLinkTooltip = styled('div')`
  .MuiTooltip-tooltip {
    white-space: pre-wrap;
  }
`;

export const StyledPartnerHeaderDefaultLinkText = styled('span')`
  display: flex;
  align-items: center;

  gap: 6px;

  color: ${({ theme }) => theme.color.typo.action} !important;

  svg {
    color: ${({ theme }) => theme.color.typo.action} !important;

    path {
      stroke-width: 3px;
    }
  }
`;
