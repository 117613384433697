import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { CopyButton } from 'components/CopyButton';
import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { Dates } from 'helpers/dates';
import { TDepositCryptoTransaction } from 'services/deposit';

import {
  StyledDepositHistoryAddressTextClipped,
  StyledDepositHistoryStatusCell,
  StyledDepositHistorySubRowAddress,
} from '../DepositHistoryTable.styled';
import { TDepositTableTransaction } from '../DepositHistoryTable.types';

import { STATUSES, TERMINAL_STATUSES } from './columns.consts';
import { TDepositHistoryGetColumnsParams } from './columns.types';

export const getColumns: ({
  t,
  dateFormatter,
}: TDepositHistoryGetColumnsParams) => IColumn<TDepositTableTransaction>[] = ({
  t,
  dateFormatter,
}) => [
  {
    id: 'created',
    Header: t('generic__date'),
    accessor: 'created',
    disableFilters: true,
    Cell: ({ row: { original } }: ICellValue<TDepositCryptoTransaction>) => {
      return original.created
        ? dateFormatter(
            Dates.convertDateToUtcTimezone(new Date(original.created)),
          )
        : DEFAULT_EMPTY_PLACEHOLDER;
    },
  },
  {
    id: 'amount',
    Header: t('generic__amount'),
    accessor: 'amount',
    disableFilters: true,
    Cell: ({ row: { original } }: ICellValue<TDepositCryptoTransaction>) => {
      return `${original.amount} ${original.currency}`;
    },
  },
  {
    id: 'transaction_id',
    Header: t('generic__transaction_id'),
    accessor: 'transaction_id',
    disableFilters: true,
    Cell: ({ row: { original } }: ICellValue<TDepositCryptoTransaction>) => {
      return (
        <StyledDepositHistorySubRowAddress className="DepositHistorySubRowAddress">
          <StyledDepositHistoryAddressTextClipped className="DepositHistoryAddressTextClipped">
            {original.transaction_id}
          </StyledDepositHistoryAddressTextClipped>
          <CopyButton
            iconSize={16}
            iconColor="primary"
            text={original.transaction_id}
            data-test-id="deposit_table__cell__copy__txn_id"
          />
        </StyledDepositHistorySubRowAddress>
      );
    },
  },
  {
    id: 'confirmations',
    Header: t('layout__deposit__confirmations'),
    accessor: 'confirmations',
    disableFilters: true,
    Cell: ({ row: { original } }: ICellValue<TDepositCryptoTransaction>) => {
      return original.status in TERMINAL_STATUSES
        ? t(STATUSES[original.status].key)
        : original.confirmations;
    },
  },
  {
    id: 'status',
    Header: t('generic__status'),
    accessor: 'status',
    disableFilters: true,
    Cell: ({ row: { original } }: ICellValue<TDepositCryptoTransaction>) => {
      const status = STATUSES[original.status];

      return (
        <StyledDepositHistoryStatusCell
          className="DepositHistoryStatusCell"
          color={status.color}
        >
          {t(status.key)}
        </StyledDepositHistoryStatusCell>
      );
    },
  },
];
