import { FC } from 'react';

import { AccordionSkeleton } from 'components/AccordionSkeleton';
import { PanelHeaderSkeleton } from 'components/PanelHeaderSkeleton';

export const TotalSkeleton: FC = () => (
  <>
    <PanelHeaderSkeleton />
    <AccordionSkeleton />
  </>
);
