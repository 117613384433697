import { useContext, useMemo } from 'react';
import { calculateCountOfPages } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { prepareTableId } from 'helpers/prepareTableId';
import { selectSettings } from 'store/application';
import { useAppSelector } from 'store/hooks';

import { AccountsTableContext } from '../../contexts';

import { getColumns } from './AccountsTable.columns';
import { StyledTable } from './AccountsTable.styled';

const tableId = prepareTableId('clientsarea-accounts-table');

export const AccountsTable = () => {
  const { currentLanguage, t } = useContext(TranslationContext);
  const { accountSettings } = useAppSelector(selectSettings);

  const {
    accountsList,
    isLoading,
    setSorting,
    limit,
    setPage,
    setLimit,
    page,
    totalRecords,
    isAnyDescriptionEditable,
    isAnyAutoDebitShowing,
    isAnyAutoConversionEditable,
    isAnyMarginTradingEditable,
  } = useContext(AccountsTableContext);

  const columns = useMemo(
    () =>
      getColumns({
        t,
        accountSettings,
        isAnyDescriptionEditable,
        isAnyAutoDebitShowing,
        isAnyAutoConversionEditable,
        isAnyMarginTradingEditable,
      }),
    [
      t,
      accountSettings,
      isAnyDescriptionEditable,
      isAnyAutoDebitShowing,
      isAnyAutoConversionEditable,
      isAnyMarginTradingEditable,
    ],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total: totalRecords,
      pageCount: calculateCountOfPages(totalRecords, limit),
    }),
    [limit, page, totalRecords],
  );

  return (
    <StyledTable
      disableSortBy
      isFlexLayout
      manualSortBy
      columns={columns}
      data={accountsList}
      isLoading={isLoading || !accountSettings}
      locale={currentLanguage}
      skeletonsCount={10}
      tableId={tableId}
      translator={t}
      hasPagination
      showScrollbar
      onSort={setSorting}
      serverPaginationProps={serverPaginationProps}
    />
  );
};
