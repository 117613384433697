import { TFunction } from 'i18next';
import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TFeedPermission } from 'services/settings/subscriptions';

import { TGetColumns } from './Subscription.types';
import { StyledCell, StyledTypeNameCell } from './Subscriptions.styled';
import {
  SubscriptionActionCell,
  SubscriptionDateCell,
  SubscriptionPriceCell,
} from './components';

const getFeedTypeTranslate = (t: TFunction, feedType: string) => {
  return t([
    `layout__market_subscriptions__${feedType.toLowerCase()}`,
    feedType,
  ]);
};

export const getColumnsDesktop: ({
  t,
  paymentAccount,
  updateSubscriptions,
  isAccountUpdating,
}: TGetColumns) => IColumn<TFeedPermission[]>[] = ({
  t,
  paymentAccount,
  updateSubscriptions,
  isAccountUpdating,
}) => [
  {
    id: 'marketDataGroup',
    Header: t('generic__type'),
    accessor: 'marketDataGroup',
    disableFilters: true,
    Cell: ({ row: { values, original } }: ICellValue<TFeedPermission>) => {
      return (
        <StyledCell className="TypeCell">
          {values.marketDataGroup}
          <StyledTypeNameCell
            className="TypeNameCell"
            title={getFeedTypeTranslate(t, original.feedType)}
          >
            {getFeedTypeTranslate(t, original.feedType)}
          </StyledTypeNameCell>
        </StyledCell>
      );
    },
  },
  {
    id: 'price',
    Header: t('layout__settings__subscriptions__month_price'),
    accessor: 'price',
    disableFilters: true,
    Cell: ({ row: { original } }: ICellValue<TFeedPermission>) => {
      return <SubscriptionPriceCell feedPermission={original} />;
    },
  },
  {
    id: 'userFeedPermission.endDate',
    Header: t('generic__date'),
    accessor: 'userFeedPermission.endDate',
    disableFilters: true,
    Cell: ({ row: { original } }: ICellValue<TFeedPermission>) => {
      return <SubscriptionDateCell feedPermission={original} />;
    },
  },
  {
    id: 'action',
    Header: t('layout__settings__subscriptions_action'),
    accessor: 'userFeedPermission.subscribed',
    disableFilters: true,
    width: 120,
    align: 'right',
    Cell: ({ row: { original } }: ICellValue<TFeedPermission>) => {
      return (
        <SubscriptionActionCell
          feedPermission={original}
          paymentAccount={paymentAccount}
          updateSubscriptions={updateSubscriptions}
          isUpdating={isAccountUpdating}
        />
      );
    },
  },
];

export const getColumnsMobile: ({
  t,
  paymentAccount,
  updateSubscriptions,
  isAccountUpdating,
}: TGetColumns) => IColumn<TFeedPermission[]>[] = ({
  t,
  paymentAccount,
  updateSubscriptions,
  isAccountUpdating,
}) => [
  {
    id: 'marketDataGroup',
    Header: t('generic__type'),
    accessor: 'marketDataGroup',
    disableFilters: true,
    Cell: ({ row: { values, original } }: ICellValue<TFeedPermission>) => {
      return (
        <StyledCell className="TypeCell">
          {values.marketDataGroup}
          <StyledTypeNameCell
            className="TypeNameCell"
            title={getFeedTypeTranslate(t, original.feedType)}
          >
            {getFeedTypeTranslate(t, original.feedType)}
          </StyledTypeNameCell>
          <SubscriptionDateCell feedPermission={original} />
        </StyledCell>
      );
    },
  },
  {
    id: 'action',
    Header: t('layout__settings__subscriptions_action'),
    accessor: 'userFeedPermission.subscribed',
    disableFilters: true,
    width: 120,
    align: 'right',
    Cell: ({ row: { original } }: ICellValue<TFeedPermission>) => {
      return (
        <StyledCell className="Cell" title="">
          <SubscriptionPriceCell feedPermission={original} />
          <SubscriptionActionCell
            feedPermission={original}
            paymentAccount={paymentAccount}
            updateSubscriptions={updateSubscriptions}
            isUpdating={isAccountUpdating}
          />
        </StyledCell>
      );
    },
  },
];
