import { FC } from 'react';

import { StyledCircleCheckIcon } from './ReportFormatCheck.styled';
import { TReportFormatCheckProps } from './ReportFormatCheck.types';

export const ReportFormatCheck: FC<TReportFormatCheckProps> = ({
  report,
  format,
}) => {
  return report.format.includes(format) ? <StyledCircleCheckIcon /> : null;
};
