import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { PATHS } from 'router/router.constants';

export const CorporateActions: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === PATHS.ROOT + PATHS.CORPORATE_ACTIONS) {
      navigate(
        PATHS.ROOT +
          PATHS.CORPORATE_ACTIONS +
          PATHS.CORPORATE_ACTIONS_INSTRUMENT,
      );
    }
  }, []);

  return <Outlet />;
};
