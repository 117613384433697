import { TSharedActionProps } from '../../../../Mfa.types';
import { ActionSmsView } from '../../../ActionSmsView';

import { useEnableSms } from './useEnableSms';

export const EnableSms = ({ onSuccess }: TSharedActionProps) => {
  const {
    code,
    enabled,
    isVerifying,
    phoneNumber,
    countryCode,
    seconds,
    verifyErrorMessage,
    handleCodeChange,
    handleResendClick,
  } = useEnableSms(onSuccess);

  return (
    <ActionSmsView
      code={code}
      onChange={handleCodeChange}
      onResend={handleResendClick}
      seconds={seconds}
      message={verifyErrorMessage}
      phoneNumber={phoneNumber}
      countryCode={countryCode}
      disabled={isVerifying || !enabled}
    />
  );
};
