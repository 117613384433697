import Cookies from 'js-cookie';

import {
  AuthHint,
  AuthType,
  TAuthFlow,
  TBrandingResponse,
} from 'services/branding';

import { GOOGLE_LOGIN_USED_COOKIE_NAME } from './ExternalAccounts.constants';

export const removeGoogleAlreadyUsedCookie = (): void => {
  if (!Cookies.get(GOOGLE_LOGIN_USED_COOKIE_NAME)) {
    return;
  }

  Cookies.remove(GOOGLE_LOGIN_USED_COOKIE_NAME);
};

export const getOAuthFlows = (
  branding?: TBrandingResponse | null,
  bindingOnly = false,
) =>
  branding?.branding?.auth_flows?.filter(
    (flow: TAuthFlow) =>
      flow.type === AuthType.OAuth &&
      flow.hint &&
      (bindingOnly ? flow.binding : true) &&
      // disable Google when disabled in branding
      (flow.hint === AuthHint.Google
        ? branding?.branding?.google_login_enabled
        : true) &&
      // disable Apple ID when disabled in branding
      (flow.hint === AuthHint.AppleId
        ? branding?.branding?.apple_login_enabled
        : true),
  );
