import { initialFetchState, initialUpdateState } from 'constants/state';

import {
  ToInternalAccountFields,
  ToInternalAccountState,
} from '../../../types';

export const initialToInternalAccountFields: ToInternalAccountFields = {
  files: [],
  reference: '',
};

export const initialToInternalAccountState: ToInternalAccountState = {
  ...initialToInternalAccountFields,
  currency: '',
  isAllFunds: false,
  limit: '',
  errors: {},
  fetchingLimits: initialFetchState,
  updatingLimits: initialFetchState,
  mode: 'IDLE',
  name: '',
  targetAccountId: '',
  requestingTransactionState: initialUpdateState,
};
