import { FC, useContext } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { CopyIcon } from 'assets/icons';
import { TranslationContext } from 'contexts';
import { useCopyToClipboard } from 'hooks/index';
import {
  StyledInviteCellItem,
  StyledInviteCellItemCopyButton,
} from 'pages/AssetManagementSetup/components/AssetManagementSetupTable/cells/InviteCell/InviteCell.styled';
import { TInviteCellProps } from 'pages/AssetManagementSetup/components/AssetManagementSetupTable/cells/InviteCell/InviteCell.types';

export const InviteCell: FC<TInviteCellProps> = ({ asset }) => {
  const [, copy] = useCopyToClipboard(asset);
  const { t } = useContext(TranslationContext);
  const copyLink = async () => {
    const copyResult = await copy(asset);
    if (copyResult) {
      Notification.success({
        title: t('asset_management_setup__table__copied'),
      });
    }
  };

  return (
    <StyledInviteCellItem className="InviteCellItem">
      <StyledInviteCellItemCopyButton
        iconSize={16}
        onClick={copyLink}
        label={asset}
      >
        <CopyIcon />
      </StyledInviteCellItemCopyButton>
    </StyledInviteCellItem>
  );
};
