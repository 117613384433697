import { ChangeEvent, FC, useRef, useState } from 'react';
import { Loader } from 'react-ui-kit-exante';

import {
  StyledActionIconAnchor,
  StyledActionIconButton,
  StyledActionsWrapper,
  StyledDownloadCompactIcon,
  StyledExportCompactIcon,
  StyledHiddenInput,
} from './DocActionsCell.styled';
import { TDocActionsCellProps } from './DocActionsCell.types';

export const DocActionsCell: FC<TDocActionsCellProps> = ({
  downloadUrl,
  onUploadClick,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    const file = files?.[0];

    if (file) {
      onUploadClick?.(file, setIsUploading);
    }
  };

  return (
    <StyledActionsWrapper className="ActionsWrapper">
      {onUploadClick && (
        <StyledActionIconButton
          className="ActionIconAnchor"
          onClick={handleClick}
          disabled={isUploading}
        >
          {isUploading ? (
            <Loader />
          ) : (
            <StyledExportCompactIcon className="ExportCompactIcon" />
          )}
          <StyledHiddenInput
            className="HiddenInput"
            type="file"
            hidden
            accept="application/pdf"
            onChange={handleChange}
            ref={hiddenFileInput}
          />
        </StyledActionIconButton>
      )}
      {downloadUrl && (
        <StyledActionIconAnchor
          className="ActionIconAnchor"
          href={downloadUrl}
          target="_blank"
        >
          <StyledDownloadCompactIcon className="DownloadCompactIcon" />
        </StyledActionIconAnchor>
      )}
    </StyledActionsWrapper>
  );
};
