import cn from 'classnames';
import { FC, useContext, useState } from 'react';
import { Button, InfoIcon, Skeleton } from 'react-ui-kit-exante';

import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { useTransfers } from '../../hooks';

import { StyledNoteText } from './NoteText.styled';
import { TNoteTextProps } from './NoteText.types';

export const NoteText: FC<TNoteTextProps> = ({
  type,
  className,
  showSkeleton = false,
}) => {
  const { t } = useContext(TranslationContext);
  const { transfersState } = useTransfers();
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const [isExpanded, setIsExpanded] = useState(false);

  const [id] = (selectedAccountId || '').split('.');

  const legalEntity = transfersState.client_id_le_map?.[id];

  const shortDescription =
    transfersState.wr_types?.[type]?.short_description?.[legalEntity];
  const description =
    transfersState.wr_types?.[type]?.description?.[legalEntity];

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  if (!shortDescription) {
    return null;
  }

  return showSkeleton ? (
    <Skeleton width="100%" height={90} />
  ) : (
    <StyledNoteText className={cn('NoteTextContainer', className)}>
      <InfoIcon className="NoteTextInfoIcon" />
      <div className="NoteText">
        <Text size="13px" color="secondary" className="ShortDescription">
          <span dangerouslySetInnerHTML={{ __html: t(shortDescription) }} />
        </Text>
        {isExpanded && description && (
          <Text size="13px" color="secondary" className="Description">
            <span dangerouslySetInnerHTML={{ __html: t(description) }} />
          </Text>
        )}
        {description && (
          <Button
            color="transparent"
            textColor="action"
            onClick={toggleExpand}
            className="Toggle"
          >
            {isExpanded
              ? t('layout__show_text_less')
              : t('layout__show_text_more')}
          </Button>
        )}
      </div>
    </StyledNoteText>
  );
};
