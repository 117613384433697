import { InfoIcon } from 'assets/icons';
import { styled } from 'theme';

import { StyledSharedContainer } from '../styled';

export const StyledNotificationsHeaderContainer = styled(StyledSharedContainer)`
  align-items: center;

  border-bottom: 1px solid
    ${({ theme }) => {
      return theme.color.line.primary;
    }};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const StyledNotificationsHeaderItem = styled('div')<{
  align: 'left' | 'center';
}>`
  display: flex;
  padding: 8px 24px;
  align-items: center;
  gap: 8px;

  color: ${({ theme }) => {
    return theme.color.typo.secondary;
  }};

  justify-content: ${({ align }) => {
    if (align === 'left') {
      return 'flex-start';
    }

    return 'center';
  }};
`;

export const StyledNotificationsHeaderItemTitle = styled('span')`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
`;

export const StyledNotificationsHeaderSkeletonWrapper = styled('div')`
  padding: 0 24px;
  display: flex;
  gap: 8px;
`;

export const StyledNotificationsHeaderEmailInfoIcon = styled(InfoIcon)`
  color: ${({ theme }) => {
    return theme.color.typo.secondary;
  }};
`;
