import { FC } from 'react';

import { WITHDRAWAL_HISTORY_ENTRY_POSITIONS_MAP } from './WithdrawalInfoSecurityPositions.constants';
import {
  StyledWithdrawalInfoSecurityPositionHeader,
  StyledWithdrawalInfoSecurityPositionTable,
  StyledWithdrawalInfoSecurityPositionWrapper,
} from './WithdrawalInfoSecurityPositions.styled';
import { TWithdrawalInfoSecurityPositionsProps } from './WithdrawalInfoSecurityPositions.types';
import { WithdrawalInfoSecurityPosition } from './components/WithdrawalInfoSecurityPosition';

export const WithdrawalInfoSecurityPositions: FC<
  TWithdrawalInfoSecurityPositionsProps
> = ({ data }) => {
  return (
    <>
      {data.positions.map((position) => {
        return (
          <StyledWithdrawalInfoSecurityPositionWrapper
            key={position.symbolId}
            className="WithdrawalInfoSecurityPositionWrapper"
          >
            <StyledWithdrawalInfoSecurityPositionHeader className="WithdrawalInfoSecurityPositionHeader">
              {position.symbolId}
            </StyledWithdrawalInfoSecurityPositionHeader>
            <StyledWithdrawalInfoSecurityPositionTable className="WithdrawalInfoSecurityPositionsTable">
              <tbody>
                {Object.entries(WITHDRAWAL_HISTORY_ENTRY_POSITIONS_MAP).map(
                  ([key, accessor]) => {
                    const value =
                      typeof accessor === 'function'
                        ? accessor(position)
                        : position[accessor];

                    return (
                      <WithdrawalInfoSecurityPosition
                        key={key}
                        value={value}
                        textKey={key}
                      />
                    );
                  },
                )}
              </tbody>
            </StyledWithdrawalInfoSecurityPositionTable>
          </StyledWithdrawalInfoSecurityPositionWrapper>
        );
      })}
    </>
  );
};
