import { FC, useContext } from 'react';

import { BrandingContext, TranslationContext } from 'contexts';
import { useRouteData } from 'hooks/useRouteData';

export const TitleUpdater: FC = () => {
  const { branding } = useContext(BrandingContext);
  const { t } = useContext(TranslationContext);
  const routeData = useRouteData();

  const title = routeData.titleKey;
  const wlName = branding?.wl_name;

  if (title) {
    let documentTitle = t(title);

    const separator = documentTitle ? '—' : '';

    if (wlName) {
      documentTitle += ` ${separator} ${wlName}`;
    }

    document.title = documentTitle;
  }

  return null;
};
