import { Button, Input } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPartnerLinksEditActionWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

export const StyledPartnerLinksEditActionInput = styled(Input)`
  background: ${({ theme }) => theme.color.bg.secondary};

  & .MuiInputBase-input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledPartnerLinksEditActionPartnerButton = styled(Button)`
  text-transform: none;
  color: ${({ theme }) => theme.color.typo.action};
`;
