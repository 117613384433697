import { FC, useContext, useMemo } from 'react';

import { TranslationContext } from 'contexts';
import { AccountDataContext } from 'contexts/AccountDataContext/AccountDataContext';
import { StyledAccountSummaryPanel } from 'pages/Portfolio/components/AccountSummary/AccountSummary.styled';
import { AccountSummarySkeleton } from 'pages/Portfolio/components/AccountSummary/components';
import { SummaryBlock } from 'pages/Portfolio/components/AccountSummary/components/SummaryBlock';
import {
  getAccountMetrics,
  getMetricNumbers,
} from 'pages/Portfolio/components/AccountSummary/helpers';
import { TNumberedMetrics } from 'types/accounts';

import { TAccountSummaryProps } from './components/AccountSummary.types';

export const AccountSummary: FC<TAccountSummaryProps> = ({ showBalance }) => {
  const { accountData, isLoading } = useContext(AccountDataContext);

  const { isTranslationsLoading } = useContext(TranslationContext);

  const numberedMetrics: TNumberedMetrics | null =
    useMemo<TNumberedMetrics | null>(() => {
      return accountData
        ? getMetricNumbers(getAccountMetrics(accountData))
        : null;
    }, [accountData]);

  const showSummarySkeleton = !accountData || isLoading;

  return (
    <StyledAccountSummaryPanel className="AccountSummaryPanel">
      {showSummarySkeleton || isTranslationsLoading ? (
        <AccountSummarySkeleton showBalance={showBalance} />
      ) : (
        !!numberedMetrics && (
          <SummaryBlock
            numberedMetrics={numberedMetrics}
            showBalance={showBalance}
          />
        )
      )}
    </StyledAccountSummaryPanel>
  );
};
