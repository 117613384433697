import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledReportAccountListProps } from '../../tabs/CustomReports/components/CustomReportsTable/columns/components/ReportUnion/ReportUnion.types';

export const StyledReportAccountList = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isMobile']),
})<TStyledReportAccountListProps>`
  white-space: normal;
  font-family: ${({ isMobile, theme }) => {
    return isMobile ? theme.font.header : theme.font.main;
  }};
`;
