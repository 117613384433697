import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { StyledPageWrapper } from 'components/Main';
import { Tabs } from 'components/Tabs';
import { TabsCacheProvider, TranslationContext } from 'contexts';
import { SECURITY_TAB_ID } from 'services/tabs';

import { StyledSecurityPanel } from './Security.styled';

export const Security = () => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledPageWrapper className="SecurityWrapper">
      <StyledSecurityPanel
        className="SecurityPanel"
        title={t('menu_item__security')}
      >
        <Tabs disableAccountValidation tabsForShow={SECURITY_TAB_ID} />
        <TabsCacheProvider>
          <Outlet />
        </TabsCacheProvider>
      </StyledSecurityPanel>
    </StyledPageWrapper>
  );
};
