import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledButton = styled('button')`
  display: flex;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: ${({ theme }) => theme.color.typo.action};
`;

export const StyledActionIconWrapper = styled('div')<{ isActive?: boolean }>`
  width: 24px;
  height: 24px;

  & svg {
    width: 24px;
    height: 24px;
  }

  color: ${({ isActive, theme }) =>
    isActive ? theme.color.typo.action : theme.color.typo.secondary};
`;

export const StyledActionBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledActionRow = styled('div')<{
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 24px 1fr auto;
  padding: 24px;
  grid-gap: 16px;
  min-height: 100px;
  border-top: 1px solid ${({ theme }) => theme.color.line.secondary};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'inherit')};
  background-color: ${({ isActive, theme }) => {
    return isActive ? theme.color.datepicker.dayHover : 'transparent';
  }};
  opacity: ${({ isDisabled }) => (isDisabled ? '.5' : '1')};

  .ActionButton {
    color: ${({ isActive, theme }) =>
      isActive ? theme.color.typo.secondary : theme.color.typo.action};
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: center;
  }
`;

export const StyledActionTitle = styled('span')`
  font-size: 15px;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const StyledActionDescription = styled('span')`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledActionSkeleton = styled('div')`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  gap: 8px;
  min-height: 100px;
  border-top: 1px solid ${({ theme }) => theme.color.line.secondary};

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.color.line.secondary};
  }
`;

export const StyledActionTitleSkeleton = styled(Skeleton)`
  width: 80px;
  height: 16px;
  flex-shrink: 0;
`;

export const StyledActionDescriptionSkeleton = styled(Skeleton)`
  width: 40px;
  height: 8px;
  flex-shrink: 0;
`;
