import { FC, useContext } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import {
  StyledTaxReportUnionWrapper,
  StyledTaxReportUnionHead,
  StyledTaxReportUnionTitle,
  StyledTaxReportDetailsList,
  StyledTaxReportDetailsListItem,
  StyledTaxReportDetailTitle,
  StyledTaxReportDetailValue,
} from '../../TaxReportUnion.styled';

import {
  StyledTaxReportUnionSkeletonWrapper,
  StyledTaxReportUnionStatusSkeletonWrapper,
} from './TaxReportUnionSkeleton.styled';
import { TTaxReportUnionSkeletonProps } from './TaxReportUnionSkeleton.types';

export const TaxReportUnionSkeleton: FC<TTaxReportUnionSkeletonProps> = ({
  title,
  items = 2,
}) => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledTaxReportUnionSkeletonWrapper>
      {[...new Array(items)].map((value, key) => (
        <StyledTaxReportUnionWrapper
          key={key}
          className="TaxReportUnionWrapper"
        >
          <StyledTaxReportUnionHead className="TaxReportUnionHead">
            <StyledTaxReportUnionTitle className="TaxReportUnionTitle">
              {t(title || '')}
            </StyledTaxReportUnionTitle>
            <Skeleton width={24} height={24} />
          </StyledTaxReportUnionHead>
          <StyledTaxReportUnionStatusSkeletonWrapper>
            <Skeleton width={16} />
            <Skeleton width="100%" />
          </StyledTaxReportUnionStatusSkeletonWrapper>
          <StyledTaxReportDetailsList className="TaxReportDetailsList">
            <StyledTaxReportDetailsListItem className="TaxReportDetailsListItem">
              <StyledTaxReportDetailTitle className="TaxReportDetailTitle">
                <Skeleton width={56} height={16} />
              </StyledTaxReportDetailTitle>
              <StyledTaxReportDetailValue className="TaxReportDetailValue">
                <Skeleton width={56} height={16} />
              </StyledTaxReportDetailValue>
            </StyledTaxReportDetailsListItem>
            <StyledTaxReportDetailsListItem className="TaxReportDetailsListItem">
              <StyledTaxReportDetailTitle className="TaxReportDetailTitle">
                <Skeleton width={56} height={16} />
              </StyledTaxReportDetailTitle>
              <StyledTaxReportDetailValue className="TaxReportDetailValue">
                <Skeleton width={56} height={16} />
              </StyledTaxReportDetailValue>
            </StyledTaxReportDetailsListItem>
          </StyledTaxReportDetailsList>
        </StyledTaxReportUnionWrapper>
      ))}
    </StyledTaxReportUnionSkeletonWrapper>
  );
};
