import { useContext, useEffect } from 'react';
import { useData } from 'react-ui-kit-exante';
import { useIntercom } from 'react-use-intercom';

import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { chatService } from 'services/chat';

export const useIntercomApp = () => {
  const { branding } = useContext(BrandingContext);
  const { currentLanguage } = useContext(TranslationContext);
  const { boot, update } = useIntercom();

  const appId = branding?.branding?.intercom_app_id;

  const isEnabled =
    appId &&
    branding &&
    branding?.branding?.show_intercom_chat &&
    !branding?.is_supervisor;

  const { fetchData: fetchIntercom, isLoading: isIntercomLoading } = useData({
    loading: true,
    onFetch: async () => {
      return chatService.getIntercomInfo();
    },
    onSuccess: (info) => {
      if (!info || !isEnabled) {
        return;
      }

      boot({
        userId: info.userId,
        userHash: info.hash,
        name: info.firstName,
        email: info.email,
        languageOverride: currentLanguage,
      });
    },
  });

  useEffect(() => {
    if (isEnabled) {
      fetchIntercom();
    }
  }, [isEnabled]);

  useEffect(() => {
    if (isIntercomLoading || !isEnabled) {
      return;
    }

    update({
      languageOverride: currentLanguage,
    });
  }, [currentLanguage]);
};
