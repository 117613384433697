import { TransferType } from 'services/transfer';

import { ToBankAccountState } from '../../../types';

import { initialToBankAccountFields } from './constants';
import { getErrors } from './helpers';
import { ToBankAccountReducer } from './types';

export const toBankAccountReducer: ToBankAccountReducer = (
  state,
  action,
): ToBankAccountState => {
  switch (action.type) {
    case 'DELETE_FILE': {
      const { fields } = state;

      return {
        ...state,
        fields: {
          ...fields,
          files: fields.files.filter((file) => file.name !== action.payload),
        },
      };
    }

    case 'REPEAT_TRANSFER': {
      const { currency, fields, isAllFunds } = action.payload;

      return {
        ...state,
        currency,
        isAllFunds,
        fields: {
          ...initialToBankAccountFields,
          ...fields,
        },
      };
    }

    case 'REQUEST_TRANSACTION_ERROR': {
      const { requestingTransactionState } = state;

      return {
        ...state,
        requestingTransactionState: {
          ...requestingTransactionState,
          error: true,
          errorMessage: 'Unable to request transfer transaction',
          pending: false,
        },
      };
    }

    case 'REQUEST_TRANSACTION_START': {
      const { requestingTransactionState } = state;

      return {
        ...state,

        requestingTransactionState: {
          ...requestingTransactionState,
          error: false,
          errorMessage: '',
          pending: true,
        },
      };
    }

    case 'REQUEST_TRANSACTION_SUCCESS': {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { name, use_personal_iban, ...fields } = initialToBankAccountFields;
      const { requestingTransactionState } = state;

      return {
        ...state,
        type: TransferType.Wire,
        fields: {
          ...state.fields,
          ...fields,
          ...action.payload,
        },

        requestingTransactionState: {
          ...requestingTransactionState,
          pending: false,
        },
      };
    }

    case 'RESET_STATE': {
      return action.payload;
    }

    case 'SET_CURRENCY': {
      return {
        ...state,
        currency: action.payload,
      };
    }

    case 'SET_IS_ALL_FUNDS': {
      return {
        ...state,
        isAllFunds: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    case 'SET_FIELD': {
      const { fields } = state;
      const { key, value } = action.payload;
      const errors = getErrors(state.errors, key);

      return {
        ...state,
        errors,
        fields: {
          ...fields,
          [key]: value,
        },
      };
    }

    case 'SET_FILES': {
      const { fields } = state;

      return {
        ...state,
        fields: {
          ...fields,
          files: [...fields.files, ...action.payload],
        },
      };
    }

    case 'SET_MODE': {
      return {
        ...state,
        mode: action.payload,
      };
    }

    case 'SET_NAME': {
      const errors = getErrors(state.errors, 'name');
      const { name, baseName, preventChecking, isBeneficiaryChanged } =
        action.payload;
      const { fields } = state;

      const type =
        name.trim() === baseName.trim() && !isBeneficiaryChanged
          ? TransferType.Wire
          : TransferType.WireThirdParty;

      return {
        ...state,
        errors,
        type: preventChecking ? TransferType.Wire : type,
        fields: {
          ...fields,
          name,
        },
      };
    }

    case 'SET_BENEFICIARY_FIELD': {
      const { key, value, initValue, preventChecking, isNameChanged } =
        action.payload;
      const errors = getErrors(state.errors, key);
      const { fields } = state;

      const type =
        value.trim() === initValue?.trim() && !isNameChanged
          ? TransferType.Wire
          : TransferType.WireThirdParty;

      return {
        ...state,
        errors,
        type: preventChecking ? TransferType.Wire : type,
        fields: {
          ...fields,
          [key]: value,
        },
      };
    }

    case 'SET_USE_PERSONAL_IBAN': {
      const { fields } = state;

      return {
        ...state,
        fields: {
          ...fields,
          use_personal_iban: action.payload,
        },
      };
    }

    case 'SET_WITHDRAWAL_FORMAT': {
      return {
        ...state,
        wrFormat: action.payload,
      };
    }

    default:
      return state;
  }
};
