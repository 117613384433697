import { TFunction } from 'i18next';

import { EXANTE_SUPPORT_EMAIL } from 'constants/WHITELABEL';
import { BrandingState } from 'contexts';

export const notifyMessages = (
  t: TFunction,
  branding: BrandingState['branding'],
) => {
  const wlSupportEmail = branding?.wl_emails_support || EXANTE_SUPPORT_EMAIL;

  return {
    disabledRestrictionText: t(
      'layout__settings__user_details__restrict_processing_disabled',
      { WL_SUPPORT_EMAIL: wlSupportEmail },
    ),
    disabledDeletedText: t(
      'layout__settings__user_details__delete_account_disabled',
      { WL_SUPPORT_EMAIL: wlSupportEmail },
    ),
    restrictConfirmMessage: t(
      'layout__settings__user_details__restrict_confirm',
      {
        SUPPORT_EMAIL: wlSupportEmail,
        NAME: branding?.wl_name,
      },
    ),
    didNotReceiveCodeText: t('if_you_didnt_receive_the_code_contact_us_at', {
      SUPPORT_EMAIL: wlSupportEmail,
    }),
  };
};
