export const CORPORATE_ACTIONS_POSTINGS_DISPLAYED_COLUMNS = {
  account_id: 'generic__account_id',
  symbol: 'generic__symbol',
  name: 'generic__name',
  ex_date: 'generic__ex_date',
  pay_date: 'generic__pay_date',
  // Amount per share (new column, no backend yet),
  currency: 'generic__currency',
  position: 'generic__position',
  sum: 'generic__sum',
  // Country of Incorp(new column, no backend yet),
  tax_rate: 'generic__tax_rate',
  tax_sum: 'generic__tax_sum',
  status: 'generic__status',
};
