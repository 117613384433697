import { FC, useContext } from 'react';

import { BrandingContext } from 'contexts/BrandingContext';

import { StyledLogo, StyledVisuallyHidden } from './Logo.styled';

type TLogoProps = {
  className: string;
  small?: boolean;
};

export const Logo: FC<TLogoProps> = ({ className, small }) => {
  const { branding } = useContext(BrandingContext);
  const wlName = branding?.alias;
  const iconLogo =
    (small
      ? branding?.branding?.ui_settings?.icon_logo_small
      : branding?.branding?.ui_settings?.icon_logo) || '';

  const isVisible = Boolean(iconLogo.length);

  return !isVisible ? null : (
    <StyledLogo className={className} logoUrl={iconLogo} small={small}>
      <StyledVisuallyHidden className="VisuallyHidden">
        {wlName}
      </StyledVisuallyHidden>
    </StyledLogo>
  );
};
