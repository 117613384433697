import { AccountDataProvider } from 'contexts';

import { SettingsWithContext } from './SettingsWithContext';
import { SettingsDataProvider } from './contexts/SettingsDataContext';

export const Settings = () => (
  <SettingsDataProvider>
    <AccountDataProvider>
      <SettingsWithContext />
    </AccountDataProvider>
  </SettingsDataProvider>
);
