import { TFunction } from 'i18next';

import { EXANTE_SUPPORT_EMAIL } from 'constants/WHITELABEL';
import { TBrandingResponse } from 'services/branding';

export const getNoAccountsText = (
  branding: TBrandingResponse | null,
  t: TFunction,
) =>
  t('layout__you_have_no_available_accounts', {
    WL_SUPPORT_EMAIL: branding?.wl_emails_support || EXANTE_SUPPORT_EMAIL,
  });
