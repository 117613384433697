import { styled } from 'theme';

export const StyledComboWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledComboText = styled('div')`
  display: flex;
  flex-direction: column;

  font-family: ${({ theme }) => theme.font.main};
`;

export const StyledComboTextTitle = styled('span')`
  color: ${({ theme }) => theme.color.typo.action};
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const StyledComboTextDate = styled('span')`
  color: ${({ theme }) => theme.color.typo.promo};
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
`;
