import { IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledHeaderDepositIcon = styled(IconButton)`
  .IconButtonIconWrapper {
    gap: 8px;
  }

  color: ${({ theme }) => theme.color.typo.action};
  font-family: ${({ theme }) => theme.font.main};
  text-align: center;
  font-feature-settings: 'cpsp' on;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  &:hover {
    // we shouldn't change hover color in header
    color: ${({ theme }) => theme.color.typo.action};

    svg {
      color: ${({ theme }) => theme.color.typo.action};
    }

    & .HeaderDepositIcon {
      transform: scale(1.2);
      transition: transform 0.3s ease;
    }
  }
`;
