import { useContext, useEffect, useReducer } from 'react';

import { AccountDataContext, BrandingContext } from 'contexts';
import { useRouteAssociatedAccounts } from 'hooks';
import { accountsService } from 'services/accounts';
import { ACCOUNT_SELECTOR_POSITION } from 'services/branding/branding.constants';
import { useAppSelector } from 'store/hooks';
import { EStatus } from 'types/api';

import { AccessRightsAction } from './AccessRights.actions';
import { accessRightsInitialState } from './AccessRights.constants';
import { accessRightsReducer } from './AccessRights.reducer';
import { TAccessRightsReducer } from './AccessRights.types';

let controller: AbortController;

export const useAccessRights = () => {
  const accountCtx = useContext(AccountDataContext);
  const { branding } = useContext(BrandingContext);

  const data = useRouteAssociatedAccounts();

  const [state, dispatch] = useReducer<TAccessRightsReducer>(
    accessRightsReducer,
    accessRightsInitialState,
  );

  const { selectedAccountId, isAccountsLoading, isAccountsFirstLoading } =
    useAppSelector((rootState) => rootState.accounts);

  const isHidden =
    branding?.branding?.ui_settings?.account_selector_position ===
      ACCOUNT_SELECTOR_POSITION.HEADER || data.length === 1;

  const isLoading =
    state.fetchStatus === EStatus.Loading ||
    accountCtx.isLoading ||
    isAccountsLoading ||
    isAccountsFirstLoading;

  const handleFetchUsers = async () => {
    if (selectedAccountId && selectedAccountId !== 'all') {
      if (state.fetchStatus === EStatus.Loading && controller) {
        controller.abort();
      }

      controller = new AbortController();

      const offset = state.pageSize * state.pageIndex;

      try {
        dispatch({ type: AccessRightsAction.FetchUsersStart });
        const payload = await accountsService.getAccountUsers(
          selectedAccountId,
          offset,
          state.pageSize,
          controller.signal,
        );

        if (payload) {
          dispatch({ type: AccessRightsAction.FetchUsersSuccess, payload });
        }
      } catch (error) {
        dispatch({
          type: AccessRightsAction.FetchUsersError,
          payload: error as Error,
        });
      }
    } else {
      dispatch({ type: AccessRightsAction.Reset });
    }
  };

  const handleSetPage = (payload: number) => {
    dispatch({
      type: AccessRightsAction.SetPageIndex,
      payload,
    });
  };

  const handleSetPageSize = (payload: number) => {
    dispatch({
      type: AccessRightsAction.SetPageSize,
      payload,
    });
  };

  useEffect(() => {
    handleFetchUsers();
  }, [selectedAccountId, state.pageIndex, state.pageSize]);

  return {
    ...state,
    isHidden,
    isLoading,
    handleSetPage,
    handleSetPageSize,
  };
};
