import { StyledPageWrapper } from 'components/Main';
import { TermsWithContext } from 'pages/Terms/TermsWithContext';

import { TermsContextProvider } from './context';

export const Terms = () => (
  <StyledPageWrapper className="TermsWrapper">
    <TermsContextProvider>
      <TermsWithContext />
    </TermsContextProvider>
  </StyledPageWrapper>
);
