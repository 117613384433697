import { Children, ReactNode } from 'react';

import { AccountPositionsColumnWrapper } from './AccountPositions.styled';

interface IAccountPositionsProps {
  columnsSelect: ReactNode;
  tables: ReactNode;
}

export const AccountPositions = ({
  columnsSelect,
  tables,
}: IAccountPositionsProps) => {
  return (
    <>
      <AccountPositionsColumnWrapper className="AccountPositionsColumnWrapper">
        {columnsSelect}
      </AccountPositionsColumnWrapper>

      {Children.map(tables, (item, index) => (
        <div key={index}>{item}</div>
      ))}
    </>
  );
};
