import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { useTheme } from 'theme';

import { StyledLoginLogo } from '../../../../Login.styled';
import { StyledEmailVerification } from '../../EmailVerification.styled';

export const EmailVerificationSkeleton: FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <StyledEmailVerification className="EmailVerification">
      {isDesktop && <StyledLoginLogo className="LoginLogo" />}
      <Skeleton width={300} height={40} />
      <Skeleton height={100} />
      <Skeleton height={48} />
      <Skeleton height={48} />
    </StyledEmailVerification>
  );
};
