import { Breadcrumbs } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledBreadcrumbsWrapper = styled('div')`
  display: flex;
  order: 4;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-right: auto;
  padding: 0 24px;

  background: ${({ theme }) => theme.color.bg.basic};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
    order: 0;
    width: auto;
    height: auto;
    padding: 0;

    background: transparent;
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  color: ${({ theme }) => theme.color.typo.ghost} !important;
`;

export const StyledBreadcrumbsAnchor = styled('a')`
  text-decoration: none;
  font-size: 13px;
  color: ${({ theme }) => theme.color.typo.ghost};

  &:visited {
    color: ${({ theme }) => theme.color.typo.ghost};
  }
`;

export const StyledBreadcrumbsSkeletonContainer = styled('div')`
  display: flex;
  gap: 16px;
`;
