import cn from 'classnames';
import { FC, useContext } from 'react';
import { useMatches } from 'react-router-dom';
import { BreadcrumbValueType, Skeleton } from 'react-ui-kit-exante';

import { getBreadcrumbs } from 'components/Header/components/Breadcrumbs/Breadcrumbs.helpers';
import {
  StyledBreadcrumbs,
  StyledBreadcrumbsSkeletonContainer,
  StyledBreadcrumbsWrapper,
} from 'components/Header/components/Breadcrumbs/Breadcrumbs.styled';
import { BrandingContext, TranslationContext } from 'contexts';
import { useAppSelector } from 'store/hooks';
import { selectMenuData } from 'store/menu';

import { TBreadcrumbsProps } from './Breadcrumbs.types';

export const Breadcrumbs: FC<TBreadcrumbsProps> = ({ className }) => {
  const { branding } = useContext(BrandingContext);
  const menuData = useAppSelector(selectMenuData);

  const { t, isTranslationsLoading } = useContext(TranslationContext);

  const customHomeLink =
    branding?.branding?.breadcrumb_settings?.custom_home_link;
  const customHomeTitle = branding?.branding?.breadcrumb_settings?.home_name;

  let defaultUrl: string | null | undefined = null;

  if (customHomeLink) {
    defaultUrl = customHomeLink;
  } else if (branding?.branding?.default_redirect_url) {
    defaultUrl = branding?.branding?.default_redirect_url;
  } else {
    defaultUrl = menuData?.default_page;
  }

  const breadcrumbs: BreadcrumbValueType[] = getBreadcrumbs({
    defaultUrl,
    customHomeTitle,
    path: useMatches(),
    translate: t,
  });

  const wrapperCn = cn('BreadcrumbsWrapper', className);

  return (
    <StyledBreadcrumbsWrapper className={wrapperCn}>
      {isTranslationsLoading && (
        <StyledBreadcrumbsSkeletonContainer>
          {' '}
          <Skeleton width={80} height={16} animation="wave" variant="rounded" />
          <Skeleton width={80} height={16} animation="wave" variant="rounded" />
        </StyledBreadcrumbsSkeletonContainer>
      )}
      {!!menuData && !isTranslationsLoading && (
        <StyledBreadcrumbs value={breadcrumbs} />
      )}
    </StyledBreadcrumbsWrapper>
  );
};
