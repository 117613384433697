import { EActionTypes, TAction, TState } from './types';

export function reducer(state: TState, action: TAction) {
  switch (action.type) {
    case EActionTypes.SET_INTERESTS_LOADING:
      return { ...state, isInterestsLoading: action.payload };

    case EActionTypes.SET_INTERESTS_EMPTY:
      return { ...state, isInterestsEmpty: action.payload };

    default:
      return state;
  }
}
