import { useState } from 'react';

import {
  StyledDocumentUploadTooltipContainer,
  StyledDocumentUploadTooltipList,
  StyledDocumentUploadTooltipListOptional,
  StyledDocumentUploadTooltipListToggle,
  StyledDocumentUploadTooltipText,
} from '../../UploadGroup.styled';
import { TTooltipContentProps } from '../../UploadGroup.types';

export const ProofOfResidenceTooltipContent = ({
  t,
  branding,
}: TTooltipContentProps) => {
  const [optionalVisible, setOptionalVisible] = useState(false);

  const handleToggleClick = () => setOptionalVisible((isVisible) => !isVisible);

  return (
    <StyledDocumentUploadTooltipContainer className="DocumentUploadTooltipContainer">
      <StyledDocumentUploadTooltipText
        className="DocumentUploadTooltipText"
        dangerouslySetInnerHTML={{
          __html: t('layout__prof_of_residence__tooltip__custom_month', {
            MONTHS: branding?.legal_texts?.por_months,
          }),
        }}
      />
      <StyledDocumentUploadTooltipText className="DocumentUploadTooltipText">
        {t('layout__proof_of_residence__allowed_docs')}
        <StyledDocumentUploadTooltipList className="DocumentUploadTooltipList">
          <li>{t('layout__proof_of_residence__id_card')}</li>
          <li>{t('layout__proof_of_residence__utility_bill')}</li>
          <li>{t('layout__proof_of_residence__account_statement')}</li>
          <StyledDocumentUploadTooltipListOptional
            className="DocumentUploadTooltipListOptional"
            isVisible={optionalVisible}
          >
            <li>{t('layout__proof_of_residence__correspondence')}</li>
            <li>{t('layout__proof_of_residence__police_certificate')}</li>
            <li>{t('layout__proof_of_residence__rental_agreement')}</li>
            <li>{t('layout__proof_of_residence__work_permit')}</li>
          </StyledDocumentUploadTooltipListOptional>
        </StyledDocumentUploadTooltipList>

        <StyledDocumentUploadTooltipListToggle
          className="StyledDocumentUploadTooltipListToggle"
          onClick={handleToggleClick}
        >
          {optionalVisible
            ? t('layout__proof_of_residence__show_less_options')
            : t('layout__proof_of_residence__show_more_options')}
        </StyledDocumentUploadTooltipListToggle>
      </StyledDocumentUploadTooltipText>
    </StyledDocumentUploadTooltipContainer>
  );
};
