import { FC, useRef } from 'react';

import { Benefits } from './Benefits';
import { Features } from './Features';
import { TPartnerLandingProps } from './PartnerLanding.types';
import { SplashScreen } from './SplashScreen';

export const PartnerLanding: FC<TPartnerLandingProps> = ({
  onChooseButtonClick,
}) => {
  const benefitsElement = useRef<HTMLDivElement>(null);

  const scrollToBenefits = () => {
    benefitsElement.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <SplashScreen onChooseType={scrollToBenefits} />
      <Features onChooseType={scrollToBenefits} />
      <Benefits
        ref={benefitsElement}
        onChooseButtonClick={onChooseButtonClick}
      />
    </>
  );
};
