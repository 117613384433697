import { Dispatch } from 'react';

export type TState = {
  isInterestsLoading: boolean;
  isInterestsEmpty: boolean;
};

export type TContext = {
  state: TState;
  dispatch: Dispatch<TAction>;
};

export enum EActionTypes {
  SET_INTERESTS_LOADING = 'SET_INTERESTS_LOADING',
  SET_INTERESTS_EMPTY = 'SET_INTERESTS_EMPTY',
}

export type TSetInterestsLoadingAction = {
  type: EActionTypes.SET_INTERESTS_LOADING;
  payload: boolean;
};

export type TSetInterestsEmptyAction = {
  type: EActionTypes.SET_INTERESTS_EMPTY;
  payload: boolean;
};

export type TAction = TSetInterestsLoadingAction | TSetInterestsEmptyAction;
