import { Outlet } from 'react-router-dom';

import { PageWrapper } from 'components/PageWrapper';

import { ApiContextProvider } from './context';

export const Api = () => (
  <ApiContextProvider>
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  </ApiContextProvider>
);
