import { isFunction } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { TGetTopOffset } from 'hooks/useDynamicTableSize/useDynamicTableSize.types';

export const MIN_EXPANDED_TABLE_HEIGHT = 350;
const MARGIN_TABLE = 170;

export const useDynamicTableSize = (topOffset: number | TGetTopOffset) => {
  const [tableHeight, setTableHeight] = useState(0);
  const [tableWidth, setTableWidth] = useState(0);

  const updateTableSizes = useCallback(() => {
    const top = isFunction(topOffset) ? topOffset() : topOffset;

    let newTableHeight =
      window.innerHeight - (top + window.scrollY) - MARGIN_TABLE;

    newTableHeight =
      newTableHeight > MIN_EXPANDED_TABLE_HEIGHT
        ? newTableHeight
        : MIN_EXPANDED_TABLE_HEIGHT;

    const headerRow = document.querySelector('[role=row]');
    if (headerRow) {
      const newWidth = headerRow.getBoundingClientRect().width;
      setTableWidth(newWidth);
    }

    setTableHeight(newTableHeight);
  }, [topOffset]);

  useEffect(() => {
    updateTableSizes();

    window.addEventListener('resize', updateTableSizes);

    return () => {
      window.removeEventListener('resize', updateTableSizes);
    };
  }, [updateTableSizes]);

  return { tableWidth, tableHeight, updateTableSizes };
};
