import {
  Children,
  createContext,
  forwardRef,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  areEqual,
  ListChildComponentProps,
  VariableSizeList,
} from 'react-window';

import { TranslationContext } from 'contexts';

import { DEFAULT_AUTOCOMPLETE_ITEM_SIZE } from './AutocompleteInfiniteListbox.constants';
import { useResetCache } from './AutocompleteInfiniteListbox.hooks';
import { StyledMoreText } from './AutocompleteInfiniteListbox.styled';
import { TAutocompleteInfiniteListboxProps } from './AutocompleteInfiniteListbox.types';

const OuterElementContext = createContext({});

const OuterElement = forwardRef<HTMLDivElement>(
  function OuterElementType(props, ref) {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  },
);

export const RenderRow = (
  props: ListChildComponentProps &
    Pick<TAutocompleteInfiniteListboxProps, 'limit' | 'contentComponent'>,
) => {
  const { t } = useContext(TranslationContext);
  const { data, index, limit = 0, style, contentComponent: Component } = props;
  const dataSet: ReactElement | undefined = data[index];
  const inlineStyle = {
    ...style,
    top: style.top as number,
  };

  return limit && index === limit - 1 ? (
    <StyledMoreText style={inlineStyle} className="AutocompleteMoreText">
      {t(
        'ui_autocomplete_load_more_accounts',
        'Start typing to search more accounts...',
      )}
    </StyledMoreText>
  ) : (
    <li {...dataSet?.props} style={inlineStyle}>
      {Component ? (
        <Component index={index} label={dataSet?.props.children} />
      ) : (
        dataSet?.props.children
      )}
    </li>
  );
};

export const AutocompleteInfiniteListbox = memo(
  forwardRef<HTMLDivElement, TAutocompleteInfiniteListboxProps>(
    function ListboxComponent(props, ref) {
      const {
        children,
        limit = 0,
        itemSize: itemSizeProp,
        height: heightProp,
        contentComponent,
        ...other
      } = props;

      const itemData = useMemo((): ReactNode[] => {
        if (children === undefined || children === null) {
          return [];
        }

        const childrenArray = Children.toArray(children);

        return !limit ? childrenArray : childrenArray.slice(0, limit);
      }, [children]);

      const getHeight = () => {
        if (itemData.length > 8) {
          return 10 * DEFAULT_AUTOCOMPLETE_ITEM_SIZE;
        }

        return itemData
          .map(() => DEFAULT_AUTOCOMPLETE_ITEM_SIZE)
          .reduce((a, b) => a + b, 0);
      };

      const gridRef = useResetCache(itemData.length);

      const itemSize = useMemo(
        () =>
          typeof itemSizeProp === 'function'
            ? itemSizeProp
            : () => itemSizeProp || DEFAULT_AUTOCOMPLETE_ITEM_SIZE,
        [itemSizeProp],
      );

      const height = useMemo(
        () => heightProp || getHeight(),
        [heightProp, itemData],
      );

      const renderRow = useCallback(
        (rowProps: ListChildComponentProps<any>) =>
          RenderRow({
            ...rowProps,
            limit,
            contentComponent,
          }),
        [limit, contentComponent],
      );

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={other}>
            <VariableSizeList
              itemData={itemData}
              height={height}
              width="100%"
              ref={gridRef}
              outerElementType={OuterElement}
              itemSize={itemSize}
              itemCount={itemData.length}
            >
              {renderRow}
            </VariableSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    },
  ),
  areEqual,
);
