import { useContext, useEffect, useMemo } from 'react';
import { ColumnSelect, IColumn } from 'react-ui-kit-exante';

import {
  ACCOUNT_ASSET_ACCORDION_LS_KEY,
  ACCOUNT_ASSET_TABLE_ID,
  POSITIONS_CONFIG,
  POSITIONS_DEFAULT_DISPLAYED_COLUMN_KEYS,
  TABLE_IDS,
} from 'components/Positions/Positions.constants';
import {
  getAggregatedValueForTable,
  getColumns,
  normalizePositions,
  omitRequiredColumns,
  setAccordionLsPrefix,
  setTableIdsPrefix,
} from 'components/Positions/Positions.helpers';
import {
  TPositionsCache,
  TPositionsContainerProps,
} from 'components/Positions/Positions.types';
import { TranslationContext } from 'contexts';
import { useAccordion, useSelectedColumns } from 'hooks/index';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { TPosition } from 'types/accounts';

import { AccordionProvider, AccordionWithContext } from '../Accordion';
import { AccountPositions as AccountPositionsComponent } from '../AccountPositions';

import { AssetTable, AssetTitle } from './components';

export const Positions = ({
  positions,
  currency,
  idPrefix,
  closeDefault = false,
  caching,
  tableId,
}: TPositionsContainerProps) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDateFormatter(true);
  const columns = useMemo<IColumn<TPosition>[]>(
    (): IColumn<TPosition>[] =>
      getColumns({
        t,
        currency,
        currencyFormatter,
        dateFormatter,
      }),
    [currency, currentLanguage, t, dateFormatter],
  );

  const groupedPositions = useMemo(
    () => normalizePositions(positions),
    [positions],
  );

  const tableIdsWithPrefix = useMemo(
    () => setTableIdsPrefix(TABLE_IDS, idPrefix),
    [idPrefix],
  );

  const {
    selectedColumn,
    handleColumnChange,
    resetColumns,
    isColumnsLoading,
    isColumnsFirstLoading,
  } = useSelectedColumns({
    columns,
    tableIds: tableIdsWithPrefix,
    defaultDisplayedColumns: POSITIONS_DEFAULT_DISPLAYED_COLUMN_KEYS,
    defaultTableId: tableId,
  });

  const { accordion, handleAccordionChange, isAccordionLoading } = useAccordion(
    {
      items: POSITIONS_CONFIG,
      lsKey: setAccordionLsPrefix(ACCOUNT_ASSET_ACCORDION_LS_KEY, idPrefix),
      closeDefault,
    },
  );

  const providedAccordion = useMemo<typeof accordion>(() => {
    if (isAccordionLoading) {
      return caching?.get()?.accordion || accordion;
    }

    return accordion;
  }, [caching?.get, accordion, isAccordionLoading]);

  const displayedColumnKeys = useMemo<string[]>(() => {
    let selectedCol: typeof selectedColumn;

    if (caching && isColumnsFirstLoading) {
      const cacheSelectedColumn = caching.get()?.selectedColumn;

      if (cacheSelectedColumn) {
        selectedCol = cacheSelectedColumn;
      } else {
        selectedCol = selectedColumn;
      }
    } else {
      selectedCol = selectedColumn;
    }

    return Object.keys(selectedCol).filter((key) => {
      return Boolean(selectedCol[key]);
    });
  }, [selectedColumn, caching?.get, isColumnsFirstLoading]);

  const columnsWithoutRequiredFields = useMemo(
    () => omitRequiredColumns(columns),
    [columns],
  );

  const onColumnChange =
    caching && isColumnsFirstLoading ? () => {} : handleColumnChange;
  const onResetColumns =
    caching && isColumnsFirstLoading ? () => {} : resetColumns;

  const isTableLoading = caching
    ? !displayedColumnKeys.length
    : isColumnsLoading;

  const tables = useMemo(
    () =>
      groupedPositions.map(({ id, title, data, iconName }) => (
        <AccordionWithContext
          key={id}
          id={id}
          title={<AssetTitle title={t(title)} iconName={iconName} />}
          additionalContent={getAggregatedValueForTable(id, data, currency)}
          onChange={handleAccordionChange}
        >
          <AssetTable
            isLoading={isTableLoading}
            positions={data}
            columns={columns}
            displayedColumnKeys={displayedColumnKeys}
            tableId={`${ACCOUNT_ASSET_TABLE_ID}-${id}`}
            requiredColumnKeys={POSITIONS_CONFIG[id].requiredColumnKeys}
          />
        </AccordionWithContext>
      )),
    [
      columns,
      displayedColumnKeys,
      groupedPositions,
      handleAccordionChange,
      isColumnsLoading,
      t,
    ],
  );

  useEffect(() => {
    const cache: TPositionsCache = { accordion };

    if (!isColumnsLoading) {
      cache.selectedColumn = selectedColumn;
    }

    caching?.set(cache);
  }, [caching?.set, selectedColumn, accordion, isColumnsLoading]);

  return (
    <AccordionProvider value={providedAccordion}>
      <AccountPositionsComponent
        columnsSelect={
          <ColumnSelect
            columns={columnsWithoutRequiredFields}
            selected={displayedColumnKeys}
            onChange={onColumnChange}
            onReset={onResetColumns}
            translator={t}
          />
        }
        tables={tables}
      />
    </AccordionProvider>
  );
};
