import { useContext, useEffect, useMemo } from 'react';
import { useData } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { useAvailableTabs, useLogHandleTime } from 'hooks/index';
import { marginService, TAccountMargin } from 'services/margin';
import { MENU_ALIAS } from 'services/menu';

export type TUseMarginDataProps = {
  accountId: string | null;
  date: Date;
  currency: string;
};

export type TUseMarginDataValue = {
  isMarginDataLoading: boolean;
  isMarginDataFirstLoading: boolean;
  marginData: TAccountMargin | null;
  onUpdateData: () => Promise<void>;
};

export const useMarginData = ({
  accountId,
  date,
  currency,
}: TUseMarginDataProps): TUseMarginDataValue => {
  const { isTabExist } = useAvailableTabs();

  const { currentLanguage } = useContext(TranslationContext);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('margin-details');

  const fetchMargin = async () => {
    setStartHandleTime();
    const response = await marginService.getAccountMargin(
      accountId,
      date,
      currency,
      currentLanguage,
    );
    logHandleTime();
    return response;
  };

  const { isLoading, data, fetchData } = useData({
    onFetch: () => fetchMargin(),
  });

  const handleAccountIdChange = async () => {
    if (accountId && date && currency && isTabExist(MENU_ALIAS.MARGIN)) {
      await fetchData();
    }
  };

  useEffect(() => {
    handleAccountIdChange();
  }, [accountId, date, currency]);

  const onUpdateData = async () => {
    await fetchData();
  };
  return useMemo(
    () => ({
      isMarginDataLoading: isLoading,
      isMarginDataFirstLoading: isLoading && !data,
      marginData: data,
      onUpdateData,
    }),
    [isLoading, data],
  );
};
