import { FC, useContext, useState } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';

import { StyledPartnerLinksShortUtmWrapper } from './PartnerLinksShortUtm.styled';

export const PartnerLinksShortUtm: FC<{ shortUtm: string }> = ({
  shortUtm,
}) => {
  const { t } = useContext(TranslationContext);

  const [, copy] = useCopyToClipboard();
  const [copiedTooltipOpen, setCopiedTooltipOpen] = useState(false);

  const onCopyClick = () => {
    copy(shortUtm);
    setCopiedTooltipOpen(true);
  };

  return !shortUtm ? null : (
    <StyledPartnerLinksShortUtmWrapper className="PartnerLinksShortUtmWrapper">
      {shortUtm}
      <Tooltip
        title={t('generic__copy_link__copied')}
        disableFocusListener
        open={copiedTooltipOpen}
        placement="top"
        onClose={() => setCopiedTooltipOpen(false)}
      >
        <IconButton iconName="CopyIcon" iconSize={16} onClick={onCopyClick} />
      </Tooltip>
    </StyledPartnerLinksShortUtmWrapper>
  );
};
