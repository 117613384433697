import { FC, ReactNode, useCallback, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';
import { hasCyrillic } from 'helpers/hasCyrillic';
import { TPartnerLinkNew } from 'services/partner';

import {
  DEFAULT_PARTNER_URL,
  PartnersContext,
} from '../../../../Partner.constants';
import { preparePartnerUrl } from '../../../../Partner.helpers';

import {
  StyledPartnerLinksAddInput,
  StyledPartnerLinksAddLinkPartnerButton,
  StyledPartnerLinksAddWrapper,
} from './PartnerLinksAdd.styled';
import { TPartnerLinksAddProps } from './PartnerLinksAdd.types';

export const PartnerLinksAdd: FC<TPartnerLinksAddProps> = ({
  link,
  onChange,
}) => {
  const { t } = useContext(TranslationContext);
  const { partnerData } = useContext(PartnersContext);

  const updateField = useCallback(
    <T extends keyof TPartnerLinkNew>(field: T, value: TPartnerLinkNew[T]) => {
      onChange((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    [onChange],
  );

  const destinationUrlTooltip = useMemo((): ReactNode => {
    const url = partnerData?.defaultDestinationUrl || DEFAULT_PARTNER_URL;

    return (
      <Trans
        i18nKey="layout__partners__links__tooltip_destination_url"
        components={{
          a: (
            <StyledPartnerLinksAddLinkPartnerButton
              className="PartnerLinksAddLinkPartnerButton"
              href={new URL(url).toString()}
              target="_blank"
            />
          ),
        }}
        values={{
          WL_NAME: preparePartnerUrl(url),
        }}
      />
    );
  }, [partnerData?.defaultDestinationUrl]);

  return (
    <StyledPartnerLinksAddWrapper className="PartnerLinksEditActionWrapper">
      <StyledPartnerLinksAddInput
        label={t('layout__partners__links__destination_url')}
        value={link.site_url}
        onChange={(e) => updateField('site_url', e.target.value)}
        error={hasCyrillic(link.site_url || '')}
        iconRight={
          <Tooltip title={destinationUrlTooltip}>
            <IconButton iconName="InfoIcon" iconSize={16} />
          </Tooltip>
        }
        required
      />
      <StyledPartnerLinksAddInput
        label={t('layout__partners__links__name')}
        value={link.name}
        onChange={(e) => updateField('name', e.target.value)}
        error={hasCyrillic(link.name)}
        iconRight={
          <Tooltip title={t('layout__partners__links__tooltip_name')}>
            <IconButton iconName="InfoIcon" iconSize={16} />
          </Tooltip>
        }
        required
      />
      <StyledPartnerLinksAddInput
        label={t('layout__partners__links__source')}
        value={link.url}
        onChange={(e) => updateField('url', e.target.value)}
        iconRight={
          <Tooltip title={t('layout__partners__links__tooltip_source')}>
            <IconButton iconName="InfoIcon" iconSize={16} />
          </Tooltip>
        }
      />
    </StyledPartnerLinksAddWrapper>
  );
};
