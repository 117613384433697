import { styled } from 'theme';

export const StyledWithdrawalAmountFormContainer = styled('div')`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 334px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }

  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const StyledWithdrawalAmountFormHeader = styled('div')`
  display: flex;
  align-items: center;
`;

export const StyledWithdrawalAmountFormInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledWithdrawalAmountFormSecondaryText = styled('div')`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledWithdrawalAmountFormDescription = styled(
  StyledWithdrawalAmountFormSecondaryText,
)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const StyledWithdrawalAmountFormAvailableDescription = styled(
  StyledWithdrawalAmountFormSecondaryText,
)`
  padding-left: 12px;
`;
