import { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Select } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts/TranslationContext';

import { TFormSelectContainerProps } from './FormSelectContainer.types';

export const FormSelectContainer = ({
  fullWidth = true,
  label = '',
  disabled,
  name,
  options,
  iconLeft,
  readOnly,
  withNone,
  ...rest
}: TFormSelectContainerProps) => {
  const { t } = useContext(TranslationContext);
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const optionsFull = withNone
    ? [{ label: <em>{t('ui__select__none')}</em>, value: '' }, ...options]
    : options;

  const selectValue =
    !options.length || value === null || value === undefined ? '' : value;

  return (
    <Select
      error={!!error}
      fullWidth={fullWidth}
      label={label}
      message={error?.message}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={optionsFull}
      value={selectValue}
      disabled={disabled}
      iconLeft={iconLeft}
      readOnly={readOnly}
      {...rest}
    />
  );
};
