import { FC } from 'react';

import { AccountSettingsTooltip } from '../AccountSettingsTooltip';

import {
  StyledAccountSettingsText,
  StyledHeaderWithTooltipContainer,
} from './TableHeaderWithTooltip.styled';
import { TTableHeaderWithTooltip } from './TableHeaderWithTooltip.types';

export const TableHeaderWithTooltip: FC<TTableHeaderWithTooltip> = ({
  tooltipText,
  headerText,
}) => {
  return (
    <StyledHeaderWithTooltipContainer className="HeaderWithTooltipContainer">
      <StyledAccountSettingsText
        className="AccountSettingsText"
        dangerouslySetInnerHTML={{ __html: headerText }}
      />
      <AccountSettingsTooltip tooltipText={tooltipText} />
    </StyledHeaderWithTooltipContainer>
  );
};
