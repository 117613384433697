import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAccessRightsContainer = styled('div')<{ compact?: boolean }>`
  display: grid;
  grid-gap: 24px;
  padding: ${({ compact }) => (compact ? '0 0 24px 0' : '24px 0')};
`;

export const StyledAccountSelector = styled(Panel)`
  padding: 0;
  overflow: hidden;

  .PanelHeading {
    display: none;
  }
`;

export const StyledFooter = styled('div')`
  font-size: 15px;
  padding: 0 24px;
`;

export const StyledLink = styled('a')`
  color: ${(props) => props.theme.color.typo.action};
  text-decoration: none;
`;
