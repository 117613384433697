import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRouteError } from 'react-router-dom';

import { ErrorFallback } from 'components/ErrorFallback';

export const RouterErrorBoundary: FC = () => {
  console.error('router error', useRouteError());

  return <ErrorBoundary FallbackComponent={ErrorFallback} />;
};
