import { Skeleton } from 'react-ui-kit-exante';

import { StyledSignupForm, StyledSignupFormRow } from '../../Signup.styled';

export const SignupFormSkeleton = () => {
  return (
    <StyledSignupForm className="SignupForm">
      <StyledSignupFormRow className="SignupFormRow">
        <Skeleton width="100%" height={48} />
        <Skeleton width="100%" height={48} />
      </StyledSignupFormRow>

      <StyledSignupFormRow className="SignupFormRow">
        <Skeleton width="100%" height={48} />
        <Skeleton width="100%" height={48} />
      </StyledSignupFormRow>

      <Skeleton width="100%" height={48} />
    </StyledSignupForm>
  );
};
