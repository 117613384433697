import { FC, useContext, useState } from 'react';

import { TranslationContext } from 'contexts';
import { AccountDataContext } from 'contexts/AccountDataContext/AccountDataContext';
import {
  DepositContext,
  DepositCryptoContext,
} from 'pages/Portfolio/tabs/Deposit/contexts';

import { DepositCash } from '../DepositCash/components';
import { DepositCrypto } from '../DepositCrypto';

import {
  StyledDepositMessage,
  StyledDepositTypeSwitch,
  StyledDepositWrapper,
  StyledToggleButtonGroup,
} from './DepositWrapper.styled';
import { DepositType, TDepositTypeOption } from './DepositWrapper.types';

export const DepositWrapper: FC = () => {
  const { t } = useContext(TranslationContext);

  const { isLoading: isLoadingCrypto, currencies: currenciesCrypto } =
    useContext(DepositCryptoContext);

  const { isLoading: isLoadingDeposit, currencies } =
    useContext(DepositContext);

  const { isLoading: isAccountDataLoading } = useContext(AccountDataContext);

  const [typeSelected, setTypeSelected] = useState<DepositType | null>(null);

  const handleChange = (value: any) => {
    setTypeSelected(value);
  };

  const options: TDepositTypeOption[] = [];

  if (!isLoadingCrypto && currencies.list.length) {
    options.push({
      value: DepositType.Cash,
      label: t('layout__deposit__type_cash'),
    });
  }

  if (!isLoadingDeposit && currenciesCrypto.list.length) {
    options.push({
      value: DepositType.Crypto,
      label: t('layout__deposit__type_crypto'),
    });
  }

  const isLoading = isLoadingDeposit || isLoadingCrypto || isAccountDataLoading;

  const notAvailable = !isLoading && !options.length;
  const showTypeSwitch = options.length > 1;

  const type = typeSelected || options[0]?.value || DepositType.Cash;

  return (
    <StyledDepositWrapper className="DepositWrapper">
      {notAvailable ? (
        <StyledDepositMessage className="DepositMessage">
          {t('layout__deposit__not_available')}
        </StyledDepositMessage>
      ) : (
        <>
          {showTypeSwitch && (
            <StyledDepositTypeSwitch className="DepositTypeSwitch">
              <StyledToggleButtonGroup
                className="ToggleButtonGroup"
                onChange={handleChange}
                options={options}
                value={type}
                exclusive
                fullWidth
                data-test-id="deposit__button_group"
              />
            </StyledDepositTypeSwitch>
          )}
          {type === DepositType.Cash ? <DepositCash /> : <DepositCrypto />}
        </>
      )}
    </StyledDepositWrapper>
  );
};
