import {
  AccountRoundedIcon,
  AgreementIcon,
  AnalysisIcon,
  ArrowsLeftRight,
  BalancesIcon,
  CardSettingsIcon,
  CommissionsIcon,
  CustomReportsIcon,
  DashboardIcon,
  DepositIcon,
  ExchangeIcon,
  GeneratedReportsIcon,
  InterestsIcon,
  LockFullIcon,
  MarginIcon,
  OvernightsIcon,
  PeriodicReportsIcon,
  ReportsIcon,
  ShieldIcon,
  ShowAllowanceStatusIcon,
  SvgIconComponentType,
  TaxReportsIcon,
  TotalIcon,
  TradesIcon,
  TransactionIcon,
  UploadIcon,
  UserAddIcon,
  UsersIcon,
} from 'assets/icons';
import { TMenuAliasValue } from 'services/menu';
import {
  PORTFOLIO_TAB_ID,
  TERMS_TAB_ID,
  SECURITY_TAB_ID,
  SETTINGS_TAB_ID,
  REPORTS_TAB_ID,
} from 'services/tabs';

export const READY_FOR_PRODUCTION_TABS: TMenuAliasValue[] = [
  PORTFOLIO_TAB_ID.ANALYSIS,
  PORTFOLIO_TAB_ID.DEPOSIT,
  PORTFOLIO_TAB_ID.EXCHANGE,
  PORTFOLIO_TAB_ID.TRANSFER,
  PORTFOLIO_TAB_ID.MARGIN,
  PORTFOLIO_TAB_ID.SUMMARY,
  PORTFOLIO_TAB_ID.TRADES,
  PORTFOLIO_TAB_ID.TRANSACTIONS,
  SECURITY_TAB_ID.ACCESS_RIGHTS,
  SECURITY_TAB_ID.MFA,
  SECURITY_TAB_ID.EXTERNAL_ACCOUNTS,
  SECURITY_TAB_ID.PASSWORD,
  SETTINGS_TAB_ID.AGREEMENTS,
  SETTINGS_TAB_ID.DOCUMENTS,
  SETTINGS_TAB_ID.PROFILE,
  SETTINGS_TAB_ID.SERVICES,
  PORTFOLIO_TAB_ID.CARD_SETTINGS,
  TERMS_TAB_ID.COMMISSIONS,
  TERMS_TAB_ID.INTERESTS,
  TERMS_TAB_ID.OVERNIGHTS,
  TERMS_TAB_ID.SHORT_ALLOWANCE,
  REPORTS_TAB_ID.PERIODIC_REPORTS,
  REPORTS_TAB_ID.TAX_REPORTS,
  REPORTS_TAB_ID.CUSTOM_REPORTS,
  REPORTS_TAB_ID.GENERATED_REPORTS,
];

export const tabIconMap: Record<string, SvgIconComponentType | string> = {
  'cards-gps': CardSettingsIcon,
  transfers: ArrowsLeftRight,
  access_rights: UsersIcon,
  account_summary: TotalIcon,
  account_transactions: TransactionIcon,
  agreements: AgreementIcon,
  analysis: AnalysisIcon,
  balances: BalancesIcon,
  commissions: CommissionsIcon,
  customReports: CustomReportsIcon,
  deposit: DepositIcon,
  documents: UploadIcon,
  exchange: ExchangeIcon,
  external_accounts: UserAddIcon,
  generatedReports: GeneratedReportsIcon,
  interests: InterestsIcon,
  margin: MarginIcon,
  mfa: LockFullIcon,
  overnights: OvernightsIcon,
  password: ShieldIcon,
  performancereport: AnalysisIcon,
  periodicReports: PeriodicReportsIcon,
  profile: AccountRoundedIcon,
  reports: ReportsIcon,
  services: DashboardIcon,
  short_allowance: ShowAllowanceStatusIcon,
  taxReports: TaxReportsIcon,
  trades: TradesIcon,
  withdrawal: ArrowsLeftRight,
};
