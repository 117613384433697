import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TApiDocsResponse, TFAQResponse, TManualsResponse } from './help.types';

class HelpService extends ApiRequest {
  async getApiDocs() {
    try {
      const { data } = await this.fetch<TApiDocsResponse>({
        url: `/clientsarea/help/api/docs/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getApiDocs error: ', error);
      return null;
    }
  }

  async getManuals(lang: string) {
    try {
      const { data } = await this.fetch<TManualsResponse>({
        url: `/${lang}/clientsarea/spa-layout/?name=help`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getManuals error: ', error);
      return null;
    }
  }

  async getFAQ() {
    try {
      const { data } = await this.fetch<TFAQResponse>({
        url: `/api/faq/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getQuestions error: ', error);
      return null;
    }
  }
}

export const helpService = new HelpService();
