import { TFunction } from 'i18next';
import { ICellValue, IRowExpand } from 'react-ui-kit-exante';

import { NameCell } from 'components/Cells';
import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import {
  formatCurrencyUnrounded,
  TCreateCurrencyFormatter,
} from 'helpers/formatters';
import { TTreeResponseTreeStructure } from 'services/instrumentsTree/instrumentsTree.types';

export const getColumns = (
  t: TFunction,
  loadingInstruments: Set<string>,
  currencyFormatter: TCreateCurrencyFormatter,
) => [
  {
    Header: t('symbol_id'),
    accessor: 'displayName',
    disableSortBy: true,
    id: 'name',
    Cell: ({ row }: { row: IRowExpand<TTreeResponseTreeStructure> }) => (
      <NameCell
        className="TermsNameCell"
        row={row}
        loadingInstruments={loadingInstruments}
      />
    ),
    minWidth: 240,
  },
  {
    Header: t('generic__commission_percents'),
    accessor: 'commissionPercent',
    disableSortBy: true,
    Cell: ({
      row: { values, original },
    }: ICellValue<TTreeResponseTreeStructure>) => {
      return original.rowType === 'node'
        ? ''
        : Number((values.commissionPercent * 100).toFixed(3)) ||
            DEFAULT_EMPTY_PLACEHOLDER;
    },
  },
  {
    Header: t('generic__commission_fixed'),
    accessor: 'commissionFixed',
    disableSortBy: true,
    Cell: ({
      row: { values, original },
    }: ICellValue<TTreeResponseTreeStructure>) => {
      if (original.rowType === 'node') {
        return '';
      }
      return values.commissionFixed
        ? `${formatCurrencyUnrounded(
            currencyFormatter,
            values.commissionFixed,
          )} ${original.currency || ''}`
        : DEFAULT_EMPTY_PLACEHOLDER;
    },
  },
  {
    Header: t('layout__trees_commissions_tree__min_commission'),
    accessor: 'minimumFee',
    disableSortBy: true,
    Cell: ({
      row: { values, original },
    }: ICellValue<TTreeResponseTreeStructure>) => {
      if (original.rowType === 'node') {
        return '';
      }
      return values.minimumFee
        ? `${formatCurrencyUnrounded(currencyFormatter, values.minimumFee)} ${
            original.currency || ''
          }`
        : DEFAULT_EMPTY_PLACEHOLDER;
    },
  },
];
