import { FC } from 'react';

import { StyledPageWrapper } from 'components/PageWrapper/PageWrapper.styled';
import { ReportsIndex } from 'pages/Reports/pages/ReportsIndex';

import { ReportsProvider } from './contexts/ReportsContext';

export const Reports: FC = () => {
  return (
    <ReportsProvider>
      <StyledPageWrapper className="ReportsWrapper">
        <ReportsIndex />
      </StyledPageWrapper>
    </ReportsProvider>
  );
};
