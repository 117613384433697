import { Input } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAffidavitFormSignWrapper = styled('div')`
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding-bottom: 48px;

  &::after {
    content: '';
    width: 100%;
    height: 50px;

    position: absolute;
    left: 0;
    bottom: 46px;

    background: linear-gradient(
      transparent,
      ${({ theme }) => theme.color.bg.primary}
    );
  }
`;

export const StyledAffidavitFormSignText = styled('p')`
  margin: 0;

  color: ${({ theme }) => theme.color.typo.promo};
  font-size: 13px;
  font-family: ${({ theme }) => theme.font.main};
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledAffidavitFormSignOptions = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledAffidavitFormSignInput = styled(Input)`
  & .MuiInputBase-input {
    padding: 0;
    min-height: 50px;
  }
`;
