import { blockNonNativeProps, css } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { ArrowDownIcon } from '../../assets/icons';

import { IAccordionArrowProps } from './types';

export const AccordionWrapper = styled('div')`
  width: 100%;
`;

export const AccordionButton = styled('button')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  cursor: pointer;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.color.bg.primary};
  border-top: 1px solid ${({ theme }) => theme.color.bg.basic};
  color: ${({ theme }) => theme.color.typo.primary};
  font-family: ${({ theme }) => theme.font.main};
`;

export const AccordionArrow = styled(ArrowDownIcon, {
  shouldForwardProp: blockNonNativeProps(['isActive']),
})<IAccordionArrowProps>`
  color: ${({ theme }) => theme.color.typo.ghost};
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-right: 16px;
  }

  ${({ isActive }) => {
    if (!isActive) {
      return css`
        transform: rotate(-90deg);
      `;
    }
    return '';
  }}
`;

export const StyledAccordionTitle = styled('div')`
  display: flex;
  align-items: center;
`;
