import { chatService } from 'services/chat';
import { sessionStorageService } from 'services/sessionStorage';

import {
  SESSION_STORAGE_KEY,
  TOKEN_DEFAULT_EXPIRATION,
  TOKEN_TYPE,
} from './Chat.constants';
import { TLiveChatToken } from './Chat.types';

const chatSessionStorageService = sessionStorageService(SESSION_STORAGE_KEY);

const getCustomerToken = async (): Promise<TLiveChatToken | null> => {
  const chatInfo = await chatService.getLiveChatInfo(false);

  if (!chatInfo) {
    return null;
  }

  const { chat_id: chatId, chat_secret: chatSecret, expiration } = chatInfo;

  const expiresIn = expiration || TOKEN_DEFAULT_EXPIRATION;

  let token: any = chatSessionStorageService.get();

  if (token) {
    token = JSON.parse(token);
  }

  return {
    expiresIn,
    entityId: chatId || '',
    accessToken: chatSecret || '',
    tokenType: TOKEN_TYPE,
    creationDate: token?.creationDate || Date.now(),
  };
};

export const customIdentityProvider = () => {
  let tokenPromise: Promise<TLiveChatToken | null> | null = null;
  let cachedToken: TLiveChatToken | null = null;
  const token = window.sessionStorage.getItem(SESSION_STORAGE_KEY);

  const isExpired = ({ creationDate, expiresIn }: TLiveChatToken) =>
    Date.now() >= creationDate + expiresIn;

  if (token) {
    cachedToken = JSON.parse(token);
  }

  const getFreshToken = () => {
    if (tokenPromise) {
      return tokenPromise;
    }

    tokenPromise = getCustomerToken()
      .then((response) => {
        tokenPromise = null;
        cachedToken = response;

        chatSessionStorageService.set(JSON.stringify(response));

        return response;
      })
      .catch((error) => {
        throw error;
      });

    return tokenPromise;
  };

  const getToken = () => {
    if (tokenPromise) {
      return tokenPromise;
    }

    if (cachedToken && !isExpired(cachedToken)) {
      return Promise.resolve(cachedToken);
    }

    return getFreshToken();
  };

  const hasToken = () => Promise.resolve(!!cachedToken);

  const invalidate = () => {
    cachedToken = null;
    chatSessionStorageService.remove();
  };

  return {
    getToken,
    getFreshToken,
    hasToken,
    invalidate,
  };
};
