import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';

import { TCreateCurrencyFormatter } from './currencyFormatter';

export function formatCurrencyUnrounded(
  formatter: TCreateCurrencyFormatter,
  value?: string | number | null,
): string {
  const numberedValue = Number(value);

  if (Number.isNaN(numberedValue)) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  return formatter.format(numberedValue);
}
