import { FC } from 'react';
import { Link } from 'react-router-dom';

import { AnchorNative } from '../AnchorNative';

import { AnchorProps } from './Anchor.types';

export const Anchor: FC<AnchorProps> = (props) => {
  const { to, href } = props;
  const computedTo = to || href || '';

  const isExternalLink = computedTo && computedTo.startsWith('http');

  return isExternalLink ? (
    <AnchorNative {...props} />
  ) : (
    <Link to={computedTo}>
      <AnchorNative {...props} />
    </Link>
  );
};
