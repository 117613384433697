import { FC } from 'react';

import { StyledPageWrapper } from 'components/Main';
import { TChildren } from 'types/TChildren';

import { StyledPartnerKycPanel } from './PartnerKycWrapper.styled';

export const PartnerKycWrapper: FC<TChildren> = ({ children }) => (
  <StyledPageWrapper className="PartnerPageWrapper">
    <StyledPartnerKycPanel>{children}</StyledPartnerKycPanel>
  </StyledPageWrapper>
);
