import { FC } from 'react';

import {
  CashConversionProvider,
  DepositCryptoProvider,
  DepositProvider,
} from 'pages/Portfolio/tabs/Deposit/contexts';

import { DepositWrapper } from './components/DepositWrapper';

export const Deposit: FC = () => {
  return (
    <DepositProvider>
      <DepositCryptoProvider>
        <CashConversionProvider>
          <DepositWrapper />
        </CashConversionProvider>
      </DepositCryptoProvider>
    </DepositProvider>
  );
};
