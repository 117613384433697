import { styled } from 'theme';

export const StyledGeneratedReportsFilters = styled('div')`
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export const StyledGeneratedReportsDateFilters = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

export const StyledGeneratedReportsDateLine = styled('div')`
  width: 16px;
  height: 1px;
  background-color: ${({ theme }) => theme.color.line.primary};
`;
