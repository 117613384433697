import { Outlet } from 'react-router-dom';

import { StyledPageWrapper } from 'components/Main';

import {
  StyledLoginContent,
  StyledLoginPanel,
  StyledLoginWrapper,
} from './Login.styled';

export const Login = () => {
  return (
    <StyledPageWrapper className="LoginPageWrapper">
      <StyledLoginPanel className="LoginPanel">
        <StyledLoginWrapper className="LoginWrapper">
          <StyledLoginContent className="LoginContent">
            <Outlet />
          </StyledLoginContent>
        </StyledLoginWrapper>
      </StyledLoginPanel>
    </StyledPageWrapper>
  );
};
