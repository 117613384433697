import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TBrandingResponse } from './branding.types';

class BrandingService extends ApiRequest {
  async getBranding(lang = 'en', wl = 'my'): Promise<TBrandingResponse | null> {
    try {
      const { data } = await this.fetch<TBrandingResponse>({
        url: `/${lang}/clientsarea/rest/branding/${wl}/`,
      });

      return data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('getBranding', error);
      return null;
    }
  }
}

export const brandingService = new BrandingService();
