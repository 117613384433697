import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledTabsSkeletonWrapper = styled('div')`
  display: flex;
  gap: 24px;
  min-height: 40px;

  border-bottom: 1px solid ${({ theme }) => theme.color.bg.basic};
`;

export const StyledTabSkeleton = styled(Skeleton)`
  width: 56px;
  height: 24px;
`;
