import { TFunction } from 'i18next';
import { IColumn } from 'react-ui-kit-exante';

import { TPartnerStatisticsRecord } from 'services/partner';

export const getPartnerStatisticsColumns = ({
  t,
}: {
  t: TFunction;
}): Array<IColumn<TPartnerStatisticsRecord>> => [
  {
    id: 'user',
    Header: t('layout__partners__statistics__user'),
    accessor: 'user',
  },
  {
    id: 'client_id',
    Header: t('layout__partners__statistics__clientid'),
    accessor: 'client_id',
  },
  {
    id: 'created',
    Header: t('layout__partners__statistics__registration_date'),
    accessor: 'created',
    width: 190,
  },
  {
    id: 'first_trade',
    Header: t('layout__partners__statistics__first_trade'),
    accessor: 'first_trade',
  },
  {
    id: 'link',
    Header: t('layout__partners__statistics__link'),
    accessor: 'source',
  },
  {
    id: 'reached_min_deposit',
    Header: t('layout__partners__statistics__min_deposit'),
    accessor: 'reached_min_deposit',
  },
  {
    id: 'funds_received_date',
    Header: t('layout__partners__funds_received_date'),
    accessor: 'funds_received_date',
  },
];

export const getPartnersDateForDeps = (date: Date | null) =>
  !date ? date : date.toISOString().split('T')[0];
