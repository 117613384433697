import { cloneDeep } from 'lodash';
import {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CANCELLED_ERROR } from 'constants/api';
import {
  ActionTypes,
  TActionsState,
} from 'modules/instrumentsTree/context/InstrumentsTreeContext.types';
import { instrumentsTreeService } from 'services/instrumentsTree/instrumentsTree';
import {
  TTreeModule,
  TTreeResponseTreeStructure,
} from 'services/instrumentsTree/instrumentsTree.types';

import {
  TMatchIdAndPositionInTable,
  TSetTableData,
} from '../../InstrumentsTree.types';

import { TUseShortAllowanceStatusTreeParams } from './useInstrumentsTree.types';

export const useInstrumentsTree = (
  params: TUseShortAllowanceStatusTreeParams,
  setTableData: TSetTableData,
  updateMatchIdAndPositionInSearchTable: (
    newData: TMatchIdAndPositionInTable,
  ) => void,
  updateMatchIdAndPositionInNonSearchTable: (
    newData: TMatchIdAndPositionInTable,
  ) => void,
  dispatch: Dispatch<TActionsState>,
  module: TTreeModule,
) => {
  const abortController = useRef(new AbortController());
  const currentTree = useRef<TTreeResponseTreeStructure[]>([]);
  const initialTree = useRef<TTreeResponseTreeStructure[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getTree = useCallback(
    async (search?: string) => {
      if (params.accountId && params.username) {
        abortController.current.abort();
        abortController.current = new AbortController();
        setIsLoading(true);
        const response = await instrumentsTreeService.getTree(
          {
            ...params,
            search,
            options: {
              signal: abortController.current.signal,
            },
          },
          module,
        );

        if (response === CANCELLED_ERROR) {
          return;
        }

        if (response) {
          dispatch({
            type: ActionTypes.SET_TREE_WAS_FILTERED,
            payload: !!search,
          });

          updateMatchIdAndPositionInNonSearchTable(
            response.matchIdAndPositionInNonSearchTable,
          );
          updateMatchIdAndPositionInSearchTable(
            response.matchIdAndPositionInNonSearchTable,
          );
          currentTree.current = cloneDeep(response.tree);
          if (!search && !initialTree.current?.length) {
            initialTree.current = cloneDeep(response.tree);
          }
          setTableData(response.tree);
        }
        setIsLoading(false);
      }
    },
    [params],
  );

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return useMemo(
    () => ({ fetchTree: getTree, isLoading, currentTree, initialTree }),
    [getTree, isLoading, currentTree],
  );
};
