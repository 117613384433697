import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TUserDetails,
  TUserDetailsResponse,
  TUserPreferred,
  TUserPreferredResponse,
} from './userDetails.types';

class UserDetailsService extends ApiRequest {
  async getUserDetails(lang = 'en'): Promise<TUserDetails | null> {
    try {
      const { data: response } = await this.fetch<TUserDetailsResponse>({
        url: `/${lang}/clientsarea/spa-layout/?name=user-details`,
      });

      const { 'user-details': userDetails } = response;

      return userDetails;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getUsername', error);
      return null;
    }
  }

  async getUserPreferred(): Promise<TUserPreferred | null> {
    try {
      const { data: response } = await this.fetch<TUserPreferredResponse>({
        url: '/clientsarea/user-preferred/',
      });

      const {
        preferable_account: preferableAccount,
        preferable_currency: preferableCurrency,
      } = response;
      return {
        preferableAccount,
        preferableCurrency,
      };
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getUserPreferred', error);
      return null;
    }
  }

  async setUserPreferred(data: TUserPreferred): Promise<TUserPreferred | null> {
    try {
      const reqData = {
        preferable_account: data.preferableAccount,
        preferable_currency: data.preferableCurrency,
      };

      const { data: response } = await this.fetch<TUserPreferredResponse>({
        url: '/clientsarea/user-preferred/',
        data: reqData,
        method: 'PUT',
      });

      const {
        preferable_account: preferableAccount,
        preferable_currency: preferableCurrency,
      } = response;
      return {
        preferableAccount,
        preferableCurrency,
      };
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getUserPreferred', error);
      return null;
    }
  }
}

export const userDetailsService = new UserDetailsService();
