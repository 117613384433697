import { FC } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { ReportType } from 'services/reports/reports.types';

import { MAX_ACCOUNTS_LENGTH } from './ReportAccounts.constants';
import { StyledReportAccountList } from './ReportAccounts.styled';
import { TReportAccountsProps } from './ReportAccounts.types';

export const ReportAccounts: FC<TReportAccountsProps> = ({
  report,
  isMobile,
}) => {
  const value = report.accounts.join(', ');

  const hasWithdrawals = report.items.find(
    (item) => item.report_type === ReportType.SubuserWithdrawals,
  );

  if (hasWithdrawals) {
    return (
      <StyledReportAccountList
        className="ReportAccountList"
        isMobile={isMobile}
      >
        {DEFAULT_EMPTY_PLACEHOLDER}
      </StyledReportAccountList>
    );
  }

  return (
    <StyledReportAccountList className="ReportAccountList" isMobile={isMobile}>
      {report.accounts.length > MAX_ACCOUNTS_LENGTH ? (
        <Tooltip title={value}>
          <span>{`${report.accounts
            .slice(0, MAX_ACCOUNTS_LENGTH)
            .join(', ')}...`}</span>
        </Tooltip>
      ) : (
        value
      )}
    </StyledReportAccountList>
  );
};
