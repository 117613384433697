import { DisableByCodeActions } from './useDisableByCode.actions';
import {
  TDisableByCodeReducer,
  TDisableByCodeState,
} from './useDisableByCode.types';

export const useDisableByCodeReducer: TDisableByCodeReducer = (
  state,
  action,
): TDisableByCodeState => {
  switch (action.type) {
    case DisableByCodeActions.OnCodeChange:
      return {
        ...state,
        code: action.payload,
        verifyErrorMessage: '',
      };

    case DisableByCodeActions.VerifyError:
      return {
        ...state,
        isVerifying: false,
        verifyErrorMessage: action.payload,
      };

    case DisableByCodeActions.VerifyStart:
      return {
        ...state,
        verifyErrorMessage: '',
        isVerifying: true,
      };

    case DisableByCodeActions.VerifySuccess:
      return {
        ...state,
        isVerifying: false,
      };

    case DisableByCodeActions.RequestStart:
      return {
        ...state,
        code: '',
        isRequesting: true,
        verifyErrorMessage: '',
        requestErrorMessage: '',
      };

    case DisableByCodeActions.RequestError:
      return {
        ...state,
        isRequesting: false,
        requestErrorMessage: action.payload,
      };

    case DisableByCodeActions.RequestSuccess:
      return {
        ...state,
        isRequesting: false,
        requestErrorMessage: '',
      };

    default:
      return state;
  }
};
