import { prepareToQueryParams } from 'helpers/prepareToQueryParams';
import {
  TFetchTransactionsHelperValue,
  TTransactionsFetchHelperProps,
} from 'pages/Portfolio/tabs/Transactions/Transactions.types';
import { columnKeys } from 'pages/Portfolio/tabs/Transactions/columns';
import { transactionsService } from 'services/transactions';

export const fetchTransactions = async ({
  params,
  accountId,
  tabAvailable,
}: TTransactionsFetchHelperProps): Promise<TFetchTransactionsHelperValue | null> => {
  const { timestamp, sorting, limit, skip, operationType } = params;

  if (!tabAvailable || !accountId || !Array.isArray(sorting)) {
    return null;
  }

  const {
    iDisplayStart: offset,
    iDisplayLength: size,
    operationTypes,
    dateRange: [dateFrom, dateTo],
    iSortCol_0: sortingColumn,
    sSortDir_0: sortingDirection,
  } = prepareToQueryParams({
    accountId,
    dateRange: timestamp,
    sorting,
    columnKeys: [...columnKeys],
    limit,
    skip,
    operationTypes: operationType as string[],
  });

  const tempOperationTypes = Array.isArray(operationTypes)
    ? operationTypes
    : [operationTypes];

  const transactions = await transactionsService.getTransactions({
    offset,
    size,
    accountId,
    operationTypes: tempOperationTypes,
    dateFrom,
    dateTo,
    sortingDirection,
    sortingColumn,
  });

  if (!transactions) {
    return null;
  }

  const { data, total, available_types: availableTypes } = transactions;

  return {
    data: data || null,
    total,
    operationTypes: availableTypes || [],
  };
};

export function multiSelectFilterToArray<T>(
  multiSelectFilter: undefined | T | T[],
): T[] {
  if (!multiSelectFilter) {
    return [];
  }

  if (Array.isArray(multiSelectFilter)) {
    return multiSelectFilter;
  }

  return [multiSelectFilter];
}
