import { useContext, useMemo } from 'react';

import { WHITESPACE_NON_BREAKING } from 'constants/common';
import { formatPhoneNumber } from 'helpers/formatters/formatPhoneNumber';

import { UserDetailsContext } from '../contexts';

export const useUserDetails = () => {
  const { userDetails } = useContext(UserDetailsContext);

  const fullPhone = useMemo(() => {
    if (!userDetails?.info) {
      return '';
    }

    const phoneField = userDetails.info.diff.fields?.find(
      (item) => item.field_name === 'full_phone',
    );
    if (!phoneField) {
      return '';
    }

    return phoneField.left;
  }, [userDetails?.info]);

  const [countryCode, phoneNumber] = fullPhone.split(' ');

  return {
    email: userDetails?.info?.current.email,
    countryCode,
    phoneNumber: formatPhoneNumber(phoneNumber, WHITESPACE_NON_BREAKING),
  };
};
