// function for computed width of Y axis, because values may be from 1 digits to 11-12 digits

export const getYWidth = <T extends Record<string, any>[]>(
  data: T,
  keys: string[],
) => {
  const width = data
    .map((i) => keys.map((key) => i[key]))
    .reduce((acc, curr) => {
      const max = Math.max(
        ...curr.map((item) => String(item?.toFixed()).length),
      );
      return acc > max ? acc : max;
    }, 0);

  return width * 10;
};
