import { Tooltip } from '@mui/material';
import {
  ChangeEvent,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Input, Notification } from 'react-ui-kit-exante';

import {
  CheckmarkIcon,
  DownloadCompactIcon,
  ExportCompactIcon,
  GenerateDocumentIcon,
  RejectedIcon,
  ScalesIcon,
} from 'assets/icons';
import { TranslationContext } from 'contexts';
import { FeeSetupStatuses } from 'pages/AssetManagementSetup/AseetManagementSetup.constants';
import { setupsContext } from 'pages/AssetManagementSetup/components/AssetManagementSetupCreation/contexts';
import { assetManagementService } from 'services/assetManagement';

import {
  SetupItemButton,
  SetupItemProgressBar,
  StyledSetupItem,
  StyledSetupItemInputHorizontalWrapper,
  StyledSetupItemInputWrapper,
  StyledSetupItemLinkButton,
  StyledSetupItemLinkEmailSwitch,
  StyledSetupItemLinkForm,
  StyledSetupItemUpload,
  StyledSetupStatusApproved,
  StyledSetupStatusRejected,
} from './SetupItem.styled';
import { TSetupItemProps } from './SetupItem.types';

export const SetupItem: FC<TSetupItemProps> = ({ setup }) => {
  const [isDocDownloaded, setIsDocDownloaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localSetup, setLocalSetup] = useState(setup);
  const [linkEmailSwitch, setLinkEmailSwitch] = useState('email');
  const [emailInput, setEmailInput] = useState('');
  const { t, currentLanguage } = useContext(TranslationContext);

  const checkSuccess = (value: any) => {
    return Boolean(value || value === 0);
  };

  const isSetupSuccess = useMemo(() => {
    return setup.status === FeeSetupStatuses.APPROVED;
  }, [setup]);

  const isChangesDisabled = useMemo(() => {
    return Boolean(setup.setup_owner_doc_url);
  }, [setup]);

  useEffect(() => {
    setLocalSetup(setup);
  }, [setup]);

  const { changeSetup, completeSetup, generateDoc, uploadDoc, sendToReview } =
    useContext(setupsContext);

  const changeField = (
    field: string,
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    commit = true,
  ) => {
    const newSetup = { ...localSetup, [field]: event.target.value };
    setLocalSetup(newSetup);
    if (commit) {
      changeSetup?.(newSetup);
    }
  };

  const handleDocUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    setIsLoading(true);
    await uploadDoc?.(setup, file);
    setIsLoading(false);
  };

  const handleSendToReview = async () => {
    setIsLoading(true);
    await sendToReview?.(setup);
    setIsLoading(false);
  };

  const handleGenerateDoc = async () => {
    setIsLoading(true);
    await generateDoc?.(setup);
    setIsLoading(false);
  };

  const handleEmailSend = async () => {
    setIsLoading(true);
    if (localSetup && emailInput) {
      const newSetup = await assetManagementService.sendLink(
        localSetup.id,
        emailInput,
      );
      if (newSetup) {
        setLocalSetup(newSetup);
        await completeSetup?.(localSetup);
        changeSetup?.(newSetup, false);
        Notification.success({
          title: `${t(
            'asset_management__setup_item__success_sent_to_mail',
          )} ${emailInput}`,
        });
      }
    }
    setIsLoading(false);
  };

  const handleSaveSetup = async () => {
    setIsLoading(true);
    await completeSetup?.(localSetup);
    setIsLoading(false);
  };

  const handleLinkEmailSwitchChange = (value: string) => {
    setLinkEmailSwitch(value);
  };

  const Loader = useCallback(
    (title = 'asset_management__setup_item__loading_title') => (
      <Tooltip title={t(title)}>
        <span>
          <SetupItemButton color="secondary" loading fullWidth>
            <span className="SetupItemButtonText">
              {t('asset_management__setup_item__loading_button')}
            </span>
          </SetupItemButton>
        </span>
      </Tooltip>
    ),
    [],
  );

  const statusInput = useMemo<ReactElement>(() => {
    if (isLoading) {
      return Loader();
    }

    switch (setup.status) {
      case FeeSetupStatuses.WAITING:
        if (setup.setup_owner_doc_url) {
          if (setup.setup_owner_doc_is_signed) {
            return (
              <SetupItemButton
                onClick={handleSendToReview}
                icon={<ScalesIcon />}
                title={t('asset_management__setup_item__to_review_title')}
                fullWidth
              >
                <span className="SetupItemButtonText">
                  {t('asset_management__setup_item__to_review_button')}
                </span>
              </SetupItemButton>
            );
          }
          if (!isDocDownloaded) {
            const docUrl = setup.setup_owner_doc_url;
            const buttonAction = () => {
              window.open(docUrl, '_blank');
              setIsDocDownloaded(true);
            };
            // download
            return (
              <SetupItemButton
                onClick={buttonAction}
                icon={<DownloadCompactIcon />}
                title={t('asset_management__setup_item__download_title')}
                fullWidth
              >
                <span className="SetupItemButtonText">
                  {t('asset_management__setup_item__download_button')}
                </span>
              </SetupItemButton>
            );
          }
          // upload
          return (
            <StyledSetupItemUpload
              component="label"
              title={t('asset_management__setup_item__upload_doc_title')}
            >
              <input
                accept="application/pdf"
                hidden
                onChange={handleDocUpload}
                type="file"
              />
              <ExportCompactIcon />
              <span>{t('asset_management__setup_item__upload_doc_title')}</span>
            </StyledSetupItemUpload>
          );
        }
        // generate doc
        return (
          <SetupItemButton
            onClick={handleGenerateDoc}
            icon={<GenerateDocumentIcon />}
            color="secondary"
            fullWidth
            title={t('asset_management__setup_item__generate_doc_title')}
          >
            <span className="SetupItemButtonText">
              {t('asset_management__setup_item__generate_doc_button')}
            </span>
          </SetupItemButton>
        );

      case FeeSetupStatuses.WAITING_DOC_GENERATE:
        return Loader();

      case FeeSetupStatuses.ON_REVIEW:
        // on review
        return Loader('asset_management__setup_item__on_review_title');

      case FeeSetupStatuses.APPROVED:
        // approved
        return (
          <StyledSetupStatusApproved
            className="SetupStatusApproved"
            title={t('asset_management__setup_item__approved_title')}
          >
            <CheckmarkIcon />
            <span>{t('asset_management__setup_item__approved')}</span>
          </StyledSetupStatusApproved>
        );

      default:
        // rejected
        return (
          <StyledSetupStatusRejected className="SetupStatusRejected">
            <RejectedIcon />
            <span>{t('asset_management__setup_item__rejected')}</span>
          </StyledSetupStatusRejected>
        );
    }
  }, [setup, isLoading, isDocDownloaded, currentLanguage]);

  const linkInput = useMemo(() => {
    return (
      <StyledSetupItemInputHorizontalWrapper className="SetupItemInputHorizontalWrapper">
        <StyledSetupItemLinkEmailSwitch
          exclusive
          disabled={!isSetupSuccess || isLoading}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={handleLinkEmailSwitchChange}
          options={[
            {
              label: t('asset_management__setup_item__email'),
              value: 'email',
            },
            {
              label: t('asset_management__setup_item__link'),
              value: 'link',
            },
          ]}
          value={linkEmailSwitch}
          fullWidth
        />
        <StyledSetupItemLinkForm className="SetupItemLinkForm">
          {linkEmailSwitch === 'email' ? (
            <>
              <Input
                placeholder={t('asset_management__setup_item__email')}
                value={emailInput}
                disabled={!isSetupSuccess || isLoading}
                onChange={(e) => setEmailInput(e.target.value)}
                fullWidth
              />
              <StyledSetupItemLinkButton
                disabled={!isSetupSuccess || isLoading}
                onClick={handleEmailSend}
                fullWidth
              >
                {t('asset_management__setup_item__button__send')}
              </StyledSetupItemLinkButton>
            </>
          ) : (
            <>
              <Input
                placeholder={t('asset_management__setup_item__link')}
                value={localSetup.link}
                disabled={!isSetupSuccess || isLoading}
                onChange={(e) => changeField('link', e)}
                fullWidth
              />
              <StyledSetupItemLinkButton
                disabled={!isSetupSuccess || isLoading}
                onClick={handleSaveSetup}
                fullWidth
              >
                {t('asset_management__setup_item__button__save')}
              </StyledSetupItemLinkButton>
            </>
          )}
        </StyledSetupItemLinkForm>
      </StyledSetupItemInputHorizontalWrapper>
    );
  }, [
    localSetup.link,
    isSetupSuccess,
    linkEmailSwitch,
    emailInput,
    isLoading,
    currentLanguage,
  ]);

  return (
    <StyledSetupItem className="AssetManagementSetupTabItem">
      <SetupItemProgressBar
        className="SetupItemProgressBar"
        isSuccess={checkSuccess(setup.name)}
      />
      <SetupItemProgressBar
        className="SetupItemProgressBar"
        isSuccess={checkSuccess(setup.value)}
      />
      <SetupItemProgressBar
        className="SetupItemProgressBar"
        isSuccess={checkSuccess(setup.management_fee)}
      />
      <SetupItemProgressBar
        className="SetupItemProgressBar"
        isSuccess={isSetupSuccess}
      />
      <SetupItemProgressBar
        className="SetupItemProgressBar"
        isSuccess={checkSuccess(setup.link)}
      />
      <StyledSetupItemInputWrapper className="SetupItemInputWrapper">
        <Input
          placeholder={t('asset_management__setup_item__name')}
          value={localSetup.name}
          disabled={isChangesDisabled}
          onChange={(e) => changeField('name', e)}
          fullWidth
        />
      </StyledSetupItemInputWrapper>

      <StyledSetupItemInputWrapper className="SetupItemInputWrapper">
        <Input
          disabled={isChangesDisabled}
          placeholder={t('asset_management__setup_item__profit_fee')}
          type="number"
          inputProps={{ max: 100, min: 0, step: 0.1 }}
          value={localSetup.value}
          onChange={(e) => changeField('value', e)}
          fullWidth
        />
      </StyledSetupItemInputWrapper>

      <StyledSetupItemInputWrapper className="SetupItemInputWrapper">
        <Input
          disabled={isChangesDisabled}
          placeholder={t('asset_management__setup_item__total_fee')}
          value={localSetup.management_fee}
          onChange={(e) => changeField('management_fee', e)}
          fullWidth
        />
      </StyledSetupItemInputWrapper>

      <StyledSetupItemInputWrapper className="SetupItemInputWrapper">
        {statusInput}
      </StyledSetupItemInputWrapper>

      <StyledSetupItemInputWrapper className="SetupItemInputWrapper">
        {linkInput}
      </StyledSetupItemInputWrapper>
    </StyledSetupItem>
  );
};
