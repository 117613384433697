import { TRootState } from 'store/store';

export function selectMenu(state: TRootState) {
  return state.menu;
}

export function selectMenuData(state: TRootState) {
  return state.menu?.data;
}

export function selectMenuItems(state: TRootState) {
  return state.menu?.data?.menu?.items.filter((i) => i) || [];
}

export function selectIsHasAtpPerms(state: TRootState) {
  return state.menu?.data?.is_has_atp_permissions || false;
}
