import { Skeleton } from 'react-ui-kit-exante';

import {
  StyledTransfersBody,
  StyledTransfersContainer,
  StyledWithdrawalSkeletonAccounts,
  StyledWithdrawalSkeletonContainer,
  StyledWithdrawalSkeletonSearch,
} from './FundsSkeleton.styled';

export const FundsSkeleton = () => {
  return (
    <StyledTransfersContainer className="TransfersContainer">
      <StyledTransfersBody className="TransfersBody">
        <StyledWithdrawalSkeletonContainer>
          <StyledWithdrawalSkeletonSearch>
            <Skeleton height={48} width={463} />
          </StyledWithdrawalSkeletonSearch>
          <StyledWithdrawalSkeletonAccounts>
            <Skeleton width={166} height={104} />
            <Skeleton width={166} height={104} />
            <Skeleton width={166} height={104} />
            <Skeleton width={166} height={104} />
            <Skeleton width={166} height={104} />
            <Skeleton width={166} height={104} />
          </StyledWithdrawalSkeletonAccounts>
        </StyledWithdrawalSkeletonContainer>
      </StyledTransfersBody>
    </StyledTransfersContainer>
  );
};
