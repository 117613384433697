import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  TUseAccountMetricsProps,
  TUseAccountMetricsValue,
} from 'components/AccountSelection/hooks/useAccountMetrics.types';
import { CurrencyContext } from 'contexts';
import { useLogHandleTime } from 'hooks/useLogHandleTime';
import { accountsService, TAccount } from 'services/accounts';
import { TAccountSummaryMetrics } from 'types/accounts';

let cachedMetrics: TAccountSummaryMetrics[] = [];

const getFilteredAccountsMetrics = (
  metrics: TAccountSummaryMetrics[],
  accounts: TAccount[],
) => {
  return metrics.filter((accountMetric) =>
    accounts.find((account) => account.value === accountMetric.accountId),
  );
};

export const useAccountMetrics = ({
  accounts,
  selectedDate,
  excludeAccountPurposes,
}: TUseAccountMetricsProps): TUseAccountMetricsValue => {
  const { currency, isCurrencyLoading } = useContext(CurrencyContext);

  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
  const [data, setData] = useState<TAccountSummaryMetrics[]>([]);
  const [forcedAccountsMetricsLoading, setForcedAccountsMetricsLoading] =
    useState<boolean>(false);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('account-metrics');
  const currencyRef = useRef<string | null>(null);
  const abortController = useRef(new AbortController());

  const fetchAccountsMetrics = useCallback(
    async (currency_: string) => {
      abortController.current.abort();
      abortController.current = new AbortController();

      try {
        setIsLoadingMetrics(true);
        setStartHandleTime();

        const response = await accountsService.getUserAccountsMetrics({
          accounts: ['all'],
          currency: currency_,
          date: selectedDate,
          signal: abortController.current.signal,
          excludeAccountPurposes,
        });

        logHandleTime();

        if (response) {
          const newMetrics = getFilteredAccountsMetrics(response, accounts);
          cachedMetrics = newMetrics;
          setData(newMetrics);
        } else {
          setData(getFilteredAccountsMetrics(cachedMetrics, accounts));
        }
      } catch (error: any) {
        setData(getFilteredAccountsMetrics(cachedMetrics, accounts));
      } finally {
        setIsLoadingMetrics(false);
        setForcedAccountsMetricsLoading(false);
      }
    },
    [selectedDate, accounts],
  );

  useEffect(() => {
    if (!accounts.length || !selectedDate || isCurrencyLoading) {
      return;
    }

    fetchAccountsMetrics(currency);
  }, [accounts.length, String(selectedDate), isCurrencyLoading]);

  useEffect(() => {
    // we shouldn't request metrics with the initial currency because it duplicated request
    if (
      !currency ||
      !currencyRef.current ||
      currencyRef.current === currency ||
      isCurrencyLoading
    ) {
      return;
    }

    setForcedAccountsMetricsLoading(true);
    fetchAccountsMetrics(currency);
  }, [currency, currencyRef.current, isCurrencyLoading]);

  useEffect(() => {
    if (!isCurrencyLoading) {
      currencyRef.current = currency;
    }
  }, [isCurrencyLoading]);

  const isLoading = forcedAccountsMetricsLoading || isCurrencyLoading;

  return useMemo<TUseAccountMetricsValue>(
    () => ({
      isAccountsMetricsLoading: isLoadingMetrics || isCurrencyLoading,
      isAccountsMetricsForcedLoading: isLoading,
      accountsMetrics: data,
      fetchAccountsMetrics,
    }),
    [isLoading, isLoadingMetrics, data?.length, data, fetchAccountsMetrics],
  );
};
