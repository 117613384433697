import { FC, useContext } from 'react';

import { SigninContext } from '../../contexts';

import { SigninMfa } from './components/SigninMfa';
import { SigninPassword } from './components/SigninPassword';

export const SigninCredentials: FC = () => {
  const { mfa } = useContext(SigninContext);

  return mfa.type ? <SigninMfa /> : <SigninPassword />;
};
