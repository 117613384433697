import { useEffect, useRef, useState } from 'react';

/**
 * Runs an async function repeatedly.
 * Returns poll kill and poll respawn functions
 *
 * @param asyncCallback callback function to run
 * @param dependencies deps of the function
 * @param interval time interval to keep between calls
 * @param onCleanUp callback function to run on cleanups
 * @param start whether to start the poll
 */
export const usePollingEffect = (
  asyncCallback: () => Promise<void> | void,
  dependencies: any[] = [],
  { interval = 10_000, onCleanUp = () => {}, start = true } = {},
) => {
  const [dead, kill] = useState(!start);

  const timeoutIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (dead) {
      return () => {};
    }

    let isStopped = false;

    (async function pollingCallback() {
      try {
        await asyncCallback();
      } finally {
        // Set timeout after it finished, unless stopped
        timeoutIdRef.current =
          !isStopped && window.setTimeout(pollingCallback, interval);
      }
    })();

    // Clean up if dependencies change
    return () => {
      isStopped = true; // prevent racing conditions
      clearTimeout(timeoutIdRef.current || undefined);
      onCleanUp();
    };
  }, [...dependencies, interval, dead]);

  return [() => kill(true), () => kill(false)];
};
