import {
  StyledHeaderContent,
  StyledHeaderWrapper,
} from 'components/Header/Header.styled';
import { Breadcrumbs } from 'components/Header/components';
import { styled } from 'theme';

export const StyledLoginHeaderWrapper = styled(StyledHeaderWrapper)`
  height: 54px !important;
  background-color: transparent;
`;

export const StyledLoginHeaderContent = styled(StyledHeaderContent)`
  height: 54px !important;
`;

export const StyledLoginHeaderBreadcrumbs = styled(Breadcrumbs)`
  margin-right: 0;
`;

export const StyledLoginHeaderContentItem = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    gap: 18px;
    justify-content: flex-end;
  }
`;
