import { TCodeVerificationState } from './useCodeVerification.types';

export const INIT_DELAY = 2000;

export const codeVerificationInitialState: TCodeVerificationState = {
  code: '',
  requestErrorMessage: '',
  verifyErrorMessage: '',
  isVerifying: false,
  isRequesting: false,
};
