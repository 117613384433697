import { TMergeLeAdapters } from './Agreements.types';

/**
 * Applies hot docs updates to initial external docs
 *
 * @param leAdaptersArray externally downloaded docs for each legal entity
 * @param docsSigned list of signed docs for each legal entity
 */
export const mergeLeAdapters: TMergeLeAdapters = ({
  leAdaptersArray,
  docsSigned,
}) => {
  return leAdaptersArray.map(([leName, leAdapter]) => {
    const leDocsSigned = docsSigned[leName] || [];

    return [
      leName,
      {
        ...leAdapter,
        // hot add signed docs to "signed" category
        legal_signed_by_user: [
          ...leAdapter.legal_signed_by_user,
          ...leDocsSigned,
        ],
        // hot remove signed docs from "un-signed" category
        to_sign: leAdapter.to_sign.filter(
          (doc) =>
            !leDocsSigned?.find((docSigned) => docSigned.doc.id === doc.doc.id),
        ),
      },
    ];
  });
};
