import { InstrumentsTree } from 'modules/instrumentsTree/InstrumentsTree';
import { TTreeModule } from 'services/instrumentsTree/instrumentsTree.types';

import { getColumns } from './columns';

export const Overnights = () => {
  return (
    <InstrumentsTree module={TTreeModule.OVERNIGHT} getColumns={getColumns} />
  );
};
