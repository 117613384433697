import { SpinnerIcon } from 'assets/icons';
import { styled } from 'theme';

export const StyledFormGroup = styled('div')`
  width: 100%;

  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  }
`;

export const StyledNotificationContent = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledSpinnerIcon = styled(SpinnerIcon)`
  color: ${({ theme }) => theme.color.icon.warning};
`;

export const StyledAnimateSpinnerIcon = styled(SpinnerIcon)`
  animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
