import { createAsyncThunk } from '@reduxjs/toolkit';

import { menuService, TMenuResponse } from 'services/menu';

export const fetchMenu = createAsyncThunk<TMenuResponse | null, undefined>(
  'menu/fetch',
  async () => {
    const menu = await menuService.getMenu();
    return menu;
  },
);
