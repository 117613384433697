import { ChangeEvent, FC, useContext } from 'react';
import {
  Checkbox,
  getBrowserDateFormat,
  getBrowserLocale,
} from 'react-ui-kit-exante';

import { today } from 'constants/dates';
import { TranslationContext } from 'contexts';
import { Dates } from 'helpers/dates';

import { AffidavitFormContext } from '../../contexts/AffidavitFormContext';

import {
  StyledAffidavitFormSignInput,
  StyledAffidavitFormSignOptions,
  StyledAffidavitFormSignText,
  StyledAffidavitFormSignWrapper,
} from './AffidavitFormSign.styled';

export const AffidavitFormSign: FC = () => {
  const { t } = useContext(TranslationContext);

  const {
    signOptions,
    signOptionsChecked,
    setSignOptionChecked,
    changesText,
    setChangesText,
    accountId,
    accountName,
  } = useContext(AffidavitFormContext);

  const browserFormat = getBrowserDateFormat(false, true, getBrowserLocale);
  const date = Dates.format(today, false, browserFormat);

  const handleTextChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setChangesText(evt.target.value);
  };

  return (
    <StyledAffidavitFormSignWrapper className="AffidavitFormSignWrapper">
      <StyledAffidavitFormSignText className="AffidavitFormSignText">
        {t('layout__w8_affidavit_form_text_i_declare')}
      </StyledAffidavitFormSignText>
      <StyledAffidavitFormSignOptions className="AffidavitFormSignOptions">
        {signOptions.map(({ value, label }) => {
          return (
            <Checkbox
              key={label}
              title={label}
              label={label}
              value={label}
              onChange={(_, checked) => setSignOptionChecked(value, checked)}
              checked={signOptionsChecked[value] || false}
            />
          );
        })}
      </StyledAffidavitFormSignOptions>
      <StyledAffidavitFormSignText className="AffidavitFormSignText">
        {t('layout__w8_affidavit_form_text_info_contained')}
      </StyledAffidavitFormSignText>
      <StyledAffidavitFormSignText className="AffidavitFormSignText">
        {t('layout__w8_affidavit_form_text_first_purposes')}
      </StyledAffidavitFormSignText>
      <StyledAffidavitFormSignText className="AffidavitFormSignText">
        {t('layout__w8_affidavit_form_text_second_purposes')}
      </StyledAffidavitFormSignText>
      <StyledAffidavitFormSignText className="AffidavitFormSignText">
        {t('layout__w8_affidavit_form_text_changes')}
      </StyledAffidavitFormSignText>
      <StyledAffidavitFormSignInput
        className="AffidavitFormSignInput"
        multiline
        fullWidth
        placeholder="Enter text"
        onChange={handleTextChange}
        value={changesText}
      />
      <StyledAffidavitFormSignText className="AffidavitFormSignText">
        {accountId}
        <br />
        {accountName}
        <br />
        {date}
      </StyledAffidavitFormSignText>
    </StyledAffidavitFormSignWrapper>
  );
};
