import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAccountSelectionCarouselSkeletonWrapper = styled('div')`
  display: flex;
  overflow: hidden;
  padding: 16px 0 8px 24px;
  gap: 8px;
`;

export const StyledAccountSelectionCarouselSkeleton = styled(Skeleton)`
  width: 166px;
  height: 104px;
  flex-shrink: 0;
`;
