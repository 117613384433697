import { TFunction } from 'i18next';
import { FC, useReducer } from 'react';
import { IColumn } from 'react-ui-kit-exante';

import { TCreateCurrencyFormatter } from 'helpers/formatters';
import {
  TTreeModule,
  TTreeResponseTreeStructure,
} from 'services/instrumentsTree/instrumentsTree.types';

import { reducer } from './InstrumentsTree.reducer';
import { InstrumentsTreeWithContext } from './InstrumentsTreeWithContext';
import {
  getInitialState,
  InstrumentsTreeContext,
} from './context/InstrumentsTreeContext';

export type TInstrumentsTreeProps = {
  module: TTreeModule;
  getColumns: (
    t: TFunction,
    loadingInstruments: Set<string>,
    currencyFormatter: TCreateCurrencyFormatter,
  ) => IColumn<TTreeResponseTreeStructure>[];
};

export const InstrumentsTree: FC<TInstrumentsTreeProps> = ({
  module,
  getColumns,
}) => {
  const InstrumentsTreeStateManager = useReducer(
    reducer,
    getInitialState(module),
  );

  return (
    <InstrumentsTreeContext.Provider value={InstrumentsTreeStateManager}>
      <InstrumentsTreeWithContext getColumns={getColumns} />
    </InstrumentsTreeContext.Provider>
  );
};
