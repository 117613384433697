import { blockNonNativeProps } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { StyledFooterCntProps } from './Footer.types';

export const StyledFooter = styled('footer')`
  padding: 24px 0;
  color: ${({ theme }) => theme.color.typo.primary};
  background: ${({ theme }) => theme.color.bg.basic};
`;

export const StyledFooterCnt = styled('div', {
  shouldForwardProp: blockNonNativeProps(['downloadAndFollowDataExists']),
})<StyledFooterCntProps>`
  padding-right: 24px;
  padding-left: 24px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: ${({ downloadAndFollowDataExists }) =>
      downloadAndFollowDataExists ? 'flex' : 'block'};
    gap: 4.7%;
  }
`;

export const StyledFooterColumn = styled('div')`
  margin-bottom: 24px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    &:first-of-type {
      flex: 0 0 272px;
    }
    &:last-of-type {
      flex: inherit;
    }
  }
`;

export const StyledFooterHelp = styled('div')`
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.typo.action};
  }
  a:hover {
    text-decoration: underline;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 15px;
  }
`;

export const StyledFooterItem = styled('div')`
  & + & {
    padding-top: 20px;
  }
`;

export const StyledFooterLegal = styled('div')`
  margin: 0 0 10px;
  font-size: 11px;
  line-height: 16px;
  font-feature-settings: 'cpsp' on;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: inline-block;
    width: 50%;
    margin-bottom: 24px;
    padding-right: 4.7%;
    vertical-align: top;
  }
`;

export const StyledFooterRestricted = styled('div')`
  margin: 0 0 10px;
  font-size: 11px;
  line-height: 16px;
  font-feature-settings: 'cpsp' on;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: inline-block;
    width: 49%;
    vertical-align: top;
  }
`;

export const StyledFooterLinks = styled('ul')`
  margin: 0 0 15px;
  padding: 0;
  font-size: 11px;
  line-height: 16px;
  list-style: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 20px;
    font-size: 12px;
    columns: 5;
  }
`;

export const StyledFooterLinksItem = styled('li')`
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 8px;
  }
`;

export const StyledLinkItem = styled('a')`
  color: ${({ theme }) => theme.color.typo.action};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.color.typo.hover};
    text-decoration: underline;
  }
`;

export const StyledFooterCopyright = styled('div')`
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  font-feature-settings: 'cpsp' on;
`;

export const StyledFooterVersion = styled('div')`
  margin: 0 0 10px;
  color: ${({ theme }) => theme.color.typo.ghost};
  font-size: 11px;
  line-height: 16px;
  font-feature-settings: 'cpsp' on;
`;
