export const enum ApiAction {
  FetchApiStateError = 'ApiAction/Fetch/Api/State/Error',
  FetchApiStateStart = 'ApiAction/Fetch/Api/State/Start',
  FetchApiStateSuccess = 'ApiAction/Fetch/Api/State/Success',
  OnAddApplication = 'ApiAction/On/Add/Application',
  OnUpdateApplication = 'ApiAction/On/Update/Application',
  OnDeleteApplication = 'ApiAction/On/Delete/Application',
  OnSetTab = 'ApiAction/On/Set/Tab',
  OnChangeApplicationScopes = 'ApiAction/On/Change/Application/Scopes',
}
