import { FC } from 'react';

import {
  StyledDepositSection,
  StyledDepositSectionBody,
  StyledDepositSectionHeadingSkeleton,
} from '../../../../../DepositWrapper/DepositWrapper.styled';
import {
  StyledRequisiteItem,
  StyledRequisiteList,
} from '../../RequisitesCashSection.styled';

import {
  StyledRequisiteTitleSkeleton,
  StyledRequisiteValueSkeleton,
} from './RequisitesCashSkeleton.styled';

export const RequisitesCashSkeleton: FC = () => {
  return (
    <StyledDepositSection className="DepositSection">
      <StyledDepositSectionHeadingSkeleton variant="rounded" animation="wave" />

      <StyledDepositSectionBody className="DepositSectionBody">
        <StyledRequisiteList className="RequisiteList">
          {[...Array(8)].map((_, i) => (
            <StyledRequisiteItem className="RequisiteItem" key={i}>
              <StyledRequisiteTitleSkeleton
                variant="rounded"
                animation="wave"
              />
              <StyledRequisiteValueSkeleton
                variant="rounded"
                animation="wave"
              />
              <StyledRequisiteTitleSkeleton
                variant="rounded"
                animation="wave"
              />
            </StyledRequisiteItem>
          ))}
        </StyledRequisiteList>
      </StyledDepositSectionBody>
    </StyledDepositSection>
  );
};
