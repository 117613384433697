import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';

export const formatNumber = (
  value?: number | string | null,
  fractionDigits = 2,
): string => {
  const numberedValue = Number(value);

  if (numberedValue === undefined || Number.isNaN(numberedValue)) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  return numberedValue.toFixed(fractionDigits).replace(/\.00$/, '');
};
