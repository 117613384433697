import { styled } from 'theme';

export const StyledAnalysisHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  padding: 0 24px;
`;

export const StyledAnalysisHeaderActions = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StyledAnalysisHeaderCalendars = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;
