export const LANG_PARAM_DEFAULT = 'en';
export const AUTHDB_ID_COOKIE_NAME = 'authdb_user_id';
export const USER_ID_COOKIE_NAME = 'user_id';
export const USER_IS_FUNDED_COOKIE_NAME = 'user_is_funded';

export const NO_CACHE_HEADERS = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};
