import { TTreeResponseTreeStructure } from 'services/instrumentsTree/instrumentsTree.types';

export const getExpandedRowsIds = (
  row: TTreeResponseTreeStructure,
  index: number | string,
): Record<string, boolean> => {
  const { subRows } = row || {};
  if (subRows?.length) {
    const exp = { [index]: true };
    return {
      ...exp,
      ...subRows.reduce((acc, curr, idx) => {
        const newIndex = `${index}.${idx}`;
        return {
          ...acc,
          [newIndex]: true,
          ...getExpandedRowsIds(curr, newIndex),
        };
      }, {}),
    };
  }

  return { [index]: true };
};
