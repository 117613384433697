import { Link } from 'react-router-dom';
import { ToggleButtonGroup } from 'react-ui-kit-exante';

import { StyledLoginHeading } from 'pages/Login/Login.styled';
import { styled } from 'theme';

export const StyledSignupWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  width: 100%;
  max-width: 558px;
`;

export const StyledSignupHeading = styled(StyledLoginHeading)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const StyledSignupForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const StyledSignupFormRow = styled('div')`
  display: grid;
  grid-gap: 24px;
  grid-template-rows: repeat(2, 1fr);

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-rows: none;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledToggleWrapper = styled('div')`
  width: 100%;
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  &.MuiToggleButtonGroup-root {
    flex-wrap: nowrap;
  }

  & .MuiButtonBase-root {
    text-transform: none;
  }
`;

export const StyledSigninText = styled('div')`
  width: 100%;
  text-align: center;

  font-family: ${({ theme }) => theme.font.main};
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledSigninButton = styled(Link)`
  color: ${({ theme }) => theme.color.typo.action};
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
`;
