import { createContext, FC, useContext, useMemo } from 'react';

import { selectAccounts, selectSelectedDate } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TChildren } from 'types/TChildren';

import { TranslationContext } from '../TranslationContext';

import { TAccountDataContextState } from './AccountDataContext.types';
import { useAccountData } from './hooks';

const initialState: TAccountDataContextState = {
  accountData: null,
  fetchAccountData() {},
  isLoading: false,
};

export const AccountDataContext =
  createContext<TAccountDataContextState>(initialState);

export const AccountDataProvider: FC<TChildren> = ({ children }) => {
  const { isTranslationsLoading } = useContext(TranslationContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const selectedDate = useAppSelector(selectSelectedDate);

  const {
    accountData,
    fetchAccountData,
    isAccountDataFirstLoading,
    isAccountDataForcedLoading,
    dateChanged,
    accountChanged,
  } = useAccountData({ selectedAccountId, selectedDate });

  const isLoading: boolean =
    isAccountDataFirstLoading ||
    isAccountDataForcedLoading ||
    isTranslationsLoading ||
    dateChanged ||
    accountChanged;

  const value = useMemo<TAccountDataContextState>(
    (): TAccountDataContextState => ({
      accountData,
      isLoading,
      fetchAccountData,
    }),
    [accountData, isLoading, fetchAccountData],
  );

  return (
    <AccountDataContext.Provider value={value}>
      {children}
    </AccountDataContext.Provider>
  );
};
