import { SORTING_ORDER } from 'constants/SORTING_ORDER';
import { Abort } from 'helpers/abortController';
import { isCancelledError } from 'helpers/abortController/abortController.helpers';
import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';
import {
  TFetchTransactionsProps,
  TTransactionsResponse,
  TTransactionsResponseWrapper,
} from 'services/transactions/transactions.types';

class TransactionsService extends ApiRequest {
  abortClient;

  constructor() {
    super();
    this.abortClient = new Abort();
  }

  async getTransactions({
    accountId = 'all',
    operationTypes = [],
    offset,
    size,
    sortingDirection = SORTING_ORDER.DESC,
    sortingColumn = 4,
    dateTo,
    dateFrom,
  }: TFetchTransactionsProps): Promise<TTransactionsResponse | null> {
    try {
      const response =
        await this.abortClient.withAbortController<TTransactionsResponseWrapper>(
          this.fetch.bind(this),
          {
            url: `/clientsarea/account/transactions_json/list/`,
            params: {
              id: accountId,
              operation_type: operationTypes.join(','),
              limit: size,
              offset,
              sSortDir_0: sortingDirection,
              iSortCol_0: sortingColumn,
              beginDate: dateFrom,
              endDate: dateTo,
            },
          },
        );

      if (response instanceof Error || response?.data?.error) {
        console.error('getTransactions error', response);
        return null;
      }

      return response.data;
    } catch (error) {
      if (isCancelledError(error)) {
        return null;
      }
      console.error('getTransactions error', error);
      notifyWith.genericNetworkError(error);
      return null;
    }
  }
}

export const transactionsService = new TransactionsService();
