export const NOTIFICATION = {
  ERROR: {
    GENERIC: {
      KEY: 'notification__error_generic',
      MESSAGE: 'An error has occurred. Try reloading the page.',
    },
    POST: {
      KEY: 'notification__error_post',
      MESSAGE: 'Something went wrong. Please try again later.',
    },
    BAD_REQUEST: {
      KEY: 'notification__error_bad_request',
      MESSAGE: 'The request is incorrect. Please correct it and try again.',
    },
    FORBIDDEN: {
      KEY: 'notification__error_forbidden',
      MESSAGE: `You don't have permission to view or edit this info. Please contact your account manager or the Support Team.`,
    },
  },
} as const;
