import { styled } from 'theme';

export const StyledSummaryBlockInfo = styled('div')`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
`;

export const StyledSummaryBlockList = styled('div')`
  font-size: 13px;
  color: ${({ theme }) => theme.color.typo.promo};

  .dottedItem:first-of-type {
    color: ${({ theme }) => theme.color.typo.primary};
    font-size: 15px;
    font-weight: 600;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
`;
