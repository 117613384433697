import { useMediaQuery } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { Modal, ModalProps, Table, useModal } from 'react-ui-kit-exante';

import { DocumentViewer } from 'components/Footer/DocumentViewer';
import { TranslationContext } from 'contexts';
import { prepareTableId } from 'helpers/prepareTableId';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { legalDocsService, TLegalEntityDocument } from 'services/legal-docs';
import { useTheme } from 'theme';

import { TAgreementsLegalDocsProps } from './AgreementsLegalDocs.types';
import { getColumns, getKeys, TABLE_SORT_BY } from './columns';

export const AgreementsLegalDocs = ({
  docs = [],
  withStatus,
  isLoading,
  skeletonsCount,
  onSign,
}: TAgreementsLegalDocsProps) => {
  const { t, currentLanguage } = useContext(TranslationContext);
  const theme = useTheme();

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const dateFormatter = useDateFormatter();

  const docModal = useModal();

  const [openedDoc, setOpenedDoc] = useState<TLegalEntityDocument | null>(null);

  const openedDocUrl = openedDoc?.preview_data_url || openedDoc?.doc?.data_url;
  const openedDocNotToSign = openedDoc
    ? openedDoc?.sign_unavailable || openedDoc?.doc?.is_signed || !onSign
    : true;

  const onOpenModal = (doc: TLegalEntityDocument) => {
    setOpenedDoc(doc);
    docModal.onOpen();
  };

  const onCloseModal = () => {
    setOpenedDoc(null);
    docModal.onClose();
  };

  const handleConfirm = async (leDoc: TLegalEntityDocument) => {
    setIsConfirmLoading(true);
    const docSigned = await legalDocsService.signDoc(
      leDoc.doc.id,
      currentLanguage,
    );

    setIsConfirmLoading(false);
    if (docSigned) {
      setOpenedDoc(null);

      const doc = {
        ...leDoc,
        sign_date: docSigned.sign_date,
        doc: docSigned,
      };
      onSign?.(docSigned.legal_entity, doc);
    }
  };

  const columnsDesktop = useMemo(
    () =>
      getColumns({
        t,
        withStatus,
        onOpenModal,
        isMobile: false,
        dateFormatter,
      }),
    [t, withStatus, dateFormatter],
  );
  const columnsMobile = useMemo(
    () =>
      getColumns({ t, withStatus, onOpenModal, isMobile: true, dateFormatter }),
    [t, withStatus, dateFormatter],
  );
  const keysDesktop = useMemo(() => getKeys({ isMobile: false }), [withStatus]);
  const keysMobile = useMemo(() => getKeys({ isMobile: true }), [withStatus]);

  const tableProps = {
    disableSortBy: true,
    data: docs,
    translator: t,
    locale: currentLanguage,
    isFlexLayout: true,
    manualSortBy: false,
    defaultSortBy: TABLE_SORT_BY,
  };

  const modalConfirmButton: ModalProps['confirmButton'] = openedDocNotToSign
    ? undefined
    : {
        confirmButtonName: t('generic__modal__i_agree'),
        handleConfirm: () => openedDoc && handleConfirm(openedDoc),
        confirmButtonIsDisabled: isConfirmLoading,
      };

  return (
    <>
      {isDesktop ? (
        <Table
          {...tableProps}
          className="LegalDocsTable"
          isLoading={isLoading}
          skeletonsCount={skeletonsCount}
          tableId={prepareTableId('clientsarea-settings-agreements-desktop')}
          columns={columnsDesktop}
          getHeaderProps={() => ({
            style: { display: 'flex' },
          })}
          displayedColumnKeys={keysDesktop}
          noHeader={isLoading}
        />
      ) : (
        <Table
          {...tableProps}
          isLoading={isLoading}
          skeletonsCount={skeletonsCount}
          tableId={prepareTableId('clientsarea-settings-agreements-mobile')}
          columns={columnsMobile}
          displayedColumnKeys={keysMobile}
          noHeader
        />
      )}
      <Modal
        title={openedDoc?.doc?.title || ''}
        isOpened={Boolean(docModal.isOpened && openedDoc)}
        onClose={onCloseModal}
        confirmButton={modalConfirmButton}
        cancelButton={{ cancelButtonName: t('generic__action__cancel') }}
        maxWidth="lg"
        disableContentPaddings
        fullWidth
      >
        {openedDoc && openedDocUrl && (
          <DocumentViewer
            doc={{
              title: openedDoc.doc.title,
              dataUrl: openedDocUrl,
              isSigned: openedDoc.doc.is_signed,
            }}
          />
        )}
      </Modal>
    </>
  );
};
