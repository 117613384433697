import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext';
import { selectAccounts } from 'store/accounts';

import { TransfersContext } from '../../context';
import { useTransfers } from '../../hooks';
import {
  StyledTransfersBody,
  StyledTransfersContainer,
  StyledTransfersContainerWrapper,
} from '../shared.styled';

import { StyledDisabledPlaceholder } from './Funds.styled';
import { Amount, TabSelector } from './components';
import { FundsProvider } from './context';
import { FundsTabsOutlet } from './tabs';
import { ToMyAccountWithdrawalHistoryTable } from './tabs/ToMyAccount';

export const Funds = () => {
  const { t } = useContext(TranslationContext);
  const { selectedAccountId } = useSelector(selectAccounts);
  const { transfersState, cashTransferTab, tableRef } = useTransfers();
  const { accountDetailsState } = useContext(TransfersContext);

  const clientName = transfersState.selectedAccountInfo.client_name;

  const isNotAvailable = useMemo(() => {
    const limits = Object.values(accountDetailsState.limits).filter((limit) => {
      const raw = parseFloat(limit);

      return !Number.isNaN(raw) && raw > 0;
    });

    return limits.length === 0;
  }, [accountDetailsState.limits]);

  if (!selectedAccountId || !clientName) {
    // TODO: show unhandled error boundary
    return null;
  }

  if (isNotAvailable) {
    return (
      <StyledDisabledPlaceholder className="DisabledPlaceholder">
        <Text size="15px" weight="400" color="secondary">
          {t('layout__subaccount_transfer__not_available')}
        </Text>
      </StyledDisabledPlaceholder>
    );
  }

  return (
    <FundsProvider selectedAccountId={selectedAccountId} baseName={clientName}>
      <StyledTransfersContainerWrapper className="TransfersContainerWrapper">
        <StyledTransfersContainer className="TransferContainer">
          <StyledTransfersBody className="TransferBody">
            <TabSelector />
            <FundsTabsOutlet />
          </StyledTransfersBody>
          <Amount />
        </StyledTransfersContainer>
      </StyledTransfersContainerWrapper>
      {cashTransferTab === 'MY_ACCOUNT' && (
        <ToMyAccountWithdrawalHistoryTable ref={tableRef} />
      )}
    </FundsProvider>
  );
};
