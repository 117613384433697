import { styled } from 'theme';

export const StyledChallengePurchaseActions = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 40%;
  }
`;

export const StyledChallengePurchaseAction = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;

  .ChallengePurchaseButton {
    flex-grow: 1;
  }
`;
