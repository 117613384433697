import { useContext, useState } from 'react';
import QRCode from 'react-qr-code';
import { Input } from 'react-ui-kit-exante';

import { AppStoreIcon, GooglePlayIcon } from 'assets/icons';
import { CopyButton } from 'components/CopyButton';
import { TranslationContext } from 'contexts';
import { TEnableTotpResponse } from 'services/mfa';

import { TOTP_CODE_LENGTH } from '../../../../Mfa.constants';
import { useCodeVerification } from '../../../../hooks';
import {
  StyledSharedMfaBarcodeKeyContainer,
  StyledSharedMfaBarcodeKeyText,
  StyledSharedMfaBarcodeKeyValue,
  StyledSharedMfaContainer,
  StyledSharedMfaInputWithFooterContainer,
  StyledSharedMfaText,
} from '../../../styled';
import { ConfirmTotp } from '../ConfirmTotp';

import { StyledTotpStepIcon, StyledTotpStepIcons } from './EnableTotp.styled';
import { TEnableTotpProps } from './EnableTotp.types';

export const EnableTotp = ({
  onSuccess,
  onSetValue,
  isSmsConfirmation,
}: TEnableTotpProps) => {
  const { t } = useContext(TranslationContext);
  const [value, setValue] = useState<TEnableTotpResponse | null>(null);

  const { code, verifyErrorMessage, handleCodeChange, isVerifying } =
    useCodeVerification(TOTP_CODE_LENGTH, 'totp', {
      onSuccess,
      disableTimer: true,
    });

  const handleSetValue = (response: TEnableTotpResponse) => {
    setValue(response);
    onSetValue();
  };

  if (!value) {
    return (
      <ConfirmTotp
        onSuccess={handleSetValue}
        isSmsConfirmation={isSmsConfirmation}
      />
    );
  }

  return (
    <StyledSharedMfaContainer className="Container">
      <StyledSharedMfaText className="Text">
        {t('layout__security_tabs__mfa_modal_totp_tooltip')}
      </StyledSharedMfaText>
      <StyledTotpStepIcons className="TotpStepIcons">
        <StyledTotpStepIcon
          className="TotpStepIcon"
          href="https://itunes.apple.com/app/google-authenticator/id388497605"
          target="_blank"
        >
          <AppStoreIcon />
        </StyledTotpStepIcon>
        <StyledTotpStepIcon
          className="TotpStepIcon"
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          target="_blank"
        >
          <GooglePlayIcon />
        </StyledTotpStepIcon>
      </StyledTotpStepIcons>
      <StyledSharedMfaText className="Text">
        {t('layout__security_tabs__mfa_modal_totp_message')}
      </StyledSharedMfaText>
      {value.uri && <QRCode size={136} value={value.uri} />}
      {value.key_b32 && (
        <StyledSharedMfaBarcodeKeyContainer className="KeyContainer">
          <StyledSharedMfaText className="Text">
            {t('layout__security_tabs__mfa_modal_totp_barcode_key')}
          </StyledSharedMfaText>
          <StyledSharedMfaBarcodeKeyValue className="KeyValue">
            <CopyButton text={value.key_b32} />
            <StyledSharedMfaBarcodeKeyText className="KeyText">
              {value.key_b32}
            </StyledSharedMfaBarcodeKeyText>
          </StyledSharedMfaBarcodeKeyValue>
        </StyledSharedMfaBarcodeKeyContainer>
      )}
      <StyledSharedMfaText className="Text">
        {t('layout__security_tabs__mfa_modal_totp_description')}
      </StyledSharedMfaText>
      <StyledSharedMfaInputWithFooterContainer className="InputContainer">
        <Input
          fullWidth
          error={Boolean(verifyErrorMessage)}
          message={verifyErrorMessage}
          disabled={isVerifying}
          placeholder={t('layout__security_tabs__mfa_modal_totp_placeholder')}
          value={code}
          onChange={(e) => handleCodeChange(e.target.value)}
        />
      </StyledSharedMfaInputWithFooterContainer>
    </StyledSharedMfaContainer>
  );
};
