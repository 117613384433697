import { initialFetchState } from 'constants/state';

import { TAccountDetailsState } from './types';

export const initialAccountDetailsState: TAccountDetailsState = {
  currencies: [],
  targetAccounts: [],
  fetching: initialFetchState,
  limits: {},
};
