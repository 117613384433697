import { FC, useContext, useState } from 'react';

import { ActionWithConfirmation } from 'components/ActionWithConfirmation';
import { TranslationContext } from 'contexts';

import { w8SignOptions } from './AgreementsW8Affidavit.consts';
import {
  StyledAgreementsW8AffidavitWrapper,
  StyledW8AffidavitLink,
} from './AgreementsW8Affidavit.styled';
import { TAgreementsW8AffidavitProps } from './AgreementsW8Affidavit.types';
import { W8AffidavitForm } from './components/W8AffidavitForm';
import { AffidavitFormProvider } from './components/W8AffidavitForm/contexts/AffidavitFormContext/AffidavitFormContext';

export const AgreementsW8Affidavit: FC<TAgreementsW8AffidavitProps> = ({
  clientId,
  clientName,
  signUrl,
  legalEntity,
  onConfirm,
  mfaType,
}) => {
  const { t } = useContext(TranslationContext);
  const [showAffidativForm, setShowAffidativForm] = useState(false);

  const handleConfirm = () => {
    onConfirm?.();
  };

  const onCloseAffidativForm = () => {
    setShowAffidativForm(false);
  };

  const toggleAffidativForm = () => {
    setShowAffidativForm((prevState) => !prevState);
  };

  return (
    <StyledAgreementsW8AffidavitWrapper className="AgreementsW8AffidavitWrapper">
      <ActionWithConfirmation
        className="W8AffidavitTooltip"
        content={
          <AffidavitFormProvider
            accountId={clientId}
            accountName={clientName}
            signOptions={w8SignOptions}
            signUrl={signUrl}
            legalEntity={legalEntity}
            onConfirm={handleConfirm}
            mfaType={mfaType}
          >
            <W8AffidavitForm onCancel={onCloseAffidativForm} />
          </AffidavitFormProvider>
        }
        externalOpened={showAffidativForm}
        openFromExternal
        closeHandler={onCloseAffidativForm}
        hideDefaultButtons
        placement="bottom"
        size="big"
      >
        <StyledW8AffidavitLink
          className="W8AffidavitLink"
          onClick={toggleAffidativForm}
        >
          {t('layout__w8_affidavit_form_header')}
        </StyledW8AffidavitLink>
      </ActionWithConfirmation>
    </StyledAgreementsW8AffidavitWrapper>
  );
};
