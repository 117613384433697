import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledDemoHeader = styled('h3')`
  font-size: 16px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.header};
  padding: 24px;
  margin: 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 20px;
    border-top: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;

export const StyledRowContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 8px 24px;
  font-weight: 400;
  font-size: 13px;
`;

export const StyledRowContainerHeader = styled(StyledRowContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
  color: ${({ theme }) => theme.color.icon.secondary};
  font-weight: 600;
  font-size: 12px;
`;

export const StyledResetDemoButton = styled(Button)`
  font-weight: 500;
  font-size: 15px;
`;

export const StyledMobileResetDemoWrapper = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;
