import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TTableVisibilityColumn } from './tables.types';

const TABLE_SERVICE_URL = `/en/clientsarea/rest/account/tables/v2/visibility/`;
const TABLE_COLUMNS_SERVICE_URL = `${TABLE_SERVICE_URL}columns/`;

class TableVisibilityService extends ApiRequest {
  async getTables() {
    try {
      const { data } = await this.fetch<Record<string, boolean>>({
        url: TABLE_SERVICE_URL,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getTables error: ', error);
      return null;
    }
  }

  async updateTableVisibility(tables: Record<string, boolean>) {
    try {
      await this.fetch({
        url: TABLE_SERVICE_URL,
        method: 'POST',
        data: tables,
      });

      return true;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('updateTableVisibility error: ', error);
      return null;
    }
  }

  async getTableColumns(tableId: string) {
    try {
      const { data } = await this.fetch<TTableVisibilityColumn>({
        url: TABLE_COLUMNS_SERVICE_URL,
        params: { table: tableId },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getTables error: ', error);
      return null;
    }
  }

  async updateTableColumnVisibility(data: TTableVisibilityColumn) {
    try {
      await this.fetch({
        url: TABLE_COLUMNS_SERVICE_URL,
        method: 'POST',
        data,
      });

      return true;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('updateTableColumnVisibility error: ', error);
      return null;
    }
  }
}

export const tableVisibilityService = new TableVisibilityService();
