import { FC, useContext } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';

import {
  StyledCardOrderInfoHeader,
  StyledCardOrderInfoIconWrapper,
  StyledOrderInfoCard,
  StyledOrderInfoCardsWrapper,
  StyledOrderInfoParagraph,
  StyledOrderInfoWrapper,
} from './components/CardSettingsOrderInfo/CardSettingsOrderInfo.styled';

export const CardSettingsOrderSkeleton: FC = () => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledOrderInfoWrapper className="OrderInfoWrapper">
      <StyledCardOrderInfoHeader className="CardOrderInfoHeader">
        {t('layout__cards__get_your_card')}
      </StyledCardOrderInfoHeader>
      <StyledOrderInfoParagraph className="OrderInfoParagraph">
        <Skeleton width={327} height={16} />
      </StyledOrderInfoParagraph>
      <StyledOrderInfoCardsWrapper className="OrderInfoCardsWrapper">
        <StyledOrderInfoCard className="OrderInfoCard">
          <StyledCardOrderInfoIconWrapper className="CardOrderInfoIconWrapper">
            <Skeleton width={24} height={24} />
          </StyledCardOrderInfoIconWrapper>
          <StyledOrderInfoParagraph className="OrderInfoParagraph">
            <Skeleton width="100%" height={16} />
          </StyledOrderInfoParagraph>
        </StyledOrderInfoCard>
        <StyledOrderInfoCard className="OrderInfoCard">
          <StyledCardOrderInfoIconWrapper className="CardOrderInfoIconWrapper">
            <Skeleton width={24} height={24} />
          </StyledCardOrderInfoIconWrapper>
          <StyledOrderInfoParagraph className="OrderInfoParagraph">
            <Skeleton width="100%" height={16} />
          </StyledOrderInfoParagraph>
        </StyledOrderInfoCard>
        <StyledOrderInfoCard className="OrderInfoCard">
          <StyledCardOrderInfoIconWrapper className="CardOrderInfoIconWrapper">
            <Skeleton width={24} height={24} />
          </StyledCardOrderInfoIconWrapper>
          <StyledOrderInfoParagraph className="OrderInfoParagraph">
            <Skeleton width="100%" height={16} />
          </StyledOrderInfoParagraph>
        </StyledOrderInfoCard>
      </StyledOrderInfoCardsWrapper>
    </StyledOrderInfoWrapper>
  );
};
