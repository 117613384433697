import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useData } from 'react-ui-kit-exante';

import { BrandingContext } from 'contexts';
import { challengeService, TChallengeHeader } from 'services/challenge';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';
import { TChildren } from 'types/TChildren';

import { initialState } from './ChallengeContext.consts';

export const ChallengeHeaderContext =
  createContext<TChallengeHeader>(initialState);

export const ChallengeHeaderProvider: FC<TChildren> = ({
  children,
}): ReactElement | null => {
  const { branding } = useContext(BrandingContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const [silentUpdate, setSilentUpdate] = useState<boolean>(false);
  const getChallengeHeader = async () => {
    const headerSettings =
      branding?.branding?.challenge_settings?.header_settings;

    if (
      !selectedAccountId ||
      (!headerSettings?.show_account_status &&
        !headerSettings?.show_client_challenge)
    ) {
      return null;
    }

    return (await challengeService.getChallengeHeader(selectedAccountId))?.data;
  };

  const {
    fetchData: fetchChallengeHeader,
    data: challengeHeader,
    isLoading: isChallengeHeaderLoading,
  } = useData({ onFetch: getChallengeHeader });

  const silentFetchChallengeHeader = async () => {
    setSilentUpdate(true);
    await fetchChallengeHeader();
    setSilentUpdate(false);
  };

  useEffect(() => {
    fetchChallengeHeader();

    const intervalId = setInterval(silentFetchChallengeHeader, 10000);

    return () => clearInterval(intervalId);
  }, [selectedAccountId]);

  if (!challengeHeader || (isChallengeHeaderLoading && !silentUpdate)) {
    return null;
  }

  return (
    <ChallengeHeaderContext.Provider value={challengeHeader}>
      {children}
    </ChallengeHeaderContext.Provider>
  );
};
