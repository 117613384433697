import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts/TranslationContext';

import { ActionTooltip } from '../ActionTooltip';
import { TMfaTooltipProps } from '../TotpTooltip/TotpTooltip.types';

import { DisableSms } from './components/DisableSms';
import { EnableSms } from './components/EnableSms';

export const SmsTooltip: FC<TMfaTooltipProps> = ({
  isActive,
  children,
  open,
  onClose,
  onSuccess,
}) => {
  const { t } = useContext(TranslationContext);

  return !isActive ? (
    <ActionTooltip
      title={t('layout__security_tabs__mfa_modal_sms_activate_header')}
      open={open}
      onClose={onClose}
      body={<EnableSms onSuccess={onSuccess} />}
    >
      {children}
    </ActionTooltip>
  ) : (
    <ActionTooltip
      title={t('layout__security_tabs__mfa_modal_sms_deactivate_header')}
      open={open}
      onClose={onClose}
      body={<DisableSms onSuccess={onSuccess} />}
    >
      {children}
    </ActionTooltip>
  );
};
