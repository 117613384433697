import { styled } from 'theme';

export const StyledChallengeName = styled('span')`
  display: block;
  padding: 12px 13px;
  font-size: 13px;

  color: ${({ theme }) => theme.color.typo.primary};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
  }
`;
