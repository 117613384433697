import { TTransferReducer, TTransfersState } from './types';

export const transfersReducer: TTransferReducer = (
  state,
  action,
): TTransfersState => {
  switch (action.type) {
    case 'UPDATE_LAST_TRANSACTION_ID': {
      return {
        ...state,
        lastTransactionId: action.payload,
      };
    }

    case 'SET_REPEATED_WITHDRAWAL': {
      return {
        ...state,
        repeatedWithdrawal: action.payload,
      };
    }

    case 'CLEAR_REPEATED_WITHDRAWAL': {
      return {
        ...state,
        repeatedWithdrawal: null,
      };
    }

    default: {
      return state;
    }
  }
};
