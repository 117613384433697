import { TFunction } from 'i18next';
import { string } from 'yup';

import { TPasswordLevel } from 'types/password';

export const calcPasswordSecurity = (password: string): TPasswordLevel => {
  let score = 0;
  let sets = 0;
  if (password.length > 10) {
    score += 3;
  } else if (password.length > 6) {
    score += 2;
  } else {
    score -= 2;
  }
  if (/\d/.test(password)) {
    score += 1;
    sets += 1;
  }
  if (/[a-z]/.test(password) && /[A-Z]/.test(password)) {
    score += 1;
    sets += 1;
  }
  if (/[!@#$,%^&*?_~\-£()[\]]/.test(password)) {
    score += 1;
    sets += 1;
  }
  if (/(.)\1{3,}/.test(password)) {
    score -= 1;
  }

  if (score >= 6) {
    return 'strong';
  }
  if (score >= 3 && sets > 1) {
    return 'medium';
  }
  return 'weak';
};

export const securityLevelTitles = (t: TFunction) => ({
  weak: t('generic__password__weak'),
  medium: t('generic__password__medium'),
  strong: t('generic__password__strong'),
});

export const calcPasswordMinimumRequirements = (password: string) => {
  if (password.length < 10) {
    return false;
  }
  if (!/\d/.test(password)) {
    return false;
  }
  if (!/[a-z]/.test(password)) {
    return false;
  }
  return /[A-Z]/.test(password);
};

export const passwordValidator = (t: TFunction) =>
  string()
    .required(t('new_password__required'))
    // eslint-disable-next-line func-names
    .test('passwordTest', '', function (this: any, value = '') {
      const { path, createError } = this;
      const result = calcPasswordMinimumRequirements(value);

      if (!result) {
        return createError({
          path,
          message: t('layout__auth__password_must_be'),
        });
      }
      return true;
    });
