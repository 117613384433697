import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledHeaderContainer = styled('div')`
  display: flex;
  padding: 24px;

  & a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;
  }
`;

export const StyledHeaderLink = styled(Button)`
  height: 18px;
  padding-bottom: 2px;
`;
