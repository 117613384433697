import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledNotificationsContainer = styled(Panel)`
  .PanelTitle {
    padding: 24px;
    margin: 0;
  }

  padding: 0;
`;

export const StyledNotificationsTitle = styled('span')`
  font-size: 13px;
  font-weight: 400;
  padding: 0 24px 16px;
  display: block;

  color: ${({ theme }) => {
    return theme.color.typo.promo;
  }};
`;
