import { IColumn } from 'react-ui-kit-exante';

import { formatCellToCurrencyUnrounded } from 'helpers/formatters';
import { TInterestsColumns } from 'pages/Terms/tabs/Interests/components/InterestsTable/InterestsTable.types';
import { TClientsAreaInterests } from 'services/interests/interests.types';

export const columnKeys = ['currency', 'negative', 'positive'] as const;

export const getColumns: ({
  t,
  currencyFormatter,
}: TInterestsColumns) => IColumn<TClientsAreaInterests>[] = ({
  t,
  currencyFormatter,
}) => [
  {
    id: 'currency',
    Header: t('generic__currency'),
    accessor: 'currency',
    disableFilters: true,
  },
  {
    id: 'negative',
    Header: t('generic__negative'),
    accessor: 'negative',
    disableFilters: true,
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'negative'),
  },
  {
    id: 'positive',
    Header: t('generic__positive'),
    accessor: 'positive',
    disableFilters: true,
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'positive'),
  },
];
