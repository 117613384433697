import difference from 'lodash/difference';

/**
 * Removes sub/aggregated accounts conflicts when added into the list
 *
 * The final list should contain account values with unique root part,
 * each of them is one of the following:
 * - any subaccount (CRV1252.005)
 * - any root account (= segregated) [CRV1252]
 * - any aggregated account [CRV1252_aggregate]
 * - the 'all' value (= all available segregated)
 * - the 'all_aggregated' value (= all available aggregated)
 *
 * @param accountsOld previous state of account list
 * @param accountsNew new state of the list, containing both old and new values
 */
export const removeAccountDuplicates = (
  accountsOld: string[],
  accountsNew: string[],
) => {
  const added = difference(accountsNew, accountsOld);

  const hasAll = added.find((d) => d.startsWith('all'));

  return accountsNew.filter((acct) => {
    // remove 'all'-like item if non 'all'-like added
    if (acct.startsWith('all') === !hasAll) {
      return false;
    }

    const isSub = acct.includes('.');

    if (isSub) {
      const root = acct.split('.')[0];

      // remove sub if root added
      if (added.includes(root)) {
        return false;
      }
    }
    // remove root account if sub added
    else if (added.find((d) => d.startsWith(`${acct}.`))) {
      return false;
    }

    const isAggr = acct.includes('_');

    if (isAggr) {
      const nonAggr = acct.split('_')[0];

      // remove aggregated if root or sub added
      if (added.find((d) => d === nonAggr || d.startsWith(`${nonAggr}.`))) {
        return false;
      }
    } else {
      const full = acct.split('.')[0];

      // remove parent or sub if aggregated added
      if (added.find((d) => d.startsWith(`${full}_`))) {
        return false;
      }
    }

    return true;
  });
};
