import { FC, useContext } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { InfoIcon } from 'assets/icons';
import { TranslationContext } from 'contexts/TranslationContext';

import {
  StyledAmountFormHintIconWrapper,
  StyledAmountFormHintText,
  StyledAmountFormHintWrapper,
} from './AmountFormHint.styled';
import { TAmountFormHintProps } from './AmountFormHint.types';

export const AmountFormHint: FC<TAmountFormHintProps> = ({
  text,
  loading = false,
}) => {
  const { t } = useContext(TranslationContext);

  return loading ? (
    <Skeleton width="100%" height={48} />
  ) : (
    <StyledAmountFormHintWrapper className="WithdrawalHintWrapper">
      <StyledAmountFormHintIconWrapper
        className="WithdrawalHintIconWrapper"
        title="title"
      >
        <InfoIcon className="layout__withdrawal__amount-form__tooltip" />
      </StyledAmountFormHintIconWrapper>
      <StyledAmountFormHintText
        className="AmountFormHintText"
        dangerouslySetInnerHTML={{
          __html: t(text),
        }}
      />
    </StyledAmountFormHintWrapper>
  );
};
