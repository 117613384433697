import { StyledLine, StyledPersonalInfoContainer } from './PersonalInfo.styled';
import { PersonalManager } from './components/PersonalManager';
import { UserInfo } from './components/UserInfo';

export const PersonalInfo = () => {
  return (
    <StyledPersonalInfoContainer className="PersonalInfoContainer">
      <PersonalManager />
      <StyledLine className="Line" />
      <UserInfo />
    </StyledPersonalInfoContainer>
  );
};
