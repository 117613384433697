import { useContext, useEffect, useReducer } from 'react';

import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';

import { ApiAction } from './actions';
import { apiStateInitialState } from './constants';
import apiReducer from './reducer';
import { fetchApiState } from './services';
import { TApiReducer } from './types';

export const useApiContext = () => {
  const { currentLanguage } = useContext(TranslationContext);
  const [state, dispatch] = useReducer<TApiReducer>(
    apiReducer,
    apiStateInitialState,
  );

  const handleRefreshApiState = async () => {
    dispatch({ type: ApiAction.FetchApiStateStart });

    const payload = await fetchApiState(currentLanguage);
    if (payload) {
      dispatch({ type: ApiAction.FetchApiStateSuccess, payload });
    } else {
      dispatch({ type: ApiAction.FetchApiStateError });
    }
  };

  useEffect(() => {
    handleRefreshApiState();
  }, []);

  return { state, dispatch, handleRefreshApiState };
};
