import { FC, useContext } from 'react';
import { DownloadIcon, WarningCircleIcon } from 'react-ui-kit-exante';

import { TranslationContext } from 'contexts';
import { useTheme } from 'theme';

import {
  isReportErrored,
  isReportPriceReady,
  isReportProcessing,
  isReportSent,
} from '../../../../../TaxReports.helpers';
import { AcceptPrice } from '../../../../AcceptPrice';

import {
  StyledLoader,
  StyledTaxReportDownloadAnchor,
} from './TaxReportAction.styled';
import { TTaxReportActionProps } from './TaxReportAction.types';

export const TaxReportAction: FC<TTaxReportActionProps> = ({
  report,
  iconSize = 'm',
}) => {
  const theme = useTheme();
  const { t } = useContext(TranslationContext);

  const size = iconSize === 'm' ? 16 : 24;

  if (isReportSent(report)) {
    return (
      <StyledTaxReportDownloadAnchor
        className="TaxReportDownloadAnchor"
        href={report.export_url}
        target="_blank"
        aria-label={t('generic__action__download')}
        rel="noreferrer"
      >
        <DownloadIcon size={size} />
      </StyledTaxReportDownloadAnchor>
    );
  }

  if (isReportPriceReady(report)) {
    return <AcceptPrice report={report} />;
  }

  if (isReportProcessing(report)) {
    return <StyledLoader className="Loader" />;
  }

  if (isReportErrored(report)) {
    return (
      <WarningCircleIcon color={theme?.color?.icon?.radical} size={size} />
    );
  }

  return null;
};
