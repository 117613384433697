import { styled } from 'theme';

export const StyledFileUploadSection = styled('div')`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const StyledSubTitle = styled('div')`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
