import cn from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { ISelectOption, IAutocompleteProps } from 'react-ui-kit-exante';

import { IS_DEVELOPER_STAND } from 'constants/endpoints';
import { useAppSelector } from 'store/hooks';

import { ACCOUNT_SELECTOR_HEIGHT } from './AccountsSearchField.constants';
import { calcOptionHeight } from './AccountsSearchField.helpers';
import { StyledAccountsSearchField } from './AccountsSearchField.styled';
import {
  AccountSearchFieldContext,
  AccountSearchFieldRow,
  TAccountSearchFieldRowContextValue,
} from './components';

export const AccountsSearchField = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: IAutocompleteProps<
    ISelectOption,
    Multiple,
    DisableClearable,
    FreeSolo
  > & {
    withDescriptions?: boolean;
    partialDescriptionSearch?: boolean;
  },
) => {
  const allAccountsDescriptions = useAppSelector(
    (state) => state.accounts.allAccountDescriptions,
  );

  const {
    withDescriptions,
    options,
    ListboxProps: originalListboxProps,
    partialDescriptionSearch = false,
    ListboxComponent,
  } = props;

  const calcOptionSize = useCallback(
    (index: number): number => {
      const option = options[index];

      if (!options.length || !option) {
        return ACCOUNT_SELECTOR_HEIGHT.ROW_DEFAULT;
      }

      const description = allAccountsDescriptions[option.value];

      return calcOptionHeight(
        typeof option.label === 'string' ? option.label : '',
        description,
      );
    },
    [options, allAccountsDescriptions],
  );

  const selectorHeight = useMemo((): number => {
    return options.slice(0, 10).reduce((acc, option) => {
      const description = allAccountsDescriptions[option.value];

      return (
        acc +
        calcOptionHeight(
          typeof option.label === 'string' ? option.label : '',
          description,
        )
      );
    }, 0);
  }, [allAccountsDescriptions, options.length < 10 && options.length]);

  const newListboxProps = useMemo(
    () => ({
      ...originalListboxProps,
      itemSize: calcOptionSize,
      height: selectorHeight,
      contentComponent: AccountSearchFieldRow,
    }),
    [options, originalListboxProps, calcOptionSize, selectorHeight],
  );

  const contextValue = useMemo(
    (): TAccountSearchFieldRowContextValue => ({
      descriptions: allAccountsDescriptions,
      partialDescriptionSearch,
    }),
    [allAccountsDescriptions, partialDescriptionSearch],
  );

  const newProps = !withDescriptions
    ? props
    : {
        ...props,
        ListboxProps: newListboxProps,
      };

  useEffect(() => {
    if (IS_DEVELOPER_STAND && withDescriptions && !ListboxComponent) {
      console.warn(
        'withDescriptions prop require ListboxComponent prop to be AutocompleteInfiniteListbox',
      );
    }
  }, []);

  return (
    <AccountSearchFieldContext.Provider value={contextValue}>
      {/* @ts-expect-error -- we pass through all props with slight modification, so error is caused by complex typing */}
      <StyledAccountsSearchField
        {...newProps}
        className={cn('AccountsSearchField', newProps.className)}
      />
    </AccountSearchFieldContext.Provider>
  );
};
