import { useMediaQuery } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ISelectOption, Table } from 'react-ui-kit-exante';

import { AutocompleteInfiniteListbox } from 'components/AutocompleteInfiniteListbox';
import { StyledPageWrapper } from 'components/Main';
import { StyledNoDataNotification } from 'components/NoDataNotification';
import { TranslationContext } from 'contexts';
import { prepareTableId } from 'helpers/prepareTableId';
import {
  subscriptionsService,
  TFeedPermission,
  TUserFeedPermission,
} from 'services/settings/subscriptions';
import { useTheme } from 'theme';

import { getRowProps, mapSubscriptions } from './Subscriptions.helpers';
import {
  StyledAccountSelectorContainer,
  StyledAccountsSearchField,
  StyledPanel,
} from './Subscriptions.styled';
import { getColumnsDesktop, getColumnsMobile } from './columns';

const tableId = prepareTableId('clientsarea-subscriptions');

export const Subscriptions = () => {
  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [fetchLoading, setFetchLoading] = useState(false);
  const [isAccountUpdating, setIsAccountUpdating] = useState(false);
  const [paymentAccount, setPaymentAccount] = useState<string>('');
  const [subscriptions, setSubscriptions] = useState<TFeedPermission[] | null>(
    null,
  );
  const [accounts, setAccounts] = useState<ISelectOption[]>([]);

  const onChangeSelect = async (_: unknown, account: ISelectOption) => {
    const selectedAccount = String(account.value);
    setIsAccountUpdating(true);
    const response = await subscriptionsService.updatePaymentAccount(
      selectedAccount,
      currentLanguage,
    );
    if (response) {
      setPaymentAccount(selectedAccount);
    } else {
      setPaymentAccount('');
    }
    setIsAccountUpdating(false);
  };

  const getSubscriptions = useCallback(async () => {
    setFetchLoading(true);

    const response =
      await subscriptionsService.getSubscriptions(currentLanguage);

    if (response) {
      const feedPermissions = Object.values(response.feedPermissions || []);
      const mappedSubscriptions = mapSubscriptions(feedPermissions);

      setPaymentAccount(response.paymentAccount || '');
      setAccounts(
        response.accounts.map((account) => {
          return {
            value: account,
            label: account,
          };
        }),
      );
      setSubscriptions(mappedSubscriptions);
    } else {
      setSubscriptions(null);
    }

    setFetchLoading(false);
  }, [subscriptions]);

  const updateSubscriptions = (updatedSubscription: TUserFeedPermission) => {
    const updatedSubscriptions = (subscriptions || []).map((subscription) => {
      if (
        subscription.marketDataGroup === updatedSubscription.marketDataGroup
      ) {
        return {
          ...subscription,
          userFeedPermission: updatedSubscription,
        };
      }
      return subscription;
    });

    setSubscriptions(mapSubscriptions(updatedSubscriptions));
  };

  const columns = useMemo(
    () =>
      isDesktop
        ? getColumnsDesktop({
            t,
            paymentAccount,
            updateSubscriptions,
            isAccountUpdating,
          })
        : getColumnsMobile({
            t,
            paymentAccount,
            updateSubscriptions,
            isAccountUpdating,
          }),
    [
      isTranslationsLoading,
      t,
      paymentAccount,
      updateSubscriptions,
      isAccountUpdating,
      isDesktop,
    ],
  );

  useEffect(() => {
    getSubscriptions();
    return () => {
      setFetchLoading(false);
    };
  }, []);

  const subscriptionsNotAvailable = !accounts?.length && !fetchLoading;

  return (
    <StyledPageWrapper className="SubscriptionsPageWrapper">
      <StyledPanel
        className="Panel"
        title={t('layout__settings__subscriptions_title')}
      />
      {subscriptionsNotAvailable && (
        <StyledNoDataNotification className="SubscriptionsNoDataNotification">
          {t('layout__settings__subscriptions_not_available')}
        </StyledNoDataNotification>
      )}
      {!subscriptionsNotAvailable && accounts.length > 1 && (
        <StyledAccountSelectorContainer className="SubscriptionsAccountSelectorContainer">
          {t('layout__settings__subscriptions_description')}:
          <StyledAccountsSearchField
            withDescriptions
            placeholder={t('generic__account')}
            options={accounts}
            disabled={isAccountUpdating}
            value={paymentAccount}
            onChange={onChangeSelect}
            loading={isAccountUpdating}
            showSkeleton={fetchLoading}
            disableClearable
            className="Autocomplete"
            ListboxComponent={AutocompleteInfiniteListbox}
          />
        </StyledAccountSelectorContainer>
      )}
      {!subscriptionsNotAvailable && accounts.length === 1 && (
        <StyledAccountSelectorContainer className="SubscriptionsAccountSelectorContainer">
          {t('layout__settings__subscriptions_description_account', {
            ACCOUNT: paymentAccount,
          })}
        </StyledAccountSelectorContainer>
      )}
      <Table
        className="SubscriptionsTable"
        tableId={tableId}
        columns={columns}
        data={subscriptions || []}
        isLoading={fetchLoading || isTranslationsLoading}
        translator={t}
        locale={currentLanguage}
        isFlexLayout
        manualSortBy
        disableSortBy
        getRowProps={getRowProps}
        skeletonsCount={22}
        noHeader={!isDesktop}
        getHeaderProps={() => ({
          style: { display: 'flex' },
        })}
      />
    </StyledPageWrapper>
  );
};
