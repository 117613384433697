import cn from 'classnames';
import { FC } from 'react';

import { StyledCardActionWrapper } from './CardAction.styled';
import { TCardActionProps } from './CardAction.types';

export const CardAction: FC<TCardActionProps> = ({
  iconName,
  label,
  className,
  disabled,
  onClick,
}) => (
  <StyledCardActionWrapper
    iconName={iconName}
    label={label}
    className={cn(['CardActionWrapper', className])}
    disabled={disabled}
    onClick={onClick}
  />
);
