import { IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledReportEdit = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledReportEditTypeHead = styled('div')`
  display: flex;
  justify-content: space-between;

  font-size: 13px;
`;

export const StyledReportEditTypeIconButton = styled(IconButton)`
  font-size: 15px;
`;

export const StyledReportEditTypeTitle = styled('h4')`
  margin: 0;

  font-family: ${({ theme }) => theme?.font?.header};
  font-size: 20px;

  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
