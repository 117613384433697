import Decimal from 'decimal.js';
import { TFunction } from 'i18next';
import { EffectCallback } from 'react';
import { InfoIcon } from 'react-ui-kit-exante';

import {
  TWithdrawalHistoryEntry,
  TWithdrawalState,
  TWithdrawalType,
} from 'services/withdrawal';

import { TRANSFERS_REFRESH_TIMEOUT } from './Transfers.constants';
import { StyledTooltip } from './Transfers.styled';
import {
  TRealizeCommissionValue,
  TWithdrawalBalanceAutoupdateEffectFactoryProps,
} from './Transfers.types';
import { TCommissionsState } from './context';
import { TWithdrawalCommissions } from './routes/Crypto/Crypto.types';

export const exanteRound = (
  value: Decimal.Value,
  decimalPlaces = 0,
): Decimal => {
  return new Decimal(value).toDecimalPlaces(decimalPlaces, Decimal.ROUND_UP);
};

export const realizeCommission = (
  amount: string | number | null,
  commissions?: TWithdrawalCommissions | null,
  mpi?: number,
): TRealizeCommissionValue => {
  if (!commissions || amount === null || Number.isNaN(Number(amount))) {
    return { realized: 0 };
  }

  const localAmount = new Decimal(amount || 0);

  const {
    minimum,
    maximum,
    crossrate,
    value: commissionValue = 0,
    type,
  } = commissions;

  if (type === '%') {
    const roundedMinimum = exanteRound(new Decimal(minimum).mul(crossrate));

    const roundedMaximum = exanteRound(new Decimal(maximum).mul(crossrate));

    let result = localAmount.mul(new Decimal(commissionValue).div('100'));

    if (!roundedMinimum.isZero()) {
      result = Decimal.max(roundedMinimum, result);
    }

    if (!roundedMaximum.isZero()) {
      result = Decimal.min(roundedMaximum, result);
    }

    const realized = exanteRound(result, mpi);

    return {
      realized: realized.toNumber(),
      minimum: roundedMinimum.toNumber(),
      maximum: roundedMaximum.toNumber(),
    };
  }

  const realized = exanteRound(new Decimal(commissionValue).mul(crossrate));

  return { realized: realized.toNumber() };
};

export const isTransactionWithdrawal = (
  data: any,
): data is TWithdrawalHistoryEntry => {
  return Object.hasOwn(data, 'wr_id');
};

export const getRoundedCommissionWithRate = (
  value: string | number = 0,
  rate?: string,
): number => {
  return exanteRound(Number(value) * parseFloat(rate || '1')).toNumber();
};

export const getRoundUp = (num: number, precision = 2) => {
  const base = 10 ** precision;

  return Math.ceil(num * base) / base;
};

// commissionsState - commissions from new api
export const getMappedCommissions = (
  commissionsState: TCommissionsState,
  commissions: TWithdrawalState['commissions'],
  key: TWithdrawalType,
  currency: string,
  otherFormatKey = '',
) => {
  if (
    commissionsState?.serviceFees &&
    (commissionsState.serviceFees[key] ||
      commissionsState.otherServiceFees?.[otherFormatKey])
  ) {
    const commission =
      commissionsState.otherServiceFees?.[otherFormatKey] ||
      commissionsState.serviceFees[key];
    let commissionType = '';
    let commissionValue = '';

    const commissionCurrency =
      commissionsState.fixedCurrency && commissionsState.currency
        ? commissionsState.currency
        : currency;

    if (commission.percent && !commission.fixed) {
      commissionType = '%';
      commissionValue = commission.percent;
    } else if (commission.fixed) {
      commissionType = commissionCurrency;
      commissionValue = commission.fixed;
    }

    return {
      type: commissionType,
      value: Number(commissionValue),
      crossrates: commissionsState.crossrates,
      minimum: commission.fixed ? 0 : commission.min,
      maximum: commission.fixed ? 0 : commission.max,
      commissionCurrency,
      fixedCurrency: Boolean(commissionsState.fixedCurrency),
    };
  }

  if (!commissions.list || !commissions.list[key]) {
    return null;
  }

  const { type, value, minimum, maximum } = commissions.list[key];

  const { crossrates } = commissions;

  return {
    isOldCommissions: true,
    type,
    value,
    crossrates,
    minimum,
    maximum,
  };
};

export const getOrderedCurrencies = (currencies: string[]): string[] => {
  const result = new Set([
    ...['EUR', 'USD', 'GBP', 'CNH', 'KZT'].filter((item) =>
      currencies.includes(item),
    ),
    ...currencies,
  ]);

  return Array.from(result.values());
};

export const getFieldTooltipHint = (
  fieldName: string,
  withdrawalType: TWithdrawalType,
  fieldsSetting: TWithdrawalState['branding']['wr_fields_settings'],
  t: TFunction,
  onlyText?: boolean,
) => {
  const hintText = fieldsSetting?.[withdrawalType]?.[fieldName]?.info_text;

  if (hintText) {
    if (onlyText) {
      return hintText;
    }
    return (
      <StyledTooltip title={t(hintText)}>
        <InfoIcon />
      </StyledTooltip>
    );
  }
  return undefined;
};

export const withdrawalBalanceAutoupdateEffectFactory: (
  props: TWithdrawalBalanceAutoupdateEffectFactoryProps,
) => EffectCallback =
  ({ selectedAccountId, currency, updateBalance }) =>
  () => {
    let force = true;
    const update = () => {
      if (selectedAccountId && currency) {
        updateBalance(selectedAccountId, currency, force);
        force = false;
      }
    };

    update();
    const updater = window.setInterval(update, TRANSFERS_REFRESH_TIMEOUT);

    return () => {
      force = true;
      clearInterval(updater);
    };
  };
