import { isToday } from 'date-fns';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { CurrencyContext } from 'contexts';
import { Dates } from 'helpers/dates';
import { TFetchAccountHistoryParams } from 'services/analysis';
import { selectAccounts, selectSelectedDate } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

export type TFiltersState = {
  filters: TFetchAccountHistoryParams;
  setFilters: (filters: TFetchAccountHistoryParams) => void;
};

const initialState: TFiltersState = {
  filters: {
    account_id: '',
    currency: '',
    symbol_ids: [],
    date_from: '',
    date_to: '',
  },
  setFilters: () => {},
};

export const FilterContext = createContext<TFiltersState>(initialState);

export const FilterProvider = ({ children }: PropsWithChildren<unknown>) => {
  const selectedDate = useAppSelector(selectSelectedDate);
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const [searchParams, setSearchParams] = useSearchParams();

  const getFiltersFromParams = useCallback(() => {
    return (
      Object.keys(initialState.filters) as Array<
        keyof TFetchAccountHistoryParams
      >
    ).reduce((acc, curr) => {
      const value = searchParams.get(curr);
      if (value?.includes(',') || curr === 'symbol_ids') {
        return {
          ...acc,
          [curr]: value ? value.split(',') : initialState.filters[curr],
        };
      }

      return {
        ...acc,
        [curr]: value || initialState.filters[curr],
      };
    }, {});
  }, []);

  const { currency } = useContext(CurrencyContext);

  const [filters, setFilters] = useState<TFiltersState['filters']>({
    ...initialState.filters,
    ...getFiltersFromParams(),
    currency,
  });

  useEffect(() => {
    if (selectedAccountId) {
      setFilters((fs) => ({
        ...fs,
        account_id: selectedAccountId,
        currency,
      }));
    }
  }, [selectedAccountId, currency]);

  useEffect(() => {
    const params = new URLSearchParams({
      date_to: filters.date_to,
      date_from: filters.date_from,
      account_id: filters.account_id,
      currency: filters.currency,
      ...(filters.symbol_ids?.length && {
        symbol_ids: filters.symbol_ids.toString(),
      }),
    });
    setSearchParams(params);
  }, [filters]);

  useEffect(() => {
    if (!isToday(selectedDate)) {
      setFilters((fs) => ({
        ...fs,
        date_to: Dates.format(selectedDate),
        date_from: Dates.format(selectedDate),
      }));
    }
  }, [selectedDate]);

  const value = useMemo<TFiltersState>(
    () => ({
      filters,
      setFilters,
    }),
    [filters, selectedAccountId],
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};
