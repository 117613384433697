import { padStart } from 'lodash';
import { FC, useContext } from 'react';
import { Input, Loader, useData } from 'react-ui-kit-exante';

import { ChangeCardPinIcon } from 'assets/icons';
import { TranslationContext } from 'contexts';
import { useTimer } from 'hooks';
import {
  StyledPinTooltipCountdown,
  StyledPinTooltipDescription,
  StyledPinTooltipTextWithSpace,
} from 'pages/CardSettings/components/CardSettingsCard/components/PinTooltip/PinTooltip.styled';
import { TPinTooltipProps } from 'pages/CardSettings/components/CardSettingsCard/components/PinTooltip/PinTooltip.types';
import {
  TooltipContent,
  TTooltipContentButton,
} from 'pages/CardSettings/components/CardSettingsCard/components/TooltipContent';
import { cardService } from 'services/cards';

export const PinTooltip: FC<TPinTooltipProps> = ({
  cardId,
  onClose,
  activation,
}) => {
  const { t, currentLanguage } = useContext(TranslationContext);

  const {
    time: countdown,
    start: startCountdown,
    clear: stopCountdown,
  } = useTimer(30, { startOnInit: false });

  const {
    data: pin,
    fetchData: getPin,
    isLoading: loading,
  } = useData({
    onFetch: () =>
      cardService.getPin({
        lang: currentLanguage,
        cardId,
      }),
    onSuccess: (value) => {
      if (value) {
        startCountdown();
      }
    },
    loading: false,
  });

  const handleCloseModal = () => {
    onClose();
    stopCountdown();
  };

  const formattedCountdown = `00:${padStart(String(countdown), 2, '0')}`;

  const DescriptionForUser = activation ? (
    <p>
      <span
        dangerouslySetInnerHTML={{
          __html: t('layout__cards__press_pin_when_ready'),
        }}
      />
      <StyledPinTooltipTextWithSpace className="PinTooltipTextWithSpace">
        {t('layout__cards__make_sure_you_are_ready')}
      </StyledPinTooltipTextWithSpace>
    </p>
  ) : (
    <>
      <p>
        <span
          dangerouslySetInnerHTML={{
            __html: t('layout__cards__press_pin_when_ready'),
          }}
        />
      </p>
      <p>{t('layout__cards__make_sure_you_are_ready')}</p>
    </>
  );

  const pinVisible = Boolean(pin && countdown);

  const confirmButton: TTooltipContentButton = pinVisible
    ? {
        label: t('generic__action__ok'),
        handler: handleCloseModal,
        actionStyle: true,
      }
    : {
        hidden: !!countdown,
        handler: getPin,
        label: t('layout__cards__show_pin_code'),
        disabled: loading,
        actionStyle: true,
      };

  const cancelButton: TTooltipContentButton = {
    label: t('generic__action__cancel'),
    handler: handleCloseModal,
    hidden: pinVisible,
  };

  return (
    <TooltipContent
      icon={<ChangeCardPinIcon />}
      title={
        pinVisible
          ? t('layout__cards__card_your_pin')
          : t('layout__cards__show_pin')
      }
      cancel={cancelButton}
      confirm={confirmButton}
    >
      {!pinVisible && (
        <StyledPinTooltipDescription className="PinTooltipDescription">
          {DescriptionForUser}
        </StyledPinTooltipDescription>
      )}
      {loading ? (
        <Loader />
      ) : (
        !!countdown && (
          <>
            <Input size="small" value={pin} />
            <StyledPinTooltipCountdown className="PinTooltipCountdown">
              {formattedCountdown}
            </StyledPinTooltipCountdown>
          </>
        )
      )}
    </TooltipContent>
  );
};
