import { ClickAwayListener } from '@mui/material';
import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  IconButton,
  Notification as UIKitNotification,
  useData,
} from 'react-ui-kit-exante';

import { EmailInputTooltip } from 'components/EmailInputTooltip';
import { TranslationContext } from 'contexts/TranslationContext';
import { DepositContext } from 'pages/Portfolio/tabs/Deposit/contexts';
import { depositService, TDepositExportEmailResponse } from 'services/deposit';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

export const SendEmailButton: FC = () => {
  const { t } = useContext(TranslationContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const { currency, bank } = useContext(DepositContext);

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  const abortController = useRef(new AbortController());

  const {
    isLoading,
    data: exportData,
    fetchData: exportEmail,
  } = useData<TDepositExportEmailResponse | null>({
    loading: false,
    onFetch: async () => {
      let response = null;

      setOpen(true);

      abortController.current.abort();
      abortController.current = new AbortController();

      if (selectedAccountId && bank && email) {
        response = await depositService.exportDepositEmail({
          accountId: selectedAccountId,
          bank,
          currency,
          email,
          options: {
            signal: abortController.current.signal,
          },
        });
      }

      setOpen(false);

      return response;
    },
  });

  // When clicked away, the tooltip should just close,
  // but not abort the request
  const handleClickAway = () => {
    setOpen(false);
  };

  // When the close button is clicked,
  // the request should be aborted
  const handleTooltipClose = () => {
    setOpen(false);
    abortController.current.abort();
  };

  // When parameters change, the request should be aborted
  useEffect(() => {
    return () => {
      abortController.current.abort();
    };
  }, [selectedAccountId, currency, bank]);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const { result, message } = exportData || {};

    if (result && message) {
      UIKitNotification.success({ title: t(message) });
    }
  }, [exportData]);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      {/* span is required to handle away clicks properly */}
      <span>
        <EmailInputTooltip
          open={open}
          loading={isLoading}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{
            disablePortal: true,
          }}
          onAccept={exportEmail}
          onDecline={handleTooltipClose}
          onClose={handleTooltipClose}
          onChange={handleEmailChange}
          value={email}
        >
          <IconButton
            iconName="EmailMeAccountInfoIcon"
            iconColor="secondary"
            iconSize={24}
            onClick={handleTooltipOpen}
            data-test-id="requisites__button__open_send_email"
          />
        </EmailInputTooltip>
      </span>
    </ClickAwayListener>
  );
};
