import { IRow } from 'react-ui-kit-exante';

import { TFeedPermission } from 'services/settings/subscriptions';
import { TClientsareaTheme } from 'theme';

export const getRowProps = (
  { original }: IRow<TFeedPermission>,
  theme: TClientsareaTheme,
) => {
  if (original?.userFeedPermission?.endDate) {
    return {
      style: {
        backgroundColor: theme.color.bg.warning,
      },
    };
  }
  return {
    style: {
      backgroundColor: original?.userFeedPermission?.subscribed
        ? theme.color.table.bg.source
        : theme.color.table.bg.basic1,
    },
  };
};

export const mapSubscriptions = (feedPermissions: TFeedPermission[]) => {
  const active = feedPermissions.filter(
    (i) => i.userFeedPermission?.subscribed,
  );

  const activeExpired = feedPermissions.filter(
    (i) => !i.userFeedPermission?.subscribed && i.userFeedPermission?.endDate,
  );

  const notActive = feedPermissions.filter(
    (i) => !i.userFeedPermission?.subscribed && !i.userFeedPermission?.endDate,
  );

  return [...active, ...activeExpired, ...notActive];
};
