import { cloneDeep } from 'lodash';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { RESEND_CODE_TIME } from 'constants/RESEND_CODE_TIME';
import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { isCodeValid } from 'helpers/string';
import { useTimer, useUserDetails } from 'hooks/index';
import { mfaService, TMfaType } from 'services/mfa';
import { selectSettings } from 'store/application';

import { MfaTooltipState } from '../../../../../../../Security/pages/Mfa/Mfa.constants';
import { useMfa } from '../../../../../../../Security/pages/Mfa/hooks';

import { useConfirmationInitData } from './useConfirmation.constants';
import { TUseConfirmationData } from './useConfirmation.types';

export const useConfirmation = (
  onConfirm: (token: string, confirmationType?: TMfaType) => void,
) => {
  const smsTimer = useTimer(RESEND_CODE_TIME, { startOnInit: false });
  const emailTimer = useTimer(RESEND_CODE_TIME, { startOnInit: false });
  const totpTimer = useTimer(RESEND_CODE_TIME, { startOnInit: false });

  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const [data, setData] = useState(useConfirmationInitData);
  const [isTokenFetching, setIsTokenFetching] = useState(false);
  const { mfa } = useSelector(selectSettings);
  const { countryCode, phoneNumber, email } = useUserDetails();
  const [localConfirmationType, setLocalConfirmationType] =
    useState<MfaTooltipState>(MfaTooltipState.Sms);
  const { allowedSet } = useMfa();

  const confirmationType = useMemo(() => {
    return mfa.site.type_name || localConfirmationType;
  }, [mfa.site.type_name, localConfirmationType]);

  const [code, error, isCodeSent] = useMemo(
    () => [
      data[confirmationType]?.code || '',
      data[confirmationType]?.error || '',
      data[confirmationType]?.isCodeSent,
    ],
    [data, confirmationType],
  );

  const updateData = useCallback(
    <T extends keyof TUseConfirmationData>(
      prevState: Record<MfaTooltipState, TUseConfirmationData>,
      key: T,
      value: TUseConfirmationData[T],
    ) => {
      const obj = cloneDeep(prevState);
      obj[confirmationType][key] = value;
      return obj;
    },
    [confirmationType],
  );

  const setCode = useCallback(
    (newCode: string) => {
      setData((prevState) => updateData(prevState, 'code', newCode));
    },
    [confirmationType],
  );

  const setError = useCallback(
    (newError: string) => {
      setData((prevState) => updateData(prevState, 'error', newError));
    },
    [confirmationType],
  );

  const setCodeSent = useCallback(
    (codeSent: boolean) => {
      setData((prevState) => updateData(prevState, 'isCodeSent', codeSent));
    },
    [confirmationType],
  );

  const { codeLength, title, resend, value, timer } = useMemo(() => {
    switch (confirmationType) {
      case 'email':
        return {
          codeLength: 8,
          title: isCodeSent
            ? t('layout__settings__we_sent_code_to_email')
            : t('layout__settings__send_code_to_email'),
          value: email,
          resend: true,
          timer: emailTimer,
        };

      case 'sms':
        return {
          codeLength: 4,
          title: isCodeSent
            ? t('layout__settings__we_sent_code_to_phone')
            : t('layout__settings__send_code_to_phone'),
          value: `${countryCode} ${phoneNumber}`,
          resend: true,
          timer: smsTimer,
        };

      case 'totp':
        return {
          codeLength: 6,
          title: t('layout__security_tabs__mfa_modal_totp_description'),
          value: null,
          resend: false,
          timer: totpTimer,
        };

      default:
        return {
          codeLength: 4,
          title: t('layout__settings__we_sent_code_to_phone'),
          value: `${countryCode} ${phoneNumber}`,
          resend: true,
          timer: smsTimer,
        };
    }
  }, [confirmationType, smsTimer, emailTimer, totpTimer, isCodeSent]);

  const handleCodeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: payload } = event.target;
      setError('');

      if (codeLength && isCodeValid(payload, codeLength)) {
        setCode(payload);
      }
    },
    [confirmationType],
  );

  const confirmationTypeOptions = useMemo(() => {
    return [
      { value: MfaTooltipState.Sms, label: 'SMS' },
      { value: MfaTooltipState.Email, label: 'Email' },
    ].filter((i) => {
      const confirmTypeValue = i.value;
      let allowed = allowedSet.has(confirmTypeValue);
      if (confirmTypeValue === MfaTooltipState.Email) {
        allowed &&= branding?.branding?.show_2fa_email || false;
      }
      return allowed;
    });
  }, [allowedSet]);

  const canChangeConfirmationType = Boolean(
    branding?.branding?.configure_wr_verification_enabled &&
      !mfa.site.type_name &&
      confirmationTypeOptions.length,
  );

  const handleConfirmationSwitch = (type: string) => {
    if (allowedSet.has(type as MfaTooltipState)) {
      setLocalConfirmationType(type as MfaTooltipState);
    }
  };

  const handleResend = useCallback(async () => {
    setCodeSent(true);
    timer.start();
    await mfaService.verificationResend(confirmationType);
  }, [confirmationType]);

  const handleConfirm = async () => {
    setIsTokenFetching(true);

    try {
      const token = await mfaService.fetchToken(code, confirmationType);
      setData(useConfirmationInitData);
      onConfirm(token, confirmationType);
    } catch (e) {
      setError(t('layout__code_expired'));
    } finally {
      setIsTokenFetching(false);
    }
  };

  useEffect(() => {
    if (!canChangeConfirmationType) {
      handleResend();
    }
  }, []);

  return {
    code,
    error,
    title,
    resend,
    value,
    handleConfirm,
    seconds: timer.time,
    handleCodeChange,
    handleResend,
    isTokenFetching,
    isCodeSent,
    confirmationType,
    confirmationTypeOptions,
    canChangeConfirmationType,
    handleConfirmationSwitch,
  };
};
