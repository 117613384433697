import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { preparePartnerFormDataForChangeRequest } from './partner.helpers';
import {
  TPartnerCreateLinkProps,
  TPartnerExportLinksToCsvProps,
  TPartnerGetStatisticsProps,
  TPartnerGetStatisticsResponse,
  TPartnerKycStatusResponse,
  TPartnerLink,
  TPartnerPaymentInfo,
  TPartnerPaymentInfoDetails,
  TPartnerRemoveLinkProps,
  TPartnerSendChangeRequestProps,
  TPartnersInfo,
  TPartnerUpdateLinkProps,
} from './partner.types';

class PartnerService extends ApiRequest {
  async getKycStatus(lang = 'en') {
    try {
      const { data } = await this.fetch<TPartnerKycStatusResponse>({
        url: `/${lang}/clientsarea/rest/wizard/partner-kyc/`,
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getKycStatus error', error);
      return null;
    }
  }

  async setKycOpen(isOpen: boolean): Promise<void> {
    try {
      await this.fetch({
        method: 'POST',
        url: `/clientsarea/rest/wizard/partner-kyc/`,
        data: {
          is_open: isOpen,
        },
      });
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('setKycOpen error', error);
    }
  }

  async sendToReviewCFSCLanding(type: string): Promise<boolean | null> {
    try {
      await this.fetch({
        method: 'POST',
        url: `/clientsarea/rest/wizard/send-to-review-cfsc-landing/`,
        data: {
          type,
        },
      });
      return true;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('partnerService', error);
      return null;
    }
  }

  async getInfo(period: Array<Date | string>): Promise<TPartnersInfo | null> {
    const preparedPeriod: string[] = period.map((date) => {
      if (date instanceof Date) {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${date.getFullYear()}-${month}-${day}`;
      }

      return date;
    });

    try {
      const { data } = await this.fetch<TPartnersInfo>({
        url: `/clientsarea/account/partner/links/`,
        params: {
          date_from: preparedPeriod[0],
          date_to: preparedPeriod[1],
        },
      });

      return data;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getInfo error: ', error);
      return null;
    }
  }

  async getStatistics({
    lang,
    offset,
    size,
    dateFrom,
    dateTo,
  }: TPartnerGetStatisticsProps): Promise<
    TPartnerGetStatisticsResponse['data'] | null
  > {
    try {
      const { data } = await this.fetch<TPartnerGetStatisticsResponse>({
        url: `/${lang}/clientsarea/account/partner/referrers/`,
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          iDisplayStart: offset,
          iDisplayLength: size,
        },
      });

      return data?.data || null;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getStatistics error: ', error);
      return null;
    }
  }

  async exportLinksToCsv({
    lang,
    dateTo,
    dateFrom,
  }: TPartnerExportLinksToCsvProps): Promise<boolean> {
    const prepareDate = (date: Date) => date.toISOString().substring(0, 10);

    try {
      await this.fetch<TPartnerLink>({
        url: `/${lang}/clientsarea/account/partner/referrers/export/`,
        params: {
          date_to: prepareDate(dateTo),
          date_from: prepareDate(dateFrom),
        },
      });

      return true;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('exportLinksToCsv error: ', error);
      return false;
    }
  }

  async createLink({
    lang,
    link,
  }: TPartnerCreateLinkProps): Promise<TPartnerLink | null> {
    try {
      const { data } = await this.fetch<TPartnerLink>({
        url: `/${lang}/clientsarea/account/partner/api/`,
        method: 'post',
        data: link,
      });

      return data || null;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('createLink error: ', error);
      return null;
    }
  }

  async updateLink({
    lang,
    link,
  }: TPartnerUpdateLinkProps): Promise<TPartnerLink | null> {
    try {
      const { data } = await this.fetch<TPartnerLink>({
        url: `/${lang}/clientsarea/account/partner/api/${link.id}/`,
        method: 'put',
        data: link,
      });

      return data || null;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('updateLink error: ', error);
      return null;
    }
  }

  async removeLink({ lang, id }: TPartnerRemoveLinkProps): Promise<boolean> {
    try {
      await this.fetch<TPartnerLink>({
        url: `/${lang}/clientsarea/account/partner/api/${id}/`,
        method: 'delete',
      });

      return true;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('removeLink error: ', error);
      return false;
    }
  }

  async getPaymentInfo(
    lang: string,
  ): Promise<TPartnerPaymentInfoDetails | null> {
    try {
      const { data } = await this.fetch<{
        'payment-details': TPartnerPaymentInfoDetails;
      }>({
        url: `/${lang}/clientsarea/spa-layout/?name=payment-details`,
      });

      return data?.['payment-details'] || null;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('getPaymentInfo error: ', error);
      return null;
    }
  }

  async sendChangeRequest({
    lang,
    formData,
    email,
  }: TPartnerSendChangeRequestProps): Promise<TPartnerPaymentInfo | null> {
    try {
      const preparedFormData = preparePartnerFormDataForChangeRequest(formData);

      const data = {
        ...preparedFormData,
        email,
        code: '',
      };

      const { data: responseData } = await this.fetch<TPartnerPaymentInfo>({
        url: `/${lang}/clientsarea/rest/change-request/`,
        method: 'post',
        data,
      });

      notifyWith.success('layout__actions__your_request_has_been_sent');

      return responseData || null;
    } catch (error) {
      notifyWith.genericNetworkError(error);
      console.error('sendChangeRequest error: ', error);
      return null;
    }
  }
}

export const partnerService = new PartnerService();
