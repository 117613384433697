import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledProductImageSkeletonWrapper = styled('div')`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 20px 0;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    padding: 44px 0;
  }
`;

export const StyledProductImageSkeleton = styled(Skeleton)`
  width: 100%;
  height: 222px;

  ${({ theme }) => theme.breakpoints.up('lr')} {
    height: 280px;
  }
`;
