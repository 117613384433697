import { TAccountInfoResponse } from 'services/accounts';
import { TWithdrawalState } from 'services/withdrawal';

import { TWithdrawalMetadataState } from './withdrawal.types';

export const initialSelectedAccountInfo: TAccountInfoResponse = {
  accountPurpose: null,
  clientId: null,
  client_address: null,
  client_name: '',
  iban: null,
  id: '',
};

export const initialMetadataState: TWithdrawalMetadataState = {
  selectedAccountInfo: initialSelectedAccountInfo,
};

export const initialWithdrawalState: TWithdrawalState = {
  accounts: {
    descriptions: {},
    ibansData: [],
    lists: {
      query_allowed: [],
      withdrawal_allowed: [],
      subuser_withdrawal_allowed: [],
      own_accounts: [],
      transfer_allowed: {
        own: [],
        other_wire: [],
        other: [],
      },
    },
    paymentAccount: '',
    ownPaymentAccount: '',
  },
  autoprocessing: {},
  branding: {
    show_iban_swift_validation: false,
    show_security_comment: false,
    wr_documents_required: false,
    wr_fields_settings: null,
    wr_hidden_fields: [],
  },
  client_id_le_map: {},
  commissions: {
    crossrates: {},
    list: null,
    security: '',
  },
  cryptocurrencies: [],
  currencies: {
    crypto: [],
    cryptoEnabled: false,
    cryptoEursMin: 0,
    cryptoEursMpi: 0,
    fiat: [],
    limitWire: false,
  },
  list: { items: [], subuserItems: [] },
  otherTransfer: { disable: false, isGbxp: false, show: null, wire: false },
  securityTransfer: { enable: false },
  showList: false,
  subuserWithdrawal: { allFundsEnabled: false, enable: false },
  user: null,
  wr_types: null,
};
