import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateTableVisibility } from 'store/tables';
import { EStatus } from 'types/api';

import { TUseAccordionProps, TUseAccordionValue } from './useAccordion.types';

export function getInitialAccordionState({
  items,
  lsKey,
  closeDefault,
}: TUseAccordionProps) {
  const lsState = lsKey ? localStorage.getItem(lsKey) : null;

  if (!lsState) {
    return Object.keys(items).reduce<Record<string, boolean>>(
      (acc, curr) => ({ ...acc, [curr]: !closeDefault }),
      {},
    );
  }

  return JSON.parse(lsState);
}

export function useAccordion({
  items,
  lsKey,
  closeDefault = false,
}: TUseAccordionProps): TUseAccordionValue {
  const dispatch = useAppDispatch();

  const [visibleTables] = useAppSelector((state) => [
    state.tables.visibleTables,
  ]);

  const [accordion, setAccordion] = useState<Record<string, boolean>>(() =>
    getInitialAccordionState({ items, lsKey, closeDefault }),
  );

  const debouncedUpdateState = useDebouncedCallback((newState) => {
    dispatch(updateTableVisibility(newState));
  }, 2000);

  const handleAccordionChange = useCallback(
    (key: string | number) =>
      setAccordion((prev) => {
        const newState = Object.keys(prev).reduce(
          (acc, curr) => (curr === key ? { ...acc, [curr]: !prev[curr] } : acc),
          prev,
        );

        if (lsKey) {
          localStorage.setItem(lsKey, JSON.stringify(newState));
        }

        debouncedUpdateState(newState);

        return newState;
      }),
    [lsKey],
  );

  useEffect(() => {
    if (visibleTables?.data) {
      setAccordion((prev) => {
        return { ...prev, ...visibleTables.data };
      });
    }
  }, [visibleTables?.data]);

  return useMemo(
    () => ({
      isAccordionLoading: visibleTables.status === EStatus.Loading,
      accordion,
      handleAccordionChange,
    }),
    [visibleTables.status, accordion, handleAccordionChange],
  );
}
