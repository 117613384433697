import { FC } from 'react';

import { StyledAvailableValue } from './AvailableValue.styled';
import { TAvailableValueProps } from './AvailableValue.types';

export const AvailableValue: FC<TAvailableValueProps> = ({
  children,
  isMarginCall = false,
}) => {
  return (
    <StyledAvailableValue
      className="AvailableValue"
      isMarginCall={isMarginCall}
    >
      {children}
    </StyledAvailableValue>
  );
};
