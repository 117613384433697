import { FC } from 'react';

import { ReportPeriodList } from './ReportPeriods.styled';
import { TReportPeriodsProps } from './ReportPeriods.types';
import { ReportPeriod } from './components/ReportPeriod';

export const ReportPeriods: FC<TReportPeriodsProps> = ({ report }) => {
  return (
    <ReportPeriodList className="ReportPeriodList">
      {report.items.map((item) => {
        return (
          <li key={item.id}>
            <ReportPeriod report={report} reportItem={item} />
          </li>
        );
      })}
    </ReportPeriodList>
  );
};
