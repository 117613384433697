import { ChangeEvent, FC, useContext, useMemo } from 'react';
import { Select } from 'react-ui-kit-exante';

import { CurrencySelect } from 'components/CurrencySelect';
import { TranslationContext } from 'contexts';
import { DepositContext } from 'pages/Portfolio/tabs/Deposit/contexts';

import {
  StyledDepositSectionBody,
  StyledDepositSectionHeading,
} from '../../../DepositWrapper/DepositWrapper.styled';

import { StyledCashAccountSectionWrapper } from './AccountCashSection.styled';

export const AccountCashSection: FC = () => {
  const { t } = useContext(TranslationContext);

  const {
    currencies,
    currency,
    setCurrency,
    banks,
    bank: bankAlias,
    setBank: setBankAlias,
  } = useContext(DepositContext);

  const options =
    banks?.map((bank) => ({
      value: bank.alias,
      label: bank.beneficiaryBank,
    })) || [];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBankAlias(e.target.value);
  };

  const currencySelectList = useMemo(
    () => currencies.list.map((ccy) => ccy.code),
    [currencies],
  );

  return (
    <StyledCashAccountSectionWrapper className="CashAccountSectionWrapper">
      <StyledDepositSectionHeading className="DepositSectionHeading">
        {t('layout__deposit__choose_account')}
      </StyledDepositSectionHeading>
      <StyledDepositSectionBody className="DepositSectionBody">
        <Select
          label={t('layout__deposit__bank_account')}
          onChange={handleChange}
          fullWidth
          options={options}
          {...(bankAlias && { value: bankAlias })}
          data-test-id="account__select__bank"
        />
      </StyledDepositSectionBody>
      <StyledDepositSectionBody className="DepositSectionBody">
        <CurrencySelect
          value={currency}
          onChange={setCurrency}
          options={currencySelectList}
          icons={currencies.icons}
          data-test-id="account__select__currency"
        />
      </StyledDepositSectionBody>
    </StyledCashAccountSectionWrapper>
  );
};
