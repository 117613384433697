import { createContext } from 'react';

export interface ILayoutContext {
  menu: IMenuInterface;
}

export interface IMenuInterface {
  opened: boolean;
  setOpened(isOpened: boolean): void;
}

export const LayoutContext = createContext<ILayoutContext>({
  menu: {
    opened: false,
    setOpened: () => {},
  },
});
