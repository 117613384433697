import { forwardRef, Ref } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbValueType } from 'react-ui-kit-exante';

import { TRouteData } from 'router';
import { TMenuItem } from 'services/menu';

import { StyledBreadcrumbsAnchor } from './Breadcrumbs.styled';
import {
  TPathChunk,
  TPathWithRouteData,
  TUseBreadcrumbsProps,
} from './Breadcrumbs.types';

export const getBreadcrumbs = ({
  defaultUrl,
  customHomeTitle,
  path: rawPath,
  translate,
}: TUseBreadcrumbsProps): BreadcrumbValueType[] => {
  const fref = (url: string, text: string) =>
    forwardRef(function BreadcrumbsForwardRef(
      linkProps,
      ref: Ref<HTMLAnchorElement>,
    ) {
      const isLinkExternal = url.includes('http');
      const props = {
        ...linkProps,
        ref,
        style: { textDecoration: 'none' },
      };

      return isLinkExternal ? (
        <StyledBreadcrumbsAnchor className="BreadcrumbsAnchor" href={url}>
          {text}
        </StyledBreadcrumbsAnchor>
      ) : (
        <Link to={url} {...props} />
      );
    });

  const getName = (chunk: TPathChunk): string => {
    const titleKey = (chunk.data as TRouteData)?.titleKey || '';
    return translate(titleKey);
  };

  let path: TPathWithRouteData = (rawPath as TPathWithRouteData).filter(
    (item) => !!item.data,
  );

  const nodeWithHiddenChildrenIndex: number = path.findIndex(({ data }) => {
    return (data as TRouteData).hideBreadcrumbsChildren;
  });

  if (nodeWithHiddenChildrenIndex !== -1) {
    path = path.slice(0, nodeWithHiddenChildrenIndex + 1);
  }

  return path.map((chunk, i): BreadcrumbValueType => {
    let text = getName(chunk);
    const {
      data: { useOnlyCustomLink, customLinkUrl },
      pathname,
    } = chunk;

    let url = customLinkUrl || pathname;
    let disabled = false;

    if (useOnlyCustomLink && !customLinkUrl) {
      disabled = true;
    }

    if (i === 0) {
      if (defaultUrl) {
        url = defaultUrl.includes('http')
          ? defaultUrl
          : window.location.origin + defaultUrl;
      } else {
        disabled = true;
      }

      if (customHomeTitle) {
        text = translate(customHomeTitle);
      }
    }

    return {
      text,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore -- ui kit type exports are still broken
      routerLink: fref(url, text),
      disabled,
    };
  });
};

export const sanitizeMenuItems = (
  menuItems: Array<TMenuItem | null> | undefined = [],
): TMenuItem[] => {
  return menuItems.filter<TMenuItem>(
    (item): item is TMenuItem => item !== null,
  );
};
