import { blockNonNativeProps } from 'react-ui-kit-exante';

import { TransferType } from 'services/transfer';
import { styled } from 'theme';

export const StyledAmountFormContainer = styled('div', {
  shouldForwardProp: blockNonNativeProps(['type']),
})<{ type?: TransferType | null }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: ${({ type }) =>
    type === TransferType.Crypto ? '24px 24px' : '40px 24px'};
  width: 334px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const StyledAmountFormHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledAmountFormHeadNotice = styled('span')`
  color: ${({ theme }) => theme.color.typo.secondary};
  font-size: 13px;
`;

export const StyledValuesBlock = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledAmountFormInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledAmountFormDescription = styled('div')`
  display: flex;
  gap: 8px;
  padding-left: 12px;
  flex-wrap: wrap;
`;

export const StyledAmountButton = styled('button')<{ disabled: boolean }>`
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.typo.ghost : theme.color.typo.action};
`;

export const StyledAmountInputsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledRemainContainer = styled('div')`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const StyledAllFundsSum = styled('span')`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;
