import { FC, useEffect, useMemo, useRef } from 'react';

import { AccountCard } from 'components/AccountCard';
import { ACCOUNT_ALL_ID } from 'constants/ACCOUNT_ALL_ID';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import {
  StyledAccountSelectionCarouselList,
  StyledAccountSelectionCarouselWrapper,
} from './AccountSelectionCarousel.styled';
import { TAccountSelectionCarouselProps } from './AccountSelectionCarousel.types';

export const AccountSelectionCarousel: FC<TAccountSelectionCarouselProps> = ({
  accountMetrics,
  onSelect,
  currency,
}) => {
  const { selectedAccountId } = useAppSelector(selectAccounts);

  const accountAll = accountMetrics.find(
    (acc) => acc.accountId === ACCOUNT_ALL_ID,
  );

  const accountsWithoutAll = useMemo(
    () => accountMetrics.filter((acc) => acc.accountId !== ACCOUNT_ALL_ID),
    [accountMetrics, currency],
  );

  const accountCardRef = useRef<HTMLButtonElement>(null);

  const selectedAccountRef = useRef<HTMLButtonElement>();
  const updateSelectedRef = (
    element: HTMLButtonElement | null,
    isSelected: boolean,
  ): void => {
    if (isSelected && element) {
      selectedAccountRef.current = element;
    }
  };

  useEffect(() => {
    selectedAccountRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [selectedAccountRef.current]);

  return (
    <StyledAccountSelectionCarouselWrapper className="AccountSelectionCarouselWrapper">
      {/* TODO Aggregated accounts picker */}
      {!!accountAll && (
        <AccountCard
          ref={accountCardRef}
          selectedAccountId={selectedAccountId}
          onClick={onSelect}
          account={accountAll}
          currency={currency}
          isAccountAll
        />
      )}
      <StyledAccountSelectionCarouselList
        className="AccountSelectionCarouselList"
        role="menu"
      >
        {accountsWithoutAll.map((account) => (
          <AccountCard
            ref={(el) =>
              updateSelectedRef(el, selectedAccountId === account.accountId)
            }
            selectedAccountId={selectedAccountId}
            key={account.accountId}
            account={account}
            onClick={onSelect}
            currency={currency}
          />
        ))}
      </StyledAccountSelectionCarouselList>
    </StyledAccountSelectionCarouselWrapper>
  );
};
