import { styled } from 'theme';

export const StyledDocumentsWrapper = styled('div')`
  display: grid;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-template-columns: 50% 50%;
  }
`;

export const StyledDocumentsSection = styled('div')`
  padding: 24px;

  &:first-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    &:first-of-type {
      border-bottom: none;
      border-right: 1px solid ${({ theme }) => theme.color.line.primary};
    }
  }
`;

export const StyledDocumentsHeading = styled('h2')`
  font-family: ${({ theme }) => theme.font.header};
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
`;

export const StyledDocumentsInfoBlock = styled('div')`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.color.typo.promo};
  font-family: ${({ theme }) => theme.font.main};
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;

  cursor: text;
`;

export const StyledDocumentsInfoText = styled('p')`
  color: ${({ theme }) => theme.color.typo.promo};
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin: 24px 0;

  ul {
    padding: 0;
    li {
      list-style-type: '—  ';
      list-style-position: inside;
    }
  }
`;

export const StyledDocumentsUploadGroups = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    gap: 16px;
  }
`;
