import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { FC, useContext, useMemo, useRef, useState } from 'react';
import { SearchInput } from 'react-ui-kit-exante';

import { NotificationsContext, TranslationContext } from 'contexts';
import { useOutsideClick } from 'hooks';
import { TNotification } from 'services/notifications';
import { useTheme } from 'theme';

import {
  StyledNotificationsBellIcon,
  StyledNotificationsCount,
  StyledNotificationsDropdown,
  StyledNotificationsHeader,
  StyledNotificationsList,
  StyledNotificationsSearch,
  StyledNotificationsToggleButton,
  StyledNotificationsWrapper,
} from './Notifications.styled';
import { Notification } from './components';

export const Notifications: FC = () => {
  const { notifications, isLoading, showModal } =
    useContext(NotificationsContext);

  const ref = useRef(null);

  const theme = useTheme();
  const { t } = useContext(TranslationContext);
  const [opened, setOpened] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const unreadCount = useMemo(
    () => notifications.filter(({ notified }) => !notified).length,
    [notifications],
  );

  const queriedNotifications = useMemo<TNotification[]>(() => {
    return notifications.filter(({ title, body, short_title }) => {
      const re = new RegExp(query, 'i');
      return [title, body, short_title].some((val) => re.test(val));
    });
  }, [notifications, query]);

  useOutsideClick(ref, () => setOpened(false));

  const onNotificationClick = (pk: TNotification['pk']) => {
    showModal(pk);
  };

  const shouldRender = !(isLoading || !notifications.length);

  return !shouldRender ? null : (
    <StyledNotificationsWrapper
      className={classNames('notifications', {
        'notifications--mobile': isMobile,
      })}
      ref={ref}
    >
      <StyledNotificationsToggleButton
        className={classNames('notifications__trigger', {
          'notifications__trigger--full': !isMobile,
          'has-unread': !!unreadCount,
        })}
        onClick={() => setOpened(!opened)}
        title={t('generic__notifications')}
        data-test-id={
          isMobile
            ? 'client-page__button--show-notifications-account-menu'
            : 'client-page__button--show-notifications-header'
        }
        aria-haspopup="listbox"
        aria-expanded={opened}
        hasUnread={!!unreadCount}
      >
        <StyledNotificationsBellIcon className="xnt-icon" />
        {isMobile && t('generic__notifications')}
      </StyledNotificationsToggleButton>

      {opened && (
        <StyledNotificationsDropdown className="notifications__dropdown">
          <StyledNotificationsHeader className="notifications__header">
            <div className="notifications__title">
              {t('generic__notifications')}{' '}
              {!!unreadCount && (
                <StyledNotificationsCount className="notifications__count">
                  {unreadCount}
                </StyledNotificationsCount>
              )}
            </div>
          </StyledNotificationsHeader>
          <StyledNotificationsSearch className="notifications__search">
            <SearchInput
              inputProps={{ value: query, fullWidth: true }}
              placeholder={t('layout__notifications__search_notifications')}
              onChange={(value) => setQuery(value)}
            />
          </StyledNotificationsSearch>
          <StyledNotificationsList className="notifications__list">
            {queriedNotifications.map((notification) => (
              <Notification
                {...notification}
                key={notification.pk}
                onClick={onNotificationClick}
              />
            ))}
          </StyledNotificationsList>
        </StyledNotificationsDropdown>
      )}
    </StyledNotificationsWrapper>
  );
};
