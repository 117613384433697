import { styled } from 'theme';

export const StyledTooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  padding: 16px;
  background-color: ${({ theme }) => theme.color.bg.primary};
  filter: ${({ theme }) => theme.filters.popup};
  position: relative;
  outline: none;
`;

export const StyledTooltipTitle = styled('div')`
  font-family: ${({ theme }) => theme.font.header};
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const StyledTooltipRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.main};
`;

export const StyledTooltipLabel = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledTooltipValue = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
`;

export const StyledPoint = styled('div')`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
`;
