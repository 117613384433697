import { TFunction } from 'i18next';
import { IColumn } from 'react-ui-kit-exante';

import {
  formatCellToCurrencyUnrounded,
  TCreateCurrencyFormatter,
} from 'helpers/formatters';
import { TMetricCurrency } from 'services/analysis';

type TCurrenciesColumns = {
  currency: string;
  t: TFunction;
  currencyFormatter: TCreateCurrencyFormatter;
};

export const defaultColumnKeys = [
  'currency',
  'interest',
  'convertedInterest',
  'conversionQuantity',
  'convertedConversionQuantity',
];

export const getColumns: ({
  currency,
  t,
  currencyFormatter,
}: TCurrenciesColumns) => IColumn<TMetricCurrency>[] = ({
  currency,
  t,
  currencyFormatter,
}) => [
  {
    id: 'currency',
    required: true,
    Header: t('generic__currency'),
    accessor: 'currency',
  },
  {
    id: 'interest',
    Header: t('generic__interest'),
    accessor: 'interest',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'interest'),
    sortType: 'numeric',
  },
  {
    id: 'convertedInterest',
    Header: `${t('generic__interest')}, ${currency}`,
    accessor: 'convertedInterest',
    Cell: formatCellToCurrencyUnrounded(currencyFormatter, 'convertedInterest'),
    sortType: 'numeric',
  },
  {
    id: 'conversionQuantity',
    Header: t('converted_volume'),
    accessor: 'conversionQuantity',
    Cell: formatCellToCurrencyUnrounded(
      currencyFormatter,
      'conversionQuantity',
    ),
    sortType: 'numeric',
  },
  {
    id: 'convertedConversionQuantity',
    Header: `${t('converted_volume')}, ${currency}`,
    accessor: 'convertedConversionQuantity',
    Cell: formatCellToCurrencyUnrounded(
      currencyFormatter,
      'convertedConversionQuantity',
    ),
    sortType: 'numeric',
  },
];
