import { Notification } from 'react-ui-kit-exante';

import { NOTIFICATION } from 'constants/NOTIFICATION';
import { i18nLocal } from 'helpers/translate';

export const serverError = (keyOrMessage: string | any) => {
  let translation: string;

  if (typeof keyOrMessage === 'string' && keyOrMessage.length) {
    translation = i18nLocal.translate([keyOrMessage, keyOrMessage]);
  } else {
    translation = NOTIFICATION.ERROR.GENERIC.MESSAGE;
  }

  Notification.error({ title: translation });
};
