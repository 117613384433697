import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { StyledNoDataNotification } from 'components/NoDataNotification';
import { CurrencyContext, TranslationContext } from 'contexts';
import { AccountDataContext } from 'contexts/AccountDataContext/AccountDataContext';
import {
  TabsCacheApiContext,
  TabsCacheDataContext,
} from 'contexts/TabsCacheContext';
import { TAccountMargin } from 'services/margin';
import { selectAccounts, selectSelectedDate } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { UpdatePortfolioContext } from '../../contexts';

import { TMarginStructureCache } from './MarginStructure.types';
import { MarginAccordion } from './components';
import { useMarginData } from './hooks';

export const MarginStructure: FC = () => {
  const { needToUpdateAccountData } = useContext(UpdatePortfolioContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const selectedDate = useAppSelector(selectSelectedDate);
  const { t } = useContext(TranslationContext);
  const { currency } = useContext(CurrencyContext);
  const { getCache } = useContext(TabsCacheDataContext);
  const { setCache } = useContext(TabsCacheApiContext);
  const { isLoading: isAccountDataLoading } = useContext(AccountDataContext);
  const [silentUpdate, setSilentUpdate] = useState<boolean>(false);

  const {
    marginData: serverMarginData,
    onUpdateData,
    isMarginDataLoading,
  } = useMarginData({
    currency,
    date: selectedDate,
    accountId: selectedAccountId,
  });

  const cacheId = useMemo<string>(() => {
    return ['portfolio-margin', currency, selectedAccountId].join('.');
  }, [currency, selectedAccountId]);

  const cachedMarginData = useMemo<TAccountMargin | null>(() => {
    return getCache<TMarginStructureCache>(cacheId)?.marginData || null;
  }, [getCache, cacheId]);

  const useCache = isMarginDataLoading && !!cachedMarginData;

  const marginData = useMemo<TAccountMargin | null>(() => {
    if (useCache) {
      return cachedMarginData;
    }

    return serverMarginData;
  }, [isMarginDataLoading, cachedMarginData, serverMarginData]);

  const updateData = async (silent = false) => {
    if (silent) {
      setSilentUpdate(true);
    }

    await onUpdateData();

    if (silent) {
      setSilentUpdate(false);
    }
  };

  useEffect(() => {
    if (needToUpdateAccountData && !isMarginDataLoading) {
      updateData(true);
    }
  }, [needToUpdateAccountData]);

  useEffect(() => {
    if (!isMarginDataLoading && serverMarginData) {
      setCache<TMarginStructureCache>(cacheId, {
        marginData: serverMarginData,
      });
    }
  }, [isMarginDataLoading, serverMarginData, setCache]);

  const shouldTableBeLoading: boolean =
    (isMarginDataLoading || isAccountDataLoading) && !useCache && !silentUpdate;

  const isMarginDataEmpty: boolean =
    marginData === null ||
    !Object.values(marginData).some((dataArray) => dataArray?.length);

  return !shouldTableBeLoading && (!marginData || isMarginDataEmpty) ? (
    <StyledNoDataNotification className="NoDataNotification">
      {t('layout__margin_structure__not_available')}
    </StyledNoDataNotification>
  ) : (
    <MarginAccordion data={marginData} isLoading={shouldTableBeLoading} />
  );
};
