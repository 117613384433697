import { useMemo } from 'react';

import { Dates } from 'helpers/dates';
import { defaultLocaleFormatter, formatCurrency } from 'helpers/formatters';

import { StyledMark } from './PositiveNegative.styled';
import {
  TPositiveNegativeProps,
  TValueMarkType,
} from './PositiveNegative.types';

function getMarkValueType(value?: string | number | null): TValueMarkType {
  const numbered = Number(value);

  if (numbered === 0) {
    return 'zero';
  }
  return numbered > 0 ? 'positive' : 'negative';
}

export const PositiveNegative = ({
  value,
  valueFormatter,
  expirationTime,
  hideZero = false,
  withoutFormat = false,
}: TPositiveNegativeProps) => {
  const markType = useMemo(() => getMarkValueType(value), [value]);
  const expired = Dates.isExpired(expirationTime);

  const formatted = useMemo(() => {
    if (!hideZero || (value && Number(value) !== 0)) {
      if (withoutFormat) {
        return value;
      }
      return valueFormatter
        ? valueFormatter(value)
        : formatCurrency(defaultLocaleFormatter, value);
    }

    return '';
  }, [value, valueFormatter]);

  return (
    <StyledMark expired={expired} markType={markType} className="Mark">
      {formatted}
    </StyledMark>
  );
};
