import { initialFetchState, initialUpdateState } from 'constants/state';

import { ToMyAccountState } from '../../../types';

export const initialToMyAccountState: ToMyAccountState = {
  accounts: [],
  currency: '',
  isAllFunds: false,
  fetchingState: initialFetchState,
  updatingState: initialFetchState,
  limit: '',
  requestingTransactionState: initialUpdateState,
  targetAccountId: '',
};
