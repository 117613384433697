import { Notification } from 'react-ui-kit-exante';

import { NOTIFICATION } from 'constants/NOTIFICATION';
import { i18nLocal } from 'helpers/translate';

export const genericError = () => {
  const translation = i18nLocal.translate([
    NOTIFICATION.ERROR.GENERIC.KEY,
    NOTIFICATION.ERROR.GENERIC.MESSAGE,
  ]);

  Notification.error({ title: translation });
};
