import { isCancelledError } from 'helpers/abortController/abortController.helpers';
import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import {
  TAccountManagementData,
  TAccountManagementResponse,
  TAccountsTableData,
  TAccountsTableResponse,
  TAutoDebitAccountData,
  TAutoDebitAccountResponse,
  TChangeDescriptionResponse,
  TChangePaymentAccountData,
  TDemoAvailableResponse,
  TDemoStatusResponse,
  TGetTableAccountsParams,
  TPaymentAccountData,
  TRefillDemoResponse,
  TResetDemoResponse,
  TSetConversionCurrencyResponse,
} from './accountSettings.types';

class AccountSettingsService extends ApiRequest {
  accountsTableEmpty: TAccountsTableData = {
    data: {
      accounts: [],
      currencies: { icons: {}, list: [] },
    },
    iTotalRecords: 0,
  };

  autoDebitDataEmpty: TAutoDebitAccountData = {
    accounts: [],
    choices: [],
    enabled: false,
    descriptions: {},
  };

  async getAccountManagement(lang = 'en'): Promise<TAccountManagementData> {
    const failedResponse = { allowAddAccount: false, limits: [], urls: {} };

    try {
      const response = await this.fetch<TAccountManagementResponse>({
        url: `/${lang}/clientsarea/spa-layout/?name=account-management`,
      });

      return response.data?.['account-management'] || failedResponse;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return failedResponse;
    }
  }

  async createNewAccount(
    createNewUrl: string,
    clientId: string,
  ): Promise<boolean> {
    try {
      const response = await this.fetch({
        method: 'POST',
        url: createNewUrl,
        data: { client_id: clientId },
      });

      return !!response;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);
      return false;
    }
  }

  async getDemoAccountName(lang = 'en'): Promise<string | null> {
    try {
      const { data } = await this.fetch<string>({
        url: `/${lang}/clientsarea/rest/account/settings/`,
        params: { demo: true },
      });

      return data || null;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return null;
    }
  }

  async getDemoAvailable(lang = 'en'): Promise<boolean> {
    try {
      const { data } = await this.fetch<TDemoAvailableResponse>({
        url: `/${lang}/clientsarea/spa-layout/`,
        params: { name: 'reset-demo' },
      });

      return Boolean(data?.['reset-demo']?.enabled);
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('getDemoAvailable', error);

      return false;
    }
  }

  async resetDemoAccount(lang = 'en'): Promise<TResetDemoResponse | null> {
    try {
      const response = await this.fetch<TResetDemoResponse>({
        method: 'POST',
        url: `/${lang}/clientsarea/account/reset-demo/recreate/`,
      });

      return response.data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return null;
    }
  }

  async getResetDemoStatus(): Promise<TDemoStatusResponse | null> {
    try {
      const response = await this.fetch<TDemoStatusResponse>({
        method: 'GET',
        url: `/clientsarea/account/reset-demo/status/`,
      });

      return response.data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return null;
    }
  }

  async refillDemo(lang = 'en'): Promise<TRefillDemoResponse | null> {
    try {
      const response = await this.fetch<TRefillDemoResponse>({
        method: 'POST',
        url: `/${lang}/clientsarea/account/reset-demo/refill/`,
      });

      return response.data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return null;
    }
  }

  async getTableAccounts({
    params,
    clientId,
    lang,
    searchedAccountId,
    options,
  }: TGetTableAccountsParams): Promise<TAccountsTableData> {
    try {
      const response = await this.fetch<TAccountsTableResponse>({
        url: `/${lang}/clientsarea/rest/account/settings/`,
        params: {
          iDisplayLength: params.limit,
          iDisplayStart: params.limit * params.page,
          client_id: clientId,
          account_id: searchedAccountId,
        },
        signal: options?.signal,
      });

      return response.data.data;
    } catch (error: any) {
      if (isCancelledError(error)) {
        return this.accountsTableEmpty;
      }

      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return this.accountsTableEmpty;
    }
  }

  async getAutoDebitData(lang = 'en'): Promise<TAutoDebitAccountData> {
    try {
      const response = await this.fetch<TAutoDebitAccountResponse>({
        url: `/${lang}/clientsarea/spa-layout/?name=autodebit`,
      });

      return response.data.autodebit;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return this.autoDebitDataEmpty;
    }
  }

  async getPaymentAccountData(
    accountId: string,
    lang = 'en',
  ): Promise<TPaymentAccountData> {
    try {
      const response = await this.fetch<TPaymentAccountData>({
        url: `/${lang}/clientsarea/rest/autodebit/${accountId}/`,
      });

      return response.data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return { autodebitAccount: null, enabled: false };
    }
  }

  async switchMarginTrading(
    accountId: string,
    enabled: boolean,
    lang = 'en',
  ): Promise<boolean | null> {
    try {
      const response = await this.fetch<boolean>({
        method: enabled ? 'DELETE' : 'POST',
        url: `/${lang}/clientsarea/rest/margin-trading/${accountId}/`,
      });

      return response?.data ?? null;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return enabled;
    }
  }

  async changeAccountDescription(
    accountId: string,
    newDescription: string,
    lang = 'en',
  ): Promise<string | null> {
    try {
      const response = await this.fetch<TChangeDescriptionResponse>({
        method: 'PATCH',
        url: `/${lang}/clientsarea/account/settings/account-descriptions/${accountId}/`,
        data: {
          description: newDescription,
        },
      });

      return response.data.description;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return null;
    }
  }

  async changeAutoDebitAccount(
    accountId: string,
    accountForAutoDebit: string,
    lang = 'en',
  ): Promise<TPaymentAccountData | null> {
    try {
      const accountToSet =
        accountForAutoDebit === 'disabled' ? null : accountForAutoDebit;

      const response = await this.fetch<TChangePaymentAccountData>({
        method: 'POST',
        url: `/${lang}/clientsarea/rest/autodebit/${accountId}/`,
        data: {
          account_id: accountToSet,
          enabled: !!accountToSet,
        },
      });

      notifyWith.success('account_settings_success_notification');
      return response.data;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return null;
    }
  }

  async setAutoConversionCurrency(
    accountId: string,
    currency: string,
    lang = 'en',
  ): Promise<string> {
    try {
      const response = await this.fetch<TSetConversionCurrencyResponse>({
        method: 'POST',
        url: `/${lang}/clientsarea/account/cash-conversion/update/${accountId}/`,
        data: {
          currency,
        },
      });

      return response.data.currency;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('accountSettingsService', error);

      return currency;
    }
  }
}

export const accountSettingsService = new AccountSettingsService();
