import { get, omit, pick, set } from 'lodash';

import { PATH_REGEXP } from 'constants/instrumentsTree';

import {
  TCommission,
  TMappedTreeResponse,
  TOvernight,
  TPermissions,
  TTreeModule,
  TTreeResponse,
  TTreeResponseTreeStructure,
} from './instrumentsTree.types';

export const DEFAULT_TREE_SET = {
  permissions: {
    short: false,
  },
  commission: {
    commissionPercent: '',
    commissionFixed: '',
    minimumFee: '',
    currency: '',
  },
  overnight: {
    positive: '',
    negative: '',
  },
};

export function convertStructureFromFlatToTree(
  items: TTreeResponse[],
  module: TTreeModule,
): TMappedTreeResponse {
  const tree: TTreeResponseTreeStructure[] = [];
  const matchIdAndPositionInNonSearchTable: Record<string, string | number> =
    {};
  const newInstrumentsByPath: Record<
    string,
    TPermissions | TCommission | TOvernight
  > = {};

  items.forEach((item) => {
    const [parent, current] = item.path.split(PATH_REGEXP);

    if (!current) {
      const pushItem: TTreeResponseTreeStructure = {
        ...DEFAULT_TREE_SET[module],
        ...omit(item, module),
        ...item?.[module],
        ...(module === TTreeModule.COMMISSION
          ? {
              currency: item?.[module]?.currency || item?.currency,
            }
          : {}),
        rowType: 'node',
      };

      tree.push(pushItem);
      newInstrumentsByPath[item.path] = pick(
        pushItem,
        Object.keys(DEFAULT_TREE_SET[module]),
      ) as TPermissions | TCommission;
      matchIdAndPositionInNonSearchTable[item.path] = tree.length - 1;
    } else {
      const subRows: TTreeResponseTreeStructure[] = get(
        tree,
        `${matchIdAndPositionInNonSearchTable[parent]}.subRows`,
        [],
      );
      subRows.push({
        ...DEFAULT_TREE_SET[module],
        ...omit(item, module),
        ...item?.[module],
        ...(module === TTreeModule.COMMISSION
          ? {
              currency: item?.[module]?.currency || item?.currency,
            }
          : {}),
        displayName: item.displayName || current,
        rowType: 'node',
      });

      matchIdAndPositionInNonSearchTable[item.path] = `${
        matchIdAndPositionInNonSearchTable[parent]
      }.subRows.${subRows.length - 1}`;

      set(
        tree,
        `${matchIdAndPositionInNonSearchTable[parent]}.subRows`,
        subRows,
      );
    }
  });

  return { tree, matchIdAndPositionInNonSearchTable, newInstrumentsByPath };
}
