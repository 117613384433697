import { blockNonNativeProps, css } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { FeatureColors } from './Features.constants';
import { TFeaturesListItemProps } from './Features.types';

export const StyledFeatures = styled('article')`
  padding: 24px;
  margin-bottom: 24px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 8px 80px 64px;
    margin-bottom: 0;
  }
`;

export const StyledFeaturesHeading = styled('h2')`
  margin: 0 24px 16px;
  font-family: ${({ theme }) => theme.font.header};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;

  strong {
    color: #32b4ff;
    font-weight: 700;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 32px;
    line-height: 48px;
  }
`;

export const StyledFeaturesDesc = styled('p')`
  font-size: 15px;
  font-family: ${({ theme }) => theme.font.main};
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 16px;
  color: ${({ theme }) => theme.color.typo.secondary};
  opacity: 0.7;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 30%;
    margin-bottom: 32px;
  }
`;

export const StyledFeaturesList = styled('ul')`
  padding: 0;
  margin: 0;
  list-style: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 24px;
    margin-bottom: 32px;
  }
`;

export const StyledFeaturesListItem = styled('li', {
  shouldForwardProp: blockNonNativeProps(['color', 'imageUrl']),
})<TFeaturesListItemProps>`
  padding: 24px;
  margin: 0 0 16px;

  text-align: left;
  color: ${({ theme }) => theme.color.typo.inverse};

  border-radius: 8px;
  background-image: linear-gradient(45deg, #880e4f 0%, #e91e63 100%);
  background-repeat: no-repeat, no-repeat;
  background-position:
    bottom right,
    left;
  box-shadow:
    0 24px 32px 0 rgba(0, 0, 0, 0.04),
    0 16px 24px 0 rgba(0, 0, 0, 0.04),
    0 4px 8px 0 rgba(0, 0, 0, 0.04),
    0 0 1px 0 rgba(0, 0, 0, 0.04);

  &:last-of-type {
    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-bottom: 200px;
    }
  }

  ${({ color, imageUrl }) => {
    switch (color) {
      case FeatureColors.GRAY: {
        return css`
          background-image: url(${imageUrl}),
            linear-gradient(45deg, #252c2e 0%, #4e5d60 100%);
          background-size:
            140px 102px,
            auto;
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        `;
      }
      case FeatureColors.VIOLET: {
        return css`
          background-image: url(${imageUrl}),
            linear-gradient(45deg, #311b92 0%, #673ab6 100%);
          background-size:
            140px 102px,
            auto;
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        `;
      }
      case FeatureColors.GREEN: {
        return css`
          background-image: url(${imageUrl}),
            linear-gradient(45deg, #007f39 0%, #409f6b 100%);
          background-size:
            140px 102px,
            auto;
          grid-column: 1 / 3;
          grid-row: 2 / 3;
          display: flex;
          flex-direction: column;
          justify-content: center;
        `;
      }
      case FeatureColors.BLUE: {
        return css`
          background-image: url(${imageUrl}),
            linear-gradient(45deg, #0d47a1 0%, #2196f3 100%);
          background-size:
            300px 172px,
            auto;
          background-position:
            30px 100%,
            0 0;
          grid-column: 3 / 4;
          grid-row: 1 / 3;

          p {
            max-width: 100%;
          }
        `;
      }
      default:
        return '';
    }
  }}
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
  }
`;

export const StyledFeaturesItemTitle = styled('h3')`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-family: ${({ theme }) => theme.font.header};
  margin: 0 0 8px;
`;

export const StyledFeaturesItemDesc = styled('p')`
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.7;
  max-width: 60%;
`;
