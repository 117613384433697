import { TStyledSubHeaderTitleProps } from 'components/SubHeader/SubHeader.types';
import { styled } from 'theme';

export const StyledSubHeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 24px;
`;

export const StyledSubHeaderTitle = styled('div')<TStyledSubHeaderTitleProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ size }) => (size === 'big' ? '24px' : '20px')};
  line-height: 24px;
  color: ${({ theme }) => theme.color.typo.primary};
`;
