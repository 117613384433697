import { useEffect, useMemo, useState } from 'react';

import { TDescription } from 'services/settings/accountSettings';

import { TUseAccountsDescriptionsValue } from './useAccountsDescriptions.types';

export const useAccountsDescriptions = (
  providedDescriptions: Record<string, TDescription>,
): TUseAccountsDescriptionsValue => {
  const [descriptions, setDescriptions] = useState(providedDescriptions || {});

  useEffect(() => {
    if (providedDescriptions) {
      setDescriptions(providedDescriptions);
    }
  }, [providedDescriptions]);

  return useMemo(
    () => ({
      accountDescriptions: descriptions,
      updateAccountDescription(accountId: string, newDescription: string) {
        setDescriptions((list) => ({
          ...list,
          [accountId]: {
            ...list[accountId],
            description: newDescription,
          },
        }));
      },
    }),
    [descriptions],
  );
};
