import i18next from 'i18next';
import HttpApi, {
  HttpBackendOptions,
  RequestCallback,
} from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { INTERPOLATION } from 'constants/INTERPOLATION';
import { languagesService } from 'services/languages';
import { LOCALIZATION_URL, translateService } from 'services/translate';

const initI18nextWithLang = async () => {
  try {
    return await languagesService.getCurrentLanguage();
  } catch (e: any) {
    return 'en';
  }
};

i18next
  .use(HttpApi)
  .use({
    type: 'languageDetector',
    init: () => {},
    async: true,
    detect: async (callback: any) => {
      const lang = await initI18nextWithLang();
      localStorage.setItem('lang', lang);
      callback(lang);
    },
    lookupCookie: 'django_lang_1',
    cacheUserLanguage: () => {},
  })
  .use(initReactI18next)
  .init(
    {
      initImmediate: false,
      returnEmptyString: false,
      fallbackLng: {
        zh: ['zh-cn', 'en'],
        uk: ['uk-ua'],
        default: [localStorage.getItem('lang') || 'en', 'en'],
      },
      lowerCaseLng: true,
      defaultNS: 'translation',
      maxRetries: 1,
      load: 'all',
      interpolation: {
        prefix: INTERPOLATION.BEFORE,
        suffix: INTERPOLATION.AFTER,
      },
      backend: {
        loadPath: LOCALIZATION_URL,
        parse: (data: string) => JSON.parse(data),
        async request(
          options: HttpBackendOptions,
          url: string,
          payload: Record<string, unknown> | string,
          callback: RequestCallback,
        ) {
          const response = await translateService.getTranslations(url);

          if (!response) {
            callback(null, {
              status: 0,
              data: '',
            });

            return;
          }

          callback(null, {
            status: response.status,
            data: JSON.stringify(response.data),
          });
        },
      },
      react: {
        useSuspense: false,
      },
      returnNull: false,
      returnDetails: false,
    },
    (error) => {
      if (error) {
        console.error('localization error', error);
      }
    },
  );

export const i18nLocal = {
  translate: i18next.t,
};

declare module 'i18next' {
  // remove this after updating i18next to version >=23
  interface CustomTypeOptions {
    returnNull: false;
    returnDetails: false;
  }
}
