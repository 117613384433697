import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { BrandingContext } from 'contexts/BrandingContext';
import { UserDetailsContext } from 'contexts/UserDetailsContext';
import { tokensService } from 'services/tokens';
import type { TOneTimeToken } from 'services/tokens/tokens.types';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { generateQRLink } from '../components/AccountSelectionTitle/AccountSelectionTitle.helpers';

const QR_TOKEN_REQUEST_TIMEOUT = 5000;
const QR_TOKEN_REQUEST_DEBOUNCE_DELAY = 3000;

export const useGetOneTimeToken = () => {
  const [oneTimeToken, setOneTimeToken] = useState<null | TOneTimeToken>(null);
  const [isExpired, setIsExpired] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { branding } = useContext(BrandingContext);
  const { userDetails } = useContext(UserDetailsContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const errorTimerId = useRef<NodeJS.Timeout | null>(null);
  const expiredTimerId = useRef<NodeJS.Timeout | null>(null);

  const host = branding?.branding?.deep_link_host || null;
  const email = userDetails?.info?.current?.email;
  const link = generateQRLink(host, oneTimeToken, selectedAccountId);

  const clearTimer = useCallback((id: NodeJS.Timeout | null) => {
    if (id) {
      clearTimeout(id);
    }
  }, []);

  const clearAllTimers = useCallback(() => {
    clearTimer(errorTimerId.current);
    clearTimer(expiredTimerId.current);
  }, []);

  const getTokens = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    clearAllTimers();

    errorTimerId.current = setTimeout(() => {
      setIsError(true);
      setIsLoading(false);
    }, QR_TOKEN_REQUEST_TIMEOUT);

    tokensService
      .getOneTimeToken()
      .then((token: TOneTimeToken | null) => {
        if (token) {
          const { username: OTUsername, expires_in: expiresIn } = token;
          const username = OTUsername || email || 'User';

          clearAllTimers();

          setOneTimeToken({
            ...token,
            username: username?.replace('@', '%40'),
          });
          setIsExpired(false);
          expiredTimerId.current = setTimeout(() => {
            setIsExpired(true);
          }, expiresIn);
        } else {
          throw new Error('one-time-token error: token is null');
        }
      })
      .catch((e: Error) => {
        setIsError(true);
        console.warn(e.message);
      })
      .finally(() => {
        setIsLoading(false);
        clearTimer(errorTimerId.current);
      });
  }, [clearTimer, clearAllTimers]);

  const getTokensDebounced = useDebouncedCallback(
    getTokens,
    QR_TOKEN_REQUEST_DEBOUNCE_DELAY,
    { leading: true },
  );

  useEffect(() => {
    if (isExpired) {
      getTokens();
    }
  }, [isExpired, getTokens]);

  return {
    getTokensDebounced,
    getTokens,
    oneTimeToken,
    link,
    isError,
    isLoading,
  };
};
