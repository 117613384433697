import { blockNonNativeProps, css } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TMarkProps } from './PositiveNegative.types';

export const StyledMark = styled('mark', {
  shouldForwardProp: blockNonNativeProps(['markType', 'isPositive', 'expired']),
})<TMarkProps>(({ theme, markType, expired }) => {
  const colorsMap = {
    positive: theme?.color?.typo?.source,
    negative: theme?.color?.typo?.radical,
    zero: theme?.color?.typo?.promo,
  };

  const defaultColor = colorsMap[markType];
  const expiredColor = theme?.color?.typo?.ghost;
  const color = expired ? expiredColor : defaultColor;

  return css`
    background-color: transparent;
    color: ${color};
  `;
});
