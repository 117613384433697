import { Panel } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledMarginWrapper = styled('div')`
  min-height: 100%;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
  }
`;

export const StyledMarginPanel = styled(Panel)`
  padding: 16px 0 0;

  .PanelHeading {
    font-size: 0;
    line-height: 1;
  }

  .PanelTitle {
    margin-bottom: 16px;
  }
`;

export const StyledTotalDownloadLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.color.typo.primary,
  fontSize: 0,
  ' > svg': {
    width: 32,
    height: 32,
    color: theme.color.icon.secondary,
  },
}));
