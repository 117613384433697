import { useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { Skeleton } from 'react-ui-kit-exante';

import { StyledSigninWrapper } from '../../../Signin/Signin.styled';

export const RecoveryCompleteSkeleton: FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <StyledSigninWrapper className="SigninWrapper">
      {/* logo */}
      {isDesktop && <Skeleton width="60%" height={48} />}

      {/* title */}
      <Skeleton width="80%" height={32} />

      {/* inputs */}
      <Skeleton width="100%" height={48} />
      <Skeleton width="100%" height={48} />

      {/* buttons */}
      <Skeleton width="100%" height={48} />
      <Skeleton width="100%" height={48} />
    </StyledSigninWrapper>
  );
};
