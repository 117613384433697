export const getEffectiveZIndex = (element: Element): number => {
  let zIndex = getComputedStyle(element).getPropertyValue('z-index');

  while (zIndex === 'auto' && element && element.tagName !== 'HTML') {
    element = element.parentElement as Element;
    zIndex = getComputedStyle(element).getPropertyValue('z-index');
  }

  return zIndex === 'auto' ? 0 : parseInt(zIndex, 10);
};

export const isElementOverlapped = (element: Element): boolean => {
  const rect = element.getBoundingClientRect();

  // check by 30px steps of the element
  for (let x = rect.right; x >= rect.left; x -= 30) {
    for (let y = rect.bottom; y >= rect.top; y -= 30) {
      const topmostElement = document.elementFromPoint(x, y);

      if (
        topmostElement &&
        topmostElement !== element &&
        element.contains(topmostElement) === false
      ) {
        const elementIndex = getEffectiveZIndex(element);
        const topmostElementIndex = getEffectiveZIndex(topmostElement);

        if (topmostElementIndex > elementIndex) {
          return true;
        }
      }
    }
  }

  return false;
};
