import { FC, useContext, useEffect, useRef, useState } from 'react';

import { INTERPOLATION } from 'constants/INTERPOLATION';
import { BASE_URL } from 'constants/endpoints';
import { TranslationContext } from 'contexts';
import { DepositCryptoContext } from 'pages/Portfolio/tabs/Deposit/contexts';

import { StyledCryptoScanWrapper } from '../../RequisitesCryptoSection.styled';

import {
  StyledCryptoQrImg,
  StyledCryptoQrSkeleton,
  StyledCryptoScanHeading,
  StyledCryptoScanHeadingSkeleton,
  StyledCryptoScanSubheading,
  StyledCryptoScanSubheadingSkeleton,
  StyledCryptoScanText,
} from './CryptoScan.styled';

export const CryptoScan: FC = () => {
  const { t } = useContext(TranslationContext);
  const { isLoading, currency, address, isLoadingAddress } =
    useContext(DepositCryptoContext);

  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const prevAddress = useRef<string | null>();

  useEffect(() => {
    if (prevAddress.current !== address) {
      setIsLoadingImage(true);
    }

    prevAddress.current = address;
  }, [address]);

  const showSkeletons = isLoading || isLoadingAddress;
  const showQrSkeleton = showSkeletons || isLoadingImage || !address;

  const qrSrc = `${BASE_URL}/clientsarea/crypto/qr/?address=${address}`;

  return (
    <StyledCryptoScanWrapper className="CryptoScanWrapper">
      {showQrSkeleton && (
        <StyledCryptoQrSkeleton variant="rounded" animation="wave" />
      )}
      <StyledCryptoQrImg
        className="CryptoQrImg"
        src={qrSrc}
        alt="qr code"
        onLoad={() => setIsLoadingImage(false)}
        hidden={showQrSkeleton}
      />
      <StyledCryptoScanText className="CryptoScanText">
        {showSkeletons ? (
          <>
            <StyledCryptoScanHeadingSkeleton
              variant="rounded"
              animation="wave"
            />
            <StyledCryptoScanSubheadingSkeleton
              variant="rounded"
              animation="wave"
            />
            <StyledCryptoScanSubheadingSkeleton
              variant="rounded"
              animation="wave"
            />
          </>
        ) : (
          <>
            <StyledCryptoScanHeading className="CryptoScanHeading">
              {t('generic__wallet__scan_to_deposit', {
                CURRENCY: currency,
              })}
            </StyledCryptoScanHeading>
            <StyledCryptoScanSubheading className="CryptoScanSubheading">
              {t('generic__wallet__qr_or_address', {
                replace: { CURRENCY: currency },
                interpolation: {
                  prefix: INTERPOLATION.LEGACY.CURLY.BEFORE,
                  suffix: INTERPOLATION.LEGACY.CURLY.AFTER,
                },
              })}
            </StyledCryptoScanSubheading>
          </>
        )}
      </StyledCryptoScanText>
    </StyledCryptoScanWrapper>
  );
};
