import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts';

import { DepositHistoryTable } from '../DepositHistoryTable';
import {
  StyledDepositHead,
  StyledDepositTableHeading,
} from '../DepositWrapper/DepositWrapper.styled';

import { AccountCryptoSection } from './components/AccountCryptoSection';
import { InfoCryptoSection } from './components/InfoCryptoSection';
import { RequisitesCryptoSection } from './components/RequisitesCryptoSection';

export const DepositCrypto: FC = () => {
  const { t } = useContext(TranslationContext);

  return (
    <>
      <StyledDepositHead className="DepositHead">
        <AccountCryptoSection />
        <RequisitesCryptoSection />
      </StyledDepositHead>

      <StyledDepositHead className="DepositHeadSub">
        <InfoCryptoSection />
      </StyledDepositHead>

      <StyledDepositTableHeading className="DepositTableHeading">
        {t('generic__history')}
      </StyledDepositTableHeading>

      <DepositHistoryTable />
    </>
  );
};
