import { FC, useContext } from 'react';

import { TranslationContext } from 'contexts';

import { TaxReportAction } from '../TaxReportAction';
import { TaxReportConditions } from '../TaxReportConditions';

import {
  StyledTaxReportDetailsList,
  StyledTaxReportDetailsListItem,
  StyledTaxReportDetailTitle,
  StyledTaxReportDetailValue,
  StyledTaxReportUnionHead,
  StyledTaxReportUnionTitle,
  StyledTaxReportUnionWrapper,
} from './TaxReportUnion.styled';
import { TTaxReportUnionProps } from './TaxReportUnion.types';

export const TaxReportUnion: FC<TTaxReportUnionProps> = ({
  report,
  title,
  supportEmail,
}) => {
  const { t } = useContext(TranslationContext);

  return (
    <StyledTaxReportUnionWrapper className="TaxReportUnionWrapper">
      <StyledTaxReportUnionHead className="TaxReportUnionHead">
        <StyledTaxReportUnionTitle className="TaxReportUnionTitle">
          {t(title)}
        </StyledTaxReportUnionTitle>
        <TaxReportAction report={report} iconSize="l" />
      </StyledTaxReportUnionHead>
      <TaxReportConditions report={report} supportEmail={supportEmail} />
      <StyledTaxReportDetailsList className="TaxReportDetailsList">
        <StyledTaxReportDetailsListItem className="TaxReportDetailsListItem">
          <StyledTaxReportDetailTitle className="TaxReportDetailTitle">
            {t('generic__year')}
          </StyledTaxReportDetailTitle>
          <StyledTaxReportDetailValue className="TaxReportDetailValue">
            {report.tax_year || '-'}
          </StyledTaxReportDetailValue>
        </StyledTaxReportDetailsListItem>
        <StyledTaxReportDetailsListItem className="TaxReportDetailsListItem">
          <StyledTaxReportDetailTitle className="TaxReportDetailTitle">
            {t('reports__tax__table__account')}
          </StyledTaxReportDetailTitle>
          <StyledTaxReportDetailValue className="TaxReportDetailValue">
            {report.tndfl_account || '-'}
          </StyledTaxReportDetailValue>
        </StyledTaxReportDetailsListItem>
      </StyledTaxReportDetailsList>
    </StyledTaxReportUnionWrapper>
  );
};
