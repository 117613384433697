import { styled } from 'theme';

export const StyledExternalAccountsBlock = styled('div')`
  padding: 24px;
  width: 100%;
`;

export const StyledExternalAccountsLabel = styled('p')`
  font-size: 15px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 0;
`;

export const StyledExternalAccountsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 40px;
  margin-bottom: 24px;
`;

export const StyledErrorLabel = styled('span')`
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.color.notifications.error};
`;
