import { ApiRequest } from 'helpers/apiRequest';
import { notifyWith } from 'helpers/notifyWith';

import { TSignW8AffidavitWithTokenParams } from './agreements.types';

class SettingsAgreementsService extends ApiRequest {
  async getToken(code: string, service: string): Promise<string | false> {
    try {
      const data = await this.fetch<string>({
        url: `/clientsarea/rest/mfa/token/`,
        method: 'POST',
        data: {
          code,
          service,
          value: code,
        },
      });

      return data.data;
    } catch (error: any) {
      if (error.response?.status === 400) {
        return false;
      }
      notifyWith.genericNetworkError(error);
      console.error('getToken', error);

      return false;
    }
  }

  async signW8AffidavitWithToken({
    token,
    legalEntity,
    changesText,
    w8Types,
    signUrl,
  }: TSignW8AffidavitWithTokenParams): Promise<boolean> {
    try {
      await this.fetch<any>({
        url: signUrl,
        method: 'POST',
        data: {
          token,
          legal_entity: legalEntity,
          changes_text: changesText,
          w8_types: w8Types,
        },
      });

      return true;
    } catch (error: any) {
      notifyWith.genericNetworkError(error);
      console.error('signW8AffidavitWithToken', error);

      return false;
    }
  }
}

export const settingsAgreementsService = new SettingsAgreementsService();
