import { TAssetTitleProps } from 'components/Positions/components/AssetTitle/AssetTitle.types';

import { InstrumentIcon } from '../../../InstrumentIcon';

import { AssetTitleStyled, AssetTitleWrapper } from './AssetTitle.styled';

export const AssetTitle = ({ title, iconName }: TAssetTitleProps) => {
  return (
    <AssetTitleWrapper className="AssetTitle">
      <InstrumentIcon iconName={iconName} className="AssetTitleIcon" />
      <AssetTitleStyled className="AssetTitleText">{title}</AssetTitleStyled>
    </AssetTitleWrapper>
  );
};
