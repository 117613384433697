import { TFunction } from 'i18next';

import { TAccountsMetrics, TTotal } from 'services/analysis';
import { TPerformance } from 'services/performance';

export const getPeriodData = (
  t: TFunction,
  total?: TTotal,
  config?: TPerformance | null,
) => {
  const periodMetrics = config?.totalItems?.flatMap((item) => {
    return item.map((totalItem) => {
      return {
        ...totalItem,
        value: total?.[totalItem.id as keyof TTotal],
      };
    });
  });

  const periodStatistic = config?.otherTotalItems?.flatMap((item) => {
    return item.map((totalItem) => {
      return {
        ...totalItem,
        value: total?.[totalItem.id as keyof TTotal],
      };
    });
  });
  const otherTransactionTypes = total?.otherTransactionTypes || '';
  const otherCommissionTypes = total?.otherCommissionTypes || '';

  return {
    periodMetrics,
    periodStatistic,
    otherTransactionTypes,
    otherCommissionTypes,
  };
};

export const checkIsMetricsEmpty = (metrics: TAccountsMetrics | null) => {
  if (!metrics) {
    return true;
  }

  const {
    total,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dailyPnL: { cumulative = [], pnl = [], net_pnl = [] },
    nav,
    currencies,
    symbols,
  } = metrics;

  const isTotalData = Object.values(total).find((value) => value);
  const isSymbolsData = symbols.length > 0;
  const isCurrenciesData = currencies.length > 0;
  const isDailyPnL = [...cumulative, ...pnl, ...net_pnl].find((value) => value);
  const isNav = Object.values(nav).find(
    ({ nav: navValue, requiredMargin }) => navValue || requiredMargin,
  );

  return !(
    isTotalData ||
    isSymbolsData ||
    isCurrenciesData ||
    isDailyPnL ||
    isNav
  );
};
