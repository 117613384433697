import { FC } from 'react';

import {
  DottedItemName,
  DottedItemValue,
  DottedItemWrapper,
} from './DottedItem.styled';
import { TDottedItemProps } from './types';

export const DottedItem: FC<TDottedItemProps> = ({
  label,
  value,
  title,
  size = 'small',
}) => {
  return (
    <DottedItemWrapper className="dottedItem" title={title} size={size}>
      <DottedItemName className="dottedItemName">{label}</DottedItemName>
      <DottedItemValue className="dottedItemValue">{value}</DottedItemValue>
    </DottedItemWrapper>
  );
};
