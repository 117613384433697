import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledPanelHeaderSkeletonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;

  padding: 16px 24px;
`;

export const StyledPanelHeaderSkeletonSmall = styled(Skeleton)`
  width: 24px;
  height: 24px;

  &:not(:first-child) {
    margin-left: 8px;
  }
`;
