import { FC, useContext } from 'react';

import { InfoIcon } from 'assets/icons';
import { Tooltip } from 'components/Tooltip';
import { TranslationContext } from 'contexts';
import { StyledAvailableLabel } from 'pages/Portfolio/components/AccountSummary/components/SummaryBlock/components/AvailableLabel/AvailableLabel.styled';

import { TAvailableLabelProps } from './AvailableLabel.types';

export const AvailableLabel: FC<TAvailableLabelProps> = ({
  isMarginCall = false,
}) => {
  const { t } = useContext(TranslationContext);
  const labelText = isMarginCall
    ? t('available-label__margin_call')
    : t('layout__portfolio__margin_call_available');

  const toolTipText = isMarginCall
    ? t('available-label__margin_call__tooltip')
    : t('available-label_tooltip');

  return (
    <StyledAvailableLabel
      className="availableLabel"
      isMarginCall={isMarginCall}
    >
      {labelText}
      <Tooltip title={toolTipText}>
        <InfoIcon className="availableLabelTooltip" />
      </Tooltip>
    </StyledAvailableLabel>
  );
};
