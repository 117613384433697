import { useCallback, useContext } from 'react';

import { TranslationContext } from 'contexts/index';
import { Dates } from 'helpers/dates';

export type TDateFormatter = (date: Date | string) => string;

export const useDateFormatter = (datetime = false) => {
  const { currentLanguage } = useContext(TranslationContext);
  return useCallback(
    (value: Date | string): string => {
      const date = Dates.convertToDate(value);
      if (!date) {
        return '';
      }

      const formatter = datetime
        ? Dates.formatLocaleDateTime
        : Dates.formatLocaleDate;
      return formatter(date, currentLanguage);
    },
    [currentLanguage],
  );
};
