import { blockNonNativeProps, IconButton } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledAmountInfoProps } from './SecuritiesAmount.types';

export const StyledSecuritiesAmountFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 334px;
  padding: 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const StyledIconButton = styled(IconButton)`
  max-width: 180px;
`;

export const StyledSecuritiesAmountFormHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const StyledAmountInfoBottom = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
`;

export const StyledValueRow = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledAmountInfo = styled('div', {
  shouldForwardProp: blockNonNativeProps(['disabled']),
})<TStyledAmountInfoProps>`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.typo.promo};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  svg {
    min-width: 16px;
    color: ${({ theme }) => theme.color.icon.secondary};
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  }
`;
