import { TPersonalInfoForm } from 'services/settings';
import { TUserDetailsDiff } from 'services/userDetails';

const getFieldsForEdit = (isCorporate?: boolean) => {
  return [
    'email',
    'full_name',
    'country_code',
    'full_phone',
    'tax_residence',
    'tax_payer_number',
    'country_of_residence',
  ].concat(
    isCorporate
      ? ['company_name', 'company_classification', 'legal_entity_identifier']
      : ['place_of_birth', 'date_of_birth'],
  );
};

export function getPersonalInfoForm(
  fields: TUserDetailsDiff['fields'],
  isCorporate: boolean,
): Partial<TPersonalInfoForm> {
  const fieldsForEdit = getFieldsForEdit(isCorporate);
  return (fields || []).reduce((acc, curr) => {
    if (!fieldsForEdit.includes(curr.field_name)) {
      return acc;
    }

    // hack for dirty fields in react-hook-form
    if (curr.field_name === 'date_of_birth') {
      return {
        ...acc,
        [curr.field_name]: curr.right ? new Date(curr.right) : curr.right,
      };
    }

    // hack for bo client unexpected symbol
    if (curr.field_name === 'full_phone') {
      return {
        ...acc,
        [curr.field_name]: curr.right.replace(/[^+\d]/g, ''),
      };
    }

    return {
      ...acc,
      [curr.field_name]: curr.right,
    };
  }, {});
}

export function getPendingValues(fields: TUserDetailsDiff['fields']): string[] {
  return (fields || [])
    ?.filter((field) => {
      return field.right !== field.left;
    })
    .map((i) => i.field_name);
}
