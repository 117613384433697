import { Skeleton } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledAccountSummarySkeletonWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 100%;

    display: flex;
    justify-content: space-between;
    gap: 40px;
  }
`;

export const StyledAccountSummarySkeletonColumnWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 100%;
  }
`;

export const StyledAccountSummarySkeletonBlock = styled(Skeleton)`
  width: 64px;
  height: 16px;
  margin-bottom: 9px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 131px;
  }
`;
