import { yupResolver } from '@hookform/resolvers/yup';
import { omit } from 'lodash';
import { FC, useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Skeleton } from 'react-ui-kit-exante';

import { Text } from 'components/Text';
import { TranslationContext } from 'contexts/TranslationContext';
import { notifyWith } from 'helpers/notifyWith';
import { TransfersContext } from 'pages/Portfolio/tabs/Transfers/context';
import { TMfaType } from 'services/mfa';
import { transferService } from 'services/transfer';
import { TWithdrawalHistoryEntry } from 'services/withdrawal';
import { WITHDRAWAL_TYPE } from 'services/withdrawal/withdrawal.constants';
import { selectAccounts } from 'store/accounts';
import { useAppSelector } from 'store/hooks';

import { NoteText } from '../../components/NoteText/NoteText';
import { useTransfers } from '../../hooks';
import {
  StyledTransfersBody,
  StyledTransfersContainer,
  StyledTransfersContainerWrapper,
} from '../shared.styled';

import { TRANSFER_SECURITIES_FORM_VALIDATION_SCHEMA } from './Securities.consts';
import {
  getMappedPositions,
  getTransferSecuritiesForm,
} from './Securities.helper';
import {
  StyledSecuritiesForm,
  StyledSecuritiesHeader,
  StyledSecuritiesTitle,
  StyledTransferContent,
} from './Securities.styled';
import { TTransferSecuritiesForm } from './Securities.types';
import { SecuritiesAmount, SecuritiesForm } from './components';
import { SecuritiesAmountContext } from './components/SecuritiesAmount/contexts/SecurityAmountContext/SecurityAmountContext';
import { SecuritiesContext } from './contexts/SecuritiesContext/SecuritiesContext';

export const SecuritiesWithContext: FC = () => {
  const { t } = useContext(TranslationContext);
  const { selectedAccountId } = useAppSelector(selectAccounts);
  const {
    isLoading: isTransfersLoading,
    updateLastTransactionId,
    transfersState: initialState,
    clearRepeatedWithdrawal,
  } = useTransfers();
  const { fetchServiceFees } = useContext(TransfersContext);
  const {
    requiredFields,
    visibleFields,
    setIsShowConfirmation,
    setIsTransferProcessing,
    isShowConfirmation,
  } = useContext(SecuritiesContext);

  const {
    positionsIsLoading,
    instrumentsIsLoading,
    fieldsErrors,
    fields,
    positions,
    instruments,
    clearFields,
    isNotAvailable,
    fetchPositions,
  } = useContext(SecuritiesAmountContext);

  const defaultValues = getTransferSecuritiesForm(
    visibleFields,
    selectedAccountId || '',
  );

  const resolver = useMemo(
    () =>
      yupResolver(
        TRANSFER_SECURITIES_FORM_VALIDATION_SCHEMA(t, requiredFields),
      ),
    [t, requiredFields],
  );

  const methods = useForm<TTransferSecuritiesForm>({
    defaultValues,
    resolver,
  });

  const { handleSubmit, getValues, setValue, reset } = methods;

  const onTransfer = async () => {
    if (
      !fieldsErrors.some(
        ({ symbolIdError, quantityError }) => symbolIdError || quantityError,
      )
    ) {
      setIsShowConfirmation(true);
    }
  };

  const cancelTransfer = () => {
    setIsShowConfirmation(false);
  };

  const confirmTransfer = async (code: string, confirmationType?: TMfaType) => {
    const mapPositions = getMappedPositions(fields, positions, instruments);

    const transferData = {
      ...omit(getValues()),
      positions: mapPositions,
      all_funds: false,
      is_third_party: false,
      confirm: '2fa',
      token: code,
      confirm_type: confirmationType,
    };

    setIsTransferProcessing(true);
    const response = await transferService.createSecurityTransfer(transferData);
    if (response?.success) {
      notifyWith.success('Withdrawal request has been sent.');
      reset(defaultValues);
      clearFields();
      fetchPositions();
      updateLastTransactionId(response?.withdrawal[0].id);
      cancelTransfer();
    }
    setIsTransferProcessing(false);
  };

  const title = isNotAvailable
    ? t('layout__transfer__not_available')
    : t('layout__transfers__details_title');

  const isLoading =
    isTransfersLoading ||
    positionsIsLoading ||
    instrumentsIsLoading ||
    initialState.fetching.idle ||
    initialState.fetching.pending;

  useEffect(() => {
    if (selectedAccountId) {
      setValue('account', selectedAccountId);
    }
  }, [selectedAccountId]);

  useEffect(() => {
    if (selectedAccountId) {
      fetchServiceFees(selectedAccountId);
    }
  }, [selectedAccountId]);

  useEffect(() => {
    const wr = initialState.repeatedWithdrawal;

    if (!wr) {
      return;
    }

    const repeatedSecurity = Object.keys(
      defaultValues,
    ).reduce<TTransferSecuritiesForm>((acc, curr) => {
      const repeatedValue =
        initialState?.repeatedWithdrawal?.[
          curr as keyof TWithdrawalHistoryEntry
        ];

      const defaultValue =
        defaultValues?.[curr as keyof TTransferSecuritiesForm];

      return {
        ...acc,
        [curr]: repeatedValue || defaultValue,
      };
    }, {});

    reset(repeatedSecurity);
    clearRepeatedWithdrawal();
  }, [initialState.repeatedWithdrawal]);

  return (
    <FormProvider {...methods}>
      <StyledTransfersContainerWrapper className="TransfersContainerWrapper">
        <StyledSecuritiesForm
          className="SecuritiesForm"
          onSubmit={handleSubmit(onTransfer)}
        >
          <StyledTransfersContainer className="TransferContainer">
            <StyledTransfersBody className="TransferBody">
              <StyledTransferContent className="TransferContent">
                <StyledSecuritiesHeader className="SecuritiesHeader">
                  {isLoading ? (
                    <>
                      <Skeleton width={100} height={32} />
                      <Skeleton width={80} height={32} />
                    </>
                  ) : (
                    <StyledSecuritiesTitle className="SecuritiesTitle">
                      {title}
                    </StyledSecuritiesTitle>
                  )}
                </StyledSecuritiesHeader>
                {isNotAvailable && (
                  <Text size="15px" weight="400" color="secondary">
                    {t('layout__transfer__not_available_description')}
                  </Text>
                )}
                {!isNotAvailable && (
                  <>
                    <SecuritiesForm
                      loading={isLoading}
                      formIsDisabled={isShowConfirmation}
                    />
                    <NoteText type={WITHDRAWAL_TYPE.SECURITY} />
                  </>
                )}
              </StyledTransferContent>
            </StyledTransfersBody>
            <SecuritiesAmount
              loading={isTransfersLoading}
              handleConfirm={confirmTransfer}
              handleCancelConfirmation={cancelTransfer}
            />
          </StyledTransfersContainer>
        </StyledSecuritiesForm>
      </StyledTransfersContainerWrapper>
    </FormProvider>
  );
};
