import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledExchangeSummaryRow = styled('dl')`
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  padding: 0;
  font-size: 15px;
  line-height: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  dt {
    color: ${({ theme }) => theme.color.typo.secondary};
  }

  dd {
    font-weight: bold;
  }
`;

export const StyledExchangeSummaryButton = styled(Button)`
  margin-top: 24px;
`;

export const StyledExchangeSummaryWarning = styled('div')`
  margin-top: 8px;
  font-size: 13px;
`;
