import { styled } from 'theme';

export const StyledTermsInterestHeader = styled('div')`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  padding: 0 24px;
  height: 34px;
`;
