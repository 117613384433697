export const getButtonColor = (isDanger?: boolean, isActive?: boolean) => {
  if (isDanger) {
    return 'radical';
  }

  if (isActive) {
    return 'action';
  }
  return 'secondary';
};
