import { FC, useContext, useMemo } from 'react';

import { DEFAULT_EMPTY_PLACEHOLDER } from 'constants/common';
import { BrandingContext } from 'contexts/BrandingContext';
import { TranslationContext } from 'contexts/TranslationContext';
import { formatCurrency } from 'helpers/formatters';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';

import {
  StyledPartnerPaymentsSetupHeader,
  StyledPartnerPaymentsSetupText,
  StyledPartnerPaymentsSetupValue,
  StyledPartnerPaymentsSetupWrapper,
} from './PartnerPaymentsSetup.styled';
import { TPartnerPaymentsSetupProps } from './PartnerPaymentsSetup.types';

export const PartnerPaymentsSetup: FC<TPartnerPaymentsSetupProps> = ({
  currency,
}) => {
  const { t } = useContext(TranslationContext);
  const { branding } = useContext(BrandingContext);
  const formatter = useCurrencyFormatter(currency || '');

  const value = useMemo(() => {
    if (!currency) {
      return DEFAULT_EMPTY_PLACEHOLDER;
    }

    return currency === 'EUR' ? 100 : 500;
  }, [currency]);

  return (
    <StyledPartnerPaymentsSetupWrapper className="PartnerPaymentsSetupWrapper">
      <StyledPartnerPaymentsSetupHeader className="PartnerPaymentsSetupHeader">
        {t('layout__partners__payment-setup__title')}
      </StyledPartnerPaymentsSetupHeader>
      <StyledPartnerPaymentsSetupValue className="PartnerPaymentsSetupValue">
        {formatCurrency(formatter, value)}
      </StyledPartnerPaymentsSetupValue>
      <StyledPartnerPaymentsSetupText className="PartnerPaymentsSetupText">
        {t('layout__partners__payment-setup__text__payment-date')}
      </StyledPartnerPaymentsSetupText>
      <StyledPartnerPaymentsSetupText className="PartnerPaymentsSetupText">
        {t('layout__partners__payment-setup__text__fee', {
          WL_NAME: branding?.wl_name,
        })}
      </StyledPartnerPaymentsSetupText>
    </StyledPartnerPaymentsSetupWrapper>
  );
};
