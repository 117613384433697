interface IErrorFallbackProps {
  error: Error;
  resetErrorBoundary(): void;
}

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: IErrorFallbackProps) => {
  console.error(error);

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};
