import { styled } from 'theme';

export const StyledMetricsSectionContainer = styled('div')`
  padding-top: 24px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    border-top: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;

export const StyledMetricsSectionValues = styled('div')`
  padding: 0 24px 24px;
`;

export const StyledMetricsSectionSkeletonRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const StyledMetricsSectionLabel = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`;
