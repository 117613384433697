import HCaptcha from '@hcaptcha/react-hcaptcha';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Skeleton } from 'react-ui-kit-exante';
import { Notification as UiKitNotification } from 'react-ui-kit-exante/build/Components/Notification/Notification';

import { FormCheckboxContainer } from 'components/Form/FormCheckboxContainer/FormCheckboxContainer';
import { FormInputContainer } from 'components/Form/FormInputContainer/FormInputContainer';
import { FormPhoneInputContainer } from 'components/Form/FormPhoneInputContainer/FormPhoneInputContainer';
import { BrandingContext, TranslationContext } from 'contexts';
import { PATHS } from 'router';
import { settingsProfileService } from 'services/settings';

import { DepositType } from '../../../Portfolio/tabs/Deposit/components/DepositWrapper/DepositWrapper.types';
import {
  StyledCaptchaErrorAction,
  StyledCaptchaErrorText,
  StyledCaptchaErrorWrapper,
  StyledCaptchaWrapper,
  StyledLoginLogo,
  StyledLoginLogoSkeleton,
} from '../../Login.styled';
import { StyledAuthPrivacyIcon } from '../EmailVerification/EmailVerification.styled';

import {
  EMAIL_REGEX,
  SIGNUP_FORM_VALIDATION_SCHEMA,
  SIGNUP_PHONE_COUNTRIES_EXCLUDED,
} from './Signup.constants';
import {
  StyledSigninButton,
  StyledSigninText,
  StyledSignupForm,
  StyledSignupFormRow,
  StyledSignupHeading,
  StyledSignupWrapper,
  StyledToggleButtonGroup,
  StyledToggleWrapper,
} from './Signup.styled';
import { AccountType, TAccountTypeOption, TSignupForm } from './Signup.types';
import { SignupFormSkeleton } from './components/SignupFormSkeleton';
import { useSignup } from './hooks/useSignup';

export const Signup = () => {
  const { branding } = useContext(BrandingContext);
  const navigate = useNavigate();

  const { t, currentLanguage, isTranslationsLoading } =
    useContext(TranslationContext);

  const iconAuthPrivacy = branding?.branding?.ui_settings?.icon_auth_privacy;
  const WL_NAME = branding?.wl_name;

  const [checkingEmail, setCheckingEmail] = useState<boolean>(false);
  const [typeSelected, setTypeSelected] = useState<DepositType | null>(null);

  const handleTypeChange = (value: any) => {
    setTypeSelected(value);
  };

  const accountTypeOptions: TAccountTypeOption[] = [
    {
      value: AccountType.Individual,
      label: t('generic__account_type__individual'),
    },
    {
      value: AccountType.Corporate,
      label: t('generic__account_type__corporate'),
    },
  ].filter((type) => !branding?.hide_open_account_type?.includes(type.value));

  useEffect(() => {
    if (accountTypeOptions.length === 0) {
      navigate(PATHS.LOGIN);
    }
  }, [accountTypeOptions]);

  const accountType =
    typeSelected || accountTypeOptions[0]?.value || AccountType.Individual;

  const methods = useForm<TSignupForm>({
    resolver: yupResolver(
      SIGNUP_FORM_VALIDATION_SCHEMA(t, accountType === AccountType.Corporate),
    ),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      marketing_agree: true,
    },
  });

  const { handleSubmit, setError, getValues, clearErrors, trigger } = methods;

  const onError = (field: keyof TSignupForm, message: string) => {
    setError(field, { message });
  };

  const {
    signup,
    isLoading,
    captcha,
    captchaRef,
    setCaptcha,
    setCaptchaToken,
  } = useSignup({
    getValues,
    onError,
  });

  const handleCaptchaErrorClick = () => {
    setCaptcha((state) => ({
      ...state,
      visible: true,
      error: '',
    }));
  };

  const handleCaptchaError = (err: string) => {
    console.error(`hCaptcha Error: ${err}`);
    UiKitNotification.error({ title: err });

    setCaptcha((state) => ({ ...state, visible: true, error: '' }));
  };

  const handleCaptchaExpire = () => {
    setCaptchaToken('');
  };

  const handleEmailBlur = async () => {
    trigger('email');

    const values = getValues();

    if (!values.email || checkingEmail) {
      return;
    }

    clearErrors('email');
    setCheckingEmail(true);

    try {
      const res = await settingsProfileService.checkEmail(
        values.email,
        currentLanguage,
      );

      if (!res || !res.valid) {
        onError(
          'email',
          !EMAIL_REGEX.test(res?.email ?? values.email)
            ? t('email_input__plus_minus')
            : t('email_input__invalid'),
        );
      } else if (res.exists) {
        onError('email', t('email_input__exists'));
      } else if (res.in_blacklist) {
        onError('email', t('email_input__in_blacklist'));
      }
    } finally {
      setCheckingEmail(false);
    }
  };

  return (
    <StyledSignupWrapper className="SignupWrapper">
      {isTranslationsLoading ? (
        <StyledLoginLogoSkeleton width={140} height={40} />
      ) : (
        <StyledLoginLogo className="LoginLogo" />
      )}

      {isTranslationsLoading ? (
        <Skeleton width="100%" height={64} />
      ) : (
        <StyledSignupHeading className="LoginHeading">
          {t('generic__open_account')}
        </StyledSignupHeading>
      )}

      <StyledToggleWrapper className="ToggleWrapper">
        <StyledToggleButtonGroup
          className="ToggleButtonGroup"
          onChange={handleTypeChange}
          options={accountTypeOptions}
          value={accountType}
          showSkeleton={isTranslationsLoading}
          exclusive
          fullWidth
        />
      </StyledToggleWrapper>

      <FormProvider {...methods}>
        {isTranslationsLoading ? (
          <SignupFormSkeleton />
        ) : (
          <StyledSignupForm
            className="SignupForm"
            onSubmit={handleSubmit(signup)}
          >
            <StyledSignupFormRow className="SignupFormRow">
              <FormInputContainer
                type="text"
                name="first_name"
                autoComplete="given-name"
                label={t('generic__first_name')}
                disabled={isLoading}
                fullWidth
              />

              <FormInputContainer
                type="text"
                name="last_name"
                autoComplete="family-name"
                label={t('generic__last_name')}
                disabled={isLoading}
                fullWidth
              />
            </StyledSignupFormRow>

            <FormInputContainer
              type="text"
              name="email"
              autoComplete="email"
              label={t('generic__email')}
              disabled={isLoading}
              onBlur={handleEmailBlur}
              fullWidth
            />

            <FormPhoneInputContainer
              name="phone"
              autoComplete="tel"
              label={t('layout__settings__user_details__phone')}
              disabled={isLoading}
              countriesExcluded={SIGNUP_PHONE_COUNTRIES_EXCLUDED}
              fullWidth
            />

            {accountType === AccountType.Corporate && (
              <FormInputContainer
                type="text"
                name="company"
                autoComplete="organization"
                label={t('company_name')}
                disabled={isLoading}
                fullWidth
              />
            )}

            <FormInputContainer
              type="text"
              name="comment"
              autoComplete="off"
              label={t('generic__comment')}
              placeholder={t('generic__comment')}
              helperText={t('layout__open_account__comment_helper')}
              fullWidth
            />

            <FormCheckboxContainer
              name="marketing_agree"
              label={
                WL_NAME
                  ? t('layout__open_account__consent_checkbox', { WL_NAME })
                  : t('generic__marketing_agree')
              }
              disabled={isLoading}
            />

            {captcha.enabled && (
              <StyledCaptchaWrapper className="CaptchaWrapper">
                {captcha.visible && (
                  <HCaptcha
                    sitekey={captcha.site_key}
                    languageOverride={currentLanguage}
                    reCaptchaCompat={false}
                    onVerify={setCaptchaToken}
                    onError={handleCaptchaError}
                    onExpire={handleCaptchaExpire}
                    ref={captchaRef}
                  />
                )}

                {captcha.error && !captcha.visible && (
                  <StyledCaptchaErrorWrapper className="CaptchaErrorWrapper">
                    <StyledCaptchaErrorText className="CaptchaErrorText">
                      {t('generic__something_went_wrong')}.
                    </StyledCaptchaErrorText>
                    <StyledCaptchaErrorAction
                      className="CaptchaErrorButton"
                      color="transparent"
                      onClick={handleCaptchaErrorClick}
                    >
                      {t('generic__action__retry')}
                    </StyledCaptchaErrorAction>
                  </StyledCaptchaErrorWrapper>
                )}
              </StyledCaptchaWrapper>
            )}

            <Button
              type="submit"
              color="primary"
              loading={isLoading}
              disabled={Boolean(captcha.error)}
              fullWidth
            >
              {t('layout__auth__get_started')}
            </Button>
          </StyledSignupForm>
        )}
      </FormProvider>

      {isTranslationsLoading ? (
        <Skeleton />
      ) : (
        <StyledSigninText className="SigninText">
          {t('layout__auth__already_have_account')}{' '}
          <StyledSigninButton to={PATHS.LOGIN}>
            {t('layout__auth__log_in')}
          </StyledSigninButton>
        </StyledSigninText>
      )}

      {iconAuthPrivacy && (
        <StyledAuthPrivacyIcon
          className="AuthPrivacyIcon"
          src={iconAuthPrivacy}
          alt={t('generic__privacy_protect')}
        />
      )}
    </StyledSignupWrapper>
  );
};
